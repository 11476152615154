import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "_ReactContext/AuthContext";
import { featuresOtherPlan } from "./useFeatureUpgradePlan";

interface IAlertUpgradePlanProps {
    feature: featuresOtherPlan;
}

const AlertUpgradePlan = (props: IAlertUpgradePlanProps) => {
    const { feature } = props;
    const { isFeatureAuthorized } = useAuthorization();
    const showAlert = isFeatureAuthorized(getFeatureName(feature));
    const [t, i18n, readyTranslation] = useTranslation(
        "MODALFEATUREUPGRADEPLAN",
        {
            useSuspense: false
        }
    );

    if (readyTranslation && showAlert) {
        return (
            <Alert severity="success" style={{ margin: 16 }}>
                <AlertTitle>{t("ModalTitle")}</AlertTitle>
                {t(`messageFeature_${feature}`)}
            </Alert>
        );
    } else {
        return null;
    }
};

const getFeatureName = (feature: featuresOtherPlan) => {
    switch (feature) {
        case "bulkActionsATS":
            return "BulkActions_Upgrade";
        case "bulkParser":
            return "BulkParser_Upgrade";
        case "emailParser":
            return "EmailParser_Upgrade";
        case "dashboards":
            return "Dashboard_Upgrade";
        case "pipelineAutomation":
            return "PipelineAutomation_Upgrade";
        case "scorecard":
            return "Scorecard_Upgrade";
    }
};

export default AlertUpgradePlan;
