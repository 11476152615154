import { Box, Grid, Paper } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import {
    FunnelChart,
    Tooltip,
    Funnel,
    LabelList,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Legend
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import {
    handleFunnelData,
    IJobFunnelChildren,
    IMainJobFunnelData
} from "./funnelHelper";
import { Alert } from "@material-ui/lab";

const JobDisqualifyReasons = (props: IJobFunnelChildren) => {
    const { mainData, t } = props;

    // const data = handleFunnelData(mainData);
    const data = mainData?.data?.disqualifyReasons || [];
    data.sort((a, b) => b.total - a.total);

    const colorData = useRandomColor({
        objectKeys: data.map((i) => i.disqualifyReason),
        luminosity: "dark"
    });
    const finalData = data.map((i) => {
        const color = colorData.colorsObject[i.disqualifyReason];
        return {
            ...i,
            name: i.disqualifyReason,
            value: i.total,
            fill: color
        };
    });

    if (data.length) {
        return (
            <ResponsiveContainer
                minHeight={300}
                minWidth={300}
                width="99%"
                maxHeight={500}
            >
                <PieChart>
                    <Pie
                        data={finalData}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={true}
                        // label={renderCustomizedLabel}
                        // outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                        isAnimationActive={false}
                    >
                        {finalData.map((entry, index: number) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    colorData.colorsObject[
                                        entry.disqualifyReason
                                    ]
                                }
                            />
                        ))}
                    </Pie>
                    <Legend verticalAlign="bottom" height={36} />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <Alert severity="warning" style={{ margin: 16 }}>
                {t("noRejectedApplicants")}
            </Alert>
        );
    }
};

export default JobDisqualifyReasons;
