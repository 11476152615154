import React from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginTop: 4,
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        },
        chipTable: {
            borderRadius: 6
        }
    })
);

export const RecipientStatusColor = {
    // approvedRequest: "#ffac33",
    // canceled: "#ff3d00",
    // disapprovedRequest: "#ff3d00",
    // draft: "#9e9e9e",
    user: "#2196f3",
    applicant: "#4caf50",
    // applicant: "#795548",
    // user: "#ffac33",
    contact: "#78909c"
};

export interface IRecipientsTags {
    name: string;
    email: string;
    applicant?: boolean;
}

interface IProps {
    recipients: IRecipientsTags[];
    applicantEmail: string;
    t: any;
    withoutColors?: boolean;
}
const ApplicantEmailRecipientsTags = (props: IProps) => {
    const classes = useStyles();
    const { recipients, applicantEmail, t, withoutColors = false } = props;

    return (
        <>
            {recipients.map((recipient) => {
                const isApplicantFromParam = recipient.applicant === true;
                const isApplicantFromEmail = recipient.email === applicantEmail;
                const isApplicant =
                    isApplicantFromParam || isApplicantFromEmail;
                const colorType = isApplicant ? "applicant" : "user";
                const infoText = isApplicant ? ` (${t("applicant")})` : "";

                return (
                    <Chip
                        key={recipient.email}
                        className={classes.chipCard}
                        style={
                            withoutColors === false
                                ? {
                                      backgroundColor:
                                          RecipientStatusColor[colorType],
                                      color: "white"
                                  }
                                : undefined
                        }
                        size="small"
                        label={`${
                            recipient.name || recipient.email
                        }${infoText}`}
                    />
                );
            })}
        </>
    );
};

export default ApplicantEmailRecipientsTags;
