import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" }
    })
);

const ApplicantHistoryLoading = (props: any) => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={classes.paper}>
            <Typography variant="body2">
                <Skeleton variant="text" width="100%" />
            </Typography>
            <Typography variant="caption">
                <Skeleton variant="text" width="100%" />
            </Typography>
        </Paper>
    );
};

export default ApplicantHistoryLoading;
