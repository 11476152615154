import React from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1)
        },
        extendedIcon: {
            marginRight: theme.spacing(1)
        },
        buttonAdd: {
            marginLeft: theme.spacing(1)
        },
        gridSearch: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(0.1),
            marginLeft: theme.spacing(1)
        }
    })
);

export type listFieldsType = {
    label: string;
    value: string;
    group: string;
    fieldType: "regular" | "custom" | "questionnaire";
    customFieldType?: string;
    listData?: any[] | null;
};

interface IProps {
    options: listFieldsType[];
    value: listFieldsType | null;
    setValue: React.Dispatch<React.SetStateAction<listFieldsType | null>>;
}

const AdvancedSearchFormAddFilter = (props: IProps) => {
    const classes = useStyles();
    const { options, value, setValue } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;

    const { otherFiltersGroup } =
        listData.definitionInfo.elasticDefData?.data || {};

    return (
        <Grid container item className={classes.gridSearch}>
            <Autocomplete
                options={options}
                size="small"
                value={value}
                // className={classes.searchField}
                onChange={(event: any, newValue: listFieldsType | null) => {
                    if (newValue) {
                        setValue(newValue);
                        // addItem(newValue?.value, newValue?.customFieldType);
                        // addItem(newValue?.value, newValue);
                    } else {
                        setValue(null);
                    }
                }}
                groupBy={(option) => option.group}
                renderGroup={(params) => {
                    // const iconName = otherFiltersGroup[params.group]?.icon;
                    const iconName = getIconName(otherFiltersGroup, params);
                    return [
                        <Grid
                            container
                            style={{ marginLeft: 9, width: "90%" }}
                            key={params.key}
                            spacing={2}
                        >
                            <Grid item xs={1}>
                                <Icon>{iconName}</Icon>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant="body2"
                                    style={{ fontWeight: 600 }}
                                    component="span"
                                >
                                    {t(
                                        `otherFilterGroup_${params.group}`,
                                        t(
                                            `ELASTICLISTSETTINGS:otherFilterGroup_${params.group}`
                                        )
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>,
                        params.children
                    ];
                }}
                renderOption={(option) => {
                    return (
                        <Typography
                            variant="body2"
                            style={{ marginLeft: 24 }}
                            component="span"
                        >
                            {option.label}
                        </Typography>
                    );
                }}
                getOptionLabel={(option) => option.label}
                style={{ width: 550 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(
                            "ELASTICLISTSETTINGS:AdvancedFilter_AddFieldlSearch"
                        )}
                        variant="outlined"
                    />
                )}
            />
            {/* {value?.value && (
                <ButtonIconTooltipCompleo
                    label={t(
                        "ELASTICLISTSETTINGS:AdvancedFilter_AddFieldlButton"
                    )}
                    onClick={() => addItem(value?.value, value)}
                >
                    <PlusOneRounded color="primary" />
                </ButtonIconTooltipCompleo>
            )} */}
        </Grid>
    );
};

const getIconName = (otherFiltersGroup: any, groupParams: any) => {
    const iconName = otherFiltersGroup[groupParams.group]?.icon;
    switch (groupParams.group) {
        case "__customFields__":
            return "settings_applications";
        case "__questionFields__":
            return "question_answer";
        default:
            return iconName || "more_horiz";
    }
};

export default AdvancedSearchFormAddFilter;
