import { addYears, addDays, toDate, format } from "date-fns";
import { ApiStateType } from "customHooks/useApi";
import { getFilteredMetadataAndRenamedMetadataComplexField } from "../Inputs/ComplexField";
import Yup from "customHooks/useCompleoForm/helpers/yupHelper";
import { clearArrayInfo } from "functions/util";

const entries = (obj: any) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array

    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
};

export const getMetadata = (
    metadadosRetorno: ApiStateType,
    language: string,
    timeZone?: null | string
) => {
    let camposMetadados: any[] = [];
    let listas = {};
    let formGroups: any[] = [];
    let IsMultiStepForm = false;
    let MultiStepForm: Compleo.IObject[] = [];

    //console.log("getMetadata metadadosRetorno", metadadosRetorno);
    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined
    ) {
        let metadataInitial = metadadosRetorno.response.data.metadado;

        camposMetadados = orderList(
            metadataInitial.map((item: any) => {
                if (item.elementConfig === undefined) {
                    item.elementConfig = {};
                }
                switch (item.elementType) {
                    case "dateOfBirth":
                    case "date":
                    case "datetime":
                    case "datetimeRange":
                        item.elementConfig.language = language;
                        item.elementConfig.timeZone =
                            timeZone ||
                            Intl.DateTimeFormat().resolvedOptions().timeZone ||
                            "America/Sao_Paulo";
                        break;
                    case "integer":
                    case "currency":
                    case "decimal":
                    case "numberrange":
                        item.elementConfig.language = language;
                        break;
                    default:
                        break;
                }

                return item;
            })
        );
    }

    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined &&
        metadadosRetorno.response.data.formGroups !== undefined
    ) {
        const allFormGroupsFromMetadata: string[] = camposMetadados.map(
            (item: Compleo.IObject) => item.formGroupId
        );
        formGroups = orderList(
            metadadosRetorno.response.data.formGroups
                .map((item: any) => item)
                .filter((item: any) =>
                    allFormGroupsFromMetadata.includes(item.id)
                )
        );
    }

    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined &&
        metadadosRetorno.response.data.listas !== undefined
    ) {
        listas = metadadosRetorno.response.data.listas;

        for (let i = 0; i < Object.keys(listas).length; i++) {
            entries(listas)[i][1].map((item: any) => {
                item[`label`] = item[`label-${language}`] || item.label;
                return item;
            });
        }
    }

    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined &&
        metadadosRetorno.response.data.IsMultiStepForm !== undefined
    ) {
        IsMultiStepForm = metadadosRetorno.response.data.IsMultiStepForm;
    }

    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined &&
        metadadosRetorno.response.data.MultiStepForm !== undefined
    ) {
        const allStepFromMetadata: string[] = formGroups.map(
            (item: Compleo.IObject) => item.stepId
        );
        MultiStepForm = orderList(
            metadadosRetorno.response.data.MultiStepForm.map(
                (item: any) => item
            ).filter((item: any) => allStepFromMetadata.includes(item.id))
        );
    }

    return {
        camposMetadados,
        listas,
        formGroups,
        IsMultiStepForm,
        MultiStepForm
    };
};

export const getSchema = (
    yup: any,
    objeto: any,
    t: any,
    companyId: number,
    ignoreComplexField: boolean = true
) => {
    let newObject: { [key: string]: any };
    newObject = {};
    let isRequiredField = false;

    if (objeto !== undefined) {
        // generated backend fields removed from validation
        let completeMetadata = [...objeto];
        objeto = objeto.filter(
            (o: any) =>
                !(o.elementType || "").includes("backend_") &&
                (!(o.complexFieldDetail === true) || !ignoreComplexField)
        );

        // remove from client validation backend fields
        objeto = objeto.filter((o: any) => o.backendField !== true);
        objeto.map((item: any) => {
            switch (item.elementType) {
                case "dateOfBirth":
                case "date":
                case "datetime":
                    newObject[item.fieldName] = yup.date().nullable(true);
                    break;
                case "dateTimeRange":
                    // newObject[item.fieldName] = yup.date().nullable(true);
                    newObject[item.fieldName] = yup.object().shape({
                        startDateTime: yup
                            .date()
                            .nullable(true)
                            .typeError(
                                t(`${item.fieldName}.startDateTimeInvalidDate`)
                            )
                            .isValidDate({
                                message: t(
                                    `${item.fieldName}.startDateTimeInvalidDate`
                                )
                            }),
                        endDateTime: yup
                            .date()
                            .nullable(true)
                            .typeError(
                                t(`${item.fieldName}.endDateTimeInvalidDate`)
                            )
                            .isValidDate({
                                message: t(
                                    `${item.fieldName}.endDateTimeInvalidDate`
                                )
                            })
                            .isMoreThanOrEmpty({
                                message: t(
                                    `${item.fieldName}.endTimeGreatherThanStartDateTime`,
                                    {
                                        fieldToCheck: t(
                                            `${item.fieldName}.startDateTime`
                                        ),
                                        fieldName: t(
                                            `${item.fieldName}.endDateTime`
                                        )
                                    }
                                ),
                                fieldToCompare: "startDateTime"
                            })
                    });
                    const requiredDataValidation = (
                        item.validation || []
                    ).filter((i: any) => i.type === "required")[0];
                    if (requiredDataValidation) {
                        const fieldSchema = newObject[item.fieldName].fields;
                        newObject[item.fieldName] = yup.object().shape({
                            startDateTime: fieldSchema.startDateTime.required(
                                t(`${item.fieldName}.startDateTimeRequired`)
                            ),
                            endDateTime: fieldSchema.endDateTime.required(
                                t(`${item.fieldName}.endDateTimeRequired`)
                            )
                        });
                    }

                    break;
                case "number":
                    newObject[
                        item.fieldName
                    ] = yup.number().integer().positive();
                    break;
                case "integer":
                case "decimal":
                    newObject[item.fieldName] = yup.number();
                    break;
                case "currency":
                    isRequiredField =
                        (item.validation &&
                            item.validation.length &&
                            item.validation.filter(
                                (v: any) => v.type === "required"
                            ).length) ||
                        item.required;

                    if (isRequiredField) {
                        newObject[item.fieldName] = yup.object().shape({
                            currency: yup.string(),
                            value: yup.number()
                        });
                    } else {
                        newObject[item.fieldName] = yup.object().shape({
                            currency: yup.string().nullable(true),
                            value: yup.number().nullable(true)
                        });
                    }
                    break;
                case "numberrange":
                    newObject[item.fieldName] = yup.object().shape({
                        type: yup.object().nullable(true),
                        minValue: yup.number().nullable(true),
                        maxValue: yup.number().nullable(true),
                        currency: yup.string().nullable(true)
                    });

                    break;
                case "arrayOneOf":
                    newObject[item.fieldName] = yup.array();
                    break;
                case "complexField":
                    const isArray =
                        (item.complexField || {}).isArray === undefined
                            ? false
                            : (item.complexField || {}).isArray;

                    const childMetadata = getFilteredMetadataAndRenamedMetadataComplexField(
                        item.fieldName,
                        completeMetadata
                    );
                    const childSchema = getSchema(
                        yup,
                        childMetadata.renamedMetadata,
                        t,
                        companyId,
                        false
                    );

                    const subSchemaParsed = Yup.object().shape(childSchema);
                    if (isArray) {
                        newObject[item.fieldName] = yup
                            .array()
                            .of(subSchemaParsed);
                    } else {
                        newObject[item.fieldName] = yup.object().nullable(true);
                    }
                    break;

                case "uniqueCheckbox":
                case "checkbox":
                    newObject[item.fieldName] = yup.bool();
                    break;
                case "select":
                case "radiobuttongroup":
                case "selectCreatable":
                    newObject[item.fieldName] = yup.object().nullable(true);
                    break;
                case "address":
                    newObject[item.fieldName] = yup
                        .object()
                        .shape({
                            country: yup.object().nullable(true),
                            city: yup.object().nullable(true),
                            provinceOrState: yup.object().nullable(true),
                            postalCode: yup.string(),
                            addressline1: yup.string(),
                            addressline2: yup.string(),
                            houseNumber: yup.string(),
                            neighborhood: yup.string(),
                            latitude: yup.string(),
                            longitude: yup.string(),
                            ibgecode_city: yup.string(),
                            ibgecode_state: yup.string()
                        })
                        .nullable(true);
                    break;
                case "delayAction":
                    // newObject[item.fieldName] = yup.object().nullable(true);
                    newObject[item.fieldName] = yup.object().shape({
                        time: yup.string(),
                        timeZone: yup.number(),
                        delayDetail: yup.object()
                    });
                    break;
                case "file":
                case "image":
                    newObject[item.fieldName] = yup.object().nullable(true);
                    break;
                case "multipleFiles":
                    newObject[item.fieldName] = yup.array();
                    break;
                case "hidden":
                    switch (item.fieldName) {
                        case "deleted":
                        case "enabled":
                        case "default":
                            newObject[item.fieldName] = yup.bool();
                            break;
                        default:
                            newObject[item.fieldName] = yup.string();
                            break;
                    }
                    switch (item.fieldType) {
                        case undefined:
                            break;
                        case "boolean":
                            newObject[item.fieldName] = yup.bool();
                            break;
                        case "object":
                            newObject[item.fieldName] = yup
                                .object()
                                .nullable(true);
                            break;
                        case "date":
                            newObject[item.fieldName] = yup
                                .date()
                                .nullable(true);
                            break;
                        case "array":
                            newObject[item.fieldName] = yup.array();
                            break;
                    }
                    break;
                case "emailData":
                    {
                        const emailDataValidation = item.validation.filter(
                            (i: any) => i.type === "placeHolderCheck"
                        )[0];
                        const emailDataPlaceholderRequiredValidation = item.validation.filter(
                            (i: any) => i.type === "placeHolderCheckRequired"
                        )[0];
                        const requiredDataValidation = item.validation.filter(
                            (i: any) => i.type === "required"
                        )[0];

                        let objEmailDataSchema = yup.object().shape({
                            defaultLanguage: yup.string(),
                            _tempEmailData: yup.object().shape({
                                subject: yup.string(),
                                body: yup.string()
                            })
                        });
                        if (emailDataValidation) {
                            const messageId = (
                                emailDataValidation.messageId || ""
                            ).replace("COMPLEXFIELD:", "");

                            objEmailDataSchema = yup.object().shape({
                                defaultLanguage: yup.string(),
                                _tempEmailData: yup.object().shape({
                                    subject: yup.string().valueExistsInArray({
                                        message: t(messageId),
                                        fieldToCompare:
                                            emailDataValidation.fieldToCheck_subject,
                                        levelObject:
                                            emailDataValidation.levelObject
                                    }),
                                    body: yup.string().valueExistsInArray({
                                        message: t(
                                            emailDataValidation.messageId
                                        ),
                                        fieldToCompare:
                                            emailDataValidation.fieldToCheck_body,
                                        levelObject:
                                            emailDataValidation.levelObject
                                    })
                                })
                            });
                        }
                        if (emailDataPlaceholderRequiredValidation) {
                            const fieldsEmailSchema = objEmailDataSchema.fields;
                            const templateEmailFields =
                                objEmailDataSchema.fields._tempEmailData.fields;

                            const messageId = (
                                emailDataPlaceholderRequiredValidation.messageId ||
                                ""
                            ).replace("COMPLEXFIELD:", "");

                            objEmailDataSchema = yup.object().shape({
                                defaultLanguage:
                                    fieldsEmailSchema.defaultLanguage,
                                _tempEmailData: yup.object().shape({
                                    subject: templateEmailFields.subject,
                                    body: templateEmailFields.body.valueRequiredInArray(
                                        {
                                            message: t(messageId),
                                            fieldToCompare:
                                                emailDataPlaceholderRequiredValidation.fieldToCheck,
                                            levelObject:
                                                emailDataPlaceholderRequiredValidation.levelObject
                                        }
                                    )
                                })
                            });
                        }
                        if (requiredDataValidation) {
                            const fieldsEmailSchema = objEmailDataSchema.fields;
                            const templateEmailFields =
                                objEmailDataSchema.fields._tempEmailData.fields;

                            const messageId = (
                                requiredDataValidation.messageId || ""
                            ).replace("COMPLEXFIELD:", "");

                            objEmailDataSchema = yup.object().shape({
                                defaultLanguage:
                                    fieldsEmailSchema.defaultLanguage,
                                _tempEmailData: yup.object().shape({
                                    subject: templateEmailFields.subject.required(
                                        t(messageId)
                                    ),
                                    body: templateEmailFields.body.required(
                                        t(messageId)
                                    )
                                })
                            });
                        }

                        newObject[item.fieldName] = yup
                            .array()
                            .of(objEmailDataSchema);
                    }
                    break;
                case "scheduleData":
                    {
                        const scheduleDataValidation = item.validation.filter(
                            (i: any) => i.type === "placeHolderCheck"
                        )[0];
                        const requiredDataValidation = item.validation.filter(
                            (i: any) => i.type === "required"
                        )[0];

                        let objScheduleDataSchema = yup.object().shape({
                            language: yup.string(),
                            default: yup.bool(),
                            subject: yup.string(),
                            body: yup.string(),
                            location: yup.string()
                        });
                        if (scheduleDataValidation) {
                            objScheduleDataSchema = yup.object().shape({
                                defaultLanguage: yup.string(),
                                subject: yup.string().valueExistsInArray({
                                    message: t(
                                        scheduleDataValidation.messageId
                                    ),
                                    fieldToCompare:
                                        scheduleDataValidation.fieldToCheck_subject,
                                    levelObject:
                                        scheduleDataValidation.levelObject
                                }),
                                body: yup.string().valueExistsInArray({
                                    message: t(
                                        scheduleDataValidation.messageId
                                    ),
                                    fieldToCompare:
                                        scheduleDataValidation.fieldToCheck_body,
                                    levelObject:
                                        scheduleDataValidation.levelObject
                                })
                            });
                        }
                        if (requiredDataValidation) {
                            const fieldsEmailSchema =
                                objScheduleDataSchema.fields;

                            const messageId = (
                                requiredDataValidation.messageId || ""
                            ).replace("COMPLEXFIELD:", "");
                            objScheduleDataSchema = yup.object().shape({
                                defaultLanguage:
                                    fieldsEmailSchema.defaultLanguage,
                                subject: fieldsEmailSchema.subject.required(
                                    t(messageId)
                                ),
                                body: fieldsEmailSchema.body.required(
                                    t(messageId)
                                )
                            });
                        }

                        newObject[item.fieldName] = yup
                            .array()
                            .of(objScheduleDataSchema);
                    }
                    break;
                case "rating":
                    newObject[item.fieldName] = yup.number();
                    break;
                case "custom":
                    switch (item.customFieldType) {
                        case "scorecardEvaluationObject": {
                            const messageItemsRequiredId = "scorecardItemsRequired".replace(
                                "COMPLEXFIELD:",
                                ""
                            );

                            const scorecardSectionItemSchema = yup
                                .object()
                                .shape({
                                    name: yup
                                        .string()
                                        .required(t(messageItemsRequiredId)),
                                    id: yup
                                        .string()
                                        .required(t(messageItemsRequiredId)),
                                    evaluation: yup
                                        .number()
                                        .min(0.1, t(messageItemsRequiredId))
                                });

                            const scorecardSectionSchema = yup.object().shape({
                                name: yup
                                    .string()
                                    .required(t(messageItemsRequiredId)),
                                id: yup
                                    .string()
                                    .required(t(messageItemsRequiredId)),
                                items: yup
                                    .array()
                                    .of(scorecardSectionItemSchema)
                            });

                            newObject[item.fieldName] = yup
                                .array()
                                .of(scorecardSectionSchema);

                            break;
                        }
                        case "object": {
                            newObject[item.fieldName] = yup.object();
                            break;
                        }
                        case "array": {
                            newObject[item.fieldName] = yup.array();
                            break;
                        }
                        default:
                            newObject[item.fieldName] = yup.string();

                            break;
                    }
                    break;
                case "tagsCreatableArray":
                case "tags":
                    newObject[item.fieldName] = yup.array().nullable(true);
                    break;
                default:
                    newObject[item.fieldName] = yup.string();
                    break;
            }

            (item.validation || []).map((v: any) => {
                const messageId = clearComplexFieldMessageId(
                    v.messageId,
                    item.fieldName
                );
                // const messageId = v.messageId;
                switch (v.type) {
                    case "dateOfBirth":
                    case "date":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidDate({
                            message: t(v.messageId)
                        });
                        break;
                    case "decimalType":
                        if (item.elementType == "currency") {
                            const fields = newObject[item.fieldName].fields;
                            //fields.value.required(t(messageId));
                            newObject[item.fieldName] = yup.object({
                                currency: fields.currency,
                                value: fields.value.isValidDecimal({
                                    message: t(v.messageId)
                                })
                            });
                        } else if (item.elementType == "numberrange") {
                            const fields = newObject[item.fieldName].fields;
                            //fields.value.required(t(v.messageId));
                            newObject[item.fieldName] = yup.object({
                                type: fields.type,
                                currency: fields.currency,
                                minValue: fields.minValue.isValidDecimal({
                                    message: t(v.messageId)
                                }),
                                maxValue: fields.maxValue.isValidDecimal({
                                    message: t(v.messageId)
                                })
                            });
                        } else {
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ].isValidDecimal({
                                message: t(v.messageId)
                            });
                        }

                        break;
                    case "integerType":
                        if (item.elementType == "currency") {
                            const fields = newObject[item.fieldName].fields;
                            newObject[item.fieldName] = yup.object({
                                currency: fields.currency,
                                value: fields.value.integer(t(messageId))
                            });
                        } else {
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ].integer(t(messageId));
                        }

                        break;
                    case "maxWhenPercentageType":
                        if (item.elementType === "numberrange") {
                            const fields = newObject[item.fieldName].fields;
                            newObject[item.fieldName] = yup.object({
                                type: fields.type,
                                currency: fields.currency,
                                minValue: fields.minValue,
                                maxValue: fields.maxValue.when("type", {
                                    is: "percentage",
                                    then: yup
                                        .number()
                                        .max(
                                            100,
                                            t(v.messageId, { max: v.value })
                                        ),
                                    otherwise: fields.maxValue
                                })
                            });
                        }
                        break;
                    case "positiveNumber":
                        if (item.elementType === "numberrange") {
                            const fields = newObject[item.fieldName].fields;
                            newObject[item.fieldName] = yup.object({
                                type: fields.type,
                                currency: fields.currency,
                                minValue: fields.minValue.positive(
                                    t(v.messageId)
                                ),
                                maxValue: fields.maxValue
                            });
                        } else {
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ].positive(t(v.messageId));
                        }
                        break;
                    case "min":
                        if (item.elementType === "currency") {
                            const fields = newObject[item.fieldName].fields;
                            newObject[item.fieldName] = yup.object({
                                currency: fields.currency,
                                value: fields.value.min(
                                    v.value,
                                    t(messageId, { min: v.value })
                                )
                            });
                        } else {
                            if (item.elementType === "text") {
                                newObject[item.fieldName] = newObject[
                                    item.fieldName
                                ].test(
                                    "min-check",
                                    t(messageId, { min: v.value }),
                                    (itemText: any) =>
                                        itemText.length === 0 ||
                                        itemText.length >= v.value
                                );
                            } else {
                                newObject[item.fieldName] = newObject[
                                    item.fieldName
                                ].min(
                                    v.value,
                                    t(v.messageId, { min: v.value })
                                );
                            }
                        }
                        break;
                    case "max":
                        if (item.elementType == "currency") {
                            const fields = newObject[item.fieldName].fields;
                            //fields.value.required(t(messageId));
                            newObject[item.fieldName] = yup.object({
                                currency: fields.currency,
                                value: fields.value.max(
                                    v.value,
                                    t(messageId, { max: v.value })
                                )
                            });
                        } else {
                            if (item.elementType === "text") {
                                newObject[item.fieldName] = newObject[
                                    item.fieldName
                                ].test(
                                    "max-check",
                                    t(messageId, { max: v.value }),
                                    (itemText: any) =>
                                        itemText.length === 0 ||
                                        itemText.length <= v.value
                                );
                            } else {
                                newObject[item.fieldName] = newObject[
                                    item.fieldName
                                ].max(v.value, t(messageId, { max: v.value }));
                            }
                        }
                        break;
                    case "required":
                        newObject = addRequiredSchema(
                            newObject,
                            item,
                            t,
                            v,
                            yup
                        );

                        break;
                    case "cpf":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidCPF({
                            message: t(v.messageId)
                        });
                        break;
                    case "cnpj":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidCNPJ({
                            message: t(v.messageId)
                        });
                        break;
                    case "cnpj_cpf":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidCNPJCPF({
                            message: t(v.messageId)
                        });
                        break;
                    case "anyPhoneNumber":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidPhoneNumber({
                            message: t(v.messageId)
                        });
                        break;
                    case "mobileNumber":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidMobilePhoneNumber({
                            message: t(v.messageId)
                        });
                        break;
                    case "notMobileNumber":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidNotMobilePhoneNumber({
                            message: t(v.messageId)
                        });
                        break;
                    case "maxAge":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].min(
                            addYears(toDate(Date.now()), -v.value),
                            t(messageId, {
                                max: v.value
                            })
                        );
                        break;
                    case "minAge":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].max(
                            addYears(toDate(Date.now()), -v.value),
                            t(messageId, {
                                min: v.value
                            })
                        );
                        break;
                    case "minDateAddDaysFromCurrentDate":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].min(
                            addDays(toDate(Date.now()), v.value),
                            t(messageId, {
                                min: format(
                                    addDays(toDate(Date.now()), v.value),
                                    "dd/MM/yyyy"
                                )
                            })
                        );
                        break;
                    case "maxDateAddDaysFromCurrentDate":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].max(
                            addDays(toDate(Date.now()), v.value),
                            t(messageId, {
                                max: format(
                                    addDays(toDate(Date.now()), v.value),
                                    "dd/MM/yyyy"
                                )
                            })
                        );
                        break;
                    case "onlyPastDates":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].max(
                            toDate(Date.now()),
                            t(messageId, {
                                max: format(toDate(Date.now()), "dd/MM/yyyy")
                            })
                        );
                        break;
                    case "onlyFutureDates":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].min(
                            toDate(Date.now()),
                            t(messageId, {
                                min: format(toDate(Date.now()), "dd/MM/yyyy")
                            })
                        );
                        break;
                    case "email":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].email(t(messageId));
                        break;
                    case "arrayOneOf":
                        newObject = addRequiredSchema(
                            newObject,
                            item,
                            t,
                            v,
                            yup
                        );
                        break;
                    case "uniqueCheckboxTrue":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].oneOf([true], t(messageId));
                        break;
                    case "equalTo":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].equalTo({
                            fieldToCompare: v.fieldToCompare,
                            message: t(messageId)
                        });
                        break;
                    case "regex":
                        {
                            const re = new RegExp(v.regex);
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ].matches(re, {
                                message: t(messageId),
                                excludeEmptyString: true
                            });
                        }
                        break;
                    case "regexArray": {
                        const re = new RegExp(v.regex);
                        // newObject[item.fieldName] = yup.array().of(
                        //     yup.string().matches(re, {
                        //         message: t(messageId),
                        //         excludeEmptyString: true
                        //     })
                        // );
                        newObject[item.fieldName] = yup
                            .array()
                            .transform(function (
                                value: any,
                                originalValue: any
                            ) {
                                if (value !== null) {
                                    return value;
                                }
                                return originalValue
                                    ? originalValue.split(/[\s,]+/)
                                    : [];
                            })
                            .of(
                                yup.string().matches(re, {
                                    message: t(messageId)
                                })
                            );
                        break;
                    }
                    case "moreThan":
                    case "moreThanOrEqual":
                        const fName =
                            v.type === "moreThan"
                                ? "isMoreThanOrEmpty"
                                : "isMoreThanOrEqualOrEmpty";

                        const fNameYup =
                            v.type === "moreThan" ? "moreThan" : "min";

                        // console.log("item.elementType", item.elementType);
                        // console.log("v.fieldToCheck", v.fieldToCheck);
                        if (item.elementType === "numberrange") {
                            const fields = newObject[item.fieldName].fields;
                            newObject[item.fieldName] = yup.object({
                                type: fields.type,
                                currency: fields.currency,
                                minValue: fields.minValue,
                                maxValue: fields.maxValue[fNameYup](
                                    yup.ref("minValue"),
                                    t(messageId)
                                )
                            });
                        } else if (item.elementType == "currency") {
                            const fields = newObject[item.fieldName].fields;

                            const fNameCurrency =
                                v.type === "moreThan"
                                    ? "isMoreThanOrEmptyCurrency"
                                    : "isMoreThanOrEqualOrEmptyCurrency";

                            newObject[item.fieldName] = yup.object({
                                currency: fields.currency,
                                value: fields.value[fNameCurrency]({
                                    message: t(messageId, {
                                        fieldToCheck: t(
                                            clearArrayInfo(v.fieldToCheck)
                                        )
                                    }),
                                    fieldToCompare: v.fieldToCheck
                                })
                            });
                        } else if (v.fieldToCheck) {
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ][fName]({
                                message: t(messageId, {
                                    fieldToCheck: t(
                                        clearArrayInfo(v.fieldToCheck)
                                    )
                                }),
                                fieldToCompare: v.fieldToCheck
                            });
                        } else {
                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ][fNameYup](v.value, t(messageId));
                        }
                        break;
                    case "url":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidHttpOrHttpAddress({
                            message: t(v.messageId)
                        });
                        break;
                    case "htmlWithoutImage":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].isValidHTMLWithoutImage({
                            message: t(v.messageId)
                        });
                        break;
                    case "emailExistsAtCompany":
                        newObject[item.fieldName] = newObject[
                            item.fieldName
                        ].emailExistsAtCompany({
                            message: t(messageId),
                            companyId: companyId
                        });
                        break;
                    // case "placeHolderCheck":
                    //     // debugger;
                    //     const fields = newObject[item.fieldName].fields;

                    //     // newObject[item.fieldName] = yup.object({
                    //     //     subject: fields.subject.valueExistsInArray({
                    //     //         message: t(messageId),
                    //     //         fieldToCompare: v.fieldToCheck_subject
                    //     //     }),
                    //     //     body: fields.body.valueExistsInArray({
                    //     //         message: t(messageId),
                    //     //         fieldToCompare: v.fieldToCheck_body
                    //     //     })
                    //     // });
                    //     newObject[item.fieldName] = yup.object({
                    //         _tempEmailData: yup.object({
                    //             subject: fields._tempEmailData.fields.subject.valueExistsInArray(
                    //                 {
                    //                     message: t(messageId),
                    //                     fieldToCompare: v.fieldToCheck_subject,
                    //                     levelObject: v.levelObject
                    //                 }
                    //             ),
                    //             body: fields._tempEmailData.fields.body.valueExistsInArray(
                    //                 {
                    //                     message: t(messageId),
                    //                     fieldToCompare: v.fieldToCheck_body,
                    //                     levelObject: v.levelObject
                    //                 }
                    //             )
                    //         })
                    //     });
                    //     break;
                    case "genericServer":
                        if ((v.onlyServer || true) == false) {
                            const genericParameters: Compleo.IObject = {};
                            genericParameters.validationType =
                                v.genericValidationType;
                            if (v.sendCompanyId) {
                                genericParameters.companyId = companyId;
                            }
                            genericParameters.message = t(messageId);

                            newObject[item.fieldName] = newObject[
                                item.fieldName
                            ].genericServerValidation(genericParameters);
                        }
                        break;
                    case "arrayEmails":
                        newObject[item.fieldName] = yup
                            .array()
                            .transform(function (
                                value: any,
                                originalValue: any
                            ) {
                                if (value !== null) {
                                    return value;
                                }
                                return originalValue
                                    ? originalValue.split(/[\s,]+/)
                                    : [];
                            })
                            .of(
                                yup
                                    .string()
                                    .email(({ value }: any) => t(messageId))
                            );

                        break;
                    default:
                        break;
                }
            });
        });
    }
    // console.log("schema", JSON.stringify(newObject, null, 4));
    return newObject;
};

const addRequiredSchema = (
    newObject: any,
    item: any,
    t: any,
    v: any,
    yup: any
) => {
    let fields: any = null;
    const messageId = clearComplexFieldMessageId(v.messageId, item.fieldName);

    if (
        item.requiredCondition === undefined ||
        Object.keys(item.requiredCondition || {}).length === 0
    ) {
        switch (item.elementType) {
            case "numberrange":
                fields = newObject[item.fieldName].fields;
                newObject[item.fieldName] = yup.object({
                    currency: fields.currency,
                    minValue: fields.minValue.required(t(messageId)),
                    maxValue: fields.maxValue.required(t(messageId)),
                    type: yup
                        .object({
                            value: yup
                                .string()
                                .nullable(true)
                                .required(t(messageId))
                        })
                        .nullable(false)
                        .required(t(messageId))
                });
                break;
            case "currency":
                fields = newObject[item.fieldName].fields;
                newObject[item.fieldName] = yup.object({
                    currency: fields.currency,
                    value: fields.value.required(t(messageId))
                });
                break;
            case "address":
                if (item.requiredType !== undefined) {
                    const fieldsToRequire = item.requiredType.split("|");
                    const objAddress: Compleo.IObject = {};
                    for (let fr of fieldsToRequire) {
                        switch (fr) {
                            case "country":
                            case "provinceOrState":
                            case "city":
                                objAddress[fr] = yup.object().nullable(true);
                                break;
                            default:
                                objAddress[fr] = yup.string();
                                break;
                        }
                        objAddress[fr] = objAddress[fr].required(t(messageId));
                        // objAddress[fr].required(t(messageId));
                    }
                    newObject[item.fieldName] = yup.object(objAddress);
                }
                break;
            case "delayAction":
                newObject[item.fieldName] = yup
                    .object({
                        delayDetail: yup
                            .object()
                            .nullable(true)
                            .required(t(messageId)),
                        time: yup.string().nullable(true),
                        timeZone: yup.string().nullable(true)
                    })
                    .nullable(true);
                newObject[item.fieldName] = newObject[item.fieldName].required(
                    t(messageId)
                );
                break;
            case "rating":
                // fields = newObject[item.fieldName].fields;
                newObject[item.fieldName] = newObject[item.fieldName].min(
                    0.1,
                    t(messageId)
                );
                break;
            case "integer":
                newObject[item.fieldName] = newObject[item.fieldName].required(
                    t(messageId)
                );
                break;
            default:
                // console.log("field name required", item.fieldName);
                newObject[item.fieldName] = newObject[item.fieldName].required(
                    t(messageId)
                );
                break;
        }
    } else {
        if (["and", "or"].includes(item.requiredCondition.type)) {
            const fieldNamesTocheck = item.requiredCondition.conditions.map(
                (c: any) => c.fieldToCheck
            );
            newObject[item.fieldName] = newObject[item.fieldName].when(
                fieldNamesTocheck,
                {
                    is: function () {
                        let allTrue = true;
                        let justOneTrue = false;

                        for (var i in arguments) {
                            const itemToCheck = item.requiredCondition.conditions.filter(
                                (f: any) =>
                                    f.fieldToCheck === fieldNamesTocheck[i]
                            )[0];
                            const typeCheck = itemToCheck.typeCheck;
                            const valueToCheck = itemToCheck.valueToCheck;

                            if (typeCheck === "equal") {
                                if (valueToCheck !== arguments[i] && allTrue) {
                                    allTrue = false;
                                }
                                if (valueToCheck === arguments[i]) {
                                    justOneTrue = true;
                                }
                            }

                            if (typeCheck === "notEqual") {
                                if (valueToCheck === arguments[i] && allTrue) {
                                    allTrue = false;
                                }
                                if (valueToCheck !== arguments[i]) {
                                    justOneTrue = true;
                                }
                            }

                            if (typeCheck === "isEmpty") {
                                let dataToVerify = arguments[i];
                                if (
                                    Array.isArray(arguments[i]) &&
                                    !arguments[i].length
                                ) {
                                    dataToVerify = null;
                                }
                                if (
                                    dataToVerify !== undefined &&
                                    dataToVerify !== "" &&
                                    dataToVerify !== null &&
                                    allTrue
                                ) {
                                    allTrue = false;
                                }
                                if (
                                    dataToVerify === undefined ||
                                    dataToVerify === "" ||
                                    dataToVerify === null
                                ) {
                                    justOneTrue = true;
                                }
                            }

                            if (typeCheck === "hasValue") {
                                if (
                                    (arguments[i] === undefined ||
                                        arguments[i] === "" ||
                                        arguments[i] === null ||
                                        (Array.isArray(arguments[i]) &&
                                            arguments[i].length === 0)) &&
                                    allTrue
                                ) {
                                    allTrue = false;
                                }
                                if (
                                    arguments[i] !== undefined &&
                                    arguments[i] !== "" &&
                                    arguments[i] !== null &&
                                    Array.isArray(arguments[i]) &&
                                    arguments[i].length > 0
                                ) {
                                    justOneTrue = true;
                                }
                            }
                        }

                        if (item.requiredCondition.type === "and") {
                            return allTrue;
                        } else {
                            return justOneTrue;
                        }
                    },
                    then: newObject[item.fieldName] = newObject[
                        item.fieldName
                    ].required(t(messageId)),
                    otherwise: newObject[item.fieldName] = newObject[
                        item.fieldName
                    ].notRequired()
                }
            );
        }
    }
    return newObject;
};

export const getInitialValues = (objeto: any, initialValues: any = null) => {
    let newObject: { [key: string]: any };
    newObject = {};

    if (objeto !== undefined) {
        objeto
            .filter((o: any) => !o.complexFieldDetail)
            .map((item: any) => {
                switch (item.elementType) {
                    case "dateOfBirth":
                    case "date":
                    case "datetime":
                        newObject[item.fieldName] = null;
                        break;
                    default:
                        newObject[item.fieldName] = "";
                        break;
                }
            });
    }

    return newObject;
};

export const orderList = (objectCol: any) => {
    const newCollection = objectCol;
    newCollection.sort((a: any, b: any) =>
        (a.formGroupId || "").localeCompare(b.formGroupId || "")
    );
    newCollection.sort((a: any, b: any) => {
        if (Number(a.order) > Number(b.order)) {
            return 1;
        }
        if (Number(a.order) < Number(b.order)) {
            return -1;
        }
        if (a.order === b.order) {
            if (a.lineOrder !== undefined && b.lineOrder !== undefined) {
                return Number(a.lineOrder) - Number(b.lineOrder);
            }
        }
        return 0;
    });
    return newCollection;
};

const clearComplexFieldMessageId = (value: string, fieldName: string) => {
    if (value.includes("COMPLEXFIELD:")) {
        return value
            .replace("COMPLEXFIELD:", "")
            .replace(`_${fieldName}`, `.${fieldName}`);
    } else {
        return value;
    }
};

export const getPageWithErrosMultiStepFrom = (
    errors: any,
    metaData: Compleo.IObject,
    currentPageNumber: number
) => {
    const idsWithErros: string[] = errors.map(
        (i: Compleo.IObject) => i.id.split("/")[0]
    );
    if (
        (metaData.MultiStepForm || []).length === 0 ||
        metaData.IsMultiStepForm !== true
    ) {
        return -1;
    }

    const metadataErrosInfo = (
        metaData.camposMetadados || []
    ).filter((item: Compleo.IObject) => idsWithErros.includes(item.fieldName));

    const currentPage = (metaData.MultiStepForm || []).filter(
        (item: Compleo.IObject, index: number) =>
            index + 1 === currentPageNumber
    )[0];
    const allFormGroupsWithErros: string[] = metadataErrosInfo.map(
        (item: Compleo.IObject) => item.formGroupId
    );
    const allFormGroupsCurrentPage = (metaData.formGroups || []).filter(
        (item: Compleo.IObject) => item.stepId === currentPage.id
    );

    const currentPageFormGroupsWithErros: Compleo.IObject[] = allFormGroupsCurrentPage.filter(
        (item: Compleo.IObject) => allFormGroupsWithErros.includes(item.id)
    );
    let indexToNavigate = -1;
    if (currentPageFormGroupsWithErros.length === 0) {
        // const firstErrorStep = metadataErrosInfo[0]?.
        const formGroupsDetailWithErrors = (
            metaData.formGroups || []
        ).filter((item: Compleo.IObject) =>
            allFormGroupsWithErros.includes(item.id)
        );

        const stepIdWithErrors: string[] = formGroupsDetailWithErrors.map(
            (item: Compleo.IObject) => item.stepId
        );

        const stepToChange = (metaData.MultiStepForm || []).filter(
            (item: Compleo.IObject, index: number) =>
                stepIdWithErrors[0] === item.id
        )[0];

        indexToNavigate =
            (metaData.MultiStepForm || []).findIndex(
                (x: Compleo.IObject) => x.id === stepToChange.id
            ) + 1;
    }

    return indexToNavigate;
};
