import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate, RouteComponentProps } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Icon } from "@material-ui/core";
import {
    useCompleoSimpleList,
    actionsTableFunction,
    actionsTableListType
} from "customHooks/useCompleoSimpleList";
import ApplicantViewModalContainer from "../ApplicantView/ApplicantViewModalContainer";
import { boolean } from "yup";
import ViewListShareHeader from "./ViewListShareHeader";

interface IProps extends RouteComponentProps {
    id: string;
}

const ViewListShare = (props: IProps) => {
    const { id } = props;
    const moduleName = "APPLICANTLISTSHARE";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [applicantModalData, setApplicantModalData] = React.useState<{
        open: boolean;
        applicantId: string;
    }>({
        open: false,
        applicantId: ""
    });
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/applicant/applicantlistshare";

    const actionsTable: actionsTableListType = [
        {
            icon: "visibility",
            tooltip: t("view"),
            onClick: (event: any, rowData: any) => {
                const applicantId = rowData.pk.split(":")[1];
                setApplicantModalData({
                    open: true,
                    applicantId: applicantId
                });
            }
        }
    ];

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            id: id
        },
        actionsTable
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                <ViewListShareHeader id={id}>{compRetorno}</ViewListShareHeader>
                <ApplicantViewModalContainer
                    onClose={() =>
                        setApplicantModalData({ open: false, applicantId: "" })
                    }
                    open={applicantModalData.open}
                    ApplicantId={applicantModalData.applicantId}
                    module="APPLICANTVIEW"
                    loadingData={false}
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ViewListShare;
