import React from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { IJobReportChildren } from "./reportHelper";
import { Alert } from "@material-ui/lab";

const JobActivitiesGraph = (props: IJobReportChildren) => {
    const { mainData, t } = props;

    // const data = handleFunnelData(mainData);
    const data = mainData?.activities || [];
    data.sort((a, b) => b.total - a.total);

    const colorData = useRandomColor({
        objectKeys: data.map((i) => i.type),
        luminosity: "dark"
    });
    const finalData = data.map((i) => {
        const color = colorData.colorsObject[i.type];
        return {
            ...i,
            name: t(`ApplicantHistoryType_${i.type}`),
            value: i.total,
            fill: color
        };
    });

    if (data.length) {
        return (
            <ResponsiveContainer
                minHeight={300}
                minWidth={300}
                width="99%"
                maxHeight={500}
                // height="99%"
            >
                <BarChart data={finalData} maxBarSize={45}>
                    <XAxis
                        dataKey="name"
                        scale="point"
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis domain={[0, 5]} />
                    <Tooltip />
                    <Bar
                        dataKey="value"
                        fill={colorData.colorsObject["main"]}
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <Alert severity="warning" style={{ margin: 16 }}>
                {t("noRejectedApplicants")}
            </Alert>
        );
    }
};

export default JobActivitiesGraph;
