import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from "@material-ui/core";
import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult
} from "react-beautiful-dnd";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useDynamicDashboard from "../..";
import { localNames } from "../ListUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        title: {
            fontWeight: 600,
            marginTop: theme.spacing(2)
        }
    })
);

interface IProps {
    filterData: Compleo.IObject[];
    setFilterData: React.Dispatch<React.SetStateAction<Compleo.IObject[]>>;
    maxItemsToShowPrimary: number;
    maxItemsToShowSecondary: number;
    type: "card" | "table";
}

const SettingsModalList = (props: IProps) => {
    const classes = useStyles();
    const [listData, listDispatch] = useDynamicDashboard();
    const { t } = listData.tempInfo;
    const {
        filterData,
        setFilterData,
        maxItemsToShowPrimary,
        maxItemsToShowSecondary,
        type
    } = props;

    const primaryList = filterData.filter((item) => item.local === "primary");
    const secondaryList = filterData.filter(
        (item) => item.local === "secondary"
    );
    const hiddenList = filterData.filter((item) => item.local === "hidden");

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (destination.droppableId === source.droppableId) {
                const listId = destination.droppableId;
                if (destination.index === source.index) {
                    return;
                }
                const items = [...filterData]
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => item.local === listId);

                const otherItems = [...filterData].filter(
                    (item) => item.local !== listId
                );
                const itemToMove = items.filter(
                    (item) => item.name === draggableId
                )[0];
                items.splice(source.index, 1);
                items.splice(destination.index, 0, itemToMove);
                const orderedItems = items.map((item, index) => {
                    return { ...item, order: index + 1 };
                });
                setFilterData([...orderedItems, ...otherItems]);
            } else {
                const itemsDestination =
                    [...filterData]
                        .sort((a, b) => a.order - b.order)
                        .filter(
                            (item) => item.local === destination.droppableId
                        ) || [];
                const itemsSource = [...filterData]
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => item.local === source.droppableId);

                // Cancel the drop if exceed the mas items per list
                switch (destination.droppableId) {
                    case "primary":
                        if (primaryList.length >= maxItemsToShowPrimary) {
                            return;
                        }
                        break;
                    case "secondary":
                        if (secondaryList.length >= maxItemsToShowSecondary) {
                            return;
                        }
                        break;
                }

                const listNames: localNames[] = [
                    "hidden",
                    "primary",
                    "secondary"
                ];
                const otherListName = listNames.filter(
                    (item) =>
                        item !== destination.droppableId &&
                        item !== source.droppableId
                )[0];
                const otherItems = [...filterData]
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => item.local === otherListName);

                const itemToMove = itemsSource.filter(
                    (item) => item.name === draggableId
                )[0];
                itemToMove.local = destination.droppableId;

                // Remove item from source list
                itemsSource.splice(source.index, 1);

                // Add item from source list
                itemsDestination.splice(destination.index, 0, itemToMove);

                const orderedSourceItems = itemsSource.map((item, index) => {
                    return { ...item, order: index + 1 };
                });
                const orderedDestinationItems = itemsDestination.map(
                    (item, index) => {
                        return { ...item, order: index + 1 };
                    }
                );
                setFilterData([
                    ...otherItems,
                    ...orderedSourceItems,
                    ...orderedDestinationItems
                ]);
            }
        }
    };
    const primaryListTitle =
        type === "card"
            ? t("ELASTICLISTSETTINGS:CardSettings_MainList", {
                  max: maxItemsToShowPrimary
              })
            : t("ELASTICLISTSETTINGS:TableSettings_MainList", {
                  max: maxItemsToShowPrimary
              });

    const secondaryListTitle =
        type === "card"
            ? t("ELASTICLISTSETTINGS:CardSettings_SecondaryList", {
                  max: maxItemsToShowSecondary
              })
            : t("ELASTICLISTSETTINGS:TableSettings_SecondaryList", {
                  max: maxItemsToShowSecondary
              });

    const hiddenListTitle =
        type === "card"
            ? t("ELASTICLISTSETTINGS:CardSettings_HiddenList")
            : t("ELASTICLISTSETTINGS:TableSettings_HiddenList");

    return (
        <>
            <List component="nav" className={classes.root}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Typography variant="body1" className={classes.title}>
                        {primaryListTitle}
                    </Typography>
                    <Droppable
                        droppableId="primary"
                        direction="vertical"
                        type="forms"
                        // isDropDisabled={
                        //     primaryList.length >= maxItemsToShowPrimary
                        // }
                    >
                        {(
                            provided: DroppableProvided,
                            snapshot: DroppableStateSnapshot
                        ) => (
                            <div
                                style={{
                                    minHeight: "30px",
                                    backgroundColor:
                                        snapshot.isDraggingOver &&
                                        primaryList.length >=
                                            maxItemsToShowPrimary
                                            ? "red"
                                            : undefined
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {primaryList
                                    .sort((a, b) => a.order - b.order)
                                    .map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            <Item
                                                item={item}
                                                index={index}
                                                maxItemsToShow={
                                                    maxItemsToShowPrimary
                                                }
                                                t={t}
                                                disabled={
                                                    primaryList.length === 1
                                                }
                                                showEyeIcon={true}
                                            />
                                        </React.Fragment>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {type === "table" && (
                        <>
                            <Typography
                                variant="body1"
                                className={classes.title}
                            >
                                {secondaryListTitle}
                            </Typography>

                            <Droppable
                                droppableId="secondary"
                                direction="vertical"
                                type="forms"
                                // isDropDisabled={
                                //     secondaryList.length >=
                                //     maxItemsToShowSecondary
                                // }
                            >
                                {(
                                    provided: DroppableProvided,
                                    snapshot: DroppableStateSnapshot
                                ) => (
                                    <div
                                        style={{
                                            minHeight: "30px",
                                            backgroundColor:
                                                snapshot.isDraggingOver &&
                                                secondaryList.length >=
                                                    maxItemsToShowSecondary
                                                    ? "red"
                                                    : undefined
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {secondaryList
                                            .sort((a, b) => a.order - b.order)
                                            .map((item, index) => (
                                                <React.Fragment key={item.name}>
                                                    <Item
                                                        item={item}
                                                        index={index}
                                                        maxItemsToShow={
                                                            maxItemsToShowSecondary
                                                        }
                                                        t={t}
                                                        disabled={false}
                                                        showEyeIcon={true}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </>
                    )}

                    <Typography variant="body1" className={classes.title}>
                        {hiddenListTitle}
                    </Typography>
                    <Droppable
                        droppableId="hidden"
                        direction="vertical"
                        type="forms"
                    >
                        {(provided: DroppableProvided) => (
                            <div
                                style={{
                                    minHeight: "30px"
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {hiddenList
                                    .sort((a, b) => a.order - b.order)
                                    .map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            <Item
                                                item={item}
                                                index={index}
                                                maxItemsToShow={
                                                    maxItemsToShowPrimary
                                                }
                                                t={t}
                                                disabled={false}
                                            />
                                        </React.Fragment>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </>
    );
};
interface IItemProps {
    item: Compleo.IObject;
    index: number;
    maxItemsToShow: number;
    t: any;
    disabled: boolean;
    showEyeIcon?: boolean;
}

const Item = (props: IItemProps) => {
    const {
        item,
        index,
        maxItemsToShow,
        t,
        disabled,
        showEyeIcon = false
    } = props;

    return (
        <Draggable
            draggableId={item.name}
            index={index}
            isDragDisabled={disabled}
        >
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItem
                        button
                        dense
                        alignItems="center"
                        style={{
                            margin: 4
                        }}
                    >
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon color="action">drag_indicator</Icon>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={t(item.name)} />
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon
                                    color={showEyeIcon ? "primary" : "action"}
                                >
                                    {showEyeIcon
                                        ? "visibility"
                                        : "visibility_off"}
                                </Icon>
                            </Tooltip>
                        </ListItemIcon>
                    </ListItem>
                    <Divider />
                </div>
            )}
        </Draggable>
    );
};

export default SettingsModalList;
