import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getWidgetData } from "../Helpers/_DashboardHelper";
import CompleoPivotTable from "../CompleoPivotTable/CompleoPivotTable";
import useDashboardItemHelper from "./useDashboardItemHelper";
import DashboardMainItem from "./DashboardMainItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        count: {
            marginTop: 8
        }
    })
);

interface IDashboardPivotTable {
    widgetName: string;
    widget: CompleoShared.Dashboard.widgetsMetadata;
}

const DashboardPivotTable = (props: IDashboardPivotTable) => {
    const { widgetName, widget } = props;
    const classes = useStyles();
    const { t, i18n, ready, getData } = useDashboardItemHelper({
        widgetName: widgetName
    });
    const data = getData?.response?.data?.data;
    const graphData = getWidgetData({
        data: data,
        widgetDef: widget,
        widgetName: widgetName,
        language: i18n.languages[0],
        mapProps: widget.mapProps
    });

    const dataA = [
        {
            key: {
                jobId: "KQ00017G",
                JobTitle: "Analista Elasticsearch",
                pipelineStage: "Cadastro Interno",
                stageType: "newSourced",
                jobDetail: {
                    companyId: "10102",
                    createdByUser: "wagner@wagnercosta.com.br",
                    hiringEndDate: "2020-12-31T15:07:00.000Z",
                    justForPCD: true,
                    status: "open",
                    visibility: {
                        "label-pt-BR": "Recrutamento Interno",
                        label: "InternalOnly",
                        "label-en-US": "InternalOnly",
                        value: "CP2"
                    },
                    numberOfPositions: 33,
                    lastUpdatedAt: "2021-05-01T15:16:54.979Z",
                    warningDaysBeforeEndDate: 5,
                    confidential: true,
                    dangerDaysBeforeEndDate: 3,
                    highlightOnCarreerSite: true,
                    tags: ["elasticsearch", "analista", "remote"],
                    experienceLevel: {
                        "label-pt-BR": "Especialista",
                        label: "Especialista",
                        "label-en-US": "Especialista",
                        value: "CP4"
                    },
                    location: {
                        country: {
                            value: "30",
                            label: "Brazil (BR)"
                        },
                        number: "15",
                        provinceOrState: {
                            value: "SP",
                            label: "São Paulo (SP)"
                        },
                        ibgeCodeCity: "3550308",
                        city: {
                            value: "9668",
                            uf: "SP",
                            label: "São Paulo"
                        },
                        ibgeCodeState: "35",
                        postalCode: "01414-000",
                        latitude: "-23.5585648422823",
                        addressline1: "Rua Haddock Lobo",
                        neighborhood: "Cerqueira César",
                        longitude: "-46.6624047981476",
                        geolocation: {
                            lat: -23.5585648422823,
                            lon: -46.6624047981476
                        }
                    },
                    translatedData: {
                        "en-US": {
                            title: "Analista Elasticsearch",
                            description:
                                "<p>Olá!&nbsp;</p>\n<p>Venha trabalhar conosco. Precisamos de um especialista elasticsearch com conhecimentos na stack AWS.</p>\n<p>Será um prazer:</p>\n<p>Requisitos<br>- Elasticserach 7.1</p>\n<p>- Javascript / Typescript</p>"
                        }
                    },
                    createdAt: "2020-12-28T15:10:50.991Z",
                    openingDate: "2020-12-28T03:00:00.000Z",
                    enableScreeningQuestionnaire: true,
                    recruiters: [
                        {
                            value: "wag.costa+cache3@gmail.com",
                            label: "Wagner Cache3"
                        },
                        {
                            value: "wag.costa+888911@gmail.com",
                            label: "Wagner Cel2"
                        }
                    ],
                    skGS1pk: "JOBCOMPANY:10102",
                    workingModel: {
                        "label-pt-BR": "Remoto",
                        label: "Remote",
                        "label-en-US": "Remote",
                        value: "CP1"
                    },
                    branch: {
                        value: "37cd19f1-2687-44ba-afa9-36178ddecde1",
                        label: "NewBranchElastick"
                    },
                    contactCanFollow: false,
                    lastUpdatedByUser: "wagner@wagnercosta.com.br",
                    category: {
                        "label-pt-BR": "Informática, TI, Telecomunicações",
                        label: "Informática, TI, Telecomunicações",
                        "label-en-US": "Informática, TI, Telecomunicações",
                        value: "CP23"
                    },
                    employmentType: {
                        "label-pt-BR": "CLT",
                        label: "CLT",
                        "label-en-US": "CLT",
                        value: "CP1"
                    },
                    customer: {
                        value: "202816b3-7828-4fb2-b4da-fb219b8e817b",
                        label: "Wagner Customer New"
                    },
                    description:
                        "<p>Olá!&nbsp;</p>\n<p>Venha trabalhar conosco. Precisamos de um especialista elasticsearch com conhecimentos na stack AWS.</p>\n<p>Será um prazer:</p>\n<p>Requisitos<br>- Elasticserach 7.1</p>\n<p>- Javascript / Typescript</p>",
                    pk: "JOB:KQ00017G",
                    requester: {
                        value: "wagner@wagnercosta.com.br",
                        label: "Wagner Costa Santos"
                    },
                    GS1sk: "CompanyCode:#JOBTITLE:Analista Elasticsearch",
                    title: "Analista Elasticsearch"
                }
            },
            doc_count: 1
        },
        {
            key: {
                jobId: "LT00025I",
                JobTitle: "Vaga de analista após merge",
                pipelineStage: "Avaliação Interna",
                stageType: "evaluation",
                jobDetail: {
                    companyId: "10102",
                    createdByUser: "wagner@wagnercosta.com.br",
                    justForPCD: false,
                    status: "open",
                    visibility: {
                        "label-pt-BR":
                            "Pública (Divulgação no trabalhe conosco e parceiros)",
                        label:
                            "Pública (Divulgação no trabalhe conosco e parceiros)",
                        "label-en-US": "Public",
                        value: "CP1"
                    },
                    lastUpdatedAt: "2021-02-01T11:32:40.646Z",
                    confidential: false,
                    highlightOnCarreerSite: false,
                    tags: [],
                    location: {
                        country: {
                            value: "30",
                            label: "Brazil (BR)"
                        }
                    },
                    translatedData: {
                        "en-US": {}
                    },
                    createdAt: "2021-01-07T15:17:59.403Z",
                    openingDate: "2021-01-07T03:00:00.000Z",
                    enableScreeningQuestionnaire: false,
                    recruiters: [],
                    skGS1pk: "JOBCOMPANY:10102",
                    workingModel: {
                        "label-pt-BR": "Hibrido",
                        label: "Hibrido",
                        "label-en-US": "Hybrid",
                        value: "CP2"
                    },
                    branch: {
                        value: "37cd19f1-2687-44ba-afa9-36178ddecde1",
                        label: "NewBranchElastick"
                    },
                    contactCanFollow: false,
                    lastUpdatedByUser: "wagner@wagnercosta.com.br",
                    customer: {
                        value: "202816b3-7828-4fb2-b4da-fb219b8e817b",
                        label: "Wagner Customer New"
                    },
                    description: "<p>fadsfdasdasf</p>",
                    pk: "JOB:LT00025I",
                    requester: {
                        value: "wagner@wagnercosta.com.br",
                        label: "Wagner Costa Santos"
                    },
                    GS1sk: "CompanyCode:#JOBTITLE:Vaga de analista após merge",
                    title: "Vaga de analista após merge"
                }
            },
            doc_count: 2
        }
    ];

    return (
        <DashboardMainItem ready={ready} t={t} widgetName={widgetName}>
            <CompleoPivotTable data={graphData} widget={widget} t={t} />
        </DashboardMainItem>
    );
};

export default DashboardPivotTable;
