import React from "react";
import ItemCard from "./ItemCard";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getColorByHistoryType } from "Pages/Applicant/ApplicantView/ApplicantHistory/ApplicantHistoryHelper";
import { useTranslation } from "react-i18next";
import { Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        },
        chipTable: {
            borderRadius: 6
        }
    })
);

export type StatusColorType = {
    [K in CompleoShared.Common.JobStatus]: string;
};

const FieldApplicantHistoryType = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType } = props;
    const classes = useStyles();
    const [listData, listDispatch] = useList();
    const { t, language } = listData.tempInfo;
    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );

    const openModalActivity = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalActivity: {
                open: true,
                currentIndex: index,
                currentPage:
                    listData.filtersInfo?.filters?.pagination?.currentPage,
                idFieldName: "pk"
            }
        });
    };

    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};

    const type: CompleoShared.Common.ApplicantHistoryType = fieldData.type;
    const color = getColorByHistoryType(type);

    if (returnType === "card") {
        return (
            <ItemCard
                label={
                    cardFields[fieldName].cardIcon ? (
                        <Icon color="action" fontSize="small">
                            {cardFields[fieldName].cardIcon}
                        </Icon>
                    ) : (
                        `${t(fieldName)}: `
                    )
                }
                removeColonAfterLabel={true}
            >
                <Chip
                    className={classes.chipCard}
                    style={{
                        backgroundColor: color,
                        color: "white"
                    }}
                    size="small"
                    label={t(`ApplicantHistoryType_${fieldData.type}`, "")}
                    onClick={openModalActivity}
                />
            </ItemCard>
        );
    } else {
        return (
            <Chip
                className={classes.chipTable}
                style={{
                    backgroundColor: color,
                    color: "white"
                }}
                size="small"
                label={t(`ApplicantHistoryType_${fieldData.type}`, "")}
                onClick={openModalActivity}
            />
        );
    }
};

export default FieldApplicantHistoryType;
