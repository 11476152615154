import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
// import useCompleoReactHookForm, { useCompleoReactHookFormNew } from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useEmailTemplate from "../../customHooks/useCompleoReactHookForm/Inputs/EmailDataV2/useEmailTemplate";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";

const ScheduleEmailEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/scheduletemplate/list");

    // const [addProps, setAddProps] = React.useState<
    //     Compleo.useCompleoReactHookForm.AdditionalPropertiesField[]
    // >([]);

    const { company } = useAuthState();
    const moduleName = "SCHEDULETEMPLATEEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const postAddressSearch = `/scheduletemplate/search`;

    const [getScheduleTemplateResult] = useApiCache(
        postAddressSearch,
        "post",
        { uuid: props.uuid, companyId: company.companyId },
        false
    );

    const postAddress = `/scheduletemplate/edit`;

    const [editScheduleTemplateReturn, editScheduleTemplate] = useApi(
        postAddress,
        "post"
    );

    const [metadata] = useGetMetadata(moduleName);

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getScheduleTemplateResult,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;
    valuesFromSource.deleted = false;

    //return <div>Teste 1</div>;
    // let type = "";
    // if (getEmailTemplateResult.status === "success") {
    //     type = getEmailTemplateResult.response.data.data.Items[0].type;
    // }
    const type = (getScheduleTemplateResult.response?.data?.data?.Items ||
        [])[0]?.type;
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "template",
            props: {
                type: type
            }
        }
    ];

    // React.useEffect(() => {
    //     if (type) {
    //         const newAddProps = [...addProps];
    //         newAddProps.push({
    //             fieldName: "template",
    //             props: {
    //                 type: type
    //             }
    //         });
    //         setAddProps(newAddProps);
    //     }
    // }, [type]);

    // React.useEffect(() => {
    //     if (addProps.length > 0) {
    //         setAddProps([]);
    //     }
    // }, [i18n.languages[0]]);

    const ready =
        readyTranslation &&
        getScheduleTemplateResult.status === "success" &&
        type &&
        metadata.status === "success";

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: ready,
        i18nV: i18n,
        postMethod: editScheduleTemplate,
        postReturn: editScheduleTemplateReturn,
        redirectAddress: "/scheduletemplate/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        additionalFieldProperties: addProps
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (ready && finished) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ScheduleEmailEdit;
