import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IMainState } from "../../useJobContext/JobViewTypes";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import { green, red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import useGetMetadata from "customHooks/useGetMetadata";
import { useVerifyLinkedInJobBoardFieldsToHide } from "Pages/Job/components/JobBoards/JobBoardsModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2)
        }
    })
);

function createMarkup(value: string) {
    return { __html: value };
}

interface IJobBoardsStatusMessagesProps {
    jobData: Compleo.IObject;
}
const JobBoardsStatusEnabled = (props: IJobBoardsStatusMessagesProps) => {
    const classes = useStyles();
    const messageList: { message: string; type: "success" | "error" }[] = [];
    const { jobData } = props;
    const [t, i18n, readyTranslation] = useTranslation("JOBBOARDSMODULE", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("JOBBOARDSMODULE");
    const dataFields: Compleo.IObject[] = (
        metadata.response?.data?.metadado || []
    ).filter((item: Compleo.IObject) => item.fieldName.includes("Enabled"));
    const jobBoardField = jobData?.jobBoards || {};
    const fieldsToHideLinkedIn = useVerifyLinkedInJobBoardFieldsToHide();

    return (
        <div className={classes.root}>
            {dataFields
                .filter((i) => !fieldsToHideLinkedIn.includes(i.fieldName))
                .map((data, index) => {
                    const enabled = jobBoardField[data.fieldName] === true;

                    return (
                        <List
                            key={data.fieldName}
                            // component="nav"
                            aria-label="main mailbox folders"
                            dense
                        >
                            <ListItem>
                                <ListItemIcon>
                                    {enabled ? (
                                        <DoneIcon
                                            style={{ color: green[500] }}
                                        />
                                    ) : (
                                        <ErrorIcon
                                            style={{ color: red[500] }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={t(data.fieldName)} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </List>
                    );
                })}
        </div>
    );
};

export default JobBoardsStatusEnabled;
