import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";

export const useNextPageFunctionsNewContact = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const verifyEmailAddress = async (nextPageFunction: any, values: any) => {
        const paramsEmailExists = {
            fieldValueToValidate: values.email,
            validationType: "userEmailExistsLogged",
            companyId
        };

        const urlEmail = "/general/genericvalidationlogged"; //genericValidationAPI
        const validationEmail = await apiDirectCall(
            urlEmail,
            "post",
            paramsEmailExists
        );
        const validEmailExists = validationEmail.data.validationResult;

        const paramsContactEmailNotExists = {
            fieldValueToValidate: values.email,
            validationType: "contactEmailNotExists",
            companyId
        };
        const urlContact = "/contact/genericvalidationcontact"; //genericValidationAPI
        const validationContact = await apiDirectCall(
            urlContact,
            "post",
            paramsContactEmailNotExists
        );
        const validContactExists = validationContact.data.validationResult;

        if (!validEmailExists) {
            callDialog({
                title: t("userEmailExists_ModalTitle"),
                bodyText: t("userEmailExists_Message"),
                agreeButtonText: t("userEmailExists_Yes"),
                //disagreeButtonText: t("userEmailExists_No"),
                agreeFunction: async () => {
                    nextPageFunction(true);
                    //navigate(`/user/edit/${values.email}`);
                }
                //disagreeFunction: () => {}
            });
        } else if (validContactExists.result) {
            callDialog({
                title: t("contactEmailExists_ModalTitle"),
                bodyText: t("contactEmailExists_Message"),
                agreeButtonText: t("contactEmailExists_Yes"),
                disagreeButtonText: t("contactEmailExists_No"),
                agreeFunction: async () => {
                    navigate(`/contact/edit/${validContactExists.uuid}`);
                },
                disagreeFunction: () => {
                    nextPageFunction(true);
                }
            });
        } else {
            nextPageFunction();
        }
    };

    const nextPageFunctions = [{ page: 1, fn: verifyEmailAddress }];

    return nextPageFunctions;
};
