import React from "react";
import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ScorecardModelChooseDialog from "./ScorecardModelChooseDialog";
import { useAuthorization } from "_ReactContext/AuthContext";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";
import useFeatureUpgradePlan, {
    ModalUpgradePlan
} from "customHooks/useFeatureUpgradePlan/useFeatureUpgradePlan";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
interface IProps {
    t: any;
    dialogChooseScorecardTemplateOpen: boolean;
    setDialogChooseScorecardTemplateOpen: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                marginTop: theme.spacing(2)
            }
        },
        grid: {
            marginTop: theme.spacing(3)
        }
    })
);

const ScorecardModel = (props: IProps) => {
    // const [dialogOpen, setDialogOpen] = React.useState(false);
    const classes = useStyles();
    const {
        t,
        dialogChooseScorecardTemplateOpen,
        setDialogChooseScorecardTemplateOpen
    } = props;
    // const [field, meta, helpers] = useField("scorecard");
    // console.log("scorecard props", props);
    const { getFieldValue } = useRhfFieldWithoutControllerOrRegister(
        "scorecard"
    );
    const fieldValue = getFieldValue();

    const { isFeatureAuthorized } = useAuthorization();
    const featuresUpgradePlan = useFeatureUpgradePlan("scorecard");

    const Scorecard_Upgrade = isFeatureAuthorized("PipelineAutomation_Upgrade");

    if (!Scorecard_Upgrade) {
        return (
            <Grid container className={classes.grid}>
                <Grid item xs={12}>
                    <Typography
                        variant="caption"
                        display="block"
                        color="primary"
                    >
                        {t("scorecardmodel")}
                    </Typography>
                    <Typography variant="body1" display="block">
                        {(fieldValue || {}).name ||
                            t("scorecardNoTemplateSeletec")}
                    </Typography>
                </Grid>
                <ScorecardModelChooseDialog
                    open={dialogChooseScorecardTemplateOpen}
                    setOpen={setDialogChooseScorecardTemplateOpen}
                    t={t}
                />
            </Grid>
        );
    } else {
        return (
            <Grid container className={classes.grid}>
                <Grid item xs={12}>
                    <AlertUpgradePlan feature="scorecard" />
                </Grid>
                <ModalUpgradePlan
                    {...featuresUpgradePlan}
                    open={dialogChooseScorecardTemplateOpen}
                    setOpen={setDialogChooseScorecardTemplateOpen}
                />
            </Grid>
        );
    }
};

export default ScorecardModel;
