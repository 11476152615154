import React from "react";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import DashboardItemSwitch from "../Helpers/DashboardItemSwitch";
import dragBackground from "../Helpers/drag-background.svg";
// import { getDashboardType } from "./_DashboardHelper";
import useDashboard, {
    breakpointsList,
    setLastDashboardToRender
} from "../Helpers/useDashboard";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useDashboardContext from "../useDashboardContext";
import DashboardEditModeFloatButtons from "../Helpers/DashboardEditModeFloatButtons";
import { Typography } from "@material-ui/core";
import DashboardTitle from "./DashboardTitle";
import DashboardCustomNotFound from "./DashboardCustomNotFound";
import useGridOnLayoutChange from "../Helpers/useGridOnLayoutChange";

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme: Theme) => {
    const mainRoot = {
        padding: 0,
        margin: 0,
        width: "100%"
    };

    return createStyles({
        root: {
            ...mainRoot,
            background: "transparent"
        },
        rootDragging: {
            ...mainRoot,
            background: `url(${dragBackground})`,
            backgroundRepeat: "repeat-y",
            // backgroundPosition: "0px -4px",
            // backgroundSize: "100% 52px",
            backgroundSize: "100%"
        },
        div: {
            borderWidth: 1
        }
    });
});

const DashboardMainPage = () => {
    const classes = useStyles();
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const onLayoutChange = useGridOnLayoutChange();

    const dashboardDef = useDashboard();
    const { t, ready } = data;
    const { editMode } = data.localStateData;
    const widgetList =
        data.localStateData.currentDashboardDef?.widgetList || [];
    const layouts = data.localStateData.currentDashboardDef?.layouts || {};
    const idDash = data.localStateData.currentDashboardDef?.id;

    const children = React.useMemo(() => {
        if (widgetList.length) {
            return widgetList.map((item) => {
                return (
                    <div key={item}>
                        <DashboardItemSwitch
                            widgetName={item}
                            widget={dashboardDef.widgets[item]}
                        />
                    </div>
                );
            });
        } else {
            return null;
        }
    }, [dashboardDef.ready, editMode, widgetList.length, idDash]);

    React.useEffect(() => {
        if (idDash) {
            setLastDashboardToRender(
                idDash,
                data.localStateData?.dashboardList || []
            );
        }
    }, [idDash]);

    if (dashboardDef.ready && ready) {
        if (widgetList.length > 0) {
            return (
                <Paper
                    className={!editMode ? classes.root : classes.rootDragging}
                    elevation={0}
                >
                    <DashboardTitle />
                    <ResponsiveGridLayout
                        className="layout"
                        layouts={layouts}
                        // onDragStart={() => setIsDragging(true)}
                        // onDragStop={() => setIsDragging(false)}
                        // onResizeStart={() => setIsDragging(true)}
                        // onResizeStop={() => setIsDragging(false)}
                        breakpoints={dashboardDef.breakpoints}
                        cols={dashboardDef.cols}
                        isDraggable={editMode}
                        isResizable={editMode}
                        onLayoutChange={onLayoutChange}
                        // onLayoutChange={(layout, layouts) => {
                        //     // console.log("layout", layout);
                        //     // console.log("layouts", layouts);
                        //     const currentDef =
                        //         data.localStateData?.currentDashboardDef;

                        //     if (currentDef) {
                        //         currentDef.layouts = layouts;
                        //         dispatchGeneralPageInfo({
                        //             payload: {
                        //                 currentDashboardDef: currentDef
                        //             },
                        //             type: "update"
                        //         });
                        //     }
                        // }}
                    >
                        {children}
                    </ResponsiveGridLayout>
                    {editMode && <DashboardEditModeFloatButtons />}
                </Paper>
            );
        } else if (data.localStateData?.pageToRender === "myDashboards") {
            return <DashboardCustomNotFound />;
        } else {
            return <p>{t("NotIdentifiedProblem")}</p>;
        }
    } else {
        return <Loading />;
    }
};

export default DashboardMainPage;
