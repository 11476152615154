import Page from "Pages/_Layouts/MainInternalPage";
import React from "react";
import { MainListToolbar, FilterList } from "./ListHelpers";
import { Box, Grid } from "@material-ui/core";
import useDynamicDashboard from "./index";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ResultListToolbar from "./ListHelpers/ResultListToolbar";
import useGlobalValues from "_ReactContext/GlobalValues";
import useDashboardContext from "Pages/Dashboard/useDashboardContext";

interface IMainPage {
    children: any;
}

export const MainPage = (props: IMainPage) => {
    const [listData] = useDynamicDashboard();
    const { readyTranslation } = listData.tempInfo;
    const ready = readyTranslation;
    const lateralFilter = useMediaQuery("(min-width:800px)");
    const [data] = useDashboardContext();

    if (ready) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                mt={data.localStateData?.uniqueDashboardView ? 0 : 2}
                maxWidth="100%"
            >
                <MainListToolbar />
                <Box
                    display="flex"
                    p={0}
                    height="100%"
                    flexDirection={lateralFilter ? "row" : "column"}
                >
                    <FilterList />
                    <Box p={0} flexGrow={2} minWidth={0}>
                        <ResultListToolbar />
                        {props.children}
                    </Box>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box display="flex" flexDirection="column" mt={2}>
                <Loading />
            </Box>
        );
    }
};

export default MainPage;
