import React from "react";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getControlOptionsAndChildrenFilterV2 } from "../util/AutoCompleteUtil";
import _ from "lodash";
import { getErrorAndMessages } from "../util/general";
import {
    IInputProps,
    useRhfFieldControlled
} from "../../helpers/reactHookFormsHelper";
import { useWatch } from "react-hook-form";
import useJobTitleList from "Pages/JobTitle/components/useJobTitleList";

export interface ISelectProps {
    customLists?: Compleo.IObject[];
    language?: string;
    disableClearable?: boolean;
    campoMetadata: Compleo.IObject;
    ignoreCurrentValueIfNotInList?: boolean;
    label?: string;
}

const JobComboListText = (props: ISelectProps & IInputProps) => {
    const {
        name,
        label,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        language: languageProps,
        ignoreCurrentValueIfNotInList = false,
        ...other
    } = props;
    const language = languageProps || "pt-BR";
    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        watch,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);

    const [jobData, ready] = useJobTitleList();
    const [options, setOptions] = React.useState<string[]>([]);

    const optionsFromServer: string[] = (jobData?.data || []).map(
        (item) => item.itemText
    );

    React.useEffect(() => {
        if (optionsFromServer.length) {
            setOptions(sortStringArray(optionsFromServer));
        }
    }, [optionsFromServer.length]);
    // console.log("jobData", jobData);
    // console.log("ready", ready);

    const valueFromFormik = watch(field.name);
    const [localValue, setLocalValue] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (options.length && valueFromFormik) {
            const lValue = options.filter(
                (item) =>
                    item.toLowerCase().trim() ===
                    (valueFromFormik || "").toLowerCase().trim()
            );
            if (lValue.length) {
                setLocalValue(lValue[0]);
            } else if (!ignoreCurrentValueIfNotInList) {
                const newOptions = [...options];
                newOptions.push(valueFromFormik);
                setOptions(sortStringArray(newOptions));
            } else {
                setLocalValue(null);
            }
        }
    }, [options.length, valueFromFormik]);

    if (valueFromFormik === undefined) {
        return null;
    }

    return (
        <Autocomplete
            disableClearable={other.disableClearable}
            className={other.className}
            autoComplete
            multiple={false}
            id={name}
            options={options}
            value={localValue}
            onChange={(event: any, newValue: string | null) => {
                setFieldValue(newValue || "");
                // setLocalValue(newValue || "");
            }}
            onBlur={field.onBlur}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    // margin="normal"
                    helperText={message}
                    autoComplete="new-password"
                    data-testid={field.name}
                    // {...field}
                    inputRef={field.ref}
                />
            )}
        />
    );
};

function sortStringArray(arr: string[]): string[] {
    return arr.sort((a, b) => a.localeCompare(b));
}

export default JobComboListText;
