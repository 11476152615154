import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface IProps {
    BulkActionsListLocal: JSX.Element;
}

const BulkActionsStep2 = (props: IProps) => {
    const classes = useStyles();
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const { BulkActionsListLocal } = props;

    return (
        <>
            <Typography variant="body2" className={classes.instructions}>
                {t("ELASTICLISTSETTINGS:ModalBulkActions_Step2Instructions")}
            </Typography>
            {BulkActionsListLocal}
        </>
    );
};

export default BulkActionsStep2;
