import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer
} from "recharts";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

const getGraphData = (dataEvaluation: Compleo.IObject[], jobId: string) => {
    const dataReturn: Compleo.IObject[] = [];

    dataEvaluation
        .filter((item) => item.jobId === jobId)
        .map((item) => {
            dataReturn.push({
                value: item.generalEvaluation / 2,
                name: item.evaluator
            });
        });
    return dataReturn;
};

interface IProps {
    open: boolean;
    handleClose: () => void;
    dataEvaluation: Compleo.IObject[];
    t: any;
    jobId: string;
}

const ModalCompareEvaluationsGraph = (props: IProps) => {
    const { open, handleClose, dataEvaluation, t, jobId } = props;
    const data = getGraphData(dataEvaluation, jobId);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">
                {t("evaluationChart")}
            </DialogTitle>
            <DialogContent>
                <ResponsiveContainer
                    minHeight={300}
                    minWidth={300}
                    width="100%"
                    height="100%"
                >
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                        barSize={20}
                        maxBarSize={45}
                    >
                        <XAxis
                            dataKey="name"
                            scale="point"
                            padding={{ left: 10, right: 10 }}
                        />
                        <YAxis domain={[0, 5]} />
                        <Tooltip />
                        {/* <Legend /> */}
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar
                            dataKey="value"
                            fill="#8884d8"
                            background={{ fill: "#eee" }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCompareEvaluationsGraph;

// const data = [
//     {
//         subject: "Math",
//         A: 120,
//         B: 110,
//         fullMark: 150
//     },
//     {
//         subject: "Chinese",
//         A: 98,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "English",
//         A: 86,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "Geography",
//         A: 99,
//         B: 100,
//         fullMark: 150
//     },
//     {
//         subject: "Physics",
//         A: 85,
//         B: 90,
//         fullMark: 150
//     },
//     {
//         subject: "History",
//         A: 65,
//         B: 85,
//         fullMark: 150
//     }
// ];
