import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import theme from "theme/Theme";
import {
    IJobViewStages,
    IJobViewStagesActionDefinition
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import { pipelineAllActionsFields } from "Pages/Pipeline/components/helperPipeline";
import { useApiCache } from "customHooks/useApi";
import JobKanbanApplicantChangeStageModal from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/JobKanbanApplicantChangeStageModal";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useJobChangeStageFunctionParam from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import ChangeStageButton from "./ChangeStageButton";
import { ApplicantViewJobDataInsideJob } from "../useApplicantViewContext/ApplicantViewTypes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }
    })
);

interface IProps {
    t: any;
    index: number;
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
}

const ChangeStageButtonInsideJobContainer = (props: IProps) => {
    const { t, index, jobDataInsideJob } = props;
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { language } = data;
    const { isInsideKanban } = data;
    // const [jobDataContext] = useJobContext();
    const jobDataContext = jobDataInsideJob?.jobDataContext || {};
    const stages = jobDataContext.LocalStateData.allStages;
    const stagesOnlyTotal = jobDataContext.LocalStateData.stagesOnlyTotal;
    const keysToInvalidate: string[] = isInsideKanban
        ? []
        : [
              data.queryKey,
              "/applicant/list",
              "/job/applicantlistjobview",
              "/applicant/applicanthistorylist"
          ];
    const jobModalFN = useJobChangeStageFunctionParam();

    const JobData = (data.applicantData?.Jobs || []).filter(
        (item: Compleo.IObject) => item.JobId === jobDataInsideJob?.jobId
    )[0];
    const applicantData = {
        ...data.applicantData,
        jobData: JobData
    };
    const stage =
        JobData?.Stage ||
        stages.filter((stage: any) => stage.id === JobData?.PipelineStage)[0];

    if (stages.length) {
        return (
            <ChangeStageButton
                applicantData={applicantData}
                currentStage={stage}
                stages={jobDataContext.LocalStateData.allStages}
                jobData={JobData}
                language={language}
                listKeyToInvalidate={keysToInvalidate}
                fnAfterUpdate={jobModalFN.jobChangeStageFunction}
                stagesOnlyTotal={stagesOnlyTotal}
                currentIndex={index}
                outsideKanban={!isInsideKanban}
                t={t}
                readonly={jobDataContext.readonly}
                buttonBorder={false}
            />
        );
    } else {
        return null;
    }
};

export default ChangeStageButtonInsideJobContainer;
