import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useJobChangeStageFunctionParam from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import ChangeStageButton from "./ChangeStageButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }
    })
);

interface IProps {
    index: number;
    ApplicantData: Compleo.IObject;
}

const ChangeStageButtonInsideJobOutsideApplicantView = (props: IProps) => {
    const { index, ApplicantData } = props;
    const classes = useStyles();

    // const [data] = useApplicantViewContext();
    const [jobDataContext] = useJobContext();
    const { language, t } = jobDataContext;
    const isInsideKanban = false;
    const stages = jobDataContext.LocalStateData.allStages;
    const stagesOnlyTotal = jobDataContext.LocalStateData.stagesOnlyTotal;
    const keysToInvalidate: string[] = isInsideKanban
        ? []
        : [
              "/applicant/list",
              "/job/applicantlistjobview",
              "/applicant/applicanthistorylist"
          ];
    const jobModalFN = useJobChangeStageFunctionParam();

    const JobData = ApplicantData.jobData;

    const applicantData = {
        ...ApplicantData
    };
    const stage =
        JobData.Stage ||
        stages.filter((stage: any) => stage.id === JobData.PipelineStage)[0];

    if (stages.length) {
        return (
            <ChangeStageButton
                applicantData={applicantData}
                currentStage={stage}
                stages={jobDataContext.LocalStateData.allStages}
                jobData={JobData}
                language={language}
                listKeyToInvalidate={keysToInvalidate}
                fnAfterUpdate={jobModalFN.jobChangeStageFunction}
                stagesOnlyTotal={stagesOnlyTotal}
                currentIndex={index}
                outsideKanban={!isInsideKanban}
                showAsTag={true}
                fontSize={13}
                t={t}
                readonly={jobDataContext.readonly}
            />
        );
    } else {
        return null;
    }
};

export default ChangeStageButtonInsideJobOutsideApplicantView;
