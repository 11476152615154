import React from "react";
import Page, { SecondComponentType } from "Pages/_Layouts/DefaultInternal";
import useCompleoForm, { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import { ApiStateType, useApi, useApiCache } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";

import { convertParserToCompleoPattern } from "functions/util";
import ApplicantExistsValidation from "../components/ApplicantExistsValidation";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useApplicantNewContext from "./useApplicantNewContext/useApplicantNewContext";

import ApplicantContainerCV from "./ApplicantContainerCV";
import { createLocalUrlFromS3 } from "customHooks/useCompleoForm/Inputs/File/File";
import { navigate } from "@gatsbyjs/reach-router";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { usePlanLimitsPage } from "customHooks/usePlanLimits/usePlanLimits";

interface IApplicantVerificationData {}

const ApplicantVerificationData = (props: IApplicantVerificationData) => {
    const [data, dispatchData] = useApplicantNewContext();
    const [readyCV, setReadyCV] = React.useState(false);
    const { operationid, itemid } = data;
    const showMessage = useShowMessage();

    const { readyForm, callDialog, agree } = useGlobalDialog();
    const { company } = useAuthState();
    const moduleName = "APPLICANTVERIFICATIONDATA";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { limitReached } = usePlanLimitsPage(
        t,
        "/applicant/list",
        "addApplicant"
    );

    const [cvKey, setCvKey] = React.useState<
        { key: string; originalFileName: string } | undefined
    >(undefined);
    const [metadata] = useGetMetadata(moduleName);

    React.useEffect(() => {
        if (cvKey?.key) {
            createLocalUrlFromS3(cvKey.key, true)
                .then((url) => {
                    // Below code is to change the extension of the file to pdf
                    // because the CV is always converted to pdf in function createLocalUrlFromS3
                    const re = /(?:\.([^.]+))?$/;
                    let fileName = cvKey.originalFileName;
                    const extension = (re.exec(fileName) || [])[1];
                    if (extension !== "pdf") {
                        fileName = fileName.replace(/\.[^.]+$/, ".pdf");
                    }

                    const file = new File([url.blob], fileName);
                    if (!readyCV) {
                        setReadyCV(true);
                    }

                    dispatchData({
                        type: "update",
                        payload: {
                            urlCV: url.url,
                            cvFile: {
                                fileName: fileName,
                                streamContent: file
                            }
                        }
                    });
                })
                .catch((err) => {
                    showMessage(t("NotIdentifiedProblem"), "error");
                    if (data.operationid) {
                        navigate(
                            `/applicant/parserlistdetail/${data.operationid}`
                        );
                    }
                });
        }
    }, [cvKey]);
    const postAddressSearch = `/parser/parseroperationsearchitem`;
    const executeJustOnCall = !(
        operationid !== undefined && itemid !== undefined
    );

    let nextStepReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const nextStepFunction = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        const jsonData = data?.localStateData?.jsonParsedCV;
        debugger;

        let ret = await ApplicantExistsValidation(values);
        // console.log(ret);
        if (!ret.result) {
            dispatchData({
                type: "update",
                payload: {
                    ApplicantFirstData: {
                        name: values.name,
                        email: values.email,
                        cpf: values.cpf
                    },
                    pageToRender: "dataEntry"
                }
            });
        } else {
            callDialog({
                title: t("applicantExists_ModalTitle"),
                bodyText: t("applicantExists_Message"),
                agreeButtonText: t("applicantExists_Yes"),
                disagreeButtonText: t("applicantExists_No"),
                agreeFunction: async () => {
                    dispatchData({
                        type: "update",
                        payload: {
                            ApplicantFirstData: {
                                name: values.name,
                                email: values.email,
                                cpf: values.cpf,
                                id: ret.applicantData?.pk.split(":")[1]
                            },
                            pageToRender: "edit",
                            editId: ret.applicantData?.pk.split(":")[1]
                        }
                    });
                },
                disagreeFunction: () => {}
            });
        }
        return retorno;
    };

    const [getOperation] = useApiCache(
        postAddressSearch,
        "post",
        {
            operationid: operationid,
            itemid: itemid,
            companyId: company.companyId
        },
        executeJustOnCall
    );

    let [valuesFromSource] = useValuesFromSource(metadata, true, {});
    let jsonCVFromDB: Compleo.IObject | undefined;

    if (operationid && itemid && getOperation.status === "success") {
        const data =
            (((getOperation.response.data || {}).data || {}).Items || [])[0] ||
            {};

        jsonCVFromDB = data.jsonCV;
        valuesFromSource = convertParserToCompleoPattern(
            valuesFromSource,
            data.jsonCV,
            true
        );

        if (cvKey === undefined) {
            setCvKey(data.cv);
        }
    }
    React.useEffect(() => {
        if (!data.localStateData?.jsonParsedCV && jsonCVFromDB) {
            dispatchData({
                type: "update",
                payload: {
                    jsonParsedCV: jsonCVFromDB
                }
            });
        }
    }, [jsonCVFromDB]);

    if (data?.localStateData?.jsonParsedCV) {
        valuesFromSource = convertParserToCompleoPattern(
            valuesFromSource,
            data?.localStateData?.jsonParsedCV,
            true
        );
    }
    if (!operationid) {
        if (!readyCV) {
            setReadyCV(true);
        }
    }

    const pageReady =
        readyTranslation &&
        (executeJustOnCall || getOperation.status === "success") &&
        readyCV;

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: pageReady,
        i18nV: i18n,
        postMethod: nextStepFunction,
        postReturn: nextStepReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth={data.localStateData?.urlCV ? "lg" : "md"}
            >
                <ApplicantContainerCV
                    compRetorno={compRetorno}
                    url={data?.localStateData?.urlCV}
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// const

// UserNew.whyDidYouRender = true;
export default ApplicantVerificationData;
