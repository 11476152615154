import React from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { transformDBData, keepOnlyNumbersInString } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { IFieldProps } from ".";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldPhone = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, fieldDef, t, language, inline } = props;

    return (
        <ItemView
            label={
                fieldDef.cardIcon ? (
                    <Icon color="action" fontSize="small">
                        {fieldDef.cardIcon}
                    </Icon>
                ) : (
                    `${t(fieldName)}: `
                )
            }
            fieldDef={fieldDef}
            removeColonAfterLabel={true}
            inline={inline}
        >
            {fieldDef?.viewElementType === "whatsapp" &&
            _.get(fieldData, fieldName) ? (
                <a
                    href={`https://wa.me/${keepOnlyNumbersInString(
                        _.get(fieldData, fieldName)
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {transformDBData({
                        value: _.get(fieldData, fieldName),
                        t,
                        language,
                        replaceOption: "-",
                        customType: "phone"
                    })}
                </a>
            ) : (
                transformDBData({
                    value: _.get(fieldData, fieldName),
                    t,
                    language,
                    replaceOption: "-",
                    customType: "phone"
                })
            )}
        </ItemView>
    );
};

export default FieldPhone;
