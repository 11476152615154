import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { apiDirectCall, ApiStateType, useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import { navigate } from "@gatsbyjs/reach-router";
import { useQueryClient } from "react-query";

const CustomCVNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/customcv/list");
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const moduleName = "CUSTOMCVNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const queryClient = useQueryClient();

    const defaultFieldsToHide: string[] = [];
    const postAddress = `/customcv/new/${i18n?.language || "pt-BR"}`;
    const [customLists, readyLists] = useCustomLists();

    //const [addDataReturn, addData] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {
        deleted: false,
        replaceTextForNoInfo: "-",
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10
    });
    //const { fieldsToHide, formikReturn } = useFormikCallBack();

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: readyTranslation && readyLanguages && readyLists,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource
    });

    const handleSubmit = async (values: any) => {
        try {
            const dataInsert = await apiDirectCall(postAddress, "post", {
                ...values
            });
            console.log("dataInsert response", dataInsert);
            queryClient.invalidateQueries("/customcv/list");

            if (dataInsert.data?.uuid) {
                navigate(`/customcv/simulate/${dataInsert.data.uuid}`);
            } else {
                navigate(`/customcv/list`);
            }
        } catch (ex) {
            return ex.response;
        }
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready:
            readyTranslation &&
            readyLanguages &&
            readyLists &&
            metadata.status === "success",
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        customLists: customLists,
        fieldsToHide: fieldsToHide,
        reactHookFormMethods: reactHookFormMethods
    });
    const ready = readyTranslation && finished && readyLists;

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomCVNew;
