import React from "react";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";

interface IProps {
    userEmail: string;
    textNotFound?: string;
}
const CompleoUserName = (props: IProps) => {
    const { userEmail, textNotFound = userEmail } = props;
    const { company } = useAuthState();
    const { params, path } = getQueryParams(userEmail, company.companyId);

    const [getAvatarReturn, executeQueryAvatarReturn] = useApiCache(
        path,
        "post",
        params,
        false,
        1000 * 60 * 10
    );
    const data = getAvatarReturn?.response?.data;

    React.useEffect(() => {
        executeQueryAvatarReturn(params);
    }, [userEmail]);

    return <span>{data?.name || textNotFound}</span>;
};

const getQueryParams = (emailUser: string, companyId: number) => {
    return {
        params: {
            email: emailUser,
            companyId: companyId,
            returnOnlyName: true
        },
        path: `/user/getavataruser`
    };
};

export default CompleoUserName;
