import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from "@material-ui/icons/Sort";
import DoneIcon from "@material-ui/icons/Done";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useList from "..";
import _ from "lodash";

interface IProps {
    sortOptions: Compleo.IObject;
    t: any;
    sortDBData?: Compleo.IObject[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            marginBottom: theme.spacing(1)
        },
        root: {
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            margin: 0
        },
        chip: {
            margin: theme.spacing(0.5)
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        resultsText: {
            marginLeft: theme.spacing(1)
        }
    })
);

const SortMenu = (props: IProps) => {
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { localInfo } = listData;
    const {
        // setModalLocationOpen,
        setFilters,
        setLocalInfo
    } = listDispatch;
    const { callDialog } = useGlobalDialog();
    const { sortOptions, t, sortDBData } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuLocationOpen = (open: boolean, newSortValues: any) => {
        setLocalInfo({
            ...localInfo,
            modalLocationInfo: {
                ...localInfo.modalLocationInfo,
                open: open,
                newSortAfterLocation: newSortValues
            }
        });
    };

    // const setNewSortAfterLocation = (values: any) => {
    //     setLocalInfo({
    //         ...listData.localInfo,
    //         modalLocationInfo: {
    //             ...listData.localInfo.modalLocationInfo,
    //             newSortAfterLocation: values
    //         }
    //     });
    // };

    const options = orderMenuOptions(sortOptions, t);

    const clickMenuItem = (sortName: string, type: string) => {
        const currentSort = _.cloneDeep(filters).sort || {};
        const allSortItems = Object.keys(currentSort);
        for (const sortItem of allSortItems) {
            delete currentSort[sortItem];
        }
        const sortDef = sortOptions?.fields[sortName];
        currentSort[sortName] = { order: 1, orderType: type };

        if (
            sortDef?.type === "distance" &&
            (!filters?.geoLocation.latitude || !filters?.geoLocation.longitude)
        ) {
            setAnchorEl(null);
            callDialog({
                title: t("ELASTICLISTSETTINGS:Dialog_SortLocationTitle"),
                bodyText: t(
                    "ELASTICLISTSETTINGS:Dialog_SortLocationInstructions"
                ),
                disagreeButtonText: t(
                    "ELASTICLISTSETTINGS:Dialog_SortLocationTitle_No"
                ),
                agreeButtonText: t(
                    "ELASTICLISTSETTINGS:Dialog_SortLocationTitle_Yes"
                ),
                disagreeFunction: async () => {
                    return false;
                },
                agreeFunction: async () => {
                    handleMenuLocationOpen(true, currentSort);
                    return false;
                }
            });
        } else {
            setFilters({
                ...filters,
                sort: currentSort,
                filterUpdated: true
            });
            setAnchorEl(null);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const currentUniqueSortItem = getCurrentItem(filters);
    const currentUniqueDBSorteItem = (sortDBData || [])[0] || {};
    let unifiedUniqueSorteItem = currentUniqueSortItem;
    if (!currentUniqueSortItem?.name) {
        unifiedUniqueSorteItem = getCurrentItem({
            sort: currentUniqueDBSorteItem
        });
    }

    const currentLabel = options.filter(
        (item: any) =>
            item.value === unifiedUniqueSorteItem?.name &&
            (unifiedUniqueSorteItem?.def?.orderType === item.type ||
                unifiedUniqueSorteItem?.def?.order === item.type)
    )[0];

    return (
        <div>
            <Button onClick={openMenu} startIcon={<SortIcon />}>
                {currentLabel?.label}
            </Button>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <IconMenuOption
                            key={`${option.value}-${option.type}`}
                            currentUniqueSortItem={unifiedUniqueSorteItem}
                            option={option}
                            clickMenuItem={clickMenuItem}
                        />
                    );
                })}
            </Menu>
        </div>
    );
};

interface IPropsIconMenuOption {
    currentUniqueSortItem: any;
    option: any;
    clickMenuItem: any;
}

const IconMenuOption = React.forwardRef(
    (props: IPropsIconMenuOption, ref: any) => {
        const { currentUniqueSortItem, option, clickMenuItem } = props;
        if (
            currentUniqueSortItem?.name === option.value &&
            (currentUniqueSortItem?.def?.orderType === option.type ||
                currentUniqueSortItem?.def?.order === option.type)
        ) {
            return (
                <MenuItem button={false} selected>
                    <DoneIcon style={{ marginRight: 8 }} />{" "}
                    <strong>{option.label}</strong>{" "}
                    {/* <ListItemIcon>
                    <DoneIcon />
                </ListItemIcon> */}
                </MenuItem>
            );
        } else {
            return (
                <MenuItem
                    onClick={() => clickMenuItem(option.value, option.type)}
                >
                    {option.label}
                </MenuItem>
            );
        }
    }
);

const orderMenuOptions = (sortOptions: any, t: any) => {
    const sortOptionsFields = sortOptions?.fields || {};
    const names = Object.keys(sortOptionsFields);
    const translatedNames: {
        label: any;
        value: string;
        type: "asc" | "desc";
    }[] = [];
    names.map((item) => {
        const sortDef = sortOptionsFields[item] || {};
        if (sortDef.exclude !== "asc" && sortDef.type !== "score") {
            translatedNames.push({
                label: getSortLabelText(item, t, sortDef, "asc"),
                value: item,
                type: "asc"
            });
        }
        if (sortDef.exclude !== "desc") {
            translatedNames.push({
                label: getSortLabelText(item, t, sortDef, "desc"),
                value: item,
                type: "desc"
            });
        }
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

const getCurrentItem = (filters: Compleo.IObject) => {
    const keyNames = Object.keys(filters.sort || {});
    if (keyNames.length === 1) {
        return { name: keyNames[0], def: filters.sort[keyNames[0]] };
    }
};

export const getSortLabelText = (
    item: string,
    t: any,
    sortDef: Compleo.IObject,
    type: "asc" | "desc"
) => {
    let sortDescription = "";
    switch (sortDef?.type) {
        case "date":
            sortDescription =
                type === "asc"
                    ? t("ELASTICLISTSETTINGS:oldest")
                    : t("ELASTICLISTSETTINGS:latest");
            break;
        case "number":
            sortDescription =
                type === "asc"
                    ? t("ELASTICLISTSETTINGS:lowToHigh")
                    : t("ELASTICLISTSETTINGS:highToLow");
            break;
        case "boolean":
            sortDescription =
                type === "asc"
                    ? t("ELASTICLISTSETTINGS:yesToNo")
                    : t("ELASTICLISTSETTINGS:noToYes");
            break;
        case "distance":
            sortDescription =
                type === "asc"
                    ? t("ELASTICLISTSETTINGS:nearest")
                    : t("ELASTICLISTSETTINGS:further");
            break;
        case "score":
        case "custom":
            break;
        default:
            sortDescription =
                type === "asc"
                    ? t("ELASTICLISTSETTINGS:AZ")
                    : t("ELASTICLISTSETTINGS:ZA");
            break;
    }
    if (sortDescription) {
        return `${t(item)}: ${sortDescription}`;
    } else {
        return t(item);
    }
};

export default SortMenu;
