import { useApiCache } from "customHooks/useApi";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { CustomListType } from "./customListsUtil";

const useListBulkActions: CustomListType = () => {
    const listAddress = "/bulkactions/dropdownlist";
    const { company } = useAuthState();
    const [data, setData] = React.useState<Compleo.IObject[] | null>(null);
    const [readyState, setReadyState] = React.useState(false);

    const [listResult, getList] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId,
            limitReturn: 20
        },
        true,
        60 * 1000 * 10
    );
    const ready = listResult.status === "success";

    React.useEffect(() => {
        if (ready && !readyState && data === null) {
            const localData = (listResult.response?.data || []).map(
                (item: Compleo.IObject) => {
                    return { label: item.name, value: item.name };
                }
            );

            setData(localData);
            setReadyState(true);
        }
    }, [ready, listResult.response?.data]);

    const run = async () => {
        await getList();
    };

    return { data: data || [], ready: readyState, run };
};

export default useListBulkActions;
