import { Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" }
    })
);

const ApplicantHistoryChangeStage = (props: IApplicantHistoryProps) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;
    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("NewStage")}
                transformDBDataParam={{
                    value: historyItem.stage.name,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("OldStage")}
                transformDBDataParam={{
                    value: historyItem.oldStage.name,
                    language: language,
                    t: tLocal
                }}
            />
            {historyItem.disqualifyReason && (
                <ApplicantHistoryItemDetail
                    title={tLocal("disqualifyReason")}
                    transformDBDataParam={{
                        value: historyItem.disqualifyReason,
                        language: language,
                        t: tLocal
                    }}
                />
            )}
        </>
        // <Paper elevation={1} className={classes.paper}>
        //     <Typography variant="body2">
        //         {tLocal("changeStageDescription", {
        //             oldStage: historyItem.oldStage.name,
        //             newStage: historyItem.stage.name
        //         })}
        //         {/* {historyItem.oldStage.name} <Icon>forward</Icon>{" "}
        //         {historyItem.stage.name} */}
        //     </Typography>
        //     <Typography variant="caption">
        //         {historyItem.createdByUser}
        //     </Typography>
        // </Paper>
    );
};

export default ApplicantHistoryChangeStage;
