import React from "react";
import { UseFormWatch } from "react-hook-form";

const useFieldsToHide = (
    watch: UseFormWatch<Compleo.IObject>,
    ready: boolean
) => {
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);
    const allBranchs = watch("filters_allBranchs") === true;
    const allCustomers = watch("filters_allCustomers") === true;

    React.useEffect(() => {
        let newFieldsToHide = [...fieldsToHide];
        if (!ready) {
            return;
        }

        if (!allBranchs && newFieldsToHide.includes("filters_branchs")) {
            newFieldsToHide = newFieldsToHide.filter(
                (field) => field !== "filters_branchs"
            );
        }
        if (allBranchs && !newFieldsToHide.includes("filters_branchs")) {
            newFieldsToHide = [...newFieldsToHide, "filters_branchs"];
        }
        if (!allCustomers && newFieldsToHide.includes("filters_customers")) {
            newFieldsToHide = newFieldsToHide.filter(
                (field) => field !== "filters_customers"
            );
        }
        if (allCustomers && !fieldsToHide.includes("filters_customers")) {
            newFieldsToHide = [...newFieldsToHide, "filters_customers"];
        }

        setFieldsToHide(newFieldsToHide);
    }, [allBranchs, allCustomers, ready]);
    return { fieldsToHide };
};

export default useFieldsToHide;
