import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useList from "../..";
import { ButtonGroup, Typography } from "@material-ui/core";
import {
    getBulkActionsList,
    BulkActionsType,
    useJobList,
    IListBulk,
    getT
} from "./helperBulkActions";
import BulkActionsList from "./BulkActionsList";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import useGetMetadata, {
    useGetMetadataMerge
} from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkActionsStep3AddToJob = (props: IProps) => {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const { company } = useAuthState();

    const { formikRef, listBulk, setStepBulk, setLoadingButtons } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const jobData = useJobList();
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKADDTOJOB";
    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);

    const [initialValues] = useValuesFromSource(metadata, false, {});
    const ready = readyTranslation && metadata.status === "success";
    const customList: Compleo.ListDefinitionType[] = [];
    if (jobData.ready) {
        customList.push({
            fieldName: "job",
            data: jobData.data,
            valueName: "title",
            labelName: "pk"
        });
    }

    const handleSubmit = async (values: any) => {
        // const type = values.filterType.value;
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);
        try {
            const data = await apiDirectCall("/bulkactions/baadd", "post", {
                companyId: company.companyId,
                ids: ids,
                job: values.job,
                name: values.name,
                type: "applicant_addToJob"
            });
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        // listData.tempInfo.useApiKey
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);
        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef,
        customLists: customList
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return FormReturn;
};

export default BulkActionsStep3AddToJob;
