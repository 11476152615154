import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
// import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";;
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";

const BranchEdit = (props: any) => {
    // const classes = useFormStyles();
    const { company } = useAuthState();

    const [metadata] = useGetMetadata("BRANCHEDIT");
    const [t, i18n, readyTranslation] = useTranslation("branchedit", {
        useSuspense: false
    });
    //console.log("BranchEdit props", props);
    const postAddress = `/branch/edit/${props.uuid}`;

    const [editBranchReturn, editBranch] = useApi(postAddress, "post");

    const postAddressSearch = `/branch/search`;
    const [getBranchReturn, getBranch] = useApiCache(
        postAddressSearch,
        "post",
        {
            uuid: props.uuid,
            companyId: company.companyId
        },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getBranchReturn,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;

    const [formCompleo, finished] = useCompleoForm(
        null,
        t,
        props,
        readyTranslation,
        i18n,
        editBranch,
        editBranchReturn,
        "/branch/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation && getBranchReturn.status === "success") {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default BranchEdit;
