import React, { useState } from "react";
import { verifyPipelineStageHasActions } from "./helperPipeline";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormContext } from "react-hook-form";

interface IProps {
    label: string;
    metadata: any;
    helperTextDefault?: any;
    t: any;
}

const InformationNoActionsCustom = (props: IProps) => {
    const { t, metadata } = props;
    // const [field, meta, helpers] = useField(metadata.fieldName);
    const { getValues } = useFormContext();
    // const formik = useFormikContext();
    const values = getValues();

    const hasActions = verifyPipelineStageHasActions(values as Compleo.IObject);

    if (!hasActions) {
        return (
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                    <Alert style={{ width: "100%" }} severity="warning">
                        {t(metadata.fieldName)}
                    </Alert>
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};
export default InformationNoActionsCustom;
