import Page from "Pages/_Layouts/MainInternalPage";
import React from "react";
import {
    MainListToolbar,
    FilterList,
    ResultListToolbar,
    ListTablePagination
} from "./ListHelpers";
import { Box, Grid } from "@material-ui/core";
import useList from "./index";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    content: {
        // flexGrow: 1,
        // height: "100vh",
        // overflow: "auto",
        backgroundColor: "#f4f6f8",
        // marginTop: theme.spacing(0.5)
        padding: theme.spacing(1)
    }
}));

interface IMainPage {
    children: any;
}

export const MainPageJobView = (props: IMainPage) => {
    const classes = useStyles();
    const [listData] = useList();
    const { readyTranslation } = listData.tempInfo;
    const ready = readyTranslation;
    const theme = useTheme();
    // const isXsSize = useMediaQuery(theme.breakpoints.only("xs"));
    const lateralFilter = useMediaQuery("(min-width:800px)");

    if (ready) {
        return (
            <>
                {/* <MainListToolbar /> */}
                <Box
                    display="flex"
                    p={0}
                    height="100%"
                    flexDirection={lateralFilter ? "row" : "column"}
                    className={classes.content}
                >
                    <FilterList />
                    <Box p={0} flexGrow={2} minWidth={0}>
                        <MainListToolbar />
                        <ResultListToolbar />
                        {props.children}
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            justify="flex-end"
                        >
                            <ListTablePagination />
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    } else {
        return (
            <Page maxWidth="lg">
                <Loading />
            </Page>
        );
    }
};

export default MainPageJobView;
