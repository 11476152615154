import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType, useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    Avatar,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@material-ui/core";
import { FieldFile } from "customHooks/useCompleoView/Fields/FieldsType";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import SaveIcon from "@material-ui/icons/Save";
import RecipientStatusTags from "./RecipientStatusTags";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "../ApplicantEmailRecipientsTags";
// import ModalSaveEmailAttachments from "./ModalSaveEmailAttachments";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    mainData: Compleo.IObject[];
    allRecipients: Compleo.IObject[];
    t: any;
}

const ModalParticipantsStatus = (props: IModalNewMail) => {
    const { open, handleClose, mainData, t, allRecipients } = props;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {t("iconParticipants")}
            </DialogTitle>
            <DialogContent>
                <Box marginBottom={2}>
                    <Typography variant="body2" component="span">
                        <strong>{t("recipients")}</strong>
                    </Typography>
                    <ApplicantEmailRecipientsTags
                        recipients={allRecipients as IRecipientsTags[]}
                        t={t}
                        applicantEmail=""
                        withoutColors={true}
                    />
                </Box>
                <Divider style={{ marginBottom: 8 }} />
                <Typography variant="body1" component="span">
                    {t("confirmation_title")}
                </Typography>
                <List dense>
                    {mainData.map((item) => (
                        <React.Fragment key={item.skGS1pk}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={`${item.userFrom?.name} - ${item.email}`}
                                    secondary={
                                        <RecipientStatusTags
                                            status={item.status}
                                            t={t}
                                        />
                                    }
                                />
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="secondary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalParticipantsStatus;
