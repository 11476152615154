import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FilterListItem from "./FilterListItem";
import FilterListModal from "./FilterListModal";
import {
    Box,
    Button,
    Collapse,
    Divider,
    Icon,
    IconButton,
    Link,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import useDynamicDashboard, { IUseListBucketReportOpen } from "../index";
// import CustomSearch from "./CustomSearchItems/CustomSearch";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import FastFiltersActionsMenu from "./FastFiltersActionsMenu";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = (fullWidth: boolean = false, isReport: boolean = false) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            box: {
                marginRight: 0,
                [theme.breakpoints.up(800)]: {
                    marginRight: theme.spacing(2)
                }
            },
            root: {
                width: "100%",
                [theme.breakpoints.up(800)]: {
                    width: fullWidth ? "100%" : !isReport ? "300px" : "400px"
                },
                paddingRight: theme.spacing(1),
                backgroundColor: theme.palette.background.paper
            },
            bucket: {
                marginBottom: 0,
                paddingBottom: 0,
                marginLeft: theme.spacing(1)
            },
            bucketTitle: {
                marginBottom: 0,
                paddingBottom: 0,
                marginRight: 0,
                paddingRight: 0
            },
            margin: {
                margin: theme.spacing(1)
            },
            nested: {
                padding: theme.spacing(0.1),
                margin: theme.spacing(0.1),
                paddingLeft: theme.spacing(3.2)
            },
            listItem: {
                minWidth: theme.spacing(0.1),
                padding: 0,
                margin: 0,
                fontWeight: "bold"
            },
            listTitle: {
                fontWeight: "bold"
            },
            lastButton: {
                marginBottom: theme.spacing(1)
            }
        });
    });

interface IProps {
    fullWidth?: boolean;
}

const FilterList = (props: IProps) => {
    const { fullWidth = false } = props;
    const [listData, listDispatch] = useDynamicDashboard();
    const isReport =
        listData?.definitionInfo?.elasticDefData?.data?.otherSettings
            ?.isReport === true;

    const classes = useStyles(fullWidth, isReport)();

    const { filters } = listData.filtersInfo;
    const { setFilters, setLocalInfo } = listDispatch;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const { t } = listData.tempInfo;
    const {
        filterIsOpen,
        openFilterBucketState
    } = listData.localInfo.filterOpenInfo;
    const bucketsDef = listData.definitionInfo.bucketsDef;

    // const [openState, setOpenState] = React.useState<Compleo.IObject>({});
    const [openModal, setOpenModal] = React.useState<Compleo.IObject>({});

    const handleOpen = (aggName: string) => {
        const currentState = openFilterBucketState[aggName] === true;
        const newState = { ...openFilterBucketState };
        newState[aggName] = !currentState;
        setLocalInfo({
            ...listData.localInfo,
            filterOpenInfo: {
                ...listData.localInfo.filterOpenInfo,
                openFilterBucketState: newState
            }
        });

        // setOpenFilterBucketState({ ...newState });
    };

    const handleOpenModal = (aggName: string) => {
        const newState: Compleo.IObject = {};
        newState[aggName] = true;
        setOpenModal({ ...newState });
    };

    const handleCloseModal = () => {
        setOpenModal({});
    };

    const closeView = () => {
        setLocalInfo({
            ...listData.localInfo,
            filterOpenInfo: {
                ...listData.localInfo.filterOpenInfo,
                filterIsOpen: false
            }
        });
    };

    if (filterIsOpen || fullWidth) {
        return (
            <Box
                p={0}
                display="flex"
                // flex={1}
                // marginRight={lateralFilter ? 2 : 0}
                ml={1.5}
                marginBottom={2}
                alignItems="stretch"
                className={classes.box}
            >
                <List
                    component="nav"
                    aria-labelledby={t("ELASTICLISTSETTINGS:FiltersSearch")}
                    className={classes.root}
                >
                    <ListItem className={classes.bucketTitle}>
                        <ListItemText
                            primary={t("ELASTICLISTSETTINGS:fastFilters")}
                            primaryTypographyProps={{
                                color: "secondary",
                                className: classes.listTitle
                            }}
                        />
                        <ListItemIcon>
                            <ButtonIconTooltipCompleo
                                label={t(
                                    "ELASTICLISTSETTINGS:OpenMenuFastFilters"
                                )}
                                onClick={openMenu}
                            >
                                <Icon color="secondary">more_horiz</Icon>
                            </ButtonIconTooltipCompleo>
                            <ButtonIconTooltipCompleo
                                onClick={closeView}
                                label={t("COMPLEOGENERAL_CLOSE")}
                            >
                                <CloseIcon fontSize="small" />
                            </ButtonIconTooltipCompleo>
                        </ListItemIcon>
                    </ListItem>
                    {bucketsDef
                        .filter((i) => {
                            const showOnly: string[] | undefined = i.showOnly;
                            if (!showOnly || !Array.isArray(showOnly)) {
                                return true;
                            }
                            if (showOnly.includes("filter")) {
                                return true;
                            }
                            return false;
                        })
                        .map((agg: any) => {
                            const open =
                                openFilterBucketState[agg.name] === true;
                            const maxItems = agg.maxItems;
                            const isShowMoreEnabled =
                                agg.items.length > maxItems;
                            const aggItems = agg.items.filter(
                                (item: any) => item.total > 0
                            );
                            if (aggItems.length) {
                                return (
                                    <React.Fragment key={agg.name}>
                                        <ListItem
                                            className={classes.bucket}
                                            button={true}
                                            onClick={() => handleOpen(agg.name)}
                                        >
                                            <ListItemText primary={agg.name} />
                                            <ListItemSecondaryAction>
                                                <ButtonIconTooltipCompleo
                                                    label={
                                                        open
                                                            ? t(
                                                                  "ELASTICLISTSETTINGS:BucketButton_ExpandLess"
                                                              )
                                                            : t(
                                                                  "ELASTICLISTSETTINGS:BucketButton_ExpandMore"
                                                              )
                                                    }
                                                    onClick={() =>
                                                        handleOpen(agg.name)
                                                    }
                                                >
                                                    {open ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </ButtonIconTooltipCompleo>
                                            </ListItemSecondaryAction>
                                        </ListItem>

                                        <Collapse
                                            in={open}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List
                                                component="div"
                                                disablePadding
                                            >
                                                {aggItems
                                                    .slice(0, maxItems)
                                                    .map(
                                                        (
                                                            bucket: any,
                                                            index: number
                                                        ) => (
                                                            <FilterListItem
                                                                key={
                                                                    bucket.value
                                                                }
                                                                itemName={
                                                                    agg.key
                                                                }
                                                                bucket={bucket}
                                                            />
                                                        )
                                                    )}
                                            </List>
                                            {isShowMoreEnabled && (
                                                <ListItem
                                                    className={classes.nested}
                                                    button
                                                    onClick={() =>
                                                        handleOpenModal(
                                                            agg.name
                                                        )
                                                    }
                                                >
                                                    <ListItemText
                                                        className={
                                                            classes.listItem
                                                        }
                                                        disableTypography
                                                        secondary={`${t(
                                                            "ELASTICLISTSETTINGS:bucketsMore"
                                                        )}`}
                                                    />
                                                </ListItem>
                                            )}
                                        </Collapse>
                                        <FilterListModal
                                            aggregation={agg}
                                            onClose={handleCloseModal}
                                            open={openModal[agg.name] === true}
                                            title={agg.name}
                                            t={t}
                                        />
                                    </React.Fragment>
                                );
                            } else {
                                return null;
                            }
                        })}
                </List>
                <FastFiltersActionsMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    t={t}
                />
            </Box>
        );
    } else {
        return null;
    }
};

export default FilterList;
