import React from "react";
import ItemCard from "./ItemCard";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    returnType: "card" | "table";
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldJobCode = (props: IProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, returnType } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const jobId = fieldData.pk.split(":")[1];
    const companyCode = fieldData.pk.externalCode;
    const code = companyCode || jobId;

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    label={
                        cardFields[fieldName].cardIcon ? (
                            <Icon color="action" fontSize="small">
                                {cardFields[fieldName].cardIcon}
                            </Icon>
                        ) : (
                            `${t(fieldName)}: `
                        )
                    }
                    removeColonAfterLabel={true}
                >
                    {code}
                </ItemCard>
            );
        case "table":
            return <>{code}</>;
    }
};

export default FieldJobCode;
