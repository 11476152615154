import { RouteComponentProps } from "@gatsbyjs/reach-router";

const useQueryString = (
    props: RouteComponentProps,
    queryStringName: string
) => {
    const search = props?.location?.search; // could be '?foo=bar'
    const params = new URLSearchParams(search || "");
    const queryStringData = params.get(queryStringName); // bar
    return queryStringData;
};

export const useQueryStringRedirectAfter = (props: RouteComponentProps) => {
    const redirectAfter = useQueryString(props, "redirectAfter");
    return redirectAfter;
};

export default useQueryString;
