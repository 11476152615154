import axios from "axios";
import React from "react";
// import axios from "__mocks__/axios";

interface IProps {
    path: string;
    isLocalStreamUrl?: boolean;
}

const PDFViewerStream = (props: IProps) => {
    const { path, isLocalStreamUrl = false } = props;
    const [url, setUrl] = React.useState("");

    React.useEffect(() => {
        if (isLocalStreamUrl) {
            setUrl(path);
        } else {
            axios({
                url: path, //your url
                method: "GET",
                responseType: "blob" // important
            }).then((response) => {
                const urlLocal = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                setUrl(urlLocal);
            });
        }
    }, [path, isLocalStreamUrl]);

    let src = `/pdfjs/web/viewer.html?file=${encodeURIComponent(url)}`;

    if (url) {
        return (
            <iframe title="filePreview" src={src} width="100%" height="700px">
                This is an embedded{" "}
            </iframe>
        );
    } else {
        return null;
    }
};

export default PDFViewerStream;
