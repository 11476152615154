import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { apiDirectCall, ApiStateType, useApiCache } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import { useAuthState } from "_ReactContext/AuthContext";
import useCompleoView from "customHooks/useCompleoView";
import { Avatar, Grid, Typography } from "@material-ui/core";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { FieldFile } from "customHooks/useCompleoView/Fields/FieldsType";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import SaveIcon from "@material-ui/icons/Save";
import ModalSaveEmailAttachments from "./ModalSaveEmailAttachments";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import useApplicantViewContext from "../useApplicantViewContext";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "Pages/Email/ApplicantEmailRecipientsTags";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    globalEmailId: string;
    enableSaveEmailApplicant?: boolean;
    fromEmail?: Compleo.IObject;
    recipients?: Compleo.IObject[];
}
function createMarkup(data: string) {
    return { __html: data };
}

const ModalViewEmail = (props: IModalNewMail) => {
    const {
        open,
        handleClose,
        globalEmailId,
        fromEmail,
        recipients,
        enableSaveEmailApplicant = false
    } = props;
    const { company, timeZone } = useAuthState();
    const [
        modalSaveAttachmentsOpen,
        setModalSaveAttachmentsOpen
    ] = React.useState(false);
    const [data] = useApplicantViewContext();

    const moduleName = "EMAILVIEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    // const [metadata] = useGetMetadata(moduleName);

    const listAddress = `/email/getemail`;
    const postData = {
        id: globalEmailId,
        companyId: company.companyId
    };
    const [emailDB, getEmailDB] = useApiCache(
        listAddress,
        "post",
        postData,
        false
    );
    const dataFromDB = emailDB.response?.data?.data;
    console.log("dataFromDB", dataFromDB);

    const valuesFromSource = {
        subject: dataFromDB?.subject,
        body: dataFromDB?.body,
        attachments: dataFromDB?.attachments
    };
    // console.log("valuesFromSource", valuesFromSource);

    const ready = emailDB.status === "success" && readyTranslation;
    const hasAttachments = (dataFromDB?.attachments || []).length > 0;
    const isFromApplicant =
        data.applicantData?.email === dataFromDB?.userFrom?.email;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {ready && (
                    <Grid container>
                        <Grid item xs={1}>
                            {isFromApplicant ? (
                                <CompleoAvatar
                                    idParams={{ id: data.applicantId }}
                                    type="applicant"
                                />
                            ) : (
                                <CompleoAvatar
                                    idParams={{
                                        email: dataFromDB?.createdByUser
                                    }}
                                    type="user"
                                />
                            )}
                        </Grid>
                        <Grid item xs>
                            {`${t("subject")}: ${valuesFromSource?.subject}`}
                        </Grid>
                        {fromEmail && (
                            <Grid item xs={12}>
                                <Typography variant="body2" component="span">
                                    <strong>{t("from")}:</strong>
                                </Typography>
                                <ApplicantEmailRecipientsTags
                                    recipients={
                                        [fromEmail] as IRecipientsTags[]
                                    }
                                    t={t}
                                    applicantEmail=""
                                    withoutColors={false}
                                />
                            </Grid>
                        )}
                        {recipients && (
                            <Grid item xs={12}>
                                <Typography variant="body2" component="span">
                                    <strong>{t("recipients")}:</strong>
                                </Typography>
                                <ApplicantEmailRecipientsTags
                                    recipients={recipients as IRecipientsTags[]}
                                    t={t}
                                    applicantEmail=""
                                    withoutColors={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogTitle>
            <DialogContent dividers>
                {ready ? (
                    <React.Fragment>
                        <Grid container>
                            <Grid item container xs={12}>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        dataFromDB?.body
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{ marginTop: 16 }}
                            >
                                {hasAttachments && (
                                    <>
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                >
                                                    <strong>
                                                        {t(
                                                            "a_FormGroup_attachments"
                                                        )}
                                                    </strong>
                                                </Typography>
                                                {!data.readOnly && (
                                                    <ButtonIconTooltipCompleo
                                                        label={t(
                                                            "saveAttachments"
                                                        )}
                                                        color="secondary"
                                                        onClick={() =>
                                                            setModalSaveAttachmentsOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <SaveIcon />
                                                    </ButtonIconTooltipCompleo>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldFile
                                                fieldData={dataFromDB}
                                                fieldName="attachments"
                                                t={t}
                                                language={i18n.languages[0]}
                                                fieldDef={{}}
                                                propsToAdd={{}}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {modalSaveAttachmentsOpen && (
                            <ModalSaveEmailAttachments
                                open={modalSaveAttachmentsOpen}
                                handleClose={() =>
                                    setModalSaveAttachmentsOpen(false)
                                }
                                files={dataFromDB.attachments}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </DialogContent>
            {ready && (
                <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary">
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ModalViewEmail;
