import React from "react";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getOpinionOnTheApplicantJob, getAgeItem } from "functions/util";

type variablesType =
    | "APPLICANTMAIN"
    | "APPLICANTPROFESSIONALHISTORY"
    | "APPLICANTEDUCATIONHISTORY"
    | "APPLICANTLANGUAGES";

type variableListItem = {
    value: string;
    label: string;
};

const useCustomCVVariableList = (
    variableType: variablesType,
    language: string,
    t?: any
): [variableListItem[], boolean] => {
    const { company } = useAuthState();
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    const [variableList, setVariableList] = React.useState<variableListItem[]>(
        []
    );

    const [variablesData] = useApiCache(
        "/customcv/variablelist",
        "post",
        { companyId, variableType, language },
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        if (variablesData?.status === "success") {
            let variableList = variablesData.response?.data || [];

            if (variableType === "APPLICANTMAIN" && variableList.length > 0) {
                const ageItem = getAgeItem(t);
                const opinionOnTheApplicantJobItem = getOpinionOnTheApplicantJob(
                    t
                );

                if (
                    !variableList.find(
                        (item: any) => item.value === ageItem.value
                    )
                ) {
                    variableList = [...variableList, ageItem];
                }

                if (
                    !variableList.find(
                        (item: any) =>
                            item.value === opinionOnTheApplicantJobItem.value
                    )
                ) {
                    variableList = [
                        ...variableList,
                        opinionOnTheApplicantJobItem
                    ];
                }
            }
            setVariableList(variableList);
            setReady(true);
        }
    }, [variablesData?.status, variablesData?.response?.data]);

    return [variableList, ready];
};

export default useCustomCVVariableList;
