import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useList from "../index";
import SettingsModalFastFilters from "./Settings/SettingsModalFastFilters";
import { Icon } from "@material-ui/core";

type ActionsMenuList = "fastFilterSettings" | "updateAfterSearch";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
}

const FastFiltersActionsMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t } = props;
    const [listData, listDispatch] = useList();
    const isReport =
        listData?.definitionInfo?.elasticDefData?.data?.otherSettings
            ?.isReport === true;

    const { localInfo } = listData;
    const [fastFilterSettingsOpen, setFastFiltersSettingsOpen] = React.useState(
        false
    );

    const { setFilters } = listDispatch;

    const menuOptions: ActionsMenuList[] = ["fastFilterSettings"];
    if (!isReport) {
        menuOptions.push("updateAfterSearch");
    }
    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleUpdateAfterSearch = () => {
        const updateAfterSearchBool =
            listData.filtersInfo.filters.updateAggsAfterFilter === true;
        setFilters({
            ...listData.filtersInfo.filters,
            updateAggsAfterFilter: !updateAfterSearchBool
        });
    };

    const clickMenuItem = (action: ActionsMenuList) => {
        switch (action) {
            case "fastFilterSettings":
                setFastFiltersSettingsOpen(true);
                handleClose();
                break;
            case "updateAfterSearch":
                toggleUpdateAfterSearch();
                handleClose();
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            <MenuCheckBox item={option.value} /> {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            <SettingsModalFastFilters
                onClose={() => setFastFiltersSettingsOpen(false)}
                open={fastFilterSettingsOpen}
            />
        </div>
    );
};

interface IMenuCheckBox {
    item: ActionsMenuList;
}

const MenuCheckBox = (props: IMenuCheckBox) => {
    const { item } = props;
    const [listData, listDispatch] = useList();
    const updateAfterSearchBool =
        listData.filtersInfo.filters.updateAggsAfterFilter === true;
    if (item === "updateAfterSearch" && updateAfterSearchBool) {
        return (
            <Icon color="primary" style={{ marginRight: 8 }}>
                check
            </Icon>
        );
    } else {
        return null;
    }
};

const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ELASTICLISTSETTINGS:FastFilterMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default FastFiltersActionsMenu;
