import React from "react";
import {
    useFormikContext,
    Formik,
    Form,
    Field,
    FieldArray,
    FieldProps
} from "formik";
import _ from "lodash";
import { Grid, Paper, Typography } from "@material-ui/core";
import { getFields } from "../helpers/getFields";
import { orderList } from "../helpers/helper";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useValuesFromSourceDirect } from "customHooks/useValuesFromSource";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

// import IconButton from "@material-ui/core/B";

// import Typography from '@material-ui/core/Typography';

interface IProps {
    fieldMetadata: any;
    t: any;
    required: boolean;
    completeMetada: Compleo.IObject[];
    fieldClasses: any;
    listas: any;
    valuesFromSource: any;
    CustomComponents: any;
    language: any;
    customLists: any;
    fieldsToHide: any;
    helperTextDefault: null | string;
    additionalFieldProperties?: Compleo.useCompleoForm.AdditionalPropertiesField[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        removeButton: {
            margin: theme.spacing(1)
        },
        addButton: {
            marginTop: theme.spacing(2)
        },
        extendedIcon: {
            marginRight: theme.spacing(1)
        },
        divider: {
            marginBottom: theme.spacing(0),
            marginTop: theme.spacing(2),
            height: 2
        },
        gridMain: {
            marginTop: theme.spacing(0)
        },
        paper: {
            padding: theme.spacing(1),
            // paddingBottom: theme.spacing(2),
            width: "100%"
        },
        formFieldSet: {
            width: "100%",
            marginTop: theme.spacing(2)
        },
        dividerItems: {
            height: 1,
            backgroundColor: theme.palette.grey[400],
            marginTop: theme.spacing(3)
        }
    })
);

const ComplexField = (props: IProps & FieldProps) => {
    const classes = useStyles();
    // const formik = useFormikContext();
    // const values: any = formik.values;
    // const { dirty, touched, errors, status }: any = formik;
    const {
        fieldMetadata,
        completeMetada,
        t,
        required,
        form: { touched, errors, status, values },
        helperTextDefault,
        additionalFieldProperties = []
    } = props;
    const fieldName = fieldMetadata.fieldName;
    const disableAddButton =
        (fieldMetadata.complexField || {}).disableAddButton === undefined
            ? false
            : (fieldMetadata.complexField || {}).disableAddButton;

    // const errorText = errors[fieldName];
    // const hasError = touched[fieldName] && errorText !== undefined;
    // let mensagemErroDetail =
    //     hasError && errorText !== undefined ? errorText : "";

    const errorText = _.get(errors, fieldName); // errors[field.name];
    const hasError = _.get(touched, fieldName) && errorText !== undefined; // touched[field.name] && errorText !== undefined;
    let mensagemErroDetail =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    let mensagemErro = "";
    if (Array.isArray(mensagemErroDetail) && mensagemErroDetail.length > 0) {
        // Se for array há o detalhamento dos subitems. Futuramente pode-se prever uma mensagem
        // genérica, tipo: Verique os erros no detalhamento dos campos abaixo.
        mensagemErro = "";
        // mensagemErro = mensagemErroDetail[0][fieldName] || "";
    } else if (typeof mensagemErroDetail === "string") {
        mensagemErro = mensagemErroDetail ?? "";
    }

    const newMetadata = getFilteredMetadataAndRenamedMetadataComplexField(
        fieldName,
        completeMetada
    );
    const [valuesFromSourceLocal] = useValuesFromSourceDirect(
        newMetadata.filteredMetadata,
        false,
        {},
        null,
        undefined,
        undefined,
        false
    );
    // renomeia os campos
    Object.entries(valuesFromSourceLocal).forEach((obj) => {
        valuesFromSourceLocal[renameComplexField(obj[0], fieldName)] = obj[1];
        delete valuesFromSourceLocal[obj[0]];
    });
    return (
        <>
            {/* <Divider className={classes.divider} /> */}
            <FormControl
                component="fieldset"
                className={classes.formFieldSet}
                error={hasError || (status && status[fieldName] !== undefined)}
            >
                <FormLabel component="legend" required={required}>
                    {t(fieldName)}
                </FormLabel>
                <FormHelperText>{mensagemErro}</FormHelperText>
                <FieldArray
                    name={fieldName}
                    render={(arrayHelpers: any) => (
                        <>
                            {values[fieldName].map(
                                (element: any, index: number) => (
                                    <React.Fragment key={index}>
                                        <ComplexFieldDetail
                                            classes={classes}
                                            index={index}
                                            key={index}
                                            arrayHelpers={arrayHelpers}
                                            {...props}
                                        />
                                    </React.Fragment>
                                )
                            )}
                            {!disableAddButton ? (
                                <Grid
                                    container
                                    item
                                    alignItems="center"
                                    justify="flex-start"
                                    direction="row"
                                >
                                    <Button
                                        className={classes.addButton}
                                        size="small"
                                        variant="outlined"
                                        startIcon={
                                            <Icon color="primary">
                                                add_circle
                                            </Icon>
                                        }
                                        color="default"
                                        onClick={() =>
                                            arrayHelpers.push(
                                                valuesFromSourceLocal
                                            )
                                        }
                                    >
                                        {`${t(
                                            "COMPLEXFIELD_addItemButton"
                                        )} ${t(fieldName)}`}
                                    </Button>
                                </Grid>
                            ) : null}
                        </>
                    )}
                />
            </FormControl>
        </>
    );
};

interface IPropsDetail {
    index: number;
    arrayHelpers: any;
    classes: any;
}

const ComplexFieldDetail = (props: IProps & IPropsDetail) => {
    const formik = useFormikContext();
    const values: any = formik.values;
    const {
        index,
        fieldMetadata,
        completeMetada,
        t,
        required,
        fieldClasses,
        listas,
        valuesFromSource,
        CustomComponents,
        language,
        customLists,
        fieldsToHide,
        arrayHelpers,
        classes,
        additionalFieldProperties = []
    } = props;
    const fieldName = fieldMetadata.fieldName;
    const camposMetadados = getFilteredMetadataAndRenamedMetadataComplexField(
        fieldName,
        completeMetada,
        index
    );

    let lastOrderItem = -1;
    return (
        <>
            <Divider className={classes.dividerItems} />
            <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={1} className={classes.gridMain}>
                    <Grid
                        item
                        xs={12}
                        sm={1}
                        container
                        alignItems="center"
                        justify="flex-start"
                        direction="row"
                        zeroMinWidth
                    >
                        <Tooltip
                            title={`${t("COMPLEXFIELD_deleteItemButton")} ${t(
                                fieldName
                            )}`}
                        >
                            <IconButton
                                color="secondary"
                                aria-label={`${t(
                                    "COMPLEXFIELD_deleteItemButton"
                                )} ${t(fieldName)}`}
                                onClick={() => arrayHelpers.remove(index)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item container spacing={1} xs={12} sm={11}>
                        {camposMetadados.renamedMetadata.map((item: any) => {
                            // converte listas para cada array
                            if (
                                listas !== undefined &&
                                listas[item.fieldName] === undefined
                            ) {
                                const simpleFieldName = item.fieldName.split(
                                    "."
                                )[1];
                                if (
                                    listas[
                                        `COMPLEXFIELD:${fieldName}_${simpleFieldName}`
                                    ] !== undefined
                                ) {
                                    listas[item.fieldName] =
                                        listas[
                                            `COMPLEXFIELD:${fieldName}_${simpleFieldName}`
                                        ];
                                }
                            }

                            const lastOrderParam = lastOrderItem;
                            lastOrderItem = item.order;
                            const Field = getFields(
                                item,
                                fieldClasses,
                                t,
                                listas,
                                valuesFromSource,
                                null,
                                CustomComponents,
                                language,
                                customLists,
                                fieldsToHide,
                                completeMetada,
                                false,
                                additionalFieldProperties
                            );

                            if (
                                lastOrderParam !== lastOrderItem &&
                                lastOrderParam !== -1
                            ) {
                                return (
                                    <React.Fragment key={item.fieldName}>
                                        <Grid container></Grid>
                                        {Field}
                                    </React.Fragment>
                                );
                            } else {
                                return Field;
                            }
                        })}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export const renameComplexField = (name: string, fieldName: string) => {
    return name.replace(`COMPLEXFIELD:${fieldName}_`, "");
};

export const getFilteredMetadataAndRenamedMetadataComplexField = (
    fieldName: string,
    metadata: Compleo.IObject[],
    index?: number
) => {
    const filteredMetadata = metadata.filter((f: any) =>
        f.fieldName.includes(`COMPLEXFIELD:${fieldName}`)
    );

    let renamedMetadata = filteredMetadata;
    if (index !== undefined) {
        renamedMetadata = filteredMetadata.map((c: any) => {
            return {
                ...c,
                fieldName: `${fieldName}[${index}].${renameComplexField(
                    c.fieldName,
                    fieldName
                )}`
            };
        });
    } else {
        renamedMetadata = filteredMetadata.map((c: any) => {
            return {
                ...c,
                fieldName: renameComplexField(c.fieldName, fieldName)
            };
        });
    }

    return {
        filteredMetadata: orderList(filteredMetadata),
        renamedMetadata: orderList(renamedMetadata)
    };
};

export default ComplexField;
