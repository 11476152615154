import React from "react";
import { ViewList, ViewStream, ViewModule } from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useList, { resultTypeOptionsUseList } from "../index";

interface IUseStyles {
    selected: boolean;
}

const useStyles = (props: IUseStyles) => {
    const { selected } = props;
    return makeStyles((theme: Theme) =>
        createStyles({
            buttons: {
                padding: theme.spacing(0.5),
                margin: 0,
                marginTop: theme.spacing(0.5),
                backgroundColor: selected ? theme.palette.grey[300] : undefined,
                borderRadius: 0
            }
        })
    );
};

interface IProps {
    className?: string;
}

const ViewResultsOptions = (props: IProps) => {
    const [listData, listDispatch] = useList();
    const { className } = props;
    const classesSelected = useStyles({ selected: true })();
    const classes = useStyles({ selected: false })();
    const { resultType } = listData.localInfo;
    const { t } = listData.tempInfo;
    const { setLocalInfo } = listDispatch;

    const setResultType = (resultType: resultTypeOptionsUseList) => {
        setLocalInfo({
            ...listData.localInfo,
            resultType: resultType
        });
    };

    return (
        <div className={className}>
            <ButtonIconTooltipCompleo
                label={t("ELASTICLISTSETTINGS:ResultsTable")}
                className={
                    resultType === "table"
                        ? classesSelected.buttons
                        : classes.buttons
                }
                onClick={() => setResultType("table")}
            >
                <ViewList />
            </ButtonIconTooltipCompleo>
            <ButtonIconTooltipCompleo
                label={t("ELASTICLISTSETTINGS:ResultsGrid")}
                className={
                    resultType === "grid"
                        ? classesSelected.buttons
                        : classes.buttons
                }
                onClick={() => setResultType("grid")}
            >
                <ViewStream />
            </ButtonIconTooltipCompleo>
            <ButtonIconTooltipCompleo
                label={t("ELASTICLISTSETTINGS:ResultsSmallGrid")}
                className={
                    resultType === "smallGrid"
                        ? classesSelected.buttons
                        : classes.buttons
                }
                onClick={() => setResultType("smallGrid")}
            >
                <ViewModule />
            </ButtonIconTooltipCompleo>
        </div>
    );
};

export default ViewResultsOptions;
