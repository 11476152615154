import React from "react";
import MaterialTable, {
    QueryResult,
    Query,
    MTableToolbar,
    Action
} from "material-table";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { TFunction } from "i18next";
import Paper from "@material-ui/core/Paper";
import { ApiCallType, ApiStateType } from "customHooks/useApi";
import { apiDirectCall } from "customHooks/useApi/api";
import { AxiosResponse } from "axios";
import { MemoryCache } from "apiCompleo/MemoryCache";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useTranslation } from "react-i18next";
import {
    getServerOptions,
    generateColumns,
    generateLocalizatinObject,
    generateMaterialTableOptions,
    convertData,
    maxLineServerExportFallBack,
    transformLocalData
} from "./helper";
import { useApiCache } from "customHooks/useApi";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";

export type actionsTableListType = actionsTableListOptionsType[];

type actionsTableListOptionsType =
    | actionsTableListType1
    | actionsTableListType2;

type actionsTableListType1 = {
    icon: any;
    tooltip: string;
    onClick: (event: any, rowData: any) => any;
    disabled?: boolean;
    hidden?: boolean;
    isFreeAction?: boolean;
};

type actionsTableListType2 = (
    rowData: any
) => {
    icon: any;
    tooltip: string;
    onClick: (event: any, rowData: any) => any;
    disabled?: boolean;
    hidden?: boolean;
    isFreeAction?: boolean;
};

export type actionsTableFunction = (
    refreshListFunction: any
) => actionsTableListType;

export type RenderTableType = () => JSX.Element | null;
export type useCompleoListType = (
    t: TFunction,
    metadata: ApiStateType,
    endpoint: string,
    method: "post" | "get",
    params: Compleo.IObject,
    actions: actionsTableListType | actionsTableFunction,
    bottomComponent?: JSX.Element,
    functionParams?: compleoSimpleListFuncionParamsType
) => [
    RenderTableType,
    boolean,
    any,
    ApiCallType,
    ApiStateType,
    Compleo.IObject[]
];

export type compleoSimpleListFuncionParamsType = {
    [functionName: string]: (rowData: any) => Promise<void>;
};

export const useCompleoSimpleList: useCompleoListType = (
    t: TFunction,
    metadata: ApiStateType,
    endpoint: string,
    method: "post" | "get",
    params: Compleo.IObject,
    actionsParam: actionsTableListType | actionsTableFunction = [],
    bottomComponent?: JSX.Element,
    functionParams?: compleoSimpleListFuncionParamsType
) => {
    const tableRef = React.useRef<any>();
    const [tMaterial, i18n, readyTranslation] = useTranslation(
        "MATERIALTABLE",
        {
            useSuspense: false
        }
    );
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const classes = useFormStyles();
    const [ListDefinition, setListDefinition] = React.useState<Compleo.IObject>(
        { finished: false, serverSide: true }
    );
    const [items, setItems] = React.useState<Compleo.IObject>({
        finished: false
    });
    const [totalCalled, setTotalCalled] = React.useState(false);
    const [totalFinished, setTotalFinished] = React.useState(false);
    const [dbReturnData, setDbReturnData] = React.useState<Compleo.IObject[]>(
        []
    );

    const [totalRecords, callTotalRecords] = useApiCache(
        endpoint,
        method,
        { ...params, onlyReturnTotal: true },
        true,
        0
    );
    const [finalSearch, callFinalSearch, invalidateQuery] = useApiCache(
        endpoint,
        method,
        { ...params, onlyReturnTotal: true },
        true,
        0
    );

    React.useEffect(() => {
        if (params.refreshQuery === true) {
            const finalParams = _.cloneDeep(params);
            delete finalParams.refreshQuery;
            callFinalSearch({ ...finalParams });
        }
    }, [params.refreshQuery]);

    const [refresh, setRefresh] = React.useState({ refreshFunction: () => {} });

    let actions: actionsTableListType = [];
    if (Array.isArray(actionsParam)) {
        actions = actionsParam;
    } else {
        actions = actionsParam(refresh.refreshFunction);
    }

    if (ListDefinition.finished && !items.finished && !totalCalled) {
        callTotalRecords();
        setTotalCalled(true);
    }

    if (totalRecords.status === "success" && !totalFinished) {
        MemoryCache.clearModule(endpoint);
        const result = totalRecords.response;
        const serverOptions = getServerOptions(ListDefinition);
        const total = result.data.total;
        let serverSide = false;
        if (
            total >= serverOptions.rowsToChangeServerSide &&
            serverOptions.enabledServerSide
        ) {
            serverSide = true;
        }
        setTotalFinished(true);
        if (!serverSide) {
            callFinalSearch({
                ...params,
                sizeProcessLocal: total + 1
            });

            if (invalidateQuery !== undefined) {
                const refreshInvalidate = () => {
                    invalidateQuery({
                        ...params,
                        sizeProcessLocal: total + 1
                    });
                };

                setRefresh({ refreshFunction: refreshInvalidate });
            }
            setItems({
                ...items,
                finished: false,
                serverSide,
                total
            });
        } else {
            if (invalidateQuery !== undefined) {
                const refreshInvalidate = () => {
                    MemoryCache.clearModule(endpoint);
                    tableRef.current.onQueryChange();
                };

                setRefresh({ refreshFunction: refreshInvalidate });
            }

            setItems({ finished: true, data: [], serverSide, total });
        }
    }

    if (finalSearch.status === "success" && !items.finished) {
        const result = finalSearch.response;
        const transformedData = transformLocalData(
            result.data.data,
            ListDefinition,
            t,
            i18n.languages[0],
            functionParams
        );
        setItems({
            ...items,
            finished: true,
            data: transformedData
        });
    }

    if (metadata.status === "success" && !ListDefinition.finished) {
        if (
            metadata.response !== null &&
            metadata.response.data !== undefined &&
            metadata.response.data.ListDefinition !== undefined &&
            metadata.response.data.ListDefinition.columns !== undefined
        ) {
            const fields = metadata.response.data.ListDefinition.columns;
            const materialTableOptions =
                metadata.response.data.ListDefinition.materialTableOptions ||
                [];
            const otherOptions =
                metadata.response.data.ListDefinition.otherOptions || [];
            setListDefinition({
                fieldsTable: fields,
                materialTableOptions,
                otherOptions,
                finished: true
            });
        }
    }

    const RenderTable: RenderTableType = () => {
        const maxLinesExportLocal = ListDefinition.otherOptions.filter(
            (i: any) => i.id === "maxLinesExportLocal"
        )[0];
        let maxLinesExport = ((maxLinesExportLocal || {}).definition || {})
            .value;
        maxLinesExport =
            maxLinesExport !== undefined
                ? maxLinesExport
                : maxLineServerExportFallBack;

        let dataRow:
            | any[]
            | ((query: Query<any>) => Promise<QueryResult<any>>) = [];

        if (items.serverSide) {
            dataRow = (query: Query<any>) => {
                return new Promise((resolve, reject) => {
                    const filters = query.filters.map((f: any) => {
                        return {
                            field: f.column.field,
                            operator: f.operator,
                            value: f.value
                        };
                    });
                    const allParams = {
                        ...params,
                        page: query.page + 1,
                        pageSize: query.pageSize,
                        sizeProcessLocal: 0,
                        orderBy: (query.orderBy || {}).field || "",
                        orderDirection: query.orderDirection,
                        search: query.search,
                        filters: filters
                    };
                    const memKey = JSON.stringify(allParams) + endpoint;
                    const memoryReturn = MemoryCache.get(memKey, endpoint);

                    if (memoryReturn === undefined) {
                        apiDirectCall(endpoint, method, allParams).then(
                            (result) => {
                                const returnData = {
                                    data: result.data.data,
                                    page: result.data.page - 1,
                                    totalCount: result.data.totalCount
                                };

                                MemoryCache.addOrEdit(
                                    memKey,
                                    returnData,
                                    endpoint,
                                    60 * 5
                                );
                                resolve(returnData);
                            }
                        );
                    } else {
                        resolve(memoryReturn);
                    }
                });
            };
        } else {
            let finalData = items.data;
            if (finalSearch.status === "success") {
                const result = finalSearch.response;
                const transformedData = transformLocalData(
                    result.data.data,
                    ListDefinition,
                    t,
                    i18n.languages[0],
                    functionParams
                );
                finalData = transformedData;
            }

            dataRow = finalData;
        }

        const columns = generateColumns(
            ListDefinition.fieldsTable,
            t,
            items.serverSide,
            i18n.languages[0],
            functionParams
        );
        const materialOptions = generateMaterialTableOptions(
            ListDefinition.materialTableOptions
        );
        const localization = generateLocalizatinObject(tMaterial);

        if (columns.length > 0) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={tableRef}
                            localization={localization}
                            options={{
                                exportButton: true,
                                exportAllData: true,
                                exportCsv: async (columns, data) => {
                                    convertData(
                                        columns,
                                        items.data,
                                        "csvdata",
                                        items,
                                        endpoint,
                                        method,
                                        params,
                                        callDialog,
                                        tMaterial,
                                        ListDefinition,
                                        i18n.languages[0],
                                        maxLinesExport,
                                        "csv"
                                    );
                                },
                                //@ts-ignore
                                exportPdf: async (columns, data) => {
                                    convertData(
                                        columns,
                                        items.data,
                                        "csvdata",
                                        items,
                                        endpoint,
                                        method,
                                        params,
                                        callDialog,
                                        tMaterial,
                                        ListDefinition,
                                        i18n.languages[0],
                                        maxLinesExport,
                                        "pdf"
                                    );
                                },
                                debounceInterval: items.serverSide ? 500 : 5,
                                ...materialOptions
                            }}
                            aria-label={t("a_FeatureMainDescription")}
                            title=""
                            columns={columns}
                            // data={rows}
                            // onColumnDragged={handleColumnDragged}
                            data={dataRow}
                            actions={actions}
                            components={{
                                Container: (props) => (
                                    <Paper className={classes.paper}>
                                        {props.children}
                                    </Paper>
                                )
                                // Toolbar: (props) => {
                                //     return (
                                //         <div style={{ backgroundColor: "#e8eaf5" }}>
                                //             <MTableToolbar {...props} />
                                //         </div>
                                //     );
                                // }
                            }}
                        />
                    </Grid>
                    {bottomComponent && (
                        <Grid item xs={12} style={{ marginTop: 8 }}>
                            <Grid container item>
                                {bottomComponent}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            );
        } else {
            return null;
        }
    };

    return [
        RenderTable,
        ListDefinition.finished && items.finished && readyTranslation,
        refresh.refreshFunction,
        callFinalSearch,
        finalSearch,
        dbReturnData
    ];
};
