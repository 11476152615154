import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        backdrop: {
            zIndex: 10000,
            color: "#fff"
        }
    });
});

const BackdropContext = React.createContext({
    backdropOpen: false,
    setBackdropOpen: (open: boolean) => {}
});

export const useBackdrop = () => React.useContext(BackdropContext);

export const BackdropProvider: React.FC = ({ children }) => {
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const classes = useStyles();

    return (
        <BackdropContext.Provider value={{ backdropOpen, setBackdropOpen }}>
            {children}
            {backdropOpen && (
                <Backdrop className={classes.backdrop} open={backdropOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </BackdropContext.Provider>
    );
};
