import React from "react";
import { TableCell, TableRow } from "@material-ui/core";

interface IProps {
    index: number;
    t: any;
    fieldValue: any;
}

const QuestionnaireRankingToResult = (props: IProps) => {
    const { index, t, fieldValue } = props;
    const minScore = fieldValue?.rankingToResults[index]?.minScore;
    const resultName = fieldValue?.rankingToResults[index]?.result?.label;

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {minScore}
            </TableCell>
            <TableCell>{resultName}</TableCell>
        </TableRow>
    );
};

export default QuestionnaireRankingToResult;
