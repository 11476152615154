import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useField } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import Forms from "./Forms";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { getErrorAndMessagesUseField } from "customHooks/useCompleoForm/Inputs/util/general";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

export type FieldOptionsType = "show" | "hidden";

interface IProps {
    label?: string;
    metadata: any;
    fieldNameTrans?: string;
    helperTextDefault?: string;
}

const FormData = (props: IProps) => {
    const classes = useStyles();
    const { metadata } = props;
    const [field, meta, helpers] = useField(metadata.fieldName);
    const [selectAll, setSelectAll] = React.useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fields = field.value?.fields;
        for (const fieldName of Object.keys(field.value?.fields)) {
            field.value.fields[fieldName].fieldOptions = event.target.checked
                ? "show"
                : "hidden";
        }
        helpers.setValue({ ...field.value, fields: fields });
        setSelectAll(event.target.checked);

        // setState({ ...state, [event.target.name]: event.target.checked });
    };
    const arrayData = Object.keys(field.value?.fields || {}).map(
        (fieldName) => field.value.fields[fieldName]
    );

    React.useEffect(() => {
        const allSelected = isAllSelected(field.value);
        if (allSelected !== selectAll) {
            setSelectAll(allSelected);
        }
    }, [arrayData]);

    const { error, message } = getErrorAndMessagesUseField(
        meta.error,
        meta.touched,
        props.helperTextDefault
    );

    const modalModuleName = "APPLICANTDATAVIEWSETTINGSFIELDDATA";
    const [t, i18n, readyTranslation] = useTranslation(modalModuleName, {
        useSuspense: false
    });
    const [tapplicantMain, , readyTranslationApplicant] = useTranslation(
        "APPLICANTVIEW",
        {
            useSuspense: false
        }
    );
    // const currentLanguage = i18n.languages[0];
    // const tapplicant = (keyTrans: string) => {
    //     const transLocal =
    //         (fieldTranslation.value || {})[currentLanguage] || {};
    //     return transLocal[keyTrans] || tapplicantMain(keyTrans);
    // };

    const ready =
        readyTranslation &&
        readyTranslationApplicant &&
        field.value?.forms &&
        field.value?.fields;

    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;

        if (destination) {
            if (type === "forms") {
                if (destination.index === source.index) {
                    return;
                }
                const forms = [...field.value.forms];
                const item = forms.filter((i: any) => i.id === draggableId)[0];
                forms.splice(source.index, 1);
                forms.splice(destination.index, 0, item);
                const reorderedForms = forms.map((item: any, index: number) => {
                    return { ...item, order: index + 1 };
                });
                helpers.setValue({ ...field.value, forms: reorderedForms });
            } else {
                if (
                    destination.index === source.index &&
                    destination.droppableId === source.droppableId
                ) {
                    return;
                }
                const fields = [...field.value.fields];
                const fieldsSource = fields.filter(
                    (item: any) => item.formGroupId === source.droppableId
                );
                const fieldsDestination = fields.filter(
                    (item: any) => item.formGroupId === destination.droppableId
                );
                const item = fields.filter(
                    (i: any) => i.fieldName === draggableId
                )[0];

                const newFields = fields.filter(
                    (item: any) =>
                        item.formGroupId !== source.droppableId &&
                        item.formGroupId !== destination.droppableId
                );

                fieldsSource.splice(source.index, 1);
                if (destination.droppableId !== source.droppableId) {
                    fieldsDestination.splice(destination.index, 0, item);
                    fieldsDestination.map((field: any) =>
                        newFields.push({
                            ...field,
                            formGroupId: destination.droppableId
                        })
                    );
                } else {
                    fieldsSource.splice(destination.index, 0, item);
                }
                fieldsSource.map((field: any) => newFields.push(field));
                const reorderedFields = newFields.map(
                    (item: any, index: number) => {
                        return { ...item, order: index + 1 };
                    }
                );
                helpers.setValue({ ...field.value, fields: reorderedFields });
            }
        }
    };

    if (ready) {
        return (
            <>
                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={selectAll}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={t("selectAll")}
                    />
                </Grid>
                <List component="nav" className={classes.root}>
                    {error && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {message}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Forms
                            classError={classError}
                            fields={field.value?.fields}
                            forms={field.value?.forms}
                            t={t}
                            field={field}
                            helpers={helpers}
                            meta={meta}
                            tapplicant={tapplicantMain}
                        />
                    </DragDropContext>
                </List>
            </>
        );
    } else {
        return <Loading />;
    }
};

const isAllSelected = (value: Compleo.IObject) => {
    let isAllSelected = true;
    for (const fieldName of Object.keys(value?.fields || {})) {
        if (value.fields[fieldName].fieldOptions !== "show") {
            isAllSelected = false;
        }
    }
    return isAllSelected;
};

export default FormData;
