import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
interface IProps {
    open: boolean;
    setOpen: any;
    t: any;
}

const FormDialog = (props: IProps) => {
    const { open, setOpen, t } = props;
    const [value, setValue] = React.useState<Compleo.IObject | null>(null);
    const { user, company } = useAuthState();
    const [templates, setTemplates] = React.useState<Compleo.IObject[]>([]);
    const {
        getFieldValue,
        setFieldValue
    } = useRhfFieldWithoutControllerOrRegister("applicationForm");
    const fieldValue = getFieldValue();

    const handleClose = () => {
        setOpen(false);
    };

    const [applicationFormList] = useApiCache(
        "/applicationform/list",
        "post",
        {
            companyId: company.companyId
        },
        false
    );
    const data = applicationFormList.response?.data?.data;

    const handleChangeTemplate = () => {
        const optionSelected = data.filter(
            (d: any) => d.skGS1pk === value?.value
        )[0];
        const newValue = { ...(fieldValue || {}) };
        newValue.name = optionSelected.name;
        newValue.id = optionSelected.skGS1pk;
        newValue.formData = optionSelected.formData;
        newValue.translations = optionSelected.translations;
        setFieldValue(newValue);
        setOpen(false);
    };

    React.useEffect(() => {
        if (data && templates.length === 0) {
            const newData: Compleo.IObject[] = [];
            data.map((d: any) => {
                newData.push({ name: d.name, value: d.skGS1pk });
                if (d.default === true) {
                    setValue({ name: d.name, value: d.skGS1pk });
                }
            });
            setTemplates(newData);
        }
    }, [data]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={t("chooseTemplateTitle")}
            >
                <DialogTitle id="form-dialog-title">
                    {t("chooseTemplateTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("chooseTemplateInstructions")}
                    </DialogContentText>
                    <Autocomplete
                        id="applicationFormTemplate"
                        options={templates}
                        value={value}
                        getOptionLabel={(option) => option.name}
                        disableClearable
                        // style={{ width: 300 }}
                        onChange={(event: any, newValue: any) =>
                            setValue(newValue)
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("applicationFormTemplate")}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t("buttonNoChooseAnotherTemplate")}
                    </Button>
                    <Button onClick={handleChangeTemplate} color="primary">
                        {t("buttonYesChooseAnotherTemplate")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormDialog;
