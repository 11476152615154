import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    useRhfFieldWithoutControllerOrRegister,
    IInputProps
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import useCompleoScreeningQuestionnaire, {
    IUseCompleoScreeningQuestionnaireParams
} from "../useCompleoScreeningQuestionnaire";
import { Box, Button } from "@material-ui/core";
import SimulationModal from "./SimulationModal";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

interface IProps {
    t: any;
    questionnaireFieldName?: string;
}

const Simulation = (props: IProps & IInputProps) => {
    const { t, questionnaireFieldName } = props;
    const [open, setOpen] = React.useState(false);
    const allData = useRhfFieldWithoutControllerOrRegister("questions");
    const values = !questionnaireFieldName
        ? allData.getValues()
        : allData.getValues(questionnaireFieldName);

    const params: IUseCompleoScreeningQuestionnaireParams = {
        jobScreeningQuestionnaireData: values as CompleoShared.Questionnaires.IQuestionnaire,
        jobData: {
            title: "Simulação",
            pk: "JOB:SIMULATION",
            skGS1pk: "ASFD"
        },
        postMethod: () => {},
        t: t,
        isSimulation: true
    };
    const questions: Compleo.IObject[] = values?.questions || [];

    if (questions.length) {
        return (
            <Box mt={2} width="100%">
                <Button onClick={() => setOpen(true)} variant="outlined">
                    {t("clickToSimulate")}
                </Button>
                {open && (
                    <SimulationModal
                        open={open}
                        screeningQuestionnaireParms={params}
                        setOpen={setOpen}
                        t={t}
                    />
                )}
            </Box>
        );
    } else {
        return (
            <Box mt={2} width="100%">
                <Alert style={{ width: "100%" }} severity="info">
                    {t("messageWarningAddQuestions")}
                </Alert>
            </Box>
        );
    }
};
export default Simulation;
