import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import theme from "theme/Theme";
import {
    IJobViewStages,
    IJobViewStagesActionDefinition
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import { pipelineAllActionsFields } from "Pages/Pipeline/components/helperPipeline";
import { useApiCache } from "customHooks/useApi";
import JobKanbanApplicantChangeStageModal from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/JobKanbanApplicantChangeStageModal";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useJobChangeStageFunctionParam from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import ChangeStageButton from "./ChangeStageButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }
    })
);

interface IProps {
    JobData: Compleo.IObject;
    t: any;
    index: number;
}

const ChangeStageButtonOutsideJobContainer = (props: IProps) => {
    const { t, index, JobData } = props;
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { language } = data;
    const { isInsideKanban } = data;
    // const [jobDataContext] = useJobContext();
    // const stages = jobDataContext.LocalStateData.allStages;
    const keysToInvalidate: string[] = [
        data.queryKey,
        "/applicant/list",
        "/job/applicantlistjobview",
        "/applicant/applicanthistorylist"
    ];
    const [stages, setStages] = React.useState<IJobViewStages[]>([]);
    const postAddressSearch = `/job/search`;
    const [getJobSearchResult, getJobSearch] = useApiCache(
        postAddressSearch,
        "post",
        { id: JobData.JobId, companyId: data.companyId, searchForEdit: true },
        false,
        1000 * 60 * 5,
        false
    );

    React.useEffect(() => {
        if (getJobSearchResult.status === "success" && !stages.length) {
            const dbStages =
                getJobSearchResult.response?.data?.mainData?.pipeline?.stages ||
                [];
            const stagesToUpdate: IJobViewStages[] = dbStages.map(
                (item: any) => {
                    const actionsStage: IJobViewStagesActionDefinition = {};
                    for (const actionName of pipelineAllActionsFields) {
                        if (item[actionName]) {
                            actionsStage[actionName] = item[actionName];
                        }
                    }
                    const obj: IJobViewStages = {
                        id: item.id,
                        name: item.name,
                        fixed: item.fixed,
                        order: item.order,
                        type: item?.type?.value || "",
                        maxDaysInStage: item?.maxDaysInStage,
                        maxItensKanban: item?.maxItensKanban
                    };
                    if (Object.keys(actionsStage).length) {
                        obj.actions = actionsStage;
                    }

                    return obj;
                }
            );
            stagesToUpdate.sort((a, b) => a.order - b.order);
            setStages(stagesToUpdate);
        }
    }, [getJobSearchResult.status]);

    const ApplicantData = {
        ...data.applicantData,
        jobData: JobData
    };
    const stage =
        JobData.Stage ||
        stages.filter((stage: any) => stage.id === JobData.PipelineStage)[0];

    const readOnly = JobData.status !== "open";

    if (stages.length) {
        return (
            <ChangeStageButton
                applicantData={ApplicantData}
                currentStage={stage}
                stages={stages}
                jobData={JobData}
                language={language}
                listKeyToInvalidate={keysToInvalidate}
                currentIndex={index}
                outsideKanban={true}
                fontSize={13}
                showAsTag={true}
                t={t}
                readonly={readOnly}
            />
        );
    } else {
        return null;
    }
};

export default ChangeStageButtonOutsideJobContainer;
