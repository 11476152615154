import React from "react";
// import TextField from "@material-ui/core/TextField";
// import { FieldProps, useField } from "formik";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";
import _ from "lodash";
import RichTextToolbarCodeEditor from "./RichTextToolbarCodeEditor";
import { getToolbarRichText } from "./RichTextHelper";
import { useLanguageState } from "_ReactContext/LanguageContext";
import { getErrorAndMessagesUseField } from "../util/general";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IStyleProps {
    height: string;
    minHeight: string;
    maxHeight: string;
}

export interface IRichTextConfig {
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    enableCodeButton?: boolean;
    toolbarType?: "min" | "full" | "jobDescription";
}
interface IProps {
    // label: string;
    // helperTextDefault?: null | string;
    // required: boolean;
    // metadata: {
    //     [x: string]: any;
    //     richTextConfig?: IRichTextConfig;
    // };
    toolbarCustomButtons?: any[];
    // t: any;
    mention?: any;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => {
    const colorText = theme.palette.grey[400];
    const colorError = theme.palette.error.main;

    return createStyles({
        wrapper: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorText} !important`,
            marginTop: theme.spacing(1)
        },
        wrapperError: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorError} !important`,
            marginTop: theme.spacing(1)
        },
        label: {
            fontSize: 12
        },
        editorText: {
            height: (props) => `${props.height} !important`,
            minHeight: (props) => `${props.minHeight} !important`,
            maxHeight: (props) => `${props.maxHeight} !important`
        },
        formControl: {
            marginTop: theme.spacing(2),
            width: "100%"
        }
    });
});

const RichText = (props: IProps & IInputProps) => {
    const {
        label,
        helperTextDefault,
        required,
        metadata,
        toolbarCustomButtons = [],
        t,
        mention,
        ...other
    } = props;
    // const [field, meta, helpers] = useField(metadata.fieldName);'

    const {
        error,
        message,
        setFieldValue,
        field,
        setError
    } = useRhfFieldControlled(metadata.fieldName, helperTextDefault);

    const [localValue, setLocalValue] = React.useState();
    const language = useLanguageState();
    const languageDraftJS = language.substring(0, 2);

    const richTextConfig: IRichTextConfig = metadata?.richTextConfig || {};
    const {
        height = "80%",
        minHeight = "10px",
        maxHeight = "2000px",
        enableCodeButton = false
    } = metadata?.richTextConfig || {};
    const toolbarObj = getToolbarRichText(richTextConfig);

    const classes = useStyles({
        height: height,
        maxHeight: maxHeight,
        minHeight: minHeight
    });

    const customButtonsAvalilabe = [
        {
            name: "code",
            Component: (
                <RichTextToolbarCodeEditor
                    t={t}
                    setLocalValue={setLocalValue}
                />
            )
        }
    ];
    const customButtonsEnabledNames: string[] = [];
    if (enableCodeButton) {
        customButtonsEnabledNames.push("code");
    }
    const CustomButtonsComponentsToAdd = customButtonsAvalilabe
        .filter((item) => customButtonsEnabledNames.includes(item.name))
        .map((item) => item.Component);

    const toolbarCustomButtonsLocal = [
        ...CustomButtonsComponentsToAdd,
        ...toolbarCustomButtons
    ];

    // let mensagemErro =
    //     hasError && errorText !== undefined
    //         ? errorText
    //         : helperTextDefault !== undefined
    //         ? helperTextDefault
    //         : "";
    // const error: boolean = hasError && errorText !== undefined;
    // const { error, message } = getErrorAndMessagesUseField(
    //     meta.error,
    //     meta.touched,
    //     helperTextDefault
    // );

    let value: any = "";
    if (localValue === undefined) {
        let sourceValue = "";
        if (typeof field.value === "string") {
            sourceValue = field.value;
        }
        const contentBlock = htmlToDraft(sourceValue);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            value = editorState;
        } else {
            value = EditorState.createEmpty();
        }
    } else {
        value = localValue;
    }

    return (
        <FormControl className={classes.formControl}>
            <FormLabel
                className={classes.label}
                htmlFor={field.name}
                aria-label={label}
                required={required}
                error={error}
            >
                {label}
            </FormLabel>
            <Editor
                toolbar={toolbarObj}
                editorState={value}
                editorClassName={classes.editorText}
                onBlur={(event: any) => {
                    const newValue = draftToHtml(
                        convertToRaw(value.getCurrentContent())
                    );

                    // Limpa tags html vazias para validação de conteúdo
                    const clearHtml = newValue
                        .replace(/<[^/>][^>]*><\/[^>]+>/gim, "")
                        .trim();
                    setFieldValue(clearHtml);
                    // helpers.setTouched(true);
                    field.onBlur();
                }}
                onEditorStateChange={(editorState: any) => {
                    setLocalValue(editorState);
                }}
                wrapperClassName={
                    error ? classes.wrapperError : classes.wrapper
                }
                toolbarCustomButtons={toolbarCustomButtonsLocal}
                localization={{
                    locale: languageDraftJS
                }}
                mention={mention}
                editorRef={field.ref}
            />
            <FormHelperText error={error} id={`helper_${field.name}`}>
                {message}
            </FormHelperText>
        </FormControl>
    );
};

export default RichText;
