import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import { useAuthState } from "_ReactContext/AuthContext";
import useNextPageFunctionsNewCustomer from "./components/useNextPageFunctionsNewCustomer";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";

const CustomerNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/customer/list");
    const classes = useFormStyles();
    const [t, i18n, readyTranslation] = useTranslation("customernew", {
        useSuspense: false
    });

    const { company, timeZone } = useAuthState();

    const nextPageFunctions = useNextPageFunctionsNewCustomer(
        company.companyId,
        t
    );

    const postAddress = `/customer/new`;

    const [addCustomerReturn, addCustomer] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata("CUSTOMERNEW");

    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.deleted = false;

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addCustomer,
        postReturn: addCustomerReturn,
        redirectAddress: "/customer/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        nextPageFunctions: nextPageFunctions,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomerNew;
