import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormHelperText from "@material-ui/core/FormHelperText";
import Forms from "./Forms";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Box, Button, Typography } from "@material-ui/core";
import { fieldsRules } from "./helper";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { ButtonGroupFieldApplicantForm } from "./Fields";
import { useRhfField } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

export type FieldOptionsType = "required" | "optional" | "hidden";

interface IProps {
    label?: string;
    metadata: any;
    fieldNameTrans?: string;
    helperTextDefault?: string;
}

const FormData = (props: IProps) => {
    const classes = useStyles();
    const { metadata, fieldNameTrans } = props;
    const { setFieldValue, error, message, watch } = useRhfField(
        metadata?.fieldName,
        props.helperTextDefault
    );
    // register(metadata?.fieldName);
    const fieldValue = watch(metadata?.fieldName);

    const { getFieldValue: getFieldTranslationValue } = useRhfField(
        fieldNameTrans || "translations"
    );
    const fieldTranslationValue = getFieldTranslationValue();
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const modalModuleName = "APPLICATIONFORMFIELDDATA";
    const [t, i18n, readyTranslation] = useTranslation(modalModuleName, {
        useSuspense: false
    });
    const [tapplicantMain, , readyTranslationApplicant] = useTranslation(
        "APPLICANTNEW",
        {
            useSuspense: false
        }
    );
    const currentLanguage = i18n.languages[0];
    const tapplicant = (keyTrans: string) => {
        const transLocal = (fieldTranslationValue || {})[currentLanguage] || {};
        return transLocal[keyTrans] || tapplicantMain(keyTrans);
    };

    const ready =
        readyTranslation &&
        readyTranslationApplicant &&
        fieldValue?.forms &&
        fieldValue?.fields;

    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;

        if (destination) {
            if (type === "forms") {
                if (destination.index === source.index) {
                    return;
                }
                const forms = [...fieldValue.forms];
                const item = forms.filter((i: any) => i.id === draggableId)[0];
                forms.splice(source.index, 1);
                forms.splice(destination.index, 0, item);
                const reorderedForms = forms.map((item: any, index: number) => {
                    return { ...item, order: index + 1 };
                });
                setFieldValue({ ...fieldValue, forms: reorderedForms });
                // helpers.setValue({ ...field.value, forms: reorderedForms });
            } else {
                if (
                    destination.index === source.index &&
                    destination.droppableId === source.droppableId
                ) {
                    return;
                }
                const fields = [...fieldValue.fields];
                const fieldsSource = fields.filter(
                    (item: any) => item.formGroupId === source.droppableId
                );
                const fieldsDestination = fields.filter(
                    (item: any) => item.formGroupId === destination.droppableId
                );
                const item = fields.filter(
                    (i: any) => i.fieldName === draggableId
                )[0];

                const newFields = fields.filter(
                    (item: any) =>
                        item.formGroupId !== source.droppableId &&
                        item.formGroupId !== destination.droppableId
                );

                fieldsSource.splice(source.index, 1);
                if (destination.droppableId !== source.droppableId) {
                    fieldsDestination.splice(destination.index, 0, item);
                    fieldsDestination.map((field: any) =>
                        newFields.push({
                            ...field,
                            formGroupId: destination.droppableId
                        })
                    );
                } else {
                    fieldsSource.splice(destination.index, 0, item);
                }
                fieldsSource.map((field: any) => newFields.push(field));
                const reorderedFields = newFields.map(
                    (item: any, index: number) => {
                        return { ...item, order: index + 1 };
                    }
                );
                setFieldValue({ ...fieldValue, fields: reorderedFields });
                // helpers.setValue({ ...field.value, fields: reorderedFields });
            }
        }
    };

    const setAllFields = (actionType: FieldOptionsType) => {
        callDialog({
            title: t("titleChangeAll"),
            bodyText: t("messageChangeAll"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const fields = [...fieldValue.fields];
                const newFields = fields.map((field: any) => {
                    if (!fieldsRules.requiredFields.includes(field.fieldName)) {
                        return { ...field, fieldOptions: actionType };
                    } else {
                        return field;
                    }
                });
                setFieldValue({ ...fieldValue, fields: newFields });
                // helpers.setValue({ ...field.value, fields: newFields });
            },
            disagreeFunction: () => {}
        });
    };

    if (ready) {
        return (
            <>
                <Box width="100%" ml={1} pl={4} pr={4} mt={2}>
                    <Box mb={1}>
                        <Typography variant="body2">
                            {t("titleChangeAllDefault")}
                        </Typography>
                    </Box>
                    <Box>
                        <ButtonGroupFieldApplicantForm
                            fieldName="all"
                            handleFieldOption={setAllFields}
                            requiredRule={false}
                            t={t}
                            variantButtonHidden="outlined"
                            variantButtonOptional="outlined"
                            variantButtonRequired="outlined"
                        />
                    </Box>
                </Box>
                <List component="nav" className={classes.root}>
                    {error && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {message}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Forms
                            classError={classError}
                            fields={fieldValue?.fields}
                            forms={fieldValue?.forms}
                            t={t}
                            fieldValue={fieldValue}
                            setFieldValue={setFieldValue}
                            tapplicant={tapplicant}
                        />
                    </DragDropContext>
                </List>
            </>
        );
    } else {
        return <Loading />;
    }
};

export default FormData;
