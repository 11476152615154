import React from "react";
import TextField from "@material-ui/core/TextField";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

const FTextFieldControlled = (props: IInputProps) => {
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;
    const { error, message, field } = useRhfFieldControlled(
        name,
        helperTextDefault
    );

    return (
        <TextField
            label={label}
            // id={name}
            error={error}
            helperText={message}
            {...other}
            inputProps={{
                maxLength: maxLength
            }}
            {...field}
            inputRef={field.ref}
        />
    );
};

export default FTextFieldControlled;
