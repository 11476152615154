import React from "react";
import TextField from "./TextField";
import { FieldProps } from "formik";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    maxLength?: number;
}

const URLField = (props: IProps & FieldProps) => {
    const onChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
        let url = event.target.value;
        if (!/^https?:\/\//i.test(event.target.value)) {
            if (url.toLowerCase() === "https:/") {
                url = "https://";
            } else {
                url = "https://" + url;
            }
        }
        if (url === "https://" || url === "http://") {
            url = "";
        }
        props.form.setFieldValue(props.field.name, url);
    };

    return <TextField {...props} onChange={onChangeMethod} />;
};

export default URLField;
