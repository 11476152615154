import React from "react";
import ItemViewInline from "./ItemView";
import Typography from "@material-ui/core/Typography";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "@gatsbyjs/reach-router";
import _ from "lodash";
import { Box, Divider, Grid } from "@material-ui/core";

function createMarkup(data: string) {
    return { __html: data };
}

const FieldHtml = (props: IFieldProps) => {
    const { fieldData, fieldName, fieldDef, t, language, url } = props;
    const showLabel = fieldDef?.elementConfig?.showLabel === true;

    const data = transformDBData({
        value: _.get(fieldData, fieldName),
        t,
        language,
        replaceOption: showLabel ? "" : `<p>-</p>`
    });

    return (
        <Grid
            item
            xs={props.fieldDef?.columnSize?.xs || 12}
            sm={props.fieldDef?.columnSize?.sm}
            md={props.fieldDef?.columnSize?.md}
            lg={props.fieldDef?.columnSize?.lg}
            container
            alignItems="center"
            justify="flex-start"
            // className={classes.gridItem}
        >
            {showLabel && (
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {t(fieldName, "")}:
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 8 }}>
                        <Divider />
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                <div dangerouslySetInnerHTML={createMarkup(data)} />
            </Grid>
        </Grid>
    );
};

export default FieldHtml;
