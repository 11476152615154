import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import { useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useFormikCallBack from "./components/useFormikCallBack";
import { usePlanLimitsPage } from "customHooks/usePlanLimits/usePlanLimits";

const CareersSettingsNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/careers");
    const moduleName = "CAREERSSETTINGSNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/careerssettings/new`;
    const [customLists, readyLists] = useCustomLists();

    const [addDataReturn, addData] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.main = false;
    const { fieldsToHide, formikReturn } = useFormikCallBack();
    const { limitReached } = usePlanLimitsPage(t, "/careers", "addCareersSite");

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation && readyLists,
        i18nV: i18n,
        postMethod: !limitReached ? addData : undefined,
        postReturn: addDataReturn,
        redirectAddress: "/careers",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        formikEnableReinitialize: false,
        customLists: customLists,
        fieldsToHide: fieldsToHide,
        formikReturn: formikReturn
    });
    const ready = readyTranslation && finished && readyLists;

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CareersSettingsNew;
