import React from "react";
import Autocomplete, {
    AutocompleteProps,
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import { getControlOptionsAndChildrenFilter } from "./util/AutoCompleteUtil";
import _ from "lodash";
import { getErrorAndMessages } from "./util/general";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    options: any;
    campoMetadata: Compleo.IObject;
    customLists: Compleo.IObject[];
    required: boolean;
    freeSolo?: boolean;
    language?: string;
    elementConfig?: Compleo.IObject;
    inputProps?: any;
}

const Tags = (props: IProps & FieldProps) => {
    const {
        freeSolo,
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue, values },
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        ...other
    } = props;
    const freeSoloControl = freeSolo === undefined ? false : freeSolo;
    const language = props.language || "pt-BR";

    const { error, message } = getErrorAndMessages(
        errors,
        field.name,
        touched,
        status,
        helperTextDefault
    );

    let defaultValue: Compleo.IObject[] = [];

    const valueFromFormik = _.get(values, field.name);
    const controlValue =
        (valueFromFormik || "") === "" ? defaultValue : valueFromFormik;

    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilter(
        campoMetadata,
        options,
        customLists,
        field,
        values,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;
    const childrenFilterFieldName =
        controlOptionsAndChildrenFilter.childrenFilterFieldName;

    const onChangeMethod = (event: any, newValue: any | null) => {
        setFieldValue(field.name, newValue);
    };

    return (
        <Autocomplete
            freeSolo={freeSoloControl}
            multiple
            id={field.name}
            value={controlValue}
            options={controlOptions}
            filterSelectedOptions
            getOptionSelected={(option, value) => {
                return value.value === option.value;
            }}
            getOptionLabel={(option: any) =>
                option.label || option[`label-${language}`] || ""
            }
            onChange={onChangeMethod}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    helperText={message}
                    // margin="normal"
                />
            )}
        />
    );
};

export default Tags;
