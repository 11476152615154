import { createStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import useList from "customHooks/useList";
import {
    getCustomSearchOrderedLocal,
    getOrderedObjectNamesByLocal
} from "customHooks/useList/ListHelpers/ListUtil";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFields from "./TableFields";
// import OtherActions from "../OtherActions";
// import StatusActions from "../StatusActions";
import TableResultsRow, {
    ModulesWithTableActions,
    ModulesWithTableActionsList
} from "./TableResultsRow";
import useGlobalValues from "_ReactContext/GlobalValues";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
            // margimTop: theme.spacing(1.5),
            // margimBottom: theme.spacing(1.5)
        }
    })
);

interface IProps {}

const TableResults = (props: IProps) => {
    const classes = useStyles();
    const [listData] = useList();
    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const { t, language } = listData.tempInfo;
    const { fields } = listData.listReturnInfo;
    const { tableFields, otherSettings } =
        listData.definitionInfo.elasticDefData?.data || {};

    const sessionName = `tableFieldsOrder_${listData.tempInfo.moduleName}`;
    const orderData = globalValues.userdb[sessionName];
    const tableFieldsDetail = getCustomSearchOrderedLocal(
        tableFields || {},
        orderData
    );
    const orderedFieldsPrimary = tableFieldsDetail
        .filter((item) => item.local === "primary")
        .sort((a, b) => a.order - b.order)
        .map((item) => item.name);

    const orderedFieldsSecondary = tableFieldsDetail
        .filter((item) => item.local === "secondary")
        .sort((a, b) => a.order - b.order)
        .map((item) => item.name);

    const jobs = fields;
    const maxItemsPrimary =
        (otherSettings || {}).tableMaxFieldsToShowPrimary || 4;
    const maxItemsSecondary =
        (otherSettings || {}).tableMaxFieldsToShowSecondary || 4;

    const showActionsLine =
        orderedFieldsSecondary.length > 0 ||
        ModulesWithTableActionsList.includes(
            listData.tempInfo.moduleName as ModulesWithTableActions
        );

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {showActionsLine && (
                            <TableCell style={{ fontWeight: 600 }}>
                                {t("tableActions")}
                            </TableCell>
                        )}
                        {orderedFieldsPrimary
                            .slice(0, maxItemsPrimary)
                            .map((item) => (
                                <TableCell
                                    style={{ fontWeight: 600 }}
                                    key={item}
                                >
                                    {t(item)}
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field: any) => (
                        <TableResultsRow
                            key={`${field.pk}${field.skGS1pk}`}
                            fieldData={field}
                            maxItemsPrimary={maxItemsPrimary}
                            maxItemsSecondary={maxItemsSecondary}
                            orderedFields={orderedFieldsPrimary}
                            orderedFieldsSecondary={orderedFieldsSecondary}
                            tableFields={tableFields}
                            t={t}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableResults;
