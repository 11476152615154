import {
    createLocalUrlFromS3,
    IS3ExternalData
} from "customHooks/useCompleoForm/Inputs/File/File";
import { getExtension } from "functions/util";
import React from "react";
import ModalViewFile, { AllowedExtensionsViewFile } from "./ModalViewFile";

interface IFileData {
    extension: AllowedExtensionsViewFile;
    path: string;
    isLocalStreamUrl?: boolean;
    t: any;
    externalData?: IS3ExternalData;
}
const useModalViewFile = (t: any) => {
    const [open, setOpen] = React.useState(false);
    const [fileData, setFileData] = React.useState<IFileData>({
        extension: "blank",
        path: "",
        t: t
    });
    const ModalComponentViewFile = (
        <ModalViewFile
            extension={fileData.extension}
            open={open}
            handleClose={() => setOpen(false)}
            path={fileData.path}
            t={t}
            isLocalStreamUrl={fileData.isLocalStreamUrl}
        />
    );

    const viewFile = (
        path: string,
        extension: AllowedExtensionsViewFile,
        isLocalStreamUrl?: boolean
    ) => {
        setFileData({
            extension: extension,
            path: path,
            t: t,
            isLocalStreamUrl: isLocalStreamUrl
        });
        setOpen(true);
    };

    const viewFileS3Key = async (
        key: string,
        externalData?: IS3ExternalData
    ) => {
        const extension: AllowedExtensionsViewFile = getExtension(
            key
        ) as AllowedExtensionsViewFile;
        const url = await createLocalUrlFromS3(key, false, externalData);
        setFileData({
            extension: extension,
            path: url.url,
            t: t,
            isLocalStreamUrl: true
        });
        setOpen(true);
    };

    return {
        ModalComponentViewFile,
        viewFile: viewFile,
        viewFileS3Key: viewFileS3Key,
        open: open
    };
};

export default useModalViewFile;
