import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// import { handleChangeBucketsFilter } from "./ListUtil";
import useList from "../..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            padding: theme.spacing(0.1),
            margin: theme.spacing(0.1),
            paddingLeft: theme.spacing(4)
        },
        listItem: {
            minWidth: theme.spacing(0.1),
            padding: 0,
            margin: 0
        },
        checkBox: {
            padding: theme.spacing(0.2),
            paddingRight: theme.spacing(0.4)
            // margin: theme.spacing(1)
        }
    })
);

interface IPropsListItem {
    item: Compleo.IObject;
    changeItemChecked: any;
}

const BulkActionsListItem = React.memo((props: IPropsListItem) => {
    const classes = useStyles();
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { setFilters } = listDispatch;

    const { item, changeItemChecked } = props;

    const handleChange = () => {
        const checked = !item.checked;
        changeItemChecked(item.value, checked);
    };

    return (
        <ListItem
            className={classes.nested}
            style={{ width: "98%" }}
            button
            onClick={handleChange}
        >
            <ListItemIcon className={classes.listItem}>
                <Checkbox
                    edge="start"
                    checked={item.checked}
                    tabIndex={-1}
                    disableRipple
                    // name={item.value}
                    // onChange={handleChange}
                    className={classes.checkBox}
                    size="small"
                />
            </ListItemIcon>
            <ListItemText className={classes.listItem} secondary={item.label} />
        </ListItem>
    );
});

export default BulkActionsListItem;
