import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from "@material-ui/lab";
import { getExtension } from "functions/util";

interface IFileModalEditTags {
    open: boolean;
    handleClose: () => void;
    t: any;
    tags: string[];
    editTags: (
        name: any,
        tags: string[],
        newFileName?: string | undefined
    ) => void;
    name: string;
}

const FileModalEditTags = (props: IFileModalEditTags) => {
    const { open, handleClose, t, tags, editTags, name } = props;
    const [value, setValue] = React.useState<string[]>(tags);

    const defaultFileName = name.replace(`.${getExtension(name)}`, "");
    const [valueName, setValueName] = React.useState<string>(defaultFileName);

    React.useEffect(() => {
        if (open) {
            setValue(tags);
        }
    }, [open]);

    const handleSave = () => {
        // Futuramente avaliar possibilidade de altera o nome do arquivo
        // necessário vários testes
        // editTags(name, value, valueName);
        editTags(name, value);
        handleClose();
    };
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueName(event.target.value);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    {t("CGT_editTags")}
                </DialogTitle>
                <DialogContent>
                    {/*     <TextField
                        id="outlined-name"
                        label={t("CGT_fileName")}
                        value={valueName}
                        onChange={handleChangeName}
                        variant="standard"
                        fullWidth
                    /> */}
                    <Autocomplete
                        value={value}
                        onChange={(event: any, newValue: any) => {
                            setValue(newValue);
                        }}
                        freeSolo
                        multiple
                        fullWidth
                        id="tags-standard"
                        options={[]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t("CGT_tags")}
                                placeholder={t("CGT_tags")}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="text"
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FileModalEditTags;
