interface IHandleListData {
    data: Compleo.IObject[];
    currentUser: string;
    returnAllUsers?: boolean;
}
export const handleEvaluationListData = (params: IHandleListData) => {
    const { data, currentUser, returnAllUsers = true } = params;
    if (!data.length) {
        return data;
    }
    // debugger;
    const returnData: Compleo.IObject[] = [];

    // get global evaluation current user
    data.filter(
        (item) => item.evaluator === currentUser && item.jobId === "GLOBAL"
    ).map((item) => {
        returnData.push(item);
    });

    // get global evaluation other users
    data.filter(
        (item) => item.evaluator !== currentUser && item.jobId === "GLOBAL"
    )
        .sort((a, b) => b.generalEvaluation - a.generalEvaluation)
        .map((item) => {
            returnData.push(item);
        });

    // get job evaluation current user
    data.filter((item) => item.jobId !== "GLOBAL")
        .sort((a, b) => a.jobId.localeCompare(b.jobId))
        .map((item) => {
            returnData.push(item);
        });

    // get global evaluation other users
    // data.filter(
    //     (item) => item.evaluator !== currentUser && item.jobId !== "GLOBAL"
    // )
    //     .sort((a, b) => {
    //         const aSize = a.jobId;
    //         const bSize = b.jobId;
    //         const aLow = a.generalEvaluation;
    //         const bLow = b.generalEvaluation;

    //         if (aSize === bSize) {
    //             return aLow < bLow ? -1 : aLow > bLow ? 1 : 0;
    //         } else {
    //             return aSize < bSize ? -1 : 1;
    //         }
    //     })
    //     .map((item) => {
    //         returnData.push(item);
    //     });

    console.log("returnData", returnData);
    return returnData;
};

export interface IAddMainAndGroupRatingScorecardReturn {
    scorecard: Compleo.IObject[];
    averageMainRating: number;
}

export type IAddMainAndGroupRatingScorecard = (
    scorecard: Compleo.IObject[]
) => IAddMainAndGroupRatingScorecardReturn;

export const addMainAndGroupRatingScorecard: IAddMainAndGroupRatingScorecard = (
    scorecard: Compleo.IObject[]
) => {
    if ((scorecard || []).length == 0) {
        return { scorecard, averageMainRating: 0 };
    }
    const sections = scorecard.map((item) => item);
    const sectionsItems = sections.map((item) => item.items);
    const sectionItemsFlat: Compleo.IObject[] = [].concat.apply(
        [],
        sectionsItems
    );
    const allRatings: number[] = sectionItemsFlat.map(
        (item) => item.evaluation
    );
    const sum = allRatings.reduce((a, b) => a + b, 0);
    const averageMainRating = sum / allRatings.length || 0;

    for (let index = 0; index < scorecard.length; index++) {
        // const sectionsItemsLocal = scorecard[index].items.map(
        //     (item: Compleo.IObject) => item
        // );
        const sectionRatings: number[] = scorecard[index].items.map(
            (item: Compleo.IObject) => item.evaluation
        );
        const sumSection = sectionRatings.reduce((a, b) => a + b, 0);
        const averageSectionRating = sumSection / sectionRatings.length || 0;
        scorecard[index].averageRating = averageSectionRating;
    }
    return { scorecard, averageMainRating };
};
