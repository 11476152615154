import { Box, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column"
            // width: "100%"
        }
    })
);

const JobDefaultExamplePage = (props: any) => {
    const classes = useStyles();

    return <Box className={classes.container}>Example page</Box>;
};

export default JobDefaultExamplePage;
