import React from "react";
import {
    DraggableLocation,
    DragStart,
    DropResult,
    ResponderProvided
} from "react-beautiful-dnd";
import useJobContext from "../useJobContext";
import { useQueryClient } from "react-query";
import {
    getApplicantsByStage,
    getStateFromDB,
    getTotalFilteredApplicantsByStage,
    getTotalStage,
    getTotalStageFromDb,
    handleOrderData
} from "./helper";
import _ from "lodash";
import {
    IApplicantsData,
    IMainStateAction,
    IOrderData
} from "../useJobContext/JobViewTypes";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import useDebounce from "customHooks/useDebounce";
import useRefreshKanban from "./useRefreshKanban";

const getOrdered = (
    orderData: IOrderData[],
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    if (destination.droppableId === source.droppableId) {
        return getOrderedSameColumn(
            orderData,
            source,
            destination,
            draggableId
        );
    } else {
        return getOrderedOtherColumn(
            orderData,
            source,
            destination,
            draggableId
        );
    }
};

const getOrderedSameColumn = (
    orderData: IOrderData[],
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    // debugger;
    const sourceApplicants = orderData.filter(
        (item) => item.stageId === source.droppableId
    );
    const applicantsOtherLists = orderData.filter(
        (item) => item.stageId !== source.droppableId
    );

    const applicantToMove = sourceApplicants.filter(
        (item) => item.applicantPK === draggableId.split(":")[1]
    )[0];
    sourceApplicants.splice(source.index, 1);
    sourceApplicants.splice(destination.index, 0, applicantToMove);

    const orderedItems: IOrderData[] = [
        ...sourceApplicants,
        ...applicantsOtherLists
    ].map((item, index) => {
        return { ...item, order: index + 1 };
    });
    orderedItems.sort((a, b) => a.order - b.order);
    // const newOrderData = [...orderedItems, ...applicantsOtherLists];
    return orderedItems;
};

const getOrderedOtherColumn = (
    orderData: IOrderData[],
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    const sourceApplicants = orderData.filter(
        (item) => item.stageId === source.droppableId
    );
    const destinationApplicants = orderData.filter(
        (item) => item.stageId === destination.droppableId
    );
    const applicantsOtherLists = orderData.filter(
        (item) =>
            item.stageId !== source.droppableId &&
            item.stageId !== destination.droppableId
    );

    const applicantToMove = sourceApplicants.filter(
        (item) => item.applicantPK === draggableId.split(":")[1]
    )[0];
    sourceApplicants.splice(source.index, 1);
    destinationApplicants.splice(destination.index, 0, {
        ...applicantToMove,
        stageId: destination.droppableId
    });
    const orderedItemsSource: IOrderData[] = sourceApplicants.map(
        (item, index) => {
            return { ...item, order: index + 1 };
        }
    );
    const orderedItemsDestination: IOrderData[] = destinationApplicants.map(
        (item, index) => {
            return { ...item, order: index + 1 };
        }
    );
    const newOrderData = [
        ...orderedItemsSource,
        ...orderedItemsDestination,
        ...applicantsOtherLists
    ];
    return newOrderData;
};

const newStateDB = (
    stateData: IApplicantsData,
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    debugger;
    if (destination.droppableId === source.droppableId) {
        const currentList =
            stateData[destination.droppableId]?.applicants || [];
        const itemToMove = currentList.filter((item) => {
            if (item?.pk) {
                return item.pk === draggableId;
            } else {
                return false;
            }
        })[0];
        currentList.splice(source.index, 1);
        currentList.splice(destination.index, 0, itemToMove);
        const newStateData: IApplicantsData = { ...stateData };
        newStateData[destination.droppableId].applicants = currentList;
        return newStateData;
    } else {
        const sourceList = stateData[source.droppableId]?.applicants || [];
        const itemToMove = sourceList.filter((item) => {
            if (item?.pk) {
                return item.pk === draggableId;
            } else {
                return false;
            }
        })[0];
        const destinationList =
            stateData[destination.droppableId]?.applicants || [];
        sourceList.splice(source.index, 1);

        destinationList.splice(destination.index, 0, {
            ...itemToMove,
            jobData: {
                ...itemToMove.jobData,
                PipelineStage: destination.droppableId,
                dateAddedToStage: new Date().toISOString()
            }
        });
        const newStateData: IApplicantsData = { ...stateData };
        newStateData[source.droppableId].applicants = sourceList;
        if (newStateData[destination.droppableId]) {
            newStateData[destination.droppableId].applicants = destinationList;
        } else {
            newStateData[destination.droppableId] = {
                applicants: destinationList,
                data: {
                    totalFiltered: { value: 0 },
                    fromValue: 0,
                    pageSize: 0
                }
            };
        }
        // update total
        const currentOldStageFiltered =
            newStateData[source.droppableId]?.data?.totalFiltered?.value || 0;
        const currentNewStageFiltered =
            newStateData[destination.droppableId]?.data?.totalFiltered?.value ||
            0;
        newStateData[source.droppableId].data.totalFiltered = {
            value: currentOldStageFiltered - 1
        };
        newStateData[destination.droppableId].data.totalFiltered = {
            value: currentNewStageFiltered + 1
        };

        return newStateData;
    }
};

interface IStateInvalidateQuery {
    changes: number;
}
const reducer = (
    state: IStateInvalidateQuery,
    action: { type: "increment" | "reset" }
): IStateInvalidateQuery => {
    switch (action.type) {
        case "increment":
            return {
                changes: state.changes + 1
            };
        case "reset":
            return { changes: 0 };
    }
};

const useUpdateCard = () => {
    const [data, dispatchData] = useJobContext();
    const queryClient = useQueryClient();
    const { company, user } = useAuthState();
    const updateAllInfo = useRefreshKanban();
    const { apiKeyOrderData, t } = data;
    const { orderData } = data.LocalStateData;
    // const orderData = orderDB.orderData || [];
    const { JobId } = data;
    const showMessage = useShowMessage();
    const [
        localInvalidateQuery,
        dispatchLocalInvalidateQuery
    ] = React.useReducer(reducer, { changes: 0 });

    const invalidateQuery: IStateInvalidateQuery = useDebounce(
        localInvalidateQuery,
        2000
    );

    React.useEffect(() => {
        if (invalidateQuery.changes > 0) {
            dispatchLocalInvalidateQuery({ type: "reset" });
            // temp removed
            updateAllInfo();
        }
    }, [invalidateQuery.changes]);

    const changeCardStateData = async (
        result: DropResult,
        updateDB: boolean = true
    ) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            const beforeUpdateOrderData = _.cloneDeep(orderData);
            const beforeUpdateApplicantsData = _.cloneDeep(
                data.LocalStateData.ApplicantsData
            );
            const beforestagesOnlyTotal = _.cloneDeep(
                data.LocalStateData.stagesOnlyTotal
            );
            // const cloned`

            const newOrderData = getOrdered(
                _.cloneDeep(orderData),
                source,
                destination,
                draggableId
            );

            debugger;
            const newStateData = newStateDB(
                _.cloneDeep(data.LocalStateData.ApplicantsData),
                source,
                destination,
                draggableId
            );

            const newDispatch: IMainStateAction = {
                payload: {
                    ApplicantsData: newStateData,
                    orderData: newOrderData
                },
                type: "update"
            };

            // update totals
            if (destination.droppableId !== source.droppableId) {
                const stagesTotals = _.cloneDeep(
                    data.LocalStateData.stagesOnlyTotal
                );
                const currentSourceTotalStage = stagesTotals.filter(
                    (itemStage) => itemStage.id === source.droppableId
                )[0];
                const currentDestinationTotalStage = stagesTotals.filter(
                    (itemStage) => itemStage.id === destination.droppableId
                )[0];
                currentSourceTotalStage.total =
                    currentSourceTotalStage.total - 1;
                currentDestinationTotalStage.total =
                    currentDestinationTotalStage.total + 1;
                stagesTotals.filter(
                    (itemStage) => itemStage.id === source.droppableId
                )[0] = currentSourceTotalStage;
                stagesTotals.filter(
                    (itemStage) => itemStage.id === destination.droppableId
                )[0] = currentDestinationTotalStage;
                newDispatch.payload.stagesOnlyTotal = stagesTotals;
            }

            dispatchData.dispatchGeneralPageInfo(newDispatch);
            const postAddress = `/job/editapplicantjobstage`;
            try {
                if (updateDB) {
                    const updateReturn = await apiDirectCall(
                        postAddress,
                        "post",
                        {
                            companyId: company.companyId,
                            jobId: JobId,
                            applicantId: draggableId.split(":")[1],
                            stageId: destination.droppableId,
                            priorityObj: { orderData: newOrderData },
                            oldStageId: source.droppableId,
                            newIndex: destination.index
                        }
                    );
                    const lastDBDate = new Date(
                        updateReturn.data.lastUpdatedAt
                    );
                    const lastLocalDate = new Date(
                        data.LocalStateData.lastUpdateApplicantJobStage
                    );
                    if (
                        updateReturn.status === 200 &&
                        updateReturn.data.lastUpdatedByUser !== user &&
                        lastDBDate > lastLocalDate
                    ) {
                        // for diferente do usuário atual, os dados devem ser atualizados
                        // do banco de dados
                        dispatchLocalInvalidateQuery({
                            type: "increment"
                        });
                    }
                    dispatchData.dispatchGeneralPageInfo({
                        payload: {
                            lastUpdateApplicantJobStage: new Date().toISOString()
                        },
                        type: "update"
                    });
                } else {
                    dispatchLocalInvalidateQuery({
                        type: "increment"
                    });
                    dispatchData.dispatchGeneralPageInfo({
                        payload: {
                            lastUpdateApplicantJobStage: new Date().toISOString()
                        },
                        type: "update"
                    });
                }
            } catch (e) {
                dispatchData.dispatchGeneralPageInfo({
                    payload: {
                        ApplicantsData: beforeUpdateApplicantsData,
                        orderData: beforeUpdateOrderData,
                        stagesOnlyTotal: beforestagesOnlyTotal
                    },
                    type: "update"
                });
                showMessage({
                    message: t("NotIdentifiedProblem"),
                    snackBarOrign: { vertical: "top", horizontal: "right" },
                    timeout: 1000 * 5,
                    typeMessage: "error"
                });
            }
        }
    };

    const onDragEnd = async (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            // debugger;
            if (destination.droppableId === source.droppableId) {
                const totalApplicantsStage = getTotalStage(
                    data.LocalStateData.stagesOnlyTotal,
                    [source.droppableId]
                );
                const totalFiltered = getTotalFilteredApplicantsByStage(data, [
                    source.droppableId
                ]);
                const isFilterApplied = totalFiltered < totalApplicantsStage;
                if (
                    isFilterApplied &&
                    destination.index !== source.index &&
                    destination.index > 0
                ) {
                    showMessage({
                        message: t("Message_MoveCardFilterIndex"),
                        snackBarOrign: { vertical: "top", horizontal: "right" },
                        timeout: 1000 * 5,
                        typeMessage: "error"
                    });
                    return;
                }

                if (destination.index === source.index) {
                    return;
                }
            }
            await changeCardStateData(result, true);
        }

        dispatchData.dispatchGeneralPageInfo({
            payload: {
                moveCardProps: undefined
            },
            type: "update"
        });
    };

    const onDragStart = (initial: DragStart, provider: ResponderProvided) => {
        dispatchData.dispatchGeneralPageInfo({
            payload: {
                moveCardProps: {
                    sourceStage: initial.source.droppableId,
                    sourceIndex: initial.source.index
                }
            },
            type: "update"
        });
    };

    return { onDragEnd, onDragStart, changeCardStateData };
};

export default useUpdateCard;
