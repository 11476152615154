import React, { useState } from "react";
import {
    getMetadataNameFromPipelineStageActionType,
    PipelineActionTypeDefinition
} from "./helperPipeline";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import GenericStageActionsModal, {
    IGenericStageActionsModal
} from "./GenericStageActionsModal";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        }
    })
);

const StageActionCustom = (props: IInputProps) => {
    const { metadata, t } = props;

    const {
        error,
        message,
        field,
        setValue,
        setFieldValue,
        watch
    } = useRhfFieldControlled(metadata.fieldName, props.helperTextDefault);

    const value = field?.value || [];

    const classes = useStyles();
    const [
        genericStageActionsModalData,
        setGenericStageActionsModalData
    ] = React.useState<IGenericStageActionsModal | null>(null);

    const deleteField = () => {
        setFieldValue([]);
    };

    const onCloseGenericModal = () => {
        setGenericStageActionsModalData((currentState) => {
            if (!currentState) {
                return null;
            }
            return { ...currentState, open: false };
        });
    };

    const editAction = (actionToEnable: PipelineActionTypeDefinition) => {
        const module = getMetadataNameFromPipelineStageActionType(
            actionToEnable
        );

        setGenericStageActionsModalData({
            open: true,
            onClose: onCloseGenericModal,
            fieldName: actionToEnable,
            module: module,
            setValue: setValue,
            initialValuesDB: value[0] || {}
        });
    };
    if (value.length) {
        return (
            <List className={classes.root}>
                <ListItem>
                    <ListItemIcon>
                        <Icon>flash_on</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={t(`button_${props.metadata.fieldName}`)}
                    />
                    <ListItemSecondaryAction>
                        <ButtonIconTooltipCompleo
                            label={t("COMPLEOGENERAL_Edit")}
                            onClick={() => {
                                editAction(
                                    props.metadata
                                        .fieldName as PipelineActionTypeDefinition
                                );
                            }}
                        >
                            <Icon>edit</Icon>
                        </ButtonIconTooltipCompleo>
                        <ButtonIconTooltipCompleo
                            label={t("COMPLEOGENERAL_Delete")}
                            onClick={deleteField}
                        >
                            <Icon>delete</Icon>
                        </ButtonIconTooltipCompleo>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                {genericStageActionsModalData?.open && (
                    <GenericStageActionsModal
                        {...genericStageActionsModalData}
                    />
                )}
            </List>
        );
    } else {
        return null;
    }
};
export default StageActionCustom;
