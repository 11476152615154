import React from "react";
import { useAuthorization } from "_ReactContext/AuthContext";
import NoAccessDashPage from "Pages/Dashboard/NoAccessDashPage";
import Dashboard from "Pages/Dashboard/Dashboard";
import useJobContext from "../../useJobContext";
import { Alert } from "@material-ui/lab";
import DashboardApplicantsJobView from "Pages/Dashboard/DashboardApplicantsJobView/DashboardApplicantsJobView";

const JobViewDashboardMain = () => {
    const { isFeatureAuthorized } = useAuthorization();
    const hasDashboardAccess = isFeatureAuthorized("Dashboard");
    const [data] = useJobContext();
    const totalApplicants = (data.LocalStateData?.stagesOnlyTotal || []).reduce(
        (previousValue, currentValue) => {
            return currentValue.total + previousValue;
        },
        0
    );
    const { t } = data;

    if (hasDashboardAccess) {
        if (totalApplicants > 0) {
            return (
                <Dashboard
                    uniqueDashboardView={true}
                    dashboardToShow="applicantsJobView"
                    dashOtherProps={{
                        applicantsJobView: { jobId: data?.JobId }
                    }}
                />
                // <DashboardApplicantsJobView jobId={data?.JobId} />
            );
        } else {
            return (
                <Alert severity="warning" style={{ margin: 16 }}>
                    {t("noApplicantsMessage")}
                </Alert>
            );
        }
    } else {
        return (
            <Alert severity="info" style={{ margin: 16 }}>
                {t("PLANLIMIT_featureNotAvailable")}
            </Alert>
        );
    }
};

export default JobViewDashboardMain;
