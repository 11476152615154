import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

export const useListPageFunctionsDeleteContact = (t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const callDeleteDialog = async (rowData: any) => {
        return new Promise((resolve, reject) => {
            const uuid = rowData.skGS1pk.split(":")[1];
            const postAddress = `/contact/delete/${uuid}`;
            rowData.accessEnabled = rowData.accessEnabled === "Não" ? false : true;
            
            callDialog({
                title: t("ContactDelete_ModalTitle"),
                bodyText: t("ContactDelete_Message"),
                agreeButtonText: t("ContactDelete_Yes"),
                disagreeButtonText: t("ContactDelete_No"),
                agreeFunction: async () => {
                    const result = await apiDirectCall(
                        postAddress,
                        "post",
                        rowData
                    );
                    if (result.status === 200) {
                        showMessage(t("successMessage"), "success");                        
                        resolve(true);
                    }
                    resolve(false);
                },
                disagreeFunction: () => {
                    resolve(false);
                }
            });
        });
    };

    const listPageFunctions = [{ action: "delete", fn: callDeleteDialog }];

    return listPageFunctions;
};
