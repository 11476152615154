import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating, {
    IconContainerProps,
    RatingProps
} from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Favorite } from "@material-ui/icons";
import { Box, Divider } from "@material-ui/core";

const StyledRating = withStyles({
    iconFilled: {
        color: "#ff6d75"
    },
    iconHover: {
        color: "#ff3d47"
    }
})(Rating);

const customIconsSentiment: {
    [index: string]: { icon: React.ReactElement; label: string };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: "Very Dissatisfied"
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: "Dissatisfied"
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: "Neutral"
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: "Satisfied"
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: "Very Satisfied"
    }
};

const RatingIconContainerSentiment = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{customIconsSentiment[value]?.icon}</span>;
};

export const getRatingAddProps = (type: Compleo.scoreType) => {
    const ratingAddProps: RatingProps = {};
    switch (type) {
        case "sentiment":
            ratingAddProps.precision = 1;
            ratingAddProps.IconContainerComponent = RatingIconContainerSentiment;
            break;
        case "10stars":
            ratingAddProps.precision = 1;
            ratingAddProps.max = 10;
            break;
        case "hearts":
            ratingAddProps.icon = <Favorite />;
            break;
        default:
            break;
    }
    return ratingAddProps;
};
