import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import StatusActions from "customHooks/useList/components/_componentsJob/StatusActions";
import useJobContext from "../useJobContext";
import { useAuthorization } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";

type MainMenuList = "jobBoards" | "shareJob";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    jobId: string;
}

const JobShareMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t, jobId } = props;
    const { isFeatureAuthorized } = useAuthorization();

    const [data, dispatchData] = useJobContext();

    const allMenuOptions: MainMenuList[] = ["jobBoards", "shareJob"];
    // if (isFeatureAuthorized("EditJob")) {
    //     allMenuOptions.push("editJob");
    // }

    const menuOptions = allMenuOptions;

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (action: MainMenuList) => {
        switch (action) {
            case "jobBoards":
                dispatchData.dispatchGeneralPageInfo({
                    payload: { modalJobBoardsStatusOpen: true },
                    type: "update"
                });
                handleClose();
                break;
            case "shareJob":
                dispatchData.dispatchGeneralPageInfo({
                    payload: { modalJobShareOpen: true },
                    type: "update"
                });
                handleClose();
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const orderMenuOptions = (menuItems: MainMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ModalShareMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default JobShareMenu;
