import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useList from "../..";
import { ButtonGroup, Typography } from "@material-ui/core";
import { IListBulk, getT } from "./helperBulkActions";
import BulkActionsList from "./BulkActionsList";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import useGetMetadata, {
    useGetMetadataMerge
} from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import useModalNewEmailLists from "Pages/Applicant/ApplicantView/ApplicantEmail/useModalNewEmailLists";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
    fromJobViewJobId?: string;
}

const BulkActionsStep3SendEmail = (props: IProps) => {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const { company } = useAuthState();

    const {
        formikRef,
        listBulk,
        setStepBulk,
        setLoadingButtons,
        fromJobViewJobId
    } = props;
    const [listData] = useList();
    // const { t } = listData.tempInfo;
    // const jobData = useJobList();
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKSENDEMAIL";
    const [metadata] = useGetMetadataMerge(module1, module2);

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "template",
            props: {
                type: fromJobViewJobId
                    ? "communicationApplicantJob"
                    : "communicationApplicant"
            }
        }
    ];

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);
    const [emailList, emailListReady] = useModalNewEmailLists(
        tMerge,
        readyTranslation
    );

    const [initialValues] = useValuesFromSource(metadata, true, {});
    const ready =
        readyTranslation && metadata.status === "success" && emailListReady;

    const handleSubmit = async (values: any) => {
        // const type = values.filterType.value;
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);
        try {
            const paramsPost: Compleo.IObject = {
                companyId: company.companyId,
                ids: ids,
                template: values.template,
                recipients: values.recipients,
                name: values.name,
                type: "applicant_sendEmail"
            };
            if (fromJobViewJobId) {
                paramsPost.job = {
                    value: fromJobViewJobId
                };
            }
            const data = await apiDirectCall(
                "/bulkactions/baadd",
                "post",
                paramsPost
            );
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        // listData.tempInfo.useApiKey
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);
        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formRef: formikRef,
        customLists: emailList,
        additionalFieldProperties: addProps
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return FormReturn;
};

export default BulkActionsStep3SendEmail;
