import React from "react";
import { getInitialValues } from "./helpers/helper";
import { PropsParent, typeForm } from "./components/FormMain";

const CompleoFormik = (props: any) => {
    const {
        loading,
        camposMetadados,
        formGroups,
        t,
        listas,
        cadastrar: postFunction,
        retornoCadastro,
        FormType,
        redirectAddress,
        valuesFromSource,
        CustomComponents,
        IsMultiStepForm,
        MultiStepForm,
        nextPageFunctions,
        secondaryButtonFunction,
        tertiaryButtonFunction,
        customLists,
        fieldsToHide,
        formikReturn
    } = props;

    const Form: (props: PropsParent) => JSX.Element = FormType;
    const updating = retornoCadastro.status === "fetching";
    const initialValues = valuesFromSource; // getInitialValues(camposMetadados, valuesFromSource);

    return (
        <Form
            t={t}
            camposMetadados={camposMetadados}
            formGroups={formGroups}
            listas={listas}
            updating={updating}
            loading={loading}
            valuesFromSource={valuesFromSource}
            CustomComponents={CustomComponents}
            IsMultiStepForm={IsMultiStepForm}
            MultiStepForm={MultiStepForm}
            initialValues={initialValues}
            redirectAddress={redirectAddress}
            postFunction={postFunction}
            nextPageFunctions={nextPageFunctions}
            secondaryButtonFunction={secondaryButtonFunction}
            tertiaryButtonFunction={tertiaryButtonFunction}
            customLists={customLists}
            fieldsToHide={fieldsToHide}
            formikReturn={formikReturn}
            enableReinitialize={true}
        />
    );
};

export default CompleoFormik;
