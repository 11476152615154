import React from "react";
import Page from "../_Layouts/DefaultExternal";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import stylesForm from "Pages/_Layouts/generalForm";
import { useApi, ApiStateType } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import { firstAccessFunction } from "./components/FirstAccessFunctions";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { navigate } from "@gatsbyjs/reach-router";
import GenericAdditionalGrid from "./GenericAdditionalGrid";

const useStyles = makeStyles((theme: Theme) => createStyles(stylesForm));

const Login = (props: any) => {
    const { login } = useAuthState();
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("FIRSTACCESS", {
        useSuspense: false
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const [metadata] = useGetMetadata("FIRSTACCESS");
    const postAddress = `/user/firstaccess`;
    const [getTokenReturn, getToken] = useApi(postAddress, "post");
    const tokenStatus = getTokenStatus(getTokenReturn);

    React.useEffect(() => {
        getToken({ token: props.useraccessuuid });
    }, []);

    const [valuesFromSource, pkSKValues] = useValuesFromSource(
        metadata,
        false,
        {},
        getTokenReturn,
        ["data", "data"]
    );

    const functionForm = firstAccessFunction(
        setIsLoading,
        login,
        t,
        props.useraccessuuid
    );
    const firstAccessReturn: ApiStateType = {
        status: "initial",
        response: {},
        exception: null
    };

    if (!isLoading) {
        firstAccessReturn.status = "success";
    } else if (isLoading) {
        firstAccessReturn.status = "fetching";
    }

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        functionForm,
        firstAccessReturn,
        `/`,
        FormExternal,
        metadata,
        valuesFromSource
    );

    let compRetorno: boolean | JSX.Element = <Loading />;
    const additionalGrid = <GenericAdditionalGrid t={t} />;

    if (finished && readyTranslation && getTokenReturn.status === "success") {
        compRetorno = formCompleo;
        if (tokenStatus === "ok") {
            return (
                <Page additionalGrid={additionalGrid} maxWidth="xs">
                    {compRetorno}
                </Page>
            );
        } else {
            return (
                <Page additionalGrid={additionalGrid} maxWidth="xs">
                    <Grid container style={{ marginTop: 24 }} spacing={2}>
                        <Grid item xs={12}>
                            <Alert>{t(`erroMSG_${tokenStatus}`)}</Alert>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate("/")}
                            >
                                {t("labelLinkLogin")}
                            </Button>
                        </Grid>
                    </Grid>
                </Page>
            );
        }
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

type getTokenStatusReturnType =
    | "notFound"
    | "ok"
    | "expired"
    | "usedBefore"
    | "loading";

const getTokenStatus: (
    getTokenReturn: ApiStateType
) => getTokenStatusReturnType = (getTokenReturn: ApiStateType) => {
    const ready = getTokenReturn.status === "success";
    const data = getTokenReturn.response?.data?.tokenData;
    console.log("token status data", data);

    if (!ready) {
        return "loading";
    }
    if (!data.GS1sk) {
        return "notFound";
    }

    const today = new Date();
    const expiresData = Number(data.GS1sk.split("#")[1].split(":")[1]);
    if (today.valueOf() > expiresData) {
        return "expired";
    }

    const enabled = data.GS1sk.split("#")[0].split(":")[1];
    if (enabled !== "1") {
        return "usedBefore";
    }
    return "ok";
};

export default Login;
