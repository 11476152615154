import React from "react";
import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import PipelineModelChooseDialog from "./PipelineModelChooseDialog";

interface IProps {
    t: any;
    dialogChoosePipelineTemplateOpen: boolean;
    setDialogChoosePipelineTemplateOpen: any;
    stageIdsWithApplicants?: string[];
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                marginTop: theme.spacing(2)
            }
        },
        grid: {
            marginTop: theme.spacing(3)
        }
    })
);

const PipelineModel = (props: IProps) => {
    const classes = useStyles();
    const {
        t,
        dialogChoosePipelineTemplateOpen,
        setDialogChoosePipelineTemplateOpen,
        stageIdsWithApplicants
    } = props;
    // const [field, meta, helpers] = useField("pipeline");
    const { field, watch, setFieldValue } = useRhfFieldControlled("pipeline");
    const fieldValue = field.value;

    return (
        <Grid container className={classes.grid}>
            <Grid item xs={12}>
                <Typography variant="caption" display="block" color="primary">
                    {t("pipelinemodel")}
                </Typography>
                <Typography variant="body1" display="block">
                    {(fieldValue || {}).name}
                </Typography>
            </Grid>
            <PipelineModelChooseDialog
                open={dialogChoosePipelineTemplateOpen}
                setOpen={setDialogChoosePipelineTemplateOpen}
                t={t}
                stageIdsWithApplicants={stageIdsWithApplicants}
                field={field}
                setFieldValue={setFieldValue}
            />
        </Grid>
    );
};

export default PipelineModel;
