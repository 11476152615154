import React from "react";
import { CompleoListPage } from "customHooks/useList";
import MainTimeToStageReport from "./TimeToStage/main";
import MainCurrentStageReport from "./CurrentStage/main";
import MainCurrentTimeToStatusReport from "./TimeToStatus/main";
import Tabs from "@material-ui/core/Tabs";
import { navigate } from "@gatsbyjs/reach-router";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";

type reportTypes = "timeToStage" | "timeJobStatus" | "currentJobAdvancedStage";

const TimeToStageReport = (props: any) => {
    const module = "JOBREPORTSTIME";
    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const queryString = props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const reportType = urlParams.get("reports") || "timeToStage";

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        navigate(`/reports/time?reports=${newValue}`);
    };

    return (
        <CompleoListPage
            moduleName={module}
            listApiAddress="/reports/jobstimesandstage"
            layoutType="childrenempty"
        >
            {readyTranslation ? (
                <Tabs
                    value={reportType}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab
                        label={t("reportJobTime_timeToStage")}
                        value="timeToStage"
                    />
                    <Tab
                        label={t("reportJobTime_timeToStatus")}
                        value="timeJobStatus"
                    />
                    <Tab
                        label={t("reportJobTime_currentJobStage")}
                        value="currentJobAdvancedStage"
                    />
                </Tabs>
            ) : null}

            <RenderChildren type={reportType as reportTypes} />
        </CompleoListPage>
    );
};

interface IRenderChildrenProps {
    type: reportTypes;
}
const RenderChildren = (props: IRenderChildrenProps) => {
    const { type } = props;
    switch (type) {
        case "timeToStage":
            return <MainTimeToStageReport />;
        case "currentJobAdvancedStage":
            return <MainCurrentStageReport />;
        case "timeJobStatus":
            return <MainCurrentTimeToStatusReport />;
        default:
            return <MainTimeToStageReport />;
    }
};

export default TimeToStageReport;
