import React from "react";
import { CompleoDynamicDashboardPage } from "customHooks/useDynamicDashboard";
import DynamicDashboardItems from "../DynamicDashboard/DynamicDashboardItems";

interface IProps {
    jobId: string;
}
const DashboardApplicantsJobView = (props: IProps) => {
    const { jobId } = props;
    const module = "APPLICANTJOBVIEWDASHBOARD";

    return (
        <CompleoDynamicDashboardPage
            moduleName={module}
            listApiAddress="/reports/applicantjobviewdynamicdashboard"
            otherParamsQuery={{
                jobId: jobId
            }}
        >
            <DynamicDashboardItems dashboardName={module} />
        </CompleoDynamicDashboardPage>
    );
};

export default DashboardApplicantsJobView;
