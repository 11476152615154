import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Box, Chip, createStyles, makeStyles, Theme } from "@material-ui/core";
import useDashboardContext from "../useDashboardContext";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Delete } from "@material-ui/icons";
import _ from "lodash";
import { gridLayoutSizes } from "../Helpers/_DashboardHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            paddingRight: theme.spacing(0.2),
            paddingBottom: theme.spacing(2),
            margin: 0
        },
        content: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(2)
        },
        title: {
            // fontSize: 14,
            marginBottom: theme.spacing(3)
        },
        count: {
            marginTop: 8
        },
        grow: {
            flexGrow: 1
        },
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#2196f3",
            marginRight: 0
        }
    })
);

interface IDashboardMainItem {
    children: any;
    widgetName: string;
    ready: boolean;
    t: any;
}

const DashboardMainItem = (props: IDashboardMainItem) => {
    const { children, widgetName, ready, t } = props;
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { editMode } = data.localStateData;

    const deleteWidget = (event: any) => {
        event.stopPropagation();

        try {
            const currentDef = data.localStateData.currentDashboardDef;

            if (currentDef) {
                const newWidgetList = currentDef.widgetList.filter(
                    (i) => i !== widgetName
                );

                currentDef.widgetList = newWidgetList;
                const layouts = _.cloneDeep(currentDef.layouts);

                for (const layoutSize of gridLayoutSizes) {
                    if (layouts[layoutSize]) {
                        layouts[layoutSize] = layouts[layoutSize].filter(
                            (item) => item.i !== widgetName
                        );
                    }
                }
                currentDef.layouts = layouts;

                dispatchGeneralPageInfo({
                    payload: { currentDashboardDef: currentDef },
                    type: "update"
                });
            }
        } catch (ex: any) {
            console.log("LOCOMPLEO: ERRORMESSAGE", ex?.message);
            console.log("LOGCOMPLEO: ERROR", JSON.stringify(ex, null, 4));
        }
    };

    const classes = useStyles();
    const legend = t(`${widgetName}_WidgetLegend`, "");
    if (ready) {
        return (
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Box display="flex" className={classes.title}>
                        <Box>
                            <Typography variant="body2" component="span">
                                <strong>{t(`${widgetName}_Name`)}</strong>
                            </Typography>
                        </Box>
                        <Box flexGrow={1} />
                        {legend && (
                            <Box>
                                {" "}
                                <Chip
                                    className={classes.chip}
                                    size="small"
                                    label={legend}
                                />
                            </Box>
                        )}
                        {editMode && (
                            <Box onClick={(e) => e.stopPropagation()}>
                                {" "}
                                <ButtonIconTooltipCompleo
                                    label={t("COMPLEOGENERAL_Delete")}
                                    onClick={deleteWidget}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onTouchStart={(e) => e.stopPropagation()}
                                >
                                    <Delete color="secondary" />
                                </ButtonIconTooltipCompleo>
                            </Box>
                        )}
                    </Box>
                    <Box padding={1} width="100%" height="100%">
                        {children}
                    </Box>
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Loading />
                </CardContent>
            </Card>
        );
    }
};

export default DashboardMainItem;
