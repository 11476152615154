import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(6),
            width: "100%",
            marginTop: theme.spacing(4)
        },
        paperstepper: {
            width: "100%",
            marginTop: theme.spacing(1)
        },
        formcontainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%"
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%"
        },
        dense: {
            marginTop: 19
        },
        menu: {
            width: 200
        },
        button: {
            marginTop: theme.spacing(4),
            marginRigh: theme.spacing(0),
            margin: theme.spacing(1)
        },
        mainTitle: {
            marginBottom: theme.spacing(1)
        },
        helpForm: {
            marginBottom: theme.spacing(1)
        },
        fabEdit: {
            position: "absolute",
            bottom: theme.spacing(6),
            right: theme.spacing(4),
            color: theme.palette.common.white,
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[600]
            },
            zIndex: 1000
        },
        fabIcon: {
            marginRight: theme.spacing(1)
        }
    })
);

export default useFormStyles;
