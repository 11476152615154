import React from "react";
import ItemCard from "./ItemCard";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { IFieldProps } from ".";
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarSmall: {
            width: theme.spacing(3),
            height: theme.spacing(3)
        }
    })
);

const getRandomColor = () => {
    const items: string[] = [
        "#4791db",
        "#dc004e",
        "#9a0036",
        "#f44336",
        "#f57c00",
        "#388e3c"
    ];
    return items[Math.floor(Math.random() * items.length)];
};

const FieldAvatar = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;

    switch (returnType) {
        case "card":
            return (
                <ItemCard label={t(fieldName)}>
                    <AvatarItem
                        fieldData={fieldData}
                        fieldName={fieldName}
                        t={t}
                        language={language}
                    />
                </ItemCard>
            );
        case "table":
            return (
                <AvatarItem
                    fieldData={fieldData}
                    fieldName={fieldName}
                    t={t}
                    language={language}
                />
            );
    }
};

interface IAvatarItem {
    fieldName: string;
    fieldData: Compleo.IObject;
    t: any;
    language: string;
}

const AvatarItem = (props: IAvatarItem) => {
    const theme = useTheme();
    const classes = useStyles();
    const { fieldName, fieldData, t, language } = props;

    if (Array.isArray(fieldData[fieldName])) {
        return (
            <AvatarGroup max={4}>
                {fieldData[fieldName].map((fieldItem: any, index: number) => {
                    const backColor = getRandomColor();
                    return (
                        <Avatar
                            className={classes.avatarSmall}
                            key={index}
                            // alt={transformDBData(fieldItem, t, language, "-")}
                            style={{
                                color: theme.palette.getContrastText(backColor),
                                backgroundColor: backColor
                            }}
                            // src="/static/images/avatar/5.jpg"
                        >
                            {transformDBData({
                                value: fieldItem,
                                t,
                                language,
                                replaceOption: "-"
                            })
                                .match(/\b(\w)/g)
                                .slice(0, 2)
                                .join("")}
                        </Avatar>
                    );
                })}
            </AvatarGroup>
        );
    } else {
        const valueAvatar = transformDBData({
            value: fieldData[fieldName],
            t,
            language,
            replaceOption: "-"
        });
        if (valueAvatar !== "-") {
            return (
                <Avatar
                    className={classes.avatarSmall}
                    alt={valueAvatar}
                    src="/static/images/avatar/5.jpg"
                />
            );
        } else {
            return null;
        }
    }
};

export default FieldAvatar;
