import React from "react";
// import { getMetadata } from "./helpers/helper";
import { TFunction, i18n } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import MainView from "./Fields/MainFields/MainView";
import { getMetadata } from "customHooks/useCompleoForm/helpers/helper";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

export const useCompleoViewEditFormGroupName =
    "editFormGroupOnlyForUseWithUseCompleoView";
export interface IUseCompleoViewEditDataParams {
    moduleEditName: string;
    postFunction: any;
    postReturn: ApiStateType;
    keyData: Compleo.IObject;
    useQueryKeyInvalidate?: string[];
    customLists?: {
        listDefinition: Compleo.ListDefinitionType[];
        ready: boolean;
    };
}

export interface UseCompleoViewCustomComponents {
    Component: (props: any) => JSX.Element;
    fieldName: string;
}

export interface UseCompleoEditCustomComponents {
    component: (props: any) => JSX.Element;
    name: string;
}

export interface IUseCompleoViewParams {
    t: TFunction;
    ready: boolean;
    language: string;
    metadataReturn: ApiStateType;
    valuesFromSource: Compleo.IObject;
    timeZone?: null | string;
    editParams?: IUseCompleoViewEditDataParams;
    CustomComponents?: UseCompleoViewCustomComponents[];
    CustomEditComponents?: UseCompleoEditCustomComponents[];
    formGroupPaperElevation?: number;
    multiStepTabNameToOpen?: string;
    addProps?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    readOnly?: boolean;
    fieldsToHide?: string[];
    customCVsData?: Compleo.IObject[];
}

const useCompleoView = (params: IUseCompleoViewParams) => {
    const {
        t,
        ready,
        language,
        metadataReturn,
        valuesFromSource,
        timeZone,
        editParams,
        CustomComponents = [],
        formGroupPaperElevation,
        multiStepTabNameToOpen,
        addProps,
        readOnly = false,
        fieldsToHide = [],
        CustomEditComponents = [],
        customCVsData
    } = params;

    let mainMetadata: Compleo.IObject = {};
    let finished = false;

    if (metadataReturn.status === "success" && ready) {
        mainMetadata = getMetadata(metadataReturn, language, timeZone);
        finished = true;
    }
    let formRetorno = <Loading />;

    if (finished) {
        formRetorno = (
            <MainView
                language={language}
                metadata={mainMetadata.camposMetadados || []}
                formGroups={mainMetadata.formGroups || []}
                IsMultiStepForm={mainMetadata.IsMultiStepForm}
                MultiStepForm={mainMetadata.MultiStepForm || []}
                t={t}
                valuesFromSource={valuesFromSource}
                editParams={editParams}
                CustomComponents={CustomComponents}
                CustomEditComponents={CustomEditComponents}
                formGroupPaperElevation={formGroupPaperElevation}
                multiStepTabNameToOpen={multiStepTabNameToOpen}
                addProps={addProps}
                readOnly={readOnly}
                fieldsToHide={fieldsToHide}
                customCVsData={customCVsData}
            />
        );
    }
    return { formRetorno, finished };
};

export default useCompleoView;
