import React, { useReducer } from "react";
import { useApiCache } from "customHooks/useApi";
import { TFunction, useTranslation } from "react-i18next";
import {
    ApplicantViewContainerType,
    IApplicantViewMainState,
    IApplicantViewMainStateAction,
    IApplicantViewLocalStateData,
    ApplicantViewModulesType,
    ApplicantViewExternalData,
    ApplicantViewJobDataInsideJob
} from "./ApplicantViewTypes";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";

const ApplicantViewContext = React.createContext<
    IApplicantViewMainState | undefined
>(undefined);
const ApplicantViewContextDispatch = React.createContext<
    React.Dispatch<IApplicantViewMainStateAction> | undefined
>(undefined);

const reducer = (
    state: IApplicantViewLocalStateData,
    action: IApplicantViewMainStateAction
): IApplicantViewLocalStateData => {
    switch (action.type) {
        case "update": {
            return { ...state, ...action.payload };
        }
    }
};

interface IApplicantViewProvider {
    applicantId: string;
    children: any;
    containerType: ApplicantViewContainerType;
    index: number;
    isInsideKanban: boolean;
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    tabOpen?: string;
    openRatingOnStart?: boolean;
    module?: ApplicantViewModulesType;
    externalData?: ApplicantViewExternalData;
    customCVsData?: Compleo.IObject[];
    evaluationId?: string;
    cvToShare?: Compleo.IObject;
}

export const ApplicantViewProvider = (props: IApplicantViewProvider) => {
    const {
        applicantId,
        children,
        containerType,
        index,
        isInsideKanban,
        jobDataInsideJob,
        tabOpen,
        openRatingOnStart = false,
        module = "APPLICANTVIEW",
        externalData,
        customCVsData,
        evaluationId,
        cvToShare
    } = props;

    const { isFeatureAuthorized } = useAuthorization();
    const readOnly = !isFeatureAuthorized("EditApplicant");

    const moduleName = module;
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const queryKey = !externalData
        ? `/applicant/searchfulldata`
        : `/applicant/searchfulldataexternal`;

    const [metadata] = useGetMetadata(moduleName, externalData?.companyId);

    const defaultState: IApplicantViewLocalStateData = {};

    const [localStateData, dispatchLocalStateData] = useReducer(
        reducer,
        defaultState
    );

    const { company, timeZone } = useAuthState();
    const companyId =
        company.companyId > 0
            ? company.companyId
            : externalData?.companyId || 0;

    const paramsGetApplicant = !externalData
        ? {
              id: applicantId,
              companyId: companyId
          }
        : {
              applicantId: applicantId,
              evaluationId: externalData.evaluationId
          };

    const [getApplicantReturn, getApplicant] = useApiCache(
        queryKey,
        "post",
        paramsGetApplicant,
        false
    );

    React.useEffect(() => {
        if (getApplicantReturn.status === "success") {
            getApplicant(paramsGetApplicant);
        }
    }, [applicantId, getApplicantReturn.status]);

    /**
     * Return Data
     */
    const ready = getApplicantReturn.status === "success" && readyTranslation;
    const applicantData = getApplicantReturn.response?.data?.data || {};

    const returnMain: IApplicantViewMainState = {
        ready: ready,
        metadata: metadata,
        t: t,
        language: i18n.languages[0],
        applicantDataReturn: getApplicantReturn,
        containerType: containerType,
        queryKey: queryKey,
        localStateData: localStateData,
        applicantData: applicantData,
        timeZone: timeZone,
        applicantId: applicantId,
        companyId: Number(companyId),
        jobDataInsideJob: jobDataInsideJob,
        applicantIndex: index,
        isInsideKanban: isInsideKanban,
        tabOpen: tabOpen,
        openRatingOnStart: openRatingOnStart,
        externalData: externalData,
        readOnly: readOnly,
        customCVsData,
        evaluationId,
        cvToShare
    };
    const returnDispatch: React.Dispatch<IApplicantViewMainStateAction> = dispatchLocalStateData;

    return (
        <ApplicantViewContext.Provider value={returnMain}>
            <ApplicantViewContextDispatch.Provider value={returnDispatch}>
                {children}
            </ApplicantViewContextDispatch.Provider>
        </ApplicantViewContext.Provider>
    );
};

const useValues = () => {
    const context = React.useContext(ApplicantViewContext);
    if (!context) {
        throw new Error(
            `useValues must be used within a ApplicantViewProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useDispatch = () => {
    const context = React.useContext(ApplicantViewContextDispatch);
    if (!context) {
        throw new Error(
            `useDispatch must be used within a ApplicantViewProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useApplicantViewContext: () => [
    IApplicantViewMainState,
    React.Dispatch<IApplicantViewMainStateAction>
] = () => {
    return [useValues(), useDispatch()];
};

export default useApplicantViewContext;
