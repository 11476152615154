import React, { useState, useEffect, memo } from "react";
import PDFViewerStream from "Components/PDFViewerStream";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Paper } from "@material-ui/core";
import { getBase64PDF, b64toBlob } from "./helper";

type margins = {
    top: string;
    bottom: string;
    right: string;
    left: string;
};
interface IProps {
    htmlToConvert: string;
    margins?: margins;
}

const Html2Pdf = (props: IProps) => {
    const { htmlToConvert, margins } = props;
    const [blob, setBlob] = useState<Blob | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [lastHtml, setLastHtml] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (htmlToConvert && htmlToConvert !== lastHtml) {
            setLoading(true);
            getBase64PDF(
                htmlToConvert,
                `customCV_${Date.now()}.html`,
                margins
            ).then((data: any) => {
                const base64PDF = data.base64data;
                const blob = b64toBlob(base64PDF, "application/pdf");
                setLastHtml(htmlToConvert);
                setBlob(blob);
                setLoading(false);
            });
        }
    }, [htmlToConvert, margins, lastHtml]);

    if (blob && !loading) {
        const localURL = blob && blobUrl(blob);

        return (
            <Paper>
                <PDFViewerStream path={localURL} isLocalStreamUrl={true} />
            </Paper>
        );
    }

    return <Loading />;
};

let urls = new WeakMap();

let blobUrl = (blob: any) => {
    if (urls.has(blob)) {
        return urls.get(blob);
    } else {
        let url = URL.createObjectURL(blob);
        urls.set(blob, url);
        return url;
    }
};

export default memo(Html2Pdf);
