import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";

export const useVerifyJobAccess = () => {
    const { company, user } = useAuthState();
    //debugger;
    const { isFeatureAuthorized } = useAuthorization();
    const userIsJobAdmin = isFeatureAuthorized("AdminJobs");
    const allowJobAccessOnlyTeam = company.allowJobAccessOnlyTeam === true;

    const verifyJobAccess = (jobData: Compleo.IObject) => {
        //debugger;
        if (allowJobAccessOnlyTeam === false) {
            return true;
        }
        if (userIsJobAdmin) {
            return true;
        }
        const { mainRecruiter, recruiters } = jobData || {};
        if (user) {
            if (
                mainRecruiter?.value === user ||
                (recruiters || [])
                    .map((item: Compleo.IObject) => item.value)
                    .includes(user)
            ) {
                return true;
            }
        }
        return false;
    };

    return verifyJobAccess;
};
