import "./wdyr";
import "./react-devtools-hook";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify, { Auth } from "aws-amplify";
import config from "./configAmplify";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // integrations: [captureConsoleIntegration()],
    // normalizeDepth: 3,
    // Performance Monitoring
    maxBreadcrumbs: 30,
    tracesSampleRate: 1.0 //  Capture 100% of the transactions
});

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "CandidatoGatewayAPI",
                endpoint: `${config.apiGateway.URL}/candidato`,
                custom_header: async () => {
                    // return { Authorization : 'token' }
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()
                    };
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            },
            {
                name: "UserGatewayAPI",
                endpoint: `${config.apiGateway.URL}/user`,
                custom_header: async () => {
                    // return { Authorization : 'token' }
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()
                    };
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            },
            {
                name: "GeneralGatewayAPI",
                endpoint: `${config.apiGateway.URL}/general`,
                custom_header: async () => {
                    // return { Authorization : 'token' }
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()
                    };
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            },
            {
                name: "RouteApi",
                endpoint: `${config.apiGateway.URL}`,
                custom_header: async () => {
                    // return { Authorization : 'token' }
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()
                    };
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            }
        ]
    }
});

const rootContainer = document.getElementById("root");

ReactDOM.render(<App />, rootContainer);
// debugContextDevtool(rootContainer, {
//     disable: process.env.NODE_ENV === "production"
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
