//@ts-nocheck

export const plugin_placeholder = (
    fnToCall: any,
    title: string,
    innerHtmlButton?: string
) => {
    return {
        name: "plugin_placeholder",
        display: "command",

        title: title,
        buttonClass: "se-btn-select se-btn-tool-font",
        // This icon uses Font Awesome
        innerHTML:
            innerHtmlButton ||
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>',

        add: function (core, targetElement) {
            const context = core.context;
            context.plugin_placeholder = {
                targetButton: targetElement
            };
        },
        action: function () {
            fnToCall();
        }
    };
};
