import { Grid } from "@material-ui/core";
import React from "react";
import CardApplicant from "./Card";
import useList, { resultTypeOptionsUseList } from "customHooks/useList";

interface IProps {}

const calculateSizes = (
    sizeType: "sm" | "md" | "lg" | "xl",
    resultType: resultTypeOptionsUseList,
    filterIsOpen: boolean
) => {
    if (resultType === "smallGrid") {
        switch (sizeType) {
            case "sm":
                return 12;
            case "md":
                if (filterIsOpen) {
                    return 12;
                } else {
                    return 6;
                }
            case "lg":
                if (filterIsOpen) {
                    return 6;
                } else {
                    return 4;
                }
            case "xl":
                if (filterIsOpen) {
                    return 4;
                } else {
                    return 3;
                }
        }
    } else {
        return 12;
    }
};

const CardList = (props: IProps) => {
    const [listData] = useList();
    const { fields } = listData.listReturnInfo;
    const { resultType, filterOpenInfo } = listData.localInfo;
    const smSize = calculateSizes(
        "sm",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const mdSize = calculateSizes(
        "md",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const lgSize = calculateSizes(
        "lg",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const xlSize = calculateSizes(
        "xl",
        resultType,
        filterOpenInfo.filterIsOpen
    );

    const jobs = fields;

    return (
        <Grid container spacing={2}>
            {jobs.map((job: any) => (
                <Grid
                    key={`${job.pk}${job.skGS1pk}`}
                    item
                    xs={12}
                    sm={smSize}
                    md={mdSize}
                    lg={lgSize}
                    xl={xlSize}
                >
                    <CardApplicant fieldData={job} />
                </Grid>
            ))}
        </Grid>
    );
};

export default CardList;
