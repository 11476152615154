import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
// import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";;
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
// import { useSelector } from "react-redux";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { useNextPageFunctionsNewContact } from "./components/useNextPageFunctionsNewContact";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import useCustomLists from "./components/useCustomLists";

const ContactNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/contact/list");
    const classes = useFormStyles();
    const { company } = useAuthState();
    const [t, i18n, readyTranslation] = useTranslation("contactnew", {
        useSuspense: false
    });
    const nextPageFunctions = useNextPageFunctionsNewContact(
        company.companyId,
        t
    );
    const [listDefinition, readyLists] = useCustomLists();

    const postAddress = `/contact/new`;
    const [addUserReturn, addUser] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata("CONTACTNEW");

    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.companyId = company.companyId;
    valuesFromSource.deleted = false;

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addUser,
        addUserReturn,
        "/contact/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        nextPageFunctions,
        cancelFunction,
        null,
        listDefinition
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ContactNew;
