import { Box, BoxProps } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useCopyToClipboard } from "./useCopyToClipboard";

interface ICopyToClipboardProps {
    text: string;
    children: React.ReactNode;
    t: TFunction;
    boxProps?: BoxProps;
}
export function CopyToClipboard(props: ICopyToClipboardProps) {
    const { text, children, t, boxProps } = props;

    const copyToClipboard = useCopyToClipboard({ text, t });

    return (
        <Box
            onClick={copyToClipboard}
            style={{ cursor: "pointer" }}
            {...boxProps}
            display={"inline-block"}
        >
            {children}
        </Box>
    );
}
