import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList, { IFilters } from "../..";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide
} from "@material-ui/core";
import useCustomSearchMetadata, {
    transformTFunction
} from "./useCustomSearchMetadata";
import AdvancedSearchForm from "./AdvancedSearchForm";
import AdvancedSearchFormAddFilter from "./AdvancedSearchFormAddFilter";
import {
    useGetAllData,
    getTranslationAndMetadataInfo
} from "./helperCustomSearch";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        dialogPaper: {
            // minHeight: "220px"
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const AdvancedSearchModal = (props: IPropsItemFilter) => {
    const classes = useStyles();
    const { open, onClose, ...other } = props;
    const advancedMetadata = useCustomSearchMetadata();
    const formikRef = React.useRef(null);
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { t } = listData.tempInfo;
    const { localMetadataReturn } = listData.localInfo;
    const allData = useGetAllData(
        listData?.definitionInfo?.elasticDefData?.data?.otherSettings
            ?.metadataToGetLists
    );

    const defaultSearchTypeValue =
        listData?.filtersInfo?.filters?.advancedSearch?.criteria || "OR";
    const [searchType, setSearchType] = React.useState(defaultSearchTypeValue);

    React.useEffect(() => {
        if (searchType !== defaultSearchTypeValue && open) {
            setSearchType(defaultSearchTypeValue);
        }
    }, [defaultSearchTypeValue, open]);

    const handleSearchTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchType((event.target as HTMLInputElement).value);
    };

    const { setFilters, setLocalInfo } = listDispatch;

    const handleOk = () => {
        onClose();
    };

    const handleSubmit = (values: any) => {
        const valuesDefinition = advancedMetadata.metadataReturn.map((item) => {
            return {
                id: item.id,
                fieldName: item.fieldName,
                otherSearchDef: item.otherSearchDef
            };
        });
        const newState: Compleo.IObject = {
            values: values,
            valuesDefinition: valuesDefinition,
            criteria: values.searchType
        };
        setFilters({
            ...filters,
            advancedSearch: newState,
            filterUpdated: true
        });
        setLocalInfo({
            ...listData.localInfo,
            localMetadataReturn: advancedMetadata.metadataReturn
        });
        onClose();
    };

    const handleSave = async () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            await formikValue.setFieldValue("searchType", searchType);
            await formikValue.submitForm();
        }
    };

    React.useEffect(() => {
        if (open) {
            if (localMetadataReturn && localMetadataReturn.length) {
                // next code update TFunction when this info is not available
                // this happen when we load filter from database
                const updatedLocalMetadataReturn = localMetadataReturn.map(
                    (item) => {
                        if (!item.t) {
                            const metaInfo = getTranslationAndMetadataInfo(
                                item.filterType,
                                allData
                            );
                            item.t = transformTFunction(metaInfo.t, item.id);
                        }
                        return item;
                    }
                );

                advancedMetadata.setMetadataReturn(updatedLocalMetadataReturn);
            } else {
                advancedMetadata.setMetadataReturn([]);
            }
        }
    }, [open]);

    return (
        <Dialog
            fullWidth
            // fullScreen
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t(`ELASTICLISTSETTINGS:advancedFilters`)}
            open={open}
            TransitionComponent={Transition}
            TransitionProps={{ timeout: { enter: 500, appear: 500 } }}
            scroll="body"
            classes={{
                paper: classes.dialogPaper
            }}
            {...other}
        >
            <DialogTitle>
                {t(`ELASTICLISTSETTINGS:advancedFilters`)}
            </DialogTitle>
            <DialogContent dividers>
                <AdvancedSearchForm
                    advancedMetadata={advancedMetadata}
                    deleteFilter={advancedMetadata.deleteFilter}
                    formikRef={formikRef}
                    handleSubmit={handleSubmit}
                />
            </DialogContent>
            <DialogActions>
                <RadioGroup
                    row
                    aria-label="searchType"
                    name="searchType"
                    value={searchType}
                    onChange={handleSearchTypeChange}
                >
                    <FormControlLabel
                        value="OR"
                        control={<Radio color="primary" />}
                        label={t(
                            "ELASTICLISTSETTINGS:AdvancedFilter_SearchAnyCriteria"
                        )}
                    />
                    <FormControlLabel
                        value="AND"
                        control={<Radio color="primary" />}
                        label={t(
                            "ELASTICLISTSETTINGS:AdvancedFilter_SearchAllCriteria"
                        )}
                    />
                </RadioGroup>
                <span style={{ flexGrow: 1 }}></span>
                {/* <Divider orientation="vertical" flexItem /> */}
                <Button onClick={handleOk} color="secondary">
                    {t("ELASTICLISTSETTINGS:closeModalCustomSearch")}
                </Button>

                <Button
                    variant="contained"
                    onClick={() => handleSave()}
                    color="primary"
                >
                    {t("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdvancedSearchModal;
