import { useApiCache } from "customHooks/useApi";
import useList from "customHooks/useList";
import React from "react";

const useAdvancedSearchQuestionnaires = (
    moduleName: string,
    companyId: number
) => {
    const [listData] = useList();
    const jobId = listData?.tempInfo?.otherParamsQuery?.jobId;
    const postData: Compleo.IObject = {
        companyId: companyId
    };
    if (jobId) {
        postData.jobId = jobId;
    }
    const [customFields, runQuery] = useApiCache(
        "/questionnaire/getquestionnairessearchelasticdata",
        "post",
        postData,
        true,
        1000 * 60 * 10
    );
    const modulesAllowed: string[] = [
        "APPLICANTLIST",
        "JOBAPPLICANTLISTFILTERKANBAN",
        "JOBAPPLICANTLISTJOBVIEW"
    ];
    React.useEffect(() => {
        if (modulesAllowed.includes(moduleName)) {
            runQuery();
        }
    }, [moduleName]);

    const fieldsDef: Compleo.IObject[] = modulesAllowed.includes(moduleName)
        ? customFields.response?.data?.data || []
        : [];

    return {
        fieldsDef: fieldsDef,
        readyCustomFields:
            customFields.status === "success" ||
            !modulesAllowed.includes(moduleName)
    };
};

export default useAdvancedSearchQuestionnaires;
