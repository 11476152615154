import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import CheckboxWithList from "customHooks/useCompleoForm/Inputs/CheckboxWithListNotNested";
import { Field } from "formik";
import { FieldProps } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useApiCache } from "customHooks/useApi";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import PageMessage from "Components/PageMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

interface IProps {
    label: string;
    t: any;
}

export default function ListDividers(props: IProps & FieldProps) {
    const classes = useStyles();
    const { company } = useAuthState();
    const postAddressSearch = `/user/listbranchstoauth`;
    const [getBranchsReturn] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false,
        1000 * 60
    );

    const {
        label,
        field,
        form: { dirty, touched, errors, status, values },
        t,
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let mensagemErro = hasError && errorText !== undefined ? errorText : "";
    const allBranchAccess =
        values.allBranchAccess === undefined ? false : values.allBranchAccess;

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    let classError = classes.color;
    if (hasError) {
        classError = classes.colorError;
    }

    let branchs: any = [];
    // let listGroups: any = {};

    if (getBranchsReturn.status === "success") {
        const data = getBranchsReturn.response.data.data;
        branchs = data;
        // listGroups = data.groups.MainGroupFeatures;
    }

    if (getBranchsReturn.status === "success") {
        return (
            <React.Fragment>
                <List component="nav" className={classes.root}>
                    {hasError && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {mensagemErro}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    <List>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                className={classError}
                                primary={label}
                            />
                        </ListItem>
                    </List>
                    {branchs.map((f: any) => (
                        <React.Fragment key={f.name}>
                            <Field
                                name={field.name}
                                value={f.name}
                                component={CheckboxWithList}
                                f={f}
                                key={f.name}
                                data_testid={`fieldbranch_${f.name}`}
                            />
                        </React.Fragment>
                    ))}
                    {branchs.length === 0 && (
                        <PageMessage severity="info">
                            {t("noBranchMessage")}
                        </PageMessage>
                    )}
                </List>
            </React.Fragment>
        );
    } else {
        return <Loading />;
    }
}
