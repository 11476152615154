import React from "react";
import Sections from "Pages/Scorecard/components/Sections";
import { Grid } from "@material-ui/core";
import { useAuthorization } from "_ReactContext/AuthContext";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";

interface IProps {}

const Scorecard = (props: IProps) => {
    const metadata = { fieldName: "scorecard.sections" };
    const { isFeatureAuthorized } = useAuthorization();
    const Scorecard_Upgrade = isFeatureAuthorized("PipelineAutomation_Upgrade");

    return (
        <Grid container>
            <Grid item xs={12}>
                {!Scorecard_Upgrade ? (
                    <Sections
                        metadata={metadata}
                        label="Sections"
                        name={metadata.fieldName}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
};

export default Scorecard;
