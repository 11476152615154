import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";

export type ListDefinitionType = {
    fieldName: string;
    data: any[];
    valueName: string;
    labelName: string;
    childrenFilterFieldName?: string;
    parentFilterField?: {
        parentFieldName: string;
        parentIdFieldFilter: string;
        fieldFilter: string;
    };
};

const useCustomLists: () => [ListDefinitionType[], boolean] = () => {
    const { company } = useAuthState();
    const plan = company.plan || "noplan";
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition] = React.useState<ListDefinitionType[]>([]);

    const [customers] = useApiCache(
        "/contact/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    if (
        customers.status === "success" &&
        !listFilled(listDefinition, "customers")
    ) {
        listDefinition.push({
            fieldName: "customers",
            data: customers.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }
    console.log("listDefinition ", listDefinition);

    if (!ready) {
        const readyModules = verifyRead([customers]);
        if (readyModules) {
            setReady(true);
        }
    }
    return [listDefinition, ready];
};

const verifyRead = (api: ApiStateType[]) => {
    return api.filter((a: ApiStateType) => a.status !== "success").length === 0;
};

const listFilled = (
    listDefinition: ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

type objGenerate = { name: string; api: ApiStateType };

export default useCustomLists;
