import React from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import { useTranslation } from "react-i18next";
import ModalNewEmail from "Pages/Email/ModalNewEmail/ModalNewEmail";
import useModalNewEmailLists from "./useModalNewEmailLists";
import { useAuthState } from "_ReactContext/AuthContext";
import AplicantEmailThreadList from "./AplicantEmailThreadList";
import RefreshIcon from "@material-ui/icons/Refresh";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { useQueryClient } from "react-query";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "90%"
        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            backgroundColor: theme.palette.backgroundGrey.main,
            overflow: "auto"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {},
        refreshButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        }
    })
);

const ApplicantEmail = (props: any) => {
    const classes = useStyles();
    const { company, timeZone, user } = useAuthState();
    const queryClient = useQueryClient();

    const [modalEmailOpen, setModalEmailOpen] = React.useState(false);
    const [data] = useApplicantViewContext();

    const [tLocal, i18n, readyTranslation] = useTranslation("APPLICANTEMAIL", {
        useSuspense: false
    });
    const [emailList, emailListReady] = useModalNewEmailLists(
        tLocal,
        readyTranslation,
        data.applicantData
    );
    const ready = readyTranslation && emailListReady;

    const refreshData = () => {
        queryClient.invalidateQueries("/email/listemails");

        // `/email/listemails`
    };

    if (ready) {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {tLocal("title")}
                                </Typography>
                            </Grid>
                            <Grid item xs sm container justify="flex-end">
                                <ButtonIconTooltipCompleo
                                    label={tLocal("searchNewMessage")}
                                    onClick={refreshData}
                                    className={classes.refreshButton}
                                >
                                    <RefreshIcon />
                                </ButtonIconTooltipCompleo>
                                {!data.readOnly && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => setModalEmailOpen(true)}
                                    >
                                        {tLocal("buttonNewEmail")}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    <AplicantEmailThreadList t={tLocal} />
                </Box>
                <ModalNewEmail
                    open={modalEmailOpen}
                    handleClose={() => setModalEmailOpen(false)}
                    listDefinition={emailList}
                    defaultRecipients={[
                        { value: "applicant" },
                        { value: user }
                    ]}
                    applicantId={data.applicantId}
                    invalidateQuery={["/email/listemails"]}
                    applicantPreferredLanguage={
                        data.applicantData?.preferredLanguage
                    }
                />
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default ApplicantEmail;
