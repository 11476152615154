import { Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" }
    })
);

const ApplicantHistoryAppliedToJob = (props: IApplicantHistoryProps) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;

    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("Stage")}
                transformDBDataParam={{
                    value: historyItem.stage?.name,
                    language: language,
                    t: tLocal
                }}
            />
        </>
    );
};

export default ApplicantHistoryAppliedToJob;
