import {
    BoxProps,
    Chip,
    Icon,
    Theme,
    createStyles,
    makeStyles
} from "@material-ui/core";
import { CopyToClipboard } from ".";
import { TFunction } from "i18next";
import { useCopyToClipboard } from "./useCopyToClipboard";

const useStyles = (backgroundColor = "#651fff", fontColor = "#fff") =>
    makeStyles((theme: Theme) =>
        createStyles({
            chip: {
                borderRadius: 6,
                backgroundColor: backgroundColor,
                color: fontColor,
                "&:hover": {
                    backgroundColor: backgroundColor
                },
                "&:active": {
                    backgroundColor: backgroundColor
                },
                "&.MuiChip-deletable:focus": {
                    backgroundColor: backgroundColor
                }
            },
            icon: {
                color: fontColor
            }
        })
    );

interface ICopyToClipboardChipProps {
    text: string;
    t: TFunction;
    chipVariant?: "default" | "outlined" | undefined;
    childrenProps?: BoxProps;
    backgroundColor?: string;
    fontColor?: string;
}

export function CopyToClipboardChip(props: ICopyToClipboardChipProps) {
    const {
        text,
        t,
        childrenProps,
        chipVariant = "outlined",
        backgroundColor,
        fontColor
    } = props;
    const classes = useStyles(backgroundColor, fontColor)();
    const copyToClipboard = useCopyToClipboard({ text, t });

    return (
        <CopyToClipboard text={text} t={t} boxProps={childrenProps}>
            <Chip
                label={text}
                clickable
                variant={chipVariant}
                className={classes.chip}
                size="small"
                onDelete={copyToClipboard}
                deleteIcon={
                    <Icon className={classes.icon} fontSize="small">
                        file_copy
                    </Icon>
                }
            />
        </CopyToClipboard>
    );
}
