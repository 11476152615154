import { useAuthState, useAuthorization } from "_ReactContext/AuthContext";

interface IUseEmailParser {
    jobId?: string;
}

export function useEmailParser(props?: IUseEmailParser) {
    const { jobId } = props || {};
    const { company } = useAuthState();
    const { isFeatureAuthorized } = useAuthorization();
    const isAvailableCompanyPlan = !isFeatureAuthorized("EmailParser_Upgrade");
    const isEnabled = isAvailableCompanyPlan
        ? company?.parserCompany === true
        : false;

    const companyUniqueName = company?.uniqueName || "";

    const emailCompanyParser = isEnabled
        ? `applicant-new@${companyUniqueName}.${company.otherData?.DOMAIN_EMAIL}`.toLowerCase()
        : undefined;
    const jobEmailParser =
        jobId && isEnabled
            ? `jobapplicant-${jobId}@${companyUniqueName}.${company.otherData?.DOMAIN_EMAIL}`.toLowerCase()
            : undefined;

    return {
        isEnabled,
        isAvailableCompanyPlan,
        emailCompanyParser,
        jobEmailParser,
        defaultEmailParser: jobEmailParser || emailCompanyParser
    };
}
