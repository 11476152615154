import {
    ApplicantScreeningQuestionnaireAnswerDataType,
    ApplicantScreeningQuestionnaireDataType
} from "../useCompleoScreeningQuestionnaire";

export const getQuestionnaireMaxPossibleScore = (
    jobScreeningQuestionnaireData?: CompleoShared.Questionnaires.IQuestionnaire
) => {
    if (jobScreeningQuestionnaireData?.enableRanking !== true) {
        return 0;
    }

    const questions = jobScreeningQuestionnaireData?.questions || [];
    let maxScore = 0;

    questions.map((question) => {
        maxScore = maxScore + getQuestionMaxScore(question);
    });

    return maxScore;
};

export const answerTypesWithWeight = [
    "CPQUESTIONSINGLECHOICE",
    "CPQUESTIONMULTIPLECHOICE"
];

export const getRankingValue = (
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire,
    questionnaireValues: ApplicantScreeningQuestionnaireDataType
) => {
    const maxScore = getQuestionnaireMaxPossibleScore(
        jobScreeningQuestionnaireData
    );

    const answers = questionnaireValues?.answers || [];
    let finalScore = 0;

    answers.map((answer) => {
        finalScore =
            finalScore +
            getAnswerWeightDetails(answer, jobScreeningQuestionnaireData)
                .answerScore;
    });
    if (finalScore < 0) {
        finalScore = 0;
    }

    const ranking = (finalScore / maxScore) * 100;
    const resultByScore = getResultByScore(
        ranking,
        jobScreeningQuestionnaireData
    );

    return {
        maxScore: maxScore,
        finalScore: finalScore,
        ranking: ranking,
        resultByScore: resultByScore
    };
};

export const getAnswerWeightDetails = (
    answer: ApplicantScreeningQuestionnaireAnswerDataType,
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire
) => {
    let answerScore = 0;
    let questionMaxScore = 0;
    if (answerTypesWithWeight.includes(answer.question?.questiontype?.value)) {
        const questionId = answer.question?.id;
        const questionData = jobScreeningQuestionnaireData.questions.filter(
            (i) => i.id === questionId
        )[0];

        questionMaxScore = questionMaxScore + getQuestionMaxScore(questionData);

        if (questionData) {
            answer.answer.map((item) => {
                const answerData = questionData.answers.filter(
                    (a) => a.id === item.id
                )[0];
                if (answerData) {
                    if (answerData.weight?.value) {
                        const weight = Number(answerData.weight?.value);
                        answerScore = answerScore + weight;
                    }
                }
            });
        }
    }
    return { answerScore, questionMaxScore };
};

export const getQuestionMaxScore = (
    question: CompleoShared.Questionnaires.IQuestion
) => {
    let maxScore = 0;
    switch (question.questiontype?.value) {
        case "CPQUESTIONMULTIPLECHOICE":
            question.answers.map((answer) => {
                if (
                    answer?.weight?.value &&
                    Number(answer?.weight?.value) > 0
                ) {
                    maxScore = maxScore + Number(answer?.weight?.value);
                }
            });
            break;
        case "CPQUESTIONSINGLECHOICE":
            const weights = question.answers.map((answer) => {
                if (
                    answer?.weight?.value &&
                    Number(answer?.weight?.value) > 0
                ) {
                    return Number(answer?.weight?.value);
                } else {
                    return 0;
                }
            });
            const maxValue = Math.max(...weights);
            maxScore = maxScore + maxValue;
            break;
        default:
            break;
    }
    return maxScore;
};

export const getResultByScore = (
    ranking: number,
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire
) => {
    debugger;
    const rankingToResults =
        jobScreeningQuestionnaireData.rankingToResults || [];
    const results = jobScreeningQuestionnaireData.results || [];
    if (
        jobScreeningQuestionnaireData.enableResults &&
        jobScreeningQuestionnaireData.enableResultsByRankingScore &&
        rankingToResults.length &&
        results.length
    ) {
        rankingToResults.sort((a, b) => b.minScore - a.minScore);
        for (const item of rankingToResults) {
            if (ranking >= item.minScore) {
                return item.result;
            }
        }
        return null;
    } else {
        return null;
    }
};
