import React from "react";
import {
    DashboardPieChart,
    DashboardSummaryCard,
    DashboardPivotTable,
    DashboardSmartTable,
    DashboardBarChart
} from "../DasboardItems/_DashboardItemIndex";

interface IDashboardSummaryCard {
    widgetName: string;
    widget: CompleoShared.Dashboard.widgetsMetadata;
    // dashboardType: CompleoShared.Dashboard.DashboardType;
}

const DashboardItemSwitch = (props: IDashboardSummaryCard) => {
    const { widgetName, widget } = props;

    switch (widget?.dashboardType) {
        case "summaryNumber":
            return <DashboardSummaryCard widgetName={widgetName} />;
        case "pie":
            return (
                <DashboardPieChart widgetName={widgetName} widget={widget} />
            );
        case "pivotTable":
            return (
                <DashboardPivotTable widgetName={widgetName} widget={widget} />
            );
        case "smartTable":
        case "custom_smartTable":
            return (
                <DashboardSmartTable widgetName={widgetName} widget={widget} />
            );
        case "bar":
            return (
                <DashboardBarChart widgetName={widgetName} widget={widget} />
            );
        default:
            return null;
    }
};

export default DashboardItemSwitch;
