import React from "react";
import { loadCSS } from "fg-loadcss";
import Icon from "@material-ui/core/Icon";

interface IProps {
    faClassName: string;
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "default"
        | "disabled"
        | "action"
        | "error"
        | undefined;
    style?: any;
}

export default function FontAwesome(props: IProps) {
    const { color, style, faClassName } = props;

    React.useEffect(() => {
        //@ts-ignore
        const node = loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            //@ts-ignore
            document.querySelector("#font-awesome-css")
        );

        return () => {
            node.parentNode!.removeChild(node);
        };
    }, []);

    return <Icon className={`${faClassName}`} color={color} style={style} />;
}
