import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import {
    Divider,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    PopoverOrigin,
    Typography
} from "@material-ui/core";
import { navigate } from "@gatsbyjs/reach-router";
import { useTranslation } from "react-i18next";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { formatDate } from "functions/formatValues";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { useMarkMessageAsRead } from "./useModalNotifications";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useBackdrop } from "_ReactContext/Backdrop";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            width: "100%"
        },
        title: {
            color: theme.palette.secondary.main,
            fontWeight: 600
        },
        menuWithoutBorder: {
            border: "none",
            outline: "none"
        },
        menuPaper: {
            maxWidth: 500
        }
    })
);

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    options: any[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

const ModalNotification = (props: IProps) => {
    const {
        anchorEl,
        setAnchorEl,
        options,
        anchorOrigin = {
            vertical: "top",
            horizontal: "right"
        },
        transformOrigin = {
            vertical: "top",
            horizontal: "left"
        }
    } = props;
    const { setBackdropOpen } = useBackdrop();
    const { markAllMessagesAsRead } = useMarkMessageAsRead();
    const { callDialog } = useGlobalDialog();

    const classes = useStyles();
    const modalLimit = 10;

    const [t, i18n, readyTranslation] = useTranslation("NOTIFICATIONMODAL", {
        useSuspense: false
    });
    const language = i18n.languages[0];
    const [markingAsRead, setMarkingAsRead] = React.useState<string | null>(
        null
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (route: string) => {
        navigate(route);
        handleClose();
    };

    const confirmMarkAllAsRead = async () => {
        callDialog({
            title: t("markAsReadAll"),
            bodyText: t("confirmMarkAsReadAll"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                setBackdropOpen(true);
                await markAllMessagesAsRead();
                handleClose();
                setBackdropOpen(false);
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    return (
        <>
            <div>
                <Menu
                    id="card-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    classes={{ paper: classes.menuPaper }}
                >
                    <MenuItem
                        button={false}
                        className={classes.menuWithoutBorder}
                    >
                        <ListItemText
                            primary={
                                <Typography className={classes.title}>
                                    {t("title")}
                                </Typography>
                            }
                        />
                    </MenuItem>
                    {options.length === 0 && (
                        <MenuItem
                            button={false}
                            className={classes.menuWithoutBorder}
                        >
                            <ListItemText secondary={t("noNotifications")} />
                        </MenuItem>
                    )}
                    {options.slice(0, modalLimit).map((option: any) => {
                        if (option.skGS1pk !== markingAsRead) {
                            return (
                                <NotificationItem
                                    key={option.skGS1pk}
                                    classes={classes}
                                    clickMenuItem={clickMenuItem}
                                    language={language}
                                    option={option}
                                    t={t}
                                    setMarkingAsRead={setMarkingAsRead}
                                />
                            );
                        } else {
                            return <Loading size={14} key={option.skGS1pk} />;
                        }
                    })}
                    <Divider component="li" />
                    <MenuItem
                        onClick={() => clickMenuItem("/notification/list")}
                    >
                        <ListItemIcon>
                            <Icon>list</Icon>
                        </ListItemIcon>
                        <ListItemText
                            style={{ whiteSpace: "normal" }}
                            primary={t("itemShowAll")}
                        />
                    </MenuItem>
                    {options.length > 0 && (
                        <MenuItem onClick={confirmMarkAllAsRead}>
                            <ListItemIcon>
                                <Icon>done_all</Icon>
                            </ListItemIcon>
                            <ListItemText
                                style={{ whiteSpace: "normal" }}
                                primary={t("markAsReadAll")}
                            />
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </>
    );
};

function createMarkup(value?: string) {
    return { __html: value || "" };
}

interface INotificatinItem {
    clickMenuItem: (route: string) => void;
    option: any;
    t: any;
    language: string;
    classes: any;
    setMarkingAsRead: React.Dispatch<React.SetStateAction<string | null>>;
}

const NotificationItem = (props: INotificatinItem) => {
    const {
        classes,
        clickMenuItem,
        language,
        option,
        t,
        setMarkingAsRead
    } = props;
    const { markMessageAsRead } = useMarkMessageAsRead();

    const markAsRead = async (item: string) => {
        setMarkingAsRead(item);
        await markMessageAsRead(item);
        setMarkingAsRead(null);
    };
    return (
        <MenuItem
            key={option.skGS1pk}
            button={false}
            className={classes.menuWithoutBorder}
        >
            {option.link && (
                <ListItemIcon>
                    <ButtonIconTooltipCompleo
                        label={t("buttonView")}
                        onClick={() => clickMenuItem(option.link)}
                        edge="start"
                    >
                        <Icon>visibility</Icon>
                    </ButtonIconTooltipCompleo>
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography
                        variant="subtitle1"
                        style={{ whiteSpace: "normal" }}
                    >
                        {option.title}
                    </Typography>
                }
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            style={{ whiteSpace: "normal" }}
                            color="textPrimary"
                        >
                            {formatDate(
                                option?.dateAdded,
                                language,
                                "dayMonthYearTime"
                            )}
                        </Typography>
                        {` — `}
                        <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                            style={{ whiteSpace: "normal" }}
                        >
                            <span
                                dangerouslySetInnerHTML={createMarkup(
                                    option?.message
                                )}
                            />
                        </Typography>
                    </React.Fragment>
                }
            />

            <ListItemSecondaryAction>
                <ButtonIconTooltipCompleo
                    label={t("buttonMarkAsRead")}
                    onClick={() => {
                        markAsRead(option?.skGS1pk);
                    }}
                    edge="end"
                >
                    <Icon>done</Icon>
                </ButtonIconTooltipCompleo>
            </ListItemSecondaryAction>
        </MenuItem>
    );
};

export default ModalNotification;
