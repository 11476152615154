import React from "react";
import {
    makeStyles,
    Theme,
    createStyles,
    fade
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { navigate } from "@gatsbyjs/reach-router";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Tooltip } from "@material-ui/core";
const localStorageExactSearchName = "compleo_exact_search";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
            marginRight: theme.spacing(1)
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            color: "inherit",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25)
            }
        },
        iconButton: {
            padding: 5,
            margin: 0
        },
        divider: {
            height: 28,
            margin: 4
        }
    })
);

export default function SearchArea(props: any) {
    const { t } = props;
    const classes = useStyles();
    const [searchValue, setSearchValue] = React.useState("");
    const [exactSearch, setExactSearch] = React.useState(false);

    React.useEffect(() => {
        const storageValue = localStorage.getItem(localStorageExactSearchName);
        if (storageValue === "true") {
            setExactSearch(true);
        }
    }, []);

    const submit = (e: any) => {
        e.preventDefault();
        if (searchValue) {
            navigate(`/search/${searchValue}?exact=${exactSearch.toString()}`);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const changeExactSearch = () => {
        const newValue = !exactSearch;
        localStorage.setItem(localStorageExactSearchName, newValue.toString());
        setExactSearch(newValue);
    };

    return (
        <Paper component="form" className={classes.root} onSubmit={submit}>
            <InputBase
                className={classes.input}
                placeholder={t("search")}
                inputProps={{ "aria-label": t("search") }}
                value={searchValue}
                onChange={handleChange}
            />
            <Tooltip title={t("exactSearch")}>
                <IconButton
                    type="button"
                    className={classes.iconButton}
                    aria-label={t("exactSearch")}
                    onClick={changeExactSearch}
                >
                    {exactSearch ? (
                        <CheckBoxIcon />
                    ) : (
                        <CheckBoxOutlineBlankIcon />
                    )}
                </IconButton>
            </Tooltip>
            <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label={t("search")}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
