import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import {
    ApiCallType,
    apiDirectCall,
    ApiStateType,
    useApiCache
} from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    applicantId: string;
    jobData?: {
        value: string;
        label: string;
    };
    applicantName: string;
    opinionOnTheApplicant?: string;
    opinionOnTheApplicantJob?: string;
}

const JobKanbanOpinionOnTheApplicantEditModal = (props: IPropsModal) => {
    const {
        onClose,
        open,
        applicantId,
        jobData,
        applicantName,
        opinionOnTheApplicant = "",
        opinionOnTheApplicantJob = ""
    } = props;

    const formikRef = React.useRef(null);
    const [isUpdating, setIsUpdating] = React.useState(false);

    const showMessage = useShowMessage();
    const queryClient = useQueryClient();

    const { company } = useAuthState();

    const [getApplicantReturn, getApplicant] = useApiCache(
        `/applicant/searchfulldata`,
        "post",
        {
            id: applicantId,
            companyId: company?.companyId
        },
        false
    );
    const applicantData = getApplicantReturn.response?.data?.data || {};

    const module = "APPLICANTVIEWEDITOPINION";
    const [metadata] = useGetMetadata(module);
    const fieldsToHide = jobData
        ? ["opinionOnTheApplicant"]
        : ["jobTitle", "opinionOnTheApplicantJob"];

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, true, {
        id: applicantId,
        jobId: jobData?.value,
        jobTitle: jobData?.label
    });
    if (jobData) {
        const jobAplicantData = (applicantData?.Jobs || []).filter(
            (job: Compleo.IObject) => job.JobId === jobData.value
        )[0];
        if (jobAplicantData) {
            initialValues.opinionOnTheApplicantJob =
                jobAplicantData.opinionOnTheApplicantJob;
            delete initialValues.opinionOnTheApplicant;
        }
    } else {
        initialValues.opinionOnTheApplicant =
            applicantData?.opinionOnTheApplicant;
        delete initialValues.opinionOnTheApplicantJob;
    }
    const ready =
        readyTranslation &&
        metadata.status === "success" &&
        getApplicantReturn.status === "success";

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        const postAddress = `/applicant/editview`;
        try {
            const dataAPI = await apiDirectCall(postAddress, "post", values);
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries(`/applicant/searchfulldata`);
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        fieldsToHide: fieldsToHide
    });

    const handleSave = () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            <DialogTitle>{`${t(
                jobData ? "opinionOnTheApplicantJob" : "modalTitle",
                ""
            )} ${applicantName ? ` - ${applicantName}` : ""} `}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalClose", "")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave", "")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobKanbanOpinionOnTheApplicantEditModal;
