import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList from "../..";
import SettingsModalList from "./SettingsModalFiltersList";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Typography } from "@material-ui/core";
import { getCustomSearchOrdered } from "../ListUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const SettingsModal = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const [globalValues, globalValuesDispatch] = useGlobalValues();

    const [listData, listDispatch] = useList();
    const orderData =
        globalValues.userdb[`filtersOrder_${listData.tempInfo.moduleName}`];

    const { otherSettings } =
        listData.definitionInfo.elasticDefData?.data || {};

    const otherFilters =
        listData.definitionInfo.elasticDefData?.data?.otherFilters;

    // const otherFilterNames = getOrderedObjectNames(otherFilters || {});
    const otherFilterNames = getCustomSearchOrdered(
        otherFilters || {},
        orderData
    );
    const data = otherFilterNames.map((item, index) => {
        return { name: item, order: index };
    });

    const [filterData, setFilterData] = React.useState<Compleo.IObject[]>(data);
    const maxItemsToShow = otherSettings?.otherFiltersMaxItems || 3;

    React.useEffect(() => {
        if (!filterData.length && data.length) {
            setFilterData(data);
        }
    }, [data]);

    React.useEffect(() => {
        setFilterData(data);
    }, [orderData]);

    const { t } = listData.tempInfo;

    const handleOk = () => {
        onClose();
    };

    const handleSave = () => {
        globalValuesDispatch.addOrEditGlobalValue({
            name: `filtersOrder_${listData.tempInfo.moduleName}`,
            type: "userdb",
            value: filterData
        });
        onClose();
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={t("ELASTICLISTSETTINGS:MainMenu_filterSettings")}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t("ELASTICLISTSETTINGS:MainMenu_filterSettings")}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">
                    {t("ELASTICLISTSETTINGS:FilterSettingsInstructions", {
                        items: maxItemsToShow
                    })}
                </Typography>
                <SettingsModalList
                    filterData={filterData}
                    setFilterData={setFilterData}
                    maxItemsToShow={maxItemsToShow}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsModal;
