import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useTranslation } from "react-i18next";
import { i18n, TFunction } from "i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper
        }
    })
);

interface IProps {
    anchorEl: null | HTMLElement;
    closeMenu: any;
    languageRendered: string;
    i18n: i18n;
    t: TFunction;
}

export default function ChangeLanguage(props: IProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState(
        props.languageRendered
    );

    const t = props.t;
    const i18n = props.i18n;

    if (props.anchorEl !== null && anchorEl === null) {
        setAnchorEl(props.anchorEl);
    } else if (props.anchorEl === null && anchorEl !== null) {
        setAnchorEl(null);
    }

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: string
    ) => {
        i18n.changeLanguage(index);
        setSelectedLanguage(index);
        props.closeMenu();
    };

    const handleClose = () => {
        props.closeMenu();
    };

    return (
        <div className={classes.root}>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem
                    key="pt-BR"
                    selected={selectedLanguage === "pt-BR"}
                    onClick={(event) => handleMenuItemClick(event, "pt-BR")}
                >
                    {t("pt-BR")}
                </MenuItem>

                <MenuItem
                    key="en-US"
                    selected={selectedLanguage === "en-US"}
                    onClick={(event) => handleMenuItemClick(event, "en-US")}
                >
                    {t("en-US")}
                </MenuItem>
            </Menu>
        </div>
    );
}
