import React from "react";
import useJobContext from "../../useJobContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { transformDBData, keepOnlyNumbersInString } from "functions/util";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: 0
        },
        fieldText: {
            marginLeft: theme.spacing(0.5),
            fontSize: 13,
            color: theme.palette.grey[600],
            maxWidth: 240
        },
        fieldIcon: {
            color: theme.palette.grey[600],
            fontSize: 16,
            margin: 0,
            padding: 0
        },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0)
        },
        tags: {
            maxWidth: 250
        },
        evaluationRoot: {
            margin: theme.spacing(0.2),
            padding: 0
        }
    })
);

interface IMainInfoProps {
    fields: Compleo.IObject[];
    ApplicantData: Compleo.IObject;
    cardKanbanFields: Compleo.IObject;
}

const JobKanbanApplicantFields = (props: IMainInfoProps) => {
    const classes = useStyles();
    const { ApplicantData, cardKanbanFields, fields } = props;
    const [data] = useJobContext();
    const { language } = data;
    const [t, i18n, readyTranslation] = data.tResponseListFilterKanban;

    // const value = a;
    return (
        <>
            {fields.map((field) => {
                const type = (cardKanbanFields || {})[field.name]?.type;
                let value = _.get(ApplicantData, field.name);
                let addSuffix = "";

                switch (type) {
                    case "phoneWhatsApp":
                        value = value ? (
                            <a
                                href={`https://wa.me/${keepOnlyNumbersInString(
                                    value
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {transformDBData({
                                    value: value,
                                    t,
                                    language,
                                    replaceOption: "-",
                                    customType: type
                                })}
                            </a>
                        ) : (
                            transformDBData({
                                value: value,
                                t,
                                language,
                                replaceOption: "-",
                                customType: type
                            })
                        );
                        break;
                    case "phone":
                        value = transformDBData({
                            value: value,
                            t,
                            language,
                            replaceOption: "-",
                            customType: type
                        });

                        break;
                    case "distance":
                        value = transformDBData({
                            value:
                                ApplicantData["_distance"] === -1
                                    ? "-"
                                    : ApplicantData["_distance"],
                            t,
                            language,
                            replaceOption: "-",
                            numberDecimalScale: 1
                        });
                        addSuffix = " km";
                        break;
                    case "tags":
                        return (
                            <Tags
                                key={field.name}
                                value={value}
                                fieldName={t(field.name)}
                                icon={cardKanbanFields[field.name]?.icon}
                                addSuffix=""
                            />
                        );
                    case "jobEvaluation": {
                        const ratingValue = (value || []).filter(
                            (item: Compleo.IObject) => item.jobId === data.JobId
                        )[0];
                        return (
                            <Evaluation
                                key={field.name}
                                value={ratingValue?.value || 0}
                                fieldName={t(field.name)}
                                icon={cardKanbanFields[field.name]?.icon}
                                addSuffix=""
                            />
                        );
                    }
                    case "evaluationSummary": {
                        return (
                            <Evaluation
                                key={field.name}
                                value={value || 0}
                                fieldName={t(field.name)}
                                icon={cardKanbanFields[field.name]?.icon}
                                addSuffix=""
                            />
                        );
                    }
                    case "screeningQuestionnaireRanking": {
                        const questionnaireValue = (
                            ApplicantData?.["Questionnaires"] || []
                        ).filter(
                            (item: Compleo.IObject) =>
                                item.jobId === data.JobId &&
                                item.skGS1pk.includes(
                                    "QUESTIONNAIREID:SCREENING#QUESTIONID:GLOBAL#"
                                )
                        )[0];

                        value = transformDBData({
                            value: questionnaireValue?.ranking,
                            t,
                            language,
                            replaceOption: "-",
                            numberDecimalScale: 1
                        });
                        addSuffix = " %";
                        break;
                    }
                    case "url":
                        value = value ? (
                            <a href={value} target="_blank" rel="noreferrer">
                                {transformDBData({
                                    value: value,
                                    t,
                                    language,
                                    replaceOption: "-"
                                })}
                            </a>
                        ) : (
                            transformDBData({
                                value: value,
                                t,
                                language,
                                replaceOption: "-"
                            })
                        );
                        break;
                    default:
                        value = transformDBData({
                            value: value,
                            t,
                            language,
                            replaceOption: "-"
                        });
                        break;
                }

                return (
                    <Item
                        key={field.name}
                        value={value}
                        fieldName={t(field.name)}
                        icon={cardKanbanFields?.[field.name]?.icon}
                        addSuffix={addSuffix}
                    />
                );
            })}
        </>
    );
};

interface IItem {
    fieldName: string;
    value: any;
    type?: string;
    icon?: string;
    addSuffix: string;
}
const Item = (props: IItem) => {
    const classes = useStyles();
    const { value, type, icon = "star", fieldName, addSuffix } = props;

    return (
        <Grid container className={classes.root}>
            <Grid container item alignContent="center" alignItems="center">
                <Tooltip title={fieldName}>
                    {icon === "whats_app" ? (
                        <WhatsAppIcon
                            fontSize="small"
                            className={classes.fieldIcon}
                        />
                    ) : (
                        <Icon fontSize="small" className={classes.fieldIcon}>
                            {icon}
                        </Icon>
                    )}
                </Tooltip>
                <Tooltip title={value}>
                    <Typography className={classes.fieldText} noWrap>
                        {value}
                        {addSuffix}
                    </Typography>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const Tags = (props: IItem) => {
    const classes = useStyles();
    const { value, type, icon = "star", fieldName } = props;
    const localValue = value || [];
    console.log("tags value", localValue);
    return (
        <Grid container className={classes.root}>
            <Grid container item alignContent="center" alignItems="center">
                <Tooltip title={localValue.join(" - ")}>
                    <Typography
                        className={classes.tags}
                        noWrap
                        component="span"
                    >
                        {localValue.map((tag: any) => (
                            <Chip
                                className={classes.chipCard}
                                // color="secondary"
                                key={tag}
                                size="small"
                                label={tag}
                            />
                        ))}
                    </Typography>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const Evaluation = React.memo((props: IItem) => {
    const classes = useStyles();
    const { value, type, icon, fieldName } = props;

    return (
        <Grid container className={classes.evaluationRoot}>
            <Grid container item alignContent="center" alignItems="center">
                {icon && (
                    <Tooltip title={fieldName}>
                        <Icon fontSize="small" className={classes.fieldIcon}>
                            {icon}
                        </Icon>
                    </Tooltip>
                )}
                <CompleoCompanyRating
                    evaluationValue={value}
                    size="small"
                    showTextValue
                />
            </Grid>
        </Grid>
    );
});

const Ranking = React.memo((props: IItem) => {
    const classes = useStyles();
    const { value, type, icon, fieldName } = props;

    return (
        <Grid container className={classes.evaluationRoot}>
            <Grid container item alignContent="center" alignItems="center">
                {icon && (
                    <Tooltip title={fieldName}>
                        <Icon fontSize="small" className={classes.fieldIcon}>
                            {icon}
                        </Icon>
                    </Tooltip>
                )}
                <CompleoCompanyRating
                    evaluationValue={value}
                    size="small"
                    showTextValue
                />
            </Grid>
        </Grid>
    );
});

export default JobKanbanApplicantFields;
