import React from "react";
import Simulation from "Pages/Questionnaire/Simulation/Simulation";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface IProps {}

const QuestionnaireSimulation = (props: IProps) => {
    const moduleName = "QUESTIONNAIRENEW";
    const [tQuestionnaireNew, i18n, readyTranslation] = useTranslation(
        moduleName,
        {
            useSuspense: false
        }
    );

    if (readyTranslation) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Simulation
                        t={tQuestionnaireNew}
                        name=""
                        questionnaireFieldName="screeningQuestionnaire"
                    />
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

export default QuestionnaireSimulation;
