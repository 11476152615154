import React from "react";
import { useApiCache } from "customHooks/useApi";
import _ from "lodash";
import { getOpinionOnTheApplicantJob, getAgeItem } from "functions/util";

type variableList = {
    value: string;
    label: string;
};

type variables = {
    applicantMain: variableList[];
    applicantEducationHistory: variableList[];
    applicantProfessionalHistory: variableList[];
    applicantLanguages: variableList[];
};

const useCustomCVVariables = (
    companyId: number,
    language: string,
    t?: any
): [variables, boolean] => {
    const [ready, setReady] = React.useState<boolean>(false);
    const [variables, setVariables] = React.useState<variables>({
        applicantMain: [],
        applicantEducationHistory: [],
        applicantProfessionalHistory: [],
        applicantLanguages: []
    });

    const [variablesData] = useApiCache(
        "/customcv/getallvariables",
        "post",
        { companyId, language },
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        if (variablesData?.status === "success") {
            const variables: variables = variablesData.response?.data;
            const ageItem = getAgeItem(t);
            const opinionOnTheApplicantJobItem = getOpinionOnTheApplicantJob(t);

            if (
                !variables.applicantMain.find(
                    (item: variableList) => item.value === ageItem.value
                )
            ) {
                _.set(variables, "applicantMain", [
                    ...variables.applicantMain,
                    ageItem
                ]);
            }

            if (
                !variables.applicantMain.find(
                    (item: variableList) =>
                        item.value === opinionOnTheApplicantJobItem.value
                )
            ) {
                _.set(variables, "applicantMain", [
                    ...variables.applicantMain,
                    opinionOnTheApplicantJobItem
                ]);
            }
            setVariables(variables);
            setReady(true);
        }
    }, [variablesData?.status, variablesData?.response?.data]);

    return [variables, ready];
};

// const getOpinionOnTheApplicantJob = (t: any) =>
//     ({
//         value: "opinionOnTheApplicantJob",
//         label: t("opinionOnTheApplicantJob")
//     } as variableList);

// const getAgeItem = (t: any) =>
//     ({
//         value: "aged",
//         label: String(t("aged"))
//     } as variableList);

export default useCustomCVVariables;
