import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Avatar, Box, Divider, Grid, Typography } from "@material-ui/core";
import { transformDBData } from "functions/util";
import ApplicantEmailRecipientsTags from "../ApplicantEmailRecipientsTags";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { People, Delete, Edit } from "@material-ui/icons";
import {
    IModalEditEvent,
    IModalParticipantsState
} from "Pages/Applicant/ApplicantView/ApplicantSchedule/AplicantScheduleThreadList";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useQueryClient } from "react-query";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import ModalParticipantsStatus from "./ModalParticipantsStatus";
import ModalEditEvent from "./ModalEditEvent";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    eventData: Compleo.IObject;
    t: any;
    language: string;
}
function createMarkup(data: string) {
    return { __html: data };
}

const ModalViewMainEvent = (props: IModalNewMail) => {
    const { open, handleClose, eventData, language, t } = props;
    const dataFromDB = eventData;
    const { company } = useAuthState();
    const showMessage = useShowMessage();

    const [modalParticipantsState, setModalParticipantsState] = React.useState<
        IModalParticipantsState
    >({ open: false, mainData: [], allRecipients: [] });
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const queryClient = useQueryClient();

    const defaultModalEditEventState: IModalEditEvent = {
        open: false,
        scheduleId: ""
    };
    const [modalEditEventState, setModalEditEventState] = React.useState<
        IModalEditEvent
    >(defaultModalEditEventState);
    const handleModalEditEventClose = () => {
        setModalEditEventState(defaultModalEditEventState);
        handleClose();
    };
    const openModalEditEvent = (scheduleId: string) => {
        setModalEditEventState({
            open: true,
            scheduleId
        });
    };

    const handleCancelEvent = (scheduleId: string) => {
        callDialog({
            title: t("cancelEventTitle"),
            bodyText: t("cancelEventMessage"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const result = await apiDirectCall(
                    "/email/cancelscheduleevent",
                    "post",
                    {
                        companyId: company.companyId,
                        scheduleId: scheduleId
                    }
                );
                if (result.status === 200) {
                    queryClient.invalidateQueries("/email/listschedule");
                    showMessage(t("CancelEvent_succesMessage"), "success");
                    handleClose();
                    return true;
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    const ready = true;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {ready && (
                    <Box display="flex" flexDirection="row">
                        <Box marginRight={1}>
                            <CompleoAvatar
                                idParams={{
                                    email: dataFromDB?.createdByUser
                                }}
                                type="user"
                            />
                        </Box>
                        <Box>{`${t("subject")}: ${dataFromDB?.subject}`}</Box>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <>
                                <ButtonIconTooltipCompleo
                                    label={t("iconParticipants")}
                                    onClick={() =>
                                        setModalParticipantsState({
                                            open: true,
                                            mainData:
                                                dataFromDB?.RecipientsStatus ||
                                                [],
                                            allRecipients:
                                                dataFromDB?.recipients || []
                                        })
                                    }
                                >
                                    <People />
                                </ButtonIconTooltipCompleo>
                                <ButtonIconTooltipCompleo
                                    label={t("iconEdit")}
                                    onClick={() =>
                                        openModalEditEvent(
                                            dataFromDB?.pk?.split(":")[1]
                                        )
                                    }
                                >
                                    <Edit />
                                </ButtonIconTooltipCompleo>
                                <ButtonIconTooltipCompleo
                                    label={t("iconDelete")}
                                    onClick={() =>
                                        handleCancelEvent(
                                            dataFromDB?.pk?.split(":")[1]
                                        )
                                    }
                                >
                                    <Delete color="secondary" />
                                </ButtonIconTooltipCompleo>
                            </>
                        </Box>
                    </Box>
                )}
            </DialogTitle>
            <DialogContent dividers>
                {ready ? (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>{`${t("from")}: `}</strong>
                                <ApplicantEmailRecipientsTags
                                    applicantEmail=""
                                    recipients={[
                                        {
                                            email: dataFromDB?.createdByUser,
                                            name: dataFromDB?.createdByUser
                                        }
                                    ]}
                                    t={t}
                                    withoutColors={true}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>{`${t("recipients")}: `}</strong>
                                <ApplicantEmailRecipientsTags
                                    applicantEmail=""
                                    recipients={dataFromDB?.recipients || []}
                                    t={t}
                                    // withoutColors={true}
                                />
                            </Typography>
                        </Grid>
                        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        <Box marginBottom={2}>
                            <strong>{`${t("dateTimeWhen")}: `}</strong>
                            {transformDBData({
                                t: t,
                                language: language,
                                value: dataFromDB.startDateTime,
                                customDateFormat: "dateWithTime"
                            })}
                            <strong>{` ${t("-")} `}</strong>
                            {transformDBData({
                                t: t,
                                language: language,
                                value: dataFromDB.endDateTime,
                                customDateFormat: "time"
                            })}
                            {dataFromDB.location && (
                                <>
                                    <br />
                                    <strong>{`${t("where")}: `}</strong>
                                    {transformDBData({
                                        t: t,
                                        language: language,
                                        value: dataFromDB.location
                                    })}
                                </>
                            )}
                            {dataFromDB.jobDetail?.title && (
                                <>
                                    <br />
                                    <strong>{`${t("job")}: `}</strong>
                                    {transformDBData({
                                        t: t,
                                        language: language,
                                        value: dataFromDB.jobDetail?.title
                                    })}
                                </>
                            )}
                            {dataFromDB.applicantDetail?.name && (
                                <>
                                    <br />
                                    <strong>{`${t("applicant")}: `}</strong>
                                    {transformDBData({
                                        t: t,
                                        language: language,
                                        value: dataFromDB.applicantDetail?.name
                                    })}
                                </>
                            )}
                        </Box>

                        <Grid container>
                            <Grid item container xs={12}>
                                <strong>{`${t("message")}: `}</strong>
                            </Grid>
                            <Grid item container xs={12}>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        (dataFromDB?.body || "").replace(
                                            "\n",
                                            "<br />"
                                        )
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {modalParticipantsState.open && (
                            <ModalParticipantsStatus
                                handleClose={() =>
                                    setModalParticipantsState({
                                        open: false,
                                        mainData: [],
                                        allRecipients: []
                                    })
                                }
                                {...modalParticipantsState}
                                t={t}
                            />
                        )}
                        {modalEditEventState.open && (
                            <ModalEditEvent
                                handleClose={() => handleModalEditEventClose()}
                                {...modalEditEventState}
                                invalidateQuery={["/email/listschedule"]}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="secondary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalViewMainEvent;
