import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { People, PlaylistAddCheck } from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import useList from "customHooks/useList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRight: {
            // marginLeft: "auto"
        }
    })
);

interface IProps {
    fieldData: Compleo.IObject;
    hideViewIcon?: boolean;
}

const OtherActions = (props: IProps) => {
    const { fieldData, hideViewIcon = false } = props;

    const [listData, listDispatch] = useList();

    const { t } = listData.tempInfo;
    const classes = useStyles();

    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );
    const openModalApplicant = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalApplicant: {
                open: true,
                currentIndex: index,
                currentPage:
                    listData.filtersInfo?.filters?.pagination?.currentPage,
                applicantIdFieldName: "applicantDetail.pk"
            }
        });
    };

    const openModalActivity = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalActivity: {
                open: true,
                currentIndex: index,
                currentPage:
                    listData.filtersInfo?.filters?.pagination?.currentPage,
                idFieldName: "pk"
            }
        });
    };

    return (
        <>
            <ButtonIconTooltipCompleo
                className={classes.buttonRight}
                label={t("viewActivity")}
                onClick={() => openModalActivity()}
            >
                <PlaylistAddCheck />
            </ButtonIconTooltipCompleo>
            {!hideViewIcon && (
                <ButtonIconTooltipCompleo
                    className={classes.buttonRight}
                    label={t("viewApplicant")}
                    onClick={() => openModalApplicant()}
                >
                    <People />
                </ButtonIconTooltipCompleo>
            )}
        </>
    );
};

export default OtherActions;
