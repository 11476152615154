import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat, { FormatInputValueFunction } from "react-number-format";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

const CNPJCPFField = (props: IInputProps) => {
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;
    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        name,
        helperTextDefault
    );

    function handleFormat(val: string | FormatInputValueFunction) {
        let fieldValue = String(val).replace(/(\.|\/|\-)/g, "");

        if (fieldValue.length <= 11) {
            //CPF
            fieldValue = fieldValue.replace(/(\d{3})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(/(\d{3})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        } else {
            //CNPJ
            fieldValue = fieldValue.replace(/^(\d{2})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(
                /^(\d{2})\.(\d{3})(\d)/,
                "$1.$2.$3"
            );
            fieldValue = fieldValue.replace(/\.(\d{3})(\d)/, ".$1/$2");
            fieldValue = fieldValue.replace(/(\d{4})(\d)/, "$1-$2");
        }

        return fieldValue;
    }

    return (
        <NumberFormat
            id={name}
            label={label}
            error={error}
            helperText={message}
            format={handleFormat}
            customInput={TextField}
            required={other.required}
            fullWidth={other.fullWidth}
            // {...field} avoid to add this because can impact setFocus property (https://github.com/compleoteam/compleo/issues/344)
            onBlur={field.onBlur}
            value={field.value}
            onChange={undefined}
            onValueChange={(values) => {
                if (values.value === null) {
                    setFieldValue(undefined);
                } else {
                    setFieldValue(values.value);
                }
            }}
            inputRef={field.ref}
            type="text"
        />
    );
};

export default CNPJCPFField;
