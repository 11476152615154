import React, { useReducer } from "react";
import { useApiCache } from "customHooks/useApi";
import {
    IMainState,
    IMainStateAction,
    IJobViewContextDispatch,
    LocalStateDataType,
    IJobViewStages,
    IJobViewStagesActionDefinition,
    ModuleViewType
} from "./JobViewTypes";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    getStateFromDB,
    handleOrderData,
    getSortStagesParams,
    getTotalStageFromDb
} from "../JobKanban/helper";
import { useTranslation } from "react-i18next";
import { IFilters } from "customHooks/useList";
import useGetMetadata from "customHooks/useGetMetadata";
import { pipelineAllActionsFields } from "Pages/Pipeline/components/helperPipeline";
import { getLastPageToRender } from "../JobViewMainHelper";
import useCustomLists from "../../components/useCustomLists";
import { useLanguageState } from "_ReactContext/LanguageContext";

const reducer = (
    state: LocalStateDataType,
    action: IMainStateAction
): LocalStateDataType => {
    switch (action.type) {
        case "update": {
            let stagesConfig = { ...state.stagesConfig };
            if (action.payload?.stagesConfig) {
                stagesConfig = {
                    ...stagesConfig,
                    ...action.payload?.stagesConfig
                };
            }
            return { ...state, ...action.payload, stagesConfig: stagesConfig };
        }
    }
};

const JobViewContext = React.createContext<IMainState | undefined>(undefined);
const JobViewContextDispatch = React.createContext<
    IJobViewContextDispatch | undefined
>(undefined);

interface IJobViewProvider {
    jobId: string;
    module?: ModuleViewType;
    readonly?: boolean;
    children: any;
}
export const JobViewProvider = (props: IJobViewProvider) => {
    const { jobId, children, module = "JOBVIEW", readonly = false } = props;
    const apiKeyApplicant = "JobViewApplicants";
    const apiKeyJob = "JobViewJob";
    const moduleName = module;
    const minimumPageSize = 30;
    const language = useLanguageState();

    const startFilter: IFilters = {
        advancedSearch: {},
        buckets: {},
        customSearch: {},
        filterUpdated: false,
        geoLocation: {},
        mainSearch: null,
        pagination: {
            pageSize: minimumPageSize,
            currentPage: 1,
            fromValue: 0
        },
        searchAsYouType: false,
        sort: {},
        updateAggsAfterFilter: false,
        language: language
    };

    const defaultGeneralPageInfo: LocalStateDataType = {
        pageToRender: getLastPageToRender(),
        pipelineView: "News_InProgress_Approveds",
        ApplicantsData: {},
        ApplicantsPagination: {
            pagination: {
                pageSize: minimumPageSize,
                currentPage: 1,
                fromValue: 0
            }
        },
        minimumPageSize: minimumPageSize,
        filter: startFilter,
        orderData: [],
        filterUpdated: false,
        filterApplied: false,
        allStages: [],
        stagesConfig: {},
        stagesOnlyTotal: [],
        firstRenderFinished: false,
        lastUpdateApplicantJobStage: new Date().toISOString(),
        modalJobBoardsStatusOpen: false,
        modalJobShareOpen: false
    };
    const [localStateData, dispatchLocalStateData] = useReducer(
        reducer,
        defaultGeneralPageInfo
    );

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { company } = useAuthState();
    const kanbanApplicantListModule = "JOBAPPLICANTLISTFILTERKANBAN";
    const [kanbanApplicantListMetadata] = useGetMetadata(
        kanbanApplicantListModule
    );
    const tResponseListFilterKanban = useTranslation(
        [kanbanApplicantListModule, "ELASTICLISTSETTINGS"],
        {
            useSuspense: false
        }
    );

    const postAddressSearch = `/job/search`;
    const [getJobSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: jobId, companyId: company.companyId, searchForEdit: true },
        false,
        1000 * 60 * 5,
        false,
        apiKeyJob
    );

    const jobSearchDBData = getJobSearchResult.response?.data;
    const [listDefinition, readyLists] = useCustomLists(jobSearchDBData?.lists);

    const localApplicantListFilter: Compleo.IObject = {
        jobId: jobId,
        companyId: company.companyId
    };

    const stagesSort = getSortStagesParams(localStateData.stagesConfig);
    const allApplicantsParams: IFilters = {
        ...localStateData.filter,
        ...localApplicantListFilter,
        ...localStateData.ApplicantsPagination,
        otherGenericParams: {
            ...(localStateData.filter.otherGenericParams || {}),
            kanbanFilterList: localStateData.filterApplied === true,
            stageBody: { ...stagesSort }
        }
    };

    const jobApplicantListAddress = "/job/applicantlist";
    const [getApplicantsSearchResult, updateKanbanApplicantsList] = useApiCache(
        jobApplicantListAddress,
        "post",
        allApplicantsParams,
        true,
        1000 * 60 * 5,
        true,
        apiKeyApplicant
    );

    const jobOrderDataAddress = "/job/applicantlistorderdata";
    const orderDataParams = {
        ...localApplicantListFilter,
        updateOrderData: true
    };
    const [getOrderDataSearchResult, updateKanbanOrderData] = useApiCache(
        jobOrderDataAddress,
        "post",
        orderDataParams,
        false,
        1000 * 60 * 5,
        false,
        "applicantKanbanData"
    );

    const orderData =
        getOrderDataSearchResult.response?.data?.orderPipelineData?.priority
            ?.orderData || [];

    // First render load data
    React.useEffect(() => {
        if (
            getOrderDataSearchResult.status === "success" &&
            getApplicantsSearchResult.status === "success"
        ) {
            if (localStateData.orderData.length === 0 && orderData.length > 0) {
                const newOrderData = handleOrderData(orderData);
                const newStateData = getStateFromDB({
                    dbData: getApplicantsSearchResult.response,
                    stateData: localStateData.ApplicantsData,
                    orderData: newOrderData
                });

                dispatchLocalStateData({
                    payload: {
                        orderData: newOrderData,
                        ApplicantsData: newStateData,
                        firstRenderFinished: true
                    },
                    type: "update"
                });
            }
        }
    }, [
        orderData,
        getApplicantsSearchResult.status,
        getOrderDataSearchResult.status
    ]);

    // get stages and location from Job and get totals
    React.useEffect(() => {
        if (
            getJobSearchResult.status === "success" &&
            localStateData.allStages.length === 0 &&
            getOrderDataSearchResult.status === "success"
        ) {
            const dbStages =
                getJobSearchResult.response?.data?.mainData?.pipeline?.stages ||
                [];
            const stagesToUpdate: IJobViewStages[] = dbStages.map(
                (item: any) => {
                    const actionsStage: IJobViewStagesActionDefinition = {};
                    for (const actionName of pipelineAllActionsFields) {
                        if (item[actionName]) {
                            actionsStage[actionName] = item[actionName];
                        }
                    }
                    const obj: IJobViewStages = {
                        id: item.id,
                        name: item.name,
                        fixed: item.fixed,
                        order: item.order,
                        type: item?.type?.value || "",
                        maxDaysInStage: item?.maxDaysInStage,
                        maxItensKanban: item?.maxItensKanban
                    };
                    if (Object.keys(actionsStage).length) {
                        obj.actions = actionsStage;
                    }

                    return obj;
                }
            );
            stagesToUpdate.sort((a, b) => a.order - b.order);
            const stagesTotal = getTotalStageFromDb(
                getOrderDataSearchResult.response?.data,
                stagesToUpdate
            );

            dispatchLocalStateData({
                payload: {
                    allStages: stagesToUpdate,
                    stagesOnlyTotal: stagesTotal
                },
                type: "update"
            });
        }
    }, [
        getJobSearchResult.status,
        localStateData.allStages.length,
        getOrderDataSearchResult.status
    ]);

    // get geolocation from job and run query
    React.useEffect(() => {
        if (getJobSearchResult.status === "success") {
            const jobData = getJobSearchResult.response?.data?.mainData || {};
            const newFilter = allApplicantsParams;
            newFilter.geoLocation = {
                latitude: jobData?.location?.latitude,
                longitude: jobData?.location?.longitude
            };
            updateKanbanApplicantsList(newFilter);
            dispatchLocalStateData({
                payload: {
                    filter: newFilter
                },
                type: "update"
            });
        }
    }, [
        getJobSearchResult.status,
        getJobSearchResult.response?.data?.mainData?.location?.latitude,
        getJobSearchResult.response?.data?.mainData?.location?.longitude
    ]);

    const jobReady =
        readyTranslation && getJobSearchResult.status === "success";
    const applicantListReady =
        readyTranslation && getApplicantsSearchResult.status === "success";

    const jobStatus: CompleoShared.Common.JobStatus =
        getJobSearchResult.response?.data?.mainData?.status;

    const statusNotReadOnly: CompleoShared.Common.JobStatus[] = ["open"];
    const finalReadOnly = readonly || !statusNotReadOnly.includes(jobStatus);

    const returnMain: IMainState = {
        Job: {
            data: getJobSearchResult.response?.data?.mainData || {},
            ready: jobReady
        },
        Applicants: {
            ready: applicantListReady,
            Aggregations:
                getOrderDataSearchResult.response?.data?.aggregations || {}
        },
        JobId: jobId,
        apiKeyJob: getJobSearchResult.useQueryKey,
        apiKeyApplicant: getApplicantsSearchResult.useQueryKey,
        apiKeyOrderData: getOrderDataSearchResult.useQueryKey,
        t: t,
        LocalStateData: localStateData,
        language: i18n.languages[0],
        fullFilter: allApplicantsParams,
        orderDataFilter: orderDataParams,
        apiKanbanListAddress: jobApplicantListAddress,
        apiKanbanOrderDataAddress: jobOrderDataAddress,
        kanbanApplicantListMetadata: kanbanApplicantListMetadata,
        tResponseListFilterKanban: tResponseListFilterKanban,
        EditJobListDefinition: {
            listDefinition: listDefinition,
            ready: readyLists
        },
        readonly: finalReadOnly,
        module: module,
        jobStatus: jobStatus
    };
    const returnDispatch: IJobViewContextDispatch = {
        dispatchGeneralPageInfo: dispatchLocalStateData
    };

    return (
        <JobViewContext.Provider value={returnMain}>
            <JobViewContextDispatch.Provider value={returnDispatch}>
                {children}
            </JobViewContextDispatch.Provider>
        </JobViewContext.Provider>
    );
};

const useValues = () => {
    const context = React.useContext(JobViewContext);
    if (!context) {
        throw new Error(`useValues must be used within a JobViewProvider`);
    }
    const returnData = context;
    return returnData;
};

const useDispatch = () => {
    const context = React.useContext(JobViewContextDispatch);
    if (!context) {
        throw new Error(`useDispatch must be used within a JobViewProvider`);
    }
    const returnData = context;
    return returnData;
};

const useJobViewContext: () => [IMainState, IJobViewContextDispatch] = () => {
    return [useValues(), useDispatch()];
};

export default useJobViewContext;
