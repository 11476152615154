import { Box, List, useMediaQuery } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "./useApplicantViewContext";
import theme from "theme/Theme";
import ApplicantViewSideContainerJobList from "./ApplicantViewSideContainerJobList";
import ApplicantViewSideContainerComments from "./ApplicantComments/ApplicantViewSideContainerComments";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
            // backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0),
            margin: 0
            // height: "65vh"
        },
        listContainer: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0),
            margin: 0
        },
        listItemIcon: {
            minWidth: theme.spacing(2),
            marginRight: theme.spacing(1)
        },
        listItemText: {},
        buttonIconTooltip: {
            padding: theme.spacing(0.5)
        }
    })
);

interface IProps {}

const ApplicantViewSideContainer = (props: IProps) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    // data.i
    const { applicantData, applicantIndex } = data;
    const isXSSMScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));

    return (
        <Box className={classes.container}>
            <List className={classes.listContainer}>
                {/* {!data.jobDataInsideJob && ( */}
                <ApplicantViewSideContainerJobList />
                {/* )} */}
            </List>
            <ApplicantViewSideContainerComments />
        </Box>
    );
};

export default ApplicantViewSideContainer;
