import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import Page from "Pages/_Layouts/DefaultInternal";
import { Link } from "@gatsbyjs/reach-router";
import useGetMetadata from "customHooks/useGetMetadata";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Alert } from "@material-ui/lab";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";

const Reports = () => {
    const moduleName = "REPORTSMAIN";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("REPORTSMAIN");

    const otherPageProps = metadata.response?.data?.OtherPageProps || {};
    const reportsList: Compleo.IObject[] =
        otherPageProps?.reportsList?.list || [];
    const ready = readyTranslation && metadata.status === "success";

    if (ready) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                <Box display="flex" flexDirection="column" width="100%">
                    <Box>
                        <Alert severity="info">{t("instructions")}</Alert>
                    </Box>
                    <Box>
                        <Paper style={{ width: "100%" }}>
                            <Box mt={2}>
                                <List>
                                    {reportsList.map((item) => (
                                        <ListItemLink
                                            key={item.name}
                                            primary={t(
                                                `reportName_${item.name}`
                                            )}
                                            to={item.route}
                                        ></ListItemLink>
                                    ))}
                                </List>
                            </Box>
                        </Paper>
                    </Box>
                    <Box>
                        <AlertUpgradePlan feature="dashboards" />
                    </Box>
                </Box>
            </Page>
        );
    } else {
        return (
            <Page maxWidth="md">
                <Loading />
            </Page>
        );
    }
};

const ListItemLink = (props: any) => {
    const { primary, to } = props;

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link to={to} {...linkProps} />
            )),
        [to]
    );

    return (
        <ListItem button component={CustomLink}>
            <ListItemIcon>
                <ArrowRightAltIcon />
            </ListItemIcon>
            <ListItemText primary={primary} />
        </ListItem>
    );
};

export default Reports;
