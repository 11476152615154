import React from "react";
import JobBoardsModal from "./JobBoardsModal";
import { Button, Grid } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IProps {
    t: any;
}

const JobBoards = (props: IProps) => {
    const { t } = props;
    const { watch, setFieldValue } = useRhfFieldWithoutControllerOrRegister(
        "jobBoards"
    );
    const location = watch("location");
    const jobBoardsValue = watch("jobBoards");

    const [openModal, setOpenModal] = React.useState(false);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button
                    onClick={() => setOpenModal(true)}
                    style={{ marginTop: 16 }}
                    variant="outlined"
                    color="primary"
                    startIcon={<SendIcon />}
                >
                    {t("btnChangeJobBoardsSettings")}
                </Button>

                <JobBoardsModal
                    open={openModal}
                    handleModalClose={() => setOpenModal(false)}
                    updateData={async (values: Compleo.IObject) => {
                        setFieldValue(values);
                    }}
                    initialValues={jobBoardsValue}
                    location={location}
                />
            </Grid>
        </Grid>
    );
};

export default JobBoards;
