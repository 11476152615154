import React from "react";
import "../i18n/i18n";
import { i18f } from "../i18n/i18n";

export const CompanyExternalContext = React.createContext({
    update: (companyId: string) => {},
    value: "0"
});

const UnAuthenticated = (props: any) => {
    const [companyId, setCompanyId] = React.useState("0");

    const plan = "noplan";
    React.useEffect(() => {
        const urli18n = `${process.env.REACT_APP_API_ADDRESS}/general/translation/${companyId}/0`;
        const currentPath = localStorage.getItem("i18nPath");

        if (urli18n !== currentPath) {
            i18f(urli18n);
        }
    }, [plan, companyId]);

    //debugger;
    const finalElement = React.cloneElement(props.children, {
        ...props
    });
    return (
        <CompanyExternalContext.Provider
            value={{
                update: (companyIdParam: string) => {
                    setCompanyId(companyIdParam);
                },
                value: companyId
            }}
        >
            <React.Fragment>{finalElement}</React.Fragment>
        </CompanyExternalContext.Provider>
    );
};

export default UnAuthenticated;
