import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Button, DialogActions } from "@material-ui/core";

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    children: any;
    t: any;
    title: string;
}

export const TemplatePlaceholderDialog = (props: SimpleDialogProps) => {
    const { onClose, open, children, t, title } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            {children}
            <DialogActions>
                <Button size="small" onClick={handleClose}>
                    {t("EMAILDATAFIELD_buttonCloseModal")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
