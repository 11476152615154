import React from "react";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

interface IPropsAddResultButtom {
    handleAddResult: any;
    t: any;
}

const AddResultButton = (props: IPropsAddResultButtom) => {
    const { handleAddResult, t } = props;

    return (
        <Button
            color="secondary"
            size="small"
            style={{ margin: "16px" }}
            startIcon={<EditIcon />}
            onClick={handleAddResult}
        >
            {t("buttonEditQuestionnaireResult")}
        </Button>
    );
};

export default AddResultButton;
