import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    Legend
} from "recharts";
import useJobScorecardData from "./components/useJobScorecardData";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";

const getScorecardGraphData = (
    dataEvaluation: Compleo.IObject[],
    jobId: string
) => {
    const dataReturn: Compleo.IObject[] = [];
    const evaluators: string[] = [];

    dataEvaluation
        .filter(
            (item) => item.jobId === jobId && (item.scorecard || []).length > 0
        )
        .map((item) => {
            item.scorecard.map((itemScorecard: Compleo.IObject) => {
                const dataSubject = dataReturn.filter(
                    (itemSub) => itemSub.subject === itemScorecard.name
                )[0];
                if (dataSubject) {
                    dataReturn.filter(
                        (itemSub) => itemSub.subject === itemScorecard.name
                    )[0][item.evaluator] = itemScorecard.averageRating / 2;
                } else {
                    dataReturn.push({
                        subject: itemScorecard.name,
                        [item.evaluator]: itemScorecard.averageRating / 2,
                        fullMark: 5
                    });
                }
                if (
                    evaluators.filter((itemE) => itemE === item.evaluator)
                        .length === 0
                ) {
                    evaluators.push(item.evaluator);
                }
            });
        });
    return { dataReturn, evaluators };
};

interface IProps {
    open: boolean;
    handleClose: () => void;
    dataEvaluation: Compleo.IObject[];
    t: any;
    jobId: string;
}

const ModalCompareScorecardGraph = (props: IProps) => {
    const { open, handleClose, dataEvaluation, t, jobId } = props;
    const newDataEvaluation = useJobScorecardData(
        jobId,
        dataEvaluation,
        true,
        true
    );
    const data = getScorecardGraphData(newDataEvaluation.mainData, jobId);
    const colorData = useRandomColor({
        objectKeys: data.evaluators,
        luminosity: "dark"
    });
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">{t("scorecard")}</DialogTitle>
            <DialogContent>
                <ResponsiveContainer
                    minHeight={300}
                    minWidth={300}
                    width="100%"
                    height="100%"
                >
                    <RadarChart
                        cx="50%"
                        cy="50%"
                        outerRadius="80%"
                        data={data.dataReturn}
                    >
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis angle={30} domain={[0, 5]} />
                        {data.evaluators.map((evaluator) => (
                            <Radar
                                key={evaluator}
                                name={evaluator}
                                dataKey={evaluator}
                                stroke={colorData.colorsObject[evaluator]}
                                fill={colorData.colorsObject[evaluator]}
                                fillOpacity={0.6}
                            />
                        ))}
                        <Legend />
                    </RadarChart>
                </ResponsiveContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCompareScorecardGraph;

// const data = [
//     {
//         subject: "Math",
//         A: 120,
//         B: 110,
//         fullMark: 150
//     },
//     {
//         subject: "Chinese",
//         A: 98,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "English",
//         A: 86,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "Geography",
//         A: 99,
//         B: 100,
//         fullMark: 150
//     },
//     {
//         subject: "Physics",
//         A: 85,
//         B: 90,
//         fullMark: 150
//     },
//     {
//         subject: "History",
//         A: 65,
//         B: 85,
//         fullMark: 150
//     }
// ];
