import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { ApiCallType, apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useApplicantViewContext from "../useApplicantViewContext";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    parentId?: string;
    usersToNotify?: string[];
}

const ApplicantNewNoteModal = (props: IPropsModal) => {
    const { onClose, open, parentId, usersToNotify } = props;
    const formikRef = React.useRef(null);
    const classes = useStyles();
    const [isUpdating, setIsUpdating] = React.useState(false);

    const showMessage = useShowMessage();
    const queryClient = useQueryClient();
    // const [jobData] = useJobContext();
    const [data] = useApplicantViewContext();

    const { company } = useAuthState();
    const module = "APPLICANTNOTE";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, true, {
        applicantId: data.applicantId
    });
    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        const postAddress = `/applicant/newapplicantnote`;
        try {
            // console.log("values", JSON.stringify(values, null, 4));
            const postValues = _.cloneDeep(values);
            if (parentId) {
                postValues.parentId = parentId;
            }
            if (usersToNotify) {
                postValues.usersToNotify = usersToNotify;
            }

            const dataAPI = await apiDirectCall(
                postAddress,
                "post",
                postValues
            );
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries("/applicant/applicantnotelist");
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const handleSave = () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            <DialogTitle>{t("modalTitle")}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApplicantNewNoteModal;
