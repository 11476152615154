import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useList from "../..";
import {
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    TextField,
    InputAdornment,
    Typography,
    Grid,
    RadioGroup,
    Radio
} from "@material-ui/core";
import { FixedSizeList as ListReactWindow } from "react-window";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import BulkActionsListItem from "./BulkActionsListItem";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { matchSorter } from "match-sorter";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { IListBulk } from "./helperBulkActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        },
        textSearch: {
            // marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        radioGroup: {
            marginBottom: theme.spacing(1)
        }
    })
);

type RadioValuesType = "all" | "selected" | "unselected";

interface IProps {
    listBulk: IListBulk[];
    setListBulk: React.Dispatch<React.SetStateAction<IListBulk[]>>;
    openDialog: boolean;
}

const BulkActionsList = (props: IProps) => {
    const classes = useStyles();
    const [listData] = useList();
    const { listBulk, setListBulk, openDialog } = props;

    const [selectAllChecked, setSelectAllChecked] = React.useState(true);
    const { t } = listData.tempInfo;
    const [textSearch, setTextSearch] = React.useState("");
    const [radioValue, setRadioValue] = React.useState<RadioValuesType>("all");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue: RadioValuesType = event.target.value as RadioValuesType;
        setRadioValue(newValue);
    };

    const totalItems = listBulk.length;
    const selectedItems = listBulk.filter((item) => item.checked).length;

    const changeItemChecked = (value: string, checked: boolean) => {
        const newState = [...listBulk];
        newState.filter((item) => item.value === value)[0].checked = checked;
        setListBulk(newState);
    };

    React.useEffect(() => {
        if (selectAllChecked && totalItems !== selectedItems) {
            setSelectAllChecked(false);
        }
        if (!selectAllChecked && totalItems === selectedItems) {
            setSelectAllChecked(true);
        }
    }, [totalItems, selectedItems]);

    const handleSelectAll = (currentValue: boolean) => {
        const newValue = !currentValue;
        const newState = [...listBulk].map((item) => {
            return { ...item, checked: newValue };
        });
        setListBulk(newState);
    };

    /**
     * Filter by text search
     */
    const filteredTextArray: Compleo.IObject[] = textSearch
        ? matchSorter(listBulk, textSearch, {
              keys: ["label"]
          })
        : listBulk;

    /**
     * Show all, selected or unselected options
     */
    const filteredArray: Compleo.IObject[] = filteredTextArray.filter(
        (item) => {
            switch (radioValue) {
                case "unselected":
                    return item.checked === false;
                case "selected":
                    return item.checked === true;
                default:
                    return true;
            }
        }
    );

    const Row = ({ index, style }: any) => {
        const item = filteredArray[index];

        return (
            <div style={style}>
                <BulkActionsListItem
                    item={item}
                    changeItemChecked={changeItemChecked}
                />
            </div>
        );
    };

    if (totalItems > 0) {
        return (
            <Grid container>
                <Grid
                    item
                    container
                    xs={12}
                    // alignContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectAllChecked}
                                    onChange={() =>
                                        handleSelectAll(selectAllChecked)
                                    }
                                />
                            }
                            label={t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_SelectAll"
                            )}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} justify="flex-end">
                        <Typography>
                            {t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_SelectedOf",
                                { selected: selectedItems, total: totalItems }
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <RadioGroup
                        // aria-label="gender"
                        // name="gender1"
                        value={radioValue}
                        onChange={handleRadioChange}
                        row
                        className={classes.radioGroup}
                    >
                        <FormControlLabel
                            value="all"
                            control={<LocalRadio />}
                            label={t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_All"
                            )}
                        />
                        <FormControlLabel
                            value="selected"
                            control={<LocalRadio />}
                            label={t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_Selected"
                            )}
                        />
                        <FormControlLabel
                            value="unselected"
                            control={<LocalRadio />}
                            label={t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_Unselected"
                            )}
                        />
                    </RadioGroup>
                </Grid>
                <Grid item container xs={12}>
                    <TextField
                        className={classes.textSearch}
                        placeholder={t(
                            "ELASTICLISTSETTINGS:searchBucketPlaceholder"
                        )}
                        fullWidth
                        value={textSearch}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ButtonIconTooltipCompleo
                                        label="Clear"
                                        onClick={() => setTextSearch("")}
                                    >
                                        <CloseIcon />
                                    </ButtonIconTooltipCompleo>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item container xs={12}>
                    <ListReactWindow
                        className="List"
                        height={300}
                        itemCount={filteredArray.length}
                        itemSize={25}
                        width="100%"
                    >
                        {Row}
                    </ListReactWindow>
                </Grid>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const LocalRadio = (props: any) => {
    return <Radio size="small" color="primary" {...props} />;
};

export default BulkActionsList;
