import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

export interface IModalUpgradeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    t: any;
    ready: boolean;
}

const ModalUpgradePlan = (prop: IModalUpgradeProps) => {
    const { open, setOpen, message, t, ready } = prop;

    const handleClose = () => {
        setOpen(false);
    };

    if (ready) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("ModalTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">""</DialogTitle>
                <DialogContent>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

export default ModalUpgradePlan;
