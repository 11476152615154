import { ListDefinitionType } from "Pages/Users/components/useCustomLists";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import useHandleInitialValues from "Pages/ApplicationForm/components/useHandleInitialValues";
import { IJobInitialValuesDBData } from "./helper";

const useHandleInitialValuesJob = (
    valuesFromSource: Compleo.IObject,
    jobInitialValuesDBData: IJobInitialValuesDBData,
    listDefinition: ListDefinitionType[],
    readyLists: boolean
) => {
    const languages = jobInitialValuesDBData.languageSettings?.languagesCareers;
    const defaultLanguage =
        jobInitialValuesDBData.languageSettings?.defaultLanguageCareers?.value;

    const languagesString = (languages || []).map((l: any) => l.value);

    const [initialValues, setInitialValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: { ...valuesFromSource } });

    const [
        applicationFormDefaultValues,
        setApplicationFormDefaultValues
    ] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    const [pipelineDefaultValues, setPipelineDefaultValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    const [
        translatedDataDefaultValues,
        setTranslatedDataDefaultValues
    ] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    const { user, company } = useAuthState();

    React.useEffect(() => {
        if (
            readyLists &&
            !initialValues.ready &&
            valuesFromSource.title !== undefined
        ) {
            let defaultValues: Compleo.IObject = {};
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            defaultValues.openingDate = currentDate.toISOString();
            defaultValues.status = "draft";
            const requesterList = listDefinition.filter(
                (l) => l.fieldName === "requester"
            );
            if (
                requesterList &&
                requesterList.length > 0 &&
                requesterList[0].data &&
                Array.isArray(requesterList[0].data)
            ) {
                const defaultRequester = requesterList[0].data.filter(
                    (r) => r.itemValue === user
                );
                if (defaultRequester && defaultRequester.length > 0) {
                    defaultValues.requester = {
                        value: defaultRequester[0].itemValue,
                        label: defaultRequester[0].itemText
                    };
                }
            }
            setInitialValues({
                ready: true,
                values: { ...valuesFromSource, ...defaultValues }
            });
        }
    }, [readyLists, valuesFromSource]);

    React.useEffect(() => {
        if (jobInitialValuesDBData.defaultApplicationFrom) {
            const defaultAF = jobInitialValuesDBData.defaultApplicationFrom;
            setApplicationFormDefaultValues({
                ready: true,
                values: {
                    applicationForm: {
                        name: defaultAF.name,
                        id: defaultAF.skGS1pk,
                        formData: defaultAF.formData,
                        translations: defaultAF.translations
                    }
                }
            });
        }
    }, [jobInitialValuesDBData.defaultApplicationFrom]);

    // load default pipeline
    React.useEffect(() => {
        if (jobInitialValuesDBData.defaultPipeline) {
            const defaultPipeline = jobInitialValuesDBData.defaultPipeline;
            setPipelineDefaultValues({
                ready: true,
                values: {
                    pipeline: {
                        name: defaultPipeline.name,
                        id: defaultPipeline.skGS1pk,
                        stages: defaultPipeline.stages
                    }
                }
            });
        }
    }, [jobInitialValuesDBData.defaultPipeline]);

    // load default languages transleteddata
    React.useEffect(() => {
        if (
            !translatedDataDefaultValues.ready &&
            languagesString &&
            languagesString.length > 0 &&
            initialValues.ready
        ) {
            const fieldsToAdd: string[] = ["title", "description"];
            const TranslatedData: Compleo.IObject = {};

            for (const l of languagesString.filter(
                (l: string) => defaultLanguage !== l
            )) {
                TranslatedData[l] = {};
                for (const f of fieldsToAdd) {
                    TranslatedData[l][f] = initialValues.values[f];
                }
            }

            setTranslatedDataDefaultValues({
                ready: true,
                values: {
                    translatedData: {
                        ...TranslatedData
                    }
                }
            });
        }
    }, [languagesString, initialValues.ready]);

    const finalValues: Compleo.IObject = {
        ...valuesFromSource,
        ...initialValues.values,
        ...applicationFormDefaultValues.values,
        ...pipelineDefaultValues.values,
        ...translatedDataDefaultValues.values
    };

    const {
        initialValues: applicationFormInitialValues,
        translations,
        readyInitialValues,
        messages
    } = useHandleInitialValues(
        finalValues.applicationForm || {},
        languagesString || [],
        defaultLanguage,
        true
    );

    // useHandleInitialValues
    const ready =
        initialValues.ready &&
        applicationFormDefaultValues.ready &&
        pipelineDefaultValues.ready &&
        translatedDataDefaultValues.ready &&
        readyInitialValues;

    const initialValuesReturn: Compleo.IObject = {
        ...finalValues,
        applicationForm: { ...applicationFormInitialValues }
    };
    return {
        initialValues: initialValuesReturn,
        translations: translations,
        languages: languages,
        defaultLanguage: defaultLanguage,
        messages: messages,
        ready: ready
    };
};

export default useHandleInitialValuesJob;
