import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";

const useNextPageFunctionsNewCustomer = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const verifyNameAndCNPJCPF = async (nextPageFunction: any, values: any) => {
        const nameParams = {
            fieldValueToValidate: values.name,
            companyId,
            validationType: "customerNameExists"
        };
        const url = "/general/genericvalidationlogged";
        const nameValidation = await apiDirectCall(url, "post", nameParams);
        const isValidName = nameValidation.data.validationResult;

        if (!isValidName) {
            //console.log("antes do callDialog");
            callDialog({
                title: t("nameExists_ModalTitle"),
                bodyText: t("nameExists_Message"),
                agreeButtonText: t("nameExists_Yes"),
                disagreeButtonText: t("nameExists_No"),
                agreeFunction: async () => {
                    const searchCustomerUrl = "/customer/search";
                    const customerData = await apiDirectCall(
                        searchCustomerUrl,
                        "post",
                        {
                            name: values.name.trim(),
                            companyId: companyId
                        }
                    );
                    if (customerData?.data?.data) {
                        const uuid: string = customerData?.data?.data.skGS1pk.split(
                            ":"
                        )[1];
                        navigate(`/customer/edit/${uuid}`);
                    }
                },
                disagreeFunction: () => {
                    nextPageFunction(true);
                }
            });
        } else if (values.id) {
            const cnpjCpfParams = {
                fieldValueToValidate: values.id,
                companyId,
                validationType: "customerCNPJCPFExists"
            };
            const cnpjCpfValidation = await apiDirectCall(
                url,
                "post",
                cnpjCpfParams
            );
            const isValidCnpjCpf = cnpjCpfValidation.data.validationResult;

            if (!isValidCnpjCpf) {
                callDialog({
                    title: t("CNPJCPFExists_ModalTitle"),
                    bodyText: t("CNPJCPFExists_Message"),
                    agreeButtonText: t("CNPJCPFExists_Yes"),
                    disagreeButtonText: t("CNPJCPFExists_No"),
                    agreeFunction: async () => {
                        const searchCustomerUrl = "/customer/search";
                        const customerData = await apiDirectCall(
                            searchCustomerUrl,
                            "post",
                            {
                                id: values.id,
                                companyId: companyId
                            }
                        );

                        if (customerData?.data?.data) {
                            const uuid: string = customerData?.data?.data.skGS1pk.split(
                                ":"
                            )[1];
                            navigate(`/customer/edit/${uuid}`);
                        }
                    },
                    disagreeFunction: () => {
                        nextPageFunction(true);
                    }
                });
            } else {
                nextPageFunction();
            }
        } else {
            nextPageFunction();
        }
    };

    const nextPageFunctions = [{ page: 1, fn: verifyNameAndCNPJCPF }];

    return nextPageFunctions;
};

export default useNextPageFunctionsNewCustomer;
