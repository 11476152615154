import React from "react";
import Page from "../_Layouts/DefaultInternal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import GenerateRoutes from "Config/useRoutes";
import Authenticated from "auth/Authenticated";
import UnAuthenticatedNotFound from "auth/UnAuthenticatedNotFound";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3, 2),
            width: "100%"
        },
        tipoDetail: {
            marginTop: theme.spacing(3)
        }
    })
);

const PageNotAuthorized = () => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("PAGENOTAUTHORIZED", {
        useSuspense: false
    });

    if (readyTranslation) {
        return (
            <Page>
                <Paper className={classes.paper}>
                    <Typography variant="h5" component="h3" color="secondary">
                        {t("title")}
                    </Typography>
                    <Typography component="p" className={classes.tipoDetail}>
                        {t("description")}
                    </Typography>
                </Paper>
            </Page>
        );
    } else {
        return <Loading />;
    }
};

export default PageNotAuthorized;
