import React from "react";
import PageExternal from "Pages/_Layouts/DefaultExternal";

import { FallbackProps } from "react-error-boundary";
import { useAuthState } from "_ReactContext/AuthContext";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { sendErrorToDB, getErrorObject } from "./helper";
import * as Sentry from "@sentry/react";

interface IErrorFallBack {
    errorCompleoCode?: string;
}
const ErrorFallback = (props: FallbackProps & IErrorFallBack) => {
    const { error, resetErrorBoundary, errorCompleoCode } = props;
    const [t, i18n, readyTranslation] = useTranslation("ERRORPAGE", {
        useSuspense: false
    });

    if (readyTranslation) {
        return (
            <PageExternal>
                <Grid container spacing={4} style={{ marginTop: 48 }}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <AlertTitle>{t("title")}</AlertTitle>
                            {t("description")}
                            <br />
                            <br />
                            <strong>
                                {errorCompleoCode
                                    ? t("informError", {
                                          errorCompleoCode: errorCompleoCode
                                      })
                                    : null}
                            </strong>
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => window.location.reload()}
                            variant="contained"
                            color="primary"
                        >
                            {t("btnReload")}
                        </Button>
                        <Button
                            onClick={() => resetErrorBoundary()}
                            variant="outlined"
                            color="secondary"
                            style={{ marginLeft: 16 }}
                        >
                            {t("bntGoHome")}
                        </Button>
                    </Grid>
                </Grid>
            </PageExternal>
        );
    } else {
        return (
            <PageExternal>
                <Loading />
            </PageExternal>
        );
    }
};

interface IErrorHandle {
    children: any;
}
const ErrorHandler = (props: IErrorHandle) => {
    const allAuthData = useAuthState();
    const [errorCode, setErrorCode] = React.useState<string>("");
    const [
        errorObject,
        setErrorObject
    ] = React.useState<CompleoShared.ErrorLog.IErrorObject | null>(null);

    React.useEffect(() => {
        if (errorObject) {
            sendErrorToDB(errorObject).then((errorId) => {
                setErrorCode(errorId);
            });
        }
    }, [errorObject?.errorMessage, errorObject?.path]);

    const myErrorHandler = async (
        error: Error,
        info: { componentStack: string }
    ) => {
        const errorObject = getErrorObject(
            "ErrorBoundary",
            error,
            info.componentStack,
            undefined,
            undefined,
            undefined,
            undefined,
            allAuthData?.company?.companyId,
            allAuthData?.company?.companyName || "",
            allAuthData?.user || ""
        );
        setErrorObject((oldError) => {
            if (!oldError) {
                return errorObject;
            } else {
                return null;
            }
        });
        // await sendErrorToDB(errorObject, setErrorCode, errorCode);
    };
    React.useEffect(() => {
        if (process.env.REACT_APP_ENVMAIN !== "DEV") {
            setErrorCode("");
            // startErrorLog();
        }
    }, []);

    return (
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetError}
                    errorCompleoCode={errorCode}
                />
            )}
            onError={(error, componentStack, eventId) => {
                const errorObjectData = getErrorObject(
                    "ErrorBoundary",
                    error,
                    componentStack,
                    undefined,
                    undefined,
                    undefined,
                    error?.message,
                    allAuthData?.company?.companyId,
                    allAuthData?.company?.companyName || "",
                    allAuthData?.user || "",
                    eventId
                );
                setErrorObject(errorObjectData);
            }}
        >
            {props.children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorHandler;
