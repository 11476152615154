import React from "react";
import { FieldProps } from "formik";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";
import { getErrorAndMessages } from "./util/general";
import { getControlOptionsAndChildrenFilter } from "./util/AutoCompleteUtil";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    options: any;
    customLists: Compleo.IObject[];
    parentFilterField?: Compleo.IObject;
    childrenFilterFieldName?: string;
    required: boolean;
    campoMetadata: Compleo.IObject;
    row?: boolean;
    language?: string;
}

const RadioButtonGroup = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue, values },
        options,
        campoMetadata,
        customLists,
        helperTextDefault,
        ...other
    } = props;

    const language = props.language || "pt-BR";
    const row = other.row === undefined ? false : other.row;

    const { error, message } = getErrorAndMessages(
        errors,
        field.name,
        touched,
        status,
        helperTextDefault
    );

    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilter(
        campoMetadata,
        options,
        customLists,
        field,
        values,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;
    const childrenFilterFieldName =
        controlOptionsAndChildrenFilter.childrenFilterFieldName;

    let defaultValue: any = undefined;
    let controlValue = defaultValue;

    if (
        values[field.name] !== "" &&
        values[field.name] !== undefined &&
        values[field.name] !== null
    ) {
        const valueFromOptions = controlOptions.filter(
            (co: any) => co.value === values[field.name].value
        )[0];
        // controlValue = JSON.stringify(valueFromOptions);
        controlValue = valueFromOptions;
        // controlValue = JSON.stringify(values[field.name]);
    }
    return (
        <FormControl component="fieldset" error={error}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                row={row}
                aria-label={label}
                name={field.name}
                value={controlValue?.value}
                onChange={(event: any, newValue: any | null) => {
                    if (newValue === null) {
                        setFieldValue(field.name, defaultValue);
                    } else {
                        const valueToSet = controlOptions.filter(
                            (co: any) => co.value === newValue
                        )[0];
                        setFieldValue(field.name, valueToSet);
                    }
                    if (
                        props.childrenFilterFieldName !== undefined &&
                        props.childrenFilterFieldName !== ""
                    ) {
                        setFieldValue(
                            props.childrenFilterFieldName,
                            defaultValue
                        );
                    }
                }}
            >
                {controlOptions.map((c: any) => {
                    const labelOption = c.label || c[`label-${language}`] || "";
                    return (
                        <FormControlLabel
                            key={c.value}
                            value={c.value}
                            control={<Radio />}
                            label={labelOption}
                            aria-label={labelOption}
                        />
                    );
                })}
                <FormHelperText>{message}</FormHelperText>
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonGroup;
