import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { ThumbUp, ThumbDown, NotInterested } from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(0.8),
            width: "fit-content",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
            "& svg": {
                margin: theme.spacing(0.5)
            },
            "& hr": {
                margin: theme.spacing(0, 0.5)
            }
        },
        margin: {},
        iconFilledRed: {
            color: "#ff3d47"
        },
        iconFilledNeutral: {
            color: "orange"
        },
        iconFilledGreen: {
            color: "green"
        }
    })
);

interface IProps {
    value: number;
    setValue: (val: number) => void;
}
const RatingCompleoThumbs = (props: IProps) => {
    const classes = useStyles();
    const { value, setValue } = props;

    return (
        <div>
            <Grid container alignItems="center" className={classes.root}>
                <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={() => setValue(1)}
                >
                    <ThumbDown
                        fontSize="small"
                        className={
                            value === 1 ? classes.iconFilledRed : undefined
                        }
                    />
                    <ThumbDown
                        fontSize="small"
                        className={
                            value === 1 ? classes.iconFilledRed : undefined
                        }
                    />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={() => setValue(2)}
                >
                    <ThumbDown
                        fontSize="small"
                        className={
                            value === 2 ? classes.iconFilledRed : undefined
                        }
                    />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={() => setValue(3)}
                >
                    <NotInterested
                        fontSize="small"
                        className={
                            value === 3 ? classes.iconFilledNeutral : undefined
                        }
                    />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={() => setValue(4)}
                >
                    <ThumbUp
                        fontSize="small"
                        className={
                            value === 4 ? classes.iconFilledGreen : undefined
                        }
                    />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.margin}
                    size="small"
                    onClick={() => setValue(5)}
                >
                    <ThumbUp
                        fontSize="small"
                        className={
                            value === 5 ? classes.iconFilledGreen : undefined
                        }
                    />
                    <ThumbUp
                        fontSize="small"
                        className={
                            value === 5 ? classes.iconFilledGreen : undefined
                        }
                    />
                </IconButton>
            </Grid>
        </div>
    );
};

export default RatingCompleoThumbs;
