import { Box, Grid, Paper } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FunnelItem from "./components/Item";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApiCache } from "customHooks/useApi";
import JobActivitiesGraph from "./components/JobActivitiesGraph";
import JobActivitiesTable from "./components/JobActivitiesTable";
import { IJobReportChildren, IMainReportData } from "./components/reportHelper";
import { Alert } from "@material-ui/lab";
import JobActivitiesByStageTable from "./components/JobActivitiesByStageTable";
import JobActivitiesByUserTable from "./components/JobActivitiesByUserTable";
import JobActivitiesByUserAndStageTable from "./components/JobActivitiesByUserAndStageTable";

const maxThemeSpacingParent = 70;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperGraph: {
            margin: theme.spacing(1),
            // maxHeight: theme.spacing(maxThemeSpacingParent),
            height: "100%",
            maxWidth: "90vw"
        },
        paperTable: {
            margin: theme.spacing(1),
            height: "100%",
            maxWidth: "90vw"
        }
    })
);
interface IJob {
    jobId: string;
    jobTitle: string;
    externalEvaluationId?: string;
}

const JobActivitiesRecruiter = (props: IJob) => {
    const classes = useStyles();
    const { jobId, jobTitle, externalEvaluationId } = props;
    const { company } = useAuthState();
    const [t, i18n, readyTranslation] = useTranslation("JOBACTIVITIESREPORT", {
        useSuspense: false
    });

    const reportToShow: CompleoShared.Reports.IJobReportsToRender =
        "activitiesRecruiter";

    const listAddress = !externalEvaluationId
        ? `/reports/activitiesjob`
        : `/reports/evaluationreportsexternal`;

    const postData = !externalEvaluationId
        ? {
              jobId: jobId,
              companyId: company.companyId
          }
        : { evaluationId: externalEvaluationId, reportToShow: reportToShow };

    const [list] = useApiCache(listAddress, "post", postData, false);
    const data: IMainReportData = list?.response?.data || {};
    const ready = readyTranslation && list.status === "success";

    const childrenProps: IJobReportChildren = {
        mainData: data,
        t: t
    };

    const hasNoActivities = ready && (data?.activities || []).length === 0;

    if (!hasNoActivities) {
        return (
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperGraph}>
                        <FunnelItem
                            ready={ready}
                            t={t}
                            itemName="activitiesGraph"
                        >
                            <JobActivitiesGraph {...childrenProps} />
                        </FunnelItem>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paperTable}>
                        <FunnelItem
                            ready={ready}
                            t={t}
                            itemName="activitiesByUserTable"
                        >
                            <JobActivitiesByUserTable
                                {...childrenProps}
                                exportFileName={`JobRejectedReasons_${jobTitle}`}
                            />
                        </FunnelItem>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperTable}>
                        <FunnelItem
                            ready={ready}
                            t={t}
                            itemName="activitiesByUserAndStageTable"
                        >
                            <JobActivitiesByUserAndStageTable
                                {...childrenProps}
                                exportFileName={`JobRejectedReasons_${jobTitle}`}
                            />
                        </FunnelItem>
                    </Paper>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12}>
                    <Alert severity="warning" style={{ margin: 16 }}>
                        {t("COMPLEOGENERAL_NoDataAvailable")}
                    </Alert>
                </Grid>
            </Grid>
        );
    }
};

export default JobActivitiesRecruiter;
