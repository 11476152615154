import React from "react";
import ItemCard from "./ItemCard";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import EmbedLink from "./EmbedLink";
import { IFieldProps } from ".";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldDefault = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, returnType, url } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const localFieldName = cardFields[fieldName].fieldName || fieldName;
    switch (returnType) {
        case "card":
            const value = transformDBData({
                value: _.get(fieldData, localFieldName),
                t,
                language,
                replaceOption: "-"
            });
            return (
                <ItemCard
                    label={
                        cardFields[fieldName].cardIcon ? (
                            <Icon color="action" fontSize="small">
                                {cardFields[fieldName].cardIcon}
                            </Icon>
                        ) : (
                            `${t(fieldName)}: `
                        )
                    }
                    removeColonAfterLabel={true}
                >
                    <EmbedLink url={url}>{value}</EmbedLink>
                </ItemCard>
            );
        case "table":
            return (
                <EmbedLink url={url}>
                    {transformDBData({
                        value: _.get(fieldData, localFieldName),
                        t,
                        language,
                        replaceOption: "-"
                    })}
                </EmbedLink>
            );
    }
};

export default FieldDefault;
