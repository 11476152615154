import React from "react";
import {
    fade,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core/styles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useTranslation } from "react-i18next";
import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import { Drawer, useMediaQuery } from "@material-ui/core";
import MenuCompleoSmall from "./MenuCompleoSmall";
import MenuCompleoNormal from "./MenuCompleoNormal";
import useSessionValues from "_ReactContext/SessionValues";
import theme from "theme/Theme";

const useStyles = (sideMenuWidth: number) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            nested: {
                paddingLeft: theme.spacing(4)
            },
            listIcon: {
                minWidth: 36
            },
            list: {
                overflow: "hidden",
                "&:hover": {
                    overflowY: "scroll"
                }
            },
            drawer: {
                [theme.breakpoints.up("sm")]: {
                    width: sideMenuWidth,
                    flexShrink: 0
                }
            },
            drawerPaper: {
                width: sideMenuWidth,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.dark
            },
            toolbar: theme.mixins.toolbar,
            fontMenu: {
                fontWeight: 500
                // font: "Roboto"
            }
        })
    );
};

interface IProps {
    container?: Element;
    fullMenu: boolean;
    handleDrawerToggle: () => void;
}

const MenuCompleo = (props: IProps) => {
    const { fullMenu, handleDrawerToggle, container } = props;
    const [sessionData] = useSessionValues();
    const { company, user } = useAuthState();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);
    const [t, i18n, readyTranslation] = useTranslation("NAVIGATION", {
        useSuspense: false
    });
    const classes = useStyles(sessionData.menuWidth)();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    const navigation: any = queryNavigation.data;
    const onlyShowFullMenu = verifyShowFullMenu(navigation);

    if (
        queryNavigation.status === "success" &&
        navigation !== null &&
        navigation !== undefined &&
        navigation.features !== undefined &&
        navigation.features.length > 0 &&
        readyTranslation
    ) {
        if (isMobile || fullMenu || onlyShowFullMenu) {
            return (
                <MenuCompleoNormal
                    t={t}
                    navigation={navigation}
                    fullMenu={fullMenu}
                    handleDrawerToggle={handleDrawerToggle}
                    container={container}
                    isMobile={isMobile}
                />
            );
        } else {
            return <MenuCompleoSmall t={t} navigation={navigation} />;
        }
    } else {
        return null;
    }
};

export const verifyShowFullMenu = (navigation: any) => {
    if (Array.isArray(navigation?.features)) {
        let showJobs = false;
        let showApplicants = false;
        // const uniqueNamesToVerify = ["job"]
        navigation.features.map((i: Compleo.IObject) => {
            if (Array.isArray(i.items)) {
                i.items.map((item: Compleo.IObject) => {
                    if (item.uniqueName === "joball" && item.shortMenuShow) {
                        showJobs = true;
                    }
                    if (
                        item.uniqueName === "allapplicants" &&
                        item.shortMenuShow
                    ) {
                        showApplicants = true;
                    }
                });
            }
        });
        if (showJobs && showApplicants) {
            return false;
        } else {
            return true;
        }
    }
    return false;
};

export default MenuCompleo;
