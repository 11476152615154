import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";

const TrialDetail = (props: any) => {
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "TRIALDETAIL";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const [metadata] = useGetMetadata(moduleName);

    const postAddress = `/trial/editApprovement/${props.id}`;
    const [editModuleReturn, editModule] = useApi(postAddress, "post");

    const postAddressSearch = `/trial/search/${props.id}`;
    const [getModuleReturn] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getModuleReturn,
        ["data", "data", "Items"]
    );
    valuesFromSource.triaapproval = [];
    valuesFromSource.statusLabel = ((valuesFromSource || {}).status || {})[
        "label-pt-BR"
    ];
    valuesFromSource.status = null;

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editModule,
        editModuleReturn,
        "/trial/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && getModuleReturn.status === "success" && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};
export default TrialDetail;
