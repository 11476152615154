import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EditorState, Modifier, ContentState } from "draft-js";
import IconButton from "@material-ui/core/IconButton";
import { useField } from "formik";
import { TemplatePlaceholderDialog } from "./RichTextVars";
import { DialogChooseTemplateOrPlaceholderBody } from "./DialogChooseTemplateOrPlaceholder";
import htmlToDraft from "html-to-draftjs";
import { ITemplateEmailData } from "./EmailData";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import { Button, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        padding: "4px"
    },
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

const RichTextToolbarItemLoadPlaceholder = (props: any) => {
    const classes = useStyles();
    const [fieldSubject, , helpersSubject] = useField(props.subjectFieldName);
    const [fieldAttachmetns, , helpersAttachments] = useField(
        props.attachmentsFieldName
    );
    // attachmentsFieldName
    const {
        editorState,
        onChange,
        placeholders = [],
        t,
        language = "pt-BR"
    } = props;
    const [open, setOpen] = React.useState(false);
    const { company } = useAuthState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    // const handleChooseAnPlaceholderOriginal = async (value: string) => {
    //     const contentState = Modifier.replaceText(
    //         editorState.getCurrentContent(),
    //         editorState.getSelection(),
    //         `{{${value}}}`,
    //         editorState.getCurrentInlineStyle()
    //     );

    //     const newEditorState = EditorState.push(
    //         editorState,
    //         contentState,
    //         "insert-characters"
    //     );
    //     // await onChange(newEditorState);
    //     // setOpen(false);
    //     handleClose().then((data) => {
    //         onChange(newEditorState);
    //     });
    // };

    /**
     * Insert a placeholder into the editor. In this case, the cursor moves after the placeholder.
     * @param value Value to be inserted
     */
    const handleChooseAnPlaceholder = async (value: string) => {
        debugger;

        const currentSelection = editorState.getSelection();

        const contentState = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            `{{${value}}}`
        );

        const newState = EditorState.createWithContent(contentState);
        // const stateWithContentAndSelection = EditorState.forceSelection(
        //     newState,
        //     currentSelection
        // );
        const nextOffSet = currentSelection.getFocusOffset() + value.length + 4;

        const newSelection = editorState.getSelection().merge({
            focusOffset: nextOffSet,
            anchorOffset: nextOffSet
        });
        const finalState = EditorState.forceSelection(newState, newSelection);

        handleClose().then((data) => {
            onChange(finalState);
        });
        // setOpen(false);
        // const newEditorState = EditorState.push(
        //     editorState,
        //     contentState,
        //     "insert-characters"
        // );
        // await onChange(newEditorState);
    };

    /**
     * Insert a placeholder into the editor. In this case, the cursor moves before the placeholder.
     * @param value Value to be inserted
     */
    const handleChooseAnPlaceholderOpt2 = async (value: string) => {
        debugger;

        const currentSelection = editorState.getSelection();

        const contentState = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            `{{${value}}}`
        );

        const newState = EditorState.createWithContent(contentState);
        const stateWithContentAndSelection = EditorState.forceSelection(
            newState,
            currentSelection
        );

        handleClose().then((data) => {
            onChange(stateWithContentAndSelection);
        });
    };

    const handleChooseAnTemplate = async (
        templateParam: ITemplateEmailData
    ) => {
        const defaultId = templateParam.items.filter((i) => i.default)[0]?.id;
        const languageID = templateParam.items.filter(
            (i) => i.language === language
        )[0]?.id;

        const dataReturn = await apiDirectCall("/email/getemail", "post", {
            id: languageID || defaultId,
            companyId: company.companyId
        });
        const mainData = dataReturn?.data?.data;
        const body = mainData?.body;
        const subject = mainData?.subject;
        const attachments = mainData?.attachments || [];

        if (body) {
            let { contentBlocks, entityMap } = htmlToDraft(body);
            let contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                ).getBlockMap()
            );
            await onChange(
                EditorState.push(editorState, contentState, "insert-fragment")
            );
        }

        if (props.subjectFieldName && subject) {
            helpersSubject.setValue(subject);
        }
        if (props.attachmentsFieldName && attachments.length) {
            helpersAttachments.setValue(attachments);
        }
        setOpen(false);
    };

    if (placeholders.length) {
        return (
            <div>
                <label htmlFor="icon-button-file">
                    <Tooltip
                        title={t(
                            "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                        )}
                    >
                        {/* <IconButton
                            aria-label={t(
                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                            )}
                            component="span"
                            onClick={handleClickOpen}
                            className={classes.iconButton}
                        >
                            <FindReplaceIcon />
                        </IconButton> */}
                        <Button
                            variant="outlined"
                            color="default"
                            className={classes.iconButton}
                            startIcon={<FindReplaceIcon />}
                            onClick={handleClickOpen}
                        >
                            {t("EMAILDATAFIELD_textChoosePlaceholder")}
                        </Button>
                    </Tooltip>
                </label>
                <TemplatePlaceholderDialog
                    t={t}
                    open={open}
                    onClose={handleClose}
                    title={t("EMAILDATAFIELD_textChooseOptionModalPlaceholder")}
                >
                    <DialogChooseTemplateOrPlaceholderBody
                        handleChooseAnOption={handleChooseAnPlaceholder}
                        // handleChooseAnTemplate={handleChooseAnTemplate}
                        placeholders={placeholders}
                        // templates={localTemplates}
                        t={t}
                    />
                </TemplatePlaceholderDialog>
            </div>
        );
    } else {
        return null;
    }
};

export default RichTextToolbarItemLoadPlaceholder;
