import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Field, useField } from "formik";
import { Button, Grid, Icon, InputAdornment, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextFieldCompleo from "../TextField";
import RichText from "../RichText/RichText";
import { DialogChoosePlaceHolderSubject } from "./DialogChooseTemplateOrPlaceholder";
import FileField from "../File/File";
import { clearArrayInfo } from "functions/util";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import { TemplatePlaceholderDialog } from "./TemplatePlaceholderDialog";

interface IProps {
    metadata: any;
    name: string;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    helperTextDefault: null | string;
    placeholdersSubject?: Compleo.IObject[];
    placeholders?: Compleo.IObject[];
    arrayNumber: number;
    idListLoadedFromDB: string[];
    language: string;
    hideSubject: boolean;
}

const ScheduleDataField = (props: IProps) => {
    const {
        metadata,
        t,
        classes,
        required,
        name,
        placeholders = [],
        placeholdersSubject = [],
        arrayNumber,
        idListLoadedFromDB,
        language,
        hideSubject
    } = props;

    const inputSubjectRef = React.useRef();
    const inputBodyRef = React.useRef();
    const [field, meta, helpers] = useField(props.name);
    const [arrayField, , helpersArrayField] = useField(
        `${props.name}[${arrayNumber}]`
    );
    const [startId] = React.useState(arrayField.value?.id);
    const loadedFromDB = idListLoadedFromDB.includes(startId);

    const subjectFieldName = getFieldName(arrayNumber, name, "subject");
    const bodyFieldName = getFieldName(arrayNumber, name, "body");
    const locationFieldName = getFieldName(arrayNumber, name, "location");

    const removeItem = () => {
        const currentValue = field.value;
        currentValue.splice(arrayNumber, 1);
        helpers.setValue(currentValue);
    };

    const [subjectField, , helpersSubject] = useField(subjectFieldName);
    const [bodyField, , helpersBody] = useField(bodyFieldName);
    const [selectionStart, setSelectionStart] = React.useState<any>(0);
    const updateSelectionStartSubject = () => {
        if (inputSubjectRef && inputSubjectRef.current) {
            const valueFieldRef: any = inputSubjectRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const updateSelectionStartBody = () => {
        if (inputBodyRef && inputBodyRef.current) {
            const valueFieldRef: any = inputBodyRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const [openPlaceHolderSubject, setOpenPlaceholderSubject] = React.useState(
        false
    );
    const handleClickOpenPlaceholderSubject = () => {
        setOpenPlaceholderSubject(true);
    };
    const handleClosePlaceholderSubject = async () => {
        setOpenPlaceholderSubject(false);
    };
    const choosePlaceHolderSubject = (value: string) => {
        const currentValue: string = subjectField.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        helpersSubject.setValue(newValue);
        setOpenPlaceholderSubject(false);
    };

    const [openPlaceHolderBody, setOpenPlaceholderBody] = React.useState(false);
    const handleClickOpenPlaceholderBody = () => {
        setOpenPlaceholderBody(true);
    };
    const handleClosePlaceholderBody = async () => {
        setOpenPlaceholderBody(false);
    };
    const choosePlaceHolderBody = (value: string) => {
        const currentValue: string = bodyField.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        helpersBody.setValue(newValue);
        setOpenPlaceholderBody(false);
    };

    const ready = startId ? loadedFromDB : true;

    // console.log("subjectFieldName", subjectFieldName);
    if (ready) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Field
                        type="text"
                        name={locationFieldName}
                        label={t("Location")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        t={t}
                    />
                </Grid>

                {!hideSubject && (
                    <Grid item xs={12} sm={6}>
                        <Field
                            type="text"
                            name={subjectFieldName}
                            label={t("EMAILDATAFIELD_Subject")}
                            fullWidth
                            className={classes.textField}
                            component={TextFieldCompleo}
                            inputRef={inputSubjectRef}
                            onSelect={updateSelectionStartSubject}
                            InputProps={{
                                endAdornment: placeholdersSubject.length >
                                    0 && (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                        >
                                            <IconButton
                                                aria-label={t(
                                                    "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                                )}
                                                onClick={
                                                    handleClickOpenPlaceholderSubject
                                                }
                                            >
                                                <FindReplaceIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                            t={t}
                        />
                        <TemplatePlaceholderDialog
                            t={t}
                            open={openPlaceHolderSubject}
                            onClose={handleClosePlaceholderSubject}
                            title={t(
                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                            )}
                        >
                            <DialogChoosePlaceHolderSubject
                                handleChooseAnOption={choosePlaceHolderSubject}
                                placeholders={placeholdersSubject}
                            />
                        </TemplatePlaceholderDialog>
                    </Grid>
                )}
                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Field
                        type="text"
                        name={bodyFieldName}
                        label={t("EMAILDATAFIELD_Body")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        inputRef={inputBodyRef}
                        onSelect={updateSelectionStartBody}
                        InputProps={{
                            endAdornment: placeholdersSubject.length > 0 && (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={t(
                                            "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                        )}
                                    >
                                        <IconButton
                                            aria-label={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                            onClick={
                                                handleClickOpenPlaceholderBody
                                            }
                                        >
                                            <FindReplaceIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                        t={t}
                        multiline
                        rows={4}
                        rowsMax={10}
                        variant="outlined"
                    />
                    <TemplatePlaceholderDialog
                        t={t}
                        open={openPlaceHolderBody}
                        onClose={handleClosePlaceholderBody}
                        title={t(
                            "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                        )}
                    >
                        <DialogChoosePlaceHolderSubject
                            handleChooseAnOption={choosePlaceHolderBody}
                            placeholders={placeholders}
                        />
                    </TemplatePlaceholderDialog>
                </Grid>
                {!field.value[arrayNumber].default && (
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={removeItem}
                            startIcon={<Icon>delete</Icon>}
                        >
                            {t("EMAILDATAFIELD_removelanguage")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const getFieldName = (
    arrayNumber: number,
    mainFieldName: string,
    fieldName: string
) => {
    return `${mainFieldName}[${arrayNumber}].${fieldName}`;
};

export default ScheduleDataField;
