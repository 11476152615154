import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import useList from "../index";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const ListTablePagination = () => {
    // const classes = useStyles({ bottomPagination: bottomPagination })();
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { totalFiltered } = listData.listReturnInfo;
    const { setFilters } = listDispatch;
    const { elasticDefData } = listData.definitionInfo;
    const { t } = listData.tempInfo;
    const currentPage = filters.pagination.currentPage;
    const pageSize = filters.pagination.pageSize;
    const totalItems = totalFiltered?.value || 0;
    const totalPages = Math.ceil(totalItems / pageSize);
    const { fields, queryFinished } = listData.listReturnInfo;

    // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //     setFilters({
    //         ...filters,
    //         pagination: {
    //             currentPage: value,
    //             pageSize: pageSize
    //         },
    //         filterUpdated: true
    //     });
    // };

    //   const [page, setPage] = React.useState(2);
    //   const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setFilters({
            ...filters,
            pagination: {
                currentPage: newPage + 1,
                pageSize: pageSize
            },
            filterUpdated: true
        });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFilters({
            ...filters,
            pagination: {
                currentPage: 1,
                pageSize: parseInt(event.target.value, 10)
            },
            filterUpdated: true
        });
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    const labelDisplayedRowsFunction = ({ from, to, count }: any) =>
        `${from}-${to} ${t("ELASTICLISTSETTINGS:Pagination_of")} ${
            count !== -1
                ? count
                : `${t("ELASTICLISTSETTINGS:Pagination_moreThan")} ${to}`
        }`;

    if (fields.length && queryFinished) {
        return (
            //@ts-ignore
            <TablePagination
                component="div"
                count={totalItems}
                page={currentPage - 1}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize || 6}
                labelRowsPerPage={t(
                    "ELASTICLISTSETTINGS:Pagination_rowsPerPage"
                )}
                backIconButtonText={t(
                    "ELASTICLISTSETTINGS:Pagination_previousPage"
                )}
                nextIconButtonText={t(
                    "ELASTICLISTSETTINGS:Pagination_nextPage"
                )}
                rowsPerPageOptions={
                    elasticDefData?.data?.otherSettings?.resultsPageOptions || [
                        5,
                        10,
                        15
                    ]
                }
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={labelDisplayedRowsFunction}
            />
        );
    } else {
        return null;
    }
};

export default ListTablePagination;
