import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Field, useField } from "formik";
import { Button, Grid, Icon, InputAdornment, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextFieldCompleo from "../TextField";
import RichText from "../RichText/RichText";
import RichTextToolbarItemLoadPlaceholder from "./RichTextToolbarItemLoadPlaceholder";
import { MoreVert } from "@material-ui/icons";
import { TemplatePlaceholderDialog } from "./RichTextVars";
import { DialogChoosePlaceHolderSubject } from "./DialogChooseTemplateOrPlaceholder";
import FileField from "../File/File";
import { clearArrayInfo } from "functions/util";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { ITemplateEmailData } from "./EmailData";
import RichTextToolbarItemLoadTemplate from "./RichTextToolbarItemLoadTemplate";
import FindReplaceIcon from "@material-ui/icons/FindReplace";

const useStyles = makeStyles((theme: Theme) => {
    const colorText = theme.palette.grey[100];
    return createStyles({
        button: {
            margin: theme.spacing(2)
        },
        inputhidden: {
            display: "none"
        }
    });
});

interface IProps {
    metadata: any;
    name: string;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    helperTextDefault: null | string;
    placeholdersSubject?: Compleo.IObject[];
    placeholders?: Compleo.IObject[];
    arrayNumber: number;
    idListLoadedFromDB: string[];
    language: string;
    hideSubject: boolean;
}

const EmailDataField = (props: IProps) => {
    const {
        metadata,
        t,
        classes,
        required,
        name,
        placeholders = [],
        placeholdersSubject = [],
        arrayNumber,
        idListLoadedFromDB,
        language,
        hideSubject
    } = props;

    const inputRef = React.useRef();
    const [field, meta, helpers] = useField(props.name);
    const [arrayField, , helpersArrayField] = useField(
        `${props.name}[${arrayNumber}]`
    );
    const [startId] = React.useState(arrayField.value?.id);
    const loadedFromDB = idListLoadedFromDB.includes(startId);

    const subjectFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.subject"
    );
    const bodyFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.body"
    );
    const attachmentsFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.attachments"
    );

    const removeItem = () => {
        const currentValue = field.value;
        currentValue.splice(arrayNumber, 1);
        helpers.setValue(currentValue);
    };

    const [subjectField, , helpersSubject] = useField(subjectFieldName);
    const [selectionStart, setSelectionStart] = React.useState<any>(0);
    const updateSelectionStart = () => {
        if (inputRef && inputRef.current) {
            const valueFieldRef: any = inputRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const choosePlaceHolderSubject = (value: string) => {
        const currentValue: string = subjectField.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        helpersSubject.setValue(newValue);
        setOpen(false);
    };

    const ready = startId ? loadedFromDB : true;

    // console.log("subjectFieldName", subjectFieldName);
    if (ready) {
        return (
            <Grid container spacing={1}>
                {!hideSubject && (
                    <Grid item xs={12} sm={6}>
                        <Field
                            type="text"
                            name={subjectFieldName}
                            label={t("EMAILDATAFIELD_Subject")}
                            fullWidth
                            className={classes.textField}
                            component={TextFieldCompleo}
                            inputRef={inputRef}
                            onSelect={updateSelectionStart}
                            InputProps={{
                                endAdornment: placeholdersSubject.length >
                                    0 && (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                        >
                                            <IconButton
                                                aria-label={t(
                                                    "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                                )}
                                                onClick={handleClickOpen}
                                            >
                                                <FindReplaceIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                            t={t}
                        />
                        <TemplatePlaceholderDialog
                            t={t}
                            open={open}
                            onClose={handleClose}
                            title={t(
                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                            )}
                        >
                            <DialogChoosePlaceHolderSubject
                                handleChooseAnOption={choosePlaceHolderSubject}
                                placeholders={placeholdersSubject}
                            />
                        </TemplatePlaceholderDialog>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <RichText
                        metadata={{
                            fieldName: bodyFieldName,
                            richTextConfig: {
                                minHeight: "300px",
                                enableCodeButton: true,
                                toolbarType: "full"
                            }
                        }}
                        t={t}
                        required={true}
                        label={t("EMAILDATAFIELD_Body")}
                        toolbarCustomButtons={
                            placeholders.length
                                ? [
                                      <RichTextToolbarItemLoadPlaceholder
                                          subjectFieldName={subjectFieldName}
                                          attachmentsFieldName={
                                              attachmentsFieldName
                                          }
                                          key="2"
                                          placeholders={placeholders}
                                          templates={[]}
                                          t={t}
                                          language={language}
                                      />
                                      //   <RichTextToolbarItemLoadTemplate
                                      //       subjectFieldName={subjectFieldName}
                                      //       attachmentsFieldName={
                                      //           attachmentsFieldName
                                      //       }
                                      //       key="3"
                                      //       templates={templates}
                                      //       t={t}
                                      //       language={language}
                                      //   />
                                  ]
                                : undefined
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        metadata={{
                            fieldName: attachmentsFieldName,
                            elementType: "multipleFiles",
                            fileDefinition: {
                                maxFileSizeKB: 2000,
                                maxFiles: 5,
                                extensions: [
                                    ".pdf",
                                    ".doc",
                                    ".docx",
                                    ".txt",
                                    ".png",
                                    ".jpg",
                                    ".gif",
                                    ".jpeg"
                                ]
                            }
                        }}
                        name={attachmentsFieldName}
                        t={t}
                        label={t("EMAILDATAFIELD_attachments")}
                        helperTextDefault={null}
                        classes={classes}
                        required={false}
                        component={FileField}
                    />
                </Grid>
                {!field.value[arrayNumber].default && (
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={removeItem}
                            startIcon={<Icon>delete</Icon>}
                        >
                            {t("EMAILDATAFIELD_removelanguage")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const getFieldName = (
    arrayNumber: number,
    mainFieldName: string,
    fieldName: string
) => {
    return `${mainFieldName}[${arrayNumber}].${fieldName}`;
};

export default EmailDataField;
