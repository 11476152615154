import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    InputAdornment,
    TableContainer,
    TextField,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Paper
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { matchSorter } from "match-sorter";
import BucketReportItemTable, {
    BucketReportItemRowSize
} from "./BucketReportItemTable";
import clsx from "clsx";

export const useStylesBucketReportTable = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            display: "block",
            flex: 1
        },
        table: {
            height: "100%",
            width: "100%"
        },
        list: {},
        thead: {},
        tbody: {
            width: "100%"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            boxSizing: "border-box",
            minWidth: "100%",
            width: "100%"
        },
        headerRow: {},
        cell: {
            display: "block",
            flexGrow: 0,
            flexShrink: 0
        },
        expandingCell: {
            flex: 1
        },
        column: {
            fontWeight: 600
        }
    })
);

const handleData = (
    items: Compleo.IObject[],
    total: number,
    mainTotal: number
) => {
    // const totalApplicants: number = items.reduce((previous, current) => {
    //     const finalValue: number = current.total + previous;
    //     return finalValue;
    // }, 0);
    if (!total) {
        return items;
    }

    return items.map((item) => {
        const percentualValueDecimal = item.total / total;
        const percentualValue = percentualValueDecimal * 100;
        const value = Math.round(percentualValue * 100) / 100;

        const percentualValueDecimalMainTotal = item.total / mainTotal;
        const percentualValueMainTotal = percentualValueDecimalMainTotal * 100;
        const valueMaintTotal =
            Math.round(percentualValueMainTotal * 100) / 100;

        return {
            ...item,
            percentualValue: percentualValueDecimal,
            percentualFormated: `${value} %`,
            percentualValueMainTotal: percentualValueDecimalMainTotal,
            percentualFormatedMain: `${valueMaintTotal} %`
        };
    });
};

export interface IBucketReportItem {
    aggregation: Compleo.IObject;
    title: string;
    t: any;
    totalFilteredValue: number;
    totalValue: number;
}

const BucketReportTable = (props: IBucketReportItem) => {
    const { aggregation, title, t, totalFilteredValue, totalValue } = props;
    const classes = useStylesBucketReportTable();
    const [textSearch, setTextSearch] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };
    const data = handleData(
        aggregation?.items || [],
        totalFilteredValue,
        totalValue
    );

    const filteredArray: Compleo.IObject[] = textSearch
        ? matchSorter(data, textSearch, {
              keys: ["label"]
          })
        : data;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    className={classes.margin}
                    placeholder={t(
                        "ELASTICLISTSETTINGS:searchBucketPlaceholder"
                    )}
                    fullWidth
                    value={textSearch}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.root}>
                    <Table className={classes.table} component="div">
                        <TableHead component="div" className={classes.thead}>
                            <TableRow
                                component="div"
                                className={clsx(classes.row, classes.headerRow)}
                            >
                                <TableCell
                                    component="div"
                                    variant="head"
                                    className={clsx(
                                        classes.cell,
                                        classes.column,
                                        classes.expandingCell
                                    )}
                                    style={{
                                        height: BucketReportItemRowSize
                                    }}
                                    scope="col"
                                >
                                    {title}
                                </TableCell>
                                <TableCell
                                    component="div"
                                    variant="head"
                                    className={clsx(
                                        classes.cell,
                                        classes.column,
                                        classes.expandingCell
                                    )}
                                    style={{
                                        height: BucketReportItemRowSize
                                    }}
                                    scope="col"
                                    align="right"
                                >
                                    {t(
                                        "ELASTICLISTSETTINGS:BucketReport_TotalColumn"
                                    )}
                                </TableCell>
                                {totalFilteredValue !== totalValue && (
                                    <TableCell
                                        component="div"
                                        variant="head"
                                        className={clsx(
                                            classes.cell,
                                            classes.column,
                                            classes.expandingCell
                                        )}
                                        style={{
                                            height: BucketReportItemRowSize
                                        }}
                                        scope="col"
                                        align="right"
                                    >
                                        {t(
                                            "ELASTICLISTSETTINGS:BucketReport_ApplicantPercentualColumnFiltered"
                                        )}
                                    </TableCell>
                                )}
                                <TableCell
                                    component="div"
                                    variant="head"
                                    className={clsx(
                                        classes.cell,
                                        classes.column,
                                        classes.expandingCell
                                    )}
                                    style={{
                                        height: BucketReportItemRowSize
                                    }}
                                    scope="col"
                                    align="right"
                                >
                                    {t(
                                        "ELASTICLISTSETTINGS:BucketReport_ApplicantPercentualColumnTotal"
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody component="div" className={classes.tbody}>
                            <BucketReportItemTable allItems={filteredArray} />
                        </TableBody>
                    </Table>
                </div>
            </Grid>
        </Grid>
    );
};

export default BucketReportTable;
