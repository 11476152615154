import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { Button, Icon, Typography } from "@material-ui/core";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

interface ChipData {
    key: number;
    label: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            listStyle: "none",
            padding: 0,
            // paddingRight: theme.spacing(0.5),
            margin: 0
        },
        chip: {
            margin: 0,
            marginRight: theme.spacing(0.5)
        }
    })
);

interface IFileTags {
    tags: string[];
}

const FileTags = (props: IFileTags) => {
    const classes = useStyles();
    const { tags } = props;

    return (
        <Paper component="ul" className={classes.root} elevation={0}>
            {tags.map((tag) => {
                let icon;

                return (
                    <li key={tag}>
                        <Chip
                            icon={icon}
                            size="small"
                            label={tag}
                            className={classes.chip}
                            color="secondary"
                        />
                    </li>
                );
            })}
        </Paper>
    );
};

export default FileTags;
