import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import useDashboardContext from "../useDashboardContext";
import { PageToRenderList } from "../useDashboardContext/DashboardTypes";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const DashboardTabs = (props: any) => {
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { t } = data;
    const { pageToRender, editMode } = data.localStateData;
    const { readyForm, callDialog, agree } = useGlobalDialog();

    // const PageToRenderLocalList = data.readonly
    //     ? PageToRenderListReadOnlyMode
    //     : PageToRenderList;

    const PageToRenderLocalList = PageToRenderList;
    const currentValue = PageToRenderLocalList.indexOf(pageToRender);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (!editMode) {
            const newPageToRender = PageToRenderLocalList[newValue];
            dispatchGeneralPageInfo({
                type: "update",
                payload: { pageToRender: newPageToRender }
            });
        } else {
            callDialog({
                title: t("mainPageName"),
                bodyText: t("messageChangeTabEditMode"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        }
    };

    return (
        <Tabs
            value={currentValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            variant="scrollable"
        >
            {PageToRenderLocalList.map((tab, index) => (
                <Tab key={tab} label={t(`TabPage_${tab}`)} />
            ))}
        </Tabs>
    );
};

export default DashboardTabs;
