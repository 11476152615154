import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "delete",
                tooltip: t("BulkActionDelete_ModalTitle"),
                onClick: async (event: any, rowData: any) => {
                    const mainId = rowData.pk.split(":")[0];
                    const bulkId = rowData.skGS1pk;
                    const postAddress = `/bulkactions/delete`;

                    callDialog({
                        title: t("buttonDeleteBulkAction"),
                        bodyText: t("BulkActionDelete_ModalMessage"),
                        agreeButtonText: t("BulkActionDelete_ModalButtonYes"),
                        disagreeButtonText: t("BulkActionDelete_ModalButtonNo"),
                        agreeFunction: async () => {
                            const result = await apiDirectCall(
                                postAddress,
                                "post",
                                {
                                    companyId: companyId,
                                    mainId: mainId,
                                    bulkId: bulkId
                                }
                            );
                            if (result.status === 200) {
                                showMessage(
                                    t("BulkActionDelete_SucessMessage"),
                                    "success"
                                );
                                refreshListFunction();
                                return true;
                            }
                            return false;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            }
        ];

        return result;
    };
};

export default useActionsTable;
