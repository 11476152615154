import React from "react";
// import { FieldProps } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { currenciesList, formatMapCurrency } from "functions/formatValues";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
export type CurrencyLanguages = "pt-BR" | "en-US" | "fr" | "es";
interface ICurrency {
    currency?: string;
    value?: number | string;
}

interface IProps {
    language: CurrencyLanguages;
    maxLength?: number;
    decimalDigits?: number;
}

// export const formatMapCurrency = {
//     "pt-BR": {
//         thousandSeparator: ".",
//         decimalSeparator: ","
//     },
//     "en-US": {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     },
//     fr: {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     },
//     es: {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     }
// };

const useStyles = makeStyles({
    currencyClass: {
        minWidth: "20px"
    }
});

const CurrencyField = (props: IProps & IInputProps) => {
    //debugger;
    const {
        name,
        label,
        // field,
        // form: { dirty, touched, errors, status, setFieldValue },
        helperTextDefault,
        // value,
        language = "pt-BR",
        decimalDigits = 2,
        ...other
    } = props;
    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        `${name}.value`,
        helperTextDefault
    );
    const {
        field: fieldCurrencyType,
        setFieldValue: setCurrencyTypeFieldValue
    } = useRhfFieldControlled(`${name}.currency`, helperTextDefault);

    const localClasses = useStyles();

    const handleValueChange = (prop: keyof ICurrency) => (
        values: NumberFormatValues
    ) => {
        // setFieldValue({
        //     ...field.value,
        //     [prop]: values.floatValue
        // });
        setFieldValue(values.floatValue);
    };

    const handleCurrencyChange = (prop: keyof ICurrency) => (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        // setFieldValue({
        //     ...field.value,
        //     [prop]: event.target.value
        // });
        setCurrencyTypeFieldValue(event.target.value);
    };
    // console.log("currencyValue", field);
    //console.log("errorMessage", errorMessage, field.value);
    return (
        // <React.Fragment>
        <NumberFormat
            id={`${field.name}.value`}
            label={label}
            error={error}
            helperText={message}
            onValueChange={handleValueChange("value")}
            thousandSeparator={formatMapCurrency[language].thousandSeparator}
            decimalSeparator={formatMapCurrency[language].decimalSeparator}
            isNumericString
            decimalScale={decimalDigits}
            fixedDecimalScale={true}
            customInput={TextField}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <NativeSelect
                            inputProps={{
                                "aria-label": `${field.name}.currency`,
                                className: localClasses.currencyClass
                            }}
                            value={fieldCurrencyType.value}
                            onChange={handleCurrencyChange("currency")}
                        >
                            {currenciesList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </NativeSelect>
                    </InputAdornment>
                ),
                onBlur: field.onBlur
            }}
            {...other}
            {...field}
            onChange={undefined}
            // value={field.value}
            type="text"
            inputRef={field.ref}
        />
        // </React.Fragment>
    );
};

export default CurrencyField;
