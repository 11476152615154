import React from "react";
import { Form } from "formik";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { TFunction } from "i18next";
import { useLanguageState } from "_ReactContext/LanguageContext";
import FormMainDetail from "./FormMainDetail";
import FormikErrorFocus from "customHooks/useCompleoForm/helpers/FormikErrorFocus";
import { Formik } from "formik";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import {
    getSchema
    // getInitialValues
} from "customHooks/useCompleoForm/helpers/helper";
import Yup from "customHooks/useCompleoForm/helpers/yupHelper";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { navigate } from "@gatsbyjs/reach-router";
import { isFileFromDB } from "../Inputs/File/File";
import { apiDirectCall } from "customHooks/useApi/api";
import _ from "lodash";
import { clearAccentuatedString } from "functions/util";
import { processUploadFileFields, processEmailFields } from "./FormMainHelper";
import { useQueryClient } from "react-query";

export type PropsParent = {
    camposMetadados: any;
    formGroups: any;
    t: TFunction;
    listas: any;
    updating: boolean;
    loading: boolean;
    valuesFromSource: any;
    CustomComponents: any;
    IsMultiStepForm: boolean;
    MultiStepForm: any;
    initialValues: any;
    redirectAddress: any;
    postFunction: any;
    enableReinitialize: boolean;
    nextPageFunctions?: Compleo.IObject[];
    secondaryButtonFunction?: any | null;
    tertiaryButtonFunction?: any | null;
    customLists?: Compleo.IObject[];
    fieldsToHide?: string[];
    formikReturn?: any;
    FormMenuActions?: any;
    additionalFieldProperties?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    sizeToChangeStepHorizontal?: "md" | "xs" | "sm" | "lg" | "xl";
    formGroupPaperElevation?: number;
    enableStepNavigation?: boolean;
    IsMultiStepLateralParam?: boolean;
    invalidateQueryClientItems?: string[];
    removeQueryClientItems?: string[];
};

export type typeForm = "INTERNAL" | "EXTERNAL";

type PropsMain = {
    classes: any;
    formType: typeForm;
    parentProps: PropsParent;
};

const FormMain = (props: PropsMain) => {
    const { classes, formType } = props;
    const {
        camposMetadados,
        formGroups,
        t,
        listas,
        updating,
        loading,
        valuesFromSource,
        CustomComponents,
        IsMultiStepForm,
        MultiStepForm,
        initialValues,
        redirectAddress,
        postFunction,
        nextPageFunctions,
        secondaryButtonFunction,
        tertiaryButtonFunction,
        customLists,
        fieldsToHide,
        formikReturn,
        FormMenuActions,
        additionalFieldProperties,
        sizeToChangeStepHorizontal,
        enableReinitialize,
        formGroupPaperElevation,
        enableStepNavigation,
        IsMultiStepLateralParam,
        invalidateQueryClientItems,
        removeQueryClientItems
    } = props.parentProps;

    const totalPages = MultiStepForm.length;
    const showMessage = useShowMessage();
    const { company } = useAuthState();
    const initialPage = totalPages > 0 ? 1 : 0;
    const [pageForm, setPageForm] = React.useState(initialPage);
    const [nonLinearPageNumber, setNonLinearPageNumber] = React.useState(0);
    const [forceSubmitFinal, setForceSubmitFinal] = React.useState(false);
    const queryClient = useQueryClient();

    const lastPage = pageForm === totalPages;

    let buttonTertiaryText = t("buttonTertiaryText");
    let buttonSecondaryText = t("buttonSecondaryText");
    let buttonMainText = t("buttonMainText");
    const renderCancelButton =
        secondaryButtonFunction !== null &&
        buttonSecondaryText !== "[disabled]";
    let renderBackButton = false;

    const steps: string[] = [];
    if (IsMultiStepForm) {
        if (!lastPage) {
            const pageStepTranslationName = `buttonNextStep_Page_${pageForm}`;
            buttonMainText = t(pageStepTranslationName);
            if (buttonMainText === pageStepTranslationName) {
                const nextPageText = t("buttonNextStep");
                buttonMainText = nextPageText;
            }
        }
        if (pageForm > 1) {
            const pageStepTranslationName = `buttonPreviousStep_Page_${pageForm}`;
            buttonSecondaryText = t(pageStepTranslationName);
            if (buttonSecondaryText === pageStepTranslationName) {
                const previousPageText = t(`buttonPreviousStep`);
                buttonSecondaryText = previousPageText;
            }
            renderBackButton = true;
        }
        MultiStepForm.map((stepForm: any) => {
            steps.push(t(`a_StepForm_${stepForm.id}`));
        });
    }
    const renderSecondaryButton = renderBackButton || renderCancelButton;

    const renderTertiaryButton =
        tertiaryButtonFunction !== null &&
        tertiaryButtonFunction !== undefined &&
        (lastPage || !IsMultiStepForm);

    const language = useLanguageState();
    const filteredCamposMetadados = filterMetaDataFromPage(
        IsMultiStepForm,
        camposMetadados,
        MultiStepForm,
        formGroups,
        pageForm
    );
    const schema = getSchema(
        Yup,
        filteredCamposMetadados,
        t,
        company.companyId
    );
    const ObjectSchema = Yup.object().shape(schema);

    const filteredFormGroups = filterFormGroups(
        formGroups,
        filteredCamposMetadados,
        fieldsToHide
    );

    const tertiaryAction = async (
        formValues: any,
        { setSubmitting, setStatus, setTouched, validateForm }: any
    ) => {
        if (lastPage || !IsMultiStepForm) {
            formValues = removeEmptyOrNull(formValues);

            return await submitFinal(
                formValues,
                setSubmitting,
                setStatus,
                tertiaryButtonFunction
            );
        }
    };

    const secondaryAction = () => {
        if (IsMultiStepForm && pageForm > 1) {
            setPageForm(pageForm - 1);
        }
        if (!IsMultiStepForm || pageForm === 1) {
            secondaryButtonFunction();
        }
    };

    const submitFinal = async (
        values: any,
        setSubmitting: any,
        setStatus: any,
        alternativePostFunction?: any
    ) => {
        try {
            values = removeEmptyOrNull(values);
            setSubmitting(true);
            const ignoreUploadEmailFields =
                values?._ignoreUploadEmailFields === true;
            const ignoreUploadFileFields =
                values?._ignoreUploadFileFields === true;

            if (!ignoreUploadFileFields) {
                values = await processUploadFileFields(values);
            }
            const newValues = !ignoreUploadEmailFields
                ? await processEmailFields(values)
                : values;

            const finalPostFunction =
                alternativePostFunction !== undefined
                    ? alternativePostFunction
                    : postFunction;

            finalPostFunction(newValues)
                .then((retorno: any) => {
                    if (retorno.status === 200) {
                        if (
                            invalidateQueryClientItems &&
                            invalidateQueryClientItems?.length > 0
                        ) {
                            for (const item of invalidateQueryClientItems) {
                                queryClient.invalidateQueries(item);
                                console.log("invalidated query: " + item);
                            }
                        }
                        if (
                            removeQueryClientItems &&
                            removeQueryClientItems?.length > 0
                        ) {
                            for (const item of removeQueryClientItems) {
                                queryClient.removeQueries(item);
                                // queryClient.cancelQueries(item);
                                // console.log("remove query: " + item);
                            }
                            // queryClient.clear();
                        }
                        values = { ...initialValues };
                        setSubmitting(false);
                        if (
                            t("successMessage") !== "[noMessage]" &&
                            t("successMessage") !== "successMessage"
                        ) {
                            showMessage(t("successMessage"), "success");
                        }
                        if (redirectAddress !== null) {
                            navigate(redirectAddress);
                        }
                    } else if (retorno.status === 400) {
                        if (retorno.data.message) {
                            const errors = retorno.data.message.map(
                                (item: any) => {
                                    const campoErro = item.dataPath.replace(
                                        "/",
                                        ""
                                    );
                                    const missingProperty = (item?.params
                                        ?.errors || [])[0]?.params
                                        ?.missingProperty;

                                    const messageErro = item.message;

                                    return {
                                        id: campoErro || missingProperty,
                                        mensagem: messageErro
                                    };
                                }
                            );

                            // let objErros = {};
                            let objErros: { [key: string]: any };
                            objErros = {};
                            errors
                                .map((value: any) => value.id)
                                .sort()
                                .filter(
                                    (item: any, pos: any, ary: any) =>
                                        !pos || item !== ary[pos - 1]
                                )
                                .map((item: any) => {
                                    let mensagemUnificadoPorId = "";
                                    errors.filter((value: any) => {
                                        if (value.id === item) {
                                            if (mensagemUnificadoPorId === "") {
                                                mensagemUnificadoPorId +=
                                                    value.mensagem;
                                            } else {
                                                mensagemUnificadoPorId +=
                                                    " - " + value.mensagem;
                                            }
                                        }
                                    });
                                    objErros[item] = mensagemUnificadoPorId;
                                    return {
                                        id: item,
                                        mensagem: mensagemUnificadoPorId
                                    };
                                });
                            setSubmitting(false);
                            setStatus(objErros);
                            console.log(
                                "ex",
                                JSON.stringify(retorno?.data, null, 4)
                            );
                        }
                    } else if (retorno.status === 999) {
                        // erro definido na mão para não exibir nenhuma mensagem
                        setSubmitting(false);
                    } else {
                        console.log(
                            "ex",
                            JSON.stringify(retorno?.data, null, 4)
                        );
                        showMessage(t("NotIdentifiedProblem"), "error");
                        setSubmitting(false);
                    }
                })
                .catch((data: any) => {
                    // TODO: Send this error to server and generate a code
                    console.log("ex", JSON.stringify(data, null, 4));

                    showMessage(t("NotIdentifiedProblem"), "error");
                    setSubmitting(false);
                });
        } catch (ex) {
            // TODO: Send this error to server and generate a code
            console.log("ex", JSON.stringify(ex, null, 4));
            showMessage(t("NotIdentifiedProblem"), "error");
            setSubmitting(false);
        }
    };

    const goToPageAfterSubmit = (
        setSubmitting: any,
        setTouched: any,
        setPage: any,
        pageNumber: number
    ) => {
        setTouched({});
        setPage(pageNumber);
        setSubmitting(false);
    };

    const clearSubmiting = (setSubmitting: any) => {
        setSubmitting(false);
    };

    const submit = (values: any, submitFormikData: any) => {
        const { setSubmitting, setStatus, setTouched } = submitFormikData;
        if (IsMultiStepForm && nonLinearPageNumber > 0) {
            const successFN = (keepSamePage = false) => {
                if (!keepSamePage) {
                    return goToPageAfterSubmit(
                        setSubmitting,
                        setTouched,
                        setPageForm,
                        nonLinearPageNumber
                    );
                } else {
                    return clearSubmiting(setSubmitting);
                }
            };
            // Traz função personalizada específica para esta página
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === pageForm
            );

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                currentPageCustomFunction[0].fn(successFN, values);
            } else {
                successFN();
            }
            setNonLinearPageNumber(0);
        } else if (lastPage || !IsMultiStepForm || forceSubmitFinal) {
            // Traz função personalizada específica para esta página
            // Se for a última página (Submit final, o parâmetro deve ser 0)
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === 0
            );

            const successFN = (keepSamePage = false) => {
                if (!keepSamePage) {
                    return submitFinal(values, setSubmitting, setStatus);
                } else {
                    return clearSubmiting(setSubmitting);
                }
            };

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                currentPageCustomFunction[0].fn(successFN, values);
            } else {
                successFN();
            }
            if (forceSubmitFinal) {
                setForceSubmitFinal(false);
            }
        } else {
            // Função padrão de submit para a próxima página
            const successFN = (keepSamePage = false) => {
                if (!keepSamePage) {
                    return goToPageAfterSubmit(
                        setSubmitting,
                        setTouched,
                        setPageForm,
                        pageForm + 1
                    );
                } else {
                    return clearSubmiting(setSubmitting);
                }
            };

            // Traz função personalizada específica para esta página
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === pageForm
            );

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                currentPageCustomFunction[0].fn(successFN, values);
            } else {
                successFN();
            }
        }
    };
    return (
        <Formik
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            validationSchema={ObjectSchema}
            onSubmit={submit}
            innerRef={formikReturn}
        >
            {(formik: any) => {
                return (
                    <Form
                        translate="yes"
                        className={classes.formcontainer}
                        noValidate
                        autoComplete="off"
                    >
                        {loading ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <CircularProgress size={24} />
                                </Grid>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <FormMainDetail
                                    filteredFormGroups={filteredFormGroups}
                                    classes={classes}
                                    camposMetadados={filteredCamposMetadados}
                                    t={t}
                                    listas={listas}
                                    valuesFromSource={valuesFromSource}
                                    CustomComponents={CustomComponents}
                                    language={language}
                                    pageForm={pageForm}
                                    IsMultiStepForm={IsMultiStepForm}
                                    steps={steps}
                                    customLists={customLists}
                                    fieldsToHide={fieldsToHide}
                                    formik={formik}
                                    FormMenuActions={FormMenuActions}
                                    additionalFieldProperties={
                                        additionalFieldProperties
                                    }
                                    sizeToChangeStepHorizontal={
                                        sizeToChangeStepHorizontal
                                    }
                                    formGroupPaperElevation={
                                        formGroupPaperElevation
                                    }
                                    setNonLinearPageNumber={
                                        setNonLinearPageNumber
                                    }
                                    enableStepNavigation={enableStepNavigation}
                                    IsMultiStepLateralParam={
                                        IsMultiStepLateralParam
                                    }
                                    setForceSubmitFinal={setForceSubmitFinal}
                                />
                                <RenderButtons
                                    buttonSecondaryText={buttonSecondaryText}
                                    buttonMainText={buttonMainText}
                                    updating={updating}
                                    isSubmitting={formik.isSubmitting}
                                    classes={classes}
                                    t={t}
                                    formType={formType}
                                    secondaryActionHandler={secondaryAction}
                                    renderSecondaryButton={
                                        renderSecondaryButton
                                    }
                                    buttonTertiaryText={buttonTertiaryText}
                                    tertiaryActionHandler={tertiaryAction}
                                    renderTertiaryButton={renderTertiaryButton}
                                    formValues={formik.values}
                                    formikData={formik}
                                />
                                <FormikErrorFocus
                                    // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                                    offset={0}
                                    align={"top"}
                                    focusDelay={200}
                                    ease={"linear"}
                                    duration={1000}
                                    camposMetadados={filteredCamposMetadados}
                                    pageForm={pageForm}
                                />
                            </React.Fragment>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export const filterFormGroups = (
    formGroups: any,
    camposMetadados: any,
    fieldsToHide: string[] = []
) => {
    if (Array.isArray(fieldsToHide) && fieldsToHide.length > 0) {
        camposMetadados = camposMetadados.filter(
            (m: any) =>
                !fieldsToHide.includes(m.fieldName) && !m.complexFieldDetail
        );
    }

    let formGroupsId = camposMetadados
        .filter((c: any) => c.elementType !== "hidden")
        .map((f: any) => f.formGroupId)
        .filter((f: any) => f !== undefined);
    formGroupsId = [...new Set(formGroupsId)];
    const filteredFormGroups = formGroups.filter((f: any) =>
        formGroupsId.includes(f.id)
    );

    return filteredFormGroups;
};

interface IRenderButtonsProps {
    buttonSecondaryText: string;
    buttonMainText: string;
    updating: boolean;
    classes: any;
    t: TFunction;
    formType: string;
    secondaryActionHandler: any;
    renderSecondaryButton: boolean;
    buttonTertiaryText?: string;
    tertiaryActionHandler?: any;
    renderTertiaryButton?: boolean;
    formValues?: string;
    setFieldValueFunction?: any;
    formSubmitHandler?: any;
    isSubmitting: boolean;
    formikData: any;
}
// const RenderButtons = React.memo((props: IRenderButtonsProps) => {
//     const Buttons = () => {
//         let fullWidth = false;
//         const loading = props.isSubmitting || props.updating;
//         if (props.formType === "EXTERNAL") {
//             fullWidth = true;
//         }

//         return (
//             <React.Fragment>
//                 {props.buttonMainText !== "[disabled]" && (
//                     <Button
//                         variant="contained"
//                         type="submit"
//                         fullWidth={fullWidth}
//                         color="primary"
//                         disabled={loading}
//                         className={props.classes.button}
//                         data-testid="buttonMainText"
//                     >
//                         {props.buttonMainText}
//                     </Button>
//                 )}
//                 {props.renderSecondaryButton ? (
//                     <Button
//                         //variant="outlined"
//                         onClick={props.secondaryActionHandler}
//                         color="secondary"
//                         fullWidth={fullWidth}
//                         disabled={loading}
//                         className={props.classes.button}
//                         data-testid="buttonSecondaryText"
//                     >
//                         {props.buttonSecondaryText}
//                     </Button>
//                 ) : (
//                     ""
//                 )}
//                 {props.renderTertiaryButton ? (
//                     <Button
//                         //variant="outlined"
//                         onClick={() => {
//                             // props.setFieldValueFunction("isDraft", true, false);
//                             // props.formSubmitHandler();
//                             props.tertiaryActionHandler(
//                                 props.formValues,
//                                 props.formikData
//                             );
//                         }}
//                         // type="submit"
//                         color="default"
//                         variant="contained"
//                         fullWidth={fullWidth}
//                         disabled={props.updating}
//                         className={props.classes.button}
//                         data-testid="buttonTertiaryText"
//                     >
//                         {props.buttonTertiaryText}
//                     </Button>
//                 ) : (
//                     ""
//                 )}

//                 {loading && (
//                     <Grid container alignItems="center" justify="center">
//                         <CircularProgress size={24} />
//                     </Grid>
//                 )}
//             </React.Fragment>
//         );
//     };
//     const InternalFormButtons = () => {
//         if (props.formType === "INTERNAL") {
//             return (
//                 <Grid container alignItems="flex-start" justify="flex-start">
//                     <Buttons />
//                 </Grid>
//             );
//         } else {
//             return (
//                 <Grid item xs={12}>
//                     <Buttons />
//                 </Grid>
//             );
//         }
//     };
//     return <InternalFormButtons />;
// });
interface IRenderButtonsWraper {
    children: any;
    formType: string;
}
const RenderButtonsWraper = (props: IRenderButtonsWraper) => {
    if (props.formType === "INTERNAL") {
        return (
            <Grid container alignItems="flex-start" justify="flex-start">
                {props.children}
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12}>
                {props.children}
            </Grid>
        );
    }
};

const RenderButtons = (props: IRenderButtonsProps) => {
    let fullWidth = false;
    const loading = props.isSubmitting || props.updating;
    if (props.formType === "EXTERNAL") {
        fullWidth = true;
    }

    return (
        <RenderButtonsWraper formType={props.formType}>
            {props.buttonMainText !== "[disabled]" && (
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth={fullWidth}
                    color="primary"
                    disabled={loading}
                    className={props.classes.button}
                    data-testid="buttonMainText"
                >
                    {props.buttonMainText}
                </Button>
            )}
            {props.renderSecondaryButton ? (
                <Button
                    //variant="outlined"
                    onClick={props.secondaryActionHandler}
                    color="secondary"
                    fullWidth={fullWidth}
                    disabled={loading}
                    className={props.classes.button}
                    data-testid="buttonSecondaryText"
                >
                    {props.buttonSecondaryText}
                </Button>
            ) : (
                ""
            )}
            {props.renderTertiaryButton ? (
                <Button
                    //variant="outlined"
                    onClick={() => {
                        // props.setFieldValueFunction("isDraft", true, false);
                        // props.formSubmitHandler();
                        props.tertiaryActionHandler(
                            props.formValues,
                            props.formikData
                        );
                    }}
                    // type="submit"
                    color="default"
                    variant="contained"
                    fullWidth={fullWidth}
                    disabled={props.updating}
                    className={props.classes.button}
                    data-testid="buttonTertiaryText"
                >
                    {props.buttonTertiaryText}
                </Button>
            ) : (
                ""
            )}

            {loading && (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress size={24} />
                </Grid>
            )}
        </RenderButtonsWraper>
    );
};
// const InternalFormButtons = () => {
//     if (props.formType === "INTERNAL") {
//         return (
//             <Grid container alignItems="flex-start" justify="flex-start">
//                 <Buttons />
//             </Grid>
//         );
//     } else {
//         return (
//             <Grid item xs={12}>
//                 <Buttons />
//             </Grid>
//         );
//     }
// };
// return <InternalFormButtons />;
// });

const filterMetaDataFromPage = (
    IsMultiStepForm: boolean,
    camposMetadados: any,
    MultiStepForm: any,
    formGroups: any,
    currentPage: number = 0
) => {
    if (!IsMultiStepForm) {
        return camposMetadados;
    } else {
        let currentStep = MultiStepForm.filter(
            (m: any) => m.order === currentPage
        );

        // se não achar volta a primeira página
        if (currentStep.length === 0) {
            currentStep = MultiStepForm.filter((m: any) => m.order === 1);
        }

        let formGroupsPageIds = formGroups
            .filter((f: any) => f.stepId === currentStep[0].id)
            .map((f: any) => f.id);

        let filteredCamposMetadados = camposMetadados.filter((c: any) =>
            formGroupsPageIds.includes(c.formGroupId)
        );

        return filteredCamposMetadados;
    }
};

const removeEmptyOrNull = (obj: any) => {
    Object.keys(obj).forEach((k) => {
        if (!k.includes("_filesDefinition")) {
            if (obj[k] === null || obj[k] === undefined) {
                delete obj[k];
            } else if (typeof obj[k] === "string" && obj[k].trim() === "") {
                console.log("obj[k]", obj[k]);
                delete obj[k];
            } else if (Array.isArray(obj[k])) {
                for (const item of obj[k]) {
                    if (typeof item === "object") {
                        removeEmptyOrNull(item);
                    }
                }
            } else if (typeof obj[k] === "object") {
                removeEmptyOrNull(obj[k]);
            }
        }
    });

    return obj;
};

export default FormMain;
