import Page from "Pages/_Layouts/MainInternalPage";
import React from "react";
import {
    MainListToolbar,
    FilterList,
    ResultListToolbar,
    ListTablePagination
} from "./ListHelpers";
import { Box, Grid } from "@material-ui/core";
import useList from "./index";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BucketReport from "./ListHelpers/BucketReport/BucketReport";
import ErrorResult from "./ErrorResult";

interface IMainPage {
    children: any;
}

export const MainPageChildrenEmpty = (props: IMainPage) => {
    const [listData] = useList();
    const { readyTranslation } = listData.tempInfo;
    const ready = readyTranslation;
    const lateralFilter = useMediaQuery("(min-width:800px)");
    const isError = listData.error;

    if (isError) {
        return (
            <Page maxWidth="xl">
                <Box p={4} flexGrow={2} minWidth={0}>
                    <ErrorResult />
                </Box>
            </Page>
        );
    }

    if (ready) {
        return (
            <Page maxWidth="xl">
                <MainListToolbar />
                <Box
                    display="flex"
                    p={0}
                    height="100%"
                    flexDirection={lateralFilter ? "row" : "column"}
                >
                    <FilterList />
                    <Box p={0} flexGrow={2} minWidth={0}>
                        {listData?.localInfo?.bucketReportOpen ? (
                            <BucketReport />
                        ) : (
                            <>
                                <ResultListToolbar simpleToolbar />
                                {props.children}
                            </>
                        )}
                    </Box>
                </Box>
            </Page>
        );
    } else {
        return (
            <Page maxWidth="lg">
                <Loading />
            </Page>
        );
    }
};

export default MainPageChildrenEmpty;
