import React from "react";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import { getControlOptionsAndChildrenFilter } from "./util/AutoCompleteUtil";
import _ from "lodash";
import { getErrorAndMessages } from "./util/general";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    options: any;
    campoMetadata: Compleo.IObject;
    customLists: Compleo.IObject[];
    multiple?: boolean;
    parentFilterField?: Compleo.IObject;
    childrenFilterFieldName?: string;
    required: boolean;
    className?: any;
    language?: string;
    groupByField?: string;
    disableClearable?: boolean;
    inputProps?: any;

}

const ComboAutoComplete = (props: IProps & FieldProps) => {
    const {
        multiple,
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue, values },
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        groupByField,
        language: languageProps,
        ...other
    } = props;
    const language = languageProps || "pt-BR";

    const { error, message } = getErrorAndMessages(
        errors,
        field.name,
        touched,
        status,
        helperTextDefault
    );
    const multipleControl = multiple === undefined ? false : multiple;

    let defaultValue: any = null;
    if (multipleControl) {
        defaultValue = [];
    }

    let controlValue = defaultValue;
    const valueFromFormik = _.get(values, field.name);

    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilter(
        campoMetadata,
        options,
        customLists,
        field,
        values,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;
    const childrenFilterFieldName =
        controlOptionsAndChildrenFilter.childrenFilterFieldName;

    if (
        valueFromFormik !== "" &&
        valueFromFormik !== undefined &&
        valueFromFormik !== null
    ) {
        if (!valueFromFormik.label) {
            const newValue = controlOptions.filter(
                (item: any) => item.value === valueFromFormik.value
            );
            if (newValue.length > 0) {
                controlValue = newValue[0];
                if (controlValue.label === undefined) {
                    controlValue.label = newValue[0][`label-${language}`];
                }
            } else {
                controlValue = valueFromFormik;
            }
        } else {
            controlValue = valueFromFormik;
        }
    }

    React.useEffect(() => {
        if (
            valueFromFormik &&
            (controlOptions || []).length > 0 &&
            !(controlOptions || []).find(
                (item: any) => item?.value === valueFromFormik.value
            )
        ) {
            setFieldValue(field.name, defaultValue);
        }
    }, [valueFromFormik, controlOptions]);

    return (
        <Autocomplete
            disableClearable={other.disableClearable}
            className={other.className}
            autoComplete
            multiple={multipleControl}
            id={field.name}
            value={controlValue}
            options={controlOptions}
            groupBy={
                groupByField ? (option) => option[groupByField] : undefined
            }
            getOptionLabel={(option: any) => {
                return option.label || option[`label-${language}`] || "";
            }}
            getOptionSelected={(option, value) => {
                return value.value === option.value;
            }}
            // inputValue={(controlValue || {}).label || ""}
            onChange={(event: any, newValue: any | null) => {
                if (
                    newValue === null ||
                    newValue === undefined ||
                    newValue === ""
                ) {
                    setFieldValue(field.name, defaultValue);
                } else {
                    setFieldValue(field.name, newValue);
                }
                if (
                    childrenFilterFieldName !== undefined &&
                    childrenFilterFieldName !== ""
                ) {
                    setFieldValue(childrenFilterFieldName, defaultValue);
                }
            }}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    // margin="normal"
                    helperText={message}
                    autoComplete="new-password"
                    data-testid={field.name}
                />
            )}
        />
    );
};

export default ComboAutoComplete;
