import React from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { IFieldProps } from ".";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldDefault = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, fieldDef, t, language, inline } = props;

    return (
        <ItemView
            label={
                fieldDef.cardIcon ? (
                    <Icon color="action" fontSize="small">
                        {fieldDef.cardIcon}
                    </Icon>
                ) : (
                    `${t(fieldName)}: `
                )
            }
            fieldDef={fieldDef}
            removeColonAfterLabel={true}
            inline={inline}
        >
            {fieldDef?.viewElementType === "url" &&
            _.get(fieldData, fieldName) ? (
                <a
                    href={_.get(fieldData, fieldName)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {transformDBData({
                        value: _.get(fieldData, fieldName),
                        t,
                        language,
                        replaceOption: "-",
                        customDateFormat: fieldDef?.viewOptionCustomDateFormat
                    })}
                </a>
            ) : (
                transformDBData({
                    value: _.get(fieldData, fieldName),
                    t,
                    language,
                    replaceOption: "-",
                    customDateFormat: getDateFormat(fieldDef)
                })
            )}
        </ItemView>
    );
};

const getDateFormat = (fieldDef: Compleo.IObject) => {
    if (!fieldDef) return undefined;
    if (fieldDef.onlyMonthYear) {
        return "onlyMonthYear";
    }
    if (fieldDef.elementConfig?.onlyTime === true) {
        return "time";
    }
    if (fieldDef.viewOptionCustomDateFormat) {
        return fieldDef.viewOptionCustomDateFormat;
    }

    return undefined;
};

export default FieldDefault;
