import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
    pipelineAllActionsFields,
    PipelineActionTypeDefinition
} from "./helperPipeline";
import { TFunction } from "i18next";

interface IProps {
    handleOptionSelected: (optionSeleted: PipelineActionTypeDefinition) => void;
    hiddenFields: string[];
    t: TFunction;
}

export default function ActionsButton(props: IProps) {
    const { handleOptionSelected, hiddenFields, t } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        const item = pipelineAllActionsFields[index];
        // setSelectedIndex(index);
        setOpen(false);
        handleOptionSelected(item);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
            style={{
                marginTop: 16
            }}
        >
            <Grid item xs={12}>
                <ButtonGroup
                    variant="contained"
                    color="secondary"
                    ref={anchorRef}
                    aria-label={t("buttonAddNewAction")}
                    size="small"
                >
                    <Button onClick={handleToggle}>
                        {t("buttonAddNewAction")}
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label={t("buttonAddNewAction")}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 20000 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {pipelineAllActionsFields.map(
                                            (option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    disabled={
                                                        !hiddenFields.includes(
                                                            option
                                                        )
                                                    }
                                                    // selected={
                                                    //     index === selectedIndex
                                                    // }
                                                    onClick={(event) =>
                                                        handleMenuItemClick(
                                                            event,
                                                            index
                                                        )
                                                    }
                                                    style={{
                                                        whiteSpace: "normal"
                                                    }}
                                                >
                                                    {t(`button_${option}`)}
                                                </MenuItem>
                                            )
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}
