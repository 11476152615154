import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "customHooks/DataTable/datatable-column-header";
import { DataTableRow } from "customHooks/DataTable/datatable-row";

export const columnsSynth: (
    t: any
) => ColumnDef<CompleoShared.Reports.SyntheticTimeToStageDataItem>[] = (
    t: any
) => [
    {
        accessorKey: "stage",
        header: ({ column }) => {
            return <DataTableColumnHeader column={column} title={t("stage")} />;
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return <DataTableRow row={row} value={value} cell={cell} />;
        },
        enableGrouping: false
    },
    {
        accessorKey: "diffFromOpeningDateAvg",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("diffFromOpeningDateAvg")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    },
    {
        accessorKey: "diffFromOpeningDateMax",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("diffFromOpeningDateMax")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    },
    {
        accessorKey: "diffFromOpeningDateMin",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("diffFromOpeningDateMin")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    },
    {
        accessorKey: "diffFromOpeningDateMedian",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("diffFromOpeningDateMedian")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    },
    {
        accessorKey: "diffFromOpeningDateStdDev",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("diffFromOpeningDateStdDev")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    },
    {
        accessorKey: "total",
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={t("total")}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue, cell }) => {
            const value = getValue() as string;
            return (
                <DataTableRow
                    row={row}
                    value={value}
                    renderType="number"
                    decimalPlaces={2}
                    align="right"
                    cell={cell}
                />
            );
        },
        enableGrouping: false
    }
];
