import { useState } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useDomainActionsTable = (
    companyId: number,
    t: any,
    handleAddButtonVisibilityCheck: () => void
) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const deleteDomain = async (domain: string, uniqueLink: string) => {
        const url = `/careerssettings/domaindelete`;
        const data = {
            companyId,
            domain,
            uniqueLink
        };
        const result = await apiDirectCall(url, "post", data);

        if (result.status === 200) {
            showMessage(t("domainDelete_SuccessMessage"));
            return true;
        }

        return false;
    };

    const revalidateDomain = async (domain: string, uniqueLink: string) => {
        const url = `/careerssettings/domainrevalidate`;
        const data = {
            companyId,
            domain,
            uniqueLink
        };
        const result = await apiDirectCall(url, "post", data);
        if (result.status === 200) {
            showMessage(t("domainRevalidate_Success"));
            return true;
        }

        return false;
    };

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "delete",
                tooltip: t("delete"),
                onClick: (event: any, rowData: any) => {
                    //confirmDelete(rowData.domain, rowData.uniqueLink);
                    callDialog({
                        title: t("domainDeleteTitle"),
                        bodyText: t("domainDelete_Message", {
                            domain: rowData.domain
                        }),
                        agreeButtonText: t("COMPLEOGENERAL_YES"),
                        disagreeButtonText: t("COMPLEOGENERAL_NO"),
                        agreeFunction: async () => {
                            const result = await deleteDomain(
                                rowData.domain,
                                rowData.uniqueLink
                            );
                            if (result) {
                                handleAddButtonVisibilityCheck();
                                refreshListFunction();
                            }

                            return result;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            },
            (rowData: any) => {
                const hidden = rowData.status !== "verificationTimedOut";

                return {
                    icon: "refresh",
                    tooltip: t("revalidate"),
                    onClick: async (event: any, rowData: any) => {
                        callDialog({
                            title: t("domainRevalidateTitle"),
                            bodyText: t("domainRevalidate_Message", {
                                domain: rowData.domain
                            }),
                            agreeButtonText: t("COMPLEOGENERAL_YES"),
                            disagreeButtonText: t("COMPLEOGENERAL_NO"),
                            agreeFunction: async () => {
                                const result = await revalidateDomain(
                                    rowData.domain,
                                    rowData.uniqueLink
                                );
                                if (result) {
                                    refreshListFunction();
                                }

                                return result;
                            },
                            disagreeFunction: () => {
                                return false;
                            }
                        });
                    },
                    hidden: hidden
                };
            }
        ];

        return result;
    };
};

export default useDomainActionsTable;
