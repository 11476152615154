import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useApplicantViewContext from "./useApplicantViewContext";
import ApplicantViewSideContainer from "./ApplicantViewSideContainer";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperModal: {
            // backgroundColor: theme.palette.backgroundGrey.main
        }
    })
);

interface IApplicantViewSideContainerModal {
    open: boolean;
    handleClose: () => void;
}

const ApplicantViewSideContainerModal = (
    props: IApplicantViewSideContainerModal
) => {
    const { open, handleClose } = props;
    const [data] = useApplicantViewContext();
    const { t, ready } = data;
    const classes = useStyles();

    return (
        <Dialog
            fullScreen
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
                className: classes.paperModal
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {t("jobsComments")}
            </DialogTitle>
            <DialogContent>
                <ApplicantViewSideContainer />
            </DialogContent>
            {ready && (
                <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary">
                        {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ApplicantViewSideContainerModal;
