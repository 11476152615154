import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import useNextPageFunctionsNewJob from "./components/useNextPageFunctionsNewJob";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useHandleInitialValuesJob from "./components/useHandleInitialValuesJob";
import useAdditionalPropertiesAndFormMenu from "./components/useAdditionalPropertiesAndFormMenu";
import CustomComponents from "./components/CustomComponents";
import { formikReturnFN } from "./components/helper";
import { getHandleJobSubmit } from "./jobPostHelper";
import usePlanLimits from "customHooks/usePlanLimits/usePlanLimits";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const JobNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/job/list");
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);
    const [t, i18n, readyTranslation] = useTranslation("jobnew", {
        useSuspense: false
    });
    const { getLimit } = usePlanLimits();
    const { callDialog } = useGlobalDialog();

    const { company, timeZone } = useAuthState();

    const nextPageFunctions = useNextPageFunctionsNewJob(company.companyId, t);

    const jobInitialValuesPost = "/job/jobinitialvalues";
    const [jobInitialValuesData] = useApiCache(
        jobInitialValuesPost,
        "post",
        {
            companyId: company.companyId,
            cloneFromJobId: props.id
        },
        false
    );
    const jobInitialValuesDBData = jobInitialValuesData.response?.data;

    // console.log("jobInitialValuesDBData", jobInitialValuesDBData);
    const [listDefinition, readyLists] = useCustomLists(
        jobInitialValuesDBData?.lists
    );

    const postAddress = `/job/new`;
    const [addJobReturn, addJob] = useApi(postAddress, "post");

    // const postAddressSaveJobDraft = "/job/draft";
    // const [saveJobDraftReturn, saveJobDraft] = useApi(
    //     postAddressSaveJobDraft,
    //     "post"
    // );

    const [metadata] = useGetMetadata("JOBNEW");
    const [valuesFromSource] = useValuesFromSource(metadata, true);
    valuesFromSource.scorecard = {};

    const {
        initialValues,
        translations,
        messages,
        languages,
        defaultLanguage,
        ready: readyInitialValues
    } = useHandleInitialValuesJob(
        valuesFromSource,
        jobInitialValuesDBData || {},
        listDefinition,
        readyLists
    );
    const finalValues: Compleo.IObject = {
        ...initialValues,
        ...(jobInitialValuesDBData?.cloneData || {}),
        openingDate: initialValues.openingDate
    };
    finalValues.defaultLanguageWhenCreated = defaultLanguage;

    const addPropsAndFormMenu = useAdditionalPropertiesAndFormMenu(
        translations,
        messages,
        languages,
        defaultLanguage,
        readyInitialValues,
        t
    );

    React.useEffect(() => {
        if (languages && languages.length <= 1) {
            const fName = "translatedData";
            if (!fieldsToHide.find((f) => f === fName)) {
                const newFieldsToHide = [...fieldsToHide].filter(
                    (f) => f !== "translatedData"
                );
                newFieldsToHide.push("translatedData");
                setFieldsToHide([...newFieldsToHide]);
            }
        }
    }, [languages]);

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: finalValues,
        timeZone: timeZone
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const enableScreeningQuestionnaire = watch("enableScreeningQuestionnaire");
    const openingReason = watch("openingReason");

    React.useEffect(() => {
        formikReturnFN(setValue, watch, fieldsToHide, setFieldsToHide);
    }, [enableScreeningQuestionnaire, openingReason, fieldsToHide]);

    const handleSubmit = getHandleJobSubmit(addJob, "open", {
        t: t,
        getLimit: getLimit,
        callDialog: callDialog
    });
    const handleSubmitDraft = getHandleJobSubmit(addJob, "draft");

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: addJobReturn,
        redirectAddress: "/job/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: finalValues,
        timeZone: timeZone,
        nextPageFunctions: nextPageFunctions, //nextPageFunctions,
        secondaryButtonFunction: cancelFunction,
        tertiaryButtonFunction: handleSubmitDraft,
        customLists: listDefinition,
        CustomComponents: CustomComponents,
        FormMenuActions: addPropsAndFormMenu.FormMenuActions,
        additionalFieldProperties: addPropsAndFormMenu.additionalProperties,
        fieldsToHide: fieldsToHide,
        sizeToChangeStepHorizontal: "lg",
        reactHookFormMethods: reactHookFormMethods,
        invalidateQueryClientItems: ["/job/list"]
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const ready = addPropsAndFormMenu.ready && finished && readyTranslation;
    if (ready) {
        compRetorno = formCompleo;
        return (
            <>
                <Page
                    featureName={t("a_FeatureName")}
                    featureMainDescription={t("a_FeatureMainDescription")}
                    maxWidth="lg"
                >
                    {compRetorno}
                </Page>
            </>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobNew;
