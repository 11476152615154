import {
    MetadataReturn,
    useCustomSearchMetadataReturnType,
    useGetAllDataCustomSearch
} from "./useCustomSearchMetadata";
import * as formatValues from "functions/formatValues";
import * as compleoUtil from "functions/util";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { ApiStateType } from "customHooks/useApi";
import { i18n } from "i18next";

export type filtersType =
    | "rangeDate"
    | "matchText"
    | "rangeNumber"
    | "list"
    | "boolean"
    | "simpleText";

export type searchTypeText = "contains" | "notContains" | "match";
export type searchTypeList = "contains" | "notContains";

export const SearchTypesText: {
    items: searchTypeText[];
    default: searchTypeText;
} = {
    items: ["contains", "notContains", "match"],
    default: "contains"
};

export const SearchTypesList: {
    items: searchTypeList[];
    default: searchTypeList;
} = {
    items: ["contains", "notContains"],
    default: "contains"
};

export type getAllDataReturnType = {
    data: useGetAllDataCustomSearch;
    metadataMainList: ApiStateType;
    language: string;
    ready: boolean;
    i18n: any;
};

export const useGetAllData: (
    metadataToGetLists: string
) => getAllDataReturnType = (metadataToGetLists: string) => {
    // const [metadataMainList] = useGetMetadata(metadataOtherFiltersList);
    const [metadataMainList] = useGetMetadata(metadataToGetLists);
    /**
     * Date Range search type
     */
    const [
        metadataRangeDate,
        tRangeDate,
        i18n,
        readyTranslation1
    ] = useGetMetadataAndTranslation("rangeDate");

    /**
     * Number Range search type
     */
    const [
        metadataRangeNumber,
        tRangeNumber,
        ,
        readyTranslation2
    ] = useGetMetadataAndTranslation("rangeNumber");

    /**
     * Text search type
     */
    const [
        metadataMatchText,
        tMatchText,
        ,
        readyTranslation3
    ] = useGetMetadataAndTranslation("matchText");

    /**
     * List search type
     */
    const [
        metadataList,
        tList,
        ,
        readyTranslation4
    ] = useGetMetadataAndTranslation("list");

    /**
     * Boolean search type
     */
    const [
        metadataBoolean,
        tBoolean,
        ,
        readyTranslation5
    ] = useGetMetadataAndTranslation("boolean");

    /**
     * Simple Text search type
     */
    const [
        metadataSimpleText,
        tSimpleText,
        ,
        readyTranslation6
    ] = useGetMetadataAndTranslation("simpleText");

    /**
     * Ready information
     */
    const readyTranslation =
        readyTranslation1 &&
        readyTranslation2 &&
        readyTranslation3 &&
        readyTranslation4 &&
        readyTranslation5 &&
        readyTranslation6;

    const readyMetadata =
        metadataRangeDate.status === "success" &&
        metadataRangeNumber.status === "success" &&
        metadataMatchText.status === "success" &&
        metadataList.status === "success" &&
        metadataBoolean.status === "success" &&
        metadataSimpleText.status === "success" &&
        metadataMainList.status === "success";

    const returnData: getAllDataReturnType = {
        data: {
            matchText: {
                metadata: metadataMatchText,
                t: tMatchText
            },
            rangeDate: {
                metadata: metadataRangeDate,
                t: tRangeDate
            },
            rangeNumber: {
                metadata: metadataRangeNumber,
                t: tRangeNumber
            },
            list: {
                metadata: metadataList,
                t: tList
            },
            boolean: {
                metadata: metadataBoolean,
                t: tBoolean
            },
            simpleText: {
                metadata: metadataSimpleText,
                t: tSimpleText
            }
        },
        language: i18n.languages[0],
        ready: readyMetadata && readyTranslation,
        metadataMainList: metadataMainList,
        i18n: i18n
    };
    return returnData;
};

const useGetMetadataAndTranslation: (
    type: filtersType
) => [ApiStateType, any, i18n, boolean] = (type: filtersType) => {
    const metadataName = getMetadataName(type);
    const [t, i18n, readyTranslation] = useTranslation(metadataName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(metadataName);

    return [metadata, t, i18n, readyTranslation];
};

export const getMetadataName = (type: filtersType) => {
    switch (type) {
        case "rangeDate":
            return "ELASTICLISTSETTINGSDATERANGEFORM";
        case "matchText":
            return "ELASTICLISTSETTINGSMATCHTEXTFORM";
        case "rangeNumber":
            return "ELASTICLISTSETTINGSNUMBERRANGEFORM";
        case "list":
            return "ELASTICLISTSETTINGSLISTFORM";
        case "boolean":
            return "ELASTICLISTSETTINGSBOOLEANFORM";
        case "simpleText":
            return "ELASTICLISTSETTINGSSIMPLETEXTFORM";
        default:
            return "";
    }
};

export const addSearchType = (
    type: filtersType,
    filterName: string,
    customSearchMetadata: useCustomSearchMetadataReturnType,
    otherSearchDef?: Compleo.IObject,
    otherLists?: Compleo.ListDefinitionType[]
) => {
    switch (type) {
        case "rangeDate":
            customSearchMetadata.addRangeDate(filterName, otherSearchDef);
            break;
        case "matchText":
            customSearchMetadata.addMatchText(filterName, otherSearchDef);
            break;
        case "simpleText":
            customSearchMetadata.addSimpleText(filterName, otherSearchDef);
            break;
        case "rangeNumber":
            customSearchMetadata.addRangeNumber(filterName, otherSearchDef);
            break;
        case "list":
            customSearchMetadata.addList(
                filterName,
                otherSearchDef,
                otherLists
            );
            break;
        case "boolean":
            customSearchMetadata.addBoolean(filterName, otherSearchDef);
            break;
    }
};

export const addFilterUserInfo = (
    type: filtersType,
    filterObj: Compleo.IObject,
    description: string,
    customFilterName: string,
    t: any,
    language: string
) => {
    switch (type) {
        case "matchText":
            return {
                value: filterObj.searchText,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        case "simpleText":
            return {
                value: filterObj.searchText,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        case "rangeDate":
            // const format = formatValues.getFormat();
            const fromText = filterObj.dateFrom
                ? `${t("ELASTICLISTSETTINGS:from")} ${formatValues.formatDate(
                      filterObj.dateFrom,
                      language,
                      "dayMonthYear"
                  )}`
                : "";
            const toText = filterObj.dateTo
                ? `${t("ELASTICLISTSETTINGS:to")} ${formatValues.formatDate(
                      filterObj.dateTo,
                      language,
                      "dayMonthYear"
                  )}`
                : "";
            return {
                value: `${fromText} ${toText}`,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        case "rangeNumber":
            const fromTextNumber = filterObj.numberFrom
                ? `${t("ELASTICLISTSETTINGS:from")} ${filterObj.numberFrom}`
                : "";
            const toTextNumber = filterObj.numberTo
                ? `${t("ELASTICLISTSETTINGS:to")} ${filterObj.numberTo}`
                : "";
            return {
                value: `${fromTextNumber} ${toTextNumber}`,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        case "list":
            const listData = compleoUtil.transformDBData({
                value: filterObj.listData,
                t,
                language,
                replaceOption: ""
            });
            let returnInfo: any = listData;
            if (Array.isArray(listData)) {
                returnInfo = listData.join(" | ");
            }
            return {
                value: returnInfo,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        case "boolean":
            const listDataBoolean = compleoUtil.transformDBData({
                value: filterObj.listData,
                t,
                language,
                replaceOption: ""
            });

            return {
                value: listDataBoolean,
                description: description,
                descriptionKey: customFilterName,
                valueKey: customFilterName,
                type: "customSearch"
            };
        default:
            break;
    }
};

export const verifyFilterIsEnabled = (
    filterItem: string,
    otherFilters: Compleo.IObject,
    customSearch: Compleo.IObject
) => {
    const filterDef = otherFilters[filterItem];
    const filterValue = customSearch[filterItem];
    const type: filtersType = filterDef.type;
    switch (type) {
        case "matchText":
            if (filterValue?.searchText) {
                return true;
            }
            break;
        case "simpleText":
            if (filterValue?.searchText) {
                return true;
            }
            break;
        case "rangeDate":
            if (filterValue?.dateFrom || filterValue?.dateTo) {
                return true;
            }
            break;
        case "rangeNumber":
            if (filterValue?.numberFrom || filterValue?.numberTo) {
                return true;
            }
            break;
        case "list":
        case "boolean":
            if (filterValue?.listData) {
                return true;
            }
            break;
        default:
            break;
    }
    return false;
};

export const handleInitialValuesAdvancedFilter = (
    values: Compleo.IObject,
    listTypeIds: { type: filtersType; id: string }[]
) => {
    listTypeIds.map((item) => {
        handleInitialValues(values, item.type, item.id);
    });
    return values;
};

export const handleInitialValues = (
    values: Compleo.IObject,
    type: filtersType,
    id?: string
) => {
    const searchTypeFieldName = id ? `${id}_searchType` : "searchType";
    switch (type) {
        case "matchText":
            if (!values[searchTypeFieldName]?.value) {
                values[searchTypeFieldName] = {
                    value: SearchTypesText.default
                };
            }
            break;
        case "simpleText":
            if (!values[searchTypeFieldName]?.value) {
                values[searchTypeFieldName] = {
                    value: SearchTypesText.default
                };
            }
            break;
        case "list":
            if (!values[searchTypeFieldName]?.value) {
                values[searchTypeFieldName] = {
                    value: SearchTypesList.default
                };
            }
            break;
    }
    return values;
};

export const getTypeFromCustomFieldType: (
    localType?: filtersType,
    customFieldType?: string
) => filtersType = (localType?: filtersType, customFieldType?: string) => {
    if (localType) {
        return localType;
    }
    switch (customFieldType) {
        case "text":
            return "matchText";
        case "number":
        case "currency":
            return "rangeNumber";
        case "bool":
            return "boolean";
        case "date":
            return "rangeDate";
        case "list":
        case "arrayList":
            return "list";
        default:
            return "matchText";
    }
};

export const getTranslationAndMetadataInfo = (
    type: filtersType,
    allData: getAllDataReturnType
) => {
    switch (type) {
        case "matchText":
            return allData.data.matchText;
        case "rangeDate":
            return allData.data.rangeDate;

        case "rangeNumber":
            return allData.data.rangeNumber;

        case "list":
            return allData.data.list;

        case "boolean":
            return allData.data.boolean;

        case "simpleText":
            return allData.data.simpleText;
        default:
            return allData.data.matchText;
    }
};
