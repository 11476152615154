import React from "react";
import { Box, Fab, Menu, MenuItem } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import {
    Check,
    Save,
    ExpandLess,
    ExpandMore,
    Add,
    Cancel
} from "@material-ui/icons";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useDashboardContext from "../useDashboardContext";
import _ from "lodash";
import { gridLayoutSizes } from "./_DashboardHelper";
import { apiDirectCall } from "customHooks/useApi";
import DashboarNewSaveModal from "./DashboarNewSaveModal";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const useClasses = makeStyles((theme: Theme) =>
    createStyles({
        boxButtons: {
            position: "absolute",
            bottom: theme.spacing(15),
            right: theme.spacing(4),
            zIndex: 1000
        },
        fabSave: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[600]
            }
        },
        fabAddWidget: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[600],
            "&:hover": {
                backgroundColor: theme.palette.grey[700]
            }
        },
        fabCancel: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: theme.palette.secondary.main
            }
        },
        fabBack: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: theme.palette.secondary.main
            }
        },
        fabMain: {
            position: "absolute",
            bottom: theme.spacing(15),
            right: theme.spacing(4),
            color: theme.palette.common.white,
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[600]
            },
            zIndex: 1000
        },
        fabIcon: {
            marginRight: theme.spacing(1)
        }
    })
);

const DashboardEditModeFloatButtons = () => {
    const classes = useClasses();
    const queryClient = useQueryClient();
    const showMessage = useShowMessage();
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const [dashboardNewOpen, setDashboardNewOpen] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { t } = data;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const allWidgets =
        data.localStateData.currentDashboardDef?.widgetListAllOptions || [];
    const currentWidgets =
        data.localStateData.currentDashboardDef?.widgetList || [];
    const widgetList = allWidgets
        .filter((i) => !currentWidgets.includes(i))
        .map((i) => {
            return {
                id: i,
                label: t(`${i}_Name`)
            };
        });

    const saveAndCloseEditMode = async (saveAs: boolean = false) => {
        if (data.localStateData?.newMode || saveAs) {
            setDashboardNewOpen(true);
        } else {
            try {
                const type =
                    data?.localStateData?.pageToRender === "main"
                        ? "MAIN"
                        : data?.localStateData?.currentDashboardDef?.type ===
                          "company"
                        ? "COMPANY"
                        : "CUSTOM";

                await apiDirectCall("/reports/putdashboard", "post", {
                    companyId: data.companyId,
                    type: type,
                    gridLayoutDef: {
                        widgetList:
                            data.localStateData?.currentDashboardDef
                                ?.widgetList || [],
                        layouts:
                            data.localStateData?.currentDashboardDef?.layouts ||
                            {}
                    },
                    id: data.localStateData?.currentDashboardDef?.id,
                    name: data.localStateData?.currentDashboardDef?.name,
                    isAdmin: true
                });

                queryClient.invalidateQueries(`/reports/listdashboards`);

                dispatch.dispatchGeneralPageInfo({
                    payload: {
                        editMode: false,
                        currentDashboardStateBeforeEditOrNewDef: undefined
                    },
                    type: "update"
                });
            } catch (ex) {
                showMessage({
                    message: t("NotIdentifiedProblem"),
                    snackBarOrign: { vertical: "bottom", horizontal: "right" },
                    timeout: 1000 * 5,
                    enabled: true,
                    typeMessage: "error"
                });
            }
        }
    };

    const cancelEditMode = () => {
        const oldState = _.cloneDeep(
            data.localStateData?.currentDashboardStateBeforeEditOrNewDef
        );
        if (oldState) {
            dispatch.dispatchGeneralPageInfo({
                payload: {
                    editMode: false,
                    currentDashboardDef: oldState,
                    currentDashboardStateBeforeEditOrNewDef: undefined
                },
                type: "update"
            });
        } else {
            dispatch.dispatchGeneralPageInfo({
                payload: {
                    editMode: false,
                    currentDashboardDef: undefined,
                    currentDashboardStateBeforeEditOrNewDef: undefined
                },
                type: "update"
            });
        }
    };

    const confirmCancel = () => {
        callDialog({
            title: t("mainPageName"),
            bodyText: t("Message_Cancel"),
            agreeButtonText: t("button_YesCancel"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                cancelEditMode();
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    const addWidget = (id: string) => {
        const currentDef = data.localStateData.currentDashboardDef;
        if (currentDef) {
            // const newWidgetList = currentDef.widgetList;
            currentDef.widgetList.push(id);
            const layouts = _.cloneDeep(currentDef.layouts);
            for (const layoutSize of gridLayoutSizes) {
                if (layouts[layoutSize]) {
                    layouts[layoutSize].push({
                        i: id,
                        x: 0,
                        y: 0,
                        w: 2,
                        h: 2
                    });
                }
            }
            currentDef.layouts = layouts;

            dispatchGeneralPageInfo({
                payload: { currentDashboardDef: currentDef },
                type: "update"
            });
        }
        handleClose();
    };

    const restoreDefault = () => {
        const defaultDash = _.cloneDeep(
            data.localStateData?.compleoDashboardDef
        );
        dispatchGeneralPageInfo({
            payload: {
                currentDashboardDef: defaultDash
            },
            type: "update"
        });
    };

    const confirmRestore = () => {
        callDialog({
            title: t("mainPageName"),
            bodyText: t("Message_Restore"),
            agreeButtonText: t("button_YesRestore"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                restoreDefault();
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };
    if (!mainMenuOpen) {
        return (
            <Fab
                variant="extended"
                className={classes.fabMain}
                onClick={() => setMainMenuOpen(true)}
                size="medium"
                aria-label={t("FloatButton_Options", "")}
            >
                <ExpandLess className={classes.fabIcon} />
                {t("FloatButton_Options", "")}
            </Fab>
        );
    } else {
        return (
            <Box
                display="flex"
                flexDirection="column"
                className={classes.boxButtons}
            >
                <Box mb={1} width="100%">
                    <Fab
                        variant="extended"
                        className={classes.fabSave}
                        onClick={() => saveAndCloseEditMode()}
                        // disabled={formik.isSubmitting}
                        size="medium"
                        aria-label={t("FloatButton_Save", "")}
                        style={{ width: "100%" }}
                    >
                        <Save className={classes.fabIcon} />
                        {t("FloatButton_Save", "")}
                    </Fab>
                </Box>
                {data.localStateData?.newMode !== true && (
                    <Box mb={1} width="100%">
                        <Fab
                            variant="extended"
                            className={classes.fabSave}
                            onClick={() => saveAndCloseEditMode(true)}
                            // disabled={formik.isSubmitting}
                            size="medium"
                            aria-label={t("FloatButton_SaveAS", "")}
                            style={{ width: "100%" }}
                        >
                            <Save className={classes.fabIcon} />
                            {t("FloatButton_SaveAS", "")}
                        </Fab>
                    </Box>
                )}
                <Box mb={1} width="100%">
                    <Fab
                        variant="extended"
                        className={classes.fabAddWidget}
                        onClick={openMenu}
                        size="medium"
                        aria-label={t("FloatButton_AddWidget", "")}
                        style={{ width: "100%" }}
                    >
                        <Add className={classes.fabIcon} />
                        {t("FloatButton_AddWidget")}
                    </Fab>
                </Box>
                <Box mb={1} width="100%">
                    <Fab
                        variant="extended"
                        className={classes.fabAddWidget}
                        onClick={confirmRestore}
                        size="medium"
                        aria-label={t("FloatButton_restoreDefault", "")}
                        style={{ width: "100%" }}
                    >
                        {t("FloatButton_restoreDefault")}
                    </Fab>
                </Box>{" "}
                {data.localStateData?.newMode !== true &&
                data.localStateData?.currentDashboardStateBeforeEditOrNewDef ? (
                    <Box mb={1} width="100%">
                        <Fab
                            variant="extended"
                            className={classes.fabCancel}
                            onClick={confirmCancel}
                            size="medium"
                            aria-label={t("FloatButton_Cancel", "")}
                            style={{ width: "100%" }}
                        >
                            <Cancel className={classes.fabIcon} />
                            {t("FloatButton_Cancel")}
                        </Fab>
                    </Box>
                ) : (
                    <Box mb={1} width="100%">
                        <Fab
                            variant="extended"
                            className={classes.fabCancel}
                            onClick={confirmCancel}
                            size="medium"
                            aria-label={t("COMPLEOGENERAL_CANCEL", "")}
                            style={{ width: "100%" }}
                        >
                            <Cancel className={classes.fabIcon} />
                            {t("COMPLEOGENERAL_CANCEL")}
                        </Fab>
                    </Box>
                )}{" "}
                <Box width="100%">
                    <Fab
                        variant="extended"
                        // className={classes.fabBack}
                        onClick={() => setMainMenuOpen(false)}
                        size="medium"
                        aria-label={t("FloatButton_Back", "")}
                        style={{ width: "100%" }}
                    >
                        <ExpandMore className={classes.fabIcon} />
                        {t("FloatButton_Back", "")}
                    </Fab>
                </Box>
                <Menu
                    id="card-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    {widgetList.map((option) => {
                        return (
                            <MenuItem
                                key={option.id}
                                onClick={() => addWidget(option.id)}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
                <DashboarNewSaveModal
                    open={dashboardNewOpen}
                    onClose={() => setDashboardNewOpen(false)}
                />
            </Box>
        );
    }
};

export default DashboardEditModeFloatButtons;
