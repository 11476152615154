import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const apiAddressRoot = process.env.REACT_APP_API_ADDRESS;

export const i18f = (
    url: string = `${apiAddressRoot}/general/translation/0/0`
) => {
    localStorage.setItem("i18nPath", url);
    // const [firstLoad, setFirstLoad] = React.useState(true);
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        // .use(LanguageDetector) // removed from problems with other languages
        .use(Backend)
        .init({
            lng: "pt-BR",
            fallbackLng: "en-US",
            keySeparator: false, // we do not use keys in form messages.welcome
            //  loadPath: '/locales/{{lng}}/{{ns}}.json',
            backend: {
                loadPath: `${url}/{{lng}}/{{ns}}`,
                crossDomain: true,
                allowMultiLoading: false
            },
            interpolation: {
                escapeValue: false // react already safes from xss
            },
            debug: false,
            load: "currentOnly"
        });

    return i18n;
};

const i18firstTime = () => {
    const currentPath = localStorage.getItem("i18nPath");
    if (currentPath !== null && currentPath !== "") {
        i18f(currentPath);
    } else {
        i18f();
    }
};

i18firstTime();
