import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { CustomListType } from "./customListsUtil";

const useCustomFieldList: CustomListType = () => {
    const listAddress = "/job/list";
    const { company } = useAuthState();

    const [listResult, getList] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId,
            pagination: {
                pageSize: 10000,
                currentPage: 1
            }
        },
        true,
        60 * 1000 * 10
    );
    const ready = listResult.status === "success";
    const data = (listResult.response?.data?.fields || []).map(
        (item: Compleo.IObject) => {
            return { label: item.title, value: item.pk };
        }
    );
    const run = async () => {
        await getList();
    };
    return { data, ready, run };
};

export default useCustomFieldList;
