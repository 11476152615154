import React from "react";
import Editor from "@monaco-editor/react";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

interface IModalViewFile {
    open: boolean;
    handleClose: () => void;
    t: any;
    htmlValue?: string;
    onChange: any;
}

const ModalViewFile = (props: IModalViewFile) => {
    const { open, handleClose, t, htmlValue, onChange } = props;
    const [code, setCode] = React.useState(htmlValue);

    function handleEditorChange(value: any, event: any) {
        // console.log("here is the current model value:", value);
        setCode(value);
    }

    React.useEffect(() => {
        setCode(htmlValue);
    }, [htmlValue]);

    const handleSave = async () => {
        const contentBlock = htmlToDraft(code || "");
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            await onChange(editorState);
            // setLocalValue(editorState);
            // EditorState.set(editorState);
            // value = editorState;
        }
        handleClose();
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    {t("RICHTEXT_EDITCODE")}
                </DialogTitle>
                <DialogContent>
                    <Editor
                        height="80vh"
                        theme="vs-dark"
                        defaultLanguage="html"
                        defaultValue={code}
                        value={code}
                        onChange={handleEditorChange}
                        options={{
                            readOnly: false,
                            automaticLayout: true,
                            // autoClosingBrackets: true,
                            fontSize: 14
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="text"
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalViewFile;
