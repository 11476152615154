import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import {
    IJobViewStages,
    IStagesOnlyTotal
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import { useAuthState } from "_ReactContext/AuthContext";
import { ChangeStageButtonDisqualifyReasonModalFnToRunType } from "./ChangeStageButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    fnToRun?: ChangeStageButtonDisqualifyReasonModalFnToRunType;
}

const ChangeStageButtonDisqualifyReasonModal = (props: IPropsItemFilter) => {
    const { onClose, open, fnToRun } = props;
    const formikRef = React.useRef(null);
    // const classes = useStyles();
    // // const { changeCardStateData } = useUpdateCard();
    // const showMessage = useShowMessage();
    // const queryClient = useQueryClient();
    // // const updateAllInfo = useRefreshKanban();

    const { company } = useAuthState();

    const module = "MODALDISQUALIFYREASON";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const language = i18n.languages[0];
    const [initialValues] = useValuesFromSource(metadata, false, {
        disqualifyReason: {
            value: "CP0",
            label: language === "pt-BR" ? "Não informado" : "Not informed"
        }
    });
    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        if (fnToRun) {
            await fnToRun(values.disqualifyReason);
        }
        onClose();
        return retorno;
    };

    const handleSave = () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const title = t("modalTitle");

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={title}
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                >
                    {t("modalButtonSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeStageButtonDisqualifyReasonModal;
