// import { useDispatch } from "react-redux";
import {
    useNotification,
    TypeMessage,
    INotificationContext
} from "_ReactContext/NotificationContext";

export type typeShowMessage = (
    message: string | INotificationContext,
    variant?: TypeMessage,
    timeout?: null | number
) => void;

const useShowMessage = () => {
    // const dispatch = useDispatch();
    const { setMessage, setMessageObjParam } = useNotification();

    /**
     * Use only the first parameter with an object type INotificationContext.
     * The other parameters is available only for keep compatibility
     * Parameter snackBarOrign format = {
     *   vertical: "top",
     *   horizontal: "right"
     * }
     * @param message String or INotificationContext
     * @param variant
     * @param timeout
     */
    const showMessage: typeShowMessage = (
        message: string | INotificationContext,
        variant?: TypeMessage,
        timeout: null | number = 6000
    ) => {
        // let localMessage = message;
        if (!message) return;
        if (typeof message === "string") {
            setMessageObjParam({
                message: message,
                timeout: timeout,
                typeMessage: variant,
                enabled: true
            });
        } else {
            setMessageObjParam({
                message: message.message,
                timeout: message.timeout,
                typeMessage: message.typeMessage,
                enabled: message.enabled === undefined ? true : message.enabled,
                className: message.className,
                snackBarOrign: message.snackBarOrign
            });
        }
    };

    return showMessage;
};

export default useShowMessage;
