import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "../../../Email/ApplicantEmailRecipientsTags";
import { Grid, Typography } from "@material-ui/core";
import useApplicantViewContext from "../useApplicantViewContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    files: any;
}

const ModalSaveEmailAttachments = (props: IModalNewMail) => {
    const { open, handleClose, files } = props;
    const queryClient = useQueryClient();

    const [isUpdating, setIsUpdating] = React.useState(false);
    const [data] = useApplicantViewContext();
    const applicantId = data.applicantId;
    const showMessage = useShowMessage();

    const module = "EMAILSAVEATTACHMENTS";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, true);
    initialValues.applicantId = applicantId;
    initialValues.attachments = files;

    const ready = readyTranslation && metadata.status === "success";
    const formikRef = React.useRef(null);

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        // console.log("values: ", JSON.stringify(values, null, 4));
        try {
            setIsUpdating(true);
            const postAddress = `/applicant/applicantsavedocumentsfromemail`;
            await apiDirectCall(postAddress, "post", values);
            setIsUpdating(false);
            queryClient.invalidateQueries(data.queryKey);

            showMessage({
                message: t("successMessageLocal"),
                typeMessage: "success",
                snackBarOrign: { horizontal: "right", vertical: "top" }
            });
            handleClose();
            return retorno;
        } catch (ex) {
            const response = ex.response;
            const responseData = response?.data;
            setIsUpdating(false);
            if (responseData?.code === "filesWithSameNameError") {
                showMessage({
                    message: t("errorSameFileName", {
                        fileList: responseData?.message
                    }),
                    typeMessage: "error",
                    snackBarOrign: { horizontal: "right", vertical: "top" }
                });
                retorno.status = 999;
                return retorno;
            }
            return ex.response;
        }
    };

    const handleSave = async () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef

        // secondaryButtonFunction: handleClose
        // additionalFieldProperties: addProps
        // formikEnableReinitialize: false
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {ready ? t("saveAttachments") : ""}
            </DialogTitle>
            <DialogContent>{FormReturn}</DialogContent>
            <DialogActions>
                {ready && (
                    <>
                        <Button
                            onClick={() => handleClose()}
                            color="secondary"
                            disabled={isUpdating}
                        >
                            {t("COMPLEOGENERAL_CLOSE")}
                        </Button>
                        <Button
                            onClick={() => handleSave()}
                            variant="contained"
                            color="primary"
                            disabled={isUpdating}
                        >
                            {t("COMPLEOGENERAL_SAVE")}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalSaveEmailAttachments;
