import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar, { AvatarProps, AvatarTypeMap } from "@material-ui/core/Avatar";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getFirstLetterNameLastName } from "functions/util";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export type avatarCompleoType = "user" | "applicant" | "applicantExternal";

interface IProps {
    type: avatarCompleoType;
    idParams: Compleo.IObject;
}
const CompleoAvatar = (props: IProps & AvatarProps) => {
    const classes = useStyles();
    const { idParams, type, ...other } = props;
    const { company } = useAuthState();
    const { params, path } = getQueryParams(type, idParams, company.companyId);
    const [getAvatarReturn, executeQueryAvatarReturn] = useApiCache(
        path,
        "post",
        params,
        true,
        1000 * 60 * 10
    );
    const data = getAvatarReturn?.response?.data;

    React.useEffect(() => {
        executeQueryAvatarReturn(params);
    }, [idParams?.id, idParams?.email]);

    if (data?.photo) {
        return (
            <Avatar
                alt={data?.name}
                {...other}
                src={`data:image/jpeg;base64,${data.photo}`}
            />
        );
    } else if (data?.name) {
        return (
            <Avatar {...other} src="" alt={data?.name}>
                {getFirstLetterNameLastName(data?.name || "")}
            </Avatar>
        );
    } else {
        return <Avatar {...other} alt="" src="" />;
    }
};

const getQueryParams = (
    type: avatarCompleoType,
    idParams: Compleo.IObject,
    companyId: number
) => {
    switch (type) {
        case "applicant":
            return {
                params: {
                    id: idParams?.id,
                    companyId: companyId
                },
                path: `/applicant/getavatarapplicant`
            };
        case "applicantExternal":
            return {
                params: {
                    id: idParams?.id,
                    evaluationId: idParams?.evaluationId
                },
                path: `/applicant/getavatarapplicantexternal`
            };
        case "user":
            return {
                params: {
                    email: idParams?.email,
                    companyId: companyId
                },
                path: `/user/getavataruser`
            };
    }
};

export default CompleoAvatar;
