import React from "react";
import Page from "../_Layouts/DefaultInternal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import useAlertDialog from "customHooks/useDialog";
import { useAuthorization } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        }
    })
);

const TestFeature = () => {
    const classes = useStyles();
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const { isFeatureAuthorized } = useAuthorization();
    const agreeErrorFunction = () => {
        navigate(`/user/list`);
    };

    // IMPORTANTE: No uso real, as strings devem se enviadas de acordo com a tradução i18n
    const [openError, closeError, FormError, agreeError] = useAlertDialog(
        "Erro",
        "Função não disponível",
        "Concordo",
        "Não Concordo",
        "md",
        agreeErrorFunction
    );

    // IMPORTANTE: No uso real, as strings devem se enviadas de acordo com a tradução i18n
    const [
        openSuccess,
        closeSuccess,
        FormSuccess,
        agreeSuccess
    ] = useAlertDialog(
        "Sucesso",
        "Função disponível",
        "Concordo",
        "Não Concordo"
    );

    console.log("agree", agree);
    // console.log("agreeSuccess", agreeSuccess);

    const callFeature = () => {
        if (isFeatureAuthorized("BatchCustomerTest")) {
            openSuccess();
        } else {
            openError();
        }
    };

    const callGlobal = () => {
        callDialog({
            title: "Teste",
            bodyText: "Texto de body",
            agreeButtonText: "Concordo",
            disagreeButtonText: "Discordo",
            agreeFunction: async () => {
                console.log("concordou");
            },
            disagreeFunction: () => {
                console.log("discordou");
            }
        });
    };
    // BatchCustomerTest
    return (
        <Page>
            <Paper className={classes.root}>
                <Typography variant="h5" component="h3">
                    This is a sheet of paper.
                </Typography>
                <Typography component="p">
                    Paper can be used to build surface or other elements for
                    your application.
                </Typography>
                <Button
                    onClick={callFeature}
                    variant="contained"
                    color="primary"
                >
                    Primary
                </Button>
                <Button
                    onClick={callGlobal}
                    variant="contained"
                    color="primary"
                >
                    Call Global
                </Button>
                <FormSuccess />
                <FormError />
            </Paper>
        </Page>
    );
};

export default TestFeature;
