import React, { useState } from "react";
import { apiDirectCall, AxiosType } from "./api";
// import { useQuery, queryCache } from "react-query";
import { useQueryClient, useQuery } from "react-query";

export type ApiStateType = {
    status: "initial" | "fetching" | "success" | "error";
    response: Compleo.IObject;
    exception: any;
    useQueryKey?: any;
};

export type UseApiType = (
    endpoint: string,
    verb?: AxiosType,
    params?: Compleo.IObject,
    executeJustOnCall?: boolean,
    staleTimeMiliseconds?: number,
    keepPreviousData?: boolean,
    alternativeMainKey?: string,
    useQueryParams?: any
) => [ApiStateType, ApiCallType, ApiCallType?];

export type ApiCallType = (
    newParams?: Compleo.IObject,
    endpointParam?: string,
    verbParam?: AxiosType
) => Promise<void>;

const genericCall = async ({ address, verb, params }: any) => {
    const { data } = await apiDirectCall(address, verb, params);
    return data;
};

export const useApiCache: UseApiType = (
    endpoint: string,
    verb: AxiosType = "get",
    params = {},
    executeJustOnCall = true,
    staleTimeMiliseconds = 0,
    keepPreviousData: boolean = false,
    alternativeMainKey?: string,
    useQueryParams: any = {}
) => {
    const queryClient = useQueryClient();

    const [paramsState, setParamsState] = useState({
        endpoint,
        verb,
        params,
        execute: !executeJustOnCall
    });
    const makeRequest = async (
        newParams = params,
        endpointParam: string = endpoint,
        verbParam: AxiosType = verb
    ) => {
        setParamsState({
            endpoint: endpointParam,
            verb: verbParam,
            params: newParams,
            execute: true
        });
    };

    let parameter: [string, string, AxiosType, Compleo.IObject];

    parameter = [
        alternativeMainKey || paramsState.endpoint,
        paramsState.endpoint,
        paramsState.verb,
        paramsState.params
    ];

    const query = useQuery(
        parameter,
        () =>
            genericCall({
                address: paramsState.endpoint,
                verb: paramsState.verb,
                params: paramsState.params
            }),
        {
            staleTime: staleTimeMiliseconds,
            refetchOnWindowFocus: false,
            enabled: paramsState.execute,
            keepPreviousData: keepPreviousData,
            ...useQueryParams
        }
    );

    const invalidateQuery = async (
        newParams = params,
        endpointParam: string = endpoint,
        verbParam: AxiosType = verb
    ) => {
        if (params === undefined) {
            parameter = [
                paramsState.endpoint,
                paramsState.endpoint,
                paramsState.verb,
                paramsState.params
            ];
        } else {
            parameter = [endpointParam, endpointParam, verbParam, newParams];
        }
        queryClient.invalidateQueries(parameter);
    };

    let status: "initial" | "fetching" | "success" | "error" = "initial";
    switch (query.status) {
        case "success":
        case "error":
            status = query.status;
            break;
        case "loading":
            status = "fetching";
            break;
        default:
            status = "initial";
            break;
    }
    let statusRetorno = status;
    if (paramsState.execute === false) {
        statusRetorno = "initial";
    }
    const metadadosRetorno: ApiStateType = {
        response: { data: query.data },
        status: statusRetorno,
        exception: query.error,
        useQueryKey: parameter
    };

    return [metadadosRetorno, makeRequest, invalidateQuery];
};

export type UseApiTypeMutate = (
    endpoint: string,
    verb?: AxiosType,
    params?: Compleo.IObject,
    executeJustOnCall?: boolean
) => [
    ApiStateType,
    (
        newParams?: any,
        newverb?: AxiosType,
        newEndpoint?: string
    ) => Promise<void>
];

export const useApi: UseApiTypeMutate = (
    endpoint: string,
    verb: AxiosType = "get",
    params = {},
    executeJustOnCall: boolean = true
) => {
    const initialState: ApiStateType = {
        status: "initial",
        response: {},
        exception: null,
        useQueryKey: ""
    };

    const [state, setState] = useState(initialState);

    const makeRequest = async (
        newParams: any = params,
        newverb: AxiosType = verb,
        newEndpoint: string = endpoint
    ) => {
        setState({ ...state, status: "fetching" });
        try {
            const response = await apiDirectCall(
                newEndpoint,
                newverb,
                newParams
            );
            setState({ ...state, response, status: "success" });
            return response;
        } catch ({ response = null, ...exception }) {
            setState({ ...state, response: {}, status: "error" });
            return response;
        }
    };

    React.useEffect(() => {
        if (!executeJustOnCall) {
            makeRequest();
        }
    }, []);

    return [state, makeRequest];
};

export { apiDirectCall };
