import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Button, Tooltip, Typography } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import JobReportTitle from "./JobReportTitle";
import JobReportsMenu from "./JobReportsMenu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ShareIcon from "@material-ui/icons/Share";

const useStyles = () =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                flexGrow: 1,
                margin: theme.spacing(0)
            },
            menuButton: {
                marginRight: theme.spacing(2)
            },
            title: {
                flexGrow: 1
            },
            appBar: {
                backgroundColor: theme.palette.grey[100]
            },
            grow: {
                flexGrow: 1
            },
            pageTitle: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // fontWeight: 600,
                width: "100%"
            }
        });
    });

interface IProps {
    t: any;
    changeReportToShow: (
        newReport: CompleoShared.Reports.IJobReportsToRender
    ) => void;
    jobReportToShow: CompleoShared.Reports.IJobReportsToRender;
    openReportShareModal: () => void;
}

const JobReportsToolbar = (props: IProps) => {
    const {
        t,
        jobReportToShow,
        changeReportToShow,
        openReportShareModal
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const classes = useStyles()();
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                color="inherit"
                elevation={0}
                style={{ padding: 0, margin: 0 }}
            >
                <Toolbar>
                    <JobReportTitle t={t} type={jobReportToShow} />
                    <div className={classes.grow} />

                    <Button
                        variant="outlined"
                        color="default"
                        onClick={openMenu}
                        size="small"
                        // className={classes.button}
                        startIcon={<MenuOpenIcon />}
                    >
                        {t("reportChooseAReport")}
                    </Button>
                    <JobReportsMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        t={t}
                        changeReportToShow={changeReportToShow}
                        jobReportToShow={jobReportToShow}
                    />
                    <ButtonIconTooltipCompleo
                        label={t("shareJobReports")}
                        onClick={openReportShareModal}
                    >
                        <ShareIcon />
                    </ButtonIconTooltipCompleo>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default JobReportsToolbar;
