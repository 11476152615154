import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
// import TextField from "customHooks/useCompleoForm/Inputs/TextField";
import ComboAutoComplete from "customHooks/useCompleoReactHookForm/Inputs/ComboAutoComplete";
import { getStagesFromGroup } from "Pages/Pipeline/components/helperPipeline";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
interface IProps {
    index: number;
    t: any;
    fieldValue: any;
}

const getStages = (pipeline: Compleo.IObject, t: any) => {
    const options: Compleo.IObject[] = [];
    let listGroups: any[] = ["new", "InProgress", "hired", "rejected"];
    const removeItems: string[] = ["newApplied", "newSourced", "newReferred"];
    const stages = (pipeline?.stages || []).filter(
        (stage: any) => !removeItems.includes(stage?.type?.value)
    );

    listGroups.map((group: any) => {
        getStagesFromGroup(group, stages).map((stage: any) => {
            options.push({
                label: stage.name,
                value: stage.id,
                group: t(`PIPELINEGROUP_${group}`)
            });
        });
    });

    return options;
};

const QuestionnaireModel = (props: IProps) => {
    const { index, t, fieldValue } = props;
    const result = fieldValue?.results[index]?.name;
    const [options, setOptions] = React.useState<any[]>([]);
    // const [fieldPipeline, meta, helpers] = useField("pipeline");
    const { getFieldValue } = useRhfFieldWithoutControllerOrRegister(
        "pipeline"
    );
    const fieldPipelineValue = getFieldValue();
    React.useEffect(() => {
        setOptions(getStages(fieldPipelineValue, t));
    }, []);

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {result}
            </TableCell>
            <TableCell>
                <ComboAutoComplete
                    required={false}
                    name={`screeningQuestionnaire.results.${index}.action`}
                    label=""
                    options={options}
                    groupByField="group"
                    campoMetadata={{}}
                    language={"pt-BR"}
                />
            </TableCell>
        </TableRow>
    );
};

export default QuestionnaireModel;
