import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useList from "customHooks/useList";
import {
    Pause,
    Block,
    Done,
    FileCopyOutlined,
    Delete,
    MoreVert,
    BlockRounded,
    TrendingUp,
    TrendingFlat
} from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
    Button,
    DialogActions,
    ListItemIcon,
    Menu,
    MenuItem
} from "@material-ui/core";
import { getGroupFromStage } from "Pages/Pipeline/components/helperPipeline";
import JobKanbanApplicantActionButtons from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActionButtons";
import useJobContext from "Pages/Job/JobView/useJobContext";
const useStyles = makeStyles((theme: Theme) => createStyles({}));

type actionsJobApplicant =
    | "disqualify"
    | "requalify"
    | "changeStage"
    | "removeFromJob";

interface IProps {
    fieldData: Compleo.IObject;
    showInDialog?: boolean;
}

const StatusActions = (props: IProps) => {
    const queryClient = useQueryClient();
    const { company } = useAuthState();
    const { fieldData, showInDialog = false } = props;
    const jobId = fieldData.pk.split(":")[1];
    const [listData] = useList();
    const { t, useApiKey } = listData.tempInfo;
    const classes = useStyles();
    const showMessage = useShowMessage();
    const { callDialog } = useGlobalDialog();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpenMenuActions = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuActions = () => {
        setAnchorEl(null);
    };

    const showErrorMessage = () => {
        callDialog({
            title: t("updateStatusErrorTitle"),
            bodyText: t("updateStatusErrorMessage"),
            agreeButtonText: t("updateStatusErrorButton"),
            // disagreeButtonText: t("ScorecardDelete_no"),
            agreeFunction: async () => {
                return false;
            }
        });
    };

    /**
     * Update Status using optimistic update concept
     * @param newStatus The new status for the Job
     */
    const executeAction = async (action: actionsJobApplicant) => {
        if (showInDialog) {
            handleCloseMenuActions();
        }
        try {
        } catch (e) {
            // queryClient.setQueryData(useApiKey, () => {
            //     return previousDataWithoutRef;
            // });
            showErrorMessage();
        }
    };

    if (showInDialog) {
        return (
            <>
                <ButtonIconTooltipCompleo
                    label={t("otherActions")}
                    onClick={handleOpenMenuActions}
                >
                    <MoreVert />
                </ButtonIconTooltipCompleo>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenuActions}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    <MainReturn
                        executeAction={executeAction}
                        fieldData={fieldData}
                        t={t}
                        isMenu={true}
                        useApiKey={useApiKey}
                    />
                </Menu>
            </>
        );
    } else {
        return (
            <MainReturn
                executeAction={executeAction}
                fieldData={fieldData}
                t={t}
                isMenu={false}
                useApiKey={useApiKey}
            />
        );
    }
};

interface IMainReturn {
    executeAction: (action: actionsJobApplicant) => Promise<void>;
    fieldData: Compleo.IObject;
    t: any;
    isMenu: boolean;
    useApiKey: any;
}
const MainReturn = (props: IMainReturn) => {
    const [data] = useJobContext();
    const { executeAction, fieldData, t, isMenu, useApiKey } = props;
    const [listData, listDispatch] = useList();
    const currentPage = listData.filtersInfo?.filters?.pagination?.currentPage;

    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === fieldData?.jobData?.PipelineStage
    )[0];

    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );
    const openModalApplicant = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalApplicant: {
                open: true,
                currentIndex: index,
                currentPage: currentPage
            }
        });
    };
    return (
        <>
            {stageData && (
                <JobKanbanApplicantActionButtons
                    ApplicantData={fieldData}
                    stageData={stageData}
                    allStages={data.LocalStateData.allStages}
                    t={data.t}
                    currentIndex={-1}
                    stagesOnlyTotal={data.LocalStateData.stagesOnlyTotal}
                    outsideKanban={true}
                    isMenu={isMenu}
                    listKeyToInvalidate={[useApiKey]}
                    openApplicantView={(value) => openModalApplicant()}
                    readonly={data.readonly}
                />
            )}
        </>
    );
};

export default StatusActions;
