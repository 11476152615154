import Sections from "./Sections";

const CustomComponents = [
    {
        name: "sections",
        component: Sections
    }
];

export default CustomComponents;
