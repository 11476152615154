import Page from "Pages/_Layouts/MainInternalPage";
import React from "react";
import {
    MainListToolbar,
    FilterList,
    ResultListToolbar
} from "customHooks/useList/ListHelpers";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import useList from "customHooks/useList";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobContext from "Pages/Job/JobView/useJobContext";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { apiDirectCall } from "customHooks/useApi";
import { getStateFromDB } from "Pages/Job/JobView/JobKanban/helper";

const useStyles = makeStyles((theme) => ({
    content: {
        // flexGrow: 1,
        // height: "100vh",
        // overflow: "auto",
        backgroundColor: "#f4f6f8",
        // marginTop: theme.spacing(0.5)
        padding: theme.spacing(1)
    }
}));

interface IMainPage {
    open: boolean;
    handleClose: any;
}

export const DialogKanbanFilter = (props: IMainPage) => {
    const { open, handleClose } = props;
    const classes = useStyles();
    const [data, dispatch] = useJobContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const [runningQuery, setRunningQuery] = React.useState(false);

    const [listData, listDispatch] = useList();
    const { readyTranslation } = listData.tempInfo;
    const [t, i18n, readyLocalTranslation] = useTranslation("JOBVIEW", {
        useSuspense: false
    });
    const ready = readyTranslation && readyLocalTranslation;

    const applyFilter = async () => {
        setRunningQuery(true);
        // const filterApplied = isListFilterApplied(data.LocalStateData.filter);
        const currentFilter = data.fullFilter;
        const newFilter = {
            ...currentFilter,
            ..._.cloneDeep(listData.filtersInfo.filters),
            geoLocation: currentFilter.geoLocation
        };
        newFilter.pagination = {
            currentPage: 1,
            pageSize: 30,
            fromValue: 0
        };
        newFilter.otherGenericParams = {
            ...newFilter.otherGenericParams,
            filterStageIds: [],
            kanbanFilterList: true
        };
        const dataApplicant = await apiDirectCall(
            data.apiKanbanListAddress,
            "post",
            newFilter
        );
        const newStateData = getStateFromDB({
            dbData: dataApplicant,
            stateData: data.LocalStateData.ApplicantsData,
            orderData: data.LocalStateData.orderData,
            clearCurrentApplicants: true
        });
        dispatchGeneralPageInfo({
            payload: {
                ApplicantsData: newStateData,
                filter: newFilter,
                filterApplied: true
            },
            type: "update"
        });

        // dispatchGeneralPageInfo({
        //     type: "update",
        //     payload: {
        //         filter: {
        //             ...newFilter
        //         },
        //         ApplicantsPagination: {
        //             pagination: {
        //                 pageSize: 30,
        //                 currentPage: 1,
        //                 fromValue: 0
        //             }
        //         },
        //         filterApplied: true,
        //         controlParams: { kanbanFilterList: new Date().getTime() },
        //         updateApplicantsQuery: true
        //     }
        // });
        setRunningQuery(false);
        handleClose();
    };

    const clearAllAndClose = async () => {
        // const newFilter = _.cloneDeep(listData.filtersInfo.filters);
        setRunningQuery(true);
        const currentFilter = _.cloneDeep(data.fullFilter);

        const clearFilterObj = {
            ...currentFilter,
            advancedSearch: {},
            buckets: {},
            customSearch: {},
            filterUpdated: true,
            // geoLocation: {},
            mainSearch: null
        };
        clearFilterObj.otherGenericParams = {
            ...currentFilter.otherGenericParams,
            filterStageIds: []
        };
        clearFilterObj.pagination = {
            currentPage: 1,
            pageSize: 30,
            fromValue: 0
        };
        listDispatch.setFilters(clearFilterObj);

        const dataApplicant = await apiDirectCall(
            data.apiKanbanListAddress,
            "post",
            clearFilterObj
        );
        const newStateData = getStateFromDB({
            dbData: dataApplicant,
            stateData: data.LocalStateData.ApplicantsData,
            orderData: data.LocalStateData.orderData,
            clearCurrentApplicants: true
        });
        dispatchGeneralPageInfo({
            payload: {
                ApplicantsData: newStateData,
                filter: clearFilterObj,
                filterApplied: false
            },
            type: "update"
        });
        setRunningQuery(false);
        handleClose();
    };

    React.useEffect(() => {
        listDispatch.setFilters({
            ...listData.filtersInfo.filters,
            geoLocation: {
                latitude: data.fullFilter.geoLocation?.latitude,
                longitude: data.fullFilter.geoLocation?.longitude
            }
        });
    }, [
        data.fullFilter.geoLocation?.latitude,
        data.fullFilter.geoLocation?.longitude
    ]);

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={t("ModalKanbanFilter_Title")}
            open={open}
        >
            <DialogTitle>{t("ModalKanbanFilter_Title")}</DialogTitle>
            <DialogContent dividers>
                {ready ? (
                    <Box>
                        <MainListToolbar fullToolbar={true} />
                        <ResultListToolbar simpleToolbar={true} />
                        <FilterList fullWidth={true} />
                    </Box>
                ) : (
                    <Loading />
                )}
            </DialogContent>
            {runningQuery ? (
                <DialogActions>
                    <Loading size={24} />
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={clearAllAndClose} color="secondary">
                        {t("ModalKanbanFilter_CloseButton")}
                    </Button>
                    <Button onClick={applyFilter} color="primary">
                        {t("ModalKanbanFilter_Apply")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DialogKanbanFilter;
