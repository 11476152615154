import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { FieldProps } from "formik";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText, List } from "@material-ui/core";
import { apiDirectCall } from "customHooks/useApi/api";
import FileSaver from "file-saver";
import _ from "lodash";
import FileList from "./FileList";
import { getExtension } from "functions/util";

const useStyles = makeStyles((theme: Theme) => {
    const colorText = theme.palette.grey[100];
    return createStyles({
        paperDropzone: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            border: "1px dashed"
        },
        paperDropzoneError: {
            borderColor: theme.palette.error.main,
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            border: "1px dashed"
        },
        margin: {
            margin: theme.spacing(1)
        },
        iconFiles: {
            margin: theme.spacing(0.2),
            padding: theme.spacing(0.2)
        }
    });
});

interface IProps {
    metadata: any;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    helperTextDefault: null | string;
    onDropFunction?: any;
}

interface ILocalFiles {
    tags: string[];
    FileInfo: File;
}

// export const controlFilesFieldName = "_CompleoInternalUseFilesToUpload_";
export const isFileFromDB = (file: any) => {
    const keys = Object.keys(file);
    if (keys.includes("key") && keys.includes("originalFileName")) {
        return true;
    }
    return false;
};

export const tooManyFiles = "too-many-files";
export const fileTooLarge = "file-too-large";
export const invalidType = "file-invalid-type";

export const fileRejectionMessages = (
    code: string,
    defaultMessage: string,
    t: any,
    maxFiles: number,
    maxFileSizeKB: number,
    extensionsAllowed?: string[]
) => {
    switch (code) {
        case tooManyFiles:
            return t(`CGT_fileUploadControl_MaxFilesExceeded`, {
                max: maxFiles.toString()
            });
        case fileTooLarge:
            return t(`CGT_fileUploadControl_MaxSizeFileExceeded`, {
                max: maxFileSizeKB.toString()
            });
        case invalidType:
            const extensions = extensionsAllowed || [];
            return t(`CGT_fileUploadControl_ExtensionNotAllowed`, {
                extensions: extensions.join(", ")
            });
        default:
            return defaultMessage;
    }
};

const FileField = (props: IProps & FieldProps) => {
    const classes = useStyles();
    const {
        label,
        field,
        form: {
            dirty,
            touched,
            errors,
            setFieldValue,
            status,
            handleBlur,
            handleChange,
            setValues,
            setFieldError,
            setTouched,
            values
        },
        helperTextDefault,
        onDropFunction,
        ...other
    } = props;
    const { metadata, t, required } = props;
    const elementType = metadata.elementType;
    const fileDefinition = metadata.fileDefinition;

    const maxFileSizeKB: number = fileDefinition.maxFileSizeKB || 30000;
    const extensionsAllowed: string[] | undefined = fileDefinition.extensions;
    const [errorMaxFiles, setErrorMaxFiles] = React.useState(false);
    const multiple = elementType === "multipleFiles";

    // Tags can only be enabled for multiple files
    const enableTags = fileDefinition?.enableTags === true && multiple;
    const maxFiles: number = multiple ? fileDefinition.maxFiles || 99 : 1;

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        if (onDropFunction !== undefined) {
            await onDropFunction(acceptedFiles, rejectedFiles);
        }
    }, []);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        multiple: multiple,
        maxSize: maxFileSizeKB * 1000,
        accept: extensionsAllowed,
        maxFiles: maxFiles
    });
    const errorText = _.get(errors, field.name); // errors[field.name];
    const hasError = _.get(touched, field.name) && errorText !== undefined;

    let mensagemErro =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    const { ref, ...rootProps } = getRootProps();

    const changeValues = (newValues: ILocalFiles[]) => {
        // const valuesToChange: Compleo.IObject = {};
        const localNewValues = multiple ? newValues : newValues[0];
        setFieldValue(field.name, localNewValues);
        // _.set(valuesToChange, field.name, localNewValues);
        // _.set(valuesToChange, `${field.name}_filesDefinition`, {
        //     values: newValues,
        //     multiple: multiple
        // });
        // const fieldName = field.name;
        // if (fieldName.includes("[") && fieldName.includes("]")) {
        //     // const mainFieldName = fieldName.split("[")[0];
        //     // const currentValue = values[mainFieldName];
        //     // currentValue
        //     const valuesChanged = { ...values };
        //     _.set(valuesChanged, field.name, localNewValues);
        //     // _.set(valuesChanged, `${field.name}_filesDefinition`, {
        //     //     values: newValues,
        //     //     multiple: multiple
        //     // });
        //     setValues(valuesChanged);
        // } else {
        //     setValues({ ...values, ...valuesToChange });
        // }
        setErrorMaxFiles(false);
    };

    const mainValue = _.get(values, field.name);
    const mainValueFilesDefinition = multiple
        ? mainValue
        : mainValue
        ? [mainValue]
        : [];

    const removeFile = (name: any) => {
        if (multiple) {
            if (Array.isArray(mainValue) && mainValue.length > 0) {
                const newValues = mainValue.filter((f: any) => {
                    if (f?.FileInfo) {
                        return f?.FileInfo?.name !== name;
                    } else {
                        return f?.originalFileName !== name;
                    }
                });
                changeValues(newValues);
            }
        } else {
            setFieldValue(field.name, null);
        }
    };

    const editTags = (name: any, tags: string[], newFileName?: string) => {
        const currentExtension = getExtension(name);
        const newFileNameWithExtenstion = newFileName
            ? `${newFileName}.${currentExtension}`
            : undefined;

        if (multiple) {
            if (Array.isArray(mainValue) && mainValue.length > 0) {
                const data = mainValue.filter((f: any) => {
                    if (f?.FileInfo) {
                        return f?.FileInfo?.name === name;
                    } else {
                        return f?.originalFileName === name;
                    }
                })[0];
                data.tags = tags;

                if (newFileNameWithExtenstion) {
                    data.originalFileName = newFileNameWithExtenstion;
                    // if (data.FileInfo?.name) {
                    //     data.FileInfo.name = newFileNameWithExtenstion;
                    // }
                }
                changeValues(mainValue);
            }
        } else {
            mainValue.tags = tags;
            if (newFileNameWithExtenstion) {
                mainValue.originalFileName = newFileNameWithExtenstion;
                // if (mainValue.FileInfo?.name) {
                //     mainValue.FileInfo.name = newFileNameWithExtenstion;
                // }
            }
            setFieldValue(field.name, mainValue);
        }
    };

    const filesFromFormik: Compleo.IObject[] = [];

    if (
        mainValueFilesDefinition !== undefined &&
        Array.isArray(mainValueFilesDefinition) &&
        mainValueFilesDefinition.length > 0
    ) {
        filesFromFormik.push(...mainValueFilesDefinition);
    }

    const fileRejectionItemsFN = () => {
        let returnData: JSX.Element | null = null;
        const hasTooManyFilesError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter((e: any) => e.code === tooManyFiles)
                        .length > 0
                );
            }).length > 0;

        const hasFileTooLargeError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter((e: any) => e.code === fileTooLarge)
                        .length > 0
                );
            }).length > 0;

        const hasInvalidTypeError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter((e: any) => e.code === invalidType).length >
                    0
                );
            }).length > 0;

        if (
            hasTooManyFilesError &&
            !hasFileTooLargeError &&
            !hasInvalidTypeError
        ) {
            returnData = (
                <>
                    <h4>{t(`${field.name}FilesError`)}</h4>
                    <p>
                        {t(`CGT_fileUploadControl_MaxFilesExceeded`, {
                            max: maxFiles.toString()
                        })}
                    </p>
                </>
            );
        } else if (fileRejections.length > 0) {
            returnData = (
                <React.Fragment>
                    {fileRejections.map(({ file, errors }: any) => {
                        return (
                            <React.Fragment key={file.name}>
                                {file.name} - {Math.round(file.size / 1000)} KB
                                {errors.map((e: any) => (
                                    <React.Fragment key={e.code}>
                                        {" "}
                                        -{" "}
                                        {fileRejectionMessages(
                                            e.code,
                                            e.message,
                                            t,
                                            maxFiles,
                                            maxFileSizeKB,
                                            extensionsAllowed
                                        )}
                                    </React.Fragment>
                                ))}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            );
        }
        return returnData;
    };

    const fileRejectionDetail = fileRejectionItemsFN();

    React.useEffect(() => {
        if (onDropFunction !== undefined) {
            return;
        }

        const newValues: ILocalFiles[] = acceptedFiles.map((item) => {
            return {
                tags: [],
                FileInfo: item
            };
        });

        if (multiple) {
            const mainValue = _.get(values, field.name);

            if (Array.isArray(mainValue) && mainValue.length > 0) {
                const newFilesNames = acceptedFiles.map((f: File) => f.name);

                // remove files with the same name to replace with new file
                const currentValues = mainValue.filter(
                    (f: any) =>
                        !newFilesNames.includes(f?.FileInfo?.name) &&
                        !newFilesNames.includes(f?.originalFileName)
                );
                if (currentValues.length + newValues.length <= maxFiles) {
                    newValues.push(...currentValues);
                    changeValues(newValues);
                } else {
                    setErrorMaxFiles(true);
                }
            } else {
                changeValues(newValues);
            }
        } else {
            if (newValues.length) {
                changeValues(newValues);
            }
        }
    }, [acceptedFiles]);

    // React.useEffect(() => {
    //     setFieldValue(`${field.name}_filesDefinition`, {
    //         values: _.get(values, field.name),
    //         multiple: multiple
    //     });
    // }, []);

    const generalErrorCheck =
        errorMaxFiles ||
        fileRejectionDetail !== null ||
        hasError ||
        (status !== undefined && _.get(status, field.name) !== undefined);

    return (
        <RootRef rootRef={ref}>
            <>
                <FormLabel
                    component="legend"
                    error={generalErrorCheck}
                    required={required}
                >
                    {label}
                </FormLabel>
                <Paper
                    elevation={0}
                    {...rootProps}
                    className={
                        generalErrorCheck
                            ? classes.paperDropzoneError
                            : classes.paperDropzone
                    }
                >
                    <input
                        {...getInputProps()}
                        id={field.name}
                        name={field.name}
                    />
                    <p>{t(`CGT_fileUploadControl_Instruction`)}</p>
                </Paper>
                <FileList
                    enableTags={enableTags}
                    filesFromFormik={filesFromFormik}
                    removeFile={removeFile}
                    editTags={editTags}
                    t={t}
                />
                <FormHelperText error={generalErrorCheck}>
                    {mensagemErro} {fileRejectionDetail}
                    {errorMaxFiles && (
                        <>
                            <h4>{t(`${field.name}FilesError`)}</h4>
                            <p>
                                {t(`CGT_fileUploadControl_MaxFilesExceeded`, {
                                    max: maxFiles.toString()
                                })}
                            </p>
                        </>
                    )}
                </FormHelperText>
            </>
        </RootRef>
    );
};

const getKeysFromFileKey = (key: string) => {
    if (key.split("/").length === 4) {
        const [companyId, foldermain, folderkey, fileName] = key.split("/");
        return [companyId, `${foldermain}/${folderkey}`, fileName];
    } else {
        const [companyId, folder, fileName] = key.split("/");
        return [companyId, folder, fileName];
    }
};

export const getUrlFromS3 = async (key: string, convertPDF = false) => {
    const [companyId, folder, fileName] = getKeysFromFileKey(key);
    const urlReturn = await apiDirectCall(
        "/fileutil/requests3urlexistentfile",
        "post",
        {
            fileName: fileName,
            folder: folder,
            companyId: companyId,
            getPDFForceConvert: convertPDF
        }
    );
    return urlReturn.data;
};

export const getUrlFromS3External = async (
    key: string,
    convertPDF = false,
    evaluationId: string
) => {
    const [companyId, folder, fileName] = getKeysFromFileKey(key);
    const urlReturn = await apiDirectCall(
        "/fileutil/requests3urlexistentfileexternal",
        "post",
        {
            fileName: fileName,
            folder: folder,
            companyId: companyId,
            getPDFForceConvert: convertPDF,
            evaluationId: evaluationId
        }
    );
    return urlReturn.data;
};

export interface IS3ExternalData {
    evaluationId: string;
}

export const createLocalUrlFromS3 = async (
    key: string,
    convertPDF = false,
    externalData?: IS3ExternalData
) => {
    const urlReturn = !externalData
        ? await getUrlFromS3(key, convertPDF)
        : await getUrlFromS3External(
              key,
              convertPDF,
              externalData.evaluationId
          );

    const res = await fetch(urlReturn);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);

    return { url, blob };
};

export const downloadFileFromS3 = async (
    key: string,
    originalFile: string,
    externalData?: IS3ExternalData
) => {
    const url = await createLocalUrlFromS3(key, false, externalData);
    const a = document.createElement("a");
    a.href = url.url;
    a.download = originalFile || "download";
    a.click();
};

export const downloadLocalFile = async (file: any, originalFile: string) => {
    const urlLocal = URL.createObjectURL(file);
    FileSaver.saveAs(urlLocal, originalFile);
};

export default FileField;

// file-invalid-type
// file-too-large
// too-many-files
