import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";

const useCustomLists: () => [
    Compleo.CustomLists.ListDefinitionType[],
    boolean
] = () => {
    const { company } = useAuthState();
    const companyId = company.companyId || "0";
    // const [
    //     languages,
    //     defaultLanguage,
    //     readyLanguages
    // ] = useGetLanguagesCareers();

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition] = React.useState<
        Compleo.CustomLists.ListDefinitionType[]
    >([]);

    const [customers] = useApiCache(
        "/contact/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        if (customers.status === "success" && listDefinition.length === 0) {
            const customersItem = getListDefinitionItem(
                "customers",
                customers?.response?.data || [],
                listDefinition
            );
            if (customersItem) listDefinition.push(customersItem);

            setReady(true);
        }
    }, [customers.status, customers?.response?.data, listDefinition]);

    //if (customers.status === "success" && !ready) {
    //  console.log("CustomCV useCustomLists customers", customers);
    // const filterCustomersItem = getListDefinitionItem(
    //     "filters_customers",
    //     customers?.response?.data || [],
    //     listDefinition
    // );
    // if (filterCustomersItem) listDefinition.push(filterCustomersItem);

    // const customersItem = getListDefinitionItem(
    //     "customers",
    //     customers?.response?.data || [],
    //     listDefinition
    // );
    // if (customersItem) listDefinition.push(customersItem);

    //setReady(true);
    //}

    // console.log("languages", languages);

    // if (readyLanguages && !listFilled(listDefinition, "languagesCareers")) {
    //     listDefinition.push({
    //         fieldName: "languagesCareers",
    //         data: languages,
    //         valueName: "itemValue",
    //         labelName: "itemText"
    //     });
    // }

    // if (!ready) {
    //     const readyModules = verifyRead([customers]);
    //     if (readyModules && readyLanguages) {
    //         setReady(true);
    //     }
    // }
    return [listDefinition, ready];
};

const getListDefinitionItem = (
    fieldName: string,
    data: Compleo.IObject[],
    currentList: Compleo.CustomLists.ListDefinitionType[]
) => {
    if (listFilled(currentList, fieldName)) return undefined;

    return {
        fieldName,
        data: data || [],
        valueName: "itemValue",
        labelName: "itemText"
    };
};

const verifyRead = (api: ApiStateType[]) => {
    return api.filter((a: ApiStateType) => a.status !== "success").length === 0;
};

const listFilled = (
    listDefinition: Compleo.CustomLists.ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

export default useCustomLists;
