import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm, { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import CustomComponents from "./components/UserCustomComponents";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import _ from "lodash";
import { CompleoErrorMessage } from "Components/CompleoErrorMessage";
import { getFieldsToHide, allBranchControlFields } from "./UserNew";
import useCustomLists from "./components/useCustomLists";
import { navigate } from "@gatsbyjs/reach-router";
import useHistory from "customHooks/useHistory/useHistory";
import usePlanLimits from "customHooks/usePlanLimits/usePlanLimits";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

const UserEdit = (props: any) => {
    const classes = useFormStyles();
    const { company, reloadCompanyAndPermissionData } = useAuthState();
    const history = useHistory();
    const { getLimit } = usePlanLimits();
    const { callDialog } = useGlobalDialog();

    let defaultFieldsToHide: string[] = [];
    if (!company.branchAccessControl) {
        defaultFieldsToHide = allBranchControlFields;
    }
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const formikReturn = React.useCallback(
        (value: any) => {
            if (company.branchAccessControl) {
                const values = (value || {}).values || {};
                if (values.allBranchAccess !== undefined) {
                    const allBranchAccess = values.allBranchAccess;
                    const fieldsToHideFN = getFieldsToHide(allBranchAccess);
                    if (!_.isEqual(fieldsToHide, fieldsToHideFN)) {
                        setFieldsToHide(fieldsToHideFN);
                    }
                }
            }
        },
        [fieldsToHide]
    );

    const [metadata] = useGetMetadata("USEREDIT");
    const [t, i18n, readyTranslation] = useTranslation("useredit", {
        useSuspense: false
    });
    const [listDefinition, readyLists] = useCustomLists(t, readyTranslation);
    const postAddress = `/user/edit/${props.useremail}`;

    const [editUserReturn, editUser] = useApi(postAddress, "post");

    const postAddressSearch = `/user/search`;
    const [getUserReturn, getUser] = useApiCache(
        postAddressSearch,
        "post",
        { email: props.useremail, companyId: company.companyId },
        false
    );

    const [valuesFromSource, pkSKValues] = useValuesFromSource(
        metadata,
        true,
        {},
        getUserReturn,
        ["data", "data"]
    );

    if (valuesFromSource.timezone === undefined) {
        valuesFromSource.timeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            "America/Sao_Paulo";
    }
    if (!company.branchAccessControl) {
        valuesFromSource.allBranchAccess = true;
        valuesFromSource.accessVacanciesWithoutBranch = true;
    }

    const editUserLocal = async (values: any) => {
        if (valuesFromSource?.enabled === false && values.enabled === true) {
            const dataLimit = await getLimit("addUser");
            if (!dataLimit.allowAction) {
                callDialog({
                    title: t("PLANLIMIT_title"),
                    // bodyText: `Seu plano atingiu o limite de vagas abertas. Limite de vagas: ${dataLimit.maxValue} - Vagas abertas: ${dataLimit.currentTotal}`,
                    bodyText: `${t("cantEnableMessage")} ${t(
                        "PLANLIMIT_userMessage",
                        {
                            maxValue: dataLimit.maxValue,
                            currentTotal: dataLimit.currentTotal
                        }
                    )}`,
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    // disagreeButtonText: t("ScorecardDelete_no"),
                    agreeFunction: async () => {
                        return false;
                    }
                });
                const retorno: Compleo.IObject = {};
                retorno.status = 999;
                return retorno;
            }
        }
        const returnFunction = await editUser(values);
        reloadCompanyAndPermissionData();
        return returnFunction;
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: readyTranslation,
        FormType: FormClassic,
        i18nV: i18n,
        postMethod: editUserLocal,
        postReturn: editUserReturn,
        metadadosRetorno: metadata,
        redirectAddress: "/user/list",
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        customLists: listDefinition,
        fieldsToHide: fieldsToHide,
        formikReturn: formikReturn,
        secondaryButtonFunction: history.fnGoBack
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (getUserReturn.status === "error") {
        compRetorno = (
            <CompleoErrorMessage
                errorTitle={t("userNotFoundMessage")}
                errorDescription={t("userNotFoundMessageDetail")}
            />
        );
    }
    if (finished && readyTranslation && getUserReturn.status === "success") {
        compRetorno = formCompleo;

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default UserEdit;
