import React from "react";
import { Grid } from "@material-ui/core";
import ApplicantCVPreview from "./ApplicantCVPreview";

const getLgSize = (pageMaxWidth: "lg" | "xl" | "md") => {
    switch (pageMaxWidth) {
        case "lg":
            return 6;
        default:
            return 7;
    }
};

interface IApplicantContainerCV {
    compRetorno: JSX.Element;
    url?: string;
    pageMaxWidth?: "lg" | "xl" | "md";
}

const ApplicantContainerCV = (props: IApplicantContainerCV) => {
    const { compRetorno, url, pageMaxWidth = "lg" } = props;
    const lgSize = getLgSize(pageMaxWidth);
    if (url) {
        return (
            <Grid
                container
                spacing={1}
                alignContent="flex-start"
                alignItems={"flex-start"}
            >
                <Grid item xs={12} sm={12} md={6} lg={lgSize}>
                    {compRetorno}
                </Grid>
                <Grid
                    item
                    xs
                    sm
                    md
                    lg
                    style={{
                        position: "sticky",
                        top: 55
                    }}
                >
                    <ApplicantCVPreview url={url} isLocalStreamUrl={true} />
                </Grid>
            </Grid>
        );
    } else {
        return <>{compRetorno}</>;
    }
};

export default ApplicantContainerCV;
