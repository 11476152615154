import React from "react";
import { Box, FormHelperText, Typography } from "@material-ui/core";
import { Rating, RatingProps } from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useAuthState } from "_ReactContext/AuthContext";
import { getRatingAddProps } from "./RatingCompleoHelper";
import RatingCompleoThumbs from "./RatingCompleoThumbs";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconFilled: {
            color: "#ff6d75"
        },
        iconHover: {
            color: "#ff3d47"
        }
    })
);

const RatingCompleo = (props: IInputProps & RatingProps) => {
    const {
        metadata,
        t,
        required,
        name,
        label,
        helperTextDefault,
        ...other
    } = props;
    const { company } = useAuthState();
    const classes = useStyles();
    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        name,
        helperTextDefault
    );
    // const [field, meta, helpers] = useField(props.name);

    // const { error, message } = getErrorAndMessagesUseField(
    //     meta.error,
    //     meta.touched,
    //     helperTextDefault
    // );
    const scoreType = company.scoreType;

    const valueFromFormik = field.value;

    const changeValue = (newValue: number, score: Compleo.scoreType) => {
        setFieldValue(newValue * (score === "10stars" ? 1 : 2));
        // setFieldValue(field.name, newValue * (score === "10stars" ? 1 : 2));
    };

    const controlValue =
        (valueFromFormik ? valueFromFormik : 0) /
        (scoreType === "10stars" ? 1 : 2);

    const onChangeMethod = (event: any, newValue: any | null) => {
        changeValue(newValue, scoreType);
    };

    const addProps = getRatingAddProps(scoreType);

    return (
        <FormControl component="fieldset" error={error}>
            <FormLabel
                component="legend"
                htmlFor={field.name}
                aria-label={label}
                required={required}
                error={error}
            >
                {label}
            </FormLabel>
            {scoreType === "thumbs" ? (
                <RatingCompleoThumbs
                    setValue={(value: number) => changeValue(value, scoreType)}
                    value={controlValue}
                />
            ) : (
                <Rating
                    {...field}
                    {...other}
                    name={field.name}
                    value={controlValue}
                    onChange={onChangeMethod}
                    style={{ marginTop: 8 }}
                    {...addProps}
                    classes={{
                        iconFilled:
                            scoreType === "hearts"
                                ? classes.iconFilled
                                : undefined,
                        iconHover:
                            scoreType === "hearts"
                                ? classes.iconHover
                                : undefined
                    }}
                />
            )}
            <FormHelperText error={error} id={`helper_${field.name}`}>
                {message}
            </FormHelperText>
        </FormControl>
    );
};

export default RatingCompleo;
