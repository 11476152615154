import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";

const CompleoModuleEdit = (props: any) => {
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "ADMINCOMPLEOMODULESEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const [metadata] = useGetMetadata(moduleName);

    const postAddress = `/admin/moduleedit`;
    const [editModuleReturn, editModule] = useApi(postAddress, "post");

    const postAddressSearch = `/admin/modulesearch`;
    const [getModuleReturn, getModule] = useApiCache(
        postAddressSearch,
        "post",
        {
            companyId: company.companyId,
            module: props.module
        },
        false
    );
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getModuleReturn,
        ["data", "data", "Items"]
    );
    // valuesFromSource.companyId = company.companyId;
    // const valuesFromSource: Compleo.IObject = {};
    // if (getModuleReturn.status === "success" && metadata.status === "success") {
    //     let fields = metadata.response.data.metadado;
    //     fields = fields.filter(
    //         (o: any) => !(o.elementType || "").includes("backend_")
    //     );

    //     fields.map((field: any) => {
    //         if (
    //             getModuleReturn.response.data.data.Items[0][field.fieldName] !==
    //             undefined
    //         ) {
    //             valuesFromSource[field.fieldName] =
    //                 getModuleReturn.response.data.data.Items[0][
    //                     field.fieldName
    //                 ];
    //         }
    //     });
    //     valuesFromSource.companyId = company.companyId;
    // }

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editModule,
        editModuleReturn,
        "/admincompleo/listcompleomodule",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && getModuleReturn.status === "success" && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default CompleoModuleEdit;
