import React from "react";
import Page from "../_Layouts/InternalFullPage";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Box, Typography } from "@material-ui/core";
import DashboardMenu from "./Helpers/DashboardMenu";
import DashboardTabs from "./Helpers/DashboardTabs";
import useDashboardContext from "./useDashboardContext";
import DashboardMainPage from "./DashboardMainPage/DashboardMainPage";
import { setLastPageToRender } from "./useDashboardContext/_dashboardContextHelper";
import JobsDateLimitList from "./JobsDateLimit/JobsDateLimitList";
import CalendarPage from "Pages/Calendar/Calendar";
import DashboardJobs from "./DashboardJobs/DashboardJobs";
import DashboardApplicants from "./DashboardApplicants/DashboardApplicants";
import DashboardApplicantsJobView from "./DashboardApplicantsJobView/DashboardApplicantsJobView";
import DashboardApplicantsJobViewExternal from "./DashboardApplicantsJobView/DashboardApplicantsJobViewExternal";

const DashboardMain = () => {
    const [data] = useDashboardContext();
    const { t } = data;

    if (data.ready) {
        return (
            <Page maxWidth={false}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" pl={1.2}>
                        <Box>
                            <Typography variant="h5" component="h3">
                                {t("mainPageName")}
                            </Typography>
                        </Box>
                        <Box flexGrow={1} />
                        {/* <Box> */}
                        <DashboardTabs />
                        {/* </Box> */}
                        <Box>
                            <DashboardMenu t={t} />
                        </Box>
                    </Box>
                    <Box>
                        <DashboardSwitchPage />
                    </Box>
                </Box>
            </Page>
        );
    } else {
        return (
            <Page maxWidth={false}>
                <Loading />
            </Page>
        );
    }
};

export const DashboardSwitchPage = () => {
    const [data] = useDashboardContext();
    const pageToRender = data.localStateData.pageToRender;
    setLastPageToRender(pageToRender);

    switch (data.localStateData.pageToRender) {
        case "main":
        case "myDashboards":
            return <DashboardMainPage />;
        case "jobsDateLimits":
            return <JobsDateLimitList />;
        case "calendar":
            return <CalendarPage insideDashboard={true} />;
        case "jobs":
            return <DashboardJobs />;
        case "applicants":
            return <DashboardApplicants />;
        case "applicantsJobView":
            return (
                <DashboardApplicantsJobView
                    jobId={data.dashOtherProps?.applicantsJobView?.jobId}
                />
            );
        case "applicantsJobViewExternal":
            return (
                <DashboardApplicantsJobViewExternal
                    evaluationId={
                        data.dashOtherProps?.applicantsJobViewExternal
                            ?.evaluationId
                    }
                    dashboardId={
                        data.dashOtherProps?.applicantsJobViewExternal
                            ?.dashboardId
                    }
                />
            );
        default:
            return null;
    }
};

export default DashboardMain;
