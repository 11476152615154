import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm, { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, ApiStateType } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { ChangePasswordFunction } from "./components/ChangePasswordFunction";

const ChangePassword = (props: any) => {
    const classes = useFormStyles();
    const [t, i18n, readyTranslation] = useTranslation("CHANGEPASSWORD", {
        useSuspense: false
    });

    const [metadata] = useGetMetadata("CHANGEPASSWORD");
    const [valuesFromSource] = useValuesFromSource(metadata, false, {});

    const changePassword = ChangePasswordFunction(t);
    const changePasswordReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: changePassword,
        postReturn: changePasswordReturn,
        FormType: FormClassic,
        redirectAddress: "/",
        metadadosRetorno: metadata,
        valuesFromSource
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page featureMainDescription={t("a_PageName")}>{compRetorno}</Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ChangePassword;
