import React, { ComponentType, Component, ReactNode, useState } from "react";
import AppBar from "Components/AppBar/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
// import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import HelperLoading from "Components/Loading/HelperLoading";
import { LanguageContext } from "_ReactContext/LanguageContext";
import { useGlobalDialogForm } from "_ReactContext/GlobalDialogContext";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import theme from "../../theme/Theme";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export type SecondComponentType = {
    component: JSX.Element;
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "97vh"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        // overflow: "auto",
        backgroundColor: "white"
        // maxWidth: "400"
        // backgroundColor: "#f4f6f8"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    toolbar: theme.mixins.toolbar
}));

interface IBaseProps {
    children: ReactNode;
    className?: string;
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
}

const Page = (props: IBaseProps) => {
    const classes = useStyles();
    const GlobalDialog = useGlobalDialogForm();
    const { company, user } = useAuthState();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);
    const { maxWidth = false } = props;

    let loadingState = false;
    loadingState =
        queryNavigation.status !== "success" ||
        company.companyId === 0 ||
        user === null ||
        user === "" ||
        user === undefined;

    // ***********************************************************************
    // ***********************************************************************
    // Prepare the translation of all main components to control loading in
    // just one place
    // ***********************************************************************
    // ***********************************************************************
    const [
        tChangeLanguage,
        i18nChangeLanguage,
        readyTranslationChangeLanguage
    ] = useTranslation("CHANGELANGUAGE", {
        useSuspense: false
    });

    const [tAppBar, i18nAppBar, readyTranslationAppBar] = useTranslation(
        "APPBAR",
        {
            useSuspense: false
        }
    );

    const [
        tNavigation,
        i18nNavigation,
        readyTranslationNavigation
    ] = useTranslation("NAVIGATION", {
        useSuspense: false
    });

    const readyAllTranslations =
        readyTranslationAppBar &&
        readyTranslationChangeLanguage &&
        readyTranslationNavigation;
    // ***********************************************************************
    // ***********************************************************************
    // END TRANSLATION LOADING
    // ***********************************************************************
    // ***********************************************************************

    if (readyAllTranslations && !loadingState) {
        const language: string = i18nChangeLanguage.languages[0];

        return (
            <LanguageContext.Provider value={language}>
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            languageRendered={language}
                            tAppBar={tAppBar}
                            tChangeLanguage={tChangeLanguage}
                            // tNavigation={tNavigation}
                            i18n={i18nChangeLanguage}
                        />
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            <Container
                                className={classes.container}
                                maxWidth={maxWidth}
                            >
                                {props.children}
                                <GlobalDialog.FormDialog />
                            </Container>
                        </main>
                    </div>
                </ThemeProvider>
            </LanguageContext.Provider>
        );
    } else {
        return <HelperLoading />;
    }
};

export default Page;
