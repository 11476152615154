import React from "react";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Divider, Menu, MenuItem } from "@material-ui/core";
import { apiDirectCall } from "customHooks/useApi";
import useJobContext from "../../useJobContext";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

interface IJobBoardsChangeCareerSite {
    t: any;
    otherListCareerItems: CompleoShared.CareersSettings.IJobCareersListStatus[];
}

const JobBoardsChangeCareerSite = (props: IJobBoardsChangeCareerSite) => {
    const { t, otherListCareerItems } = props;
    const [data] = useJobContext();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const hasOtherCareerItems = otherListCareerItems.length > 0;
    const queryClient = useQueryClient();
    const showMessage = useShowMessage();

    const openMenu = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const changeCareerSite = async (newUniqueLink: string) => {
        setAnchorEl(null);
        showMessage({
            message: t("COMPLEOGENERAL_MessageUpdating"),
            typeMessage: "warning",
            snackBarOrign: {
                vertical: "top",
                horizontal: "center"
            }
        });
        await apiDirectCall("/job/editview", "post", {
            jobBoardsCareersUniqueName: newUniqueLink,
            companyId: data.Job?.data?.companyId,
            id: data.JobId
        });
        queryClient.invalidateQueries(data.apiKeyJob);
        showMessage({
            message: t("COMPLEOGENERAL_MessageUpdated"),
            timeout: 2000,
            typeMessage: "success",
            snackBarOrign: {
                vertical: "top",
                horizontal: "center"
            }
        });
    };

    if (hasOtherCareerItems) {
        return (
            <>
                <ButtonIconTooltipCompleo
                    label={t("btnChangeCareersSite")}
                    onClick={openMenu}
                >
                    <Edit fontSize="small" />
                </ButtonIconTooltipCompleo>
                <div>
                    <Menu
                        id="card-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    >
                        <MenuItem button={false}>
                            <strong>{t("btnChangeCareersSite")}</strong>
                        </MenuItem>
                        <Divider />
                        {otherListCareerItems.map((option) => {
                            const uniqueLink = (
                                option.careerSettings?.uniqueLink || ""
                            ).toLowerCase();
                            return (
                                <MenuItem
                                    key={option.rootURL}
                                    onClick={() => changeCareerSite(uniqueLink)}
                                >
                                    {uniqueLink}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default JobBoardsChangeCareerSite;
