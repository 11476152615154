import React from "react";
import {
    Box,
    Button,
    Fab,
    Grid,
    Icon,
    Paper,
    StepButton,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { getFields } from "../helpers/getFields";
import _, { last } from "lodash";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import useMenuFormGroup from "./useMenuFormGroup";
import IconButton from "@material-ui/core/IconButton";
import theme from "theme/Theme";
import CheckIcon from "@material-ui/icons/Check";
import { FieldValues, UseFormReturn } from "react-hook-form";

const FormMainDetail = (props: any) => {
    const {
        filteredFormGroups,
        classes,
        camposMetadados,
        t,
        listas,
        valuesFromSource,
        CustomComponents,
        language,
        IsMultiStepForm,
        pageForm,
        steps,
        customLists,
        fieldsToHide,
        hookFormMethods,
        FormMenuActions,
        additionalFieldProperties,
        sizeToChangeStepHorizontal,
        formGroupPaperElevation,
        enableStepNavigation = false,
        IsMultiStepLateralParam = false,
        submit
    } = props;

    const reactHookFormMethods: UseFormReturn<
        FieldValues,
        object
    > = hookFormMethods;

    const matches = useMediaQuery(
        theme.breakpoints.up(sizeToChangeStepHorizontal || "sm")
    );

    const IsMultiStepLateral = IsMultiStepLateralParam && matches;
    const stepOrientation =
        matches && !IsMultiStepLateral ? "horizontal" : "vertical";
    const stepperStyle: React.CSSProperties = {};
    const mainPaperStyle: React.CSSProperties = {};
    if (IsMultiStepLateral) {
        stepperStyle.marginTop = 0;
        mainPaperStyle.marginTop = 0;
    }

    const clickStepButton = async (index: number) => {
        const localSubmit = (values: any) => {
            return submit(values, index, false);
        };
        const localHandleSubmit = reactHookFormMethods.handleSubmit(
            localSubmit
        );
        localHandleSubmit();
    };

    const applyChangeButtonForceSubmit = async () => {
        const localSubmit = (values: any) => {
            return submit(values, 0, true);
        };
        const localHandleSubmit = reactHookFormMethods.handleSubmit(
            localSubmit
        );
        localHandleSubmit();
    };

    return (
        <Box
            display="flex"
            flexDirection={IsMultiStepLateral ? "row" : "column"}
            width="100%"
        >
            {IsMultiStepForm && (
                <Box
                    p={0}
                    display="flex"
                    flex={1}
                    paddingRight={IsMultiStepLateral ? 2 : 0}
                >
                    <Paper
                        className={classes.paperstepper}
                        key="stepper"
                        style={stepperStyle}
                    >
                        <Stepper
                            nonLinear={enableStepNavigation}
                            activeStep={pageForm - 1}
                            orientation={stepOrientation}
                        >
                            {steps.map((label: any, index: any) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        {/* <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel> */}
                                        <StepButton
                                            onClick={async () =>
                                                await clickStepButton(index + 1)
                                            }
                                            {...labelProps}
                                            style={{ textAlign: "left" }}
                                        >
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Paper>
                </Box>
            )}
            <Box p={0} flexGrow={2} minWidth={0}>
                {filteredFormGroups.map((itemGroup: any, index: number) => {
                    let lastOrderItem = -1;
                    const formGroupHelp = t(
                        `a_FormGroup_${itemGroup.id}_help`,
                        ""
                    );
                    const formMenuData = (FormMenuActions || []).filter(
                        (f: any) => f.formGroupId === itemGroup.id
                    )[0];
                    const MenuComponent =
                        formMenuData?.menuDefinition?.MenuComponent;
                    const handleMenuClick =
                        formMenuData?.menuDefinition?.handleMenuClick;

                    return (
                        <Paper
                            className={classes.paper}
                            key={itemGroup.id}
                            elevation={formGroupPaperElevation}
                            style={index === 0 ? mainPaperStyle : {}}
                        >
                            {MenuComponent !== undefined && <MenuComponent />}
                            <Grid container>
                                {t(`a_FormGroup_${itemGroup.id}`) && (
                                    <Grid item xs>
                                        <Typography
                                            component="h3"
                                            variant="h6"
                                            align="left"
                                            className={classes.mainTitle}
                                        >
                                            {t(`a_FormGroup_${itemGroup.id}`)}
                                        </Typography>
                                    </Grid>
                                )}
                                {handleMenuClick !== undefined && (
                                    <Grid
                                        container
                                        item
                                        xs={1}
                                        justify="flex-end"
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={handleMenuClick}
                                        >
                                            <MoreVertIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                            {formGroupHelp && (
                                <Typography
                                    component="h3"
                                    variant="caption"
                                    align="left"
                                    className={classes.helpForm}
                                >
                                    {formGroupHelp}
                                </Typography>
                            )}
                            <Grid
                                container
                                className={classes.gridContainer}
                                spacing={1}
                            >
                                {camposMetadados
                                    .filter(
                                        (i: any) =>
                                            i.formGroupId === itemGroup.id
                                    )
                                    .map((item: any) => {
                                        //debugger;
                                        const lastOrderParam = lastOrderItem;
                                        lastOrderItem = item.order;
                                        const Field = getFields(
                                            item,
                                            classes,
                                            t,
                                            listas,
                                            valuesFromSource,
                                            CustomComponents,
                                            language,
                                            customLists,
                                            fieldsToHide,
                                            camposMetadados,
                                            true,
                                            additionalFieldProperties
                                        );

                                        if (
                                            lastOrderParam !== lastOrderItem &&
                                            lastOrderParam !== -1
                                        ) {
                                            return (
                                                <React.Fragment
                                                    key={item.fieldName}
                                                >
                                                    <Grid container></Grid>
                                                    {Field}
                                                </React.Fragment>
                                            );
                                        } else {
                                            return Field;
                                        }
                                    })}
                            </Grid>
                        </Paper>
                    );
                })}
                {t("button_FloatSubmit", "") && (
                    <Fab
                        variant="extended"
                        className={classes.fabEdit}
                        onClick={applyChangeButtonForceSubmit}
                        disabled={reactHookFormMethods.formState.isSubmitting}
                        size="medium"
                        aria-label={t("button_FloatSubmit", "")}
                    >
                        {t("button_FloatSubmitIcon", "") ? (
                            <Icon className={classes.fabIcon}>
                                {t("button_FloatSubmitIcon", "")}
                            </Icon>
                        ) : (
                            <CheckIcon className={classes.fabIcon} />
                        )}
                        {t("button_FloatSubmit", "")}
                    </Fab>
                )}
            </Box>
        </Box>
    );
};

function areEqual(prevProps: any, nextProps: any) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    let areEqualVar = false;
    if (typeof prevProps === "object" && typeof nextProps === "object") {
        // A comparação usando JSON.stringify leva em conta a ordem das
        // propriedades do objeto. Uma alternativa caso tenhamos problemas com a ordem
        // é o pacote lodash (isEqual)
        areEqualVar = _.isEqual(prevProps, nextProps);
        // areEqualVar = JSON.stringify(prevProps) === JSON.stringify(nextProps);
    }

    return areEqualVar;
}

// export default React.memo(FormMainDetail, areEqual);
export default FormMainDetail;
