import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat, { FormatInputValueFunction } from "react-number-format";
import { FieldProps } from "formik";

interface IProps {
    label: string;
    helperTextDefault: null | string;
}

const CNPJCPFField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue },
        helperTextDefault,
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let mensagemErro =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    function handleFormat(val: string | FormatInputValueFunction) {
        let fieldValue = String(val).replace(/(\.|\/|\-)/g, "");

        if (fieldValue.length <= 11) {
            //CPF
            fieldValue = fieldValue.replace(/(\d{3})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(/(\d{3})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        } else {
            //CNPJ
            fieldValue = fieldValue.replace(/^(\d{2})(\d)/, "$1.$2");
            fieldValue = fieldValue.replace(
                /^(\d{2})\.(\d{3})(\d)/,
                "$1.$2.$3"
            );
            fieldValue = fieldValue.replace(/\.(\d{3})(\d)/, ".$1/$2");
            fieldValue = fieldValue.replace(/(\d{4})(\d)/, "$1-$2");
        }

        return fieldValue;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("onChange", event.target.value);
        setFieldValue(field.name, event.target.value);
    };

    return (
        <NumberFormat
            id={field.name}
            label={label}
            error={hasError || (status && status[field.name] !== undefined)}
            helperText={mensagemErro}
            {...field}
            {...other}
            format={handleFormat}
            customInput={TextField}
        />
    );
};

// CNPJCPFField.defaultProps = {
//     fullWidth: true,
//     margin: "normal"
// };

export default CNPJCPFField;
