import React from "react";
import {
    fade,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Router, Link, useLocation } from "@gatsbyjs/reach-router";
import MenuModal from "./MenuModal";
import classNames from "classnames";
import { Drawer } from "@material-ui/core";
import useSessionValues from "_ReactContext/SessionValues";

const useStyles = () => {
    const [sessionData] = useSessionValues();

    return makeStyles((theme: Theme) =>
        createStyles({
            root: { height: "100%", display: "flex", flexDirection: "column" },
            list: {
                display: "flex",
                flexDirection: "column",
                "&:hover, &:focus": {
                    backgroundColor: theme.palette.primary.light
                }
            },
            fontMenu: {
                fontSize: 12,
                maxWidth: 80,
                color: theme.palette.grey[400]
            },
            linkIcon: {
                transition: theme.transitions.create("color"),
                display: "flex",
                justifyContent: "center"
            },
            linkText: {
                transition: theme.transitions.create(["opacity", "color"]),
                display: "flex",
                justifyContent: "flex-end"
            },
            icon: {
                fontSize: 30,
                color: theme.palette.grey[400]
            },
            activeColor: {
                color: "white"
            },
            grow: {
                flexGrow: 2
            },
            drawer: {
                [theme.breakpoints.up("sm")]: {
                    width: sessionData.menuWidth,
                    flexShrink: 0
                }
            },
            drawerPaper: {
                width: sessionData.menuWidth,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.dark
            },
            toolbar: theme.mixins.toolbar
        })
    );
};

interface IProps {
    navigation: any;
    t: any;
}

const MenuCompleoSmall = (props: IProps) => {
    const { navigation, t } = props;
    const flatNavigation: Compleo.IObject[] = [];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const location = useLocation();
    const mainRouter = location.pathname.split("/")[1];
    // console.log("mainRouter", mainRouter);

    ((navigation || {}).features || []).map((main: Compleo.IObject) => {
        main.items.map((itemNav: Compleo.IObject) => {
            if (itemNav?.shortMenuShow === true) {
                flatNavigation.push(itemNav);
            }
        });
    });

    const settingsMenuOptions = navigation.features.filter(
        (item: any) => item.groupUniqueName === "settingsGroup"
    )[0];
    const settingsOptions = settingsMenuOptions?.items || [];
    settingsOptions.sort((a: any, b: any) => a.order - b.order);

    const classes = useStyles()();
    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
        >
            <div className={classes.toolbar} />
            <List className={classes.root}>
                {flatNavigation
                    .sort(
                        (a: Compleo.IObject, b: Compleo.IObject) =>
                            a.shortMenuOrder - b.shortMenuOrder
                    )
                    .map((item: Compleo.IObject) => {
                        const linkRoute = item.route.split("/")[1];
                        const isLinkActive = mainRouter === linkRoute;

                        var iconClasses = classNames(classes.icon, {
                            [classes.activeColor]: isLinkActive
                        });
                        const fontMenuClasses = classNames(classes.fontMenu, {
                            [classes.activeColor]: isLinkActive
                        });

                        return (
                            <div key={item.uniqueName}>
                                <ListItem
                                    button
                                    component={Link}
                                    className={classes.list}
                                    to={item.route}
                                >
                                    <ListItemIcon className={classes.linkIcon}>
                                        <Icon className={iconClasses}>
                                            {item.shortMenuIcon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        // className={classes.linkText}
                                        classes={{
                                            primary: classes.linkText
                                        }}
                                        primary={t(item.uniqueName)}
                                        primaryTypographyProps={{
                                            className: fontMenuClasses,
                                            noWrap: true
                                        }}
                                    />
                                </ListItem>
                            </div>
                        );
                    })}
                <div className={classes.grow} />

                {settingsOptions.length > 0 && (
                    <ListItem
                        button
                        className={classes.list}
                        onClick={openMenu}
                    >
                        <ListItemIcon className={classes.linkIcon}>
                            <Icon className={classes.icon}>settings</Icon>
                        </ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.linkText
                            }}
                            primary={t("fixeSmallMenu_settings")}
                            primaryTypographyProps={{
                                className: classes.fontMenu,
                                noWrap: true
                            }}
                        />
                    </ListItem>
                )}
                <MenuModal
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    options={settingsOptions}
                />
            </List>
        </Drawer>
    );
};

export default MenuCompleoSmall;
