import React from "react";
// import { useTranslation } from "react-i18next";

const defaultLanguage = "pt-BR";
const LanguageContext = React.createContext(defaultLanguage);
// const LanguageContextDispatch = React.createContext(defaultLanguage);

// interface ILanguageContextProvider {
//     children: any;
// }

// const LanguageContextProvider = (props: ILanguageContextProvider) => {
//     const { children } = props;
//     const [language, setLanguage] = React.useState(defaultLanguage);

//     const [, i18n, readyTranslation] = useTranslation("SIGNIN", {
//         useSuspense: false
//     });
//     const tranlationLanguage = i18n.languages[0];
//     React.useEffect(() => {
//         if (readyTranslation && tranlationLanguage !== language) {
//             setLanguage(tranlationLanguage);
//         }
//     }, [tranlationLanguage, readyTranslation]);
//     console.log("inside context tranlationLanguage", tranlationLanguage);
//     console.log("inside context language", language);

//     return (
//         <LanguageContext.Provider value={language}>
//             {children}
//         </LanguageContext.Provider>
//     );
// };

function useLanguageState() {
    const contextState = React.useContext(LanguageContext);
    return contextState;
}

export { LanguageContext, useLanguageState };
