import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2)
        }
    })
);

interface IViewListShareHeader {
    id: string;
    children: any;
}
const ViewListShareHeader = (props: IViewListShareHeader) => {
    const { id, children } = props;
    const classes = useStyles();
    const moduleName = "APPLICANTLISTSHARE";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const postAddressSearch = "/applicant/applicantlistshare";
    const [getApplicantListShareResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: id },
        false
    );
    const mainData =
        getApplicantListShareResult.response?.data?.otherData || null;
    const ready =
        getApplicantListShareResult.status === "success" && readyTranslation;

    if (ready) {
        return (
            <Box width="100%" display="flex" flexDirection="column">
                <Box>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>{t("job")}</strong>:
                                    {` ${mainData?.jobData?.title}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                    <strong>{t("stage")}</strong>:
                                    {` ${mainData?.newStage?.labelName}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
                <Box>{children}</Box>
            </Box>
        );
    } else {
        return <Loading />;
    }
};

export default ViewListShareHeader;
