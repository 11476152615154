import FormData from "./FormData";
import Translations from "./TranslationComponent/Translations";

const CustomComponents = [
    {
        name: "formData",
        component: FormData
    },
    {
        name: "translations",
        component: Translations
    }
];

export default CustomComponents;
