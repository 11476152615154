import { useApiCache } from "customHooks/useApi";
import useApplicantViewContext from "Pages/Applicant/ApplicantView/useApplicantViewContext";
import React, { useReducer } from "react";
import { useAuthState } from "_ReactContext/AuthContext";

const reducer = (
    state: Compleo.CustomLists.ListDefinitionType[],
    action: Compleo.CustomLists.ListDefinitionType
) => {
    const newState = state.filter(
        (item) => item.fieldName !== action.fieldName
    );
    newState.push(action);
    return newState;
};

const verifyReady = (
    fieldList: string[],
    state: Compleo.CustomLists.ListDefinitionType[]
) => {
    const currentFieldsLoaded = state.map((item) => item.fieldName);
    for (const field of fieldList) {
        if (!currentFieldsLoaded.includes(field)) {
            return false;
        }
    }
    return true;
};

const useModalNewEmailLists: (
    t: any,
    readyTranslation: boolean,
    applicantData?: Compleo.IObject
) => [Compleo.CustomLists.ListDefinitionType[], boolean] = (
    t: any,
    readyTranslation: boolean,
    applicantData?: Compleo.IObject
) => {
    const { company } = useAuthState();

    const [listDefinition, dispatch] = useReducer(reducer, []);

    const applicantEmail = applicantData?.email;

    const jobs = applicantData?.Jobs || [];
    React.useEffect(() => {
        dispatch({
            data: jobs.map((item: any) => {
                return {
                    JobId: item.JobId,
                    JobTitle: item.JobTitle
                };
            }),
            fieldName: "job",
            labelName: "JobTitle",
            valueName: "JobId"
        });
    }, [jobs.length]);

    // /user/dropdownlist
    const [listResult, getList] = useApiCache(
        "/user/dropdownlist",
        "post",
        {
            companyId: company.companyId
        },
        false,
        60 * 1000 * 10
    );
    const userList = listResult.response?.data || [];

    React.useEffect(() => {
        if (readyTranslation) {
            const usersData = userList.map((item: any) => {
                return {
                    value: item.itemValue,
                    label: `${item.itemText} - ${item.itemValue}`
                };
            });
            const dataToLoad = [...usersData];
            if (applicantEmail) {
                dataToLoad.push({
                    value: "applicant",
                    label: `${t("applicantRecipient")} - ${applicantEmail}`
                });
            }

            dispatch({
                data: dataToLoad,
                fieldName: "recipients",
                labelName: "label",
                valueName: "value"
            });
        }
        // console.log("userList", userList);
    }, [userList.length, readyTranslation]);
    // const ready = verifyReady(["job", "recipientes"], listDefinition);
    const ready = verifyReady(["job", "recipients"], listDefinition);
    // console.log("listDefinition", listDefinition);
    return [listDefinition, ready];
};

export default useModalNewEmailLists;
