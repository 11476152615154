import _ from "lodash";
import { clearArrayInfo } from "functions/util";
import { FieldValues, UseFormGetValues } from "react-hook-form";

export const getControlOptionsAndChildrenFilter = (
    campoMetadata: any,
    options: any,
    customLists: any,
    field: any,
    values: any,
    props: any
) => {
    let controlOptions = options !== undefined ? options : [];
    let parentFilterField = props.parentFilterField;
    let childrenFilterFieldName = props.childrenFilterFieldName;

    const selectCustomList =
        campoMetadata.selectCustomList === undefined
            ? false
            : campoMetadata.selectCustomList;

    if (selectCustomList) {
        const customList = (customLists || []).filter(
            (c: any) => c.fieldName === clearArrayInfo(field.name)
        )[0];
        const dataOptions = customList?.data || [];
        controlOptions = convertToAutocompletePattern(
            dataOptions,
            customList?.valueName || "value",
            customList?.labelName || "label",
            campoMetadata.selectCustomListSort
        );
        if (customList?.parentFilterField) {
            parentFilterField = customList.parentFilterField;
        }
        if (customList?.childrenFilterFieldName) {
            childrenFilterFieldName = customList.childrenFilterFieldName;
        }
    }

    if (parentFilterField) {
        const parentObject = _.get(values, parentFilterField.parentFieldName);
        if (parentObject) {
            const parentValue =
                parentObject[parentFilterField.parentIdFieldFilter];
            controlOptions = controlOptions.filter(
                (c: any) =>
                    c[(parentFilterField || {}).fieldFilter] === parentValue
            );
        } else {
            controlOptions = [];
        }
    }
    return { controlOptions, childrenFilterFieldName };
};

export const getControlOptionsAndChildrenFilterV2 = (
    campoMetadata: any,
    options: any,
    customLists: any,
    fieldName: string,
    parentFieldValue: any,
    props: any
) => {
    let controlOptions = options !== undefined ? options : [];
    let parentFilterField = props.parentFilterField;
    let childrenFilterFieldName = props.childrenFilterFieldName;

    const selectCustomList =
        campoMetadata.selectCustomList === undefined
            ? false
            : campoMetadata.selectCustomList;

    if (selectCustomList) {
        const customList = (customLists || []).filter(
            (c: any) => c.fieldName === clearArrayInfo(fieldName)
        )[0];
        const dataOptions = customList?.data || [];
        controlOptions = convertToAutocompletePattern(
            dataOptions,
            customList?.valueName || "value",
            customList?.labelName || "label",
            campoMetadata.selectCustomListSort
        );
        if (customList?.parentFilterField) {
            parentFilterField = customList.parentFilterField;
        }
        if (customList?.childrenFilterFieldName) {
            childrenFilterFieldName = customList.childrenFilterFieldName;
        }
    }

    if (parentFilterField) {
        const parentObject = parentFieldValue;
        if (parentObject) {
            const parentValue =
                parentObject[parentFilterField.parentIdFieldFilter];
            controlOptions = controlOptions.filter(
                (c: any) =>
                    c[(parentFilterField || {}).fieldFilter] === parentValue
            );
        } else {
            controlOptions = [];
        }
    }
    return { controlOptions, childrenFilterFieldName };
};

export const convertToAutocompletePattern = (
    data: any[],
    valueName: string,
    labelName: string,
    sort?: string
) => {
    const dataReturn: Compleo.IObject[] = [];
    data.map((d: any) => {
        const {
            [valueName]: removeValue,
            [labelName]: removeLabel,
            ...rest
        } = d;
        dataReturn.push({ value: d[valueName], label: d[labelName], ...rest });
    });
    if (sort) {
        switch (sort) {
            case "asc":
            case "desc":
                dataReturn.sort((a, b) => {
                    if (sort === "asc") {
                        if (a[labelName]) {
                            return a[labelName].localeCompare(b[labelName]);
                        } else {
                            return a.label.localeCompare(b.label);
                        }
                    } else {
                        if (b[labelName]) {
                            return b[labelName].localeCompare(a[labelName]);
                        } else {
                            return b.label.localeCompare(a.label);
                        }
                    }
                });
                break;
            case "ascNumber":
            case "descNumber":
                dataReturn.sort((a, b) => {
                    if (sort === "ascNumber") {
                        if (a[labelName]) {
                            return a[labelName] - b[labelName];
                        } else {
                            return a.label - b.label;
                        }
                    } else {
                        if (b[labelName]) {
                            return b[labelName] - a[labelName];
                        } else {
                            return b.label - a.label;
                        }
                    }
                });
                break;
            default:
                break;
        }
    }
    return dataReturn;
};
