import React from "react";
import { useApiCache } from "customHooks/useApi";

const useApplicantPhoto = (
    applicantId: string,
    companyId: number,
    isExternal = false,
    evaluationId?: string
): [string | undefined, boolean] => {
    const [ready, setReady] = React.useState<boolean>(false);
    const [applicantPhoto, setApplicantPhoto] = React.useState<
        string | undefined
    >(undefined);

    const apiPath = isExternal
        ? "/applicant/getavatarapplicantexternal"
        : "/applicant/getavatarapplicant";
    const params =
        isExternal && evaluationId
            ? {
                  id: applicantId,
                  evaluationId: evaluationId
              }
            : {
                  id: applicantId,
                  companyId: companyId
              };

    const [applicantPhotoData] = useApiCache(
        apiPath,
        "post",
        params,
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        if (applicantPhotoData?.status === "success") {
            const responseData = applicantPhotoData.response?.data;
            if (responseData?.photo) {
                setApplicantPhoto(responseData.photo);
            }
            setReady(true);
        } else if (applicantPhotoData?.status === "error") setReady(true);
    }, [applicantPhotoData]);

    return [applicantPhoto, ready];
};

export default useApplicantPhoto;
