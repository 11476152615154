import { CompleoListPage } from "customHooks/useList";
import ModalApplicantContainer from "customHooks/useList/ModalApplicantContainer";
import ModalActivity from "customHooks/useList/ModalActivity";

const ActivitiesReport = (props: any) => {
    const module = "REPORTSACTIVITIES";

    return (
        <CompleoListPage
            moduleName={module}
            listApiAddress="/reports/activitieslist"
        >
            <ModalApplicantContainer module={"APPLICANTVIEW"} />
            <ModalActivity />
        </CompleoListPage>
    );
};

export default ActivitiesReport;
