import ApplicationForm from "./ApplicationForm/ApplicationForm";
import ApplicationFormTranslations from "./ApplicationForm/ApplicationFormTranslations";
import ApplicationFormModel from "./ApplicationForm/ApplicationFormModel";
import Pipeline from "./Pipeline/Pipeline";
import PipelineModel from "./Pipeline/PipelineModel";
import TranslatedData from "./TranslatedData/TranslatedData";
import Scorecard from "./Scorecard/Scorecard";
import ScorecardModel from "./Scorecard/ScorecardModel";
import QuestionnaireModel from "./ScreeningQuestionnaire/QuestionnaireModel";
import Questionnaire from "./ScreeningQuestionnaire/Questionnaire";
import QuestionnaireTranslations from "./ScreeningQuestionnaire/QuestionnaireTranslations";
import JobBoards from "./JobBoards/JobBoards";
import QuestionnaireSimulation from "./ScreeningQuestionnaire/QuestionnaireSimulation";

const CustomComponents = [
    {
        name: "applicationFormformData",
        component: ApplicationForm
    },
    {
        name: "applicationFormtranslations",
        component: ApplicationFormTranslations
    },
    {
        name: "applicationformmodel",
        component: ApplicationFormModel
    },
    {
        name: "pipeline",
        component: Pipeline
    },
    {
        name: "pipelinemodel",
        component: PipelineModel
    },
    {
        name: "translatedData",
        component: TranslatedData
    },
    {
        name: "scorecard",
        component: Scorecard
    },
    {
        name: "scorecardmodel",
        component: ScorecardModel
    },
    {
        name: "questionnairemodel",
        component: QuestionnaireModel
    },
    {
        name: "screeningQuestionnaire",
        component: Questionnaire
    },
    {
        name: "questionnaireSimulation",
        component: QuestionnaireSimulation
    },
    {
        name: "questionnairetranslation",
        component: QuestionnaireTranslations
    },
    {
        name: "jobBoards",
        component: JobBoards
    }
];

export default CustomComponents;
