import { Box, Button, Chip, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useTranslation } from "react-i18next";
import ApplicantHistoryNewCommentModal from "./ApplicantHistoryNewCommentModal";
import ApplicantHistoryRow from "./ApplicantHistoryRow";
import { getSizeByHistoryType } from "./ApplicantHistoryHelper";
import useApplicantViewContext from "../useApplicantViewContext";
import ApplicantHistoryMenuFilter from "./ApplicantHistoryMenuFilter";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "90%",
            backgroundColor: "blue"
        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            backgroundColor: theme.palette.backgroundGrey.main
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {},
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        },
        alertNoResults: {
            margin: theme.spacing(2)
        }
    })
);

const ApplicantHistory = (props: any) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { company } = useAuthState();
    const { applicantId } = data;
    const [modalCommentOpen, setModalCommentOpen] = React.useState(false);

    const [filterType, setFilterType] = React.useState<
        CompleoShared.Common.ApplicantHistoryType | "all"
    >("all");
    const stageId = data?.jobDataInsideJob
        ? (data.applicantData?.Jobs || []).filter(
              (job: Compleo.IObject) =>
                  job.JobId === data?.jobDataInsideJob?.jobId
          )?.[0]?.Stage?.id
        : undefined;

    const [historyData, setHistoryData] = React.useState<Compleo.IObject[]>([]);
    const [displayLimit, setDisplayLimit] = React.useState(20);
    const [hasMore, setHasMore] = React.useState(historyData.length > 20);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        "APPLICANTVIEWHISTORY",
        {
            useSuspense: false
        }
    );
    const listAddress = `/applicant/applicanthistorylist`;
    const postData = {
        applicantId: applicantId,
        companyId: company.companyId,
        pageSize: 5000,
        page: 1,
        sizeProcessLocal: 0,
        startArray: 0,
        type: filterType
    };
    const [listHistory, updateListHistory] = useApiCache(
        listAddress,
        "post",
        postData,
        false,
        1000 * 60
    );

    React.useEffect(() => {
        updateListHistory(postData);
    }, [applicantId]);

    React.useEffect(() => {
        if (listHistory.status === "success") {
            const dbHistoryData: Compleo.IObject[] =
                listHistory.response?.data?.data || [];
            setHistoryData(dbHistoryData);
            setHasMore(dbHistoryData.length > 20);
            // Reset display limit to initial value when data changes
            setDisplayLimit(20);
        }
    }, [listHistory.status, (listHistory.response?.data?.data || []).length]);

    if (readyTranslation) {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" component="span">
                                    {tLocal("title")}
                                </Typography>
                                <Chip
                                    className={classes.chipCard}
                                    style={{
                                        backgroundColor: "salmon",
                                        color: "white"
                                    }}
                                    size="small"
                                    label={
                                        filterType === "all"
                                            ? tLocal("labelAll")
                                            : tLocal(
                                                  `ApplicantHistoryType_${filterType}`
                                              )
                                    }
                                />
                            </Grid>

                            <Grid item xs sm container justify="flex-end">
                                {!data.readOnly && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        // disabled={!ready}
                                        onClick={() =>
                                            setModalCommentOpen(true)
                                        }
                                    >
                                        {tLocal("buttonNewComment")}
                                    </Button>
                                )}
                                <ApplicantHistoryMenuFilter
                                    t={tLocal}
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    {historyData.length ? (
                        <Timeline align="left" className={classes.timeline}>
                            {historyData
                                .slice(0, displayLimit)
                                .map((historyItem, index) => (
                                    <ApplicantHistoryRow
                                        key={index}
                                        data={{
                                            historyData: historyData,
                                            tLocal: tLocal,
                                            language: i18n.languages[0]
                                        }}
                                        index={index}
                                        style={{
                                            height: getSizeByHistoryType(
                                                historyItem?.type
                                            )
                                        }}
                                    />
                                ))}
                            {hasMore && (
                                <Button
                                    onClick={() => {
                                        const newLimit = displayLimit + 10;
                                        setDisplayLimit(newLimit);
                                        setHasMore(
                                            newLimit < historyData.length
                                        );
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    style={{ margin: "auto", marginTop: 24 }}
                                >
                                    {tLocal("showMore")}
                                </Button>
                            )}
                        </Timeline>
                    ) : (
                        <Alert
                            severity="info"
                            className={classes.alertNoResults}
                        >
                            {tLocal("noResuts")}
                        </Alert>
                    )}

                    {modalCommentOpen && (
                        <ApplicantHistoryNewCommentModal
                            open={modalCommentOpen}
                            onClose={() => setModalCommentOpen(false)}
                            appicantViewDataProvider={data}
                            allowChangeJob={true}
                            stageId={stageId}
                        />
                    )}
                </Box>
            </Grid>
        );
    } else {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Loading />
                    </Paper>
                </Box>
            </Grid>
        );
    }
};

export default ApplicantHistory;
