import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useJobContext from "../../../useJobContext";
import JobKanbanSortColumnModalItems from "./JobKanbanSortColumnModalItems";
import { handleSort, priorityOrderName } from "./helperSortColumn";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    stageId: string;
}

const JobKanbanSortColumnModal = (props: IPropsItemFilter) => {
    const { onClose, open, stageId } = props;
    const [data, dispatchJobContext] = useJobContext();

    const [runningQuery, setRunningQuery] = React.useState(false);
    const { callDialog } = useGlobalDialog();
    const queryClient = useQueryClient();

    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === stageId
    )[0];
    const { stagesConfig } = data.LocalStateData;
    const { t, apiKeyOrderData, apiKeyApplicant, apiKeyJob } = data;
    const stageDef = stagesConfig[stageData?.id] || {};
    const [localValue, setLocalValue] = React.useState<{
        name: string;
        sortType: "asc" | "desc";
    }>(stageDef.currentSort || { name: priorityOrderName, sortType: "asc" });
    const [replacePriority, setReplacePriority] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            setLocalValue(
                stageDef.currentSort || {
                    name: priorityOrderName,
                    sortType: "asc"
                }
            );
            setReplacePriority(false);
        }
    }, [open]);

    React.useEffect(() => {
        if (localValue.name === priorityOrderName && replacePriority) {
            setReplacePriority(false);
        }
    }, [localValue.name]);

    const handleOk = () => {
        onClose();
    };

    const setSort = async () => {
        const sortFN = async () =>
            await handleSort({
                data: data,
                dispatchJobContext: dispatchJobContext,
                localValue: localValue,
                replacePriority: replacePriority,
                stageData: stageData
            });

        setRunningQuery(true);
        if (replacePriority) {
            callDialog({
                agreeButtonText: t("ModalKanbanSort_WarningYes"),
                bodyText: t("ModalKanbanSort_Warning"),
                title: t("ModalKanbanSort_WarningTitle"),
                agreeFunction: async () => {
                    await sortFN();
                    queryClient.invalidateQueries(apiKeyOrderData);
                    queryClient.invalidateQueries(apiKeyApplicant);
                    setRunningQuery(false);
                    onClose();
                },
                disagreeButtonText: t("ModalKanbanSort_WarningNo"),
                disagreeFunction: () => {
                    setRunningQuery(false);
                }
            });
        } else {
            await sortFN();
            setRunningQuery(false);
            onClose();
        }
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={t("ModalSortColumn_Title")}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t("ModalSortColumn_Title")} - {stageData?.name}
            </DialogTitle>
            <DialogContent dividers>
                <JobKanbanSortColumnModalItems
                    handleClose={onClose}
                    stageData={stageData}
                    localValue={localValue}
                    setLocalValue={setLocalValue}
                    replacePriority={replacePriority}
                    setReplacePriority={setReplacePriority}
                />
            </DialogContent>
            {runningQuery ? (
                <DialogActions>
                    <Loading size={24} />
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={handleOk} color="secondary">
                        {t("GeneralModalClose")}
                    </Button>
                    <Button onClick={setSort} color="primary">
                        {t("ModalKanbanSort_Apply")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default JobKanbanSortColumnModal;
