import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import { useJobListDropdown } from "customHooks/useList/ListHelpers/BulkActions/helperBulkActions";

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    operationId: string;
    operationItemId?: string;
    refreshListFunction: () => void;
}

const ModalAutomaticallyProcess = (props: IPropsItemFilter) => {
    const {
        onClose,
        open,
        operationId,
        refreshListFunction,
        operationItemId
    } = props;

    console.log("www", operationItemId);

    const [isUpdating, setIsUpdating] = React.useState(false);
    const jobData = useJobListDropdown();

    const { company } = useAuthState();
    const module = "PARSERMODALAUTOMATICALLYPROCESS";
    const [metadata] = useGetMetadata(module);
    const queryClient = useQueryClient();

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, false, {});
    const ready = readyTranslation && metadata.status === "success";

    const customList: Compleo.ListDefinitionType[] = [];
    if (jobData.ready) {
        const jobDataFiltered = jobData.data;
        customList.push({
            fieldName: "job",
            data: jobDataFiltered,
            valueName: "title",
            labelName: "pk"
        });
    }

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        const jobId = values?.job?.value || "";

        const dataPost: Compleo.IObject = {
            companyId: company.companyId,
            jobId: jobId,
            operationId: operationId
        };
        if (operationItemId) {
            dataPost.itemId = operationItemId;
        }

        const postAddress = `/parser/parserautomaticprocess`;

        try {
            await apiDirectCall(postAddress, "post", dataPost);
            queryClient.invalidateQueries("/parser/parseroperationlist");
            queryClient.invalidateQueries("/parser/parseroperationdetaillist");
            queryClient.invalidateQueries("/parser/parseroperationsearch");

            refreshListFunction();
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);
        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const title = t("ModalTitle");

    const [formCompleo, finished, handleSubmitRHF] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: customList
    });
    const handleSave = async () => {
        await handleSubmitRHF();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (finished && ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                aria-labelledby={title}
                open={open}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default ModalAutomaticallyProcess;
