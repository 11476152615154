import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useDashboardItemHelper from "./useDashboardItemHelper";
import DashboardMainItem from "./DashboardMainItem";

const useStyles = makeStyles({
    count: {
        marginTop: 8
    }
});

interface IDashboardSummaryCard {
    widgetName: string;
}

const DashboardSummaryCard = (props: IDashboardSummaryCard) => {
    const { widgetName } = props;
    const classes = useStyles();
    const { t, i18n, ready, getData } = useDashboardItemHelper({
        widgetName: widgetName
    });
    const total = getData?.response?.data?.data;

    return (
        <DashboardMainItem ready={ready} t={t} widgetName={widgetName}>
            <Typography variant="h5" component="h2" className={classes.count}>
                {total}
            </Typography>
        </DashboardMainItem>
    );
};

export default DashboardSummaryCard;
