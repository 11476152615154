import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    Hidden,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "./useApplicantViewContext";
import theme from "theme/Theme";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { getGroupFromStage } from "Pages/Pipeline/components/helperPipeline";
import { getColorFromPipelineGroupType } from "Pages/Job/JobView/JobKanban/JobKanbanColumn";
import MenuJobActions, {
    MenuJobActionRunActionType,
    MenuJobActionRunActionTypeList
} from "./ApplicantJobList/MenuJobActions";
import { useTranslation } from "react-i18next";
import useRemoveApplicantFromJob from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useRemoveApplicantFromJob";
import ChangeStageButtonOutsideJobContainer from "./ChangeStageButton/ChangeStageButtonOutsideJobContainer";
import { JobStatusColor } from "customHooks/useList/components/FieldsType/FieldJobStatus";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import AddIcon from "@material-ui/icons/Add";
import ApplicantAddToJob from "../components/ApplicantAddToJob";
import { Alert } from "@material-ui/lab";
import { Link } from "@gatsbyjs/reach-router";
import { useVerifyJobAccess } from "Pages/Job/util/useVerifyJobAccess";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            padding: 0,
            marginTop: theme.spacing(0),
            height: "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column"
        },
        container: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
            margin: 0
        },
        listItemIcon: {
            minWidth: theme.spacing(2),
            marginRight: theme.spacing(1)
        },
        listItemText: {},
        buttonIconTooltip: {
            padding: theme.spacing(0.5)
        },
        paperContainer: {
            width: "100%",
            margin: 0,
            padding: 0
        },
        iconButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        },
        jobList: {
            maxHeight: 250,
            overflowX: "hidden",
            overflowY: "auto"
        },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        }
    })
);

interface IProps {}

const ApplicantViewSideContainerJobList = (props: IProps) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const [addToJobModalOpen, setAddToJobModalOpen] = React.useState(false);
    const keysToInvalidate: string[] = [
        data.queryKey,
        "/applicant/list",
        "/job/applicantlistjobview"
    ];
    const jobDataContext = data.jobDataInsideJob?.jobDataContext || {};

    const { t } = data;
    // data.i
    const { applicantData, applicantIndex } = data;
    const isXSSMScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));
    const firstBoxSize = isXSSMScreen ? 100 : isMd ? 50 : 70;
    // console.log("applicantData", applicantData);
    return (
        // <Box className={classes.mainContainer}>
        <Paper className={classes.mainContainer} elevation={1}>
            <Box className={classes.container}>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="body1">
                            <strong>{t("jobs")}</strong>
                        </Typography>
                    </Grid>
                    {!jobDataContext.readonly && !data.readOnly ? (
                        <Grid item xs sm container justify="flex-end">
                            <ButtonIconTooltipCompleo
                                label={t("addToJob")}
                                onClick={() => setAddToJobModalOpen(true)}
                                className={classes.iconButton}
                                edge="end"
                            >
                                <AddIcon />
                            </ButtonIconTooltipCompleo>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>

            {/* <Divider /> */}
            <Box className={classes.jobList}>
                {(applicantData.Jobs || []).map((jobData: Compleo.IObject) => {
                    const ratingValue = (
                        applicantData?.EvaluationsAllJobs || []
                    ).filter(
                        (item: Compleo.IObject) => item.jobId === jobData.JobId
                    )[0];
                    const jobEvaluation = ratingValue?.value || 0;

                    return (
                        <JobActions
                            key={jobData.JobId}
                            jobData={jobData}
                            index={applicantIndex}
                            jobEvaluation={jobEvaluation}
                        />
                    );
                })}
            </Box>
            {!(applicantData.Jobs || []).length && (
                <Box className={classes.jobList}>
                    <Alert severity="info">{t("noJobsInfo")}</Alert>
                </Box>
            )}
            {addToJobModalOpen && (
                <ApplicantAddToJob
                    open={addToJobModalOpen}
                    onClose={() => setAddToJobModalOpen(false)}
                    applicantId={data.applicantId}
                    currentJobs={applicantData.Jobs}
                    listKeyToInvalidate={keysToInvalidate}
                />
            )}
        </Paper>
    );
};

interface IJobActions {
    jobData: Compleo.IObject;
    index: number;
    jobEvaluation: number;
}
const JobActions = (props: IJobActions) => {
    const classes = useStyles();
    const { jobData, index, jobEvaluation } = props;
    const [data] = useApplicantViewContext();
    const keysToInvalidate: string[] = [
        data.queryKey,
        "/applicant/list",
        "/job/applicantlistjobview"
    ];
    const { t } = data;
    const verifyJobAccess = useVerifyJobAccess();
    const hasJobAccess = verifyJobAccess(jobData?._updatedJobData || {});

    const [modalOpen, setModalOpen] = React.useState(false);
    // const [isChangeStage, setIsChangeStage] = React.useState(false);
    const groupType = getGroupFromStage(jobData?.Stage);
    const iconColor = getColorFromPipelineGroupType(groupType);
    const [tJobView, i18n, readyTranslation] = useTranslation("JOBVIEW", {
        useSuspense: false
    });
    const removeApplicant = useRemoveApplicantFromJob({
        companyId: data.companyId,
        jobId: jobData.JobId,
        listKeyToInvalidate: keysToInvalidate,
        t: tJobView
    });
    const readOnly = hasJobAccess === false || data.readOnly;
    const jobTitle = jobData?._updatedJobData?.title || jobData.JobTitle;
    const jobStatus: CompleoShared.Common.JobStatus = t(
        `JOBSTATUS_${jobData?._updatedJobData?.status || jobData.status}`
    );

    const menuActions: MenuJobActionRunActionType = async (
        type: MenuJobActionRunActionTypeList
    ) => {
        switch (type) {
            case "removeFromJob":
                await removeApplicant.removeApplicantFromJob(
                    data.applicantData?.pk
                );
                break;
        }
    };
    return (
        <>
            <Divider />
            <ListItem>
                <ListItemText
                    className={classes.listItemText}
                    primary={
                        <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Box mr={1}>
                                {hasJobAccess ? (
                                    <Link to={`/job/view/${jobData.JobId}`}>
                                        {jobTitle}
                                    </Link>
                                ) : (
                                    jobTitle
                                )}
                            </Box>
                            <Box>
                                <CompleoCompanyRating
                                    evaluationValue={jobEvaluation}
                                    showTextValue
                                />
                            </Box>
                        </Box>
                    }
                    secondary={
                        <Box marginTop={0.7}>
                            {hasJobAccess && (
                                <ChangeStageButtonOutsideJobContainer
                                    JobData={jobData}
                                    t={tJobView}
                                    index={index}
                                />
                            )}
                            {data.jobDataInsideJob &&
                                data.jobDataInsideJob?.jobId ===
                                    jobData?.pk.split(":")[1] && (
                                    <Chip
                                        className={classes.chipCard}
                                        style={{
                                            backgroundColor: "indigo",
                                            color: "white"
                                            // fontSize: 16
                                        }}
                                        size="small"
                                        label={t("currentJobTag")}
                                    />
                                )}
                            <Chip
                                className={classes.chipCard}
                                style={{
                                    backgroundColor: "grey",
                                    color: "white"
                                    // fontSize: 16
                                }}
                                size="small"
                                label={jobStatus}
                            />
                        </Box>
                    }
                />
                {/* <ListItemText>
                    {" "}
                    <Chip
                        // className={classes.chipCard}
                        style={{
                            backgroundColor: JobStatusColor[status],
                            color: "white"
                        }}
                        size="small"
                        label={tJobView(`JOBSTATUS_${jobData?.status}`)}
                    />
                </ListItemText>{" "} */}
                {!data.jobDataInsideJob && !readOnly && (
                    <ListItemSecondaryAction>
                        <MenuJobActions
                            {...props}
                            runAction={menuActions}
                            t={tJobView}
                        />
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        </>
    );
};

export default ApplicantViewSideContainerJobList;
