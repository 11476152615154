import React from "react";
import "./App.css";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import CompleoRoutes from "Routes";
import { NotificationProvider } from "_ReactContext/NotificationContext";
import { DialogProvider } from "_ReactContext/GlobalDialogContext";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalValuesProvider } from "_ReactContext/GlobalValues";
import { SessionValuesProvider } from "_ReactContext/SessionValues";
import ErrorHandler from "ErrorBoundary/Index";
import { BackdropProvider } from "_ReactContext/Backdrop";

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <SessionValuesProvider>
                    <NotificationProvider>
                        <BackdropProvider>
                            <AuthProvider>
                                <ErrorHandler>
                                    <GlobalValuesProvider>
                                        <DialogProvider>
                                            <CompleoRoutes />
                                        </DialogProvider>
                                    </GlobalValuesProvider>
                                </ErrorHandler>
                            </AuthProvider>
                        </BackdropProvider>
                    </NotificationProvider>
                </SessionValuesProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </>
    );
};

export default App;
