import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useJobContext from "../../useJobContext";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import JobShareErrorSiteList from "./JobShareErrorSiteList";
import JobShareSiteList from "./JobShareSiteList";
import useJobBoardsCareersStatus from "../JobBoards/useJobBoardsCareersStatus";
import { IJobBoardsShareStatusMessages } from "../JobBoards/JobBoardsStatusModal";
import JobBoardsShareStatusMessages from "../JobBoardsShareStatusMessages";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        mainAlert: {
            marginBottom: theme.spacing(1)
        }
    })
);

interface IJobShareModal {}

const JobShareModal = (props: IJobShareModal) => {
    const [data, dispatchJobData] = useJobContext();
    const open = data?.LocalStateData?.modalJobShareOpen === true;
    const showMessage = useShowMessage();
    const classes = useStyles();
    const {
        listCareersItems,
        ready,
        listCareersErrors,
        listCareersNoErrors
    } = useJobBoardsCareersStatus();

    const handleModalClose = () => {
        dispatchJobData.dispatchGeneralPageInfo({
            payload: {
                modalJobShareOpen: false
            },
            type: "update"
        });
    };

    const [t, i18n, readyTranslation] = useTranslation("JOBSHAREMODULE", {
        useSuspense: false
    });

    const readyFinal = readyTranslation && ready;
    const jobMessages = getMessageList(
        data?.Job?.data || {},
        t,
        i18n.languages[0],
        readyFinal
    );

    return (
        <Dialog
            open={open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-title"
            scroll="body"
            PaperProps={{
                className: classes.dialog
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {t("jobShareTitle", "")}
            </DialogTitle>
            {readyFinal ? (
                <DialogContent>
                    {jobMessages.hasError && (
                        <Paper elevation={1} className={classes.paper}>
                            <Alert severity="warning">
                                {t("messageGeneralErrorShare")}
                            </Alert>
                            <JobBoardsShareStatusMessages
                                messageList={jobMessages.messageList.filter(
                                    (i) => i.type === "error"
                                )}
                            />
                        </Paper>
                    )}
                    {!jobMessages.hasError && (
                        <JobShareSiteList
                            t={t}
                            classes={classes}
                            listCareers={listCareersNoErrors}
                        />
                    )}
                    {!jobMessages.hasError && (
                        <JobShareErrorSiteList
                            t={t}
                            classes={classes}
                            listCareersErrors={listCareersErrors}
                        />
                    )}
                </DialogContent>
            ) : (
                <DialogContent>
                    <Loading />
                </DialogContent>
            )}
            {readyFinal && (
                <DialogActions>
                    <Button onClick={handleModalClose} color="secondary">
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

const getMessageList = (
    jobData: Compleo.IObject,
    t: any,
    language: string,
    ready: boolean
) => {
    const messageList: IJobBoardsShareStatusMessages[] = [];
    if (!ready) {
        return { messageList: messageList, hasError: false };
    }

    if (jobData?.status === "open") {
        messageList.push({
            message: t("jobShareModalStatusOpenMessageOK", {
                jobStatus: t("JOBSTATUS_open")
            }),
            type: "success"
        });
    } else {
        messageList.push({
            message: t("jobShareModalStatusOpenMessageError", {
                jobStatus: t(`JOBSTATUS_${jobData?.status}`),
                openStatus: t("JOBSTATUS_open")
            }),
            type: "error"
        });
    }

    if (jobData?.visibility?.value !== "CP4") {
        messageList.push({
            message: t("jobShareModalStatusVisibleMessageOK", {
                visibility:
                    jobData?.visibility?.label ||
                    jobData?.visibility?.[`label-${language}`]
            }),
            type: "success"
        });
    } else {
        messageList.push({
            message: t("jobShareModalStatusVisibleMessageError", {
                visibility:
                    jobData?.visibility?.label ||
                    jobData?.visibility?.[`label-${language}`]
            }),
            type: "error"
        });
    }

    const hasError = messageList.filter((i) => i.type === "error").length > 0;
    return { messageList, hasError };
};

export default JobShareModal;
