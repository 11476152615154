/* eslint-disable react/jsx-key */
import React from "react";
// import { TestReactTableData } from "./TestReactTableData";
//@ts-ignore
import { useTable, useGroupBy, useExpanded } from "react-table";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles
} from "@material-ui/core/styles";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {
    FormatIndentIncrease,
    FormatIndentDecrease,
    ExpandLess,
    ExpandMore,
    Block
} from "@material-ui/icons";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import _ from "lodash";
import { Link } from "@gatsbyjs/reach-router";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#5c6bc0",
            color: theme.palette.common.white
        },
        body: {
            fontSize: 14
        }
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover
            }
        }
    })
)(TableRow);

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: 360
    }
});

interface IReactTableComponent {
    columns: any;
    data: Compleo.IObject[];
    t: any;
}
const ReactTableComponent = (props: IReactTableComponent) => {
    const { columns, data, t } = props;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { groupBy, expanded }
    } = useTable(
        {
            columns,
            data
        },
        useGroupBy,
        useExpanded // useGroupBy would be pretty useless without useExpanded ;)
    );

    const classes = useStyles();

    return (
        <>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <MaUTable {...getTableProps()} stickyHeader>
                        <TableHead>
                            {headerGroups.map((headerGroup: any) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column: any) => (
                                        <StyledTableCell
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                            {column.canGroupBy ? (
                                                // If the column can be grouped, let's add a toggle
                                                <Tooltip
                                                    title={
                                                        column.isGrouped
                                                            ? t(
                                                                  "SMARTTABLE_removeGroup"
                                                              )
                                                            : t(
                                                                  "SMARTTABLE_groupByThisColumn"
                                                              )
                                                    }
                                                >
                                                    <IconButton
                                                        color={
                                                            column.isGrouped
                                                                ? "secondary"
                                                                : "inherit"
                                                        }
                                                        {...column.getGroupByToggleProps()}
                                                        component="span"
                                                        title={undefined}
                                                    >
                                                        {column.isGrouped ? (
                                                            <Block
                                                                style={{
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        ) : (
                                                            <FormatIndentIncrease
                                                                style={{
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            ) : // <span
                                            //     {...column.getGroupByToggleProps()}
                                            // >
                                            //     {column.isGrouped ? (
                                            //         <FormatIndentDecrease
                                            //             style={{
                                            //                 margin: 0,
                                            //                 padding: 0
                                            //             }}
                                            //         />
                                            //     ) : (
                                            //         <FormatIndentIncrease
                                            //             style={{
                                            //                 margin: 0,
                                            //                 padding: 0
                                            //             }}
                                            //         />
                                            //     )}
                                            // </span>
                                            null}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {rows.map((row: any, i: any) => {
                                prepareRow(row);
                                return (
                                    <StyledTableRow {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                            return (
                                                <WrapCellType
                                                    row={row}
                                                    cell={cell}
                                                    t={t}
                                                    columns={columns}
                                                />
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </MaUTable>
                </TableContainer>
            </Paper>
        </>
    );
};
interface IWrapCellTypeProps {
    cell: any;
    row: any;
    t: any;
    columns: any;
}

const WrapCellType = (props: IWrapCellTypeProps) => {
    const { cell, row, t, columns } = props;
    // debugger;
    const columnDef = columns.filter(
        (c: Compleo.IObject) => c.accessor === cell?.column?.id
    )[0];

    const ReturnItem = (
        <StyledTableCell
            // For educational purposes, let's color the
            // cell depending on what type it is given
            // from the useGroupBy hook
            {...cell.getCellProps()}
            // style={{
            //     background: cell.isGrouped
            //         ? "#0aff0082"
            //         : cell.isAggregated
            //         ? "#ffa50078"
            //         : cell.isPlaceholder
            //         ? "#ff000042"
            //         : "white"
            // }}
            // component="th"
            // scope="row"
        >
            {cell.isGrouped ? (
                // If it's a grouped cell, add an expander and row count
                <>
                    <Tooltip
                        title={
                            row.isExpanded
                                ? t("SMARTTABLE_expandless")
                                : t("SMARTTABLE_expandmore")
                        }
                    >
                        <IconButton
                            color="inherit"
                            {...row.getToggleRowExpandedProps()}
                            component="span"
                            title={undefined}
                        >
                            {row.isExpanded ? (
                                <ExpandLess
                                    style={{
                                        margin: 0,
                                        padding: 0
                                    }}
                                />
                            ) : (
                                <ExpandMore
                                    style={{
                                        margin: 0,
                                        padding: 0
                                    }}
                                />
                            )}
                        </IconButton>
                    </Tooltip>
                    {/* <span
                {...row.getToggleRowExpandedProps()}
            >
                {row.isExpanded ? (
                    <ExpandLess />
                ) : (
                    <ExpandMore />
                )}
            </span> */}{" "}
                    {cell.render("Cell")} ({row.subRows.length})
                </>
            ) : cell.isAggregated ? (
                // If the cell is aggregated, use the Aggregated
                // renderer for cell
                cell.render("Aggregated")
            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                // Otherwise, just render the regular cell
                cell.render("Cell")
            )}
        </StyledTableCell>
    );

    switch (columnDef?.type) {
        case "jobLink":
            const jobId =
                cell?.row?.original?.key?.jobId ||
                (cell?.row?.original?.pk || "").split(":")[1];
            return <Link to={`/job/view/${jobId}`}>{ReturnItem}</Link>;
        case "applicantLink":
            return (
                <Link
                    to={`/applicant/view/${
                        (cell?.row?.original?.pk || "").split(":")[1]
                    }`}
                >
                    {ReturnItem}
                </Link>
            );

        default:
            return ReturnItem;
    }
};

// This is a custom aggregator that
// takes in an array of leaf values and
// returns the rounded median
function roundedMedian(leafValues: any) {
    let min = leafValues[0] || 0;
    let max = leafValues[0] || 0;

    leafValues.forEach((value: any) => {
        min = Math.min(min, value);
        max = Math.max(max, value);
    });

    return Math.round((min + max) / 2);
}

interface ICompleoSmartTable {
    data: Compleo.IObject[];
    widget: CompleoShared.Dashboard.widgetsMetadata;
    t: any;
    language: string;
}

const SmartTable = (props: ICompleoSmartTable) => {
    const { data, widget, t, language } = props;

    const columns: CompleoShared.Dashboard.IReactTableColumnDef[] = processColumns(
        widget.smartTableConfig?.columns || [],
        widget,
        t
    );

    const memoColumns = React.useMemo(() => columns, [
        columns.length,
        language
    ]);
    const rowsData = processRows(data, widget, t);

    return <ReactTableComponent columns={memoColumns} data={rowsData} t={t} />;
};

const processColumns = (
    columns: CompleoShared.Dashboard.IReactTableColumnDef[],
    widget: CompleoShared.Dashboard.widgetsMetadata,
    t: any
) => {
    const translate =
        widget.smartTableConfig?.translateOptions?.translate === true;
    const prefix = widget.smartTableConfig?.translateOptions?.prefix || "";

    const returnData = columns.map((item) => {
        const Header = translate ? t(`${prefix}${item.Header}`) : item.Header;
        const localReturnData: CompleoShared.Dashboard.IReactTableColumnDef = {
            ...item,
            Header: Header
        };
        if (item.customAccessor) {
            localReturnData.accessor = (data: Compleo.IObject) =>
                (data[item.accessor || ""] || []).map(
                    (itemLocal: Compleo.IObject) => {
                        return (
                            <div>
                                {(
                                    item.customAccessor?.fieldsToRender || []
                                ).map((i, index, array) => (
                                    <span>
                                        {itemLocal[i]}
                                        {index !== array.length - 1
                                            ? " - "
                                            : ""}
                                    </span>
                                ))}
                            </div>
                        );
                    }
                );
        }
        const finalItem = _.cloneDeep(localReturnData);
        delete finalItem.translateItemsOptions;
        return finalItem;
    });
    return returnData;
};

const processRows = (
    data: Compleo.IObject[],
    widget: CompleoShared.Dashboard.widgetsMetadata,
    t: any
) => {
    const returnData = data.map((item) => {
        const retData: Compleo.IObject = _.cloneDeep(item);
        const fieldsDef = (widget.smartTableConfig?.columns || []).filter(
            (i) => i.translateItemsOptions?.translate === true
        );
        fieldsDef.map((fieldDef) => {
            if (fieldDef.accessor) {
                const prefix = fieldDef.translateItemsOptions?.prefix || "";
                const currentFieldValue = _.get(retData, fieldDef.accessor);
                _.set(
                    retData,
                    fieldDef.accessor,
                    t(`${prefix}${currentFieldValue}`)
                );
            }
        });

        return retData;
    });
    return returnData;
};

export default SmartTable;
