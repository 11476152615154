import React from "react";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import useRefreshKanban from "../../useRefreshKanban";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

interface IUseRemoveApplicantFromJob {
    companyId: number;
    jobId: string;
    t: any;
    listKeyToInvalidate: string[] | undefined;
    setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
    fnToRunAfterRemove?: any;
    fnToRunUserSelectedNoOption?: any;
}
const useRemoveApplicantFromJob = (params: IUseRemoveApplicantFromJob) => {
    const {
        jobId,
        t,
        companyId,
        listKeyToInvalidate,
        setIsUpdating,
        fnToRunAfterRemove,
        fnToRunUserSelectedNoOption
    } = params;
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const queryClient = useQueryClient();

    const removeApplicantFromJob = async (applicantId: string) => {
        const applicantIdLocal = applicantId.split(":")[1];
        callDialog({
            title: t("DialogRemoveFromJob_title"),
            bodyText: t("DialogRemoveFromJob_message"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const postAddress = "/job/removefromjob";
                if (setIsUpdating !== undefined) {
                    setIsUpdating(true);
                }
                const result = await apiDirectCall(postAddress, "post", {
                    companyId: companyId,
                    jobId: jobId,
                    applicantId: applicantIdLocal
                });
                if (result.status === 200) {
                    if (listKeyToInvalidate?.length) {
                        for (const keyName of listKeyToInvalidate) {
                            queryClient.invalidateQueries(keyName);
                        }
                    }
                    if (fnToRunAfterRemove) {
                        await fnToRunAfterRemove();
                    }
                    showMessage(
                        t("DialogRemoveFromJob_Successmessage"),
                        "success"
                    );
                    if (setIsUpdating !== undefined) {
                        setIsUpdating(false);
                    }
                    return true;
                }
                if (setIsUpdating !== undefined) {
                    setIsUpdating(false);
                }
                return false;
            },
            disagreeFunction: async () => {
                if (fnToRunUserSelectedNoOption) {
                    await fnToRunUserSelectedNoOption();
                }
                return false;
            }
        });
    };

    return { removeApplicantFromJob };
};

export default useRemoveApplicantFromJob;
