import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

export const useListPageFunctionsDeleteCustomer = (t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const callDeleteDialog = async (rowData: any) => {
        return new Promise((resolve, reject) => {
            const uuid = rowData.skGS1pk.split(":")[1];
            const postAddress = `/customer/delete/${uuid}`;
            //const [deleteCustomerReturn, deleteCustomer] = useApi(postAddress, "post");

            callDialog({
                title: t("CustomerDelete_ModalTitle"),
                bodyText: t("CustomerDelete_Message"),
                agreeButtonText: t("CustomerDelete_Yes"),
                disagreeButtonText: t("CustomerDelete_No"),
                agreeFunction: async () => {
                    //const uuid = rowData.skGS1pk.split(":")[1];
                    const result = await apiDirectCall(
                        postAddress,
                        "post",
                        rowData
                    );
                    //console.log("retorno apiDirectCall", result);
                    if (result.status === 200) {
                        showMessage(t("successMessage"), "success");
                        //return true;
                        resolve(true);
                    }
                    resolve(false);
                },
                disagreeFunction: () => {
                    resolve(false);
                }
            });
        });
    };

    const listPageFunctions = [{ action: "delete", fn: callDeleteDialog }];

    return listPageFunctions;
};
