import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import useList from "../index";

interface IUseStylesProps {
    bottomPagination: boolean;
}
const useStyles = (props: IUseStylesProps) =>
    makeStyles((theme) => {
        const { bottomPagination } = { ...props };
        return createStyles({
            root: {
                "& > * + *": {
                    marginTop: theme.spacing(2)
                },
                marginTop: bottomPagination ? theme.spacing(2) : undefined
            }
        });
    });

interface IProps {
    bottomPagination?: boolean;
}

const ListPagination = (props: IProps) => {
    const { bottomPagination } = { bottomPagination: false, ...props };
    const classes = useStyles({ bottomPagination: bottomPagination })();
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { totalFiltered } = listData.listReturnInfo;
    const { setFilters } = listDispatch;
    const currentPage = filters.pagination.currentPage;
    const pageSize = filters.pagination.pageSize;
    const totalPages = Math.ceil((totalFiltered?.value || -1) / pageSize);
    const { fields, queryFinished } = listData.listReturnInfo;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setFilters({
            ...filters,
            pagination: {
                currentPage: value,
                pageSize: pageSize
            },
            filterUpdated: true
        });
    };

    if (fields.length && queryFinished) {
        return (
            <div className={classes.root}>
                <Pagination
                    count={totalPages}
                    showFirstButton
                    showLastButton
                    page={currentPage}
                    onChange={handleChange}
                    // defaultPage={15}
                    siblingCount={3}
                    boundaryCount={2}
                />
            </div>
        );
    } else {
        return null;
    }
};

export default ListPagination;
