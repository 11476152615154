import React from "react";
import { useAuthorization } from "_ReactContext/AuthContext";
import Dashboard from "Pages/Dashboard/Dashboard";
import { Alert } from "@material-ui/lab";

interface IProps {
    evaluationId: string;
    dashboardId: string;
    companyId: number;
}
const JobApplicantsDashboardExternal = (props: IProps) => {
    const { evaluationId, dashboardId, companyId } = props;

    return (
        <Dashboard
            uniqueDashboardView={true}
            dashboardToShow="applicantsJobViewExternal"
            dashOtherProps={{
                applicantsJobViewExternal: {
                    evaluationId: evaluationId,
                    dashboardId: dashboardId,
                    companyId: companyId
                }
            }}
            externalAccess={true}
        />
    );
};

export default JobApplicantsDashboardExternal;
