import { apiDirectCall } from "customHooks/useApi";

// All data was downloaded from the below github
// https://github.com/dr5hn/countries-states-cities-database

export const getStatesOfCountry = async (id: string) => {
    const data = await apiDirectCall(
        "/general/getinternationalcountrystatecity",
        "post",
        {
            countryId: Number(id)
        }
    );
    return data.data?.states || [];
};

export const getCitiesOfState = async (id: string) => {
    const data = await apiDirectCall(
        "/general/getinternationalcountrystatecity",
        "post",
        {
            stateId: Number(id)
        }
    );
    return data.data?.cities || [];
};

export const getAllCountries = async () => {
    const data = await apiDirectCall(
        "/general/getinternationalcountrystatecity",
        "post",
        {}
    );
    return data.data?.countries || [];
};
