import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {
    Theme,
    withStyles,
    createStyles,
    makeStyles
} from "@material-ui/core/styles";
import MenuLanguage from "./MenuLanguage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            marginTop: theme.spacing(5)
        }
    })
);

interface IBaseProps {
    t: any;
}

const GenericAdditionalGrid = (props: IBaseProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.grid}
            alignContent="center"
            alignItems="center"
        >
            <Grid item xs>
                <Link href="/" variant="body2">
                    {props.t("COMPLEOGENERAL_CANCEL")}
                </Link>
            </Grid>
            <Grid item>
                <MenuLanguage />
            </Grid>
        </Grid>
    );
};

export default GenericAdditionalGrid;
