export type PipelineActionTypeDefinition = CompleoShared.Pipeline.PipelineActionTypeDefinition;

export const pipelineAllActionsFields: PipelineActionTypeDefinition[] = [
    "actionApplicantAddTags",
    "actionApplicantEmail",
    "actionApplicantSendEmailToUsers",
    "actionApplicantRequestEvaluation"
];

export type pipelineGroupType = "new" | "hired" | "rejected" | "others";

export const getStagesFromGroup = (
    group: pipelineGroupType,
    listStages: Compleo.IObject[]
) => {
    const newList: Compleo.IObject[] = [];
    switch (group) {
        case "new":
            listStages
                .filter((item: any) =>
                    ["newApplied", "newSourced", "newReferred"].includes(
                        item.type.value
                    )
                )
                .map((item: any) => {
                    newList.push(item);
                });
            break;
        case "hired":
        case "rejected":
            listStages
                .filter((item: any) => item.type.value === group)
                .map((item: any) => {
                    newList.push(item);
                });
            break;
        default:
            listStages
                .filter(
                    (item: any) =>
                        ![
                            "newApplied",
                            "newSourced",
                            "newReferred",
                            "hired",
                            "rejected"
                        ].includes(item.type.value)
                )
                .map((item: any) => {
                    newList.push(item);
                });
            break;
    }
    return newList;
};

export const getGroupFromStage = (
    stage: Compleo.IObject
): pipelineGroupType | null => {
    const value = stage?.type?.value || stage?.type;
    switch (value) {
        case "hired":
        case "rejected":
            return value;
        default:
            if (["newApplied", "newSourced", "newReferred"].includes(value)) {
                return "new";
            }
            if (value) {
                return "others";
            }
            return null;
    }
};

export const getLastIndexFromGroup = (
    group: any,
    listStages: Compleo.IObject[]
) => {
    const itemsFromGroup = getStagesFromGroup(group, listStages);
    const lastItem = itemsFromGroup[itemsFromGroup.length - 1];
    const mainListCurrentItem = listStages.findIndex(
        (item: any) => item?.id === lastItem?.id && item?.id
    );
    return { lastIndex: mainListCurrentItem, lastType: lastItem.type };
};

export type ModalDataType = {
    open: boolean;
    stage: Compleo.IObject;
    index: number;
    name: string;
    typeList: Compleo.IObject[];
    new: boolean;
};

export const getMetadataNameFromPipelineStageActionType = (
    type: PipelineActionTypeDefinition
) => {
    switch (type) {
        case "actionApplicantEmail":
            return "PIPELINESTAGEMAINACTIONAPPLICANTEMAIL";
        case "actionApplicantAddTags":
            return "PIPELINESTAGEMAINACTIONADDTAGS";
        case "actionApplicantRequestEvaluation":
            return "PIPELINESTAGEMAINACTIONREQUESTEVALUATION";
        case "actionApplicantSendEmailToUsers":
            return "PIPELINESTAGEMAINACTIONSENDEMAILTOUSERS";
    }
};

export const verifyPipelineStageHasActions = (values: Compleo.IObject) => {
    for (const fieldName of pipelineAllActionsFields) {
        const fieldValue = values?.[fieldName] || [];
        if (fieldValue.length) {
            return true;
        }
    }
    return false;
};

export const getActionsFromStages = (values: Compleo.IObject) => {
    const returnDataEnabled: PipelineActionTypeDefinition[] = [];
    const returnDataDisabled: PipelineActionTypeDefinition[] = [];
    for (const fieldName of pipelineAllActionsFields) {
        const fieldValue = values?.[fieldName] || [];
        if (fieldValue.length) {
            returnDataEnabled.push(fieldName);
        } else {
            returnDataDisabled.push(fieldName);
        }
    }
    return { enabled: returnDataEnabled, disabled: returnDataDisabled };
};
