import React from "react";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { FieldProps } from "formik";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(6)
        },
        listCheck: {
            minWidth: 36
        }
    })
);

interface IProps {
    helperTextDefault: null | string;
    value: string;
    exclude?: string[];
    t?: any;
    f?: any;
    data_testid?: string;
    secondaryText?: boolean;
}

const CheckboxControl = (props: IProps & FieldProps) => {
    const {
        field,
        form: {
            dirty,
            touched,
            errors,
            status,
            setFieldValue,
            setFieldTouched
        },
        exclude,
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    const t = props.t !== undefined ? props.t : (value: any) => value;
    const f = props.f;
    const secondaryText =
        props.secondaryText === undefined ? false : props.secondaryText;
    const classes = useStyles();

    let mensagemErro = hasError || errorText !== undefined ? errorText : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    const HandleChange = () => {
        if (field.value.includes(props.value)) {
            const nextValue = field.value.filter(
                (value: any) => value !== props.value
            );
            setFieldValue(field.name, nextValue);
        } else {
            let nextValue = field.value.concat(props.value);
            if (exclude !== undefined && exclude.length > 0) {
                nextValue = nextValue.filter(
                    (value: any) => !exclude.includes(value)
                );
            }
            setFieldValue(field.name, nextValue);
        }
    };
    return (
        <ListItem
            key={f.name}
            aria-label={f.name}
            dense
            button
            onClick={HandleChange}
            alignItems="center"
            data-testid={other.data_testid}
        >
            <ListItemIcon className={classes.listCheck} aria-label={f.name}>
                <Checkbox
                    {...field}
                    value={other.value}
                    checked={field.value.includes(props.value)}
                    onChange={HandleChange}
                    inputProps={{ "aria-label": other.value }}
                />
            </ListItemIcon>
            {secondaryText ? (
                <ListItemText
                    aria-label={f.name}
                    primary={t(f.name)}
                    secondary={t(`help_${f.name}`)}
                />
            ) : (
                <ListItemText aria-label={f.name} primary={t(f.name)} />
            )}
        </ListItem>
    );
};

export default CheckboxControl;
