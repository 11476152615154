import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { ApiStateType, apiDirectCall } from "customHooks/useApi";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useAuthState } from "_ReactContext/AuthContext";
import useDashboardContext from "../useDashboardContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const DashboarNewSaveModal = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const [data, dispatch] = useDashboardContext();

    const showMessage = useShowMessage();
    const formikRef = React.useRef(null);
    const classes = useStyles();
    const queryClient = useQueryClient();
    const module = "DASHBOARDNEWSAVEMODAL";
    const [metadata] = useGetMetadata(module);
    const [tLocal, i18n, readyTranslation] = useTranslation([module], {
        useSuspense: false
    });

    const handleOk = () => {
        onClose();
    };
    const [initialValues] = useValuesFromSource(metadata, false, {
        type: { value: "1" }
    });

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const handleSubmit = async (values: any) => {
        const type = values.type.value;
        const mainType = type === "1" ? "CUSTOM" : "COMPANY";

        await apiDirectCall("/reports/putdashboard", "post", {
            companyId: data.companyId,
            type: mainType,
            name: values.name,
            gridLayoutDef: {
                widgetList:
                    data.localStateData?.currentDashboardDef?.widgetList || [],
                layouts: data.localStateData?.currentDashboardDef?.layouts || {}
            },
            isAdmin: true
        });
        queryClient.invalidateQueries(`/reports/listdashboards`);
        showMessage({
            message: tLocal("DashboardAdddedMessage"),
            snackBarOrign: { vertical: "bottom", horizontal: "right" },
            timeout: 1000 * 2,
            enabled: true
        });
        dispatch.dispatchGeneralPageInfo({
            payload: {
                editMode: false,
                newMode: false,
                currentDashboardStateBeforeEditOrNewDef: undefined,
                pageToRender: "myDashboards"
            },
            type: "update"
        });
        onClose();

        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };

    const handleSave = async () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const ready = readyTranslation && metadata.status === "success";
    const [formCompleo, finished] = useCompleoFormNew({
        t: tLocal,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && ready) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={tLocal("modalTitle")}
            open={open}
        >
            <DialogTitle>{tLocal("modalTitle")}</DialogTitle>
            <DialogContent dividers>{compRetorno}</DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {tLocal("buttonCloseModal")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                >
                    {tLocal("buttonSaveModal")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashboarNewSaveModal;
