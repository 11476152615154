import { Auth } from "aws-amplify";
import { navigate } from "@gatsbyjs/reach-router";
import { helperAmplifyMessage } from "functions/helperAmplifyMessages";
import { apiDirectCall } from "customHooks/useApi";
import { useAlertDialogFNType } from "customHooks/useDialog";

export const forgotPasswordFunction = (
    t: any,
    callDialog: (args: useAlertDialogFNType) => void
) => {
    return async (values: any) => {
        let retorno: { [k: string]: any } = {};
        try {
            const user = await Auth.forgotPassword(values.email);
            if (user) {
                navigate(`/newpassword/${values.email}`);
                retorno = { status: 200 };
            } else {
                retorno = { status: 400 };
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "email",
                        message: t("genericError")
                    }
                ];
            }
            return retorno;
        } catch (err) {
            try {
                const userData = await apiDirectCall(
                    "/user/userwithouttoken",
                    "post",
                    {
                        email: values.email
                    }
                );
                const returnData = userData?.data?.data;
                if (returnData === "NEWTOKENSENT") {
                    callDialog({
                        title: t("firstAccessTitle"),
                        bodyText: t("firstAccessMessage"),
                        agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                        agreeFunction: async () => {
                            navigate("/");
                        }
                    });
                    return { status: 200 };
                } else {
                    throw new Error("token not sent");
                }
            } catch (errUser) {
                retorno = { status: 400 };
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "email",
                        message: helperAmplifyMessage(err.code, t)
                    }
                ];
                return retorno;
            }
        }
    };
};
