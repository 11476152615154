let data: Compleo.IObject = {};

const MemoryCacheInternal = () => {
    const addOrEdit = (
        key: string,
        value: any,
        module: string = "general",
        secondsTTL: number = 60 * 60
    ) => {
        const currentModule: Compleo.IObject = data[module] || {};
        currentModule[key] = {
            value,
            secondsTTL,
            timestamp: new Date().getTime()
        };
        data[module] = currentModule;
    };

    const get = (key: string, module: string = "general") => {
        const currentTimeStamp = new Date().getTime();
        const objKey = (data[`${module}`] || {})[`${key}`] || {};
        if (objKey.timestamp !== undefined) {
            const secondsTTL = objKey.secondsTTL;
            const currentSecondsDiff =
                (currentTimeStamp - objKey.timestamp) / 1000;
            if (currentSecondsDiff > secondsTTL) {
                (data[`${module}`] || {})[`${key}`] = undefined;
            }
        }
        return ((data[`${module}`] || {})[`${key}`] || {}).value;
    };

    const clearAll = () => {
        data = {};
    };

    const clearModule = (module: string) => {
        data[module] = {};
    };

    return { addOrEdit, get, clearAll, clearModule };
};

export const MemoryCache = MemoryCacheInternal();
