import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

const NoAccessDashPage = () => {
    const [t, i18n, readyTranslation] = useTranslation("NOACCESSDASHPAGE", {
        useSuspense: false
    });

    if (readyTranslation) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                <Alert style={{ width: "100%" }}>
                    <AlertTitle>{t("msgTitle")}</AlertTitle>
                    {t("msgText")}
                </Alert>
            </Page>
        );
    } else {
        return (
            <Page>
                <Loading />
            </Page>
        );
    }
};

export default NoAccessDashPage;
