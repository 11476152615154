import React from "react";
import useList from "./index";
import ApplicantViewModalContainer from "Pages/Applicant/ApplicantView/ApplicantViewModalContainer";
import {
    ApplicantViewJobDataInsideJob,
    ApplicantViewModulesType
} from "Pages/Applicant/ApplicantView/useApplicantViewContext/ApplicantViewTypes";
import { apiDirectCall } from "customHooks/useApi/api";
import { useAuthState } from "_ReactContext/AuthContext";
interface IMainPage {
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    module: ApplicantViewModulesType;
}

export const ModalApplicantContainer = (props: IMainPage) => {
    const [listData, listDispatch] = useList();
    const { readyTranslation } = listData.tempInfo;
    const ready = readyTranslation;
    const { company } = useAuthState();
    const { filters } = listData.filtersInfo;
    const { setFilters, setLocalInfo } = listDispatch;
    const [
        externalDataLoadFinished,
        setExternalDataLoadFinished
    ] = React.useState(false);
    const [customCVsData, setCustomCVsData] = React.useState<Compleo.IObject[]>(
        []
    );

    const pageSize = filters.pagination.pageSize;
    const handlePageChange = (value: number) => {
        setFilters({
            ...filters,
            pagination: {
                currentPage: value,
                pageSize: pageSize
            },
            filterUpdated: true
        });
    };

    React.useEffect(() => {
        apiDirectCall("/customcv/getcustomcv", "post", {
            companyId: company.companyId,
            jobId: props.jobDataInsideJob?.jobId
        }).then((result: any) => {
            setCustomCVsData(result.data || []);
            setExternalDataLoadFinished(true);
        });
    }, [company, props.jobDataInsideJob]);

    if (ready && externalDataLoadFinished) {
        return (
            <ApplicantViewModalContainer
                onClose={() =>
                    listDispatch.setLocalInfo({
                        ...listData.localInfo,
                        modalApplicant: undefined
                    })
                }
                open={listData.localInfo?.modalApplicant?.open === true}
                navigationInfo={{
                    index:
                        listData.localInfo?.modalApplicant?.currentIndex || 0,
                    changePageFN: handlePageChange,
                    currentPage:
                        listData.filtersInfo?.filters?.pagination?.currentPage,
                    fieldArray: listData?.listReturnInfo?.fields,
                    totalPages: listData?.tempInfo?.totalPages
                }}
                jobDataInsideJob={props.jobDataInsideJob}
                loadingData={!listData.listReturnInfo.queryFinished}
                module={props.module}
                applicantIdFieldName={
                    listData.localInfo?.modalApplicant?.applicantIdFieldName
                }
                customCVsData={customCVsData}
            />
        );
    } else {
        return null;
    }
};

export default ModalApplicantContainer;
