import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Fields from "./Fields";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided
} from "react-beautiful-dnd";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import { useTranslation } from "react-i18next";

interface IProps {
    forms: Compleo.IObject[];
    fields: Compleo.IObject[];
    classError: any;
    t: any;
    field: FieldInputProps<any>;
    meta: FieldMetaProps<any>;
    helpers: FieldHelperProps<any>;
    tapplicant: any;
}

const FormData = (props: IProps) => {
    const {
        forms,
        classError,
        fields,
        t,
        field,
        meta,
        helpers,
        tapplicant
    } = props;

    return (
        <Droppable
            droppableId="mainDroppable"
            direction="vertical"
            type="forms"
        >
            {(provided: DroppableProvided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {forms.map((formGroup: any, index: number) => (
                        <React.Fragment key={formGroup.id}>
                            <Draggable
                                draggableId={formGroup.id}
                                index={index}
                                isDragDisabled={true}
                            >
                                {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                ) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                className={classError}
                                                primary={tapplicant(
                                                    `a_FormGroup_${formGroup.id}`
                                                )}
                                            />
                                        </ListItem>
                                        <Droppable
                                            droppableId={formGroup.id}
                                            direction="vertical"
                                            type="fields"
                                        >
                                            {(provided: DroppableProvided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={{
                                                        minHeight: "10px"
                                                    }}
                                                >
                                                    <Fields
                                                        classError={classError}
                                                        formId={formGroup.id}
                                                        listFields={fields}
                                                        t={t}
                                                        tapplicant={tapplicant}
                                                        handleEditStage={null}
                                                        handleDeleteStage={null}
                                                        fieldValue={field.value}
                                                        setFieldValue={
                                                            helpers.setValue
                                                        }
                                                    ></Fields>
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                )}
                            </Draggable>

                            <Divider />
                        </React.Fragment>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default FormData;
