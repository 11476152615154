import React from "react";
import {
    useGlobalAlertDialog,
    useAlertDialogFNType
} from "customHooks/useDialog";

type StateDialogType = {
    readyForm: boolean;
    callDialog: (args: useAlertDialogFNType) => void;
    agree: boolean | null;
    FormDialog: () => JSX.Element;
};

const GlobalDialogContext = React.createContext<StateDialogType>({
    readyForm: false,
    callDialog: () => {},
    agree: null,
    FormDialog: () => <></>
});

const useGlobalDialog = () => {
    const context = React.useContext(GlobalDialogContext);
    if (!context) {
        throw new Error(
            `useNotification must be used within a NotificationProvider`
        );
    }
    const { FormDialog, ...otherProps } = context;
    return { ...otherProps };
};

const useGlobalDialogForm = () => {
    const context = React.useContext(GlobalDialogContext);
    if (!context) {
        throw new Error(
            `useNotification must be used within a NotificationProvider`
        );
    }
    const { FormDialog } = context;
    return { FormDialog };
};

const DialogProvider = (props: any) => {
    const [readyForm, callDialog, agree, FormDialog] = useGlobalAlertDialog();

    return (
        <GlobalDialogContext.Provider
            value={{ readyForm, callDialog, agree, FormDialog }}
            {...props}
        />
    );
};

export { DialogProvider, useGlobalDialog, useGlobalDialogForm };
