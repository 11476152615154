import React from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import { useTranslation } from "react-i18next";
import ModalNewEmail from "Pages/Email/ModalNewEmail/ModalNewEmail";
import { useAuthState } from "_ReactContext/AuthContext";
import RefreshIcon from "@material-ui/icons/Refresh";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { useQueryClient } from "react-query";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
    Chip,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    Switch
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import WorkIcon from "@material-ui/icons/Work";
import ModalRating from "./ModalRating";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface IButtonAddRating {
    t: any;
    jobParamData?: Compleo.IObject;
}

const ButtonAddRating = (props: IButtonAddRating) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { applicantData } = data;
    const { t, jobParamData } = props;
    const [open, setOpen] = React.useState(false);

    const [modalRatingData, setModalRatingData] = React.useState<{
        open: boolean;
        jobData: Compleo.IObject | null;
    }>({ open: false, jobData: null });

    // const jobParamData: Compleo.IObject | undefined = (
    //     applicantData?.Jobs || []
    // ).filter((item: Compleo.IObject) => item.JobId === jobId)[0];

    const closeModalRating = () => {
        setModalRatingData({ open: false, jobData: null });
    };

    const openModalRating = (jobData: Compleo.IObject | null) => {
        setOpen(false);
        setModalRatingData({ open: true, jobData: jobData });
    };

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    React.useEffect(() => {
        if (data.openRatingOnStart) {
            if (jobParamData) {
                openModalRating(jobParamData);
            } else {
                handleToggle();
            }
        }
    }, [data.openRatingOnStart]);

    if (jobParamData) {
        return (
            <>
                <Button
                    id="mainAddRating"
                    variant="text"
                    startIcon={<Icon>star</Icon>}
                    color="inherit"
                    onClick={() => openModalRating(jobParamData)}
                    style={{
                        textTransform: "none"
                    }}
                >
                    <Typography noWrap>{t("Evaluate")}</Typography>
                </Button>
                {modalRatingData.open && (
                    <ModalRating
                        open={modalRatingData.open}
                        jobData={modalRatingData.jobData}
                        onClose={closeModalRating}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleToggle}
                    ref={anchorRef}
                    endIcon={<ArrowDropDownIcon />}
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                >
                    {t("buttonEvaluate")}
                </Button>
                <Menu
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    PaperProps={{
                        style: {
                            width: 370
                        }
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <MenuItem
                        onClick={() => openModalRating(null)}
                        button={true}
                    >
                        <ListItemText primary={t("generalRating")} />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {}} button={false}>
                        <ListItemText
                            primary={<strong>{t("selectAJob")}</strong>}
                        />
                    </MenuItem>
                    {(applicantData.Jobs || []).map(
                        (jobData: Compleo.IObject) => (
                            <MenuItem
                                key={jobData.JobId}
                                onClick={() => openModalRating(jobData)}
                                button={true}
                            >
                                <ListItemIcon>
                                    <WorkIcon />
                                </ListItemIcon>
                                {jobData.JobTitle}
                            </MenuItem>
                        )
                    )}
                </Menu>
                {modalRatingData.open && (
                    <ModalRating
                        open={modalRatingData.open}
                        jobData={modalRatingData.jobData}
                        onClose={closeModalRating}
                    />
                )}
            </>
        );
    }
};

export default ButtonAddRating;
