import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
// import LanguageIcon from "@material-ui/icons/Language";
import TranslateIcon from "@material-ui/icons/Translate";
import Logo from "assets/Logo";
import ChangeLanguage from "../ChangeLanguage";
import ChangeCompany from "../ChangeCompany";
import MenuCompleo, { verifyShowFullMenu } from "../MenuCompleo/MenuCompleo";
import Hidden from "@material-ui/core/Hidden";
import GlobalNotifications from "../GlobalNotifications";
import RenderMenu from "./RenderMenu";
import { AppBarStyles } from "./AppBarStyles";
import { TFunction, i18n } from "i18next";
import useSessionValues from "_ReactContext/SessionValues";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuModal from "Components/MenuCompleo/MenuModal";
import useGetShortcutAddItems from "./useGetShortcutAddItems";
import ModalNotification from "Pages/Notification/ModalNotification";
import useModalNotifications from "Pages/Notification/useModalNotifications";
import SearchArea from "./SearchArea";
import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import { useMediaQuery } from "@material-ui/core";
import theme from "theme/Theme";

const useStyles = AppBarStyles;

interface IProps {
    languageRendered: string;
    tAppBar: TFunction;
    tChangeLanguage: TFunction;
    // tNavigation: TFunction;
    i18n: i18n;
    container?: Element;
}

export default function PrimarySearchAppBar(props: IProps) {
    const classes = useStyles();
    const { company, user } = useAuthState();

    const { container } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [sessionValues, dispatchSessionData] = useSessionValues();
    const shortcutAddObj = useGetShortcutAddItems();
    const modalNotificationsObj = useModalNotifications();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);
    const navigation: any = queryNavigation.data;
    const onlyShowFullMenu = verifyShowFullMenu(navigation);
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { isFeatureAuthorized } = useAuthorization();
    const showSearch =
        isFeatureAuthorized("ViewJob") || isFeatureAuthorized("ViewApplicant");

    const [
        mobileMoreAnchorEl,
        setMobileMoreAnchorEl
    ] = React.useState<null | HTMLElement>(null);

    const t = props.tAppBar;
    const tChangeLanguage = props.tChangeLanguage;
    // const tNavigation = props.tNavigation;
    const i18n = props.i18n;

    const [
        menuElementLanguage,
        setMenuElementLanguage
    ] = React.useState<HTMLElement | null>(null);

    const [
        menuElementChangeCompany,
        setMenuElementChangeCompany
    ] = React.useState<HTMLElement | null>(null);

    const handleDrawerToggle = () => {
        if (!sessionValues.fullMenu) {
            dispatchSessionData({ menuWidth: 260, fullMenu: true });
        } else {
            if (onlyShowFullMenu) {
                dispatchSessionData({ menuWidth: 260, fullMenu: false });
            } else {
                dispatchSessionData({ menuWidth: 90, fullMenu: false });
            }
        }
    };

    React.useEffect(() => {
        if (onlyShowFullMenu) {
            handleDrawerToggle();
        }
    }, [onlyShowFullMenu]);

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElementLanguage(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setMenuElementLanguage(null);
    };

    const handleChangeCompanyMenuClose = () => {
        setMenuElementChangeCompany(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    // React.useEffect(() => {
    //     if (userCompany.companyId > 0) {
    //         dispatch(fetchNavigation(userEmail, userCompany.companyId));
    //     }
    // }, [userCompany.companyId]);

    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";

    return (
        <>
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        {(!onlyShowFullMenu || isMobile) && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Logo className={classes.logo} />
                        {/* <div className={classes.search}>
                            <InputBase
                                placeholder={t("search")}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                            />
                            <IconButton
                                type="submit"
                                className={classes.searchIcon}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                        </div> */}
                        {showSearch ? <SearchArea t={t} /> : null}
                        {shortcutAddObj.shortcutAddOptions.length ? (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={shortcutAddObj.openMenu}
                                className={classes.menuButton}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        ) : null}
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            {/* <IconButton
                                aria-label="show 4 new mails"
                                color="inherit"
                            >
                                <Badge badgeContent={4} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton> */}
                            <IconButton
                                aria-label={`exibir ${
                                    modalNotificationsObj.total || 0
                                } novas notificações`}
                                color="inherit"
                                onClick={modalNotificationsObj.openMenu}
                            >
                                <Badge
                                    badgeContent={modalNotificationsObj.total}
                                    color="secondary"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-label="change language"
                                color="inherit"
                                onClick={handleLanguageMenuOpen}
                            >
                                <TranslateIcon />
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <RenderMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    mobileMoreAnchorEl={mobileMoreAnchorEl}
                    setMobileMoreAnchorEl={setMobileMoreAnchorEl}
                    setMenuElementChangeCompany={setMenuElementChangeCompany}
                    mobile={false}
                    menuId={menuId}
                    handleLanguageMenuOpen={handleLanguageMenuOpen}
                    handleProfileMenuOpen={handleProfileMenuOpen}
                    t={t}
                    modalNotificationsObj={modalNotificationsObj}
                />
                <RenderMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    mobileMoreAnchorEl={mobileMoreAnchorEl}
                    setMobileMoreAnchorEl={setMobileMoreAnchorEl}
                    setMenuElementChangeCompany={setMenuElementChangeCompany}
                    mobile={true}
                    menuId={mobileMenuId}
                    handleLanguageMenuOpen={handleLanguageMenuOpen}
                    handleProfileMenuOpen={handleProfileMenuOpen}
                    t={t}
                    modalNotificationsObj={modalNotificationsObj}
                />
                <ChangeLanguage
                    anchorEl={menuElementLanguage}
                    closeMenu={handleLanguageMenuClose}
                    languageRendered={props.languageRendered}
                    i18n={i18n}
                    t={tChangeLanguage}
                />
                <ChangeCompany
                    anchorEl={menuElementChangeCompany}
                    closeMenu={handleChangeCompanyMenuClose}
                />
                <MenuModal
                    anchorEl={shortcutAddObj.anchorEl}
                    options={shortcutAddObj.shortcutAddOptions}
                    setAnchorEl={shortcutAddObj.setAnchorEl}
                    showIcon={false}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                />
                <ModalNotification
                    anchorEl={modalNotificationsObj.anchorEl}
                    options={modalNotificationsObj.options}
                    setAnchorEl={modalNotificationsObj.setAnchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                />

                <GlobalNotifications />
            </div>
            <MenuCompleo
                handleDrawerToggle={handleDrawerToggle}
                fullMenu={sessionValues.fullMenu}
            />
        </>
    );
}
