import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Divider, List } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import TranslationGrid from "./TranslationGrid";
import TranslationFieldsAndMessages from "./TranslationFieldsAndMessages";
import { getTranslationFN } from "../useHandleInitialValues";
import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        rootList: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        textField: {
            margin: theme.spacing(1)
        },
        gridContainer: {},
        gridDescription: {
            padding: "1px"
        },
        gridField: {
            padding: "1px"
        },
        divider: {
            backgroundColor: theme.palette.error.main,
            height: "1px"
        }
    })
);

interface IProps {
    metadata: any;
    t: any;
    translations: Compleo.IObject;
    messages: any;
    defaultLanguageCareers: string;
    formDataFieldName?: string;
}

interface ITranslationProps {
    languageObj: Compleo.IObject;
}

const TranslationsDetail = (props: IProps & ITranslationProps) => {
    const classes = useStyles();
    const {
        metadata,
        languageObj,
        t: tField,
        translations,
        messages,
        defaultLanguageCareers,
        formDataFieldName
    } = props;
    const language = languageObj.value;

    const t = getTranslationFN(
        translations[language],
        translations[defaultLanguageCareers]
    );

    const fieldName = metadata?.fieldName; // metadata?.fieldName);
    const { getFieldValue } = useRhfFieldWithoutControllerOrRegister(
        formDataFieldName || "formData"
    );
    const fieldFormDataValue = getFieldValue();

    const forms = fieldFormDataValue?.forms;
    const fields = fieldFormDataValue?.fields;
    const ready = forms && fields;

    if (ready) {
        return (
            <List component="nav" className={classes.rootList}>
                {forms.map((item: any) => {
                    return (
                        <React.Fragment key={item.id}>
                            <TranslationGrid
                                language={language}
                                localFieldName={`a_FormGroup_${item.id}`}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(`a_FormGroup_${item.id}`)}
                                type={tField("formGroup")}
                            />
                            <Divider />
                            <TranslationGrid
                                language={language}
                                localFieldName={`a_FormGroup_${item.id}_help`}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(`a_FormGroup_${item.id}`)}
                                type={tField("formGroupHelp")}
                            />
                            <Divider />
                            <TranslationFieldsAndMessages
                                fieldName={fieldName}
                                fields={fields}
                                formGroup={item}
                                language={language}
                                messages={messages}
                                tField={tField}
                                t={t}
                            />
                        </React.Fragment>
                    );
                })}
            </List>
        );
    } else {
        return <Loading />;
    }
};

export default TranslationsDetail;
