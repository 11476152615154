import { Box, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useCompleoView from "customHooks/useCompleoView";
import useJobContext from "../useJobContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { useApi } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useJobAccess from "customHooks/useAccess/useJobAccess";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main
            // width: "100%"
        }
    })
);

const JobViewDataMain = (props: any) => {
    const classes = useStyles();
    const { company, timeZone } = useAuthState();
    const jobAccess = useJobAccess();

    const [data] = useJobContext();
    const { language, t } = data;
    const moduleName = data.module;
    const [metadata] = useGetMetadata(moduleName);
    const postAddress = `/job/editview`;
    const [addJobReturn, addJob] = useApi(postAddress, "post");

    const { formRetorno, finished } = useCompleoView({
        language: language,
        t: t,
        metadataReturn: metadata,
        ready: data.Job.ready,
        valuesFromSource: { ...data.Job.data },
        timeZone: timeZone,
        editParams: {
            moduleEditName: data.module,
            postFunction: addJob,
            postReturn: addJobReturn,
            keyData: { id: data.JobId, companyId: company.companyId },
            useQueryKeyInvalidate: [data.apiKeyJob],
            customLists: data.EditJobListDefinition
        },
        readOnly: !jobAccess.allowEdit
    });

    return <Box className={classes.container}>{formRetorno}</Box>;
};

export default JobViewDataMain;
