import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";

export type ListDefinitionType = {
    fieldName: string;
    data: any[];
    valueName: string;
    labelName: string;
    childrenFilterFieldName?: string;
    parentFilterField?: {
        parentFieldName: string;
        parentIdFieldFilter: string;
        fieldFilter: string;
    };
};

const useCustomLists: (
    t: any,
    readyTranslation: boolean
) => [ListDefinitionType[], boolean] = (t: any, readyTranslation: boolean) => {
    const { company } = useAuthState();
    const plan = company.plan || "noplan";
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    // let listDefinition: ListDefinitionType[] = [];
    const [listDefinition, setListDefinition] = React.useState<
        ListDefinitionType[]
    >([]);

    const [roles, callRoles] = useApiCache(
        "/user/rolelistdropdown",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    if (roles.status === "success" && !ready && readyTranslation) {
        const data = processDataRoles(roles.response.data, t, plan);
        listDefinition.push({
            fieldName: "role",
            data: data,
            valueName: "value",
            labelName: "label"
        });
        // setListDefinition(listDefinition)l
        setReady(true);
    }

    return [listDefinition, ready];
};

const processDataRoles = (data: Compleo.IObject, t: any, plan: string) => {
    const rolesCompany = data.rolesCompany || [];
    const rolesCompleo =
        data.rolesCompleo[0].PlansAndRoles[plan] ||
        data.rolesCompleo[0].PlansAndRoles.default;
    const dataReturn: Compleo.IObject[] = [];
    rolesCompleo.roles.map((roleC: any) => {
        dataReturn.push({
            value: roleC.role,
            label: `${t(`ROLE_${roleC.role}`)} - ${t(
                `ROLE_compleoPatternKeyword`
            )}`,
            type: "Compleo"
        });
    });

    rolesCompany.map((roleC: any) => {
        dataReturn.push({
            value: roleC.skGS1pk,
            label: `${roleC.name} - ${t(`ROLE_customkeyword`)}`,
            roleName: roleC.name,
            type: "Company"
        });
    });

    return dataReturn;
};

export default useCustomLists;
