import React, { useReducer } from "react";
// import { useField, useFormikContext } from "formik";
import MainEmailData from "./MainEmailData";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Grid } from "@material-ui/core";
import ButtonAddNewLanguage from "./ButtonAddNewLanguage";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useEmailTemplate, { useEmailTemplateType } from "./useEmailTemplate";
import ButtonLoadTemplate from "./ButtonLoadTemplate";
import _ from "lodash";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import useHandleInitialIdsEmailData from "./useHandleInitialIdsEmailData";

export interface ITemplateEmailData {
    id: string;
    label: string;
    items: { default: boolean; id: string; language: string }[];
}
interface IProps {
    type?: string;
}

const reducerIdLoaded = (state: string[], id: string) => {
    state.push(id);
    return state;
};

const EmailDataField = (props: IProps & IInputProps) => {
    const {
        metadata,
        t,
        classes,
        required,
        name,
        type: typeFromProps,
        helperTextDefault
    } = props;

    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const [idListLoadedFromDB, dispatchNewIdLoadedFromDB] = useReducer(
        reducerIdLoaded,
        []
    );
    const companyData = useAuthState();
    // const { errors, touched } = useFormikContext();

    // const [field, meta, helpers] = useField(props.name);
    const {
        field,
        setFieldValue,
        formState,
        reset,
        getValues
    } = useRhfFieldControlled(props.name, helperTextDefault);
    const fieldValue = field.value; // field.value;

    const localSetFieldValue = (value: any) => {
        reset({ ...getValues(), [props.name]: value });
    };
    const loadTemplates = metadata?.elementConfig?.loadTemplates !== false;
    const multipleLanguages =
        metadata?.elementConfig?.multipleLanguages !== false;

    const type = typeFromProps || metadata?.elementConfig?.type;
    const hideSubject = metadata?.elementConfig?.hideSubject === true;

    const emailTemplateData = useEmailTemplate(type);
    const {
        placeholdersBodyList,
        placeholdersSubjectList,
        placeholdersBodyRequiredList,
        ready: readyEmailTemplate
    } = emailTemplateData;

    const initialIds = useHandleInitialIdsEmailData(fieldValue);

    const updateFieldsFromDB = async (
        idList: string[],
        emailData: useEmailTemplateType
    ) => {
        const fieldToUpdate: Compleo.IObject[] = [];
        for (const idToLoad of idList) {
            if (idToLoad && !idListLoadedFromDB.includes(idToLoad)) {
                const currentFieldValue =
                    fieldValue.filter((item: any) => item.id === idToLoad)[0] ||
                    {};

                if (
                    !currentFieldValue?._tempEmailData?.subject &&
                    !currentFieldValue?._tempEmailData?.body
                ) {
                    const dataReturn = await apiDirectCall(
                        "/email/getemail",
                        "post",
                        {
                            id: idToLoad,
                            companyId: companyData.company.companyId
                        }
                    );

                    const mainData = dataReturn?.data?.data;
                    const body = mainData?.body;
                    const subject = mainData?.subject;
                    const attachments = mainData?.attachments;

                    fieldToUpdate.push({
                        ...currentFieldValue,
                        _tempEmailData: {
                            body: body,
                            subject: subject,
                            attachments: attachments,
                            attachments_filesDefinition: {
                                values: [],
                                multiple: true
                            },
                            arrayPlaceHolderSubject: emailData.placeholdersSubjectList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBody: emailData.placeholdersBodyList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBodyRequired: emailData.placeholdersBodyRequiredList.map(
                                (item) => item.value
                            )
                        }
                    });
                }
                dispatchNewIdLoadedFromDB(idToLoad);
            }
        }
        return fieldToUpdate;
    };

    React.useEffect(() => {
        if (readyEmailTemplate) {
            updateFieldsFromDB(initialIds, emailTemplateData).then(
                (fieldToUpdate: Compleo.IObject[]) => {
                    if (fieldToUpdate.length) {
                        // setFieldValue(fieldToUpdate);
                        // reset({ ...getValues(), [props.name]: fieldToUpdate });
                        localSetFieldValue(fieldToUpdate);
                    }
                }
            );
        }
    }, [initialIds, readyEmailTemplate]);

    React.useEffect(() => {
        // Load placeholders for new items
        const currentValue = fieldValue || [];
        if (readyEmailTemplate && currentValue.length) {
            let updated = false;
            for (let index = 0; index < currentValue.length; index++) {
                if (!currentValue[index].id) {
                    currentValue[index] = {
                        ...currentValue[index],
                        _tempEmailData: {
                            ...currentValue[index]._tempEmailData,
                            arrayPlaceHolderSubject: emailTemplateData.placeholdersSubjectList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBody: emailTemplateData.placeholdersBodyList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBodyRequired: emailTemplateData.placeholdersBodyRequiredList.map(
                                (item) => item.value
                            )
                        }
                    };
                    updated = true;
                }
            }
            if (updated) {
                // setFieldValue(currentValue);
                localSetFieldValue(currentValue);
            }
        }
    }, [(fieldValue || []).length, readyEmailTemplate]);

    React.useEffect(() => {
        const mainData = fieldValue || [];
        if (mainData.length && !mainData[0].language) {
            mainData[0].language = defaultLanguage;
        }
        localSetFieldValue(mainData);
    }, [defaultLanguage]);

    const ready = readyLanguages && readyEmailTemplate;

    const tabs: TabTypeCompleo[] = [];
    if (ready) {
        let forceTabFocus = false;
        (fieldValue || []).map((item: any, index: number) => {
            const labelTab = item.default
                ? `${t(`Language_${item.language}`)} - ${t(
                      "EMAILDATAFIELD_default"
                  )}`
                : t(`Language_${item.language}`);

            // if (formState.errors) {
            //     debugger;
            //     console.log(props.name);
            //     console.log(formState.errors?.[props.name]);
            // }
            const tabHasError = verifyTabHasError(
                formState.errors?.[props.name],
                formState.isSubmitted,
                index
            );
            if (tabHasError && !forceTabFocus) {
                forceTabFocus = true;
            }

            const TabLanguage = (
                <MainEmailData
                    {...props}
                    arrayNumber={index}
                    placeholdersSubject={placeholdersSubjectList}
                    placeholders={placeholdersBodyList}
                    idListLoadedFromDB={idListLoadedFromDB}
                    language={item.language}
                    hideSubject={hideSubject}
                    metadata={props.metadata || {}}
                />
            );
            tabs.push({
                label: labelTab,
                Component: TabLanguage,
                hasError: tabHasError,
                forceFocus: forceTabFocus
            });
            forceTabFocus = false;
        });
    }

    const oneLanguage = (languages || []).length < 2;
    if (ready) {
        return (
            <Grid container>
                {oneLanguage || !multipleLanguages ? (
                    <>
                        {loadTemplates && (
                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                <ButtonLoadTemplate
                                    fieldValue={fieldValue}
                                    fieldName={props.name}
                                    setFieldValue={setFieldValue}
                                    t={t}
                                    type={type}
                                    buttonPositionEnd={false}
                                    arrayPlaceHolderBody={placeholdersBodyList.map(
                                        (item) => item.value
                                    )}
                                    arrayPlaceHolderSubject={placeholdersSubjectList.map(
                                        (item) => item.value
                                    )}
                                    splitTemplatesByLanguage={true}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid container item xs={12}>
                        <Grid container item xs={12} sm={6}>
                            <ButtonAddNewLanguage
                                fieldValue={fieldValue}
                                setFieldValue={setFieldValue}
                                t={t}
                            />
                        </Grid>
                        {loadTemplates && (
                            <Grid container item xs={12} sm={6}>
                                <ButtonLoadTemplate
                                    fieldName={props.name}
                                    fieldValue={fieldValue}
                                    setFieldValue={setFieldValue}
                                    t={t}
                                    type={type}
                                    arrayPlaceHolderBody={placeholdersBodyList.map(
                                        (item) => item.value
                                    )}
                                    arrayPlaceHolderSubject={placeholdersSubjectList.map(
                                        (item) => item.value
                                    )}
                                    splitTemplatesByLanguage={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    {oneLanguage || !multipleLanguages ? (
                        tabs[0]?.Component || null
                    ) : (
                        <CompleoTabs t={t} tabs={tabs} />
                    )}
                </Grid>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const verifyTabHasError = (
    errors: any,
    isSubmitted: boolean,
    index: number
) => {
    const fieldsToVerify = ["_tempEmailData.body", "_tempEmailData.subject"];
    const fieldObjErros = (errors || [])[index] || null;
    // const fieldObjTouched = (touched || [])[index] || null;
    let hasError = false;
    if (fieldObjErros && isSubmitted) {
        fieldsToVerify.forEach((field) => {
            if (_.get(fieldObjErros, field)) {
                hasError = true;
            }
        });
    }
    return hasError;
};

export default EmailDataField;
