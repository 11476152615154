import {
    Box,
    Collapse,
    createStyles,
    Theme,
    Typography
} from "@material-ui/core";
import React from "react";
import useList from "customHooks/useList";
import { getOrderedObjectNamesByLocal } from "customHooks/useList/ListHelpers/ListUtil";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFields from "./TableFields";
import OtherActionsJob from "../_componentsJob/OtherActions";
import StatusActionsJob from "../_componentsJob/StatusActions";
import StatusActionsJobApplicant from "../_componentsJobApplicant/StatusActions";
import OtherActionsActivities from "../_componentsActivities/OtherActions";

import OtherActionsApplicant from "../_componentsApplicant/OtherActions";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    }
});

interface IProps {
    maxItemsPrimary: number;
    maxItemsSecondary: number;
    orderedFields: any[];
    orderedFieldsSecondary: any[];
    tableFields: any;
    fieldData: any;
    t: any;
}

const TableResultsRow = (props: IProps) => {
    const {
        maxItemsPrimary,
        maxItemsSecondary,
        orderedFields,
        orderedFieldsSecondary,
        tableFields,
        fieldData,
        t
    } = props;
    const [listData] = useList();
    const [otherFieldsOpen, setOtherFieldsOpen] = React.useState(false);
    const classes = useRowStyles();
    const showMoreButton = orderedFieldsSecondary.length > 0;
    const localArray = orderedFields.slice(0, maxItemsPrimary);
    const showActionsLine =
        orderedFieldsSecondary.length > 0 ||
        ModulesWithTableActionsList.includes(
            listData.tempInfo.moduleName as ModulesWithTableActions
        );

    return (
        <>
            <TableRow className={classes.root}>
                {showActionsLine && (
                    <TableCell style={{ width: "135px" }}>
                        <Typography noWrap>
                            {showMoreButton && (
                                <ButtonIconTooltipCompleo
                                    label="Edit"
                                    onClick={() =>
                                        setOtherFieldsOpen(!otherFieldsOpen)
                                    }
                                >
                                    {otherFieldsOpen ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )}
                                </ButtonIconTooltipCompleo>
                            )}
                            <TableActionsItem
                                fieldData={fieldData}
                                moduleName={listData.tempInfo.moduleName}
                            />
                            {/* <OtherActions fieldData={job} hideViewIcon={true} /> */}
                            {/* <StatusActions fieldData={job} showInDialog={true} /> */}
                        </Typography>
                    </TableCell>
                )}
                {localArray.map((item) => {
                    return (
                        <TableFields
                            key={item}
                            fieldData={fieldData}
                            fieldName={item}
                            type={tableFields[item]?.type}
                        />
                    );
                })}
                <TableCell></TableCell>
            </TableRow>
            <SubTable
                orderedFields={orderedFieldsSecondary}
                totalItemsPrimary={localArray.length}
                maxItemsSecondary={maxItemsSecondary}
                fieldData={fieldData}
                tableFields={tableFields}
                open={otherFieldsOpen}
                t={t}
            />
        </>
    );
};
interface IPropsSubTable {
    orderedFields: any[];
    totalItemsPrimary: number;
    maxItemsSecondary: number;
    fieldData: any;
    tableFields: any;
    open: boolean;
    t: any;
}
const SubTable = (props: IPropsSubTable) => {
    const {
        orderedFields,
        totalItemsPrimary,
        maxItemsSecondary,
        fieldData,
        tableFields,
        open,
        t
    } = props;

    const newArray = orderedFields.slice(0, maxItemsSecondary);
    return (
        <TableRow>
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={totalItemsPrimary + 1}
            >
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                        >
                            {t("ELASTICLISTSETTINGS:ResultsTableOtherFields")}
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    {newArray.map((item) => (
                                        <TableCell
                                            key={item}
                                            style={{ fontWeight: 600 }}
                                        >
                                            {t(item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {newArray.map((item) => {
                                        return (
                                            <TableFields
                                                key={item}
                                                fieldData={fieldData}
                                                fieldName={item}
                                                type={tableFields[item]?.type}
                                            />
                                        );
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

interface ITableActions {
    fieldData: Compleo.IObject;
    moduleName: string;
}

export type ModulesWithTableActions =
    | "APPLICANTLIST"
    | "JOBAPPLICANTLIST"
    | "JOBLIST"
    | "JOBAPPLICANTLISTJOBVIEW"
    | "REPORTSACTIVITIES";

export const ModulesWithTableActionsList: ModulesWithTableActions[] = [
    "APPLICANTLIST",
    "JOBAPPLICANTLIST",
    "JOBLIST",
    "JOBAPPLICANTLISTJOBVIEW",
    "REPORTSACTIVITIES"
];

const TableActionsItem = (props: ITableActions) => {
    const { fieldData, moduleName } = props;
    const [listData] = useList();
    const moduleNameLocal: ModulesWithTableActions | null = ModulesWithTableActionsList.includes(
        moduleName as ModulesWithTableActions
    )
        ? (moduleName as ModulesWithTableActions)
        : null;

    switch (moduleNameLocal) {
        case "APPLICANTLIST":
        case "JOBAPPLICANTLIST":
            return (
                <>
                    <OtherActionsApplicant
                        fieldData={fieldData}
                        hideViewIcon={false}
                    />
                </>
            );

        case "JOBLIST":
            return (
                <>
                    <OtherActionsJob
                        fieldData={fieldData}
                        hideViewIcon={true}
                    />
                    <StatusActionsJob
                        fieldData={fieldData}
                        showInDialog={true}
                        t={listData.tempInfo.t}
                        useApiKey={listData.tempInfo.useApiKey}
                    />
                </>
            );
        case "JOBAPPLICANTLISTJOBVIEW":
            return (
                <>
                    <StatusActionsJobApplicant
                        fieldData={fieldData}
                        showInDialog={true}
                    />
                </>
            );
        case "REPORTSACTIVITIES":
            return (
                <>
                    <OtherActionsActivities
                        fieldData={fieldData}
                        // showInDialog={true}
                    />
                </>
            );
        default:
            return null;
    }
};
export default TableResultsRow;
