import React from "react";
import ItemCard from "./ItemCard";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import useJobContext from "Pages/Job/JobView/useJobContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        chipTable: {
            margin: theme.spacing(0.5)
        }
    })
);

const FieldJobEvaluation = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType } = props;
    const classes = useStyles();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const defFieldInfo = cardFields[fieldName];
    const [data] = useJobContext();

    const ratingValue = (fieldData[fieldName] || []).filter(
        (item: Compleo.IObject) => item.jobId === data.JobId
    )[0];

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    // smSize={12}
                    label={
                        defFieldInfo.hideLabelCard ? undefined : t(fieldName)
                    }
                    removeColonAfterLabel={defFieldInfo.hideLabelCard}
                >
                    <CompleoCompanyRating
                        evaluationValue={ratingValue?.value || 0}
                        size="small"
                        showTextValue
                    />
                </ItemCard>
            );

        case "table":
            return (
                <>
                    <CompleoCompanyRating
                        evaluationValue={ratingValue?.value || 0}
                        size="small"
                        showTextValue
                    />
                </>
            );
    }
};

export default FieldJobEvaluation;
