import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import TranslationsDetail from "./TranslationsDetail";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1)
    }
}));

interface IProps {
    languages: Compleo.IObject[];
    currentLanguage: string;
    defaultLanguage: string;
    fieldQuestionsName?: string;
    fieldTranslationsName?: string;
    t: any;
}

const Translation = (props: IProps) => {
    const classes = useStyles();
    const {
        languages,
        fieldQuestionsName,
        fieldTranslationsName,
        currentLanguage,
        defaultLanguage,
        t
    } = props;

    const filteredLanguages = languages.filter(
        (l: any) => l.value !== defaultLanguage
    );
    const ready = filteredLanguages && filteredLanguages.length > 0;

    if (ready) {
        const tabs: TabTypeCompleo[] = [];
        languages.map((item: any, index: number) => {
            const TabComponent = (
                <TranslationsDetail
                    languageObj={item}
                    fieldQuestionsName={fieldQuestionsName}
                    fieldTranslationsName={fieldTranslationsName}
                    t={t}
                />
            );
            tabs.push({
                label: item[`label-${currentLanguage}`],
                Component: TabComponent
            });
        });
        return (
            <CompleoTabs
                t={t}
                tabs={tabs}
                interfaceProps={{
                    AppBarOtherProps: { className: classes.root }
                }}
            />
        );
    } else {
        return <Loading />;
    }
};

export default Translation;
