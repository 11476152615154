import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IImageDefinitionMetadata } from "./types";
import { TFunction } from "i18next";
import Webcam from "react-webcam";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
// src={(upImg || {}).src}

interface IProps {
    modalIsOpen: boolean;
    handleModalClose: any;
    onLoad: any;
    t: TFunction;
    setCrop: any;
    setCompletedCrop: any;
    crop: ReactCrop.Crop;
    srcImage: string;
    imageDefinition: IImageDefinitionMetadata;
    enableCamera: boolean;
    labelImage: string;
    setUpImage: any;
}

const ModalCrop = (props: IProps) => {
    const {
        imageDefinition,
        handleModalClose,
        modalIsOpen,
        srcImage,
        onLoad,
        crop,
        setCrop,
        setCompletedCrop,
        enableCamera,
        labelImage,
        setUpImage,
        t
    } = props;

    const webcamRef = React.useRef(null);
    const [pictureFinished, setPictureFinished] = React.useState(false);
    // const [imgSrc, setImgSrc] = React.useState<undefined | string>(undefined);
    const capture = React.useCallback(() => {
        const webCamObj: any = webcamRef.current;
        if (webCamObj !== null) {
            const imageSrc = webCamObj.getScreenshot();
            setUpImage({ src: imageSrc });
            // setImgSrc(imageSrc);
            setPictureFinished(true);
        }
    }, [webcamRef, setUpImage]);

    const closeModal = () => {
        // setImgSrc(undefined);
        setPictureFinished(false);
        handleModalClose();
    };
    const srcImageLocal = srcImage;

    if (enableCamera && !pictureFinished) {
        return (
            <Dialog
                open={modalIsOpen}
                onClose={handleModalClose}
                maxWidth="xs"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("ImageField_ModalTitle")}
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: `${
                                imageDefinition.cameraFrameWidth || 350
                            }px`
                        }}
                    >
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            height={imageDefinition.cameraFrameHeight || 350}
                            width={imageDefinition.cameraFrameWidth || 350}
                        />
                    </div>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<PhotoCameraIcon />}
                        onClick={capture}
                        color="secondary"
                        autoFocus
                    >
                        {t("ImageField_takePictureButton")}
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        {t("ImageField_ButtonFinished")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else
        return (
            <Dialog
                open={modalIsOpen}
                onClose={handleModalClose}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("ImageField_ModalTitle")}
                </DialogTitle>
                <DialogContent>
                    <ReactCrop
                        src={srcImageLocal}
                        onImageLoaded={onLoad}
                        crop={crop}
                        minWidth={imageDefinition.minWidth}
                        minHeight={imageDefinition.minHeight}
                        maxWidth={imageDefinition.maxWidth}
                        maxHeight={imageDefinition.maxHeight}
                        locked={imageDefinition.locked}
                        ruleOfThirds={imageDefinition.ruleOfThirds}
                        circularCrop={imageDefinition.circularCrop}
                        onChange={(c) => setCrop({ ...crop, ...c })}
                        onComplete={(c) => setCompletedCrop({ ...crop, ...c })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" autoFocus>
                        {t("ImageField_ButtonFinished")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
};

export default ModalCrop;
