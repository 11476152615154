import {
    PageToRenderList,
    PageToRenderType
} from "./useJobContext/JobViewTypes";

const pageToRenderKey = "jobViewLastPageToRender";
export const defaultPageToRender: PageToRenderType = "viewData";

export const getLastPageToRender = () => {
    const dataFromStorage = localStorage.getItem(pageToRenderKey);

    if (
        dataFromStorage &&
        PageToRenderList.includes(dataFromStorage as PageToRenderType)
    ) {
        const returnData = dataFromStorage as PageToRenderType;
        return returnData;
    }
    return defaultPageToRender;
};

export const setLastPageToRender = (pageToRender: PageToRenderType) => {
    if (pageToRender && PageToRenderList.includes(pageToRender)) {
        localStorage.setItem(pageToRenderKey, pageToRender);
    }
};
