import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import useJobContext from "../../useJobContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: 0
        },
        daysInStageText: {
            marginLeft: theme.spacing(0.5),
            fontSize: 14
        }
    })
);

interface IMainInfoProps {
    daysInStage: string;
    applicantLongTimeInStage: boolean;
    maxDaysInStage?: number;
}

const JobKanbanApplicantMainInfo = (props: IMainInfoProps) => {
    const classes = useStyles();
    const [data] = useJobContext();

    const { t } = data;
    const { daysInStage, applicantLongTimeInStage, maxDaysInStage } = props;
    const showMaxMessage =
        applicantLongTimeInStage && maxDaysInStage !== undefined;
    const displayText =
        maxDaysInStage === undefined
            ? t("KanbanCard_DaysInStage")
            : t("KanbanCard_DaysInStageMax", { max: maxDaysInStage });

    return (
        <Grid container className={classes.root}>
            <Grid container item>
                <Tooltip title={displayText}>
                    <AccessTimeIcon
                        fontSize="small"
                        color={showMaxMessage ? "secondary" : undefined}
                    />
                </Tooltip>
                <Typography className={classes.daysInStageText}>
                    {daysInStage}d
                </Typography>
            </Grid>
        </Grid>
    );
};

export default JobKanbanApplicantMainInfo;
