import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormMain from "./FormMain";
import { PropsParent, typeForm } from "./FormMain";

const useStyles = makeStyles((theme: any) => ({
    paper: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(6),
        width: "100%",
        marginTop: theme.spacing(4)
    },
    formcontainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    button: {
        marginTop: theme.spacing(4)
    },
    gridContainer: {
        marginTop: theme.spacing(3)
    }
}));

const FormExternal = (props: PropsParent) => {
    const classes = useStyles();
    const formType: typeForm = "EXTERNAL";

    return (
        <FormMain parentProps={props} classes={classes} formType={formType} />
    );
};

export default FormExternal;
