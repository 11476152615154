import { apiDirectCall } from "customHooks/useApi";
import * as ReactDeviceDetect from "react-device-detect";

export const sendErrorToDB = async (
    errorObject: CompleoShared.ErrorLog.IErrorObject
) => {
    const apiAddress = errorObject.userEmail
        ? "/log/loginternal"
        : "/log/logpublic";

    const dataAPI = await apiDirectCall(apiAddress, "post", {
        ...errorObject
    });
    const errorId = dataAPI?.data?.errorId;
    console.log("api call", errorId);
    console.log("api call", JSON.stringify(errorObject, null, 4));
    return errorId;
};

export const getErrorObject = (
    sourceError: CompleoShared.ErrorLog.sourceErrorType,
    error: any,
    stack?: any,
    line?: number,
    file?: string,
    column?: number,
    message?: string | Event,
    companyId?: number,
    companyName?: string,
    userEmail?: string,
    sentryEventId?: string
) => {
    const finalMessage = typeof message === "string" ? message : undefined;
    const errorObject: CompleoShared.ErrorLog.IErrorObject = {
        companyId: companyId,
        companyName: companyName || "",
        dateTime: new Date().toISOString(),
        errorMessage: finalMessage || error?.message,
        fullErrorObject: JSON.stringify(
            error,
            Object.getOwnPropertyNames(error),
            4
        ),
        stack: JSON.stringify(stack, null, 4),
        browserName: ReactDeviceDetect.browserName,
        browserVersion: ReactDeviceDetect.browserVersion,
        osName: ReactDeviceDetect.osName,
        osVersion: ReactDeviceDetect.osVersion,
        engineName: ReactDeviceDetect.engineName,
        engineVersion: ReactDeviceDetect.engineVersion,
        fullBrowserVersion: ReactDeviceDetect.fullBrowserVersion,
        userEmail: userEmail || "",
        path: window.location.href,
        line: line,
        file: file,
        column: column,
        sourceError: sourceError,
        sentryEventId: sentryEventId
    };
    return errorObject;
};
