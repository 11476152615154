import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
// import useList from "../..";
import SettingsModalCardFieldsList from "./SettingsModalCardKanbanFieldsList";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useGetMetadata from "customHooks/useGetMetadata";
import { getCustomSearchOrderedLocal } from "customHooks/useList/ListHelpers/ListUtil";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useJobViewContext from "../../useJobContext";
// import {
//     getCustomSearchOrdered,
//     getCustomSearchOrderedLocal,
//     getOrderedObjectNamesByLocal
// } from "../ListUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

export type KanbanCardSizeType = "small" | "medium" | "big";

export interface IKanbanCardFieldState {
    cardSize: KanbanCardSizeType;
    showPhoto: boolean;
    fieldsDefinition: Compleo.IObject[];
}
export const KanbanCardSessionName = `cardKanbanApplicantsFieldsOrder`;
interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const SettingsModalCardKanbanFields = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const [data] = useJobViewContext();

    const orderData: IKanbanCardFieldState | undefined =
        globalValues.userdb[KanbanCardSessionName];

    const metadata = data.kanbanApplicantListMetadata;
    const [t, i18n, readyTranslation] = data.tResponseListFilterKanban;
    const { otherSettings, cardKanbanFields } =
        metadata.response?.data?.ElasticSearchListDefinition || {};

    const fields = cardKanbanFields;
    const fieldsDetails = getCustomSearchOrderedLocal(
        fields,
        orderData?.fieldsDefinition || []
    );

    const [filterData, setFilterData] = React.useState<IKanbanCardFieldState>({
        cardSize: "small",
        showPhoto: true,
        fieldsDefinition: fieldsDetails
    });

    const kanbanSmallCardItems = otherSettings?.kanbanSmallCardItems || 1;
    const kanbanMediumCardItems = otherSettings?.kanbanMediumCardItems || 2;
    const kanbanBigCardItems = otherSettings?.kanbanBigCardItems || 3;

    // React.useEffect(() => {
    //     if (!Object.keys(filterData).length) {
    //         setFilterData(fieldsDetails);
    //     }
    // }, [fieldsDetails]);

    React.useEffect(() => {
        if (!filterData.fieldsDefinition.length && fieldsDetails.length) {
            setFilterData({
                cardSize: orderData?.cardSize || "small",
                showPhoto: orderData?.showPhoto !== false,
                fieldsDefinition: fieldsDetails
            });
        }
    }, [fieldsDetails]);

    React.useEffect(() => {
        setFilterData({
            cardSize: orderData?.cardSize || "small",
            showPhoto: orderData?.showPhoto !== false,
            fieldsDefinition: fieldsDetails
        });
    }, [open, orderData?.cardSize]);

    // const { t } = listData.tempInfo;

    const handleOk = () => {
        onClose();
    };

    const handleSave = () => {
        globalValuesDispatch.addOrEditGlobalValue({
            name: KanbanCardSessionName,
            type: "userdb",
            value: filterData
        });
        onClose();
    };
    const title = t("KanbanCardSettings_ModalTitle");
    const instructions = t("KanbanCardSettings_ModalInstructions");
    const ready = metadata.status === "success" && readyTranslation;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={title}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">{instructions}</Typography>
                {ready ? (
                    <SettingsModalCardFieldsList
                        filterData={filterData}
                        setFilterData={setFilterData}
                        kanbanSmallCardItems={kanbanSmallCardItems}
                        kanbanMediumCardItems={kanbanMediumCardItems}
                        kanbanBigCardItems={kanbanBigCardItems}
                        // type={type}
                        t={t}
                        tJobView={data.t}
                    />
                ) : (
                    <Loading />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsModalCardKanbanFields;
