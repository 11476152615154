import React from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTranslation } from "react-i18next";
import TranslatedDataJob from "./TranslatedDataJob";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2)
    },
    appBar: {
        marginTop: theme.spacing(1)
    }
}));

interface IProps {
    t: any;
    languages: Compleo.IObject[];
    defaultLanguage: string;
    metadata: any;
}

const TranslatedData = (props: IProps) => {
    const classes = useStyles();
    const { t, languages, defaultLanguage } = props;
    const moduleName = "APPLICATIONFORMNEW";
    const [tLocal, i18n] = useTranslation(moduleName, {
        useSuspense: false
    });
    const currentLanguage = i18n.languages[0];
    const onlyAlternativeLanguages = languages.filter(
        (f: any) => f.value !== defaultLanguage
    );
    const defaultLanguageObj = languages.filter(
        (f: any) => f.value === defaultLanguage
    )[0];

    const ready = languages && languages.length > 0;

    if (ready) {
        const tabs: TabTypeCompleo[] = [];
        onlyAlternativeLanguages.map((item: any, index: number) => {
            const TabComponent = <TranslatedDataJob t={t} language={item} />;
            tabs.push({
                label: item[`label-${currentLanguage}`],
                Component: TabComponent
            });
        });
        return (
            <div className={classes.root}>
                {" "}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            display="block"
                            color="primary"
                        >
                            {t("defaultLanguageWhenCreated")}
                        </Typography>
                        <Typography variant="body1" display="block">
                            {defaultLanguageObj[`label-${currentLanguage}`]}
                        </Typography>
                    </Grid>
                </Grid>
                <CompleoTabs
                    t={t}
                    tabs={tabs}
                    interfaceProps={{
                        AppBarOtherProps: { className: classes.appBar }
                    }}
                />
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default TranslatedData;
