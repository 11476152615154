import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList from "../..";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { ApiStateType } from "customHooks/useApi";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const verifyLocationData = (values: any) => {
    const LocationToReturn: {
        longitude?: number;
        latitude?: number;
    } = {};

    const addressGeoLocation = {
        longitude: values?.location?.longitude,
        latitude: values?.location?.latitude
    };
    const cityGeoLocation = {
        longitude: values?.location?.city?.longitude,
        latitude: values?.location?.city?.latitude
    };

    const hasAddressLocation =
        !isNaN(addressGeoLocation.latitude) &&
        !isNaN(addressGeoLocation.longitude);

    const hasCityLocation =
        !isNaN(cityGeoLocation.latitude) && !isNaN(cityGeoLocation.longitude);

    let IsAddressSameLocationCity = false;
    if (hasAddressLocation && hasCityLocation) {
        IsAddressSameLocationCity =
            addressGeoLocation.latitude === cityGeoLocation.latitude &&
            addressGeoLocation.longitude === cityGeoLocation.longitude;
    }

    let messageCode = "";
    let canContinue = false;
    if (hasAddressLocation && !IsAddressSameLocationCity) {
        LocationToReturn.latitude = addressGeoLocation.latitude;
        LocationToReturn.longitude = addressGeoLocation.longitude;
        messageCode = "messageSuccessAddress";
        canContinue = true;
    } else if (hasAddressLocation && IsAddressSameLocationCity) {
        canContinue = true;
        LocationToReturn.latitude = addressGeoLocation.latitude;
        LocationToReturn.longitude = addressGeoLocation.longitude;
        messageCode = "messageSuccessAddressEqualCity";
    } else if (hasCityLocation) {
        LocationToReturn.latitude = cityGeoLocation.latitude;
        LocationToReturn.longitude = cityGeoLocation.longitude;
        canContinue = true;
        messageCode = "messageSuccessAddressOnlyCity";
    } else {
        canContinue = false;
        messageCode = "messageErrorAddress";
    }

    return {
        geoLocation: LocationToReturn,
        hasAddressLocation,
        hasCityLocation,
        IsAddressSameLocationCity,
        canContinue,
        messageCode
    };
};

const LocationInfoModal = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const { callDialog } = useGlobalDialog();
    const showMessage = useShowMessage();
    const formikRef = React.useRef(null);
    const [listData, listDispatch] = useList();
    const {
        setLocalInfo,
        // setFormLocationInfo,
        setFilters
        // setNewSortAfterLocation
    } = listDispatch;
    const { filters } = listData.filtersInfo;
    const { modalLocationInfo } = listData.localInfo;
    // const { formLocationInfo, modalLocationInfo } = listData.localInfo;
    const { t } = listData.tempInfo;
    const classes = useStyles();
    const queryClient = useQueryClient();
    const module = "ELASTICLISTSETTINGSMODALLOCATION";
    const [metadata] = useGetMetadata(module);
    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module, "ADDRESSFIELD"],
        {
            useSuspense: false
        }
    );

    const setNewModalInfo = (
        newSortLocation: any,
        formValues: any,
        modalStatus: boolean
    ) => {
        setLocalInfo({
            ...listData.localInfo,
            modalLocationInfo: {
                ...listData.localInfo.modalLocationInfo,
                newSortAfterLocation: newSortLocation,
                values: formValues,
                open: modalStatus
            }
        });
    };

    const handleOk = () => {
        onClose();
    };
    const [initialValues] = useValuesFromSource(
        metadata,
        false,
        modalLocationInfo.values
    );

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const handleSubmit = async (values: any) => {
        const {
            geoLocation,
            hasAddressLocation,
            hasCityLocation,
            IsAddressSameLocationCity,
            messageCode,
            canContinue
        } = verifyLocationData(values);

        if (messageCode === "messageSuccessAddress") {
            showMessage({
                message: tLocal(messageCode),
                snackBarOrign: { vertical: "bottom", horizontal: "right" },
                timeout: 1000 * 2
            });
        } else {
            callDialog({
                title: canContinue
                    ? tLocal("messageSuccessTitle")
                    : tLocal("messageErrorTitle"),
                bodyText: tLocal(messageCode),
                agreeButtonText: tLocal("buttonCloseModal"),
                agreeFunction: async () => {
                    return false;
                }
            });
        }

        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        if (canContinue) {
            setFilters({
                ...filters,
                geoLocation: geoLocation,
                filterUpdated: true,
                sort:
                    Object.keys(modalLocationInfo.newSortAfterLocation || {})
                        .length > 0
                        ? modalLocationInfo.newSortAfterLocation || {}
                        : filters.sort
            });
            setNewModalInfo(undefined, values, false);
        }
        return retorno;
    };

    const handleSave = async () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const ready = readyTranslation && metadata.status === "success";
    const [formCompleo, finished] = useCompleoFormNew({
        t: tLocal,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && ready) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("ELASTICLISTSETTINGS:ModalLocationInfo_Title")}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t("ELASTICLISTSETTINGS:ModalLocationInfo_Title")}
            </DialogTitle>
            <DialogContent dividers>{compRetorno}</DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {tLocal("buttonCloseModal")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                >
                    {tLocal("buttonSaveModal")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationInfoModal;
