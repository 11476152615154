import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "customHooks/DataTable/datatable-column-header";
import { DataTableRow } from "customHooks/DataTable/datatable-row";

export const getCustomListColumn = (
    columnName: string,
    label: string,
    language: string
) => {
    const returnData: ColumnDef<Compleo.IObject> = {
        id: columnName,
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={label}
                    align="right"
                />
            );
        },
        cell: ({ row }) => {
            const rowData = row.original as Compleo.IObject;
            const value =
                rowData?.[columnName]?.label ||
                rowData?.[columnName]?.["label_" + language];
            return <DataTableRow row={row} value={value} align="right" />;
        },
        getGroupingValue: (row) => {
            const value =
                row?.[columnName]?.label ||
                row?.[columnName]?.["label_" + language];
            return value;
        }
    };
    return returnData;
};
