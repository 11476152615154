import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { MoreVert } from "@material-ui/icons";
import useDashboardContext from "../useDashboardContext";
import _ from "lodash";
import { apiDirectCall } from "customHooks/useApi";
import {
    DashboardPageToRenderType,
    IDashboardMainDef,
    IDashboardMainState
} from "../useDashboardContext/DashboardTypes";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useQueryClient } from "react-query";
type MainMenuList =
    | "editDashboard"
    | "newDashboard"
    | "openDashboard"
    | "deleteDashboard"
    | "openDefault";

const getMenuOptionsByContext: (
    dataContext: IDashboardMainState,
    dashList: IDashboardMainDef[]
) => MainMenuList[] = (
    dataContext: IDashboardMainState,
    dashList: IDashboardMainDef[]
) => {
    if (dataContext.localStateData?.editMode) {
        return [];
    }
    switch (dataContext.localStateData.pageToRender) {
        case "main": {
            const dataToReturn: MainMenuList[] = ["editDashboard"];
            return dataToReturn;
        }
        case "jobs":
        case "applicantsJobView":
        case "applicants": {
            const dataToReturn: MainMenuList[] = ["editDashboard"];
            if (dashList.length) {
                dataToReturn.push("openDashboard");
            }
            if (dataContext.localStateData?.currentDashboardDef?.id) {
                dataToReturn.push("deleteDashboard");
                dataToReturn.push("openDefault");
            }
            return dataToReturn;
        }
        case "myDashboards": {
            const dataToReturn: MainMenuList[] = ["newDashboard"];
            if (dashList.length) {
                dataToReturn.push("openDashboard");
            }
            if (dataContext.localStateData?.currentDashboardDef) {
                dataToReturn.push("editDashboard");
            }
            if (dataContext.localStateData?.currentDashboardDef?.id) {
                dataToReturn.push("deleteDashboard");
            }
            return dataToReturn;
        }
        default:
            return [];
    }
};
interface IProps {
    t: any;
}

const DashboardMenu = (props: IProps) => {
    const { t } = props;
    const [data, dispatch] = useDashboardContext();
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const queryClient = useQueryClient();

    const { dispatchGeneralPageInfo } = dispatch;
    const dashList = (data.localStateData?.dashboardList || []).filter(
        (i) => i.id !== data?.localStateData?.currentDashboardDef?.id
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [
        anchorElOpenDash,
        setAnchorElOpenDash
    ] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const openMenuDash = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElOpenDash(event.currentTarget);
    };

    const menuOptions = getMenuOptionsByContext(data, dashList);

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseDash = () => {
        setAnchorElOpenDash(null);
    };

    const clickMenuItem = async (event: any, action: MainMenuList) => {
        switch (action) {
            case "editDashboard":
                {
                    const currentDef = _.cloneDeep(
                        data.localStateData.currentDashboardDef
                    );
                    dispatchGeneralPageInfo({
                        payload: {
                            editMode: true,
                            currentDashboardStateBeforeEditOrNewDef: currentDef
                        },
                        type: "update"
                    });
                    handleClose();
                }
                break;
            case "newDashboard":
                {
                    const currentDef = _.cloneDeep(
                        data.localStateData.currentDashboardDef
                    );
                    const newDef = _.cloneDeep(
                        data.localStateData.mainDashboardDef
                    );
                    dispatchGeneralPageInfo({
                        payload: {
                            editMode: true,
                            currentDashboardDef: newDef,
                            currentDashboardStateBeforeEditOrNewDef: currentDef,
                            newMode: true
                        },
                        type: "update"
                    });
                    handleClose();
                }
                break;
            case "openDashboard":
                openMenuDash(event);
                break;
            case "openDefault":
                debugger;
                if (data?.localStateData?.mainDashboardDef) {
                    openDash(data?.localStateData?.mainDashboardDef);
                }
                break;
            case "deleteDashboard":
                if (
                    data.localStateData?.pageToRender === "jobs" ||
                    data.localStateData?.pageToRender === "applicants" ||
                    data.localStateData?.pageToRender === "applicantsJobView"
                ) {
                    await deleteDynamicDashboard();
                } else {
                    await deleteDashboard();
                }
                handleClose();

                break;
            default:
                break;
        }
    };

    const deleteDashboard = async () => {
        const type =
            data.localStateData?.currentDashboardDef?.type === "company"
                ? "COMPANY"
                : "CUSTOM";

        callDialog({
            title: t("mainPageName"),
            bodyText: t("Message_Delete"),
            agreeButtonText: t("button_YesDelete"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                await apiDirectCall("/reports/deletedashboard", "post", {
                    companyId: data.companyId,
                    type: type,
                    id: data.localStateData?.currentDashboardDef?.id
                });
                queryClient.invalidateQueries(`/reports/listdashboards`);
                const otherDashToChange = data.localStateData?.dashboardList?.filter(
                    (i) => i.id !== data.localStateData?.currentDashboardDef?.id
                )[0];
                if (otherDashToChange) {
                    dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: _.cloneDeep(otherDashToChange)
                        },
                        type: "update"
                    });
                } else {
                    dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: undefined
                        },
                        type: "update"
                    });
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    const deleteDynamicDashboard = async () => {
        const type =
            data.localStateData?.currentDashboardDef?.type === "company"
                ? "COMPANY"
                : "CUSTOM";

        callDialog({
            title: t("mainPageName"),
            bodyText: t("Message_Delete"),
            agreeButtonText: t("button_YesDelete"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                await apiDirectCall("/reports/deletedynamicdashboard", "post", {
                    companyId: data.companyId,
                    type: type,
                    id: data.localStateData?.currentDashboardDef?.id,
                    dashboardName: getDynamicDashModuleFromPageToRender(
                        data.localStateData?.pageToRender
                    )
                });
                queryClient.invalidateQueries(`/reports/listdynamicdashboards`);
                const otherDashToChange = data.localStateData?.dashboardList?.filter(
                    (i) => i.id !== data.localStateData?.currentDashboardDef?.id
                )[0];
                if (otherDashToChange) {
                    dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: _.cloneDeep(otherDashToChange)
                        },
                        type: "update"
                    });
                } else {
                    dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: undefined
                        },
                        type: "update"
                    });
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };
    const openDash = (dash: IDashboardMainDef) => {
        const dashToOpen = _.cloneDeep(dash);
        dispatchGeneralPageInfo({
            payload: {
                currentDashboardDef: dashToOpen
            },
            type: "update"
        });
        handleCloseDash();
        handleClose();
    };

    return (
        <div>
            {options.length > 0 && (
                <ButtonIconTooltipCompleo
                    label={t("dashboardMenu")}
                    onClick={openMenu}
                >
                    <MoreVert />
                </ButtonIconTooltipCompleo>
            )}
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={(event: any) =>
                                clickMenuItem(event, option.value)
                            }
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            <Menu
                id="openDash"
                anchorEl={anchorElOpenDash}
                keepMounted
                open={Boolean(anchorElOpenDash)}
                onClose={handleCloseDash}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {dashList.map((option) => {
                    const type =
                        option.type === "company" ? "typeCompany" : "typeUser";
                    return (
                        <MenuItem
                            key={option.id}
                            onClick={() => openDash(option)}
                        >
                            {`${option.name} - ${t(type)}`}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const orderMenuOptions = (menuItems: MainMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ModalMainMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

const getDynamicDashModuleFromPageToRender = (
    pageToRender: DashboardPageToRenderType
) => {
    switch (pageToRender) {
        case "jobs":
            return "JOBDASHBOARD";
        case "applicants":
            return "APPLICANTDASHBOARD";
        case "applicantsJobView":
            return "APPLICANTJOBVIEWDASHBOARD";
        default:
            return "";
    }
};

export default DashboardMenu;
