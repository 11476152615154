import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as util from "functions/util";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

interface IProps {
    open: boolean;
    setOpen: any;
    t: any;
    stageIdsWithApplicants?: string[];
    field: ControllerRenderProps<FieldValues, string>;
    setFieldValue: (value: any) => void;
}

const FormDialog = (props: IProps) => {
    const {
        field,
        setFieldValue,
        open,
        setOpen,
        t,
        stageIdsWithApplicants = []
    } = props;
    const [value, setValue] = React.useState<Compleo.IObject | null>(null);
    const { user, company } = useAuthState();
    const [templates, setTemplates] = React.useState<Compleo.IObject[]>([]);
    // const [field, meta, helpers] = useField("pipeline");
    // const { field, watch, setFieldValue } = useRhfFieldControlled("pipeline");
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const fieldValue = field.value;

    const handleClose = () => {
        setOpen(false);
    };

    const [pipelineList] = useApiCache(
        "/pipeline/list",
        "post",
        {
            companyId: company.companyId
        },
        false
    );
    const data = pipelineList.response?.data?.data;

    const handleChangeTemplate = () => {
        const optionSelected = data.filter(
            (d: any) => d.skGS1pk === value?.value
        )[0];
        const newValue = { ...(fieldValue || {}) };
        newValue.name = optionSelected.name;
        newValue.id = optionSelected.skGS1pk;
        // newValue.stages = optionSelected.stages;
        newValue.stages = handleStages(
            optionSelected.stages,
            fieldValue?.stages,
            stageIdsWithApplicants
        );
        setFieldValue(newValue);

        if (stageIdsWithApplicants.length) {
            callDialog({
                title: t("afterReplacePipelineWithApplicantsTitle"),
                bodyText: t("afterReplacePipelineWithApplicants"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        }
        setOpen(false);
    };

    React.useEffect(() => {
        if (data && templates.length === 0) {
            const newData: Compleo.IObject[] = [];
            data.map((d: any) => {
                newData.push({ name: d.name, value: d.skGS1pk });
                if (d.default === true) {
                    setValue({ name: d.name, value: d.skGS1pk });
                }
            });
            setTemplates(newData);
        }
    }, [data]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={t("chooseTemplateTitle")}
            >
                <DialogTitle id="form-dialog-title">
                    {t("chooseTemplatePipelineTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("chooseTemplatePipelineInstructions")}
                    </DialogContentText>
                    <Autocomplete
                        id="pipelineTemplate"
                        options={templates}
                        value={value}
                        getOptionLabel={(option) => option.name}
                        disableClearable
                        // style={{ width: 300 }}
                        onChange={(event: any, newValue: any) =>
                            setValue(newValue)
                        }
                        renderInput={(params) => (
                            <TextField {...params} label={t("pipelinemodel")} />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t("buttonNoChooseAnotherTemplate")}
                    </Button>
                    <Button onClick={handleChangeTemplate} color="primary">
                        {t("buttonYesChooseAnotherTemplate")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const handleStages = (
    newStages: Compleo.IObject[],
    oldStages: Compleo.IObject[],
    stageIdsWithApplicants: string[]
) => {
    const oldStagesToKeep = oldStages.filter((i) =>
        stageIdsWithApplicants.includes(i.id)
    );
    const oldStagesNamesToKeep = oldStagesToKeep.map((i) => i.name);
    if (!oldStagesToKeep.length) {
        return newStages;
    }

    const newGroupTypeList: string[] = [
        "newApplied",
        "newSourced",
        "newReferred"
    ];

    // Handle "NEW" group stages
    const newGroupStages = handleNewGroupStage(
        newGroupTypeList,
        oldStagesToKeep,
        newStages
    );

    const newStagesExceptNewGroup: Compleo.IObject[] = newStages
        .filter((i) => !newGroupTypeList.includes(i.type?.value))
        .filter((i) => !oldStagesNamesToKeep.includes(i.name))
        .map((i) => {
            const uuidNew = util.uuid();
            return {
                ...i,
                id: uuidNew
            };
        });

    const oldStagesToKeepExceptNewGroup = oldStagesToKeep.filter(
        (i) => !newGroupTypeList.includes(i.type?.value)
    );

    const endTypes: string[] = ["hired", "rejected"];
    const finalStagesExceptNewGroup = [
        ...newStagesExceptNewGroup,
        ...oldStagesToKeepExceptNewGroup
    ];

    const stagesReturn: Compleo.IObject[] = [
        ...newGroupStages,
        ...finalStagesExceptNewGroup.filter(
            (i) => !endTypes.includes(i.type?.value)
        ),
        ...finalStagesExceptNewGroup.filter((i) => i.type?.value === "hired"),
        ...finalStagesExceptNewGroup.filter((i) => i.type?.value === "rejected")
    ].map((i, index) => {
        i.order = index;
        return i;
    });

    return stagesReturn;
};

const handleNewGroupStage = (
    typeListOrdered: string[],
    oldStagesToKeep: Compleo.IObject[],
    newStages: Compleo.IObject[]
) => {
    const oldStagesCurrentGroup = oldStagesToKeep.filter((stage) =>
        typeListOrdered.includes(stage.type?.value)
    );

    const oldStagesTypes = oldStagesCurrentGroup.map((i) => i.type?.value);

    const newStagesCurrentGroup = newStages.filter(
        (i) =>
            typeListOrdered.includes(i.type?.value) &&
            !oldStagesTypes.includes(i.type?.value)
    );
    const concatenatedStages = [
        ...newStagesCurrentGroup,
        ...oldStagesCurrentGroup
    ];

    // order the concatenatedStages
    concatenatedStages.sort((a, b) => {
        const indexA = typeListOrdered.indexOf(a.type?.value);
        const indexB = typeListOrdered.indexOf(b.type?.value);

        if (indexA > indexB) {
            return 1;
        }
        if (indexA < indexB) {
            return -1;
        }

        // a must be equal to b
        return 0;
    });

    return concatenatedStages;
};

export default FormDialog;
