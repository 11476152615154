import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import {
    useCompleoSimpleList,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import useActionsTableParserListItem from "./components/useActionsTableParserListItem";
import { Button, Grid } from "@material-ui/core";
import React from "react";
import ModalAutomaticallyProcess from "./ParserComponents/ModalAutomaticallyProcess";
import { useApiCache } from "customHooks/useApi";

const ParserOperationList = (props: any) => {
    const moduleName = "PARSEROPERATIONLISTDETAIL";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/parser/parseroperationdetaillist";
    const { company } = useAuthState();
    const [openModalAuto, setOpenModalAuto] = React.useState<{
        open: boolean;
        operationId: string;
        refreshListFunction: () => void;
        itemId?: string;
    }>({
        open: false,
        operationId: "",
        refreshListFunction: () => {},
        itemId: ""
    });
    const actions: actionsTableFunction = useActionsTableParserListItem(
        setOpenModalAuto,
        t
    );
    const postAddressSearch = `/parser/parseroperationsearch`;
    const [parserOperationResult] = useApiCache(
        postAddressSearch,
        "post",
        {
            operationid: props.operationid,
            companyId: company.companyId
        },
        false
    );
    const statusListShowButton: CompleoShared.ParserOperation.parserOperationStatus[] = [
        "partiallyProcessed",
        "requested"
    ];
    const showButton = statusListShowButton.includes(
        parserOperationResult?.response?.data?.data?.Items?.[0]?.status?.value
    );

    const [
        renderTable,
        finished,
        refreshFunction,
        ,
        finalSearch
    ] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId,
            operationId: props.operationid
            // totalFakeDataMultiply: 650
        },
        actions,
        <Button
            size="small"
            variant="outlined"
            onClick={() => navigate("/applicant/parserlist")}
            color="secondary"
        >
            {t("COMPLEOGENERAL_Back")}
        </Button>
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="xl"
                RightButtonComponent={
                    showButton ? (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                setOpenModalAuto({
                                    open: true,
                                    operationId: props.operationid,
                                    refreshListFunction: refreshFunction,
                                    itemId: undefined
                                })
                            }
                        >
                            {t("btnProcessAll")}
                        </Button>
                    ) : (
                        <> </>
                    )
                }
            >
                {compRetorno}
                {openModalAuto.open && (
                    <ModalAutomaticallyProcess
                        onClose={() =>
                            setOpenModalAuto({
                                open: false,
                                operationId: "",
                                refreshListFunction: () => {},
                                itemId: undefined
                            })
                        }
                        open={openModalAuto.open}
                        operationId={openModalAuto.operationId}
                        refreshListFunction={openModalAuto.refreshListFunction}
                        operationItemId={openModalAuto.itemId}
                    />
                )}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ParserOperationList;
