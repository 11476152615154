import Questions from "./Questions";
import Translations from "./TranslationComponent/Translations";
import Simulation from "../Simulation/Simulation";

const CustomComponents = [
    {
        name: "questions",
        component: Questions
    },
    {
        name: "translations",
        component: Translations
    },
    {
        name: "simulation",
        component: Simulation
    }
];

export default CustomComponents;
