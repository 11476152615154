import React from "react";
import { FieldProps } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import { addYears, addDays, isValid, toDate } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import _ from "lodash";
import * as formatValues from "functions/formatValues";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    openTo?: "date" | "year" | "month" | undefined;
    disablePast?: boolean;
    disableFuture?: boolean;
    invalidDateMessage?: string;
    invalidLabel?: string;
    readOnly?: boolean;
    language: "pt-BR" | "en-US" | "fr" | "es";
    orientation: "portrait" | "landscape";
    maxAge?: number;
    minAge?: number;
    timeZone: string; //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    minDateAddDaysFromCurrentDate?: number;
    maxDateAddDaysFromCurrentDate?: number;
    formatType?: Compleo.RegionalSettings.formatType;
}

// const localeMap = {
//     "pt-BR": ptBR,
//     "en-US": enUS,
//     fr: fr,
//     es: es
// };

const DateField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: {
            dirty,
            touched,
            errors,
            status,
            setFieldValue,
            setFieldError,
            setFieldTouched
        },
        helperTextDefault,
        value,
        openTo,
        disablePast,
        disableFuture,
        invalidDateMessage,
        invalidLabel,
        readOnly,
        language,
        orientation,
        minAge,
        maxAge,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone,
        formatType,
        ...other
    } = props;

    let { minDate, maxDate } = props;

    const errorText = _.get(errors, field.name); // errors[field.name];
    const hasError = _.get(touched, field.name) && errorText !== undefined; // touched[field.name] && errorText !== undefined;
    let errorMessage =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (errorMessage === "") {
            errorMessage += status[field.name];
        } else {
            errorMessage += " - " + status[field.name];
        }
    }

    //Convertendo data no timeZone do Usuário para UTC, pois o componente trabalha com UTC para Data
    minDate = dateToUTC(minDate, timeZone);

    if (maxAge && !minDate) {
        minDate = addYears(zonedTimeToUtc(Date.now(), timeZone), -maxAge);
    }

    if (minDateAddDaysFromCurrentDate && !minDate) {
        minDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            minDateAddDaysFromCurrentDate
        );
    }

    //Convertendo data no timeZone do Usuário para UTC, pois o componente trabalha com UTC para Data
    maxDate = dateToUTC(maxDate, timeZone);

    if (maxDateAddDaysFromCurrentDate && !maxDate) {
        maxDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            maxDateAddDaysFromCurrentDate
        );
    }

    if (minAge && !maxDate) {
        maxDate = addYears(zonedTimeToUtc(Date.now(), timeZone), -minAge);
    }

    const { format } = formatValues.getFormat(
        language,
        formatType || "dayMonthYear"
    );
    const locale = formatValues.getFNSLocaleFromString(language);

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <KeyboardDatePicker
                id={field.name}
                label={label}
                error={hasError || (status && status[field.name] !== undefined)}
                helperText={errorMessage}
                {...field}
                {...other}
                onChange={(val, stringVal) => {
                    // se for mes / ano fixa o primeiro dia do mês
                    if (formatType === "monthYear") {
                        if (val !== undefined) {
                            val?.setDate(1);
                            val?.setHours(0, 0, 0, 0);
                            setFieldValue(field.name, val);
                            return;
                        }
                    }

                    // Remove o horário pois é componente apenas de data
                    if (val !== undefined) {
                        val?.setHours(0, 0, 0, 0);
                    }
                    setFieldValue(field.name, val, false);
                }}
                onError={(error) => {
                    //console.log("onError", error, hasError, status);
                    if (error && error !== errorText) {
                        setFieldError(field.name, String(error));
                        // setFieldValue(field.name, null);
                    }
                }}
                value={field.value}
                format={format}
                minDate={minDate}
                maxDate={maxDate}
                openTo={openTo}
                disablePast={disablePast}
                disableFuture={disableFuture}
                invalidDateMessage={invalidDateMessage}
                invalidLabel={invalidLabel}
                readOnly={readOnly}
                orientation={orientation}
                KeyboardButtonProps={{
                    "aria-label": `change date ${field.name}`
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

DateField.defaultProps = {
    openTo: "date",
    invalidDateMessage: "Data inválida",
    language: "pt-BR",
    orientation: "portrait",
    timeZone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo"
};

function dateToUTC(originalDate: Date | undefined, timeZone: string) {
    let newDate = originalDate;

    if (originalDate) {
        newDate = zonedTimeToUtc(originalDate, timeZone);
    }
    return newDate;
}

export default DateField;
