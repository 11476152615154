import React from "react";
import {
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormHelperText,
    FormGroup,
    Checkbox,
    Box
} from "@material-ui/core";
import File from "../components/File";
import { Alert } from "@material-ui/lab";

interface IProps {
    t: any;
    question: any;
    value: any;
    setValue: any;
    error: boolean;
    isSimulation?: boolean;
}

export const getAnswerFields = ({
    t,
    question,
    value,
    setValue,
    error,
    isSimulation = false
}: IProps) => {
    const handleChange = (newValue: any) => {
        //console.log("handleChange", newValue);
        setValue(newValue);
    };

    let helperTextError = "A resposta é obrigatória!";
    //console.log("getAnswerFields", question, value, error);

    switch (question.questiontype.value) {
        case "CPQUESTIONSINGLECHOICE":
            return (
                <FormControl component="fieldset" error={error}>
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup
                        aria-label={question.name}
                        name={`answer${question.id}`}
                        value={value ?? ""}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                            handleChange(
                                (event.target as HTMLInputElement).value
                            )
                        }
                    >
                        {question.answers.map((a: any) => (
                            <FormControlLabel
                                key={a.id}
                                value={a.name}
                                control={<Radio />}
                                label={a.name}
                            />
                        ))}
                    </RadioGroup>
                    {error && (
                        <FormHelperText>{helperTextError}</FormHelperText>
                    )}
                </FormControl>
            );
            break;
        case "CPQUESTIONMULTIPLECHOICE":
            return (
                <FormControl required error={error} component="fieldset">
                    {/* <FormLabel component="legend">Pick two</FormLabel> */}
                    <FormGroup>
                        {question.answers.map((a: any) => (
                            <FormControlLabel
                                key={a.id}
                                control={
                                    <Checkbox
                                        checked={
                                            value && value[a.name]
                                                ? true
                                                : false
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement
                                            >
                                        ) =>
                                            handleChange({
                                                ...value,
                                                [event.target.name]:
                                                    event.target.checked
                                            })
                                        }
                                        name={a.name}
                                    />
                                }
                                label={a.name}
                            />
                        ))}
                    </FormGroup>
                    {error && (
                        <FormHelperText>{helperTextError}</FormHelperText>
                    )}
                </FormControl>
            );
        case "CPQUESTIONSIMPLETEXT": //Texto simples
            return (
                <TextField
                    id={question.id}
                    value={value ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(event.target.value)
                    }
                    error={error}
                    helperText={error ? helperTextError : ""}
                    variant="outlined"
                    fullWidth
                ></TextField>
            );
            break;
        case "CPQUESTIONPARAGRAFH": //Parágrafo Longo
            return (
                <TextField
                    id={question.id}
                    value={value ?? ""}
                    multiline
                    rows={10}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(event.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    error={error}
                    helperText={error ? helperTextError : ""}
                ></TextField>
            );
            break;
        case "CPQUESTIONSINGLEFILE": //Upload de um único arquivo
            helperTextError = "É obrigatório o envio do arquivo!";
            if (!isSimulation) {
                return (
                    <File
                        fieldName="singleFile"
                        label={t("cpquestionsinglefile")}
                        elementType="file"
                        error={error}
                        helperTextDefault={error ? helperTextError : ""}
                        fileDefinition={{
                            maxFileSizeKB: 2000,
                            extensions: [
                                ".pdf",
                                ".doc",
                                ".docx",
                                ".txt",
                                ".png",
                                ".rtf",
                                ".jpg",
                                ".jpeg"
                            ]
                        }}
                        fieldValue={value}
                        setFieldValue={handleChange}
                        t={t}
                    ></File>
                );
            } else {
                return (
                    <QuestionNotAvailableSimulation
                        message={t("uploadDisabledSimulation")}
                        setValue={setValue}
                        value={[]}
                        question={question.name}
                    />
                );
            }
        //break;
        case "CPQUESTIONMULTIPLEFILES": // Upload de múltiplos arquivos
            if (!isSimulation) {
                helperTextError =
                    "É obrigatório o envio de ao menos 1 arquivo!";
                return (
                    <File
                        fieldName="multipleFiles"
                        label={t("cpquestionmultiplesfiles")}
                        elementType="multipleFiles"
                        error={error}
                        helperTextDefault={error ? helperTextError : ""}
                        fileDefinition={{
                            maxFiles: 10,
                            enableTags: false,
                            maxFileSizeKB: 2000,
                            extensions: [
                                ".pdf",
                                ".doc",
                                ".docx",
                                ".txt",
                                ".png",
                                ".rtf",
                                ".jpg",
                                ".jpeg"
                            ]
                        }}
                        fieldValue={value}
                        setFieldValue={handleChange}
                        t={t}
                    ></File>
                );
            } else {
                return (
                    <QuestionNotAvailableSimulation
                        message={t("uploadDisabledSimulation")}
                        setValue={setValue}
                        value={[]}
                        question={question.name}
                    />
                );
            }
    }

    return <div>Question Type not implemented yet</div>;
};

interface IQuestionNotAvailableSimulationProps {
    message: string;
    setValue: any;
    value: any;
    question: string;
}
const QuestionNotAvailableSimulation = (
    props: IQuestionNotAvailableSimulationProps
) => {
    const { message, setValue, value, question } = props;

    React.useEffect(() => {
        setValue(value);
    }, [question]);

    return (
        <Box mt={1} mb={1} width="100%">
            <Alert style={{ width: "100%" }} severity="info">
                {message}
            </Alert>
        </Box>
    );
};

export default getAnswerFields;
