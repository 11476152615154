import { useApiCache } from "customHooks/useApi";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";

const useGetLanguagesCareers = () => {
    const { company } = useAuthState();
    const postAddressSearch = `/languagesettings/search`;
    const [getLanguageSettingsReturn] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );

    const languagesCareers =
        getLanguageSettingsReturn.response?.data?.data?.languagesCareers;
    const defaultLanguageCareers =
        getLanguageSettingsReturn.response?.data?.data?.defaultLanguageCareers;
    const ready = getLanguageSettingsReturn.status === "success";

    return [languagesCareers, defaultLanguageCareers?.value, ready];
};

export default useGetLanguagesCareers;
