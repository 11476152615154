import { processEmailFields } from "customHooks/useCompleoForm/components/FormMainHelper";
import { useAlertDialogFNType } from "customHooks/useDialog";
import _ from "lodash";

export const getHandleJobSubmit = (
    fnToRun: any,
    status?: "open" | "draft",
    limitJobInfo?: {
        t: any;
        getLimit: (action: CompleoShared.Common.limitType) => Promise<any>;
        callDialog: (args: useAlertDialogFNType) => void;
    }
) => {
    const HandleSubmit = async (values: any) => {
        try {
            if (status === "open" && limitJobInfo !== undefined) {
                const { getLimit, callDialog, t } = limitJobInfo;
                const dataLimit = await getLimit("addJob");
                if (!dataLimit.allowAction) {
                    callDialog({
                        title: t("PLANLIMIT_title"),
                        // bodyText: `Seu plano atingiu o limite de vagas abertas. Limite de vagas: ${dataLimit.maxValue} - Vagas abertas: ${dataLimit.currentTotal}`,
                        bodyText: `${t("PLANLIMIT_jobMessage", {
                            maxValue: dataLimit.maxValue,
                            currentTotal: dataLimit.currentTotal
                        })}. ${t("additionalTextAddJobPlanLimitErros")}`,
                        agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                        // disagreeButtonText: t("ScorecardDelete_no"),
                        agreeFunction: async () => {
                            return false;
                        }
                    });
                    const retorno: Compleo.IObject = {};
                    retorno.status = 999;
                    return retorno;
                }
            }
            const companyId = values.companyId;
            const clonedValues = _.cloneDeep(values);
            if (status) {
                clonedValues.status = status;
            }

            const stages = values?.pipeline?.stages || [];
            for (let index = 0; index < stages.length; index++) {
                for (const fieldStageName of Object.keys(stages[index])) {
                    if (Array.isArray(stages[index][fieldStageName])) {
                        for (
                            let i = 0;
                            i < stages[index][fieldStageName].length;
                            i++
                        ) {
                            clonedValues.pipeline.stages[index][fieldStageName][
                                i
                            ] = await processEmailFields({
                                ...stages[index][fieldStageName][i],
                                companyId: companyId
                            });
                        }
                    }
                }
            }

            return await fnToRun(clonedValues);
            // return;
        } catch (ex) {
            return ex.response;
        }
    };
    return HandleSubmit;
};
