import { TableRow, TableCell } from "@material-ui/core";
import React from "react";
import { FixedSizeList as ListReactWindow } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useStylesBucketReportTable } from "./BucketReportTable";
import clsx from "clsx";

interface IPropsItemFilter {
    allItems: Compleo.IObject[];
}
export const BucketReportItemRowSize = 40;

const BucketReportItemTable = React.memo((props: IPropsItemFilter) => {
    const { allItems } = props;
    const classes = useStylesBucketReportTable();

    const Row = ({ index, style }: any) => {
        const row = allItems[index];

        return (
            <TableRow component="div" className={classes.row} style={style}>
                <TableCell
                    component="div"
                    variant="body"
                    className={clsx(classes.cell, classes.expandingCell)}
                    style={{
                        height: BucketReportItemRowSize
                    }}
                >
                    {row.label}
                </TableCell>
                <TableCell
                    component="div"
                    variant="body"
                    align="right"
                    className={clsx(classes.cell, classes.expandingCell)}
                    style={{
                        height: BucketReportItemRowSize
                    }}
                >
                    {row.total}
                </TableCell>
                {row.percentualFormated !== row.percentualFormatedMain && (
                    <TableCell
                        component="div"
                        variant="body"
                        align="right"
                        className={clsx(classes.cell, classes.expandingCell)}
                        style={{
                            height: BucketReportItemRowSize
                        }}
                    >
                        {row.percentualFormated}
                    </TableCell>
                )}

                <TableCell
                    component="div"
                    variant="body"
                    align="right"
                    className={clsx(classes.cell, classes.expandingCell)}
                    style={{
                        height: BucketReportItemRowSize
                    }}
                >
                    {row.percentualFormatedMain}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <ListReactWindow
            // className="List"
            height={550}
            itemCount={allItems.length}
            itemSize={40}
            width="100%"
        >
            {Row}
        </ListReactWindow>
    );
});

export default BucketReportItemTable;
