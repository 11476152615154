import { Auth } from "aws-amplify";

export const newPasswordFunction = (t: any, email: any, loginFN: any) => {
    return async (values: any) => {
        let retorno: { [k: string]: any } = {};

        try {
            await Auth.forgotPasswordSubmit(
                email,
                values.confirmationCode,
                values.password
            );

            await loginFN(email, values.password);
            retorno = { status: 200 };
            return retorno;
        } catch (err) {
            retorno = { status: 400 };
            if (err.code === "ExpiredCodeException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("expiredCodeException")
                    }
                ];
            } else if (err.code === "CodeMismatchException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("invalidConfirmationCode")
                    }
                ];
            } else if (err.code === "LimitExceededException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("limitExceededException")
                    }
                ];
            } else {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("genericError")
                    }
                ];
            }
            return retorno;
        }
    };
};
