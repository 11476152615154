import React from "react";
import { Divider } from "@material-ui/core";
import TranslationGrid from "./TranslationGrid";

interface IFieldsAndMessageProps {
    answers: Compleo.IObject[];
    mainFieldName: any;
    language: string;
    t: any;
}

const TranslationAnswers = (props: IFieldsAndMessageProps) => {
    const { answers, mainFieldName, language, t } = props;
    return (
        <>
            {answers.map((answer: any) => {
                return (
                    <React.Fragment key={answer.id}>
                        <TranslationGrid
                            language={language}
                            localFieldName={answer.id}
                            mainFieldName={mainFieldName}
                            label={answer.name}
                            type={t("labelAnswer")}
                            t={t}
                        />
                        <Divider />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default TranslationAnswers;
