import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
// import languagesAvailable from "functions/getAllLanguagesSuported";
import { Icon, Menu } from "@material-ui/core";
import TooltipCompleo from "Components/Tooltip";
import { ScheduleDataDefaultValues } from "customHooks/useValuesFromSource";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getTemplatesFromScheduleDataDB } from "./EmailDataHelper";

interface IButtonLoadTemplate {
    fieldValue: any;
    setFieldValue: (p: any) => void;
    t: any;
    type?: string;
    buttonPositionEnd?: boolean;
    splitTemplatesByLanguage: boolean;
}

const ButtonLoadTemplate = (props: IButtonLoadTemplate) => {
    const {
        t,
        fieldValue,
        setFieldValue,
        type,
        buttonPositionEnd = true,
        splitTemplatesByLanguage
    } = props;
    // const [field, meta, helpers] = useField(mainFieldName);
    const companyData = useAuthState();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const postAddressSearch = `/scheduletemplate/list`;

    const [
        getScheduleTemplateResult,
        executeScheduleTemplateData
    ] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: companyData.company.companyId },
        false
    );
    const templateList = getTemplatesFromScheduleDataDB(
        getScheduleTemplateResult,
        splitTemplatesByLanguage,
        t,
        type
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        id: string,
        language?: string
    ) => {
        // const templateData = templateList.filter((item) => item.id === id)[0];
        const templateData = templateList.filter(
            (item) =>
                item.id === id &&
                (!language ||
                    item.items.filter((i) => i.language === language).length >
                        0)
        )[0];
        const templateLanguages = templateData.items.map(
            (item) => item.language
        );
        const currentValue = [...fieldValue];
        const currentFieldLanguages = currentValue.map((item) => item.language);

        for (let index = 0; index < currentValue.length; index++) {
            const element = currentValue[index];
            // const languageData = templateData.items.filter(
            //     (item) => item.language === element.language
            // )[0];
            const languageData = templateData.items.filter((item) => {
                if (!language) {
                    return item.language === element.language;
                } else {
                    return true;
                }
            })[0];
            if (languageData) {
                currentValue[index].body = languageData.body;
                currentValue[index].subject = languageData.subject;
                currentValue[index].location = languageData.location;
                if (language) {
                    break;
                }
            }
        }
        if (!language) {
            const languagesToInclude = templateLanguages.filter(
                (item) => !currentFieldLanguages.includes(item)
            );
            languagesToInclude.map((item) => {
                const languageData = templateData.items.filter(
                    (itemLanguage) => itemLanguage.language === item
                )[0];
                currentValue.push({
                    ...ScheduleDataDefaultValues,
                    default: false,
                    language: languageData.language,
                    body: languageData.body,
                    subject: languageData.subject,
                    location: languageData.location
                });
            });
        }

        setFieldValue(currentValue);
        handleClose();
    };

    if (templateList.length) {
        return (
            <Grid
                container
                item
                alignItems={buttonPositionEnd ? "flex-end" : "flex-start"}
                justify={buttonPositionEnd ? "flex-end" : "flex-start"}
                alignContent="center"
            >
                <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClick}
                        endIcon={<Icon>arrow_drop_down</Icon>}
                    >
                        {t("SCHEDULEDATAFIELD_textChooseTemplate")}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {templateList.map((option, index) => {
                            const langParam = splitTemplatesByLanguage
                                ? option.items[0].language
                                : undefined;
                            return (
                                <MenuItem
                                    key={option.id}
                                    onClick={(event) =>
                                        handleMenuItemClick(
                                            event,
                                            option.id,
                                            langParam
                                        )
                                    }
                                >
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

export default ButtonLoadTemplate;
