import Page from "Pages/_Layouts/InternalWithoutMenu";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { Box, Typography } from "@material-ui/core";

import useCompleoView from "customHooks/useCompleoView";
import useGetMetadata from "customHooks/useGetMetadata";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useJobAccess from "customHooks/useAccess/useJobAccess";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React from "react";

interface IJobPrint {
    id: string;
}
const JobPrint = (props: RouteComponentProps & IJobPrint) => {
    const { company, timeZone } = useAuthState();
    const jobAccess = useJobAccess();
    const moduleName = "JOBPRINT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const printRef: any = React.useRef(null);
    const [metadata] = useGetMetadata(moduleName);
    const postAddressSearch = `/job/search`;
    const [getJobSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: props.id, companyId: company.companyId, searchForEdit: false },
        false,
        1000 * 60 * 5,
        false
    );
    const jobSearchDBData = getJobSearchResult.response?.data?.data || {};
    const ready = readyTranslation;

    const { formRetorno } = useCompleoView({
        language: i18n.language,
        t: t,
        metadataReturn: metadata,
        ready: ready,
        valuesFromSource: { ...jobSearchDBData },
        timeZone: timeZone,
        readOnly: !jobAccess.allowEdit
    });

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("print.pdf");
    };

    return (
        <>
            {/* <button onClick={handleDownloadPdf}>Download PDF</button> */}
            <div ref={printRef}>
                <Page maxWidth={false} className="overflow-hidden">
                    <Box m={2} textAlign={"center"}>
                        <Typography variant="h4">
                            {jobSearchDBData?.title}
                        </Typography>
                    </Box>
                    <Box>{formRetorno}</Box>
                </Page>
            </div>
        </>
    );
};

export default JobPrint;
