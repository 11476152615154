import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

import JobCareersStatusMessages, {
    getCareerItemsMessages
} from "../JobCareersStatusMessages";
import { Alert } from "@material-ui/lab";

interface IJobShareErrorSiteList {
    listCareersErrors: CompleoShared.CareersSettings.IJobCareersListStatus[];
    classes: any;
    t: any;
}

const JobShareErrorSiteList = (props: IJobShareErrorSiteList) => {
    const { listCareersErrors, classes, t } = props;
    return (
        <>
            {listCareersErrors
                .filter((i) => i.enabled)
                .map((careerItem) => {
                    const careerUniqueLink = (
                        careerItem?.careerSettings?.uniqueLink || ""
                    ).toLowerCase();

                    const messageList = getCareerItemsMessages(
                        careerItem,
                        t,
                        false
                    );
                    const hasError =
                        messageList.filter((i) => i.type === "error").length >
                        0;
                    const filteredMessageList = messageList.filter(
                        (i) => i.type === "error"
                    );

                    return (
                        <Paper
                            elevation={1}
                            className={classes.paper}
                            key={careerItem.careerSettings.uniqueLink}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                    >
                                        {t("careers")}:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        style={{ marginLeft: 16 }}
                                    >
                                        <strong>{careerUniqueLink}</strong>
                                    </Typography>
                                </Grid>
                                {hasError && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            {t("messageCareersErrors")}
                                        </Alert>
                                    </Grid>
                                )}
                                {hasError && (
                                    <Grid item xs={12}>
                                        <JobCareersStatusMessages
                                            careerItem={careerItem}
                                            messageListParam={
                                                filteredMessageList
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    );
                })}
        </>
    );
};

export default JobShareErrorSiteList;
