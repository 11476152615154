import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type useAlertDialogType = (
    title: string,
    bodyText: string,
    agreeButtonText: string,
    disagreeButtonText: string | null,
    dialogMaxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl",
    agreeFunction?: () => void,
    disagreeFunction?: () => void
) => [() => void, () => void, () => JSX.Element, null | boolean];

const useAlertDialog: useAlertDialogType = (
    title,
    bodyText,
    agreeButtonText,
    disagreeButtonText = null,
    dialogMaxWidth = "sm",
    agreeFunction = () => {},
    disagreeFunction = () => {}
) => {
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState<null | boolean>(null);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const agreeDisagreeFN = (agree: boolean) => {
        setAgree(agree);
        if (agree) {
            agreeFunction();
        } else {
            disagreeFunction();
        }
        closeDialog();
    };

    const ReturnForm = () => {
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={dialogMaxWidth}
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {bodyText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {disagreeButtonText !== null ? (
                            <Button
                                onClick={() => agreeDisagreeFN(false)}
                                color="primary"
                            >
                                {disagreeButtonText}
                            </Button>
                        ) : null}
                        <Button
                            onClick={() => agreeDisagreeFN(true)}
                            color="primary"
                            autoFocus
                        >
                            {agreeButtonText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return [openDialog, closeDialog, ReturnForm, agree];
};

type fnTypeSync = () => void;
type fnTypeASync = () => Promise<void>;

export type useAlertDialogFNType = {
    title: string;
    bodyText: string;
    agreeButtonText: string;
    disagreeButtonText?: string;
    agreeFunction?: () => void;
    disagreeFunction?: () => void;
    dialogMaxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl";
};

type useAlertDialogTypeV2 = () => [
    boolean,
    (args: useAlertDialogFNType) => void,
    null | boolean,
    () => JSX.Element
];

export const useGlobalAlertDialog: useAlertDialogTypeV2 = () => {
    const defaultState: useAlertDialogFNType = {
        title: "",
        bodyText: "",
        agreeButtonText: "",
        disagreeButtonText: "",
        dialogMaxWidth: "sm",
        agreeFunction: () => {},
        disagreeFunction: () => {}
    };
    const [dialogValues, setDialogValues] = React.useState<
        useAlertDialogFNType
    >(defaultState);

    const [readyForm, setReadyForm] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState<null | boolean>(null);

    const callDialog = ({ ...args }: useAlertDialogFNType) => {
        setDialogValues({ ...defaultState, ...args });
        setReadyForm(true);
    };

    if (readyForm && !open) {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
        setReadyForm(false);
        setDialogValues(defaultState);
    };

    const agreeDisagreeFN = async (agree: boolean) => {
        setAgree(agree);
        if (agree) {
            if (dialogValues.agreeFunction !== undefined) {
                dialogValues.agreeFunction();
                // if (dialogValues.agreeFunction as fnTypeSync) {
                //     dialogValues.agreeFunction();
                // } else if (dialogValues.agreeFunction as fnTypeASync) {
                //     await dialogValues.agreeFunction();
                // }
            }
        } else {
            if (dialogValues.disagreeFunction !== undefined) {
                dialogValues.disagreeFunction();
            }
        }
        closeDialog();
    };
    function createMarkup(value: string) {
        return { __html: value };
    }

    const ReturnForm = () => {
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={dialogValues.dialogMaxWidth}
                    disableEscapeKeyDown
                    disableBackdropClick
                >
                    <DialogTitle id="alert-dialog-title">
                        <span
                            dangerouslySetInnerHTML={createMarkup(
                                dialogValues.title
                            )}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <span
                                dangerouslySetInnerHTML={createMarkup(
                                    dialogValues.bodyText
                                )}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => agreeDisagreeFN(false)}
                            color="secondary"
                        >
                            {dialogValues.disagreeButtonText}
                        </Button>
                        <Button
                            onClick={() => agreeDisagreeFN(true)}
                            color="primary"
                            autoFocus
                        >
                            {dialogValues.agreeButtonText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return [readyForm, callDialog, agree, ReturnForm];
};

export default useAlertDialog;
