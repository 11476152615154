import { Chip, Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import useApplicantViewContext from "../../useApplicantViewContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0)
        },
        tags: {
            marginLeft: theme.spacing(1),
            maxWidth: 250
        }
    })
);

const ApplicantHistoryAddTagsAutomation = (props: IApplicantHistoryProps) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;

    return (
        <ApplicantHistoryItemDetail
            title={tLocal("TagsAdded")}
            ElementToRender={<Tags value={historyItem.tagsAdded} />}
        />
    );
};

const Tags = (props: any) => {
    const classes = useStyles();
    const { value } = props;

    return (
        <Typography className={classes.tags} noWrap>
            {value.map((tag: any) => (
                <Chip
                    className={classes.chipCard}
                    // color="secondary"
                    key={tag}
                    size="small"
                    label={tag}
                />
            ))}
        </Typography>
    );
};

export default ApplicantHistoryAddTagsAutomation;
