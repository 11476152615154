import { Grid, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import CardList from "./Card/CardList";
import TableResults from "./Table/TableResults";
import useList from "customHooks/useList";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {}
    })
);

interface IProps {}

const MainListRender = (props: IProps) => {
    const classes = useStyles();
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const { fields, queryFinished } = listData.listReturnInfo;
    const { resultType } = listData.localInfo;

    if (fields.length || !queryFinished) {
        if (queryFinished) {
            if (resultType === "grid" || resultType === "smallGrid") {
                return <CardList />;
            } else {
                return <TableResults />;
            }
        } else {
            return <Loading />;
        }
    } else {
        return (
            <Typography variant="subtitle1" gutterBottom>
                {t("ELASTICLISTSETTINGS:emptyResults")}
            </Typography>
        );
    }
};

export default MainListRender;
