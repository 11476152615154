import { Box, Divider, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { ApplicantScreeningQuestionnaireDataType } from "../useCompleoScreeningQuestionnaire";
import {
    getQuestionnaireMaxPossibleScore,
    getRankingValue
} from "./rankingHelper";
import SimulationResultAnswers from "./SimulationResultAnswers";

interface ISimulationResultProps {
    questionnaireValues: ApplicantScreeningQuestionnaireDataType;
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire;
    t: any;
}

const SimulationResult = (props: ISimulationResultProps) => {
    const { questionnaireValues, jobScreeningQuestionnaireData, t } = props;

    const rankingValue = getRankingValue(
        jobScreeningQuestionnaireData,
        questionnaireValues
    );
    const enableRanking = jobScreeningQuestionnaireData.enableRanking === true;
    return (
        <Box>
            <Box mb={2}>
                <Typography variant="body1">
                    <strong>{t("simulationResult")}</strong>
                </Typography>
            </Box>
            {enableRanking && (
                <Box width="100%" mb={1}>
                    <Alert style={{ width: "100%" }}>
                        <Box mt={1} width="100%">
                            <strong>{t("resultMaxScore")}</strong>:{" "}
                            {rankingValue.maxScore}
                        </Box>
                        <Box>
                            <strong>{t("resultFinal")}</strong>:{" "}
                            {rankingValue.finalScore}
                        </Box>
                        <Box>
                            <strong>{t("rankingScore")}</strong>:{" "}
                            {Math.round(
                                (rankingValue.ranking + Number.EPSILON) * 100
                            ) / 100}{" "}
                            %
                        </Box>
                        {rankingValue.resultByScore?.value && (
                            <Box>
                                <strong>{t("resultByScore")}</strong>:{" "}
                                {rankingValue.resultByScore?.value}
                            </Box>
                        )}
                    </Alert>
                </Box>
            )}
            <Box mb={2} mt={2}>
                <Typography variant="body1">
                    <strong>{t("answers")}</strong>
                </Typography>
            </Box>
            <SimulationResultAnswers
                questionnaireValues={questionnaireValues}
                t={t}
                jobScreeningQuestionnaireData={jobScreeningQuestionnaireData}
            />
            {questionnaireValues?.result && (
                <Box mt={1}>
                    <strong>{t("result")}: </strong>{" "}
                    {questionnaireValues?.result}
                </Box>
            )}
        </Box>
    );
};

export default SimulationResult;
