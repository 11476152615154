import { apiDirectCall, useApiCache } from "customHooks/useApi";
import React from "react";
import { useAuthState } from "./AuthContext";
import { useQueryClient } from "react-query";
import _ from "lodash";

/**
 * tempsession: Value that only is persisted temporarily (not in db) |
 * userdb: User session (persisted in database) |
 * userdb: Company session (persisted in database)
 */
export type GlobalValuesType = "tempsession" | "userdb" | "companydb";

interface IGlobalValuesState {
    name: string;
    value: Compleo.IObject;
    type: GlobalValuesType;
}

export type IGlobalValues = {
    [K in GlobalValuesType]: Compleo.IObject;
};

export interface IGlobalValuesDispatch {
    addOrEditGlobalValue: (params: IGlobalValuesState) => void;
    isReady: () => boolean;
}

const GlobalValuesContext = React.createContext<IGlobalValues | undefined>(
    undefined
);
const GlobalValuesContextDispatch = React.createContext<
    IGlobalValuesDispatch | undefined
>(undefined);

interface IProps {
    children: any;
}

export const GlobalValuesProvider = (props: any) => {
    const { children } = props;
    const [globalValues, setGlobalValues] = React.useState<
        IGlobalValuesState[]
    >([]);
    const { company } = useAuthState();
    const queryClient = useQueryClient();
    const userSessionQueryKey = "userSessions";

    const [userSessionDB, runUserSessionDB] = useApiCache(
        "/sessions/listusersession",
        "post",
        { companyId: company.companyId },
        true,
        1000 * 60 * 10,
        false,
        userSessionQueryKey
    );

    React.useEffect(() => {
        if (company.companyId) {
            runUserSessionDB({ companyId: company.companyId });
        }
    }, [company.companyId]);

    const userDbData: IGlobalValuesState[] =
        company.companyId > 0
            ? (userSessionDB.response?.data || []).map(
                  (item: Compleo.IObject) => {
                      return {
                          name: item.name,
                          value: item.value,
                          type: "userdb"
                      };
                  }
              )
            : [];

    const addOrEditGlobalValue = async (params: IGlobalValuesState) => {
        if (params.type === "tempsession") {
            const newGlobalValuesType = [...globalValues].filter(
                (item) => item.name !== params.name && item.type === params.type
            );
            const globalValuesFiltered = [...globalValues].filter(
                (item) => item.type !== params.type
            );
            setGlobalValues([
                ...newGlobalValuesType,
                ...globalValuesFiltered,
                params
            ]);
        } else if (params.type === "userdb") {
            const currentData = userDbData.filter(
                (item) => item.name === params.name
            )[0];
            if (currentData && _.isEqual(currentData.value, params.value)) {
                // console.log("isEqual");
            } else {
                const result = await apiDirectCall(
                    "/sessions/newusersession",
                    "post",
                    {
                        companyId: company.companyId,
                        sessionName: params.name,
                        value: params.value
                    }
                );
                if (result.status === 200) {
                    queryClient.invalidateQueries(userSessionQueryKey);
                }
            }
        }
    };

    const isReady = () => {
        return userSessionDB.status === "success";
    };

    const returnDispatch: IGlobalValuesDispatch = {
        addOrEditGlobalValue: addOrEditGlobalValue,
        isReady: isReady
    };

    const returnObj: IGlobalValues = {
        companydb: {},
        tempsession: {},
        userdb: {}
    };
    globalValues.map((item) => {
        returnObj[item.type][item.name] = item.value;
    });
    userDbData.map((item) => {
        returnObj[item.type][item.name] = item.value;
    });

    return (
        <GlobalValuesContext.Provider value={returnObj}>
            <GlobalValuesContextDispatch.Provider value={returnDispatch}>
                {children}
            </GlobalValuesContextDispatch.Provider>
        </GlobalValuesContext.Provider>
    );
};

const useGlobalValuesState = () => {
    const context = React.useContext(GlobalValuesContext);
    if (!context) {
        throw new Error(
            `useGlobalValues must be used within a GlobalValuesProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useGlobalValuesDispatch = () => {
    const context = React.useContext(GlobalValuesContextDispatch);
    if (!context) {
        throw new Error(
            `useGlobalValues must be used within a GlobalValuesDispatchProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useGlobalValues: () => [IGlobalValues, IGlobalValuesDispatch] = () => {
    return [useGlobalValuesState(), useGlobalValuesDispatch()];
};

export default useGlobalValues;
