import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import CheckboxWithList from "customHooks/useCompleoForm/Inputs/CheckboxWithList";
import { Field } from "formik";
import { FieldProps } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useApiCache } from "customHooks/useApi";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

interface IProps {
    label: string;
}

export default function ListDividers(props: IProps & FieldProps) {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("PLANFEATURES", {
        useSuspense: false
    });

    const { company } = useAuthState();
    const plan = company.plan || "noplan";
    const postAddressSearch = `/general/listfeatureplan/${plan}`;
    const [getFeaturesReturn] = useApiCache(
        postAddressSearch,
        "get",
        {},
        false
    );

    const {
        field,
        form: { dirty, touched, errors, status },
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let mensagemErro = hasError && errorText !== undefined ? errorText : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    let classError = classes.color;
    if (hasError) {
        classError = classes.colorError;
    }

    let listFeatures: any = {};
    let listGroups: any = {};

    if (getFeaturesReturn.status === "success") {
        const data = getFeaturesReturn.response.data.data;
        listFeatures = data.groups.groups;
        listGroups = data.groups.MainGroupFeatures;
    }

    if (readyTranslation && getFeaturesReturn.status === "success") {
        return (
            <React.Fragment>
                <List component="nav" className={classes.root}>
                    {hasError && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {mensagemErro}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    {listGroups.map((i: any) => (
                        <React.Fragment key={i.name}>
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        className={classError}
                                        primary={t(i.name)}
                                        secondary={t(`help_${i.name}`)}
                                    />
                                </ListItem>
                            </List>
                            {listFeatures
                                .filter(
                                    (fi: any) => fi.mainGroupName === i.name
                                )
                                .map((f: any) => (
                                    <Field
                                        name={field.name}
                                        value={f.name}
                                        component={CheckboxWithList}
                                        exclude={f.excludeItemOnChoose}
                                        f={f}
                                        t={t}
                                        key={f.name}
                                        data_testid={`field_${f.name}`}
                                        secondaryText={t(`help_${f.name}`, "")}
                                    />
                                ))}
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>
        );
    } else {
        return <Loading />;
    }
}
