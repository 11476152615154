import { processEmailFields } from "customHooks/useCompleoReactHookForm/components/FormMainHelper";
import _ from "lodash";

export const getHandlePipelineSubmit = (fnToRun: any) => {
    const HandleSubmit = async (values: any) => {
        try {
            const companyId = values.companyId;
            const clonedValues = _.cloneDeep(values);
            const stages = values?.stages || [];
            for (let index = 0; index < stages.length; index++) {
                for (const fieldStageName of Object.keys(stages[index])) {
                    if (Array.isArray(stages[index][fieldStageName])) {
                        for (
                            let i = 0;
                            i < stages[index][fieldStageName].length;
                            i++
                        ) {
                            clonedValues.stages[index][fieldStageName][
                                i
                            ] = await processEmailFields({
                                ...stages[index][fieldStageName][i],
                                companyId: companyId
                            });
                        }
                    }
                }
            }

            return fnToRun(clonedValues);
            // return;
        } catch (ex) {
            return ex.response;
        }
    };
    return HandleSubmit;
};
