"use client";

import {
    ColumnDef,
    ColumnFiltersState,
    GroupingState,
    SortingState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getGroupedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import React from "react";
import { DataTablePagination } from "./datatable-pagination";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import Paper from "@material-ui/core/Paper";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        borderRight: "0.5px solid lightgrey",
        minWidth: 200,
        zIndex: 1
    }
});

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    enableGlobalFilter?: boolean;
    fixFirstColumn?: boolean;
    horizontalLines?: boolean;
}

export function DataTable<TData, TValue>({
    columns,
    data,
    enableGlobalFilter = false,
    fixFirstColumn = false,
    horizontalLines = false
}: DataTableProps<TData, TValue>) {
    const classes = useStyles();
    const [t, , readyTranslation] = useTranslation("DATATABLE", {
        useSuspense: false
    });

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<
        ColumnFiltersState
    >([]);
    const [grouping, setGrouping] = React.useState<GroupingState>([]);

    const table = useReactTable({
        data,
        columns,
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            grouping,
            sorting,
            columnFilters
        }
    });

    if (!readyTranslation) {
        return <Loading />;
    }
    return (
        <div>
            {enableGlobalFilter && (
                <div className="flex items-center py-4">
                    <TextField
                        placeholder={`${t("filter")}...`}
                        onChange={(event) => {
                            table.setGlobalFilter(event.target.value);
                        }}
                        className="max-w-sm"
                    />
                </div>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => {
                                    return (
                                        <TableCell
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className={
                                                fixFirstColumn && index === 0
                                                    ? classes.sticky
                                                    : ""
                                            }
                                            style={{
                                                borderRight: horizontalLines
                                                    ? "0.5px solid lightgrey"
                                                    : "none"
                                            }}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div style={{ width: "100%" }}>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && "selected"
                                    }
                                >
                                    {row
                                        .getVisibleCells()
                                        .map((cell, index) => (
                                            <TableCell
                                                key={cell.id}
                                                className={
                                                    fixFirstColumn &&
                                                    index === 0
                                                        ? classes.sticky
                                                        : ""
                                                }
                                                {...{
                                                    style: {
                                                        background: cell.getIsGrouped()
                                                            ? "#f4f6f8"
                                                            : cell.getIsAggregated()
                                                            ? "#f4f6f8"
                                                            : cell.getIsPlaceholder()
                                                            ? "#f4f6f8"
                                                            : "white",
                                                        borderRight: horizontalLines
                                                            ? "0.5px solid lightgrey"
                                                            : "none"
                                                    }
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <div
                                                        {...{
                                                            onClick: row.getToggleExpandedHandler(),
                                                            style: {
                                                                width: "100%",
                                                                cursor: row.getCanExpand()
                                                                    ? "pointer"
                                                                    : "normal"
                                                            }
                                                        }}
                                                    >
                                                        {row.getIsExpanded() ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}{" "}
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}{" "}
                                                        ({row.subRows.length})
                                                    </div>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    <div
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef
                                                                .aggregatedCell ??
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                            cell.getContext()
                                                        )}
                                                    </div>
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    <div
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </div>
                                                )}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    {t("noResults")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <DataTablePagination table={table} />
            </TableContainer>
        </div>
    );
}
