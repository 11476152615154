import React from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import ApplicantAssessmentsList from "./ApplicantAssessmentsList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "90%"
        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            backgroundColor: theme.palette.backgroundGrey.main,
            overflow: "auto"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {},
        refreshButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        }
    })
);

const ApplicantAssessments = (props: any) => {
    const classes = useStyles();
    const { company, timeZone, user } = useAuthState();
    const queryClient = useQueryClient();
    const [tLocal, i18n, readyTranslation] = useTranslation(
        "APPLICANTVIEWASSESSMENTS",
        {
            useSuspense: false
        }
    );

    const [data] = useApplicantViewContext();
    const { t, language } = data;
    const ready = readyTranslation;

    if (ready) {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {t("a_StepForm_applicantAssessments")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <ApplicantAssessmentsList t={tLocal} language={language} />
                </Box>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default ApplicantAssessments;
