import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("buttonEditScheduleTemplate"),
                onClick: (event: any, rowData: any) => {
                    navigate(
                        `/scheduletemplate/edit/${
                            rowData.skGS1pk.split(":")[1]
                        }`
                    );
                }
            },
            {
                icon: "delete",
                tooltip: t("buttonDeleteScheduleTemplate"),
                onClick: async (event: any, rowData: any) => {
                    const uuid = rowData.skGS1pk.split(":")[1];
                    const postAddress = `/scheduletemplate/delete`;

                    callDialog({
                        title: t("ScheduleTemplateDelete_ModalTitle"),
                        bodyText: t("ScheduleTemplateDelete_Message"),
                        agreeButtonText: t("ScheduleTemplateDelete_yes"),
                        disagreeButtonText: t("ScheduleTemplateDelete_no"),
                        agreeFunction: async () => {
                            //const uuid = rowData.skGS1pk.split(":")[1];
                            const result = await apiDirectCall(
                                postAddress,
                                "post",
                                {
                                    companyId: companyId,
                                    uuid: uuid
                                }
                            );
                            if (result.status === 200) {
                                showMessage(
                                    t("ScheduleTemplateDelete_succesMessage"),
                                    "success"
                                );
                                refreshListFunction();
                                return true;
                            }
                            return false;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            }
        ];

        return result;
    };
};

export default useActionsTable;
