import React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import _ from "lodash";
import { getErrorAndMessages } from "./util/general";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    maxLength?: number;
    onChange?: any;
    t?: any;
}

const FTextField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue },
        helperTextDefault,
        maxLength,
        t,
        ...other
    } = props;

    const { error, message } = getErrorAndMessages(
        errors,
        field.name,
        touched,
        status,
        helperTextDefault
    );

    return (
        <TextField
            id={field.name}
            label={label}
            error={error}
            helperText={message}
            {...field}
            {...other}
            // margin="normal"
            inputProps={{
                maxLength: maxLength
            }}
            // onChange={onChangeMethod}
        />
    );
};

const isEqual = (
    prevProps: IProps & FieldProps,
    nextProps: IProps & FieldProps
) => {
    if (prevProps.field.value === nextProps.field.value) {
        return true;
    }
    return false;
};
// avaliar melhor no futuro. Comportamento estava incorreto
// mensagens de erro não eram atualizadas
// export default React.memo(FTextField, isEqual);
export default FTextField;
