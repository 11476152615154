import React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import {
    parsePhoneNumberFromString,
    AsYouType,
    getCountries,
    getCountryCallingCode,
    CountryCode,
    parseIncompletePhoneNumber
} from "libphonenumber-js";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

// @ts-ignore
import en from "react-phone-number-input/locale/en.json";

const PhoneNumberCompleo = (props: IInputProps) => {
    const startCountry: CountryCode = "BR";
    const [country, setCountry] = React.useState(startCountry);
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;

    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        name,
        helperTextDefault
    );

    React.useEffect(() => {
        changeValue(field.value, false);
    }, []);

    const handleChange = (e: any) => {
        changeValue(e.target.value);
    };

    const changeValue = (value: any, addCountryCode: boolean = true) => {
        if (value !== undefined && value !== "") {
            let fromCountry: any = country;
            let phoneNumber = value;
            if (addCountryCode) {
                phoneNumber = `+${getCountryCallingCode(fromCountry)}${value}`;
            }
            const formatedPhoneNumber = formatPhoneNumber(phoneNumber);
            const countryFromPhoneNumber = getCountryName(formatedPhoneNumber);

            if (
                countryFromPhoneNumber !== undefined &&
                countryFromPhoneNumber !== ""
            ) {
                fromCountry = countryFromPhoneNumber;
                setCountry(fromCountry);
            }

            setStateValues(formatedPhoneNumber);
        } else {
            setFieldValue("");
        }
    };

    const setStateValues = (value: string) => {
        const unformatedValue = parseIncompletePhoneNumber(value);
        setFieldValue(unformatedValue);
    };

    const formatPhoneNumber = (value: string) => {
        const phoneNumberParsed = parsePhoneNumberFromString(value);

        if (phoneNumberParsed !== undefined) {
            const countryCallingCode = phoneNumberParsed.countryCallingCode;
            const phoneNumber = new AsYouType(phoneNumberParsed.country).input(
                phoneNumberParsed.nationalNumber.toString()
            );
            let finalPhoneNumber = `+${countryCallingCode} ${phoneNumber}`;

            if (finalPhoneNumber.slice(-1) === ")") {
                finalPhoneNumber = finalPhoneNumber
                    .replace("(", "")
                    .replace(")", "");
            }

            return finalPhoneNumber;
        } else {
            return value;
        }
    };

    const getCountryName = (value: string) => {
        const phoneNumberParsed = parsePhoneNumberFromString(value);

        if (phoneNumberParsed !== undefined) {
            if (phoneNumberParsed.country == undefined) {
                return "";
            } else {
                return phoneNumberParsed.country;
            }
        } else {
            return "";
        }
    };

    const updateCountryFromPhoneNumber = (value: string) => {
        if (value !== undefined && value !== "") {
            let fromCountry: any = country;
            const countryFromPhoneNumber = getCountryName(value);
            if (
                countryFromPhoneNumber !== undefined &&
                countryFromPhoneNumber !== ""
            ) {
                fromCountry = countryFromPhoneNumber;
                if (country !== fromCountry) {
                    setCountry(fromCountry);
                }
            }
        }
    };

    const handleCountryChange = (value: any) => {
        setCountry(value);

        const phoneNumberParsed = parsePhoneNumberFromString(field.value);
        if (phoneNumberParsed !== undefined) {
            const countryCallingCode = phoneNumberParsed.countryCallingCode;
            const newCountryCallingCode = getCountryCallingCode(value);
            let newPhoneNumber = fieldValue.replace(
                `+${countryCallingCode}`,
                `+${newCountryCallingCode}`
            );
            newPhoneNumber = formatPhoneNumber(newPhoneNumber);
            setStateValues(newPhoneNumber);
        } else {
            const newPhoneNumber = ``;
            setStateValues(newPhoneNumber);
        }
    };

    let newCountries: { [key: string]: any };
    newCountries = en;

    const removeCountryCodeFromShowValue = () => {
        const value = field.value || "";
        const countryCallingCode = getCountryCallingCode(country);
        const newValue = formatPhoneNumber(value);
        updateCountryFromPhoneNumber(value);
        const withoutCountryCode = newValue.replace(
            `+${countryCallingCode}`,
            ""
        );
        return withoutCountryCode.trim();
    };

    const fieldValue = removeCountryCodeFromShowValue();

    return (
        <React.Fragment>
            <TextField
                id={name}
                label={label}
                error={error}
                helperText={message}
                {...other}
                value={fieldValue}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <NativeSelect
                                value={country}
                                onChange={(e: any) =>
                                    handleCountryChange(e.target.value)
                                }
                                inputProps={{
                                    name: `country${name}`,
                                    id: `country${name}`,
                                    "aria-label": "Choose a country"
                                }}
                            >
                                {getCountries().map(
                                    (countryItem: CountryCode) => (
                                        <option
                                            key={countryItem}
                                            value={countryItem}
                                        >
                                            {newCountries[countryItem]} +
                                            {getCountryCallingCode(countryItem)}
                                        </option>
                                    )
                                )}
                            </NativeSelect>
                        </InputAdornment>
                    )
                }}
                inputRef={field.ref}
                onBlur={field.onBlur}
            />
        </React.Fragment>
    );
};

export default PhoneNumberCompleo;
