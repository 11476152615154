import React from "react";
import { IJobFunnelChildren } from "./funnelHelper";
import CompleoMUITable, {
    ICompleoMUITableRowsDef
} from "Components/CompleoMUITable/CompleoMUITable";
import { Alert } from "@material-ui/lab";

const JobDisqualifyReasonsTable = (props: IJobFunnelChildren) => {
    const { mainData, t, exportFileName } = props;

    const data = mainData?.data?.disqualifyReasonsWithStage;
    data.sort((a, b) => b.total - a.total);
    const columns = getColumnsMaterialTableData(t);
    // data[0].total
    if (data.length) {
        return (
            <CompleoMUITable
                columns={columns}
                data={data}
                t={t}
                // tableContainerStyle={{ maxHeight: "80%" }}
                enableExportToCSV
                exportFileName={exportFileName}
                reportPDFTitle={t("reasonsForRejectionTable_Name")}
            />
        );
    } else {
        return (
            <Alert severity="warning" style={{ margin: 16 }}>
                {t("noRejectedApplicants")}
            </Alert>
        );
    }
};

const getColumnsMaterialTableData: (t: any) => ICompleoMUITableRowsDef[] = (
    t
) => {
    return [
        {
            name: t("disqualifyReason"),
            fieldName: "disqualifyReason"
        },
        {
            name: t("stageDisqualified"),
            fieldName: "stageDisqualified"
        },
        {
            name: t("total"),
            fieldName: "total"
        }
    ];
};

export default JobDisqualifyReasonsTable;
