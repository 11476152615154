import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useHandleInitialValues from "./components/useHandleInitialValues";
import useGetLanguagesCareers from "../../customHooks/useGetLanguages/useGetLanguages";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import CustomComponents from "./components/CustomComponents";

const ApplicationFormNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/applicationform/list");
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const languagesString = (languages || []).map((l: any) => l.value);
    const moduleName = "APPLICATIONFORMNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/applicationform/new`;

    const [addApplicationFormReturn, addApplicationForm] = useApi(
        postAddress,
        "post"
    );
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.deleted = false;
    valuesFromSource.default = false;

    const {
        initialValues,
        translations,
        readyInitialValues,
        messages
    } = useHandleInitialValues(
        valuesFromSource,
        languagesString || [],
        defaultLanguage
    );

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "translations",
            props: {
                translations: translations,
                messages: messages,
                languages: languages,
                defaultLanguage: defaultLanguage
            }
        }
    ];

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        postMethod: addApplicationForm,
        postReturn: addApplicationFormReturn,
        redirectAddress: "/applicationform/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction,
        additionalFieldProperties: addProps
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation && readyInitialValues && readyLanguages) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default ApplicationFormNew;
