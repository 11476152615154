import React from "react";
import { Auth, API } from "aws-amplify";

const LoginCypress = (props: any) => {
    const [email, setEmail] = React.useState("");
    const signIn = async () => {
        const user = await Auth.signIn({
            username: props.email,
            password: props.password
        });
        return user;
    };

    React.useEffect(() => {
        signIn().then((user: any) => {
            setEmail(props.email);
        });
    }, []);

    return <code>{email}</code>;
};

export default LoginCypress;
