import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "delete",
                tooltip: t("NotificationDelete_ModalTitle"),
                onClick: async (event: any, rowData: any) => {
                    // const mainId = rowData.pk.split(":")[0];
                    const notificationId = rowData.skGS1pk;
                    const postAddress = `/notifications/delete`;

                    callDialog({
                        title: t("buttonDeleteNotification"),
                        bodyText: t("NotificationDelete_ModalMessage"),
                        agreeButtonText: t("NotificationDelete_ModalButtonYes"),
                        disagreeButtonText: t(
                            "NotificationDelete_ModalButtonNo"
                        ),
                        agreeFunction: async () => {
                            const result = await apiDirectCall(
                                postAddress,
                                "post",
                                {
                                    companyId: companyId,
                                    notificationId: notificationId
                                }
                            );
                            if (result.status === 200) {
                                showMessage(
                                    t("NotificationDelete_SucessMessage"),
                                    "success"
                                );
                                refreshListFunction();
                                return true;
                            }
                            return false;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            },
            (rowData: any) => {
                // console.log("rowData", rowData);
                let hidden = false;
                if (rowData.read === true) {
                    hidden = true;
                }

                return {
                    icon: "check",
                    tooltip: t("markAsRead"),
                    onClick: async (event: any, rowData: any) => {
                        const notificationId = rowData.skGS1pk;
                        const postAddress = `/notifications/settoread`;

                        const result = await apiDirectCall(
                            postAddress,
                            "post",
                            {
                                companyId: companyId,
                                notificationId: notificationId
                            }
                        );
                        if (result.status === 200) {
                            refreshListFunction();
                            return true;
                        }
                    },
                    hidden: hidden
                };
            },
            (rowData: any) => {
                const hidden = rowData?.link === undefined;
                return {
                    icon: "visibility",
                    tooltip: t("COMPLEOGENERAL_View"),
                    onClick: async (event: any, rowData: any) => {
                        navigate(rowData.link);
                    },
                    hidden: hidden
                };
            }
        ];

        return result;
    };
};

export default useActionsTable;
