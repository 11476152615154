import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useApi, useApiCache } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import { useAuthState } from "_ReactContext/AuthContext";

const CustomCVEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/customcv/list");
    const moduleName = "CUSTOMCVEDIT";
    const { company } = useAuthState();

    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/customcv/edit`;
    const [customLists, readyLists] = useCustomLists();
    const [editDataReturn, editData] = useApi(postAddress, "post");

    const postAddressSearch = `/customcv/search`;
    const [getDataSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { uuid: props.uuid, companyId: company.companyId },
        false
    );

    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getDataSearchResult,
        ["data", "data"]
    );
    valuesFromSource.id = props.uuid;
    const defaultFieldsToHide: string[] = [];
    // const editCustomCV = async (values: any) => {
    //     return await editData(values);
    // };

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready:
            readyTranslation &&
            readyLanguages &&
            readyLists &&
            metadata.status === "success" &&
            getDataSearchResult.status === "success",
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource
    });

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready:
            readyTranslation &&
            readyLanguages &&
            readyLists &&
            metadata.status === "success" &&
            getDataSearchResult.status === "success",
        i18nV: i18n,
        postMethod: editData,
        postReturn: editDataReturn,
        redirectAddress: `/customcv/simulate/${props.uuid}`, //"/customcv/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        customLists: customLists,
        fieldsToHide: fieldsToHide,
        reactHookFormMethods: reactHookFormMethods,
        invalidateQueryClientItems: ["/customcv/search", "/customcv/list"]
    });
    const ready =
        readyTranslation &&
        readyLanguages &&
        readyLists &&
        metadata.status === "success" &&
        getDataSearchResult.status === "success";

    // const [formCompleo, finished] = useCompleoFormNew({
    //     t,
    //     ready:
    //         readyTranslation &&
    //         readyLists &&
    //         getDataSearchResult.status === "success",
    //     i18nV: i18n,
    //     postMethod: editCustomCV,
    //     postReturn: editDataReturn,
    //     redirectAddress: "/customcv/list",
    //     FormType: FormClassic,
    //     metadadosRetorno: metadata,
    //     valuesFromSource: valuesFromSource,
    //     secondaryButtonFunction: cancelFunction,
    //     formikEnableReinitialize: false,
    //     customLists: customLists,
    //     fieldsToHide: fieldsToHide,
    //     formikReturn: formikReturn,
    //     invalidateQueryClientItems: ["/customcv/search", "/customcv/list"]
    // });
    // const ready =
    //     readyTranslation &&
    //     finished &&
    //     readyLists &&
    //     getDataSearchResult.status === "success";

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomCVEdit;
