import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { IBucketReportItem } from "../BucketReportTable";
import { Box } from "@material-ui/core";

const BucketReportBar = (props: IBucketReportItem) => {
    const { aggregation, title, t, totalFilteredValue } = props;
    const data = (aggregation?.items || []).map((i: Compleo.IObject) => {
        return {
            value: i.total,
            name: i.label
        };
    });

    const finalData = data;

    const colorData = useRandomColor({
        objectKeys: ["default"],
        luminosity: "dark"
    });

    return (
        <Box width="100%" height={350}>
            <ResponsiveContainer minWidth="90%" minHeight="90%">
                <BarChart
                    data={finalData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                    maxBarSize={45}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        // scale="point"
                        // padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar
                        dataKey="value"
                        fill={colorData.colorsObject.default || "red"}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default BucketReportBar;
