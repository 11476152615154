export interface ICalendarEvent {
    id: string;
    title: string;
    allDay?: boolean;
    start: Date;
    end: Date;
    participants: string;
    user: string;
    location: string;
    organizer: string;
    jobTitle?: string;
    applicantName?: string;
    mainData: Compleo.IObject;
}

export const getCalendarEventsFromDBData = (dbData: Compleo.IObject[]) => {
    const myEventsList: ICalendarEvent[] = [];
    dbData.map((item, index) => {
        if (item.deleted !== true) {
            const participants = (item.recipients || []).map(
                (item: Compleo.IObject) => item.name
            );
            myEventsList.push({
                id: item.pk.split(":")[1],
                title: item?.subject,
                participants: participants.join(", "),
                end: new Date(item.endDateTime),
                start: new Date(item.startDateTime),
                user: item?.createdByUser || "",
                location: item?.location,
                organizer: item?.createdByUser,
                applicantName: item?.applicantDetail?.name,
                jobTitle: item?.jobDetail?.title,
                mainData: item
            });
        }
    });
    return myEventsList;
};
