import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    InstapaperShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    TelegramIcon,
    InstapaperIcon
} from "react-share";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useJobContext from "../../useJobContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnShare: {
            marginRight: theme.spacing(0.3)
        }
    })
);

interface IJobShareButtons {
    shareUrl: string;
    t: any;
}
const JobShareButtons = (props: IJobShareButtons) => {
    const { shareUrl, t } = props;
    const classes = useStyles();
    const [data] = useJobContext();
    const quote = t("defaultShareMessage", {
        jobTitle: data?.Job?.data?.title
    });

    return (
        <>
            <FacebookShareButton
                url={`${shareUrl}?source=facebook`}
                quote={quote}
                className={classes.btnShare}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
                url={`${shareUrl}?source=twitter`}
                title={quote}
                className={classes.btnShare}
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton
                url={`${shareUrl}?source=linkedin`}
                title={quote}
                className={classes.btnShare}
            >
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton
                url={`${shareUrl}?source=whatsapp`}
                title={quote}
                className={classes.btnShare}
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton
                url={`${shareUrl}?source=email`}
                title={quote}
                className={classes.btnShare}
            >
                <EmailIcon size={32} round />
            </EmailShareButton>
            <TelegramShareButton
                url={`${shareUrl}?source=telegram`}
                title={quote}
                className={classes.btnShare}
            >
                <TelegramIcon size={32} round />
            </TelegramShareButton>
        </>
    );
};

export default JobShareButtons;
