import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useDashboardContext from "../useDashboardContext";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { DashboardPageToRenderType } from "../useDashboardContext/DashboardTypes";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#00a152",
            padding: 8
        }
    });
});

const DashboardTitle = () => {
    const classes = useStyles();
    const [data] = useDashboardContext();
    const { t } = data;

    const currentDashName = data.localStateData?.currentDashboardDef?.name;
    const currentDashType = data.localStateData?.currentDashboardDef?.type;
    const pagesToRender: DashboardPageToRenderType[] = [
        "applicants",
        "jobs",
        "myDashboards",
        "applicantsJobView"
    ];

    if (
        pagesToRender.includes(data.localStateData?.pageToRender) &&
        currentDashName &&
        currentDashType
    ) {
        const typeName =
            currentDashType === "company" ? "typeCompany" : "typeUser";
        return (
            <Box ml={1.2}>
                {" "}
                <Chip
                    className={classes.chip}
                    size="medium"
                    label={
                        <Typography component="p" variant="body1">
                            {`${t("dashName")}: `}
                            <strong>{currentDashName}</strong>
                            {` - ${t(typeName)}`}
                        </Typography>
                    }
                />
            </Box>
        );
    } else {
        return null;
    }
};

export default DashboardTitle;
