import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";

const useActionsTable = (
    setOpenModalAuto: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            operationId: string;
            refreshListFunction: () => void;
        }>
    >,
    t: any
) => {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            (rowData: any) => {
                return {
                    icon: "edit",
                    tooltip: t("COMPLEOGENERAL_Edit"),
                    onClick: (event: any, rowData: any) => {
                        navigate(
                            `/applicant/parserlistdetail/${
                                rowData.skGS1pk.split(":")[1]
                            }`
                        );
                    }
                };
            },
            (rowData: any) => {
                const status: CompleoShared.ParserOperation.parserOperationStatus =
                    rowData.status?.value;
                const hidden = status === "processed";

                return {
                    icon: "check",
                    tooltip: t("process"),
                    onClick: (event: any, rowData: any) => {
                        setOpenModalAuto({
                            open: true,
                            operationId: rowData.skGS1pk.split(":")[1],
                            refreshListFunction: refreshListFunction
                        });
                    },
                    hidden: hidden
                };
            }
        ];

        return result;
    };
};

export default useActionsTable;
