import useDynamicDashboard from "customHooks/useDynamicDashboard";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import _ from "lodash";
import React from "react";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useGlobalValues from "_ReactContext/GlobalValues";

const useDefaultFilter = () => {
    const { callDialog } = useGlobalDialog();
    const showMessage = useShowMessage();

    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const [listData, listDispatch] = useDynamicDashboard();
    const { setFilters, setLocalInfo } = listDispatch;
    const { t, moduleName } = listData.tempInfo;
    const sessionName = `defaultFilter_${moduleName}`;
    const defaultFilterValue = globalValues.userdb[sessionName];
    const currentFilterData = {
        filters: listData.filtersInfo.filters,
        localInfo: listData.localInfo
    };

    const setCurrentFilterAsDefault = () => {
        callDialog({
            title: t("ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterTitle"),
            bodyText: t(
                "ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterMessage"
            ),
            disagreeButtonText: t(
                "ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterNo"
            ),
            agreeButtonText: t(
                "ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterYes"
            ),
            agreeFunction: async () => {
                globalValuesDispatch.addOrEditGlobalValue({
                    type: "userdb",
                    name: sessionName,
                    value: currentFilterData
                });
                showMessage({
                    message: t(
                        "ELASTICLISTSETTINGS:LoadFilters_SuccessMessage"
                    ),
                    snackBarOrign: { vertical: "bottom", horizontal: "right" },
                    timeout: 1000 * 2
                });
            },
            disagreeFunction: async () => {
                return false;
            }
        });
    };

    const loadDefaultFilter = () => {
        debugger;
        if (globalValues.userdb[sessionName]) {
            callDialog({
                title: t(
                    "ELASTICLISTSETTINGS:LoadFilters_LoadDefaultFilterTitle"
                ),
                bodyText: t(
                    "ELASTICLISTSETTINGS:LoadFilters_LoadDefaultFilterMessage"
                ),
                disagreeButtonText: t(
                    "ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterNo"
                ),
                agreeButtonText: t(
                    "ELASTICLISTSETTINGS:LoadFilters_SetDefaultFilterYes"
                ),
                agreeFunction: async () => {
                    const defaultFilterData = globalValues.userdb[sessionName];
                    const filters = _.cloneDeep(
                        defaultFilterData?.filters || {}
                    );
                    const localInfo = _.cloneDeep(
                        defaultFilterData?.localInfo || {}
                    );
                    localInfo.loadedMainSearchFromFilterSaved =
                        filters.mainSearch || "";

                    setFilters({
                        ...listData.filtersInfo.filters,
                        ...filters,
                        filterUpdated: true
                    });
                    setLocalInfo({
                        ...listData.localInfo,
                        ...localInfo
                    });
                    showMessage({
                        message: t(
                            "ELASTICLISTSETTINGS:LoadFilters_LoadSuccessMessage"
                        ),
                        snackBarOrign: {
                            vertical: "bottom",
                            horizontal: "right"
                        },
                        timeout: 1000 * 2
                    });
                },
                disagreeFunction: async () => {
                    return false;
                }
            });
        } else {
            showMessage({
                message: t("ELASTICLISTSETTINGS:LoadFilters_NoDefaultFilter"),
                snackBarOrign: { vertical: "top", horizontal: "right" },
                timeout: 1000 * 10,
                typeMessage: "error"
            });
        }
    };

    return [defaultFilterValue, setCurrentFilterAsDefault, loadDefaultFilter];
};

export default useDefaultFilter;
