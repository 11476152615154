import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PDFViewerStream from "Components/PDFViewerStream";
import { Box } from "@material-ui/core";

export type AllowedExtensionsViewFile =
    | "pdf"
    | "jpg"
    | "jpeg"
    | "blank"
    | "png"
    | "gif";
export const AllowedExtensionsViewFileList: AllowedExtensionsViewFile[] = [
    "pdf",
    "jpg",
    "jpeg",
    "png",
    "gif"
];

interface IModalViewFile {
    open: boolean;
    handleClose: () => void;
    t: any;
    extension: AllowedExtensionsViewFile;
    path: string;
    isLocalStreamUrl?: boolean;
}

const ModalViewFile = (props: IModalViewFile) => {
    const {
        open,
        handleClose,
        t,
        extension,
        path,
        isLocalStreamUrl = false
    } = props;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth
            >
                {/* <DialogTitle id="form-dialog-title">
                    {t("CGT_editTags")}
                </DialogTitle> */}
                <DialogContent>
                    <RenderContent {...props} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="text"
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const RenderContent = (props: IModalViewFile) => {
    const { open, handleClose, t, extension, path, isLocalStreamUrl } = props;
    switch (extension) {
        case "pdf":
            return (
                <PDFViewerStream
                    path={path}
                    isLocalStreamUrl={isLocalStreamUrl}
                />
            );
        case "jpeg":
        case "jpg":
        case "png":
        case "gif":
            return (
                <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    alignSelf="center"
                    justifyItems="center"
                >
                    <img src={path} alt="" style={{ maxWidth: "100%" }} />
                </Box>
            );
        default:
            return null;
    }
};

export default ModalViewFile;
