import {
    Box,
    Divider,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import {
    ApplicantScreeningQuestionnaireAnswerDataType,
    ApplicantScreeningQuestionnaireDataType
} from "../useCompleoScreeningQuestionnaire";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { answerTypesWithWeight, getAnswerWeightDetails } from "./rankingHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        nested2: {
            paddingLeft: theme.spacing(8)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);

interface ISimulationResultProps {
    questionnaireValues: ApplicantScreeningQuestionnaireDataType;
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire;
    t: any;
}

const SimulationResultAnswers = (props: ISimulationResultProps) => {
    const { questionnaireValues, t, jobScreeningQuestionnaireData } = props;
    const classes = useStyles();

    return (
        <Box>
            {questionnaireValues.answers.map((answerData, index) => {
                const fileTypes: string[] = [
                    "CPQUESTIONSINGLEFILE",
                    "CPQUESTIONMULTIPLEFILES"
                ];
                let fileData: Compleo.IObject = {};
                const isFile = fileTypes.includes(
                    answerData?.question?.questiontype?.value
                );
                if (isFile) {
                    fileData.file = answerData.answer.map((a) => a.value);
                }
                const isLast = index === questionnaireValues.answers.length - 1;
                const questionText = getQuestionText(
                    answerData,
                    jobScreeningQuestionnaireData,
                    t
                );

                return (
                    <React.Fragment key={answerData.question.id}>
                        <ListItem className={classes.nested2}>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                        >
                                            <strong>
                                                {answerData.question.name}
                                            </strong>{" "}
                                            {questionText}
                                        </Typography>
                                    </>
                                }
                                secondary={
                                    !isFile ? (
                                        <Typography variant="body2">
                                            {(answerData.answer || []).map(
                                                (answer, index) => {
                                                    const isLast =
                                                        (
                                                            answerData.answer ||
                                                            []
                                                        ).length -
                                                            1 ===
                                                        index;
                                                    const suffix = isLast
                                                        ? ""
                                                        : " | ";

                                                    return `${answer?.value?.stringValue}${suffix}`;
                                                }
                                            )}
                                        </Typography>
                                    ) : (
                                        <>
                                            <Alert severity="info">
                                                {t("uploadDisabledSimulation")}
                                            </Alert>
                                        </>
                                    )
                                }
                            />
                        </ListItem>
                        {!isLast && <Divider variant="inset" />}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

const getQuestionText = (
    answer: ApplicantScreeningQuestionnaireAnswerDataType,
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire,
    t: any
) => {
    if (
        jobScreeningQuestionnaireData.enableRanking === true &&
        answerTypesWithWeight.includes(answer?.question?.questiontype?.value)
    ) {
        const answerScoreInfo = getAnswerWeightDetails(
            answer,
            jobScreeningQuestionnaireData
        );
        // return ` - Nota máxima (${answerScoreInfo.questionMaxScore}) / Nota da(s) resposta(s): ${answerScoreInfo.answerScore}`;
        return t("textRankingResultAnswer", {
            questionMaxScore: answerScoreInfo.questionMaxScore,
            answerScore: answerScoreInfo.answerScore
        });
    } else {
        return "";
    }
};

export default SimulationResultAnswers;
