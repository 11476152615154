import React, { useReducer } from "react";
import { useField } from "formik";
import MainScheduleData from "./MainScheduleData";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Button, Grid } from "@material-ui/core";
import ButtonAddNewLanguage from "./ButtonAddNewLanguage";
import ButtonLoadTemplate from "./ButtonLoadTemplate";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useScheduleTemplate, {
    useEmailTemplateType
} from "./useScheduleTemplate";
import { getTemplatesFromScheduleDataDB } from "./EmailDataHelper";

export interface IScheduleEmailData {
    label: string;
    id: string;
    items: {
        language: string;
        location: string;
        subject: string;
        body: string;
    }[];
}
interface IProps {
    metadata: any;
    name: string;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    helperTextDefault: null | string;
    type?: string;
}

const reducerIdLoaded = (state: string[], id: string) => {
    state.push(id);
    return state;
};

const ScheduleDataField = (props: IProps) => {
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const [idListLoadedFromDB, dispatchNewIdLoadedFromDB] = useReducer(
        reducerIdLoaded,
        []
    );
    const companyData = useAuthState();

    const [field, meta, helpers] = useField(props.name);

    const { metadata, t, classes, required, name, type: typeFromProps } = props;
    const loadTemplates = metadata?.elementConfig?.loadTemplates !== false;
    const multipleLanguages =
        metadata?.elementConfig?.multipleLanguages !== false;

    const type = typeFromProps || metadata?.elementConfig?.type;
    const hideSubject = metadata?.elementConfig?.hideSubject === true;

    const scheduleTemplateData = useScheduleTemplate(type);
    const {
        placeholdersBodyList,
        placeholdersSubjectList,
        ready: readyScheduleTemplate
    } = scheduleTemplateData;

    React.useEffect(() => {
        // Load placeholders for new items
        const currentValue = field.value || [];
        if (readyScheduleTemplate && currentValue.length) {
            let updated = false;
            for (let index = 0; index < currentValue.length; index++) {
                if (!currentValue[index].id) {
                    currentValue[index] = {
                        ...currentValue[index],
                        arrayPlaceHolderSubject: scheduleTemplateData.placeholdersSubjectList.map(
                            (item) => item.value
                        ),
                        arrayPlaceHolderBody: scheduleTemplateData.placeholdersBodyList.map(
                            (item) => item.value
                        )
                    };
                    updated = true;
                }
            }
            if (updated) {
                helpers.setValue(currentValue);
            }
        }
    }, [(field.value || []).length, readyScheduleTemplate]);

    React.useEffect(() => {
        const mainData = field.value || [];
        if (mainData.length && !mainData[0].language) {
            mainData[0].language = defaultLanguage;
        }
        helpers.setValue(mainData);
    }, [defaultLanguage]);

    const ready = readyLanguages && readyScheduleTemplate;
    const tabs: TabTypeCompleo[] = [];
    if (ready) {
        (field.value || []).map((item: any, index: number) => {
            const labelTab = item.default
                ? `${t(`Language_${item.language}`)} - ${t(
                      "EMAILDATAFIELD_default"
                  )}`
                : t(`Language_${item.language}`);

            const TabLanguage = (
                <MainScheduleData
                    {...props}
                    arrayNumber={index}
                    placeholdersSubject={placeholdersSubjectList}
                    placeholders={placeholdersBodyList}
                    idListLoadedFromDB={idListLoadedFromDB}
                    language={item.language}
                    hideSubject={hideSubject}
                />
            );
            tabs.push({
                label: labelTab,
                Component: TabLanguage
            });
        });
    }

    const oneLanguage = (languages || []).length < 2;
    if (ready) {
        return (
            <Grid container>
                {oneLanguage || !multipleLanguages ? (
                    <>
                        {loadTemplates && (
                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                <ButtonLoadTemplate
                                    mainFieldName={props.name}
                                    t={t}
                                    type={type}
                                    buttonPositionEnd={false}
                                    splitTemplatesByLanguage={true}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid container item xs={12}>
                        <Grid container item xs={12} sm={6}>
                            <ButtonAddNewLanguage
                                mainFieldName={props.name}
                                t={t}
                            />
                        </Grid>
                        {loadTemplates && (
                            <Grid container item xs={12} sm={6}>
                                <ButtonLoadTemplate
                                    mainFieldName={props.name}
                                    t={t}
                                    type={type}
                                    splitTemplatesByLanguage={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    {oneLanguage || !multipleLanguages ? (
                        tabs[0]?.Component || null
                    ) : (
                        <CompleoTabs t={t} tabs={tabs} />
                    )}
                </Grid>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default ScheduleDataField;
