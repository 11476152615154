import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

export type TabTypeCompleo = {
    label: string;
    Component: any;
    hasError?: boolean;
    forceFocus?: boolean;
};

type TabInterfaceProps = {
    AppBarColor?:
        | "transparent"
        | "inherit"
        | "primary"
        | "secondary"
        | "default";
    AppBarPosition?: "fixed" | "absolute" | "relative" | "static" | "sticky";
    AppBarElevation?: number;
    AppBarOtherProps?: any;
    TabsIndicatorColor?: "primary" | "secondary";
    TabsTextColor?: "inherit" | "primary" | "secondary";
    TabsVariant?: "scrollable" | "standard" | "fullWidth";
    TabsScrollButtons?: "auto" | "on" | "off" | "desktop";
    TabsAriaLabel?: string;
    TabsOtherProps?: any;
    TabIndividualOtherProps?: any;
    TabPanelOtherProps?: any;
};

export interface ITabsCompleo {
    tabs: TabTypeCompleo[];
    t: any;
    interfaceProps?: TabInterfaceProps;
}

const CompleoTabs = (props: ITabsCompleo) => {
    const { tabs, t, interfaceProps } = props;

    const {
        AppBarColor,
        AppBarPosition,
        AppBarElevation,
        AppBarOtherProps,
        TabsIndicatorColor,
        TabsTextColor,
        TabsVariant,
        TabsScrollButtons,
        TabsAriaLabel,
        TabsOtherProps,
        TabIndividualOtherProps,
        TabPanelOtherProps
    }: TabInterfaceProps = {
        AppBarColor: "transparent",
        AppBarPosition: "static",
        AppBarElevation: 0,
        TabsIndicatorColor: "primary",
        TabsTextColor: "primary",
        TabsVariant: "scrollable",
        TabsScrollButtons: "auto",
        TabsAriaLabel: "Choose an option | Escolha uma opção",
        ...interfaceProps
    };

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        console.log("CompleoTabs handleChange", newValue);
        setValue(newValue);
    };

    const focus = tabs.findIndex((x) => x.forceFocus === true);

    React.useEffect(() => {
        if (focus !== value && focus !== -1) {
            setValue(focus);
        }
    }, [focus, value]);

    React.useEffect(() => {
        if (value !== 0) {
            setValue(0);
        }
    }, [tabs.length]);

    return (
        <div className={classes.root}>
            <AppBar
                position={AppBarPosition}
                color={AppBarColor}
                elevation={AppBarElevation}
                {...AppBarOtherProps}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor={TabsIndicatorColor}
                    textColor={TabsTextColor}
                    variant={TabsVariant}
                    scrollButtons={TabsScrollButtons}
                    aria-label={TabsAriaLabel}
                    {...TabsOtherProps}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab.label}
                            label={
                                !tab.hasError
                                    ? t(tab.label)
                                    : `${t(tab.label)} (*)`
                            }
                            {...a11yProps(index)}
                            {...TabIndividualOtherProps}
                            style={
                                tab.hasError
                                    ? {
                                          color: "red"
                                      }
                                    : undefined
                            }
                        />
                    ))}
                </Tabs>
            </AppBar>
            {tabs.map((tab, index) => {
                // const ComponentRendered = () => tab.Component;
                return (
                    <TabPanel
                        key={tab.label}
                        value={value}
                        index={index}
                        {...TabPanelOtherProps}
                    >
                        {tab.Component}
                    </TabPanel>
                );
            })}
        </div>
    );
};

export default CompleoTabs;
