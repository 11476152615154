import { ApiStateType } from "customHooks/useApi";
import GridLayout from "react-grid-layout";

export type DashboardPageToRenderType =
    | "main"
    | "jobs"
    | "applicants"
    | "jobsDateLimits"
    | "myDashboards"
    | "calendar"
    | "applicantsJobView"
    | "applicantsJobViewExternal";

export const PageToRenderList: DashboardPageToRenderType[] = [
    "main",
    "jobs",
    "applicants",
    "jobsDateLimits",
    "myDashboards",
    "calendar"
];

export const PageToRenderListReadUserMode: DashboardPageToRenderType[] = [
    "main",
    "myDashboards"
];

export type IDashOtherPropsType = {
    [x in DashboardPageToRenderType]?: Compleo.IObject;
};

export interface IDashboardMainDef {
    name?: string;
    id?: string;
    type?: "company" | "user";
    layouts: GridLayout.Layouts;
    widgetList: string[];
    widgetListAllOptions: string[];
}

export type LocalStateDataType = {
    pageToRender: DashboardPageToRenderType;
    editMode: boolean;
    currentDashboardDef?: IDashboardMainDef;
    currentDashboardStateBeforeEditOrNewDef?: IDashboardMainDef;
    mainDashboardDef?: IDashboardMainDef;
    compleoDashboardDef?: IDashboardMainDef;
    dashboardList?: IDashboardMainDef[];
    newMode: boolean;
    uniqueDashboardView: boolean;
};

export type PayloadActionType = {
    pageToRender?: DashboardPageToRenderType;
    editMode?: boolean;
    currentDashboardDef?: IDashboardMainDef;
    currentDashboardStateBeforeEditOrNewDef?: IDashboardMainDef;
    mainDashboardDef?: IDashboardMainDef;
    compleoDashboardDef?: IDashboardMainDef;
    dashboardList?: IDashboardMainDef[];
    newMode?: boolean;
    dashOtherProps?: IDashOtherPropsType;
    uniqueDashboardView?: boolean;
};

export interface IDashboardMainState {
    t: any;
    language: string;
    companyId: number;
    ready: boolean;
    localStateData: LocalStateDataType;
    metadata: ApiStateType;
    dashOtherProps?: IDashOtherPropsType;
}

export interface IMainStateAction {
    type: "update";
    payload: PayloadActionType;
}

export interface IDashboardContextDispatch {
    dispatchGeneralPageInfo: React.Dispatch<IMainStateAction>;
}
