import { useQuery } from "react-query";
import { apiDirectCall } from "customHooks/useApi/api";

const getCompanyData = async ({
    email,
    companyId
}: {
    email: string;
    companyId: number;
}) => {
    const postAddress = `/user/getdefaultcompanyuser`;
    if (email === null || email === "") {
        return null;
    }
    const { data } = await apiDirectCall(postAddress, "post", {
        email,
        companyId
    });
    return data;
};

export default function useQueryCompanyData(email: string, companyId: number) {
    let parameter: [string, string, number] = ["companydata", email, companyId];
    let enabled: boolean = false;
    if (email !== null && email !== "") {
        parameter = ["companydata", email, companyId];
        enabled = true;
    }
    return useQuery(parameter, () => getCompanyData({ email, companyId }), {
        enabled: enabled
    });
}
