import React from "react";
import { getMetadata } from "./helpers/helper";
import Loading from "./helpers/Loading";
import CompleoFormik from "./CompleoFormik";
import { TFunction, i18n } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import { getInitialValues } from "./helpers/helper";
import { PropsParent, typeForm } from "./components/FormMain";
import { MenuOptionTypeReturn } from "./components/useMenuFormGroup";

/**
 * @deprecated The method should not be used. Change to useCompleoFormNew
 */
const useCompleoForm = (
    classes: any,
    t: TFunction,
    props: any,
    readyTranslation: boolean,
    i18nV: i18n,
    postMethod: any,
    postReturn: any,
    redirectAddress: null | string,
    FormType: any,
    metadadosRetorno: ApiStateType,
    valuesFromSource: Compleo.IObject,
    CustomComponents?: any,
    timeZone?: null | string,
    nextPageFunctions?: Compleo.IObject[],
    secondaryButtonFunction: any | null = null,
    tertiaryButtonFunction: any | null = null,
    customLists?: Compleo.IObject[],
    fieldsToHide?: string[],
    formikReturn?: any
) => {
    let finished = false;
    let metaData: Compleo.IObject = {};

    if (metadadosRetorno.status === "success" && readyTranslation) {
        const language = i18nV.languages[0];
        finished = true;
        metaData = getMetadata(metadadosRetorno, language, timeZone);
    }

    let formRetorno = <Loading />;

    if (finished) {
        formRetorno = (
            <CompleoFormik
                classes={classes}
                t={t}
                loading={!finished}
                camposMetadados={metaData.camposMetadados}
                formGroups={metaData.formGroups}
                listas={metaData.listas}
                cadastrar={postMethod}
                retornoCadastro={postReturn}
                FormType={FormType}
                redirectAddress={redirectAddress}
                props={props}
                valuesFromSource={valuesFromSource}
                CustomComponents={CustomComponents}
                IsMultiStepForm={metaData.IsMultiStepForm}
                MultiStepForm={metaData.MultiStepForm}
                nextPageFunctions={nextPageFunctions}
                secondaryButtonFunction={secondaryButtonFunction}
                tertiaryButtonFunction={tertiaryButtonFunction}
                customLists={customLists}
                fieldsToHide={fieldsToHide}
                formikReturn={formikReturn}
            />
        );
    }

    return [formRetorno, finished];
};

interface IUseCompleoFormParams {
    t: TFunction;
    ready: boolean;
    i18nV: i18n;
    postMethod: any;
    postReturn: any;
    redirectAddress?: null | string;
    FormType: any;
    metadadosRetorno: ApiStateType;
    valuesFromSource: Compleo.IObject;
    CustomComponents?: any;
    timeZone?: null | string;
    nextPageFunctions?: Compleo.IObject[];
    secondaryButtonFunction?: any;
    tertiaryButtonFunction?: any;
    customLists?: Compleo.CustomLists.ListDefinitionType[];
    fieldsToHide?: string[];
    formikReturn?: any;
    FormMenuActions?: {
        formGroupId: string;
        menuDefinition: MenuOptionTypeReturn;
    }[];
    additionalFieldProperties?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    sizeToChangeStepHorizontal?: "md" | "xs" | "sm" | "lg" | "xl";
    formikEnableReinitialize?: boolean;
    formGroupPaperElevation?: number;
    enableStepNavigation?: boolean;
    IsMultiStepLateralParam?: boolean;
    invalidateQueryClientItems?: string[];
    removeQueryClientItems?: string[];
}

export const useCompleoFormNew: (
    params: IUseCompleoFormParams
) => [JSX.Element, boolean] = (params: IUseCompleoFormParams) => {
    const {
        t,
        ready,
        i18nV,
        postMethod,
        postReturn,
        redirectAddress,
        FormType,
        metadadosRetorno,
        valuesFromSource,
        CustomComponents,
        timeZone,
        nextPageFunctions,
        secondaryButtonFunction,
        tertiaryButtonFunction,
        customLists,
        fieldsToHide,
        formikReturn,
        FormMenuActions,
        additionalFieldProperties,
        sizeToChangeStepHorizontal,
        formikEnableReinitialize,
        formGroupPaperElevation,
        enableStepNavigation,
        IsMultiStepLateralParam,
        invalidateQueryClientItems,
        removeQueryClientItems
    } = params;

    let finished = false;
    let metaData: Compleo.IObject = {};

    if (metadadosRetorno.status === "success" && ready) {
        const language = i18nV.languages[0];
        finished = true;
        metaData = getMetadata(metadadosRetorno, language, timeZone);
    }

    let formRetorno = <Loading />;
    const enableReinitialize =
        formikEnableReinitialize === undefined
            ? true
            : formikEnableReinitialize;
    const Form: (props: PropsParent) => JSX.Element = FormType;
    const updating = postReturn.status === "fetching";
    const initialValues = valuesFromSource; // getInitialValues(camposMetadados, valuesFromSource);
    const newRedirectAddress =
        redirectAddress === undefined ? null : redirectAddress;

    if (finished) {
        formRetorno = (
            <Form
                t={t}
                camposMetadados={metaData.camposMetadados}
                formGroups={metaData.formGroups}
                listas={metaData.listas}
                updating={updating}
                loading={!finished}
                valuesFromSource={valuesFromSource}
                CustomComponents={CustomComponents}
                IsMultiStepForm={metaData.IsMultiStepForm}
                MultiStepForm={metaData.MultiStepForm}
                initialValues={initialValues}
                redirectAddress={newRedirectAddress}
                postFunction={postMethod}
                nextPageFunctions={nextPageFunctions}
                secondaryButtonFunction={secondaryButtonFunction || null}
                tertiaryButtonFunction={tertiaryButtonFunction || null}
                customLists={customLists}
                fieldsToHide={fieldsToHide}
                formikReturn={formikReturn}
                FormMenuActions={FormMenuActions}
                additionalFieldProperties={additionalFieldProperties}
                sizeToChangeStepHorizontal={sizeToChangeStepHorizontal}
                enableReinitialize={enableReinitialize}
                formGroupPaperElevation={formGroupPaperElevation}
                enableStepNavigation={enableStepNavigation}
                IsMultiStepLateralParam={IsMultiStepLateralParam}
                invalidateQueryClientItems={invalidateQueryClientItems}
                removeQueryClientItems={removeQueryClientItems}
            />
        );
    }

    return [formRetorno, finished];
};
export default useCompleoForm;
