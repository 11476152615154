import React from "react";
import Page from "Pages/_Layouts/DefaultExternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, ApiStateType } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { forgotPasswordFunction } from "./components/ForgotPasswordFunction";
import { navigate } from "@gatsbyjs/reach-router";
import GenericAdditionalGrid from "./GenericAdditionalGrid";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const ForgotPassword = (props: any) => {
    const classes = useFormStyles();
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const [t, i18n, readyTranslation] = useTranslation("FORGOTPASSWORD", {
        useSuspense: false
    });

    const [metadata] = useGetMetadata("FORGOTPASSWORD");

    const forgotPassword = forgotPasswordFunction(t, callDialog);
    const forgotPasswordReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const valuesFromSource = {
        email: ""
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        forgotPassword,
        forgotPasswordReturn,
        null,
        FormExternal,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;
    const additionalGrid = <GenericAdditionalGrid t={t} />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                additionalGrid={additionalGrid}
                featureMainDescription={t("a_PageName")}
                maxWidth="xs"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ForgotPassword;
