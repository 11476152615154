import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "customHooks/DataTable/datatable-column-header";
import { DataTableRow } from "customHooks/DataTable/datatable-row";
import { JobTitle } from "../utils/JobTitleColumn";
import { getCustomListColumn } from "../utils/CustomColum";
import { getTimeBetweenStatusColumn } from "../utils/TimeBetweenStatusColumn";

export const columns: (
    t: any,
    language: string
) => ColumnDef<Compleo.IObject>[] = (t: any, language: string) => {
    const customerColumn = getCustomListColumn(
        "customer",
        t("customer"),
        language
    );
    const branchColumn = getCustomListColumn("branch", t("branch"), language);
    const dateFields = [
        "days_requested_to_approvedRequest",
        "days_requested_to_disapprovedRequest",
        "days_requested_to_open",
        "days_approvedRequest_to_open",
        "days_open_to_canceled",
        "days_open_to_suspended",
        "days_open_to_finished",
        "days_requested_to_finished",
        "days_awaitingCustomer_to_finished",
        "days_open_to_awaitingCustomer",
        "days_requested_to_awaitingCustomer"
    ];
    const dateColumns = dateFields.map((field) => {
        return getTimeBetweenStatusColumn(field, t);
    });
    return [
        {
            accessorKey: "title",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title={t("title")} />
                );
            },
            cell: ({ row, getValue, cell }) => {
                return <JobTitle row={row} />;
            },
            enableGrouping: true
        },
        {
            accessorKey: "status",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("status")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                return (
                    <DataTableRow
                        row={row}
                        value={t(`JOBSTATUS_${value}`)}
                        align="right"
                        cell={cell}
                    />
                );
            }
        },
        customerColumn,
        branchColumn,
        ...dateColumns
    ];
};
