import React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

interface IPropsAddStageButtom {
    handleAddStage: any;
    listSections: Compleo.IObject[];
    t: any;
}

const AddSectionButtom = (props: IPropsAddStageButtom) => {
    const { handleAddStage: handleAddSection, listSections, t } = props;
    const lastIndexFromGroup = listSections.length;

    return (
        <Button
            // variant="contained"
            color="secondary"
            size="small"
            style={{ margin: "16px" }}
            // className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => handleAddSection(lastIndexFromGroup)}
        >
            {t("button_addSection")}
        </Button>
    );
};

export default AddSectionButtom;
