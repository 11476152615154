import { Chip, Grid, Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import useApplicantViewContext from "../../useApplicantViewContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0)
        },
        tags: {
            marginLeft: theme.spacing(1),
            maxWidth: 250
        }
    })
);

interface IApplicantHistoryTemplate {
    children: JSX.Element;
    type: CompleoShared.Common.ApplicantHistoryType;
    elementEnd?: JSX.Element;
}

const ApplicantHistoryTemplate = (
    props: IApplicantHistoryProps & IApplicantHistoryTemplate
) => {
    const classes = useStyles();
    const { historyItem, tLocal, children, type, language, elementEnd } = props;

    return (
        <Paper elevation={1} className={classes.paper}>
            <ApplicantHistoryItemDetail
                title={tLocal("Type")}
                transformDBDataParam={{
                    value: tLocal(`ApplicantHistoryType_${type}`),
                    t: tLocal,
                    language: language
                }}
            />
            {children}
            <ApplicantHistoryItemDetail
                title={tLocal("User")}
                transformDBDataParam={{
                    value: historyItem.createdByUser,
                    t: tLocal,
                    language: language
                }}
            />
            {elementEnd && (
                <Grid container item alignContent="center" alignItems="center">
                    {elementEnd}
                </Grid>
            )}
        </Paper>
    );
};

export default ApplicantHistoryTemplate;
