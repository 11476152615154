import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FieldSwitchType from "./FieldSwitchType";
import {
    Box,
    Grid,
    Icon,
    Paper,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import { filterFormGroups } from "customHooks/useCompleoForm/components/FormMain";
import ModalEdit from "../ModalEdit/ModalEdit";
import {
    IUseCompleoViewEditDataParams,
    UseCompleoViewCustomComponents,
    UseCompleoEditCustomComponents
} from "customHooks/useCompleoView";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            padding: theme.spacing(1),
            height: "100%"
        },
        formGroup: {
            width: "100%",
            margin: theme.spacing(1),
            padding: theme.spacing(2)
        },
        formGroupTitle: {
            marginBottom: theme.spacing(1)
        },
        formGroupTypography: {
            fontWeight: 600
        },
        grow: {
            flexGrow: 1
        }
    })
);

interface IProps {
    valuesFromSource: Compleo.IObject;
    metadata: Compleo.IObject[];
    t: any;
    language: string;
    formGroups: Compleo.IObject[];
    IsMultiStepForm?: boolean;
    MultiStepForm: Compleo.IObject[];
    editParams?: IUseCompleoViewEditDataParams;
    CustomComponents: UseCompleoViewCustomComponents[];
    CustomEditComponents: UseCompleoEditCustomComponents[];
    formGroupPaperElevation?: number;
    multiStepTabNameToOpen?: string;
    addProps?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    readOnly: boolean;
    fieldsToHide?: string[];
    customCVsData?: Compleo.IObject[];
}

const MainView = (props: IProps) => {
    const {
        valuesFromSource,
        metadata,
        t,
        language,
        formGroups,
        IsMultiStepForm = false,
        MultiStepForm,
        editParams,
        CustomComponents,
        CustomEditComponents = [],
        formGroupPaperElevation = 1,
        multiStepTabNameToOpen,
        addProps,
        readOnly,
        fieldsToHide = [],
        customCVsData
    } = props;
    const { moduleEditName = "" } = editParams || {};
    const [modalEditOpen, setModalEditOpen] = React.useState(false);
    const [formIdToEdit, setFormIdToEdit] = React.useState<string>("");

    const storageStepName = `MainViewStepdId_${moduleEditName}`;
    const lastStageId = Number(localStorage.getItem(storageStepName) || 0);

    const [currentStepId, setCurrentStepId] = React.useState<number>(
        lastStageId
    );

    React.useEffect(() => {
        if (multiStepTabNameToOpen && MultiStepForm.length) {
            const indexToOpen = MultiStepForm.findIndex(
                (item) => item.id === multiStepTabNameToOpen
            );
            if (indexToOpen >= 0 && indexToOpen !== currentStepId) {
                handleTabChange(null, indexToOpen);
            }
        }
    }, [multiStepTabNameToOpen, MultiStepForm]);

    const classes = useStyles();
    const currentTabValue = MultiStepForm[currentStepId] || MultiStepForm[0];
    // console.log("MultiStepForm", MultiStepForm);
    const isMultiStep = IsMultiStepForm && MultiStepForm?.length;

    const stepFormGroups = formGroups.filter(
        (f) => !isMultiStep || f.stepId === currentTabValue.id
    );
    const formGroupsFiltered = filterFormGroups(
        stepFormGroups,
        metadata,
        fieldsToHide
    );

    const allFormGroupsFiltered = filterFormGroups(
        formGroups,
        metadata,
        fieldsToHide
    );
    const stepsFormGroups: string[] = allFormGroupsFiltered
        .filter((item: Compleo.IObject) => item.stepId)
        .map((item: Compleo.IObject) => item.stepId);

    const MultiStepFormFiltered = (MultiStepForm || []).filter((item) =>
        stepsFormGroups.includes(item.id)
    );
    let lastOrderItem = -1;

    const openModal = (formId: string) => {
        setFormIdToEdit(formId);
        setModalEditOpen(true);
    };

    const handleTabChange = (
        event: React.ChangeEvent<{}> | null,
        newValue: number
    ) => {
        setCurrentStepId(newValue);
        localStorage.setItem(storageStepName, newValue.toString());
    };

    return (
        <Grid
            container
            className={classes.root}
            // alignItems="flex-start"
            alignContent="flex-start"
            alignItems="stretch"
        >
            {isMultiStep && (
                <Tabs
                    value={currentStepId}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="secondary"
                    variant="scrollable"
                >
                    {MultiStepFormFiltered.map((tab, index) => (
                        <Tab key={tab.id} label={t(`a_StepForm_${tab.id}`)} />
                    ))}
                </Tabs>
            )}
            {formGroupsFiltered.map((form: Compleo.IObject) => {
                const viewOptionShowOutsideGrid =
                    form.viewOptionShowOutsideGrid === true;
                const filteredMetadata = metadata.filter(
                    (item) =>
                        item.formGroupId === form.id &&
                        !fieldsToHide.includes(item.fieldName)
                );
                const hasFieldsToEdit =
                    filteredMetadata.filter(
                        (item: Compleo.IObject) =>
                            item.viewOptionAllowEdit === true
                    ).length > 0;

                const showEditButton =
                    editParams !== undefined && hasFieldsToEdit;
                const showTopEditButton =
                    t(`a_FormGroup_${form.id}`, "") !== "" && showEditButton;
                const showBottomEditButton =
                    showEditButton && !showTopEditButton;
                const inline = form.viewOptionInline === true;

                if (!viewOptionShowOutsideGrid) {
                    return (
                        <Grid
                            container
                            item
                            xs={form?.viewOptionColumnSize?.xs || 12}
                            sm={form?.viewOptionColumnSize?.sm}
                            md={form?.viewOptionColumnSize?.md}
                            lg={form?.viewOptionColumnSize?.lg}
                            key={form.id}
                        >
                            <Paper
                                elevation={formGroupPaperElevation}
                                className={classes.formGroup}
                            >
                                <Grid container>
                                    {t(`a_FormGroup_${form.id}`, "") !== "" && (
                                        <Grid
                                            item
                                            xs
                                            className={classes.formGroupTitle}
                                        >
                                            <Typography
                                                component="h3"
                                                variant="body1"
                                                align="left"
                                                className={
                                                    classes.formGroupTypography
                                                }
                                            >
                                                {t(`a_FormGroup_${form.id}`)}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {!readOnly && showTopEditButton && (
                                        <Grid
                                            container
                                            item
                                            xs={1}
                                            justify="flex-end"
                                        >
                                            <ButtonIconTooltipCompleo
                                                label={t("COMPLEOGENERAL_Edit")}
                                                onClick={() => {
                                                    openModal(form.id);
                                                }}
                                                size="small"
                                            >
                                                <Icon fontSize="small">
                                                    edit
                                                </Icon>
                                            </ButtonIconTooltipCompleo>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid container>
                                    <Grid container spacing={1}>
                                        {filteredMetadata.map((m) => {
                                            const lastOrderParam = lastOrderItem;
                                            lastOrderItem = m.order;

                                            const fieldDef = m;
                                            return (
                                                <MainViewItemData
                                                    key={m.fieldName}
                                                    lastOrderItem={
                                                        lastOrderItem
                                                    }
                                                    lastOrderParam={
                                                        lastOrderParam
                                                    }
                                                    inline={inline}
                                                >
                                                    <FieldSwitchType
                                                        fieldData={
                                                            valuesFromSource
                                                        }
                                                        fieldName={m.fieldName}
                                                        type={
                                                            fieldDef?.elementType
                                                        }
                                                        t={t}
                                                        language={language}
                                                        fieldDef={fieldDef}
                                                        inline={inline}
                                                        completeMetadata={
                                                            metadata
                                                        }
                                                        CustomComponents={
                                                            CustomComponents
                                                        }
                                                        addProps={addProps}
                                                        customCVsData={
                                                            customCVsData
                                                        }
                                                        applicantId={
                                                            editParams?.keyData
                                                                ?.id
                                                        }
                                                    />
                                                </MainViewItemData>
                                            );
                                        })}
                                        {!readOnly && showBottomEditButton && (
                                            <>
                                                <Box flexGrow={1} />
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    alignSelf="center"
                                                >
                                                    <ButtonIconTooltipCompleo
                                                        label="Edit"
                                                        onClick={() => {
                                                            openModal(form.id);
                                                        }}
                                                        size="small"
                                                    >
                                                        <Icon fontSize="small">
                                                            edit
                                                        </Icon>
                                                    </ButtonIconTooltipCompleo>
                                                </Box>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                {/* {showBottomEditButton && (
                                <Grid container item xs={12} justify="flex-end">
                                    <ButtonIconTooltipCompleo
                                        label="Edit"
                                        onClick={() => {
                                            openModal(form.id);
                                        }}
                                        size="small"
                                    >
                                        <Icon fontSize="small">edit</Icon>
                                    </ButtonIconTooltipCompleo>
                                </Grid>
                            )} */}
                            </Paper>
                        </Grid>
                    );
                } else {
                    return (
                        <>
                            {filteredMetadata.map((m) => {
                                const fieldDef = m;

                                return (
                                    <FieldSwitchType
                                        key={m.fieldName}
                                        fieldData={valuesFromSource}
                                        fieldName={m.fieldName}
                                        type={fieldDef?.elementType}
                                        t={t}
                                        language={language}
                                        fieldDef={fieldDef}
                                        inline={inline}
                                        completeMetadata={metadata}
                                        CustomComponents={CustomComponents}
                                        addProps={addProps}
                                    />
                                );
                            })}
                        </>
                    );
                }
            })}
            {!readOnly && editParams && modalEditOpen && (
                <ModalEdit
                    open={modalEditOpen}
                    onClose={() => setModalEditOpen(false)}
                    data={valuesFromSource}
                    formId={formIdToEdit}
                    editParams={editParams}
                    CustomEditComponents={CustomEditComponents}
                />
            )}
        </Grid>
    );
};

interface IItemData {
    children: any;
    lastOrderParam: number;
    lastOrderItem: number;
    inline?: boolean;
}

export const MainViewItemData = (props: IItemData) => {
    const { children, lastOrderParam, lastOrderItem, inline = false } = props;

    if (
        lastOrderParam !== lastOrderItem &&
        lastOrderParam !== -1 &&
        inline === false
    ) {
        return (
            <>
                <Grid container></Grid>
                {children}
            </>
        );
    } else {
        return <>{children}</>;
    }
};

export default MainView;
