import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useDynamicDashboard from "../..";
import SettingsModalCardFieldsList from "./SettingsModalCardTableFieldsList";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Typography } from "@material-ui/core";
import {
    getCustomSearchOrdered,
    getCustomSearchOrderedLocal,
    getOrderedObjectNamesByLocal
} from "../ListUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    type: "card" | "table";
}

const SettingsModalCardTableFields = (props: IPropsItemFilter) => {
    const { onClose, open, type } = props;
    const [globalValues, globalValuesDispatch] = useGlobalValues();

    const [listData, listDispatch] = useDynamicDashboard();

    const sessionName =
        type === "card"
            ? `cardFieldsOrder_${listData.tempInfo.moduleName}`
            : `tableFieldsOrder_${listData.tempInfo.moduleName}`;

    const orderData = globalValues.userdb[sessionName];

    const { otherSettings, cardFields, tableFields } =
        listData.definitionInfo.elasticDefData?.data || {};

    const fields = type === "card" ? cardFields || {} : tableFields || {};
    const fieldsDetails = getCustomSearchOrderedLocal(fields, orderData);

    const [filterData, setFilterData] = React.useState<Compleo.IObject[]>(
        fieldsDetails
    );

    const maxItemsToShowPrimary =
        type === "card"
            ? otherSettings?.cardMaxFieldsToShow || 8
            : otherSettings?.tableMaxFieldsToShowPrimary || 5;

    const maxItemsToShowSecondary =
        type === "card" ? 0 : otherSettings?.tableMaxFieldsToShowSecondary || 5;

    // React.useEffect(() => {
    //     if (!Object.keys(filterData).length) {
    //         setFilterData(fieldsDetails);
    //     }
    // }, [fieldsDetails]);
    React.useEffect(() => {
        if (!filterData.length && fieldsDetails.length) {
            setFilterData(fieldsDetails);
        }
    }, [fieldsDetails]);

    React.useEffect(() => {
        setFilterData(fieldsDetails);
    }, [open, type, orderData]);

    const { t } = listData.tempInfo;

    const handleOk = () => {
        onClose();
    };

    const handleSave = () => {
        globalValuesDispatch.addOrEditGlobalValue({
            name: sessionName,
            type: "userdb",
            value: filterData
        });
        onClose();
    };
    const title =
        type === "card"
            ? t("ELASTICLISTSETTINGS:MainMenu_cardFieldsSettings")
            : t("ELASTICLISTSETTINGS:MainMenu_tableFieldsSettings");
    const instructions =
        type === "card"
            ? t("ELASTICLISTSETTINGS:CardSettingsInstructions")
            : t("ELASTICLISTSETTINGS:TableSettingsInstructions");

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={title}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">{instructions}</Typography>
                <SettingsModalCardFieldsList
                    filterData={filterData}
                    setFilterData={setFilterData}
                    maxItemsToShowPrimary={maxItemsToShowPrimary}
                    maxItemsToShowSecondary={maxItemsToShowSecondary}
                    type={type}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsModalCardTableFields;
