import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { PropTypes } from "@material-ui/core";

interface IProps {
    label: string;
    children: any;
    className?: any;
    iconButtonClassName?: any;
    onClick?:
        | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined;
    edge?: false | "end" | "start" | undefined;
    size?: "small" | "medium" | undefined;
    color?: PropTypes.Color | undefined;
    onMouseDown?:
        | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined;
    onTouchStart?: ((event: any) => void) | undefined;
}

const ButtonIconTooltipCompleo = (props: IProps) => {
    const {
        label,
        children,
        onClick,
        className,
        iconButtonClassName,
        edge,
        size,
        color = "primary"
    } = props;

    return (
        <Tooltip className={className} title={label}>
            <IconButton
                color={color}
                onClick={onClick}
                aria-label={label}
                className={iconButtonClassName}
                edge={edge}
                size={size}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default ButtonIconTooltipCompleo;
