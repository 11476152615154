import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import ApplicantViewModalContainer from "../ApplicantViewModalContainer";

const ApplicantViewOutsideList = (props: any) => {
    const { uuid, tabopen } = props;
    const moduleName = "APPLICANTVIEWOUTSIDELIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [openModal, setOpenModal] = React.useState(true);
    const ready = readyTranslation;
    if (ready) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                <Grid container style={{ marginTop: 16 }}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {t("mainInstructions")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => setOpenModal(true)}
                            startIcon={<Icon>visibility</Icon>}
                        >
                            {t("mainButton")}
                        </Button>
                    </Grid>
                </Grid>

                <ApplicantViewModalContainer
                    onClose={() => setOpenModal(false)}
                    open={openModal}
                    loadingData={ready}
                    ApplicantId={uuid}
                    tabOpen={tabopen}
                    module="APPLICANTVIEW"
                />
            </Page>
        );
    } else {
        return (
            <Page>
                <Loading />
            </Page>
        );
    }
};

export default ApplicantViewOutsideList;
