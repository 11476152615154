import {
    AppBar,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Hidden,
    Link,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "./useApplicantViewContext";
import { ITransformDBData, transformDBData, clearString } from "functions/util";
import _ from "lodash";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Icon from "@material-ui/core/Icon";
import ChangeStageButtonInsideJobContainer from "./ChangeStageButton/ChangeStageButtonInsideJobContainer";
import ApplicantAddToJob from "../components/ApplicantAddToJob";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import RemoveFromJobInsideJobContainer from "./RemoveFromJobInsideJobContainer";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import ButtonAddRating from "./ApplicantRating/ButtonAddRating";
import { ApplicantViewExternalData } from "./useApplicantViewContext/ApplicantViewTypes";
import ApplicantViewSideContainerModal from "./ApplicantViewSideContainerModal";
import DisqualifyRequalifyJobInsideJobContainer from "./DisqualifyRequalifyJobInsideJobContainer";
import MoveToNextStageInsideJobContainer from "./MoveToNextStageInsideJobContainer";

const useStyles = makeStyles((theme: Theme) => {
    const gradient1 = `linear-gradient(to right top, #322241, #4d3960, #6a5181, #886ba4, #a886c8)`;
    const gradient2 = `linear-gradient(to right top, #322241, #423e6a, #485e96, #4081c2, #12a7eb)`;
    const gradient3 = `linear-gradient(to bottom, #322241, #564367, #7c6690, #a58cbc, #cfb4e9)`;

    return createStyles({
        itemRoot: {
            margin: 0,
            padding: 0
        },
        toolbar: {
            minHeight: 100,
            maxHeight: 100
        },
        container: {
            flexGrow: 1,
            display: "flex",
            background: gradient1,
            color: "white"
        },
        gridAvatar: {
            margin: 0,
            marginRight: theme.spacing(0.2)
        },
        gridNameAndOtherData: {
            margin: 0,
            padding: 0
            // color: "black"
        },
        boxItem: {
            marginRight: theme.spacing(1),
            alignContent: "justify",
            alignItems: "justify"
        },
        fieldIcon: {
            // color: theme.palette.grey[600],
            fontSize: 16,
            margin: 0,
            padding: 0
        },
        fieldText: {
            marginLeft: theme.spacing(0.5),
            fontSize: 14,
            // color: theme.palette.grey[600],
            maxWidth: 240,
            marginRight: theme.spacing(1),
            fontFamily: "'Helvetica', sans-serif",
            letterSpacing: "0.05em"
        },
        rating: {
            marginLeft: theme.spacing(2)
        },
        grow: {
            flexGrow: 1
        },
        iconActionButton: {
            color: "inherit"
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex"
            },
            alignItems: "center"
        },
        sectionMobile: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none"
            }
        }
    });
});

interface IProps {}

const ApplicantViewHeader = (props: IProps) => {
    const classes = useStyles();
    const [addToJobModalOpen, setAddToJobModalOpen] = React.useState(false);
    const [data] = useApplicantViewContext();
    const { applicantData, t, language, metadata } = data;
    const [modalJobComments, setModalJobComments] = React.useState(false);

    const defHeader =
        metadata.response?.data?.OtherPageProps?.applicantViewHeader || {};
    const keysToInvalidate: string[] = [
        data.queryKey,
        "/applicant/list",
        "/job/applicantlistjobview"
    ];
    const evaluationValue = getEvaluationValue(
        data.applicantData,
        data.jobDataInsideJob?.jobId,
        data.externalData
    );
    const currentJob = (applicantData?.Jobs || []).filter(
        (job: Compleo.IObject) => job.JobId === data.jobDataInsideJob?.jobId
    )[0];
    const stageId = currentJob?.Stage?.id;
    return (
        <AppBar position="sticky" elevation={0} className={classes.container}>
            <Toolbar className={classes.toolbar}>
                <Box
                    alignContent="flex-start"
                    alignItems="center"
                    display="flex"
                    // justify="flex-start"
                >
                    <Box mr={1}>
                        {defHeader.showPhoto === true && (
                            <>
                                {!data.externalData ? (
                                    <CompleoAvatar
                                        idParams={{ id: data.applicantId }}
                                        type="applicant"
                                    />
                                ) : (
                                    <CompleoAvatar
                                        idParams={{
                                            id: data.applicantId,
                                            evaluationId:
                                                data?.externalData?.evaluationId
                                        }}
                                        type="applicantExternal"
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item>
                                {defHeader.showName === true && (
                                    <Typography variant="h6" component="span">
                                        {applicantData.name}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item className={classes.rating}>
                                <CompleoCompanyRating
                                    evaluationValue={evaluationValue}
                                    showTextValue
                                />
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        onClick={() =>
                                            setModalJobComments(true)
                                        }
                                        style={{
                                            textTransform: "none"
                                        }}
                                        size="small"
                                    >
                                        {t("jobsComments")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <ItemList defHeader={defHeader} />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Box>
                </Box>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    {data.jobDataInsideJob && (
                        <ButtonAddRating
                            t={data.t}
                            jobParamData={{
                                ...(data.jobDataInsideJob?.jobData || {}),
                                JobId: data.jobDataInsideJob?.jobId
                            }}
                            // jobId={data.jobDataInsideJob?.jobId}
                        />
                    )}
                    {data.jobDataInsideJob?.jobDataContext && (
                        <ChangeStageButtonInsideJobContainer
                            index={data.applicantIndex}
                            t={data.t}
                            jobDataInsideJob={data.jobDataInsideJob}
                        />
                    )}
                    {data.jobDataInsideJob?.jobDataContext && (
                        <MoveToNextStageInsideJobContainer
                            jobDataInsideJob={data.jobDataInsideJob}
                            currentIndex={data.applicantIndex}
                            stageId={stageId}
                            t={t}
                            listKeyToInvalidate={keysToInvalidate}
                        />
                    )}
                    {data.jobDataInsideJob?.jobDataContext && (
                        <DisqualifyRequalifyJobInsideJobContainer
                            jobDataInsideJob={data.jobDataInsideJob}
                            currentIndex={data.applicantIndex}
                            stageId={stageId}
                            t={t}
                            listKeyToInvalidate={keysToInvalidate}
                        />
                    )}
                    {data.jobDataInsideJob?.jobDataContext && (
                        <RemoveFromJobInsideJobContainer
                            jobDataInsideJob={data.jobDataInsideJob}
                        />
                    )}
                    {/* {!data.jobDataInsideJob && (
                        <Button
                            variant="text"
                            startIcon={<Icon>add</Icon>}
                            color="inherit"
                            onClick={() => setAddToJobModalOpen(true)}
                        >
                            <Typography noWrap>{t("addToJob")}</Typography>
                        </Button>
                    )} */}
                    {/* <Button
                        variant="text"
                        startIcon={<Icon>email</Icon>}
                        color="inherit"
                    >
                        <Typography noWrap>Email</Typography>
                    </Button> */}

                    {/* <ButtonIconTooltipCompleo
                        className={classes.iconActionButton}
                        label={t("COMPLEOGENERAL_OTHERACTIONS")}
                        edge="end"
                    >
                        <Icon>more_vert</Icon>
                    </ButtonIconTooltipCompleo> */}
                </div>
                {addToJobModalOpen && (
                    <ApplicantAddToJob
                        open={addToJobModalOpen}
                        onClose={() => setAddToJobModalOpen(false)}
                        applicantId={data.applicantId}
                        currentJobs={applicantData.Jobs}
                        listKeyToInvalidate={keysToInvalidate}
                    />
                )}
            </Toolbar>
            <ApplicantViewSideContainerModal
                open={modalJobComments}
                handleClose={() => setModalJobComments(false)}
            />
        </AppBar>
    );
};

const getEvaluationValue = (
    applicantData: Compleo.IObject,
    jobId?: string,
    externalData?: ApplicantViewExternalData
) => {
    if (externalData) {
        return (
            applicantData?.externalUserEvaluationData?.generalEvaluation || 0
        );
    }
    if (jobId) {
        const ratingValue = (applicantData?.EvaluationsAllJobs || []).filter(
            (item: Compleo.IObject) => item.jobId === jobId
        )[0];
        return ratingValue?.value || 0;
    } else {
        return applicantData?.EvaluationsSummaryGlobal || 0;
    }
};

interface IItem {
    defHeader: Compleo.IObject;
}
const ItemList = (props: IItem) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { applicantData, t, language } = data;
    const { defHeader } = props;
    const firstRowFields: Compleo.IObject[] = defHeader?.firstRowFields || [];
    const secondRowFields: Compleo.IObject[] = defHeader?.secondRowFields || [];

    return (
        <Grid container className={classes.itemRoot}>
            <Grid container item alignContent="center" alignItems="center">
                {firstRowFields.map((fieldItem) => (
                    <ItemDetail
                        key={fieldItem.fieldName}
                        transformDBDataParam={{
                            value: _.get(applicantData, fieldItem.fieldName),
                            t,
                            language,
                            replaceOption: "-",
                            customType: fieldItem.customType,
                            customDateFormat: fieldItem.customDateFormat
                        }}
                        title={t(fieldItem.fieldName)}
                        IconElement={GetIcon(classes, fieldItem.icon)}
                        linkTo={fieldItem.linkTo}
                    />
                ))}
            </Grid>
            <Grid container item alignContent="center" alignItems="center">
                {secondRowFields.map((fieldItem) => (
                    <ItemDetail
                        key={fieldItem.fieldName}
                        transformDBDataParam={{
                            value: _.get(applicantData, fieldItem.fieldName),
                            t,
                            language,
                            replaceOption: "-",
                            customType: fieldItem.customType,
                            customDateFormat: fieldItem.customDateFormat
                        }}
                        title={t(fieldItem.fieldName)}
                        IconElement={GetIcon(classes, fieldItem.icon)}
                        linkTo={fieldItem.linkTo}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

const GetIcon = (classes: any, icon?: string) => {
    if (icon) {
        switch (icon) {
            case "whatsapp":
                return (
                    <WhatsAppIcon
                        fontSize="small"
                        className={classes.fieldIcon}
                    />
                );
            default:
                return (
                    <Icon fontSize="small" className={classes.fieldIcon}>
                        {icon}
                    </Icon>
                );
        }
    }
    return undefined;
};

interface IItemDetail {
    transformDBDataParam: ITransformDBData;
    IconElement?: JSX.Element;
    title: string;
    linkTo?: string;
}
const ItemDetail = (props: IItemDetail) => {
    const classes = useStyles();
    const { transformDBDataParam, IconElement, title, linkTo } = props;
    const value = transformDBData(transformDBDataParam);
    return (
        <>
            {IconElement ? (
                <EmbedLink
                    IconElement={IconElement}
                    title={title}
                    fieldValue={value}
                    linkTo={linkTo}
                />
            ) : (
                <Typography variant="body2" component="span">
                    {`${title}: `}
                </Typography>
            )}
            <Tooltip title={title}>
                <Typography className={classes.fieldText} noWrap>
                    {value}
                </Typography>
            </Tooltip>
        </>
    );
};

interface IEmbedLink {
    title: string;
    IconElement: JSX.Element;
    linkTo?: string;
    fieldValue: string;
}

const EmbedLink = (params: IEmbedLink) => {
    const { title, IconElement, linkTo, fieldValue } = params;
    if (linkTo) {
        switch (linkTo) {
            case "whatsApp":
                if (clearString(fieldValue)) {
                    return (
                        <Link
                            href={`https://wa.me/${clearString(fieldValue)}`}
                            target="_blank"
                            color="inherit"
                            style={{
                                margin: 0,
                                padding: 0,
                                width: 16,
                                height: 16
                            }}
                        >
                            <Tooltip title={title}>{IconElement}</Tooltip>
                        </Link>
                    );
                }
                break;
            default:
                return (
                    <a href={`${linkTo}`}>
                        <Tooltip title={title}>{IconElement}</Tooltip>
                    </a>
                );
        }
    }
    return <Tooltip title={title}>{IconElement}</Tooltip>;
};

export default ApplicantViewHeader;
