import React from "react";
import Autocomplete, {
    AutocompleteProps,
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import _ from "lodash";
import { getControlOptionsAndChildrenFilter } from "./util/AutoCompleteUtil";
import { getErrorAndMessages } from "./util/general";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { boolean } from "yup";
interface IProps {
    label: string;
    helperTextDefault: null | string;
    options: any;
    campoMetadata: Compleo.IObject;
    customLists: Compleo.IObject[];
    multiple?: boolean;
    freeSolo?: boolean;
    required: boolean;
    language?: string;
    t: any;
    validateOnEntryDefinition?: IValidateOnEntryDefinition;
    inputProps?: any;
}

export interface IValidateOnEntryDefinition {
    validateFunction: (value: string) => boolean;
    errorMessage: string;
}

const filter = createFilterOptions<{ label: string; inputValue?: string }>();

const TagsCreatable = (props: IProps & FieldProps) => {
    const {
        freeSolo,
        multiple,
        label,
        field,
        form: {
            dirty,
            touched,
            errors,
            status,
            setFieldValue,
            values,
            setFieldError,
            setFieldTouched
        },
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        t,
        validateOnEntryDefinition,
        ...other
    } = props;
    const language = props.language || "pt-BR";

    const showMessage = useShowMessage();

    const { error, message } = getErrorAndMessages(
        errors,
        field.name,
        touched,
        status,
        helperTextDefault
    );

    const freeSoloControl = freeSolo === undefined ? false : freeSolo;
    const multipleControl = multiple === undefined ? false : multiple;

    let defaultValue = null;
    if (multipleControl) {
        defaultValue = [];
    }

    const valueFromFormik = _.get(values, field.name);
    const controlValue =
        (valueFromFormik || "") === "" ? defaultValue : valueFromFormik;

    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilter(
        campoMetadata,
        options,
        customLists,
        field,
        values,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;

    const addNewValue = (lastItemArray: string, newArrayValue: any[]) => {
        if (validateOnEntryDefinition) {
            if (validateOnEntryDefinition.validateFunction(lastItemArray)) {
                setFieldValue(field.name, newArrayValue);
            } else {
                showMessage({
                    message: validateOnEntryDefinition.errorMessage,
                    typeMessage: "error",
                    snackBarOrign: {
                        horizontal: "center",
                        vertical: "top"
                    },
                    timeout: 2000
                });
            }
        } else {
            setFieldValue(field.name, newArrayValue);
        }
    };

    const onChangeMethod = (event: any, newValue: any | null) => {
        if (newValue === null) {
            setFieldValue(field.name, []);
        } else {
            const lastItem = newValue[newValue.length - 1];
            if (typeof lastItem === "string") {
                addNewValue(lastItem, newValue);
            } else if (newValue && (lastItem || {}).inputValue) {
                addNewValue((lastItem || {}).inputValue, [
                    ...newValue.slice(0, -1),
                    lastItem.inputValue
                ]);
            } else if (newValue) {
                setFieldValue(field.name, newValue);
            } else {
                setFieldValue(field.name, []);
            }
        }
    };

    return (
        <Autocomplete
            freeSolo={freeSoloControl}
            multiple={multipleControl}
            id={field.name}
            value={controlValue}
            options={controlOptions}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                    return option;
                }

                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label || option[`label-${language}`] || "";
            }}
            onChange={onChangeMethod}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                    filtered.push({
                        inputValue: params.inputValue,
                        label: t("TAGSCREATABLE_add", {
                            value: params.inputValue
                        })
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) =>
                option.label || option[`label-${language}`] || ""
            }
            onBlur={() => {
                setFieldTouched(field.name);
            }}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    helperText={message}
                    // margin="normal"
                />
            )}
        />
    );
};

export default TagsCreatable;
