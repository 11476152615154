import React, { ComponentType, Component, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f4f6f8"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: { main: "#322241" }
    }
});

const Page = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div>
                <CssBaseline />
                <main className={classes.content}>
                    <Container className={classes.container} maxWidth="lg">
                        <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={1}>
                                <Loading />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </div>
        </ThemeProvider>
    );
};

export default Page;
