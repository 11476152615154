import { useAuthorization } from "_ReactContext/AuthContext";

const useJobAccess = () => {
    const { isFeatureAuthorized } = useAuthorization();
    const allowView = isFeatureAuthorized("ViewJob");
    const allowEdit = isFeatureAuthorized("EditJob");
    const allowDelete = isFeatureAuthorized("DeleteJob");
    const allowAdd = isFeatureAuthorized("AddJob");

    return { allowAdd, allowEdit, allowView, allowDelete };
};

export default useJobAccess;
