import React from "react";
import { amber, green, orange } from "@material-ui/core/colors";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TypeMessage } from "_ReactContext/NotificationContext";
import { Icon, LinearProgress } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const topSpace = 7;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%"
    },
    success: {
        backgroundColor: green[600],
        marginTop: theme.spacing(topSpace)
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginTop: theme.spacing(topSpace)
    },
    info: {
        backgroundColor: orange[400],
        color: theme.palette.getContrastText(orange[400]),
        marginTop: theme.spacing(topSpace)
    },
    warning: {
        backgroundColor: amber[700],
        marginTop: theme.spacing(topSpace)
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        width: "100%"
    },
    linearProgressRoot: {
        width: "100%",
        marginTop: theme.spacing(2),
        "& > * + *": {
            marginTop: theme.spacing(4)
        }
    }
}));

interface IProps {
    open: boolean;
    snackBarOrigin: SnackbarOrigin;
    variant: TypeMessage;
    message: string;
    autoHideDuration?: number;
    closeMessage: () => void;
    showLinearProgress?: boolean;
}

const MessageSnackBar = (props: IProps) => {
    const classes = useStyles();
    const {
        open,
        snackBarOrigin,
        variant,
        message,
        autoHideDuration,
        closeMessage,
        showLinearProgress = false
    } = props;

    const handleClose = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        debugger;
        if (reason === "clickaway") {
            return;
        }

        closeMessage();
    };
    // console.log("autoHideDuration", autoHideDuration);

    return (
        <Snackbar
            anchorOrigin={snackBarOrigin}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={variant}>
                {message}
                {showLinearProgress && (
                    <div className={classes.linearProgressRoot}>
                        <LinearProgress
                            style={{
                                width: "100%",
                                backgroundColor: "white"
                            }}
                        />
                    </div>
                )}
            </Alert>
        </Snackbar>
    );
};

interface IState {
    open: boolean;
    message: string;
    snackBarOrigin: SnackbarOrigin;
    variant: TypeMessage;
    autoHideDuration?: number;
    showLinearProgress?: boolean;
}
const useShowMessageFullControl = () => {
    const defaultState: IState = {
        message: "",
        open: false,
        snackBarOrigin: {
            vertical: "top",
            horizontal: "right"
        },
        variant: "success"
    };
    const [state, setState] = React.useState<IState>(defaultState);
    const openMessage = (params: {
        message: string;
        snackBarOrigin?: SnackbarOrigin;
        variant?: TypeMessage;
        autoHideDuration?: number;
        showLinearProgress?: boolean;
    }) => {
        setState({ ...state, ...params, open: true });
    };
    const closeMessage = () => {
        setState(defaultState);
    };

    const MessageElement = state.open ? (
        <MessageSnackBar {...state} closeMessage={closeMessage} />
    ) : null;

    return { MessageElement, openMessage, closeMessage };
};

export default useShowMessageFullControl;
