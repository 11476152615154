import { apiDirectCall } from "customHooks/useApi/api";
import useGetMetadata from "customHooks/useGetMetadata";
import _ from "lodash";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { FieldOptionsType } from "./FormData";

export const getTranslationFN = (
    translationData: any,
    translationDataDefaultLanguage: any
) => {
    const t = (keyTrans: string, optionalValue?: string) => {
        const fallBackValue =
            optionalValue === undefined ? keyTrans : optionalValue;
        return (
            (translationData || {})[keyTrans] ||
            (translationDataDefaultLanguage || {})[keyTrans] ||
            fallBackValue
        );
    };
    return t;
};

const useHandleInitialValues = (
    valuesFromSource: Compleo.IObject,
    languages: string[],
    defaultLanguage?: string,
    isEditPage: boolean = false
) => {
    // console.log("languages", languages);
    // console.log("defaultLanguage", defaultLanguage);
    // console.log("isEditPage", isEditPage);
    const [initialValues, setInitialValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
        readyToExecute: boolean;
    }>({ ready: false, values: valuesFromSource, readyToExecute: false });

    const readyToExecute = initialValues.readyToExecute;
    const languagesReady = languages.length > 0;
    const allReady =
        (isEditPage ? !_.isEmpty(valuesFromSource.name) : true) &&
        languagesReady;

    React.useEffect(() => {
        if (!initialValues.readyToExecute && allReady) {
            setInitialValues({
                ...initialValues,
                values: { ...valuesFromSource },
                readyToExecute: true
            });
        }
    }, [allReady, initialValues.readyToExecute]);

    const [translationsData, setTranslationsData] = React.useState<
        Compleo.IObject
    >({ ready: false, translations: {} });
    const [translationsReady, setTranslationsReady] = React.useState(false);
    const [
        formsAndFields,
        setFormsAndFields
    ] = React.useState<Compleo.IObject | null>(null);

    const { company } = useAuthState();
    const [applicantMetadata] = useGetMetadata("APPLICANTNEW");

    React.useEffect(() => {
        if (!translationsReady && readyToExecute) {
            const defineTranslations = async () => {
                const newTranslations = { ...translationsData.translations };
                for (const language of languages) {
                    const postAddressSearch = `/general/translation/${company.companyId}/0/${language}/APPLICANTNEW`;
                    const data = await apiDirectCall(postAddressSearch, "get");
                    newTranslations[language] = data.data;
                }
                setTranslationsData({
                    ready: true,
                    translations: newTranslations
                });
            };
            defineTranslations();
            setTranslationsReady(true);
        }
    }, [languages, readyToExecute]);

    const formGroups = applicantMetadata.response?.data?.formGroups;
    const metadata: Compleo.IObject[] = (
        applicantMetadata.response?.data?.metadado || []
    ).filter((i: Compleo.IObject) => i.allowInCareers === true);
    // const metadata = applicantMetadata.response?.data?.metadado;

    React.useEffect(() => {
        if (formGroups && metadata && readyToExecute) {
            const currentValue = { ...(initialValues.values?.formData || {}) };

            const currentFields = currentValue.fields || [];
            const currentForms = currentValue.forms || [];
            const currentFormsIds = currentForms.map((f: any) => f.id);

            const formsJoined = [
                ...formGroups.filter(
                    (f: any) => !currentFormsIds.includes(f.id)
                ),
                ...currentForms
            ].filter((f: any) => {
                return (
                    metadata.filter(
                        (field: Compleo.IObject) => field.formGroupId === f.id
                    ).length > 0
                );
            });

            const forms = [...formsJoined].sort(
                (itemA: Compleo.IObject, itemB: Compleo.IObject) =>
                    itemA.order - itemB.order
            );
            const newFields: Compleo.IObject[] = [];

            forms.map((form: Compleo.IObject) =>
                metadata
                    .filter(
                        (field: Compleo.IObject) =>
                            field.formGroupId === form.id
                    )
                    .sort(
                        (itemA: Compleo.IObject, itemB: Compleo.IObject) =>
                            itemA.order - itemB.order
                    )
                    .map((field: Compleo.IObject, index: number) => {
                        const currentField = currentFields.filter(
                            (f: any) => f.fieldName === field.fieldName
                        )[0];

                        const fieldToAdd = currentField || field;

                        let fieldOptions: FieldOptionsType =
                            fieldToAdd.required === true
                                ? "required"
                                : "optional";

                        if (currentField) {
                            fieldOptions = currentField.fieldOptions;
                        }

                        /*
                            Por enquanto não é possível personalizar
                            as traduções de detalhe do ComplexField.
                            No futuro alterar este flag para true
                            e fazer as conversões de nome de campo
                        */
                        const loadComplexFieldDetail = false;
                        const loadField = !field.complexFieldDetail
                            ? true
                            : loadComplexFieldDetail &&
                              field.complexFieldDetail === true;

                        if (loadField) {
                            newFields.push({
                                fieldName: fieldToAdd.fieldName,
                                order: index,
                                formGroupId: fieldToAdd.formGroupId,
                                fieldOptions: fieldOptions
                            });
                        }
                    })
            );
            setFormsAndFields({ forms, fields: newFields });
        }
    }, [metadata.length, formGroups, readyToExecute]);

    const fieldsMetadata = applicantMetadata.response?.data?.metadado || [];
    const messages: Compleo.IObject[] = [];
    fieldsMetadata.map((item: Compleo.IObject) => {
        (item.validation || []).map((it: any) => {
            if (
                messages.filter((f: any) => f.messageId === it.messageId)
                    .length === 0
            ) {
                messages.push({ ...it, field: item.fieldName });
            }
        });
    });

    if (
        translationsData.ready &&
        !initialValues.ready &&
        applicantMetadata.status === "success" &&
        formsAndFields !== null &&
        readyToExecute
    ) {
        const forms = formsAndFields.forms;
        const fields = formsAndFields.fields;
        const currentValue = initialValues.values.translations || {};
        const currentFieldValue = { ...(currentValue || {}) };
        const translations = translationsData.translations;
        languages.map((language: string) => {
            const t = getTranslationFN(
                translations[language],
                translations[defaultLanguage || language]
            );

            const defaultValues: Compleo.IObject = {};
            forms.map((item: Compleo.IObject) => {
                defaultValues[`a_FormGroup_${item.id}`] = t(
                    `a_FormGroup_${item.id}`,
                    ""
                );
                // Help do form group
                defaultValues[`a_FormGroup_${item.id}_help`] = t(
                    `a_FormGroup_${item.id}_help`,
                    ""
                );
            });

            fields.map((item: Compleo.IObject) => {
                defaultValues[item.fieldName] = t(item.fieldName, "");

                // Padrões de help inline e popup
                defaultValues[`${item.fieldName}_helperText`] = t(
                    `${item.fieldName}_helperText`,
                    ""
                );
                defaultValues[`${item.fieldName}_tooltipTitle`] = t(
                    `${item.fieldName}_tooltipTitle`,
                    ""
                );
                defaultValues[`${item.fieldName}_tooltipDetail`] = t(
                    `${item.fieldName}_tooltipDetail`,
                    ""
                );
            });
            messages.map((item: Compleo.IObject) => {
                defaultValues[item.messageId] = t(item.messageId, "");
            });
            const newLanguageValue = {
                ...defaultValues,
                ...(currentValue[language] || {})
            };

            currentFieldValue[language] = newLanguageValue;
        });
        setInitialValues({
            ...initialValues,
            values: {
                ...initialValues.values,
                translations: currentFieldValue,
                formData: { forms, fields }
            },
            ready: true
        });
    }

    return {
        initialValues: initialValues.values,
        translations: translationsData.translations,
        readyInitialValues: initialValues.ready,
        messages: messages
    };
};

export default useHandleInitialValues;
