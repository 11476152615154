import { useApiCache } from "customHooks/useApi";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";

interface IUseDashboardItemHelper {
    widgetName: string;
}

const useDashboardItemHelper = (props: IUseDashboardItemHelper) => {
    const { widgetName } = props;
    const { company } = useAuthState();
    const moduleName = "DASHBOARDMAIN";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddressSearch = `/reports/genericdashboard`;
    const [getData] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId, widgetType: widgetName },
        false
    );
    const ready = readyTranslation && getData.status === "success";

    return {
        t,
        i18n,
        getData,
        ready
    };
};

export default useDashboardItemHelper;
