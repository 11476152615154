import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useCompleoScreeningQuestionnaire, {
    IUseCompleoScreeningQuestionnaireParams
} from "../useCompleoScreeningQuestionnaire";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ISimulationModelProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    screeningQuestionnaireParms: IUseCompleoScreeningQuestionnaireParams;
    t: any;
}
const SimulationModal = (props: ISimulationModelProps) => {
    const { open, setOpen, screeningQuestionnaireParms, t } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const [form] = useCompleoScreeningQuestionnaire(
        screeningQuestionnaireParms
    );

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">
                {t("simulation")}
            </DialogTitle>
            <DialogContent>{form}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SimulationModal;
