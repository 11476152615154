import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import * as util from "functions/util";
import { useTranslation } from "react-i18next";
import useGetMetadata from "customHooks/useGetMetadata";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        }
    })
);

interface IProps {
    handleModalClose: any;
    open: boolean;
    fieldValue: any;
    setFieldValue: (value: any) => void;
    // field: FieldInputProps<any>;
    // helpers: FieldHelperProps<any>;
}

const ResultsModal = (props: IProps) => {
    const { handleModalClose, open, fieldValue, setFieldValue } = props;
    const classes = useStyles();

    const moduleName = "JOBQUESTIONNAIRERESULTMODAL";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, false, {});
    valuesFromSource.results = fieldValue?.results || [];

    const closeModal = () => {
        handleModalClose();
    };

    let addSectionReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const addResults = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        // const currentField = ((fieldValue || {}).results = []);
        setFieldValue({ ...fieldValue, results: values.results });

        handleModalClose();
        return retorno;
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addResults,
        postReturn: addSectionReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource,
        secondaryButtonFunction: closeModal
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && readyTranslation) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            open={open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("a_ModalTitle")}
            aria-describedby={t("a_ModalTitle")}
            PaperProps={{
                className: classes.dialog
            }}
            scroll="body"
        >
            <DialogTitle id="alert-dialog-title">
                {t("a_ModalTitle")}
            </DialogTitle>
            <DialogContent>{compRetorno}</DialogContent>
        </Dialog>
    );
};

export default ResultsModal;
