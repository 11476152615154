import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import CustomComponents from "./components/ScorecardCustomComponents";
// import { useSelector } from "react-redux";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi } from "customHooks/useApi";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";

const ScorecardNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/scorecard/list");
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "SCORECARDNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/scorecard/new`;

    const [addPipelineReturn, addPipeline] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.deleted = false;
    valuesFromSource.default = false;

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addPipeline,
        postReturn: addPipelineReturn,
        redirectAddress: "/scorecard/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ScorecardNew;
