import { FieldHtml } from "customHooks/useCompleoView/Fields/FieldsType";
import FieldSwitchType from "customHooks/useCompleoView/Fields/MainFields/FieldSwitchType";
import React from "react";

const ApplicantOpinionInTheJob = (props: any) => {
    console.log("props", props);
    return <FieldSwitchType {...props} type="richtext" />;
};

export default ApplicantOpinionInTheJob;
