import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { ApiStateType, useApiCache } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { useAuthState } from "_ReactContext/AuthContext";
import useJobRequestLastRequestCorrectionReason from "./useJobHistory";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    jobData: Compleo.IObject;
    newStatus: CompleoShared.Common.JobStatus;
    oldStatus: CompleoShared.Common.JobStatus;
    editStatusMain: (
        newStatus: CompleoShared.Common.JobStatus,
        otherValues?: Compleo.IObject | undefined
    ) => Promise<void>;
}

const ModalChangeStatus = (props: IModalNewMail) => {
    const {
        open,
        handleClose,
        jobData,
        newStatus,
        editStatusMain,
        oldStatus
    } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const formikRef = React.useRef(null);
    const { company } = useAuthState();

    const module = getModuleName(newStatus, oldStatus);
    const [metadata] = useGetMetadata(module);
    const disapprovedReason = useJobRequestLastRequestCorrectionReason(
        jobData.pk.split(":")[1],
        newStatus
    );

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, true);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    initialValues.title = jobData.title;
    let otherDataReady = false;

    const postAddressSearch = `/job/getapplicantsbyjobstage`;
    const [getJobInfoReturn, getJobInfo] = useApiCache(
        postAddressSearch,
        "post",
        {
            jobId: jobData.pk.split(":")[1],
            companyId: company.companyId
        },
        true
    );

    React.useEffect(() => {
        if (newStatus === "finished") {
            getJobInfo();
        }
    }, [newStatus]);

    if (newStatus === "finished") {
        const jobDataGroups =
            getJobInfoReturn.response?.data?.applicantsByGroup || [];
        const hiredValue =
            jobDataGroups.filter(
                (i: Compleo.IObject) => i?.key?.stageGroup === "hired"
            )[0]?.doc_count || 0;

        initialValues.numberOfPositions = jobData.numberOfPositions || 0;
        initialValues.applicantsInHiredGroup = hiredValue;
        initialValues.closingDate = currentDate;
        otherDataReady = getJobInfoReturn.status === "success";
    } else {
        otherDataReady = true;
    }
    if (newStatus === "canceled") {
        initialValues.cancellationDate = currentDate;
    }
    if (newStatus === "open" && oldStatus === "draft") {
        initialValues.openingDate = currentDate;
    }
    if (newStatus === "open" && oldStatus === "approvedRequest") {
        initialValues.openingDate = currentDate;
    }
    if (newStatus === "open" && oldStatus === "suspended") {
        initialValues.openingDate = jobData?.openingDate || currentDate;
    }
    if (newStatus === "approvedRequest") {
        initialValues.approvalDate = currentDate;
    }
    if (newStatus === "disapprovedRequest") {
        initialValues.disapprovalDate = currentDate;
    }
    if (newStatus === "suspended") {
        initialValues.lastSuspensionDate = currentDate;
    }
    if (newStatus === "requested" && disapprovedReason) {
        initialValues.disapprovedReason = disapprovedReason;
    }
    if (newStatus === "awaitingCorrectionRequest") {
        initialValues.lastCorrectionJobRequestDate = currentDate;
    }

    const ready =
        readyTranslation && metadata.status === "success" && otherDataReady;

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            setIsUpdating(true);
            await editStatusMain(newStatus, values);
            setIsUpdating(false);
            handleClose();
            return retorno;
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
    };

    const handleSave = async () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            await formikValue.submitForm();
        }
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikEnableReinitialize: false,
        formikReturn: formikRef
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (finished && ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("a_FeatureMainDescription")}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item container xs={12}>
                            {FormReturn}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("btnChangeStatus")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogContent>
                    <Grid container>
                        <Grid item container xs={12}>
                            <Loading />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
};

const getModuleName = (
    newStatus: CompleoShared.Common.JobStatus,
    oldStatus: CompleoShared.Common.JobStatus
) => {
    switch (newStatus) {
        case "finished":
            return "CHANGEJOBSTATUSTOFINISHED";
        case "canceled":
            return "CHANGEJOBSTATUSTOCANCELED";
        case "awaitingCorrectionRequest":
            return "CHANGEJOBSTATUSTOAWAITINGCORRECTIONREQUEST";
        case "open":
            if (
                oldStatus === "draft" ||
                oldStatus === "approvedRequest" ||
                oldStatus === "suspended"
            ) {
                return "CHANGEJOBSTATUSFROMDRAFTTOOPEN";
            } else {
                return "CHANGEJOBSTATUSTOOPEN";
            }
        case "approvedRequest":
            return "CHANGEJOBSTATUSTOAPPROVED";
        case "disapprovedRequest":
            return "CHANGEJOBSTATUSTODISAPPROVED";
        case "suspended":
            return "CHANGEJOBSTATUSTOSUSPENDED";
        case "requested":
            return "CHANGEJOBSTATUSTOREQUESTED";
        default:
            return "";
    }
};

export default ModalChangeStatus;
