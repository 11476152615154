import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";

const CompleoModuleEdit = (props: any) => {
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "ADMINCOMPLEOIDEASEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [listDefinition, readyLists] = useCustomLists();
    const [metadata] = useGetMetadata(moduleName);

    const postAddress = `/admin/ideaedit`;
    const [editModuleReturn, editModule] = useApi(postAddress, "post");

    const postAddressSearch = `/admin/ideasearch`;
    const [getModuleReturn, getModule] = useApiCache(
        postAddressSearch,
        "post",
        {
            uuid: props.uuid,
            companyId: company.companyId
        },
        false
    );

    // const valuesFromSource: Compleo.IObject = {};
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getModuleReturn,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;
    // valuesFromSource.details = EditorState.createEmpty();
    // console.log("valuesFromSource", valuesFromSource);

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editModule,
        editModuleReturn,
        "/admincompleo/listcompleoidea",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined,
        null,
        null,
        listDefinition
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && getModuleReturn.status === "success" && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default CompleoModuleEdit;
