import React, { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Divider, Grid, Slide, Typography } from "@material-ui/core";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFields } from "customHooks/useCompleoForm/helpers/getFields";
import useFormModalStyles from "customHooks/useCompleoForm/components/useFormModalStyles";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Delete } from "@material-ui/icons";
import {
    MetadataReturn,
    useCustomSearchMetadataReturnType
} from "./useCustomSearchMetadata";
import { getSchema } from "customHooks/useCompleoForm/helpers/helper";
import Yup from "customHooks/useCompleoForm/helpers/yupHelper";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { ApiStateType } from "customHooks/useApi";
import useList from "customHooks/useList";
import {
    filtersType,
    getTypeFromCustomFieldType,
    handleInitialValuesAdvancedFilter
} from "./helperCustomSearch";
import AddIcon from "@material-ui/icons/Add";
import AdvancedSearchFormAddFilterModal from "./AdvancedSearchFormAddFilterModal";

interface IPropsFormMain {
    advancedMetadata: useCustomSearchMetadataReturnType;
    deleteFilter: (id: string) => void;
    formikRef: React.MutableRefObject<null>;
    handleSubmit: (values: any) => void;
}
const AdvancedSearchForm = (props: IPropsFormMain) => {
    const [listData] = useList();
    const { otherFilters } = listData.definitionInfo.elasticDefData?.data || {};
    const filterValues =
        listData?.filtersInfo?.filters?.advancedSearch?.values || {};
    const lastInput = useRef(null);

    const [addFilterModalOpen, setAddFilterModalOpen] = React.useState(false);
    const { t } = listData.tempInfo;
    const classes = useFormModalStyles();
    const { advancedMetadata, deleteFilter, formikRef, handleSubmit } = props;
    const metadata: MetadataReturn[] = advancedMetadata.metadataReturn;
    const { company } = useAuthState();

    const localDeleteFilter = (id: string) => {
        const formikValueDelete: any = formikRef.current;
        if (formikValueDelete) {
            const formikValues = { ...formikValue.values };
            const keysToDelete = Object.keys(formikValues).filter((item) =>
                item.includes(id)
            );
            keysToDelete.map((keyName) => {
                delete formikValues[keyName];
            });
            formikValueDelete.setValues(formikValues);
        }
        deleteFilter(id);
    };
    // const formikRef = React.useRef(null);
    const [localMetadata, setLocalMetadata] = React.useState<MetadataReturn[]>(
        []
    );
    const { ObjectSchema, fullMetadata } = getLocalSchema(
        localMetadata,
        company.companyId
    );
    const metadataApiState: ApiStateType = {
        exception: null,
        response: {
            data: {
                metadado: fullMetadata
            }
        },
        status: "success"
    };

    const formikValue: any = formikRef.current;
    let values: Compleo.IObject = {};
    if (formikValue) {
        values = formikValue.values;
    }

    const listTypeIds = localMetadata.map((item) => {
        const itemType: filtersType = getTypeFromCustomFieldType(
            otherFilters[item.fieldName]?.type,
            item?.otherSearchDef?.fieldType
        );
        return {
            type: itemType,
            id: item.id
        };
    });

    handleInitialValuesAdvancedFilter(values, listTypeIds);
    const [initialValues] = useValuesFromSource(metadataApiState, true, {
        ...values
    });
    const finalInitialValues = {
        ...filterValues,
        ...initialValues,
        searchType: "and"
    };

    /**
     * The effect below is to update metadata information only after
     * the initial values has changed, to avoid uncontrolled react error
     */
    React.useEffect(() => {
        setLocalMetadata(metadata);
    }, [metadata.length]);

    React.useEffect(() => {
        if (addFilterModalOpen === false) {
            const timer = setTimeout(() => {
                const fieldItem: any = lastInput?.current;
                if (fieldItem !== null) {
                    fieldItem.focus();
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
        // }
    }, [addFilterModalOpen]);

    return (
        <Formik
            initialValues={finalInitialValues}
            enableReinitialize={true}
            validationSchema={ObjectSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
            }}
            innerRef={formikRef}
        >
            <Form noValidate autoComplete="off">
                {localMetadata.map(
                    (innerMetadata: MetadataReturn, index: number) => {
                        const lastGroup = index === localMetadata.length - 1;

                        return (
                            <React.Fragment key={innerMetadata.id}>
                                {index > 0 && (
                                    <Divider
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 8,
                                            padding: 0
                                        }}
                                    />
                                )}
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 600 }}
                                    gutterBottom
                                >
                                    {getFieldLabel(t, innerMetadata)}
                                </Typography>
                                <Grid
                                    key={innerMetadata.id}
                                    container
                                    spacing={0}
                                >
                                    <Grid
                                        spacing={1}
                                        container
                                        item
                                        xs={12}
                                        sm={11}
                                    >
                                        {innerMetadata.metadata.map(
                                            (
                                                fieldMetadata: Compleo.IObject,
                                                subGroupIndex: number
                                            ) => {
                                                const lastSubGroup =
                                                    subGroupIndex ===
                                                    innerMetadata.metadata
                                                        .length -
                                                        1;
                                                const itemMetadata = getNewMetadataHandleFocus(
                                                    lastGroup,
                                                    lastSubGroup,
                                                    fieldMetadata,
                                                    lastInput
                                                );

                                                return getFields(
                                                    itemMetadata,
                                                    classes,
                                                    innerMetadata.t,
                                                    {},
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    listData.tempInfo.language,
                                                    innerMetadata.lists
                                                );
                                            }
                                        )}
                                    </Grid>
                                    <Grid item xs sm>
                                        <ButtonIconTooltipCompleo
                                            label={t("COMPLEOGENERAL_Delete")}
                                            onClick={() =>
                                                localDeleteFilter(
                                                    innerMetadata.id
                                                )
                                            }
                                        >
                                            <Delete color="secondary" />
                                        </ButtonIconTooltipCompleo>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    }
                )}
                <Button
                    color="secondary"
                    onClick={() => setAddFilterModalOpen(true)}
                    style={{
                        marginTop: 16
                    }}
                    startIcon={<AddIcon />}
                >
                    {t("ELASTICLISTSETTINGS:AdvancedFilter_AddField")}
                </Button>
                {/* <AdvancedSearchFormAddFilter
                    advancedMetadata={advancedMetadata}
                /> */}
                <AdvancedSearchFormAddFilterModal
                    open={addFilterModalOpen}
                    advancedMetadata={advancedMetadata}
                    onClose={() => setAddFilterModalOpen(false)}
                    t={t}
                />
            </Form>
        </Formik>
    );
};

const getLocalSchema = (metadata: MetadataReturn[], companyId: number) => {
    const aggMetadata: Compleo.IObject[] = [];
    const aggTFunction = (keyValue: string, alternativeTrans?: string) => {
        for (const itemMetadata of metadata) {
            const translation: string = itemMetadata.t(keyValue, "");
            if (translation) {
                return translation;
            }
        }
        return "";
    };

    metadata.map((itemMetadata) =>
        itemMetadata.metadata.map((finalMetadata: Compleo.IObject) => {
            aggMetadata.push(finalMetadata);
        })
    );
    const schema = getSchema(Yup, aggMetadata, aggTFunction, companyId);
    const ObjectSchema = Yup.object().shape(schema);
    return { ObjectSchema, fullMetadata: aggMetadata };
};

const getFieldLabel = (t: any, innerMetadata: MetadataReturn) => {
    switch (innerMetadata.otherSearchDef?.type) {
        case "questionnaire":
            return `${innerMetadata.otherSearchDef?.jobTitle} - ${t(
                `ELASTICLISTSETTINGS:${(
                    innerMetadata.otherSearchDef?.QuestionnaireName || ""
                ).toLowerCase()}`,
                innerMetadata.otherSearchDef?.QuestionnaireName
            )} - ${innerMetadata.otherSearchDef?.QuestionName}`;
        default:
            return t(
                `otherFilter_${innerMetadata.fieldName}`,
                t(
                    innerMetadata.fieldName,
                    `otherFilter_${innerMetadata.fieldName}`
                )
            );
    }
};

const getNewMetadataHandleFocus = (
    lastGroup: boolean,
    lastSubGroup: boolean,
    fieldMetadata: Compleo.IObject,
    lastInput: any
) => {
    const lastField = lastSubGroup && lastGroup;
    const isAutoCompleteFields = [
        "tags",
        "select",
        "tagsCreatableArray"
    ].includes(fieldMetadata.elementType);

    const itemMetadata = !lastField
        ? fieldMetadata
        : isAutoCompleteFields
        ? {
              ...fieldMetadata,
              elementConfig: {
                  ...(fieldMetadata.elementConfig || {}),
                  inputProps: { inputRef: lastInput }
              }
          }
        : {
              ...fieldMetadata,
              elementConfig: {
                  ...(fieldMetadata.elementConfig || {}),
                  inputRef: lastInput
              }
          };

    return itemMetadata;
};

export default AdvancedSearchForm;
