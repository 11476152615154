import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";

const CustomerEdit = (props: any) => {
    const classes = useFormStyles();

    const cancelFunction = useCancelButtonForm("/customer/list");
    const { company } = useAuthState();

    const [metadata] = useGetMetadata("CUSTOMEREDIT");
    const [t, i18n, readyTranslation] = useTranslation("customeredit", {
        useSuspense: false
    });
    //console.log("CustomerEdit props", props);
    const postAddress = `/customer/edit/${props.uuid}`;

    const [editCustomerReturn, editCustomer] = useApi(postAddress, "post");

    const postAddressSearch = `/customer/search`;
    const [getCustomerReturn, getCustomer] = useApiCache(
        postAddressSearch,
        "post",
        {
            uuid: props.uuid,
            companyId: company.companyId
        },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getCustomerReturn,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;

    // const editCustomerLocal = async (values: any) => {
    //     const returnFunction = await editCustomer(values);
    //     return returnFunction;
    // };

    const [formCompleo, finished] = useCompleoForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: editCustomer,
        postReturn: editCustomerReturn,
        redirectAddress: "/customer/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (
        finished &&
        readyTranslation &&
        getCustomerReturn.status === "success"
    ) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomerEdit;
