import axios, { AxiosResponse } from "axios";
import Amplify, { Auth, API } from "aws-amplify";
import pako from "pako";

export const apiDirectCall = async (
    endpoint: string,
    verb: AxiosType = "get",
    params: Compleo.IObject = {},
    options: Compleo.IObject = {},
    fullUrl: boolean = false,
    addAuth: boolean = true
) => {
    const initialEndPoint = process.env.REACT_APP_API_ADDRESS;

    let optionsRequest: Compleo.IObject = {};
    if (addAuth) {
        const authHeader = await getAmplifyAuthHeader();
        optionsRequest.headers = { ...(options.headers || {}), ...authHeader };
        optionsRequest = { ...options, ...optionsRequest };
    } else {
        optionsRequest = options;
    }

    let endPointLocal = initialEndPoint + endpoint;
    if (fullUrl) {
        endPointLocal = endpoint;
    }
    if (verb === "get") {
        const response = await axios.get(endPointLocal, optionsRequest);
        return handleCompressedResultGzip(response);
    } else if (verb === "post") {
        const response = await axios.post(
            endPointLocal,
            params,
            optionsRequest
        );
        return handleCompressedResultGzip(response);
    } else {
        const response = await axios.put(endPointLocal, params, optionsRequest);
        return handleCompressedResultGzip(response);
    }
    // }
};

const handleCompressedResultGzip = (response: AxiosResponse) => {
    if (Object.keys(response.data || {}).includes("compressedData")) {
        const binaryString = atob(response.data.compressedData);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        // Decompress using pako
        const decompressedData = pako.inflate(bytes, { to: "string" });
        response.data = JSON.parse(decompressedData);
    }
    return response;
};

const getAmplifyAuthHeader = async () => {
    try {
        return {
            Authorization: (await Auth.currentSession())
                .getIdToken()
                .getJwtToken()
        };
    } catch (e) {
        return {};
    }
};

export type AxiosType = "get" | "post" | "put";
