import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/FilterList";
import MainTextSearch from "./MainTextSearch";
import MoreVert from "@material-ui/icons/MoreVert";
import ActionsMenu from "./ActionsMenu";
import useList from "../index";
import CustomSearch from "./CustomSearchItems/CustomSearch";
import { Typography } from "@material-ui/core";

const useStyles = (fullToolbar: boolean) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            root: {
                flexGrow: 1,
                marginBottom: fullToolbar
                    ? theme.spacing(0.5)
                    : theme.spacing(2)
            },
            menuButton: {
                marginRight: theme.spacing(2)
            },
            title: {
                flexGrow: 1
            },
            appBar: {
                backgroundColor: theme.palette.grey[100]
            },
            grow: {
                flexGrow: 1
            },
            pageTitle: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // fontWeight: 600,
                width: "100%"
            }
        });
    });

interface IProps {
    fullToolbar?: boolean;
}

const MainListToolbar = (props: IProps) => {
    const { fullToolbar = false } = props;
    const [listData, listDispatch] = useList();
    const { setLocalInfo } = listDispatch;
    const { t } = listData.tempInfo;
    const { filterIsOpen } = listData.localInfo.filterOpenInfo;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const classes = useStyles(fullToolbar)();
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const setFilterIsOpen = (open: boolean) => {
        setLocalInfo({
            ...listData.localInfo,
            filterOpenInfo: {
                ...listData.localInfo.filterOpenInfo,
                filterIsOpen: open
            }
        });
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit" elevation={0}>
                <Toolbar>
                    {!fullToolbar && (
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setFilterIsOpen(!filterIsOpen)}
                        >
                            <FilterList />
                        </IconButton>
                    )}
                    {!fullToolbar && (
                        <Typography variant="h6" className={classes.pageTitle}>
                            {t("a_FeatureMainDescription")}
                        </Typography>
                    )}
                    <MainTextSearch />
                    <div className={classes.grow} />
                    <IconButton edge="end" color="inherit" onClick={openMenu}>
                        <MoreVert />
                    </IconButton>
                    <ActionsMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        t={t}
                    />
                </Toolbar>
                <CustomSearch />
            </AppBar>
        </div>
    );
};

export default MainListToolbar;
