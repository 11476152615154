import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import {
    useCompleoSimpleList,
    actionsTableListType
} from "customHooks/useCompleoSimpleList";

const actionsTable: actionsTableListType = [
    (rowData: any) => ({
        icon: "edit",
        tooltip: "Edit Trial",
        onClick: (event: any, rowData: any) => {
            navigate(`/trial/detail/${rowData.skGS1pk.split(":")[1]}`);
        },
        hidden: !["2", "4", "12", "13"].includes(rowData.status.value)
    })
];

const TrialList = (props: any) => {
    const [t, i18n, readyTranslation] = useTranslation("TRIALLIST", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("TRIALLIST");
    const listAddress = "/trial/list";
    const { company } = useAuthState();
    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actionsTable
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};
export default TrialList;
