import React from "react";
import FormMain from "./FormMain";
import { PropsParent, typeForm } from "./FormMain";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";

const FormClassic = (props: PropsParent) => {
    const classes = useFormStyles();
    const formType: typeForm = "INTERNAL";

    return (
        <FormMain parentProps={props} classes={classes} formType={formType} />
    );
};

export default FormClassic;
