import React from "react";
import {
    handleFunnelData,
    IJobFunnelChildren,
    IMainJobFunnelData,
    IReturnFunnelData
} from "./funnelHelper";
import { useTheme } from "@material-ui/core/styles";
import CompleoMUITable, {
    ICompleoMUITableRowsDef
} from "Components/CompleoMUITable/CompleoMUITable";

const JobFunnelTable = (props: IJobFunnelChildren) => {
    const { mainData, t, exportFileName } = props;
    const initialData = handleFunnelData(mainData, t);
    const data = getFinalData(initialData);
    const columns = getColumnsMaterialTableData(t);

    return (
        <CompleoMUITable
            columns={columns}
            data={data}
            t={t}
            // tableContainerStyle={{ maxHeight: "55%" }}
            enableExportToCSV
            exportFileName={exportFileName}
            reportPDFTitle={t("funnelTable_Name")}
        />
    );
};

const getFinalData = (data: IReturnFunnelData[]) => {
    let lastStageValue = 0;
    const totalValue = data[0].value;

    return data.map((row, index) => {
        const conversionRateLastStage = lastStageValue
            ? Math.round((row.value / lastStageValue) * 100)
            : "";
        const conversionRateTotal = Math.round((row.value / totalValue) * 100);
        lastStageValue = row.value;

        return {
            name: row.name,
            value: row.value,
            totalCurrentStage: row.totalCurrentStage,
            rejectedAtStage: row.rejectedAtStage,
            totalGoNextStage:
                row.value - row.totalCurrentStage - row.rejectedAtStage,
            conversionRateLastStage:
                index > 0 ? `${conversionRateLastStage}%` : "",
            conversionRateTotal: index > 0 ? `${conversionRateTotal}%` : ""
        };
    });
};

const getColumnsMaterialTableData: (t: any) => ICompleoMUITableRowsDef[] = (
    t
) => {
    return [
        {
            name: t("stage"),
            fieldName: "name"
        },
        {
            name: t("total"),
            fieldName: "value"
        },
        {
            name: t("totalCurrentStage"),
            fieldName: "totalCurrentStage"
        },
        {
            name: t("rejectedAtStage"),
            fieldName: "rejectedAtStage"
        },
        {
            name: t("totalGoNextStage"),
            fieldName: "totalGoNextStage"
        },
        {
            name: t("conversionRateLastStage"),
            fieldName: "conversionRateLastStage"
        },
        {
            name: t("conversionRateTotal"),
            fieldName: "conversionRateTotal"
        }
    ];
};

export default JobFunnelTable;
