import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import useList, { resultTypeOptionsUseList } from "customHooks/useList";
import ItemViewGrid from "./ItemViewGrid";
import ItemViewInline from "./ItemViewInline";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemCardValue: {
            marginLeft: theme.spacing(1)
        },
        gridItem: {
            marginTop: theme.spacing(0.5)
        }
    })
);

export interface IItemView {
    label?: any;
    children: any;
    fieldDef: Compleo.IObject;
    dontApplyTypography?: boolean;
    removeColonAfterLabel?: boolean;
    inline?: boolean;
}
const ItemView = (props: IItemView) => {
    const classes = useStyles();
    const { inline = false } = props;

    if (inline) {
        return <ItemViewInline {...props} />;
    } else {
        return <ItemViewGrid {...props} />;
    }
};

export default ItemView;
