import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
// import { useField } from "formik";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText, Grid, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { apiDirectCall } from "customHooks/useApi/api";
import "react-image-crop/dist/ReactCrop.css";
import { IImageDefinitionMetadata } from "./types";
import Preview from "./Preview";
import ModalCrop from "./ModalCrop";
import { useDropzone } from "react-dropzone";
import * as CompleoFileUtil from "../File/File";
import { getUrlFromS3 } from "../File/File";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) => {
    const colorText = theme.palette.grey[100];
    return createStyles({
        paperDropzone: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            border: "1px dashed"
        },
        paperDropzoneError: {
            borderColor: theme.palette.error.main,
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            border: "1px dashed"
        },
        margin: {
            margin: theme.spacing(0)
        },
        gridPreview: {
            margin: theme.spacing(0),
            padding: theme.spacing(0)
        },
        formcontainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%"
        },
        button: {
            margin: theme.spacing(2)
        },
        inputhidden: {
            display: "none"
        }
    });
});

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const transformAspectImageDefinition = (aspect?: string) => {
    if (aspect === undefined) {
        return 1;
    } else {
        const splited = aspect.split("/");
        if (splited[0] !== undefined && splited[1] !== undefined) {
            return Number(splited[0]) / Number(splited[1]);
        } else {
            return 1;
        }
    }
};

const ImageField = (props: IInputProps) => {
    const classes = useStyles();

    const {
        name,
        label,
        helperTextDefault,
        metadata,
        t,
        required,
        ...other
    } = props;

    const {
        field,
        setFieldValue,
        setValue,
        error,
        message
    } = useRhfFieldControlled(name, helperTextDefault);

    const imageDefinition: IImageDefinitionMetadata = {
        ...(metadata.imageDefinition || {})
    };
    const [imageFromS3, setImageFromS3] = useState<null | string>(null);
    const [takePicture, setTakePicture] = useState<boolean>(
        imageDefinition.enableCamera || true
    );

    const onDropFunction = async (
        acceptedFiles: any[],
        rejectedFiles: any[]
    ) => {
        setTakePicture(false);
        if (acceptedFiles.length === 0) {
            return;
        }
        if (rejectedFiles.length > 0) {
            return;
        }
        const file = acceptedFiles[0];
        const reader = new FileReader();
        // console.log("reader.result", reader.result);
        reader.addEventListener("load", () => setUpImg({ src: reader.result }));
        reader.readAsDataURL(file);
        handleModalOpen();
    };

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: "image/*",
        onDrop: onDropFunction
    });
    const { ref, ...rootProps } = getRootProps();

    const changeValues = (fileDetails: any) => {
        // const newValue = {
        //     multiple: false,
        //     values: fileDetails
        // };
        // helpers.setValue(fileDetails);
        setFieldValue(fileDetails);
        // helpers_FileDefinition.setValue(newValue);
    };

    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setTakePicture(false);
    };
    const handleTakePicture = () => {
        setTakePicture(true);
        setModalOpen(true);
    };

    const [upImg, setUpImg] = useState<any>();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const cropDefinition: ReactCrop.Crop = {
        unit: imageDefinition.unit || "px",
        x: imageDefinition.x || 1,
        y: imageDefinition.y || 1,
        width: imageDefinition.minWidth || 200,
        height: imageDefinition.minHeight || 350,
        aspect: transformAspectImageDefinition(imageDefinition.aspect)
    };

    const [crop, setCrop] = useState<ReactCrop.Crop>(cropDefinition);
    const [completedCrop, setCompletedCrop] = useState<any>(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    // const linkFromS3 = async (key: string, originalFile: string) => {
    //     const [companyId, folder, fileName] = key.split("/");
    //     const urlReturn = await apiDirectCall(
    //         "/fileutil/requests3urlexistentfile",
    //         "post",
    //         {
    //             fileName: fileName,
    //             folder: folder,
    //             companyId: companyId
    //         }
    //     );
    //     return urlReturn.data;
    // };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image: any = imgRef.current;
        const canvas: any = previewCanvasRef.current;
        const crop: any = completedCrop;
        if (crop !== null && image !== null && canvas !== null) {
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext("2d");

            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;

            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            canvas.toBlob(
                (blob: any) => {
                    if (blob !== null) {
                        const file = new File(
                            [blob],
                            `image${new Date().getTime()}.png`,
                            { type: "image/png" }
                        );
                        // const fileDetails: Compleo.IObject[] = [];
                        // fileDetails.push(file);
                        // console.log("file photo", file);
                        changeValues({
                            tags: [],
                            FileInfo: file
                        });
                        setImageFromS3(null);
                    }
                },
                "image/png",
                1
            );
        }
    }, [completedCrop]);

    // useEffect(() => {
    //     helpers_FileDefinition.setValue({
    //         values: field.value,
    //         multiple: false
    //     });
    // }, []);

    useEffect(() => {
        const s3URL = getS3Url(field.value);
        if (!s3URL) {
            // console.log("field.value", field.value);
            const dbValue = field.value || {};
            if (CompleoFileUtil.isFileFromDB(dbValue)) {
                getUrlFromS3(dbValue.key, dbValue.originalFileName).then(
                    (pathS3: any) => {
                        // console.log("pathS3", pathS3);
                        setImageFromS3(pathS3);
                    }
                );
            }
        } else {
            setImageFromS3(s3URL);
        }
    }, [field.value]);

    const fileRejectionItemsFN = () => {
        let returnData: JSX.Element | null = null;
        const hasTooManyFilesError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter(
                        (e: any) => e.code === CompleoFileUtil.tooManyFiles
                    ).length > 0
                );
            }).length > 0;

        const hasFileTooLargeError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter(
                        (e: any) => e.code === CompleoFileUtil.fileTooLarge
                    ).length > 0
                );
            }).length > 0;

        const hasInvalidTypeError =
            fileRejections.filter((f: any) => {
                return (
                    f.errors.filter(
                        (e: any) => e.code === CompleoFileUtil.invalidType
                    ).length > 0
                );
            }).length > 0;

        if (
            hasTooManyFilesError &&
            !hasFileTooLargeError &&
            !hasInvalidTypeError
        ) {
            returnData = (
                <>
                    <h4>{t(`${field.name}FilesError`)}</h4>
                    <div>
                        {t(`CGT_fileUploadControl_MaxFilesExceeded`, {
                            max: "1"
                        })}
                    </div>
                </>
            );
        } else if (fileRejections.length > 0) {
            returnData = (
                <React.Fragment>
                    {fileRejections.map(({ file, errors }: any) => {
                        return (
                            <React.Fragment key={file.path}>
                                {file.path} - {Math.round(file.size / 1000)} KB
                                {errors.map((e: any) => (
                                    <React.Fragment key={e.code}>
                                        {" "}
                                        -{" "}
                                        {CompleoFileUtil.fileRejectionMessages(
                                            e.code,
                                            e.message,
                                            t,
                                            1,
                                            2000,
                                            ["image/*"]
                                        )}
                                    </React.Fragment>
                                ))}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            );
        }
        return returnData;
    };

    const fileRejectionDetail = fileRejectionItemsFN();

    const removeImage = () => {
        setCompletedCrop(null);
        changeValues([]);
        setImageFromS3(null);
    };
    const hasImage = completedCrop || imageFromS3;
    const paddingBottom = hasImage ? 0 : 16;
    const generalErrorCheck = error || fileRejectionDetail !== null;
    return (
        <div tabIndex={0} ref={field.ref}>
            <FormLabel
                component="legend"
                error={generalErrorCheck}
                required={required}
            >
                {props.label}
            </FormLabel>
            <Grid container>
                {imageDefinition.enableCamera === true ||
                imageDefinition.enableCamera === undefined ? (
                    <Grid
                        item
                        container
                        xs={1}
                        alignItems="center"
                        justify="center"
                        direction="row"
                        zeroMinWidth
                    >
                        <Tooltip title={t("ImageField_takePictureButton")}>
                            <IconButton
                                aria-label={t("ImageField_takePictureButton")}
                                color="primary"
                                onClick={handleTakePicture}
                            >
                                <PhotoCameraIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null}
                <Grid item xs>
                    <Paper
                        elevation={0}
                        {...rootProps}
                        className={
                            error
                                ? classes.paperDropzoneError
                                : classes.paperDropzone
                        }
                        style={{ paddingBottom: paddingBottom }}
                    >
                        <input
                            {...getInputProps()}
                            id={field.name}
                            name={field.name}
                        />
                        <div>{t(`ImageField_Instruction`)}</div>
                        <Preview
                            classes={classes}
                            completedCrop={completedCrop}
                            imageFromS3={imageFromS3}
                            removeImage={removeImage}
                            previewCanvasRef={previewCanvasRef}
                            imageDefinition={imageDefinition}
                            labelImage={props.label || ""}
                            t={t}
                            enableCamera={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ModalCrop
                modalIsOpen={modalOpen}
                handleModalClose={handleModalClose}
                t={t}
                onLoad={onLoad}
                setCrop={setCrop}
                setCompletedCrop={setCompletedCrop}
                crop={crop}
                srcImage={(upImg || {}).src}
                imageDefinition={imageDefinition}
                enableCamera={takePicture}
                labelImage={props.label || ""}
                setUpImage={setUpImg}
            />
            {/* <Button onClick={handleTakePicture} color="primary" autoFocus>
                Take a picture
            </Button> */}

            <FormHelperText error={generalErrorCheck}>
                {message} {fileRejectionDetail}
            </FormHelperText>
        </div>
    );
};

const getS3Url: (fieldValue: any) => string | undefined = (fieldValue: any) => {
    // if (Array.isArray(fieldValue) && fieldValue.length > 0) {
    //     if (fieldValue[0].s3URL !== undefined) {
    //         return fieldValue[0].s3URL;
    //     } else {
    //         return null;
    //     }
    // }
    return fieldValue?.s3URL;
};

export default ImageField;
