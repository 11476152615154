import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Legend,
    LabelList,
    Tooltip
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { defaultMapProps, getWidgetData } from "../Helpers/_DashboardHelper";
import useDashboardItemHelper from "./useDashboardItemHelper";
import DashboardMainItem from "./DashboardMainItem";

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
        paddingRight: 16,
        paddingBottom: 16,
        margin: 0
    },
    content: {
        width: "100%",
        height: "100%"
    },
    title: {
        fontSize: 14
    },
    count: {
        marginTop: 8
    }
});

interface IDashboardPieChart {
    widgetName: string;
    widget: CompleoShared.Dashboard.widgetsMetadata;
}

const DashboardPieChart = (props: IDashboardPieChart) => {
    const { widgetName, widget } = props;
    const { t, i18n, ready, getData } = useDashboardItemHelper({
        widgetName: widgetName
    });
    const data = getData?.response?.data?.data;
    const graphData = getWidgetData({
        data: data,
        widgetDef: widget,
        widgetName: widgetName,
        language: i18n.languages[0],
        mapProps: widget.mapProps || defaultMapProps
    });

    const finalData = React.useMemo(() => graphData, [graphData.length]);

    const objKeys = finalData.map((item: any) => item.name);

    const colorData = useRandomColor({
        objectKeys: objKeys,
        luminosity: "dark"
    });

    return (
        <DashboardMainItem ready={ready} t={t} widgetName={widgetName}>
            <ResponsiveContainer minWidth="100%" minHeight="100%">
                <PieChart>
                    <Pie
                        data={finalData}
                        cx="50%"
                        cy="50%"
                        // labelLine={true}
                        label={true}
                        // label={renderCustomizedLabel}
                        // outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {finalData.map(
                            (entry: Compleo.IObject, index: number) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colorData.colorsObject[entry.name]}
                                />
                            )
                        )}
                        {/* <LabelList dataKey="name" position="outside" /> */}
                    </Pie>
                    <Legend verticalAlign="bottom" height={36} />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </DashboardMainItem>
    );
};

export default DashboardPieChart;
