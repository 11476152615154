import { Button, Icon } from "@material-ui/core";
import ApplicantViewModalContainer from "Pages/Applicant/ApplicantView/ApplicantViewModalContainer";
import React from "react";
import { TFunction } from "react-i18next";

interface IButtonViewApplicant {
    t: TFunction;
    applicantId: string;
}

const ButtonViewApplicant = (props: IButtonViewApplicant) => {
    const { t, applicantId } = props;

    const applicantIdFinal = applicantId
        ? applicantId.toString().split(":")[1] || applicantId.toString()
        : "";

    const [openModalApplicantView, setOpenModalApplicantView] = React.useState<{
        open: boolean;
        applicandId: string;
    }>({
        open: false,
        applicandId: ""
    });

    if (!applicantIdFinal) return <></>;

    return (
        <>
            <div
                style={{
                    whiteSpace: "nowrap"
                }}
            >
                <Button
                    onClick={() =>
                        setOpenModalApplicantView({
                            open: true,
                            applicandId: applicantIdFinal
                        })
                    }
                    startIcon={<Icon>visibility</Icon>}
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    {t("COMPLEOGENERAL_View")}
                </Button>
            </div>
            <ApplicantViewModalContainer
                onClose={() =>
                    setOpenModalApplicantView((modalData) => {
                        return { ...modalData, open: false };
                    })
                }
                open={openModalApplicantView.open}
                loadingData={true}
                ApplicantId={openModalApplicantView.applicandId}
                module={"APPLICANTVIEW"}
            />
        </>
    );
};

export default ButtonViewApplicant;
