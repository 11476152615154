import { Auth } from "aws-amplify";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { helperAmplifyMessage } from "functions/helperAmplifyMessages";

export const ChangePasswordFunction = (t: any) => {
    const showMessage = useShowMessage();
    return async (values: any) => {
        let retorno: { [k: string]: any } = {};

        try {
            await Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(
                        user,
                        values.currentPassword,
                        values.newPassword
                    );
                })
                .then(() => (retorno = { status: 200 }));

            showMessage(t("Ok_Yes"), "success");
            return retorno;
        } catch (err) {
            retorno = { status: 400 };
            retorno.data = {};
            retorno.data.message = [
                {
                    dataPath: "currentPassword",
                    message: helperAmplifyMessage(err.code, t)
                }
            ];
        }
        return retorno;
    };
};
