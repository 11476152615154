import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Button, Icon } from "@material-ui/core";
import {
    useCompleoSimpleList,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import useActionsTable from "./components/useActionsTable";
import RightButtonList from "./components/RightButtonList";
import useEmailTemplate from "../../customHooks/useCompleoForm/Inputs/EmailDataV2/useEmailTemplate";

const EmailTemplateList = (props: any) => {
    const moduleName = "EMAILTEMPLATELIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const emailTemplateData = useEmailTemplate();
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/emailtemplate/list";
    const { company } = useAuthState();
    const actions: actionsTableFunction = useActionsTable(company.companyId, t);

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
            // totalFakeDataMultiply: 650
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    const ready = readyTranslation && finished && emailTemplateData.ready;
    if (ready) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                RightButtonComponent={
                    <RightButtonList
                        t={t}
                        options={emailTemplateData.typesOfEmailList}
                    />
                }
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default EmailTemplateList;
