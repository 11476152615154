import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { EditorState, Modifier, ContentState, convertToRaw } from "draft-js";
import IconButton from "@material-ui/core/IconButton";
import CodeIcon from "@material-ui/icons/Code";
// @ts-ignore
import draftToHtml from "draftjs-to-html";

// import { useField } from "formik";
// import { TemplatePlaceholderDialog } from "./RichTextVars";
// import { DialogBody } from "./DialogBody";
import htmlToDraft from "html-to-draftjs";
import ModalEditCode from "./ModalEditCode";

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        padding: "4px"
    },
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

const RichTextToolbarCodeEditor = (props: any) => {
    const classes = useStyles();
    // const [fieldSubject, meta, helpers] = useField(props.subjectFieldName);
    const { editorState, onChange, t, setLocalValue } = props;
    const htmlValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
    );
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    // const handleChooseAnPlaceholder = async (value: string) => {
    //     const contentState = Modifier.replaceText(
    //         editorState.getCurrentContent(),
    //         editorState.getSelection(),
    //         `{{${value}}}`,
    //         editorState.getCurrentInlineStyle()
    //     );
    //     await onChange(
    //         EditorState.push(editorState, contentState, "insert-characters")
    //     );
    //     setOpen(false);
    // };

    // const handleChooseAnTemplate = async (subject?: string, body?: string) => {
    //     if (body) {
    //         let { contentBlocks, entityMap } = htmlToDraft(body);
    //         let contentState = Modifier.replaceWithFragment(
    //             editorState.getCurrentContent(),
    //             editorState.getSelection(),
    //             ContentState.createFromBlockArray(
    //                 contentBlocks,
    //                 entityMap
    //             ).getBlockMap()
    //         );
    //         await onChange(
    //             EditorState.push(editorState, contentState, "insert-fragment")
    //         );
    //     }

    //     if (props.subjectFieldName && subject) {
    //         helpers.setValue(subject);
    //     }
    //     setOpen(false);
    // };

    return (
        <div>
            <label htmlFor="icon-button-file">
                <IconButton
                    aria-label={t("EMAILDATAFIELLD_openChooseOptionModal")}
                    component="span"
                    onClick={handleClickOpen}
                    className={classes.iconButton}
                >
                    <CodeIcon />
                </IconButton>
            </label>
            <ModalEditCode
                open={open}
                handleClose={() => setOpen(false)}
                t={t}
                htmlValue={htmlValue}
                onChange={onChange}
            />
            {/* <TemplatePlaceholderDialog t={t} open={open} onClose={handleClose}>
                <DialogBody
                    handleChooseAnOption={handleChooseAnPlaceholder}
                    handleChooseAnTemplate={handleChooseAnTemplate}
                    placeholders={placeholders}
                    templates={templates}
                    t={t}
                />
            </TemplatePlaceholderDialog> */}
        </div>
    );
};

export default RichTextToolbarCodeEditor;
