import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: "flex",
            padding: theme.spacing(2),
            height: "100%",
            flexDirection: "column"
        },
        divider: {
            marginTop: theme.spacing(1)
        },
        comment: {
            textAlign: "left",
            overflow: "auto"
        },
        grow: {
            flexGrow: 1
        },
        viewIcon: {
            margin: theme.spacing(0.1),
            padding: theme.spacing(0.1)
        }
    })
);

function createMarkup(data: string) {
    return { __html: data };
}

const ApplicantHistoryOpinionOnTheApplicant = (
    props: IApplicantHistoryProps
) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;

    return (
        <Paper elevation={1} className={classes.paper}>
            <ApplicantHistoryItemDetail
                title={tLocal("Type")}
                transformDBDataParam={{
                    value: tLocal(`ApplicantHistoryType_${historyItem?.type}`),
                    t: tLocal,
                    language: language
                }}
            />

            {historyItem?.type === "newOrUpdateApplicantOpinionJob" && (
                <ApplicantHistoryItemDetail
                    title={tLocal("JobTitle")}
                    transformDBDataParam={{
                        value: historyItem?.jobData?.title,
                        language: language,
                        t: tLocal
                    }}
                />
            )}
            {historyItem?.stage?.name && (
                <ApplicantHistoryItemDetail
                    title={tLocal("Stage")}
                    transformDBDataParam={{
                        value: historyItem?.stage?.name,
                        language: language,
                        t: tLocal
                    }}
                />
            )}
            <Typography variant="body2" className={classes.comment}>
                <div
                    dangerouslySetInnerHTML={createMarkup(historyItem.comment)}
                />
            </Typography>
            <div className={classes.grow} />
            <Divider className={classes.divider} />
            <Typography variant="caption">
                {historyItem.createdByUser}
            </Typography>
        </Paper>
    );
};

export default ApplicantHistoryOpinionOnTheApplicant;
