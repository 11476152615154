import React from "react";
import { Field, FieldProps, useField } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import { addYears, addDays, isValid } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import {
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import * as formatValues from "functions/formatValues";
import DateTimeField, { getMinMaxDate } from "./DateTimeField";
import { Grid } from "@material-ui/core";
import {
    getErrorAndMessages,
    getErrorAndMessagesUseField
} from "./util/general";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    openTo?: "date" | "year" | "month" | "hours" | "minutes" | undefined;
    disablePast?: boolean;
    disableFuture?: boolean;
    invalidDateMessage?: string;
    readOnly?: boolean;
    language: Compleo.RegionalSettings.languages;
    orientation: "portrait" | "landscape";
    timeZone: string; //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    minDateAddDaysFromCurrentDate?: number;
    maxDateAddDaysFromCurrentDate?: number;
    t: any;
    name: string;
    fieldMetadata: Compleo.IObject;
    required: boolean;
}

const DateTimeRangeField = (props: IProps) => {
    const {
        name,
        label,
        helperTextDefault,
        value,
        openTo,
        disablePast,
        disableFuture,
        invalidDateMessage,
        readOnly,
        language,
        orientation,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone,
        t,
        ...other
    } = props;
    const { minDate, maxDate } = getMinMaxDate(
        props.minDate,
        props.maxDate,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone
    );

    const { format, ampm } = formatValues.getFormat(
        language,
        "dayMonthYearTime"
    );
    const [minutesDifference, setMinutesDifference] = React.useState(0);

    const startDateTimeName = `${name}.startDateTime`;
    const endDateTimeName = `${name}.endDateTime`;
    const [fieldStart, metaStart, helpersStart] = useField(startDateTimeName);
    const [fieldEnd, metaEnd, helpersEnd] = useField(endDateTimeName);

    const {
        error: errorStartField,
        message: messageStartField
    } = getErrorAndMessagesUseField(
        metaStart.error,
        metaStart.touched,
        t(`${startDateTimeName}_helperText`, "")
    );
    const {
        error: errorEndField,
        message: messageEndField
    } = getErrorAndMessagesUseField(
        metaEnd.error,
        metaEnd.touched,
        t(`${endDateTimeName}_helperText`, "")
    );

    const locale = formatValues.getFNSLocaleFromString(language);

    function isValidDate(d: any) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    const onChangeStartDate = (val: MaterialUiPickersDate) => {
        helpersStart.setValue(val);
        if (val && isValidDate(val)) {
            const finalMinutesDifference =
                minutesDifference <= 0 ? 30 : minutesDifference;
            const newDateObj = new Date(
                val.getTime() + finalMinutesDifference * 1000 * 60
            );
            helpersEnd.setValue(newDateObj);
        }
    };

    const onChangeEndDate = (val: MaterialUiPickersDate) => {
        let dateRef = new Date();
        if (isValidDate(fieldStart.value)) {
            dateRef = new Date(fieldStart.value);
        }
        if (val) {
            dateRef.setHours(val.getHours());
            dateRef.setMinutes(val.getMinutes());
        } else {
            dateRef.setMinutes(0);
        }

        if (isValidDate(fieldStart.value)) {
            const startDate = new Date(fieldStart.value);
            // const endDate = new Date(dateRef);
            const diffMs = dateRef.getTime() - startDate.getTime();
            const diffMins = diffMs / 1000 / 60;
            if (diffMins > 0) {
                setMinutesDifference(diffMins);
            } else {
                setMinutesDifference(0);
            }
        } else {
            setMinutesDifference(0);
        }
        helpersEnd.setValue(dateRef);
    };

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <Grid container>
                <Grid item container xs={12} sm={6}>
                    <KeyboardDateTimePicker
                        name={startDateTimeName}
                        id={startDateTimeName}
                        label={t(startDateTimeName)}
                        error={errorStartField}
                        helperText={messageStartField}
                        onChange={onChangeStartDate}
                        autoOk={true}
                        // onError={(error) => {
                        //     if (error && error !== messageStartField) {
                        //         helpersStart.setError(String(error));
                        //     }
                        // }}
                        value={fieldStart.value}
                        format={format}
                        ampm={ampm}
                        minDate={minDate}
                        maxDate={maxDate}
                        openTo={openTo}
                        disablePast={true}
                        disableFuture={false}
                        invalidDateMessage={invalidDateMessage}
                        readOnly={readOnly}
                        orientation={orientation}
                        KeyboardButtonProps={{
                            "aria-label": `change date ${startDateTimeName}`
                        }}
                        onBlur={() => helpersStart.setTouched(true)}
                        minutesStep={5}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <KeyboardTimePicker
                        name={endDateTimeName}
                        autoOk
                        variant="inline"
                        label={t(endDateTimeName)}
                        error={errorEndField}
                        helperText={messageEndField}
                        // format={format}
                        ampm={ampm}
                        value={fieldEnd.value}
                        onChange={onChangeEndDate}
                        onBlur={() => helpersEnd.setTouched(true)}
                        openTo="hours"
                        minutesStep={5}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default DateTimeRangeField;
