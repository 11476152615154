import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    ApplicantNewRenderType,
    getInitialPageToRender,
    IApplicantNewLocalStateData,
    IApplicantNewMainState,
    IApplicantNewMainStateAction,
    IPayloadApplicantNewAction
} from "./ApplicantNewContextHelper";

const ApplicantNewContext = React.createContext<
    IApplicantNewMainState | undefined
>(undefined);

const ApplicantNewContextDispatch = React.createContext<
    React.Dispatch<IApplicantNewMainStateAction> | undefined
>(undefined);

const defaultStateReducer: IApplicantNewLocalStateData = {
    pageToRender: "dataEntry"
};

const reducer = (
    state: IApplicantNewLocalStateData,
    action: IApplicantNewMainStateAction
): IApplicantNewLocalStateData => {
    switch (action.type) {
        case "update": {
            return { ...state, ...action.payload };
        }
        case "reset": {
            return { ...defaultStateReducer, ...action.payload };
        }
    }
};

interface IApplicantNewProvider {
    moduleName: string;
    children: JSX.Element;
    renderType: ApplicantNewRenderType;
    operationid?: string;
    itemid?: string;
    editId?: string;
}

export const ApplicantNewProvider = (props: IApplicantNewProvider) => {
    const {
        moduleName,
        children,
        renderType,
        operationid,
        itemid,
        editId
    } = props;
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { company, timeZone } = useAuthState();
    const defaultState: IApplicantNewLocalStateData = {
        pageToRender: getInitialPageToRender(renderType),
        editId: editId
    };

    const [localStateData, dispatchLocalStateData] = useReducer(
        reducer,
        defaultState
    );

    React.useEffect(() => {
        switch (renderType) {
            case "upload":
                dispatchLocalStateData({
                    payload: { pageToRender: "upload" },
                    type: "reset"
                });
                break;
            case "edit":
                dispatchLocalStateData({
                    payload: { pageToRender: "edit" },
                    type: "update"
                });
                break;
            default:
                dispatchLocalStateData({
                    payload: { pageToRender: "verificationData" },
                    type: "reset"
                });
                break;
        }
    }, [renderType]);
    /**
     * Return Data
     */
    const ready = readyTranslation;

    const returnMain: IApplicantNewMainState = {
        companyId: company.companyId,
        language: i18n.languages[0],
        localStateData: localStateData,
        queryKey: "",
        ready: ready,
        t: t,
        renderType: renderType,
        operationid: operationid,
        itemid: itemid
    };
    const returnDispatch: React.Dispatch<IApplicantNewMainStateAction> = dispatchLocalStateData;

    return (
        <ApplicantNewContext.Provider value={returnMain}>
            <ApplicantNewContextDispatch.Provider value={returnDispatch}>
                {children}
            </ApplicantNewContextDispatch.Provider>
        </ApplicantNewContext.Provider>
    );
};

const useValues = () => {
    const context = React.useContext(ApplicantNewContext);
    if (!context) {
        throw new Error(`useValues must be used within a ApplicantNewProvider`);
    }
    const returnData = context;
    return returnData;
};

const useDispatch = () => {
    const context = React.useContext(ApplicantNewContextDispatch);
    if (!context) {
        throw new Error(
            `useDispatch must be used within a ApplicantNewProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useApplicantNewContext: () => [
    IApplicantNewMainState,
    React.Dispatch<IApplicantNewMainStateAction>
] = () => {
    return [useValues(), useDispatch()];
};

export default useApplicantNewContext;
