import { IFilters } from "..";
import { addFilterUserInfo } from "./CustomSearchItems/helperCustomSearch";
import { formatDate } from "functions/formatValues";

/**
 * The type that defines the local that the field will appear on Card or Table
 */
export type localNames = "primary" | "secondary" | "hidden";

export const handleElasticData = (
    aggregations: Compleo.IObject,
    ElasticSearchListDefinition: Compleo.IObject,
    t: any,
    language: string,
    bucketSortDef?: Compleo.IObject[]
) => {
    const buckets: Compleo.IObject = ElasticSearchListDefinition?.buckets || {};
    const bucketSettings: Compleo.IObject =
        ElasticSearchListDefinition?.bucketSettings || {};

    const bucketReturn: Compleo.IObject[] = generateBucketInfo(
        buckets,
        aggregations,
        t,
        language,
        bucketSettings,
        bucketSortDef
    );
    return bucketReturn;
};

export const getFiltersUserInfo = (
    filters: Compleo.IObject,
    elasticDefData: ElasticDefDataType,
    t: any,
    language: string
) => {
    const returnData: Compleo.IObject[] = [];
    const bucketNames = Object.keys(filters?.buckets || {});
    for (const bucketName of bucketNames) {
        const bucketDefinition = elasticDefData?.data?.bucketsDef.filter(
            (bucketInfo: any) => bucketInfo.key === bucketName
        )[0];
        filters?.buckets[bucketName].values.map((item: any) => {
            if (bucketDefinition?.items?.length) {
                const bucketData = bucketDefinition.items.filter(
                    (bData: any) => bData.value === item.value
                )[0];
                if (bucketData) {
                    returnData.push({
                        value: bucketData.label,
                        description: bucketDefinition.name,
                        descriptionKey: bucketDefinition.key,
                        valueKey: bucketData.value,
                        type: "bucket"
                    });
                }
            }
        });
    }

    const customFilterNames = Object.keys(filters?.customSearch || {});
    for (const customFilterName of customFilterNames) {
        if (Object.keys(filters?.customSearch[customFilterName]).length) {
            const filterDefinition = (elasticDefData?.data?.otherFilters || {})[
                customFilterName
            ];
            const filterObj = (filters?.customSearch || {})[customFilterName];
            const description = `${t(
                `otherFilter_${customFilterName}`,
                t(customFilterName, `otherFilter_${customFilterName}`)
            )}`;
            const dataToAdd = addFilterUserInfo(
                filterDefinition?.type,
                filterObj,
                description,
                customFilterName,
                t,
                language
            );
            if (dataToAdd) {
                returnData.push(dataToAdd);
            }
        }
    }

    const advancedFilterNames = Object.keys(
        filters?.advancedSearch?.values || {}
    ).filter((item) => !["companyId", "searchType"].includes(item));
    if (advancedFilterNames.length) {
        const criteriaType =
            filters?.advancedSearch?.criteria === "OR"
                ? t("ELASTICLISTSETTINGS:AdvancedFilter_SearchAnyCriteriaShort")
                : t(
                      "ELASTICLISTSETTINGS:AdvancedFilter_SearchAllCriteriaShort"
                  );

        returnData.push({
            value: "",
            description: `${t(
                `ELASTICLISTSETTINGS:advancedFilters`
            )} (${criteriaType})`,
            descriptionKey: "advancedSearch",
            valueKey: "advancedSearch",
            type: "advancedSearch"
        });
    }

    if (filters?.mainSearch) {
        returnData.push({
            value: filters?.mainSearch,
            description: `${t(`ELASTICLISTSETTINGS:mainSearchListFilters`)}`,
            descriptionKey: "mainSearch",
            valueKey: "mainSearch",
            type: "mainSearch"
        });
    }
    return returnData;
};

export const handleChangeBucketsFilter = (
    newValue: boolean,
    itemName: string,
    filters: Compleo.IObject,
    setFilters: any,
    bucket: Compleo.IObject
) => {
    const field = (filters.buckets || {})[itemName] || {};
    const currentLocalValue = field.values || [];
    const newFilters: Compleo.IObject = { ...(filters || {}) };

    if (newValue) {
        if (
            !currentLocalValue.find((item: any) => item.value === bucket.value)
        ) {
            currentLocalValue.push({
                value: bucket.value,
                postData: bucket.postData
            });
            newFilters.buckets[itemName] = {
                values: currentLocalValue
            };
            setFilters({ ...newFilters, filterUpdated: true });
        }
    } else {
        newFilters.buckets[itemName] = {
            values: currentLocalValue.filter(
                (f: any) => f.value !== bucket.value
            )
        };
        if (!newFilters.buckets[itemName].values.length) {
            delete newFilters.buckets[itemName];
        }
        setFilters({ ...newFilters, filterUpdated: true });
    }
};

const getListBucketNested = (
    aggregations: Compleo.IObject,
    language: string,
    bucketName: string,
    buckets: Compleo.IObject
) => {
    const convertListNestedPatternToMainPattern = (aggs: any) => {
        const returnData: Compleo.IObject = {};
        if ((aggs || {})[bucketName]) {
            returnData[bucketName] = (aggs[bucketName] || {})[bucketName];
            if ((aggs || {})[bucketName][`${bucketName}-${language}`]) {
                returnData[`${bucketName}-${language}`] =
                    aggs[bucketName][`${bucketName}-${language}`];
            }
        }
        return returnData;
    };

    if (
        [
            "list",
            "listWithTranslation",
            "arrayListWithTranslation",
            "arrayList"
        ].includes(buckets[bucketName].type) &&
        buckets[bucketName].customFieldNestedName
    ) {
        const returnData = convertListNestedPatternToMainPattern(aggregations);
        return {
            aggregations: { ...aggregations, ...returnData }
        };
    }
    return { aggregations };
};

const generateBucketInfo = (
    buckets: Compleo.IObject,
    aggregations: Compleo.IObject,
    t: any,
    language: string,
    bucketSettings: Compleo.IObject,
    bucketSortDef?: Compleo.IObject[]
) => {
    const bucketNames = Object.keys(buckets);
    const bucketReturn: Compleo.IObject[] = [];
    const defaultMaxItems = bucketSettings?.defaultMaxItems || 99999;
    for (const bucketName of bucketNames) {
        const { aggregations: aggregationsObj } = getListBucketNested(
            aggregations,
            language,
            bucketName,
            buckets
        );
        const data = generateBucketItem(
            t,
            buckets,
            bucketName,
            aggregationsObj,
            language
        );
        bucketReturn.push({
            items: [...data],
            name: t(
                `BucketName_${bucketName}`,
                t(bucketName, `BucketName_${bucketName}`)
            ),
            key: bucketName,
            maxItems: buckets[bucketName].maxItems || defaultMaxItems,
            bucketReport: buckets[bucketName].bucketReport,
            sortAlphabetically: buckets[bucketName].sortAlphabetically
        });
    }

    // const bucketSorted = bucketReturn
    //     .sort((a: any, b: any) => a.name.localeCompare(b.name))
    //     .map((item, index) => {
    //         return { ...item, order: index };
    //     });
    const bucketSorted = sortBucket(bucketReturn, bucketSortDef);
    return bucketSorted;
};

const sortBucket = (
    bucketReturn: Compleo.IObject[],
    bucketSortDef?: Compleo.IObject[]
) => {
    const bucketSorted: Compleo.IObject[] = bucketReturn
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .map((item, index) => {
            return { ...item, order: index };
        });
    if (!Object.keys(bucketSortDef || {}).length) {
        // Ordenação padrão (alfabética)
        return bucketSorted;
    } else {
        const newBucket: Compleo.IObject[] = bucketSorted.map((item, index) => {
            const defItem = bucketSortDef?.filter(
                (sortItem) => sortItem.key === item.key
            )[0];
            if (defItem) {
                return { ...item, order: defItem.order };
            } else {
                return { ...item, order: 1000 + index };
            }
        });
        newBucket.sort((a, b) => a.order - b.order);
        return newBucket;
    }
};

export const getOrderedObjectNames = (
    obj: Compleo.IObject,
    orderFieldName: string = "order"
) => {
    const statusOrder = Object.keys(obj).map((name) => {
        return {
            name: name,
            order: obj[name][orderFieldName] || 99999
        };
    });
    statusOrder.sort((a: any, b: any) => a.order - b.order);

    return statusOrder.map((item: Compleo.IObject) => item.name);
};

export const getCustomSearchOrdered = (otherFilters: any, orderData: any) => {
    const orderOtherFilters: Compleo.IObject = {};
    Object.keys(otherFilters || {}).map((item) => {
        const defItem = (orderData || []).filter(
            (orderItem: any) => orderItem.name === item
        )[0];
        if (defItem) {
            orderOtherFilters[item] = {
                ...otherFilters[item],
                order: defItem.order || 1
            };
        } else {
            orderOtherFilters[item] = {
                ...otherFilters[item],
                order: (otherFilters[item].order || 0) + 1000
            };
        }
    });
    const otherFilterNames = getOrderedObjectNames(orderOtherFilters || {});
    return otherFilterNames;
};

export const getOrderedObjectNamesByLocal = (
    obj: Compleo.IObject,
    local: localNames,
    orderFieldName: string = "order"
) => {
    const allItemsOrdered = getOrderedObjectNames(obj, orderFieldName);
    const returnData: string[] = [];
    allItemsOrdered.map((item) => {
        if (obj[item]?.local === local) {
            returnData.push(item);
        }
    });
    return returnData;
};

export const getCustomSearchOrderedLocal = (
    otherFilters: any,
    orderData: any,
    local?: localNames
) => {
    const orderOtherFilters: Compleo.IObject[] = [];

    for (const item of Object.keys(otherFilters || {})) {
        const defItem = (orderData || []).filter(
            (orderItem: any) => orderItem.name === item
        )[0];
        if (defItem) {
            orderOtherFilters.push({
                name: item,
                order: defItem.order || 1,
                local: defItem.local
            });
        } else {
            orderOtherFilters.push({
                name: item,
                order: (otherFilters[item].order || 0) + 1000,
                local: otherFilters[item]?.local || "hidden"
            });
        }
    }

    return orderOtherFilters.filter(
        (item) => item.local === local || local === undefined
    );
};

export const translateAndOrderArray = (objArray: string[], t: any) => {
    const returnData = objArray.map((value) => {
        return {
            value: value,
            label: t(`ELASTICLISTSETTINGS:searchType_${value}`)
        };
    });
    returnData.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return returnData;
};

export type ElasticDefDataType = {
    data: Compleo.IObject;
    language: string;
} | null;

const tLocal = (t: any, transKey: any, value: any) => {
    const translation = t(transKey, "");
    return translation !== "" ? translation : value;
};

const generateBucketItem = (
    t: any,
    buckets: Compleo.IObject,
    bucketName: string,
    aggregations: Compleo.IObject,
    language: string
) => {
    const getBucketInfo = (
        aggsObj: Compleo.IObject,
        aggsBucketName: string
    ) => {
        return (
            (((aggsObj || {})[aggsBucketName] || {}).inner || {})[
                aggsBucketName
            ] ||
            ((aggsObj || {})[aggsBucketName] || {}).inner ||
            (aggsObj || {})[aggsBucketName]
        );
    };

    const bucketMetadataInfo = buckets[bucketName];
    const bucketFromQuery = getBucketInfo(aggregations, bucketName);
    const bucketMissingData = getBucketInfo(
        aggregations,
        `${bucketName}_missing`
    );
    const bucketLocalLanguage = getBucketInfo(
        aggregations,
        `${bucketName}-${language}`
    );

    const retObj: Compleo.IObject[] = [];

    switch (bucketMetadataInfo.type) {
        case "list":
        case "arrayList": {
            const isArray = bucketMetadataInfo.type === "arrayList";
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    const isItemToAdd = customFieldItemValidation(
                        bucketMetadataInfo,
                        bFromQuery
                    );
                    if (isItemToAdd) {
                        retObj.push({
                            label: bFromQuery.key.label,
                            value: isArray
                                ? bFromQuery.key.label
                                : bFromQuery.key.value,
                            total: bFromQuery.doc_count
                            // filtered: filterItem?.doc_count || 0
                        });
                    }
                });
            }
            break;
        }
        case "listWithTranslation":
        case "arrayListWithTranslation":
            {
                const isArray =
                    bucketMetadataInfo.type === "arrayListWithTranslation";
                let bucketToWork: Compleo.IObject[] = [];

                if (bucketLocalLanguage?.buckets?.length) {
                    bucketToWork = bucketLocalLanguage?.buckets;
                } else {
                    bucketToWork = bucketFromQuery?.buckets;
                }
                bucketToWork.map((bFromQuery: any) => {
                    const isItemToAdd = customFieldItemValidation(
                        bucketMetadataInfo,
                        bFromQuery
                    );
                    if (isItemToAdd) {
                        retObj.push({
                            label: bFromQuery.key.label,
                            value: isArray
                                ? bFromQuery.key.label
                                : bFromQuery.key.value,
                            total: bFromQuery.doc_count
                            // filtered: filterItem?.doc_count || 0
                        });
                    }
                });
            }
            break;
        case "boolean":
            if (bucketFromQuery?.buckets?.false) {
                retObj.push({
                    label: t("booleanFalse"),
                    value: "false",
                    total: bucketFromQuery?.buckets?.false.doc_count
                    // filtered:
                    //     bucketFromQueryFiltered?.buckets?.false.doc_count || 0
                });
            }
            if (bucketFromQuery?.buckets?.true) {
                retObj.push({
                    label: t("booleanTrue"),
                    value: "true",
                    total: bucketFromQuery?.buckets?.true.doc_count
                    // filtered:
                    //     bucketFromQueryFiltered?.buckets?.true.doc_count || 0
                });
            }
            break;
        case "jobStatus":
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    // const filterItem = (
                    //     bucketFromQueryFiltered?.buckets || []
                    // ).filter((item: any) => item.key === bFromQuery.key)[0];
                    retObj.push({
                        label: tLocal(
                            t,
                            `JOBSTATUS_${bFromQuery.key}`,
                            bFromQuery.key
                        ),
                        value: bFromQuery.key,
                        total: bFromQuery.doc_count,
                        // filtered: filterItem?.doc_count || 0,
                        postData: { ...bFromQuery }
                    });
                });
            }
            break;
        case "applicantHistoryType":
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    // const filterItem = (
                    //     bucketFromQueryFiltered?.buckets || []
                    // ).filter((item: any) => item.key === bFromQuery.key)[0];
                    retObj.push({
                        label: tLocal(
                            t,
                            `ApplicantHistoryType_${bFromQuery.key}`,
                            bFromQuery.key
                        ),
                        value: bFromQuery.key,
                        total: bFromQuery.doc_count,
                        // filtered: filterItem?.doc_count || 0,
                        postData: { ...bFromQuery }
                    });
                });
            }
            break;
        case "date":
        case "number":
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    retObj.push({
                        label: tLocal(
                            t,
                            `BucketKey_${bFromQuery.key}`,
                            `BucketKey_${bFromQuery.key}`
                        ),
                        value: bFromQuery.key,
                        total: bFromQuery.doc_count,
                        // filtered: filterItem?.doc_count || 0,
                        postData: { ...bFromQuery }
                    });
                });
            }
            break;
        case "dateHistogram":
        case "jobReferenceDateHistogram":
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    const day = Number(bFromQuery.key_as_string.split("-")[2]);
                    const month = Number(
                        bFromQuery.key_as_string.split("-")[1]
                    );
                    const year = Number(bFromQuery.key_as_string.split("-")[0]);
                    let fromPostData = Number(bFromQuery.key);
                    let toPostData = Number(bFromQuery.key);

                    const dateRefRegularDate = new Date(year, month - 1, day);

                    const dateRef = dateRefRegularDate.toISOString();
                    let label = formatDate(dateRef, language, "monthYear");
                    if (
                        bucketMetadataInfo.calendar_interval &&
                        bucketMetadataInfo.calendar_interval.includes("M")
                    ) {
                        toPostData = new Date(
                            dateRefRegularDate.getFullYear(),
                            dateRefRegularDate.getMonth() + 1,
                            0,
                            23,
                            59
                        ).getTime();

                        label = formatDate(dateRef, language, "monthYear");
                    } else if (
                        bucketMetadataInfo.calendar_interval &&
                        bucketMetadataInfo.calendar_interval.includes("y")
                    ) {
                        toPostData = new Date(
                            dateRefRegularDate.getFullYear(),
                            12,
                            0,
                            23,
                            59
                        ).getTime();
                        label = year.toString();
                    } else if (
                        bucketMetadataInfo.calendar_interval &&
                        bucketMetadataInfo.calendar_interval.includes("d")
                    ) {
                        toPostData = new Date(
                            dateRefRegularDate.getFullYear(),
                            dateRefRegularDate.getMonth(),
                            dateRefRegularDate.getDate() + 1
                        ).getTime();
                        label = formatDate(dateRef, language, "dayMonthYear");
                    }

                    retObj.push({
                        label: label,
                        value: bFromQuery.key,
                        total: bFromQuery.doc_count,
                        // filtered: filterItem?.doc_count || 0,
                        postData: {
                            ...bFromQuery,
                            from: fromPostData,
                            to: toPostData
                        }
                    });
                });
            }
            break;
        case "applicantJobTitle":
            if (bucketFromQuery?.job_buckets?.buckets?.length) {
                bucketFromQuery?.job_buckets?.buckets.map((bFromQuery: any) => {
                    retObj.push({
                        label: `${bFromQuery.key.JobTitle} (${bFromQuery.key.JobId})`,
                        value: bFromQuery.key.JobId,
                        total: bFromQuery.doc_count,
                        postData: { ...bFromQuery }
                    });
                });
            }
            break;
        default:
            if (bucketFromQuery?.buckets?.length) {
                bucketFromQuery?.buckets.map((bFromQuery: any) => {
                    retObj.push({
                        label: tLocal(
                            t,
                            `BucketKey_${bFromQuery.key}`,
                            bFromQuery.key
                        ),
                        value: bFromQuery.key,
                        total: bFromQuery.doc_count,
                        // filtered: filterItem?.doc_count || 0,
                        postData: { ...bFromQuery }
                    });
                });
            }
            break;
    }
    if (bucketMissingData?.doc_count) {
        retObj.push(addMissingData(bucketMissingData, t));
    }
    // sort by total example
    const dontOrderByTotal = bucketMetadataInfo?.dontOrderByTotal === true;
    if (!dontOrderByTotal) {
        retObj.sort((a: any, b: any) => b.total - a.total);
    }

    // sort by label example
    // retObj.sort((a: any, b: any) => a.label.localeCompare(b.label));

    return retObj;
};

/**
 * O bucket composite, no elasticsearch, não permite o filtro
 * do nome do campo personalizado no momento da geração do bucket.
 * Por este motivo, temos o filtro abaixo
 * https://github.com/elastic/elasticsearch/issues/28611
 */
const customFieldItemValidation = (
    bucketMetadataInfo: Compleo.IObject,
    itemValue: Compleo.IObject
) => {
    if (!bucketMetadataInfo?.customFieldNestedName) {
        // se não for campo personalizado, retorna true
        return true;
    }

    if (itemValue?.key?.name && bucketMetadataInfo?.fieldName) {
        return itemValue?.key?.name === bucketMetadataInfo?.fieldName;
    }

    return true;
};

const addMissingData = (
    bucketMissingData: any,
    // bucketMissingDataFiltered: any,
    t: any
) => {
    return {
        label: t("missing_empty_data"),
        value: "missing_empty_data",
        total: bucketMissingData?.doc_count
        // filtered: bucketMissingDataFiltered?.doc_count || 0
    };
};

export const isListFilterApplied = (filter?: IFilters) => {
    if (filter) {
        if (Object.keys(filter.advancedSearch || {}).length > 0) {
            return true;
        }
        if (Object.keys(filter.buckets || {}).length > 0) {
            return true;
        }
        if (Object.keys(filter.customSearch || {}).length > 0) {
            return true;
        }
        if (filter.mainSearch) {
            return true;
        }
    }
    return false;
};
