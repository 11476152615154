import {
    AppBar,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ITransformDBData, transformDBData, clearString } from "functions/util";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        fieldIcon: {
            // color: theme.palette.grey[600],
            fontSize: 16,
            margin: 0,
            padding: 0
        },
        fieldText: {
            marginLeft: theme.spacing(0.5),
            fontSize: 13,
            // color: theme.palette.grey[600],
            maxWidth: 540,
            marginRight: theme.spacing(1)
        }
    });
});

interface IItemDetail {
    transformDBDataParam?: ITransformDBData;
    IconElement?: JSX.Element;
    title: string;
    linkTo?: string;
    ElementToRender?: JSX.Element;
}
const ApplicantHistoryItemDetail = (props: IItemDetail) => {
    const classes = useStyles();
    const {
        transformDBDataParam,
        IconElement,
        title,
        linkTo,
        ElementToRender
    } = props;

    const value = transformDBDataParam
        ? transformDBData(transformDBDataParam)
        : null;

    return (
        <Grid container item alignContent="center" alignItems="center">
            {IconElement ? (
                <EmbedLink
                    Element={IconElement}
                    title={title}
                    fieldValue={value}
                    linkTo={linkTo}
                />
            ) : (
                <Typography
                    variant="body2"
                    component="span"
                    style={{ fontWeight: 600 }}
                >
                    {value ? `${title}: ` : title}
                </Typography>
            )}
            <Tooltip title={title}>
                {ElementToRender ? (
                    ElementToRender
                ) : (
                    <Typography className={classes.fieldText} noWrap>
                        {value}
                    </Typography>
                )}
            </Tooltip>
        </Grid>
    );
};

interface IEmbedLink {
    title: string;
    Element: JSX.Element;
    linkTo?: string;
    fieldValue: string;
}

const EmbedLink = (params: IEmbedLink) => {
    const { title, Element, linkTo, fieldValue } = params;
    if (linkTo) {
        switch (linkTo) {
            case "whatsApp":
                if (clearString(fieldValue)) {
                    return (
                        <Link
                            href={`https://wa.me/${clearString(fieldValue)}`}
                            target="_blank"
                            color="inherit"
                            style={{
                                margin: 0,
                                padding: 0,
                                width: 16,
                                height: 16
                            }}
                        >
                            <Tooltip title={title}>{Element}</Tooltip>
                        </Link>
                    );
                }
                break;
            default:
                return (
                    <a href={`${linkTo}`}>
                        <Tooltip title={title}>{Element}</Tooltip>
                    </a>
                );
        }
    }
    return <Tooltip title={title}>{Element}</Tooltip>;
};
export default ApplicantHistoryItemDetail;
