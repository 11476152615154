import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import { useApi } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import CustomComponents from "../components/ApplicantCustomComponents";

const ApplicantUpload = (props: any) => {
    const moduleName = "APPLICANTUPLOAD";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const postAddress = `/applicant/new`;
    const [addUserReturn, addUser] = useApi(postAddress, "post");

    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addUser,
        postReturn: addUserReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        CustomComponents
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ApplicantUpload;
