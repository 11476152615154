import React from "react";
import Page from "Pages/_Layouts/MainInternalPageJobView";
import { JobViewProvider } from "../JobView/useJobContext";
import JobMain from "../JobView/JobMain";

const JobView = (props: any) => {
    return (
        <JobViewProvider
            jobId={props.id}
            readonly={true}
            module="JOBREQUESTVIEW"
        >
            <Page>
                <JobMain pageToRender={props.pageToRender} />
            </Page>
        </JobViewProvider>
    );
};

export default JobView;
