import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

const CPFField = (props: IInputProps) => {
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;
    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        name,
        helperTextDefault
    );

    return (
        <NumberFormat
            id={name}
            label={label}
            error={error}
            helperText={message}
            format="###.###.###-##"
            required={other.required}
            fullWidth={other.fullWidth}
            customInput={TextField}
            // {...field} avoid to add this because can impact setFocus property (https://github.com/compleoteam/compleo/issues/344)
            onBlur={field.onBlur}
            value={field.value}
            onChange={undefined}
            onValueChange={(values) => {
                if (values.value === null) {
                    setFieldValue(undefined);
                } else {
                    setFieldValue(values.value);
                }
            }}
            inputRef={field.ref}
            type="text"
        />
    );
};

export default CPFField;
