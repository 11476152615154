import React, { ComponentType, Component, ReactNode } from "react";
import AppBar from "../../Components/AppBar/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { useGlobalDialogForm } from "_ReactContext/GlobalDialogContext";
import { useTranslation } from "react-i18next";
import HelperLoading from "Components/Loading/HelperLoading";
import Logo from "assets/LogoAzul";
import theme from "../../theme/Theme";
import GlobalNotifications from "Components/GlobalNotifications";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f4f6f8"
    },
    container: {
        marginTop: theme.spacing(12),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    },
    logo: {
        width: "300px"
    },
    toolbar: theme.mixins.toolbar
}));

interface IBaseProps {
    children: ReactNode;
    className?: string;
    featureName?: string;
    featureMainDescription?: string;
    additionalGrid?: ReactNode;
}

const Page = (props: IBaseProps) => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("CHANGELANGUAGE", {
        useSuspense: false
    });
    const GlobalDialog = useGlobalDialogForm();

    if (readyTranslation) {
        const language: string = i18n.languages[0];

        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />
                    <main className={classes.content}>
                        <Container className={classes.container} maxWidth="xs">
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                                justify="center"
                            >
                                <Logo className={classes.logo} />
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.root}>
                                        {props.children}
                                    </div>
                                </Grid>
                            </Grid>
                            {props.additionalGrid !== null
                                ? props.additionalGrid
                                : null}
                            <GlobalNotifications />
                            <GlobalDialog.FormDialog />
                        </Container>
                    </main>
                </div>
            </ThemeProvider>
        );
    } else {
        return <HelperLoading />;
    }
};

export default Page;
