import React from "react";
import ItemView from "./ItemView";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        chipTable: {
            margin: theme.spacing(0.5)
        }
    })
);

const FieldTags = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, fieldDef, t, language, inline } = props;

    const tagsData = transformDBData({
        value: fieldData[fieldName],
        t,
        language,
        replaceOption: "-"
    });
    const isTagArray = Array.isArray(tagsData);

    if (isTagArray) {
        return (
            <ItemView
                label={fieldDef.hideLabelCard ? undefined : t(fieldName)}
                removeColonAfterLabel={fieldDef.hideLabelCard}
                fieldDef={fieldDef}
                inline={inline}
            >
                {tagsData.map((tag: any) => (
                    <Chip
                        className={classes.chipCard}
                        color="secondary"
                        key={tag}
                        size="small"
                        label={tag}
                    />
                ))}
            </ItemView>
        );
    } else {
        return (
            <ItemView label={t(fieldName)} fieldDef={fieldDef} inline={inline}>
                {`${transformDBData({
                    value: fieldData[fieldName],
                    t,
                    language,
                    replaceOption: "-"
                })}`}
            </ItemView>
        );
    }
};

export default FieldTags;
