import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";

interface IPropsModal {
    open: boolean;
    onClose: () => void;
}
export function ModalParserOptions(props: IPropsModal) {
    const { onClose, open } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const showMessage = useShowMessage();
    const { isFeatureAuthorized } = useAuthorization();
    const parserEmailNotAvailable = isFeatureAuthorized("EmailParser_Upgrade");
    console.log("parserEmailNotAvailable", parserEmailNotAvailable);

    const { company, reloadCompanyAndPermissionData } = useAuthState();
    const module = "PARSERMODALCOMPANYSETTINGS";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const operationType: CompleoShared.Company.updateCompanyType[] = [
        "parserAutomaticProcess",
        "parserCompany",
        "parserCompanyAcceptExternalSender"
    ];
    const initialValues: Compleo.IObject = {
        companyId: company?.companyId,
        parserCompany: company?.parserCompany,
        parserCompanyAcceptExternalSender:
            company?.parserCompanyAcceptExternalSender,
        parserAutomaticProcess: company?.parserAutomaticProcess,
        operationType: operationType
    };

    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        try {
            const postAddress = `/company/updatecompanysetup`;

            const dataAPI = await apiDirectCall(postAddress, "post", values);
            debugger;
            if (dataAPI.status === 200) {
                reloadCompanyAndPermissionData();
                company.parserCompany = values.parserCompany;
                company.parserCompanyAcceptExternalSender =
                    values.parserCompanyAcceptExternalSender;
                company.parserAutomaticProcess = values.parserAutomaticProcess;

                showMessage(t("COMPLEOGENERAL_MessageUpdated"), "success");
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);
        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleSubmitRHF] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0
    });

    const handleSave = async () => {
        await handleSubmitRHF();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            {ready && <DialogTitle>{t("modalTitle")} </DialogTitle>}
            {!parserEmailNotAvailable ? (
                <DialogContent dividers>{FormReturn}</DialogContent>
            ) : (
                <DialogContent dividers>
                    <AlertUpgradePlan feature="emailParser" />
                </DialogContent>
            )}
            {ready && (
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    {!parserEmailNotAvailable && (
                        <Button
                            onClick={() => handleSave()}
                            color="primary"
                            disabled={isUpdating}
                        >
                            {t("COMPLEOGENERAL_SAVE")}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}
