import { UseFormWatch } from "react-hook-form";

export const formikReturnFN = (
    setValue: (fieldName: string, value: any) => void,
    watch: UseFormWatch<Compleo.IObject>,
    fieldsToHide: string[],
    setFieldsToHide: React.Dispatch<React.SetStateAction<string[]>>
) => {
    const enableScreeningQuestionnaire = watch("enableScreeningQuestionnaire");
    const openingReason = watch("openingReason");

    const fieldsQuestion = [
        "questionnairemodel",
        "screeningQuestionnaire",
        "questionnairetranslation",
        "questionnaireSimulation"
    ];
    if (enableScreeningQuestionnaire === false) {
        if (
            fieldsToHide.filter((i) => fieldsQuestion.includes(i)).length === 0
        ) {
            const newFieldsToHide = [...fieldsToHide].filter(
                (f) => !fieldsQuestion.includes(f)
            );
            setFieldsToHide([...newFieldsToHide, ...fieldsQuestion]);
        }
    } else {
        if (enableScreeningQuestionnaire === true) {
            if (
                fieldsToHide.filter((i) => fieldsQuestion.includes(i)).length >
                0
            ) {
                const newFieldsToHide = [...fieldsToHide].filter(
                    (f) => !fieldsQuestion.includes(f)
                );
                setFieldsToHide([...newFieldsToHide]);
            }
        }
    }
    const nameToBeReplacedFieldName = "nameToBeReplaced";
    if (openingReason?.value !== "CP2") {
        if (
            fieldsToHide.filter((i) => i === nameToBeReplacedFieldName)
                .length === 0
        ) {
            setValue(nameToBeReplacedFieldName, "");
            const newFieldsToHide = [...fieldsToHide];
            newFieldsToHide.push(nameToBeReplacedFieldName);
            setFieldsToHide([...newFieldsToHide]);
        }
    } else {
        if (
            fieldsToHide.filter((i) => i === nameToBeReplacedFieldName).length >
            0
        ) {
            const newFieldsToHide = [...fieldsToHide].filter(
                (c) => c !== nameToBeReplacedFieldName
            );

            setFieldsToHide([...newFieldsToHide]);
        }
    }
};

export const requestFormikReturnFN = (
    setValue: (fieldName: string, value: any) => void,
    watch: UseFormWatch<Compleo.IObject>,
    fieldsToHide: string[],
    setFieldsToHide: React.Dispatch<React.SetStateAction<string[]>>
) => {
    const openingReason = watch("openingReason");

    const nameToBeReplacedFieldName = "nameToBeReplaced";
    if (openingReason?.value !== "CP2") {
        if (
            fieldsToHide.filter((i) => i === nameToBeReplacedFieldName)
                .length === 0
        ) {
            setValue(nameToBeReplacedFieldName, "");
            const newFieldsToHide = [...fieldsToHide];
            newFieldsToHide.push(nameToBeReplacedFieldName);
            setFieldsToHide([...newFieldsToHide]);
        }
    } else {
        if (
            fieldsToHide.filter((i) => i === nameToBeReplacedFieldName).length >
            0
        ) {
            const newFieldsToHide = [...fieldsToHide].filter(
                (c) => c !== nameToBeReplacedFieldName
            );

            setFieldsToHide([...newFieldsToHide]);
        }
    }
};

export interface IJobInitialValuesDBData {
    languageSettings: Compleo.IObject;
    defaultApplicationFrom: Compleo.IObject;
    defaultPipeline: Compleo.IObject;
    lists: Compleo.IObject;
}
