import { formatDate } from "functions/formatValues";
import { Row, Cell, flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

type RenderType = "general" | "date" | "dateTime" | "currency" | "number";
interface DataTableColumnHeaderProps<TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    row: Row<TData>;
    cell?: Cell<TData, TValue>;
    renderType?: RenderType;
    value: TValue;
    decimalPlaces?: number;
    align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}

export function DataTableRow<TData, TValue>(
    props: DataTableColumnHeaderProps<TData, TValue>
) {
    const {
        renderType = "general",
        value,
        decimalPlaces,
        align,
        // cell,
        row
    } = props;
    const [, i18n] = useTranslation("DATATABLE", {
        useSuspense: false
    });

    switch (renderType) {
        case "currency": {
            const amount = parseFloat((value as unknown) as string);
            const formatted = new Intl.NumberFormat(i18n.language, {
                style: "currency",
                currency: "BRL"
            }).format(amount);

            return (
                <span
                    style={{
                        width: "100%",
                        textAlign: align
                    }}
                >
                    {formatted}
                </span>
            );
        }
        case "dateTime": {
            const formattedDate = formatDate(
                (value as unknown) as string,
                i18n.language,
                "dayMonthYearTime"
            );
            return (
                <span
                    style={{
                        width: "100%",
                        textAlign: align
                    }}
                >
                    {formattedDate}
                </span>
            );
        }
        case "date": {
            const formattedDate = formatDate(
                (value as unknown) as string,
                i18n.language,
                "dayMonthYear"
            );
            return (
                <span
                    style={{
                        width: "100%",
                        textAlign: align
                    }}
                >
                    {formattedDate}
                </span>
            );
        }
        case "number": {
            const amount = parseFloat((value as unknown) as string);
            const formatted = new Intl.NumberFormat(i18n.language, {
                style: "decimal",
                maximumFractionDigits: decimalPlaces || 0
            }).format(amount);

            return (
                <span
                    style={{
                        width: "100%",
                        textAlign: align
                    }}
                >
                    {formatted}
                </span>
            );
        }
        default:
            return (
                <span
                    style={{
                        width: "100%",
                        textAlign: align
                    }}
                >
                    {(value as unknown) as string}
                </span>
            );
    }
}
