import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { CardActions, ListItemIcon, MenuItem } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
    Share,
    BlockRounded,
    TrendingUp,
    Delete,
    MoreHoriz,
    Forward,
    Visibility,
    Edit
} from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import {
    IJobViewStages,
    IStagesOnlyTotal
} from "../../useJobContext/JobViewTypes";
import JobKanbanApplicantChangeStageModal from "./JobKanbanApplicantActions/JobKanbanApplicantChangeStageModal";
import useRemoveApplicantFromJob from "./JobKanbanApplicantActions/useRemoveApplicantFromJob";
import useJobChangeStageFunctionParam from "./JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import useRefreshKanban from "../useRefreshKanban";
import ApplicantEvaluationRequestModal from "Pages/Applicant/ApplicantEvaluationRequest/ApplicantEvaluationRequestModal";
import useJobContext from "../../useJobContext";
import { useAuthorization } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import JobKanbanApplicantActionsMenu from "./JobKanbanApplicantActions/JobKanbanApplicantActionsMenu";

const useStyles = () => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            actionsIcon: {
                padding: theme.spacing(0.5)
            },
            buttonRight: {
                marginLeft: "auto",
                padding: theme.spacing(0.5)
            },
            actionsIconOutsideKanban: {
                padding: theme.spacing(1)
            },
            buttonRightOutsideKanban: {
                marginLeft: "auto",
                padding: theme.spacing(1)
            }
        });
    });
};
interface IProps {
    ApplicantData: Compleo.IObject;
    stageData: IJobViewStages;
    t: any;
    allStages: IJobViewStages[];
    currentIndex: number;
    stagesOnlyTotal: IStagesOnlyTotal[];
    outsideKanban?: boolean;
    isMenu?: boolean;
    listKeyToInvalidate?: string[];
    setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
    openApplicantView: (index: number) => void;
    readonly: boolean;
}

const JobKanbanApplicantActionButtons = (props: IProps) => {
    const {
        ApplicantData,
        stageData,
        t,
        allStages,
        currentIndex,
        stagesOnlyTotal,
        outsideKanban = false,
        isMenu = false,
        listKeyToInvalidate,
        setIsUpdating,
        openApplicantView,
        readonly
    } = props;
    const [data] = useJobContext();
    const updateAllInfo = useRefreshKanban();
    const jobModalFN = useJobChangeStageFunctionParam();
    const [disqualifyModalOpen, setDisqualifyModalOpen] = React.useState(false);
    const [modalChangeStageOption, setModalChangeStageOption] = React.useState(
        false
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const [evaluationModalOpen, setEvaluationModalOpen] = React.useState(false);
    const { isFeatureAuthorized } = useAuthorization();
    // EditApplicant
    const classes = useStyles()();
    const removeApplicant = useRemoveApplicantFromJob({
        jobId: ApplicantData.jobData.JobId,
        companyId: ApplicantData.companyId,
        listKeyToInvalidate: listKeyToInvalidate,
        t: t,
        setIsUpdating: setIsUpdating,
        fnToRunAfterRemove: updateAllInfo
    });

    const qualifyRequalifyTitle =
        stageData.type !== "rejected"
            ? t("ModalDisqualify_title")
            : t("ModalRequalify_title");

    const classesActionIcon = outsideKanban
        ? classes.actionsIconOutsideKanban
        : classes.actionsIcon;
    const classesLastIcon = outsideKanban
        ? classes.buttonRightOutsideKanban
        : classes.buttonRight;

    const closeModal = () => {
        setDisqualifyModalOpen(false);
        setModalChangeStageOption(false);
    };

    const removeApplicantLocal = async () => {
        await removeApplicant.removeApplicantFromJob(ApplicantData.pk);
    };

    const openMenuMoreActions = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <ButtonActionStatus
                label={t("ViewIcon")}
                onClick={() => openApplicantView(currentIndex)}
                className={classesActionIcon}
                isMenu={isMenu}
            >
                <Visibility fontSize="small" />
            </ButtonActionStatus>
            {!readonly && (
                <ButtonActionStatus
                    label={t("ShareIcon")}
                    onClick={() => setEvaluationModalOpen(true)}
                    className={classesActionIcon}
                    isMenu={isMenu}
                >
                    <Share fontSize="small" />
                </ButtonActionStatus>
            )}
            {!readonly && isFeatureAuthorized("EditApplicant") && (
                <ButtonActionStatus
                    label={t("EditApplicantIcon")}
                    onClick={() =>
                        navigate(
                            `/applicant/edit/${
                                ApplicantData.pk.split(":")[1]
                            }?redirectAfter=/job/view/${
                                ApplicantData.jobData.JobId
                            }`
                        )
                    }
                    className={classesActionIcon}
                    isMenu={isMenu}
                >
                    <Edit fontSize="small" />
                </ButtonActionStatus>
            )}
            {!readonly && (
                <ButtonActionStatus
                    label={t("DialogRemoveFromJob_title")}
                    onClick={removeApplicantLocal}
                    className={classesActionIcon}
                    isMenu={isMenu}
                >
                    <Delete fontSize="small" />
                </ButtonActionStatus>
            )}
            {!readonly && (
                <ButtonActionStatus
                    label={t("moreKanbanApplicantActions")}
                    onClick={openMenuMoreActions}
                    className={classesActionIcon}
                    isMenu={isMenu}
                >
                    <MoreHoriz fontSize="small" />
                </ButtonActionStatus>
            )}
            {!outsideKanban && !readonly && (
                <ButtonActionStatus
                    label={qualifyRequalifyTitle}
                    onClick={() => setDisqualifyModalOpen(true)}
                    className={classesLastIcon}
                    isMenu={isMenu}
                >
                    {stageData.type !== "rejected" ? (
                        <BlockRounded fontSize="small" />
                    ) : (
                        <TrendingUp fontSize="small" />
                    )}
                </ButtonActionStatus>
            )}

            {/* {outsideKanban && (
                <>
                    <div className={classesLastIcon} />
                    <ChangeStageButtonInsideJobOutsideApplicantView
                        index={currentIndex}
                        ApplicantData={ApplicantData}
                    />
                </>
            )} */}
            {disqualifyModalOpen && (
                <JobKanbanApplicantChangeStageModal
                    onClose={() => closeModal()}
                    open={disqualifyModalOpen}
                    allStages={allStages}
                    stageData={stageData}
                    t={t}
                    ApplicantData={ApplicantData}
                    currentIndex={currentIndex}
                    stagesOnlyTotal={stagesOnlyTotal}
                    listKeyToInvalidate={listKeyToInvalidate}
                    changeStage={modalChangeStageOption}
                    outsideKanban={outsideKanban}
                    fnAfterUpdate={jobModalFN.jobChangeStageFunction}
                />
            )}
            {evaluationModalOpen && (
                <ApplicantEvaluationRequestModal
                    applicantData={ApplicantData}
                    jobData={data.Job?.data || {}}
                    open={evaluationModalOpen}
                    onClose={() => setEvaluationModalOpen(false)}
                />
            )}

            <JobKanbanApplicantActionsMenu
                anchorEl={anchorEl}
                applicantData={ApplicantData}
                setAnchorEl={setAnchorEl}
                t={t}
                stageData={stageData}
            />
        </>
    );
};

const useStylesButtonAction = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1)
        }
    })
);

interface IButtonAction {
    children: any;
    onClick: any;
    isMenu?: boolean;
    label: string;
    className?: any;
}

const ButtonActionStatus = (props: IButtonAction) => {
    const { children, isMenu = true, onClick, label, className } = props;
    const classes = useStylesButtonAction();

    if (isMenu) {
        return (
            <MenuItem onClick={onClick}>
                <ListItemIcon>{children}</ListItemIcon>
                {label}
            </MenuItem>
        );
    } else {
        return (
            <ButtonIconTooltipCompleo
                label={label}
                onClick={onClick}
                className={className}
            >
                {children}
            </ButtonIconTooltipCompleo>
        );
    }
};

export default JobKanbanApplicantActionButtons;
