import { navigate } from "@gatsbyjs/reach-router";
import { useTranslation } from "react-i18next";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

interface IUseHistory {
    askBefore?: boolean;
    customMessage?: string;
    customTitle?: string;
    historyBack?: number;
}

const useHistory = (params?: IUseHistory) => {
    const { askBefore = true, historyBack = -1, customTitle, customMessage } =
        params || {};
    const [t, i18n, readyTranslation] = useTranslation("USEHISTORYHOOK", {
        useSuspense: false
    });
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const goBack = () => {
        if (!askBefore) {
            navigate(historyBack);
        } else {
            callDialog({
                title: customTitle || t("modalTitle"),
                bodyText: customMessage || t("modalMessage"),
                agreeButtonText: t("yes"),
                disagreeButtonText: t("no"),
                agreeFunction: async () => {
                    navigate(historyBack);
                    return false;
                },
                disagreeFunction: () => {
                    return false;
                }
            });
        }
    };
    return { fnGoBack: goBack, ready: readyTranslation };
};

export default useHistory;
