import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import Page from "Pages/_Layouts/DefaultInternal";
import { Icon } from "@material-ui/core";
import {
    actionsTableFunction,
    useCompleoSimpleList
} from "customHooks/useCompleoSimpleList";
import useDomainActionsTable from "./useDomainActionsTable";

interface IProps {
    uniqueLink: string;
    handleAddButtonVisibilityCheck: () => void;
}

const DomainSettingsList = ({
    uniqueLink,
    handleAddButtonVisibilityCheck
}: IProps) => {
    const moduleName = "CAREERSDOMAINLIST";

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    //const emailTemplateData = useEmailTemplate();
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/careerssettings/domainlist";
    const { company } = useAuthState();

    const actions: actionsTableFunction = useDomainActionsTable(
        company.companyId,
        t,
        handleAddButtonVisibilityCheck
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId,
            uniqueLink
            // totalFakeDataMultiply: 650
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    const ready = readyTranslation && finished;
    if (ready) {
        compRetorno = renderTable();
    }

    return compRetorno;
};

export default DomainSettingsList;
