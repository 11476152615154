import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Translate } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@material-ui/core";

const MenuLanguage = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const { t, i18n } = useTranslation("LANGUAGEMENUUNAUTHAREA", {
        useSuspense: false
    });
    const currentLanguage = i18n.languages[0];
    const options = [{ value: "pt-BR" }, { value: "en-US" }];
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = async (event: any, language: string) => {
        i18n.changeLanguage(language);
        handleClose();
    };

    return (
        <>
            <Tooltip title={t("chooseAnLanguage") as string}>
                <IconButton onClick={openMenu}>
                    <Translate />
                </IconButton>
            </Tooltip>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={(event: any) =>
                                clickMenuItem(event, option.value)
                            }
                            disabled={option.value === currentLanguage}
                        >
                            {t(option.value)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default MenuLanguage;
