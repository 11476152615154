import { TFunction } from "i18next";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

interface ICopyToClipboardChipProps {
    text: string;
    t: TFunction;
}

export function useCopyToClipboard(props: ICopyToClipboardChipProps) {
    const { text, t } = props;
    const showMessage = useShowMessage();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        showMessage(t("COMPLEOGENERAL_copiedToClipboard"), "success", 2000);
    };

    return copyToClipboard;
}
