import { useAuthState } from "_ReactContext/AuthContext";
// we are using the package because old safari versions do not support the native BroadcastChannel
import { BroadcastChannel } from "broadcast-channel";
import { useEffect, useState } from "react";

interface IUseIdleTimer {
    idleTimeoutMinutes: number;
}
export const LOCALSTORAGEINACTIVITYNAME = "lastActive";
export const LOGOUTCONTROLCHANNELNAME = "logoutcontrol";

const useIdleTimer = (params: IUseIdleTimer) => {
    const { idleTimeoutMinutes } = params;
    const { logout } = useAuthState();

    const timeout = idleTimeoutMinutes * 60000; // Converte minutos para milissegundos
    const [isActive, setIsActive] = useState(true);
    const channel = new BroadcastChannel(LOGOUTCONTROLCHANNELNAME);
    // A ideia é antes de adicionar qualquer movimento, verificar se é a primeira vez que o usuário está logado
    // se sim, vamos verificar se ele está inativo, se sim, vamos deslogar
    const [firstCheckRun, setFirstCheckRun] = useState(false);

    const saveLastActive = () => {
        const now = Date.now();
        localStorage.setItem(LOCALSTORAGEINACTIVITYNAME, now.toString());
    };

    const checkIdle = () => {
        const now = Date.now();
        const savedLastActive = localStorage.getItem(
            LOCALSTORAGEINACTIVITYNAME
        );
        const lastActiveTime = savedLastActive
            ? parseInt(savedLastActive, 10)
            : now;
        if (now - lastActiveTime > timeout) {
            channel.postMessage("logout");
            logout(true);
        }
        if (!firstCheckRun) {
            setFirstCheckRun(true);
        }
    };

    useEffect(() => {
        channel.onmessage = (ev) => {
            if (ev === "logout") {
                logout();
            }
        };
        return () => {
            channel.close();
        };
    }, [channel]);

    useEffect(() => {
        const handleActivity = () => {
            if (firstCheckRun) {
                saveLastActive();
            }
        };
        const handleVisibilityChange = () => {
            checkIdle();
            setIsActive(!document.hidden);
        };

        const events = [
            "mousedown",
            "mousemove",
            "wheel",
            "keydown",
            "touchstart",
            "scroll"
        ];

        events.forEach((event) =>
            window.addEventListener(event, handleActivity, { passive: true })
        );
        document.addEventListener("visibilitychange", handleVisibilityChange);

        const interval = setInterval(() => {
            checkIdle();
        }, 1000);

        return () => {
            clearInterval(interval);
            events.forEach((event) =>
                window.removeEventListener(event, handleActivity)
            );
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, [timeout, logout, isActive, firstCheckRun]);
};

export default useIdleTimer;
