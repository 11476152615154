import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import CustomComponents from "./components/UserCustomComponents";
// import { useSelector } from "react-redux";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi } from "customHooks/useApi";
import { useNextPageFunctionsNewUser } from "./components/useNextPageFunctionsNewUser";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import useCustomLists from "./components/useCustomLists";
import { usePlanLimitsPage } from "customHooks/usePlanLimits/usePlanLimits";

export const getFieldsToHide = (allBranchAccess: boolean) => {
    if (allBranchAccess) {
        return ["accessVacanciesWithoutBranch", "BranchsAccessList"];
    } else {
        return [];
    }
};

export const allBranchControlFields = [
    "accessVacanciesWithoutBranch",
    "BranchsAccessList",
    "allBranchAccess"
];

const UserNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/user/list");
    const classes = useFormStyles();
    const { company } = useAuthState();
    const [t, i18n, readyTranslation] = useTranslation("usernew", {
        useSuspense: false
    });
    const nextPageFunctions = useNextPageFunctionsNewUser(company.companyId, t);
    const [listDefinition, readyLists] = useCustomLists(t, readyTranslation);
    const { limitReached } = usePlanLimitsPage(t, "/user/list", "addUser");

    let defaultFieldsToHide: string[] = [];
    if (!company.branchAccessControl) {
        defaultFieldsToHide = allBranchControlFields;
    }
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );
    const formikReturn = React.useCallback(
        (value: any) => {
            if (company.branchAccessControl) {
                const values = (value || {}).values || {};
                if (values.allBranchAccess !== undefined) {
                    const allBranchAccess = values.allBranchAccess;
                    const fieldsToHideFN = getFieldsToHide(allBranchAccess);
                    if (!_.isEqual(fieldsToHide, fieldsToHideFN)) {
                        setFieldsToHide(fieldsToHideFN);
                    }
                }
            }
        },
        [fieldsToHide]
    );

    const postAddress = `/user/new`;
    const [addUserReturn, addUser] = useApi(postAddress, "post");

    const [metadata] = useGetMetadata("USERNEW");

    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    // valuesFromSource.companyId = company.companyId;
    valuesFromSource.timeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo";

    valuesFromSource.enabled = true;
    if (!company.branchAccessControl) {
        valuesFromSource.allBranchAccess = true;
        valuesFromSource.accessVacanciesWithoutBranch = true;
    }

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        !limitReached ? addUser : undefined,
        addUserReturn,
        "/user/list",
        FormClassic,
        metadata,
        valuesFromSource,
        CustomComponents,
        null,
        nextPageFunctions,
        cancelFunction,
        undefined,
        listDefinition,
        fieldsToHide,
        formikReturn
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default UserNew;
