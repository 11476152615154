import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList from "../..";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Typography } from "@material-ui/core";
import { getCustomSearchOrdered } from "../ListUtil";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import LoadFilterModalList from "./LoadFilterModalList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const LoadFilterModal = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const { company } = useAuthState();

    const [listData, listDispatch] = useList();
    const { t } = listData.tempInfo;
    const { setLocalInfo } = listDispatch;
    const { localInfo } = listData;
    const [listFilters] = useApiCache(
        "/sessions/listfilters",
        "post",
        {
            companyId: company.companyId,
            filterType: listData.tempInfo.moduleName
        },
        false,
        1000 * 60 * 10,
        false,
        "listFilters"
    );
    const ready = listFilters.status === "success";

    const handleOk = () => {
        onClose();
    };

    const handleSave = () => {
        onClose();
    };

    React.useEffect(() => {
        if (open) {
            setLocalInfo({
                ...localInfo,
                loadedMainSearchFromFilterSaved: undefined
            });
        }
    }, [open]);

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={t("ELASTICLISTSETTINGS:LoadFilters_Title")}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t("ELASTICLISTSETTINGS:LoadFilters_Title")}
            </DialogTitle>
            <DialogContent dividers>
                {ready && (
                    <LoadFilterModalList
                        filterData={listFilters.response?.data || []}
                        handleClose={handleOk}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoadFilterModal;
