import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LocationInfoModal from "./GeoDistante/LocationInfoModal";
import useList from "../index";
import SettingsModalFilters from "./Settings/SettingsModalFilters";
import SettingsModalCardFields from "./Settings/SettingsModalCardTableFields";
import SaveFilterModal from "./SaveFilter/SaveFilterModal";
import LoadFilterModal from "./SaveFilter/LoadFilterModal";
import useDefaultFilter from "./Settings/useDefaultFilter";
import BulkActionsModal from "./BulkActions/BulkActionsModal";
import { useAuthorization } from "_ReactContext/AuthContext";
import { BulkActionsType } from "./BulkActions/helperBulkActions";
import useFeatureUpgradePlan, {
    ModalUpgradePlan
} from "customHooks/useFeatureUpgradePlan/useFeatureUpgradePlan";

type ActionsMenuList =
    | "setlocation"
    | "export"
    | "savefilter"
    | "filterSettings"
    | "cardFieldsSettings"
    | "tableFieldsSettings"
    | "loadFilter"
    | "markAsDefault"
    | "loadDefaultFilter"
    | "bulkActions"
    | "bulkActionsUpgradePlan";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
}

const ActionsMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t } = props;
    const [, setCurrentFilterAsDefault, loadDefaultFilter] = useDefaultFilter();
    const [listData, listDispatch] = useList();
    const { localInfo } = listData;
    const [filterSettingsOpen, setFiltersSettingsOpen] = React.useState(false);
    const [fieldsSettingsOpen, setFieldsSettingsOpen] = React.useState(false);
    const [typeFieldsSettings, setTypeFieldsSettings] = React.useState<
        "card" | "table"
    >("card");
    const [saveFilterOpen, setSaveFilterOpen] = React.useState(false);
    const [loadFilterOpen, setLoadFilterOpen] = React.useState(false);
    const [bulkActionsOpen, setBulkActionsOpen] = React.useState(false);
    const bulkActionsRemoveFromList: BulkActionsType[] = [];
    const featuresUpgradePlan = useFeatureUpgradePlan("bulkActionsATS");

    const { setLocalInfo } = listDispatch;

    const mainMenuItemsToRemove: ActionsMenuList[] =
        (listData.definitionInfo.elasticDefData?.data?.otherSettings || {})
            .mainMenuItemsToRemove || [];

    const { isFeatureAuthorized } = useAuthorization();
    const canEdit = isFeatureAuthorized("EditApplicant");
    if (!canEdit) {
        bulkActionsRemoveFromList.push("applicant_Delete");
        bulkActionsRemoveFromList.push("applicant_addTag");
        bulkActionsRemoveFromList.push("applicant_addToJob");
        bulkActionsRemoveFromList.push("applicant_sendEmail");
    }
    const canBulk = isFeatureAuthorized("BulkActions");
    const allMenuOptions: ActionsMenuList[] = [
        // "export",
        "savefilter",
        "setlocation",
        "filterSettings",
        "cardFieldsSettings",
        "tableFieldsSettings",
        "loadFilter",
        "markAsDefault",
        "loadDefaultFilter",
        "bulkActions",
        "bulkActionsUpgradePlan"
    ];
    if (!canBulk) {
        mainMenuItemsToRemove.push("bulkActions");
        if (!isFeatureAuthorized("BulkActions_Upgrade")) {
            mainMenuItemsToRemove.push("bulkActionsUpgradePlan");
        }
    } else {
        if (!isFeatureAuthorized("BulkActions_Upgrade")) {
            mainMenuItemsToRemove.push("bulkActionsUpgradePlan");
        }
    }
    const menuOptions = allMenuOptions.filter(
        (item: ActionsMenuList) => !mainMenuItemsToRemove.includes(item)
    );

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuLocationOpen = (open: boolean) => {
        setLocalInfo({
            ...localInfo,
            modalLocationInfo: {
                ...localInfo.modalLocationInfo,
                open: open
            }
        });
    };

    const clickMenuItem = (action: ActionsMenuList) => {
        switch (action) {
            case "setlocation":
                handleMenuLocationOpen(true);
                break;
            case "filterSettings":
                setFiltersSettingsOpen(true);
                break;
            case "cardFieldsSettings":
                setTypeFieldsSettings("card");
                setFieldsSettingsOpen(true);
                break;
            case "tableFieldsSettings":
                setTypeFieldsSettings("table");
                setFieldsSettingsOpen(true);
                break;
            case "savefilter":
                setSaveFilterOpen(true);
                break;
            case "loadFilter":
                setLoadFilterOpen(true);
                break;
            case "markAsDefault":
                setCurrentFilterAsDefault();
                break;
            case "loadDefaultFilter":
                loadDefaultFilter();
                break;
            case "bulkActions":
                setBulkActionsOpen(true);
                break;
            case "bulkActionsUpgradePlan":
                featuresUpgradePlan.setOpen(true);
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            <LocationInfoModal
                onClose={() => handleMenuLocationOpen(false)}
                open={localInfo.modalLocationInfo.open}
            />
            <SettingsModalFilters
                onClose={() => setFiltersSettingsOpen(false)}
                open={filterSettingsOpen}
            />
            <SettingsModalCardFields
                onClose={() => setFieldsSettingsOpen(false)}
                open={fieldsSettingsOpen}
                type={typeFieldsSettings}
            />
            <SaveFilterModal
                onClose={() => setSaveFilterOpen(false)}
                open={saveFilterOpen}
            />
            <LoadFilterModal
                onClose={() => setLoadFilterOpen(false)}
                open={loadFilterOpen}
            />
            <BulkActionsModal
                onClose={() => setBulkActionsOpen(false)}
                open={bulkActionsOpen}
                BulkActionsRemoveFromList={bulkActionsRemoveFromList}
            />
            <ModalUpgradePlan {...featuresUpgradePlan} />
        </div>
    );
};

const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ELASTICLISTSETTINGS:MainMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default ActionsMenu;
