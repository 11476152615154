import Page from "Pages/_Layouts/DefaultInternal";
import PageInsideDashboard from "Pages/_Layouts/InternalFullPage";

interface ICalendarWrapper {
    children: React.ReactNode;
    t: any;
    insideDashboard: boolean;
}
const CalendarWrapper = (props: ICalendarWrapper) => {
    const { children, t, insideDashboard } = props;
    if (insideDashboard) {
        return (
            <PageInsideDashboard maxWidth="lg">{children}</PageInsideDashboard>
        );
    } else {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {children}
            </Page>
        );
    }
};

export default CalendarWrapper;
