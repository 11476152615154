import React from "react";
import ItemCard from "./ItemCard";
import Typography from "@material-ui/core/Typography";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import Link from "@material-ui/core/Link";

const FieldApplicantTitle = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType, url } = props;
    const [listData, listDispatch] = useList();
    const { t, language } = listData.tempInfo;
    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );
    const openModalApplicant = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalApplicant: {
                open: true,
                currentIndex: index,
                currentPage:
                    listData.filtersInfo?.filters?.pagination?.currentPage
            }
        });
    };

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    dontApplyTypography={true}
                    removeColonAfterLabel={true}
                    smSize={12}
                >
                    <Link component="button" onClick={openModalApplicant}>
                        <Typography gutterBottom variant="h6">
                            {fieldData[fieldName]}
                        </Typography>
                    </Link>
                    {/* <ApplicantViewModalContainer
                        ApplicantId={applicantId}
                        onClose={() => setOpenModal(false)}
                        open={openModal}
                    /> */}
                </ItemCard>
            );
        case "table":
            return (
                <>
                    <Link
                        variant="inherit"
                        onClick={openModalApplicant}
                        style={{ cursor: "pointer" }}
                    >
                        {transformDBData({
                            value: fieldData[fieldName],
                            t,
                            language,
                            replaceOption: "-"
                        })}
                    </Link>
                    {/* <ApplicantViewModalContainer
                        ApplicantId={applicantId}
                        onClose={() => setOpenModal(false)}
                        open={openModal}
                    /> */}
                </>
            );
    }
};

export default FieldApplicantTitle;
