import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";

const useCustomLists: (
    t: any,
    readyTranslation: boolean
) => [Compleo.ListDefinitionType[], boolean] = (
    t: any,
    readyTranslation: boolean
) => {
    const { company } = useAuthState();
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition, setListDefinition] = React.useState<
        Compleo.ListDefinitionType[]
    >([]);

    const [customCVListResult, getCustomCVList] = useApiCache(
        "/customcv/getcustomcv",
        "post",
        {
            companyId
        },
        false,
        60 * 1000 * 10
    );

    if (
        customCVListResult.status === "success" &&
        !listFilled(listDefinition, "cvToShare") &&
        readyTranslation
    ) {
        const cvList = customCVListResult?.response?.data?.map(
            (cv: Compleo.IObject) => ({
                label: cv.name,
                value: cv.skGS1pk.split(":")[1]
            })
        );

        cvList?.sort((a: Compleo.IObject, b: Compleo.IObject) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

        cvList?.unshift({
            label: t("applicantOriginalCVType"),
            value: "cv"
        });

        listDefinition.push({
            fieldName: "cvToShare",
            data: cvList,
            valueName: "value",
            labelName: "label"
        });
    }

    const [recruiters, callRecruiters] = useApiCache(
        "/user/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );
    if (
        recruiters.status === "success" &&
        !listFilled(listDefinition, "recipients") &&
        !listFilled(listDefinition, "evaluators")
    ) {
        listDefinition.push({
            fieldName: "recipients",
            data: recruiters.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
        listDefinition.push({
            fieldName: "evaluators",
            data: recruiters.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }

    if (!ready) {
        const readyModules = verifyReady(
            ["recipients", "evaluators", "cvToShare"],
            listDefinition
        );
        if (readyModules) {
            setReady(true);
        }
    }
    return [listDefinition, ready];
};

const verifyReady = (
    fieldList: string[],
    state: Compleo.CustomLists.ListDefinitionType[]
) => {
    const currentFieldsLoaded = state.map((item) => item.fieldName);
    for (const field of fieldList) {
        if (!currentFieldsLoaded.includes(field)) {
            return false;
        }
    }
    return true;
};

const listFilled = (
    listDefinition: Compleo.ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

export default useCustomLists;
