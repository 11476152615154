import { ApiStateType } from "customHooks/useApi";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { getFilteredMetadataAndRenamedMetadataComplexField } from "customHooks/useCompleoForm/Inputs/ComplexField";
import languagesAvailable from "functions/getAllLanguagesSuported";
import _ from "lodash";

type SpecialMappingType = {
    valuesFieldName: string;
    rootFieldName: string[];
};

type PKType = { pk: any; skGS1pk: any; GS1sk: any };

export const EmailDataDefaultValues = {
    default: true,
    language: "pt-BR",
    id: "",
    _tempEmailData: {
        body: "",
        subject: "",
        attachments: [],
        attachments_filesDefinition: {
            values: [],
            multiple: true
        },
        arrayPlaceHolderBody: [],
        arrayPlaceHolderSubject: []
    }
};

export const ScheduleDataDefaultValues = {
    default: true,
    language: "pt-BR",
    arrayPlaceHolderBody: [],
    arrayPlaceHolderSubject: [],
    location: "",
    subject: "",
    body: ""
};

const useValuesFromSource = (
    metadata: ApiStateType,
    setCompanyId: boolean = false,
    defaultValues: Compleo.IObject = {},
    getDataReturn: ApiStateType | null = null,
    rootFieldName: string[] = [],
    specialMapping?: SpecialMappingType[]
) => {
    const { company, timeZone } = useAuthState();
    let fields: Compleo.IObject[] = [];
    let dataReturn: Compleo.IObject | null = null;

    if (getDataReturn !== null && getDataReturn.status === "success") {
        dataReturn = getDataReturn.response;
    }
    if (metadata.status === "success") {
        fields = metadata.response.data.metadado;
    }
    const returnData = processValuesFromSource(
        fields,
        company,
        timeZone,
        setCompanyId,
        defaultValues,
        dataReturn,
        rootFieldName,
        specialMapping
    );

    return returnData;
};

export const useValuesFromSourceDirect = (
    fields: Compleo.IObject[],
    setCompanyId: boolean = false,
    defaultValues: Compleo.IObject = {},
    dataReturn: Compleo.IObject | null = null,
    rootFieldName: string[] = [],
    specialMapping?: SpecialMappingType[],
    ignoreComplexField: boolean = true
) => {
    const { company, timeZone } = useAuthState();
    return processValuesFromSource(
        fields,
        company,
        timeZone,
        setCompanyId,
        defaultValues,
        dataReturn,
        rootFieldName,
        specialMapping,
        ignoreComplexField
    );
};

const processValuesFromSource = (
    fields: Compleo.IObject[],
    company: any,
    timeZone: any,
    setCompanyId: boolean = false,
    defaultValues: Compleo.IObject = {},
    dataReturn: Compleo.IObject | null = null,
    rootFieldName: string[] = [],
    specialMapping?: SpecialMappingType[],
    ignoreComplexField: boolean = true
) => {
    let valuesFromSource: Compleo.IObject = {};
    let pkSKValues: PKType = { pk: null, skGS1pk: null, GS1sk: null };

    let completeMetadata = [...fields];
    fields = fields.filter(
        (o: any) =>
            !(o.elementType || "").includes("backend_") &&
            (!o.complexFieldDetail || !ignoreComplexField)
    );

    if (dataReturn !== null) {
        const dbReturn = updateFromDB(
            valuesFromSource,
            dataReturn,
            fields,
            rootFieldName,
            specialMapping,
            company,
            completeMetadata
        );
        valuesFromSource = dbReturn.valuesFromSource;
        pkSKValues = dbReturn.pkSKValues;
    } else {
        for (const field of fields) {
            _.set(
                valuesFromSource,
                field.fieldName,
                getDefaultValue(field, company)
            );
            // valuesFromSource[field.fieldName] = getDefaultValue(field, company);

            if (
                field.elementType === "file" ||
                field.elementType === "multipleFiles" ||
                field.elementType === "image"
            ) {
                _.set(valuesFromSource, `${field.fieldName}_filesDefinition`, {
                    values: [],
                    multiple: field.elementType === "multipleFiles"
                });
                // valuesFromSource[`${field.fieldName}_filesDefinition`] = {
                //     values: [],
                //     multiple: field.elementType === "multipleFiles"
                // };
            }
        }
    }

    if (setCompanyId) {
        valuesFromSource.companyId = company.companyId;
    }
    valuesFromSource = { ...valuesFromSource, ...defaultValues };
    const returnValues: [Compleo.IObject, PKType] = [
        valuesFromSource,
        pkSKValues
    ];
    return returnValues;
};

const updateFromDB = (
    valuesFromSource: Compleo.IObject,
    dataReturn: Compleo.IObject,
    fields: any,
    rootFieldName: string[] = [],
    specialMapping?: SpecialMappingType[],
    company?: any,
    completeMetadata?: Compleo.IObject[]
) => {
    let pkSKValues: PKType = { pk: null, skGS1pk: null, GS1sk: null };

    // console.log("fields", fields);
    fields.map((field: any) => {
        const rootField = getRootObjWithField(dataReturn, field, rootFieldName);

        if (rootField !== undefined) {
            _.set(valuesFromSource, field.fieldName, rootField);
            // valuesFromSource[field.fieldName] = rootField;
        } else {
            _.set(
                valuesFromSource,
                field.fieldName,
                getDefaultValue(field, company)
            );
            // valuesFromSource[field.fieldName] = getDefaultValue(field, company);
        }

        if (specialMapping !== undefined) {
            const specialMappingMatch = specialMapping.filter(
                (f: SpecialMappingType) => f.valuesFieldName === field.fieldName
            );
            if (specialMappingMatch.length > 0) {
                const specialRootField = getRootObjWithField(
                    dataReturn,
                    field,
                    specialMappingMatch[0].rootFieldName
                );
                if (specialRootField !== undefined) {
                    _.set(valuesFromSource, field.fieldName, specialRootField);
                    // valuesFromSource[field.fieldName] = specialRootField;
                } else {
                    _.set(
                        valuesFromSource,
                        field.fieldName,
                        getDefaultValue(field, company)
                    );
                    // valuesFromSource[field.fieldName] = getDefaultValue(
                    //     field,
                    //     company
                    // );
                }
            }
        }

        if (
            field.elementType === "file" ||
            field.elementType === "multipleFiles" ||
            field.elementType === "image"
        ) {
            _.set(valuesFromSource, `${field.fieldName}_filesDefinition`, {
                values: _.get(valuesFromSource, field.fieldName),
                multiple: field.elementType === "multipleFiles"
            });

            // valuesFromSource[`${field.fieldName}_filesDefinition`] = {
            //     values: _.get(valuesFromSource, field.fieldName),
            //     multiple: field.elementType === "multipleFiles"
            // };
        }
        if (
            field.elementType === "complexField" &&
            completeMetadata !== undefined &&
            Array.isArray(_.get(valuesFromSource, field.fieldName))
        ) {
            const complexFieldMetadata = getFilteredMetadataAndRenamedMetadataComplexField(
                field.fieldName,
                completeMetadata
            ).filteredMetadata;
            const newObj: Compleo.IObject[] = [];

            for (const arrayItem of _.get(valuesFromSource, field.fieldName)) {
                const keys = Object.keys(arrayItem);
                const objItem: Compleo.IObject = { ...arrayItem };
                for (const key of keys) {
                    const elementMetadata = complexFieldMetadata.filter(
                        (cf: any) =>
                            cf.fieldName.includes(
                                `COMPLEXFIELD:${field.fieldName}_${key}`
                            )
                    );
                    const insideField = elementMetadata[0] || {};
                    if (
                        insideField.elementType === "file" ||
                        insideField.elementType === "multipleFiles" ||
                        insideField.elementType === "image"
                    ) {
                        _.set(objItem, `${key}_filesDefinition`, {
                            values: objItem[key],
                            multiple:
                                insideField.elementType === "multipleFiles"
                        });
                        // objItem[`${key}_filesDefinition`] = {
                        //     values: objItem[key],
                        //     multiple:
                        //         insideField.elementType === "multipleFiles"
                        // };
                    }
                }
                newObj.push(objItem);
            }
            _.set(valuesFromSource, field.fieldName, newObj);
            // valuesFromSource[field.fieldName] = newObj;
        }

        if (field.elementType === "emailData") {
            const emailDataValues =
                _.get(valuesFromSource, field.fieldName) || [];
            for (const emailItem of emailDataValues) {
                emailItem._tempEmailData = _.cloneDeep(
                    EmailDataDefaultValues._tempEmailData
                );
            }
        }
    });
    const rootForPK = getRootObj(dataReturn, rootFieldName);
    pkSKValues.pk = rootForPK.pk;
    pkSKValues.skGS1pk = rootForPK.skGS1pk;
    pkSKValues.GS1sk = rootForPK.GS1sk;

    return { valuesFromSource, pkSKValues };
};

const getRootObjWithField = (
    dataReturn: Compleo.IObject,
    field: any,
    rootFieldName: string[]
) => {
    let objReturn: Compleo.IObject = getRootObj(dataReturn, rootFieldName);
    let returnData: any = _.get(objReturn, field.fieldName);
    // if (field.elementType === "file" || field.elementType === "image") {
    //     // se for tipo arquivo, converter objeto para array para funcionamento na página
    //     const data: any[] = [];
    //     if (objReturn[field.fieldName] !== undefined) {
    //         data.push(objReturn[field.fieldName]);
    //     }
    //     returnData = data;
    //     // console.log("returnData", returnData);
    // }
    return returnData;
};

const getRootObj = (dataReturn: Compleo.IObject, rootFieldName: string[]) => {
    if (rootFieldName.length === 0) {
        return dataReturn;
    } else {
        let objReturn: Compleo.IObject = dataReturn;

        for (const fieldName of rootFieldName) {
            objReturn = _.get(objReturn, fieldName);
        }

        if (Array.isArray(objReturn) && objReturn.length > 0) {
            return objReturn[0];
        } else {
            return objReturn;
        }
    }
};

const getDefaultValue = (field: any, company: any) => {
    let defaultValue: any = "";
    switch (field.elementType) {
        case "select":
        case "radiobuttongroup":
            defaultValue = null;
            break;
        case "custom":
            switch (field.customFieldType) {
                case "array":
                    defaultValue = [];
                    break;
                case "object":
                    defaultValue = {};
                    break;
            }
            break;
        case "file":
        case "image":
            defaultValue = null;
            break;
        case "tagsCreatableArray":
        case "tags":
        case "multipleFiles":
            defaultValue = [];
            break;
        case "dateOfBirth":
        case "date":
        case "datetime":
            defaultValue = null;
            break;
        case "dateTimeRange":
            defaultValue = {
                startDateTime: null,
                endDateTime: null
            };
            break;
        case "integer":
        case "decimal":
            defaultValue = undefined;
            break;
        case "currency":
            defaultValue = {
                currency: (company || {}).defaultCurrency || "BRL",
                value: undefined
            };
            break;

        case "uniqueCheckbox":
        case "checkbox":
            defaultValue = false;
            break;
        case "address":
            defaultValue = {
                postalCode: "",
                addressline1: "",
                addressline2: "",
                country: { label: "Brasil", value: "31" },
                number: "",
                neighborhood: "",
                city: null,
                provinceOrState: null
            };
            break;
        case "numberrange":
            defaultValue = {
                minValue: undefined,
                maxValue: undefined,
                type: { value: null },
                currency: (company || {}).defaultCurrency || "BRL"
            };
            break;
        case "complexField":
            const isArray =
                (field.complexField || {}).isArray === undefined
                    ? false
                    : (field.complexField || {}).isArray;
            const objDefault: any = {};
            if (isArray) {
                defaultValue = [];
            } else {
                defaultValue = objDefault;
            }
            break;
        case "url":
            defaultValue = null;
            break;
        case "delayAction":
            defaultValue = {
                delayDetail: null,
                time: null,
                timeZone: ""
            };
            break;
        case "emailData":
            defaultValue = [_.cloneDeep(EmailDataDefaultValues)];
            break;
        case "scheduleData":
            defaultValue = [_.cloneDeep(ScheduleDataDefaultValues)];
            break;
        case "rating":
            defaultValue = 0;
            break;
        case "hidden":
            switch (field.fieldType) {
                case "array":
                    defaultValue = [];
                    break;
                case "object":
                    defaultValue = {};
                    break;
                case "date":
                    defaultValue = null;
                    break;
            }
            break;
        case "jobList":
            defaultValue = "";
            break;
        default:
            break;
    }
    return defaultValue;
};

export default useValuesFromSource;
