import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Edit, Visibility } from "@material-ui/icons";
import { navigate } from "@gatsbyjs/reach-router";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRight: {
            marginLeft: "auto"
        }
    })
);

interface IProps {
    fieldData: Compleo.IObject;
    hideViewIcon?: boolean;
}

const OtherActions = (props: IProps) => {
    const { fieldData, hideViewIcon = false } = props;
    const classes = useStyles();

    const editJob = () => {
        navigate(`/job/editrequest/${fieldData.pk.split(":")[1]}`);
    };

    const viewJob = () => {
        navigate(`/job/viewrequest/${fieldData.pk.split(":")[1]}`);
    };
    const status: CompleoShared.Common.JobStatus = fieldData.status;
    const showEditButton =
        status === "requested" || status === "awaitingCorrectionRequest";

    return (
        <>
            {!hideViewIcon && (
                <ButtonIconTooltipCompleo
                    className={classes.buttonRight}
                    label="View"
                    onClick={viewJob}
                >
                    <Visibility />
                </ButtonIconTooltipCompleo>
            )}
            {showEditButton && (
                <ButtonIconTooltipCompleo label="Edit" onClick={editJob}>
                    <Edit />
                </ButtonIconTooltipCompleo>
            )}
        </>
    );
};

export default OtherActions;
