import React from "react";
import { CompleoListPage } from "customHooks/useList";
import ModalApplicantContainer from "customHooks/useList/ModalApplicantContainer";

const ApplicantList = (props: any) => {
    const module = "APPLICANTLIST";

    return (
        <CompleoListPage moduleName={module} listApiAddress="/applicant/list">
            <ModalApplicantContainer module={"APPLICANTVIEW"} />
        </CompleoListPage>
    );
};

export default ApplicantList;
