import React from "react";
import FormMain from "./FormMain";
import { PropsParent, typeForm } from "./FormMain";
import useFormModalStyles from "customHooks/useCompleoForm/components/useFormModalStyles";

const FormClassic = (props: PropsParent) => {
    const classes = useFormModalStyles();
    const formType: typeForm = "INTERNAL";

    return (
        <FormMain parentProps={props} classes={classes} formType={formType} />
    );
};

export default FormClassic;
