import React from "react";
import useJobContext from "Pages/Job/JobView/useJobContext";
import ApplicantViewHeaderButtonRemoveFromJob from "./ApplicantViewHeaderButtonRemoveFromJob";
import { ApplicantViewJobDataInsideJob } from "./useApplicantViewContext/ApplicantViewTypes";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Directions } from "@material-ui/icons";
import { IJobViewStages } from "Pages/Job/JobView/useJobContext/JobViewTypes";
import JobKanbanApplicantChangeStageModal from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/JobKanbanApplicantChangeStageModal";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobChangeStageFunctionParam from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import useApplicantViewContext from "./useApplicantViewContext";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getTotalStage } from "Pages/Job/JobView/JobKanban/helper";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        iconActionButton: {
            color: "inherit"
        }
    });
});

interface IProps {
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    listKeyToInvalidate?: string[];
    currentIndex: number;
    t: any;
    stageId: string;
}

const MoveToNextStageInsideJobContainer = (props: IProps) => {
    const classes = useStyles();
    const [data] = useJobContext();
    const [applicantContext] = useApplicantViewContext();
    const queryClient = useQueryClient();

    const {
        jobDataInsideJob,
        currentIndex,
        listKeyToInvalidate = [],
        t,
        stageId
    } = props;
    const jobDataContext = jobDataInsideJob?.jobDataContext || {};
    const jobModalFN = useJobChangeStageFunctionParam();
    const showMessageFullControl = useShowMessageFullControl();
    const { company } = useAuthState();

    // const stageData = data.LocalStateData.allStages.filter(
    //     (item) => item.id === stageId
    // )[0];

    const stageData = getNextStage(stageId, data.LocalStateData.allStages);
    const JobData = (applicantContext.applicantData?.Jobs || []).filter(
        (item: Compleo.IObject) => item.JobId === jobDataInsideJob?.jobId
    )[0];
    const applicantData: Compleo.IObject = {
        ...applicantContext.applicantData,
        jobData: JobData
    };

    const tJobView = data.t;

    const moveToNextStage = async () => {
        if (stageData) {
            showMessageFullControl.openMessage({
                message: t("COMPLEOGENERAL_MessageUpdating"),
                variant: "info",
                showLinearProgress: true
            });
            const applicantId = applicantData.pk.split(":")[1];
            const addToEndOfList = true;
            // setSelectedIndex(index);
            const dataPost: Compleo.IObject = {
                companyId: company.companyId,
                jobId: jobDataInsideJob?.jobId,
                applicantId: applicantId,
                stageId: stageData?.nextStage?.id,
                addToEndOfList: addToEndOfList,
                oldStageId: stageId,
                priorityObj: { orderData: [] },
                newIndex: 0
            };

            const postAddress = `/job/editapplicantjobstage`;
            const dataAPI = await apiDirectCall(postAddress, "post", dataPost);

            if (dataAPI.status === 200) {
                // if (fnAfterUpdate) {
                const destinationIndex = addToEndOfList
                    ? getTotalStage(data.LocalStateData.stagesOnlyTotal, [
                          stageData?.nextStage?.id
                      ])
                    : 0;

                await jobModalFN.jobChangeStageFunction({
                    outsideKanban: false,
                    destinationIndex,
                    applicantId: applicantData.pk,
                    stageData: stageData?.currentStage,
                    currentIndex,
                    newStageId: stageData?.nextStage?.id
                });
                // }

                if (listKeyToInvalidate.length) {
                    for (const keyName of listKeyToInvalidate) {
                        queryClient.invalidateQueries(keyName);
                    }
                }
            }
            // showMessageFullControl.closeMessage();
            showMessageFullControl.openMessage({
                message: t("COMPLEOGENERAL_MessageUpdated"),
                autoHideDuration: 2000,
                variant: "success"
            });
        }
    };

    if (!jobDataContext.readonly && stageData) {
        const title = tJobView("moveToStageButton", {
            newStage: stageData?.nextStage?.name
        });
        return (
            <>
                <ButtonIconTooltipCompleo
                    label={title}
                    onClick={() => moveToNextStage()}
                    className={classes.iconActionButton}
                >
                    <Directions fontSize="small" />
                </ButtonIconTooltipCompleo>
                {showMessageFullControl.MessageElement}
            </>
        );
    } else {
        return null;
    }
};

const getNextStage = (currentStageId: string, allStages: IJobViewStages[]) => {
    const currentIndex = allStages.findIndex((x) => x.id === currentStageId);
    if (currentIndex === -1) {
        return null;
    }
    const currentStage = allStages[currentIndex];
    const rejectedType = "rejected";
    const newTypeList: string[] = ["newApplied", "newSourced", "newReferred"];

    for (let index = currentIndex + 1; index < allStages.length; index++) {
        const element = allStages[index];
        if (
            currentStage?.type !== rejectedType &&
            element?.type === rejectedType
        ) {
            return null;
        } else if (!newTypeList.includes(element?.type)) {
            return { currentStage: currentStage, nextStage: element };
        }
    }
    return null;
};

export default MoveToNextStageInsideJobContainer;
