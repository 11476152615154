import React from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { IFieldProps } from ".";
import {
    createLocalUrlFromS3,
    IS3ExternalData
} from "customHooks/useCompleoForm/Inputs/File/File";
import ApplicantCVPreview from "Pages/Applicant/ApplicantNewEdit/ApplicantCVPreview";
import { Box } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Alert } from "@material-ui/lab";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import FieldFile from "./FieldFile";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldViewFile = (props: IFieldProps) => {
    const classes = useStyles();
    const {
        fieldData,
        fieldName,
        fieldDef,
        t,
        language,
        inline,
        propsToAdd
    } = props;
    const showMessage = useShowMessage();

    const fieldValue = _.get(fieldData, fieldName);
    const [localUrl, setLocalUrl] = React.useState("");
    const [retryAfterUpdate, setRetryAfterUpdate] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorConvertPDF, setErrorConvertPDF] = React.useState(false);

    const getFileFromS3 = (ignoreRetry: boolean) => {
        const externalData:
            | IS3ExternalData
            | undefined = propsToAdd.evaluationId
            ? {
                  evaluationId: propsToAdd.evaluationId
              }
            : undefined;

        createLocalUrlFromS3(fieldValue?.key, true, externalData).then(
            (localUrlData) => {
                setLocalUrl(localUrlData.url);
            },
            (failure: any) => {
                const cvFileName = fieldData?.[fieldName]?.key;

                if (!ignoreRetry) {
                    if (!cvFileName.includes(".pdf")) {
                        showMessage({
                            enabled: true,
                            message: t("convertingFileMessage"),
                            timeout: 7000,
                            typeMessage: "warning",
                            snackBarOrign: {
                                horizontal: "right",
                                vertical: "top"
                            }
                        });
                    }
                    setTimeout(function () {
                        setRetryAfterUpdate(true);
                    }, 5000);
                } else {
                    if (!cvFileName.includes(".pdf")) {
                        setErrorConvertPDF(true);
                    } else {
                        setError(true);
                    }
                }
            }
        );
    };

    React.useEffect(() => {
        if (fieldValue?.key) {
            setLocalUrl("");
            getFileFromS3(false);
        } else {
            setLocalUrl("");
        }
    }, [fieldValue?.key]);

    React.useEffect(() => {
        if (fieldValue?.key && retryAfterUpdate) {
            getFileFromS3(true);
            setRetryAfterUpdate(false);
        }
    }, [fieldValue?.key, retryAfterUpdate]);

    // console.log("localUrl", localUrl);

    if (localUrl) {
        return (
            <Box width="100%">
                <ApplicantCVPreview url={localUrl} isLocalStreamUrl={true} />
            </Box>
        );
    } else if (fieldValue?.key) {
        if (!error && !errorConvertPDF) {
            return (
                <Box width="100%">
                    <Loading />
                </Box>
            );
        } else if (errorConvertPDF) {
            return <FieldFile {...props} disableSearch />;
        } else {
            return (
                <Box width="100%" mt={2}>
                    <Alert severity="error">{t("errorFileMsg")}</Alert>
                </Box>
            );
        }
    } else {
        return <Box width="100%">{t("applicantWithoutCVMessage")}</Box>;
    }
};

export default FieldViewFile;
