import { Box, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useCompleoView, {
    UseCompleoEditCustomComponents,
    UseCompleoViewCustomComponents
} from "customHooks/useCompleoView";
import { useApi, useApiCache } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useApplicantViewContext from "./useApplicantViewContext";
import ApplicantHistory from "./ApplicantHistory/ApplicantHistory";
import ApplicantEmail from "./ApplicantEmail/ApplicantEmail";
import ApplicantSchedule from "./ApplicantSchedule/ApplicantSchedule";
import ApplicantRating from "./ApplicantRating/ApplicantRating";
import ApplicantAssessments from "./ApplicantAssessments/ApplicantAssessments";
import ApplicantOpinionInTheJob from "./ApplicantOpinionInTheJob/ApplicantOpinionInTheJob";
import ApplicantOpinionInTheJobEdit from "./ApplicantOpinionInTheJob/customEditComponent/ApplicantOpinionInTheJobEdit";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            // position: "absolute",
            display: "flex",
            // alignItems: "stretch",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main,
            // backgroundColor: "blue",
            height: "100%"
        }
    })
);

interface IProps {
    readOnly: boolean;
}

const ApplicantViewMainView = (props: IProps) => {
    const { readOnly } = props;
    const classes = useStyles();

    const [data] = useApplicantViewContext();
    const {
        metadata,
        ready,
        queryKey,
        language,
        t,
        timeZone,
        applicantId,
        companyId,
        tabOpen,
        customCVsData,
        evaluationId,
        cvToShare
    } = data;

    const [valuesFromSource] = useValuesFromSource(
        data.metadata,
        true,
        {},
        data.applicantDataReturn,
        ["data", "data"]
    );

    const postAddress = `/applicant/editview`;
    const [editApplicantReturn, editApplicant] = useApi(postAddress, "post");

    const CustomComponents: UseCompleoViewCustomComponents[] = [];
    const CustomEditComponents: UseCompleoEditCustomComponents[] = [];
    CustomComponents.push(
        {
            fieldName: "applicantHistory",
            Component: ApplicantHistory
        },
        {
            fieldName: "applicantEmail",
            Component: ApplicantEmail
        },
        {
            fieldName: "applicantSchedule",
            Component: ApplicantSchedule
        },
        {
            fieldName: "applicantRating",
            Component: ApplicantRating
        },
        {
            fieldName: "applicantAssessments",
            Component: ApplicantAssessments
        }
    );
    let fieldsToHide: string[] = [];
    if (data.jobDataInsideJob) {
        CustomComponents.push({
            fieldName: "opinionOnTheApplicantJob",
            Component: ApplicantOpinionInTheJob
        });
        CustomEditComponents.push({
            name: "opinionOnTheApplicantJob",
            component: ApplicantOpinionInTheJobEdit
        });
        const jobAplicantData = data.applicantData.Jobs.filter(
            (job: Compleo.IObject) => job.JobId === data.jobDataInsideJob?.jobId
        )[0];
        if (jobAplicantData) {
            valuesFromSource.opinionOnTheApplicantJob =
                jobAplicantData.opinionOnTheApplicantJob;
        }
    } else {
        fieldsToHide.push("opinionOnTheApplicantJob");
    }

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [];
    if (data.externalData?.evaluationId) {
        addProps.push({
            fieldName: "cv",
            props: {
                evaluationId: data.externalData.evaluationId,
                applicantId,
                cvToShare: data?.externalData?.cvToShare
            }
        });
        addProps.push({
            fieldName: "documents",
            props: { evaluationId: data.externalData.evaluationId }
        });
    } else if (evaluationId && cvToShare) {
        addProps.push({
            fieldName: "cv",
            props: {
                evaluationId,
                applicantId,
                cvToShare
            }
        });
    } else {
        addProps.push({
            fieldName: "cv",
            props: { applicantId }
        });
    }

    const { formRetorno, finished } = useCompleoView({
        language: language,
        t: t,
        metadataReturn: metadata,
        ready: ready,
        valuesFromSource: valuesFromSource,
        timeZone: timeZone,
        editParams: {
            moduleEditName: "APPLICANTVIEW",
            postFunction: editApplicant,
            postReturn: editApplicantReturn,
            keyData: {
                id: applicantId,
                companyId: companyId,
                jobId: data.jobDataInsideJob?.jobId
            },
            useQueryKeyInvalidate: [queryKey]
        },
        CustomComponents: CustomComponents,
        CustomEditComponents: CustomEditComponents,
        multiStepTabNameToOpen: tabOpen,
        addProps: addProps,
        readOnly: readOnly,
        fieldsToHide: fieldsToHide,
        customCVsData
    });

    return <Box className={classes.container}>{formRetorno}</Box>;
};

export default ApplicantViewMainView;
