import { useApiCache } from "customHooks/useApi";
import useGetMetadata from "customHooks/useGetMetadata";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    handleElasticData,
    ElasticDefDataType,
    getFiltersUserInfo
} from "./ListHelpers/ListUtil";
import MainPage from "./MainPage";
import useGlobalValues from "_ReactContext/GlobalValues";
import { useLanguageState } from "_ReactContext/LanguageContext";
import _ from "lodash";

export interface IFilters {
    filterUpdated: boolean;
    buckets: Compleo.IObject;
    language: string;
    otherGenericParams?: Compleo.IObject;
}

interface IListReturnInfo {
    aggregations: Compleo.IObject | null;
    aggregationsAfterQuery: Compleo.IObject | null;
    totalFiltered: Compleo.IObject | null;
    total: number | null;
    queryFinished: boolean;
    filtersUserInfo: Compleo.IObject[];
}

export interface IUseListBucketReportOpen {
    bucketDef: Compleo.IObject;
}
interface ILocalInfo {
    filterOpenInfo: {
        filterIsOpen: boolean;
        openFilterBucketState: Compleo.IObject;
    };
    loadedMainSearchFromFilterSaved?: string;
}

interface IDefinitionInfo {
    elasticDefData: ElasticDefDataType;
    bucketsDef: Compleo.IObject[];
    bucketsDefAfterQuery: Compleo.IObject[];
}

interface ITempInfo {
    t: TFunction<string[]>;
    readyTranslation: boolean;
    language: string;
    useApiKey: any;
    listApiAddress: string;
    moduleName: string;
    firstLoadFinished: boolean;
    otherParamsQuery?: Compleo.IObject;
}

interface IListContext {
    filtersInfo: {
        filters: IFilters;
    };
    listReturnInfo: IListReturnInfo;
    localInfo: ILocalInfo;
    definitionInfo: IDefinitionInfo;
    tempInfo: ITempInfo;
    externalAccess: boolean;
}

interface IListContextDispatch {
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
    setLocalInfo: React.Dispatch<React.SetStateAction<ILocalInfo>>;
    setBucketsDef: (value: React.SetStateAction<Compleo.IObject[]>) => void;
}

const ListContext = React.createContext<IListContext | undefined>(undefined);
const ListContextDispatch = React.createContext<
    IListContextDispatch | undefined
>(undefined);

const useListGlobalValues = (moduleName: string) => {
    const LocalSaveData = "tempsession";

    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const { isReady } = globalValuesDispatch;
    const globalValueFilterDataName = `useListFilter_${moduleName}`;
    const globalValueLocalInfoName = `useListLocalInfo_${moduleName}`;

    const globalValueFilterData = _.cloneDeep(
        globalValues[LocalSaveData][globalValueFilterDataName]
    );
    const globalValueLocalInfo = _.cloneDeep(
        globalValues[LocalSaveData][globalValueLocalInfoName]
    );

    const defaultFilter = _.cloneDeep(
        globalValues.userdb[`defaultFilter_${moduleName}`]
    );

    const changeGlobalValueFilterData = (value: any) => {
        globalValuesDispatch.addOrEditGlobalValue({
            type: LocalSaveData,
            name: globalValueFilterDataName,
            value: value
        });
    };

    const changeGlobalValueLocalInfo = (value: any) => {
        globalValuesDispatch.addOrEditGlobalValue({
            type: LocalSaveData,
            name: globalValueLocalInfoName,
            value: value
        });
    };

    const ready = isReady();

    return {
        globalValueFilterData,
        globalValueLocalInfo,
        changeGlobalValueFilterData,
        changeGlobalValueLocalInfo,
        bucketSortDef: globalValues.userdb[`bucketsOrder_${moduleName}`],
        defaultFilter,
        ready
    };
};
interface IProps {
    moduleName: string;
    listApiAddress: string;
    children: any;
    otherParamsQuery?: Compleo.IObject;
    externalAccess?: boolean;
}

export const CompleoDynamicDashboardProvider = (props: IProps) => {
    const {
        moduleName,
        listApiAddress,
        otherParamsQuery = {},
        externalAccess = false
    } = props;
    const globalValuesData = useListGlobalValues(moduleName);
    const { defaultFilter } = globalValuesData;

    const [t, i18n, readyTranslation] = useTranslation(
        [moduleName, "ELASTICLISTSETTINGS"],
        {
            useSuspense: false
        }
    );
    const [metadata] = useGetMetadata(moduleName);

    const localInfoDefaultDB = !externalAccess
        ? globalValuesData.globalValueLocalInfo || defaultFilter?.localInfo
        : undefined;

    const localInfoDefaultValue: ILocalInfo = localInfoDefaultDB || {
        filterOpenInfo: { filterIsOpen: true, openFilterBucketState: {} },
        modalLocationInfo: { open: false, values: {} },
        resultType: "grid",
        localMetadataReturn: [],
        openFirstItemApplicantModalOnPage: -1
    };

    const [localInfo, setLocalInfo] = React.useState<ILocalInfo>(
        localInfoDefaultValue
    );

    const [firstLoadFinished, setFirstLoadFinished] = React.useState(false);

    const localFilterDefaultDB =
        globalValuesData.globalValueFilterData || defaultFilter?.filters;

    const filtersDefaultValue: IFilters = localFilterDefaultDB || {
        filterUpdated: false,
        buckets: {},
        language: i18n.languages[0],
        otherGenericParams: otherParamsQuery
    };
    if (otherParamsQuery) {
        filtersDefaultValue.otherGenericParams = otherParamsQuery;
    }

    const [filters, setFilters] = React.useState<IFilters>(filtersDefaultValue);
    React.useEffect(() => {
        if (!externalAccess) {
            globalValuesData.changeGlobalValueFilterData(filters);
        }
    }, [filters]);

    const [filtersUserInfo, setFiltersUserInfo] = React.useState<
        Compleo.IObject[]
    >([]);
    const [elasticDefData, setElasticDefData] = React.useState<
        ElasticDefDataType
    >(null);
    const [bucketsDef, setBucketsDef] = React.useState<Compleo.IObject[]>([]);
    const [bucketsDefAfterQuery, setBucketsDefAfterQuery] = React.useState<
        Compleo.IObject[]
    >([]);

    const [totalRecords, setTotalRecords] = React.useState<number | null>(null);

    const { company } = useAuthState();
    const [getSearch, executeSearch] = useApiCache(
        listApiAddress,
        "post",
        {
            companyId: company.companyId,
            ...filters
        },
        false,
        1000 * 60 * 5,
        false
    );

    React.useEffect(() => {
        executeSearch({
            companyId: company.companyId,
            ...filters,
            language: i18n.languages[0],
            updateAggsAfterFilter: false
        });
    }, [filters, i18n.languages[0]]);

    React.useEffect(() => {
        setFiltersUserInfo(
            getFiltersUserInfo(filters, elasticDefData, t, i18n.languages[0])
        );
    }, [filters, elasticDefData, i18n.languages[0]]);

    // const allAggs: Compleo.IObject = getBuckets?.response?.data?.allAggs;

    const aggregations: Compleo.IObject =
        getSearch?.response?.data?.aggregations;

    const aggregationsAfterQuery: Compleo.IObject =
        getSearch?.response?.data?.aggregationsAfterQuery;
    const fields: Compleo.IObject[] = getSearch?.response?.data?.fields || [];
    const totalFiltered: Compleo.IObject =
        getSearch?.response?.data?.totalFiltered || {};
    const queryFinished = getSearch.status === "success";
    const totalReturnValue = totalFiltered?.value || -1;
    const sortDBData = getSearch?.response?.data?.sort;

    React.useEffect(() => {
        if (queryFinished && readyTranslation && !firstLoadFinished) {
            setFirstLoadFinished(true);
        }
    }, [queryFinished, readyTranslation, firstLoadFinished]);

    React.useEffect(() => {
        if (
            aggregations &&
            metadata.status === "success" &&
            (elasticDefData === null ||
                !elasticDefData.data.sortDBData ||
                elasticDefData?.language !== i18n.languages[0]) &&
            readyTranslation
        ) {
            console.log("debugbucket aggregations", aggregations);
            console.log(
                "debugbucket aggregationsAfterQuery",
                aggregationsAfterQuery
            );
            console.log(
                "debugbucket ElasticSearchListDefinition",
                metadata.response.data?.ElasticSearchListDefinition
            );
            console.log("debugbucket i18n.languages[0]", i18n.languages[0]);
            console.log(
                "debugbucket i18n.globalValuesData.bucketSortDef",
                globalValuesData.bucketSortDef
            );

            const bucksData = handleElasticData(
                aggregations,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );
            const bucksDataAfterQuery = handleElasticData(
                aggregationsAfterQuery,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );

            console.log("bucksData", bucksData);
            console.log("bucksDataAfterQuery", bucksDataAfterQuery);

            setElasticDefData({
                data: {
                    ...(metadata.response.data?.ElasticSearchListDefinition ||
                        {}),
                    bucketsDef: bucksData,
                    bucketsDefAfterQuery: bucksDataAfterQuery,
                    sortDBData: sortDBData
                },
                language: i18n.languages[0]
            });
        }
    }, [
        aggregations,
        aggregationsAfterQuery,
        elasticDefData,
        metadata.status,
        i18n.languages[0],
        readyTranslation,
        sortDBData
    ]);

    const [getTotal] = useApiCache(
        listApiAddress,
        "post",
        {
            companyId: company.companyId,
            returnTotal: true,
            otherGenericParams: filters.otherGenericParams
        },
        false,
        1000 * 60 * 5
    );

    React.useEffect(() => {
        if (getTotal.response?.data?.totalRecords && totalRecords === null) {
            setTotalRecords(getTotal.response?.data?.totalRecords);
        }
    }, [getTotal.response?.data?.totalRecords]);

    React.useEffect(() => {
        if (aggregations && metadata.status === "success" && readyTranslation) {
            const bucksData = handleElasticData(
                aggregations,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );
            const bucksDataAfterQuery = handleElasticData(
                aggregationsAfterQuery,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );
            setBucketsDef(bucksData);
            setBucketsDefAfterQuery(bucksDataAfterQuery);
        }
    }, [
        aggregations,
        aggregationsAfterQuery,
        readyTranslation,
        i18n.languages[0]
    ]);

    const returnDataContext: IListContext = {
        filtersInfo: {
            filters: filters
        },
        listReturnInfo: {
            aggregations: aggregations,
            aggregationsAfterQuery: aggregationsAfterQuery,
            totalFiltered: totalFiltered,
            filtersUserInfo: filtersUserInfo,
            total: totalRecords,
            queryFinished: queryFinished
        },
        localInfo: localInfo,
        definitionInfo: {
            elasticDefData: elasticDefData,
            bucketsDef: bucketsDef,
            bucketsDefAfterQuery: bucketsDefAfterQuery
        },
        tempInfo: {
            t: t,
            readyTranslation: readyTranslation,
            language: i18n.languages[0],
            useApiKey: getSearch.useQueryKey,
            listApiAddress: listApiAddress,
            moduleName: moduleName,
            firstLoadFinished: firstLoadFinished,
            otherParamsQuery: otherParamsQuery
        },
        externalAccess: externalAccess
    };

    const returnDataContextDispatch: IListContextDispatch = {
        setFilters: setFilters,
        setLocalInfo: setLocalInfo,
        setBucketsDef: setBucketsDef
    };

    return (
        <ListContext.Provider value={returnDataContext}>
            <ListContextDispatch.Provider value={returnDataContextDispatch}>
                {props.children}
            </ListContextDispatch.Provider>
        </ListContext.Provider>
    );
};

const useDynamicDashboard: () => [IListContext, IListContextDispatch] = () => {
    return [useDynamicDashboardValues(), useDynamicDashboardDispatch()];
};

const useDynamicDashboardValues = () => {
    const context = React.useContext(ListContext);
    if (!context) {
        throw new Error(
            `useDynamicDashboard must be used within a ListProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useDynamicDashboardDispatch = () => {
    const context = React.useContext(ListContextDispatch);
    if (!context) {
        throw new Error(
            `useDynamicDashboard must be used within a ListProvider`
        );
    }
    const returnData = context;
    return returnData;
};

// type layoutTypeList = "default" | "jobview" | "jobkanbanfilter" | "blank";
interface IUseListPage {
    moduleName: string;
    listApiAddress: string;
    // layoutType?: layoutTypeList;
    otherParamsQuery?: Compleo.IObject;
    // modalOptions?: { open: boolean; handleClose: any };
    children?: any;
    externalAccess?: boolean;
}

export const CompleoDynamicDashboardPage = (props: IUseListPage) => {
    const {
        moduleName,
        listApiAddress,
        otherParamsQuery = {},
        externalAccess = false,
        children = null
    } = props;
    const [, globalValuesDispatch] = useGlobalValues();
    const globalValuesReady = globalValuesDispatch.isReady();

    if (globalValuesReady || externalAccess) {
        return (
            <CompleoDynamicDashboardProvider
                moduleName={moduleName}
                listApiAddress={listApiAddress}
                otherParamsQuery={otherParamsQuery}
                externalAccess={externalAccess}
            >
                <LayoutPage>{children}</LayoutPage>
            </CompleoDynamicDashboardProvider>
        );
    } else {
        return null;
    }
};

interface IPropsLayoutPage {
    children: any;
}

const LayoutPage = (props: IPropsLayoutPage) => {
    const { children } = props;
    return <MainPage>{children}</MainPage>;
};

export default useDynamicDashboard;
