import React from "react";
import UserAuthorization from "./UserAuthorization";
import BranchAuthorization from "./UserBranchAccess";

const CustomComponents = [
    {
        name: "FeaturesAccessList",
        component: UserAuthorization
    },
    {
        name: "BranchsAccessList",
        component: BranchAuthorization
    }
];

export default CustomComponents;
