import { apiDirectCall } from "customHooks/useApi/api";

export const getBase64DOCX = async (
    base64FileString: string,
    fileName: string
) => {
    const postAddressConvertToPDF = `/fileutil/converthtmltodocx`;
    const result = await apiDirectCall(postAddressConvertToPDF, "post", {
        base64File: base64FileString,
        fileName
    });

    if (result.status !== 200) return {};

    return result.data;
};

export const getBase64PDF = async (
    //base64FileString: string,
    html: string,
    fileName: string,
    margins?: Compleo.IObject
) => {
    //const postAddressConvertToPDF = `/fileutil/converttopdf`;
    const postAddressConvertToPDF = "/fileutil/converthtmltopdf";
    const result = await apiDirectCall(postAddressConvertToPDF, "post", {
        html,
        fileName,
        margins
    });

    if (result.status !== 200) return {};

    return result.data;
};

export const getApplicantData = async (
    applicantId: string,
    companyId: number
) => {
    const t0 = performance.now();

    const postAddressSearch = `/applicant/search`;
    const result = await apiDirectCall(postAddressSearch, "post", {
        companyId,
        id: applicantId
    });
    const t1 = performance.now();

    if (result.status !== 200) return {};

    return result.data?.data;
};

function unescapeHtmlString(input: string): string {
    const htmlEntities: { [key: string]: string } = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#39;": "'",
        "&#x2F;": "/",
        "&#x60;": "`",
        "&#x3D;": "="
    };

    return input.replace(
        /&(?:amp|lt|gt|quot|#39|#x2F|#x60|#x3D);/g,
        (match) => htmlEntities[match]
    );
}

function replaceEscapedHtmlInBrackets(input: string): string {
    return input.replace(
        /\[.*?]/g,
        (match) => `[${unescapeHtmlString(match.slice(1, -1))}]`
    );
}

export function getCustomCVDataWithUnescapedHtml(
    customCVData: Compleo.IObject
): Compleo.IObject {
    return {
        ...customCVData,
        mainBody: replaceEscapedHtmlInBrackets(customCVData.mainBody),
        educationHistoryBody: replaceEscapedHtmlInBrackets(
            customCVData.educationHistoryBody
        ),
        professionalHistoryBody: replaceEscapedHtmlInBrackets(
            customCVData.professionalHistoryBody
        ),
        languagesBody: replaceEscapedHtmlInBrackets(customCVData.languagesBody)
    };
}

export const getCustomCVData = async (
    customCVId: string,
    companyId: number
) => {
    const t0 = performance.now();
    if (!companyId) {
        console.log(
            "getCustomCVData: getcustomcv without companyId",
            companyId
        );
    }
    const postAddressSearch = `/customcv/search`;
    const result = await apiDirectCall(postAddressSearch, "post", {
        companyId,
        uuid: customCVId
    });

    const t1 = performance.now();
    if (result.status !== 200) return {};

    const response = getCustomCVDataWithUnescapedHtml(result.data?.data);

    return response;
};

export const cleanWordHTML = (html: string) => {
    let cleanedHTML = html;
    //TODO implement function to clean word formatting and keep same structure on html

    return cleanedHTML;
};

export const b64toBlob = (
    b64Data: string,
    contentType = "",
    sliceSize = 512
) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};
