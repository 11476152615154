import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "./useApplicantViewContext";
import { Delete } from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import useRemoveApplicantFromJob from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useRemoveApplicantFromJob";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import useRefreshKanban from "Pages/Job/JobView/JobKanban/useRefreshKanban";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        iconActionButton: {
            color: "inherit"
        }
    });
});

interface IProps {}

const ApplicantViewHeaderButtonRemoveFromJob = (props: IProps) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { applicantData, t, language, metadata } = data;
    const keysToInvalidate: string[] = [
        data.queryKey,
        "/applicant/list",
        "/job/applicantlistjobview"
    ];
    const updateAllInfo = useRefreshKanban();

    const runAfterRemove = async () => {
        await updateAllInfo();
        showMessageFullControl.closeMessage();
    };
    const showMessageFullControl = useShowMessageFullControl();
    const removeApplicant = useRemoveApplicantFromJob({
        jobId: data.jobDataInsideJob?.jobId || "",
        companyId: applicantData.companyId,
        listKeyToInvalidate: keysToInvalidate,
        t: t,
        fnToRunAfterRemove: runAfterRemove,
        fnToRunUserSelectedNoOption: showMessageFullControl.closeMessage
    });
    const removeApplicantLocal = async () => {
        showMessageFullControl.openMessage({
            message: t("COMPLEOGENERAL_MessageUpdating"),
            variant: "info",
            showLinearProgress: true
        });
        await removeApplicant.removeApplicantFromJob(applicantData.pk);
    };

    return (
        <>
            <ButtonIconTooltipCompleo
                label={t("removeFromJob")}
                onClick={removeApplicantLocal}
                className={classes.iconActionButton}
            >
                <Delete color="inherit" fontSize="small" />
            </ButtonIconTooltipCompleo>
            {showMessageFullControl.MessageElement}
        </>
    );
};

export default ApplicantViewHeaderButtonRemoveFromJob;
