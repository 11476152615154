import React, { useState } from "react";
import {
    getActionsFromStages,
    getMetadataNameFromPipelineStageActionType,
    PipelineActionTypeDefinition
} from "./helperPipeline";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ActionsButton from "./ActionsButton";
import GenericStageActionsModal, {
    IGenericStageActionsModal
} from "./GenericStageActionsModal";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";
import { useAuthorization } from "_ReactContext/AuthContext";
import { useFormContext } from "react-hook-form";

interface IProps {
    label: string;
    metadata: any;
    helperTextDefault?: any;
    t: any;
}

const NewActionCustomField = (props: IProps) => {
    const { t, metadata } = props;
    const { isFeatureAuthorized } = useAuthorization();

    const disableAutomationPlan = isFeatureAuthorized(
        "PipelineAutomation_Upgrade"
    );

    const { setValue, getValues, watch } = useFormContext();
    const values = watch();

    const actionsType = getActionsFromStages(values as Compleo.IObject);
    const [
        genericStageActionsModalData,
        setGenericStageActionsModalData
    ] = React.useState<IGenericStageActionsModal | null>(null);

    const onCloseGenericModal = () => {
        setGenericStageActionsModalData((currentState) => {
            if (!currentState) {
                return null;
            }
            return { ...currentState, open: false };
        });
    };

    const addAction = (actionToEnable: PipelineActionTypeDefinition) => {
        const module = getMetadataNameFromPipelineStageActionType(
            actionToEnable
        );

        setGenericStageActionsModalData({
            open: true,
            onClose: onCloseGenericModal,
            fieldName: actionToEnable,
            module: module,
            setValue: setValue,
            initialValuesDB: {}
        });
    };

    return (
        <>
            {!disableAutomationPlan && (
                <ActionsButton
                    hiddenFields={actionsType.disabled}
                    t={t}
                    handleOptionSelected={addAction}
                />
            )}
            {genericStageActionsModalData?.open && (
                <GenericStageActionsModal {...genericStageActionsModalData} />
            )}
            <AlertUpgradePlan feature="pipelineAutomation" />
        </>
    );
};
export default NewActionCustomField;
