import { apiDirectCall } from "customHooks/useApi/api";
import useGetMetadata from "customHooks/useGetMetadata";
import _ from "lodash";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { FieldOptionsType } from "./FormData";
import { fieldsRules } from "./helper";

const useHandleInitialValues = (
    valuesFromSource: Compleo.IObject,
    ready: boolean
) => {
    const [initialValues, setInitialValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: valuesFromSource });

    const [
        formsAndFields,
        setFormsAndFields
    ] = React.useState<Compleo.IObject | null>(null);

    const [applicantMetadata] = useGetMetadata("APPLICANTVIEW");

    const formGroups = applicantMetadata.response?.data?.formGroups;
    const metadata = applicantMetadata.response?.data?.metadado;

    React.useEffect(() => {
        if (formGroups && metadata && !initialValues.ready && ready) {
            const requesterSettings = getFormsAndFields(
                valuesFromSource,
                formGroups,
                metadata,
                "requesterSettings"
            );
            const contactSettings = getFormsAndFields(
                valuesFromSource,
                formGroups,
                metadata,
                "contactSettings"
            );
            const externalAndOthersSettings = getFormsAndFields(
                valuesFromSource,
                formGroups,
                metadata,
                "externalAndOthersSettings"
            );
            setFormsAndFields({
                requesterSettings: { ...requesterSettings },
                contactSettings: { ...contactSettings },
                externalAndOthersSettings: { ...externalAndOthersSettings }
            });
        }
    }, [metadata, formGroups, ready, valuesFromSource]);

    if (
        !initialValues.ready &&
        applicantMetadata.status === "success" &&
        formsAndFields !== null
    ) {
        setInitialValues({
            ...initialValues,
            values: {
                ...initialValues.values,
                ...formsAndFields
            },
            ready: true
        });
    }

    return {
        initialValues: initialValues.values,
        readyInitialValues: initialValues.ready
    };
};

const getFormsAndFields = (
    initialValues: Compleo.IObject,
    formGroups: Compleo.IObject[],
    metadata: Compleo.IObject[],
    fieldName: string
) => {
    const currentValue = { ...(initialValues?.[fieldName] || {}) };
    const currentFields = currentValue.fields || [];
    const currentFieldsFormsIds: string[] = currentFields.map(
        (f: any) => f.formGroupId
    );
    const currentForms = (
        currentValue.forms || []
    ).filter((form: Compleo.IObject) =>
        currentFieldsFormsIds.includes(form.id)
    );
    const currentFormsIds = currentForms.map((f: any) => f.id);

    const formsJoined = [
        ...formGroups.filter((f: any) => !currentFormsIds.includes(f.id)),
        ...currentForms
    ].filter((item) => !fieldsRules.formsToRemove.includes(item.id));

    const forms = [...formsJoined].sort(
        (itemA: Compleo.IObject, itemB: Compleo.IObject) =>
            itemA.order - itemB.order
    );
    const newFields: Compleo.IObject[] = [];

    forms.map((form: Compleo.IObject) =>
        metadata
            .filter((field: Compleo.IObject) => field.formGroupId === form.id)
            .sort(
                (itemA: Compleo.IObject, itemB: Compleo.IObject) =>
                    itemA.order - itemB.order
            )
            .map((field: Compleo.IObject, index: number) => {
                const currentField = currentFields.filter(
                    (f: any) => f.fieldName === field.fieldName
                )[0];

                const fieldToAdd = currentField || field;

                let fieldOptions: FieldOptionsType = "hidden";

                if (currentField) {
                    fieldOptions = currentField.fieldOptions;
                }

                /*
                    Por enquanto não é possível personalizar
                    as traduções de detalhe do ComplexField.
                    No futuro alterar este flag para true
                    e fazer as conversões de nome de campo
                */
                const loadComplexFieldDetail = false;
                const loadField = !field.complexFieldDetail
                    ? true
                    : loadComplexFieldDetail &&
                      field.complexFieldDetail === true;

                if (loadField) {
                    newFields.push({
                        fieldName: fieldToAdd.fieldName,
                        order: currentField?.order || index,
                        formGroupId: fieldToAdd.formGroupId,
                        fieldOptions: fieldOptions
                    });
                }
            })
    );
    const finalFields = newFields
        .filter((item) => !fieldsRules.fieldsToRemove.includes(item.fieldName))
        .sort((a, b) => a.order - b.order);

    return { forms, fields: finalFields };
};

export default useHandleInitialValues;
