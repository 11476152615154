import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { ApiCallType, apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useQueryClient } from "react-query";
import useJobList from "./useJobList";
import { IApplicantViewMainState } from "../useApplicantViewContext/ApplicantViewTypes";

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    applicantId?: string;
    jobData?: {
        value: string;
        label: string;
    };
    allowChangeJob?: boolean;
    appicantViewDataProvider?: IApplicantViewMainState;
    applicantName?: string;
    type?: CompleoShared.Common.ApplicantHistoryType;
    stageId?: string;
}

const ApplicantHistoryNewCommentModal = (props: IPropsModal) => {
    const {
        appicantViewDataProvider,
        onClose,
        open,
        applicantId = appicantViewDataProvider?.applicantId,
        jobData = {
            value: appicantViewDataProvider?.jobDataInsideJob?.jobId,
            label:
                appicantViewDataProvider?.jobDataInsideJob?.jobData?.data?.title
        },
        allowChangeJob = true,
        applicantName,
        type,
        stageId
    } = props;

    const [isUpdating, setIsUpdating] = React.useState(false);
    const dateWithSecondsZero = new Date();
    dateWithSecondsZero.setSeconds(0);
    const [keepOpen, setKeepOpen] = React.useState(false);

    const [currentDate, setCurrentDate] = React.useState(dateWithSecondsZero);

    const queryClient = useQueryClient();
    const localCloseWindow = () => {
        queryClient.invalidateQueries("/applicant/applicanthistorylist");
        onClose();
    };

    const [listDefinition, listReady] = useJobList(
        appicantViewDataProvider?.applicantData
    );

    const module = "APPLICANTVIEWCOMMENT";
    const [metadata] = useGetMetadata(module);
    const fieldsToHide = allowChangeJob ? ["jobTitle"] : ["job"];

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, true, {
        applicantId: applicantId,
        operationDate: currentDate,
        job: jobData,
        jobTitle: jobData?.label,
        type: type ? { value: type } : undefined,
        stageId: stageId,
        comment: ""
    });
    const ready =
        readyTranslation && metadata.status === "success" && listReady;

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { setValue, getValues } = reactHookFormMethods.reactHookFormMethods;

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        const postAddress = `/applicant/newapplicanthistorycomment`;
        try {
            const dataAPI = await apiDirectCall(postAddress, "post", values);
            if (dataAPI.status === 200) {
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }

        setIsUpdating(false);
        if (!keepOpen) {
            localCloseWindow();
        } else {
            const dateWithSecondsZero = new Date();
            dateWithSecondsZero.setSeconds(0);
            setValue("operationDate", dateWithSecondsZero);
            setValue("type", undefined);
            setValue("comment", "");
        }
        return retorno;
    };
    const [formCompleo, finished, handleSubmitRHF] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: listDefinition,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide: fieldsToHide,
        forceBackdrop: true
    });
    const handleSave = async () => {
        await handleSubmitRHF();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            <DialogTitle>{`${t("modalTitle", "")} ${
                applicantName ? ` - ${applicantName}` : ""
            } `}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={keepOpen}
                            onChange={() => setKeepOpen(!keepOpen)}
                            name="keepOpen"
                            color="primary"
                        />
                    }
                    label={t("keepWindowOpen", "")}
                />
                <Button
                    onClick={() => localCloseWindow()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalClose", "")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave", "")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApplicantHistoryNewCommentModal;
