import React from "react";
import JobCareersStatusMessages from "../JobCareersStatusMessages";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import JobBoardsChangeCareerSite from "./JobBoardsChangeCareerSite";
import useJobBoardsCareersStatus from "./useJobBoardsCareersStatus";

interface IJobBoardsCareersStatus {}

const JobBoardsCareersStatus = (props: IJobBoardsCareersStatus) => {
    const {
        hasError,
        careerItem,
        filteredMessageList,
        translation,
        otherListCareerItems
    } = useJobBoardsCareersStatus();
    const { t } = translation;
    const careerUniqueLink = (
        careerItem?.careerSettings?.uniqueLink || ""
    ).toLowerCase();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="caption">{t("careers")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    component="span"
                    style={{ marginRight: 16 }}
                >
                    <strong>{careerUniqueLink}</strong>
                </Typography>
                <JobBoardsChangeCareerSite
                    otherListCareerItems={otherListCareerItems}
                    t={t}
                />
            </Grid>
            {hasError && (
                <Grid item xs={12}>
                    <Alert severity="error">{t("messageCareersErrors")}</Alert>
                </Grid>
            )}
            {hasError && (
                <Grid item xs={12}>
                    <JobCareersStatusMessages
                        careerItem={careerItem}
                        messageListParam={filteredMessageList}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default JobBoardsCareersStatus;
