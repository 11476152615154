import React from "react";
import ItemView from "./ItemView";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { IFieldProps } from ".";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldScorecard = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, fieldDef, t, language } = props;
    const [tlocal, i18n, readyTranslation] = useTranslation("ADDRESSFIELD", {
        useSuspense: false
    });

    // const countryFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const postalCodeFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const addressLine1FieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const addressLine2FieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const numberFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const neighborhoodFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    // const cityFieldDef = {
    //     ...fieldDef,
    //     columnSize: { xs: 12, sm: 6 }
    // };
    // const provinceOrStateFieldDef = {
    //     ...fieldDef,
    //     columnSize: { xs: 12, sm: 6 }
    // };
    const sections: Compleo.IObject[] = _.get(
        fieldData,
        `${fieldName}.sections`,
        []
    );
    if (sections.length === 0) return null;
    console.log("sections", sections);
    return (
        <>
            {sections.map((section: Compleo.IObject, index: number) => {
                const items = (section.items || [])
                    .map((item: Compleo.IObject) => item.name)
                    .join(" | ");
                return (
                    <ItemView
                        label={`${tlocal(`${section.name}`)}: `}
                        fieldDef={fieldDef}
                        removeColonAfterLabel={true}
                        key={section.name}
                    >
                        {items}
                    </ItemView>
                );
            })}
        </>
    );

    // return (
    //     <>
    //         <ItemView
    //             label={`${tlocal(`country`)}: `}
    //             fieldDef={countryFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.country`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`postalCode`)}: `}
    //             fieldDef={postalCodeFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.postalCode`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`addressline1`)}: `}
    //             fieldDef={addressLine1FieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.addressline1`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`number`)}: `}
    //             fieldDef={numberFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.number`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`addressline2`)}: `}
    //             fieldDef={addressLine2FieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.addressline2`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`neighborhood`)}: `}
    //             fieldDef={neighborhoodFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.neighborhood`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`city`)}: `}
    //             fieldDef={cityFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.city`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //         <ItemView
    //             label={`${tlocal(`provinceOrState`)}: `}
    //             fieldDef={provinceOrStateFieldDef}
    //             removeColonAfterLabel={true}
    //         >
    //             {transformDBData({
    //                 value: _.get(fieldData, `${fieldName}.provinceOrState`),
    //                 t,
    //                 language,
    //                 replaceOption: "-"
    //             })}
    //         </ItemView>
    //     </>
    // );
};

export default FieldScorecard;
