import React from "react";

import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ListIcon from "@material-ui/icons/List";

export type JobDateLimitType = "red" | "warning" | "normal" | "all";
interface IProps {
    value: JobDateLimitType;
    allWhite?: boolean;
    iconSize?: "small" | "inherit" | "default" | "large";
}
const JobDateLimitFlagColumnRender = (props: IProps) => {
    const { value, allWhite = false, iconSize = "small" } = props;

    const color = allWhite ? "white" : getColorByTypeJobDateLimit(value);
    switch (value) {
        case "red": {
            return <ErrorIcon fontSize={iconSize} style={{ color: color }} />;
        }
        case "warning": {
            return <WarningIcon fontSize={iconSize} style={{ color: color }} />;
        }
        case "normal": {
            return (
                <CheckCircleIcon fontSize={iconSize} style={{ color: color }} />
            );
        }
        default: {
            return <ListIcon fontSize={iconSize} style={{ color: color }} />;
        }
    }
};

export const getColorByTypeJobDateLimit = (type: JobDateLimitType) => {
    switch (type) {
        case "red": {
            return "red";
        }
        case "warning": {
            return "#f0ad4e";
        }
        case "normal": {
            return "#00a152";
        }
        default: {
            return "#2196f3";
        }
    }
};

export default JobDateLimitFlagColumnRender;
