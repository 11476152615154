import React from "react";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { apiDirectCall } from "customHooks/useApi/api";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

export const useResetDefaults = (
    companyId: number,
    t: any,
    i18n: any,
    language: any,
    moduleName: any
) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();
    const [state, setstate] = React.useState(false);
    const isReset = "1";
    const postAddress = `/settings/keyword/${companyId}/${language}/${isReset}`;
    const restoreDefaults = async () =>
        callDialog({
            title: t("language_ModalTitle"),
            bodyText: t("language_Message"),
            agreeButtonText: t("language_Yes"),
            disagreeButtonText: t("language_No"),
            agreeFunction: async () => {
                const result = await apiDirectCall(postAddress, "post");
                //console.log("ret ", result.data);
                if (result.status === 200) {
                    setstate(true);
                    showMessage(t("successMessage"), "success");
                    await i18n.reloadResources(null, moduleName);
                }
            },
            disagreeFunction: () => {}
        });
    return [restoreDefaults, state];
};
