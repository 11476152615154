import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import { Typography } from "@material-ui/core";
import { IBulkActions } from "./helperBulkActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    bulkAction: IBulkActions | null;
}

const BulkActionsStepConfirmation = (props: IProps) => {
    const classes = useStyles();
    const { bulkAction } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const message =
        bulkAction?.type === "online"
            ? t(
                  "ELASTICLISTSETTINGS:ModalBulkActions_StepConfirmationMessageOnline"
              )
            : t(
                  "ELASTICLISTSETTINGS:ModalBulkActions_StepConfirmationMessageOffline"
              );
    return (
        <>
            <Typography variant="body2" className={classes.instructions}>
                {message}
            </Typography>
        </>
    );
};

export default BulkActionsStepConfirmation;
