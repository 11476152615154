import React, { useState } from "react";
import { useAuthState, useAuthorization } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import "i18n/i18n";
import { i18f } from "i18n/i18n";
import PageNotAuthorized from "Pages/_General/PageNotAuthorized";
import { checkTokenExpiration } from "./utils";
import ModalSessionExpired from "./ModalSessionExpired";
import useIdleTimer from "./useIdleTimer";

const Authenticated = (props: any) => {
    const { isAuthenticated, company } = useAuthState();
    const { isRouteAuthorized } = useAuthorization();
    const [pathToRedirect, setPathToRedirect] = React.useState("");
    const plan = company.plan || "noplan";
    const companyId = company.companyId || 0;
    const inactivityTimeoutMinutes = company.inactivityTimeoutMinutes;
    const [showModal, setShowModal] = useState(false);

    useIdleTimer({
        idleTimeoutMinutes: inactivityTimeoutMinutes
    });
    React.useEffect(() => {
        const urli18n = `${process.env.REACT_APP_API_ADDRESS}/general/translation/${companyId}/0`;
        const currentPath = localStorage.getItem("i18nPath");

        let canChange =
            (companyId > 0 && plan !== "noplan") ||
            (companyId === 0 && plan === "noplan");

        if (urli18n !== currentPath && canChange) {
            i18f(urli18n);
        }
    }, [companyId, plan]);

    React.useEffect(() => {
        if (pathToRedirect !== "") {
            navigate(pathToRedirect);
        }
    }, [pathToRedirect]);

    // Temporary removed to avoid any issues with date errors on token verification
    // and show the modal incorrectly
    // React.useEffect(() => {
    //     const intervalId = setInterval(async () => {
    //         const isTokenExpired = await checkTokenExpiration({
    //             tryAgainError: { tryAgain: true, tryAgainInSeconds: 15 }
    //         });
    //         setShowModal(isTokenExpired);
    //     }, 20 * 1000); // Check every 20 seconds, adjust as needed
    //     return () => clearInterval(intervalId);
    // }, []);

    const currentRoute = props.routes.find(
        (item: any) => item.route === props.path
    );

    if (isAuthenticated) {
        let routeAuthorized = true;

        if (
            currentRoute !== undefined &&
            currentRoute.uniqueName !== undefined
        ) {
            let requiresAuthorization = true;
            if (
                currentRoute.requiresAuthorization !== undefined &&
                !currentRoute.requiresAuthorization
            ) {
                requiresAuthorization = false;
            }
            if (requiresAuthorization) {
                routeAuthorized = isRouteAuthorized(currentRoute.uniqueName);
            }
        }

        if (routeAuthorized) {
            return (
                <>
                    {showModal && (
                        <ModalSessionExpired
                            open={showModal}
                            setOpen={setShowModal}
                        />
                    )}
                    {React.cloneElement(props.children, {
                        ...props
                    })}
                </>
            );
        } else {
            return <PageNotAuthorized />;
        }
    } else {
        let redirectAddress = "/";
        if (currentRoute !== undefined && currentRoute.route !== undefined) {
            redirectAddress = encodeURI(window.location.pathname);
        }
        if (pathToRedirect === "") {
            setPathToRedirect(`/login?to=${redirectAddress}`);
        }
        return null;
    }
};

export default Authenticated;
