import { useAuthorization } from "_ReactContext/AuthContext";

const useApplicantAccess = () => {
    const { isFeatureAuthorized } = useAuthorization();
    const allowView = isFeatureAuthorized("ViewApplicant");
    const allowEdit = isFeatureAuthorized("EditApplicant");
    const allowDelete = isFeatureAuthorized("DeleteApplicant");
    const allowAdd = isFeatureAuthorized("AddApplicant");

    return { allowAdd, allowEdit, allowView, allowDelete };
};

export default useApplicantAccess;
