import { useState } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useCustomCVActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const callDeleteDialog = async (rowData: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const uuid = rowData.skGS1pk.split(":")[1];
            const postAddress = "/customcv/delete";
            //rowData.accessEnabled = rowData.accessEnabled === "Não" ? false : true;

            callDialog({
                title: t("CustomCVDelete_ModalTitle"),
                bodyText: t("CustomCVDelete_Message"),
                agreeButtonText: t("CustomCVDelete_Yes"),
                disagreeButtonText: t("CustomCVDelete_No"),
                agreeFunction: async () => {
                    const result = await apiDirectCall(postAddress, "post", {
                        companyId,
                        uuid
                    });
                    if (result.status === 200) {
                        showMessage(t("successMessage"), "success");
                        resolve(true);
                    }
                    resolve(false);
                },
                disagreeFunction: () => {
                    resolve(false);
                }
            });
        });
    };

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("edit_tooltip"),
                onClick: (event: any, rowData: any) => {
                    navigate(`/customcv/edit/${rowData.skGS1pk.split(":")[1]}`);
                }
                //hidden: !featuresAccess.includes("EditContact")
            },
            {
                icon: "delete",
                tooltip: t("delete_tooltip"),
                onClick: (event: any, rowData: any) => {
                    callDeleteDialog(rowData).then(
                        (resolve: boolean) => {
                            if (resolve) {
                                refreshListFunction();
                            }
                        },
                        (reject: boolean) => {}
                    );
                }
                //hidden: !featuresAccess.includes("DeleteContact")
            }
        ];

        return result;
    };
};

export default useCustomCVActionsTable;
