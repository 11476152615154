import React from "react";
import ItemCard from "./ItemCard";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";
import { keepOnlyNumbersInString, transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { Typography } from "@material-ui/core";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    returnType: "card" | "table";
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldWhatsApp = (props: IProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, returnType } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const localFieldName = cardFields[fieldName].fieldName || fieldName;
    switch (returnType) {
        case "card": {
            const value = transformDBData({
                value: _.get(fieldData, localFieldName),
                t,
                language,
                replaceOption: "-"
            });
            return (
                <ItemCard
                    label={
                        cardFields[fieldName].cardIcon ? (
                            <Icon color="action" fontSize="small">
                                {cardFields[fieldName].cardIcon}
                            </Icon>
                        ) : (
                            `${t(fieldName)}: `
                        )
                    }
                    removeColonAfterLabel={true}
                >
                    {value && value !== "-" ? (
                        <a
                            href={`https://wa.me/${keepOnlyNumbersInString(
                                value
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {value}
                        </a>
                    ) : (
                        value
                    )}
                </ItemCard>
            );
        }
        case "table": {
            const value = _.get(fieldData, localFieldName);
            return (
                <>
                    {value ? (
                        <a
                            href={`https://wa.me/${keepOnlyNumbersInString(
                                value
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {transformDBData({
                                value: value,
                                t,
                                language,
                                replaceOption: "-"
                            })}
                        </a>
                    ) : (
                        transformDBData({
                            value: value,
                            t,
                            language,
                            replaceOption: "-"
                        })
                    )}
                </>
            );
        }
    }
};

export default FieldWhatsApp;
