import React from "react";
import ItemViewInline from "./ItemView";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        },
        chipTable: {
            borderRadius: 6
        }
    })
);

export type StatusColorType = {
    [K in CompleoShared.Common.JobStatus]: string;
};

export const StatusColor: StatusColorType = {
    approvedRequest: "#ffac33",
    canceled: "#ff3d00",
    disapprovedRequest: "#ff3d00",
    draft: "#9e9e9e",
    finished: "#2196f3",
    open: "#4caf50",
    requestApprovalInProgress: "#795548",
    awaitingCorrectionRequest: "#673ab7",
    requested: "#ffac33",
    suspended: "#78909c",
    awaitingCustomer: "#78909c"
};

const FieldJobStatus = (props: IFieldProps) => {
    const { fieldData, fieldName } = props;
    const classes = useStyles();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const status: CompleoShared.Common.JobStatus = fieldData.status;

    return (
        <Chip
            className={classes.chipCard}
            style={{
                backgroundColor: StatusColor[status],
                color: "white"
            }}
            size="small"
            label={t(`JOBSTATUS_${fieldData.status}`)}
        />
    );
};

export default FieldJobStatus;
