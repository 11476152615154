import React from "react";
import ItemCard from "./ItemCard";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getGroupFromStage } from "Pages/Pipeline/components/helperPipeline";
import { getColorFromPipelineGroupType } from "Pages/Job/JobView/JobKanban/JobKanbanColumn";
import { Icon, Tooltip } from "@material-ui/core";
import useJobContext from "Pages/Job/JobView/useJobContext";
import ChangeStageButtonInsideJobOutsideApplicantView from "Pages/Applicant/ApplicantView/ChangeStageButton/ChangeStageButtonInsideJobOutsideApplicantView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 0,
            borderRadius: 6
        },
        chipTable: {
            borderRadius: 6
        }
    })
);

const FieldPipelineStage = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType } = props;
    const classes = useStyles();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const groupType = getGroupFromStage(fieldData?.jobData?.Stage);
    const color = getColorFromPipelineGroupType(groupType);
    const [data] = useJobContext();
    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === fieldData?.jobData?.PipelineStage
    )[0];

    if (returnType === "card") {
        return (
            <ItemCard label={t(fieldName)}>
                {fieldData.pk && (
                    <ChangeStageButtonInsideJobOutsideApplicantView
                        index={0}
                        ApplicantData={fieldData}
                    />
                )}
                {/* <Chip
                    className={classes.chipCard}
                    style={{
                        backgroundColor: color.mainColor,
                        color: "white"
                    }}
                    size="small"
                    label={stageData?.name}
                /> */}
            </ItemCard>
        );
    } else {
        return (
            <>
                {fieldData.pk && (
                    <ChangeStageButtonInsideJobOutsideApplicantView
                        index={0}
                        ApplicantData={fieldData}
                    />
                )}
            </>
        );
    }
};

export default FieldPipelineStage;
