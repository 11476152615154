import { CompanyExternalContext } from "auth/UnAuthenticated";
import React from "react";

const useExternalHelper = (companyId?: string) => {
    const companyExternal = React.useContext(CompanyExternalContext);

    React.useEffect(() => {
        if (companyId !== companyExternal.value && companyId) {
            companyExternal.update(companyId);
        }
    }, [companyId, companyExternal.value]);
};

export default useExternalHelper;
