import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Edit, Visibility, Add, DeleteForever } from "@material-ui/icons";
import { navigate } from "@gatsbyjs/reach-router";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import useList from "customHooks/useList";
import ApplicantAddToJob from "Pages/Applicant/components/ApplicantAddToJob";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRight: {
            // marginLeft: "auto"
        }
    })
);

interface IProps {
    fieldData: Compleo.IObject;
    hideViewIcon?: boolean;
}

const OtherActions = (props: IProps) => {
    const { fieldData, hideViewIcon = false } = props;
    const { company } = useAuthState();
    const queryClient = useQueryClient();
    const showMessage = useShowMessage();
    const { isFeatureAuthorized } = useAuthorization();
    const canEdit = isFeatureAuthorized("EditApplicant");

    const [listData, listDispatch] = useList();

    const currentPage = listData.filtersInfo?.filters?.pagination?.currentPage;
    const { t } = listData.tempInfo;
    const [addToJobModalOpen, setAddToJobModalOpen] = React.useState(false);
    const keysToInvalidate: string[] = [
        listData.tempInfo.listApiAddress,
        ...listData.tempInfo.useApiKey
    ];
    const classes = useStyles();
    const applicantId = fieldData.pk.split(":")[1];
    const { callDialog } = useGlobalDialog();

    const editJob = () => {
        navigate(`/applicant/edit/${applicantId}`);
    };

    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );
    const openModalApplicant = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalApplicant: {
                open: true,
                currentIndex: index,
                currentPage: currentPage
            }
        });
    };

    const deleteApplicant = (applicantIdParam: string) => {
        callDialog({
            title: t("ELASTICLISTSETTINGS:dialogDeleteApplicant_Title"),
            bodyText: t("ELASTICLISTSETTINGS:dialogDeleteApplicant_Message"),
            agreeButtonText: t("ELASTICLISTSETTINGS:deleteApplicantButtonOk"),
            disagreeButtonText: t("COMPLEOGENERAL_CANCEL"),
            agreeFunction: async () => {
                try {
                    showMessage({
                        message: t(
                            "ELASTICLISTSETTINGS:deleteApplicantInProgress"
                        ),
                        snackBarOrign: {
                            horizontal: "right",
                            vertical: "top"
                        },
                        typeMessage: "warning"
                    });
                    const dataAPI = await apiDirectCall(
                        "/applicant/delete",
                        "post",
                        {
                            applicantId: applicantIdParam,
                            companyId: company?.companyId
                        }
                    );
                    if (dataAPI.status === 200) {
                        if (keysToInvalidate.length) {
                            for (const keyName of keysToInvalidate) {
                                queryClient.invalidateQueries(keyName);
                            }
                        }
                        // listDispatch.setFilters(listData.filtersInfo?.filters);
                        showMessage({
                            message: t(
                                "ELASTICLISTSETTINGS:deleteApplicantDone"
                            ),
                            snackBarOrign: {
                                horizontal: "right",
                                vertical: "top"
                            },
                            timeout: 5000
                        });
                    }
                } catch (ex) {
                    showMessage({
                        message: t("NotIdentifiedProblem"),
                        typeMessage: "error",
                        snackBarOrign: { horizontal: "right", vertical: "top" }
                    });
                }
            },
            disagreeFunction: async () => {}
        });
    };

    return (
        <>
            {!hideViewIcon && (
                <ButtonIconTooltipCompleo
                    className={classes.buttonRight}
                    label={t("viewApplicant")}
                    onClick={() => openModalApplicant()}
                >
                    <Visibility />
                </ButtonIconTooltipCompleo>
            )}
            {canEdit && (
                <ButtonIconTooltipCompleo
                    label={t("editApplicant")}
                    onClick={editJob}
                >
                    <Edit />
                </ButtonIconTooltipCompleo>
            )}
            {canEdit && (
                <ButtonIconTooltipCompleo
                    label={t("addToJob")}
                    onClick={() => setAddToJobModalOpen(true)}
                >
                    <Add />
                </ButtonIconTooltipCompleo>
            )}
            {canEdit && (
                <ButtonIconTooltipCompleo
                    label={t("ELASTICLISTSETTINGS:dialogDeleteApplicant_Title")}
                    onClick={() => deleteApplicant(applicantId)}
                >
                    <DeleteForever />
                </ButtonIconTooltipCompleo>
            )}
            {addToJobModalOpen && (
                <ApplicantAddToJob
                    open={addToJobModalOpen}
                    onClose={() => setAddToJobModalOpen(false)}
                    applicantId={applicantId}
                    currentJobs={fieldData.Jobs}
                    listKeyToInvalidate={keysToInvalidate}
                />
            )}
        </>
    );
};

export default OtherActions;
