import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Divider, List } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import TranslationGrid from "./TranslationGrid";
import TranslationAnswers from "./TranslationAnswers";
import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        rootList: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        textField: {
            margin: theme.spacing(1)
        },
        gridContainer: {},
        gridDescription: {
            padding: "1px"
        },
        gridField: {
            padding: "1px"
        },
        divider: {
            backgroundColor: theme.palette.error.main,
            height: "1px"
        }
    })
);

interface IProps {
    fieldQuestionsName?: string;
    fieldTranslationsName?: string;
    languageObj: Compleo.IObject;
    t: any;
}

const TranslationsDetail = (props: IProps) => {
    const classes = useStyles();
    const { languageObj, fieldQuestionsName, fieldTranslationsName, t } = props;
    const language = languageObj.value;

    // const [field] = useField(fieldTranslationsName || "translations");
    // const [fieldQuestions] = useField(fieldQuestionsName || "questions");
    const mainFieldName = fieldTranslationsName || "translations";
    const { getFieldValue } = useRhfFieldWithoutControllerOrRegister(
        mainFieldName
    );
    const {
        getFieldValue: getFieldQuestionValue
    } = useRhfFieldWithoutControllerOrRegister(
        fieldQuestionsName || "questions"
    );

    const questions = getFieldQuestionValue();
    const ready = questions;

    if (ready) {
        return (
            <List component="nav" className={classes.rootList}>
                <TranslationGrid
                    language={language}
                    localFieldName={`startMessage`}
                    mainFieldName={mainFieldName}
                    label={""}
                    type={t("startMessage")}
                    richText={true}
                    t={t}
                />
                <Divider />
                {questions.map((item: any) => {
                    return (
                        <React.Fragment key={item.id}>
                            <TranslationGrid
                                language={language}
                                localFieldName={`question_${item.id}`}
                                mainFieldName={mainFieldName}
                                label={item.name}
                                type={t("labelQuestion")}
                                t={t}
                            />
                            <Divider />
                            <TranslationAnswers
                                mainFieldName={mainFieldName}
                                answers={item.answers || []}
                                language={language}
                                t={t}
                            />
                        </React.Fragment>
                    );
                })}
            </List>
        );
    } else {
        return <Loading />;
    }
};

export default TranslationsDetail;
