import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography
} from "@material-ui/core";
import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult
} from "react-beautiful-dnd";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    IKanbanCardFieldState,
    KanbanCardSizeType
} from "./SettingsModalCardKanbanFields";

interface IProps {
    filterData: IKanbanCardFieldState;
    setFilterData: React.Dispatch<React.SetStateAction<IKanbanCardFieldState>>;
    kanbanSmallCardItems: number;
    kanbanMediumCardItems: number;
    kanbanBigCardItems: number;
    t: any;
    tJobView: any;
}

interface ICardInfo {
    type: KanbanCardSizeType;
    title: string;
    maxItems: number;
}

const getStateData = (params: IProps, cardSize: KanbanCardSizeType) => {
    const {
        kanbanSmallCardItems,
        kanbanMediumCardItems,
        kanbanBigCardItems,
        t
    } = params;

    const smallCardListTitle = t("KanbanCardSettings_SmallCardList", {
        max: kanbanSmallCardItems
    });
    const mediumCardListTitle = t("KanbanCardSettings_MediumCardList", {
        max: kanbanMediumCardItems
    });
    const bigCardListTitle = t("KanbanCardSettings_BigCardList", {
        max: kanbanBigCardItems
    });

    switch (cardSize) {
        case "small":
            const returnDataSmall: ICardInfo = {
                maxItems: kanbanSmallCardItems,
                title: smallCardListTitle,
                type: cardSize
            };
            return returnDataSmall;
        case "medium":
            const returnDataMedium: ICardInfo = {
                maxItems: kanbanMediumCardItems,
                title: mediumCardListTitle,
                type: cardSize
            };
            return returnDataMedium;

        default:
            const returnDataBig: ICardInfo = {
                maxItems: kanbanBigCardItems,
                title: bigCardListTitle,
                type: cardSize
            };
            return returnDataBig;
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        title: {
            fontWeight: 600,
            marginTop: theme.spacing(2)
        },
        radioGroup: {
            marginTop: theme.spacing(3)
        },
        radioGroupLabel: {
            fontSize: 14
        }
    })
);

export type localNames = "primary" | "hidden";

const SettingsModalCardKanbanFieldsList = (props: IProps) => {
    const classes = useStyles();
    const { filterData, setFilterData, t, tJobView } = props;

    const filterList = filterData.fieldsDefinition.filter(
        (item) => item.local === "primary"
    );
    const hiddenListTitle = t("ELASTICLISTSETTINGS:CardSettings_HiddenList");
    const defaultLocalState = getStateData(props, filterData.cardSize);

    const [cardMainInfo, setCardMainInfo] = React.useState<ICardInfo>(
        defaultLocalState
    );
    const hiddenList = filterData.fieldsDefinition.filter(
        (item) => item.local !== "primary"
    );

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (destination.droppableId === source.droppableId) {
                const listId = destination.droppableId;
                if (destination.index === source.index) {
                    return;
                }
                const items = [...filterData.fieldsDefinition]
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => item.local === listId);

                const otherItems = [...filterData.fieldsDefinition].filter(
                    (item) => item.local !== listId
                );
                const itemToMove = items.filter(
                    (item) => item.name === draggableId
                )[0];
                items.splice(source.index, 1);
                items.splice(destination.index, 0, itemToMove);
                const orderedItems = items.map((item, index) => {
                    return { ...item, order: index + 1 };
                });
                setFilterData({
                    ...filterData,
                    cardSize: cardMainInfo.type,
                    fieldsDefinition: [...orderedItems, ...otherItems]
                });
            } else {
                const itemsDestination =
                    [...filterData.fieldsDefinition]
                        .sort((a, b) => a.order - b.order)
                        .filter(
                            (item) => item.local === destination.droppableId
                        ) || [];
                const itemsSource = [...filterData.fieldsDefinition]
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => item.local === source.droppableId);

                // Cancel the drop if exceed the mas items per list
                switch (destination.droppableId) {
                    case "primary":
                        if (filterList.length >= cardMainInfo.maxItems) {
                            return;
                        }
                        break;
                }

                const itemToMove = itemsSource.filter(
                    (item) => item.name === draggableId
                )[0];
                itemToMove.local = destination.droppableId;

                // Remove item from source list
                itemsSource.splice(source.index, 1);

                // Add item from source list
                itemsDestination.splice(destination.index, 0, itemToMove);

                const orderedSourceItems = itemsSource.map((item, index) => {
                    return { ...item, order: index + 1 };
                });
                const orderedDestinationItems = itemsDestination.map(
                    (item, index) => {
                        return { ...item, order: index + 1 };
                    }
                );
                setFilterData({
                    ...filterData,
                    cardSize: cardMainInfo.type,
                    fieldsDefinition: [
                        ...orderedSourceItems,
                        ...orderedDestinationItems
                    ]
                });
            }
        }
    };

    const changeFilterData = (maxItems: number, size: KanbanCardSizeType) => {
        let totalPrimary = 0;
        const newFilterData = [...filterData.fieldsDefinition].map(
            (item, index) => {
                if (item.local !== "primary") {
                    return { ...item, order: (item.order || 0) + 1000 };
                } else {
                    if (totalPrimary >= maxItems) {
                        return { ...item, local: "hidden" };
                    } else {
                        totalPrimary = totalPrimary + 1;
                        return item;
                    }
                }
            }
        );
        newFilterData.sort((a, b) => {
            return a.order - b.order;
        });
        // const totalPrimaryFinalList = newFilterData.filter((item) => item.local === "primary").length;
        if (totalPrimary < maxItems) {
            const diff = maxItems - totalPrimary;
            const onlyHidden = newFilterData.filter(
                (item) => item.local === "hidden"
            );
            for (let index = 0; index < diff; index++) {
                onlyHidden[index].local = "primary";
            }
        }
        const sorted = newFilterData.map((item, index) => {
            return { ...item, order: index + 1 };
        });
        setFilterData({
            ...filterData,
            cardSize: size,
            fieldsDefinition: sorted
        });
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: KanbanCardSizeType = (event.target as HTMLInputElement)
            .value as KanbanCardSizeType;
        const newState = getStateData(props, value);
        changeFilterData(newState.maxItems, value);
        setCardMainInfo(newState);
    };

    const handlePhotoChange = () => {
        setFilterData({
            ...filterData,
            showPhoto: !filterData.showPhoto
        });
    };
    const cardSizeList: KanbanCardSizeType[] = ["small", "medium", "big"];
    return (
        <>
            <FormControl component="fieldset" className={classes.radioGroup}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={filterData.showPhoto}
                            onChange={() => handlePhotoChange()}
                            color="primary"
                        />
                    }
                    label={tJobView(
                        "ModalMainMenu_cardKanbanFieldsShowPicture"
                    )}
                />
                <FormLabel
                    component="legend"
                    className={classes.radioGroupLabel}
                >
                    {t("KanbanCardSettings_ChooseCardType")}
                </FormLabel>
                <RadioGroup
                    aria-label={t("KanbanCardSettings_ChooseCardType")}
                    value={cardMainInfo.type}
                    onChange={handleChange}
                    row
                >
                    {cardSizeList.map((itemSize) => (
                        <FormControlLabel
                            key={itemSize}
                            value={itemSize}
                            control={<Radio size="small" />}
                            label={t(`KanbanCardSettings_CardType_${itemSize}`)}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <List component="nav" className={classes.root}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <ListCard
                        classes={classes}
                        listItems={filterList}
                        maxItems={cardMainInfo.maxItems}
                        listTitle={cardMainInfo.title}
                        t={t}
                        showEyeIcon={true}
                        droppableId="primary"
                    />
                    <ListCard
                        classes={classes}
                        listItems={hiddenList}
                        maxItems={-1}
                        listTitle={hiddenListTitle}
                        t={t}
                        showEyeIcon={false}
                        droppableId="hidden"
                    />
                </DragDropContext>
            </List>
        </>
    );
};
interface IItemProps {
    item: Compleo.IObject;
    index: number;
    t: any;
    disabled: boolean;
    showEyeIcon?: boolean;
}

const Item = (props: IItemProps) => {
    const { item, index, t, disabled, showEyeIcon = false } = props;

    return (
        <Draggable
            draggableId={item.name}
            index={index}
            isDragDisabled={disabled}
        >
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItem
                        button
                        dense
                        alignItems="center"
                        style={{
                            margin: 4
                        }}
                    >
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon color="action">drag_indicator</Icon>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={t(item.name)} />
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon
                                    color={showEyeIcon ? "primary" : "action"}
                                >
                                    {showEyeIcon
                                        ? "visibility"
                                        : "visibility_off"}
                                </Icon>
                            </Tooltip>
                        </ListItemIcon>
                    </ListItem>
                    <Divider />
                </div>
            )}
        </Draggable>
    );
};

interface IListCard {
    classes: any;
    listTitle: string;
    t: any;
    listItems: Compleo.IObject[];
    maxItems: number;
    showEyeIcon: boolean;
    droppableId: string;
}
const ListCard = (props: IListCard) => {
    const {
        classes,
        listTitle,
        t,
        listItems,
        maxItems,
        showEyeIcon,
        droppableId
    } = props;

    return (
        <>
            <Typography variant="body1" className={classes.title}>
                {listTitle}
            </Typography>
            <Droppable
                droppableId={droppableId}
                direction="vertical"
                type="forms"
            >
                {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                ) => (
                    <div
                        style={{
                            minHeight: "30px",
                            backgroundColor:
                                snapshot.isDraggingOver &&
                                listItems.length >= maxItems &&
                                maxItems !== -1
                                    ? "red"
                                    : undefined
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {listItems
                            .sort((a, b) => a.order - b.order)
                            .map((item, index) => (
                                <React.Fragment key={item.name}>
                                    <Item
                                        item={item}
                                        index={index}
                                        t={t}
                                        disabled={false}
                                        showEyeIcon={showEyeIcon}
                                    />
                                </React.Fragment>
                            ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </>
    );
};

export default SettingsModalCardKanbanFieldsList;
