import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";

const useActionsTable = (
    setOpenModalAuto: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            operationId: string;
            refreshListFunction: () => void;
            itemId?: string | undefined;
        }>
    >,
    t: any
) => {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            (rowData: any) => {
                let hidden = true;
                if (
                    rowData.status?.value === "1" ||
                    rowData.status?.value === "requested"
                ) {
                    hidden = false;
                }

                return {
                    icon: "edit",
                    tooltip: t("COMPLEOGENERAL_Edit"),
                    onClick: (event: any, rowData: any) => {
                        navigate(
                            `/applicant/newparsed/verificationData/${
                                rowData.pk.split(":")[1]
                            }/${rowData.skGS1pk.split(":")[1]}`
                        );
                    },
                    hidden: hidden
                };
            },
            (rowData: any) => {
                let hidden = true;
                if (
                    rowData.status?.value === "1" ||
                    rowData.status?.value === "requested"
                ) {
                    hidden = false;
                }

                return {
                    icon: "check",
                    tooltip: t("btnProcess"),
                    onClick: (event: any, rowData: any) => {
                        setOpenModalAuto({
                            open: true,
                            operationId: rowData.pk.split(":")[1],
                            refreshListFunction: refreshListFunction,
                            itemId: rowData.skGS1pk.split(":")[1]
                        });
                    },
                    hidden: hidden
                };
            }
        ];

        return result;
    };
};

export default useActionsTable;
