import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import {
    IJobViewStages,
    IStagesOnlyTotal
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import { useAuthState } from "_ReactContext/AuthContext";
import useUpdateCard from "../../useUpdateCard";
import { getTotalStage } from "../../helper";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useRefreshKanban from "../../useRefreshKanban";

type actionsType = "disqualify" | "requalify" | "changeStage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

const getStages = (
    allStages: IJobViewStages[],
    stageData: IJobViewStages,
    action: actionsType
) => {
    let stages = allStages;
    switch (action) {
        case "disqualify":
            stages = allStages.filter((i) => i.type === "rejected");
            break;
        case "requalify":
            stages = allStages.filter((i) => i.type !== "rejected");
            break;
        case "changeStage":
            if (stageData.type === "rejected") {
                stages = allStages.filter((i) => i.type === "rejected");
            } else {
                stages = allStages.filter((i) => i.type !== "rejected");
            }
            break;
        default:
            break;
    }

    const stageDataList = stages
        .map((stage) => {
            return {
                label: stage.name,
                value: stage.id
            };
        })
        .filter((i) => i.value !== stageData?.id);
    return stageDataList;
};

const getFieldsToHide = (action: actionsType) => {
    switch (action) {
        case "requalify":
            return ["disqualifyReason"];
        case "changeStage":
            return ["disqualifyReason"];
        default:
            return [];
    }
};

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    allStages: IJobViewStages[];
    stageData: IJobViewStages;
    t: any;
    ApplicantData: Compleo.IObject;
    currentIndex: number;
    stagesOnlyTotal: IStagesOnlyTotal[];
    outsideKanban?: boolean;
    listKeyToInvalidate?: string[];
    changeStage?: boolean;
    fnAfterUpdate?: any;
}

const JobKanbanApplicantChangeStageModal = (props: IPropsItemFilter) => {
    const {
        onClose,
        open,
        allStages,
        stageData,
        t: tJobView,
        ApplicantData,
        currentIndex,
        stagesOnlyTotal,
        outsideKanban = false,
        listKeyToInvalidate = [],
        changeStage = false,
        fnAfterUpdate
    } = props;

    const formikRef = React.useRef(null);
    const classes = useStyles();
    // const { changeCardStateData } = useUpdateCard();
    const showMessage = useShowMessage();
    const queryClient = useQueryClient();
    // const updateAllInfo = useRefreshKanban();

    const { company } = useAuthState();
    const action: actionsType = changeStage
        ? "changeStage"
        : stageData?.type !== "rejected"
        ? "disqualify"
        : "requalify";

    const module = "ELASTICLISTSETTINGSBULKADDTOSTAGE";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const language = i18n.languages[0];

    const [initialValues] = useValuesFromSource(metadata, false, {
        newStagePosition: { value: "end", label: t("endStageOption") },
        disqualifyReason: {
            value: "CP0",
            label: language === "pt-BR" ? "Não informado" : "Not informed"
        }
    });
    const ready = readyTranslation && metadata.status === "success";
    const stages = getStages(allStages, stageData, action);
    const fieldsToHide = getFieldsToHide(action);
    const customList: Compleo.ListDefinitionType[] = [];
    if (readyTranslation) {
        const listPositionData = [
            { label: t("beginStageOption"), value: "begin" },
            { label: t("endStageOption"), value: "end" }
        ];
        customList.push({
            fieldName: "newStagePosition",
            data: listPositionData,
            valueName: "value",
            labelName: "label"
        });
    }
    if (stages.length) {
        customList.push({
            fieldName: "stage",
            data: stages,
            valueName: "value",
            labelName: "label"
        });
        initialValues.stage = stages[0];
    }

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        debugger;

        const applicantId = ApplicantData.pk.split(":")[1];
        const addToEndOfList = values.newStagePosition?.value === "end";
        const newStageId = values.stage?.value;
        const dataPost: Compleo.IObject = {
            companyId: company.companyId,
            jobId: ApplicantData.jobData.JobId,
            applicantId: applicantId,
            stageId: newStageId,
            addToEndOfList: addToEndOfList,
            oldStageId: stageData?.id,
            priorityObj: { orderData: [] },
            newIndex: 0
        };

        if (action === "disqualify") {
            dataPost.disqualifyReason = values.disqualifyReason;
            dataPost.disqualifyApplicants = true;
        }
        if (action === "requalify") {
            dataPost.requalify = true;
        }
        const postAddress = `/job/editapplicantjobstage`;

        try {
            const dataAPI = await apiDirectCall(postAddress, "post", dataPost);
            if (dataAPI.status === 200) {
                if (fnAfterUpdate) {
                    const destinationIndex = addToEndOfList
                        ? getTotalStage(stagesOnlyTotal, [newStageId])
                        : 0;
                    await fnAfterUpdate({
                        outsideKanban,
                        destinationIndex,
                        applicantId: ApplicantData.pk,
                        stageData,
                        currentIndex,
                        newStageId
                    });
                }

                if (listKeyToInvalidate.length) {
                    for (const keyName of listKeyToInvalidate) {
                        queryClient.invalidateQueries(keyName);
                    }
                }
            }
        } catch (ex) {
            showMessage({
                message: t("NotIdentifiedProblem"),
                snackBarOrign: { vertical: "top", horizontal: "right" },
                timeout: 1000 * 5,
                typeMessage: "error"
            });
            return ex.response;
        }
        onClose();
        return retorno;
    };

    const handleSave = () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const title = changeStage
        ? tJobView("ModalChangeStage_title")
        : action === "disqualify"
        ? tJobView("ModalDisqualify_title")
        : tJobView("ModalRequalify_title");

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef,
        customLists: customList,
        fieldsToHide: fieldsToHide
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={title}
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary">
                    {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobKanbanApplicantChangeStageModal;
