import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export type menuOptionType = { text: string; fnToRun: any };
export interface useMenuFormGroupType {
    menuOptions: menuOptionType[];
    id: string;
}
export type MenuOptionTypeReturn = {
    MenuComponent: () => JSX.Element;
    handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const useMenuFormGroup: (
    props: useMenuFormGroupType
) => MenuOptionTypeReturn = (props: useMenuFormGroupType) => {
    const { menuOptions, id } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (fnToRun?: any) => {
        if (fnToRun) {
            fnToRun();
        }
        setAnchorEl(null);
    };

    const MenuComponent = () => {
        return (
            <div>
                <Menu
                    id={id}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    {menuOptions.map((option: menuOptionType) => (
                        <MenuItem
                            key={option.text}
                            onClick={() => handleClick(option.fnToRun)}
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    };
    return { MenuComponent, handleMenuClick };
};

export default useMenuFormGroup;
