import { apiDirectCall } from "customHooks/useApi/api";
import _ from "lodash";

const ApplicantExistsValidation = async (values: any) => {
    const paramsEmailExists = {
        email: values.email,
        cpf: values.cpf,
        companyId: values.companyId
    };

    const urlEmail = "/applicant/validateduplicateapplicants"; //genericValidationAPI
    const validationEmail = await apiDirectCall(
        urlEmail,
        "post",
        paramsEmailExists
    );
    console.log("validationEmail", validationEmail);
    const validEmailExists = validationEmail?.data?.data;
    const applicantExist = !_.isEmpty(validEmailExists);

    // console.log(validEmailExists);
    return { result: applicantExist, applicantData: validEmailExists };
};

export default ApplicantExistsValidation;
