import React from "react";

const useCustomLists: (
    listsDB?: Compleo.IObject
) => [Compleo.ListDefinitionType[], boolean] = (listsDB?: Compleo.IObject) => {
    const [listDefinitionState, setListDefinitionState] = React.useState<{
        ListDefinition: Compleo.ListDefinitionType[];
        ready: boolean;
    }>({ ListDefinition: [], ready: false });

    React.useEffect(() => {
        if (!listDefinitionState.ready && listsDB) {
            const listDefinition = [...listDefinitionState.ListDefinition];
            listDefinition.push({
                fieldName: "customer",
                data: listsDB.customers,
                valueName: "itemValue",
                labelName: "itemText",
                childrenFilterFieldName: "contact"
            });
            listDefinition.push({
                fieldName: "contact",
                data: listsDB.contacts,
                valueName: "itemValue",
                labelName: "itemText",
                parentFilterField: {
                    fieldFilter: "customerId",
                    parentFieldName: "customer",
                    parentIdFieldFilter: "value"
                }
            });
            listDefinition.push({
                fieldName: "branch",
                data: listsDB.branchs,
                valueName: "itemValue",
                labelName: "itemText"
            });
            listDefinition.push({
                fieldName: "recruiters",
                data: listsDB.recruiters,
                valueName: "itemValue",
                labelName: "itemText"
            });
            listDefinition.push({
                fieldName: "mainRecruiter",
                data: listsDB.recruiters,
                valueName: "itemValue",
                labelName: "itemText"
            });
            listDefinition.push({
                fieldName: "requester",
                data: listsDB.recruiters,
                valueName: "itemValue",
                labelName: "itemText"
            });
            listDefinition.push({
                fieldName: "otherRequesters",
                data: listsDB.recruiters,
                valueName: "itemValue",
                labelName: "itemText"
            });
            setListDefinitionState({
                ListDefinition: listDefinition,
                ready: true
            });
        }
    }, [listsDB]);

    return [listDefinitionState.ListDefinition, listDefinitionState.ready];
};
export default useCustomLists;
