import React from "react";
import useJobContext from "../useJobContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip, Typography } from "@material-ui/core";
import {
    getApplicantsByStage,
    getTotalStage,
    getTotalFilteredApplicantsByStage,
    getStateFromDB,
    getKanbanCardHeight
} from "./helper";
import JobKanbanApplicant from "./JobKanbanApplicant/JobKanbanApplicant";
import JobKanbanApplicantLoading from "./JobKanbanApplicant/JobKanbanApplicantLoading";
import {
    Draggable,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import { getGroupFromStage } from "Pages/Pipeline/components/helperPipeline";
import { MoreVert, FiberManualRecord, FlashOn } from "@material-ui/icons";
import {
    indigo,
    lightBlue,
    grey,
    blue,
    green,
    red
} from "@material-ui/core/colors";
import { FixedSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import _ from "lodash";
import { IMainStateAction } from "../useJobContext/JobViewTypes";
import useDebounce from "customHooks/useDebounce";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import JobKanbanColumnMenu from "./ColumnMenu/JobKanbanColumnMenu";
import { apiDirectCall } from "customHooks/useApi";
import {
    KanbanCardSizeType,
    KanbanCardSessionName,
    IKanbanCardFieldState
} from "./JobKanbanCardFields/SettingsModalCardKanbanFields";
import useGlobalValues from "_ReactContext/GlobalValues";
import ApplicantViewModalContainerKanban from "Pages/Applicant/ApplicantView/ApplicantViewModalContainerKanban";
import { ApplicantViewModulesType } from "Pages/Applicant/ApplicantView/useApplicantViewContext/ApplicantViewTypes";

const Row = function Row(props: any) {
    const { data, index, style } = props;
    const {
        items,
        stageId,
        CardDef,
        cardKanbanFields,
        openApplicantView,
        readonly = false
    } = data;
    const ApplicantData = items[index];

    // We are rendering an extra item for the placeholder
    if (index >= items.length) {
        return null;
    }

    if (ApplicantData?._loaded) {
        return (
            <Draggable
                draggableId={ApplicantData.pk}
                index={index}
                key={ApplicantData.pk}
                isDragDisabled={readonly}
            >
                {(provided, snapshot) => (
                    <div style={style}>
                        <JobKanbanApplicant
                            provided={provided}
                            ApplicantData={ApplicantData}
                            index={index}
                            snapshot={snapshot}
                            stageId={stageId}
                            CardDef={CardDef}
                            cardKanbanFields={cardKanbanFields}
                            openApplicantView={openApplicantView}
                        />
                    </div>
                )}
            </Draggable>
        );
    } else {
        return (
            <div style={style} key={ApplicantData.pk}>
                <JobKanbanApplicantLoading index={index} CardDef={CardDef} />
            </div>
        );
    }
};

const getColumnClassName = (
    isDropDisabled: boolean,
    oneToLimit: boolean,
    limitItems: boolean,
    classes: any,
    snapshot: DroppableStateSnapshot
) => {
    if (isDropDisabled) {
        return classes.columnItemsDisabledColumn;
    }
    if (oneToLimit && snapshot.isDraggingOver) {
        return classes.columnItemsLimitItems;
    }
    if (limitItems && snapshot.isDraggingOver) {
        return classes.columnItemsLimitItems;
    }
    return classes.columnItems;
};

export const getColorFromPipelineGroupType = (
    groupType: "new" | "hired" | "rejected" | "others" | null
) => {
    let returnColor = "#f4f6f8";
    const normalNumber = 600;

    switch (groupType) {
        case "new":
            // backgroundColor = grey[100];
            returnColor = grey[normalNumber];
            break;
        case "hired":
            // backgroundColor = green[lightNumber];
            returnColor = green[normalNumber];
            break;
        case "rejected":
            // backgroundColor = red[lightNumber];
            returnColor = red[normalNumber];
            break;
        default:
            // backgroundColor = blue[lightNumber];
            returnColor = blue[normalNumber];
            break;
    }
    return { mainColor: returnColor };
};

const useStyles = (
    groupType: "new" | "hired" | "rejected" | "others" | null
) => {
    let backgroundColor = "#f4f6f8";
    // let iconColor = "#f4f6f8";
    let fontColor = grey[700];
    // let
    const lightNumber = 50;
    // const normalNumber = 600;

    // switch (groupType) {
    //     case "new":
    //         // backgroundColor = grey[100];
    //         iconColor = grey[normalNumber];
    //         break;
    //     case "hired":
    //         // backgroundColor = green[lightNumber];
    //         iconColor = green[normalNumber];
    //         break;
    //     case "rejected":
    //         // backgroundColor = red[lightNumber];
    //         iconColor = red[normalNumber];
    //         break;
    //     default:
    //         // backgroundColor = blue[lightNumber];
    //         iconColor = blue[normalNumber];
    //         break;
    // }
    const iconColor = getColorFromPipelineGroupType(groupType).mainColor;

    return makeStyles((theme: Theme) => {
        const columnItems = {
            flexGrow: 1,
            flexBasis: 0,
            "&::-webkit-scrollbar": {
                width: "0.4em"
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey"
            }
        };

        const boxTitle = {
            margin: 0,
            padding: 0,
            display: "flex",
            // backgroundColor: backgroundColor,
            minHeight: 40,
            alignItems: "center"
        };
        const columnTitle = {
            padding: 0,
            marginLeft: theme.spacing(0.7)
        };

        const columnDotted = {
            borderWidth: 4,
            borderStyle: "dotted",
            borderColor: blue[300],
            height: "100%"
        };

        return createStyles({
            column: {
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                // border: "lightgrey",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: grey[300],

                // borderRadius: "2px",
                maxWidth: 300,
                minWidth: 300,
                marginTop: theme.spacing(1),
                // margin: theme.spacing(1),
                marginLeft: 0,
                marginRight: 0,
                backgroundColor: grey[50]
            },
            boxTitle: {
                ...boxTitle,
                backgroundColor: backgroundColor
            },
            boxTitleRed: {
                ...boxTitle,
                backgroundColor: red[400]
            },
            columnTitle: {
                ...columnTitle,
                color: fontColor
            },
            columnTitleRed: {
                ...columnTitle,
                color: "white"
            },
            columnIcon: {
                padding: 0,
                marginLeft: theme.spacing(0.7),
                color: iconColor
            },
            columnIconAutomation: {
                padding: 0,
                marginLeft: theme.spacing(0.7),
                color: grey[700]
            },
            columnItems: {
                ...columnItems
            },
            columnItemsDisabledColumn: {
                ...columnItems,
                backgroundColor: grey[300]
            },
            columnItemsLimitItems: {
                ...columnItems,
                backgroundColor: red[500]
            },
            columnDotted: {
                ...columnDotted
            },
            columnDottedLimitItems: {
                ...columnDotted,
                backgroundColor: red[500]
            },
            actionsIcon: {
                padding: theme.spacing(0.5)
            },
            grow: {
                flexGrow: 1
            }
        });
    });
};
interface ILocalAction {
    pageSize: number;
    fromValue: number;
}
interface IProps {
    ColumnData: Compleo.IObject;
}

const JobKanbanColumn = (props: IProps) => {
    const [data, dispatchJobContext] = useJobContext();
    const infiniteLoaderRef = React.useRef(null);
    const reactWindowRef = React.useRef(null);
    const [applicantViewOpen, setApplicantViewOpen] = React.useState(false);
    const [applicantViewIndex, setApplicantViewIndex] = React.useState(0);

    const { ColumnData } = props;
    const groupType = getGroupFromStage(ColumnData);
    const [globalValues] = useGlobalValues();
    const CardDef: IKanbanCardFieldState = globalValues.userdb[
        KanbanCardSessionName
    ] || { cardSize: "small", fieldsDefinition: [], showPhoto: true };
    const cardHeight = getKanbanCardHeight(CardDef);
    const metadata = data.kanbanApplicantListMetadata;
    const { cardKanbanFields } =
        metadata.response?.data?.ElasticSearchListDefinition || {};

    const classes = useStyles(groupType)();
    const [action, setAction] = React.useState<ILocalAction | null>(null);
    const currentAction: ILocalAction | null = useDebounce(action, 500);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const stageId = ColumnData.id;
    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === stageId
    )[0];
    const stageConfig = (data.LocalStateData.stagesConfig || {})[stageId] || {};
    React.useEffect(() => {
        if (currentAction !== null) {
            const currentFilter = data.fullFilter;
            currentFilter.pagination = {
                currentPage: 1,
                pageSize: currentAction.pageSize,
                fromValue: currentAction.fromValue
            };
            currentFilter.otherGenericParams = {
                ...currentFilter.otherGenericParams,
                filterStageIds: [stageId]
            };

            apiDirectCall(
                data.apiKanbanListAddress,
                "post",
                currentFilter
            ).then((apiData) => {
                const newStateData = getStateFromDB({
                    dbData: apiData,
                    stateData: data.LocalStateData.ApplicantsData,
                    orderData: data.LocalStateData.orderData,
                    onlyUpdateStagesFromDB: true
                });
                dispatchJobContext.dispatchGeneralPageInfo({
                    payload: {
                        ApplicantsData: newStateData,
                        filter: currentFilter
                    },
                    type: "update"
                });
            });
            setAction(null);
        }
    }, [currentAction]);

    // stageData.
    const totalApplicantsStage = getTotalStage(
        data.LocalStateData.stagesOnlyTotal,
        [stageId]
    );
    const { t } = data;
    const minimumPageSize = data.LocalStateData.minimumPageSize;

    const applicants = getApplicantsByStage(data, stageId);
    const totalStage = getTotalFilteredApplicantsByStage(data, [stageId]);
    const totalFiltered = totalStage === -1 ? totalApplicantsStage : totalStage;

    const totalReadyData =
        totalFiltered === totalApplicantsStage
            ? `${totalApplicantsStage}`
            : `${totalFiltered} ${t("ofText")} ${totalApplicantsStage}`;

    const isFilterApplied = totalFiltered < totalApplicantsStage;
    const itemsLabel = totalReadyData;
    const isItemLoaded = (index: number) => applicants[index]?._loaded === true;
    const loadMoreItems = (startIndex: number, stopIndex: number) => {
        if (data.LocalStateData.firstRenderFinished) {
            const pageSize = stopIndex + 1 - startIndex;
            const fromValue = startIndex;
            setAction({ fromValue: fromValue, pageSize: pageSize });
        }
        return null;
    };

    const totalItems = data.LocalStateData.orderData.length ? totalFiltered : 0;
    const isPriorityDisabled = stageConfig.currentSort?.name
        ? stageConfig.currentSort?.name !== "_priority"
        : false;

    const limitItems = stageData?.maxItensKanban
        ? totalApplicantsStage > stageData?.maxItensKanban
        : false;

    const oneToLimit = stageData?.maxItensKanban
        ? totalApplicantsStage === stageData?.maxItensKanban
        : false;

    const isDropDisabled = isPriorityDisabled; // &&

    const stageWithAutomation =
        Object.keys(stageData?.actions || {}).length > 0;
    // data.LocalStateData.moveCardProps?.sourceStage === stageId;

    const openApplicantView = (index: number) => {
        setApplicantViewIndex(index);
        setApplicantViewOpen(true);
    };

    const paramModuleName: ApplicantViewModulesType =
        data.module === "JOBREQUESTVIEW"
            ? "APPLICANTVIEWREQUESTER"
            : "APPLICANTVIEW";

    return (
        <Box className={classes.column}>
            <Box
                className={!limitItems ? classes.boxTitle : classes.boxTitleRed}
            >
                {stageWithAutomation && (
                    <Tooltip title={t("KanbanTooltip_automationEnabled")}>
                        <FlashOn
                            className={classes.columnIconAutomation}
                            fontSize="small"
                        />
                    </Tooltip>
                )}

                <FiberManualRecord
                    className={classes.columnIcon}
                    fontSize="small"
                />
                <Typography
                    className={
                        !limitItems
                            ? classes.columnTitle
                            : classes.columnTitleRed
                    }
                    variant="body2"
                    component="span"
                >
                    {`${ColumnData.name} ${itemsLabel}`}
                </Typography>
                {stageData?.maxItensKanban && (
                    <Typography
                        className={
                            !limitItems
                                ? classes.columnTitle
                                : classes.columnTitleRed
                        }
                        variant="caption"
                        component="span"
                    >
                        {`Max ${stageData.maxItensKanban}`}
                    </Typography>
                )}
                <div className={classes.grow} />
                <ButtonIconTooltipCompleo
                    label={t("KanbamColumMenuHelp")}
                    onClick={openMenu}
                    className={classes.actionsIcon}
                >
                    <MoreVert fontSize="small" />
                </ButtonIconTooltipCompleo>
            </Box>
            <Droppable
                droppableId={stageId}
                mode="virtual"
                isDropDisabled={isDropDisabled}
                renderClone={(provided, snapshot, rubric) => (
                    <JobKanbanApplicant
                        provided={provided}
                        ApplicantData={applicants[rubric.source.index]}
                        index={rubric.source.index}
                        snapshot={snapshot}
                        stageId={stageId}
                        CardDef={CardDef}
                        cardKanbanFields={cardKanbanFields}
                        openApplicantView={openApplicantView}
                    />
                )}
            >
                {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                ) => {
                    const disableColumn =
                        data.LocalStateData.moveCardProps?.sourceStage &&
                        snapshot.isDraggingOver &&
                        stageId !==
                            data.LocalStateData.moveCardProps?.sourceStage &&
                        isFilterApplied;

                    const className = getColumnClassName(
                        isDropDisabled,
                        oneToLimit,
                        limitItems,
                        classes,
                        snapshot
                    );

                    const classNameDotted =
                        oneToLimit || limitItems
                            ? classes.columnDottedLimitItems
                            : classes.columnDotted;

                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={className}
                        >
                            {disableColumn ? (
                                <div className={classNameDotted}></div>
                            ) : (
                                <InfiniteLoader
                                    isItemLoaded={isItemLoaded}
                                    itemCount={totalItems}
                                    //@ts-ignore
                                    loadMoreItems={loadMoreItems}
                                    minimumBatchSize={minimumPageSize}
                                    ref={infiniteLoaderRef}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <AutoSizer>
                                            {({ height, width }: any) => (
                                                <FixedSizeList
                                                    className={
                                                        classes.columnItems
                                                    }
                                                    height={height}
                                                    itemCount={totalItems + 1}
                                                    itemSize={
                                                        cardHeight.reactWindowHeightParam
                                                    }
                                                    width={width}
                                                    outerRef={provided.innerRef}
                                                    itemData={{
                                                        items: applicants,
                                                        stageId: stageId,
                                                        CardDef: CardDef,
                                                        cardKanbanFields: cardKanbanFields,
                                                        openApplicantView: openApplicantView,
                                                        readonly: data.readonly
                                                    }}
                                                    onItemsRendered={
                                                        onItemsRendered
                                                    }
                                                    ref={(list) => {
                                                        // @ts-ignore
                                                        ref(list);

                                                        const currentRef: any = reactWindowRef;
                                                        currentRef.current = list;
                                                    }}
                                                >
                                                    {Row}
                                                </FixedSizeList>
                                            )}
                                        </AutoSizer>
                                    )}
                                </InfiniteLoader>
                            )}
                        </div>
                    );
                }}
            </Droppable>
            <JobKanbanColumnMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                t={t}
                stageId={stageId}
            />
            {applicantViewOpen && (
                <ApplicantViewModalContainerKanban
                    navigationInfo={{
                        fieldArray: applicants,
                        reactWindowRef: reactWindowRef,
                        index: applicantViewIndex
                    }}
                    open={applicantViewOpen}
                    onClose={() => setApplicantViewOpen(false)}
                    jobDataInsideJob={{
                        jobId: data.JobId,
                        jobData: data.Job,
                        jobDataContext: data
                    }}
                    module={paramModuleName}
                />
            )}
        </Box>
    );
};

const propsAreEqual = (
    prevProps: Readonly<IProps>,
    nextProps: Readonly<IProps>
) => {
    const isEqual = _.isEqual(prevProps.ColumnData, nextProps.ColumnData);
    return isEqual;
};

export default React.memo(JobKanbanColumn, propsAreEqual);
