import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import { Chip, Grid, Paper, Typography } from "@material-ui/core";
import CustomSearchModal from "./CustomSearchModal";
import AdvancedSearchModal from "./AdvancedSearchModal";
import { verifyFilterIsEnabled } from "./helperCustomSearch";
import { getCustomSearchOrdered } from "../ListUtil";
import useGlobalValues from "_ReactContext/GlobalValues";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(0.5),
            margin: 0
        },
        chip: {
            margin: theme.spacing(0.5)
        },
        filtersText: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
            // fontWeight: 600
        }
    })
);

interface IProps {}

const CustomSearch = (props: IProps) => {
    const classes = useStyles();
    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const [listData, listDispatch] = useList();
    const orderData =
        globalValues.userdb[`filtersOrder_${listData.tempInfo.moduleName}`];
    const { t } = listData.tempInfo;
    const { filters } = listData.filtersInfo;
    const { setFilters, setLocalInfo } = listDispatch;
    const otherFilters =
        listData.definitionInfo.elasticDefData?.data?.otherFilters;

    const otherFilterNames = getCustomSearchOrdered(
        otherFilters || {},
        orderData
    );

    const { otherSettings } =
        listData.definitionInfo.elasticDefData?.data || {};
    const maxItemsToShow = otherSettings?.otherFiltersMaxItems || 3;
    const [modalInfo, setModalInfo] = React.useState<{
        open: boolean;
        filterName: string;
    }>({ open: false, filterName: "" });
    const [advancedModalOpen, setAdvancedModalOpen] = React.useState(false);

    const handleClose = () => {
        setModalInfo({ ...modalInfo, open: false });
    };

    const handleOpen = (itemName: string) => {
        setModalInfo({ filterName: itemName, open: true });
    };

    const handleDelete = (itemName: string) => {
        const newValue: Compleo.IObject = filters.customSearch || {};
        delete newValue[itemName];
        setFilters({
            ...filters,
            customSearch: { ...newValue }
        });
    };

    const handleDeleteAdvancedSearch = () => {
        const newFiltersAdvancedSearch = { ...filters };
        newFiltersAdvancedSearch.advancedSearch = {};
        setFilters({ ...newFiltersAdvancedSearch });
        setLocalInfo({ ...listData.localInfo, localMetadataReturn: [] });
    };

    const advancedFilterNames = Object.keys(
        filters?.advancedSearch?.values || {}
    ).filter((item) => !["companyId", "searchType"].includes(item));

    return (
        <Grid container>
            <Grid item>
                <Paper component="ul" className={classes.root} elevation={0}>
                    {otherFilterNames.length > 0 && (
                        <Typography className={classes.filtersText}>
                            {`${t("ELASTICLISTSETTINGS:filters")}:`}
                        </Typography>
                    )}
                    {otherFilterNames
                        .slice(0, maxItemsToShow)
                        .map((filterItem) => {
                            const filterEnabled = verifyFilterIsEnabled(
                                filterItem,
                                otherFilters,
                                filters?.customSearch
                            );
                            return (
                                <li key={filterItem}>
                                    <Chip
                                        label={t(
                                            `otherFilter_${filterItem}`,
                                            t(
                                                filterItem,
                                                `otherFilter_${filterItem}`
                                            )
                                        )}
                                        clickable
                                        variant="outlined"
                                        className={classes.chip}
                                        color="primary"
                                        onClick={() => handleOpen(filterItem)}
                                        onDelete={
                                            filterEnabled
                                                ? () => handleDelete(filterItem)
                                                : undefined
                                        }
                                        style={{
                                            border: filterEnabled
                                                ? "2px solid"
                                                : ""
                                        }}
                                    />
                                </li>
                            );
                        })}
                    {otherFilterNames.length > 0 && (
                        <li>
                            <Chip
                                label={t(`ELASTICLISTSETTINGS:advancedFilters`)}
                                clickable
                                // variant=""
                                className={classes.chip}
                                color="primary"
                                onClick={() => setAdvancedModalOpen(true)}
                                onDelete={
                                    advancedFilterNames.length
                                        ? () => handleDeleteAdvancedSearch()
                                        : undefined
                                }
                                style={{
                                    border: advancedFilterNames.length
                                        ? "2px solid"
                                        : ""
                                }}
                            />
                        </li>
                    )}
                </Paper>
                {modalInfo.open && (
                    <CustomSearchModal
                        open={modalInfo.open}
                        filterName={modalInfo.filterName}
                        title={t(
                            `otherFilter_${modalInfo.filterName}`,
                            t(
                                modalInfo.filterName,
                                `otherFilter_${modalInfo.filterName}`
                            )
                        )}
                        onClose={handleClose}
                    />
                )}
                <AdvancedSearchModal
                    open={advancedModalOpen}
                    onClose={() => setAdvancedModalOpen(false)}
                />
            </Grid>
        </Grid>
    );
};

export default CustomSearch;
