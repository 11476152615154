import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import useDynamicDashboard from "customHooks/useDynamicDashboard";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BucketReportTable, { IBucketReportItem } from "./BucketReportTable";
import BucketReportPie from "./BucketGraphs/BucketReportPie";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Delete } from "@material-ui/icons";
import BucketReportBar from "./BucketGraphs/BucketReportBar";
import useDashboardContext from "Pages/Dashboard/useDashboardContext";
import _ from "lodash";
import { gridLayoutSizes } from "Pages/Dashboard/Helpers/_DashboardHelper";
// import Page from "Pages/_Layouts/DefaultInternal";
// import { Link } from "@gatsbyjs/reach-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            margin: 0
        },
        content: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(2)
        },
        title: {
            // fontSize: 14,
            marginBottom: theme.spacing(3)
        },
        count: {
            marginTop: 8
        },
        grow: {
            flexGrow: 1
        },
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#2196f3"
        }
    })
);

interface IBucketReport {
    bucketName: string;
    type: string;
}
const BucketReport = (props: IBucketReport) => {
    const { bucketName, type } = props;
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { editMode } = data.localStateData;
    const widgetName = `${bucketName}_${type}`;

    const deleteWidget = (event: any) => {
        event.stopPropagation();

        try {
            const currentDef = data.localStateData.currentDashboardDef;

            if (currentDef) {
                const newWidgetList = currentDef.widgetList.filter(
                    (i) => i !== widgetName
                );

                currentDef.widgetList = newWidgetList;
                const layouts = _.cloneDeep(currentDef.layouts);

                for (const layoutSize of gridLayoutSizes) {
                    if (layouts[layoutSize]) {
                        layouts[layoutSize] = layouts[layoutSize].filter(
                            (item) => item.i !== widgetName
                        );
                        console.log(
                            "LOGCOMPLEO_B: layouts[layoutSize]",
                            JSON.stringify(layouts[layoutSize], null, 4)
                        );
                    }
                }
                currentDef.layouts = layouts;

                dispatchGeneralPageInfo({
                    payload: { currentDashboardDef: currentDef },
                    type: "update"
                });
            }
        } catch (ex: any) {
            console.log("LOCOMPLEO_B: ERRORMESSAGE", ex?.message);
            console.log("LOGCOMPLEO_B: ERROR", JSON.stringify(ex, null, 4));
        }
    };

    const [listData, listDispatch] = useDynamicDashboard();
    const { t } = listData.tempInfo;
    const { totalFiltered, total } = listData.listReturnInfo;
    const totalFilteredValue: number = totalFiltered?.value || total || 0;

    const classes = useStyles();

    const bucketDef =
        (listData.definitionInfo?.bucketsDefAfterQuery || []).filter(
            (i) => i.key === bucketName
        )[0] || {};

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Box display="flex" className={classes.title}>
                    <Box>
                        <Typography variant="h6">{bucketDef?.name}</Typography>
                        <Typography variant="subtitle1">
                            {t("ELASTICLISTSETTINGS:results", {
                                filtered: totalFiltered?.value,
                                total: total
                            })}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}></Box>
                    {editMode && (
                        <Box>
                            {" "}
                            <ButtonIconTooltipCompleo
                                label={t("COMPLEOGENERAL_Delete")}
                                onClick={deleteWidget}
                                onMouseDown={(e) => e.stopPropagation()}
                                onTouchStart={(e) => e.stopPropagation()}
                            >
                                <Delete color="secondary" />
                            </ButtonIconTooltipCompleo>
                        </Box>
                    )}
                </Box>
                <Grid container spacing={2}>
                    {type === "graph" && (
                        <Grid item xs={12}>
                            <BucketReportSwitch
                                aggregation={bucketDef}
                                t={t}
                                title={bucketDef?.name}
                                totalFilteredValue={totalFilteredValue}
                                totalValue={total || 0}
                            />
                        </Grid>
                    )}
                    {type === "table" && (
                        <Grid item xs={12}>
                            <BucketReportTable
                                aggregation={bucketDef}
                                t={t}
                                title={bucketDef?.name}
                                totalFilteredValue={totalFilteredValue}
                                totalValue={total || 0}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

const BucketReportSwitch = (props: IBucketReportItem) => {
    const { aggregation, t, title } = props;
    switch (aggregation?.bucketReport?.graph) {
        case "pie":
            return <BucketReportPie {...props} />;
        case "bar":
            return <BucketReportBar {...props} />;
        default:
            return <BucketReportBar {...props} />;
    }
};

export default BucketReport;
