import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Grid, Typography, Paper } from "@material-ui/core";
import RatingCompleo from "customHooks/useCompleoReactHookForm/Inputs/RatingCompleo";
import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            width: "100%",
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            marginTop: 0,
            paddingTop: 0
        },
        nested: {
            paddingLeft: theme.spacing(4)
        }
    })
);

interface IProps {
    label: string;
    metadata: any;
    helperTextDefault?: any;
    t: any;
}

const EvaluationScorecard = (props: IProps) => {
    const classes = useStyles();
    const { t } = props;
    const fieldName = props.metadata.fieldName;

    const { watch } = useRhfFieldWithoutControllerOrRegister(
        fieldName,
        props.helperTextDefault
    );
    const fieldValue = watch(fieldName);

    const sections = (fieldValue || []).sort(
        (a: Compleo.IObject, b: Compleo.IObject) => a.order - b.order
    );

    if (sections.length) {
        return (
            <Paper className={classes.paperContainer} elevation={0}>
                <List className={classes.root} dense>
                    {sections.map(
                        (section: Compleo.IObject, sectionIndex: number) => {
                            const items = section.items || [];
                            return (
                                <React.Fragment key={section.id}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1">
                                                    <strong>
                                                        {section.name}
                                                    </strong>
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    {items.map(
                                        (
                                            sectionItem: Compleo.IObject,
                                            index: number
                                        ) => {
                                            const fieldName = `scorecard[${sectionIndex}].items[${index}].evaluation`;
                                            return (
                                                <ListItem
                                                    key={sectionItem.name}
                                                    className={classes.nested}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            sectionItem.name
                                                        }
                                                        secondary={
                                                            <RatingCompleo
                                                                helperTextDefault=""
                                                                label={""}
                                                                t={t}
                                                                metadata={{
                                                                    fieldName: fieldName
                                                                }}
                                                                name={fieldName}
                                                                required={false}
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            );
                                        }
                                    )}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </React.Fragment>
                            );
                        }
                    )}
                </List>
            </Paper>
        );
    } else {
        return <Loading />;
    }
};

export default EvaluationScorecard;
