import React from "react";
import ItemCard from "./ItemCard";
import Typography from "@material-ui/core/Typography";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import Link from "@material-ui/core/Link";
import _ from "lodash";

const FieldApplicantTitleActivities = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType, url } = props;
    const [listData, listDispatch] = useList();
    const { t, language } = listData.tempInfo;
    const index = (listData?.listReturnInfo?.fields || []).findIndex(
        (field) => field.pk === fieldData.pk
    );
    const openModalApplicant = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalApplicant: {
                open: true,
                currentIndex: index,
                currentPage:
                    listData.filtersInfo?.filters?.pagination?.currentPage,
                applicantIdFieldName: "applicantDetail.pk"
            }
        });
    };

    // const openModalActivity = () => {
    //     listDispatch.setLocalInfo({
    //         ...listData.localInfo,
    //         modalActivity: {
    //             open: true,
    //             currentIndex: index,
    //             currentPage:
    //                 listData.filtersInfo?.filters?.pagination?.currentPage,
    //             idFieldName: "pk"
    //         }
    //     });
    // };
    const value = _.get(fieldData, fieldName);
    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    removeColonAfterLabel={true}
                    label={`${t(fieldName)}: `}
                >
                    {value ? (
                        <Link
                            style={{ cursor: "pointer" }}
                            onClick={openModalApplicant}
                        >
                            {_.get(fieldData, fieldName)}
                        </Link>
                    ) : (
                        value
                    )}
                </ItemCard>
            );
        case "table":
            return (
                <>
                    {value ? (
                        <Link
                            variant="inherit"
                            onClick={openModalApplicant}
                            style={{ cursor: "pointer" }}
                        >
                            {transformDBData({
                                value: _.get(fieldData, fieldName),
                                t,
                                language,
                                replaceOption: "-"
                            })}
                        </Link>
                    ) : (
                        value
                    )}
                </>
            );
    }
};

export default FieldApplicantTitleActivities;
