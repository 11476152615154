import React from "react";
import {
    Button,
    Grid,
    Icon,
    InputAdornment,
    TextField,
    Tooltip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextFieldCompleo from "../TextFieldControlled";
import { DialogChoosePlaceHolderSubject } from "./DialogChooseTemplateOrPlaceholder";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import { TemplatePlaceholderDialog } from "./TemplatePlaceholderDialog";
import useHandleInitialIdsScheduleData from "./useHandleInitialIdsScheduleData";
import {
    IInputProps,
    useRhfFieldControlled,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IProps {
    placeholdersSubject?: Compleo.IObject[];
    placeholders?: Compleo.IObject[];
    arrayNumber: number;
    language: string;
    hideSubject: boolean;
}

const ScheduleDataField = (props: IProps & IInputProps) => {
    const {
        metadata,
        t,
        classes,
        required,
        name,
        placeholders = [],
        placeholdersSubject = [],
        arrayNumber,
        language,
        hideSubject,
        helperTextDefault
    } = props;

    // const inputSubjectRef = React.useRef();
    // const inputBodyRef = React.useRef();
    // const [field, meta, helpers] = useField(props.name);
    const {
        field,
        setFieldValue,
        formState,
        reset,
        getValues
    } = useRhfFieldControlled(props.name, helperTextDefault);

    // const startId = useHandleInitialIdsScheduleData(field.value, arrayNumber);
    // const loadedFromDB = idListLoadedFromDB.includes(startId);

    // console.log("schedule startId", startId);
    // console.log("schedule loadedFromDB", loadedFromDB);

    const subjectFieldName = getFieldName(arrayNumber, name, "subject");
    const bodyFieldName = getFieldName(arrayNumber, name, "body");
    const locationFieldName = getFieldName(arrayNumber, name, "location");

    const removeItem = () => {
        const currentValue = field.value;
        currentValue.splice(arrayNumber, 1);
        setFieldValue(currentValue);
    };

    // const [subjectField, , helpersSubject] = useField(subjectFieldName);
    // const [bodyField, , helpersBody] = useField(bodyFieldName);
    const {
        field: subjectField,
        setFieldValue: setSubjectFieldValue,
        error: errorSubject,
        message: messageSubject
    } = useRhfFieldControlled(subjectFieldName, helperTextDefault);
    const {
        field: bodyField,
        setFieldValue: setBodyFieldValue,
        error: errorBody,
        message: messageBody
    } = useRhfFieldControlled(bodyFieldName, helperTextDefault);
    const {
        field: locationField,
        error: errorLocation,
        message: messageLocation
    } = useRhfFieldControlled(locationFieldName, helperTextDefault);

    // const inputSubjectRef = subjectField.ref;
    // const inputBodyRef = bodyField.ref;
    const inputSubjectRef = React.useRef();
    const inputBodyRef = React.useRef();

    const [selectionStart, setSelectionStart] = React.useState<any>(0);
    const updateSelectionStartSubject = () => {
        if (inputSubjectRef && inputSubjectRef.current) {
            const valueFieldRef: any = inputSubjectRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const updateSelectionStartBody = () => {
        if (inputBodyRef && inputBodyRef.current) {
            const valueFieldRef: any = inputBodyRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const [openPlaceHolderSubject, setOpenPlaceholderSubject] = React.useState(
        false
    );
    const handleClickOpenPlaceholderSubject = () => {
        setOpenPlaceholderSubject(true);
    };
    const handleClosePlaceholderSubject = async () => {
        setOpenPlaceholderSubject(false);
    };
    const choosePlaceHolderSubject = (value: string) => {
        const currentValue: string = subjectField.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        setSubjectFieldValue(newValue);
        setOpenPlaceholderSubject(false);
    };

    const [openPlaceHolderBody, setOpenPlaceholderBody] = React.useState(false);
    const handleClickOpenPlaceholderBody = () => {
        setOpenPlaceholderBody(true);
    };
    const handleClosePlaceholderBody = async () => {
        setOpenPlaceholderBody(false);
    };
    const choosePlaceHolderBody = (value: string) => {
        const currentValue: string = bodyField.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        setBodyFieldValue(newValue);
        setOpenPlaceholderBody(false);
    };

    const ready = true;

    // console.log("subjectFieldName", subjectFieldName);
    if (ready) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        // name={subjectFieldName}
                        label={t("Location")}
                        error={errorLocation}
                        helperText={messageLocation}
                        fullWidth
                        className={classes.textField}
                        {...locationField}
                    />
                </Grid>

                {!hideSubject && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="text"
                            // name={subjectFieldName}
                            label={t("EMAILDATAFIELD_Subject")}
                            error={errorSubject}
                            helperText={messageSubject}
                            fullWidth
                            className={classes.textField}
                            inputRef={inputSubjectRef}
                            onSelect={updateSelectionStartSubject}
                            InputProps={{
                                endAdornment: placeholdersSubject.length >
                                    0 && (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                        >
                                            <IconButton
                                                aria-label={t(
                                                    "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                                )}
                                                onClick={
                                                    handleClickOpenPlaceholderSubject
                                                }
                                            >
                                                <FindReplaceIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                            {...subjectField}
                        />
                        <TemplatePlaceholderDialog
                            t={t}
                            open={openPlaceHolderSubject}
                            onClose={handleClosePlaceholderSubject}
                            title={t(
                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                            )}
                        >
                            <DialogChoosePlaceHolderSubject
                                handleChooseAnOption={choosePlaceHolderSubject}
                                placeholders={placeholdersSubject}
                            />
                        </TemplatePlaceholderDialog>
                    </Grid>
                )}
                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <TextField
                        type="text"
                        // name={bodyFieldName}
                        label={t("EMAILDATAFIELD_Body")}
                        fullWidth
                        error={errorBody}
                        helperText={messageBody}
                        className={classes.textField}
                        inputRef={inputBodyRef}
                        onSelect={updateSelectionStartBody}
                        InputProps={{
                            endAdornment: placeholdersSubject.length > 0 && (
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={t(
                                            "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                        )}
                                    >
                                        <IconButton
                                            aria-label={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                            onClick={
                                                handleClickOpenPlaceholderBody
                                            }
                                        >
                                            <FindReplaceIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                        multiline
                        rows={4}
                        rowsMax={10}
                        variant="outlined"
                        {...bodyField}
                    />
                    <TemplatePlaceholderDialog
                        t={t}
                        open={openPlaceHolderBody}
                        onClose={handleClosePlaceholderBody}
                        title={t(
                            "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                        )}
                    >
                        <DialogChoosePlaceHolderSubject
                            handleChooseAnOption={choosePlaceHolderBody}
                            placeholders={placeholders}
                        />
                    </TemplatePlaceholderDialog>
                </Grid>
                {!field.value[arrayNumber].default && (
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={removeItem}
                            startIcon={<Icon>delete</Icon>}
                        >
                            {t("EMAILDATAFIELD_removelanguage")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const getFieldName = (
    arrayNumber: number,
    mainFieldName: string,
    fieldName: string
) => {
    return `${mainFieldName}.${arrayNumber}.${fieldName}`;
};

export default ScheduleDataField;
