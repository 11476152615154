import { useApiCache } from "customHooks/useApi";
import { apiDirectCall } from "customHooks/useApi/api";
import useGetMetadata from "customHooks/useGetMetadata";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import { ListDefinitionType } from "Pages/Users/components/useCustomLists";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import useHandleInitialValues from "Pages/ApplicationForm/components/useHandleInitialValues";

const useHandleInitialValuesJob = (
    valuesFromSource: Compleo.IObject,
    languageSettings?: Compleo.IObject
) => {
    const [readyValuesFromSource, setReadyValuesFromSource] = React.useState(
        false
    );
    // const [
    //     languages,
    //     defaultLanguage,
    //     readyLanguages
    // ] = useGetLanguagesCareers();
    const languages = languageSettings?.languagesCareers;
    const defaultLanguage = languageSettings?.defaultLanguageCareers?.value;
    const languagesString = (languages || []).map((l: any) => l.value);

    const [
        translatedDataDefaultValues,
        setTranslatedDataDefaultValues
    ] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    const {
        initialValues: applicationFormInitialValues,
        translations,
        readyInitialValues,
        messages
    } = useHandleInitialValues(
        valuesFromSource.applicationForm || {},
        languagesString || [],
        defaultLanguage,
        true
    );

    React.useEffect(() => {
        if (valuesFromSource?.title && !readyValuesFromSource) {
            setReadyValuesFromSource(true);
        }
    }, [valuesFromSource?.title, readyValuesFromSource]);

    React.useEffect(() => {
        if (
            !translatedDataDefaultValues.ready &&
            languagesString &&
            languagesString.length > 0 &&
            readyInitialValues &&
            readyValuesFromSource
        ) {
            const fieldsToAdd: string[] = ["title", "description"];
            const TranslatedData: Compleo.IObject = {};

            for (const l of languagesString.filter(
                (l: string) => defaultLanguage !== l
            )) {
                TranslatedData[l] = {};
                for (const f of fieldsToAdd) {
                    if (!(valuesFromSource.translatedData || {})[l]) {
                        TranslatedData[l][f] = valuesFromSource[f];
                    } else {
                        TranslatedData[l][f] =
                            valuesFromSource.translatedData[l][f] ||
                            valuesFromSource[f];
                    }
                }
            }

            setTranslatedDataDefaultValues({
                ready: true,
                values: {
                    translatedData: {
                        ...TranslatedData
                    }
                }
            });
        }
    }, [languagesString, readyInitialValues]);

    return {
        initialValues: {
            ...valuesFromSource,
            applicationForm: { ...applicationFormInitialValues },
            ...translatedDataDefaultValues.values
        },
        translations: translations,
        languages: languages,
        defaultLanguage: defaultLanguage,
        messages: messages,
        ready: readyInitialValues && languagesString.length > 0
    };
};

export default useHandleInitialValuesJob;
