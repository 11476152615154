import React from "react";
import Page from "../_Layouts/DefaultExternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { useApi, useApiCache } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import { navigate } from "@gatsbyjs/reach-router";
import useValuesFromSource from "customHooks/useValuesFromSource";

const TrialCompany = (props: any) => {
    const classes = useFormStyles();

    const [t, i18n, readyTranslation] = useTranslation("TRIALREQUESTCOMPANY", {
        useSuspense: false
    });
    let userId = "";
    const postAddress = `/trial/edit/${props.id}`;
    const [addTrialCompanyReturn, addTrialCompany] = useApi(
        postAddress,
        "post"
    );

    const [metadata] = useGetMetadata("TRIALREQUESTCOMPANY");
    if (addTrialCompanyReturn.status === "success") {
        if (addTrialCompanyReturn.response.data.data.isValid) {
            userId = addTrialCompanyReturn.response.data.data.userGuid.guid;
            navigate(`/firstaccess/${userId}`);
        } else {
            navigate(`/trial/trialend`);
        }
    }

    const postAddressSearch = `/trial/search/${props.id}`;
    const [getModuleReturn] = useApiCache(postAddressSearch, "post", {}, false);

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getModuleReturn,
        ["data", "data", "Items"]
    );

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addTrialCompany,
        addTrialCompanyReturn,
        null,
        FormExternal,
        metadata,
        valuesFromSource,
        null,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default TrialCompany;
