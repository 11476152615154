import React from "react";
import Translations from "Pages/Questionnaire/components/TranslationComponent/Translations";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Divider, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface IProps {
    languages: Compleo.IObject[];
    defaultLanguage: string;
    t: any;
}

const ApplicationFormTranslations = (props: IProps) => {
    const { languages, defaultLanguage, t } = props;
    const [tLocal, i18n, readyTranslation] = useTranslation("QUESTIONNAIRE", {
        useSuspense: false
    });
    const [
        enableCustomTranslation,
        setEnableCustomTranslation
    ] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // const statusAtual = enableCustomTranslation;
        setEnableCustomTranslation(event.target.checked);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableCustomTranslation}
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={t("keepDefaulTranslationCheck")}
                />
            </Grid>
            <Divider />
            {enableCustomTranslation && (
                <Grid item xs={12}>
                    <Translations
                        languages={languages}
                        defaultLanguage={defaultLanguage}
                        fieldTranslationsName={
                            "screeningQuestionnaire.translations"
                        }
                        fieldQuestionsName={"screeningQuestionnaire.questions"}
                        currentLanguage={i18n.languages[0]}
                        t={tLocal}
                    />
                </Grid>
            )}
        </Grid>
    );
};

// ApplicationFormTranslations.whyDidYouRender = true;

export default ApplicationFormTranslations;
