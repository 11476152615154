import React from "react";
import Page from "../_Layouts/DefaultExternal";
import { Auth } from "aws-amplify";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import stylesForm from "Pages/_Layouts/generalForm";
import { useApi, ApiStateType } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import useValuesFromSource from "customHooks/useValuesFromSource";

const useStyles = makeStyles((theme: Theme) => createStyles(stylesForm));

const Login = (props: any) => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation(
        "LOGINCONFIRMATIONCODE",
        {
            useSuspense: false
        }
    );
    let pageReady = false;
    const [isLoading, setIsLoading] = React.useState(false);
    const redirectAfterLogin: null | string | string[] = "/";
    const postAddress = `/user/firstaccess`;
    const [getTokenReturn, getToken] = useApi(postAddress, "post");

    const postAddressVerify = `/user/sendverificationtokenuser`;
    const [sendVerificationCodeReturn, sendVerificationCode] = useApi(
        postAddressVerify,
        "post"
    );

    const [metadata] = useGetMetadata("LOGINCONFIRMATIONCODE");
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getTokenReturn,
        ["data", "data"]
    );
    valuesFromSource.confirmationCode = "";

    const sendConfirmationCode = async () => {
        const currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: false
        });
        if (currentUser) {
            await sendVerificationCode({
                token: currentUser.signInUserSession.accessToken.jwtToken,
                language: i18n.language
            });
        }
    };

    React.useEffect(() => {
        getToken({ token: props.useraccessuuid });
        sendConfirmationCode();
    }, []);

    if (
        getTokenReturn.status === "success" &&
        metadata.status === "success" &&
        sendVerificationCodeReturn.status === "success"
    ) {
        pageReady = true;
        // const email = getTokenReturn.response.data.data.email;
        // const name = getTokenReturn.response.data.data.name;
        // const cellPhone = getTokenReturn.response.data.data.cellPhone;

        // valuesFromSource = {
        //     email,
        //     name,
        //     cellPhone,
        //     c: ""
        // };
    }

    const confirmationCodeFunction = async (values: any) => {
        setIsLoading(true);
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        try {
            const returnConfirmation = await Auth.verifyCurrentUserAttributeSubmit(
                "email",
                values.confirmationCode
            );
            if (returnConfirmation === "SUCCESS") {
                retorno = { status: 200 };
            } else {
                retorno = { status: 400 };
            }
            setIsLoading(false);
            return retorno;
        } catch (err) {
            retorno = { status: 400 };
            console.log("err", err);

            if (err.code === "CodeMismatchException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("invalidConfirmationCode")
                    }
                ];
            } else if (err.code === "ExpiredCodeException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("invalidConfirmationCode")
                    }
                ];
            } else {
                // Some samples of other errors
                // UserNotConfirmedException
                // UserNotFoundException
                // TooManyRequestsException
                // ResourceNotFoundException
                // PasswordResetRequiredException
                // NotAuthorizedException
                // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_VerifyUserAttribute.html
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "confirmationCode",
                        message: t("invalidConfirmationCode")
                    }
                ];
            }
            // PasswordResetRequiredException
            setIsLoading(false);
            return retorno;
        }
    };

    let loginReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    if (isLoading) {
        loginReturn.status = "fetching";
    } else {
        loginReturn.status = "success";
    }

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation && pageReady,
        i18n,
        confirmationCodeFunction,
        loginReturn,
        redirectAfterLogin,
        FormExternal,
        metadata,
        valuesFromSource
    );

    let compRetorno: boolean | JSX.Element = <Loading />;

    if (finished && readyTranslation && getTokenReturn.status === "success") {
        compRetorno = formCompleo;
        return <Page maxWidth="xs">{compRetorno}</Page>;
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default Login;
