import { PageToRenderList, DashboardPageToRenderType } from "./DashboardTypes";

const pageToRenderKey = "dashboardLastPageToRender";
export const defaultPageToRender: DashboardPageToRenderType = "main";

export const getLastPageToRender = () => {
    const dataFromStorage = localStorage.getItem(pageToRenderKey);

    if (
        dataFromStorage &&
        PageToRenderList.includes(dataFromStorage as DashboardPageToRenderType)
    ) {
        const returnData = dataFromStorage as DashboardPageToRenderType;
        return returnData;
    }
    return defaultPageToRender;
};

export const setLastPageToRender = (
    pageToRender: DashboardPageToRenderType
) => {
    if (pageToRender && PageToRenderList.includes(pageToRender)) {
        localStorage.setItem(pageToRenderKey, pageToRender);
    }
};
