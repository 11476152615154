import { ApiStateType } from "customHooks/useApi";
import { ITemplateEmailData } from "./EmailData";

export const getTemplatesFromEmailDataDB = (
    getEmailTemplateResult: ApiStateType,
    splitTemplatesByLanguage: boolean,
    t: any,
    type?: string
) => {
    const templates: ITemplateEmailData[] = [];
    const mainData = getEmailTemplateResult.response?.data?.data || [];
    const typeListFromMain = getTypeListFromMainType(type);

    mainData
        .filter((item: Compleo.IObject) => typeListFromMain.includes(item.type))
        .map((item: Compleo.IObject) => {
            if (splitTemplatesByLanguage) {
                (item?.template || []).map((d: any) => {
                    const dataReturn: ITemplateEmailData = {
                        label: `${item.name} - ${t(`Language_${d.language}`)}`,
                        id: item.skGS1pk.split(":")[1],
                        items: [
                            {
                                default: true,
                                id: d.id,
                                language: d.language
                            }
                        ]
                    };
                    templates.push(dataReturn);
                });
            } else {
                const dataReturn: ITemplateEmailData = {
                    label: item.name,
                    id: item.skGS1pk.split(":")[1],
                    items: (item.template || []).map((d: any) => {
                        return {
                            default: d.default,
                            id: d.id,
                            language: d.language
                        };
                    })
                };
                templates.push(dataReturn);
            }
        });
    return templates;
};

const getTypeListFromMainType = (mainType?: string) => {
    switch (mainType) {
        case "communicationApplicantJob":
            return [mainType, "communicationApplicant"];
        default:
            return [mainType];
    }
};
