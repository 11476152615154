import React from "react";
// import { Field, FastField } from "formik";
import { Grid } from "@material-ui/core";
import TextField from "../Inputs/TextField";
import RichText from "../Inputs/RichText/RichText";
import RichTextV2 from "../Inputs/RichTextV2/RichTextV2";
import RichTextV3 from "../Inputs/RichTextV3";
import CPFField from "../Inputs/CPFField";
import CNPJField from "../Inputs/CNPJField";
import CNPJCPFField from "../Inputs/CNPJCPFField";
import ComboAutoComplete from "../Inputs/ComboAutoComplete";
import RadioButtonGroup from "../Inputs/RadioButtonGroup";
import TagsCreatable from "../Inputs/TagsCreatable";
import Tags from "../Inputs/Tags";
import CheckboxControl from "../Inputs/Checkbox";
import Typography from "@material-ui/core/Typography";
import PhoneNumber from "../Inputs/PhoneNumber";
import DateField from "../Inputs/DateField";
import DateTimeRangeField from "../Inputs/DateTimeRangeField";
import NumberField from "../Inputs/NumberField";
import CurrencyField, { CurrencyLanguages } from "../Inputs/CurrencyField";
import NumberRangeField from "../Inputs/NumberRangeField";
import Address from "../Inputs/Address";
import ComplexField from "../Inputs/ComplexField";
import * as formatFunctions from "functions/formatValues";
import File from "../Inputs/File/File";
import ImageField from "../Inputs/Image/Image";
import EmailData from "../Inputs/EmailDataV2/EmailData";
import ScheduleData from "../Inputs/ScheduleData/ScheduleData";
import { clearArrayInfo, transformDBData } from "functions/util";
import DelayAction from "../Inputs/DelayAction";
import TooltipCompleo from "Components/Tooltip";
import RatingCompleo from "../Inputs/RatingCompleo";
import AlertCompleo from "../Inputs/AlertCompleo";

import JobTitleSwitch from "../Inputs/JobComboListText/JobTitleSwitch";
import TextFieldMask from "../Inputs/TextFieldMask";
interface IInsideGridProps {
    children: any;
    campoMetadata: Compleo.IObject;
    t: any;
}

const InsideGrid = (props: IInsideGridProps) => {
    const campoMetadata = props.campoMetadata;
    const xsSize = (campoMetadata.columnSize || {}).xs || 12;
    const smSize = (campoMetadata.columnSize || {}).sm || xsSize;
    const mdSize = (campoMetadata.columnSize || {}).md || smSize;
    const lgSize = (campoMetadata.columnSize || {}).lg || mdSize;
    const xlSize = (campoMetadata.columnSize || {}).xl || lgSize;

    const tooltipTitle = props.t(`${campoMetadata.fieldName}_tooltipTitle`, "");
    const tooltipDetail = props.t(
        `${campoMetadata.fieldName}_tooltipDetail`,
        ""
    );
    if (tooltipDetail !== "") {
        return (
            <Grid
                item
                xs={xsSize}
                sm={smSize}
                md={mdSize}
                lg={lgSize}
                xl={xlSize}
            >
                <Grid container alignItems="center">
                    <Grid item xs={12} sm container>
                        {props.children}
                    </Grid>
                    <Grid item>
                        <TooltipCompleo
                            detail={tooltipDetail}
                            title={tooltipTitle}
                            buttonAriaText={tooltipTitle}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid
                item
                xs={xsSize}
                sm={smSize}
                md={mdSize}
                lg={lgSize}
                xl={xlSize}
            >
                {props.children}
            </Grid>
        );
    }
};

export const getFields = (
    campoMetadata: any,
    classes: any,
    t: any,
    listas: any,
    valuesFromSource: any = null,
    CustomComponents: any = null,
    language: string = "en-US",
    customLists?: Compleo.IObject[],
    fieldsToHide?: string[],
    completeMetadata?: Compleo.IObject[],
    hideComplexFieldsDetail: boolean = true,
    additionalFieldProperties: Compleo.useCompleoForm.AdditionalPropertiesField[] = []
) => {
    if (Array.isArray(fieldsToHide) && fieldsToHide.length > 0) {
        if (fieldsToHide.includes(clearArrayInfo(campoMetadata.fieldName))) {
            return null;
        }
    }
    // não renderiza unicamente um campo de detalhe do Complex Field
    if (
        hideComplexFieldsDetail &&
        campoMetadata.complexFieldDetail !== undefined &&
        campoMetadata.complexFieldDetail === true
    ) {
        return null;
    }

    const requiredMetadata = isRequiredMetadata(campoMetadata);

    const labelName = clearArrayInfo(campoMetadata.fieldName);
    const otherPropsToAdd =
        (
            additionalFieldProperties.filter(
                (p) => p.fieldName === clearArrayInfo(campoMetadata.fieldName)
            )[0] || {}
        ).props || {};

    switch (campoMetadata.elementType) {
        case "label": {
            return getLabel(t, campoMetadata, valuesFromSource, language);
        }
        case "cpf": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <CPFField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "cnpj": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <CNPJField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "cnpj_cpf": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <CNPJCPFField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "text": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TextField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        {
                            ...campoMetadata.elementConfig,
                            ...otherPropsToAdd
                        }
                    )}
                </InsideGrid>
            );
        }
        case "textMask": {
            console.log("textMask", campoMetadata);
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TextFieldMask
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                            cleaveZenOptions={campoMetadata.cleaveZenOptions}
                        />,
                        {
                            ...campoMetadata.elementConfig,
                            ...otherPropsToAdd
                        }
                    )}
                </InsideGrid>
            );
        }
        // case "url": {
        //     return (
        //         <InsideGrid
        //             t={t}
        //             key={campoMetadata.fieldName}
        //             campoMetadata={campoMetadata}
        //         >
        //             {NewField(
        //                 <Field
        //                     type="text"
        //                     name={campoMetadata.fieldName}
        //                     label={t(labelName)}
        //                     helperTextDefault={t(`${labelName}_helperText`, "")}
        //                     fullWidth
        //                     className={classes.textField}
        //                     component={URLField}
        //                     required={requiredMetadata}
        //                 />,
        //                 { ...campoMetadata.elementConfig, ...otherPropsToAdd }
        //             )}
        //         </InsideGrid>
        //     );
        // }
        case "richtext": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <RichText
                            name={campoMetadata.fieldName}
                            metadata={campoMetadata}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            required={requiredMetadata}
                            label={t(labelName)}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "richtextv2": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <RichTextV2
                            name={campoMetadata.fieldName}
                            metadata={campoMetadata}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            required={requiredMetadata}
                            label={t(labelName)}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "richtextv3": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    <React.StrictMode>
                        {NewField(
                            <RichTextV3
                                name={campoMetadata.fieldName}
                                metadata={campoMetadata}
                                helperTextDefault={t(
                                    `${labelName}_helperText`,
                                    ""
                                )}
                                required={requiredMetadata}
                                label={t(labelName)}
                                t={t}
                                key={`RichTextV3_${campoMetadata.fieldName}`}
                            />,
                            {
                                ...campoMetadata.elementConfig,
                                ...otherPropsToAdd
                            }
                        )}
                    </React.StrictMode>
                </InsideGrid>
            );
        }
        case "address": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <Address
                            name={campoMetadata.fieldName}
                            metadata={campoMetadata}
                            t={t}
                            classes={classes}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "delayAction": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <DelayAction
                            name={campoMetadata.fieldName}
                            metadata={campoMetadata}
                            t={t}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            classes={classes}
                            required={requiredMetadata}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            language={language}
                            // fieldName={field.name}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "number": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TextField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "password": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TextField
                            type="password"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "phone": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <PhoneNumber
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "checkbox":
        case "uniqueCheckbox": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <CheckboxControl
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            // helperTextDefault={t(`helperText${campoMetadado.fieldName}`, "")}
                            // className={classes.textField}
                            // component={CheckboxControl}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "email": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TextField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "dateOfBirth": {
            let dataInvalidaMsg = "Data inválida";
            dataInvalidaMsg = getMessageInvalidDate(campoMetadata, t);

            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <DateField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            value={campoMetadata.fieldValue}
                            openTo="year"
                            invalidDateMessage={dataInvalidaMsg}
                            invalidLabel={dataInvalidaMsg}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            required={requiredMetadata}
                            language={language}
                            disableFuture={true}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "select": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <ComboAutoComplete
                            required={requiredMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            parentFilterField={campoMetadata.parentFilterField}
                            childrenFilterFieldName={
                                campoMetadata.childrenFilterFieldName
                            }
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            language={language}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "jobList": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <JobTitleSwitch
                            required={requiredMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            className={classes.textField}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            language={language}
                            fullWidth
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "radiobuttongroup": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <RadioButtonGroup
                            required={requiredMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            // helperText={t(`${labelName}_helperText`, "")}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            parentFilterField={campoMetadata.parentFilterField}
                            childrenFilterFieldName={
                                campoMetadata.childrenFilterFieldName
                            }
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            language={language}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "tags": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <Tags
                            multiple={true}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            // helperText={t(`${labelName}_helperText`, "")}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            required={requiredMetadata}
                            language={language}
                            parentFilterField={campoMetadata.parentFilterField}
                            childrenFilterFieldName={
                                campoMetadata.childrenFilterFieldName
                            }
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "tagsCreatableArray": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TagsCreatable
                            freeSolo={true}
                            multiple={true}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            required={requiredMetadata}
                            language={language}
                            t={t}
                            parentFilterField={campoMetadata.parentFilterField}
                            childrenFilterFieldName={
                                campoMetadata.childrenFilterFieldName
                            }
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "selectCreatable": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <TagsCreatable
                            freeSolo={true}
                            multiple={false}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            campoMetadata={campoMetadata}
                            customLists={customLists}
                            required={requiredMetadata}
                            language={language}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "date": {
            let dataInvalidaMsg = "Invalid Date";
            dataInvalidaMsg = getMessageInvalidDate(campoMetadata, t);
            if (campoMetadata.onlyMonthYear) {
                campoMetadata.elementConfig = {
                    ...campoMetadata.elementConfig,
                    views: ["year", "month"]
                };
            }
            // debugger;
            //("field date", campoMetadado.fieldValue);

            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <DateField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            value={campoMetadata.fieldValue}
                            invalidDateMessage={dataInvalidaMsg}
                            invalidLabel={dataInvalidaMsg}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            required={requiredMetadata}
                            language={language}
                            onlyMonthYear={campoMetadata.onlyMonthYear}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "datetime": {
            let dataInvalidaMsg = "Invalid DateTime";
            dataInvalidaMsg = getMessageInvalidDate(campoMetadata, t);

            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <DateField
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            value={campoMetadata.fieldValue}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            invalidDateMessage={dataInvalidaMsg}
                            required={requiredMetadata}
                            language={language}
                            dateType="DateTime"
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "dateTimeRange": {
            let dataInvalidaMsg = "Invalid DateTime";
            dataInvalidaMsg = getMessageInvalidDate(campoMetadata, t);

            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <DateTimeRangeField
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            value={campoMetadata.fieldValue}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            invalidDateMessage={dataInvalidaMsg}
                            fieldMetadata={campoMetadata}
                            required={requiredMetadata}
                            language={language}
                            t={t}
                            {...campoMetadata.elementConfig}
                        />,
                        { ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "integer": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <NumberField
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            numberType="integer"
                            className={classes.textField}
                            // component={NumberField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "decimal": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <NumberField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            numberType="decimal"
                            className={classes.textField}
                            // component={NumberField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "currency": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <CurrencyField
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                            language={language as CurrencyLanguages}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "numberrange": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <NumberRangeField
                            type="text"
                            name={campoMetadata.fieldName}
                            labelMinField={`${t(
                                campoMetadata.fieldName + "MinLabel"
                            )}`}
                            labelMaxField={`${t(
                                campoMetadata.fieldName + "MaxLabel"
                            )}`}
                            labelTypeTitle={`${t(
                                campoMetadata.fieldName + "TypeTitleLabel"
                            )}`}
                            helperTextDefault={t(
                                `${campoMetadata.fieldName}_helperText`,
                                ""
                            )}
                            options={listas[campoMetadata.fieldName]}
                            className={classes.textField}
                            // component={NumberRangeField}
                            required={requiredMetadata}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "multipleFiles":
        case "file": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(
                        <File
                            metadata={campoMetadata}
                            type="text"
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            fullWidth
                            className={classes.textField}
                            required={requiredMetadata}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </Grid>
            );
        }
        case "image": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(
                        <ImageField
                            metadata={campoMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            classes={classes}
                            required={requiredMetadata}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </Grid>
            );
        }
        case "complexField": {
            return (
                <InsideGrid
                    t={t}
                    key={campoMetadata.fieldName}
                    campoMetadata={campoMetadata}
                >
                    {NewField(
                        <ComplexField
                            name={campoMetadata.fieldName}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            metadata={campoMetadata}
                            completeMetada={completeMetadata || []}
                            t={t}
                            fieldClasses={classes}
                            required={requiredMetadata}
                            listas={listas}
                            valuesFromSource={valuesFromSource}
                            CustomComponents={CustomComponents}
                            language={language}
                            customLists={customLists}
                            fieldsToHide={fieldsToHide}
                            additionalFieldProperties={
                                additionalFieldProperties
                            }
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </InsideGrid>
            );
        }
        case "custom": {
            const customData = CustomComponents.filter(
                (c: any) => c.name === campoMetadata.fieldName
            );

            if (customData !== undefined && customData[0] != undefined) {
                const CustomField = customData[0].component;
                return (
                    <React.Fragment key={campoMetadata.fieldName}>
                        {NewField(
                            <CustomField
                                metadata={campoMetadata}
                                name={campoMetadata.fieldName}
                                label={t(labelName)}
                                component={CustomField}
                                required={requiredMetadata}
                                t={t}
                                helperTextDefault={t(
                                    `${labelName}_helperText`,
                                    ""
                                )}
                            />,
                            {
                                ...campoMetadata.elementConfig,
                                ...otherPropsToAdd
                            }
                        )}
                    </React.Fragment>
                );
            }
            break;
        }
        case "emailData": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(
                        <EmailData
                            metadata={campoMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            classes={classes}
                            required={requiredMetadata}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </Grid>
            );
        }
        case "scheduleData": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(
                        <ScheduleData
                            metadata={campoMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            classes={classes}
                            required={requiredMetadata}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </Grid>
            );
        }
        case "rating": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(
                        <RatingCompleo
                            metadata={campoMetadata}
                            name={campoMetadata.fieldName}
                            label={t(labelName)}
                            helperTextDefault={t(`${labelName}_helperText`, "")}
                            classes={classes}
                            required={requiredMetadata}
                            t={t}
                        />,
                        { ...campoMetadata.elementConfig, ...otherPropsToAdd }
                    )}
                </Grid>
            );
        }
        case "alertCompleo": {
            return (
                <Grid key={campoMetadata.fieldName} item xs={12}>
                    {NewField(<AlertCompleo metadata={campoMetadata} t={t} />, {
                        ...campoMetadata.elementConfig,
                        ...otherPropsToAdd
                    })}
                </Grid>
            );
        }
        default:
            return null;
    }
};

const getMessageInvalidDate = (field: any, t: any) => {
    let invalidDateMessage: any;
    if (field.validation && field.validation.length) {
        const dateValidation = field.validation.filter((i: any) => {
            return i.type === "dateOfBirth" || i.type === "date";
        });

        if (
            dateValidation &&
            dateValidation[0] &&
            dateValidation[0].messageId
        ) {
            invalidDateMessage = t(dateValidation[0].messageId);
        }
    }
    return invalidDateMessage;
};

const NewField = (field: any, propsToAdd: any) => {
    propsToAdd = propsToAdd || {};
    propsToAdd["data-testid"] = `field_${field.props.name}`;
    return React.cloneElement(field, propsToAdd);
};

function createMarkup(value: string) {
    return { __html: value };
}
const getLabel = (
    t: any,
    campoMetadata: Compleo.IObject,
    valuesFromSource: any,
    language: string
) => {
    const labelReplaceEmptyOption = campoMetadata.labelReplaceEmptyOption || "";
    let value: any = labelReplaceEmptyOption;
    if (valuesFromSource[campoMetadata.fieldName]) {
        switch (campoMetadata.labelType) {
            case "text":
                value = valuesFromSource[campoMetadata.fieldName];
                break;
            case "object":
                value = transformDBData({
                    value: valuesFromSource[campoMetadata.fieldName],
                    t: t,
                    language
                });
                break;
            case "phoneNumber":
                value = formatFunctions.maskPhone(
                    valuesFromSource[campoMetadata.fieldName]
                );
                break;
            case "cnpj":
                value = formatFunctions.maskCNPJ(
                    valuesFromSource[campoMetadata.fieldName]
                );
                break;
            case "cpf":
                value = formatFunctions.maskCPF(
                    valuesFromSource[campoMetadata.fieldName]
                );
                break;
            default:
                value = valuesFromSource[campoMetadata.fieldName];
                break;
        }
    }

    return (
        <React.Fragment key={campoMetadata.fieldName}>
            <InsideGrid t={t} campoMetadata={campoMetadata}>
                <div>
                    <Typography
                        variant="caption"
                        display="block"
                        color="primary"
                    >
                        {t(campoMetadata.fieldName)}
                    </Typography>
                    <Typography variant="body1" display="block">
                        <span dangerouslySetInnerHTML={createMarkup(value)} />
                    </Typography>
                </div>
            </InsideGrid>
            {/* <Divider variant="middle" /> */}
        </React.Fragment>
    );
};

const isRequiredMetadata = (campoMetadata: Compleo.IObject) => {
    const requiredMetadata =
        campoMetadata.required === undefined ? false : campoMetadata.required;
    const hasRequiredCondition = campoMetadata?.requiredCondition !== undefined;

    return hasRequiredCondition ? false : requiredMetadata;
};
