import { IRichTextConfig } from "./RichTextV2";

const fullToolBar = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "removeFormat"
    ],
    // "/",
    [
        "fontColor",
        "hiliteColor",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "table"
    ],
    [
        "link",
        "image",
        // "video",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print"
        // "save"
    ],
    ["plugin_emoji"]
];

const emailTemplateToolbar = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "removeFormat"
    ],
    [
        "fontColor",
        "hiliteColor",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "table"
    ],
    [
        "link",
        "image",
        "video",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print"
    ],
    ["plugin_emoji", "plugin_placeholder"]
];

const customCVToolbar = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "removeFormat"
    ],
    // "/",
    [
        "fontColor",
        "hiliteColor",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "table"
    ],
    [
        "link",
        // "image",
        // "video",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print"
        // "save"
    ],
    ["plugin_emoji"]
];

const jobDescriptionToolbar = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "removeFormat"
    ],
    // "/",
    [
        "fontColor",
        "hiliteColor",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "table"
    ],
    [
        "link",
        "image",
        "video",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print"
        // "save"
    ],
    ["plugin_emoji"]
];

const minToolBar = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "removeFormat"
    ],
    // "/",
    ["fontColor", "hiliteColor"],
    ["plugin_emoji"]
];

export const getToolbarRichText = (richtTextConfig: IRichTextConfig) => {
    const { toolbarType = "min" } = richtTextConfig;
    switch (toolbarType) {
        case "min":
            return minToolBar;
        case "full":
            return fullToolBar;
        case "jobDescription":
            return jobDescriptionToolbar;
        case "customCV":
            return customCVToolbar;
        case "emailTemplate":
            return emailTemplateToolbar;
        default:
            return minToolBar;
    }
};
