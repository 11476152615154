export const helperAmplifyMessage = (message: string, t: any) => {
    switch (message) {
        case "CodeMismatchException":
            return t("AMPLIFYMESSAGE_codeMismatchException");
        case "ExpiredCodeException":
            return t("AMPLIFYMESSAGE_expiredCodeException");
        case "InvalidParameterException":
            return t("AMPLIFYMESSAGE_invalidParameterException");
        case "LimitExceededException":
            return t("AMPLIFYMESSAGE_limitExceededException");
        case "NotAuthorizedException":
            return t("AMPLIFYMESSAGE_notAuthorizedException");
        case "PasswordResetRequiredException":
            return t("AMPLIFYMESSAGE_passwordResetRequiredException");
        case "ResourceNotFoundException":
            return t("AMPLIFYMESSAGE_resourceNotFoundException");
        case "TooManyRequestsException":
            return t("AMPLIFYMESSAGE_tooManyRequestsException");
        case "UserNotConfirmedException":
            return t("AMPLIFYMESSAGE_userNotConfirmedException");
        case "UserNotFoundException":
            return t("AMPLIFYMESSAGE_userNotFoundException");
        case "InternalErrorException":
            return t("AMPLIFYMESSAGE_currentPasswordException");
        default:
            return t("AMPLIFYMESSAGE_genericErrorException");
    }
};
