import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { ApiCallType, apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import { IValidateOnEntryDefinition } from "customHooks/useCompleoReactHookForm/Inputs/TagsCreatable";
import { validateEmail } from "functions/util";
import useModalEvaluationList from "./useModalEvaluationList";
import isEqual from "lodash/isEqual";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    applicantData: Compleo.IObject;
    jobData?: Compleo.IObject;
}

const ApplicantEvaluationRequestModal = (props: IPropsModal) => {
    const { onClose, open, applicantData, jobData } = props;
    const applicantId = applicantData?.pk.split(":")[1];
    //const formikRef = React.useRef(null);
    const formRef = React.useRef<HTMLFormElement>(null);
    const classes = useStyles();
    const [isUpdating, setIsUpdating] = React.useState(false);
    const hasScorecard = (jobData?.scorecard?.sections || []).length > 0;
    const hasDocuments = (applicantData?.documents || []).length > 0;
    const customerFilterIdList: string[] = [];
    if (jobData?.customer?.value) {
        customerFilterIdList.push(jobData?.customer?.value);
    }

    const defaultFieldsToHide: string[] = [];
    if (!hasScorecard) {
        defaultFieldsToHide.push("requestScorecard");
    }
    if (!hasDocuments) {
        defaultFieldsToHide.push("documentsToShare");
    }
    defaultFieldsToHide.push("cvToShare");

    const showMessage = useShowMessage();
    const queryClient = useQueryClient();

    //const { company } = useAuthState();

    const module = "APPLICANTEVALUATIONREQUESTMODAL";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [lists, readyLists] = useModalEvaluationList(
        t,
        readyTranslation,
        customerFilterIdList,
        applicantData?.documents || [],
        jobData?.pk.split(":")[1]
    );

    const [initialValues] = useValuesFromSource(metadata, true, {
        applicantId: applicantId,
        applicantName: applicantData?.name,
        jobTitle: jobData?.title,
        jobId: jobData?.pk.split(":")[1]
    });
    const ready =
        readyTranslation && metadata.status === "success" && readyLists;

    const tagsAddProps: IValidateOnEntryDefinition = {
        errorMessage: t("invalidEmail"),
        validateFunction: validateEmail
    };
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "externalEvaluators",
            props: {
                validateOnEntryDefinition: tagsAddProps
            }
        }
    ];

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const {
        watch,
        handleSubmit,
        trigger
    } = reactHookFormMethods.reactHookFormMethods;
    const displayCVField = watch("displayCV");
    React.useEffect(() => {
        console.log("useEffect displayCVField", displayCVField);
        let fieldsToHideFN = defaultFieldsToHide;
        if (displayCVField) {
            fieldsToHideFN = defaultFieldsToHide.filter(
                (item) => item !== "cvToShare"
            );
        }
        if (!isEqual(fieldsToHide, fieldsToHideFN)) {
            setFieldsToHide(fieldsToHideFN);
        }
    }, [displayCVField]);

    const handleSubmitLocal = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        setIsUpdating(true);
        retorno.status = 200;
        const postAddress = `/applicant/requestevaluation`;
        try {
            const dataAPI = await apiDirectCall(postAddress, "post", values);
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries(
                    "/applicant/applicanthistorylist"
                );
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const handleSave = () => {
        // const formikValue: any = formikRef.current;
        // if (formikValue) {
        //     await formikValue.submitForm();
        // }
        if (formRef.current) {
            formRef.current?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmitLocal,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        additionalFieldProperties: addProps,
        customLists: lists,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide: fieldsToHide,
        formRef
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="lg"
                aria-labelledby={t("a_FeatureMainDescription")}
                open={open}
            >
                <DialogTitle>{t("a_FeatureMainDescription")}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("modalButtonMainText")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_FeatureMainDescription")}
                open={open}
            >
                <DialogContent dividers>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

export default ApplicantEvaluationRequestModal;
