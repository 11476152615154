import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import {
    useCompleoSimpleList,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import useActionsTable from "./components/useActionsTable";

const NotificationList = (props: any) => {
    const moduleName = "NOTIFICATIONLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/notifications/list";
    const { company } = useAuthState();
    const actions: actionsTableFunction = useActionsTable(company.companyId, t);

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
            // totalFakeDataMultiply: 650
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default NotificationList;
