import { apiDirectCall } from "customHooks/useApi";
import _ from "lodash";
import {
    IJobViewContextDispatch,
    IJobViewStages,
    IMainState
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import {
    getSortStagesParams,
    getStateFromDB,
    getTotalStageFromDb,
    handleOrderData
} from "../../helper";
export interface IHandleSortColumn {
    localValue: {
        name: string;
        sortType: "asc" | "desc";
    };
    data: IMainState;
    stageData: IJobViewStages;
    dispatchJobContext: IJobViewContextDispatch;
    replacePriority: boolean;
}
export const priorityOrderName: "_priority" = "_priority";

export const handleSort = async (params: IHandleSortColumn) => {
    const { localValue, replacePriority } = params;
    const callReplaceSort =
        replacePriority && localValue?.name !== priorityOrderName;
    if (callReplaceSort) {
        await replaceSort(params);
    } else {
        await setSort(params);
    }
};

const setSort = async (params: IHandleSortColumn) => {
    const { localValue, data, stageData, dispatchJobContext } = params;

    const sortId = localValue?.name;
    const sortType = localValue?.sortType;

    const currentFilter = _.cloneDeep(data.fullFilter);
    const stageConfig = _.cloneDeep(data.LocalStateData.stagesConfig || {});

    let newSort: any = { name: sortId, sortType: sortType };
    if (sortId === priorityOrderName) {
        newSort = undefined;
    }
    stageConfig[stageData.id] = {
        currentSort: newSort
    };
    const stagesParams = getSortStagesParams(stageConfig);
    // currentFilter.sort = newSort;
    currentFilter.pagination = {
        currentPage: 1,
        pageSize: 30,
        fromValue: 0
    };
    currentFilter.otherGenericParams = {
        ...currentFilter.otherGenericParams,
        filterStageIds: [stageData.id],
        stageBody: { ...stagesParams }
    };
    const dataApplicant = await apiDirectCall(
        data.apiKanbanListAddress,
        "post",
        currentFilter
    );
    const newStateData = getStateFromDB({
        dbData: dataApplicant,
        stateData: data.LocalStateData.ApplicantsData,
        orderData: data.LocalStateData.orderData,
        clearApplicantsStages: [stageData.id],
        onlyUpdateStagesFromDB: true
    });

    dispatchJobContext.dispatchGeneralPageInfo({
        payload: {
            stagesConfig: stageConfig,
            filter: currentFilter,
            ApplicantsData: newStateData
        },
        type: "update"
    });
};

const replaceSort = async (params: IHandleSortColumn) => {
    const { localValue, data, stageData, dispatchJobContext } = params;

    const sortId = localValue?.name;
    const sortType = localValue?.sortType;

    const currentFilter = _.cloneDeep(data.fullFilter);
    const stageConfig = _.cloneDeep(data.LocalStateData.stagesConfig || {});

    const newSort = { name: sortId, sortType: sortType };
    stageConfig[stageData.id] = {
        currentSort: undefined
    };
    const stagesParams = getSortStagesParams(stageConfig);
    currentFilter.pagination = {
        currentPage: 1,
        pageSize: 30,
        fromValue: 0
    };
    currentFilter.otherGenericParams = {
        ...currentFilter.otherGenericParams,
        filterStageIds: [stageData.id],
        stageBody: { ...stagesParams }
    };
    const dataOrderDB = await apiDirectCall(
        data.apiKanbanOrderDataAddress,
        "post",
        {
            ...data.orderDataFilter,
            replacePriorityByOrder: true,
            geoLocation: data.fullFilter.geoLocation,
            replacePriorityObj: {
                stages: [stageData.id],
                sort: {
                    sortName: newSort.name,
                    orderType: newSort.sortType
                }
            }
        }
    );
    const stagesTotal = getTotalStageFromDb(
        dataOrderDB.data,
        data.LocalStateData.allStages
    );
    const orderData =
        dataOrderDB?.data?.orderPipelineData?.priority?.orderData || [];
    const newOrderData = handleOrderData(orderData);
    const dataApplicant = await apiDirectCall(
        data.apiKanbanListAddress,
        "post",
        currentFilter
    );
    const newStateData = getStateFromDB({
        dbData: dataApplicant,
        stateData: data.LocalStateData.ApplicantsData,
        orderData: newOrderData,
        clearApplicantsStages: [stageData.id],
        onlyUpdateStagesFromDB: true
    });

    dispatchJobContext.dispatchGeneralPageInfo({
        payload: {
            stagesConfig: stageConfig,
            filter: currentFilter,
            ApplicantsData: newStateData,
            orderData: newOrderData,
            stagesOnlyTotal: stagesTotal
        },
        type: "update"
    });
};
