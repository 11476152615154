import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import { FieldProps } from "formik";
import _ from "lodash";
import {
    IInputProps,
    IReturnUseRhfFieldControlled,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

export interface IGenericFormatFieldProps {
    format: string;
    fieldRHFDef: IReturnUseRhfFieldControlled;
}

const GenericFormatField = (props: IGenericFormatFieldProps & IInputProps) => {
    const { label, helperTextDefault, format, fieldRHFDef, ...other } = props;

    const { message, error, field, setFieldValue } = fieldRHFDef;

    return (
        <NumberFormat
            id={field.name}
            label={label}
            helperText={message}
            // {...other}
            // format="##.###.###/####-##"
            error={error}
            format={format}
            customInput={TextField}
            // {...field} avoid to add this because can impact setFocus property (https://github.com/compleoteam/compleo/issues/344)
            onBlur={field.onBlur}
            value={field.value}
            onChange={field.onChange}
            // onValueChange={(values) => {
            //     if (values.value === null) {
            //         setFieldValue(undefined);
            //     } else {
            //         setFieldValue(values.value);
            //     }
            // }}
            inputRef={field.ref}
            type="text"
        />
    );
};

export default GenericFormatField;
