import { Auth } from "aws-amplify";
import { helperAmplifyMessage } from "functions/helperAmplifyMessages";

export const firstAccessFunction = (
    setIsLoading: any,
    loginFN: any,
    t: any,
    token: string
) => {
    return async (values: any) => {
        setIsLoading(true);

        let retorno: { [k: string]: any } = {};

        try {
            const user = await Auth.signUp({
                username: values.email,
                password: values.password,
                attributes: {
                    email: values.email,
                    "custom:isInternalUser": "true",
                    "custom:tokenSignUp": token
                }
            });

            if (user) {
                // const dataConfirm = await confirmUser({ email: values.email });

                // if (dataConfirm) {
                await loginFN(values.email, values.password);
                retorno = { status: 200 };
                // }
            } else {
                retorno = { status: 400 };
            }
            setIsLoading(false);
            return retorno;
        } catch (err) {
            // throw err;
            retorno = { status: 400 };
            retorno.data = {};
            retorno.data.message = [
                {
                    dataPath: "password",
                    message: helperAmplifyMessage(err.code, t)
                }
            ];
            setIsLoading(false);
            return retorno;
        }
    };
};
