import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles, Theme } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";
import { useNotification } from "_ReactContext/NotificationContext";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const topSpace = 7;

const useStyles1 = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: green[600],
        marginTop: theme.spacing(topSpace)
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        marginTop: theme.spacing(topSpace)
    },
    info: {
        backgroundColor: theme.palette.primary.main,
        marginTop: theme.spacing(topSpace)
    },
    warning: {
        backgroundColor: amber[700],
        marginTop: theme.spacing(topSpace)
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
}));

export interface IProps {}

const MySnackbarContentWrapper = (props: IProps) => {
    // const [open, setOpen] = React.useState(false);
    const { clearMessage, notification } = useNotification();
    const classes = useStyles1();
    const {
        message,
        typeMessage = "success",
        snackBarOrign,
        className,
        enabled,
        ...other
    } = notification;
    const variant = typeMessage;
    const Icon = variantIcon[variant];

    // const dispatch = useDispatch();

    const handleCloseMessage = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (
            reason === "clickaway" ||
            (notification.timeout === null && reason === "timeout")
        ) {
            return;
        }
        clearMessage();
    };

    return (
        <Snackbar
            anchorOrigin={snackBarOrign}
            open={enabled}
            autoHideDuration={notification.timeout}
            onClose={(e: any) => handleCloseMessage(e, "timeout")}
        >
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={(e: any) => handleCloseMessage(e, "click")}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
                {...other}
            />
        </Snackbar>
    );
};

export default MySnackbarContentWrapper;
