import React from "react";

const useFormikCallBack = () => {
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);
    const formikReturn = React.useCallback(
        (value: any) => {
            if (value) {
                const values = (value || {}).values || {};
                const allBranchs = values?.filters_allBranchs === true;
                const allCustomers = values?.filters_allCustomers === true;
                let newFieldsToHide = [...fieldsToHide];

                if (
                    !allBranchs &&
                    newFieldsToHide.includes("filters_branchs")
                ) {
                    setFieldsToHide(
                        newFieldsToHide.filter(
                            (field) => field !== "filters_branchs"
                        )
                    );
                }
                if (
                    allBranchs &&
                    !newFieldsToHide.includes("filters_branchs")
                ) {
                    setFieldsToHide([...newFieldsToHide, "filters_branchs"]);
                }
                if (
                    !allCustomers &&
                    newFieldsToHide.includes("filters_customers")
                ) {
                    setFieldsToHide(
                        newFieldsToHide.filter(
                            (field) => field !== "filters_customers"
                        )
                    );
                }
                if (
                    allCustomers &&
                    !fieldsToHide.includes("filters_customers")
                ) {
                    setFieldsToHide([...fieldsToHide, "filters_customers"]);
                }
            }
        },
        [fieldsToHide]
    );
    return { formikReturn, fieldsToHide };
};

export default useFormikCallBack;
