import React from "react";
import useJobContext from "Pages/Job/JobView/useJobContext";
import ApplicantViewHeaderButtonRemoveFromJob from "./ApplicantViewHeaderButtonRemoveFromJob";
import { ApplicantViewJobDataInsideJob } from "./useApplicantViewContext/ApplicantViewTypes";

interface IProps {
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
}

const RemoveFromJobInsideJobContainer = (props: IProps) => {
    const jobDataContext = props.jobDataInsideJob?.jobDataContext || {};

    if (!jobDataContext.readonly) {
        return <ApplicantViewHeaderButtonRemoveFromJob />;
    } else {
        return null;
    }
};

export default RemoveFromJobInsideJobContainer;
