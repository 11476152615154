import React from "react";
import "../i18n/i18n";
import { i18f } from "../i18n/i18n";

const UnAuthenticatedNotFound = (props: any) => {
    const plan = "noplan";
    React.useEffect(() => {
        const urli18n = `${process.env.REACT_APP_API_ADDRESS}/general/translation/0/0`;
        const currentPath = localStorage.getItem("i18nPath");

        if (urli18n !== currentPath) {
            i18f(urli18n);
        }
    }, [plan]);

    // //debugger;
    // return React.cloneElement(props.children, {
    //     ...props
    // });
    return <React.Fragment>{props.children}</React.Fragment>;
};

export default UnAuthenticatedNotFound;
