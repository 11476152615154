import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useValuesFromSource from "customHooks/useValuesFromSource";

const UpdateProfile = (props: any) => {
    const [t, i18n, readyTranslation] = useTranslation("PROFILEUPDATE", {
        useSuspense: false
    });

    const [metadata] = useGetMetadata("PROFILEUPDATE");

    const postAddress = `/profile/update`;
    const [updateProfileReturn, updateProfile] = useApi(postAddress, "post");
    // const [listDefinition, ready] = useCustomLists();

    const postAddressSearch = `/profile/search`;
    const [getProfileReturn] = useApiCache(
        postAddressSearch,
        "post",
        {},
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getProfileReturn,
        ["data", "data"]
    );

    // console.log(listDefinition);

    valuesFromSource.companyId = 0;
    if (valuesFromSource.timeZone !== null) {
        valuesFromSource.timeZone = { value: valuesFromSource.timeZone };
    }
    if (valuesFromSource.receiveNotificationsByEmail !== true) {
        valuesFromSource.receiveNotificationsByEmail = false;
    }

    const [formCompleo, finished] = useCompleoForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: updateProfile,
        postReturn: updateProfileReturn,
        FormType: FormClassic,
        redirectAddress: "/",
        metadadosRetorno: metadata,
        valuesFromSource,
        invalidateQueryClientItems: [postAddressSearch]
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const readyAll =
        finished && readyTranslation && getProfileReturn.status === "success";
    if (readyAll) {
        compRetorno = formCompleo;
        return (
            <Page featureMainDescription={t("a_PageName")}>{compRetorno}</Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default UpdateProfile;
