import React, { useReducer } from "react";
import { useField, useFormikContext } from "formik";
import MainEmailData from "./MainEmailData";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Button, Grid } from "@material-ui/core";
import ButtonAddNewLanguage from "./ButtonAddNewLanguage";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useEmailTemplate, { useEmailTemplateType } from "./useEmailTemplate";
import { getTemplatesFromEmailDataDB } from "./EmailDataHelper";
import ButtonLoadTemplate from "./ButtonLoadTemplate";
import _ from "lodash";

export interface ITemplateEmailData {
    id: string;
    label: string;
    items: { default: boolean; id: string; language: string }[];
}
interface IProps {
    metadata: any;
    name: string;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    helperTextDefault: null | string;
    type?: string;
}

const reducerIdLoaded = (state: string[], id: string) => {
    state.push(id);
    return state;
};

const EmailDataField = (props: IProps) => {
    const { metadata, t, classes, required, name, type: typeFromProps } = props;

    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const [idListLoadedFromDB, dispatchNewIdLoadedFromDB] = useReducer(
        reducerIdLoaded,
        []
    );
    const companyData = useAuthState();
    const { errors, touched } = useFormikContext();

    const [field, meta, helpers] = useField(props.name);

    const loadTemplates = metadata?.elementConfig?.loadTemplates !== false;
    const multipleLanguages =
        metadata?.elementConfig?.multipleLanguages !== false;

    const type = typeFromProps || metadata?.elementConfig?.type;
    const hideSubject = metadata?.elementConfig?.hideSubject === true;

    const emailTemplateData = useEmailTemplate(type);
    const {
        placeholdersBodyList,
        placeholdersSubjectList,
        placeholdersBodyRequiredList,
        ready: readyEmailTemplate
    } = emailTemplateData;

    const ids = (field.value || []).map((item: any) => item.id);
    const [initialIds] = React.useState(ids);

    const updateFieldsFromDB = async (
        idList: string[],
        emailData: useEmailTemplateType
    ) => {
        const fieldToUpdate: Compleo.IObject[] = [];
        for (const idToLoad of idList) {
            if (idToLoad && !idListLoadedFromDB.includes(idToLoad)) {
                const currentFieldValue =
                    field.value.filter(
                        (item: any) => item.id === idToLoad
                    )[0] || {};

                if (
                    !currentFieldValue?._tempEmailData?.subject &&
                    !currentFieldValue?._tempEmailData?.body
                ) {
                    const dataReturn = await apiDirectCall(
                        "/email/getemail",
                        "post",
                        {
                            id: idToLoad,
                            companyId: companyData.company.companyId
                        }
                    );

                    const mainData = dataReturn?.data?.data;
                    const body = mainData?.body;
                    const subject = mainData?.subject;
                    const attachments = mainData?.attachments;

                    fieldToUpdate.push({
                        ...currentFieldValue,
                        _tempEmailData: {
                            body: body,
                            subject: subject,
                            attachments: attachments,
                            attachments_filesDefinition: {
                                values: [],
                                multiple: true
                            },
                            arrayPlaceHolderSubject: emailData.placeholdersSubjectList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBody: emailData.placeholdersBodyList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBodyRequired: emailData.placeholdersBodyRequiredList.map(
                                (item) => item.value
                            )
                        }
                    });
                }
                dispatchNewIdLoadedFromDB(idToLoad);
            }
        }
        return fieldToUpdate;
    };

    React.useEffect(() => {
        if (readyEmailTemplate) {
            updateFieldsFromDB(initialIds, emailTemplateData).then(
                (fieldToUpdate: Compleo.IObject[]) => {
                    if (fieldToUpdate.length) {
                        helpers.setValue(fieldToUpdate);
                    }
                }
            );
        }
    }, [initialIds, readyEmailTemplate]);

    React.useEffect(() => {
        // Load placeholders for new items
        const currentValue = field.value || [];
        if (readyEmailTemplate && currentValue.length) {
            let updated = false;
            for (let index = 0; index < currentValue.length; index++) {
                if (!currentValue[index].id) {
                    currentValue[index] = {
                        ...currentValue[index],
                        _tempEmailData: {
                            ...currentValue[index]._tempEmailData,
                            arrayPlaceHolderSubject: emailTemplateData.placeholdersSubjectList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBody: emailTemplateData.placeholdersBodyList.map(
                                (item) => item.value
                            ),
                            arrayPlaceHolderBodyRequired: emailTemplateData.placeholdersBodyRequiredList.map(
                                (item) => item.value
                            )
                        }
                    };
                    updated = true;
                }
            }
            if (updated) {
                helpers.setValue(currentValue);
            }
        }
    }, [(field.value || []).length, readyEmailTemplate]);

    React.useEffect(() => {
        const mainData = field.value || [];
        if (mainData.length && !mainData[0].language) {
            mainData[0].language = defaultLanguage;
        }
        helpers.setValue(mainData);
    }, [defaultLanguage]);

    const ready = readyLanguages && readyEmailTemplate;

    const tabs: TabTypeCompleo[] = [];
    if (ready) {
        let forceTabFocus = false;
        (field.value || []).map((item: any, index: number) => {
            const labelTab = item.default
                ? `${t(`Language_${item.language}`)} - ${t(
                      "EMAILDATAFIELD_default"
                  )}`
                : t(`Language_${item.language}`);

            const tabHasError = verifyTabHasError(errors, touched, index);
            if (tabHasError && !forceTabFocus) {
                forceTabFocus = true;
            }

            const TabLanguage = (
                <MainEmailData
                    {...props}
                    arrayNumber={index}
                    placeholdersSubject={placeholdersSubjectList}
                    placeholders={placeholdersBodyList}
                    idListLoadedFromDB={idListLoadedFromDB}
                    language={item.language}
                    hideSubject={hideSubject}
                />
            );
            tabs.push({
                label: labelTab,
                Component: TabLanguage,
                hasError: tabHasError,
                forceFocus: forceTabFocus
            });
            forceTabFocus = false;
        });
    }

    const oneLanguage = (languages || []).length < 2;
    if (ready) {
        return (
            <Grid container>
                {oneLanguage || !multipleLanguages ? (
                    <>
                        {loadTemplates && (
                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                <ButtonLoadTemplate
                                    mainFieldName={props.name}
                                    t={t}
                                    type={type}
                                    buttonPositionEnd={false}
                                    arrayPlaceHolderBody={placeholdersBodyList.map(
                                        (item) => item.value
                                    )}
                                    arrayPlaceHolderSubject={placeholdersSubjectList.map(
                                        (item) => item.value
                                    )}
                                    splitTemplatesByLanguage={true}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid container item xs={12}>
                        <Grid container item xs={12} sm={6}>
                            <ButtonAddNewLanguage
                                mainFieldName={props.name}
                                t={t}
                            />
                        </Grid>
                        {loadTemplates && (
                            <Grid container item xs={12} sm={6}>
                                <ButtonLoadTemplate
                                    mainFieldName={props.name}
                                    t={t}
                                    type={type}
                                    arrayPlaceHolderBody={placeholdersBodyList.map(
                                        (item) => item.value
                                    )}
                                    arrayPlaceHolderSubject={placeholdersSubjectList.map(
                                        (item) => item.value
                                    )}
                                    splitTemplatesByLanguage={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    {oneLanguage || !multipleLanguages ? (
                        tabs[0]?.Component || null
                    ) : (
                        <CompleoTabs t={t} tabs={tabs} />
                    )}
                </Grid>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const verifyTabHasError = (errors: any, touched: any, index: number) => {
    const fieldsToVerify = ["_tempEmailData.body", "_tempEmailData.subject"];
    const fieldObjErros = (errors?.template || [])[index] || null;
    const fieldObjTouched = (touched?.template || [])[index] || null;
    let hasError = false;
    if (fieldObjErros && fieldObjTouched) {
        fieldsToVerify.forEach((field) => {
            if (_.get(fieldObjErros, field) && _.get(fieldObjTouched, field)) {
                hasError = true;
            }
        });
    }
    return hasError;
};

export default EmailDataField;
