import React from "react";
import { Button } from "@material-ui/core";
import { getLastIndexFromGroup } from "./helperPipeline";
import AddIcon from "@material-ui/icons/Add";

interface IPropsAddStageButtom {
    handleAddStage: any;
    group: string;
    listStages: Compleo.IObject[];
    t: any;
}

const AddStageButtom = (props: IPropsAddStageButtom) => {
    const { handleAddStage, group, listStages, t } = props;
    const lastIndexFromGroup = getLastIndexFromGroup(group, listStages);

    return (
        <Button
            // variant="contained"
            color="secondary"
            size="small"
            style={{ margin: "16px" }}
            // className={classes.button}
            startIcon={<AddIcon />}
            onClick={() =>
                handleAddStage(
                    lastIndexFromGroup.lastIndex + 1,
                    lastIndexFromGroup.lastType
                )
            }
        >
            {t("button_addStage")}
        </Button>
    );
};

export default AddStageButtom;
