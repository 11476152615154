import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "_ReactContext/AuthContext";
import GridLayout from "react-grid-layout";
import useDashboardContext from "../useDashboardContext";
import React from "react";
import {
    IDashboardMainDef,
    IDashboardMainState
} from "../useDashboardContext/DashboardTypes";
import { useApi, useApiCache } from "customHooks/useApi";
import _ from "lodash";

type breakpointsListType = "lg" | "md" | "sm" | "xs" | "xss";
export const breakpointsList: breakpointsListType[] = [
    "lg",
    "md",
    "sm",
    "xs",
    "xss"
];

interface IUseDynamicDashboardGetDefaultData {
    moduleName: string;
}

const useDynamicDashboardGetDefaultData = (
    props: IUseDynamicDashboardGetDefaultData
) => {
    const { moduleName } = props;
    const [data, dispatch] = useDashboardContext();

    const { isFeatureAuthorized } = useAuthorization();
    const isAdmin = isFeatureAuthorized("DashboardAdmin"); //TODO: Criar feature
    // const accessType = isAdmin ? "admin" : "user";
    const accessType: CompleoShared.Dashboard.accessType = "admin";

    const [metadata] = useGetMetadata(moduleName);

    const buckets =
        metadata.response.data?.ElasticSearchListDefinition?.buckets;
    const otherSettings =
        metadata.response.data?.ElasticSearchListDefinition?.otherSettings;

    const widgets: Compleo.IObject = {};
    Object.keys(buckets || {}).map((bucketName) => {
        const showOnly: string[] | undefined = buckets[bucketName].showOnly;

        if (!showOnly || showOnly.includes("table")) {
            widgets[`${bucketName}_table`] = buckets[bucketName];
        }
        if (!showOnly || showOnly.includes("graph")) {
            widgets[`${bucketName}_graph`] = buckets[bucketName];
        }
    });

    const breakpoints = otherSettings?.[`widgetGridLayout_${accessType}`]
        ?.breakpoints || {
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 480,
        xxs: 0
    };
    const cols = otherSettings?.[`widgetGridLayout_${accessType}`]?.cols || {
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2
    };
    const defaultItemsToShow =
        otherSettings?.[`widgetGridLayout_${accessType}`]?.defaultItemsToShow ||
        [];

    const defaultWidgets: string[] = [];
    const allWidgets: string[] = [];
    for (const widgetName of Object.keys(widgets)) {
        const accessTypeWidget = widgets[widgetName].accessType || "admin";
        if (accessTypeWidget.includes(accessType)) {
            if (widgets[widgetName].defaultPage === true) {
                defaultWidgets.push(widgetName);
            }
            allWidgets.push(widgetName);
        }
    }

    for (const itemToShow of defaultItemsToShow) {
        const itemName = `${itemToShow.bucket}_${itemToShow.type}`;
        if (allWidgets.includes(itemName)) {
            defaultWidgets.push(itemName);
        }
    }

    const layouts: GridLayout.Layouts = {};
    for (let index = 0; index < breakpointsList.length; index++) {
        const element = breakpointsList[index];
        let axisX = 0;
        let axisY = 0;
        const colsBreakpoint = cols[element];
        layouts[element] = [];

        for (const widgetName of defaultWidgets) {
            const returnGrid = calculateGrid(
                layouts,
                element,
                widgetName,
                colsBreakpoint,
                axisX,
                axisY
            );
            axisX = returnGrid.axisX;
            axisY = returnGrid.axisY;
        }
        for (const widgetName of Object.keys(widgets)) {
            if (!defaultWidgets.includes(widgetName)) {
                const returnGrid = calculateGrid(
                    layouts,
                    element,
                    widgetName,
                    colsBreakpoint,
                    axisX,
                    axisY
                );
                axisX = returnGrid.axisX;
                axisY = returnGrid.axisY;
            }
        }
    }

    React.useEffect(() => {
        if (
            Object.keys(layouts).length &&
            defaultWidgets.length &&
            allWidgets.length
        ) {
            const dashboardDef: IDashboardMainDef = {
                layouts: layouts,
                widgetList: defaultWidgets,
                widgetListAllOptions: allWidgets
            };
            dispatch.dispatchGeneralPageInfo({
                payload: { compleoDashboardDef: dashboardDef },
                type: "update"
            });
        }
    }, [Object.keys(layouts).length, defaultWidgets.length, allWidgets.length]);

    const ready =
        metadata.status === "success" &&
        data?.localStateData?.compleoDashboardDef !== null &&
        data?.localStateData?.compleoDashboardDef !== undefined;

    return {
        breakpoints,
        cols,
        widgets,
        ready,
        accessType
    };
};

const calculateGrid = (
    layouts: GridLayout.Layouts,
    breakpoint: breakpointsListType,
    widgetName: string,
    colsBreakpoint: number,
    axisX: number,
    axisY: number
) => {
    let w = 4;
    let h = 3;

    switch (breakpoint) {
        case "lg":
            w = 4;
            break;
        case "md":
            w = 5;
            break;
        case "sm":
            w = 6;
            break;
        case "xs":
            w = 4;
            break;
        default:
            break;
    }

    layouts[breakpoint].push({
        i: widgetName,
        x: axisX,
        y: axisY,
        w: w,
        h: h,
        minH: h,
        minW: w
    });
    axisX = axisX + w;
    if (axisX >= colsBreakpoint) {
        axisX = 0;
        axisY = axisY + h;
    }

    return { axisX, axisY };
};

const useDynamicDashboard = (props: IUseDynamicDashboardGetDefaultData) => {
    const [data, dispatch] = useDashboardContext();
    const defaultData = useDynamicDashboardGetDefaultData(props);
    const [mainReady, setMainReady] = React.useState(false);

    const postAddressSearch = `/reports/listdynamicdashboards`;
    const [getListDashboard, executeListDashboard] = useApiCache(
        postAddressSearch,
        "post",
        {
            isAdmin: true,
            companyId: data.companyId,
            dashboardName: props.moduleName
        },
        false
    );
    const listData = getListDashboard.response?.data || [];
    const listReady = getListDashboard.status === "success";

    React.useEffect(() => {
        executeListDashboard();
        setMainReady(false);
    }, [data.localStateData?.pageToRender]);

    React.useEffect(() => {
        if (listReady && defaultData.ready) {
            const dashData = getDashboard(data, listData);
            const dashList = getDashboardList(data, listData);
            const defaultDashData = _.cloneDeep(
                data.localStateData?.compleoDashboardDef
            );
            const mainDash = listData.filter((l: Compleo.IObject) =>
                l.skGS1pk.includes("TYPE:MAIN")
            )[0];
            const mainDashToRender: IDashboardMainDef = {
                layouts: mainDash?.gridLayoutDef?.layouts || {},
                widgetList: mainDash?.gridLayoutDef?.widgetList || {},
                widgetListAllOptions:
                    data.localStateData?.compleoDashboardDef
                        ?.widgetListAllOptions || []
            };

            if (dashData) {
                dispatch.dispatchGeneralPageInfo({
                    payload: {
                        currentDashboardDef: dashData,
                        mainDashboardDef: mainDashToRender || defaultDashData,
                        dashboardList: dashList
                    },
                    type: "update"
                });
            } else {
                dispatch.dispatchGeneralPageInfo({
                    payload: {
                        currentDashboardDef: defaultDashData,
                        dashboardList: dashList
                    },
                    type: "update"
                });
            }
            setMainReady(true);
        }
    }, [
        listData.length,
        defaultData.ready,
        listReady,
        data.localStateData?.pageToRender,
        mainReady
    ]);

    return { ...defaultData, ready: mainReady };
};

const getDashboard = (
    dataContext: IDashboardMainState,
    listData: Compleo.IObject[]
) => {
    const dashData = getDashboardList(dataContext, listData);
    const dashIdToRender = getLastDashboardToRender(dashData);
    const dashToRender =
        dashData.filter((i) => i.id === dashIdToRender)[0] || null;

    if (dashToRender) {
        return dashToRender;
    } else {
        const dashMainData = listData.filter((item: Compleo.IObject) =>
            item.skGS1pk.includes("TYPE:MAIN#")
        )[0];
        if (dashMainData) {
            const dashToRender: IDashboardMainDef = {
                layouts: dashMainData?.gridLayoutDef?.layouts || {},
                widgetList: dashMainData?.gridLayoutDef?.widgetList || {},
                widgetListAllOptions:
                    dataContext.localStateData?.compleoDashboardDef
                        ?.widgetListAllOptions || []
            };
            return dashToRender;
        } else {
            return undefined;
        }
    }
};

const getDashboardList = (
    dataContext: IDashboardMainState,
    listData: Compleo.IObject[]
) => {
    const dashList: IDashboardMainDef[] = listData
        .filter(
            (item: Compleo.IObject) =>
                item.skGS1pk.includes("TYPE:CUSTOM#") ||
                item.skGS1pk.includes("TYPE:COMPANY#")
        )
        .map((item) => {
            const type = item.skGS1pk.includes("TYPE:CUSTOM#")
                ? "user"
                : "company";
            const id =
                type === "user"
                    ? item.skGS1pk.split("#")[3].split(":")[1]
                    : item.skGS1pk.split("#")[2].split(":")[1];
            return {
                name: item.name,
                layouts: item.gridLayoutDef?.layouts || {},
                widgetList: item.gridLayoutDef?.widgetList || [],
                widgetListAllOptions:
                    dataContext.localStateData?.compleoDashboardDef
                        ?.widgetListAllOptions || [],
                type: type,
                id: id
            };
        });

    return dashList;
};

const dashboardToRenderKey = "dynamicDashboardLastDashboardToRender";

const getLastDashboardToRender = (dashData: Compleo.IObject[]) => {
    const dataFromStorage = localStorage.getItem(dashboardToRenderKey);
    const dashIdList = dashData.map((i) => i.id);

    if (dataFromStorage && dashIdList.includes(dataFromStorage as string)) {
        const returnData = dataFromStorage as string;
        return returnData;
    } else {
        return null;
    }
};

export const setLastDynamicDashboardToRender = (
    dashData: IDashboardMainDef[],
    dashboardId?: string
) => {
    if (dashboardId) {
        const dashIdList = dashData.map((i) => i.id);

        if (dashboardId && dashIdList.includes(dashboardId)) {
            localStorage.setItem(dashboardToRenderKey, dashboardId);
        }
    } else {
        localStorage.removeItem(dashboardToRenderKey);
    }
};

export const useDynamicDashboardExternal = (
    dashboardId: string,
    evaluationId: string
) => {
    const [data, dispatch] = useDashboardContext();
    const [mainReady, setMainReady] = React.useState(false);

    const postAddressSearch = `/reports/listdynamicdashboardsexternal`;
    const [getListDashboard, executeListDashboard] = useApiCache(
        postAddressSearch,
        "post",
        {
            evaluationId: evaluationId,
            dashboardId: dashboardId
        },
        false
    );
    const listData = getListDashboard.response?.data?.dashboard;
    const listReady = getListDashboard.status === "success";

    React.useEffect(() => {
        executeListDashboard();
        setMainReady(false);
    }, [dashboardId, evaluationId]);

    React.useEffect(() => {
        if (listReady) {
            debugger;
            // const dashData = listData;
            const mainDash = listData;

            const mainDashToRender: IDashboardMainDef = {
                layouts: mainDash?.gridLayoutDef?.layouts || {},
                widgetList: mainDash?.gridLayoutDef?.widgetList || {},
                widgetListAllOptions:
                    data.localStateData?.compleoDashboardDef
                        ?.widgetListAllOptions || []
            };
            dispatch.dispatchGeneralPageInfo({
                payload: {
                    currentDashboardDef: mainDashToRender,
                    dashboardList: []
                },
                type: "update"
            });

            setMainReady(true);
        }
    }, [listData, listReady, data.localStateData?.pageToRender, mainReady]);

    return { ...listData, ready: mainReady };
};

export default useDynamicDashboard;
