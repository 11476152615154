import React from "react";
import JobFunnel from "../Funnel/JobFunnel";
import JobActivities from "../JobActivities/JobActivities";
import JobActivitiesRecruiter from "../JobActivities/JobActivitiesRecruiter";
import useJobContext from "../../useJobContext";
import JobViewDashboardMain from "../JobViewDashboard/JobViewDashboardMain";

interface IJobReportsSwitch {
    jobReportToShow: CompleoShared.Reports.IJobReportsToRender;
}

const JobReportsSwitch = (props: IJobReportsSwitch) => {
    const { jobReportToShow } = props;

    const [data] = useJobContext();

    switch (jobReportToShow) {
        case "funnel":
            return (
                <JobFunnel
                    jobId={data.JobId}
                    jobTitle={data.Job?.data?.title}
                />
            );
        case "dashboard":
            return <JobViewDashboardMain />;
        case "activities":
            return (
                <JobActivities
                    jobId={data.JobId}
                    jobTitle={data.Job?.data?.title}
                />
            );
        case "activitiesRecruiter":
            return (
                <JobActivitiesRecruiter
                    jobId={data.JobId}
                    jobTitle={data.Job?.data?.title}
                />
            );
        default:
            return null;
    }
};

export default JobReportsSwitch;
