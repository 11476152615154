import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";

export type ListDefinitionType = {
    fieldName: string;
    data: any[];
    valueName: string;
    labelName: string;
    childrenFilterFieldName?: string;
    parentFilterField?: {
        parentFieldName: string;
        parentIdFieldFilter: string;
        fieldFilter: string;
    };
};

const useCustomLists: () => [ListDefinitionType[], boolean] = () => {
    const { company } = useAuthState();
    const plan = company.plan || "noplan";
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition, setListDefinition] = React.useState<
        ListDefinitionType[]
    >([]);

    const [customers, callCustomers] = useApiCache(
        "/admin/customerlistdropdown",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );
    if (
        customers.status === "success" &&
        !listFilled(listDefinition, "customers")
    ) {
        listDefinition.push({
            fieldName: "customers",
            data: customers.response.data,
            valueName: "CustomerId",
            labelName: "CustomerName"
        });
    }

    const [modules, callModules] = useApiCache(
        "/admin/modulelist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    if (
        modules.status === "success" &&
        !listFilled(listDefinition, "modules") &&
        !listFilled(listDefinition, "submodules")
    ) {
        const data = modules.response.data.data || [];
        const filterFieldsData: Compleo.IObject[] = [];
        data.map((d: any) => {
            filterFieldsData.push({ name: d.name });
        });
        listDefinition.push({
            fieldName: "modules",
            data: filterFieldsData,
            childrenFilterFieldName: "submodules",
            valueName: "name",
            labelName: "name"
        });

        const submodules: any[] = [];
        modules.response.data.data.map((d: any) => {
            d.submodules.map((s: any) => {
                submodules.push({
                    value: s,
                    label: s,
                    parentFilterValue: d.name
                });
            });
        });
        listDefinition.push({
            fieldName: "submodules",
            data: submodules,
            parentFilterField: {
                parentFieldName: "modules",
                parentIdFieldFilter: "value",
                fieldFilter: "parentFilterValue"
            },
            valueName: "value",
            labelName: "label"
        });
    }
    if (!ready) {
        const readyModules = verifyRead([customers, modules]);
        if (readyModules) {
            setReady(true);
        }
    }
    return [listDefinition, ready];
};

const verifyRead = (api: ApiStateType[]) => {
    return api.filter((a: ApiStateType) => a.status !== "success").length === 0;
};

const listFilled = (
    listDefinition: ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

type objGenerate = { name: string; api: ApiStateType };

// const generateObject = (returns: objGenerate[], setListDefinition: any) => {
//     const notFinished = returns.filter(
//         (r: objGenerate) => r.api.status !== "success"
//     );

//     if (notFinished.length > 0) {
//         return false;
//     } else {
//         const listDefinitions: ListDefinitionType[] = [];
//         for (const obj of returns) {
//             listDefinitions.push({
//                 fieldName: "modules",
//                 data: obj.api.response.data
//             });
//         }
//         setListDefinition(listDefinitions);
//         return true;
//     }
// };

export default useCustomLists;
