import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { transformPivotData } from "./_CompleoPivotTableHelper";
import { Link } from "@gatsbyjs/reach-router";

// const columns: Column[] = [
//     { id: "name", label: "Name", minWidth: 170 },
//     { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
//     {
//         id: "population",
//         label: "Population",
//         minWidth: 170,
//         align: "right",
//         format: (value: number) => value.toLocaleString("en-US")
//     },
//     {
//         id: "size",
//         label: "Size\u00a0(km\u00b2)",
//         minWidth: 170,
//         align: "right",
//         format: (value: number) => value.toLocaleString("en-US")
//     },
//     {
//         id: "density",
//         label: "Density",
//         minWidth: 170,
//         align: "right",
//         format: (value: number) => value.toFixed(2)
//     }
// ];

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: 360
    }
});

interface ICompleoPivotTable {
    data: Compleo.IObject[];
    widget: CompleoShared.Dashboard.widgetsMetadata;
    t: any;
}

const CompleoPivotTable = (props: ICompleoPivotTable) => {
    const { data, widget, t } = props;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const finalData = transformPivotData({
        countRoot: "doc_count",
        dataRoot: "key",
        data: data,
        widget: widget,
        t: t
    });

    const columns = (
        finalData.filter((item) => item.itemType === "header")[0]?.data || []
    ).filter((item: Compleo.IObject) => item.type !== "idColumn");
    const rows = finalData.filter((item) => item.itemType === "row");

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column: Compleo.IObject) => (
                                <TableCell
                                    key={column.id}
                                    // align={column.align}
                                    // style={{ minWidth: column.minWidth }}
                                >
                                    <strong>{column.value}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {columns.map((column: Compleo.IObject) => {
                                        const rowInfo = row.data.filter(
                                            (r: Compleo.IObject) =>
                                                r.columnId === column.id
                                        )[0];
                                        const value = rowInfo?.value;
                                        const rowDef = (
                                            widget.pivotTableConfig?.rows || []
                                        ).filter(
                                            (i) =>
                                                i.mainColumnName ===
                                                rowInfo?.columnId
                                        )[0];

                                        return (
                                            <TableCell
                                                key={column.id}
                                                // align={column.align}
                                            >
                                                <GetRowValue
                                                    allRowData={row?.data || []}
                                                    rowDef={rowDef}
                                                    value={value}
                                                />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper>
    );
};

interface IGetRowValueProps {
    value: any;
    rowDef: CompleoShared.Dashboard.IRowsColumnsDashboard;
    allRowData: Compleo.IObject[];
}
const GetRowValue = (props: IGetRowValueProps) => {
    const { rowDef, value, allRowData } = props;
    if (!value) {
        return null;
    }
    switch (rowDef?.type) {
        case "jobLink":
            const jobId = allRowData.filter(
                (row) => row.columnId === rowDef.idColumnName
            )[0]?.value;
            return <Link to={`/job/view/${jobId}`}>{value}</Link>;

        default:
            return value;
    }
};

export default CompleoPivotTable;
