import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { i18n, TFunction } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { pipelineAllActionsFields, ModalDataType } from "./helperPipeline";
import { Grid } from "@material-ui/core";
import * as util from "functions/util";
import StageActionCustom from "./StageActionCustom";
import InformationNoActionsCustom from "./InformationNoActionsCustom";
import NewActionCustomField from "./NewActionCustomField";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        }
    })
);

const CustomComponents = [
    {
        name: "actionApplicantEmail",
        component: StageActionCustom
    },
    {
        name: "actionApplicantAddTags",
        component: StageActionCustom
    },
    {
        name: "actionApplicantSendEmailToUsers",
        component: StageActionCustom
    },
    {
        name: "actionApplicantRequestEvaluation",
        component: StageActionCustom
    },
    {
        name: "informationNoActions",
        component: InformationNoActionsCustom
    },
    {
        name: "newAction",
        component: NewActionCustomField
    }
];

const getFieldsToHide = (
    currentFieldsToHide: string[],
    typeValue?: "string",
    forceHideName: boolean = false
) => {
    if (typeValue) {
        const typesToHideTypeField = [
            "newApplied",
            "newSourced",
            "newReferred",
            "hired",
            "rejected"
        ];
        const typesToHideNameField = [
            "newApplied",
            "newSourced",
            "newReferred"
        ];
        const hideType: boolean = typesToHideTypeField.includes(typeValue);
        const hideName: boolean = typesToHideNameField.includes(typeValue);

        const fieldsToHide: string[] = [...currentFieldsToHide].filter(
            (item: string) => !["type", "name", "labelName"].includes(item)
        );
        if (hideType) {
            fieldsToHide.push("type");
        }
        if (hideName || forceHideName) {
            fieldsToHide.push("name");
        } else {
            fieldsToHide.push("labelName");
        }
        return fieldsToHide;
    }
};

interface IProps {
    modalData: ModalDataType;
    handleModalClose: any;
    t: TFunction;
    mainFieldName: string;
    setFieldValue: (value: any) => void;
    metadata: ApiStateType;
    i18n: i18n;
    readyTranslation: boolean;
    stageIdsWithApplicants?: string[];
}

const StageActionsModal = (props: IProps) => {
    const {
        handleModalClose,
        modalData,
        t,
        mainFieldName,
        setFieldValue,
        metadata,
        i18n,
        readyTranslation,
        stageIdsWithApplicants = []
    } = props;
    const classes = useStyles();

    const { watch } = useRhfFieldWithoutControllerOrRegister(mainFieldName);

    const lists: Compleo.ListDefinitionType[] = [];
    lists.push({
        fieldName: "type",
        data: modalData.typeList.map((item: any) => {
            return { ...item, label: item[`label-${i18n.languages[0]}`] };
        }),
        valueName: "value",
        labelName: "label"
    });

    const initialValues = { ...(modalData.stage || {}) };
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        false,
        initialValues
    );
    valuesFromSource.labelName = valuesFromSource.name;

    const closeModal = () => {
        handleModalClose();
    };

    let addStageReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);

    React.useEffect(() => {
        const typeValue = modalData.stage?.type?.value;
        const forceHideName = stageIdsWithApplicants.includes(
            modalData.stage?.id
        );

        const newFieldsToHide = getFieldsToHide(
            fieldsToHide,
            typeValue,
            forceHideName
        );
        if (newFieldsToHide) {
            setFieldsToHide(newFieldsToHide);
        }
    }, [modalData.stage?.type?.value]);

    const addStage = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        const fieldValue = watch(mainFieldName);

        const currentField = [...fieldValue];

        for (let action of pipelineAllActionsFields) {
            if (!values[action] || values[action].length === 0) {
                delete values[action];
            }
        }
        if (modalData.new) {
            const newId = util.uuid();
            currentField.splice(modalData.index, 0, { ...values, id: newId });
            const reorderedStages = currentField.map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        order: index
                    };
                }
            );
            setFieldValue(reorderedStages);
        } else {
            for (let action of pipelineAllActionsFields) {
                if (currentField[modalData.index]) {
                    delete currentField[modalData.index][action];
                }
            }
            currentField[modalData.index] = {
                ...currentField[modalData.index],
                ...values,
                maxItensKanban: values?.maxItensKanban,
                maxDaysInStage: values?.maxDaysInStage
            };
            setFieldValue(currentField);
        }

        handleModalClose();
        return retorno;
    };

    const initialValuesReady =
        (!modalData.new && !_.isEmpty(initialValues.name)) ||
        (modalData.new && !_.isEmpty(modalData.stage?.type));
    const ready =
        metadata.status === "success" && readyTranslation && initialValuesReady;

    const [formCompleo, finished, handleSubmit] = useCompleoReactHookForm({
        FormType: FormModal,
        t: t,
        i18nV: i18n,
        ready: ready,
        postMethod: addStage,
        postReturn: addStageReturn,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        customLists: lists,
        fieldsToHide: fieldsToHide,
        CustomComponents: CustomComponents
    });

    const handleSave = async () => {
        handleSubmit();
    };

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && readyTranslation) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            open={modalData.open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("a_ModalTitle")}
            aria-describedby={t("a_ModalTitle")}
            PaperProps={{
                className: classes.dialog
            }}
            scroll="body"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        {t("a_ModalTitle")}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>{compRetorno}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => closeModal()}
                    color="secondary"
                    disabled={!finished}
                >
                    {t("COMPLEOGENERAL_CANCEL")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={!finished}
                >
                    {t("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StageActionsModal;
