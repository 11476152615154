import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import {
    ApiCallType,
    apiDirectCall,
    ApiStateType,
    useApiCache
} from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useModalEvaluationList from "Pages/Applicant/ApplicantEvaluationRequest/useModalEvaluationList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsModal {
    open: boolean;
    onClose: () => void;
}

const JobReportsShareModal = (props: IPropsModal) => {
    const { onClose, open } = props;
    const [data, dispatchData] = useJobContext();

    const [isUpdating, setIsUpdating] = React.useState(false);

    const queryClient = useQueryClient();

    const module = "JOBREPORTSSHARE";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, true);
    const customerFilterIdList: string[] = [];
    if (data.Job.data?.customer?.value) {
        customerFilterIdList.push(data.Job.data?.customer?.value);
    }

    const [lists, readyLists] = useModalEvaluationList(
        t,
        readyTranslation,
        customerFilterIdList,
        []
    );

    const ready = readyTranslation && readyLists;

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        const postAddress = `/job/jobsharereports`;
        try {
            const dataAPI = await apiDirectCall(postAddress, "post", {
                ...values,
                jobId: data.JobId
            });
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries(`/applicant/searchfulldata`);
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: lists
    });

    const handleSave = () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            <DialogTitle>{t("modalTitle")}</DialogTitle>
            <DialogContent dividers>{FormReturn}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalClose", "")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave", "")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobReportsShareModal;
