import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApiCache } from "customHooks/useApi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Icon, IconButton, MuiThemeProvider, Input } from "@material-ui/core";
import { navigate } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import { zonedTimeToUtc, format, utcToZonedTime } from "date-fns-tz";
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    cell: {
        width: 106,
        padding: 5
    }
});

const InputsExampleList = (props: any) => {
    const classes = useStyles();
    const featureName = "INPUTSEXAMPLELIST";
    const [t, i18n, readyTranslation] = useTranslation(featureName, {
        useSuspense: false
    });
    const postAddress = "/inputsexample/list";
    const [listInputsExampleReturn, getInputs] = useApiCache(
        postAddress,
        "post"
    );

    const { company, timeZone } = useAuthState();

    const UserTimeZone =
        timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    React.useEffect(() => {
        if (company.companyId > 0) {
            getInputs({ companyId: company.companyId });
        }
    }, [company.companyId]);
    let InputsExample = [];

    if (listInputsExampleReturn.status === "success") {
        InputsExample =
            listInputsExampleReturn.response.data.inputsExample.Items;
    }

    let compRetorno: boolean | JSX.Element = <Loading />;

    if (listInputsExampleReturn.status === "success" && readyTranslation) {
        compRetorno = TableRetorno(classes, InputsExample, t, UserTimeZone);
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

const handleEdit = (event: any, id: any) => {};

const handleDelete = (event: any, id: any) => {};

const TableRetorno = (
    classes: any,
    rows: any[],
    t: TFunction,
    userTimeZone: string
) => {
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cell}></TableCell>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("cnpj")}</TableCell>
                        <TableCell>{t("cpf")}</TableCell>
                        <TableCell>{t("legalName")}</TableCell>
                        <TableCell>{t("interviewDate")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.skGS1pk}>
                            <TableCell
                                component="th"
                                scope="row"
                                className={classes.cell}
                            >
                                <IconButton
                                    onClick={(event: any) =>
                                        handleEdit(event, row.skGS1pk)
                                    }
                                >
                                    <Icon>edit</Icon>
                                </IconButton>
                                <IconButton
                                    onClick={(event: any) =>
                                        handleDelete(event, row.skGS1pk)
                                    }
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.cnpj}</TableCell>
                            <TableCell>{row.cpf}</TableCell>
                            <TableCell>{row.legalName}</TableCell>
                            <TableCell>
                                {format(
                                    utcToZonedTime(
                                        row.dataHoraEntrevista,
                                        userTimeZone
                                    ),
                                    "dd/MM/yyyy HH:mm"
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InputsExampleList;
