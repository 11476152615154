import { Box, Grid, Paper } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import JobFunnelGraph from "./components/JobFunnelGraph";
import FunnelItem from "./components/FunnelItem";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApiCache } from "customHooks/useApi";
import JobFunnelTable from "./components/JobFunnelTable";
import JobDisqualifyReasons from "./components/JobDisqualifyReasons";
import JobDisqualifyReasonsTable from "./components/JobDisqualifyReasonsTable";
import {
    IJobFunnelChildren,
    IMainJobFunnelData
} from "./components/funnelHelper";
import { Alert } from "@material-ui/lab";

const maxThemeSpacingParent = 60;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(1),
            // maxHeight: theme.spacing(maxThemeSpacingParent),
            height: "100%",
            maxWidth: "90vw"
        }
    })
);
interface IJobFunnel {
    jobId: string;
    jobTitle: string;
    externalEvaluationId?: string;
}

const JobFunnel = (props: IJobFunnel) => {
    const classes = useStyles();
    const { jobId, jobTitle, externalEvaluationId } = props;
    const { company } = useAuthState();
    const [t, i18n, readyTranslation] = useTranslation("JOBFUNNELREPORT", {
        useSuspense: false
    });

    const listAddress = !externalEvaluationId
        ? `/reports/jobfunnel`
        : `/reports/evaluationreportsexternal`;

    const reportToShow: CompleoShared.Reports.IJobReportsToRender = "funnel";
    const postData = !externalEvaluationId
        ? {
              jobId: jobId,
              companyId: company.companyId
          }
        : { evaluationId: externalEvaluationId, reportToShow: reportToShow };

    const [listFunnel] = useApiCache(listAddress, "post", postData, false);
    const data: IMainJobFunnelData = listFunnel?.response?.data || {};
    const ready = readyTranslation && listFunnel.status === "success";

    const childrenProps: IJobFunnelChildren = {
        mainData: data,
        t: t
    };

    const hasNoApplicants =
        ready && (data.data?.applicantsByStage || []).length === 0;

    if (!hasNoApplicants) {
        return (
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                        <FunnelItem ready={ready} t={t} itemName="funnelGraph">
                            <JobFunnelGraph {...childrenProps} />
                        </FunnelItem>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper className={classes.paper}>
                        <FunnelItem ready={ready} t={t} itemName="funnelTable">
                            <JobFunnelTable
                                {...childrenProps}
                                exportFileName={`FunnelTable_${jobTitle}`}
                            />
                        </FunnelItem>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                        <FunnelItem
                            ready={ready}
                            t={t}
                            itemName="reasonsForRejectionGraph"
                        >
                            <JobDisqualifyReasons {...childrenProps} />
                        </FunnelItem>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper className={classes.paper}>
                        <FunnelItem
                            ready={ready}
                            t={t}
                            itemName="reasonsForRejectionTable"
                        >
                            <JobDisqualifyReasonsTable
                                {...childrenProps}
                                exportFileName={`JobRejectedReasons_${jobTitle}`}
                            />
                        </FunnelItem>
                    </Paper>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12}>
                    <Alert severity="warning" style={{ margin: 16 }}>
                        {t("noApplicantsMessage")}
                    </Alert>
                </Grid>
            </Grid>
        );
    }
};

export default JobFunnel;
