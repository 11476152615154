import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from "@material-ui/core";
import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IProps {
    filterData: Compleo.IObject[];
    setFilterData: React.Dispatch<React.SetStateAction<Compleo.IObject[]>>;
    maxItemsToShow: number;
}

const SettingsModalList = (props: IProps) => {
    const classes = useStyles();
    const [listData, listDispatch] = useList();
    const { t } = listData.tempInfo;
    const { filterData, setFilterData, maxItemsToShow } = props;

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            const items = [...filterData];
            const itemToMove = items.filter(
                (item) => item.name === draggableId
            )[0];
            items.splice(source.index, 1);
            items.splice(destination.index, 0, itemToMove);
            const orderedItems = items.map((item, index) => {
                return { ...item, order: index + 1 };
            });
            setFilterData(orderedItems);
        }
    };

    return (
        <List component="nav" className={classes.root}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="mainDroppable"
                    direction="vertical"
                    type="forms"
                >
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {filterData
                                .sort((a, b) => a.order - b.order)
                                .map((item, index) => (
                                    <React.Fragment key={item.name}>
                                        <Item
                                            item={item}
                                            index={index}
                                            maxItemsToShow={maxItemsToShow}
                                            t={t}
                                        />
                                    </React.Fragment>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </List>
    );
};
interface IItemProps {
    item: Compleo.IObject;
    index: number;
    maxItemsToShow: number;
    t: any;
}

const Item = (props: IItemProps) => {
    const { item, index, maxItemsToShow, t } = props;

    return (
        <Draggable draggableId={item.name} index={index}>
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItem
                        button
                        dense
                        alignItems="center"
                        style={{
                            margin: 4
                        }}
                    >
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon color="action">drag_indicator</Icon>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: {
                                    fontWeight:
                                        index < maxItemsToShow ? 600 : undefined
                                }
                            }}
                            primary={t(
                                item.name,
                                t(`otherFilter_${item.name}`)
                            )}
                        />
                        <ListItemIcon>
                            <Tooltip title="">
                                <Icon
                                    color={
                                        index < maxItemsToShow
                                            ? "primary"
                                            : "action"
                                    }
                                >
                                    {index < maxItemsToShow
                                        ? "visibility"
                                        : "visibility_off"}
                                </Icon>
                            </Tooltip>
                        </ListItemIcon>
                    </ListItem>
                    <Divider />
                </div>
            )}
        </Draggable>
    );
};

export default SettingsModalList;
