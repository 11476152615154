import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { clearArrayInfo } from "functions/util";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2)
            }
        }
    })
);

interface ICompleoAlert {
    t: any;
    metadata: Compleo.IObject;
}

function createMarkup(value: string) {
    return { __html: value };
}

const CompleoAlert = (props: ICompleoAlert) => {
    const classes = useStyles();
    const { metadata, t } = props;
    const elementConfig = metadata?.elementConfig || {};
    const clearedFieldName = clearArrayInfo(metadata?.fieldName);
    const title = t(`${clearedFieldName}_Title`, "");

    return (
        <div className={classes.root}>
            <Alert {...elementConfig}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {
                    <div
                        dangerouslySetInnerHTML={createMarkup(
                            t(clearedFieldName)
                        )}
                    />
                }
            </Alert>
        </div>
    );
};

export default CompleoAlert;
