import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FileListItem from "./FileListItem";
import { matchSorter } from "match-sorter";
import { InputAdornment, List, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            fontSize: 13,
            "& input::placeholder": {
                fontSize: 13
            },
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(2)
        }
    })
);

interface IFileList {
    filesFromFormik: Compleo.IObject[];
    removeFile: (name: any) => void;
    editTags: (
        name: any,
        tags: string[],
        newFileName?: string | undefined
    ) => void;
    enableTags: boolean;
    t: any;
}

const FileList = (props: IFileList) => {
    const { filesFromFormik, removeFile, enableTags, t, editTags } = props;
    const classes = useStyles();
    const [textSearch, setTextSearch] = React.useState("");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };
    const filteredArray: Compleo.IObject[] = textSearch
        ? matchSorter(filesFromFormik, textSearch, {
              keys: [
                  "FileInfo.name",
                  "FileInfo.path",
                  "originalFileName",
                  "tags",
                  "FileInfo.tags"
              ]
          })
        : filesFromFormik;

    return (
        <React.Fragment>
            {filesFromFormik.length > 1 && (
                <TextField
                    className={classes.margin}
                    placeholder={t("CGT_search")}
                    fullWidth
                    value={textSearch}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <ButtonIconTooltipCompleo
                                    label="Clear"
                                    onClick={() => setTextSearch("")}
                                >
                                    <CloseIcon />
                                </ButtonIconTooltipCompleo>
                            </InputAdornment>
                        )
                    }}
                />
            )}
            <List dense>
                {filteredArray.map((fileparam: any) => {
                    return (
                        <FileListItem
                            key={
                                fileparam?.FileInfo?.name ||
                                fileparam?.originalFileName
                            }
                            enableTags={enableTags}
                            fileparam={fileparam}
                            removeFile={removeFile}
                            editTags={editTags}
                            t={t}
                        />
                    );
                })}
            </List>
        </React.Fragment>
    );
};

export default FileList;
