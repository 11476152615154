import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { getWidgetData } from "../Helpers/_DashboardHelper";
import SmartTable from "../SmartTable/SmartTable";
import useDashboardItemHelper from "./useDashboardItemHelper";
import DashboardMainItem from "./DashboardMainItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        count: {
            marginTop: 0
        }
    })
);

interface IDashboardPivotTable {
    widgetName: string;
    widget: CompleoShared.Dashboard.widgetsMetadata;
}

const DashboardSmartTable = (props: IDashboardPivotTable) => {
    const { widgetName, widget } = props;
    const classes = useStyles();
    const { t, i18n, ready, getData } = useDashboardItemHelper({
        widgetName: widgetName
    });
    const data = getData?.response?.data?.data;
    const graphData = getWidgetData({
        data: data,
        widgetDef: widget,
        widgetName: widgetName,
        language: i18n.languages[0],
        mapProps: widget.mapProps
    });
    return (
        <DashboardMainItem ready={ready} t={t} widgetName={widgetName}>
            <SmartTable
                data={graphData}
                widget={widget}
                t={t}
                language={i18n.languages[0]}
            />
        </DashboardMainItem>
    );
};

export default DashboardSmartTable;
