import React from "react";
import { useTranslation } from "react-i18next";
import ModalUpgradePlan, { IModalUpgradeProps } from "./ModalUpgradePlan";

export type featuresOtherPlan =
    | "bulkActionsATS"
    | "pipelineAutomation"
    | "bulkParser"
    | "dashboards"
    | "scorecard"
    | "emailParser";

const useFeatureUpgradePlan = (feature: featuresOtherPlan) => {
    const [isModalUpgradeOpen, setModalUpgradeIsOpen] = React.useState(false);
    const [t, i18n, readyTranslation] = useTranslation(
        "MODALFEATUREUPGRADEPLAN",
        {
            useSuspense: false
        }
    );

    const returnData: IModalUpgradeProps = {
        message: readyTranslation ? t(`messageFeature_${feature}`) : "",
        open: isModalUpgradeOpen,
        setOpen: setModalUpgradeIsOpen,
        t: t,
        ready: readyTranslation
    };

    return returnData;
};

export { ModalUpgradePlan };
export default useFeatureUpgradePlan;
