import React from "react";
import { Chip, Grid, Paper, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ElasticDefDataType, handleChangeBucketsFilter } from "./ListUtil";
import DeleteIcon from "@material-ui/icons/Delete";
// import SortMenuSelect from "./SortMenuSelect";
import useList, { IFilters } from "../index";
// import ListPagination from "./ListPagination";
// import ViewResultsOptions from "./ViewResultsOptions";

const useStyles = (simpleToolbar: boolean) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            box: {
                marginBottom: simpleToolbar
                    ? theme.spacing(0.2)
                    : theme.spacing(0.5),
                padding: 0,
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1)
            },
            root: {
                display: "flex",
                justifyContent: "left",
                flexWrap: "wrap",
                listStyle: "none",
                padding: theme.spacing(0.5),
                margin: 0
            },
            chip: {
                margin: theme.spacing(0.5)
            },
            formControl: {
                margin: theme.spacing(1),
                minWidth: 120
            },
            selectEmpty: {
                marginTop: theme.spacing(2)
            },
            resultsText: {
                marginLeft: theme.spacing(1)
            },
            viewResultListOptions: {
                marginRight: theme.spacing(1)
            }
        })
    );
};

interface IProps {
    simpleToolbar?: boolean;
}

const ResultListToolbar = (props: IProps) => {
    const { simpleToolbar = false } = props;
    const classes = useStyles(simpleToolbar)();
    const [listData, listDispatch] = useList();
    const { setFilters, setLocalInfo } = listDispatch;
    const { t } = listData.tempInfo;
    const { filters } = listData.filtersInfo;
    const { elasticDefData } = listData.definitionInfo;
    const {
        filtersUserInfo,
        totalFiltered,
        queryFinished,
        total
    } = listData.listReturnInfo;

    const handleDelete = (
        valueKey: string,
        descriptionKey: string,
        type: string
    ) => {
        switch (type) {
            case "bucket":
                handleChangeBucketsFilter(
                    false,
                    descriptionKey,
                    filters,
                    setFilters,
                    {
                        value: valueKey
                    }
                );
                break;

            default:
                break;
        }
    };

    const clearAll = () => {
        const newFilters: IFilters = { ...(filters || {}) };
        const bucketNames = Object.keys(newFilters.buckets || {});
        for (const bucketName of bucketNames) {
            delete newFilters.buckets[bucketName];
        }
        // newFilters.customSearch = {};
        // newFilters.advancedSearch = {};
        // newFilters.mainSearch = null;
        setFilters({ ...newFilters, filterUpdated: true });
    };

    const ready = simpleToolbar
        ? elasticDefData?.data !== undefined
        : queryFinished && elasticDefData?.data !== undefined;

    if (ready && filtersUserInfo.length > 0) {
        return (
            <Box className={classes.box}>
                <Paper className={classes.root} elevation={0}>
                    <Grid container>
                        {filtersUserInfo.length > 0 ? (
                            <Grid item xs={12}>
                                <Paper
                                    component="ul"
                                    className={classes.root}
                                    elevation={0}
                                >
                                    {filtersUserInfo.map(
                                        (filterInfo: Compleo.IObject) => {
                                            return (
                                                <li
                                                    key={`${filterInfo.descriptionKey}-${filterInfo.valueKey}`}
                                                >
                                                    <Chip
                                                        onDelete={() =>
                                                            handleDelete(
                                                                filterInfo.valueKey,
                                                                filterInfo.descriptionKey,
                                                                filterInfo.type
                                                            )
                                                        }
                                                        label={`${
                                                            filterInfo.description
                                                        }${
                                                            filterInfo.value
                                                                ? `: ${filterInfo.value}`
                                                                : ""
                                                        }`}
                                                        className={classes.chip}
                                                        size="small"
                                                    />
                                                </li>
                                            );
                                        }
                                    )}
                                    {filtersUserInfo.length > 1 ? (
                                        <li>
                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                className={classes.chip}
                                                icon={<DeleteIcon />}
                                                label={t(
                                                    "ELASTICLISTSETTINGS:searchItemsClearAll"
                                                )}
                                                onClick={clearAll}
                                            />
                                        </li>
                                    ) : null}
                                </Paper>
                            </Grid>
                        ) : null}
                    </Grid>
                </Paper>
            </Box>
        );
    } else {
        return null;
    }
};

export default ResultListToolbar;
