import React from "react";
import { FieldProps } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { currenciesList, formatMapCurrency } from "functions/formatValues";

interface ICurrency {
    currency?: string;
    value?: number | string;
}

interface IProps {
    label: string;
    helperTextDefault: null | string;
    value: ICurrency;
    language: "pt-BR" | "en-US" | "fr" | "es";
    maxLength?: number;
    decimalDigits?: number;
}

// export const formatMapCurrency = {
//     "pt-BR": {
//         thousandSeparator: ".",
//         decimalSeparator: ","
//     },
//     "en-US": {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     },
//     fr: {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     },
//     es: {
//         thousandSeparator: ",",
//         decimalSeparator: "."
//     }
// };

const useStyles = makeStyles({
    currencyClass: {
        minWidth: "20px"
    }
});

const CurrencyField = (props: IProps & FieldProps) => {
    //debugger;
    const {
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue },
        helperTextDefault,
        value,
        language,
        maxLength,
        decimalDigits,
        ...other
    } = props;

    const localClasses = useStyles();
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;

    // const errorText = errors[`${field.name}.value`];
    // const hasError = touched[`${field.name}.value`] && errorText !== undefined;

    //console.log("errorText", errorText); // !== undefined ? errorText.value : "");

    let errorMessage =
        hasError &&
        errorText !== undefined &&
        (errorText as ICurrency).value !== undefined
            ? String((errorText as ICurrency).value)
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (errorMessage === "") {
            errorMessage += status[field.name];
        } else {
            errorMessage += " - " + status[field.name];
        }
    }

    const handleValueChange = (prop: keyof ICurrency) => (
        values: NumberFormatValues
    ) => {
        setFieldValue(field.name, {
            ...field.value,
            [prop]: values.floatValue
        });
    };

    const handleCurrencyChange = (prop: keyof ICurrency) => (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setFieldValue(field.name, {
            ...field.value,
            [prop]: event.target.value
        });
    };
    // console.log("currencyValue", field);
    //console.log("errorMessage", errorMessage, field.value);
    return (
        <React.Fragment>
            <NumberFormat
                id={`${field.name}.value`}
                label={label}
                type="text"
                error={hasError || (status && status[field.name] !== undefined)}
                helperText={errorMessage}
                onValueChange={handleValueChange("value")}
                thousandSeparator={
                    formatMapCurrency[language].thousandSeparator
                }
                decimalSeparator={formatMapCurrency[language].decimalSeparator}
                isNumericString
                decimalScale={decimalDigits}
                fixedDecimalScale={true}
                customInput={TextField}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <NativeSelect
                                inputProps={{
                                    "aria-label": `${field.name}.currency`,
                                    className: localClasses.currencyClass
                                }}
                                value={(field.value || {}).currency}
                                onChange={handleCurrencyChange("currency")}
                            >
                                {currenciesList.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </NativeSelect>
                        </InputAdornment>
                    )
                }}
                {...field}
                onChange={undefined}
                value={(field.value || {}).value}
                {...other}
            />
        </React.Fragment>
    );
};

CurrencyField.defaultProps = {
    language: "pt-BR",
    decimalDigits: 2
};

export default CurrencyField;
