import { BulkActionsType } from "customHooks/useList/ListHelpers/BulkActions/helperBulkActions";
import { IJobViewStages } from "../../useJobContext/JobViewTypes";

export const getItemsToExcludeFromBulk: (
    stageData: IJobViewStages,
    allStages: IJobViewStages[]
) => BulkActionsType[] = (
    stageData: IJobViewStages,
    allStages: IJobViewStages[]
) => {
    if (stageData?.type === "rejected") {
        const totalRejectedStages = allStages.filter(
            (i) => i.type === "rejected"
        ).length;
        const returnData: BulkActionsType[] = [
            "applicantjob_disqualify",
            "applicantjob_addToStage"
        ];
        if (totalRejectedStages <= 1) {
            returnData.push("applicantjob_changeRejectedStage");
        }
        return returnData;
    }
    return ["applicantjob_requalify", "applicantjob_changeRejectedStage"];
};
