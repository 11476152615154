import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Icon } from "@material-ui/core";
import {
    actionsTableFunction,
    useCompleoSimpleList
} from "customHooks/useCompleoSimpleList";
import useEmailTemplate from "../../customHooks/useCompleoForm/Inputs/EmailDataV2/useEmailTemplate";
import useCareersActionsTable from "./components/useCareersActionsTable";
import DomainSettings from "./components/DomainSettings";

const CareersListSettings = (props: any) => {
    const moduleName = "CAREERSSETTINGSLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const emailTemplateData = useEmailTemplate();
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/careerssettings/list";
    const { company } = useAuthState();
    const [domainSettingsModal, setDomainSettingsModal] = React.useState({
        open: false,
        uniqueLink: ""
    });

    const openDomainSettingsModal = (uniqueLink: string) => {
        setDomainSettingsModal({
            open: true,
            uniqueLink
        });
    };

    const closeDomainSettingsModal = () => {
        setDomainSettingsModal({
            open: false,
            uniqueLink: ""
        });
    };

    const actions: actionsTableFunction = useCareersActionsTable(
        company.companyId,
        t,
        openDomainSettingsModal
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
            // totalFakeDataMultiply: 650
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    const ready = readyTranslation && finished && emailTemplateData.ready;
    if (ready) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                rightButton={{
                    text: t("buttonTopRight"),
                    action: () => navigate(`/careers/new`),
                    enabled: true,
                    startIcon: <Icon>add</Icon>
                }}
            >
                {compRetorno}
                <DomainSettings
                    open={domainSettingsModal.open}
                    handleClose={closeDomainSettingsModal}
                    uniqueLink={domainSettingsModal.uniqueLink}
                    t={t}
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CareersListSettings;
