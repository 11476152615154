import React from "react";
import Questions from "Pages/Questionnaire/components/Questions";
import { Grid } from "@material-ui/core";

interface IProps {}

const Questionnaire = (props: IProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Questions
                    questionsFieldName="screeningQuestionnaire.questions"
                    resultsFieldName="screeningQuestionnaire.results"
                    rankingFieldName="screeningQuestionnaire.enableRanking"
                    enableResultsByRankingScoreFieldName="screeningQuestionnaire.enableResultsByRankingScore"
                    name=""
                />
            </Grid>
        </Grid>
    );
};

export default Questionnaire;
