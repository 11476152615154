import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FilterList } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { applicantHistoryTypeList } from "./ApplicantHistoryHelper";

interface IProps {
    t: any;
    filterType: CompleoShared.Common.ApplicantHistoryType | "all";
    setFilterType: React.Dispatch<
        React.SetStateAction<CompleoShared.Common.ApplicantHistoryType | "all">
    >;
}

const ApplicantHistoryMenuFilter = (props: IProps) => {
    const { t, filterType, setFilterType } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const options = applicantHistoryTypeList.map((i) => {
        return {
            value: i,
            label: t(`ApplicantHistoryType_${i}`)
        };
    });
    options.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = async (
        event: any,
        value: CompleoShared.Common.ApplicantHistoryType | "all"
    ) => {
        setFilterType(value);
        handleClose();
    };

    return (
        <div style={{ marginLeft: 8 }}>
            <Tooltip title={t("filterHistory") as string}>
                <IconButton
                    onClick={openMenu}
                    style={{ margin: 1, padding: 1, marginLeft: 8 }}
                >
                    <FilterList />
                </IconButton>
            </Tooltip>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem
                    onClick={(event: any) => clickMenuItem(event, "all")}
                    disabled={"all" === filterType}
                >
                    {t("labelAll")}
                </MenuItem>
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={(event: any) =>
                                clickMenuItem(event, option.value)
                            }
                            disabled={option.value === filterType}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default ApplicantHistoryMenuFilter;
