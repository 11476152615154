import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Icon,
    InputAdornment,
    TextField,
    Tooltip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextFieldCompleo from "../TextFieldControlled";
//import RichText from "../RichText/RichText";
import RichTextToolbarItemLoadPlaceholder from "./RichTextToolbarItemLoadPlaceholder";
import { MoreVert } from "@material-ui/icons";
import { TemplatePlaceholderDialog } from "./RichTextVars";
import { DialogChoosePlaceHolderSubject } from "./DialogChooseTemplateOrPlaceholder";
import FileField from "../File/File";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import RichTextV2, { IRichTextConfig } from "../RichTextV2/RichTextV2";
import {
    IInputProps,
    useRhfField,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) => {
    const colorText = theme.palette.grey[100];
    return createStyles({
        button: {
            margin: theme.spacing(2)
        },
        inputhidden: {
            display: "none"
        }
    });
});

interface IProps {
    placeholdersSubject?: Compleo.IObject[];
    placeholders?: Compleo.IObject[];
    arrayNumber: number;
    idListLoadedFromDB: string[];
    hideSubject: boolean;
    language: string;
}

const EmailDataField = (props: IProps & IInputProps) => {
    const {
        metadata,
        t,
        classes,
        required,
        name,
        placeholders = [],
        placeholdersSubject = [],
        arrayNumber,
        idListLoadedFromDB,
        language,
        hideSubject
    } = props;

    const mainField = useRhfFieldControlled(props.name, "");
    const { watch } = mainField;

    // const arrayDataField = useRhfFieldControlled(
    //     `${props.name}.${arrayNumber}.id`,
    //     ""
    // );
    const arrayFieldValue = watch(`${props.name}.${arrayNumber}.id`);

    // const [field, meta, helpers] = useField(props.name);
    // const [arrayField, , helpersArrayField] = useField(
    //     `${props.name}[${arrayNumber}]`
    // );
    const [startId] = React.useState(arrayFieldValue);
    const loadedFromDB = idListLoadedFromDB.includes(startId);

    const subjectFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.subject"
    );
    const bodyFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.body"
    );
    const attachmentsFieldName = getFieldName(
        arrayNumber,
        name,
        "_tempEmailData.attachments"
    );

    const subjectDataField = useRhfFieldControlled(subjectFieldName, "");

    const removeItem = () => {
        const currentValue = mainField.field.value;
        currentValue.splice(arrayNumber, 1);
        mainField.setFieldValue(currentValue);
    };

    // const [subjectField, , helpersSubject] = useField(subjectFieldName);

    const [selectionStart, setSelectionStart] = React.useState<any>(0);
    const inputRef = React.useRef();
    const updateSelectionStart = () => {
        if (inputRef && inputRef.current) {
            const valueFieldRef: any = inputRef?.current;
            setSelectionStart(valueFieldRef?.selectionStart);
        }
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const choosePlaceHolderSubject = (value: string) => {
        // subjectDataField.getFieldValue();
        const currentValue: string = subjectDataField.field.value || "";
        const valueToInsert: string = value || "";
        const position = selectionStart || 0;

        const newValue = [
            currentValue.slice(0, position),
            `{{${valueToInsert}}}`,
            currentValue.slice(position)
        ].join("");
        subjectDataField.setFieldValue(newValue);
        setOpen(false);
    };

    const ready = startId ? loadedFromDB : true;

    // console.log("subjectFieldName", subjectFieldName);
    if (ready) {
        return (
            <Grid container spacing={1}>
                {!hideSubject && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="text"
                            // name={subjectFieldName}
                            label={t("EMAILDATAFIELD_Subject")}
                            fullWidth
                            className={classes.textField}
                            // component={TextFieldCompleo}
                            error={subjectDataField.error}
                            helperText={subjectDataField.message}
                            // inputRef={inputRef}
                            onSelect={updateSelectionStart}
                            InputProps={{
                                endAdornment: placeholdersSubject.length >
                                    0 && (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={t(
                                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                            )}
                                        >
                                            <IconButton
                                                aria-label={t(
                                                    "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                                                )}
                                                onClick={handleClickOpen}
                                            >
                                                <FindReplaceIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                                // ref: subjectDataField.registerReturn.ref
                            }}
                            {...subjectDataField.field}
                            inputRef={inputRef}

                            // t={t}
                        />
                        <TemplatePlaceholderDialog
                            t={t}
                            open={open}
                            onClose={handleClose}
                            title={t(
                                "EMAILDATAFIELD_textChooseOptionModalPlaceholder"
                            )}
                        >
                            <DialogChoosePlaceHolderSubject
                                handleChooseAnOption={choosePlaceHolderSubject}
                                placeholders={placeholdersSubject}
                            />
                        </TemplatePlaceholderDialog>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <RichTextV2
                        name={bodyFieldName}
                        metadata={{
                            fieldName: bodyFieldName,
                            richTextConfig: {
                                height: "300px",
                                minHeight: "300px",
                                toolbarType: "emailTemplate"
                            } as IRichTextConfig
                        }}
                        t={t}
                        required={true}
                        label={t("EMAILDATAFIELD_Body")}
                        placeholders={placeholders}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileField
                        metadata={{
                            fieldName: attachmentsFieldName,
                            elementType: "multipleFiles",
                            fileDefinition: {
                                maxFileSizeKB: 2000,
                                maxFiles: 5,
                                extensions: [
                                    ".pdf",
                                    ".doc",
                                    ".docx",
                                    ".txt",
                                    ".png",
                                    ".jpg",
                                    ".gif",
                                    ".jpeg"
                                ]
                            }
                        }}
                        name={attachmentsFieldName}
                        t={t}
                        label={t("EMAILDATAFIELD_attachments")}
                        helperTextDefault={null}
                        classes={classes}
                        required={false}
                        // component={FileField}
                    />
                </Grid>
                {!mainField.field.value[arrayNumber].default && (
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={removeItem}
                            startIcon={<Icon>delete</Icon>}
                        >
                            {t("EMAILDATAFIELD_removelanguage")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

const getFieldName = (
    arrayNumber: number,
    mainFieldName: string,
    fieldName: string
) => {
    return `${mainFieldName}.${arrayNumber}.${fieldName}`;
};

export default EmailDataField;
