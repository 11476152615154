import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import CommentItems from "./CommentItems";
import AddIcon from "@material-ui/icons/Add";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import ApplicantNewNoteModal from "./ApplicantNewNoteModal";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            padding: 0,
            marginTop: theme.spacing(1.2),
            minHeight: "100px",
            height: "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column"
        },
        paperContainer: {
            width: "100%",
            margin: 0,
            padding: 0
        },
        root: {
            width: "100%",
            // backgroundColor: theme.palette.background.paper,
            margin: theme.spacing(0.5),
            padding: theme.spacing(0.5)
        },
        container: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
            margin: 0
        },
        refreshButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        }
    })
);

const ApplicantViewSideContainerComments = () => {
    const classes = useStyles();
    const [tLocal, i18n, readyTranslation] = useTranslation("APPLICANTNOTE", {
        useSuspense: false
    });
    const [newNoteOpen, setNewNoteOpen] = React.useState<{
        open: boolean;
        parentId?: string;
        usersToNotify?: string[];
    }>({ open: false });
    const queryClient = useQueryClient();

    const ready = readyTranslation;

    if (ready) {
        return (
            // <Box className={classes.mainContainer}>
            <Paper className={classes.mainContainer} elevation={1}>
                <Box className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">
                                <strong>{tLocal("title")}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs sm container justify="flex-end">
                            <ButtonIconTooltipCompleo
                                label={tLocal("refresh")}
                                onClick={() =>
                                    queryClient.invalidateQueries(
                                        "/applicant/applicantnotelist"
                                    )
                                }
                                className={classes.refreshButton}
                            >
                                <RefreshIcon />
                            </ButtonIconTooltipCompleo>
                            <ButtonIconTooltipCompleo
                                label={tLocal("buttonNewNote")}
                                onClick={() =>
                                    setNewNoteOpen({
                                        open: true,
                                        parentId: undefined,
                                        usersToNotify: undefined
                                    })
                                }
                                className={classes.refreshButton}
                                edge="end"
                            >
                                <AddIcon />
                            </ButtonIconTooltipCompleo>
                        </Grid>
                    </Grid>
                </Box>

                <Divider />
                <CommentItems setNewNoteOpen={setNewNoteOpen} />
                {newNoteOpen.open && (
                    <ApplicantNewNoteModal
                        open={newNoteOpen.open}
                        onClose={() =>
                            setNewNoteOpen({
                                open: false,
                                parentId: undefined,
                                usersToNotify: undefined
                            })
                        }
                        parentId={newNoteOpen.parentId}
                        usersToNotify={newNoteOpen.usersToNotify}
                    />
                )}
            </Paper>
            // </Paper>
        );
    } else {
        return (
            <Box className={classes.mainContainer}>
                <Loading />
            </Box>
        );
    }
};

export default ApplicantViewSideContainerComments;
