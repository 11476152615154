import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { useSelector } from "react-redux";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { getInstance } from "./i18nLocalInstace";
const apiAddressRoot = process.env.REACT_APP_API_ADDRESS;

const InputsExampleNew = (props: any) => {
    const classes = useFormStyles();

    // padrão
    // const local18n = getInstance("WX00029U", 10102);
    const [t, i18n, readyTranslation] = useTranslation("inputsexamplenew", {
        useSuspense: false
    });

    // React.useEffect(() => {
    //     // const url = `${apiAddressRoot}/careers/gettranslationapplicationform/WX00029U/10102`;
    //     // console.log("i18n?.options?.backend", i18n?.options?.backend);
    //     // if (i18n?.options?.backend) {
    //     //     i18n.addResourceBundle()
    //     //     i18n.options.backend = {
    //     //         loadPath: `${url}/{{lng}}/{{ns}}`,
    //     //         crossDomain: true,
    //     //         allowMultiLoading: false
    //     //     };
    //     // }
    //     // i18n.addResourceBundle("pt-BR", "teste", "")
    //     // i18n.addResourceBundle()
    //     // getInstance("WX00029U", 10102);
    // }, []);

    const { company, timeZone } = useAuthState();

    const postAddress = `/inputsexample/new`;

    const [addInputsExampleReturn, addInputsExample] = useApi(
        postAddress,
        "post"
    );
    const [metadata] = useGetMetadata("INPUTSEXAMPLENEW");

    const valuesFromSource = {
        name: "",
        dataNascimento: null,
        dataSolicitacao: null,
        dataHoraEntrevista: null,
        // cellPhone: "",
        phoneNumber: "",
        homeNumber: "",
        companyId: company.companyId,
        idade: null,
        peso: null,
        salario: {
            currency: company.defaultCurrency || "BRL",
            value: ""
        },
        plan: {
            label: "Compleo ATS para Consultorias (Plus)",
            //             "label-en-US": "Compleo ATS for Agencies (Plus)",
            // "label-pt-BR": "Compleo ATS para Consultorias (Plus)",
            value: "ats-agencies-plus-v1"
        },
        customerBillingRange: {
            minValue: "",
            maxValue: "",
            type: "hourly",
            currency: (company || {}).defaultCurrency || "BRL"
        },
        salaryRange: {
            minValue: "",
            maxValue: "",
            type: "hourly",
            currency: (company || {}).defaultCurrency || "BRL"
        }
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addInputsExample,
        addInputsExampleReturn,
        "/inputsexample/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        timeZone
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default InputsExampleNew;
