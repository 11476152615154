import React from "react";
import ItemCard from "./ItemCard";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        chipTable: {
            margin: theme.spacing(0.5)
        }
    })
);

const FieldTags = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType } = props;
    const classes = useStyles();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const defFieldInfo = cardFields[fieldName];

    const tagsData = transformDBData({
        value: fieldData[fieldName],
        t,
        language,
        replaceOption: "-"
    });
    const isTagArray = Array.isArray(tagsData);

    switch (returnType) {
        case "card":
            if (isTagArray) {
                return (
                    <ItemCard
                        smSize={12}
                        label={
                            defFieldInfo.hideLabelCard
                                ? undefined
                                : t(fieldName)
                        }
                        removeColonAfterLabel={defFieldInfo.hideLabelCard}
                    >
                        {tagsData.map((tag: any) => (
                            <Chip
                                className={classes.chipCard}
                                color="secondary"
                                key={tag}
                                size="small"
                                label={tag}
                            />
                        ))}
                    </ItemCard>
                );
            } else {
                return (
                    <ItemCard label={t(fieldName)}>
                        {`${transformDBData({
                            value: fieldData[fieldName],
                            t,
                            language,
                            replaceOption: "-"
                        })}`}
                    </ItemCard>
                );
            }
        case "table":
            if (isTagArray) {
                return (
                    <>
                        {tagsData.map((tag: any) => (
                            <Chip
                                className={classes.chipTable}
                                color="secondary"
                                key={tag}
                                size="small"
                                label={tag}
                            />
                        ))}
                    </>
                );
            } else {
                return (
                    <>
                        {transformDBData({
                            value: fieldData[fieldName],
                            t,
                            language,
                            replaceOption: "-"
                        })}
                    </>
                );
            }
    }
};

export default FieldTags;
