import { getStagesFromGroup } from "Pages/Pipeline/components/helperPipeline";
import { number } from "prop-types";
import {
    PipelineViewType,
    IMainState,
    IApplicantsData,
    IOrderData,
    IStagesConfig,
    IJobViewStages,
    IStagesOnlyTotal
} from "../useJobContext/JobViewTypes";
import { IKanbanCardFieldState } from "./JobKanbanCardFields/SettingsModalCardKanbanFields";

export const getSortStagesParams = (stagesConfig: IStagesConfig) => {
    const stages = Object.keys(stagesConfig);
    const objReturn: Compleo.IObject = {};
    for (const stage of stages) {
        const config = stagesConfig[stage];
        if (config.currentSort && config.currentSort?.name !== "_priority") {
            objReturn[stage] = {
                sort: {
                    [config.currentSort.name]: {
                        order: 1,
                        orderType: config.currentSort.sortType
                    }
                },
                disableCustomSort: true
            };
        }
    }
    return objReturn;
};

export const getStages = (
    stages: Compleo.IObject[],
    view: PipelineViewType
) => {
    const localStages: Compleo.IObject[] = stages || [];
    const returnData: Compleo.IObject[] = [];

    switch (view) {
        case "News_InProgress_Approveds":
            getStagesFromGroup("new", localStages).map((item) =>
                returnData.push(item)
            );
            getStagesFromGroup("others", localStages).map((item) =>
                returnData.push(item)
            );
            getStagesFromGroup("hired", localStages).map((item) =>
                returnData.push(item)
            );
            break;
        case "InProgress_Approveds":
            getStagesFromGroup("others", localStages).map((item) =>
                returnData.push(item)
            );
            getStagesFromGroup("hired", localStages).map((item) =>
                returnData.push(item)
            );
            break;
        case "Approveds":
            getStagesFromGroup("hired", localStages).map((item) =>
                returnData.push(item)
            );
            break;
        case "Disqualifieds":
            getStagesFromGroup("rejected", localStages).map((item) =>
                returnData.push(item)
            );
            break;
        default:
            break;
    }
    returnData.sort(
        (a: Compleo.IObject, b: Compleo.IObject) => a.order - b.order
    );
    return returnData;
};

export const getApplicantsByStage = (state: IMainState, stageId: string) => {
    const applicantsData = state.LocalStateData.ApplicantsData[stageId] || {};
    const returnData: Compleo.IObject[] = applicantsData?.applicants || [];
    return returnData;
    // }
};

export const handleOrderData = (orderObj: IOrderData[]) => {
    const finalOrderData: IOrderData[] = [...orderObj].filter((item) => item);
    finalOrderData.sort((a, b) => {
        if (a.stageId > b.stageId) {
            return 1;
        }
        if (a.stageId < b.stageId) {
            return -1;
        }
        return 0;
    });
    finalOrderData.sort((a, b) => a.order - b.order);
    return finalOrderData;
};

export const getTotalStage = (
    stagesOnlyTotal: IStagesOnlyTotal[],
    stageIds: string[]
) => {
    let total = 0;
    for (const stageId of stageIds) {
        const currentTotal = stagesOnlyTotal.filter(
            (item) => item.id === stageId
        )[0];
        const totalStage = currentTotal?.total || 0;
        total = total + totalStage;
    }
    return total;
    // const bucketsCount: { doc_count: number; key: string }[] =
    //     state.Applicants.Aggregations["Jobs.PipelineStage"]?.inner
    //         ?.pipelineStage?.buckets || [];

    // let total = 0;
    // for (const stageId of stageIds) {
    //     const totalStage =
    //         bucketsCount.filter((item) => item.key === stageId)[0]?.doc_count ||
    //         0;
    //     total = total + totalStage;
    // }
    // return total;
};

export const getTotalFilteredApplicantsByStage = (
    state: IMainState,
    stageIds: string[]
) => {
    let total = 0;
    const mainData = state.LocalStateData.ApplicantsData || {};
    for (const stageId of stageIds) {
        const totalStage = mainData[stageId]?.data?.totalFiltered?.value || 0;
        total = total + totalStage;
    }
    return total;
};

export interface IGetStateFromDB {
    dbData: Compleo.IObject;
    stateData: Compleo.IObject;
    orderData: IOrderData[];
    clearCurrentApplicants?: boolean;
    onlyUpdateStagesFromDB?: boolean;
    clearApplicantsStages?: string[];
}

export const getStateFromDB = (params: IGetStateFromDB) => {
    const {
        dbData,
        stateData,
        orderData,
        clearCurrentApplicants = false,
        onlyUpdateStagesFromDB = false,
        clearApplicantsStages
    } = params;
    // debugger;
    const databaseData = dbData?.data?.data || {};
    const localStateData: IApplicantsData = { ...(stateData || {}) };
    const currentListStages = orderData.map((i) => i.stageId);
    const currentStateStages = Object.keys(localStateData);
    const currentDBStages = Object.keys(databaseData);
    const stages = [
        ...new Set([
            ...currentListStages,
            ...currentStateStages,
            ...currentDBStages
        ])
    ];
    const listStages = onlyUpdateStagesFromDB ? currentDBStages : stages;
    // const newAllApplicantsList: Compleo.IObject = updateAllApplicantsList(
    //     AllApplicantsList || {},
    //     dbData
    // );

    for (const stage of listStages) {
        // if (stage === "e9e1d1a2-ec4a-4dda-83f7-76aeddc88dc4") {
        //     debugger;
        // }
        // debugger;
        const pageSize = databaseData[stage]?.data?.pageSize || 0;
        const fromValue = databaseData[stage]?.data?.fromValue || 0;

        const totalApplicants =
            databaseData[stage]?.data?.totalFiltered?.value || 0;

        localStateData[stage] = localStateData[stage] || {};

        const clearApplicantsStage =
            (clearApplicantsStages || []).filter((i) => i === stage).length > 0;

        localStateData[stage].applicants =
            clearCurrentApplicants || clearApplicantsStage
                ? []
                : localStateData[stage].applicants || [];

        for (let index = 0; index < totalApplicants; index++) {
            const currentData = localStateData[stage].applicants[index];
            const applicantsDBStage = databaseData[stage]?.applicantsData || [];
            const defaultEmptyValue = { _loaded: false };

            const mapStartIndex = fromValue;
            const mapFinishIndex = mapStartIndex + pageSize;
            localStateData[stage].applicants =
                localStateData[stage].applicants || [];

            if (index >= mapStartIndex && index < mapFinishIndex) {
                const newData = applicantsDBStage[index - mapStartIndex];
                if (newData) {
                    localStateData[stage].applicants[index] = {
                        ...newData,
                        _loaded: true
                    };
                } else {
                    localStateData[stage].applicants[index] = defaultEmptyValue;
                }
            } else {
                localStateData[stage].applicants[index] =
                    currentData || defaultEmptyValue;
            }
        }

        if (localStateData[stage].applicants.length > totalApplicants) {
            const itensToRemove =
                localStateData[stage].applicants.length - totalApplicants;
            localStateData[stage].applicants.splice(
                totalApplicants,
                itensToRemove
            );
        }

        localStateData[stage].data =
            databaseData[stage]?.data || localStateData[stage].data;
    }
    return localStateData;
};

export const getFilterStateFromDB = (
    dbData: Compleo.IObject,
    stateData?: IApplicantsData
) => {
    const mainData = dbData?.data?.data || {};
    const stages = Object.keys(mainData);
    const localStateData: IApplicantsData = stateData || {};

    for (const stage of stages) {
        const pageSize = mainData[stage].data.pageSize;
        const fromValue = mainData[stage].data.fromValue;

        const totalApplicants =
            mainData[stage]?.data?.totalFiltered?.value || 0;

        localStateData[stage] = localStateData[stage] || {};
        localStateData[stage].applicants =
            localStateData[stage].applicants || [];

        for (let index = 0; index < totalApplicants; index++) {
            const mapStartIndex = fromValue;
            const mapFinishIndex = mapStartIndex + pageSize;
            localStateData[stage].applicants =
                localStateData[stage].applicants || [];

            if (index >= mapStartIndex && index < mapFinishIndex) {
                const newData =
                    mainData[stage].applicantsData[index - mapStartIndex];
                localStateData[stage].applicants[index] = newData || null;
            } else {
                const currentData = localStateData[stage].applicants[index];
                localStateData[stage].applicants[index] = currentData || null;
            }
        }

        localStateData[stage].data = mainData[stage].data;
    }
    return localStateData;
};

export const getTotalStageFromDb = (
    orderDBData: Compleo.IObject,
    allAtages: IJobViewStages[]
) => {
    const bucketsCount: { doc_count: number; key: string }[] =
        orderDBData.aggregations["Jobs.PipelineStage"]?.inner?.pipelineStage
            ?.buckets || [];
    const responseData: IStagesOnlyTotal[] = [];

    allAtages.map((stage) => {
        const totalStage =
            bucketsCount.filter((item) => item.key === stage.id)[0]
                ?.doc_count || 0;

        responseData.push({
            id: stage.id,
            name: stage.name,
            total: totalStage
        });
    });
    return responseData;
};

interface IGetKanbanCardHeightReturn {
    reactWindowHeightParam: number;
    cardHeight: number;
    cardContentHeight: number;
}
export const getKanbanCardHeight: (
    CardDef: IKanbanCardFieldState
) => IGetKanbanCardHeightReturn = (CardDef: IKanbanCardFieldState) => {
    const emptyFields =
        CardDef.fieldsDefinition.filter((i) => i.local === "primary").length ===
        0;

    const returnEmptyWithoutCardActions = {
        reactWindowHeightParam: 85,
        cardHeight: 75,
        cardContentHeight: 2
    };

    const returnEmptyWithCardActions = {
        reactWindowHeightParam: 120,
        cardHeight: 110,
        cardContentHeight: 0
    };

    switch (CardDef.cardSize) {
        case "small":
            if (emptyFields) {
                return returnEmptyWithoutCardActions;
            }
            return {
                reactWindowHeightParam: 135,
                cardHeight: 125,
                cardContentHeight: 22
            };
        case "medium":
            if (emptyFields) {
                return returnEmptyWithCardActions;
            }
            return {
                reactWindowHeightParam: 197,
                cardHeight: 187,
                cardContentHeight: 85
            };
        case "big":
            if (emptyFields) {
                return returnEmptyWithCardActions;
            }
            return {
                reactWindowHeightParam: 285,
                cardHeight: 275,
                cardContentHeight: 170
            };
    }
};
