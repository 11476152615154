import React from "react";
import FormData from "Pages/ApplicationForm/components/FormData";
import { Grid } from "@material-ui/core";

interface IProps {}

const ApplicationForm = (props: IProps) => {
    const metadata = { fieldName: "applicationForm.formData" };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormData
                    metadata={metadata}
                    fieldNameTrans={"applicationForm.translations"}
                />
            </Grid>
        </Grid>
    );
};

export default ApplicationForm;
