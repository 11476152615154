import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import CustomComponents from "./components/CustomComponents";
import { requestFormikReturnFN } from "./components/helper";
import useCustomLists from "./components/useCustomLists";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import { useQueryStringRedirectAfter } from "customHooks/useQueryString/useQueryString";

const JobRequestEdit = (props: any) => {
    const moduleName = "JOBREQUESTEDIT";
    const redirectAfter = useQueryStringRedirectAfter(props);
    const cancelFunction = useCancelButtonForm(
        redirectAfter || "/job/listrequest"
    );

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);

    const { company } = useAuthState();

    const postAddress = `/jobrequest/editrequest/${props.jobid}`;
    const [addJobReturn, addJob] = useApi(postAddress, "post");

    const postAddressSearch = `/job/search`;
    const [getJobSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: props.jobid, companyId: company.companyId, searchForEdit: true },
        false
    );
    const jobSearchDBData = getJobSearchResult.response?.data;
    console.log("jobSearchDBData", jobSearchDBData);
    const [listDefinition, readyLists] = useCustomLists(jobSearchDBData?.lists);

    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getJobSearchResult,
        ["data", "mainData"]
    );

    const finalReady =
        readyLists &&
        readyTranslation &&
        getJobSearchResult.status === "success" &&
        metadata.status === "success";

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: finalReady,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const openingReason = watch("openingReason");

    React.useEffect(() => {
        requestFormikReturnFN(setValue, watch, fieldsToHide, setFieldsToHide);
    }, [openingReason, fieldsToHide]);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: finalReady && valuesFromSource?.description !== undefined,
        i18nV: i18n,
        postMethod: addJob,
        postReturn: addJobReturn,
        redirectAddress: redirectAfter || "/job/listrequest",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        CustomComponents: CustomComponents,
        customLists: listDefinition,
        fieldsToHide: fieldsToHide,
        sizeToChangeStepHorizontal: "lg",
        enableStepNavigation: true,
        IsMultiStepLateralParam: true,
        reactHookFormMethods: reactHookFormMethods,
        invalidateQueryClientItems: ["/job/list", "/job/search"],
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const ready = finished && finalReady;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <>
                <Page
                    featureName={t("a_FeatureName")}
                    featureMainDescription={t("a_FeatureMainDescription")}
                    maxWidth="lg"
                >
                    {compRetorno}
                </Page>
            </>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobRequestEdit;
