import { useTranslation } from "react-i18next";
import { useApiCache } from "customHooks/useApi";
import useJobContext from "../../useJobContext";
import { getCareerItemsMessages } from "../JobCareersStatusMessages";

export const useJobBoardsCareersStatus = () => {
    const [data] = useJobContext();
    const [t, i18n, readyTranslation] = useTranslation("JOBBOARDSMODULE", {
        useSuspense: false
    });
    const [tShare, , readyShareTranslation] = useTranslation("JOBSHAREMODULE", {
        useSuspense: false
    });
    const [listCareers] = useApiCache(
        "/careerssettings/careersbyjob",
        "post",
        {
            companyId: data?.Job?.data?.companyId,
            jobId: data.JobId
        },
        false
    );
    const listCareersItems: CompleoShared.CareersSettings.IJobCareersListStatus[] =
        listCareers?.response?.data?.items || [];

    const careerItem = getCareerItem(data.Job?.data || {}, listCareersItems);
    const messageList = getCareerItemsMessages(careerItem, tShare, true);
    const hasError = messageList.filter((i) => i.type === "error").length > 0;
    const filteredMessageList = messageList.filter((i) => i.type === "error");

    listCareersItems.sort((a, b) => {
        return a.careerSettings.uniqueLink.localeCompare(
            b.careerSettings.uniqueLink
        );
    });

    const otherListCareerItems = listCareersItems.filter(
        (i) =>
            i.careerSettings?.uniqueLink !==
            careerItem?.careerSettings?.uniqueLink
    );

    const listCareersNoErrors = listCareersItems.filter(
        (i) =>
            i.meetFilterBranchs &&
            i.meetFilterCustomers &&
            i.meetFilterInternalRecruitment &&
            i.meetFilterPCD &&
            i.enabled
    );
    const linkNameWorking = listCareersNoErrors.map(
        (i) => i.careerSettings.uniqueLink
    );
    const listCareersErrors = listCareersItems.filter(
        (i) => !linkNameWorking.includes(i.careerSettings.uniqueLink)
    );

    const ready =
        listCareers.status === "success" &&
        readyShareTranslation &&
        readyTranslation;

    return {
        translation: {
            t,
            i18n,
            readyTranslation
        },
        careerItem,
        messageList,
        hasError,
        filteredMessageList,
        listCareersItems,
        otherListCareerItems,
        listCareersNoErrors,
        listCareersErrors,
        ready
    };
};

const getCareerItem = (
    jobData: Compleo.IObject,
    listCareersItems: CompleoShared.CareersSettings.IJobCareersListStatus[]
) => {
    const jobBoardsCareersUniqueName = jobData?.jobBoardsCareersUniqueName;
    const mainCareer = listCareersItems.filter(
        (item) => item.careerSettings.main
    )[0];

    const careersItem:
        | CompleoShared.CareersSettings.IJobCareersListStatus
        | undefined = jobBoardsCareersUniqueName
        ? listCareersItems.filter(
              (item) =>
                  (item?.careerSettings?.uniqueLink || "").toLowerCase() ===
                  (jobBoardsCareersUniqueName || "").toLowerCase()
          )[0]
        : mainCareer;

    return careersItem;
};

export default useJobBoardsCareersStatus;
