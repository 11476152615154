import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { apiDirectCall, ApiStateType, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import _ from "lodash";
import { CompleoErrorMessage } from "Components/CompleoErrorMessage";
import { convertParserToCompleoPattern } from "functions/util";
import useApplicantNewContext from "./useApplicantNewContext/useApplicantNewContext";
import ApplicantContainerCV from "./ApplicantContainerCV";
import { createLocalUrlFromS3 } from "customHooks/useCompleoReactHookForm/Inputs/File/File";
import { navigate, RouteComponentProps } from "@gatsbyjs/reach-router";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useQueryStringRedirectAfter } from "customHooks/useQueryString/useQueryString";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";

interface IApplicantEdit {}

const ApplicantEdit = (props: RouteComponentProps & IApplicantEdit) => {
    const redirectAfter = useQueryStringRedirectAfter(props);
    const cancelFunction = useCancelButtonForm(
        redirectAfter || "/applicant/list"
    );

    const [data, dispatchData] = useApplicantNewContext();
    // const queryClient = useQueryClient();
    const { callDialog } = useGlobalDialog();

    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const listDefinition: Compleo.CustomLists.ListDefinitionType[] = [];
    // const isJsonParsed = data.localStateData?.jsonParsedCV !== undefined;
    const applicant = data.localStateData?.ApplicantFirstData || null;

    const [cvKey, setCvKey] = React.useState<
        { key: string; originalFileName: string } | undefined
    >(undefined);
    React.useEffect(() => {
        if (cvKey?.key) {
            createLocalUrlFromS3(cvKey.key, true).then((url) => {
                const file = new File([url.blob], cvKey.originalFileName);
                dispatchData({
                    type: "update",
                    payload: {
                        urlCV: url.url,
                        cvFile: {
                            fileName: cvKey.originalFileName,
                            streamContent: file
                        }
                    }
                });
            });
        }
    }, [cvKey]);

    const { company } = useAuthState();
    let defaultFieldsToHide: string[] = [];
    const moduleName = "APPLICANTEDIT";
    const [metadata] = useGetMetadata(moduleName);
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const postAddressSearch = `/applicant/search`;
    const [getApplicantReturn, getApplicant] = useApiCache(
        postAddressSearch,
        "post",
        {
            id: data.localStateData?.editId,
            companyId: company.companyId
        },
        false
    );

    let [valuesFromSource, pkSKValues] = useValuesFromSource(
        metadata,
        true,
        {},
        getApplicantReturn,
        ["data", "data"]
    );

    valuesFromSource.id = data.localStateData?.editId;

    if (!valuesFromSource.disabledPerson) {
        defaultFieldsToHide = ["typeOfDisability"];
    }

    if (data.localStateData?.jsonParsedCV) {
        valuesFromSource = convertParserToCompleoPattern(
            valuesFromSource,
            data.localStateData?.jsonParsedCV,
            true
        );
    }
    if (applicant) {
        valuesFromSource.name = applicant.name || valuesFromSource.name;
        valuesFromSource.email = applicant.email || valuesFromSource.email;
        valuesFromSource.cpf = applicant.cpf || valuesFromSource.cpf;

        if (data.localStateData.cvFile?.streamContent) {
            valuesFromSource.cv = {
                FileInfo: data.localStateData.cvFile?.streamContent,
                tags: []
            };
            defaultFieldsToHide.push("cv");
        }
        if (data.localStateData.jsonParsedCV?.TxtCV) {
            valuesFromSource.cvText = data.localStateData.jsonParsedCV?.TxtCV;
        }
    } else if (!cvKey && valuesFromSource.cv) {
        setCvKey(valuesFromSource.cv);
    }

    if (readyLanguages && (languages || []).length < 2) {
        if (
            defaultFieldsToHide.filter((item) => item === "preferredLanguage")
                .length === 0
        ) {
            defaultFieldsToHide.push("preferredLanguage");
        }
    }
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    if (readyLanguages) {
        const defaultLanguageData = languages.filter(
            (item: Compleo.IObject) => item.value === defaultLanguage
        )[0] || { value: defaultLanguage };
        valuesFromSource.preferredLanguage =
            valuesFromSource.preferredLanguage || defaultLanguageData;
        if (
            listDefinition.filter(
                (item) => item.fieldName === "preferredLanguage"
            ).length === 0
        ) {
            listDefinition.push({
                data: languages,
                fieldName: "preferredLanguage",
                valueName: "value",
                labelName: "label"
            });
        }
    }

    const finalReady =
        readyTranslation &&
        readyLanguages &&
        getApplicantReturn.status === "success" &&
        metadata.status === "success";

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: finalReady,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource
    });

    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const disabledPersonField = watch("disabledPerson");
    React.useEffect(() => {
        let fieldsToHideFN = defaultFieldsToHide;
        if (disabledPersonField) {
            fieldsToHideFN = defaultFieldsToHide.filter(
                (item) => item !== "typeOfDisability"
            );
        }
        if (!_.isEqual(fieldsToHide, fieldsToHideFN)) {
            setFieldsToHide(fieldsToHideFN);
        }
    }, [disabledPersonField]);

    let navigateAddress = redirectAfter || `/applicant/list`;
    if (data.operationid) {
        navigateAddress = `/applicant/parserlistdetail/${data.operationid}`;
    }

    const handleSubmit = async (values: any) => {
        try {
            const postAddress = `/applicant/edit`;
            const dataInsert = await apiDirectCall(postAddress, "post", {
                ...values
            });

            if (data.operationid && data.itemid) {
                const postAddressParserUpdate = `/parser/parseroperationdetaileditstatus`;
                console.log("dataInsert edit", dataInsert);

                const dataParserUpdate = await apiDirectCall(
                    postAddressParserUpdate,
                    "post",
                    {
                        operationId: data.operationid,
                        itemId: data.itemid,
                        companyId: values.companyId,
                        status: "finished",
                        applicantId: values.id
                    }
                );
            }

            const mergeData = dataInsert.data?.duplicateApplicants || [];

            if (mergeData.length > 0) {
                callDialog({
                    title: t("duplicanteApplicants_MessageTitle"),
                    bodyText: t("duplicanteApplicants_MessageBody"),
                    agreeButtonText: t("COMPLEOGENERAL_YES"),
                    disagreeButtonText: t("COMPLEOGENERAL_NO"),
                    agreeFunction: async () => {
                        navigate(`/applicant/merge/${values.id}`);
                    },
                    disagreeFunction: () => {
                        navigate(navigateAddress);
                    }
                });
            } else {
                navigate(navigateAddress);
            }
        } catch (ex) {
            return ex.response;
        }
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: finalReady,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        IsMultiStepLateralParam: data.localStateData?.urlCV ? false : true,
        enableStepNavigation: true,
        fieldsToHide: fieldsToHide,
        customLists: listDefinition,
        secondaryButtonFunction: cancelFunction,
        invalidateQueryClientItems: [
            "/applicant/list",
            "/job/applicantlist",
            "/job/applicantlistorderdata"
        ],
        removeQueryClientItems: ["JobViewJob", "JobViewApplicants"],
        reactHookFormMethods: reactHookFormMethods,
        forceBackdrop: true
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (getApplicantReturn.status === "error") {
        compRetorno = (
            <CompleoErrorMessage
                errorTitle={t("applicantNotFoundMessage")}
                errorDescription={t("applicantNotFoundMessageDetail")}
            />
        );
    }
    if (finalReady && finished) {
        compRetorno = formCompleo;

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth={data.localStateData?.urlCV ? "xl" : "lg"}
            >
                <ApplicantContainerCV
                    compRetorno={compRetorno}
                    url={data.localStateData?.urlCV}
                    pageMaxWidth="xl"
                />
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ApplicantEdit;
