import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { useApi, useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useResetDefaults } from "./components/useResetDefaults";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

//var Scroll = require("react-scroll");
//var scroll = Scroll.animateScroll;

const male = {
    "label-en-US": "Male",
    value: "1",
    "label-pt-BR": "Masculino",
    label: "Masculino"
};

const female = {
    "label-en-US": "Female",
    value: "2",
    "label-pt-BR": "Feminino",
    label: "Feminino"
};

const keysToUpdate: string[] = [
    "applicant",
    "branchOffice",
    "contacts",
    "customer",
    "user",
    "job",
    "recruiter"
];

const Keyword = (props: any) => {
    const showMessage = useShowMessage();
    const classes = useFormStyles();
    const { company } = useAuthState();
    const companyId = company.companyId || "0";
    const isReset = "0";
    const [languageState, setLanguageState] = React.useState(false);

    let moduleName = "";
    let language = "";
    if (props.path == "/settings/keyword/keywordsPTBR") {
        moduleName = "DOMAINTRANSLATIONSCOMPANYPTBR";
        language = "pt-BR";
    } else {
        moduleName = "DOMAINTRANSLATIONSCOMPANYENUS";
        language = "en-US";
    }

    let [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [restoreDefaults, restoreSuccess] = useResetDefaults(
        Number(companyId),
        t,
        i18n,
        language,
        moduleName
    );

    const reload = async () => {
        await i18n.reloadResources(null, moduleName);
    };

    const [metadata] = useGetMetadata(moduleName);

    const postAddress = `/settings/keyword/${companyId}/${language}/${isReset}`;
    const [addKeywordReturn, addKeyword] = useApi(postAddress, "post");

    const postAddressSearch = `/settings/keywordSearch/${companyId}/${language}/${isReset}`;
    const [getModuleReturn, executeQuery] = useApiCache(
        postAddressSearch,
        "post",
        {},
        false
    );

    const formikReturn = React.useRef();

    React.useEffect(() => {
        executeQuery();
        const formik: any = formikReturn.current;
        if (formik !== undefined) {
            console.log("reset formik");
            formik.resetForm();
        }
        // reload();
        // i18n.reloadResources(null, moduleName).then(() => {
        //     setLanguageState(true);
        // });
    }, [restoreSuccess]);
    //[] vazio, executa somente 1 vez
    //[restoreSuccess] quando alterado, executa toda vez

    let valuesFromSource: Compleo.IObject = getValuesFromSource(
        getModuleReturn
    );

    const addKeywordLocal = async (values: any) => {
        const returnFunction = await addKeyword(values);
        showMessage(t("successMessage"), "success");
        await i18n.reloadResources(null, moduleName);
        //$('.MuiTypography-caption').scrollIntoView()
        return returnFunction;
    };

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addKeywordLocal,
        addKeywordReturn,
        null,
        FormClassic,
        metadata,
        valuesFromSource,
        undefined,
        undefined,
        undefined,
        restoreDefaults,
        undefined,
        undefined
        //formikReturn
    );

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default Keyword;

function getValuesFromSource(getModuleReturn: any) {
    let valuesFromSource: Compleo.IObject = {};
    if (getModuleReturn.status == "success") {
        for (let key of keysToUpdate) {
            const data = getModuleReturn.response.data.dataRet;
            console.log(key);
            valuesFromSource[`${key}SingularForm`] = data?.[key]?.singularForm;
            valuesFromSource[`${key}PluralForm`] = data?.[key]?.pluralForm;
            if (data?.[key]?.letterGender == "o") {
                valuesFromSource[`${key}Gender`] = male;
            } else {
                valuesFromSource[`${key}Gender`] = female;
            }
        }
    } else {
        for (let key of keysToUpdate) {
            valuesFromSource[`${key}SingularForm`] = "";
            valuesFromSource[`${key}PluralForm`] = "";
            valuesFromSource[`${key}Gender`] = {};
        }
    }

    return valuesFromSource;
}
