import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";

const useActionsTable = (companyId: number, t: any) => {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "get_app",
                tooltip: t("buttonDownload"),
                onClick: async (event: any, rowData: any) => {
                    await downloadFileFromS3(
                        rowData.s3Key,
                        `jobsdownload_${new Date().toISOString()}.csv`
                    );
                }
            }
        ];

        return result;
    };
};

export const getUrlFromS3 = async (key: string, convertPDF = false) => {
    // const [companyId, folder, fileName] = getKeysFromFileKey(key);
    const keySplit = key.split("/");
    const fileName = keySplit[keySplit.length - 1];
    const companyId = keySplit[0];

    const urlReturn = await apiDirectCall(
        "/fileutil/requests3urlexistentfile",
        "post",
        {
            fileName: fileName,
            folder: key
                .replace(`${companyId}/`, "")
                .replace(`/${fileName}`, ""),
            companyId: companyId,
            getPDFForceConvert: false
        }
    );
    return urlReturn.data;
};

export const createLocalUrlFromS3 = async (key: string, convertPDF = false) => {
    const urlReturn = await getUrlFromS3(key, convertPDF);

    const res = await fetch(urlReturn);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);

    return { url, blob };
};

export const downloadFileFromS3 = async (key: string, originalFile: string) => {
    const url = await createLocalUrlFromS3(key, false);
    const a = document.createElement("a");
    a.href = url.url;
    a.download = originalFile || "download";
    a.click();
};

export default useActionsTable;
