import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Icon } from "@material-ui/core";
import {
    useCompleoSimpleList,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import useActionsTable from "./components/useActionsTable";

const ExportDataList = (props: any) => {
    const moduleName = "EXPORTDATALIST";
    const [t, i18n, readyTranslation] = useTranslation(
        [moduleName, "JOBEXPORTMAIN"],
        {
            useSuspense: false
        }
    );
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/exportimportdata/exportdatalist";
    const { company } = useAuthState();
    const actions: actionsTableFunction = useActionsTable(company.companyId, t);

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ExportDataList;
