import React, { ComponentType, Component } from "react";
import { Router, Link } from "@gatsbyjs/reach-router";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import useRoutes from "Config/useRoutes";
import NotFound from "Pages/_General/PageNotFound";
import Authenticated from "auth/Authenticated";
import UnAuthenticated from "auth/UnAuthenticated";
// import useGlobalValues from "_ReactContext/GlobalValues";

type Props = { component: ComponentType; routes?: any } & RouteComponentProps;

const Route: ComponentType<Props> = ({ component: Component, ...rest }) => {
    return <Component {...rest} />;
};

const CompleoRoutes = () => {
    const routesReturn = useRoutes();

    if (routesReturn.routes !== null && routesReturn.isLoading === false) {
        return (
            <React.Fragment>
                <Router>
                    <Route
                        component={NotFound}
                        routes={routesReturn.routes}
                        default
                    />
                    {routesReturn.routes.map((item: any) => {
                        if (item.component === undefined) {
                            if (process.env.NODE_ENV === "development") {
                                console.error(
                                    `COMPLEO Message: Doesn't exist an exported component called ` +
                                        `${item.componentName}. All routes defined in db needs to have a ` +
                                        `component exported with the same name.`
                                );
                            }
                        } else {
                            const RouteComponent = item.component;
                            const Comp = (props: any) => {
                                if (item.requiresAuthentication) {
                                    return (
                                        <Authenticated
                                            routes={routesReturn.routes}
                                            {...props}
                                        >
                                            <RouteComponent />
                                        </Authenticated>
                                    );
                                } else {
                                    return (
                                        <UnAuthenticated {...props}>
                                            <RouteComponent />
                                        </UnAuthenticated>
                                    );
                                }
                            };

                            return (
                                <Route
                                    component={Comp}
                                    path={item.route}
                                    key={item.route}
                                />
                            );
                        }
                    })}
                </Router>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default CompleoRoutes;
