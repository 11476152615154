import React from "react";
// import { FieldProps } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import { addYears, addDays, isValid, toDate } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker
} from "@material-ui/pickers";
import _ from "lodash";
import * as formatValues from "functions/formatValues";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

type DateType = "Date" | "DateTime";
export interface IDateProps {
    label: string;
    helperTextDefault: null | string;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    openTo?: "date" | "year" | "month" | undefined;
    disablePast?: boolean;
    disableFuture?: boolean;
    invalidDateMessage?: string;
    invalidLabel?: string;
    readOnly?: boolean;
    // language: "pt-BR" | "en-US" | "fr" | "es";
    language: string;
    orientation?: "portrait" | "landscape";
    maxAge?: number;
    minAge?: number;
    timeZone?: string; //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    minDateAddDaysFromCurrentDate?: number;
    maxDateAddDaysFromCurrentDate?: number;
    formatType?: Compleo.RegionalSettings.formatType;
    dateType?: DateType;
    onlyTime?: boolean;
    onlyMonthYear?: boolean;
}

const DateField = (props: IDateProps & IInputProps) => {
    const {
        name,
        label,
        helperTextDefault,
        openTo = "date",
        disablePast,
        disableFuture,
        invalidDateMessage = "Data inválida",
        invalidLabel,
        readOnly,
        language = "pt-BR",
        orientation = "portrait",
        minAge,
        maxAge,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ||
            "America/Sao_Paulo",
        formatType,
        dateType = "Date",
        onlyTime = false,
        onlyMonthYear = false,
        type,
        variant,
        required,
        ...other
    } = props;

    let { minDate, maxDate } = props;

    const {
        error,
        message,
        setFieldValue,
        field,
        setError
    } = useRhfFieldControlled(name, helperTextDefault);

    //Convertendo data no timeZone do Usuário para UTC, pois o componente trabalha com UTC para Data
    minDate = dateToUTC(minDate, timeZone);

    if (maxAge && !minDate) {
        minDate = addYears(zonedTimeToUtc(Date.now(), timeZone), -maxAge);
    }

    if (minDateAddDaysFromCurrentDate && !minDate) {
        minDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            minDateAddDaysFromCurrentDate
        );
    }

    //Convertendo data no timeZone do Usuário para UTC, pois o componente trabalha com UTC para Data
    maxDate = dateToUTC(maxDate, timeZone);

    if (maxDateAddDaysFromCurrentDate && !maxDate) {
        maxDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            maxDateAddDaysFromCurrentDate
        );
    }

    if (minAge && !maxDate) {
        maxDate = addYears(zonedTimeToUtc(Date.now(), timeZone), -minAge);
    }

    const { format, ampm } = formatValues.getFormat(
        language,
        getLocalFormat(formatType, dateType, onlyMonthYear)
    );
    const locale = formatValues.getFNSLocaleFromString(language);
    const DateComponentToRender =
        dateType === "DateTime" ? KeyboardDateTimePicker : KeyboardDatePicker;

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            {!onlyTime ? (
                <DateComponentToRender
                    id={name}
                    label={label}
                    error={error}
                    helperText={message}
                    // {...field}
                    {...other}
                    onChange={(val, stringVal) => {
                        // se for mes / ano fixa o primeiro dia do mês
                        if (formatType === "monthYear") {
                            if (val !== undefined) {
                                val?.setDate(1);
                                val?.setHours(0, 0, 0, 0);
                                setFieldValue(val);
                                return;
                            }
                        }

                        // Remove o horário pois é componente apenas de data
                        if (dateType !== "DateTime" && val !== undefined) {
                            val?.setHours(0, 0, 0, 0);
                        }
                        setFieldValue(val);
                    }}
                    value={field.value}
                    format={format}
                    minDate={minDate}
                    maxDate={maxDate}
                    openTo={openTo}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    invalidLabel={invalidLabel}
                    readOnly={readOnly}
                    orientation={orientation}
                    KeyboardButtonProps={{
                        "aria-label": `change date ${field.name}`
                    }}
                    inputProps={{
                        ref: field.ref
                    }}
                    onBlur={field.onBlur}
                    onAccept={field.onBlur}
                    required={required}
                />
            ) : (
                <KeyboardTimePicker
                    autoOk
                    variant="inline"
                    label={label}
                    error={error}
                    helperText={message}
                    // format={format}
                    ampm={ampm}
                    // {...field}
                    // {...other}
                    inputProps={{
                        ref: field.ref
                    }}
                    value={field.value}
                    onChange={(val) => {
                        setFieldValue(val);
                    }}
                    onBlur={field.onBlur}
                    onAccept={field.onBlur}
                    required={required}
                />
            )}
        </MuiPickersUtilsProvider>
    );
};

// DateField.defaultProps = {
//     openTo: "date",
//     invalidDateMessage: "Data inválida",
//     language: "pt-BR",
//     orientation: "portrait",
//     timeZone:
//         Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo"
// };

const getLocalFormat = (
    formatType: Compleo.RegionalSettings.formatType | undefined,
    dateType: DateType,
    onlyMonthYear: boolean
) => {
    if (formatType) {
        return formatType;
    }
    if (onlyMonthYear) {
        return "monthYear";
    }
    switch (dateType) {
        case "DateTime":
            return "dayMonthYearTime";
        default:
            return "dayMonthYear";
    }
};

function dateToUTC(originalDate: Date | undefined, timeZone: string) {
    let newDate = originalDate;

    if (originalDate) {
        newDate = zonedTimeToUtc(originalDate, timeZone);
    }
    return newDate;
}

export const getMinMaxDate = (
    minDate: Date | undefined,
    maxDate: Date | undefined,
    minDateAddDaysFromCurrentDate: number | undefined,
    maxDateAddDaysFromCurrentDate: number | undefined,
    timeZone: string
) => {
    minDate = dateToUTC(minDate, timeZone);

    if (minDateAddDaysFromCurrentDate && !minDate) {
        minDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            minDateAddDaysFromCurrentDate
        );
    }

    maxDate = dateToUTC(maxDate, timeZone);

    if (maxDateAddDaysFromCurrentDate && !maxDate) {
        maxDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            maxDateAddDaysFromCurrentDate
        );
    }
    return { minDate, maxDate };
};

export default DateField;
