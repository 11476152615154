import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, ListItem, Typography } from "@material-ui/core";
import TextField from "customHooks/useCompleoReactHookForm/Inputs/TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        rootList: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        textField: {
            margin: theme.spacing(1)
        },
        gridContainer: {},
        gridDescription: {
            padding: "1px"
        },
        gridField: {
            padding: "1px"
        },
        divider: {
            backgroundColor: theme.palette.error.main,
            height: "1px"
        },
        typoBold: {
            fontWeight: "bold"
        }
    })
);

interface ITranslationGridProps {
    t: any;
    tField: any;
    language: string;
    mainFieldName: string;
    localFieldName: string;
    label: string;
    type: string;
}

const TranslationGrid = (props: ITranslationGridProps) => {
    const classes = useStyles();
    const {
        t,
        language,
        mainFieldName,
        localFieldName,
        tField,
        label,
        type
    } = props;

    return (
        <ListItem button={false}>
            <Grid container className={classes.gridContainer}>
                <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    className={classes.gridDescription}
                >
                    <Typography className={classes.typoBold} variant="caption">
                        {`${type} - `}
                    </Typography>
                    <Typography variant="caption">{label}</Typography>
                </Grid>
                <Grid item xs={9} className={classes.gridField}>
                    <TextField
                        type="text"
                        name={`${mainFieldName}.${language}.${localFieldName}`}
                        fullWidth
                        className={classes.textField}
                        required={true}
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default TranslationGrid;
