import React from "react";
import TranslationsDetail from "./TranslationsDetail";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTranslation } from "react-i18next";
import CompleoTabs, { TabTypeCompleo } from "Components/CompleoTabs";
import { makeStyles, Theme } from "@material-ui/core";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface IProps {
    metadata: any;
    translations: Compleo.IObject;
    messages: any;
    languages: Compleo.IObject[];
    defaultLanguage: string;
    formDataFieldName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1)
    }
}));

const Translation = (props: IProps) => {
    const {
        languages,
        defaultLanguage,
        metadata,
        translations,
        messages,
        formDataFieldName
    } = props;
    const classes = useStyles();

    const moduleName = "APPLICATIONFORMNEW";
    const [t, i18n] = useTranslation(moduleName, {
        useSuspense: false
    });
    const currentLanguage = i18n.languages[0];

    const ready = languages && languages.length > 0;

    if (ready) {
        const tabs: TabTypeCompleo[] = [];
        languages.map((item: any, index: number) => {
            const TabComponent = (
                <TranslationsDetail
                    metadata={metadata}
                    t={t}
                    translations={translations}
                    messages={messages}
                    languageObj={item}
                    defaultLanguageCareers={defaultLanguage}
                    formDataFieldName={formDataFieldName}
                />
            );
            tabs.push({
                label: item[`label-${currentLanguage}`],
                Component: TabComponent
            });
        });
        return (
            <CompleoTabs
                t={t}
                tabs={tabs}
                interfaceProps={{
                    AppBarOtherProps: { className: classes.root }
                }}
            />
        );
    } else {
        return <Loading />;
    }
};

export default Translation;
