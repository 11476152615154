import React from "react";
import Page from "../_Layouts/MainExternal";
import { Auth } from "aws-amplify";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import stylesForm from "Pages/_Layouts/generalForm";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import queryString from "query-string";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { ApiStateType, useApiCache } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { navigate, RouteComponentProps } from "@gatsbyjs/reach-router";
import { boolean } from "yup";
import { Alert } from "@material-ui/lab";
import { Box, Button, Grid } from "@material-ui/core";
import TermsOfUseAndPrivacyFooter from "Components/TermsOfUseAndPrivacyFooter";
import EvaluationIncorrectDataMessage from "./components/EvaluationIncorrectDataMessage";

const useStyles = makeStyles((theme: Theme) => createStyles(stylesForm));

interface IProps extends RouteComponentProps {
    uuid?: string;
}

const CustomComponents = [
    {
        name: "footer",
        component: TermsOfUseAndPrivacyFooter
    }
];

const EvaluationRequestEntry = (props: IProps) => {
    const [t, i18n, readyTranslation] = useTranslation(
        "EVALUATIONREQUESTENTRY",
        {
            useSuspense: false
        }
    );
    const [tReportsShare, , readyTranslationtReportsShare] = useTranslation(
        "JOBREPORTSSHAREENTRY",
        {
            useSuspense: false
        }
    );
    const [
        incorrectUserEvaluation,
        setIncorrectUserEvaluation
    ] = React.useState<{
        incorrectUser: boolean;
        finished: boolean;
        userEvaluation?: string;
        currentUser?: string;
    }>({ incorrectUser: false, finished: false });

    const postAddressSearch = `/applicant/evaluationrequestexternalget`;
    const [getListResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: props.uuid },
        false
    );
    const evaluationData = getListResult?.response?.data?.data || [];
    const expired = getListResult?.response?.data?.expired === true;
    const [notFound, setNotFound] = React.useState(false);
    const firstItem = evaluationData[0];
    const { user, logout } = useAuthState();

    React.useEffect(() => {
        if (
            getListResult.status === "success" &&
            !incorrectUserEvaluation.finished
        ) {
            if (user) {
                if (
                    (firstItem?.evaluatorEmail || "").toLowerCase().trim() !==
                    user.toLowerCase().trim()
                ) {
                    setIncorrectUserEvaluation({
                        incorrectUser: true,
                        userEvaluation: firstItem?.evaluatorEmail,
                        currentUser: user.toLowerCase().trim(),
                        finished: true
                    });
                } else {
                    setIncorrectUserEvaluation({
                        incorrectUser: false,
                        finished: true
                    });
                }
            } else {
                setIncorrectUserEvaluation({
                    incorrectUser: false,
                    finished: true
                });
            }
        }
        if (getListResult?.exception?.response?.status === 404) {
            setNotFound(true);
        }
    }, [getListResult.status, incorrectUserEvaluation.finished]);

    const [metadata] = useGetMetadata("EVALUATIONREQUESTENTRY");
    const [valuesFromSource] = useValuesFromSource(metadata, false, {
        evaluatorEmail: firstItem?.evaluatorEmail
    });

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const accessType:
        | CompleoShared.EvaluationRequest.IGetUserEvaluationTypeReturn
        | undefined = getListResult?.response?.data?.accessType;
    const onlyReports =
        (getListResult?.response?.data?.data || [])[0]?.onlyReports === true;

    const appendRedirectAddress =
        accessType?.userType === "external" ? `/${firstItem?.companyId}` : "";
    const redirectAddress = `/evaluationrequestmain/${props.uuid}${appendRedirectAddress}`;

    const ready = readyTranslation && readyTranslationtReportsShare;
    const [formCompleo, finished] = useCompleoFormNew({
        ready: ready,
        t: onlyReports ? tReportsShare : t,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        metadadosRetorno: metadata,
        FormType: FormClassic,
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        redirectAddress: redirectAddress
    });

    const logoutAndReload = async () => {
        await logout();
        setIncorrectUserEvaluation({ incorrectUser: false, finished: false });
    };
    const allReady = finished && ready && incorrectUserEvaluation.finished;

    if (!expired && !notFound) {
        if (allReady) {
            if (!incorrectUserEvaluation.incorrectUser) {
                return (
                    <PageDataReturn t={onlyReports ? tReportsShare : t}>
                        {formCompleo}
                    </PageDataReturn>
                );
            } else {
                return (
                    <PageDataReturn t={t}>
                        <EvaluationIncorrectDataMessage
                            t={onlyReports ? tReportsShare : t}
                            alertMessageDef={{
                                messageId: "incorrectUser",
                                translateReplaceObj: {
                                    userRequest:
                                        incorrectUserEvaluation.userEvaluation,
                                    userLogged:
                                        incorrectUserEvaluation.currentUser
                                }
                            }}
                            logoutAndReload={logoutAndReload}
                        />
                    </PageDataReturn>
                );
            }
        } else {
            return (
                <PageDataReturn t={t}>
                    <Loading />
                </PageDataReturn>
            );
        }
    } else if (notFound) {
        return (
            <PageDataReturn t={t}>
                <Box width="100%">
                    <Alert severity="error">{t("notFoundMessage")}</Alert>
                </Box>
            </PageDataReturn>
        );
    } else {
        return (
            <PageDataReturn t={t}>
                <Box width="100%">
                    <Alert severity="error">{t("expired")}</Alert>
                </Box>
            </PageDataReturn>
        );
    }
};

interface IPageDataReturn {
    children: any;
    t: any;
}
const PageDataReturn = (props: IPageDataReturn) => {
    const { children, t } = props;
    return (
        <Page
            featureMainDescription={t("a_FeatureMainDescription", "")}
            featureName={t("a_FeatureName", "")}
        >
            {children}
        </Page>
    );
};

export default EvaluationRequestEntry;
