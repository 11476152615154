import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useDynamicDashboard from "../index";
import SettingsModalFilters from "./Settings/SettingsModalFilters";
import SettingsModalCardFields from "./Settings/SettingsModalCardTableFields";
import useDefaultFilter from "./Settings/useDefaultFilter";
import SaveFilterModal from "./SaveFilter/SaveFilterModal";
import LoadFilterModal from "./SaveFilter/LoadFilterModal";

type ActionsMenuList =
    | "savefilter"
    | "loadFilter"
    | "markAsDefault"
    | "loadDefaultFilter";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
}

const ActionsMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t } = props;
    const [, setCurrentFilterAsDefault, loadDefaultFilter] = useDefaultFilter();
    const [listData, listDispatch] = useDynamicDashboard();
    const { localInfo } = listData;
    const [filterSettingsOpen, setFiltersSettingsOpen] = React.useState(false);
    const [fieldsSettingsOpen, setFieldsSettingsOpen] = React.useState(false);
    const [typeFieldsSettings, setTypeFieldsSettings] = React.useState<
        "card" | "table"
    >("card");
    const [saveFilterOpen, setSaveFilterOpen] = React.useState(false);
    const [loadFilterOpen, setLoadFilterOpen] = React.useState(false);
    const [bulkActionsOpen, setBulkActionsOpen] = React.useState(false);

    const { setLocalInfo } = listDispatch;

    const mainMenuItemsToRemove: ActionsMenuList[] =
        (listData.definitionInfo.elasticDefData?.data?.otherSettings || {})
            .mainMenuItemsToRemove || [];

    const allMenuOptions: ActionsMenuList[] = [
        "savefilter",
        "loadFilter",
        "markAsDefault",
        "loadDefaultFilter"
    ];
    const menuOptions = allMenuOptions.filter(
        (item: ActionsMenuList) => !mainMenuItemsToRemove.includes(item)
    );

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (action: ActionsMenuList) => {
        switch (action) {
            case "savefilter":
                setSaveFilterOpen(true);
                break;
            case "loadFilter":
                setLoadFilterOpen(true);
                break;
            case "markAsDefault":
                setCurrentFilterAsDefault();
                break;
            case "loadDefaultFilter":
                loadDefaultFilter();
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            <SettingsModalFilters
                onClose={() => setFiltersSettingsOpen(false)}
                open={filterSettingsOpen}
            />
            <SettingsModalCardFields
                onClose={() => setFieldsSettingsOpen(false)}
                open={fieldsSettingsOpen}
                type={typeFieldsSettings}
            />
            <SaveFilterModal
                onClose={() => setSaveFilterOpen(false)}
                open={saveFilterOpen}
            />
            <LoadFilterModal
                onClose={() => setLoadFilterOpen(false)}
                open={loadFilterOpen}
            />
        </div>
    );
};

const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ELASTICLISTSETTINGS:MainMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default ActionsMenu;
