import React from "react";
import { FieldProps } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { FormHelperText, Grid } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface INumberRange {
    type?: Compleo.IObject;
    minValue?: number | string;
    maxValue?: number | string;
    currency?: string;
}

interface IProps {
    label: string;
    labelMinField: string;
    labelMaxField: string;
    labelTypeTitle: string;
    helperTextDefault: null | string;
    value?: any;
    language: "pt-BR" | "en-US" | "fr" | "es";
    maxLength?: number;
    decimalDigits?: number;
    options: any;
}

interface IInputProps {
    maxLength?: number;
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
}

const formatMap = {
    "pt-BR": {
        thousandSeparator: ".",
        decimalSeparator: ","
    },
    "en-US": {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    fr: {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    es: {
        thousandSeparator: ",",
        decimalSeparator: "."
    }
};

const currencies = [
    {
        value: "BRL",
        label: "R$"
    },
    {
        value: "EUR",
        label: "€"
    },
    {
        value: "USD",
        label: "$"
    }
];

const useStyles = makeStyles((theme: Theme) => ({
    formControlLabel: { fontSize: "0.7rem", "& label": { fontSize: "0.7rem" } },
    formLabel: { fontSize: "0.775rem", "& label": { fontSize: "0.775rem" } },
    root: {
        padding: "3px",
        "& input": {
            height: "24px"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: "0.875rem"
        }
    }
}));

const NumberRangeField = (props: IProps & FieldProps) => {
    const {
        label,
        labelMinField,
        labelMaxField,
        labelTypeTitle,
        field,
        form: { touched, errors, status, setFieldValue },
        helperTextDefault,
        value,
        language,
        maxLength,
        decimalDigits,
        options,
        //inputRef,
        ...other
    } = props;

    const classes = useStyles();

    let inputPropsObj: IInputProps = {};
    if (maxLength) {
        inputPropsObj["maxLength"] = maxLength;
    }

    if (field.value && field.value.type === "percentage") {
        //endAdornment =
        inputPropsObj["endAdornment"] = (
            <InputAdornment position="end">%</InputAdornment>
        );
    }

    if (
        field.value &&
        field.value.type !== "percentage" &&
        field.value.currency
    ) {
        const currencyItem: any = currencies.filter(
            (c) => c.value === field.value.currency
        );
        //console.log("curreny item", currencyItem);
        inputPropsObj["startAdornment"] = (
            <InputAdornment position="start">
                {currencyItem[0].label || field.value.currency}
            </InputAdornment>
        );
    }

    const handleRadioGroupChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newValue = options.filter(
            (item: any) => item.value === event.target.value
        )[0];
        setFieldValue(field.name, {
            ...field.value,
            type: newValue
        });
    };

    const handleValueChange = (prop: string) => (
        values: NumberFormatValues
    ) => {
        setFieldValue(field.name, {
            ...field.value,
            [prop]: values.floatValue
        });
    };

    const errorText = errors[field.name];

    //const hasError = touched[field.name] && errorText !== undefined;
    const hasError =
        touched[field.name] &&
        errorText !== undefined &&
        ((errorText as INumberRange).minValue !== undefined ||
            (errorText as INumberRange).maxValue !== undefined);

    let errorMessageMinValue =
        hasError &&
        errorText !== undefined &&
        (errorText as INumberRange).minValue !== undefined
            ? String((errorText as INumberRange).minValue)
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    let errorMessageMaxValue =
        hasError &&
        errorText !== undefined &&
        (errorText as INumberRange).maxValue !== undefined
            ? String((errorText as INumberRange).maxValue)
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    let errorMessageType =
        hasError &&
        errorText !== undefined &&
        (errorText as INumberRange).type !== undefined
            ? String((errorText as INumberRange).type?.value)
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberFormat
                        id={`${field.name}.minValue`}
                        label={labelMinField}
                        type="text"
                        error={
                            hasError ||
                            (status && status[field.name] !== undefined)
                        }
                        helperText={errorMessageMinValue}
                        onValueChange={handleValueChange("minValue")}
                        thousandSeparator={
                            formatMap?.[language]?.thousandSeparator ||
                            formatMap?.["pt-BR"]?.thousandSeparator
                        }
                        decimalSeparator={formatMap[language].decimalSeparator}
                        isNumericString={true}
                        allowNegative={false}
                        decimalScale={decimalDigits}
                        fixedDecimalScale={true}
                        customInput={TextField}
                        InputProps={inputPropsObj}
                        {...field}
                        onChange={undefined}
                        value={field.value.minValue}
                        {...other}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberFormat
                        id={`${field.name}.maxValue`}
                        label={labelMaxField}
                        type="text"
                        error={
                            hasError ||
                            (status && status[field.name] !== undefined)
                        }
                        helperText={errorMessageMaxValue}
                        onValueChange={handleValueChange("maxValue")}
                        thousandSeparator={
                            formatMap[language].thousandSeparator
                        }
                        decimalSeparator={formatMap[language].decimalSeparator}
                        isNumericString={true}
                        allowNegative={false}
                        decimalScale={decimalDigits}
                        fixedDecimalScale={true}
                        customInput={TextField}
                        InputProps={inputPropsObj}
                        //getInputRef={inputRef}
                        {...field}
                        onChange={undefined}
                        value={field.value.maxValue}
                        {...other}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                        component="fieldset"
                        error={
                            hasError ||
                            (status && status[field.name] !== undefined)
                        }
                    >
                        <FormLabel component="legend">
                            <Typography className={classes.formLabel}>
                                {labelTypeTitle}
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label={labelTypeTitle}
                            id={`${field.name}.type`}
                            // defaultValue={
                            //     field.value?.type?.value ||
                            //     options[0].value?.type?.value
                            // }
                            onChange={handleRadioGroupChange}
                            value={field.value?.type?.value}
                        >
                            {options.map((c: any) => {
                                //console.log("FormControleLabel", c);
                                return (
                                    <FormControlLabel
                                        key={c.value}
                                        value={c.value}
                                        control={
                                            <Radio
                                                size="small"
                                                className={classes.root}
                                            />
                                        }
                                        label={
                                            <Typography
                                                className={
                                                    classes.formControlLabel
                                                }
                                            >
                                                {c[`label-${language}`]}
                                            </Typography>
                                        }
                                        labelPlacement="top"
                                    />
                                );
                            })}
                            <FormHelperText>{errorMessageType}</FormHelperText>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

NumberRangeField.defaultProps = {
    language: "pt-BR",
    decimalDigits: 2
};

export default NumberRangeField;
