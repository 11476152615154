import * as Yup from "yup";
import { isValidCPF } from "sharedCompleo/validation";
import {
    isValidPhoneNumber,
    isValidPhoneNumberMobile,
    isValidPhoneNumberNotMobile,
    isValidCNPJ,
    isValidCNPJCPF
} from "sharedCompleo/validation";
import * as clienteValidation from "./validationOnlyClient";

import isValid from "date-fns/isValid";
import _ from "lodash";

Yup.addMethod(Yup.string, "isValidCNPJCPF", function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;

    return this.test("testecnpjcpf", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return true;
        }

        if (isValidCNPJCPF(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidCPF", function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;

    return this.test("testcpf", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return true;
        }

        if (isValidCPF(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidCNPJ", function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;

    return this.test("testcnpj", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return true;
        }

        if (isValidCNPJ(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidPhoneNumber", function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;

    return this.test("testphonenumber", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos

        if (value === undefined) {
            return true;
        }

        if (isValidPhoneNumber(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidMobilePhoneNumber", function (
    anyArgsYouNeed
) {
    const { message } = anyArgsYouNeed;

    return this.test("testphonenumber", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return true;
        }

        if (isValidPhoneNumberMobile(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidNotMobilePhoneNumber", function (
    anyArgsYouNeed
) {
    const { message } = anyArgsYouNeed;

    return this.test("testphonenumber", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return true;
        }

        if (isValidPhoneNumberNotMobile(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.date, "isValidDate", function (anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;

    return this.test("testDate", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined || value === null || value === "") {
            return true;
        }

        if (_.isDate(value) && !isNaN(value.getTime())) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
    // const dateTransform = (curVal: Date) =>
    //     isValid(curVal) ? curVal : undefined;

    // return this.transform(dateTransform);
});

Yup.addMethod(Yup.number, "isValidDecimal", function (anyArgsYouNeed) {
    const decimalTransform = (curVal: number) =>
        !isNaN(curVal) ? curVal : undefined;

    return this.transform(decimalTransform);
});

Yup.addMethod(Yup.number, "isMoreThanOrEmptyCurrency", function (
    anyArgsYouNeed
) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("isMoreThanOrEmptyCurrency", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        // @ts-ignore
        const { parent, from } = this;
        const grandParent = from[1].value;
        const compareValue = ((grandParent || {})[fieldToCompare] || {}).value;

        if (!value || !compareValue) {
            return true;
        }

        if (value > compareValue) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.number, "isMoreThanOrEmpty", function (anyArgsYouNeed) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("moreThan", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        // let a = Yup.ref(`#/${fieldToCompare}.value`);
        let b = Yup.ref(fieldToCompare);
        const compareValue = this.resolve(b);

        if (!value || !compareValue) {
            return true;
        }

        if (Number(value) > Number(compareValue)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.date, "isMoreThanOrEmpty", function (anyArgsYouNeed) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("moreThan", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        const compareValue = this.resolve(Yup.ref(fieldToCompare));

        if (!value || !compareValue) {
            return true;
        }

        if (Date.parse(value) > Date.parse(compareValue)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

// ****
Yup.addMethod(Yup.number, "isMoreThanOrEqualOrEmptyCurrency", function (
    anyArgsYouNeed
) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("isMoreThanOrEqualOrEmptyCurrency", message, function (
        value
    ) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        // @ts-ignore
        const { parent, from } = this;
        const grandParent = from[1].value;
        const compareValue = ((grandParent || {})[fieldToCompare] || {}).value;

        if (!value || !compareValue) {
            return true;
        }

        if (value >= compareValue) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.number, "isMoreThanOrEqualOrEmpty", function (
    anyArgsYouNeed
) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("moreThan", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        const compareValue = this.resolve(Yup.ref(fieldToCompare));

        if (!value || !compareValue) {
            return true;
        }

        if (Number(value) >= Number(compareValue)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.date, "isMoreThanOrEqualOrEmpty", function (anyArgsYouNeed) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("moreThan", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        const compareValue = this.resolve(Yup.ref(fieldToCompare));

        if (!value || !compareValue) {
            return true;
        }

        if (Date.parse(value) >= Date.parse(compareValue)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "isValidHttpOrHttpAddress", function (
    anyArgsYouNeed
) {
    const { fieldToCompare, message } = anyArgsYouNeed;
    const isValidHttpOrHttpAddress = (value: string) => {
        let url;

        try {
            url = new URL(value);
        } catch (e) {
            console.log(JSON.stringify(e, null, 4));
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    };

    return this.test("isValidHttpOrHttpAddress", message, function (value) {
        // console.log("httpvalue", value);
        if (isValidHttpOrHttpAddress(value)) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "equalTo", function (anyArgsYouNeed) {
    const { fieldToCompare, message } = anyArgsYouNeed;

    return this.test("equalTo", message, function (value) {
        // const { some, more, args } = anyArgsYouNeed; //Caso necessário passar mais argumentos
        if (value === undefined) {
            return false;
        }

        if (this.resolve(Yup.ref(fieldToCompare)) === value) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "emailExistsAtCompany", function (anyArgsYouNeed) {
    const { companyId, message } = anyArgsYouNeed;

    return this.test("emailExistsAtCompany", message, async function (value) {
        if (value === undefined || value === "") {
            return true;
        }
        const data = await clienteValidation.validEmailExistsAtCompanyDebounced(
            value,
            companyId
        );
        if (data === true || data === undefined) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "valueExistsInArray", function (anyArgsYouNeed) {
    const { fieldToCompare, levelObject = 1 } = anyArgsYouNeed;
    let { message } = anyArgsYouNeed;
    return this.test("valueExistsInArray", message, async function (value) {
        // debugger;
        let isValid = false;
        let tagName = "";
        const regex = /{([^}]+)}}/g;

        let placeHolders: any = [];
        if (value !== undefined && value !== "") {
            placeHolders = value.match(regex);
        }

        if (placeHolders !== null && placeHolders.length > 0) {
            //placeHolders = placeHolders.filter((u: any) => u !== "");
            for (var ph in placeHolders) {
                placeHolders[ph] = placeHolders[ph]
                    .replace("{{", "")
                    .replace("}}", "");

                // @ts-ignore
                const { parent, from } = this;
                const grandParent = from[levelObject].value;
                const compareValue = (grandParent || {})[fieldToCompare] || {};
                if (compareValue.length !== undefined) {
                    isValid = await compareValue.includes(placeHolders[ph]);
                    if (!isValid) {
                        tagName = placeHolders[ph];
                        break;
                    }
                }

                // compareValue.map((p: string) => {
                //     if (p.toLowerCase() === placeHolders[ph].toLowerCase()) {
                //         isValid = true;
                //     }
                // });
            }
            if (isValid) {
                return true;
            } else {
                const { path, createError } = this;
                //message = message + `: ${tagName}`;
                const erro = createError({ path, message });
                return erro;
            }
        } else {
            return true;
        }

        // let b = Yup.ref(fieldToCompare);
        // const compareValue = this.resolve(b);

        // if (!value || !compareValue) {
        //     return true;
        // }

        // if (Number(value) > Number(compareValue)) {
        //     return true;
        // } else {
        //     const { path, createError } = this;
        //     const erro = createError({ path, message });
        //     return erro;
        // }
    });
});

Yup.addMethod(Yup.string, "valueRequiredInArray", function (anyArgsYouNeed) {
    const { fieldToCompare, levelObject = 1 } = anyArgsYouNeed;
    let { message } = anyArgsYouNeed;
    return this.test("valueRequiredInArray", message, async function (value) {
        // debugger;
        let isValid = false;
        const regex = /{([^}]+)}}/g;

        let placeHolders: any = [];
        if (value !== undefined && value !== "") {
            placeHolders = value.match(regex);
        }

        // @ts-ignore
        const { parent, from } = this;
        const grandParent = from[levelObject].value;
        const compareValue = (grandParent || {})[fieldToCompare] || {};
        if (compareValue.length !== undefined) {
            const placeholderInField: any[] = Array.isArray(placeHolders)
                ? placeHolders
                : [];

            isValid = true;
            for (const requiredPlaceholder of compareValue) {
                if (
                    !placeholderInField.includes(`{{${requiredPlaceholder}}}`)
                ) {
                    isValid = false;
                    break;
                }
            }
        } else {
            isValid = true;
        }

        if (isValid) {
            return true;
        } else {
            const { path, createError } = this;
            //message = message + `: ${tagName}`;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

Yup.addMethod(Yup.string, "genericServerValidation", function (anyArgsYouNeed) {
    const { companyId, message, validationType } = anyArgsYouNeed;

    return this.test("genericServerValidation", message, async function (
        value
    ) {
        if (value === undefined || value === "") {
            return true;
        }

        const data = await clienteValidation.validGenericServerDebounced(
            value,
            companyId,
            validationType
        );

        // console.log("genericServerValidation", value, data);

        if (data === true || data === undefined) {
            return true;
        } else {
            const { path, createError } = this;
            const erro = createError({ path, message });
            return erro;
        }
    });
});

export default Yup;
