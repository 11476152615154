import React from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginTop: 4,
            marginRight: theme.spacing(0.5),
            // marginLeft: 8,
            borderRadius: 6
        },
        chipTable: {
            borderRadius: 6
        }
    })
);

type RecipientStatusTagsColorType = {
    [x in CompleoShared.Common.ScheduleMessageType]?: string;
};
export const RecipientStatusTagsColor: RecipientStatusTagsColorType = {
    // approvedRequest: "#ffac33",
    // canceled: "#ff3d00",
    // disapprovedRequest: "#ff3d00",
    // draft: "#9e9e9e",
    ACCEPTED: "#4caf50",
    MAYBE: "#ffac33",
    // applicant: "#795548",
    // user: "#ffac33",
    DECLINED: "#ff3d00",
    CANCELINVITE: "#ff3d00",
    INVITE: "#2196f3",
    INVITEUPDATE: "#2196f3"
};

interface IRecipientsStatusTags {
    status: CompleoShared.Common.ScheduleMessageType;
    t: any;
}
const RecipientStatusTags = (props: IRecipientsStatusTags) => {
    const { status, t } = props;
    const classes = useStyles();

    return (
        <Chip
            className={classes.chipCard}
            style={{
                backgroundColor: RecipientStatusTagsColor[status],
                color: "white"
            }}
            size="small"
            label={t(`SCHEDULEMESSAGETYPE_${status}`)}
        />
    );
};

export default RecipientStatusTags;
