import React from "react";
import { CompleoListPage } from "customHooks/useList";

const ApplicantsReport = (props: any) => {
    const module = "REPORTSJOBS";

    return (
        <CompleoListPage
            moduleName={module}
            listApiAddress="/job/list"
        ></CompleoListPage>
    );
};

export default ApplicantsReport;
