import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import _ from "lodash";
import { CompleoErrorMessage } from "Components/CompleoErrorMessage";
import useCustomLists from "./components/useCustomLists";

const ContactEdit = (props: any) => {
    const classes = useFormStyles();
    const { company, reloadCompanyAndPermissionData } = useAuthState();

    const [metadata] = useGetMetadata("CONTACTEDIT");
    const [t, i18n, readyTranslation] = useTranslation("contactedit", {
        useSuspense: false
    });

    const [listDefinition, readyLists] = useCustomLists();
    const postAddress = `/contact/edit/${props.uuid}`;

    const [editContactReturn, editContact] = useApi(postAddress, "post");

    const postAddressSearch = `/contact/search/${props.uuid}`;
    const [getContactReturn, getContact] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getContactReturn,
        ["data", "dataContact", "Items"]
    );

    const editContactLocal = async (values: any) => {
        const returnFunction = await editContact(values);
        reloadCompanyAndPermissionData();
        return returnFunction;
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editContactLocal,
        editContactReturn,
        "/contact/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        undefined,
        undefined,
        undefined,
        null,
        listDefinition
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (getContactReturn.status === "error") {
        compRetorno = (
            <CompleoErrorMessage
                errorTitle={t("userNotFoundMessage")}
                errorDescription={t("userNotFoundMessageDetail")}
            />
        );
    }

    if (finished && readyTranslation && getContactReturn.status === "success") {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ContactEdit;
