import React from "react";
import ItemCard from "./ItemCard";
import Typography from "@material-ui/core/Typography";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "@gatsbyjs/reach-router";
import _ from "lodash";

interface IEmbedLink {
    url?: string;
    children: any;
}
const EmbedLink = (props: IEmbedLink) => {
    const { url, children } = props;

    if (url) {
        return (
            <Link component={RouterLink} to={url}>
                {children}
            </Link>
        );
    } else {
        return <>{children}</>;
    }
};

export default EmbedLink;
