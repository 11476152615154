import { useApiCache } from "customHooks/useApi";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";

export type useEmailTemplateType = {
    ready: boolean;
    placeholdersBodyList: Compleo.IObject[];
    placeholdersSubjectList: Compleo.IObject[];
    placeholdersBodyRequiredList: Compleo.IObject[];
    typesOfEmailList: Compleo.IObject[];
    typesOfEmail: Compleo.IObject;
    typeOfEmailDetailFilterReturn?: Compleo.IObject;
};

const useEmailTemplate = (typeOfEmailFilter?: string) => {
    const { company } = useAuthState();
    const [state, setState] = React.useState<useEmailTemplateType>({
        ready: false,
        placeholdersBodyList: [],
        placeholdersSubjectList: [],
        placeholdersBodyRequiredList: [],
        typesOfEmailList: [],
        typesOfEmail: {}
    });
    const moduleName = "EMAILORSCHEDULETEMPLATETYPEPLACEHOLDER";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const currentLanguage = i18n.languages[0];
    const [currentLanguageState, setCurrentLanguage] = React.useState(
        currentLanguage
    );

    React.useEffect(() => {
        if (currentLanguage !== currentLanguageState) {
            setCurrentLanguage(currentLanguage);
            setState({ ...state, ready: false });
        }
    }, [currentLanguage]);

    React.useEffect(() => {
        if (typeOfEmailFilter && state.ready) {
            setState({ ...state, ready: false });
        }
    }, [typeOfEmailFilter]);

    const postAddressSearch = `/emailtemplate/getemailtemplatepatterns`;
    const [searchResult] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );

    const placeholders = searchResult.response?.data?.placeholders;
    const typesOfEmail = searchResult.response?.data?.typesOfEmail;

    const ready =
        typesOfEmail &&
        placeholders &&
        readyTranslation &&
        typeOfEmailFilter !== "";

    if (ready && !state.ready) {
        const placeholdersList = placeholders.map((item: any) => {
            return {
                label: t(item.value),
                value: item.value
            };
        });

        const typesOfEmailList = Object.keys(typesOfEmail).map((key) => {
            return {
                label: t(`typeEmail_${key}`),
                value: key
            };
        });
        const typeOfEmailFiltered = typesOfEmail[typeOfEmailFilter || ""];

        const placeholderAllLists = getPlaceHolderList(
            placeholdersList,
            typeOfEmailFiltered
        );

        setState({
            ready: true,
            placeholdersBodyList: placeholderAllLists.placeholdersBodyList,
            placeholdersSubjectList:
                placeholderAllLists.placeholdersSubjectList,
            placeholdersBodyRequiredList:
                placeholderAllLists.placeholderBodyRequiredList,
            typesOfEmailList: typesOfEmailList,
            typesOfEmail: typesOfEmail,
            typeOfEmailDetailFilterReturn: typeOfEmailFiltered
        });
    }

    return state;
};

const getPlaceHolderList = (
    placeholdersList: Compleo.IObject[],
    typeOfEmail?: Compleo.IObject
) => {
    const placeholdersBodyList: Compleo.IObject[] = [];
    const placeholdersSubjectList: Compleo.IObject[] = [];
    const placeholderBodyRequiredList: Compleo.IObject[] = [];
    if (typeOfEmail) {
        const subjectItems = typeOfEmail.subjectPlaceholders || [];
        const bodyItems = typeOfEmail.bodyPlaceholders || [];
        const bodyRequiredItems = typeOfEmail.bodyPlaceholdersRequired || [];

        placeholdersList
            .filter((item: any) => subjectItems.includes(item.value))
            .map((item: any) => {
                placeholdersSubjectList.push(item);
            });
        placeholdersList
            .filter((item: any) => bodyItems.includes(item.value))
            .map((item: any) => {
                if (bodyRequiredItems.includes(item.value)) {
                    placeholdersBodyList.push({ ...item, required: true });
                } else {
                    placeholdersBodyList.push(item);
                }
            });

        placeholdersList
            .filter((item: any) => bodyRequiredItems.includes(item.value))
            .map((item: any) => {
                placeholderBodyRequiredList.push(item);
                if (!bodyItems.includes(item.value)) {
                    placeholdersBodyList.push({ ...item, required: true });
                }
            });
    }

    return {
        placeholdersBodyList,
        placeholdersSubjectList,
        placeholderBodyRequiredList
    };
};

export default useEmailTemplate;
