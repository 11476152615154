import React, { useState } from "react";
import {
    Grid,
    Box,
    Paper,
    Button,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import getAnswerFields from "./helpers/getAnswerFields";
import isObject from "lodash/isObject";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import SimulationResult from "../Simulation/SimulationResult";

export type ApplicantScreeningQuestionnaireAnswerDataType = {
    jobId?: string;
    jobTitle?: string;
    question: ApplicantScreeningQuestionnaireQuestionDataType;
    answer: ApplicantScreeningQuestionnaireAnswerItemDataType[];
};

export type ApplicantScreeningQuestionnaireQuestionDataType = {
    id: string;
    name: string;
    order: number;
    questiontype: Compleo.IObject;
    questionWithAnswers: boolean;
};

export type ApplicantScreeningQuestionnaireAnswerItemDataType = {
    id?: string;
    value: any;
};

export type ApplicantScreeningQuestionnaireDataType = {
    answers: ApplicantScreeningQuestionnaireAnswerDataType[];
    result?: any;
    startDate: any;
    endDate?: any;
};

export interface IUseCompleoScreeningQuestionnaireParams {
    t: any;
    jobScreeningQuestionnaireData: CompleoShared.Questionnaires.IQuestionnaire;
    jobData: Compleo.IObject;
    postMethod: any;
    isSimulation?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(6),
            width: "100%",
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4)
        }
    })
);

export const useCompleoScreeningQuestionnaire: (
    params: IUseCompleoScreeningQuestionnaireParams
) => [JSX.Element] = (params: IUseCompleoScreeningQuestionnaireParams) => {
    const {
        t,
        jobScreeningQuestionnaireData,
        jobData,
        postMethod,
        isSimulation
    } = params;

    const classes = useStyles();

    // console.log(
    //     "useCompleoScreeningQuestionnaire jobScreeningQuestionnaireData",
    //     jobScreeningQuestionnaireData
    // );

    // console.log("useCompleoScreeningQuestionnaire jobData", jobData);

    const initialApplicantScreeningQuestionnaireValues: ApplicantScreeningQuestionnaireDataType = {
        answers: [],
        startDate: new Date().toISOString()
    };
    const [questionnaireValues, setQuestionnaireValues] = useState<
        ApplicantScreeningQuestionnaireDataType
    >(initialApplicantScreeningQuestionnaireValues);

    const [questionIndex, setQuestionIndex] = useState(0);
    const [isStartMessagePage, setIsStartMessagePage] = useState(
        jobScreeningQuestionnaireData.enableStartMessage ?? false
    );
    const [isSimulationResultPage, setIsSimulationResultPage] = React.useState(
        false
    );

    const [currentAnswer, setCurrentAnswer] = useState<any | undefined>(
        undefined
    );
    const [error, setError] = useState(false);

    const questionnairePageType = isStartMessagePage
        ? "introductionPage"
        : !isSimulationResultPage
        ? "questionPage"
        : "simulationResultPage";

    const getAnswerObject = (questionIndex: number, answerValue: any) => {
        const question = jobScreeningQuestionnaireData.questions[questionIndex];
        // console.log(
        //     `getAnswerObject questionIndex:${questionIndex}, answerValue:${answerValue}`
        // );

        if (
            isObject(answerValue) &&
            !answerValue.hasOwnProperty("FileInfo") &&
            !Array.isArray(answerValue)
        ) {
            //MULTIPLE OPTIONS QUESTION TYPE
            const answer = question.answers.filter(
                (a: any) => Object(answerValue)[a.name] === true
            );

            const answerObject: ApplicantScreeningQuestionnaireAnswerDataType = {
                //questionId: question.id,
                jobId: jobData.pk ? jobData.pk.split(":")[1] : undefined,
                jobTitle: jobData.title ?? undefined,
                question: {
                    id: question.id,
                    name: question.name,
                    order: question.order,
                    questiontype: question.questiontype,
                    questionWithAnswers: question.questionWithAnswers
                },
                answer: answer.map((a: any) => ({
                    id: a.id,
                    value: {
                        stringValue: a.name
                    }
                }))
            };

            // console.log(
            //     "useCompleoScreeningQuestionnaire index getAnswerObject IF 1",
            //     answerObject
            // );

            return answerObject;
        } else if (Array.isArray(answerValue)) {
            //MULTIPLE FILES QUESTION TYPE
            //console.log("Answer isArray", answerValue);
            const answerObject: ApplicantScreeningQuestionnaireAnswerDataType = {
                jobId: jobData.pk ? jobData.pk.split(":")[1] : undefined,
                jobTitle: jobData.title ?? undefined,
                question: {
                    id: question.id,
                    name: question.name,
                    order: question.order,
                    questiontype: question.questiontype,
                    questionWithAnswers: question.questionWithAnswers
                },
                answer: answerValue.map((a: any) => ({
                    id: undefined,
                    value: a
                }))
            };
            //console.log("answerObject Array type", answerObject);
            // console.log(
            //     "useCompleoScreeningQuestionnaire index getAnswerObject IF 2",
            //     answerObject
            // );
            return answerObject;
        } else {
            const answer = question.answers.filter(
                (a: any) => a.name === answerValue
            );

            const answerObject: ApplicantScreeningQuestionnaireAnswerDataType = {
                jobId: jobData.pk ? jobData.pk.split(":")[1] : undefined,
                jobTitle: jobData.title ?? undefined,
                question: {
                    id: question.id,
                    name: question.name,
                    order: question.order,
                    questiontype: question.questiontype,
                    questionWithAnswers: question.questionWithAnswers
                },
                answer: [
                    {
                        id:
                            answer && answer.length === 1
                                ? answer[0].id
                                : undefined,
                        value: !isObject(answerValue)
                            ? { stringValue: answerValue }
                            : answerValue
                    }
                ]
            };

            // console.log(
            //     "useCompleoScreeningQuestionnaire index getAnswerObject IF 3",
            //     answerObject
            // );
            return answerObject;
        }
    };

    const handleAnswerButtonClick = () => {
        if (isStartMessagePage) {
            setIsStartMessagePage(false);
        } else {
            debugger;
            if (currentAnswer) {
                const answer = getAnswerObject(questionIndex, currentAnswer);
                const values = { ...questionnaireValues };
                if (Array.isArray(answer)) {
                    Array.prototype.push.apply(values.answers, answer);
                } else {
                    values.answers.push(answer);
                }
                setQuestionnaireValues({ ...values });

                //console.log("questionnaireValues", questionnaireValues);
                const questionnaireNextAction = getNextAction(
                    jobScreeningQuestionnaireData,
                    questionIndex,
                    answer
                );

                switch (questionnaireNextAction.nextAction) {
                    case "endQuestionnaire":
                    case "endQuestionnaireAndSetResult":
                        // console.log(
                        //     "Endquestionnaire post questionnaireValues",
                        //     questionnaireValues
                        // );
                        questionnaireValues.endDate = new Date().toISOString();
                        questionnaireValues.result =
                            questionnaireNextAction.result;

                        // postMethod({ ...questionnaireValues }).then(
                        //     (retorno: any) => {
                        //         console.log("postMethod return", retorno);
                        //     }
                        // );
                        setQuestionnaireValues(questionnaireValues);
                        setIsSimulationResultPage(true);

                        console.log("questionnaireValues", questionnaireValues);
                        break;
                    default:
                        setCurrentAnswer(undefined);
                        setError(false);
                        setQuestionIndex(
                            questionnaireNextAction.nextQuestionIndex ??
                                questionIndex + 1
                        );
                        break;
                }
            } else {
                //Resposta não informada
                setError(true);
                //console.log("error - resposta é obrigatória");
            }
        }
    };

    let questionnaireContent = <Loading />;
    //console.log("questionnairePageType", questionnairePageType);
    switch (questionnairePageType) {
        case "introductionPage":
            questionnaireContent = (
                <>
                    <Grid
                        container
                        direction="column"
                        spacing={1}
                        justify="space-between"
                    >
                        <Grid item>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        jobScreeningQuestionnaireData.startMessage
                                }}
                            ></div>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAnswerButtonClick}
                            >
                                Iniciar
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
            break;
        case "questionPage":
            const questionObject = jobScreeningQuestionnaireData?.questions?.filter(
                (q: any) => q.order === questionIndex
            );
            if (questionObject && questionObject.length === 1) {
                const questionElement = questionObject[0];
                //console.log("questionElement", questionElement);
                const answerElement = getAnswerFields({
                    t,
                    question: questionElement,
                    value: currentAnswer,
                    setValue: setCurrentAnswer,
                    error: error && !currentAnswer,
                    isSimulation: isSimulation
                });
                questionnaireContent = (
                    <>
                        <Grid
                            container
                            direction="column"
                            spacing={1}
                            justify="space-between"
                        >
                            <Grid item>
                                <h3>{questionElement.name}</h3>
                            </Grid>
                            <Grid item>{answerElement}</Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAnswerButtonClick}
                                >
                                    {t("nextStep")}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                );
            }
            break;
        case "simulationResultPage":
            questionnaireContent = (
                <SimulationResult
                    questionnaireValues={questionnaireValues}
                    jobScreeningQuestionnaireData={
                        jobScreeningQuestionnaireData
                    }
                    t={t}
                />
            );
            break;
        default:
            break;
    }

    const element = (
        <Box>
            <Paper className={classes.paper}>{questionnaireContent}</Paper>
        </Box>
    );
    return [element];
};

type questionnaireNextAction = {
    nextAction:
        | "goToNextQuestion"
        | "goToSpecificQuestion"
        | "endQuestionnaire"
        | "endQuestionnaireAndSetResult";
    nextQuestionIndex?: number;
    result?: string;
};

const getNextAction = (
    jobScreeningQuestionnaireData: any,
    questionIndex: number,
    answer: ApplicantScreeningQuestionnaireAnswerDataType
): questionnaireNextAction => {
    const questionsTotalNumber = jobScreeningQuestionnaireData.questions.length;
    const question = jobScreeningQuestionnaireData.questions[questionIndex];

    if (
        question.questiontype.value === "CPQUESTIONSINGLECHOICE" &&
        question.enableActions
    ) {
        //Escolha única pode ter ação em cada resposta
        //Verificar se resposta selecionada tem action
        const selectedAnswer = question.answers.filter(
            (a: any) =>
                (answer.answer[0].id && a.id === answer.answer[0].id) ||
                a.name === answer.answer[0].value
        )[0];
        const action = selectedAnswer.action
            ? selectedAnswer.action
            : question.action;

        return getNextActionObject(
            jobScreeningQuestionnaireData,
            questionsTotalNumber,
            questionIndex,
            action
        );
    }

    return getNextActionObject(
        jobScreeningQuestionnaireData,
        questionsTotalNumber,
        questionIndex,
        question.enableActions && question.action ? question.action : null
    );
};

const getNextActionObject = (
    jobScreeningQuestionnaireData: any,
    questionsTotalNumber: number,
    questionIndex: number,
    action: any
): questionnaireNextAction => {
    if (action) {
        switch (action.group) {
            case "Resultados":
                if (action.value !== "finished") {
                    return {
                        nextAction: "endQuestionnaireAndSetResult",
                        result: action.value
                    };
                }

                return {
                    nextAction: "endQuestionnaire"
                };
            case "Pergunta":
                const nextQuestion = jobScreeningQuestionnaireData.questions.filter(
                    (q: any) => q.id === action.value
                )[0];

                if (nextQuestion) {
                    return {
                        nextAction: "goToSpecificQuestion",
                        nextQuestionIndex: nextQuestion.order
                    };
                }
                break;
            default:
                break;
        }
    }

    return {
        nextAction:
            questionsTotalNumber > questionIndex + 1
                ? "goToNextQuestion"
                : "endQuestionnaire"
    };
};

export default useCompleoScreeningQuestionnaire;
