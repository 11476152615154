import { DataTable } from "customHooks/DataTable";
import useList from "customHooks/useList";
import { columns } from "./columns";
import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";

const MainCurrentStageReport = () => {
    const [listData] = useList();
    const t = listData.tempInfo.t;
    const language = listData.tempInfo.language;
    const analyticsData =
        listData.listReturnInfo?.otherData?.currentStage || [];

    const analyticsColumns = columns(t, language);
    const { filtersUserInfo } = listData.listReturnInfo;
    const ready =
        listData.listReturnInfo.queryFinished &&
        listData.definitionInfo?.elasticDefData !== undefined;
    if (!ready) {
        return <Loading />;
    }

    return (
        <Paper
            style={{
                padding: 24,
                marginTop: filtersUserInfo.length > 0 ? 16 : 0
            }}
        >
            <Box flex flexDirection={"col"}>
                <ReportTitle>{t("currentStage")}</ReportTitle>
                <DataTable columns={analyticsColumns} data={analyticsData} />
            </Box>
        </Paper>
    );
};

interface IReportTitle {
    children: React.ReactNode;
}
const ReportTitle = (props: IReportTitle) => {
    const { children } = props;
    return (
        <Typography
            variant="body2"
            gutterBottom
            style={{
                marginTop: 12,
                fontSize: 16,
                marginBottom: 12,
                fontWeight: 600
            }}
        >
            {children}
        </Typography>
    );
};

export default MainCurrentStageReport;
