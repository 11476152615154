import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { BroadcastChannel } from "broadcast-channel";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TranslateIcon from "@material-ui/icons/Translate";
import { TFunction } from "i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import { IUseModalNotificationsReturn } from "Pages/Notification/useModalNotifications";
import { LOGOUTCONTROLCHANNELNAME } from "auth/useIdleTimer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuBoldTitle: {
            fontWeight: "bold",
            border: "none",
            outline: "none"
        }
    })
);

interface IProps {
    anchorEl: null | HTMLElement;
    mobileMoreAnchorEl: null | HTMLElement;
    setAnchorEl: any;
    setMobileMoreAnchorEl: any;
    setMenuElementChangeCompany: any;
    mobile: boolean;
    menuId: string;
    handleLanguageMenuOpen: any;
    handleProfileMenuOpen: any;
    t: TFunction;
    container?: Element;
    modalNotificationsObj: IUseModalNotificationsReturn;
}

const RenderMenu = (props: IProps) => {
    const classes = useStyles();
    const { modalNotificationsObj } = props;
    const { company, logout, allCompanies } = useAuthState();
    const t = props.t;
    const channel = new BroadcastChannel(LOGOUTCONTROLCHANNELNAME);
    const isMenuOpen = Boolean(props.anchorEl);

    const isMobileMenuOpen = Boolean(props.mobileMoreAnchorEl);

    const handleChangeCompanyMenuOpen = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        if (allCompanies.length > 1) {
            props.setMenuElementChangeCompany(event.currentTarget);
            handleMenuClose();
        }
    };

    const handleMobileMenuClose = () => {
        props.setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        props.setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleProfileOpen = () => {
        navigate(`/profile/update`);
    };

    const handleAccountOpen = () => {
        navigate(`/profile/changepassword`);
    };

    const Logout = () => {
        channel.postMessage("logout");
        logout();
        // Auth.signOut();
        // navigate(`/login`);
    };

    const menuId = props.menuId;

    if (!props.mobile) {
        return (
            <Menu
                anchorEl={props.anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem className={classes.menuBoldTitle} button={false}>
                    {t("yourcompany")}
                </MenuItem>
                <MenuItem
                    onClick={handleChangeCompanyMenuOpen}
                    button={allCompanies.length > 1 ? true : undefined}
                >
                    {company.companyFancyName}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleProfileOpen}>{t("profile")}</MenuItem>
                <MenuItem onClick={handleAccountOpen}>{t("account")}</MenuItem>
                <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
        );
    } else {
        return (
            <Menu
                anchorEl={props.mobileMoreAnchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                {/* <MenuItem>
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>{t("messages")}</p>
                </MenuItem> */}
                <MenuItem onClick={modalNotificationsObj.openMenu}>
                    <IconButton
                        aria-label={`exibir ${
                            modalNotificationsObj.total || 0
                        } novas notificações`}
                        color="inherit"
                    >
                        <Badge
                            badgeContent={modalNotificationsObj.total}
                            color="secondary"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p style={{ marginLeft: 8 }}>{t("notifications")}</p>
                </MenuItem>
                <MenuItem onClick={props.handleLanguageMenuOpen}>
                    <TranslateIcon
                        aria-label={t("changelanguage")}
                        color="inherit"
                    ></TranslateIcon>
                    <p style={{ marginLeft: 8 }}>{t("changelanguage")}</p>
                </MenuItem>
                <MenuItem onClick={props.handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p style={{ marginLeft: 8 }}>{t("profile")}</p>
                </MenuItem>
            </Menu>
        );
    }
    // return null;
};

export default RenderMenu;
