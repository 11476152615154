import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { apiDirectCall } from "customHooks/useApi";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface IModalFinishEvaluation {
    open: boolean;
    handleClose: () => void;
    finishEvaluation: () => Promise<void>;
    t: any;
    totalApplicants?: number;
    applicantsEvaluated?: number;
    isCalledAfterLastApplicant?: boolean;
}

const ModalFinishEvaluation = (props: IModalFinishEvaluation) => {
    const {
        open,
        handleClose,
        t,
        totalApplicants = 0,
        applicantsEvaluated = 0,
        isCalledAfterLastApplicant = false,
        finishEvaluation
    } = props;

    let message = t("finishEvaluation_Message");

    const noApplicantEvaluated = applicantsEvaluated === 0;
    if (isCalledAfterLastApplicant) {
        message = t("finishEvaluation_MessageAfterLast");
    } else {
        if (noApplicantEvaluated) {
            message = t("finishEvaluation_noApplicantEvaluated");
        } else {
            const pendingApplicants = totalApplicants - applicantsEvaluated;
            if (pendingApplicants > 0) {
                message = t("finishEvaluation_MessageApplicantsPending", {
                    applicantsNotEvaluated: pendingApplicants
                });
            }
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {t("finishEvaluation_ModalTitle")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {noApplicantEvaluated ? (
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                ) : (
                    <>
                        <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="outlined"
                        >
                            {t("COMPLEOGENERAL_NO")}
                        </Button>
                        <Button
                            onClick={finishEvaluation}
                            color="primary"
                            variant="outlined"
                        >
                            {t("COMPLEOGENERAL_YES")}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalFinishEvaluation;
