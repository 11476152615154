import { FormikErrors, FormikTouched } from "formik";
import _ from "lodash";

export const getErrorAndMessages = (
    errors: FormikErrors<any>,
    fieldName: string,
    touched: FormikTouched<any>,
    status?: any,
    helperTextDefault?: string | null
) => {
    const errorText = _.get(errors, fieldName); // errors[field.name];
    const hasError = _.get(touched, fieldName) && errorText !== undefined; // touched[field.name] && errorText !== undefined;

    const finalErrorText = Array.isArray(errorText)
        ? errorText.join("")
        : errorText;

    let errorMessage =
        hasError && finalErrorText !== undefined
            ? finalErrorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    const formikStatus = _.get(status, fieldName);
    if (formikStatus) {
        if (errorMessage === "") {
            errorMessage += formikStatus;
        } else {
            errorMessage += " - " + formikStatus;
        }
    }
    const error = hasError || formikStatus !== undefined;
    return { error, message: errorMessage };
};

const getErroMessage = (errorDetail: any) => {
    if (typeof errorDetail === "string") {
        return errorDetail;
    } else if (typeof errorDetail === "object") {
        let errorMessage: any = "";
        Object.entries(errorDetail).forEach(([key, value]) => {
            if (errorMessage === "") {
                errorMessage = value;
            } else {
                errorMessage = `${errorMessage} - ${value}`;
            }
        });
        return errorMessage;
    }
    return undefined;
};

export const getErrorAndMessagesUseField = (
    errorDetail: any,
    touched: boolean,
    helperTextDefault?: string | null
) => {
    const hasError = touched && errorDetail !== undefined;
    const errorMessage = getErroMessage(errorDetail);

    const finalErrorMessage =
        hasError && errorMessage !== undefined
            ? errorMessage
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    return { error: hasError, message: finalErrorMessage };
};
