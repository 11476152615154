import { useAuthState } from "_ReactContext/AuthContext";
import { apiDirectCall } from "customHooks/useApi";
import { useEffect, useState } from "react";

const useJobRequestLastRequestCorrectionReason = (
    jobId: string,
    newStatus: CompleoShared.Common.JobStatus
) => {
    const { company } = useAuthState();

    const [reason, setReason] = useState("");

    useEffect(() => {
        if (newStatus === "requested") {
            apiDirectCall("/job/jobhistorylist", "post", {
                companyId: company.companyId,
                jobId: jobId
            }).then((res) => {
                const data = res?.data?.data || [];
                data.sort((a: any, b: any) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    );
                });
                const lastDisapproved = data.filter(
                    (item: any) => item.status === "awaitingCorrectionRequest"
                )[0];
                setReason(lastDisapproved?.comment);
            });
        }
    }, [newStatus]);
    return reason;
};

export default useJobRequestLastRequestCorrectionReason;
