import React from "react";
import useUpdateCard from "../../useUpdateCard";
import useRefreshKanban from "../../useRefreshKanban";

const useJobChangeStageFunctionParam = () => {
    const { changeCardStateData } = useUpdateCard();
    const updateAllInfo = useRefreshKanban();

    const jobChangeStageFunction = async (params: any) => {
        const {
            outsideKanban,
            destinationIndex,
            applicantId,
            stageData,
            currentIndex,
            newStageId
        } = params;
        debugger;
        if (!outsideKanban) {
            changeCardStateData(
                //@ts-ignore
                {
                    draggableId: applicantId,
                    mode: "FLUID",
                    reason: "DROP",
                    type: "",
                    source: {
                        droppableId: stageData?.id,
                        index: currentIndex
                    },
                    destination: {
                        droppableId: newStageId,
                        index: destinationIndex
                    }
                },
                false
            );
        }
        if (outsideKanban) {
            updateAllInfo();
        }
    };
    return { jobChangeStageFunction };
};

export default useJobChangeStageFunctionParam;
