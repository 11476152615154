import { ApiStateType } from "customHooks/useApi";
import { IScheduleEmailData } from "./ScheduleData";

export const getTemplatesFromScheduleDataDB = (
    getScheduleTemplateResult: ApiStateType,
    splitTemplatesByLanguage: boolean,
    t: any,
    type?: string
) => {
    const templates: IScheduleEmailData[] = [];

    (getScheduleTemplateResult.response?.data?.data || [])
        .filter((item: Compleo.IObject) => item.type === type)
        .map((item: Compleo.IObject) => {
            if (splitTemplatesByLanguage) {
                (item.template || []).map((d: any) => {
                    const dataReturn: IScheduleEmailData = {
                        label: `${item.name} - ${t(`Language_${d.language}`)}`,
                        id: item.skGS1pk.split(":")[1],
                        items: [
                            {
                                language: d.language,
                                location: d.location || "",
                                body: d.body || "",
                                subject: d.subject || ""
                            }
                        ]
                    };
                    templates.push(dataReturn);
                });
            } else {
                const dataReturn: IScheduleEmailData = {
                    label: item.name,
                    id: item.skGS1pk.split(":")[1],
                    items: (item.template || []).map((d: any) => {
                        return {
                            language: d.language,
                            location: d.location || "",
                            body: d.body || "",
                            subject: d.subject || ""
                        };
                    })
                };
                templates.push(dataReturn);
            }
        });
    return templates;
};
