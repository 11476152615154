import TextField from "@material-ui/core/TextField";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import {
    formatGeneral,
    formatCreditCard,
    formatDate,
    formatNumeral,
    formatTime
} from "cleave-zen";
interface ILocalProps extends IInputProps {
    cleaveZenOptions?: {
        functionName?: string;
        functionOptions: any;
    };
}
const FTextFieldMask = (props: ILocalProps) => {
    const {
        name,
        label,
        helperTextDefault,
        maxLength,
        t,
        cleaveZenOptions,
        ...other
    } = props;
    const { error, message, field, setFieldValue } = useRhfFieldControlled(
        name,
        helperTextDefault
    );
    console.log("cleaveZenOptions", cleaveZenOptions);

    const handleChange = (e: any) => {
        if (cleaveZenOptions?.functionOptions) {
            const functionToRun = getFunctionCleaveZen(
                cleaveZenOptions?.functionName
            );
            const newValue = functionToRun(
                e.target.value,
                cleaveZenOptions?.functionOptions
            );
            setFieldValue(newValue);
        } else {
            setFieldValue(e.target.value);
        }
    };

    return (
        <TextField
            label={label}
            id={name}
            error={error}
            helperText={message}
            {...other}
            inputProps={{
                maxLength: maxLength
            }}
            inputRef={field.ref}
            onBlur={field.onBlur}
            value={field.value}
            onChange={handleChange}
        />
    );
};

const getFunctionCleaveZen = (functionName?: string) => {
    switch (functionName) {
        case "formatGeneral":
            return formatGeneral;
        case "formatCreditCard":
            return formatCreditCard;
        case "formatDate":
            return formatDate;
        case "formatNumeral":
            return formatNumeral;
        case "formatTime":
            return formatTime;
        default:
            return formatGeneral;
    }
};

export default FTextFieldMask;
