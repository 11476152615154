import { useApiCache } from "customHooks/useApi";
import React from "react";

const useAdvancedSearchCustomFields = (
    moduleName: string,
    companyId: number
) => {
    let mainModuleCustomField = "";
    switch (moduleName) {
        case "JOBLIST":
            mainModuleCustomField = "JOB";
            break;
        case "APPLICANTLIST":
            mainModuleCustomField = "APPLICANT";
            break;
        default:
            break;
    }
    const [customFields, runQuery] = useApiCache(
        "/customfields/customfieldslist",
        "post",
        {
            companyId: companyId,
            module: mainModuleCustomField
        },
        true,
        1000 * 60 * 10
    );

    React.useEffect(() => {
        if (mainModuleCustomField) {
            runQuery();
        }
    }, [mainModuleCustomField]);

    const customFieldsDef: Compleo.IObject[] =
        customFields.response?.data?.fields || [];

    return {
        customFields: customFieldsDef,
        readyCustomFields:
            customFields.status === "success" || !mainModuleCustomField
    };
};

export default useAdvancedSearchCustomFields;
