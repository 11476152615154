import React from "react";
import ApplicantNewSwitch from "./ApplicantNewSwitch";
import useApplicantNewContext, {
    ApplicantNewProvider
} from "./useApplicantNewContext/useApplicantNewContext";
import { ApplicantNewRenderType } from "./useApplicantNewContext/ApplicantNewContextHelper";
import { RouteComponentProps } from "@gatsbyjs/reach-router";

export interface IApplicantNewMainContainer {
    operationid?: string;
    itemid?: string;
    render?: ApplicantNewRenderType;
    uuid?: string;
}

const ApplicantNewMainContainer = (
    props: RouteComponentProps & IApplicantNewMainContainer
) => {
    const {
        operationid,
        itemid,
        uuid,
        render = uuid ? "edit" : "normal"
    } = props;

    return (
        <ApplicantNewProvider
            moduleName="APPLICANTUPLOAD"
            operationid={operationid}
            itemid={itemid}
            renderType={render}
            editId={uuid}
        >
            <ApplicantNewSwitch {...props} />
        </ApplicantNewProvider>
    );
};

export default ApplicantNewMainContainer;
