import { Box, Grid, Paper } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import {
    FunnelChart,
    Tooltip,
    Funnel,
    LabelList,
    ResponsiveContainer
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import {
    handleFunnelData,
    IJobFunnelChildren,
    IMainJobFunnelData
} from "./funnelHelper";

const JobFunnelGraph = (props: IJobFunnelChildren) => {
    const { mainData, t } = props;

    const data = handleFunnelData(mainData, t);
    const colorData = useRandomColor({
        objectKeys: data.map((i) => i.name),
        luminosity: "dark"
    });
    const returnData = data.map((i) => {
        const color = colorData.colorsObject[i.name];
        return {
            ...i,
            fill: color
        };
    });

    return (
        <ResponsiveContainer
            // minHeight={200}
            // minWidth={200}
            width="99%"
            maxHeight={500}
        >
            <FunnelChart compact layout="centric">
                <Tooltip />
                <Funnel
                    dataKey="value"
                    data={returnData}
                    isAnimationActive={false}
                    width="70%"
                >
                    <LabelList
                        position="right"
                        fill="#000"
                        stroke="none"
                        dataKey="value"
                    />
                    <LabelList
                        position="left"
                        fill="#000"
                        stroke="none"
                        dataKey="name"
                    />
                </Funnel>
            </FunnelChart>
        </ResponsiveContainer>
    );
};

export default JobFunnelGraph;
