import React from "react";
import { useApiCache } from "customHooks/useApi";
import useList from "customHooks/useList";
import { useAuthState } from "_ReactContext/AuthContext";
import { IJobViewStages } from "Pages/Job/JobView/useJobContext/JobViewTypes";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { keepOnlyNumbersInString } from "functions/util";

export type BulkActionsType =
    | "applicant_Delete"
    | "applicant_addToJob"
    | "applicant_exportToFile"
    | "applicant_addTag"
    | "job_addTag"
    | "job_exportToFile"
    | "applicant_sendEmail"
    | "applicantjob_addToStage"
    | "applicantjob_disqualify"
    | "applicantjob_requalify"
    | "applicantjob_changeRejectedStage"
    | "applicantjob_requestEvaluation";

export interface IBulkActions {
    name: BulkActionsType;
    type: "online" | "offline";
}
export interface IListBulk {
    label: string;
    checked: boolean;
    value: string;
}

export type BulkActionChangeStageOptions =
    | "disqualify"
    | "requalify"
    | "changeStage"
    | "changeRejectedStage";

const applicantListBulkActions: IBulkActions[] = [
    { name: "applicant_addToJob", type: "online" },
    // { name: "applicant_exportToFile", type: "offline" },
    { name: "applicant_addTag", type: "offline" },
    { name: "applicant_sendEmail", type: "offline" },
    { name: "applicant_Delete", type: "offline" }
];
const jobListBulkActions: IBulkActions[] = [
    // { name: "job_addTag", type: "offline" },
    { name: "job_exportToFile", type: "offline" }
];
const applicantListFromJobBulkActions: IBulkActions[] = [
    // { name: "applicant_exportToFile", type: "offline" },
    { name: "applicant_addTag", type: "offline" },
    { name: "applicant_sendEmail", type: "offline" },
    { name: "applicantjob_addToStage", type: "online" },
    { name: "applicantjob_disqualify", type: "online" },
    { name: "applicantjob_requalify", type: "online" },
    { name: "applicantjob_changeRejectedStage", type: "online" },
    { name: "applicantjob_requestEvaluation", type: "offline" }
];
// const defaultBulkActions: BulkActionsType[] = ["exportToFile"];

export const getBulkActionsList = (
    moduleName: string,
    planName: string,
    BulkActionsRemoveFromList: BulkActionsType[] = []
) => {
    let returnData: IBulkActions[] = [];
    switch (moduleName) {
        case "APPLICANTLIST":
            returnData = applicantListBulkActions;
            break;
        case "JOBLIST":
        case "JOBREQUESTLIST":
            returnData = jobListBulkActions;
            break;
        case "JOBAPPLICANTLISTFILTERKANBAN":
            returnData = applicantListFromJobBulkActions;
            break;
        case "REPORTSJOBS":
            returnData = jobListBulkActions.filter(
                (item) => item.name !== "job_exportToFile"
            );
            break;
        default:
            returnData = [];
            break;
    }
    returnData = returnData.filter(
        (item: IBulkActions) => !BulkActionsRemoveFromList.includes(item.name)
    );
    return returnData;
};

const getBachTypeLimit = (
    bulkActionsSettings: Compleo.IObject,
    bulkType: IBulkActions
) => {
    const defaultMaxItems = 1000;
    const maxItems =
        (bulkActionsSettings?.maxItems || {})[bulkType.name] || defaultMaxItems;
    return maxItems;
};

export const stepValidations = (
    callDialog: any,
    t: any,
    stepBulk: number,
    bulkType: IBulkActions,
    bulkActionsSettings: Compleo.IObject,
    currentFilterTotal: number,
    listBulk: IListBulk[]
) => {
    if (stepBulk === 1 && bulkType) {
        const maxItems = getBachTypeLimit(bulkActionsSettings, bulkType);
        if (currentFilterTotal > maxItems) {
            callDialog({
                agreeButtonText: t("ELASTICLISTSETTINGS:GeneralModalClose"),
                title: t(
                    "ELASTICLISTSETTINGS:ModalBulkActions_MaxItemsMessageTitle"
                ),
                bodyText: t(
                    "ELASTICLISTSETTINGS:ModalBulkActions_MaxItemsMessageDescription",
                    { limit: maxItems, filtered: currentFilterTotal }
                ),
                agreeFunction: () => {
                    return false;
                }
            });
            return false;
        }
    }
    if (stepBulk === 2) {
        const selectedItems = listBulk.filter((item) => item.checked).length;
        if (selectedItems === 0) {
            callDialog({
                agreeButtonText: t("ELASTICLISTSETTINGS:GeneralModalClose"),
                title: t(
                    "ELASTICLISTSETTINGS:ModalBulkActions_EmptyErrorTitle"
                ),
                bodyText: t(
                    "ELASTICLISTSETTINGS:ModalBulkActions_EmptyErrorDescription"
                ),
                agreeFunction: () => {
                    return false;
                }
            });
            return false;
        }
    }
    return true;
};

const getLabel = (
    item: Compleo.IObject,
    labelFields: string[],
    splitString: string = "|"
) => {
    let returnValue = "";
    labelFields.map((localItem) => {
        if (returnValue) {
            returnValue = `${returnValue} ${splitString} ${item[localItem]}`;
        } else {
            returnValue = item[localItem];
        }
    });
    return returnValue;
};

export const useBulkLoadList = (
    listBulk: IListBulk[],
    setListBulk: React.Dispatch<React.SetStateAction<IListBulk[]>>,
    openDialog: boolean,
    otherGenericParams: Compleo.IObject = {}
) => {
    const [listData] = useList();
    const { company } = useAuthState();

    const fieldsToReturn =
        listData.definitionInfo.elasticDefData?.data?.otherSettings
            ?.bulkActionsSettings?.fieldsToReturnFromQuery || [];
    const labelFields =
        listData.definitionInfo.elasticDefData?.data?.otherSettings
            ?.bulkActionsSettings?.labelFields || [];
    const valueField =
        listData.definitionInfo.elasticDefData?.data?.otherSettings
            ?.bulkActionsSettings?.valueField || "pk";

    const params = {
        ...listData.filtersInfo.filters,
        companyId: company.companyId,
        returnAllItems: true,
        fieldsToReturn: fieldsToReturn,
        otherGenericParams: {
            ...(listData.filtersInfo.filters.otherGenericParams || {}),
            ...otherGenericParams
        }
    };

    const [data, getData] = useApiCache(
        listData.tempInfo.listApiAddress,
        "post",
        params,
        true,
        1000 * 60 * 5,
        false
    );

    React.useEffect(() => {
        if (openDialog) {
            setListBulk([]);
            getData(params);
        }
    }, [listData.filtersInfo.filters, openDialog]);

    const list = data.response?.data?.fields || [];
    const mainList: IListBulk[] = list.map((item: any) => {
        return {
            checked: true,
            label: getLabel(item, labelFields, "-"),
            value: item[valueField]
        };
    });
    const ready = data.status === "success";

    React.useEffect(() => {
        if (
            ready &&
            openDialog &&
            listBulk.length === 0 &&
            mainList.length > 0
        ) {
            setListBulk(mainList);
        }
    }, [ready, mainList, openDialog, listBulk.length]);

    return ready;
};

export const useJobListDropdown = () => {
    const listAddress = "/job/dropdownjobsopenstatus";
    const { company } = useAuthState();

    const [listResult, getList] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        false,
        60 * 1000 * 10
    );
    const ready = listResult.status === "success";
    const data: Compleo.IObject[] = (listResult.response?.data?.data || []).map(
        (item: Compleo.IObject) => {
            const code = Number(keepOnlyNumbersInString(item?.id || "") || 0);

            return {
                label: `${item.title} - (${code})`,
                value: item.id,
                labelSort: item.title
            };
        }
    );

    data.sort((a, b) => a.label.localeCompare(b.label));

    return { data, ready };
};

export const useJobList = () => {
    const listAddress = "/job/list";
    const { company } = useAuthState();

    const [listResult, getList] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId,
            pagination: {
                pageSize: 10000,
                currentPage: 1
            }
        },
        false,
        60 * 1000 * 10
    );
    const ready = listResult.status === "success";
    const data: Compleo.IObject[] = (listResult.response?.data?.fields || [])
        .filter((item: Compleo.IObject) => item.status === "open")
        .map((item: Compleo.IObject) => {
            const code = Number(keepOnlyNumbersInString(item?.pk || "") || 0);
            return {
                label: `${item.title} - (${code})`,
                value: item.pk,
                labelSort: item.title
            };
        });
    data.sort((a, b) => a.label.localeCompare(b.label));

    return { data, ready };
};

export const useExportJobList = () => {
    const moduleName = "JOBEXPORTMAIN";
    const [metadata] = useGetMetadata(moduleName);
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const ready = metadata.status === "success" && readyTranslation;

    const reports = Object.keys(
        metadata.response.data?.OtherPageProps?.otherReports || {}
    );
    reports.push("mainExport");

    // const data: Compleo.IObject[] = metadata.response.da;
    const data: Compleo.IObject[] = reports.map((item: string) => {
        return { label: t(`reportName_${item}`), value: item };
    });
    data.sort((a, b) => a.label.localeCompare(b.label));

    return { data, ready };
};

export const getT = (t: any, secondaryModule: string) => {
    const tMerge = (keyName: string, options?: any, otherOptions?: any) => {
        const defaultValue = typeof options === "string" ? options : keyName;
        const toptions = typeof options === "object" ? options : otherOptions;

        const module2Trans = t(keyName, "", toptions);
        if (module2Trans) {
            return t(keyName, defaultValue, toptions);
        }
        return t(`${secondaryModule}:${keyName}`, defaultValue, toptions);
    };
    return tMerge;
};

export const getFieldsToHideBulkActionChangeStage: (
    action: BulkActionChangeStageOptions
) => string[] = (action: BulkActionChangeStageOptions) => {
    switch (action) {
        case "changeStage":
        case "requalify":
        case "changeRejectedStage":
            return ["disqualifyReason"];
        default:
            return [];
    }
};

export const getBulkActionChangeStageStagesList = (
    allStages: IJobViewStages[],
    action: BulkActionChangeStageOptions,
    currentStageId: string
) => {
    let stages: IJobViewStages[] = [];
    switch (action) {
        case "changeStage":
            stages = allStages.filter((i) => !["rejected"].includes(i.type));
            break;
        case "requalify":
            stages = allStages.filter((i) => !["rejected"].includes(i.type));
            break;
        case "disqualify":
        case "changeRejectedStage":
            stages = allStages.filter((i) => i.type === "rejected");
            break;
        default:
            stages = allStages;
    }
    const stageData = stages
        .map((stage) => {
            return {
                label: stage.name,
                value: stage.id
            };
        })
        .filter((i) => i.value !== currentStageId);

    return stageData;
};

export const getBulkChangeStageActionName: (
    bulkAction: BulkActionsType
) => BulkActionChangeStageOptions | null = (bulkAction: BulkActionsType) => {
    switch (bulkAction) {
        case "applicantjob_addToStage":
            return "changeStage";
        case "applicantjob_disqualify":
            return "disqualify";
        case "applicantjob_requalify":
            return "requalify";
        case "applicantjob_changeRejectedStage":
            return "changeRejectedStage";
        default:
            return null;
    }
};
