import React from "react";
import { Box, Divider, Grid, Paper } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { useTranslation } from "react-i18next";
import { ApiStateType, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useApplicantViewContext from "../useApplicantViewContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { formatDate } from "functions/formatValues";
import CompleoUserName from "Pages/_General/CompleoUserName";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperContainer: {
            width: "100%",
            margin: 0,
            padding: 0,
            // height: "100%",
            height: "90%",
            overflowX: "hidden",
            overflowY: "auto"
        },
        root: {
            width: "100%",
            margin: 0,
            padding: theme.spacing(0.5)
        },
        rootChildren: {
            width: "100%",
            margin: 0,
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(6)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        },
        avatar: {}
    })
);

const getComments = (listHistory: ApiStateType) => {
    const parentFields: Compleo.IObject[] =
        listHistory.response?.data?.parentFields || [];
    const childrenFields: Compleo.IObject[] =
        listHistory.response?.data?.childrenFields || [];

    const returnData: Compleo.IObject[] = [];

    parentFields.map((i) => {
        const usersToNotify: string[] = [];
        const childrens: Compleo.IObject[] = [];
        childrenFields
            .filter(
                (children) => `APPLICANTNOTE:${children.parentId}` === i.skGS1pk
            )
            .map((children) => {
                usersToNotify.push(children.createdByUser);
                childrens.push(children);
            });

        usersToNotify.push(i.createdByUser);
        const uniqUsersToNotify = [...new Set(usersToNotify)];
        returnData.push({ ...i, usersToNotify: uniqUsersToNotify });
        childrens.map((c) => returnData.push(c));
    });
    return returnData;
};

const createMarkup = (value?: string) => {
    return { __html: value || "" };
};

interface ICommentItems {
    setNewNoteOpen: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            parentId?: string | undefined;
            usersToNotify?: string[] | undefined;
        }>
    >;
}

const CommentItems = (props: ICommentItems) => {
    const { setNewNoteOpen } = props;
    const classes = useStyles();
    const [data] = useApplicantViewContext();
    const { applicantId } = data;

    const { company } = useAuthState();

    const [tLocal, i18n, readyTranslation] = useTranslation("APPLICANTNOTE", {
        useSuspense: false
    });

    const listAddress = `/applicant/applicantnotelist`;
    const postData = {
        applicantId: applicantId,
        companyId: company.companyId
    };
    const [listHistory, refreshListHistory] = useApiCache(
        listAddress,
        "post",
        postData,
        false
    );
    const comments = getComments(listHistory);

    React.useEffect(() => {
        refreshListHistory(postData);
    }, [data.applicantId]);
    // console.log("comments", comments);

    const ready = readyTranslation && listHistory.status === "success";

    // const commentItemsArr = [1, 2, 3, 4, 5, 6, 7];
    if (ready) {
        if (comments.length) {
            return (
                <Paper className={classes.paperContainer} elevation={0}>
                    {comments.map((c) => (
                        <Grid
                            className={
                                c.parentId ? classes.rootChildren : classes.root
                            }
                            container
                            spacing={1}
                            key={c.skGS1pk}
                        >
                            <Grid item>
                                <CompleoAvatar
                                    idParams={{
                                        email: c.createdByUser
                                    }}
                                    type="user"
                                    className={
                                        c.parentId
                                            ? classes.avatarsmall
                                            : classes.avatar
                                    }
                                />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <h4 style={{ margin: 0, textAlign: "left" }}>
                                    <CompleoUserName
                                        userEmail={c.createdByUser}
                                    />
                                </h4>
                                <Box style={{ textAlign: "left" }} m={0} p={0}>
                                    <span
                                        dangerouslySetInnerHTML={createMarkup(
                                            c.note
                                        )}
                                    />
                                </Box>
                                <Box
                                    style={{ textAlign: "left", color: "gray" }}
                                    m={0}
                                    p={0}
                                >
                                    {formatDate(
                                        c.createdAt,
                                        i18n.languages[0],
                                        "relative"
                                    )}

                                    {!c.parentId && (
                                        <ButtonIconTooltipCompleo
                                            label={tLocal("repply")}
                                            onClick={() => {
                                                setNewNoteOpen({
                                                    open: true,
                                                    parentId: c.skGS1pk.split(
                                                        ":"
                                                    )[1],
                                                    usersToNotify:
                                                        c.usersToNotify
                                                });
                                            }}
                                        >
                                            <ReplyAllIcon />
                                        </ButtonIconTooltipCompleo>
                                    )}
                                </Box>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            );
        } else {
            return (
                <Paper className={classes.paperContainer} elevation={0}>
                    <Box width="100%">
                        <Alert severity="info">
                            {tLocal("noCommentsMessage")}
                        </Alert>
                    </Box>
                </Paper>
            );
        }
    } else {
        return (
            <Paper className={classes.paperContainer} elevation={0}>
                <Loading />
            </Paper>
        );
    }
};

export default CommentItems;
