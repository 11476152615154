import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import JobComboListText from "./JobComboListText";
import TextField from "../TextField";

const JobTitleSwitch = (props: any) => {
    const { company } = useAuthState();

    if (company.jobTitleType === "select") {
        return <JobComboListText {...props} />;
    } else {
        return <TextField type="text" {...props} />;
    }
};

export default JobTitleSwitch;
