import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("editUser"),
                onClick: (event: any, rowData: any) => {
                    navigate(`/user/edit/${rowData.skGS1pk.split(":")[1]}`);
                }
            },
            (rowData: any) => {
                const enabled = (rowData.GS1sk || "").includes("Enabled:true#");

                return {
                    icon: "email",
                    tooltip: t("resendToken"),
                    onClick: async (event: any, rowData: any) => {
                        const email = rowData.email;
                        const postAddress = `/user/newtoken`;

                        callDialog({
                            title: t("resendEmailNewPassword_ModalTitle"),
                            bodyText: t("resendEmailNewPassword_Message"),
                            agreeButtonText: t("COMPLEOGENERAL_YES"),
                            disagreeButtonText: t("COMPLEOGENERAL_NO"),
                            agreeFunction: async () => {
                                //const uuid = rowData.skGS1pk.split(":")[1];
                                const result = await apiDirectCall(
                                    postAddress,
                                    "post",
                                    {
                                        companyId: companyId,
                                        email: email
                                    }
                                );
                                if (result.status === 200) {
                                    if (
                                        result?.data?.data ===
                                        "USERALREADYEXISTS"
                                    ) {
                                        callDialog({
                                            title: t(
                                                "resendEmailNewPassword_ModalTitle"
                                            ),
                                            bodyText: t(
                                                "userAlreadyHasPassword"
                                            ),
                                            agreeButtonText: t(
                                                "COMPLEOGENERAL_CLOSE"
                                            )
                                        });
                                    } else if (
                                        result?.data?.data === "NEWTOKENSENT"
                                    ) {
                                        showMessage(
                                            t("resendTokenSuccess"),
                                            "success"
                                        );
                                    } else {
                                        showMessage(
                                            t("NotIdentifiedProblem"),
                                            "error"
                                        );
                                    }
                                    refreshListFunction();
                                    return true;
                                }
                                return false;
                            },
                            disagreeFunction: () => {
                                return false;
                            }
                        });
                    },
                    hidden: !enabled
                };
            }
        ];

        return result;
    };
};

export default useActionsTable;
