import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

interface ILoading {
    size?: number;
}

const Loading = (props: ILoading) => {
    const { size = 48 } = props;
    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            justify="center"
        >
            <Grid item xs={1}>
                <CircularProgress size={size} aria-label="progress" />
            </Grid>
        </Grid>
    );
};

export default Loading;
