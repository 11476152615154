import React from "react";
import Page, { SecondComponentType } from "Pages/_Layouts/DefaultInternal";
import { useAuthState } from "_ReactContext/AuthContext";
import ApplicationVerificationData from "./ApplicantVerificationData";
import ApplicantNewDataEntry from "./ApplicantNewDataEntry";
import ApplicantUpload from "./ApplicantUpload";
import ApplicantEdit from "./ApplicantEdit";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useApplicantNewContext, {
    ApplicantNewProvider
} from "./useApplicantNewContext/useApplicantNewContext";
import { RouteComponentProps } from "@gatsbyjs/reach-router";

export interface IApplicantNew {}

const ApplicantNewSwitch = (props: RouteComponentProps & IApplicantNew) => {
    const [data] = useApplicantNewContext();

    switch (data.localStateData?.pageToRender) {
        case "verificationData":
            return <ApplicationVerificationData {...props} />;
        case "dataEntry":
            return <ApplicantNewDataEntry {...props} />;
        case "upload":
            return <ApplicantUpload {...props} />;
        case "edit":
            return <ApplicantEdit {...props} />;
        default:
            return <Loading />;
    }
};

export default ApplicantNewSwitch;
