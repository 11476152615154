import {
    parseNumber,
    parsePhoneNumberFromString as parseMin
} from "libphonenumber-js";
import { parsePhoneNumberFromString as parseMax } from "libphonenumber-js/max";
import { parsePhoneNumberFromString as parseMobile } from "libphonenumber-js/mobile";
import { isPossibleNumber, parse } from "libphonenumber-js";
import {
    isAfter,
    isBefore,
    toDate,
    isValid,
    addDays,
    addYears,
    parseISO
} from "date-fns";
import { isInteger, isNumber } from "lodash";
// import { URL } from "url";

export const isValidCPFServer = (schema: any, cpfParametro: string) => {
    return isValidCPF(cpfParametro);
};

export const isValidCPF = (cpfParametro: string) => {
    // console.log(schema);
    const cpf: string = cpfParametro.replace(/[^0-9]/gi, ""); // Remove tudo que n„o for n˙mero
    let add;
    let i;
    let rev;

    if (cpfParametro.length === 0) {
        return true; // vazio
    }

    if (
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false;
    }

    add = 0;
    for (i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
        rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9), 10)) {
        return false;
    }

    add = 0;

    for (i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i), 10) * (11 - i);
    }

    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
        rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(10), 10)) {
        return false;
    }

    return true;
};

export const isValidCNPJServer = (schema: any, cnpjParametro: string) => {
    return isValidCNPJ(cnpjParametro);
};

export const isValidCNPJ = (cnpjParametro: string) => {
    const cnpj: string = cnpjParametro.replace(/[^0-9]/gi, ""); // Remove tudo que n„o for n˙mero

    let add;
    let i: number;
    let rev;

    if (cnpjParametro.length === 0) {
        return true; // vazio
    }

    if (cnpj.length == 0) return true; //vazio

    // if(isNaN(cnpj)){
    //     return false;
    // }

    var s;
    s = cnpj;

    if (s.length > 14) return false; //CNPJ maior que 14

    var c = s.substr(0, 12);
    var dv = s.substr(12, 2);
    var d1 = 0;

    for (i = 0; i < 12; i++) {
        d1 += parseInt(c.charAt(11 - i)) * (2 + (i % 8));
    }

    // if (rev !== parseInt(cpf.charAt(9), 10)) {
    //     return false;
    // }

    if (d1 == 0) return false;

    d1 = 11 - (d1 % 11);

    if (d1 > 9) d1 = 0;
    if (parseInt(dv.charAt(0)) != d1) {
        return false;
    }

    d1 *= 2;

    for (i = 0; i < 12; i++) {
        d1 += parseInt(c.charAt(11 - i)) * (2 + ((i + 1) % 8));
    }

    d1 = 11 - (d1 % 11);

    if (d1 > 9) d1 = 0;
    if (parseInt(dv.charAt(1)) != d1) {
        return false;
    }

    return true;
};

export const isValidCNPJCPFServer = (schema: any, cnpjCpfParametro: string) => {
    return isValidCNPJCPF(cnpjCpfParametro);
};

export const isValidCNPJCPF = (cnpjCpfParametro: string) => {
    const value = cnpjCpfParametro.replace(/[^0-9]/gi, "");
    if (value.length > 11) {
        return isValidCNPJ(value);
    } else {
        return isValidCPF(value);
    }
};

export const isValidPhoneNumberServer = (
    schema: any,
    phone: string | undefined
) => {
    return isValidPhoneNumber(phone);
};

export const isValidPhoneNumber = (phone: string | undefined) => {
    if (phone === undefined || phone === "") {
        return true;
    }
    const parserNumber = parse(phone);
    if (parserNumber !== undefined) {
        if (isPossibleNumber(parserNumber)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isValidPhoneNumberMobileServer = (
    schema: any,
    phone: string | undefined
) => {
    return isValidPhoneNumberMobile(phone);
};

export const isValidPhoneNumberMobile = (phone: string | undefined) => {
    if (phone === undefined || phone === "") {
        return true;
    }
    const parseNumber = parseMobile(phone);
    if (parseNumber !== undefined) {
        if (parseNumber.isValid()) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isValidPhoneNumberNotMobileServer = (
    schema: any,
    phone: string | undefined
) => {
    return isValidPhoneNumberNotMobile(phone);
};

export const isValidPhoneNumberNotMobile = (phone: string | undefined) => {
    if (phone === undefined || phone === "") {
        return true;
    }
    const parseNumber = parseMobile(phone);
    if (parseNumber !== undefined) {
        if (parseNumber.isValid()) {
            return false;
        } else {
            return isValidPhoneNumber(phone);
        }
    } else {
        return false;
    }
};

export const isValidMinDateServer = (schema: any, originalDate: string) => {
    return isValidMinDate(parseISO(originalDate), toDate(parseISO(schema)));
};

export const isValidMinDate = (originalDate: Date, minDate: Date) => {
    return isAfter(originalDate, minDate);
};

export const isValidMaxDateServer = (schema: any, originalDate: string) => {
    return isValidMaxDate(parseISO(originalDate), toDate(parseISO(schema)));
};

export const isValidMaxDate = (originalDate: Date, maxDate: Date) => {
    return isBefore(originalDate, maxDate);
};

export const isValidOnlyPastDatesServer = (schema: any, date: string) => {
    return isValidOnlyPastDates(parseISO(date));
};

export const isValidOnlyPastDates = (date: Date) => {
    return isBefore(date, toDate(new Date()));
};

export const isValidOnlyFutureDatesServer = (schema: any, date: string) => {
    return isValidOnlyFutureDates(parseISO(date));
};

export const isValidOnlyFutureDates = (date: Date) => {
    return isAfter(date, toDate(new Date()));
};

export const isValidMinDateAddDaysFromCurrentDateServer = (
    schema: any,
    date: string
) => {
    return isValidMinDateAddDaysFromCurrentDate(parseISO(date), schema);
};

export const isValidMinDateAddDaysFromCurrentDate = (
    date: Date,
    days: number
) => {
    return isAfter(date, addDays(toDate(new Date()), days));
};

export const isValidMaxDateAddDaysFromCurrentDateServer = (
    schema: any,
    date: string
) => {
    return isValidMaxDateAddDaysFromCurrentDate(parseISO(date), schema);
};

export const isValidMaxDateAddDaysFromCurrentDate = (
    date: Date,
    days: number
) => {
    return isBefore(date, addDays(toDate(new Date()), days));
};

export const isValidMinAgeServer = (schema: any, date: string) => {
    return isValidMinAge(parseISO(date), schema);
};

export const isValidMinAge = (date: Date, minAge: number) => {
    return isBefore(date, addYears(toDate(new Date()), -minAge));
};

export const isValidMaxAgeServer = (schema: any, date: string) => {
    return isValidMaxAge(parseISO(date), schema);
};

export const isValidMaxAge = (date: Date, maxAge: number) => {
    return isAfter(date, addYears(toDate(new Date()), -maxAge));
};

export const isValidDateServer = (schema: any, date: string) => {
    return isValidDate(parseISO(date));
};

export const isValidDate = (date: Date) => {
    //console.log(isValidDate, toDate(date));
    return isValid(date);
};

export const isValidIntegerServer = (schema: any, value: any) => {
    return isValidInteger(value);
};

export const isValidInteger = (value: any) => {
    if (value === undefined || value === "") {
        return true;
    }

    try {
        return !isNaN(value) && isInteger(parseInt(value));
    } catch {
        return false;
    }
};

export const isMoreThanNumberServer = (refValue: any, value: any) => {
    return isMoreThanNumber(value, refValue);
};

export const isMoreThanNumber = (value: any, refValue: any) => {
    if (
        value === undefined ||
        value === "" ||
        refValue === undefined ||
        refValue === ""
    ) {
        return true;
    }

    try {
        if (!isNaN(value)) {
            return Number(value) > Number(refValue);
        }
        return false;
    } catch {
        return false;
    }
};

export const isMoreThanCurrencyServer = (refValue: any, value: any) => {
    return isMoreThanCurrency(value, refValue);
};

export const isMoreThanCurrency = (value: any, refValue: any) => {
    const mainValue = (value || {}).value;
    const compareValue = (refValue || {}).value;
    if (
        mainValue === undefined ||
        mainValue === "" ||
        compareValue === undefined ||
        compareValue === ""
    ) {
        return true;
    }

    try {
        if (!isNaN(mainValue)) {
            return Number(mainValue) > Number(compareValue);
        }
        return false;
    } catch {
        return false;
    }
};

export const isMoreThanOrEqualCurrencyServer = (refValue: any, value: any) => {
    return isMoreThanOrEqualCurrency(value, refValue);
};

export const isMoreThanOrEqualCurrency = (value: any, refValue: any) => {
    const mainValue = (value || {}).value;
    const compareValue = (refValue || {}).value;
    if (
        mainValue === undefined ||
        mainValue === "" ||
        compareValue === undefined ||
        compareValue === ""
    ) {
        return true;
    }

    try {
        if (!isNaN(mainValue)) {
            return Number(mainValue) >= Number(compareValue);
        }
        return false;
    } catch {
        return false;
    }
};

export const isMoreThanOrEqualNumberServer = (refValue: any, value: any) => {
    return isMoreThanOrEqualNumber(value, refValue);
};

export const isMoreThanOrEqualNumber = (value: any, refValue: any) => {
    if (
        value === undefined ||
        value === "" ||
        refValue === undefined ||
        refValue === ""
    ) {
        return true;
    }

    try {
        if (!isNaN(value)) {
            return Number(value) >= Number(refValue);
        }
        return false;
    } catch {
        return false;
    }
};

export const isMoreThanDateServer = (refValue: any, value: any) => {
    return isMoreThanDate(value, refValue);
};

export const isMoreThanDate = (value: any, refValue: any) => {
    if (
        value === undefined ||
        value === "" ||
        refValue === undefined ||
        refValue === ""
    ) {
        return true;
    }

    try {
        if (Date.parse(value) > Date.parse(refValue)) {
            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
};

export const isMoreThanOrEqualDateServer = (refValue: any, value: any) => {
    return isMoreThanOrEqualDate(value, refValue);
};

export const isMoreThanOrEqualDate = (value: any, refValue: any) => {
    if (
        value === undefined ||
        value === "" ||
        refValue === undefined ||
        refValue === ""
    ) {
        return true;
    }

    try {
        if (Date.parse(value) >= Date.parse(refValue)) {
            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
};

export const isValidNumberServer = (schema: any, value: any) => {
    return isValidNumber(value);
};

export const isValidNumber = (value: any) => {
    if (value === undefined || value === "") {
        return true;
    }

    try {
        return !isNaN(value) && isNumber(parseFloat(value));
    } catch {
        return false;
    }
};

export const isValidHttpOrHttpAddressServer = (schema: any, value: any) => {
    return isValidHttpOrHttpAddress(value);
};

export const isValidHttpOrHttpAddress = (value: any) => {
    // debugger;
    let url;

    try {
        url = new URL(value);
    } catch (e) {
        console.log(JSON.stringify(e, null, 4));
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const htmlWithoutImageIsValidServer = (schema: any, value: any) => {
    return htmlWithoutImageIsValid(value);
};

export const htmlWithoutImageIsValid = (value: any) => {
    if (
        value === undefined ||
        value === "" ||
        value === null ||
        typeof value !== "string"
    ) {
        return true;
    }

    if ((value || "").toLocaleLowerCase().includes("<img ")) {
        return false;
    } else {
        return true;
    }
};
