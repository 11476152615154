import { useState } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useCareersActionsTable = (
    companyId: number,
    t: any,
    openDomainSettingsModal: (uniqueLink: string) => void
) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("COMPLEOGENERAL_Edit"),
                onClick: (event: any, rowData: any) => {
                    navigate(`/careers/edit/${rowData.uniqueLink}`);
                }
            },
            (rowData: any) => {
                const hidden = rowData.url ? false : true;

                return {
                    icon: "visibility",
                    tooltip: t("url"),
                    onClick: async (event: any, rowData: any) => {
                        const newWindow = window.open(rowData?.url, "_blank");
                        if (newWindow) {
                            newWindow.focus();
                        }
                    },
                    hidden: hidden
                };
            },
            (rowData: any) => {
                const hidden = rowData.url ? false : true;

                return {
                    icon: "build",
                    tooltip: t("editUrl"),
                    onClick: async (event: any, rowData: any) => {
                        const newWindow = window.open(
                            rowData?.editUrl,
                            "_blank"
                        );
                        if (newWindow) {
                            newWindow.focus();
                        }
                    },
                    hidden: hidden
                };
            },
            (rowData: any) => {
                const hidden = rowData.url ? false : true;

                return {
                    icon: "domain",
                    tooltip: t("configDomain"),
                    onClick: async (event: any, rowData: any) => {
                        openDomainSettingsModal(rowData.uniqueLink);
                    },
                    hidden: hidden
                };
            }
        ];

        return result;
    };
};

export default useCareersActionsTable;
