import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import SectionsActionsModal from "./SectionsActionsModal";
import useGetMetadata from "customHooks/useGetMetadata";
import * as util from "functions/util";
import SectionsItems from "./SectionsItems";
import AddSectionButtom from "./AddSectionButtom";
import { ModalDataType } from "./helperScorecard";
// import { getErrorAndMessagesUseField } from "customHooks/useCompleoForm/Inputs/util/general";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

const Sections = (props: IInputProps) => {
    const classes = useStyles();
    // const [field, meta, helpers] = useField(props.metadata.fieldName);
    // const { error, message } = getErrorAndMessagesUseField(
    //     meta.error,
    //     meta.touched,
    //     props.helperTextDefault
    // );
    // const [
    //     pipelinePatterns,
    //     setPipelinePatterns
    // ] = React.useState<PipelinePatternsType | null>(null);
    const {
        error,
        message,
        field,
        setFieldValue,
        watch
    } = useRhfFieldControlled(
        props.metadata.fieldName,
        props.helperTextDefault
    );
    const [modalData, setModalData] = useState<ModalDataType>({
        open: false,
        section: {},
        index: 0,
        name: field.name,
        new: false
    });

    const modalModuleName = "SCORECARDSECTIONMAIN";
    const [t, i18n, readyTranslation] = useTranslation(modalModuleName, {
        useSuspense: false
    });
    const [modalMetadata] = useGetMetadata(modalModuleName);
    const sectionData = watch(field.name);

    const handleModalClose = () => {
        setModalData({ ...modalData, open: false });
    };

    const handleEditStage = (section: any, index: number) => {
        setModalData({
            ...modalData,
            section: section,
            open: true,
            index: index,
            new: false
        });
    };

    const handleAddStage = (index: number, type: string) => {
        const newSection = {
            name: "",
            order: index + 1
        };
        setModalData({
            ...modalData,
            section: newSection,
            open: true,
            index: index,
            new: true
        });
    };

    const handleDeleteStage = (section: any) => {
        const newValue = [...sectionData].filter(
            (item: any) => item.id !== section.id
        );
        setFieldValue(newValue);
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;

        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            const sectionsDestination = [...sectionData];
            const currentItem = sectionData.filter(
                (item: any) => item.name === draggableId
            )[0];
            const mainListCurrentItem = sectionData.findIndex(
                (item: any) => item.name === draggableId
            );
            const indexDifference = mainListCurrentItem - source.index;
            sectionsDestination.splice(mainListCurrentItem, 1);
            sectionsDestination.splice(
                destination.index + indexDifference,
                0,
                currentItem
            );
            const reorderedsections = sectionsDestination.map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        order: index
                    };
                }
            );
            setFieldValue(reorderedsections);
        }
    };

    // const hasError = false;
    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }
    // const mensagemErro = "temp";

    let listsections: any = sectionData || [];

    if (readyTranslation && listsections) {
        return (
            <React.Fragment>
                <SectionsActionsModal
                    modalData={modalData}
                    handleModalClose={handleModalClose}
                    t={t}
                    fieldValue={sectionData}
                    setFieldValue={setFieldValue}
                    metadata={modalMetadata}
                    i18n={i18n}
                    readyTranslation={readyTranslation}
                />
                <List component="nav" className={classes.root}>
                    {error && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {message}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={"sectionsItems"}
                            direction="vertical"
                        >
                            {(provided: DroppableProvided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <SectionsItems
                                        classError={classError}
                                        listSections={listsections}
                                        t={t}
                                        handleEditStage={handleEditStage}
                                        handleDeleteStage={handleDeleteStage}
                                    />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <AddSectionButtom
                        handleAddStage={handleAddStage}
                        listSections={listsections}
                        t={t}
                    />
                    <Divider />
                </List>
            </React.Fragment>
        );
    } else {
        return <Loading />;
    }
};

export default Sections;
