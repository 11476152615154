import React from "react";
import { Alert } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";

interface IIncorrectDataMessage {
    t: any;
    alertMessageDef: {
        messageId: string;
        translateReplaceObj: Compleo.IObject;
    };
    logoutAndReload?: () => Promise<void>;
}

const EvaluationIncorrectDataMessage = (props: IIncorrectDataMessage) => {
    const { t, alertMessageDef, logoutAndReload } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Alert severity="error">
                    {t(alertMessageDef.messageId, {
                        ...alertMessageDef.translateReplaceObj
                    })}
                </Alert>
            </Grid>
            {logoutAndReload && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={logoutAndReload}
                    >
                        {t("logoff")}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default EvaluationIncorrectDataMessage;
