import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface ITermsOfUseAndPrivacyFooter {
    t: any;
}
const TermsOfUseAndPrivacyFooter = (props: ITermsOfUseAndPrivacyFooter) => {
    const { t } = props;

    return (
        <Alert style={{ width: "100%", marginTop: 16 }}>
            <AlertTitle>{t("COMPLEOGENERAL_termsPolicyTitle")}</AlertTitle>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Link
                            rel="noopener"
                            href="https://ats.compleo.com.br/TermosServico"
                            target="_blank"
                        >
                            {t("COMPLEOGENERAL_termsOfUse")}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Link
                            rel="noopener"
                            href="https://ats.compleo.com.br/PoliticaPrivacidade"
                            target="_blank"
                        >
                            {t("COMPLEOGENERAL_privacyPolicy")}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Alert>
    );
};

export default TermsOfUseAndPrivacyFooter;
