import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { apiDirectCall } from "customHooks/useApi";
import { Alert, AlertTitle } from "@material-ui/lab";
import useJobContext from "../../useJobContext";
import JobBoardsShareStatusMessages from "../JobBoardsShareStatusMessages";
import JobBoardsStatusEnabled from "./JobBoardsStatusEnabled";
import JobBoardsModal from "Pages/Job/components/JobBoards/JobBoardsModal";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import JobBoardsCareersStatus from "./JobBoardsCareersStatus";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useJobBoardsCareersStatus from "./useJobBoardsCareersStatus";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        mainAlert: {
            marginBottom: theme.spacing(1)
        }
    })
);

interface IJobBoardsStatusModalProps {}

const JobBoardsStatusModal = (props: IJobBoardsStatusModalProps) => {
    // const { open, handleModalClose } = props;
    const queryClient = useQueryClient();
    const [data, dispatchJobData] = useJobContext();
    const open = data?.LocalStateData?.modalJobBoardsStatusOpen === true;
    const [modalEditOpen, setModalEditOpen] = React.useState(false);
    const showMessage = useShowMessage();
    const classes = useStyles();

    const handleModalClose = () => {
        dispatchJobData.dispatchGeneralPageInfo({
            payload: {
                modalJobBoardsStatusOpen: false
            },
            type: "update"
        });
    };

    const [t, i18n, readyTranslation] = useTranslation("JOBBOARDSMODULE", {
        useSuspense: false
    });

    const ready = readyTranslation;

    const messageListObj = getMessageList(
        data?.Job?.data || {},
        t,
        i18n.languages[0],
        ready
    );

    const updateData = async (values: any) => {
        await apiDirectCall("/job/editview", "post", {
            jobBoards: values,
            companyId: data.Job?.data?.companyId,
            id: data.JobId
        });
        queryClient.invalidateQueries(data.apiKeyJob);
        showMessage({
            message: t("COMPLEOGENERAL_MessageUpdated"),
            timeout: 2000,
            typeMessage: "success",
            snackBarOrign: {
                vertical: "top",
                horizontal: "center"
            }
        });
    };
    const filteredMessageList = messageListObj.messageList.filter(
        (i) => i.type === "error"
    );
    const {
        hasError: hasErrorJobsCareerStatus,
        careerItem
    } = useJobBoardsCareersStatus();
    const hasAnyError = hasErrorJobsCareerStatus || messageListObj?.hasError;

    return (
        <Dialog
            open={open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("jobBoardModalStatusTitle")}
            aria-describedby={t("jobBoardModalStatusTitle")}
            scroll="body"
            PaperProps={{
                className: classes.dialog
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {t("jobBoardModalStatusTitle")}
            </DialogTitle>
            {ready ? (
                <DialogContent>
                    <Alert severity="warning" className={classes.mainAlert}>
                        {t("messageTimeJobBoardChange")}
                    </Alert>
                    <Paper elevation={1} className={classes.paper}>
                        <JobBoardsCareersStatus />
                        {!hasAnyError && (
                            <Alert>
                                <a
                                    target="_blank"
                                    href={careerItem?.rootURL}
                                    rel="noreferrer"
                                >
                                    {t("messageViewJobCareers")}
                                </a>
                            </Alert>
                        )}
                    </Paper>
                    {messageListObj?.hasError && (
                        <Paper elevation={1} className={classes.paper}>
                            <Alert
                                severity={
                                    messageListObj?.hasError
                                        ? "error"
                                        : "success"
                                }
                            >
                                {t("messageJobBoardsIssues")}
                            </Alert>
                            {messageListObj?.hasError && (
                                <JobBoardsShareStatusMessages
                                    messageList={filteredMessageList}
                                />
                            )}
                        </Paper>
                    )}
                    {!hasAnyError && (
                        <Paper elevation={1} className={classes.paper}>
                            <Alert severity="info">
                                {t("messageJobBoardsLists")}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    size="small"
                                    onClick={() => setModalEditOpen(true)}
                                >
                                    {t("messageJobBoardsEnableDisable")}
                                </Button>
                            </Alert>
                            <JobBoardsStatusEnabled jobData={data.Job?.data} />
                        </Paper>
                    )}
                    <JobBoardsModal
                        handleModalClose={() => setModalEditOpen(false)}
                        open={modalEditOpen}
                        location={data.Job?.data?.location}
                        updateData={updateData}
                        initialValues={data.Job?.data?.jobBoards || {}}
                    />
                </DialogContent>
            ) : (
                <DialogContent>
                    <Loading />
                </DialogContent>
            )}
            {ready && (
                <DialogActions>
                    <Button onClick={handleModalClose} color="secondary">
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export interface IJobBoardsShareStatusMessages {
    message: string;
    type: "success" | "error";
}

const getMessageList = (
    jobData: Compleo.IObject,
    t: any,
    language: string,
    ready: boolean
) => {
    const messageList: IJobBoardsShareStatusMessages[] = [];
    if (!ready) {
        return { messageList: messageList, hasError: false };
    }

    const location = jobData?.location;
    const locationDefined =
        location?.city?.label &&
        location?.provinceOrState?.label &&
        (location?.country?.iso2 ||
            location?.country?.label.includes("Brasil") ||
            location?.country?.label.includes("Brazil"));

    if (locationDefined) {
        messageList.push({
            message: t("jobBoardModalStatusLocationMessageOK"),
            type: "success"
        });
    } else {
        messageList.push({
            message: t("jobBoardModalStatusLocationMessageError"),
            type: "error"
        });
    }
    if (jobData?.status === "open") {
        messageList.push({
            message: t("jobBoardModalStatusOpenMessageOK", {
                jobStatus: t("JOBSTATUS_open")
            }),
            type: "success"
        });
    } else {
        messageList.push({
            message: t("jobBoardModalStatusOpenMessageError", {
                jobStatus: t(`JOBSTATUS_${jobData?.status}`),
                openStatus: t("JOBSTATUS_open")
            }),
            type: "error"
        });
    }
    if (jobData?.visibility?.value === "CP1") {
        messageList.push({
            message: t("jobBoardModalStatusVisibleMessageOK", {
                visibility:
                    jobData?.visibility?.label ||
                    jobData?.visibility?.[`label-${language}`]
            }),
            type: "success"
        });
    } else {
        messageList.push({
            message: t("jobBoardModalStatusVisibleMessageError", {
                visibility:
                    jobData?.visibility?.label ||
                    jobData?.visibility?.[`label-${language}`]
            }),
            type: "error"
        });
    }

    const hasError = messageList.filter((i) => i.type === "error").length > 0;
    return { messageList, hasError };
};

export default JobBoardsStatusModal;
