import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { DialogContentText, Grid, Paper } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTranslation } from "react-i18next";

interface IPropsItemFilter {
    open: boolean;
    onClose: () => void;
    jobId: string;
}

const JobPrintModal = (props: IPropsItemFilter) => {
    const { onClose, open, jobId } = props;
    const printRef = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [t, i18n, readyTranslation] = useTranslation("JOBPRINT", {
        useSuspense: false
    });
    const ready = readyTranslation && !loading;
    React.useEffect(() => {
        // set loaded after 10 seconds
        if (open) {
            setLoading(true);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 7000);
        return () => clearTimeout(timer);
    }, [open]);
    const print = () => {
        //@ts-ignore
        const element = printRef.current?.contentWindow;
        element.focus();
        element.print();
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            open={open}
        >
            <DialogTitle id="form-dialog-title">
                {readyTranslation ? t("modal_Title") : ""}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {readyTranslation ? t("modal_confirmationText") : ""}
                </DialogContentText>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Paper>
                            {open && (
                                <iframe
                                    title="filePreview"
                                    src={`/job/print/${jobId}`}
                                    ref={printRef}
                                    style={{ display: "none" }}
                                >
                                    Enable iframe{" "}
                                </iframe>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            {ready ? (
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        disabled={!ready}
                    >
                        {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                    </Button>
                    <Button
                        onClick={print}
                        color="primary"
                        variant="contained"
                        disabled={!ready}
                    >
                        {t("modal_buttonPrint")}
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Loading />
                </DialogActions>
            )}
        </Dialog>
    );
};

export default JobPrintModal;
