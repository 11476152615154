import { useApiCache } from "customHooks/useApi";
import useGetMetadata from "customHooks/useGetMetadata";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { MetadataReturn } from "./ListHelpers/CustomSearchItems/useCustomSearchMetadata";
import {
    handleElasticData,
    ElasticDefDataType,
    getFiltersUserInfo
} from "./ListHelpers/ListUtil";
import MainPage from "./MainPage";
import MainPageJobView from "./MainPageJobView";
import MainPageDialogKanbanFilter from "./MainPageDialogKanbanFilter";
import useGlobalValues from "_ReactContext/GlobalValues";
import ListsRender from "./components/ListsRender";
import _ from "lodash";
import MainPageChildrenEmpty from "./MainPageChildrenEmpty";

export type resultTypeOptionsUseList = "table" | "grid" | "smallGrid";

export interface IFilters {
    filterUpdated: boolean;
    buckets: Compleo.IObject;
    mainSearch: string | null;
    searchAsYouType: boolean;
    customSearch: Compleo.IObject;
    advancedSearch: Compleo.IObject;
    pagination: { pageSize: number; currentPage: number; fromValue?: number };
    geoLocation: {
        latitude?: number;
        longitude?: number;
    };
    sort: Compleo.IObject;
    updateAggsAfterFilter: boolean;
    otherGenericParams?: Compleo.IObject;
    controlParams?: Compleo.IObject;
    language: string;
}
interface IListReturnInfo {
    aggregations: Compleo.IObject | null;
    // allAggs: Compleo.IObject | null;
    fields: Compleo.IObject[];
    totalFiltered: Compleo.IObject | null;
    filtersUserInfo: Compleo.IObject[];
    total: number | null;
    queryFinished: boolean;
    otherData?: Compleo.IObject;
}

export interface IUseListBucketReportOpen {
    bucketDef: Compleo.IObject;
}
interface ILocalInfo {
    filterOpenInfo: {
        filterIsOpen: boolean;
        openFilterBucketState: Compleo.IObject;
    };
    resultType: resultTypeOptionsUseList;
    modalLocationInfo: {
        open: boolean;
        newSortAfterLocation?: Compleo.IObject;
        values?: Compleo.IObject;
    };
    localMetadataReturn: MetadataReturn[];
    loadedMainSearchFromFilterSaved?: string;
    modalApplicant?: {
        open: boolean;
        currentPage: number;
        currentIndex: number;
        newPage?: number;
        applicantIdFieldName?: string;
    };
    modalActivity?: {
        open: boolean;
        currentPage: number;
        currentIndex: number;
        newPage?: number;
        idFieldName?: string;
    };
    bucketReportOpen?: IUseListBucketReportOpen;
}

interface IDefinitionInfo {
    elasticDefData: ElasticDefDataType;
    bucketsDef: Compleo.IObject[];
}

interface ITempInfo {
    t: TFunction<string[]>;
    readyTranslation: boolean;
    language: string;
    totalPages: number;
    useApiKey: any;
    listApiAddress: string;
    moduleName: string;
    // mainModuleName: string;
    firstLoadFinished: boolean;
    otherParamsQuery?: Compleo.IObject;
}

interface IListContext {
    filtersInfo: {
        filters: IFilters;
    };
    listReturnInfo: IListReturnInfo;
    localInfo: ILocalInfo;
    definitionInfo: IDefinitionInfo;
    tempInfo: ITempInfo;
    error: boolean;
}

interface IListContextDispatch {
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
    setLocalInfo: React.Dispatch<React.SetStateAction<ILocalInfo>>;
    setBucketsDef: (value: React.SetStateAction<Compleo.IObject[]>) => void;
}

const ListContext = React.createContext<IListContext | undefined>(undefined);
const ListContextDispatch = React.createContext<
    IListContextDispatch | undefined
>(undefined);

const useListGlobalValues = (moduleName: string) => {
    const LocalSaveData = "tempsession";

    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const globalValueFilterDataName = `useListFilter_${moduleName}`;
    const globalValueLocalInfoName = `useListLocalInfo_${moduleName}`;

    const globalValueFilterData = _.cloneDeep(
        globalValues[LocalSaveData][globalValueFilterDataName]
    );
    const globalValueLocalInfo = _.cloneDeep(
        globalValues[LocalSaveData][globalValueLocalInfoName]
    );

    const defaultFilter = _.cloneDeep(
        globalValues.userdb[`defaultFilter_${moduleName}`]
    );

    const changeGlobalValueFilterData = (value: any) => {
        globalValuesDispatch.addOrEditGlobalValue({
            type: LocalSaveData,
            name: globalValueFilterDataName,
            value: value
        });
    };

    const changeGlobalValueLocalInfo = (value: any) => {
        globalValuesDispatch.addOrEditGlobalValue({
            type: LocalSaveData,
            name: globalValueLocalInfoName,
            value: value
        });
    };
    return {
        globalValueFilterData,
        globalValueLocalInfo,
        changeGlobalValueFilterData,
        changeGlobalValueLocalInfo,
        bucketSortDef: globalValues.userdb[`bucketsOrder_${moduleName}`],
        defaultFilter
    };
};
interface IProps {
    moduleName: string;
    listApiAddress: string;
    children: any;
    otherParamsQuery?: Compleo.IObject;
}

export const CompleoListProvider = (props: IProps) => {
    const { moduleName, listApiAddress, otherParamsQuery = {} } = props;
    const globalValuesData = useListGlobalValues(moduleName);
    const { defaultFilter } = globalValuesData;

    const [t, i18n, readyTranslation] = useTranslation(
        [moduleName, "ELASTICLISTSETTINGS"],
        {
            useSuspense: false
        }
    );
    const [metadata] = useGetMetadata(moduleName);

    const localInfoDefaultDB =
        globalValuesData.globalValueLocalInfo || defaultFilter?.localInfo;

    const localInfoDefaultValue: ILocalInfo = localInfoDefaultDB || {
        filterOpenInfo: { filterIsOpen: false, openFilterBucketState: {} },
        modalLocationInfo: { open: false, values: {} },
        resultType: "grid",
        localMetadataReturn: [],
        openFirstItemApplicantModalOnPage: -1
    };

    const [localInfo, setLocalInfo] = React.useState<ILocalInfo>(
        localInfoDefaultValue
    );
    React.useEffect(() => {
        globalValuesData.changeGlobalValueLocalInfo(localInfo);
    }, [localInfo]);

    const [firstLoadFinished, setFirstLoadFinished] = React.useState(false);

    const localFilterDefaultDB =
        globalValuesData.globalValueFilterData || defaultFilter?.filters;

    const filtersDefaultValue: IFilters = localFilterDefaultDB || {
        filterUpdated: false,
        buckets: {},
        mainSearch: "",
        searchAsYouType: false,
        customSearch: {},
        advancedSearch: {},
        pagination: {
            // pageSize: 12,
            currentPage: 1
        },
        geoLocation: {},
        sort: {},
        updateAggsAfterFilter: false,
        otherGenericParams: otherParamsQuery,
        language: i18n.languages[0]
    };
    if (otherParamsQuery) {
        filtersDefaultValue.otherGenericParams = otherParamsQuery;
    }

    const [filters, setFilters] = React.useState<IFilters>(filtersDefaultValue);
    React.useEffect(() => {
        globalValuesData.changeGlobalValueFilterData(filters);
    }, [filters]);

    const [filtersUserInfo, setFiltersUserInfo] = React.useState<
        Compleo.IObject[]
    >([]);
    const [elasticDefData, setElasticDefData] = React.useState<
        ElasticDefDataType
    >(null);
    const [bucketsDef, setBucketsDef] = React.useState<Compleo.IObject[]>([]);

    const [totalRecords, setTotalRecords] = React.useState<number | null>(null);

    const { company } = useAuthState();
    const [getSearch, executeSearch] = useApiCache(
        listApiAddress,
        "post",
        {
            companyId: company.companyId,
            ...filters
        },
        false,
        1000 * 60 * 5,
        false
    );

    React.useEffect(() => {
        const isReport = elasticDefData?.data?.otherSettings?.isReport === true;
        executeSearch({
            companyId: company.companyId,
            ...filters,
            language: i18n.languages[0],
            updateAggsAfterFilter: isReport
                ? true
                : filters.updateAggsAfterFilter
        });
    }, [filters, i18n.languages[0]]);

    React.useEffect(() => {
        setFiltersUserInfo(
            getFiltersUserInfo(filters, elasticDefData, t, i18n.languages[0])
        );
    }, [filters, elasticDefData, i18n.languages[0]]);

    // const allAggs: Compleo.IObject = getBuckets?.response?.data?.allAggs;

    const aggregations: Compleo.IObject =
        getSearch?.response?.data?.aggregations;
    const fields: Compleo.IObject[] = getSearch?.response?.data?.fields || [];
    const otherData: Compleo.IObject = getSearch?.response?.data?.otherData;
    const totalFiltered: Compleo.IObject =
        getSearch?.response?.data?.totalFiltered || {};
    const queryFinished = getSearch.status === "success";
    const queryError = getSearch.status === "error";
    const currentPage = filters.pagination.currentPage;
    const pageSize = filters.pagination.pageSize;
    const totalReturnValue = totalFiltered?.value || -1;
    const totalPages = Math.ceil(totalReturnValue / pageSize);
    const sortDBData = getSearch?.response?.data?.sort;
    // const totalRecordsReturnDB = getSearch?.response?.data?.totalRecords;

    React.useEffect(() => {
        if (queryFinished && readyTranslation && !firstLoadFinished) {
            setFirstLoadFinished(true);
        }
    }, [queryFinished, readyTranslation, firstLoadFinished]);

    React.useEffect(() => {
        if (
            aggregations &&
            metadata.status === "success" &&
            (elasticDefData === null ||
                !elasticDefData.data.sortDBData ||
                elasticDefData?.language !== i18n.languages[0]) &&
            readyTranslation
        ) {
            const bucksData = handleElasticData(
                aggregations,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );
            setElasticDefData({
                data: {
                    ...(metadata.response.data?.ElasticSearchListDefinition ||
                        {}),
                    bucketsDef: bucksData,
                    sortDBData: sortDBData
                },
                language: i18n.languages[0]
            });
        }
    }, [
        aggregations,
        elasticDefData,
        metadata.status,
        i18n.languages[0],
        readyTranslation,
        sortDBData
    ]);

    React.useEffect(() => {
        if (elasticDefData?.data?.otherSettings?.resultsPageOptions?.length) {
            if (elasticDefData?.data?.otherSettings?.defaultValue) {
                setFilters({
                    ...filters,
                    pagination: {
                        currentPage:
                            globalValuesData.globalValueFilterData?.pagination
                                ?.currentPage || 1,
                        pageSize:
                            globalValuesData.globalValueFilterData?.pagination
                                ?.pageSize ||
                            elasticDefData?.data?.otherSettings?.defaultValue
                    },
                    filterUpdated: true
                });
            } else {
                setFilters({
                    ...filters,
                    pagination: {
                        currentPage:
                            globalValuesData.globalValueFilterData?.pagination
                                ?.currentPage || 1,
                        pageSize:
                            globalValuesData.globalValueFilterData?.pagination
                                ?.pageSize ||
                            elasticDefData?.data?.otherSettings
                                ?.resultsPageOptions[0]
                    },
                    filterUpdated: true
                });
            }
        }
    }, [elasticDefData?.data?.otherSettings?.resultsPageOptions]);

    const [getTotal] = useApiCache(
        listApiAddress,
        "post",
        {
            companyId: company.companyId,
            returnTotal: true,
            otherGenericParams: filters.otherGenericParams
        },
        false,
        1000 * 60 * 5
    );
    React.useEffect(() => {
        if (getTotal.response?.data?.totalRecords && totalRecords === null) {
            setTotalRecords(getTotal.response?.data?.totalRecords);
        }
    }, [getTotal.response?.data?.totalRecords]);
    const queryTotalError = getTotal.status === "error";

    React.useEffect(() => {
        if (aggregations && metadata.status === "success" && readyTranslation) {
            const bucksData = handleElasticData(
                aggregations,
                metadata.response.data?.ElasticSearchListDefinition,
                t,
                i18n.languages[0],
                globalValuesData.bucketSortDef
            );
            setBucketsDef(bucksData);
        }
    }, [aggregations, readyTranslation, i18n.languages[0]]);

    /**
     * Bloco abaixo retorna para a última página caso pesquisa tenha retornado
     * um número de páginas menor do que a página atual
     */
    React.useEffect(() => {
        if (totalReturnValue !== -1 && currentPage > totalPages) {
            setFilters({
                ...filters,
                pagination: {
                    currentPage: totalPages,
                    pageSize: pageSize
                },
                filterUpdated: true
            });
        }
    }, [currentPage, totalPages, totalReturnValue]);

    const returnDataContext: IListContext = {
        filtersInfo: {
            filters: filters
        },
        listReturnInfo: {
            aggregations: aggregations,
            // allAggs: allAggs,
            totalFiltered: totalFiltered,
            filtersUserInfo: filtersUserInfo,
            total: totalRecords,
            queryFinished: queryFinished,
            fields: fields,
            otherData: otherData
        },
        localInfo: localInfo,
        definitionInfo: {
            elasticDefData: elasticDefData,
            bucketsDef: bucketsDef
        },
        tempInfo: {
            t: t,
            readyTranslation: readyTranslation,
            language: i18n.languages[0],
            totalPages: totalPages,
            useApiKey: getSearch.useQueryKey,
            listApiAddress: listApiAddress,
            moduleName: moduleName,
            // mainModuleName: mainModuleName,
            firstLoadFinished: firstLoadFinished,
            otherParamsQuery: otherParamsQuery
        },
        error: queryError || queryTotalError
    };

    const returnDataContextDispatch: IListContextDispatch = {
        setFilters: setFilters,
        setLocalInfo: setLocalInfo,
        setBucketsDef: setBucketsDef
    };

    return (
        <ListContext.Provider value={returnDataContext}>
            <ListContextDispatch.Provider value={returnDataContextDispatch}>
                {props.children}
            </ListContextDispatch.Provider>
        </ListContext.Provider>
    );
};

const useList: () => [IListContext, IListContextDispatch] = () => {
    return [useListValues(), useListDispatch()];
};

const useListValues = () => {
    const context = React.useContext(ListContext);
    if (!context) {
        throw new Error(`useList must be used within a ListProvider`);
    }
    const returnData = context;
    return returnData;
};

const useListDispatch = () => {
    const context = React.useContext(ListContextDispatch);
    if (!context) {
        throw new Error(`useListDisptch must be used within a ListProvider`);
    }
    const returnData = context;
    return returnData;
};

type layoutTypeList =
    | "default"
    | "jobview"
    | "jobkanbanfilter"
    | "blank"
    | "childrenempty";

interface IUseListPage {
    moduleName: string;
    listApiAddress: string;
    layoutType?: layoutTypeList;
    otherParamsQuery?: Compleo.IObject;
    modalOptions?: { open: boolean; handleClose: any };
    children?: any;
}

export const CompleoListPage = (props: IUseListPage) => {
    const {
        moduleName,
        listApiAddress,
        otherParamsQuery = {},
        layoutType = "default",
        modalOptions,
        children = null
    } = props;

    const [, globalValuesDispatch] = useGlobalValues();
    const globalValuesReady = globalValuesDispatch.isReady();

    if (globalValuesReady) {
        return (
            <CompleoListProvider
                moduleName={moduleName}
                listApiAddress={listApiAddress}
                otherParamsQuery={otherParamsQuery}
            >
                <LayoutPage layoutType={layoutType} modalOptions={modalOptions}>
                    {layoutType !== "childrenempty" ? <ListsRender /> : null}
                    {children}
                </LayoutPage>
            </CompleoListProvider>
        );
    } else {
        return null;
    }
};

interface IUseListPageBlank {
    moduleName: string;
    listApiAddress: string;
    children: any;
    otherParamsQuery?: Compleo.IObject;
}
export const CompleoListPageBlank = (props: IUseListPageBlank) => {
    const {
        moduleName,
        listApiAddress,
        children,
        otherParamsQuery = {}
    } = props;

    const [, globalValuesDispatch] = useGlobalValues();
    const globalValuesReady = globalValuesDispatch.isReady();

    if (globalValuesReady) {
        return (
            <CompleoListProvider
                moduleName={moduleName}
                listApiAddress={listApiAddress}
                otherParamsQuery={otherParamsQuery}
            >
                {children}
            </CompleoListProvider>
        );
    } else {
        return null;
    }
};

interface IPropsLayoutPage {
    layoutType: layoutTypeList;
    children: any;
    modalOptions?: { open: boolean; handleClose: any };
}

const LayoutPage = (props: IPropsLayoutPage) => {
    const { layoutType, children, modalOptions } = props;
    switch (layoutType) {
        case "jobview":
            return <MainPageJobView>{children}</MainPageJobView>;
        case "jobkanbanfilter":
            if (modalOptions) {
                return (
                    <MainPageDialogKanbanFilter
                        open={modalOptions.open}
                        handleClose={modalOptions.handleClose}
                    >
                        {children}
                    </MainPageDialogKanbanFilter>
                );
            } else {
                return null;
            }
        case "blank":
            return <>{children}</>;
        case "childrenempty":
            return <MainPageChildrenEmpty>{children}</MainPageChildrenEmpty>;
        default:
            return <MainPage>{children}</MainPage>;
    }
};

export default useList;
