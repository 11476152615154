import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import useList from "customHooks/useList";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BucketReportTable, { IBucketReportItem } from "./BucketReportTable";
import BucketReportPie from "./BucketGraphs/BucketReportPie";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import CloseIcon from "@material-ui/icons/Close";
import BucketReportBar from "./BucketGraphs/BucketReportBar";
// import Page from "Pages/_Layouts/DefaultInternal";
// import { Link } from "@gatsbyjs/reach-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            marginBottom: theme.spacing(0.2)
        },
        root: {
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            listStyle: "none",
            padding: theme.spacing(2),
            margin: 0
        }
    })
);

const BucketReport = () => {
    const [listData, listDispatch] = useList();
    const { t } = listData.tempInfo;
    const { totalFiltered, total, filtersUserInfo } = listData.listReturnInfo;
    const updateAggsConfig =
        listData.filtersInfo.filters.updateAggsAfterFilter === true;

    const totalFilteredValue: number = totalFiltered?.value || total || 0;

    const { setLocalInfo } = listDispatch;
    const classes = useStyles();
    // const moduleName = "REPORTSMAIN";
    // const [t, i18n, readyTranslation] = useTranslation(moduleName, {
    //     useSuspense: false
    // });

    const closeView = () => {
        setLocalInfo({
            ...listData.localInfo,
            bucketReportOpen: undefined
        });
    };
    const a = {
        bucketDef: {
            items: [
                {
                    label: "mais de $10.000,00",
                    value: "from10000",
                    total: 16614,
                    postData: {
                        key: "from10000",
                        from: 10000,
                        doc_count: 16614
                    }
                },
                {
                    label: "$5.000,00 a $10.000,00",
                    value: "5000to10000",
                    total: 8348,
                    postData: {
                        key: "5000to10000",
                        from: 5000,
                        to: 10000,
                        doc_count: 8348
                    }
                },
                {
                    label: "$2.000,00 a $5.000,00",
                    value: "2000to5000",
                    total: 4996,
                    postData: {
                        key: "2000to5000",
                        from: 2000,
                        to: 5000,
                        doc_count: 4996
                    }
                },
                {
                    label: "$1,00 a $2.000,00",
                    value: "1to2000",
                    total: 1641,
                    postData: {
                        key: "1to2000",
                        from: 1,
                        to: 2000,
                        doc_count: 1641
                    }
                }
            ],
            name: "Pretensão salarial",
            key: "monthlySalaryClaim",
            maxItems: 5,
            bucketReport: {
                tablePath: "",
                graph: "bar"
            },
            order: 13
        }
    };
    const bucketDefParam =
        listData.localInfo?.bucketReportOpen?.bucketDef || {};
    const bucketDef =
        (listData.definitionInfo?.bucketsDef || []).filter(
            (i) => i.key === bucketDefParam.key
        )[0] || {};

    return (
        <Box className={classes.box}>
            <Paper className={classes.root} elevation={0}>
                <Box display="flex" width="100%" mb={2}>
                    <Box>
                        <Typography variant="h6">{bucketDef?.name}</Typography>
                        <Typography variant="subtitle1">
                            {filtersUserInfo.length
                                ? t("ELASTICLISTSETTINGS:results", {
                                      filtered: totalFiltered?.value,
                                      total: total
                                  })
                                : t("ELASTICLISTSETTINGS:totalRecords", {
                                      total: total
                                  })}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}></Box>
                    <Box>
                        <ButtonIconTooltipCompleo
                            onClick={closeView}
                            label={t("COMPLEOGENERAL_CLOSE")}
                        >
                            <CloseIcon />
                        </ButtonIconTooltipCompleo>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BucketReportSwitch
                            aggregation={bucketDef}
                            t={t}
                            title={bucketDef?.name}
                            totalFilteredValue={totalFilteredValue}
                            totalValue={total || 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BucketReportTable
                            aggregation={bucketDef}
                            t={t}
                            title={bucketDef?.name}
                            totalFilteredValue={totalFilteredValue}
                            totalValue={total || 0}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

const BucketReportSwitch = (props: IBucketReportItem) => {
    const { aggregation, t, title } = props;
    switch (aggregation?.bucketReport?.graph) {
        case "pie":
            return <BucketReportPie {...props} />;
        case "bar":
            return <BucketReportBar {...props} />;
        default:
            return null;
    }
};

export default BucketReport;
