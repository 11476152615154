import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
// import languagesAvailable from "functions/getAllLanguagesSuported";
import { Icon, Menu } from "@material-ui/core";
import TooltipCompleo from "Components/Tooltip";
import { EmailDataDefaultValues } from "customHooks/useValuesFromSource";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";

interface IButtonAddNewLanguage {
    fieldValue: any;
    setFieldValue: (p: any) => void;
    t: any;
}

const ButtonAddNewLanguage = (props: IButtonAddNewLanguage) => {
    const { t, fieldValue, setFieldValue } = props;

    // const [field, meta, helpers] = useField(mainFieldName);

    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const languagesSelected = (fieldValue || []).map(
        (item: any) => item.language
    );
    const languagesAvailable: string[] = (languages || []).map(
        (item: any) => item.value
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const [open, setOpen] = React.useState(false);
    // const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        const languageToAdd = languagesAvailable[index];
        const currentValue = [...fieldValue];
        currentValue.push({
            default: false,
            language: languageToAdd,
            id: "",
            _tempEmailData: EmailDataDefaultValues._tempEmailData
        });
        setFieldValue(currentValue);
        handleClose();
    };

    return (
        <Grid
            container
            item
            alignItems="center"
            justify="center"
            alignContent="center"
        >
            <Grid item xs={1}>
                <TooltipCompleo
                    title={t("COMPLEOGENERAL_HELP")}
                    detail={t("EMAILDATAFIELD_infomessage")}
                />
            </Grid>
            <Grid item xs={11}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleClick}
                    endIcon={<Icon>arrow_drop_down</Icon>}
                >
                    {t("EMAILDATAFIELD_addnewlanguage")}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {languagesAvailable.map((option, index) => (
                        <MenuItem
                            key={option}
                            disabled={
                                languagesSelected.filter(
                                    (item: string) => item === option
                                ).length > 0
                            }
                            onClick={(event) =>
                                handleMenuItemClick(event, index)
                            }
                        >
                            {t(`Language_${option}`)}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </Grid>
    );
};

export default ButtonAddNewLanguage;
