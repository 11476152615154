import React, { useEffect } from "react";
import {
    Calendar,
    dateFnsLocalizer,
    stringOrDate,
    View
} from "react-big-calendar";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import * as formatValues from "functions/formatValues";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "@material-ui/core";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { getCalendarEventsFromDBData, ICalendarEvent } from "./CalendarHelper";
import CalendarCustomEvent from "./CalendarCustomEvent";
import ModalViewMainEvent from "Pages/Email/Schedule/ModalViewMainEvent";
import CalendarWrapper from "./CalendarWrapper";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";

interface IModalViewMainEvent {
    open: boolean;
    eventData: Compleo.IObject;
}

interface ICalendarPage {
    insideDashboard?: boolean;
}

const CalendarPage = (props: ICalendarPage) => {
    const { insideDashboard = false } = props;
    const moduleName = "CALENDAR";
    const { isFeatureAuthorized } = useAuthorization();
    const showMessageFullControl = useShowMessageFullControl();

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [valueTab, setValueTab] = React.useState(0);
    const { company } = useAuthState();
    const date = new Date();

    const [queryParams, setQueryParams] = React.useState<Compleo.IObject>({
        companyId: company.companyId,
        getFromCurrentUser: true,
        startDate: new Date(date.setMonth(date.getMonth() - 2)), // month index base 0
        endDate: new Date(date.setMonth(date.getMonth() + 4)), // month index base 0
        getFieldsDetailsFullData: true,
        returnOnlyCalendarData: true
    });

    const defaultModalEditEventState: IModalViewMainEvent = {
        open: false,
        eventData: {}
    };
    const [
        modalViewMainEventState,
        setModalViewMainEventState
    ] = React.useState<IModalViewMainEvent>(defaultModalEditEventState);
    const handleModalViewMainEventClose = () => {
        setModalViewMainEventState(defaultModalEditEventState);
    };

    const handleChangeTab = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        setValueTab(newValue);
    };

    const locales = {
        "en-US": formatValues.getFNSLocaleFromString("en-US"),
        "pt-BR": formatValues.getFNSLocaleFromString("pt-BR")
    };
    const listAddress = `/email/listschedule`;

    const [scheduleList, refreshScheduleList] = useApiCache(
        listAddress,
        "post",
        queryParams,
        false
    );
    useEffect(() => {
        if (readyTranslation) {
            if (["initial", "fetching"].includes(scheduleList.status)) {
                showMessageFullControl.openMessage({
                    message: t("COMPLEOGENERAL_MessageLoading"),
                    variant: "info",
                    showLinearProgress: true
                });
            } else if (scheduleList.status === "error") {
                showMessageFullControl.openMessage({
                    message: t("NotIdentifiedProblem"),
                    variant: "error",
                    showLinearProgress: false,
                    autoHideDuration: 10000
                });
            } else {
                showMessageFullControl.closeMessage();
            }
        }
    }, [scheduleList.status, readyTranslation]);

    React.useEffect(() => {
        if (valueTab === 0) {
            const newParams = { ...queryParams, getFromCurrentUser: true };
            setQueryParams(newParams);
            refreshScheduleList(newParams);
        } else {
            const newParams = { ...queryParams, getFromCurrentUser: false };
            setQueryParams(newParams);
            refreshScheduleList(newParams);
        }
    }, [valueTab]);
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
    });

    const dataEmail: Compleo.IObject[] =
        scheduleList.response?.data?.data || [];
    const eventsList = getCalendarEventsFromDBData(dataEmail);

    const callBackRange = (
        range: Date[] | { start: stringOrDate; end: stringOrDate },
        view?: View | undefined
    ) => {
        const refreshList = (starDateParam: string, endDateParam: string) => {
            if (
                starDateParam < queryParams.startDate ||
                endDateParam > queryParams.endDate
            ) {
                const newParams = {
                    ...queryParams,
                    startDate: starDateParam,
                    endDate: endDateParam
                };
                setQueryParams(newParams);

                refreshScheduleList({
                    ...newParams
                });
            }
        };

        if (typeof range === "object" && !Array.isArray(range)) {
            const rangeAny: any = range;
            refreshList(rangeAny.start, rangeAny.end);
        } else if (Array.isArray(range)) {
            const rangeAny: Date[] = range;
            if (rangeAny.length) {
                const startDate = rangeAny[0].toISOString();
                const endDate = new Date(
                    rangeAny[rangeAny.length - 1].setDate(
                        rangeAny[rangeAny.length - 1].getDate() + 1
                    )
                ).toISOString();
                refreshList(startDate, endDate);
            }
        }
    };

    let compRetorno: null | JSX.Element = <Loading />;

    if (readyTranslation) {
        return (
            <CalendarWrapper t={t} insideDashboard={insideDashboard}>
                <Box height="100%" width="100%">
                    <Paper square style={{ marginBottom: 8 }}>
                        <Tabs
                            value={valueTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example"
                        >
                            <Tab label={t("myCalendar")} />
                            {isFeatureAuthorized("TeamCalendar") && (
                                <Tab label={t("teamCalendar")} />
                            )}
                        </Tabs>
                    </Paper>
                    <Calendar
                        localizer={localizer}
                        events={eventsList}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        culture={i18n.languages[0]}
                        components={{
                            event: CalendarCustomEvent
                        }}
                        onRangeChange={callBackRange}
                        //@ts-ignore
                        tooltipAccessor={null}
                        messages={{
                            today: t("BIGCALENDAR_today"),
                            previous: t("BIGCALENDAR_previous"),
                            next: t("BIGCALENDAR_next"),
                            agenda: t("BIGCALENDAR_agenda"),
                            month: t("BIGCALENDAR_month"),
                            day: t("BIGCALENDAR_day"),
                            allDay: t("BIGCALENDAR_allDay"),
                            date: t("BIGCALENDAR_date"),
                            week: t("BIGCALENDAR_week")
                        }}
                        onDoubleClickEvent={async (event) => {
                            showMessageFullControl.openMessage({
                                message: t("COMPLEOGENERAL_MessageLoading"),
                                variant: "info",
                                showLinearProgress: true
                            });
                            const eventData = await apiDirectCall(
                                listAddress,
                                "post",
                                {
                                    companyId: company.companyId,
                                    scheduleId: event.id,
                                    getFieldsDetailsFullData: true
                                }
                            );
                            const mainData = (eventData?.data?.data || [])[0];
                            setModalViewMainEventState({
                                open: true,
                                eventData: mainData
                            });
                            showMessageFullControl.closeMessage();
                        }}
                    />
                    {showMessageFullControl.MessageElement}
                </Box>
                {modalViewMainEventState.open && (
                    <ModalViewMainEvent
                        handleClose={() => handleModalViewMainEventClose()}
                        {...modalViewMainEventState}
                        t={t}
                        language={i18n.languages[0]}
                        // invalidateQuery={["/email/listschedule"]}
                    />
                )}
            </CalendarWrapper>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CalendarPage;
