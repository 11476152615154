import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { useListPageFunctionsDeleteJobTitle } from "./components/useListPageFunctionsDeleteBranch";
import {
    useCompleoSimpleList,
    actionsTableListType,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import { Button, Icon } from "@material-ui/core";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const actionsTable = (
    listPageFunctions: Compleo.IObject[],
    t: any,
    disableDelete: boolean
) => {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("COMPLEOGENERAL_Edit"),
                onClick: (event: any, rowData: any) => {
                    //console.log("edit rowData", rowData);
                    navigate(`/jobtitle/edit/${rowData.skGS1pk.split(":")[1]}`);
                }
            },
            {
                icon: "delete",
                tooltip: t("COMPLEOGENERAL_Delete"),
                onClick: (event: any, rowData: any) => {
                    // console.log("clique no delete");
                    if (listPageFunctions.length > 0) {
                        listPageFunctions[0].fn(rowData).then(
                            (resolve: boolean) => {
                                if (resolve) {
                                    refreshListFunction();
                                }
                            },
                            (reject: boolean) => {
                                console.log(
                                    "reject return do callDialog",
                                    reject
                                );
                            }
                        );
                    }
                },
                hidden: disableDelete
            }
        ];

        return result;
    };
};

const JobTitleList = (props: any) => {
    const [t, i18n, readyTranslation] = useTranslation("JOBTITLELIST", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("JOBTITLELIST");
    const listAddress = "/jobtitle/jobtitlelist";
    const { company, reloadCompanyAndPermissionData } = useAuthState();
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const [totalItems, setTotalItems] = React.useState(0);

    const listPageFunctions = useListPageFunctionsDeleteJobTitle(
        t,
        company.companyId
    );

    const disableDelete = totalItems === 1 && company.jobTitleType === "select";
    const actions: actionsTableFunction = actionsTable(
        listPageFunctions,
        t,
        disableDelete
    );
    const [returnQueryResult, getBranch] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        false
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actions
    );

    React.useEffect(() => {
        if (returnQueryResult.status === "success") {
            const queryReturnTotal = Number(
                returnQueryResult?.response?.data?.totalCount || 0
            );

            if (queryReturnTotal !== totalItems) {
                setTotalItems(queryReturnTotal);
            }
        }
    }, [
        returnQueryResult.status,
        returnQueryResult?.response?.data?.totalCount
    ]);

    const callDialogChangeCompanyData = () => {
        if (totalItems > 0) {
            callDialog({
                title: t("jobUpdateCompany_ModalTitle"),
                bodyText:
                    company.jobTitleType !== "select"
                        ? t("jobUpdateCompany_MessageToJobTitleList")
                        : t("jobUpdateCompany_MessageToJobTitleDefault"),
                agreeButtonText: t("COMPLEOGENERAL_YES"),
                disagreeButtonText: t("COMPLEOGENERAL_NO"),
                agreeFunction: async () => {
                    const postAddress = `/company/updatecompanysetup`;
                    const jobTitleType =
                        company.jobTitleType !== "select"
                            ? "select"
                            : "default";
                    const result = await apiDirectCall(postAddress, "post", {
                        companyId: company.companyId,
                        operationType: ["jobTitleType"],
                        jobTitleType: jobTitleType
                    });
                    //console.log("retorno apiDirectCall", result);
                    if (result.status === 200) {
                        reloadCompanyAndPermissionData();
                        company.jobTitleType = jobTitleType;

                        showMessage(
                            t("COMPLEOGENERAL_MessageUpdated"),
                            "success"
                        );
                        return true;
                        // resolve(true);
                    }
                    return false;
                },
                disagreeFunction: () => {
                    return false;
                }
            });
        } else {
            callDialog({
                title: t("jobUpdateCompany_ModalTitle"),
                bodyText: t("jobTitleMinimumOne"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        }
    };

    React.useEffect(() => {
        if (company.jobTitleType !== "select" && readyTranslation) {
            callDialog({
                title: t("jobTitleInfoFreeText_Warning"),
                bodyText: t("jobTitleInfoFreeText"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        }
    }, [readyTranslation]);
    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="xl"
                RightButtonComponent={
                    <>
                        <Button
                            onClick={callDialogChangeCompanyData}
                            startIcon={<Icon>settings</Icon>}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            style={{
                                marginRight: 8
                            }}
                        >
                            {t("jobUpdateSettings")}
                        </Button>
                        <Button
                            onClick={() => navigate(`/jobtitle/new`)}
                            startIcon={<Icon>add</Icon>}
                            variant="outlined"
                            size="small"
                            color="primary"
                        >
                            {t("buttonTopRight")}
                        </Button>
                    </>
                }
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobTitleList;
