import { useApiCache } from "customHooks/useApi";
import React, { useState } from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { addMainAndGroupRatingScorecard } from "../ApplicantRatingHelper";

const useJobScorecardData = (
    jobId: string | undefined,
    currentData: Compleo.IObject[],
    currentDataReady: boolean,
    isEvaluationArray: boolean = false,
    externalEvaluationId?: string
) => {
    const { company } = useAuthState();
    const [returnData, setReturnData] = React.useState<{
        ready: boolean;
        mainData: Compleo.IObject[];
    }>({ ready: false, mainData: [] });

    const postAddressSearch = !externalEvaluationId
        ? `/job/searchjobscorecard`
        : `/job/searchjobscorecardexternal`;

    const params = !externalEvaluationId
        ? {
              companyId: company.companyId,
              jobId: jobId
          }
        : {
              evaluationId: externalEvaluationId
          };
    const [scorecardData, searchScorecardData] = useApiCache(
        postAddressSearch,
        "post",
        params,
        true
    );

    React.useEffect(() => {
        if (jobId) {
            searchScorecardData();
            setReturnData({ ready: false, mainData: [] });
        } else {
            setReturnData({ ready: true, mainData: [] });
        }
    }, [jobId]);

    const scorecard = scorecardData.response?.data?.data;
    const sections = (scorecard?.sections || []).sort(
        (a: Compleo.IObject, b: Compleo.IObject) => a.order - b.order
    );

    React.useEffect(() => {
        if (
            scorecardData.status === "success" &&
            !returnData.ready &&
            currentDataReady
        ) {
            if (!isEvaluationArray) {
                const fieldValue = getScorecardData(sections, currentData);
                setReturnData({ ready: true, mainData: fieldValue });
            } else {
                for (let index = 0; index < currentData.length; index++) {
                    const element = currentData[index];
                    if (
                        element.jobId === jobId &&
                        (element.scorecard || []).length > 0
                    ) {
                        currentData[index].scorecard = getScorecardData(
                            sections,
                            currentData[index].scorecard
                        );
                        currentData[
                            index
                        ].scorecard = addMainAndGroupRatingScorecard(
                            currentData[index].scorecard
                        ).scorecard;
                    }
                }
                setReturnData({ ready: true, mainData: currentData });
            }
        }
    }, [scorecardData.status, sections, returnData.ready, currentDataReady]);

    return returnData;
};

const getScorecardData = (
    sections: Compleo.IObject[],
    currentData: Compleo.IObject[]
) => {
    debugger;
    const fieldValue: Compleo.IObject[] = [];

    if (sections.length) {
        for (const section of sections) {
            const sectionItems: Compleo.IObject[] = [];
            const currentDataSection = currentData.filter(
                (item) => item.id === section.id
            )[0];

            for (const sectionItem of section.items || []) {
                const currentDataSectionItem = (
                    currentDataSection?.items || []
                ).filter((itemLocal: any) => {
                    return (
                        itemLocal.id === sectionItem.id ||
                        itemLocal.name === sectionItem.name
                    );
                })[0];

                const currentDataEvaluation =
                    currentDataSectionItem?.evaluation || 0;

                sectionItems.push({
                    name: sectionItem.name,
                    id: sectionItem.id || sectionItem.name,
                    evaluation: currentDataEvaluation
                });
            }
            if (sectionItems.length) {
                fieldValue.push({
                    name: section.name,
                    id: section.id,
                    items: sectionItems
                });
            }
        }
    }

    return fieldValue;
};

export default useJobScorecardData;
