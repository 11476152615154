import { Avatar, Box, Grid, Icon, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import useJobContext from "../useJobContext";
import {
    getIconByHistoryType,
    IJobHistoryProps,
    getColorByHistoryType,
    getSizeByHistoryType
} from "./JobHistoryHelper";
import JobHistoryChangeStatus from "./Items/JobHistoryChangeStatus";
import JobHistoryComment from "./Items/JobHistoryComment";
import { TimelineOppositeContent } from "@material-ui/lab";
import { transformDBData } from "functions/util";

const useStyles = (dotColor: string, heightRoot: number) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: { margin: theme.spacing(2) },
            paper: { padding: theme.spacing(2) },
            timeDot: {
                borderColor: dotColor,
                backgroundColor: dotColor
            },
            icon: {
                color: "white"
            },
            content: { height: heightRoot }
        })
    );
};

const listHistory = {
    response: {
        data: {
            data: [
                {
                    skGS1pk:
                        "JOBHISTORY:2021-04-08T20:01:16.772Z#a5a6611b-7906-474e-9f5a-fbca67a19b9f",
                    createdByUser: "wagner@wagnercosta.com.br",
                    lastUpdatedByUser: "wagner@wagnercosta.com.br",
                    status: "suspended",
                    createdAt: "2021-04-08T20:01:16.772Z",
                    lastUpdatedAt: "2021-04-08T20:01:16.772Z",
                    pk: "JOBHISTORY10102:SP00021K",
                    type: "changeStatus"
                },
                {
                    skGS1pk:
                        "JOBHISTORY:2021-04-08T20:01:16.839Z#a422fdd4-4558-4105-8945-a5f9c31530b0",
                    createdByUser: "wagner@wagnercosta.com.br",
                    lastUpdatedByUser: "wagner@wagnercosta.com.br",
                    status: "open",
                    createdAt: "2021-04-08T20:01:16.839Z",
                    lastUpdatedAt: "2021-04-08T20:01:16.839Z",
                    pk: "JOBHISTORY10102:SP00021K",
                    type: "changeStatus"
                }
            ],
            page: 1,
            totalCount: 2,
            totalPage: 1
        }
    },
    status: "success",
    exception: null,
    useQueryKey: [
        "/job/jobhistorylist",
        "/job/jobhistorylist",
        "post",
        {
            jobId: "SP00021K",
            companyId: "10102"
        }
    ]
};

const JobHistoryTimelineItem = (props: IJobHistoryProps) => {
    const { historyItem } = props;
    const color = getColorByHistoryType(historyItem.type);

    const [data] = useJobContext();
    const { t, JobId, language } = data;
    const heightItem = getSizeByHistoryType(
        historyItem?.type,
        historyItem?.comment !== undefined
    );
    const classes = useStyles(color, heightItem)();

    return (
        <TimelineItem className={classes.root}>
            <TimelineOppositeContent style={{ flex: 0.2 }}>
                {transformDBData({
                    value: historyItem.createdAt,
                    t,
                    language,
                    replaceOption: "-",
                    customDateFormat: "dateRelative"
                })}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot variant="default" className={classes.timeDot}>
                    <Icon className={classes.icon}>
                        {getIconByHistoryType(historyItem.type)}
                    </Icon>
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <JobHistorySwitch {...props} />
            </TimelineContent>
        </TimelineItem>
    );
};

const JobHistorySwitch = (props: IJobHistoryProps) => {
    const { historyItem } = props;
    const type: CompleoShared.Common.JobHistoryType = historyItem.type;
    switch (type) {
        case "changeStatus":
            return <JobHistoryChangeStatus {...props} />;
        case "addComment":
            return <JobHistoryComment {...props} />;
        default:
            return null;
    }
};

export default JobHistoryTimelineItem;
