import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { navigate } from "@gatsbyjs/reach-router";
import DomainSettingsNew from "./DomainSettingsNew";
import DomainSettingsList from "./DomainSettingsList";
import { Icon } from "@material-ui/core";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
interface IDomainSettingsProps {
    open: boolean;
    uniqueLink: string;
    t: any;
    handleClose: () => void;
}

const DomainSettings = ({
    open,
    uniqueLink,
    t,
    handleClose
}: IDomainSettingsProps) => {
    const { company } = useAuthState();
    const [currentDomainView, setCurrentDomainView] = useState("domainList");
    const [canAddDomain, setCanAddDomain] = useState(false);
    const [refreshedList, setRefreshedList] = useState(false);

    const handleDomainView = (domainView: string) => {
        console.log("handleDomainView", domainView);
        setCurrentDomainView(domainView);
    };

    useEffect(() => {
        if (currentDomainView === "domainList") {
            async function getDomainList() {
                const domainList = await apiDirectCall(
                    "/careerssettings/domainlist",
                    "post",
                    {
                        companyId: company.companyId,
                        uniqueLink
                    }
                );

                console.log("getDomainList response", domainList);
                setCanAddDomain(domainList?.data?.data.length < 1);
            }

            getDomainList();
            if (refreshedList) {
                setRefreshedList(false);
            }
        }
    }, [currentDomainView, uniqueLink, company.companyId, refreshedList]);

    const onModalClose = () => {
        setCurrentDomainView("domainList");
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                <Grid container justifyContent="space-between">
                    <Grid item>{t("a_CustomDomainFeatureName")}</Grid>
                    {currentDomainView === "domainList" && canAddDomain && (
                        <Grid item>
                            <RightButton
                                rightButton={{
                                    text: t("buttonTopRight"),
                                    action: () => handleDomainView("domainNew"),
                                    enabled: true,
                                    startIcon: <Icon>add</Icon>
                                }}
                                isXsSize={false}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent>
                {currentDomainView === "domainList" ? (
                    <DomainSettingsList
                        uniqueLink={uniqueLink}
                        handleAddButtonVisibilityCheck={() =>
                            setRefreshedList(true)
                        }
                    />
                ) : (
                    <DomainSettingsNew
                        uniqueLink={uniqueLink}
                        handleCancel={() => setCurrentDomainView("domainList")}
                        handleRedirectAfterPost={() =>
                            setCurrentDomainView("domainList")
                        }
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onModalClose} color="secondary" variant="text">
                    {t("customDomainModalMainButton")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

type RightButtonType = {
    enabled: boolean;
    text: string;
    action: any;
    startIcon?: any;
};

interface IPropsRightButton {
    isXsSize: boolean;
    rightButton?: RightButtonType;
    RightButtonComponent?: JSX.Element;
}

const RightButton = (props: IPropsRightButton) => {
    const { rightButton, RightButtonComponent, isXsSize } = props;
    const InternalGrid = (props: any) => (
        <Grid
            container
            item
            alignItems="center"
            justify={isXsSize ? "flex-start" : "flex-end"}
        >
            {props.children}
        </Grid>
    );

    if (rightButton) {
        return (
            <InternalGrid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={rightButton.action}
                    startIcon={rightButton.startIcon}
                >
                    {rightButton.text}
                </Button>
            </InternalGrid>
        );
    } else if (RightButtonComponent) {
        const Component = () => RightButtonComponent;
        return (
            <InternalGrid>
                <Component />
            </InternalGrid>
        );
    } else {
        return null;
    }
};

export default DomainSettings;
