import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Box, Grid, Icon } from "@material-ui/core";
import {
    useCompleoSimpleList,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import JobDateLimitCardFilters from "./JobDateLimitCardFilters";
import { useApiCache } from "customHooks/useApi";
import { JobDateLimitType } from "./JobDateLimitFlagColumnRender";

const JobsDateLimitList = (props: any) => {
    const moduleName = "JOBSDATELIMITLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const jobStatus: CompleoShared.Common.JobStatus[] = ["open"];
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/reports/jobsdatelimit";
    const { company } = useAuthState();
    const postData: Compleo.IObject = {
        companyId: company.companyId,
        jobStatus: jobStatus,
        filterJobs: "all"
    };

    const [listPostData, setListPostData] = React.useState(postData);

    const [finalSearch] = useApiCache(
        listAddress,
        "post",
        postData,
        false,
        1000 * 60 * 2
    );
    const totalByType = finalSearch.response.data?.totalByType || [];
    const totalData = getTotalData(totalByType);

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        listPostData,
        []
    );

    const changeListPostData = (filterType: JobDateLimitType) => {
        setListPostData({
            ...postData,
            filterJobs: filterType,
            refreshQuery: true
        });
    };

    React.useEffect(() => {
        if (listPostData?.refreshQuery === true) {
            setListPostData({ ...listPostData, refreshQuery: false });
        }
    }, [listPostData?.refreshQuery]);

    let compRetorno: null | JSX.Element = <Loading />;
    const ready =
        readyTranslation && finished && finalSearch.status === "success";

    if (ready) {
        compRetorno = renderTable();
        return (
            <Box display="flex" flexDirection="column" mt={2}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <JobDateLimitCardFilters
                                title={t("jobText_red", {
                                    value: totalData.red
                                })}
                                type="red"
                                changeListPostData={changeListPostData}
                                t={t}
                                selected={listPostData.filterJobs === "red"}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <JobDateLimitCardFilters
                                title={t("jobText_warning", {
                                    value: totalData.warning
                                })}
                                type="warning"
                                changeListPostData={changeListPostData}
                                t={t}
                                selected={listPostData.filterJobs === "warning"}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <JobDateLimitCardFilters
                                title={t("jobText_normal", {
                                    value: totalData.normal
                                })}
                                type="normal"
                                changeListPostData={changeListPostData}
                                t={t}
                                selected={listPostData.filterJobs === "normal"}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <JobDateLimitCardFilters
                                title={t("jobText_all", {
                                    value: totalData.all
                                })}
                                type="all"
                                changeListPostData={changeListPostData}
                                t={t}
                                selected={listPostData.filterJobs === "all"}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box>{compRetorno}</Box>
            </Box>
        );
    } else {
        return <Loading />;
    }
};
type ITotalByType = {
    [x in JobDateLimitType]: number;
};

const getTotalData = (totalByType: Compleo.IObject[]) => {
    const returnData: ITotalByType = {
        red: totalByType.filter((i) => i.key === "red")[0]?.doc_count || 0,
        warning:
            totalByType.filter((i) => i.key === "warning")[0]?.doc_count || 0,
        normal:
            totalByType.filter((i) => i.key === "normal")[0]?.doc_count || 0,
        all: totalByType
            .map((i) => i.doc_count || 0)
            .reduce((previous, current) => previous + current, 0)
    };
    return returnData;
};

export default JobsDateLimitList;
