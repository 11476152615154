import React, { useEffect } from "react";
import Page from "../_Layouts/DefaultExternalLogin";
import { Auth } from "aws-amplify";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import stylesForm from "Pages/_Layouts/generalForm";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import LoginSignInAdditionalGrid from "./LoginSignInAdditionalGrid";
import queryString from "query-string";
import {
    LOGOFFISINACTIVITYSTORAGENAME,
    useAuthState
} from "_ReactContext/AuthContext";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { LOCALSTORAGEINACTIVITYNAME } from "auth/useIdleTimer";

const useStyles = makeStyles((theme: Theme) => createStyles(stylesForm));

const Login = (props: any) => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("SIGNIN", {
        useSuspense: false
    });
    const { login } = useAuthState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [metadata] = useGetMetadata("SIGNIN");
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    const [showInactivityMessage, setShowInactivityMessage] = React.useState(
        false
    );

    useEffect(() => {
        const localStorageValue =
            localStorage.getItem(LOGOFFISINACTIVITYSTORAGENAME) || "false";
        if (localStorageValue === "true") {
            setShowInactivityMessage(true);
            localStorage.removeItem(LOGOFFISINACTIVITYSTORAGENAME);
            if (localStorage.getItem(LOCALSTORAGEINACTIVITYNAME)) {
                localStorage.removeItem(LOCALSTORAGEINACTIVITYNAME);
            }
        }
    }, []);
    let redirectAfterLogin: null | string = "/";
    const queryStringParsed = queryString.parse(window.location.search);
    if (queryStringParsed !== undefined && queryStringParsed.to !== undefined) {
        redirectAfterLogin = Array.isArray(queryStringParsed.to)
            ? queryStringParsed.to[0]
            : queryStringParsed.to;
    }
    // ***********************************************************************
    // ***********************************************************************
    // To avoid loop at login, clear if redirect is to /login
    // ***********************************************************************
    // ***********************************************************************
    // console.log(redirectAfterLogin);
    if (redirectAfterLogin === "/login") {
        redirectAfterLogin = "/";
    }

    let loginReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const signupFunction = async (values: any) => {
        setIsLoading(true);

        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };

        try {
            // debugger;
            const user = await login(values.email, values.password);

            if (user) {
                setIsLoading(false);
                retorno = { status: 200 };
                const now = Date.now();
                localStorage.setItem(
                    LOCALSTORAGEINACTIVITYNAME,
                    now.toString()
                );
                return retorno;
            }
        } catch (err) {
            // retorno = { status: 400, data: { message: [{
            //   campoErro: "password",
            //   messageErro: "Senha Incorreta"
            // }]}};
            retorno = { status: 400 };

            if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === "NotAuthorizedException") {
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "password",
                        message: t("msgErrorIncorrectPassword")
                    }
                ];
                // The error happens when the incorrect password is provided
            } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                retorno.data = {};
                retorno.data.message = [
                    {
                        dataPath: "email",
                        message: t("msgErrorUserNotFound")
                    }
                ];
            } else {
                console.log(err);
            }
            setIsLoading(false);
            return retorno;
        }
    };

    if (isLoading) {
        loginReturn.status = "fetching";
    } else {
        loginReturn.status = "success";
    }

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        signupFunction,
        loginReturn,
        redirectAfterLogin,
        FormExternal,
        metadata,
        valuesFromSource
    );

    let compRetorno: boolean | JSX.Element = <Loading />;
    const additionalGrid = <LoginSignInAdditionalGrid t={t} />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page additionalGrid={additionalGrid}>
                <Box display={"flex"} flexDirection={"column"}>
                    {showInactivityMessage && (
                        <Box>
                            <Alert severity="info">
                                {t("inactivitySessionExpired")}
                            </Alert>
                        </Box>
                    )}
                    <Box>{compRetorno}</Box>
                </Box>
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default Login;
