import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import { green, red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { IJobBoardsShareStatusMessages } from "./JobBoards/JobBoardsStatusModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2)
        }
    })
);

function createMarkup(value: string) {
    return { __html: value };
}

interface IJobCareersListStatus {
    careerItem: CompleoShared.CareersSettings.IJobCareersListStatus;
    messageListParam?: IJobBoardsShareStatusMessages[];
}

const JobCareersStatusMessages = (props: IJobCareersListStatus) => {
    const classes = useStyles();
    const { careerItem, messageListParam } = props;
    const [t, i18n, readyTranslation] = useTranslation("JOBSHAREMODULE", {
        useSuspense: false
    });

    const messageList =
        messageListParam || getCareerItemsMessages(careerItem, t, true);

    if (!readyTranslation) {
        return null;
    }
    return (
        <div className={classes.root}>
            {messageList.map((message, index) => (
                <List key={message.message} dense>
                    <ListItem>
                        <ListItemIcon>
                            {message.type === "success" ? (
                                <DoneIcon style={{ color: green[500] }} />
                            ) : (
                                <ErrorIcon style={{ color: red[500] }} />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        message.message
                                    )}
                                />
                            }
                        />
                    </ListItem>
                    <Divider />
                </List>
            ))}
        </div>
    );
};

export const getCareerItemsMessages = (
    careersItem:
        | CompleoShared.CareersSettings.IJobCareersListStatus
        | undefined,
    t: any,
    returnErrorForInternalRecruitment: boolean = false
) => {
    const messageList: IJobBoardsShareStatusMessages[] = [];
    if (!careersItem) {
        return messageList;
    }

    addCareersJobBoardsShareMessage({
        messageList: messageList,
        isError: careersItem.enabled !== true,
        messageError: t("jobShareStatusCareerDisabledMessageError", {
            career: careersItem.careerSettings.uniqueLink
        }),
        messageSucess: t("jobShareStatusCareerDisabledMessageOK", {
            career: careersItem.careerSettings.uniqueLink
        })
    });
    addCareersJobBoardsShareMessage({
        messageList: messageList,
        isError: !careersItem.meetFilterBranchs,
        messageError: t("jobShareStatusCareerMeetFilterBranchsMessageError", {
            career: careersItem.careerSettings.uniqueLink
        }),
        messageSucess: t("jobShareStatusCareerMeetFilterBranchsMessageOK", {
            career: careersItem.careerSettings.uniqueLink
        })
    });
    addCareersJobBoardsShareMessage({
        messageList: messageList,
        isError: !careersItem.meetFilterCustomers,
        messageError: t("jobShareStatusCareerMeetFilterCustomersMessageError", {
            career: careersItem.careerSettings.uniqueLink
        }),
        messageSucess: t("jobShareStatusCareerMeetFilterCustomersMessageOK", {
            career: careersItem.careerSettings.uniqueLink
        })
    });

    addCareersJobBoardsShareMessage({
        messageList: messageList,
        isError: !careersItem.meetFilterPCD,
        messageError: t("jobShareStatusCareerMeetFilterPCDMessageError", {
            career: careersItem.careerSettings.uniqueLink
        }),
        messageSucess: t("jobShareStatusCareerMeetFilterPCDMessageOK", {
            career: careersItem.careerSettings.uniqueLink
        })
    });

    if (returnErrorForInternalRecruitment) {
        if (
            careersItem.careerSettings.filters_onlyInternalRecruitment === true
        ) {
            messageList.push({
                message: t("jobBoardInternalRecruitmentMessageError"),
                type: "error"
            });
        }
    } else {
        addCareersJobBoardsShareMessage({
            messageList: messageList,
            isError: !careersItem.meetFilterInternalRecruitment,
            messageError: t(
                "jobShareStatusCareerMeetFilterInternalRecruitmentMessageError",
                {
                    career: careersItem.careerSettings.uniqueLink
                }
            ),
            messageSucess: t(
                "jobShareStatusCareerMeetFilterInternalRecruitmentMessageOK",
                {
                    career: careersItem.careerSettings.uniqueLink
                }
            )
        });
    }
    return messageList;
};

interface IAddMessage {
    messageList: any[];
    isError: boolean;
    messageError: string;
    messageSucess: string;
}
export const addCareersJobBoardsShareMessage = (params: IAddMessage) => {
    const { isError, messageError, messageList, messageSucess } = params;
    if (isError) {
        messageList.push({
            message: messageError,
            type: "error"
        });
    } else {
        messageList.push({
            message: messageSucess,
            type: "success"
        });
    }
};

export default JobCareersStatusMessages;
