import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { i18n, TFunction } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useCustomLists from "./useCustomLists";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { ModalDataType } from "./helperScorecard";
import { Grid } from "@material-ui/core";
import * as util from "functions/util";
import { ListDefinitionType } from "Pages/Users/components/useCustomLists";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        }
    })
);

interface IProps {
    modalData: ModalDataType;
    handleModalClose: any;
    t: TFunction;
    fieldValue: any;
    setFieldValue: (value: any) => void;
    metadata: ApiStateType;
    i18n: i18n;
    readyTranslation: boolean;
}

const SectionsActionsModal = (props: IProps) => {
    const {
        handleModalClose,
        modalData,
        t,
        fieldValue,
        setFieldValue,
        metadata,
        i18n,
        readyTranslation
    } = props;
    const classes = useStyles();

    const initialValues = { ...(modalData.section || {}) };
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        false,
        initialValues
    );

    const closeModal = () => {
        handleModalClose();
    };

    let addSectionReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const addSection = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        const currentField = [...(fieldValue || [])];

        if (modalData.new) {
            const newId = util.uuid();
            currentField.splice(modalData.index, 0, { ...values, id: newId });
            const reorderedStages = currentField.map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        order: index
                    };
                }
            );
            setFieldValue(reorderedStages);
        } else {
            currentField[modalData.index] = {
                ...currentField[modalData.index],
                ...values
            };
            setFieldValue(currentField);
        }

        handleModalClose();
        return retorno;
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addSection,
        postReturn: addSectionReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: closeModal
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && readyTranslation) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            open={modalData.open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("a_ModalTitle")}
            aria-describedby={t("a_ModalTitle")}
            PaperProps={{
                className: classes.dialog
            }}
            scroll="body"
        >
            <DialogTitle id="alert-dialog-title">
                {t("a_ModalTitle")}
            </DialogTitle>
            <DialogContent>{compRetorno}</DialogContent>
        </Dialog>
    );
};

export default SectionsActionsModal;
