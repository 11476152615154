import React from "react";
import useList from "./index";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Button,
    Chip,
    DialogTitle,
    Divider,
    Grid,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import theme from "theme/Theme";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CloseIcon from "@material-ui/icons/Close";
import { useHotkeys } from "react-hotkeys-hook";
import _ from "lodash";
import { ApplicantHistorySwitch } from "Pages/Applicant/ApplicantView/ApplicantHistory/ApplicantHistoryTimelineItem";
import { getColorByHistoryType } from "Pages/Applicant/ApplicantView/ApplicantHistory/ApplicantHistoryHelper";
import { transformDBData } from "functions/util";

const useStylesItemGrid = makeStyles((theme: Theme) =>
    createStyles({
        ItemActivityValue: {
            marginLeft: theme.spacing(1)
        },
        gridItem: {
            marginTop: theme.spacing(0.5)
        },
        spanHtml: {
            // backgroundColor: "white",
            padding: theme.spacing(1),
            borderWidth: 1
            // borderColor: "black"
        }
    })
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperDialogProps: {
            // backgroundColor: theme.palette.backgroundGrey.main,
            minHeight: "75vh",
            maxHeight: "75vh"
        },
        dialogActions: {
            backgroundColor: "white"
            // borderTopColor: theme.palette.backgroundGrey.main,
            // borderTopWidth: 2,
            // borderTopStyle: "solid"
        },
        gridContainer: {
            // margin: theme.spacing(2)
            padding: theme.spacing(2),
            maxWidth: "95%"
        },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 4,
            borderRadius: 6
        }
    })
);

interface INavigationInfo {
    index: number;
    changePageFN: (value: number) => void;
    currentPage: number;
    fieldArray: Compleo.IObject[];
    totalPages: number;
}

interface IMainPage {
    idFieldName?: string;
}

const getId = (
    navigationInfo: INavigationInfo,
    currentIndex?: number,
    idFieldName: string = "pk"
) => {
    let fieldData: Compleo.IObject | null = null;
    if (currentIndex !== undefined) {
        fieldData = navigationInfo.fieldArray[currentIndex];
    } else {
        fieldData = navigationInfo.fieldArray[navigationInfo.index];
    }
    const pkData = _.get(fieldData, idFieldName);
    return {
        pkData: (pkData || "").split(":")[1] || pkData,
        fieldData: fieldData
    };
};

export const ModalActivity = (props: IMainPage) => {
    const { idFieldName = "pk" } = props;
    const classes = useStyles();
    const isXSScreen = useMediaQuery(theme.breakpoints.only("xs"));
    const classPaperProps = !isXSScreen ? classes.paperDialogProps : "";
    const [listData, listDispatch] = useList();
    const t = listData?.tempInfo?.t;

    const handlePageChange = (value: number) => {
        setFilters({
            ...filters,
            pagination: {
                currentPage: value,
                pageSize: pageSize
            },
            filterUpdated: true
        });
    };

    const navigationInfo = {
        index: listData.localInfo?.modalActivity?.currentIndex || 0,
        changePageFN: handlePageChange,
        currentPage: listData.filtersInfo?.filters?.pagination?.currentPage,
        fieldArray: listData?.listReturnInfo?.fields,
        totalPages: listData?.tempInfo?.totalPages
    };
    const [currentIndex, setCurrentIndex] = React.useState(
        navigationInfo?.index !== undefined ? navigationInfo?.index : -1
    );
    const currentPageSize = (navigationInfo?.fieldArray || []).length;

    const { pkData: localId, fieldData } = getId(
        navigationInfo,
        currentIndex,
        idFieldName
    );
    const { readyTranslation } = listData.tempInfo;
    const ready = readyTranslation;
    const { filters } = listData.filtersInfo;
    const { setFilters, setLocalInfo } = listDispatch;
    const modalActivity = listData?.localInfo?.modalActivity;
    const open = modalActivity?.open === true;

    const closeModal = () => {
        listDispatch.setLocalInfo({
            ...listData.localInfo,
            modalActivity: undefined
        });
    };

    React.useEffect(() => {
        if (open) {
            setCurrentIndex(
                navigationInfo?.index !== undefined ? navigationInfo?.index : -1
            );
        }
    }, [open]);

    React.useEffect(() => {
        if (open && !localId && !listData.listReturnInfo.queryFinished) {
            closeModal();
        }
    }, [open, localId, listData.listReturnInfo.queryFinished]);

    const previous = (
        currentIndexParam: number,
        currentPageSizeParam: number
    ) => {
        if (currentIndexParam > 0) {
            setCurrentIndex(currentIndexParam - 1);
        } else {
            if (navigationInfo) {
                if (navigationInfo.currentPage > 1) {
                    navigationInfo.changePageFN(navigationInfo.currentPage - 1);
                    setCurrentIndex(currentPageSizeParam - 1);
                }
            }
        }
    };

    const next = (currentIndexParam: number, currentPageSizeParam: number) => {
        if (currentIndexParam === currentPageSizeParam - 1) {
            if (navigationInfo) {
                if (navigationInfo.currentPage < navigationInfo.totalPages) {
                    navigationInfo.changePageFN(navigationInfo.currentPage + 1);
                    setCurrentIndex(0);
                }
            }
        } else {
            setCurrentIndex(currentIndexParam + 1);
        }
    };

    useHotkeys(
        "ctrl+q,right,left",
        (event: any, handler: any) => {
            switch (handler.key) {
                case "ctrl+q":
                    closeModal();
                    break;
                case "right":
                    next(currentIndex, currentPageSize);
                    break;
                case "left":
                    previous(currentIndex, currentPageSize);
                    break;
                default:
                    break;
            }
        },
        {},
        [currentIndex, currentPageSize]
    );

    const pageSize = filters.pagination.pageSize;

    const disableNextButton =
        currentPageSize === 0 ||
        (navigationInfo?.totalPages === navigationInfo?.currentPage &&
            currentIndex === currentPageSize - 1);

    const type: CompleoShared.Common.ApplicantHistoryType = fieldData?.type;
    const color = getColorByHistoryType(type);

    if (ready) {
        return (
            <Dialog
                fullWidth
                fullScreen={isXSScreen}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("ModalSortColumn_Title")}
                open={open}
                PaperProps={{ className: classPaperProps }}
            >
                <DialogTitle>
                    {t("actityDetailModal", "")}{" "}
                    <Chip
                        className={classes.chipCard}
                        style={{
                            backgroundColor: color,
                            color: "white"
                        }}
                        size="medium"
                        label={t(`ApplicantHistoryType_${fieldData?.type}`, "")}
                    />
                </DialogTitle>
                <DialogContent style={{ padding: 0, margin: 0 }}>
                    {open && localId && readyTranslation && (
                        <Grid
                            container
                            className={classes.gridContainer}
                            spacing={1}
                        >
                            {fieldData?.jobData?.title && (
                                <Grid item xs={12}>
                                    <ItemActivity
                                        children={fieldData?.jobData?.title}
                                        label={t("jobData.title")}
                                    />
                                </Grid>
                            )}
                            {fieldData?.applicantDetail?.name && (
                                <Grid item xs={12}>
                                    <ItemActivity
                                        children={
                                            fieldData?.applicantDetail?.name
                                        }
                                        label={t("applicantDetail.name")}
                                    />
                                </Grid>
                            )}
                            {fieldData?.stage?.name && (
                                <Grid item xs={12}>
                                    <ItemActivity
                                        children={fieldData?.stage?.name}
                                        label={t("stage.name")}
                                    />
                                </Grid>
                            )}
                            {fieldData?.createdAt && (
                                <Grid item xs={12}>
                                    <ItemActivity
                                        children={transformDBData({
                                            value: _.get(
                                                fieldData,
                                                "createdAt"
                                            ),
                                            t,
                                            language:
                                                listData?.tempInfo?.language,
                                            replaceOption: "-",
                                            customDateFormat: "dateWithTime"
                                        })}
                                        label={t("createdAt")}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <ItemActivity
                                    children={fieldData?.createdByUser}
                                    label={t("createdByUser")}
                                />
                            </Grid>
                            {fieldData?.comment && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemActivity
                                            children={fieldData?.comment}
                                            label={t("comment")}
                                            isHtml
                                            removeColonAfterLabel
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </DialogContent>
                {open && localId && readyTranslation && (
                    <DialogActions className={classes.dialogActions}>
                        {((currentPageSize > 0 &&
                            (navigationInfo?.currentPage || 0) > 1) ||
                            currentIndex > 0) && (
                            <Button
                                onClick={() => {
                                    previous(currentIndex, currentPageSize);
                                }}
                                color="primary"
                                variant="outlined"
                                startIcon={<NavigateBeforeIcon />}
                            >
                                {t("COMPLEOGENERAL_PREVIOUS")}
                            </Button>
                        )}
                        {!disableNextButton && (
                            <Button
                                onClick={() => {
                                    next(currentIndex, currentPageSize);
                                }}
                                color="primary"
                                variant="outlined"
                                endIcon={<NavigateNextIcon />}
                            >
                                {t("COMPLEOGENERAL_NEXT")}
                            </Button>
                        )}
                        <Button
                            onClick={closeModal}
                            color="secondary"
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            {t("COMPLEOGENERAL_CLOSE")}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    } else {
        return null;
    }
};

interface IItemActivityProps {
    label?: any;
    children: any;
    removeColonAfterLabel?: boolean;
    isHtml?: boolean;
}

function createMarkup(value: string) {
    return { __html: value };
}

const ItemActivity = (props: IItemActivityProps) => {
    const classes = useStylesItemGrid();
    const { isHtml = false } = props;

    return (
        <>
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="flex-start"
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    style={{
                        fontWeight: 600
                    }}
                >
                    {props.label}
                    {props.removeColonAfterLabel ? "" : ":"}
                </Typography>

                {!isHtml && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        className={classes.ItemActivityValue}
                    >
                        {props.children}
                    </Typography>
                )}
            </Grid>

            {isHtml && (
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="flex-start"
                >
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        className={classes.ItemActivityValue}
                    >
                        <div
                            className={classes.spanHtml}
                            dangerouslySetInnerHTML={createMarkup(
                                props.children
                            )}
                        />
                    </Typography>
                </Grid>
            )}
        </>
    );
};

export default ModalActivity;
