import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import {
    MoreVert,
    Share,
    BlockRounded,
    TrendingUp,
    Delete,
    Forward,
    Visibility,
    FiberManualRecord
} from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

export type MenuJobActionRunActionTypeList = "removeFromJob";
export type MenuJobActionRunActionType = (
    type: MenuJobActionRunActionTypeList
) => Promise<void>;

interface IJobActions {
    jobData: Compleo.IObject;
    runAction: MenuJobActionRunActionType;
    t: any;
}
const MenuJobActions = (props: IJobActions) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { jobData, runAction, t } = props;

    const handleOpenMenuActions = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuActions = () => {
        setAnchorEl(null);
    };

    const localRunAction = async (type: MenuJobActionRunActionTypeList) => {
        await runAction(type);
        handleCloseMenuActions();
    };
    const qualifyRequalifyTitle =
        jobData?.Stage?.type?.value !== "rejected"
            ? t("ModalDisqualify_title")
            : t("ModalRequalify_title");

    // console.log("jobData?.Stage", jobData?.Stage);
    return (
        <>
            <ButtonIconTooltipCompleo
                label={t("COMPLEOGENERAL_OTHERACTIONS")}
                onClick={handleOpenMenuActions}
            >
                <MoreVert />
            </ButtonIconTooltipCompleo>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenuActions}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem onClick={() => localRunAction("removeFromJob")}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    {t("DialogRemoveFromJob_title")}
                </MenuItem>
                {/* <MenuItem
                    onClick={() => localRunAction("disqualifyOrRequalify")}
                >
                    <ListItemIcon>
                        {jobData?.Stage?.type?.value !== "rejected" ? (
                            <BlockRounded fontSize="small" />
                        ) : (
                            <TrendingUp fontSize="small" />
                        )}
                    </ListItemIcon>
                    {qualifyRequalifyTitle}
                </MenuItem> */}
            </Menu>
        </>
    );
};

export default MenuJobActions;
