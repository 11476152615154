import React from "react";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridLayout, {
    Responsive as ResponsiveGridLayout,
    WidthProvider
} from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import dragBackground from "../Helpers/drag-background.svg";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useDashboardContext from "../useDashboardContext";
import BucketReport from "./BucketReport/BucketReport";
import useDynamicDashboard, {
    breakpointsList,
    setLastDynamicDashboardToRender
} from "../Helpers/useDynamicDashboard";
import DynamicDashboardEditModeFloatButtons from "../Helpers/DynamicDashboardEditModeFloatButtons";
import { SizeMe } from "react-sizeme";
import { useTranslation } from "react-i18next";
import useGridOnLayoutChange from "../Helpers/useGridOnLayoutChange";

// const ResponsiveGridLayout = WidthProvider(Responsive);

// const ResponsiveGridLayout2 = WidthProvider(ResponsiveGridLayout);

const useStyles = makeStyles((theme: Theme) => {
    const mainRoot = {
        padding: 0,
        margin: 0,
        width: "100%"
    };

    return createStyles({
        root: {
            ...mainRoot,
            background: "transparent"
        },
        rootDragging: {
            ...mainRoot,
            background: `url(${dragBackground})`,
            backgroundRepeat: "repeat-y",
            // backgroundPosition: "0px -4px",
            // backgroundSize: "100% 52px",
            backgroundSize: "100%"
        },
        div: {
            borderWidth: 1
        }
    });
});

interface IProps {
    dashboardName: string;
}

const DynamicDashboardItems = (props: IProps) => {
    const { dashboardName } = props;
    const classes = useStyles();
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const [tLocalDashboard, i18n, readyTranslation] = useTranslation(
        dashboardName,
        {
            useSuspense: false
        }
    );
    const onLayoutChange = useGridOnLayoutChange();

    const dashboardDef = useDynamicDashboard({
        moduleName: dashboardName
    });
    const { t, ready } = data;
    const { editMode } = data.localStateData;

    const widgetList =
        data.localStateData.currentDashboardDef?.widgetList || [];
    const layouts = data.localStateData.currentDashboardDef?.layouts || {};
    const idDash = data.localStateData.currentDashboardDef?.id;

    const children = React.useMemo(() => {
        if (widgetList.length) {
            return widgetList.map((item) => {
                const itemName = item.split("_")[0];
                const itemType = item.split("_")[1];

                return (
                    <div key={item}>
                        <BucketReport bucketName={itemName} type={itemType} />
                    </div>
                );
            });
        } else {
            return null;
        }
    }, [
        dashboardDef.ready,
        editMode,
        widgetList.length,
        idDash,
        data.localStateData.pageToRender
    ]);

    React.useEffect(() => {
        if (dashboardDef.ready && ready) {
            setLastDynamicDashboardToRender(
                data.localStateData?.dashboardList || [],
                idDash
            );
        }
    }, [idDash, dashboardDef.ready, ready]);

    if (dashboardDef.ready && ready) {
        if (widgetList.length > 0) {
            return (
                <Paper
                    className={!editMode ? classes.root : classes.rootDragging}
                    elevation={0}
                >
                    {/* <DashboardTitle /> */}
                    <SizeMe>
                        {({ size }) => (
                            <ResponsiveGridLayout
                                width={size.width || undefined}
                                className="layout"
                                layouts={layouts}
                                breakpoints={dashboardDef.breakpoints}
                                cols={dashboardDef.cols}
                                isDraggable={editMode}
                                isResizable={editMode}
                                onLayoutChange={onLayoutChange}
                            >
                                {children}
                            </ResponsiveGridLayout>
                        )}
                    </SizeMe>
                    {editMode && (
                        <DynamicDashboardEditModeFloatButtons
                            dashboardName={dashboardName}
                            tMetadata={tLocalDashboard}
                        />
                    )}
                </Paper>
            );
        } else {
            return <p>{t("NotIdentifiedProblem")}</p>;
        }
    } else {
        return <Loading />;
    }
};

export default DynamicDashboardItems;
