import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import {
    AuthProvider,
    useAuthorization,
    useAuthState
} from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import {
    useCompleoSimpleList,
    actionsTableListType,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import Icon from "@material-ui/core/Icon";
import { useListPageFunctionsDeleteContact } from "./components/useListPageFunctionsDeleteContact";

function actionsTable(
    listPageFunctions: Compleo.IObject[],
    featuresAccess: string[]
) {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: "Edit Contact",
                onClick: (event: any, rowData: any) => {
                    navigate(`/contact/edit/${rowData.skGS1pk.split(":")[1]}`);
                },
                hidden: !featuresAccess.includes("EditContact")
            },
            {
                icon: "delete",
                tooltip: "Delete Contact",
                onClick: (event: any, rowData: any) => {
                    if (listPageFunctions.length > 0) {
                        listPageFunctions[0].fn(rowData).then(
                            (resolve: boolean) => {
                                if (resolve) {
                                    refreshListFunction();
                                }
                            },
                            (reject: boolean) => {}
                        );
                    }
                },
                hidden: !featuresAccess.includes("DeleteContact")
            }
        ];

        return result;
    };
}

const ContactList = (props: any) => {
    const [t, i18n, readyTranslation] = useTranslation("CONTACTLIST", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("CONTACTLIST");
    const listAddress = "/contact/list";
    const { company } = useAuthState();
    const listPageFunctions = useListPageFunctionsDeleteContact(t);

    const { isFeatureAuthorized } = useAuthorization();
    const featuresAccess = [];

    if (isFeatureAuthorized("EditContact")) {
        featuresAccess.push("EditContact");
    }

    if (isFeatureAuthorized("DeleteContact")) {
        featuresAccess.push("DeleteContact");
    }
    const canAddContact = isFeatureAuthorized("AddContact");

    const actions: actionsTableFunction = actionsTable(
        listPageFunctions,
        featuresAccess
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actions
    );
    //return <div></div>;

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                rightButton={
                    canAddContact
                        ? {
                              text: t("buttonTopRight"),
                              action: () => navigate(`/contact/new`),
                              enabled: true,
                              startIcon: <Icon>add</Icon>
                          }
                        : undefined
                }
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ContactList;
