import { Alert } from "@material-ui/lab";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTranslation } from "react-i18next";

const ErrorResult = () => {
    const [t, i18n, readyTranslation] = useTranslation(
        ["ELASTICLISTSETTINGS"],
        {
            useSuspense: false
        }
    );
    if (readyTranslation) {
        return <Alert severity="error">{t("NotIdentifiedProblem")}</Alert>;
    } else {
        return <Loading />;
    }
};

export default ErrorResult;
