import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
    Pause,
    Block,
    Done,
    FileCopyOutlined,
    PlayArrow,
    MoreVert,
    PauseCircleFilledOutlined,
    CheckCircle,
    Cancel,
    Replay,
    HelpOutline
} from "@material-ui/icons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import ModalChangeStatus from "./ModalChangeStatus";
import { navigate } from "@gatsbyjs/reach-router";
import useJobAccess from "customHooks/useAccess/useJobAccess";
import usePlanLimits from "customHooks/usePlanLimits/usePlanLimits";
const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface IProps {
    fieldData: Compleo.IObject;
    t: any;
    useApiKey?: any;
    showInDialog?: boolean;
    isInsideJobView?: boolean;
    showCloneJob?: boolean;
    handleCloseParentMenu?: any;
    isJobRequestList?: boolean;
}

const StatusActions = (props: IProps) => {
    const queryClient = useQueryClient();
    const { company } = useAuthState();
    const { getLimit } = usePlanLimits();
    const {
        fieldData,
        showInDialog = false,
        isInsideJobView = false,
        t,
        useApiKey,
        showCloneJob = true,
        handleCloseParentMenu,
        isJobRequestList = false
    } = props;
    const jobId = fieldData.pk.split(":")[1];
    const jobAccess = useJobAccess();

    const classes = useStyles();
    const showMessage = useShowMessage();
    const { callDialog } = useGlobalDialog();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const modalChangeStatus = useChangeJobStatusOptions();
    const [modalStatusData, setModalStatusData] = React.useState<{
        newStatus: CompleoShared.Common.JobStatus;
        oldStatus: CompleoShared.Common.JobStatus;
        open: boolean;
    }>({ newStatus: "finished", open: false, oldStatus: "finished" });

    const handleOpenMenuActions = (
        event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuActions = () => {
        setAnchorEl(null);
        if (handleCloseParentMenu) {
            handleCloseParentMenu();
        }
    };

    const showErrorMessage = () => {
        callDialog({
            title: t("updateStatusErrorTitle"),
            bodyText: t("updateStatusErrorMessage"),
            agreeButtonText: t("updateStatusErrorButton"),
            // disagreeButtonText: t("ScorecardDelete_no"),
            agreeFunction: async () => {
                return false;
            }
        });
    };

    /**
     * Update Status using optimistic update concept
     * @param newStatus The new status for the Job
     */
    const editStatusMain = async (
        newStatus: CompleoShared.Common.JobStatus,
        otherValues?: Compleo.IObject
    ) => {
        if (showInDialog) {
            handleCloseMenuActions();
        }

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(useApiKey);

        // get the data before update
        const previousData: any = queryClient.getQueryData(useApiKey);

        // clone because the original object is byref
        const previousDataWithoutRef = _.cloneDeep(previousData);
        if (!isInsideJobView) {
            // change the local data with the new status
            await queryClient.setQueryData(useApiKey, (oldData: any) => {
                oldData.fields.filter(
                    (item: any) => item.pk === `JOB:${jobId}`
                )[0].status = newStatus;
                return oldData;
            });
        }

        const postAddress = `/job/editstatus`;
        try {
            // change the database status
            const postValues = {
                ...(otherValues || {}),
                companyId: company.companyId,
                id: jobId,
                status: newStatus
            };

            const updateReturn = await apiDirectCall(
                postAddress,
                "post",
                postValues
            );

            if (updateReturn.status === 200) {
                // if the update is successfully, after some time, invalidate queries
                // to search new data on elasticsearch.
                // The wait time is necessary, because the delay to update elasticsearch index
                // showMessage(t("updateStatusSuccess"), "success", 1000 * 3);

                showMessage({
                    message: t("updateStatusSuccess"),
                    snackBarOrign: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    timeout: 1000 * 2
                });
                setTimeout(() => {
                    queryClient.invalidateQueries("/job/list");
                    queryClient.invalidateQueries(useApiKey);
                }, 3000);
            } else {
                if (!isInsideJobView) {
                    queryClient.setQueryData(useApiKey, () => {
                        return previousDataWithoutRef;
                    });
                }
                showErrorMessage();
            }
        } catch (e) {
            if (!isInsideJobView) {
                queryClient.setQueryData(useApiKey, () => {
                    return previousDataWithoutRef;
                });
            }
            showErrorMessage();
        }
    };

    const editStatus = async (newStatus: CompleoShared.Common.JobStatus) => {
        if (showInDialog) {
            handleCloseMenuActions();
        }

        if (newStatus === "open") {
            const dataLimit = await getLimit("addJob");
            if (!dataLimit.allowAction) {
                callDialog({
                    title: t("PLANLIMIT_title"),
                    // bodyText: `Seu plano atingiu o limite de vagas abertas. Limite de vagas: ${dataLimit.maxValue} - Vagas abertas: ${dataLimit.currentTotal}`,
                    bodyText: t("PLANLIMIT_jobMessage", {
                        maxValue: dataLimit.maxValue,
                        currentTotal: dataLimit.currentTotal
                    }),
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    // disagreeButtonText: t("ScorecardDelete_no"),
                    agreeFunction: async () => {
                        return false;
                    }
                });
                return undefined;
            }
        }

        if (
            [
                "finished",
                "canceled",
                "approvedRequest",
                "disapprovedRequest",
                "suspended",
                "requested",
                "awaitingCorrectionRequest"
            ].includes(newStatus)
        ) {
            setModalStatusData({
                newStatus: newStatus,
                open: true,
                oldStatus: fieldData.status
            });
        } else if (
            [
                "finished",
                "canceled",
                "draft",
                "approvedRequest",
                "suspended"
            ].includes(fieldData.status) &&
            newStatus === "open"
        ) {
            setModalStatusData({
                newStatus: newStatus,
                open: true,
                oldStatus: fieldData.status
            });
        } else {
            await editStatusMain(newStatus);
        }
    };

    if (!jobAccess.allowEdit && !isJobRequestList) {
        return null;
    }

    if (showInDialog) {
        return (
            <>
                {isInsideJobView ? (
                    <MenuItem onClick={handleOpenMenuActions}>
                        {t("changeJobStatus")}
                    </MenuItem>
                ) : (
                    <ButtonIconTooltipCompleo
                        label={t("otherActions")}
                        onClick={handleOpenMenuActions}
                    >
                        <MoreVert />
                    </ButtonIconTooltipCompleo>
                )}
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenuActions}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    <MainReturn
                        editStatus={editStatus}
                        fieldData={fieldData}
                        t={t}
                        isMenu={true}
                        showCloneJob={showCloneJob}
                        isJobRequestList={isJobRequestList}
                    />
                </Menu>
                {modalStatusData.open && (
                    <ModalChangeStatus
                        open={modalStatusData.open}
                        handleClose={() =>
                            setModalStatusData((state) => {
                                return { ...state, open: false };
                            })
                        }
                        newStatus={modalStatusData.newStatus}
                        oldStatus={modalStatusData.oldStatus}
                        jobData={fieldData}
                        editStatusMain={editStatusMain}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <MainReturn
                    editStatus={editStatus}
                    fieldData={fieldData}
                    t={t}
                    isMenu={false}
                    showCloneJob={showCloneJob}
                    isJobRequestList={isJobRequestList}
                />
                {modalStatusData.open && (
                    <ModalChangeStatus
                        open={modalStatusData.open}
                        handleClose={() =>
                            setModalStatusData((state) => {
                                return { ...state, open: false };
                            })
                        }
                        newStatus={modalStatusData.newStatus}
                        oldStatus={modalStatusData.oldStatus}
                        jobData={fieldData}
                        editStatusMain={editStatusMain}
                    />
                )}
            </>
        );
    }
};

export type AllowedNewStatusType = {
    [K in CompleoShared.Common.JobStatus]?: CompleoShared.Common.JobStatus[];
};

export const AllowedNewStatus: AllowedNewStatusType = {
    open: ["canceled", "finished", "suspended", "awaitingCustomer"],
    draft: ["open", "canceled"],
    requested: [
        "approvedRequest",
        "disapprovedRequest",
        "awaitingCorrectionRequest"
    ],
    suspended: ["open", "canceled"],
    awaitingCustomer: ["open", "canceled", "suspended"],
    finished: ["open"],
    canceled: ["open"],
    disapprovedRequest: [],
    approvedRequest: ["open", "canceled"]
};

export const AllowedNewStatusJobRequest: AllowedNewStatusType = {
    open: [],
    draft: [],
    requested: [],
    suspended: [],
    awaitingCustomer: [],
    finished: [],
    canceled: [],
    awaitingCorrectionRequest: ["requested"],
    disapprovedRequest: [],
    approvedRequest: []
};

const verifyShowActionButton = (
    currentStatus: CompleoShared.Common.JobStatus,
    newStatus: CompleoShared.Common.JobStatus,
    isJobRequestList: boolean
) => {
    const statusAllowedList = isJobRequestList
        ? AllowedNewStatusJobRequest
        : AllowedNewStatus;

    if (
        statusAllowedList[currentStatus] &&
        statusAllowedList[currentStatus]?.includes(newStatus)
    ) {
        return true;
    }
    return false;
};

interface IMainReturn {
    editStatus: (newStatus: CompleoShared.Common.JobStatus) => Promise<void>;
    fieldData: Compleo.IObject;
    t: any;
    showCloneJob: boolean;
    isMenu: boolean;
    isJobRequestList: boolean;
}
const MainReturn = (props: IMainReturn) => {
    const {
        editStatus,
        fieldData,
        t,
        isMenu,
        showCloneJob,
        isJobRequestList
    } = props;
    const otherProps = {
        isMenu,
        currentStatus: fieldData.status,
        editStatus: editStatus,
        isJobRequestList: isJobRequestList
    };
    const jobId = fieldData?.pk.split(":")[1];
    const cloneLink = isJobRequestList
        ? `/job/newrequest/${jobId}`
        : `/job/new/${jobId}`;

    return (
        <>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_open")}`}
                newStatus="open"
                {...otherProps}
            >
                <PlayArrow color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_suspended")}`}
                newStatus="suspended"
                {...otherProps}
            >
                <Pause color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_canceled")}`}
                newStatus="canceled"
                {...otherProps}
            >
                <Block color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_finished")}`}
                newStatus="finished"
                {...otherProps}
            >
                <Done color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_approvedRequest")}`}
                newStatus="approvedRequest"
                {...otherProps}
            >
                <CheckCircle color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t(
                    "JOBSTATUS_disapprovedRequest"
                )}`}
                newStatus="disapprovedRequest"
                {...otherProps}
            >
                <Cancel color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t(
                    "JOBSTATUS_awaitingCorrectionRequest"
                )}`}
                newStatus="awaitingCorrectionRequest"
                {...otherProps}
            >
                <HelpOutline color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t("JOBSTATUS_requested")}`}
                newStatus="requested"
                {...otherProps}
            >
                <Replay color="action" />
            </ButtonActionStatus>
            <ButtonActionStatus
                label={`${t("updateStatus")} ${t(
                    "JOBSTATUS_awaitingCustomer"
                )}`}
                newStatus="awaitingCustomer"
                {...otherProps}
            >
                <PauseCircleFilledOutlined color="action" />
            </ButtonActionStatus>

            {showCloneJob && (
                <ButtonActionStatus
                    label={t("cloneJob")}
                    otherFunctionToRun={() => {
                        navigate(cloneLink);
                    }}
                    isMenu={isMenu}
                >
                    <FileCopyOutlined color="action" />
                </ButtonActionStatus>
            )}
        </>
    );
};

const useStylesButtonAction = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1)
        }
    })
);

interface IButtonAction {
    label: string;
    children: any;
    currentStatus?: CompleoShared.Common.JobStatus;
    newStatus?: CompleoShared.Common.JobStatus;
    editStatus?: (newStatus: CompleoShared.Common.JobStatus) => Promise<void>;
    isMenu?: boolean;
    otherFunctionToRun?: any;
    isJobRequestList?: boolean;
}
const ButtonActionStatus = (props: IButtonAction) => {
    const {
        label,
        children,
        newStatus,
        currentStatus,
        editStatus,
        otherFunctionToRun = () => {},
        isMenu = true,
        isJobRequestList = false
    } = props;
    const classes = useStylesButtonAction();

    const showButton =
        currentStatus && newStatus
            ? verifyShowActionButton(currentStatus, newStatus, isJobRequestList)
            : true;

    const fnToRun =
        currentStatus && newStatus && editStatus
            ? () => editStatus(newStatus)
            : otherFunctionToRun;

    if (showButton) {
        if (isMenu) {
            return (
                <MenuItem onClick={fnToRun}>
                    <ListItemIcon>{children}</ListItemIcon>
                    {label}
                </MenuItem>
            );
        } else {
            return (
                <ButtonIconTooltipCompleo label={label} onClick={fnToRun}>
                    {children}
                </ButtonIconTooltipCompleo>
            );
        }
    } else {
        return null;
    }
};

export default StatusActions;
