import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, ListItemSecondaryAction, Switch } from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { Button, ButtonGroup } from "@material-ui/core";
import { FieldOptionsType } from "./FormData";
import { fieldsRules } from "./helper";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

interface IButtonGroupFieldProps {
    handleFieldOption: any;
    t: any;
    fieldName: string;
    currentValue: FieldOptionsType;
}

const ButtonGroupField = (props: IButtonGroupFieldProps) => {
    const { handleFieldOption, t, fieldName, currentValue } = props;

    const handleClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldOption(event.target.checked ? "show" : "hidden", fieldName);
    };
    return (
        <Switch
            checked={currentValue === "show"}
            onChange={handleClick}
            name="checkedB"
            color="primary"
        />
        // <ButtonGroup
        //     size="small"
        //     color={!requiredRule ? "secondary" : undefined}
        //     aria-label="small outlined button group"
        // >
        //     <Button
        //         variant={variantButtonShow}
        //         onClick={() => handleClick("show", fieldName)}
        //     >
        //         {t("buttonShow")}
        //     </Button>
        //     <Button
        //         variant={variantButtonHidden}
        //         onClick={() => handleClick("hidden", fieldName)}
        //     >
        //         {t("buttonHidden")}
        //     </Button>
        // </ButtonGroup>
    );
};

interface IPropsStageItems {
    listFields: Compleo.IObject[];
    formId: string;
    classError: any;
    t: any;
    tapplicant: any;
    handleEditStage: any;
    handleDeleteStage: any;
    fieldValue: any;
    setFieldValue: any;
}
const Fields = (props: IPropsStageItems) => {
    const {
        listFields,
        formId,
        classError,
        t,
        tapplicant,
        handleEditStage,
        handleDeleteStage,
        fieldValue,
        setFieldValue
    } = props;
    const newList: Compleo.IObject[] = listFields.filter(
        (field: any) => field.formGroupId === formId
    );

    const handleFieldOption = (value: FieldOptionsType, fieldName: string) => {
        const newValue = { ...fieldValue };
        const fields = [...newValue.fields];
        fields.filter(
            (item: any) => item.fieldName === fieldName
        )[0].fieldOptions = value;
        setFieldValue({ ...newValue, fields: fields });
        // currentField.fieldOptions =
    };

    return (
        <>
            {newList.map((field: any, index: number) => {
                // const requiredRule = false;
                // const fieldOptions: FieldOptionsType = requiredRule
                //     ? "required"
                //     : field.fieldOptions || "optional";

                // const variantButtonShow =
                //     fieldOptions === "show" ? "contained" : "outlined";
                // const variantButtonHidden =
                //     fieldOptions === "hidden" ? "contained" : "outlined";
                return (
                    <Draggable
                        key={field.fieldName}
                        draggableId={field.fieldName}
                        index={index}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ListItem
                                    button
                                    alignItems="center"
                                    style={{
                                        // borderStyle: "dotted",
                                        // borderWidth: 1,
                                        margin: 4
                                    }}
                                >
                                    <ListItemIcon>
                                        <Tooltip title="">
                                            <Icon color="action">
                                                drag_indicator
                                            </Icon>
                                        </Tooltip>
                                    </ListItemIcon>

                                    <ListItemText
                                        className={classError}
                                        primary={tapplicant(field.fieldName)}
                                    />
                                    <ListItemSecondaryAction>
                                        <ButtonGroupField
                                            fieldName={field.fieldName}
                                            handleFieldOption={
                                                handleFieldOption
                                            }
                                            currentValue={field.fieldOptions}
                                            // requiredRule={requiredRule}
                                            t={t}
                                            // variantButtonHidden={
                                            //     variantButtonHidden
                                            // }
                                            // variantButtonShow={
                                            //     variantButtonShow
                                            // }
                                        />
                                        {/* <ButtonGroup
                                            size="small"
                                            color="secondary"
                                            aria-label="small outlined button group"
                                        >
                                            <Button
                                                variant={variantButtonRequired}
                                                onClick={() =>
                                                    handleFieldOption(
                                                        "required",
                                                        field.fieldName
                                                    )
                                                }
                                            >
                                                {t("buttonRequired")}
                                            </Button>
                                            <Button
                                                variant={variantButtonOptional}
                                                onClick={() =>
                                                    handleFieldOption(
                                                        "optional",
                                                        field.fieldName
                                                    )
                                                }
                                            >
                                                {t("buttonOptional")}
                                            </Button>
                                            <Button
                                                variant={variantButtonHidden}
                                                onClick={() =>
                                                    handleFieldOption(
                                                        "hidden",
                                                        field.fieldName
                                                    )
                                                }
                                            >
                                                {t("buttonHidden")}
                                            </Button>
                                        </ButtonGroup> */}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </div>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default Fields;
