import { Box, Button } from "@material-ui/core";
import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
interface DataTablePaginationProps<TData> {
    table: Table<TData>;
}

export function DataTablePagination<TData>({
    table
}: DataTablePaginationProps<TData>) {
    const [t, , readyTranslation] = useTranslation("DATATABLE", {
        useSuspense: false
    });
    const currentPage = table.getState().pagination.pageIndex;
    const totalPages = table.getPageCount();
    if (totalPages === 1 || !readyTranslation) {
        return null;
    }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        table.setPageIndex(value - 1);
    };
    const { rows } = table.getPrePaginationRowModel();
    return (
        <Box display={"flex"} p={2}>
            <Box flexGrow={1} />
            <Box alignSelf={"center"} p={0} mr={2}>
                {t("pageNofM", {
                    currentPage: (currentPage + 1).toString(),
                    totalPages: totalPages.toString()
                })}
                {" - "}
                {`Total: ${rows.length}`}
            </Box>
            <Box>
                <Pagination
                    count={table.getPageCount()}
                    page={currentPage + 1}
                    onChange={handleChange}
                    showFirstButton
                    showLastButton
                />
            </Box>
        </Box>
    );
}
