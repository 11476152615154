import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ListItemSecondaryAction } from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

interface IPropsStageItems {
    listSections: Compleo.IObject[];
    classError: any;
    t: any;
    handleEditStage: any;
    handleDeleteStage: any;
}

const StagesItems = (props: IPropsStageItems) => {
    const {
        listSections,
        classError,
        t,
        handleEditStage,
        handleDeleteStage
    } = props;
    const newList = listSections;

    return (
        <>
            {newList.map((section: any, index: number) => {
                const mainListCurrentItem = listSections.findIndex(
                    (item: any) => item.id === section.id
                );
                return (
                    <Draggable
                        key={section.name}
                        draggableId={section.name}
                        index={index}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <ListItem
                                button
                                alignItems="flex-start"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ListItemText
                                    className={classError}
                                    primary={t(section.name)}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title={t("COMPLEOGENERAL_Delete")}>
                                        <IconButton
                                            aria-label={t(
                                                "COMPLEOGENERAL_Delete"
                                            )}
                                            color="secondary"
                                            onClick={() =>
                                                handleDeleteStage(section)
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={t("COMPLEOGENERAL_Edit")}>
                                        <IconButton
                                            aria-label={t(
                                                "COMPLEOGENERAL_Edit"
                                            )}
                                            color="primary"
                                            onClick={() =>
                                                handleEditStage(
                                                    section,
                                                    mainListCurrentItem
                                                )
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default StagesItems;
