import React from "react";
import { IJobReportChildren } from "./reportHelper";
import CompleoMUITable, {
    ICompleoMUITableRowsDef
} from "Components/CompleoMUITable/CompleoMUITable";
import { Alert } from "@material-ui/lab";

const JobActivitiesByStageTable = (props: IJobReportChildren) => {
    const { mainData, t, exportFileName } = props;

    const data = mainData?.activitiesByStage;
    data.sort((a, b) => b.total - a.total);
    const columns = getColumnsMaterialTableData(t);
    const finalData = data.map((item) => {
        return {
            ...item,
            type: t(`ApplicantHistoryType_${item.type}`)
        };
    });

    if (data.length) {
        return (
            <CompleoMUITable
                columns={columns}
                data={finalData}
                t={t}
                // tableContainerStyle={{ maxHeight: "50%" }}
                enableExportToCSV
                exportFileName={exportFileName}
                reportPDFTitle={t("reasonsForRejectionTable_Name")}
            />
        );
    } else {
        return (
            <Alert severity="warning" style={{ margin: 16 }}>
                {t("noRejectedApplicants")}
            </Alert>
        );
    }
};

const getColumnsMaterialTableData: (t: any) => ICompleoMUITableRowsDef[] = (
    t
) => {
    return [
        {
            name: t("type"),
            fieldName: "type"
        },
        {
            name: t("stage"),
            fieldName: "stage"
        },
        {
            name: t("total"),
            fieldName: "total"
        }
    ];
};

export default JobActivitiesByStageTable;
