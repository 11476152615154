import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Typography } from "@material-ui/core";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "./ApplicantEmailRecipientsTags";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    mainData: Compleo.IObject[];
    allRecipients: Compleo.IObject[];
    t: any;
}

const ModalEmailRecipients = (props: IModalNewMail) => {
    const { open, handleClose, mainData, t, allRecipients } = props;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {t("iconRecipients")}
            </DialogTitle>
            <DialogContent>
                <Box marginBottom={2}>
                    <Typography variant="body2" component="span">
                        <strong>{t("recipients")}</strong>
                    </Typography>
                    <ApplicantEmailRecipientsTags
                        recipients={allRecipients as IRecipientsTags[]}
                        t={t}
                        applicantEmail=""
                        withoutColors={true}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="secondary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalEmailRecipients;
