import { Link } from "@gatsbyjs/reach-router";
import { Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { CopyToClipboardEmailParser } from "Components/CopyToClipboard/CopyToClipboardEmailParser";
import { useEmailParser } from "customHooks/useEmailParser";
import { useTranslation } from "react-i18next";

export function MainInstructions() {
    const mailParser = useEmailParser();
    const [t, , readyTranslation] = useTranslation("PARSERINSTRUCTIONS", {
        useSuspense: false
    });

    if (!readyTranslation) return null;
    if (mailParser.isAvailableCompanyPlan) {
        return (
            <Alert severity="info">
                <AlertTitle>
                    <strong>{t("title")}</strong>
                </AlertTitle>
                <Typography variant="body2">{t("description")}</Typography>
                <Typography variant="body2">
                    {t("registerCandidates")}
                </Typography>
                <Typography variant="body2">
                    <ul>
                        {mailParser.isEnabled && (
                            <li>
                                {t("sendEmailWithResume")}{" "}
                                <span>
                                    <CopyToClipboardEmailParser ml={0.7} />
                                </span>
                            </li>
                        )}
                        {mailParser.isEnabled && (
                            <li>{t("sendEmailToSpecificAddress")}</li>
                        )}
                        {!mailParser.isEnabled && (
                            <li>{t("enableEmailSending")}</li>
                        )}
                        <li>
                            {t("uploadResumes")}{" "}
                            <Link to="/applicant/new/upload">
                                {t("uploadPageLinkText")}
                            </Link>
                        </li>
                    </ul>
                </Typography>
                <Typography variant="body2">
                    {t("configureProcessing")}
                </Typography>
            </Alert>
        );
    }

    return null;
}
