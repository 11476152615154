import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import useList, { resultTypeOptionsUseList } from "customHooks/useList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemCardValue: {
            marginLeft: theme.spacing(1)
        },
        gridItem: {
            marginTop: theme.spacing(0.5)
        }
    })
);

const calculateSizes = (
    sizeType: "sm" | "lg" | "xl" | "md",
    resultType: resultTypeOptionsUseList,
    filterIsOpen: boolean
) => {
    if (resultType === "smallGrid") {
        return 12;
    } else {
        switch (sizeType) {
            case "sm":
                return 12;
            case "md":
                if (filterIsOpen) {
                    return 12;
                }
                return 6;
            case "lg":
                return 6;
            case "xl":
                if (filterIsOpen) {
                    return 6;
                }
                return 6;
        }
    }
};

interface IItemCard {
    label?: any;
    children: any;
    dontApplyTypography?: boolean;
    smSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    removeColonAfterLabel?: boolean;
}
const ItemCard = (props: IItemCard) => {
    const classes = useStyles();
    const [listData] = useList();
    const { resultType, filterOpenInfo } = listData.localInfo;
    const smSize = calculateSizes(
        "sm",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const mdSize = calculateSizes(
        "md",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const lgSize = calculateSizes(
        "lg",
        resultType,
        filterOpenInfo.filterIsOpen
    );
    const xlSize = calculateSizes(
        "xl",
        resultType,
        filterOpenInfo.filterIsOpen
    );

    return (
        <Grid
            item
            xs={12}
            sm={props.smSize || smSize}
            md={props.smSize || mdSize}
            lg={props.smSize || lgSize}
            xl={props.smSize || xlSize}
            container
            alignItems="center"
            justify="flex-start"
            className={classes.gridItem}
        >
            <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{
                    fontWeight: 600
                }}
            >
                {props.label}
                {props.removeColonAfterLabel ? "" : ":"}
            </Typography>
            {props.dontApplyTypography ? (
                props.children
            ) : (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.itemCardValue}
                    style={{
                        marginLeft:
                            props.smSize === 12 && !props.label ? 0 : undefined
                    }}
                >
                    {props.children}
                </Typography>
            )}
        </Grid>
    );
};

export default ItemCard;
