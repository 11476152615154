import React from "react";
import { FieldProps } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import { addYears, addDays, isValid } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import {
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import * as formatValues from "functions/formatValues";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    openTo?: "date" | "year" | "month" | "hours" | "minutes" | undefined;
    disablePast?: boolean;
    disableFuture?: boolean;
    invalidDateMessage?: string;
    readOnly?: boolean;
    language: "pt-BR" | "en-US" | "fr" | "es";
    orientation: "portrait" | "landscape";
    timeZone: string; //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    minDateAddDaysFromCurrentDate?: number;
    maxDateAddDaysFromCurrentDate?: number;
    onlyTime?: boolean;
}

const DateTimeField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: {
            dirty,
            touched,
            errors,
            status,
            setFieldValue,
            setFieldError,
            setFieldTouched
        },
        helperTextDefault,
        value,
        openTo,
        disablePast,
        disableFuture,
        invalidDateMessage,
        readOnly,
        language,
        orientation,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone,
        onlyTime = false,
        ...other
    } = props;

    // let { minDate, maxDate } = props;
    const { minDate, maxDate } = getMinMaxDate(
        props.minDate,
        props.maxDate,
        minDateAddDaysFromCurrentDate,
        maxDateAddDaysFromCurrentDate,
        timeZone
    );

    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let errorMessage =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (errorMessage === "") {
            errorMessage += status[field.name];
        } else {
            errorMessage += " - " + status[field.name];
        }
    }

    // const { format, ampm } = formatMap[language];
    const { format, ampm } = formatValues.getFormat(
        language,
        "dayMonthYearTime"
    );
    const locale = formatValues.getFNSLocaleFromString(language);

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            {!onlyTime ? (
                <KeyboardDateTimePicker
                    id={field.name}
                    label={label}
                    error={
                        hasError || (status && status[field.name] !== undefined)
                    }
                    helperText={errorMessage}
                    {...field}
                    {...other}
                    onChange={(val) => {
                        setFieldValue(field.name, val, false);
                    }}
                    onError={(error) => {
                        if (error && error !== errorText) {
                            setFieldError(field.name, String(error));
                        }
                    }}
                    value={field.value}
                    format={format}
                    ampm={ampm}
                    minDate={minDate}
                    maxDate={maxDate}
                    openTo={openTo}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    readOnly={readOnly}
                    orientation={orientation}
                    KeyboardButtonProps={{
                        "aria-label": `change date ${field.name}`
                    }}
                />
            ) : (
                <KeyboardTimePicker
                    autoOk
                    variant="inline"
                    label={label}
                    error={
                        hasError || (status && status[field.name] !== undefined)
                    }
                    helperText={errorMessage}
                    // format={format}
                    ampm={ampm}
                    {...field}
                    // {...other}
                    value={field.value}
                    onChange={(val) => {
                        setFieldValue(field.name, val, false);
                    }}
                    onError={(error) => {
                        if (error && error !== errorText) {
                            setFieldError(field.name, String(error));
                        }
                    }}
                />
            )}
        </MuiPickersUtilsProvider>
    );
};

DateTimeField.defaultProps = {
    openTo: "date",
    invalidDateMessage: "Data inválida",
    locale: "pt-BR",
    orientation: "portrait",
    timeZone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo"
};

function dateToUTC(originalDate: Date | undefined, timeZone: string) {
    let newDate = originalDate;

    if (originalDate) {
        newDate = zonedTimeToUtc(originalDate, timeZone);
    }
    return newDate;
}

export const getMinMaxDate = (
    minDate: Date | undefined,
    maxDate: Date | undefined,
    minDateAddDaysFromCurrentDate: number | undefined,
    maxDateAddDaysFromCurrentDate: number | undefined,
    timeZone: string
) => {
    minDate = dateToUTC(minDate, timeZone);

    if (minDateAddDaysFromCurrentDate && !minDate) {
        minDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            minDateAddDaysFromCurrentDate
        );
    }

    maxDate = dateToUTC(maxDate, timeZone);

    if (maxDateAddDaysFromCurrentDate && !maxDate) {
        maxDate = addDays(
            zonedTimeToUtc(Date.now(), timeZone),
            maxDateAddDaysFromCurrentDate
        );
    }
    return { minDate, maxDate };
};

export default DateTimeField;
