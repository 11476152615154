import React, { useState, useEffect } from "react";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useValuesFromSource from "customHooks/useValuesFromSource";
import * as _ from "lodash";
import * as formatValues from "functions/formatValues";
import { calculateAge, transformDBData } from "functions/util";
import { ApiStateType, useApiCache } from "customHooks/useApi";
import useGetMetadata from "customHooks/useGetMetadata";
import useVariables from "customHooks/useCustomCVVariableList/useVariables";
import useApplicantPhoto from "customHooks/useApplicantPhoto";
import { getApplicantData, getCustomCVData, cleanWordHTML } from "./helper";
import { Grid } from "@material-ui/core";
import Html2Docx from "./html2docx";
import Html2Pdf from "./html2pdf";

interface IProps {
    customCVId: string;
    companyId: number;
    applicantId: string;
    t: any;
    language: string;
}

const CustomCVSimulation = (props: IProps) => {
    const { customCVId, companyId, applicantId, t, language } = props;

    const [applicantData, setApplicantData] = useState<
        Compleo.IObject | undefined
    >(undefined);
    const [customCVData, setCustomCVData] = useState<
        Compleo.IObject | undefined
    >(undefined);
    const [customCVHtml, setCustomCVHtml] = useState<string | undefined>(
        undefined
    );

    const [metadata] = useGetMetadata("APPLICANTVIEW");

    const dataReturn: ApiStateType = {
        status: applicantData ? "success" : "fetching",
        response: {
            data: {
                data: { ...applicantData }
            }
        },
        exception: null
    };

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        false,
        {},
        dataReturn,
        ["data", "data"]
    );

    const [variables, variablesReady] = useVariables(
        companyId,
        language || "pt-BR",
        t
    );

    const [applicantPhoto, applicantPhotoReady] = useApplicantPhoto(
        applicantId,
        companyId || 0
    );

    useEffect(() => {
        if (!companyId) {
            console.log(
                "CustomCVSimulation: getcustomcv without companyId",
                companyId
            );
        }
        setCustomCVData(undefined);
        setCustomCVHtml(undefined);
        getCustomCVData(customCVId, companyId).then((data: any) => {
            if (data.name) setCustomCVData(data);
        });
    }, [customCVId, companyId]);

    useEffect(() => {
        getApplicantData(applicantId, companyId).then((data: any) => {
            if (data.name) setApplicantData(data);
        });
    }, [applicantId, companyId]);

    useEffect(() => {
        if (
            customCVData &&
            applicantData &&
            variablesReady &&
            applicantPhotoReady
        ) {
            const mainVariableList = variables["applicantMain"];
            const professionalHistoryVariableList =
                variables["applicantProfessionalHistory"];
            const educationHistoryVariableList =
                variables["applicantEducationHistory"];
            const languageVariableList = variables["applicantLanguages"];

            const t0 = performance.now();

            const finalHTML = getFinalHTML(
                customCVData,
                applicantPhoto
                    ? { ...applicantData, photoBase64: applicantPhoto }
                    : applicantData,
                t,
                language,
                valuesFromSource,
                mainVariableList,
                professionalHistoryVariableList,
                educationHistoryVariableList,
                languageVariableList
            );
            const t1 = performance.now();
            console.log(
                "Time - finalHTML: " + (t1 - t0) + " milliseconds.",
                customCVData.name
            );
            setCustomCVHtml(finalHTML);
        }
    }, [
        applicantData,
        customCVData,
        variablesReady,
        variables,
        language,
        t,
        valuesFromSource,
        applicantPhotoReady,
        applicantPhoto
    ]);

    if (customCVHtml) {
        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Html2Pdf
                        htmlToConvert={customCVHtml}
                        margins={
                            customCVData
                                ? {
                                      top: `${customCVData.marginTop ?? 30}px`,
                                      right: `${
                                          customCVData.marginRight ?? 20
                                      }px`,
                                      bottom: `${
                                          customCVData.marginBottom ?? 30
                                      }px`,
                                      left: `${customCVData.marginLeft ?? 20}px`
                                  }
                                : undefined
                        }
                    />
                </Grid>
                <Grid item style={{ alignSelf: "end", width: "100%" }}>
                    <Html2Docx
                        htmlToConvert={getFullHTML(customCVHtml, {
                            top: customCVData?.marginTop ?? 30,
                            right: customCVData?.marginRight ?? 20,
                            bottom: customCVData?.marginBottom ?? 30,
                            left: customCVData?.marginLeft ?? 20
                        })}
                        t={t}
                    />
                </Grid>
            </Grid>
        );
    }

    return <Loading />;
};

const getFinalHTML = (
    customCVData: Compleo.IObject,
    applicantData: Compleo.IObject,
    t: any,
    language: string,
    valuesFromSource: any,
    mainVariableList: Compleo.IObject[],
    professionalHistoryVariableList: Compleo.IObject[],
    educationHistoryVariableList: Compleo.IObject[],
    languageVariableList: Compleo.IObject[]
): string => {
    const professionalHistoryVariables = getStringsInsideSquareBrackets(
        customCVData.professionalHistoryBody
    );
    const professionalHistoryVariablesValues = getVariablesValues(
        professionalHistoryVariables,
        { ...valuesFromSource, ...applicantData },
        true,
        professionalHistoryVariableList,
        "professionalHistory"
    );

    let professionalHistoryHTML = "";
    for (const professionalItem of professionalHistoryVariablesValues) {
        const professionalItemHTML = updateHTMLWithVariablesValues(
            customCVData.professionalHistoryBody,
            professionalItem,
            t,
            language,
            professionalHistoryVariableList
        );
        professionalHistoryHTML += `<p>${professionalItemHTML}</p>`;
    }

    const educationHistoryVariables = getStringsInsideSquareBrackets(
        customCVData.educationHistoryBody
    );
    const educationHistoryVariablesValues = getVariablesValues(
        educationHistoryVariables,
        { ...valuesFromSource, ...applicantData },
        true,
        educationHistoryVariableList,
        "educationHistory"
    );
    let educationHistoryHTML = "";
    for (const educationItem of educationHistoryVariablesValues) {
        const educationItemHTML = updateHTMLWithVariablesValues(
            customCVData.educationHistoryBody,
            educationItem,
            t,
            language,
            educationHistoryVariableList
        );
        educationHistoryHTML += `<p>${educationItemHTML}</p>`;
    }

    const languagesVariables = getStringsInsideSquareBrackets(
        customCVData.languagesBody
    );
    const languagesVariablesValues = getVariablesValues(
        languagesVariables,
        { ...valuesFromSource, ...applicantData },
        true,
        languageVariableList,
        "languages"
    );
    let languagesHTML = "";
    for (const languageItem of languagesVariablesValues) {
        const languageItemHTML = updateHTMLWithVariablesValues(
            customCVData.languagesBody,
            languageItem,
            t,
            language,
            languageVariableList
        );
        languagesHTML += `<p>${languageItemHTML}</p>`;
    }

    const mainBodyVariables = getStringsInsideSquareBrackets(
        customCVData.mainBody
    );

    const mainBodyVariablesValues = getVariablesValues(
        mainBodyVariables,
        {
            ...valuesFromSource,
            ...applicantData,
            languages: languagesHTML,
            educationHistory: educationHistoryHTML,
            professionalHistory: professionalHistoryHTML
        },
        false,
        mainVariableList
    );

    const finalUpdatedHTML = updateHTMLWithVariablesValues(
        customCVData.mainBody,
        mainBodyVariablesValues[0],
        t,
        language,
        mainVariableList
    );
    const finalHTML = customCVData.removeStylesFromRichTextFields
        ? cleanWordHTML(finalUpdatedHTML)
        : finalUpdatedHTML;

    const fullHTML = getFullHTML(finalHTML);

    return fullHTML;
};

const getFullHTML = (
    body: string,
    margins?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    }
): string => {
    const marginStyle = margins
        ? `margin: ${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`
        : "";
    const fullHTML = marginStyle
        ? `<!DOCTYPE html><html><body style="max-width:960px;${marginStyle}">${body}</body></html>`
        : `<!DOCTYPE html><html><body style="max-width:960px">${body}</body></html>`;
    return fullHTML;
};

const updateHTMLWithVariablesValues = (
    originalHTML: string,
    variablesValues: Compleo.IObject,
    t: any,
    language: string,
    fullVariableList: Compleo.IObject[]
) => {
    let finalHTML = originalHTML;
    for (const variableValueKey of Object.keys(variablesValues)) {
        const key = `[${variableValueKey}]`;

        const originalValue = variablesValues[variableValueKey];

        const variableType = getVariableType(
            variableValueKey,
            fullVariableList
        );
        const variableValue =
            variableType === "LABEL"
                ? fullVariableList.find(
                      (variableItem: any) =>
                          variableItem.label === variableValueKey
                  )?.value || ""
                : variableValueKey;

        let value = transformDBData({
            value: originalValue,
            t,
            language,
            replaceOption: "-",
            customType:
                variableValue === "mobileNumber"
                    ? "phone"
                    : variableValue === "photo"
                    ? "photo"
                    : undefined, // fieldItem.customType,
            customDateFormat: "dateRelative"
        });

        if (typeof originalValue === "object") {
            if (originalValue?.value !== undefined) {
                if (originalValue?.currency !== undefined) {
                    value = formatValues.maskCurrencyToString(
                        originalValue.value,
                        language,
                        originalValue.currency || "BRL"
                    );
                }
            }
        }

        if (typeof value === "object") {
            if (typeof originalValue === "number") {
                value = originalValue;
            } else if (
                Array.isArray(value) &&
                value.every((item) => typeof item === "string")
            ) {
                let newValue = value.join(", ");
                // for (const stringItem of value) {
                //     newValue += `<p>${stringItem}</p>`;
                // }
                value = newValue;
            } else {
                value = "-";
            }
        }

        if (typeof value === "object") {
            value = "-";
        }

        finalHTML = finalHTML.split(key).join(value || "");
    }
    return finalHTML;
};

const getStringsInsideSquareBrackets = (originalString: string): string[] => {
    return (
        originalString
            .match(/\[.*?\]/g)
            ?.map((value: string) =>
                value.substring(1, value.lastIndexOf("]"))
            ) || []
    );
};

const getVariableType = (
    variable: string,
    fullVariableList: Compleo.IObject[]
) => {
    if (
        fullVariableList.find(
            (variableItem: Compleo.IObject) => variableItem.label === variable
        )
    )
        return "LABEL";
    return "VALUE";
};

const getFinalVariableValue = (
    data: Compleo.IObject,
    variable: string,
    fullVariableList: Compleo.IObject[],
    isArray: boolean
) => {
    const variableType = getVariableType(variable, fullVariableList);

    const variableName =
        variableType === "LABEL"
            ? fullVariableList.find(
                  (variableItem: Compleo.IObject) =>
                      variableItem.label === variable
              )?.value || ""
            : variable;

    return getFieldValue(data, variableName, isArray);
};

const getFieldValue = (
    data: Compleo.IObject,
    variable: string,
    isArray: boolean
) => {
    const COMPLEXFIELD_PREFIX = "COMPLEXFIELD:";
    const COMPLEXFIELD_PREFIX_LENGTH = COMPLEXFIELD_PREFIX.length;

    if (variable === "aged" && data["dateOfBirth"]) {
        return calculateAge(data["dateOfBirth"]);
    }

    const variablePath = variable.startsWith(COMPLEXFIELD_PREFIX)
        ? variable.slice(COMPLEXFIELD_PREFIX_LENGTH)
        : variable;

    const fieldValue = isArray
        ? _.get(
              data,
              (variablePath.split("_").length === 1
                  ? variablePath.split("_")[1]
                  : variablePath.substring(variablePath.indexOf("_") + 1)) || ""
          )
        : _.get(data, variablePath);

    if (
        fieldValue &&
        (variablePath === "professionalHistory_startDate" ||
            variablePath === "professionalHistory_endDate" ||
            variablePath === "educationHistory_startDate" ||
            variablePath === "educationHistory_endDate")
    ) {
        return formatValues.formatDate(fieldValue, "dateRelative", "monthYear");
    }

    console.log(`variable ${variable} - fieldValue`, fieldValue);

    return fieldValue;
};

const getVariablesValues = (
    variables: string[],
    applicantData: Compleo.IObject,
    isArray = false,
    fullVariableList: Compleo.IObject[],
    arrayField?: string
): Compleo.IObject[] => {
    const valueList: Compleo.IObject[] = [];

    if (isArray && arrayField) {
        const applicantField = applicantData[arrayField];

        if (applicantField && applicantField.length > 0) {
            for (const item of applicantField) {
                const values: Compleo.IObject = {};
                for (const variable of variables) {
                    values[variable] = getFinalVariableValue(
                        item,
                        variable,
                        fullVariableList,
                        true
                    );
                }
                valueList.push(values);
            }
        }
    } else {
        const values: Compleo.IObject = {};
        for (const variable of variables) {
            values[variable] = getFinalVariableValue(
                applicantData,
                variable === "photo" && applicantData.photoBase64
                    ? "photoBase64"
                    : variable,
                fullVariableList,
                false
            );
        }
        valueList.push(values);
    }

    console.log("getVariablesValues valueList", valueList);

    return valueList;
};

export default CustomCVSimulation;
