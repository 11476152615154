import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import useList, { resultTypeOptionsUseList } from "customHooks/useList";
import { IItemView } from "./ItemView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemCardValue: {
            marginLeft: theme.spacing(1)
        },
        gridItem: {
            marginTop: theme.spacing(0.5)
        }
    })
);

const ItemView = (props: IItemView) => {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={props.fieldDef?.columnSize?.xs || 12}
            sm={props.fieldDef?.columnSize?.sm}
            md={props.fieldDef?.columnSize?.md}
            lg={props.fieldDef?.columnSize?.lg}
            container
            alignItems="center"
            justify="flex-start"
            className={classes.gridItem}
        >
            <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{
                    fontWeight: 600
                }}
            >
                {props.label}
                {props.removeColonAfterLabel ? "" : ":"}
            </Typography>
            <br />
            <ItemTypography {...props} />
        </Grid>
    );
};

const ItemTypography = (props: IItemView) => {
    const classes = useStyles();

    if (typeof props.children === "string") {
        return (
            <>
                {props.dontApplyTypography ? (
                    props.children
                ) : (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        className={classes.itemCardValue}
                        dangerouslySetInnerHTML={{ __html: props.children }}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                {props.dontApplyTypography ? (
                    props.children
                ) : (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        className={classes.itemCardValue}
                    >
                        {props.children}
                    </Typography>
                )}
            </>
        );
    }
};

export default ItemView;
