import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import { green, red } from "@material-ui/core/colors";

import { IJobBoardsShareStatusMessages } from "./JobBoards/JobBoardsStatusModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2)
        }
    })
);

function createMarkup(value: string) {
    return { __html: value };
}

interface IJobBoardsStatusMessagesProps {
    messageList: IJobBoardsShareStatusMessages[];
}
const JobBoardsShareStatusMessages = (props: IJobBoardsStatusMessagesProps) => {
    const classes = useStyles();
    const { messageList } = props;

    return (
        <div className={classes.root}>
            {messageList.map((message, index) => (
                <List
                    key={message.message}
                    // component="nav"
                    aria-label="main mailbox folders"
                    dense
                >
                    <ListItem>
                        <ListItemIcon>
                            {message.type === "success" ? (
                                <DoneIcon style={{ color: green[500] }} />
                            ) : (
                                <ErrorIcon style={{ color: red[500] }} />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        message.message
                                    )}
                                />
                            }
                        />
                    </ListItem>
                    <Divider />
                </List>
            ))}
        </div>
    );
};

export default JobBoardsShareStatusMessages;
