import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { handleChangeBucketsFilter } from "./ListUtil";
import useList from "..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            padding: theme.spacing(0.1),
            margin: theme.spacing(0.1),
            paddingLeft: theme.spacing(4)
        },
        listItem: {
            minWidth: theme.spacing(0.1),
            padding: 0,
            margin: 0
        },
        checkBox: {
            padding: theme.spacing(0.2),
            paddingRight: theme.spacing(0.4)
            // margin: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    bucket: Compleo.IObject;
    itemName: string;
}

const FilterListItem = React.memo((props: IPropsItemFilter) => {
    const classes = useStyles();
    const [listData, listDispatch] = useList();
    const { filters } = listData.filtersInfo;
    const { setFilters } = listDispatch;

    const { bucket, itemName } = props;
    const field = (filters.buckets || {})[itemName] || {};

    const currentValue =
        (field.values || []).filter((f: any) => f.value === bucket.value)
            .length > 0;

    const handleChange = () => {
        const newValue = !currentValue;
        handleChangeBucketsFilter(
            newValue,
            itemName,
            filters,
            setFilters,
            bucket
        );
    };

    return (
        <ListItem
            className={classes.nested}
            style={{ width: "98%" }}
            button
            onClick={handleChange}
        >
            <ListItemIcon className={classes.listItem}>
                <Checkbox
                    edge="start"
                    checked={currentValue}
                    tabIndex={-1}
                    disableRipple
                    name={bucket.value}
                    onChange={handleChange}
                    className={classes.checkBox}
                    size="small"
                />
            </ListItemIcon>
            <ListItemText
                className={classes.listItem}
                secondary={getStringBucket(bucket)}
            />
        </ListItem>
    );
});

const getStringBucket = (
    bucket: any,
    showOption: "onlyTotal" | "onlyFiltered" | "TotalAndFiltered" = "onlyTotal"
) => {
    switch (showOption) {
        case "onlyTotal":
            return `${bucket.label} - (${bucket.total})`;
        case "onlyFiltered":
            return `${bucket.label} - (${bucket.filtered})`;
        case "TotalAndFiltered":
            return `${bucket.label} - (${bucket.filtered || 0} of ${
                bucket.total
            })`;
    }
};
export default FilterListItem;
