import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import useJobContext from "../../useJobContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Paper,
    Tooltip,
    Typography
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { IKanbanCardFieldState } from "../JobKanbanCardFields/SettingsModalCardKanbanFields";
import { getKanbanCardHeight } from "../helper";

const useStyles = (cardHeight: number, cardContentHeight: number) => {
    return makeStyles((theme: Theme) => {
        const card = {
            padding: theme.spacing(0.5),
            minWidth: 250,
            height: cardHeight,
            margin: theme.spacing(1)
        };
        return createStyles({
            card: {
                ...card,
                boxShadow: "0 3px 6px 0 rgba(138, 148, 159, 0.2)"
            },
            cardRed: {
                ...card,
                boxShadow: "0 3px 6px 0 red"
            },
            cardMoving: {
                ...card,
                boxShadow: "0 3px 6px 0 blue"
                // transform: "rotate(45deg) translateX(180px)"
            },
            cardHeader: {
                padding: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.1)
            },
            avatar: {
                //   backgroundColor: red[500],
            },
            heading: {
                display: "inline-block",
                margin: 0,
                padding: 0,
                width: 180,
                marginTop: theme.spacing(0.5),
                fontWeight: 600
            },
            items: {
                width: 240
            },
            cardContent: {
                height: cardContentHeight,
                padding: theme.spacing(0.1),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                paddingLeft: theme.spacing(0.5)
            },
            actionsIcon: {
                padding: theme.spacing(0.5)
            }
        });
    });
};

interface IProps {
    index: number;
    CardDef: IKanbanCardFieldState;
}

const JobKanbanApplicantLoading = (props: IProps) => {
    const { index, CardDef } = props;
    const cardHeight = getKanbanCardHeight(CardDef);
    const totalFields = CardDef.fieldsDefinition.filter(
        (i) => i.local === "primary"
    ).length;

    const classes = useStyles(
        cardHeight.cardHeight,
        cardHeight.cardContentHeight
    )();

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Skeleton
                        animation="wave"
                        variant="circle"
                        width={40}
                        height={40}
                    />
                }
                title={
                    <Typography className={classes.heading} noWrap>
                        <Skeleton animation="wave" />
                    </Typography>
                }
            />
            <CardContent className={classes.cardContent}>
                {Array.from({ length: totalFields }, (item, index) => (
                    <Typography key={index} className={classes.items} noWrap>
                        <Skeleton animation="wave" />
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );
    // return (
    //     <Card className={classes.card} elevation={2}>
    //         <Skeleton
    //             animation="wave"
    //             variant="circle"
    //             width={40}
    //             height={40}
    //         />
    //         <Box>
    //             <h3 className={classes.heading}>
    //                 <Skeleton animation="wave" />
    //             </h3>
    //             <p className={classes.subheader}>
    //                 <Skeleton animation="wave" />
    //             </p>
    //         </Box>
    //     </Card>
    // );
};

export default JobKanbanApplicantLoading;
