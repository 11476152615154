import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList from "../..";
import SettingsModalList from "./SettingsModalFastFiltersList";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
}

const SettingsModalFastFilters = (props: IPropsItemFilter) => {
    const { onClose, open } = props;
    const [globalValues, globalValuesDispatch] = useGlobalValues();

    const [listData, listDispatch] = useList();
    const [bucketsDef, setBucketsDef] = React.useState<Compleo.IObject[]>([
        ...listData.definitionInfo.bucketsDef
    ]);

    React.useEffect(() => {
        setBucketsDef([...listData.definitionInfo.bucketsDef]);
    }, [listData.definitionInfo.bucketsDef]);

    const { t } = listData.tempInfo;

    const handleOk = () => {
        onClose();
    };

    const handleSave = () => {
        globalValuesDispatch.addOrEditGlobalValue({
            name: `bucketsOrder_${listData.tempInfo.moduleName}`,
            type: "userdb",
            value: bucketsDef
        });
        listDispatch.setBucketsDef(bucketsDef);
        onClose();
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={t(
                "ELASTICLISTSETTINGS:MainMenu_fastFilterSettings"
            )}
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">
                {t("ELASTICLISTSETTINGS:MainMenu_fastFilterSettings")}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">
                    {t("ELASTICLISTSETTINGS:FastFilterSettingsInstructions")}
                </Typography>
                <SettingsModalList
                    bucketsDef={bucketsDef}
                    setBucketsDef={setBucketsDef}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsModalFastFilters;
