import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

import AutoComplete from "./util/MaterialFields/AutoComplete";
import { clearArrayInfo } from "functions/util";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { FormHelperText, Grid, Typography } from "@material-ui/core";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    IInputProps,
    useRhfFieldControlled,
    useRhfFieldOnlyErrorsOrMessages
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const localeMap: Compleo.IObject = {
    "pt-BR": ptBR,
    "en-US": enUS,
    fr: fr,
    es: es
};

interface IProps {
    options: Compleo.IObject[];
    language: string;
}

const DelayAction = (props: IProps & IInputProps) => {
    const {
        metadata,
        label,
        helperTextDefault,
        t,
        required,
        options,
        language
    } = props;
    // const [field, meta, helpers] = useField(metadata.fieldName);
    const { field, setFieldValue, watch } = useRhfFieldControlled(
        metadata.fieldName,
        helperTextDefault
    );
    const mainFieldValue = watch(metadata.fieldName);

    const childrenKeys = Object.keys(mainFieldValue || {}).map(
        (keyName) => `${metadata.fieldName}.${keyName}`
    );
    const { error, message } = useRhfFieldOnlyErrorsOrMessages(
        [metadata.fieldName, ...childrenKeys],
        helperTextDefault
    );

    const dropDownFieldName = `${field.name}.delayDetail`;
    const {
        field: fieldDelay,
        setFieldValue: setFieldDelayValue
    } = useRhfFieldControlled(dropDownFieldName);

    const fieldDelayValue = watch(dropDownFieldName);
    // const fieldDelayDetail = watch(dropDownFieldName);

    const disableTime = (fieldDelayValue || {}).disableTime;
    const excludeTimeControl = disableTime === undefined ? true : disableTime;
    const { timeZone } = useAuthState();

    const dateDefault = new Date();
    dateDefault.setHours(9);
    dateDefault.setMinutes(0);

    const handleAutocompleteChange = (value: any) => {
        if ((value || {}).disableTime) {
            setFieldValue({
                delayDetail: value,
                time: null,
                timeZone: currentTimeZone
            });
        } else {
            const currentValue = mainFieldValue || {};
            if (currentValue.time === undefined || currentValue.time === null) {
                currentValue.time = dateDefault;
            }
            currentValue.timeZone = currentTimeZone;
            setFieldDelayValue(value);
        }
    };
    const currentTimeZone =
        timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "America/Sao_Paulo";

    const handleTimeChange = (value: any) => {
        const currentValue = mainFieldValue;
        const newTime = value || dateDefault;
        setFieldValue({
            ...currentValue,
            time: newTime,
            timeZone: currentTimeZone
        });
    };

    React.useEffect(() => {
        const currentValue = fieldDelayValue;
        if (currentValue === undefined || currentValue === null) {
            const defaultValue = getDefaultAutoCompleteValue(
                currentValue,
                options
            );
            setFieldValue({ ...mainFieldValue, delayDetail: defaultValue });
        }
    }, []);

    // const AutoCompleteValue = (mainFieldValue || {}).delayDetail;
    const TimeValue = (mainFieldValue || {}).time;
    let controlValue: any = null;

    if (fieldDelayValue) {
        if (!fieldDelayValue?.label) {
            const newValue = options.filter(
                (item: any) => item.value === fieldDelayValue?.value
            );
            if (newValue.length > 0) {
                controlValue = newValue[0];
                if (controlValue.label === undefined) {
                    controlValue.label = newValue[0][`label-${language}`];
                }
            } else {
                controlValue = fieldDelayValue;
            }
        } else {
            controlValue = fieldDelayValue;
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <AutoComplete
                        options={options}
                        value={controlValue}
                        setValue={handleAutocompleteChange}
                        defaultProps={{ ...fieldDelay }}
                        label={label || ""}
                        helperText={message || ""}
                        name={fieldDelay.name}
                        labelProperty="label"
                        error={error}
                        required={required === true}
                        onBlur={fieldDelay.onBlur}
                        inputRef={fieldDelay.ref}
                    />
                </Grid>
                {!excludeTimeControl ? (
                    <>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider
                                locale={localeMap[language]}
                                utils={DateFnsUtils}
                            >
                                <KeyboardTimePicker
                                    style={{ marginLeft: "8px" }}
                                    autoOk
                                    ampm={false}
                                    variant="inline"
                                    label={t(
                                        `${clearArrayInfo(
                                            metadata.fieldName
                                        )}Time`
                                    )}
                                    value={TimeValue}
                                    onChange={handleTimeChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container item xs={2} alignItems="center">
                            <Typography
                                style={{ marginLeft: "8px" }}
                                variant="caption"
                            >
                                Timezone: {currentTimeZone}
                            </Typography>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
};

const getDefaultAutoCompleteValue = (
    value: any,
    optionsValue: Compleo.IObject[]
) => {
    if (value === null || value === undefined) {
        const defaultValue = optionsValue.filter(
            (o: any) => o.default === true
        )[0];
        if (defaultValue !== undefined) {
            return defaultValue;
        } else {
            return value;
        }
    } else {
        return value;
    }
};

export default DelayAction;
