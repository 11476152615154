import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import Page from "Pages/_Layouts/DefaultInternal";
import { Link, navigate } from "@gatsbyjs/reach-router";
import useGetMetadata from "customHooks/useGetMetadata";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import { useApiCache } from "customHooks/useApi";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { green, pink } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";
import queryString from "query-string";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: "#fff",
            backgroundColor: green[500]
        }
    })
);

const SearchMain = (props: any) => {
    console.log("props", props);
    const moduleName = "SEARCHMAIN";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { company } = useAuthState();
    const queryStringParsed = queryString.parse(window.location.search);

    const currentPage = props.page ? Number(props.page) : 1;

    const [metadata] = useGetMetadata("SEARCHMAIN");

    const postAddressSearch = `/search/mainsearch`;
    const pageSize = 10;

    const fromValue = currentPage * pageSize - pageSize;

    const { isFeatureAuthorized } = useAuthorization();
    const scope: string[] = [];
    if (isFeatureAuthorized("ViewJob")) {
        scope.push("jobs");
    }
    if (isFeatureAuthorized("ViewApplicant")) {
        scope.push("applicants");
    }
    const exact = queryStringParsed?.exact === "true";
    const postValues = {
        terms: props.terms,
        companyId: company.companyId,
        fromValue: fromValue,
        scope: scope,
        exact: exact
    };
    const [getSearchResults, executeSearch] = useApiCache(
        postAddressSearch,
        "post",
        postValues,
        false
    );

    React.useEffect(() => {
        executeSearch(postValues);
    }, [currentPage, props.terms, exact]);

    const ready =
        readyTranslation &&
        metadata.status === "success" &&
        getSearchResults.status === "success";

    const data: Compleo.IObject[] =
        getSearchResults?.response?.data?.data || [];
    const total = getSearchResults?.response?.data?.total;

    const totalPages = Math.ceil((total || -1) / pageSize);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        navigate(`/search/${props.terms}/${value}?exact=${exact.toString()}`);
    };

    if (ready) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                <Box display="flex" flexDirection="column" width="100%">
                    <Box>
                        <Typography>
                            {`${t("searchTerm")}: `}
                            <strong>{props.terms}</strong>
                        </Typography>
                        <Typography>{`${t("results")}: ${total}`}</Typography>
                    </Box>
                    {data.length > 0 ? (
                        <Box>
                            <Paper style={{ width: "100%" }}>
                                <Box mt={2}>
                                    <List>
                                        {data.map((i) => (
                                            <ListItemResult
                                                key={i.pk}
                                                data={i}
                                                t={t}
                                            />
                                        ))}
                                        <ListItem>
                                            <Pagination
                                                count={totalPages}
                                                showFirstButton
                                                showLastButton
                                                page={currentPage}
                                                onChange={handleChange}
                                                // defaultPage={15}
                                                siblingCount={3}
                                                boundaryCount={2}
                                            />
                                        </ListItem>
                                    </List>
                                </Box>
                            </Paper>
                        </Box>
                    ) : null}
                </Box>
            </Page>
        );
    } else {
        return (
            <Page
                // featureName={t("a_FeatureName")}
                // featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                <Loading />
            </Page>
        );
    }
};

function createMarkup(value?: string) {
    return { __html: value || "" };
}

interface IListItemResultProps {
    data: any;
    t: any;
}
const ListItemResult = (props: IListItemResultProps) => {
    const { data, t } = props;
    const resultText = getResultText(data, t);
    const classes = useStyles();

    const goTo = () => {
        if (resultText.resultType === "applicant") {
            navigate(`/applicant/view/${resultText.key}`);
        } else {
            navigate(`/job/view/${resultText.key}`);
        }
    };
    return (
        <>
            <ListItem button>
                {resultText.resultType === "applicant" ? (
                    <ListItemAvatar>
                        <CompleoAvatar
                            idParams={{ id: resultText.key }}
                            type="applicant"
                            // className={classes.avatarsmall}
                        />
                    </ListItemAvatar>
                ) : (
                    <ListItemAvatar>
                        <Avatar className={classes.green}>
                            <WorkIcon />
                        </Avatar>
                    </ListItemAvatar>
                )}
                <ListItemText
                    onClick={goTo}
                    primary={`${resultText.mainText} - (${t(
                        resultText.resultType
                    )})`}
                    secondary={
                        <span
                            dangerouslySetInnerHTML={createMarkup(
                                resultText.secondaryText
                            )}
                        />
                    }
                />
            </ListItem>
            <Divider component="li" />
        </>
    );
};

const getResultText = (data: Compleo.IObject, t: any) => {
    const resultType = getResultType(data.index);
    let mainText = ``;
    const highlightsText = getHighlights(data.highlight, t);
    let secondaryText = highlightsText
        ? `<strong>${t(
              "resultsFoundText"
          )}</strong><br /><br />${highlightsText}`
        : "";
    let key = ``;

    switch (data.index) {
        case "applicant":
            mainText = data?.data?.name;
            key = data?.data?.pk.split(":")[1];
            break;
        case "applicanthistory":
            mainText = data?.data?.applicantDetail?.name;
            key = data?.data?.applicantDetail?.pk.split(":")[1];
            key = data?.data?.pk.split(":")[1];
            break;
        case "emailthread":
        case "schedule":
            mainText = data?.data?.applicantDetail?.name;
            key = data?.data?.applicantId;
            break;
        case "job":
            mainText = data?.data?.title;
            key = data?.data?.pk.split(":")[1];
            break;
        default:
            break;
    }

    return { resultType, mainText, secondaryText, key };
};

const getHighlights = (highlights: Compleo.IObject, t: any) => {
    if (highlights) {
        let htmlReturn = ``;
        Object.keys(highlights).map((fieldname) => {
            htmlReturn =
                htmlReturn +
                `<strong>${t(`field_${fieldname}`)}</strong>: ${highlights[
                    fieldname
                ].join(" <br />")} <br />`;
        });
        return htmlReturn;
    } else {
        return "";
    }
};

const getResultType = (indexName: string) => {
    switch (indexName) {
        case "job":
            return "job";
        default:
            return "applicant";
    }
};

export default SearchMain;
