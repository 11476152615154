import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FilterListItemModal from "./FilterListItemModal";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { matchSorter } from "match-sorter";
import useList from "..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    aggregation: Compleo.IObject;
    open: boolean;
    title: string;
    onClose: (value?: string) => void;
    t: any;
}

const FilterListModal = (props: IPropsItemFilter) => {
    const { onClose, open, aggregation, title, t, ...other } = props;
    const classes = useStyles();
    const [textSearch, setTextSearch] = React.useState("");

    const handleOk = () => {
        onClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };

    const filteredArray: Compleo.IObject[] = textSearch
        ? matchSorter(aggregation?.items, textSearch, {
              keys: ["label"]
          })
        : aggregation?.items;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby={title}
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    className={classes.margin}
                    placeholder={t(
                        "ELASTICLISTSETTINGS:searchBucketPlaceholder"
                    )}
                    fullWidth
                    value={textSearch}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <List
                    component="div"
                    disablePadding
                    style={{ position: "relative" }}
                >
                    <FilterListItemModal
                        itemName={aggregation.key}
                        allBuckets={filteredArray.filter(
                            (item: any) => item.total > 0
                        )}
                    />
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {t("ELASTICLISTSETTINGS:closeModalBucketFilter")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterListModal;
