import React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, createStyles, Grid, Typography } from "@material-ui/core";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        minWidth: 300,
        maxWidth: 450,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}))(Tooltip);

const CalendarCustomEvent = (props: Compleo.IObject) => {
    const { event } = props;
    const classes = useStyles();
    const moduleName = "CALENDAR";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const titleToolTip = (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" marginBottom={2}>
                <Box marginRight={1}>
                    <CompleoAvatar
                        idParams={{
                            email: event.user
                        }}
                        type="user"
                        className={classes.avatarsmall}
                    />
                </Box>
                <Box>
                    <Typography variant="body2" component="span">
                        <strong>{event.title}</strong>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column">
                <Box marginBottom={0.5}>
                    <Typography variant="body2" component="span">
                        <strong>{t("organizer")}</strong>
                        {": "}
                        {event.organizer}
                    </Typography>
                </Box>
                <Box marginBottom={0.5}>
                    <Typography variant="body2" component="span">
                        <strong>{t("participants")}</strong>
                        {": "}
                        {event.participants}
                    </Typography>
                </Box>
                <Box marginBottom={0.5}>
                    <Typography variant="body2" component="span">
                        <strong>{t("location")}</strong>
                        {": "}
                        {event.location}
                    </Typography>
                </Box>
                {event.applicantName && (
                    <Box marginBottom={0.5}>
                        <Typography variant="body2" component="span">
                            <strong>{t("applicantRecipient")}</strong>
                            {": "}
                            {event.applicantName}
                        </Typography>
                    </Box>
                )}
                {event.jobTitle && (
                    <Box marginBottom={0.5}>
                        <Typography variant="body2" component="span">
                            <strong>{t("job")}</strong>
                            {": "}
                            {event.jobTitle}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
    if (readyTranslation) {
        return (
            <HtmlTooltip title={titleToolTip}>
                <Box display="flex" flexDirection="horizontal">
                    <Box marginRight={0.5}>
                        <CompleoAvatar
                            idParams={{
                                email: event.user
                            }}
                            type="user"
                            className={classes.avatarsmall}
                        />
                    </Box>
                    <Box>
                        <strong>{event.title}</strong>
                        {event.location && " - " + event.location}
                    </Box>
                </Box>
            </HtmlTooltip>
        );
    } else {
        return <Loading />;
    }
};

export default CalendarCustomEvent;
