import React from "react";
import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import ApplicationFormModelChooseDialog from "./ApplicationFormModelChooseDialog";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IProps {
    dialogChooseApplicationFormTemplateOpen: boolean;
    setDialogChooseApplicationFormTemplateOpen: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                marginTop: theme.spacing(2)
            }
        },
        grid: {
            marginTop: theme.spacing(3)
        }
    })
);

const ApplicationForm = (props: IProps & IInputProps) => {
    // const [dialogOpen, setDialogOpen] = React.useState(false);
    const classes = useStyles();
    const {
        t,
        dialogChooseApplicationFormTemplateOpen,
        setDialogChooseApplicationFormTemplateOpen
    } = props;
    const { getFieldValue } = useRhfFieldWithoutControllerOrRegister(
        "applicationForm"
    );
    const fieldValue = getFieldValue();

    return (
        <Grid container className={classes.grid}>
            <Grid item xs={12}>
                <Typography variant="caption" display="block" color="primary">
                    {t("applicationFormTemplate")}
                </Typography>
                <Typography variant="body1" display="block">
                    {(fieldValue || {}).name}
                </Typography>
            </Grid>
            <ApplicationFormModelChooseDialog
                open={dialogChooseApplicationFormTemplateOpen}
                setOpen={setDialogChooseApplicationFormTemplateOpen}
                t={t}
            />
        </Grid>
    );
};

export default ApplicationForm;
