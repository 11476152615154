import React, { Component, useState } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";

/* 
    Esta função carrega o dropdown "Default Language" de acordo com os idiomas 
    selecionados. Também limpa o item caso tenha sido apagado da lista de idiomas
*/
const handleLoadDefaultLanguageField = (
    formik: any,
    customLists: Compleo.CustomLists.ListDefinitionType[],
    setCustomLists: React.Dispatch<
        React.SetStateAction<Compleo.CustomLists.ListDefinitionType[]>
    >,
    currentLanguage: string,
    defaultLanguageFieldName: string,
    languagesFieldName: string
) => {
    const values = formik?.values || {};
    const newCustomLists = [...customLists];
    const currentValue = (
        newCustomLists.filter(
            (item: Compleo.CustomLists.ListDefinitionType) =>
                item.fieldName === defaultLanguageFieldName
        )[0] || {}
    ).data;
    // const current
    if (!_.isEqual(currentValue, values[languagesFieldName])) {
        const newList = newCustomLists.filter(
            (item: Compleo.CustomLists.ListDefinitionType) =>
                item.fieldName !== defaultLanguageFieldName
        );
        newList.push({
            data: values[languagesFieldName],
            fieldName: defaultLanguageFieldName,
            labelName: `label-${currentLanguage}`,
            valueName: "value"
        });
        setCustomLists(newList);
    } else {
        const currentDefaultLanguageValue =
            values[defaultLanguageFieldName]?.value;
        if (currentDefaultLanguageValue) {
            const valuesFromLanguages = (values[languagesFieldName] || []).map(
                (item: any) => item.value
            );

            const languageNotAvailable = !valuesFromLanguages.includes(
                currentDefaultLanguageValue
            );
            if (languageNotAvailable) {
                formik.setFieldValue(defaultLanguageFieldName, null);
            }
        }
    }
};

const LanguageSettings = (props: any) => {
    const classes = useFormStyles();
    const [customLists, setCustomLists] = useState<
        Compleo.CustomLists.ListDefinitionType[]
    >([]);
    const { company } = useAuthState();
    const moduleName = "LANGUAGESETTINGSMAIN";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const currentLanguage = i18n.languages[0];
    const postAddress = `/languagesettings/edit`;

    const [editLanguageSettingsReturn, editLanguageSettings] = useApi(
        postAddress,
        "post"
    );
    const [metadata] = useGetMetadata(moduleName);

    const postAddressSearch = `/languagesettings/search`;
    const [getLanguageSettingsReturn] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getLanguageSettingsReturn,
        ["data", "data"]
    );

    const formikReturn = React.useCallback(
        (formik: any) => {
            if (formik !== null) {
                handleLoadDefaultLanguageField(
                    formik,
                    customLists,
                    setCustomLists,
                    currentLanguage,
                    "defaultLanguage",
                    "languages"
                );
                handleLoadDefaultLanguageField(
                    formik,
                    customLists,
                    setCustomLists,
                    currentLanguage,
                    "defaultLanguageCareers",
                    "languagesCareers"
                );
            }
        },
        [currentLanguage, customLists]
    );

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editLanguageSettings,
        editLanguageSettingsReturn,
        "/language/settings",
        FormClassic,
        metadata,
        valuesFromSource,
        undefined,
        null,
        undefined,
        undefined,
        undefined,
        customLists,
        undefined,
        formikReturn
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default LanguageSettings;
