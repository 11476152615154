import useApplicantViewContext from "../useApplicantViewContext";

export interface IQuestionnaireAnswerList {
    id: string;
    value: {
        stringValue?: string;
        originalFileName?: string;
        key?: string;
    };
}

export interface IQuestionnaireAnswer {
    question: {
        name: string;
        questionWithAnswers: boolean;
        id: string;
        questiontype: {
            label: string;
            value: string;
        };
        order: number;
    };
    answer: IQuestionnaireAnswerList[];
}

export interface IQuestionnaires {
    QuestionnaireId: string;
    QuestionnaireName: string;
    startDate?: string;
    endDate?: string;
    result?: string;
    Answers: IQuestionnaireAnswer[];
    ranking?: number;
    maxScore?: number;
    finalScore?: number;
    enableRanking?: boolean;
    answerScoreInfo?: {
        answerScore: number;
        questionMaxScore: number;
    };
}

export interface IQuestionnaireJobs {
    jobId: string;
    jobTitle: string;
    Questionnaires: IQuestionnaires[];
}

const getJobData = (applicantData: Compleo.IObject, jobId: string) => {
    const Jobs = applicantData.Jobs || [];
    const jobData = Jobs.filter((j: Compleo.IObject) => j.JobId === jobId)[0];
    if (jobData) {
        const returnData: IQuestionnaireJobs = {
            jobId: jobData.JobId,
            jobTitle: jobData.JobTitle,
            Questionnaires: []
        };
        return returnData;
    } else {
        return null;
    }
};

const useApplicantAssessmentsHelper = () => {
    const [data] = useApplicantViewContext();
    const mainData = data.applicantData || {};
    const questionnaires: Compleo.IObject[] = mainData?.Questionnaires || [];
    const returnData: IQuestionnaireJobs[] = [];

    questionnaires.map((q) => {
        const jobId = q.pk.split(":")[1];
        let currentJobQuestionData: IQuestionnaireJobs | null = returnData.filter(
            (j) => j.jobId === jobId
        )[0];
        if (!currentJobQuestionData) {
            currentJobQuestionData = getJobData(mainData, jobId);
            if (currentJobQuestionData) {
                returnData.push(currentJobQuestionData);
            }
        }
        if (currentJobQuestionData) {
            let currentQuestionnaire: IQuestionnaires | null = currentJobQuestionData.Questionnaires.filter(
                (que) => que.QuestionnaireId === q.QuestionnaireId
            )[0];
            if (!currentQuestionnaire) {
                currentQuestionnaire = {
                    Answers: [],
                    QuestionnaireId: q.QuestionnaireId,
                    QuestionnaireName: q.QuestionnaireName
                };
                currentJobQuestionData.Questionnaires.push(
                    currentQuestionnaire
                );
            }
            if (q.skGS1pk.split("#")[1].split(":")[1] === "GLOBAL") {
                currentQuestionnaire.startDate = q.startDate;
                currentQuestionnaire.endDate = q.endDate;
                currentQuestionnaire.result = q.result;
                currentQuestionnaire.ranking = q.ranking;
                currentQuestionnaire.enableRanking = q.enableRanking;
                currentQuestionnaire.maxScore = q.maxScore;
                currentQuestionnaire.finalScore = q.finalScore;
            } else {
                const answers: IQuestionnaireAnswerList[] = [];
                (q.answer || []).map((ans: IQuestionnaireAnswerList) => {
                    answers.push({
                        id: ans.id,
                        value: {
                            key: ans.value?.key,
                            originalFileName: ans.value?.originalFileName,
                            stringValue: ans.value?.stringValue
                        }
                    });
                });

                currentQuestionnaire.Answers.push({
                    answer: answers,
                    question: {
                        id: q.question?.id,
                        name: q.question?.name,
                        questionWithAnswers: q.question?.questionWithAnswers,
                        order: q.question?.order,
                        questiontype: {
                            label: q.question?.questiontype?.label,
                            value: q.question?.questiontype?.value
                        }
                    }
                });
            }
        }
    });
    returnData.map((jobs) => {
        jobs.Questionnaires.map((questionnaire) => {
            questionnaire.Answers.sort(
                (a, b) => a.question.order - b.question.order
            );
        });
    });

    return returnData;
};

export default useApplicantAssessmentsHelper;
