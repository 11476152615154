import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
// import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import CustomComponents from "./components/PipelineCustomComponents";
import { ApiStateType, useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { getHandlePipelineSubmit } from "./pipelinePostHelper";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";

const PipelineNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/pipeline/list");
    const moduleName = "PIPELINENEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/pipeline/new`;

    const [addPipelineReturn, addPipeline] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.deleted = false;
    valuesFromSource.default = false;

    const handleSubmit = getHandlePipelineSubmit(addPipeline);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        // props,
        ready: readyTranslation && metadata.status === "success",
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: addPipelineReturn,
        redirectAddress: "/pipeline/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default PipelineNew;
