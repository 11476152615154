import { Box, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import JobFunnel from "./Funnel/JobFunnel";
import useJobContext from "../useJobContext";
import JobReportsToolbar from "./components/JobReportsToolbar";
import { JobReportsTypeToRender } from "./components/JobReportTitle";
import JobReportsSwitch from "./components/JobReportsSwitch";
import JobReportsShareModal from "./JobReportsShare/JobReportsShareModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main
            // width: "100%"
        }
    })
);

const storageNameItem = "lastJobReportToShow";

const JobReportsMain = (props: any) => {
    const classes = useStyles();
    const [data] = useJobContext();
    const { t } = data;
    const [jobReportToShow, setJobReportToShow] = React.useState<
        CompleoShared.Reports.IJobReportsToRender
    >("funnel");
    const [openShareModal, setOpenShareModal] = React.useState(false);

    const changeReportToShow = (
        newReport: CompleoShared.Reports.IJobReportsToRender
    ) => {
        setJobReportToShow(newReport);
        localStorage.setItem(storageNameItem, newReport);
    };

    React.useEffect(() => {
        const lastReport = localStorage.getItem(
            storageNameItem
        ) as CompleoShared.Reports.IJobReportsToRender;
        if (
            lastReport &&
            lastReport !== jobReportToShow &&
            JobReportsTypeToRender.includes(lastReport)
        ) {
            setJobReportToShow(lastReport);
        }
    }, []);

    return (
        <Box className={classes.container}>
            <Box>
                <JobReportsToolbar
                    t={t}
                    jobReportToShow={jobReportToShow}
                    changeReportToShow={changeReportToShow}
                    openReportShareModal={() => {
                        setOpenShareModal(true);
                    }}
                />
            </Box>
            <Box>
                <JobReportsSwitch jobReportToShow={jobReportToShow} />
                <JobReportsShareModal
                    onClose={() => {
                        setOpenShareModal(false);
                    }}
                    open={openShareModal}
                />
            </Box>
        </Box>
    );
};

export default JobReportsMain;
