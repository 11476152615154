import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType, useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import { useAuthState } from "_ReactContext/AuthContext";
import { Avatar, Box, Divider, Grid, Typography } from "@material-ui/core";
import { FieldFile } from "customHooks/useCompleoView/Fields/FieldsType";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import SaveIcon from "@material-ui/icons/Save";
import { transformDBData } from "functions/util";
import RecipientStatusTags from "../../../Email/Schedule/RecipientStatusTags";
import ApplicantEmailRecipientsTags from "../../../Email/ApplicantEmailRecipientsTags";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import useApplicantViewContext from "../useApplicantViewContext";
// import ModalSaveEmailAttachments from "./ModalSaveEmailAttachments";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    eventData: Compleo.IObject;
    t: any;
    language: string;
}
function createMarkup(data: string) {
    return { __html: data };
}

const ModalViewEvent = (props: IModalNewMail) => {
    const { open, handleClose, eventData, language, t } = props;
    const { company, timeZone } = useAuthState();
    const [
        modalSaveAttachmentsOpen,
        setModalSaveAttachmentsOpen
    ] = React.useState(false);
    const [data] = useApplicantViewContext();

    const dataFromDB = eventData;

    const valuesFromSource = {
        subject: dataFromDB?.subject,
        body: dataFromDB?.body,
        attachments: dataFromDB?.attachments
    };
    // console.log("valuesFromSource", valuesFromSource);

    const ready = true;
    // por enquanto não tratar anexos... Verificar depois
    // const hasAttachments = (dataFromDB?.attachments || []).length > 0;
    const hasAttachments = false;
    const statusMeetingListShowTime: CompleoShared.Common.ScheduleMessageType[] = [
        "INVITE",
        "INVITEUPDATE"
    ];
    const statusMeetingListShowConfirmation: CompleoShared.Common.ScheduleMessageType[] = [
        "MAYBE",
        "DECLINED",
        "ACCEPTED"
    ];
    const tagMessageToShow: CompleoShared.Common.ScheduleMessageType[] = [
        "INVITE",
        "INVITEUPDATE",
        "CANCELINVITE"
    ];

    const isFromApplicant =
        data.applicantData?.email === dataFromDB?.userFrom?.email;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {ready && (
                    <Grid container>
                        <Grid item xs={1}>
                            {isFromApplicant ? (
                                <CompleoAvatar
                                    idParams={{ id: data.applicantId }}
                                    type="applicant"
                                />
                            ) : (
                                <CompleoAvatar
                                    idParams={{
                                        email: dataFromDB?.userFrom?.email
                                    }}
                                    type="user"
                                />
                            )}
                        </Grid>
                        <Grid item xs>
                            {`${t("subject")}: ${valuesFromSource?.subject}`}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>{`${t("from")}: `}</strong>
                                <ApplicantEmailRecipientsTags
                                    applicantEmail=""
                                    recipients={[dataFromDB?.userFrom || []]}
                                    t={t}
                                    withoutColors={true}
                                />
                            </Typography>
                        </Grid>
                        {tagMessageToShow.includes(dataFromDB?.type) && (
                            <Grid item xs={12}>
                                <RecipientStatusTags
                                    status={dataFromDB?.type}
                                    t={t}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogTitle>
            <DialogContent dividers>
                {ready ? (
                    <React.Fragment>
                        {statusMeetingListShowTime.includes(
                            dataFromDB.type
                        ) && (
                            <Box marginBottom={2}>
                                <strong>{`${t("dateTimeWhen")}: `}</strong>
                                {transformDBData({
                                    t: t,
                                    language: language,
                                    value: dataFromDB.startDateTime,
                                    customDateFormat: "dateWithTime"
                                })}
                                <strong>{` ${t("-")} `}</strong>
                                {transformDBData({
                                    t: t,
                                    language: language,
                                    value: dataFromDB.endDateTime,
                                    customDateFormat: "time"
                                })}
                                {dataFromDB.location && (
                                    <>
                                        <br />
                                        <strong>{`${t("where")}: `}</strong>
                                        {transformDBData({
                                            t: t,
                                            language: language,
                                            value: dataFromDB.location
                                        })}
                                    </>
                                )}
                            </Box>
                        )}
                        {statusMeetingListShowConfirmation.includes(
                            dataFromDB?.type
                        ) && (
                            <Box marginBottom={2}>
                                <strong>{`${t("confirmation")}: `}</strong>
                                <RecipientStatusTags
                                    status={dataFromDB?.type}
                                    t={t}
                                />
                            </Box>
                        )}
                        {/* {tagMessageToShow.includes(dataFromDB?.type) && (
                            <Box marginBottom={2}>
                                <RecipientStatusTags
                                    status={dataFromDB?.type}
                                    t={t}
                                />
                            </Box>
                        )} */}
                        <Grid container>
                            <Grid item container xs={12}>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        (dataFromDB?.body || "").replace(
                                            "\n",
                                            "<br />"
                                        )
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{ marginTop: 16 }}
                            >
                                {hasAttachments && (
                                    <>
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                >
                                                    <strong>
                                                        {t(
                                                            "a_FormGroup_attachments"
                                                        )}
                                                    </strong>
                                                </Typography>
                                                <ButtonIconTooltipCompleo
                                                    label={t("saveAttachments")}
                                                    color="secondary"
                                                    onClick={() =>
                                                        setModalSaveAttachmentsOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <SaveIcon />
                                                </ButtonIconTooltipCompleo>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldFile
                                                fieldData={dataFromDB}
                                                fieldName="attachments"
                                                t={t}
                                                language={language}
                                                fieldDef={{}}
                                                propsToAdd={{}}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {/* {modalSaveAttachmentsOpen && (
                            <ModalSaveEmailAttachments
                                open={modalSaveAttachmentsOpen}
                                globalEmailId={globalEmailId}
                                handleClose={() =>
                                    setModalSaveAttachmentsOpen(false)
                                }
                                dataFromDB={dataFromDB}
                            />
                        )} */}
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </DialogContent>
            {ready && (
                <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary">
                        {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ModalViewEvent;
