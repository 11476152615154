"use client";

import { Column } from "@tanstack/react-table";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

import ImportExportIcon from "@material-ui/icons/ImportExport";
import { TableCell } from "@material-ui/core";
import {
    FormatIndentIncrease,
    FormatIndentDecrease,
    ExpandLess,
    ExpandMore,
    Block
} from "@material-ui/icons";

interface DataTableColumnHeaderProps<TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<TData, TValue>;
    title: string;
    enableSorting?: boolean;
    align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}

export function DataTableColumnHeader<TData, TValue>({
    column,
    title,
    className,
    enableSorting = true,
    align
}: DataTableColumnHeaderProps<TData, TValue>) {
    if (!enableSorting) {
        return <div className={className}>{title}</div>;
    } else {
        return (
            <div
                style={{
                    width: "100%",
                    textAlign: align
                }}
            >
                <ButtonIconTooltipCompleo
                    label="Sort"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ImportExportIcon fontSize="small" />
                </ButtonIconTooltipCompleo>

                <strong>{title}</strong>
                {column.getCanGroup() ? (
                    // If the header can be grouped, let's add a toggle
                    <ButtonIconTooltipCompleo
                        onClick={column.getToggleGroupingHandler()}
                        label={
                            column.getIsGrouped()
                                ? "Remover agrupamento"
                                : "Agrupar"
                        }
                    >
                        {column.getIsGrouped() ? (
                            <>
                                <Block
                                    fontSize="small"
                                    style={{
                                        margin: 0,
                                        padding: 0
                                    }}
                                />
                            </>
                        ) : (
                            <FormatIndentIncrease
                                fontSize="small"
                                style={{
                                    margin: 0,
                                    padding: 0
                                }}
                            />
                        )}
                    </ButtonIconTooltipCompleo>
                ) : null}
            </div>
        );
    }
}
