import { Box } from "@material-ui/core";
import React from "react";
import { useAuthorization } from "_ReactContext/AuthContext";
import DashboardMain, { DashboardSwitchPage } from "./DashboardMain";
import DashboardMainUniqueView from "./DashboardMainUniqueView";
import DashboardMenu from "./Helpers/DashboardMenu";
import NoAccessDashPage from "./NoAccessDashPage";
import { DashboardProvider } from "./useDashboardContext";
import {
    DashboardPageToRenderType,
    IDashOtherPropsType
} from "./useDashboardContext/DashboardTypes";

interface IDashboard {
    uniqueDashboardView?: boolean;
    dashboardToShow?: DashboardPageToRenderType;
    dashOtherProps?: IDashOtherPropsType;
    externalAccess?: boolean;
}

const Dashboard = (props: IDashboard) => {
    const { isFeatureAuthorized } = useAuthorization();
    const {
        uniqueDashboardView = false,
        dashboardToShow,
        dashOtherProps,
        externalAccess = false
    } = props;

    const hasDashboardAccess = isFeatureAuthorized("Dashboard");

    if (hasDashboardAccess || externalAccess) {
        if (uniqueDashboardView && dashboardToShow) {
            return (
                <DashboardProvider
                    uniqueDashboardView={uniqueDashboardView}
                    dashOtherProps={dashOtherProps}
                >
                    <DashboardMainUniqueView
                        dashboardToShow={dashboardToShow}
                    />
                </DashboardProvider>
            );
        } else {
            return (
                <DashboardProvider>
                    <DashboardMain />
                </DashboardProvider>
            );
        }
    } else {
        return <NoAccessDashPage />;
    }
};

export default Dashboard;
