import { useQuery } from "react-query";
import { apiDirectCall } from "customHooks/useApi/api";

const getNavigation = async ({
    email,
    companyId
}: {
    email: string;
    companyId: number;
}) => {
    // debugger;
    const postAddress = `/general/listmenuuser`;
    const { data } = await apiDirectCall(postAddress, "post", {
        email,
        companyId
    });

    return data;
};

export default function useQueryNavigation(email: string, companyId: number) {
    let parameter: [string, string, number] = ["navigation", email, companyId];
    let enabled: boolean = false;
    if (email !== null && email !== "" && companyId > 0) {
        parameter = ["navigation", email, companyId];
        enabled = true;
    }
    return useQuery(
        parameter,
        () => getNavigation({ email: email, companyId: companyId }),
        { enabled: enabled }
    );
}
