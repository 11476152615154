import _ from "lodash";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { CsvBuilder } from "filefy";

// const jsPDF = require("jspdf");

export const toCSV = (
    title: string,
    columns: any,
    data: any,
    columnsFinalLabel?: string[]
) => {
    debugger;

    const finalData = data.map((d: any) => {
        const arrReturn: any[] = [];
        columns.map((c: any) => {
            arrReturn.push(_.get(d, c));
        });
        return arrReturn;
    });
    new CsvBuilder(`${title}.csv`)
        .setDelimeter(",")
        .setColumns(columnsFinalLabel || columns)
        .addRows(finalData)
        .exportFile();
};

export const toPDF = (
    title: string,
    columns: string[],
    data: Compleo.IObject[],
    columnsFinalLabel?: string[],
    fileName?: string
) => {
    const newData = data.map((d: any) => {
        const arrReturn: any[] = [];
        columns.map((c: any) => {
            arrReturn.push(d[c]);
        });
        return arrReturn;
    });

    let content = {
        startY: 50,
        head: [columnsFinalLabel || columns],
        body: newData
    };

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    doc.text(title, 40, 40);
    //@ts-ignore
    doc.autoTable(content);
    doc.save((fileName || title || "data") + ".pdf");
};
