/* eslint-disable react/jsx-no-undef */
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ApplicantHistoryNewCommentModal from "Pages/Applicant/ApplicantView/ApplicantHistory/ApplicantHistoryNewCommentModal";
import JobKanbanOpinionOnTheApplicantEditModal from "./JobKanbanOpinionOnTheApplicantEditModal";
import { IJobViewStages } from "Pages/Job/JobView/useJobContext/JobViewTypes";

type ActionsMenuList =
    | "newHistory_PhoneCall"
    | "newHistory_Interview"
    | "newHistory_Comment"
    | "newHistory_TextMessage"
    | "opinionOnTheApplicant"
    | "opinionOnTheApplicantJob";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    applicantData: Compleo.IObject;
    stageData: IJobViewStages;
}

const JobKanbanApplicantActionsMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t, applicantData, stageData } = props;
    const [modalCommentOpen, setModalCommentOpen] = React.useState<{
        open: boolean;
        type?: CompleoShared.Common.ApplicantHistoryType;
    }>({ open: false });
    const [modalOpinion, setModalOpinion] = React.useState<{
        open: boolean;
        jobData?: { value: string; label: string };
    }>({ open: false });

    const applicantId = (applicantData?.pk || "").split(":")[1];

    const allMenuOptions: ActionsMenuList[] = [
        "newHistory_PhoneCall",
        "newHistory_Interview",
        "newHistory_Comment",
        "opinionOnTheApplicant",
        "opinionOnTheApplicantJob"
    ];

    const menuOptions = allMenuOptions;

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (action: ActionsMenuList) => {
        switch (action) {
            case "newHistory_PhoneCall":
                setModalCommentOpen({
                    type: "phoneCall",
                    open: true
                });
                break;
            case "newHistory_Interview":
                setModalCommentOpen({
                    type: "interview",
                    open: true
                });
                break;
            case "newHistory_Comment":
                setModalCommentOpen({
                    type: "addComment",
                    open: true
                });
                break;
            case "newHistory_TextMessage":
                setModalCommentOpen({
                    type: "textMessage",
                    open: true
                });
                break;
            case "opinionOnTheApplicant":
                setModalOpinion({
                    jobData: undefined,
                    open: true
                });
                break;
            case "opinionOnTheApplicantJob":
                setModalOpinion({
                    jobData: {
                        label: applicantData?.jobData?.JobTitle,
                        value: applicantData?.jobData?.JobId
                    },
                    open: true
                });
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            {modalCommentOpen.open && (
                <ApplicantHistoryNewCommentModal
                    open={modalCommentOpen.open}
                    onClose={() => setModalCommentOpen({ open: false })}
                    allowChangeJob={false}
                    applicantId={applicantId}
                    jobData={{
                        label: applicantData?.jobData?.JobTitle,
                        value: applicantData?.jobData?.JobId
                    }}
                    applicantName={applicantData?.name}
                    type={modalCommentOpen.type}
                    stageId={stageData?.id}
                />
            )}
            {modalOpinion.open && (
                <JobKanbanOpinionOnTheApplicantEditModal
                    open={modalOpinion.open}
                    onClose={() => setModalOpinion({ open: false })}
                    applicantId={applicantId}
                    opinionOnTheApplicant={applicantData?.opinionOnTheApplicant}
                    opinionOnTheApplicantJob={
                        applicantData?.jobData?.opinionOnTheApplicantJob
                    }
                    jobData={modalOpinion.jobData}
                    applicantName={applicantData?.name}
                />
            )}
        </div>
    );
};

const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`KanbanApplicantMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default JobKanbanApplicantActionsMenu;
