import { IRichTextConfig } from "./RichText";

const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader(); // eslint-disable-line no-undef
        reader.onload = (e: any) =>
            resolve({ data: { link: e.target.result } });
        reader.onerror = (e: any) => reject(e);
        reader.readAsDataURL(file);
    });
};

// Toolbar all options on below link
// https://jpuri.github.io/react-draft-wysiwyg/#/docs

const fullToolBar = {
    options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        // "embedded",
        "emoji",
        "image",
        "remove",
        "history"
    ],
    inline: {
        inDropdown: false,
        options: [
            "bold",
            "italic",
            "underline",
            "strikethrough"
            // "monospace",
            // "superscript",
            // "subscript"
        ]
    },
    list: { inDropdown: true },
    image: {
        uploadCallback: uploadImageCallBack,
        previewImage: true
    }
    // textAlign: { inDropdown: true },
    // link: { inDropdown: true },
    // history: { inDropdown: true }
};

const jobDescriptionToolbar = {
    options: [
        "inline",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "emoji",
        "image",
        "remove",
        "history"
    ],
    inline: {
        inDropdown: false,
        options: ["bold", "italic", "underline", "strikethrough"]
    },
    list: { inDropdown: true },
    image: {
        uploadCallback: uploadImageCallBack,
        previewImage: true
    }
};

const minToolBar = {
    options: ["inline", "emoji"],
    inline: {
        inDropdown: false,
        options: [
            "bold",
            "italic",
            "underline",
            "strikethrough"
            // "monospace",
            // "superscript",
            // "subscript"
        ]
    },
    image: {
        uploadCallback: uploadImageCallBack,
        previewImage: true
    }
};

export const getToolbarRichText = (richtTextConfig: IRichTextConfig) => {
    const { toolbarType = "min" } = richtTextConfig;
    switch (toolbarType) {
        case "min":
            return minToolBar;
        case "full":
            return fullToolBar;
        case "jobDescription":
            return jobDescriptionToolbar;
        default:
            return minToolBar;
    }
};
