import React from "react";
import { Paper, Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import Simulation from "Components/CustomCV";
import Page from "Pages/_Layouts/DefaultInternal";
import MainTextSearch from "./components/MainTextSearch";
import { useApiCache } from "customHooks/useApi";
import { apiDirectCall } from "customHooks/useApi/api";
import { useAuthState } from "_ReactContext/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@gatsbyjs/reach-router";
interface IFilters {
    filterUpdated: boolean;
    buckets: Compleo.IObject;
    mainSearch: string | null;
    searchAsYouType: boolean;
    customSearch: Compleo.IObject;
    advancedSearch: Compleo.IObject;
    pagination: { pageSize: number; currentPage: number; fromValue?: number };
    geoLocation: {
        latitude?: number;
        longitude?: number;
    };
    sort: Compleo.IObject;
    updateAggsAfterFilter: boolean;
    otherGenericParams?: Compleo.IObject;
    controlParams?: Compleo.IObject;
    language: string;
}

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
        marginLeft: "8px"
    }
}));

const CustomCVSimulation = (props: any) => {
    const internalClasses = useStyles();
    const classes = useFormStyles();
    const moduleName = "CUSTOMCVSIMULATION";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { company } = useAuthState();

    //const applicantId = "4";
    const customCVId = props.uuid; //"f984a031-b205-49c9-a81d-0f8762152f16";
    const filtersDefaultValue: IFilters = {
        filterUpdated: false,
        buckets: {},
        mainSearch: "",
        searchAsYouType: false,
        customSearch: {},
        advancedSearch: {},
        pagination: {
            pageSize: 12,
            currentPage: 1
        },
        geoLocation: {},
        sort: {},
        updateAggsAfterFilter: false,
        otherGenericParams: undefined,
        language: i18n.languages[0]
    };
    const [filters, setFilters] = React.useState<IFilters>(filtersDefaultValue);
    const [applicantId, setApplicantId] = React.useState<string | undefined>(
        undefined
    );
    const [loadPreview, setLoadPreview] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
        undefined
    );
    const listApiAddress = "/applicant/list";
    React.useEffect(() => {
        setApplicantId(undefined);
        setLoadPreview(false);
        apiDirectCall(listApiAddress, "post", {
            companyId: company.companyId,
            ...filters,
            language: i18n.languages[0]
        }).then((result: any) => {
            if (
                result.status === 200 &&
                result.data?.fields?.length > 0 &&
                filters.mainSearch
            ) {
                const selectedApplicantId =
                    result.data.fields[0].pk?.split(":")[1] || undefined;
                setApplicantId(selectedApplicantId);
            }
        });
        //}
    }, [filters, i18n.languages, company.companyId]);

    let compRetorno: null | JSX.Element = <Loading />;

    const handleClick = () => {
        if (!applicantId) {
            setErrorMessage(t("applicantRequired"));
            return false;
        }

        setLoadPreview(true);
        setErrorMessage(undefined);
    };

    if (readyTranslation) {
        compRetorno = (
            <Paper className={classes.paper}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography
                            component="h3"
                            variant="h6"
                            align="left"
                            className={classes.mainTitle}
                        >
                            {t("a_FormGroup_mainData")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            style={{ alignItems: "center" }}
                        >
                            <Grid item style={{ width: "70%" }}>
                                <MainTextSearch
                                    t={t}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                                {errorMessage && (
                                    <span className={internalClasses.error}>
                                        {errorMessage}
                                    </span>
                                )}
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClick}
                                    disabled={!applicantId}
                                >
                                    {t("buttonSimulateText")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {applicantId && loadPreview && (
                        <Grid item>
                            <Simulation
                                applicantId={applicantId}
                                companyId={company.companyId}
                                customCVId={customCVId}
                                language={i18n.languages[0]}
                                t={t}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="flex-start"
                        >
                            {applicantId && loadPreview && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() =>
                                            navigate(
                                                `/customcv/edit/${customCVId}`
                                            )
                                        }
                                    >
                                        {t("buttonMainText")}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    onClick={() => navigate("/customcv/list")}
                                >
                                    {t("buttonSecondaryText")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};
export default CustomCVSimulation;
