import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

export const useListPageFunctionsDeleteJobTitle = (
    t: any,
    companyId: number
) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const callDeleteDialog = async (rowData: any) => {
        return new Promise((resolve, reject) => {
            const uuid = rowData.skGS1pk.split(":")[1];
            const postAddress = `/jobtitle/jobtitledelete`;
            //const [deleteBranchReturn, deleteBranch] = useApi(postAddress, "post");

            callDialog({
                title: t("jobTitleDelete_ModalTitle"),
                bodyText: t("jobTitleDelete_Message"),
                agreeButtonText: t("jobTitleDelete_Yes"),
                disagreeButtonText: t("jobTitleDelete_No"),
                agreeFunction: async () => {
                    //const uuid = rowData.skGS1pk.split(":")[1];
                    //console.log("concordou", rowData, uuid);
                    const result = await apiDirectCall(postAddress, "post", {
                        companyId: companyId,
                        id: uuid
                    });
                    //console.log("retorno apiDirectCall", result);
                    if (result.status === 200) {
                        showMessage(t("successMessage"), "success");
                        //return true;
                        resolve(true);
                    }
                    resolve(false);
                },
                disagreeFunction: () => {
                    //console.log("discordou", rowData);
                    resolve(false);
                }
            });
        });
    };

    const listPageFunctions = [{ action: "delete", fn: callDeleteDialog }];

    return listPageFunctions;
};
