import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { InputAdornment, TextField } from "@material-ui/core";
import useDebounce from "customHooks/useDebounce";
import { useAuthState } from "_ReactContext/AuthContext";
import { apiDirectCall } from "customHooks/useApi/api";
import { useApiCache } from "customHooks/useApi";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            margin: theme.spacing(1)
        }
    })
);
interface IProps {
    t: any;
    filters: any;
    setFilters: any;
}

const MainTextSearch = (props: IProps) => {
    const classes = useStyles();
    const { t, filters, setFilters } = props;
    const listApiAddress = "/applicant/list";
    //const [listData, listDispatch] = useList();
    //const { setFilters } = listDispatch;
    //const { t, listApiAddress, otherParamsQuery = {} } = listData.tempInfo;

    const [typeText, setTypeText] = React.useState("");

    const [textSearch, setTextSearch] = React.useState("");
    const newTextSearch = useDebounce(textSearch, 1000);
    const [fields, setFields] = React.useState<any[]>([]);
    const [suggest, setSuggest] = React.useState<any[]>([]);

    const handleTextChange = (value: any) => {
        setTextSearch(value);
    };

    const listAddress = listApiAddress;
    const { company } = useAuthState();
    // const [getSearchResult, executeSearch] = useApiCache(
    //     listAddress,
    //     "post",
    //     {
    //         companyId: company.companyId,
    //         //...filters,
    //         searchAsYouType: true,
    //         mainSearch: textSearch
    //         //...otherParamsQuery
    //     },
    //     true,
    //     60 * 1000
    // );

    React.useEffect(() => {
        setFilters({
            ...filters,
            mainSearch: newTextSearch,
            filterUpdated: true
        });
        console.log("setFilter newTextSearch", newTextSearch);
    }, [newTextSearch]);

    React.useEffect(() => {
        if (typeText) {
            // executeSearch({
            //     companyId: company.companyId,
            //     //...filters,
            //     searchAsYouType: true,
            //     mainSearch: typeText
            //     //...otherParamsQuery
            // });
            apiDirectCall(listApiAddress, "post", {
                companyId: company.companyId,
                searchAsYouType: true,
                mainSearch: typeText
            }).then((result: any) => {
                console.log("apiDirectCall result", result);
                if (result.status === 200) {
                    if (result.data?.fields) setFields(result.data.fields);
                    if (result.data?.suggest) setSuggest(result.data.suggest);
                    // const fields = result.data?.fields || [];
                    // const suggest = result.data?.suggest || {};
                }
            });
        }
    }, [typeText, company.companyId]);

    React.useEffect(() => {
        if (filters.mainSearch === null) {
            setTextSearch("");
        }
    }, [filters.mainSearch]);

    // Effect below load text search if a filter saved is loaded
    // React.useEffect(() => {
    //     if (
    //         listData?.localInfo?.loadedMainSearchFromFilterSaved !== undefined
    //     ) {
    //         handleTextChange(
    //             listData.localInfo.loadedMainSearchFromFilterSaved
    //         );
    //     }
    // }, [listData.localInfo.loadedMainSearchFromFilterSaved]);

    //console.log("getSearchResult", getSearchResult);

    // const fields = getSearchResult.response?.data?.fields || [];
    // const suggest = getSearchResult.response?.data?.suggest || {};
    if (Object.keys(suggest).length) {
        const itemsToSuggest = getSuggestions(suggest);
        itemsToSuggest.map((item) => {
            if (!fields.find((f: string) => f === item)) {
                fields.push(item);
            }
        });
    }

    return (
        <Autocomplete
            fullWidth
            freeSolo
            id="list-search"
            // disableClearable
            options={fields.map((option: string) => option)}
            onChange={(event: any, newValue: any) => {
                handleTextChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setTypeText(newInputValue);
                if (!newInputValue) {
                    handleTextChange("");
                }
            }}
            filterOptions={(options, params) => {
                // const filtered = filter(options, params);
                const filtered = options;

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                    filtered.push(params.inputValue);
                }

                return filtered;
            }}
            selectOnFocus
            // clearOnBlur
            handleHomeEndKeys
            value={textSearch}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        className={classes.search}
                        label={t("applicant")}
                        placeholder={t("applicantPlaceholder")}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            )
                        }}
                    />
                );
            }}
        />
    );
};

const getSuggestions = (suggest: Compleo.IObject) => {
    const suggestName = "mainTextSuggest";
    const optionsNumberArray = (suggest[suggestName] || []).map(
        (suggestItem: any) => suggestItem.options?.length
    );
    const maxSuggestions = Math.max(...optionsNumberArray);
    const itemsToSuggest: string[] = [];

    (suggest[suggestName] || []).map((suggestItem: any) => {
        for (let index = 0; index < maxSuggestions; index++) {
            if (suggestItem.options[index]) {
                itemsToSuggest[index] = `${itemsToSuggest[index] || ""} ${
                    suggestItem.options[index].text
                }`.trim();
            } else {
                itemsToSuggest[index] = `${itemsToSuggest[index] || ""} ${
                    suggestItem.text
                }`.trim();
            }
        }
    });
    return itemsToSuggest;
};

export default MainTextSearch;
