import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider, Menu } from "@material-ui/core";
import { ModalParserOptions } from "./ModalParserOptions";

const options = [
    {
        value: "companyOptions"
    }
    // ,
    // {
    //     value: "userOptions"
    // }
];

interface IProps {
    t: any;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightButtonList = (props: IProps) => {
    const { t, setOpenModal } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (itemOption: string) => {
        if (itemOption === "companyOptions") setOpenModal(true);
        setAnchorEl(null);
    };

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(divRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div>
            <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="split button"
                ref={divRef}
            >
                <Button onClick={handleClickListItem}>
                    {t("buttonTopRight")}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label={t("buttonTopRight")}
                    aria-haspopup="menu"
                    onClick={handleClickListItem}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem disabled button={false}>
                    {t("buttonTopRightChooseAnOption")}
                </MenuItem>
                <Divider />
                {options.map((option, index) => (
                    <MenuItem
                        key={option.value}
                        onClick={() => handleMenuItemClick(option.value)}
                    >
                        {t(option.value)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default RightButtonList;
