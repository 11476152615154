import React from "react";
import { CompleoDynamicDashboardPage } from "customHooks/useDynamicDashboard";
import DynamicDashboardItemsExternal from "../DynamicDashboard/DynamicDashboardItemsExternal";

interface IProps {
    evaluationId: string;
    dashboardId: string;
}
const DashboardApplicantsJobViewExternal = (props: IProps) => {
    const { evaluationId, dashboardId } = props;
    const module = "APPLICANTJOBVIEWDASHBOARD";
    const reportToShow: CompleoShared.Reports.IJobReportsToRender = "dashboard";

    return (
        <CompleoDynamicDashboardPage
            moduleName={module}
            listApiAddress="/reports/evaluationreportsexternal"
            otherParamsQuery={{
                evaluationId: evaluationId,
                reportToShow: reportToShow
            }}
            externalAccess={true}
        >
            <DynamicDashboardItemsExternal
                evaluationId={evaluationId}
                dashboardId={dashboardId}
                dashboardName={module}
            />
        </CompleoDynamicDashboardPage>
    );
};

export default DashboardApplicantsJobViewExternal;
