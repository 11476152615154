import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import useApplicantViewContext from "../useApplicantViewContext";
import { useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Collapse,
    ListItemIcon,
    ListItemSecondaryAction,
    Paper
} from "@material-ui/core";
import _ from "lodash";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { getFirstNWordsFromText, transformDBData } from "functions/util";
import ModalViewEmail from "./ModalViewEmail";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "../../../Email/ApplicantEmailRecipientsTags";
import ModalRepplyEmailThread from "./ModalRepplyEmailThread";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import ModalEmailRecipients from "Pages/Email/ModalEmailRecipients";
import { People } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);
interface IModalRepplyThread {
    open: boolean;
    recipients: IRecipientsTags[];
    threadId: string;
    jobId?: string;
    subject: string;
}
interface IModalViewEmailState {
    open: boolean;
    globalEmailId: string;
    recipients: Compleo.IObject[];
    fromEmail: Compleo.IObject;
}

export interface IModalEmailRecipientsState {
    open: boolean;
    mainData: Compleo.IObject[];
    allRecipients: Compleo.IObject[];
}
interface IProps {
    t: any;
}
const AplicantEmailThreadList = (props: IProps) => {
    const classes = useStyles();
    const { t } = props;
    const [threadOpen, setThreadOpen] = React.useState<Compleo.IObject>({});

    const [
        modalEmailRecipientsState,
        setModalEmailRecipientsState
    ] = React.useState<IModalEmailRecipientsState>({
        open: false,
        mainData: [],
        allRecipients: []
    });
    const handleModalEmailRecipientsStateClose = () => {
        setModalEmailRecipientsState({
            open: false,
            mainData: [],
            allRecipients: []
        });
    };

    const defaultModalViewEmailState: IModalViewEmailState = {
        open: false,
        globalEmailId: "",
        recipients: [],
        fromEmail: {}
    };
    const [modalViewEmailState, setModalViewEmailState] = React.useState<
        IModalViewEmailState
    >(defaultModalViewEmailState);
    const handleModalViewEmailStateClose = () => {
        setModalViewEmailState(defaultModalViewEmailState);
    };
    const openModalViewEmail = (
        globalId: string,
        recipients: Compleo.IObject[],
        fromEmail: Compleo.IObject
    ) => {
        setModalViewEmailState({
            open: true,
            globalEmailId: globalId,
            recipients: recipients,
            fromEmail: fromEmail
        });
    };

    const defaultModalReplyEmailState: IModalRepplyThread = {
        open: false,
        recipients: [],
        threadId: "",
        subject: ""
    };
    const [modalRepplyThread, setModalRepplyThread] = React.useState<
        IModalRepplyThread
    >(defaultModalReplyEmailState);
    const handleModalReplyThreadStateClose = () => {
        setModalRepplyThread(defaultModalReplyEmailState);
    };
    const openModalReplyThread = (
        threadId: string,
        recipients: IRecipientsTags[],
        subject: string,
        jobId?: string
    ) => {
        setModalRepplyThread({
            open: true,
            threadId,
            recipients,
            jobId,
            subject
        });
    };

    const [data] = useApplicantViewContext();
    const { language } = data;
    const listAddress = `/email/listemails`;
    const postData = {
        applicantId: data.applicantId,
        companyId: data.companyId
    };
    const [emailList, refreshEmailList] = useApiCache(
        listAddress,
        "post",
        postData,
        false
    );
    const dataEmail: Compleo.IObject[] = emailList.response?.data?.data || [];

    React.useEffect(() => {
        refreshEmailList(postData);
    }, [data.applicantId]);
    // console.log("dataEmail", dataEmail);

    const handleClick = (threadPK: string) => {
        const localThreadOpen = _.cloneDeep(threadOpen);
        localThreadOpen[threadPK] = !(localThreadOpen[threadPK] === true);
        setThreadOpen(localThreadOpen);
    };

    if (dataEmail.length) {
        return (
            <Paper className={classes.paperContainer}>
                <List className={classes.root}>
                    {dataEmail.map((item) => {
                        const messagesCount = (item.MessagesThread || [])
                            .length;
                        const open = threadOpen[item.pk] === true;
                        const threadId = item.pk.split(":")[1];

                        return (
                            <React.Fragment key={item.pk}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <CompleoAvatar
                                            idParams={{
                                                email: item.createdByUser
                                            }}
                                            type="user"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.subject}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {`${messagesCount} ${t(
                                                        "messages"
                                                    )}`}
                                                </Typography>
                                                {` - ${t("lastMessage")}: `}
                                                {transformDBData({
                                                    t: t,
                                                    language: language,
                                                    value: item.lastUpdateAt,
                                                    customDateFormat:
                                                        "dateRelative"
                                                })}
                                                {/* <br />
                                                {`${t("recipients")}: `}
                                                <ApplicantEmailRecipientsTags
                                                    applicantEmail={
                                                        data.applicantData
                                                            ?.email
                                                    }
                                                    recipients={item.recipients}
                                                    t={t}
                                                /> */}
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <ButtonIconTooltipCompleo
                                            label={t("iconRecipients")}
                                            onClick={() =>
                                                setModalEmailRecipientsState({
                                                    open: true,
                                                    mainData: [],
                                                    allRecipients:
                                                        item.recipients || []
                                                })
                                            }
                                        >
                                            <People />
                                        </ButtonIconTooltipCompleo>
                                        {!data.readOnly && (
                                            <ButtonIconTooltipCompleo
                                                label={t("newMessageThread")}
                                                onClick={() =>
                                                    openModalReplyThread(
                                                        threadId,
                                                        item.recipients,
                                                        item.subject,
                                                        item.jobId
                                                    )
                                                }
                                            >
                                                <ReplyAllIcon />
                                            </ButtonIconTooltipCompleo>
                                        )}
                                        <ButtonIconTooltipCompleo
                                            label={t("showMessagesExpand")}
                                            onClick={() => handleClick(item.pk)}
                                        >
                                            {open ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ButtonIconTooltipCompleo>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <AplicantEmailThreadListItem
                                        items={item.MessagesThread || []}
                                        t={t}
                                        language={language}
                                        openModalViewEmail={openModalViewEmail}
                                    />
                                </Collapse>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
                {modalViewEmailState.open && (
                    <ModalViewEmail
                        handleClose={handleModalViewEmailStateClose}
                        {...modalViewEmailState}
                    />
                )}
                {modalRepplyThread.open && (
                    <ModalRepplyEmailThread
                        handleClose={handleModalReplyThreadStateClose}
                        {...modalRepplyThread}
                    />
                )}
                {modalEmailRecipientsState.open && (
                    <ModalEmailRecipients
                        handleClose={handleModalEmailRecipientsStateClose}
                        {...modalEmailRecipientsState}
                        t={t}
                    />
                )}
            </Paper>
        );
    } else if (emailList.status !== "success") {
        return <Loading />;
    } else {
        return null;
    }
};

interface IAplicantEmailThreadListItem {
    items: Compleo.IObject[];
    language: string;
    t: any;
    openModalViewEmail: (
        globalId: string,
        recipients: Compleo.IObject[],
        fromEmail: Compleo.IObject
    ) => void;
}
const AplicantEmailThreadListItem = (props: IAplicantEmailThreadListItem) => {
    const { items, language, t, openModalViewEmail } = props;
    const classes = useStyles();
    const [data] = useApplicantViewContext();

    // const sortedItems =
    items.sort(function (a, b) {
        return a.createdAt < b.createdAt
            ? -1
            : a.createdAt > b.createdAt
            ? 1
            : 0;
    });

    return (
        <List component="div" disablePadding dense>
            {items.map((item) => {
                const hasAttachments = item.hasAttachments === true;
                const isFromApplicant =
                    data.applicantData?.email === item.userFrom?.email;

                return (
                    <React.Fragment key={item.skGS1pk}>
                        <ListItem
                            key={item.skGS1pk}
                            button
                            className={classes.nested}
                            onClick={() =>
                                openModalViewEmail(
                                    item.emailGlobalId,
                                    item?.recipients || [],
                                    item.userFrom || {}
                                )
                            }
                        >
                            <ListItemAvatar>
                                {isFromApplicant ? (
                                    <CompleoAvatar
                                        idParams={{ id: data.applicantId }}
                                        type="applicant"
                                        className={classes.avatarsmall}
                                    />
                                ) : (
                                    <CompleoAvatar
                                        idParams={{
                                            email: item.userFrom?.email
                                        }}
                                        type="user"
                                        className={classes.avatarsmall}
                                    />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    item.userFrom?.name || item.userFrom?.email
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {getFirstNWordsFromText(
                                                item.startOfBody || "",
                                                5
                                            ) || item.subject}
                                        </Typography>
                                        {" - "}
                                        {transformDBData({
                                            t: t,
                                            language: language,
                                            value: item.createdAt,
                                            customDateFormat: "dateRelative"
                                        })}
                                    </React.Fragment>
                                }
                            />
                            {hasAttachments && (
                                <ListItemIcon>
                                    <AttachFileIcon />
                                </ListItemIcon>
                            )}
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default AplicantEmailThreadList;
