import React from "react";
// import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import { useFormikContext, Formik, Form, Field } from "formik";
import { Grid } from "@material-ui/core";
import TextFieldCompleo from "./TextField";
import GenericFormatField from "./GenericFormatField";
import { useApiCache } from "customHooks/useApi";
import ComboAutoComplete from "./ComboAutoComplete";
import countries from "apiCompleo/CountryStateCity";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface IProps {
    metadata: any;
    t: any;
    classes: any;
    required: boolean;
}
const getUFLabel = (uf: string, estado: string) => {
    return {
        label: `${estado} (${uf})`,
        value: uf
    };
};

const getCityLabel = (
    uf: string,
    id_cidade: string,
    cidade: string,
    latitude?: number,
    longitude?: number
) => {
    return {
        label: cidade,
        value: id_cidade,
        uf: uf,
        latitude: latitude,
        longitude: longitude
    };
};

const Address = (props: IProps) => {
    const [tlocal, i18n, readyTranslation] = useTranslation("ADDRESSFIELD", {
        useSuspense: false
    });
    const [zipCode, setZipCode] = React.useState("");
    const [ufState, setUfState] = React.useState("");
    const [countryState, setCountryState] = React.useState("");

    const formik = useFormikContext();
    const values: any = formik.values;
    const { metadata, t, classes, required } = props;

    const countryName = `${metadata.fieldName}.country`;
    const postalCode = `${metadata.fieldName}.postalCode`;
    const addressline1 = `${metadata.fieldName}.addressline1`;
    const addressline2 = `${metadata.fieldName}.addressline2`;
    const houseNumber = `${metadata.fieldName}.number`;
    const neighborhood = `${metadata.fieldName}.neighborhood`;
    const city = `${metadata.fieldName}.city`;
    const provinceOrState = `${metadata.fieldName}.provinceOrState`;
    const latitude = `${metadata.fieldName}.latitude`;
    const longitude = `${metadata.fieldName}.longitude`;
    const ibgecode_city = `${metadata.fieldName}.ibgeCodeCity`;
    const ibgecode_state = `${metadata.fieldName}.ibgeCodeState`;

    let fieldsToRequire: string[] = [];
    if (metadata.requiredType !== undefined) {
        fieldsToRequire = metadata.requiredType.split("|");
    }
    const urlCEP = `/general/getaddress/${zipCode}`;
    const [addressReturn, callGetAddress] = useApiCache(
        urlCEP,
        "get",
        {},
        true,
        1000 * 60 * 5
    );

    const urlStates = `/general/listufs`;
    const [ufsReturn, callGetUFS] = useApiCache(
        urlStates,
        "get",
        {},
        false,
        1000 * 60 * 60
    );

    let messageCEP = "";
    if (addressReturn.status === "success") {
        const data = addressReturn.response.data;
        if (data.endereco === undefined) {
            messageCEP = tlocal("messageZipCodeNotFound");
        }
    }

    const urlCities = `/general/listcities/${ufState}`;
    const [citiesReturn, callGetCities] = useApiCache(
        urlCities,
        "get",
        {},
        true,
        1000 * 60 * 60
    );

    React.useEffect(() => {
        if (zipCode !== "" && countryState === "30") {
            debugger;
            callGetAddress({}, urlCEP);
        }
    }, [zipCode]);

    React.useEffect(() => {
        if (ufState !== "" && countryState === "30") {
            // debugger;
            // formik.setFieldValue(city, null);
            callGetCities({}, urlCities);
            // if (addressReturn.status === "success") {
            //     const data = addressReturn.response.data;
            //     if (data.endereco === undefined) {
            //         formik.setFieldValue(city, null);
            //     }
            // } else {
            //     formik.setFieldValue(city, null);
            // }
        }
        if (ufState !== "" && countryState !== "30") {
            formik.setFieldValue(city, null);
        }
    }, [ufState]);

    React.useEffect(() => {
        if (
            ufState !== "" &&
            countryState === "30" &&
            addressReturn.status === "success"
        ) {
            const data = addressReturn.response.data;
            if (data.endereco === undefined) {
                formik.setFieldValue(city, null);
            }
        }
    }, [ufState, addressReturn.status]);

    // React.useEffect(() => {
    //     if (countryState !== "") {
    //         debugger;
    //         formik.setFieldValue(provinceOrState, null);
    //         formik.setFieldValue(city, null);
    //         formik.setFieldValue(latitude, undefined);
    //         formik.setFieldValue(longitude, undefined);
    //         formik.setFieldValue(ibgecode_city, undefined);
    //         formik.setFieldValue(ibgecode_state, undefined);
    //     }
    // }, [countryState]);

    React.useEffect(() => {
        if (addressReturn.status === "success" && countryState === "30") {
            debugger;
            const data = addressReturn.response.data;
            if (data.endereco !== undefined) {
                formik.setFieldValue(addressline1, data.endereco);
                formik.setFieldValue(neighborhood, data.bairro);
                formik.setFieldValue(
                    provinceOrState,
                    getUFLabel(data.uf, data.estado)
                );
                formik.setFieldValue(
                    city,
                    getCityLabel(
                        data.uf,
                        data.id_cidade,
                        data.cidade,
                        data.cidade_latitude,
                        data.cidade_longitude
                    )
                );
                formik.setFieldValue(latitude, data.latitude);
                formik.setFieldValue(longitude, data.longitude);
                formik.setFieldValue(ibgecode_city, data.cidade_cod_ibge);
                formik.setFieldValue(ibgecode_state, data.uf_cod_ibge);
            } else {
                formik.setFieldValue(latitude, undefined);
                formik.setFieldValue(longitude, undefined);
                formik.setFieldValue(ibgecode_city, undefined);
                formik.setFieldValue(ibgecode_state, undefined);
            }
        }
    }, [addressReturn.response.data]);

    let states: Compleo.IObject[] = [];
    if (countryState === "30") {
        if (
            ufsReturn.status === "success" &&
            ufsReturn.response.data &&
            ufsReturn.response.data.ufs &&
            Array.isArray(ufsReturn.response.data.ufs)
        ) {
            states = ufsReturn.response.data.ufs.map((u: any) => {
                return getUFLabel(u.uf, u.estado);
            });
        }
    } else {
        states = countries.getStatesOfCountry(countryState).map((s: any) => {
            return {
                value: s.id,
                label: s.name,
                country_id: s.country_id
            };
        });
    }

    let cities: Compleo.IObject[] = [];
    if (countryState === "30") {
        if (
            citiesReturn.status === "success" &&
            citiesReturn.response.data &&
            Array.isArray(citiesReturn.response.data)
        ) {
            cities = citiesReturn.response.data.map((c: any) => {
                return getCityLabel(
                    c.uf,
                    c.idCidade,
                    c.cidade,
                    c.latitude,
                    c.longitude
                );
            });
        }
    } else {
        // console.log(countries.getCitiesOfState(ufState));
        cities = countries.getCitiesOfState(ufState).map((s: any) => {
            return {
                value: s.id,
                label: s.name,
                state_id: s.state_id
            };
        });
    }

    const countriesList = countries.getAllCountries().map((c: any) => {
        return {
            value: c.id,
            label: `${c.name} (${c.sortname})`
        };
    });

    const formikPostalCode = _.get(values, postalCode);
    if (formikPostalCode !== undefined && formikPostalCode !== zipCode) {
        setZipCode(formikPostalCode);
    }

    const formikState = _.get(values, provinceOrState);
    if (
        formikState !== undefined &&
        formikState !== null &&
        formikState.value !== ufState
    ) {
        setUfState(formikState.value);
    }

    const formikCountry = _.get(values, countryName);
    if (
        formikCountry !== undefined &&
        formikCountry !== null &&
        formikCountry.value !== countryState
    ) {
        setCountryState(formikCountry.value);
    }

    // Remove formatting and limit for another countries
    let format: string | undefined = "#####-###";
    let maxPostalCodeLength = 9;
    let PostalCodeComponent: any = GenericFormatField;
    if (countryState !== "30") {
        format = undefined;
        maxPostalCodeLength = 15;
        PostalCodeComponent = TextFieldCompleo;
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={9}>
                    <Field
                        required={fieldsToRequire.includes(
                            countryName.replace(`${metadata.fieldName}.`, "")
                        )}
                        name={countryName}
                        label={tlocal("country")}
                        options={countriesList}
                        className={classes.textField}
                        component={ComboAutoComplete}
                        campoMetadata={metadata}
                        helperText={tlocal(`country_helperText`, "")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Field
                        type="text"
                        name={postalCode}
                        label={tlocal("postalCode")}
                        helperTextDefault={messageCEP}
                        // fullWidth
                        className={classes.textField}
                        component={PostalCodeComponent}
                        required={fieldsToRequire.includes(
                            postalCode.replace(`${metadata.fieldName}.`, "")
                        )}
                        format={format}
                        maxLength={maxPostalCodeLength}
                        error={messageCEP !== ""}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={9}>
                    <Field
                        type="text"
                        name={addressline1}
                        label={tlocal("addressline1")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            addressline1.replace(`${metadata.fieldName}.`, "")
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Field
                        type="text"
                        name={houseNumber}
                        label={tlocal("number")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            houseNumber.replace(`${metadata.fieldName}.`, "")
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Field
                        type="text"
                        name={addressline2}
                        label={tlocal("addressline2")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            addressline2.replace(`${metadata.fieldName}.`, "")
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field
                        type="text"
                        name={neighborhood}
                        label={tlocal("neighborhood")}
                        fullWidth
                        className={classes.textField}
                        component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            neighborhood.replace(`${metadata.fieldName}.`, "")
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Field
                        required={fieldsToRequire.includes(
                            provinceOrState.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                        name={provinceOrState}
                        label={tlocal("provinceOrState")}
                        options={states}
                        className={classes.textField}
                        component={ComboAutoComplete}
                        helperText={t(`${provinceOrState}_helperText`, "")}
                        campoMetadata={metadata}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                        name={city}
                        label={tlocal("city")}
                        options={cities}
                        className={classes.textField}
                        component={ComboAutoComplete}
                        required={fieldsToRequire.includes(
                            city.replace(`${metadata.fieldName}.`, "")
                        )}
                        campoMetadata={metadata}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Address;
