import { formatDate } from "functions/formatValues";
import _ from "lodash";

export const defaultMapProps: CompleoShared.Dashboard.IMapPropsWidget[] = [
    { source: "doc_count", target: "value" },
    { source: "key.label", target: "name" }
];
interface IGetWidgetDataParams {
    data: Compleo.IObject[];
    widgetName: string;
    widgetDef: CompleoShared.Dashboard.widgetsMetadata;
    mapProps?: CompleoShared.Dashboard.IMapPropsWidget[];
    language: string;
}
export const getWidgetData = (params: IGetWidgetDataParams) => {
    const { data, widgetName, widgetDef, language, mapProps } = params;
    const bucketsData: Compleo.IObject[] = data || [];

    const dataReturn = bucketsData.map((item: Compleo.IObject) => {
        if (mapProps) {
            const objReturn: Compleo.IObject = {};
            for (const mapData of mapProps) {
                const value = transformData(
                    _.get(item, mapData.source),
                    mapData,
                    language
                );
                objReturn[mapData.target] = value;
            }
            const currentFieldMapped = Object.keys(objReturn);
            const fieldsNotMapped = Object.keys(item).filter(
                (item) => !currentFieldMapped.includes(item)
            );
            for (const fieldNotMapped of fieldsNotMapped) {
                objReturn[fieldNotMapped] = item[fieldNotMapped];
            }

            return objReturn;
        } else {
            return item;
        }
    });
    return dataReturn;
};

const transformData = (
    value: any,
    mapData: CompleoShared.Dashboard.IMapPropsWidget,
    language: string
) => {
    if (value) {
        switch (mapData.transformDataFunction) {
            case "dateToMonthYear":
                return formatDate(value, language, "monthYear");
            case "dayMonthYear":
                return formatDate(value, language, "dayMonthYear");
            default:
                return value;
        }
    } else {
        return value;
    }
};

export const gridLayoutSizes = ["lg", "md", "sm", "xs", "xxs"];
