import { UseCompleoViewCustomComponents } from "customHooks/useCompleoView";
import { keepOnlyNumbersInString } from "functions/util";
import React from "react";
import {
    FieldDefault,
    FieldTitle,
    IFieldProps,
    FieldTags,
    FieldJobStatus,
    FieldHtml,
    FieldAddress,
    FieldNumberRange,
    FieldComplexField,
    FieldPhone,
    FieldViewFile,
    FieldFile,
    FieldViewMultipleFiles,
    FieldScorecard
} from "../FieldsType";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    type?: string;
    t: any;
    language: string;
    fieldDef: Compleo.IObject;
    completeMetadata: Compleo.IObject[];
    inline?: boolean;
    hideComplexFieldsDetail?: boolean;
    CustomComponents: UseCompleoViewCustomComponents[];
    addProps?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    customCVsData?: Compleo.IObject[];
    applicantId?: string;
}

const FieldSwitchType = (props: IProps) => {
    const {
        type,
        fieldData,
        fieldName,
        t,
        language,
        fieldDef,
        inline,
        completeMetadata,
        hideComplexFieldsDetail = true,
        CustomComponents,
        addProps = [],
        customCVsData,
        applicantId
    } = props;

    const propsToAdd =
        addProps.filter((item) => item.fieldName === fieldName)[0]?.props || {};

    const propsField: IFieldProps = {
        fieldName,
        fieldData,
        t,
        language,
        fieldDef,
        inline,
        propsToAdd: { ...propsToAdd }
    };

    // não renderiza unicamente um campo de detalhe do Complex Field
    if (
        hideComplexFieldsDetail &&
        fieldDef.complexFieldDetail !== undefined &&
        fieldDef.complexFieldDetail === true
    ) {
        return null;
    }

    switch (type) {
        case "address":
            return <FieldAddress {...propsField} />;
        case "tagsCreatableArray":
        case "tags":
            return <FieldTags {...propsField} />;
        case "numberrange":
            return <FieldNumberRange {...propsField} />;
        case "richtext":
        case "richtextv2":
            return <FieldHtml {...propsField} />;
        case "phone":
            return <FieldPhone {...propsField} />;
        case "file": {
            if (fieldDef?.viewOptionProps?.type === "viewPDF") {
                if (customCVsData && customCVsData.length > 0) {
                    return (
                        <FieldViewMultipleFiles
                            customCVsData={customCVsData}
                            completeMetadata={completeMetadata}
                            {...propsField}
                        />
                    );
                }
                return <FieldViewFile {...propsField} />;
            } else {
                return <FieldFile {...propsField} />;
            }
        }
        case "multipleFiles": {
            return <FieldFile {...propsField} />;
        }
        case "custom": {
            const ComponentData = CustomComponents.filter(
                (item) => item.fieldName === fieldName
            )[0];
            if (ComponentData) {
                const ComponentElement = ComponentData.Component;
                return <ComponentElement {...propsField} />;
            } else {
                return null;
            }
        }
        case "complexField":
            return (
                <FieldComplexField
                    {...propsField}
                    completeMetadata={completeMetadata}
                />
            );
        case "jobCode": {
            fieldData.jobCode = (fieldData?.pk || "").split(":")[1];
            return <FieldDefault {...propsField} fieldData={fieldData} />;
        }
        case "jobNumber": {
            const code = Number(
                keepOnlyNumbersInString((fieldData?.pk || "").split(":")[1]) ||
                    0
            );
            fieldData.jobNumber = code;
            return <FieldDefault {...propsField} fieldData={fieldData} />;
        }
        case "scorecard":
            return <FieldScorecard {...propsField} fieldData={fieldData} />;
        default:
            return <FieldDefault {...propsField} />;
    }
};

export default FieldSwitchType;
