import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import {
    getFieldsToHideBulkActionChangeStage,
    IListBulk,
    getT,
    BulkActionChangeStageOptions,
    getBulkActionChangeStageStagesList
} from "./helperBulkActions";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata, {
    useGetMetadataMerge
} from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import useJobContext from "Pages/Job/JobView/useJobContext";
import _ from "lodash";
import {
    getStateFromDB,
    getTotalStageFromDb,
    handleOrderData
} from "Pages/Job/JobView/JobKanban/helper";
import useRefreshKanban from "Pages/Job/JobView/JobKanban/useRefreshKanban";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    otherGenericParamsListQuery?: Compleo.IObject;
    action: BulkActionChangeStageOptions;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkActionsStep3ChangeStage = (props: IProps) => {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const { company } = useAuthState();

    // only works inside the JOBVIEW context
    const [data, dispatchData] = useJobContext();

    const {
        formikRef,
        listBulk,
        setStepBulk,
        otherGenericParamsListQuery,
        action,
        setLoadingButtons
    } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKADDTOSTAGE";

    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);
    const fieldsToHide = getFieldsToHideBulkActionChangeStage(action);
    const language = i18n.languages[0];

    const [initialValues] = useValuesFromSource(metadata, false, {
        newStagePosition: { value: "end", label: tMerge("endStageOption") },
        disqualifyReason: {
            value: "CP0",
            label: language === "pt-BR" ? "Não informado" : "Not informed"
        }
    });
    const updateAllInfo = useRefreshKanban();

    const ready = readyTranslation && metadata.status === "success";
    const currentStageId = otherGenericParamsListQuery?.filterStageId;

    const stageData = getBulkActionChangeStageStagesList(
        data.LocalStateData.allStages,
        action,
        currentStageId
    );

    const customList: Compleo.ListDefinitionType[] = [];
    if (readyTranslation) {
        const listPositionData = [
            { label: tMerge("beginStageOption"), value: "begin" },
            { label: tMerge("endStageOption"), value: "end" }
        ];
        customList.push({
            fieldName: "newStagePosition",
            data: listPositionData,
            valueName: "value",
            labelName: "label"
        });
    }
    if (stageData.length) {
        customList.push({
            fieldName: "stage",
            data: stageData,
            valueName: "value",
            labelName: "label"
        });
        initialValues.stage = stageData[0];
    }

    const handleSubmit = async (values: any) => {
        // const type = values.filterType.value;
        // setStepBulk(-1);
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);

        const dataPost: Compleo.IObject = {
            companyId: company.companyId,
            ids: ids,
            job: { value: `JOB:${data.JobId}` },
            name: values.name,
            stageId: values.stage?.value,
            addToEndOfList: values.newStagePosition?.value === "end",
            oldStageId: currentStageId,
            type: "applicantjob_addToStage"
        };

        if (action === "disqualify") {
            dataPost.disqualifyReason = values.disqualifyReason;
            dataPost.disqualifyApplicants = true;
        }
        if (action === "requalify") {
            dataPost.requalify = true;
        }

        try {
            const dataAPI = await apiDirectCall(
                "/bulkactions/baadd",
                "post",
                dataPost
            );
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        await updateAllInfo();
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);

        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef,
        customLists: customList,
        fieldsToHide: fieldsToHide,
        formikEnableReinitialize: false
    });

    let FormReturn: JSX.Element = <Loading />;
    if (ready) {
        FormReturn = formCompleo;
    }

    return FormReturn;
};

export default BulkActionsStep3ChangeStage;
