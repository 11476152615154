import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
// import useCompleoForm from "customHooks/useCompleoForm";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";

const BranchNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/branch/list");
    // const classes = useFormStyles();
    const [t, i18n, readyTranslation] = useTranslation("branchnew", {
        useSuspense: false
    });

    const postAddress = `/branch/new`;

    const [addBranchReturn, addBranch] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata("BRANCHNEW");

    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    valuesFromSource.deleted = false;

    // const [formCompleo, finished] = useCompleoForm(
    //     null,
    //     t,
    //     props,
    //     readyTranslation,
    //     i18n,
    //     addBranch,
    //     addBranchReturn,
    //     "/branch/list",
    //     FormClassic,
    //     metadata,
    //     valuesFromSource,
    //     null,
    //     null,
    //     undefined,
    //     cancelFunction
    // );

    const [formCompleo, finished] = useCompleoReactHookForm({
        FormType: FormClassic,
        i18nV: i18n,
        metadadosRetorno: metadata,
        postMethod: addBranch,
        postReturn: addBranchReturn,
        ready: readyTranslation,
        valuesFromSource: valuesFromSource,
        t: t,
        secondaryButtonFunction: cancelFunction,
        redirectAddress: "/branch/list"
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default BranchNew;
