import React from "react";
import PageExternal from "Pages/_Layouts/MainExternal";
import PageInternal from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate, RouteComponentProps } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useExternalHelper from "customHooks/useExternalHelper";
import { useApiCache } from "customHooks/useApi";
import EvaluationIncorrectDataMessage from "./components/EvaluationIncorrectDataMessage";
import { Alert } from "@material-ui/lab";
import { Box, Paper, Typography } from "@material-ui/core";
import EvaluationTabs from "./components/EvaluationTabs";
import EvaluationRequestApplicantList, {
    IEvaluationRequestApplicantListProps
} from "./components/EvaluationRequestApplicantList";
import JobReportsMain from "./Reports/JobReportsMain";

export type evaluationPageToRender = "reports" | "applicantList";
export const evaluationPagesToRender: evaluationPageToRender[] = [
    "reports",
    "applicantList"
];

export interface IEvalutionReportsDBReturn {
    label: string;
    value: string;
}

interface IProps extends RouteComponentProps {
    uuid: string;
    companyId?: string;
}

const EvaluationRequestMain = (props: IProps) => {
    const moduleName = "EVALUATIONREQUESTAPPLICANTLIST";
    const externalCompanyId = props.companyId;
    const [pageToRender, setPageToRender] = React.useState<
        evaluationPageToRender
    >("applicantList");

    useExternalHelper(externalCompanyId);
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const [
        incorrectUserOrCompanyEvaluation,
        setIncorrectUserEvaluation
    ] = React.useState<{
        incorrectUser: boolean;
        incorrectCompany: boolean;
        finished: boolean;
        userEvaluation?: string;
        currentUser?: string;
        companyEvaluation?: string;
        currentCompany?: string;
    }>({ incorrectUser: false, incorrectCompany: false, finished: false });

    const postAddressSearch = `/applicant/evaluationrequestexternalget`;
    const [getListResult] = useApiCache(
        postAddressSearch,
        "post",
        { id: props.uuid },
        false
    );
    const evaluationData = getListResult?.response?.data?.data || [];
    const jobData = getListResult?.response?.data?.jobData || {};
    const expired = getListResult?.response?.data?.expired === true;
    const firstItem = evaluationData[0];
    const { user, logout, company, allCompanies } = useAuthState();
    const companyId = externalCompanyId || company.companyId;

    const dynamicDashboardsList: IEvalutionReportsDBReturn[] = [];

    const reportsToShare: CompleoShared.Reports.IJobReportsToRender[] = (
        firstItem?.reportsToShare || []
    ).map((item: IEvalutionReportsDBReturn) => {
        if (item.value.includes("dashboardId:")) {
            dynamicDashboardsList.push(item);
            return "dashboard";
        } else {
            return item.value;
        }
    });

    const shareReports = reportsToShare.length > 0;
    const onlyReports = firstItem?.onlyReports === true;

    React.useEffect(() => {
        if (shareReports && pageToRender !== "reports") {
            setPageToRender("reports");
        }
    }, [shareReports]);
    const accessType:
        | CompleoShared.EvaluationRequest.IGetUserEvaluationTypeReturn
        | undefined = getListResult?.response?.data?.accessType;

    React.useEffect(() => {
        if (
            getListResult.status === "success" &&
            !incorrectUserOrCompanyEvaluation.finished &&
            companyId &&
            firstItem?.companyId
        ) {
            const sameUser =
                (firstItem?.evaluatorEmail || "").toLowerCase().trim() ===
                    user?.toLowerCase().trim() || !user;

            const sameCompany =
                companyId.toString() === firstItem?.companyId.toString() ||
                externalCompanyId !== undefined;

            if (!sameUser) {
                setIncorrectUserEvaluation({
                    incorrectUser: true,
                    incorrectCompany: false,
                    userEvaluation: firstItem?.evaluatorEmail,
                    currentUser: (user || "").toLowerCase().trim(),
                    finished: true
                });
            } else if (!sameCompany) {
                const currentCompany = company.companyFancyName;
                const companyEvaluation = allCompanies.filter(
                    (item) => item.pk === `COMPANY:${firstItem?.companyId}`
                )[0]?.CompanyFancyName;

                setIncorrectUserEvaluation({
                    incorrectUser: false,
                    incorrectCompany: true,
                    currentCompany: currentCompany || "",
                    companyEvaluation: companyEvaluation || "",
                    finished: true
                });
            } else {
                setIncorrectUserEvaluation({
                    incorrectUser: false,
                    incorrectCompany: false,
                    finished: true
                });
            }
        }
    }, [
        getListResult.status,
        incorrectUserOrCompanyEvaluation.finished,
        companyId,
        firstItem?.companyId
    ]);

    React.useEffect(() => {
        if (incorrectUserOrCompanyEvaluation.finished && !company.companyId) {
            setIncorrectUserEvaluation({
                incorrectUser: false,
                incorrectCompany: false,
                finished: false
            });
        }
    }, [companyId]);

    const logoutAndReload = async () => {
        await logout();
        setIncorrectUserEvaluation({
            incorrectUser: false,
            incorrectCompany: false,
            finished: false
        });
    };

    const Page = externalCompanyId ? PageExternal : PageInternal;

    const allReady =
        readyTranslation &&
        incorrectUserOrCompanyEvaluation.finished &&
        accessType;

    let compRetorno: null | JSX.Element = <Loading />;
    const pageDescription = onlyReports ? jobData?.title : t("a_FeatureName");

    if (!expired) {
        if (allReady) {
            if (
                !incorrectUserOrCompanyEvaluation.incorrectUser &&
                !incorrectUserOrCompanyEvaluation.incorrectCompany
            ) {
                return (
                    <Page
                        featureName=""
                        featureMainDescription={pageDescription}
                        maxWidth="xl"
                    >
                        <Box display="flex" flexDirection="column" width="100%">
                            {shareReports && !onlyReports && (
                                <Box>
                                    <EvaluationTabs
                                        pageToRender={pageToRender}
                                        setPageToRender={setPageToRender}
                                        pageToRenderList={
                                            evaluationPagesToRender
                                        }
                                        t={t}
                                    />
                                </Box>
                            )}
                            <Box>
                                <Paper square>
                                    <EvaluationSwitchPage
                                        pageToRender={pageToRender}
                                        pageProps={{
                                            accessType: accessType,
                                            companyId: companyId,
                                            evaluationId: props.uuid,
                                            external:
                                                externalCompanyId !== undefined
                                        }}
                                        jobId={firstItem?.jobId}
                                        reportsToShare={reportsToShare}
                                        dynamicDashboardsList={
                                            dynamicDashboardsList
                                        }
                                    />
                                </Paper>
                            </Box>
                            {/* </Box> */}
                        </Box>
                    </Page>
                );
            } else if (incorrectUserOrCompanyEvaluation.incorrectUser) {
                return (
                    <Page
                        featureName=""
                        featureMainDescription={t("a_FeatureName")}
                        maxWidth="xl"
                    >
                        <EvaluationIncorrectDataMessage
                            t={t}
                            alertMessageDef={{
                                messageId: "incorrectUser",
                                translateReplaceObj: {
                                    userRequest:
                                        incorrectUserOrCompanyEvaluation.userEvaluation,
                                    userLogged:
                                        incorrectUserOrCompanyEvaluation.currentUser
                                }
                            }}
                            logoutAndReload={logoutAndReload}
                        />
                    </Page>
                );
            } else {
                return (
                    <Page
                        featureName={t("a_FeatureName")}
                        featureMainDescription={t("a_FeatureMainDescription")}
                        maxWidth="xl"
                    >
                        <EvaluationIncorrectDataMessage
                            t={t}
                            alertMessageDef={{
                                messageId: "incorrectCompany",
                                translateReplaceObj: {
                                    currentCompany:
                                        incorrectUserOrCompanyEvaluation.currentCompany,
                                    companyEvaluation:
                                        incorrectUserOrCompanyEvaluation.companyEvaluation
                                }
                            }}
                        />
                    </Page>
                );
            }
        } else {
            return <Page>{compRetorno}</Page>;
        }
    } else {
        if (readyTranslation) {
            return (
                <Page
                    featureName={t("a_FeatureName")}
                    featureMainDescription={t("a_FeatureMainDescription")}
                    maxWidth="lg"
                >
                    <Alert severity="error">{t("expired")}</Alert>
                </Page>
            );
        } else {
            return (
                <Page>
                    <Loading />
                </Page>
            );
        }
    }
};

interface IEvaluationSwitchPage {
    pageToRender: evaluationPageToRender;
    pageProps: IEvaluationRequestApplicantListProps;
    jobId: string;
    reportsToShare: CompleoShared.Reports.IJobReportsToRender[];
    dynamicDashboardsList: IEvalutionReportsDBReturn[];
}
const EvaluationSwitchPage = (props: IEvaluationSwitchPage) => {
    const {
        pageToRender,
        pageProps,
        jobId,
        reportsToShare,
        dynamicDashboardsList
    } = props;

    switch (pageToRender) {
        case "applicantList":
            return <EvaluationRequestApplicantList {...pageProps} />;
        case "reports":
            return (
                <Box p={2}>
                    <JobReportsMain
                        jobId={jobId}
                        jobTitle={jobId}
                        externalEvaluationId={pageProps.evaluationId}
                        allowedReports={reportsToShare}
                        dynamicDashboardsList={dynamicDashboardsList}
                        companyId={Number(pageProps?.companyId)}
                    />
                </Box>
            );
        default:
            return null;
    }
};

export default EvaluationRequestMain;
