import { ListDefinitionType } from "Pages/Users/components/useCustomLists";
import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { IJobInitialValuesDBData } from "./helper";

const useHandleInitialValuesJobRequestNew = (
    valuesFromSource: Compleo.IObject,
    jobInitialValuesDBData: IJobInitialValuesDBData,
    listDefinition: ListDefinitionType[],
    readyLists: boolean
) => {
    const [initialValues, setInitialValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: { ...valuesFromSource } });

    const { user, company } = useAuthState();
    const [
        applicationFormDefaultValues,
        setApplicationFormDefaultValues
    ] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    const [pipelineDefaultValues, setPipelineDefaultValues] = React.useState<{
        ready: boolean;
        values: Compleo.IObject;
    }>({ ready: false, values: {} });

    React.useEffect(() => {
        if (
            readyLists &&
            !initialValues.ready &&
            valuesFromSource.title !== undefined
        ) {
            let defaultValues: Compleo.IObject = {};
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            defaultValues.status = "requested";
            defaultValues.applicationForm = {};
            defaultValues.pipeline = {};
            defaultValues.openingDate = currentDate.toISOString();

            const requesterList = listDefinition.filter(
                (l) => l.fieldName === "requester"
            );
            if (
                requesterList &&
                requesterList.length > 0 &&
                requesterList[0].data &&
                Array.isArray(requesterList[0].data)
            ) {
                const defaultRequester = requesterList[0].data.filter(
                    (r) => r.itemValue === user
                );
                if (defaultRequester && defaultRequester.length > 0) {
                    defaultValues.requester = {
                        value: defaultRequester[0].itemValue,
                        label: defaultRequester[0].itemText
                    };
                }
            }
            setInitialValues({
                ready: true,
                values: { ...valuesFromSource, ...defaultValues }
            });
        }
    }, [readyLists, valuesFromSource]);

    React.useEffect(() => {
        if (jobInitialValuesDBData.defaultApplicationFrom) {
            const defaultAF = jobInitialValuesDBData.defaultApplicationFrom;
            setApplicationFormDefaultValues({
                ready: true,
                values: {
                    applicationForm: {
                        name: defaultAF.name,
                        id: defaultAF.skGS1pk,
                        formData: defaultAF.formData,
                        translations: defaultAF.translations
                    }
                }
            });
        }
    }, [jobInitialValuesDBData.defaultApplicationFrom]);

    // load default pipeline
    React.useEffect(() => {
        if (jobInitialValuesDBData.defaultPipeline) {
            const defaultPipeline = jobInitialValuesDBData.defaultPipeline;
            setPipelineDefaultValues({
                ready: true,
                values: {
                    pipeline: {
                        name: defaultPipeline.name,
                        id: defaultPipeline.skGS1pk,
                        stages: defaultPipeline.stages
                    }
                }
            });
        }
    }, [jobInitialValuesDBData.defaultPipeline]);

    const finalValues = {
        ...valuesFromSource,
        ...initialValues.values,
        ...applicationFormDefaultValues.values,
        ...pipelineDefaultValues.values
    };

    // useHandleInitialValues
    const ready = initialValues.ready;

    return {
        initialValues: {
            ...finalValues
        },
        ready: ready
    };
};

export default useHandleInitialValuesJobRequestNew;
