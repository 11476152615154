import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from "@material-ui/core";
import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        }
    })
);

interface IProps {
    bucketsDef: Compleo.IObject[];
    setBucketsDef: React.Dispatch<React.SetStateAction<Compleo.IObject[]>>;
}

const SettingsModalList = (props: IProps) => {
    const classes = useStyles();
    // const [listData, listDispatch] = useList();
    const { bucketsDef, setBucketsDef } = props;

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            const items = [...bucketsDef];
            const itemToMove = items.filter(
                (item) => item.key === draggableId
            )[0];
            items.splice(source.index, 1);
            items.splice(destination.index, 0, itemToMove);
            const orderedItems = items.map((item, index) => {
                return { ...item, order: index + 1 };
            });
            setBucketsDef(orderedItems);
        }
    };

    return (
        <List component="nav" className={classes.root}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="mainDroppable"
                    direction="vertical"
                    type="forms"
                >
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {bucketsDef
                                .sort((a, b) => a.order - b.order)
                                .map((item, index) => (
                                    <React.Fragment key={item.key}>
                                        <Draggable
                                            draggableId={item.key}
                                            index={index}
                                        >
                                            {(
                                                provided: DraggableProvided,
                                                snapshot: DraggableStateSnapshot
                                            ) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ListItem
                                                        dense
                                                        button
                                                        alignItems="center"
                                                        style={{
                                                            margin: 4
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <Tooltip title="">
                                                                <Icon color="action">
                                                                    drag_indicator
                                                                </Icon>
                                                            </Tooltip>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={item.name}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            )}
                                        </Draggable>
                                    </React.Fragment>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </List>
    );
};

export default SettingsModalList;
