import React from "react";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

const CheckboxControl = (props: IInputProps) => {
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;
    const { error, message, field, watch } = useRhfFieldControlled(
        name,
        helperTextDefault
    );
    const classes = useStyles();
    const valueFromFormik = watch(field.name) === true;

    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }

    return (
        <FormControl error={error} className="items-center">
            <FormControlLabel
                className={classError}
                control={
                    <Checkbox
                        {...other}
                        onChange={field.onChange}
                        name={field.name}
                        checked={valueFromFormik}
                    />
                }
                label={label}
                // checked={valueFromFormik}
                // value={valueFromFormik}
                // inputRef={field.ref}
            />
            {error && <FormHelperText>{message}</FormHelperText>}
        </FormControl>
    );
};

export default CheckboxControl;
