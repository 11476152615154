import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useDashboardContext from "../useDashboardContext";
import { Box, Button, Chip, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#00a152",
            padding: 8
        }
    });
});

const DashboardCustomNotFound = () => {
    const classes = useStyles();
    const [data, dispatch] = useDashboardContext();
    const { t } = data;
    const { dispatchGeneralPageInfo } = dispatch;

    const newDashboard = () => {
        const currentDef = _.cloneDeep(data.localStateData.currentDashboardDef);
        const newDef = _.cloneDeep(data.localStateData.mainDashboardDef);
        dispatchGeneralPageInfo({
            payload: {
                editMode: true,
                currentDashboardDef: newDef,
                currentDashboardStateBeforeEditOrNewDef: currentDef,
                newMode: true
            },
            type: "update"
        });
    };

    return (
        <Box m={3} p={2}>
            <Alert severity="info">
                {t("noCustomDashboardMessage")}{" "}
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={newDashboard}
                >
                    {t("ModalMainMenu_newDashboard")}
                </Button>
            </Alert>
        </Box>
    );
};

export default DashboardCustomNotFound;
