import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import {
    ApiCallType,
    apiDirectCall,
    ApiStateType,
    useApiCache
} from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useApplicantViewContext from "../useApplicantViewContext";
import EvaluationScorecard from "./components/EvaluationScorecard";
import useJobScorecardData from "./components/useJobScorecardData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    jobData: Compleo.IObject | null;
}

const CustomComponents = [
    {
        name: "scorecard",
        component: EvaluationScorecard
    }
];

const ModalRating = (props: IPropsModal) => {
    const { onClose, open, jobData } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);

    const queryClient = useQueryClient();
    const [data] = useApplicantViewContext();

    const { company } = useAuthState();
    const module = "APPLICANTVIEWEVALUATIONMODAL";
    const [metadata] = useGetMetadata(module, data.externalData?.companyId);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const postAddressSearch = !data.externalData
        ? `/applicant/getevaluation`
        : `/applicant/getevaluationexternal`;
    const paramsEvaluation = !data.externalData
        ? {
              applicantId: data.applicantId,
              companyId: company.companyId,
              jobId: jobData?.JobId
          }
        : {
              evaluationId: data.externalData.evaluationId,
              applicantId: data.applicantId
          };

    const [getEvaluationSearchResult, searchEvaluation] = useApiCache(
        postAddressSearch,
        "post",
        paramsEvaluation,
        false
    );

    // console.log("getEvaluationSearchResult", getEvaluationSearchResult);
    React.useEffect(() => {
        if (open) {
            searchEvaluation();
        }
    }, [open]);

    const currentScorecard =
        getEvaluationSearchResult.response.data?.data?.scorecard || [];
    const scorecardUseData = useJobScorecardData(
        jobData?.JobId,
        currentScorecard,
        getEvaluationSearchResult.status === "success",
        undefined,
        data.externalData?.evaluationId
    );

    const [initialValues] = useValuesFromSource(
        metadata,
        true,
        {
            applicantId: data.applicantId,
            jobId: jobData?.JobId,
            evaluationId: data.externalData?.evaluationId
        },
        getEvaluationSearchResult,
        ["data", "data"]
    );
    let fieldsToHide: string[] = [];
    if (scorecardUseData.ready && scorecardUseData.mainData.length) {
        initialValues.scorecard = scorecardUseData.mainData;
    } else {
        delete initialValues.scorecard;
        fieldsToHide = ["scorecard"];
    }

    const ready =
        readyTranslation &&
        metadata.status === "success" &&
        scorecardUseData.ready;

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        // console.log("values: ", JSON.stringify(values, null, 4));
        const postAddress = !data.externalData
            ? `/applicant/saveevaluation`
            : `/applicant/saveevaluationexternal`;

        try {
            const dataAPI = await apiDirectCall(postAddress, "post", values);
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries(data.queryKey);
                queryClient.invalidateQueries(`/applicant/getevaluation`);
                queryClient.invalidateQueries(
                    `/applicant/getevaluationexternal`
                );
                queryClient.invalidateQueries(
                    "/applicant/evaluationrequestexternalgetlist"
                );
                queryClient.invalidateQueries(`/job/applicantlist`);
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleSubmitRHF] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        CustomComponents: CustomComponents,
        fieldsToHide: fieldsToHide
    });

    const handleSave = async () => {
        await handleSubmitRHF();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            {ready && (
                <DialogTitle>
                    {t("modalTitle")}{" "}
                    {jobData?.JobTitle ? ` - ${jobData?.JobTitle}` : ""}
                </DialogTitle>
            )}
            <DialogContent dividers>{FormReturn}</DialogContent>
            {ready && (
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ModalRating;
