import React, { useReducer } from "react";
import {
    IDashboardMainState,
    IMainStateAction,
    IDashboardContextDispatch,
    LocalStateDataType,
    IDashOtherPropsType
} from "./DashboardTypes";
import { useAuthState } from "_ReactContext/AuthContext";
import { useTranslation } from "react-i18next";
import useGetMetadata from "customHooks/useGetMetadata";
import { getLastPageToRender } from "./_dashboardContextHelper";

const reducer = (
    state: LocalStateDataType,
    action: IMainStateAction
): LocalStateDataType => {
    switch (action.type) {
        case "update": {
            return { ...state, ...action.payload };
        }
    }
};

const DashboardContext = React.createContext<IDashboardMainState | undefined>(
    undefined
);
const DashboardContextDispatch = React.createContext<
    IDashboardContextDispatch | undefined
>(undefined);

interface IDashboardProvider {
    children: any;
    uniqueDashboardView?: boolean;
    dashOtherProps?: IDashOtherPropsType;
}

export const DashboardProvider = (props: IDashboardProvider) => {
    const { children, uniqueDashboardView = false, dashOtherProps } = props;
    const moduleName = "DASHBOARDMAIN";

    const defaultGeneralPageInfo: LocalStateDataType = {
        pageToRender: getLastPageToRender(),
        editMode: false,
        newMode: false,
        uniqueDashboardView: uniqueDashboardView
    };
    const [localStateData, dispatchLocalStateData] = useReducer(
        reducer,
        defaultGeneralPageInfo
    );
    const [metadata] = useGetMetadata(moduleName);

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const { company } = useAuthState();
    const ready = readyTranslation && metadata.status === "success";

    const returnMain: IDashboardMainState = {
        language: i18n.languages[0],
        t: t,
        companyId: company.companyId,
        ready: ready,
        metadata: metadata,
        localStateData: localStateData,
        dashOtherProps: dashOtherProps
    };
    const returnDispatch: IDashboardContextDispatch = {
        dispatchGeneralPageInfo: dispatchLocalStateData
    };

    return (
        <DashboardContext.Provider value={returnMain}>
            <DashboardContextDispatch.Provider value={returnDispatch}>
                {children}
            </DashboardContextDispatch.Provider>
        </DashboardContext.Provider>
    );
};

const useValues = () => {
    const context = React.useContext(DashboardContext);
    if (!context) {
        throw new Error(`useValues must be used within a JobViewProvider`);
    }
    const returnData = context;
    return returnData;
};

const useDispatch = () => {
    const context = React.useContext(DashboardContextDispatch);
    if (!context) {
        throw new Error(`useDispatch must be used within a DashboardProvider`);
    }
    const returnData = context;
    return returnData;
};

const useDashboardContext: () => [
    IDashboardMainState,
    IDashboardContextDispatch
] = () => {
    return [useValues(), useDispatch()];
};

export default useDashboardContext;
