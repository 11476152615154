import React from "react";

import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import { useAuthState } from "_ReactContext/AuthContext";

const useGetShortcutAddItems = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const { company, user } = useAuthState();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);
    const navigation: any = queryNavigation.data;
    const shortcutAddOptions: any[] = [];
    navigation.features.map((item: any) => {
        (item.items || []).map((menu: any) => {
            if (menu?.addToShorcutAddButton === true) {
                shortcutAddOptions.push(menu);
            }
        });
    });

    if (company.companyId.toString() === "10102") {
        shortcutAddOptions.push({
            uniqueName: "cadastrar_candidato_beta",
            route: "/applicant/new/upload?beta=1",
            icon: "post_add",
            addToShorcutAddButton: true,
            isMenuItem: true,
            requiresAuthentication: true,
            order: 3,
            group: "applicantsGroup"
        });
    }
    // const shortcutAddOptions = settingsMenuOptions?.items || [];
    shortcutAddOptions.sort((a: any, b: any) => a.order - b.order);

    return { anchorEl, setAnchorEl, openMenu, shortcutAddOptions };
};

export default useGetShortcutAddItems;

// ,
//                     "addToShorcutAddButton": true
