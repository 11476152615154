import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useApi, useApiCache } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useFormikCallBack from "./components/useFormikCallBack";
import { useAuthState } from "_ReactContext/AuthContext";
import usePlanLimits from "customHooks/usePlanLimits/usePlanLimits";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useFieldsToHide from "./components/useFieldsToHide";

const CareersSettingsEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/careers");
    const moduleName = "CAREERSSETTINGSEDIT";
    const { company } = useAuthState();
    const { getLimit } = usePlanLimits();
    const { callDialog } = useGlobalDialog();

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/careerssettings/edit`;
    const [customLists, readyLists] = useCustomLists();
    const [addDataReturn, addData] = useApi(postAddress, "post");

    const postAddressSearch = `/careerssettings/search`;
    const [getDataSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { uniqueLink: props.uniquelink, companyId: company.companyId },
        false
    );

    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getDataSearchResult,
        ["data", "data"]
    );
    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready:
            readyTranslation &&
            readyLists &&
            getDataSearchResult.status === "success",
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const finalReady =
        readyTranslation &&
        readyLists &&
        getDataSearchResult.status === "success";
    const { fieldsToHide } = useFieldsToHide(watch, finalReady);

    const editCareers = async (values: any) => {
        if (valuesFromSource?.enabled === false && values.enabled === true) {
            const dataLimit = await getLimit("addCareersSite");
            if (!dataLimit.allowAction) {
                callDialog({
                    title: t("PLANLIMIT_title"),
                    bodyText: `${t("cantEnableMessage")} ${t(
                        "PLANLIMIT_careersSiteMessage",
                        {
                            maxValue: dataLimit.maxValue,
                            currentTotal: dataLimit.currentTotal
                        }
                    )}`,
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    agreeFunction: async () => {
                        return false;
                    }
                });
                const retorno: Compleo.IObject = {};
                retorno.status = 999;
                return retorno;
            }
        }
        const returnFunction = await addData(values);
        return returnFunction;
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: finalReady,
        i18nV: i18n,
        postMethod: editCareers,
        postReturn: addDataReturn,
        redirectAddress: "/careers",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        customLists: customLists,
        fieldsToHide: fieldsToHide,
        invalidateQueryClientItems: [
            "/careerssettings/careersbyjob",
            "/careerssettings/search",
            "/careerssettings/list"
        ],
        reactHookFormMethods: reactHookFormMethods
    });
    const ready =
        readyTranslation &&
        finished &&
        readyLists &&
        getDataSearchResult.status === "success";

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CareersSettingsEdit;
