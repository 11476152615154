import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import { FieldProps } from "formik";
import _ from "lodash";

interface IProps {
    label: string;
    helperTextDefault: null | string;
    format: string;
    error: boolean;
}

const GenericFormatField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status },
        helperTextDefault,
        format,
        error,
        ...other
    } = props;
    const errorText = _.get(errors, field.name); // errors[field.name];
    const hasError = _.get(touched, field.name) && errorText !== undefined; // touched[field.name] && errorText !== undefined;
    let mensagemErro =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    const formikStatus = _.get(status, field.name);
    if (status && formikStatus) {
        if (mensagemErro === "") {
            mensagemErro += formikStatus;
        } else {
            mensagemErro += " - " + formikStatus;
        }
    }

    return (
        <NumberFormat
            id={field.name}
            label={label}
            helperText={mensagemErro}
            {...field}
            {...other}
            // format="##.###.###/####-##"
            error={error || hasError || (status && formikStatus !== undefined)}
            format={format}
            customInput={TextField}
        />
    );
};

GenericFormatField.defaultProps = {
    fullWidth: true
};

export default GenericFormatField;
