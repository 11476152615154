import { apiDirectCall } from "customHooks/useApi";
import { handleFileNameUpload } from "functions/util";
import _ from "lodash";
import { isFileFromDB } from "../Inputs/File/File";

export const TempEmailFieldName = "_tempEmailData";

export const getFilesFields = (values: any) => {
    const keys = Object.keys(values);
    const fileFields = keys.filter((v: any) => {
        return v.includes("_filesDefinition");
    });

    // complex Fields
    for (const key of keys) {
        if (Array.isArray(values[key])) {
            for (let index = 0; index < values[key].length; index++) {
                if (typeof values[key][index] === "object") {
                    const insideKeys = Object.keys(values[key][index]);
                    const fileFieldsInside = insideKeys
                        .filter((v: string) => {
                            return v.includes("_filesDefinition");
                        })
                        .map((v: any) => `${key}[${index}].${v}`);
                    fileFields.push(...fileFieldsInside);
                }
            }
        }
    }

    return fileFields.map((f: any) => f.replace("_filesDefinition", ""));
};

export const processUploadFileFields = async (
    values: any,
    companyId: number = 0
) => {
    const fileFields = getFilesFields(values);

    if (fileFields.length > 0) {
        const re = /(?:\.([^.]+))?$/;
        const filesAndExtensions: Compleo.IObject[] = [];
        for (const fieldToUpload of fileFields) {
            // const fieldNameToUpload = `${fieldToUpload}_filesDefinition`;
            // const files = values[fieldNameToUpload].values;
            // const files = _.get(values, fieldNameToUpload).values;
            const files = _.get(values, fieldToUpload);
            const isMultiple = Array.isArray(files);
            const fileList = isMultiple ? files : [files];
            if (files && fileList.length) {
                for (const fileDef of fileList) {
                    const file = fileDef?.FileInfo || fileDef;
                    if (!isFileFromDB(file)) {
                        const extension = (re.exec(file.name) || [])[1];
                        filesAndExtensions.push({
                            extension: extension,
                            originalFileName: handleFileNameUpload(file.name),
                            fieldName: fieldToUpload,
                            contentType: file.type
                        });
                    }
                }
            }
        }
        if (filesAndExtensions.length) {
            const axPost = await apiDirectCall(
                "/general/requests3url",
                "post",
                {
                    files: filesAndExtensions,
                    companyId: values.companyId || companyId
                }
            );
            const urls = axPost.data;
            for (const fieldToUpload of fileFields) {
                const fieldNameToUpload = fieldToUpload;
                // const fieldNameToUploadDef = `${fieldToUpload}_filesDefinition`;
                const valueDefinition: Compleo.IObject[] = [];
                const files = _.get(values, fieldNameToUpload);
                const isMultiple = Array.isArray(files);
                const fileList = isMultiple ? files : [files];
                // debugger;
                if (files && fileList.length && urls) {
                    for (const fileDef of fileList) {
                        const file = fileDef?.FileInfo;
                        if (!isFileFromDB(fileDef)) {
                            const returnData = urls.filter(
                                (u: any) =>
                                    u.originalFileName ===
                                        handleFileNameUpload(file.name) &&
                                    u.fieldName === fieldNameToUpload
                            )[0];
                            const signedUrl = returnData.uploadURL;
                            const filename = returnData.fileName;
                            const options = {
                                headers: {
                                    "Content-Type":
                                        returnData.contentType || file.type,
                                    "x-amz-tagging": `originalFileName=${handleFileNameUpload(
                                        file.name
                                    )}`
                                }
                            };
                            const axiosReturn = await apiDirectCall(
                                signedUrl,
                                "put",
                                file,
                                options,
                                true,
                                false
                            );
                            const itemToAdd: Compleo.IObject = {
                                key: filename,
                                originalFileName: file.name
                            };
                            if ((fileDef.tags || []).length) {
                                itemToAdd.tags = fileDef.tags;
                            }
                            valueDefinition.push(itemToAdd);
                        } else {
                            valueDefinition.push(fileDef);
                        }
                    }
                }
                if (isMultiple) {
                    _.set(values, fieldNameToUpload, valueDefinition);
                } else {
                    _.set(values, fieldNameToUpload, valueDefinition[0]);
                }
            }
        }
    }
    return values;
};

export const getEmailFields = (values: any) => {
    const fieldNames = Object.keys(values);
    const emailFields: string[] = [];
    for (const fieldName of fieldNames) {
        if (Array.isArray(values[fieldName]) && values[fieldName].length) {
            const firstItemArray = values[fieldName][0];
            const fieldToVerify = TempEmailFieldName;
            if (Object.keys(firstItemArray).includes(fieldToVerify)) {
                emailFields.push(fieldName);
            }
        }
    }
    return emailFields;
};

export const processEmailFields = async (values: any) => {
    const emailFields = getEmailFields(values);
    if (emailFields.length > 0) {
        for (const fieldName of emailFields) {
            const fieldData = values[fieldName];
            for (const fieldItem of fieldData) {
                await processEmailAttachments(fieldItem, values.companyId);
                await processEmailData(fieldItem, values.companyId);
            }
        }
    }

    // this block is just cloear tempEmailData before submit
    const clonedValues = _.cloneDeep(values);
    const emailFieldsToClear = getEmailFields(clonedValues);
    if (emailFieldsToClear.length > 0) {
        for (const fieldName of emailFieldsToClear) {
            const fieldData = clonedValues[fieldName];
            for (const fieldItem of fieldData) {
                delete fieldItem[TempEmailFieldName];
            }
        }
    }
    return clonedValues;
};

const processEmailAttachments = async (fieldItem: any, companyId: number) => {
    const tempData = fieldItem[TempEmailFieldName];
    if ((tempData.attachments || []).length) {
        let tempDataCloned = _.cloneDeep(tempData);
        tempDataCloned = await processUploadFileFields(
            tempDataCloned,
            companyId
        );
        fieldItem[TempEmailFieldName] = tempDataCloned;
    }
};

const processEmailData = async (fieldItem: any, companyId: number) => {
    const currentId = fieldItem.id;

    // clear path from attachments
    const attachments: { key: string; originalFileName: string }[] = [];

    for (const attachment of fieldItem[TempEmailFieldName].attachments || []) {
        attachments.push({
            key: attachment.key,
            originalFileName: attachment.originalFileName
        });
    }

    const postObj: Compleo.IObject = {
        companyId: companyId,
        subject: fieldItem[TempEmailFieldName].subject,
        body: fieldItem[TempEmailFieldName].body
    };

    if (attachments.length) {
        postObj.attachments = attachments;
    }
    if (currentId) {
        postObj.id = currentId;
    }

    const axPost = await apiDirectCall("/email/putemail", "post", postObj);
    const id = axPost.data?.id;
    fieldItem.id = id;
};

// "fefcb3a5-14ab-4835-97ac-f47933261a6c"
// "0a255bd0-7cb4-4298-b094-a208be3c12b2"
