import React from "react";
import { ApplicantViewProvider } from "./useApplicantViewContext";
import {
    ApplicantViewContainerType,
    ApplicantViewExternalData,
    ApplicantViewJobDataInsideJob,
    ApplicantViewModulesType
} from "./useApplicantViewContext/ApplicantViewTypes";
import ApplicantViewMain from "./ApplicantViewMain";

interface IProps {
    applicantId: string;
    containerType: ApplicantViewContainerType;
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    index: number;
    isInsideKanban?: boolean;
    tabOpen?: string;
    module: ApplicantViewModulesType;
    externalData?: ApplicantViewExternalData;
    openRatingOnStart?: boolean;
    customCVsData?: Compleo.IObject[];
    evaluationId?: string;
    cvToShare?: Compleo.IObject;
}

const ApplicantView = (props: IProps) => {
    const {
        applicantId,
        containerType,
        jobDataInsideJob,
        index,
        isInsideKanban = false,
        tabOpen,
        module,
        externalData,
        openRatingOnStart,
        customCVsData,
        evaluationId,
        cvToShare
    } = props;

    return (
        <ApplicantViewProvider
            applicantId={applicantId}
            containerType={containerType}
            jobDataInsideJob={jobDataInsideJob}
            index={index}
            isInsideKanban={isInsideKanban}
            tabOpen={tabOpen}
            module={module}
            externalData={externalData}
            openRatingOnStart={openRatingOnStart}
            customCVsData={customCVsData}
            evaluationId={evaluationId}
            cvToShare={cvToShare}
        >
            <ApplicantViewMain />
        </ApplicantViewProvider>
    );
};

export default ApplicantView;
