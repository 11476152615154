import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobContext from "../../useJobContext";
import { IJobHistoryProps } from "../JobHistoryHelper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: "flex",
            padding: theme.spacing(2),
            height: "100%",
            flexDirection: "column"
        },
        divider: {
            marginTop: theme.spacing(1)
        },
        comment: {
            textAlign: "left",
            overflow: "auto"
        },
        grow: {
            flexGrow: 1
        },
        viewIcon: {
            margin: theme.spacing(0.1),
            padding: theme.spacing(0.1)
        }
    })
);

function createMarkup(data: string) {
    return { __html: data };
}

const JobHistoryComment = (props: IJobHistoryProps) => {
    const classes = useStyles();
    const { historyItem, tLocal } = props;
    const [data] = useJobContext();
    const { t, JobId, language } = data;

    return (
        <Paper elevation={1} className={classes.paper}>
            <Typography variant="body2" className={classes.comment}>
                <div
                    dangerouslySetInnerHTML={createMarkup(historyItem.comment)}
                />
            </Typography>
            <div className={classes.grow} />
            <Divider className={classes.divider} />
            <Typography variant="caption">
                {historyItem.createdByUser}
            </Typography>
        </Paper>
    );
};

export default JobHistoryComment;
