import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import JobDateLimitFlagColumnRender, {
    getColorByTypeJobDateLimit,
    JobDateLimitType
} from "./JobDateLimitFlagColumnRender";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) => {
    const mainRoot = {
        width: "100%",
        color: "white"
    };
    return createStyles({
        root: {
            ...mainRoot
        },
        rootSeleted: {
            ...mainRoot,
            boxShadow: "0 3px 6px 0 blue"
        }
    });
});

interface IJobDateLimitCardFilters {
    title: string;
    type: JobDateLimitType;
    changeListPostData: (filterType: JobDateLimitType) => void;
    t: any;
    selected?: boolean;
}

const JobDateLimitCardFilters = (props: IJobDateLimitCardFilters) => {
    const classes = useStyles();
    const { title, type, changeListPostData, t, selected = false } = props;
    const bgColor = getColorByTypeJobDateLimit(type);
    return (
        <Card className={selected ? classes.rootSeleted : classes.root}>
            <CardHeader
                action={
                    <JobDateLimitFlagColumnRender
                        value={type}
                        iconSize="large"
                        allWhite
                    />
                }
                title={
                    <Typography variant="body1">
                        <strong>{title}</strong>
                    </Typography>
                }
                style={{
                    backgroundColor: bgColor
                }}
            />
            <CardActions>
                <Button
                    startIcon={<SearchIcon />}
                    variant="outlined"
                    style={{ marginLeft: "auto" }}
                    onClick={() => changeListPostData(type)}
                >
                    {t("filterJobs")}
                </Button>
            </CardActions>
        </Card>
    );
};

export default JobDateLimitCardFilters;
