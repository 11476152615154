import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { useCompleoSimpleList } from "customHooks/useCompleoSimpleList";
import RightButtonList from "./ParserComponents/RightButtonList";
import { ModalParserOptions } from "./ParserComponents/ModalParserOptions";
import React from "react";
import useActionsTableParserItem from "./components/useActionsTableParserItem";
import ModalAutomaticallyProcess from "./ParserComponents/ModalAutomaticallyProcess";
import { CopyToClipboardChip } from "Components/CopyToClipboard/CopyToClipboardChip";
import { CopyToClipboardEmailParser } from "Components/CopyToClipboard/CopyToClipboardEmailParser";
import { Box } from "@material-ui/core";
import { MainInstructions } from "./ParserComponents/MainInstructions";

const ParserOperationList = (props: any) => {
    const moduleName = "PARSEROPERATIONLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalAuto, setOpenModalAuto] = React.useState({
        open: false,
        operationId: "",
        refreshListFunction: () => {}
    });
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/parser/parseroperationlist";
    const { company } = useAuthState();
    const actionsTable = useActionsTableParserItem(setOpenModalAuto, t);
    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId,
            page: 1,
            pageSize: 5,
            orderBy: "createdAt",
            orderDirection: "desc"
        },
        actionsTable
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                RightButtonComponent={
                    <RightButtonList t={t} setOpenModal={setOpenModal} />
                }
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"}>
                        <MainInstructions />
                    </Box>
                    <Box display={"flex"}>{compRetorno}</Box>
                </Box>
                {openModal && (
                    <ModalParserOptions
                        onClose={() => setOpenModal(false)}
                        open={openModal}
                    />
                )}
                {openModalAuto.open && (
                    <ModalAutomaticallyProcess
                        onClose={() =>
                            setOpenModalAuto({
                                open: false,
                                operationId: "",
                                refreshListFunction: () => {}
                            })
                        }
                        open={openModalAuto.open}
                        operationId={openModalAuto.operationId}
                        refreshListFunction={openModalAuto.refreshListFunction}
                    />
                )}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ParserOperationList;
