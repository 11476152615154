import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { Icon } from "@material-ui/core";
import {
    actionsTableFunction,
    useCompleoSimpleList
} from "customHooks/useCompleoSimpleList";
import useCustomCVActionsTable from "./components/useCustomCVActionsTable";

const CustomCVList = (props: any) => {
    const moduleName = "CUSTOMCVLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    //const emailTemplateData = useEmailTemplate();
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/customcv/list";
    const { company } = useAuthState();

    const actions: actionsTableFunction = useCustomCVActionsTable(
        company.companyId,
        t
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
            // totalFakeDataMultiply: 650
        },
        actions
    );

    let compRetorno: null | JSX.Element = <Loading />;
    const ready = readyTranslation && finished;
    if (ready) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                rightButton={{
                    text: t("buttonTopRight"),
                    action: () => navigate(`/customcv/new`),
                    enabled: true,
                    startIcon: <Icon>add</Icon>
                }}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomCVList;
