import React from "react";
import ItemCard from "./ItemCard";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    returnType: "card" | "table";
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldDefault = (props: IProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, returnType } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const returnData =
        fieldData._distance === -1 || fieldData._distance === undefined
            ? "-"
            : `${Math.ceil(fieldData._distance)} km`;

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    label={
                        <Icon color="action" fontSize="small">
                            location_city
                        </Icon>
                    }
                    removeColonAfterLabel={true}
                >
                    {returnData}
                </ItemCard>
            );
        case "table":
            return <>{returnData}</>;
    }
};

export default FieldDefault;
