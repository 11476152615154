import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";

const CompleoModuleNew = (props: any) => {
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "ADMINCOMPLEOIDEASNEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [listDefinition, readyLists] = useCustomLists();

    const postAddress = `/admin/ideanew`;

    const [addUserReturn, addUser] = useApi(postAddress, "post");

    const [metadata] = useGetMetadata(moduleName);

    // const valuesFromSource = {
    //     name: "",
    //     companyId: company.companyId,
    //     submodules: []
    // };
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});
    // valuesFromSource.companyId = company.companyId;
    // console.log("valuesFromSource", valuesFromSource);

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addUser,
        addUserReturn,
        "/admincompleo/listcompleoidea",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined,
        null,
        null,
        listDefinition
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default CompleoModuleNew;
