import React from "react";
import PageInternal from "../_Layouts/DefaultInternal";
import PageExternal from "../_Layouts/DefaultExternal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import GenerateRoutes from "Config/useRoutes";
import Authenticated from "auth/Authenticated";
import UnAuthenticatedNotFound from "auth/UnAuthenticatedNotFound";
import { PageNotFoundComponent } from "Components/CompleoErrorMessage";

const PageNotFound = (props: any) => {
    const { isAuthenticated } = useAuthState();
    // return null;
    const routes: any[] = props.routes;

    if (isAuthenticated) {
        return (
            <Authenticated routes={routes} {...props}>
                <PageInternal>
                    <PageNotFoundComponent />
                </PageInternal>
            </Authenticated>
        );
    } else {
        return (
            <UnAuthenticatedNotFound routes={routes} {...props}>
                <PageExternal>
                    <PageNotFoundComponent />
                </PageExternal>
            </UnAuthenticatedNotFound>
        );
    }
};
export default PageNotFound;
