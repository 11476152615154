import { debounce } from "lodash";
import { apiDirectCall } from "customHooks/useApi/api";
import _ from "lodash";

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000;

const validEmailExistsAtCompany = async (email: string, companyId: number) => {
    const params = { email, companyId };
    const url = "/user/search";
    // return false;
    try {
        const user = await apiDirectCall(url, "post", params);
        return false;
    } catch {
        return true;
    }
};

export const validEmailExistsAtCompanyDebounced = debounce(
    validEmailExistsAtCompany,
    ASYNC_VALIDATION_TIMEOUT_IN_MS
);

const validGenericServer = async (
    fieldValueToValidate: string,
    companyId: number,
    validationType: string
) => {
    const params = { fieldValueToValidate, companyId, validationType };
    const url = "/general/genericvalidationlogged";

    try {
        const validation = await apiDirectCall(url, "post", params);
        return validation.data.validationResult;
    } catch {
        return false;
    }
};

export const validGenericServerDebounced = debounce(
    _.memoize(validGenericServer),
    ASYNC_VALIDATION_TIMEOUT_IN_MS
);
