import React from "react";
import ApplicantUploadComponent from "./ApplicantUploadComponent";
// import BranchAuthorization from "./UserBranchAccess";

const CustomComponents = [
    {
        name: "uploadCV",
        component: ApplicantUploadComponent
    }
];

export default CustomComponents;
