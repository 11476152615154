import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useList from "../..";
import {
    ButtonGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {
    getBulkActionsList,
    BulkActionsType,
    IBulkActions
} from "./helperBulkActions";
import { useAuthState } from "_ReactContext/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    action: IBulkActions | null;
    setAction: (type: IBulkActions) => void;
    BulkActionsRemoveFromList: BulkActionsType[];
}

const BulkActionsStep1 = (props: IProps) => {
    const classes = useStyles();
    const { setAction, action, BulkActionsRemoveFromList } = props;
    const [listData] = useList();
    const { company } = useAuthState();
    const { t } = listData.tempInfo;

    // const companiesExport = ["100017", "10102"];
    // if (!companiesExport.includes(company.companyId.toString())) {
    //     BulkActionsRemoveFromList.push("job_exportToFile");
    // }
    const bulkActionsList = getBulkActionsList(
        listData.tempInfo.moduleName,
        "",
        BulkActionsRemoveFromList
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue: IBulkActions = JSON.parse(
            event.target.value
        ) as IBulkActions;
        setAction(newValue);
    };
    const value = action || bulkActionsList[0];
    return (
        <>
            <Typography variant="body2" className={classes.instructions}>
                {t("ELASTICLISTSETTINGS:ModalBulkActions_Instructions")}
            </Typography>
            <RadioGroup
                aria-label="gender"
                name="gender1"
                value={JSON.stringify(value)}
                onChange={handleChange}
            >
                {bulkActionsList.map((item: IBulkActions) => (
                    <FormControlLabel
                        key={item.name}
                        value={JSON.stringify(item)}
                        control={<Radio />}
                        label={t(
                            `ELASTICLISTSETTINGS:BulkActions_${item.name}`
                        )}
                    />
                ))}
            </RadioGroup>
        </>
    );
};

export default BulkActionsStep1;
