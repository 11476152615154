import { Button, Chip, Grid, Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ModalViewEmail from "../../ApplicantEmail/ModalViewEmail";
import ApplicantHistoryTemplate from "./ApplicantHistoryTemplate";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
export interface IModalHistoryViewEmailState {
    open: boolean;
    globalEmailId: string;
    recipients: Compleo.IObject[];
    fromEmail?: Compleo.IObject;
}
export const defaultModalHistoryViewEmailState: IModalHistoryViewEmailState = {
    open: false,
    globalEmailId: "",
    recipients: [],
    fromEmail: {}
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0)
        },
        tags: {
            marginLeft: theme.spacing(1),
            maxWidth: 250
        }
    })
);

const ApplicantHistorySendEmailAutomationLater = (
    props: IApplicantHistoryProps
) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;

    const [modalViewEmailState, setModalViewEmailState] = React.useState<
        IModalHistoryViewEmailState
    >(defaultModalHistoryViewEmailState);
    const handleModalViewEmailStateClose = () => {
        setModalViewEmailState(defaultModalHistoryViewEmailState);
    };
    const openModalViewEmail = (
        globalId: string,
        recipients: Compleo.IObject[],
        fromEmail?: Compleo.IObject
    ) => {
        setModalViewEmailState({
            open: true,
            globalEmailId: globalId,
            recipients: recipients,
            fromEmail: fromEmail
        });
    };

    const elementEnd = (
        <ButtonIconTooltipCompleo
            label={tLocal("showEmail")}
            onClick={() =>
                openModalViewEmail(
                    historyItem.globalEmailId,
                    historyItem?.operationInfo?.recipients
                )
            }
        >
            <Icon>visibility</Icon>
        </ButtonIconTooltipCompleo>
    );
    return (
        <ApplicantHistoryTemplate
            {...props}
            type="sendEmailAutomation_later"
            elementEnd={elementEnd}
        >
            <>
                <ApplicantHistoryItemDetail
                    title={tLocal("JobTitle")}
                    transformDBDataParam={{
                        value: historyItem.jobData?.title,
                        language: language,
                        t: tLocal
                    }}
                />
                <ApplicantHistoryItemDetail
                    title={tLocal("NewStage")}
                    transformDBDataParam={{
                        value: historyItem.automationStageData?.newStage?.name,
                        language: language,
                        t: tLocal
                    }}
                />
                {historyItem.automationStageData?.oldStage?.name && (
                    <ApplicantHistoryItemDetail
                        title={tLocal("OldStage")}
                        transformDBDataParam={{
                            value:
                                historyItem.automationStageData?.oldStage?.name,
                            language: language,
                            t: tLocal
                        }}
                    />
                )}
                <ApplicantHistoryItemDetail
                    title={
                        historyItem.operationInfo?.noRepplyEnabled === true
                            ? tLocal("noReplyEnabled")
                            : tLocal("allowReply")
                    }
                />
                {historyItem?.operationInfo?.sendLaterDate && (
                    <ApplicantHistoryItemDetail
                        title={tLocal("scheduledToRun")}
                        transformDBDataParam={{
                            value: historyItem?.operationInfo?.sendLaterDate,
                            language: language,
                            t: tLocal,
                            customDateFormat: "dateWithTime"
                        }}
                    />
                )}

                {modalViewEmailState.open && (
                    <ModalViewEmail
                        handleClose={handleModalViewEmailStateClose}
                        {...modalViewEmailState}
                    />
                )}
            </>
        </ApplicantHistoryTemplate>
    );
};

export default ApplicantHistorySendEmailAutomationLater;
