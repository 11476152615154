import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";

const ScheduleTemplateNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/scheduletemplate/list");
    // const [addProps, setAddProps] = React.useState<
    //     Compleo.useCompleoReactHookForm.AdditionalPropertiesField[]
    // >([]);
    const moduleName = "SCHEDULETEMPLATENEW";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/scheduletemplate/new`;

    const [addDataReturn, addData] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true);
    valuesFromSource.deleted = false;
    valuesFromSource.type = props.type;

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        { fieldName: "template", props: { type: props.type } }
    ];

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addData,
        postReturn: addDataReturn,
        redirectAddress: "/scheduletemplate/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        secondaryButtonFunction: cancelFunction,
        additionalFieldProperties: addProps
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    // addProps

    const ready = finished && readyTranslation;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ScheduleTemplateNew;
