import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import QuestionsModal from "./QuestionsModal";
import useGetMetadata from "customHooks/useGetMetadata";
import QuestionsItems from "./QuestionsItems";
import AddQuestionButtom from "./AddQuestionButtom";
import { ModalDataType } from "./helper";
import {
    useRhfFieldWithoutControllerOrRegister,
    IInputProps
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

interface IProps {
    questionsFieldName?: string;
    resultsFieldName?: string;
    rankingFieldName?: string;
    enableResultsByRankingScoreFieldName?: string;
}

const Sections = (props: IProps & IInputProps) => {
    const classes = useStyles();
    const {
        questionsFieldName,
        resultsFieldName,
        rankingFieldName,
        enableResultsByRankingScoreFieldName,
        helperTextDefault
    } = {
        questionsFieldName: "questions",
        resultsFieldName: "results",
        rankingFieldName: "enableRanking",
        enableResultsByRankingScoreFieldName: "enableResultsByRankingScore",
        ...props
    };

    const fieldName = questionsFieldName;
    const {
        watch,
        error,
        message,
        setFieldValue
    } = useRhfFieldWithoutControllerOrRegister(fieldName);

    const questionsData = watch(questionsFieldName);
    const enableRanking = watch(rankingFieldName) === true;
    const enableResultsByRankingScore =
        watch(enableResultsByRankingScoreFieldName) === true;

    const [modalData, setModalData] = useState<ModalDataType>({
        open: false,
        section: {},
        index: 0,
        name: fieldName,
        new: false,
        questionWithAnswers: false,
        enableRanking: enableRanking
    });

    const modalModuleName = "QUESTIONNAIREQUESTIONMAIN";
    const [t, i18n, readyTranslation] = useTranslation(modalModuleName, {
        useSuspense: false
    });
    const [modalMetadata] = useGetMetadata(modalModuleName);

    const handleModalClose = () => {
        setModalData({
            ...modalData,
            enableRanking: enableRanking,
            open: false
        });
    };

    const handleEditStage = (section: any, index: number) => {
        debugger;
        setModalData({
            ...modalData,
            section: section,
            open: true,
            index: index,
            new: false,
            questionWithAnswers: section?.questionWithAnswers === true,
            enableRanking: enableRanking
        });
    };

    const handleAddStage = (index: number, type: string) => {
        const newSection = {
            name: "",
            order: index + 1
        };
        setModalData({
            ...modalData,
            section: newSection,
            open: true,
            index: index,
            new: true,
            questionWithAnswers: false,
            enableRanking: enableRanking
        });
    };

    const handleDeleteStage = (section: any) => {
        const newValue = [...questionsData].filter(
            (item: any) => item.id !== section.id
        );
        setFieldValue(newValue);
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        // console.log("result", JSON.stringify(result, null, 4));

        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            if (type !== "questions") {
                const indexQuestion = Number(source.droppableId.split("_")[1]);
                const currentValue = questionsData[indexQuestion];
                const currentAnswers = [...currentValue.answers];
                const currentItem = currentAnswers[source.index];
                currentAnswers.splice(source.index, 1);
                currentAnswers.splice(destination.index, 0, currentItem);
                const reorderedAnswers = currentAnswers.map(
                    (item: any, index: any) => {
                        return {
                            ...item,
                            order: index
                        };
                    }
                );
                currentValue.answers = reorderedAnswers;
                const newQuestions = [...questionsData];
                newQuestions[indexQuestion] = currentValue;
                setFieldValue(newQuestions);
            } else {
                const sectionsDestination = [...questionsData];
                const currentItem = questionsData.filter(
                    (item: any) => item.id === draggableId
                )[0];
                const mainListCurrentItem = questionsData.findIndex(
                    (item: any) => item.id === draggableId
                );
                const indexDifference = mainListCurrentItem - source.index;
                sectionsDestination.splice(mainListCurrentItem, 1);
                sectionsDestination.splice(
                    destination.index + indexDifference,
                    0,
                    currentItem
                );
                const reorderedsections = sectionsDestination.map(
                    (item: any, index: any) => {
                        return {
                            ...item,
                            order: index
                        };
                    }
                );
                setFieldValue(reorderedsections);
            }
        }
    };

    // const hasError = false;
    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }
    // const mensagemErro = "temp";

    let listsections: any = questionsData || [];

    if (readyTranslation && listsections) {
        return (
            <React.Fragment>
                <QuestionsModal
                    modalData={modalData}
                    handleModalClose={handleModalClose}
                    t={t}
                    setFieldValue={setFieldValue}
                    fieldName={fieldName}
                    metadata={modalMetadata}
                    i18n={i18n}
                    readyTranslation={readyTranslation}
                />
                <div className={classes.root}>
                    {error && (
                        <FormHelperText id="component-helper-text" error>
                            {message}
                        </FormHelperText>
                    )}
                    <Divider />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId={"questions"}
                            direction="vertical"
                            type="questions"
                        >
                            {(provided: DroppableProvided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <QuestionsItems
                                        classError={classError}
                                        listSections={listsections}
                                        t={t}
                                        handleEditStage={handleEditStage}
                                        handleDeleteStage={handleDeleteStage}
                                        questionsFieldName={questionsFieldName}
                                        resultsFieldName={resultsFieldName}
                                        enableRanking={enableRanking}
                                        enableResultsByRankingScore={
                                            enableResultsByRankingScore
                                        }
                                    />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <AddQuestionButtom
                        handleAddStage={handleAddStage}
                        listSections={listsections}
                        t={t}
                    />
                    <Divider />
                </div>
            </React.Fragment>
        );
    } else {
        return <Loading />;
    }
};

export default Sections;
