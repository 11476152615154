import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
//import useCustomLists from "./components/useCustomLists";
//import useFormikCallBack from "./components/useFormikCallBack";
import { usePlanLimitsPage } from "customHooks/usePlanLimits/usePlanLimits";

interface IDnsConfig {
    dnsRecordType: string;
    dnsRecordName: string;
    dnsRecordValue: string;
    fullDomain: string;
    subdomain: string;
    apexName: string;
}

interface IDomainSettingsNewProps {
    uniqueLink: string;
    handleCancel: () => void;
    handleRedirectAfterPost: () => void;
}

const DomainSettingsNew = (props: IDomainSettingsNewProps) => {
    const { uniqueLink, handleCancel, handleRedirectAfterPost } = props;
    const moduleName = "CAREERSDOMAINNEW";
    const [showDomainSettingsInfo, setShowDomainSettingsInfo] = React.useState(
        false
    );
    const [domainSettingsInfo, setDomainSettingsInfo] = React.useState<
        IDnsConfig | undefined
    >(undefined);

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/careerssettings/domainnew/${
        i18n?.language || "pt-BR"
    }`;
    const [metadata] = useGetMetadata(moduleName);
    const [valuesFromSource] = useValuesFromSource(metadata, true, {
        enabled: true,
        uniqueLink: uniqueLink,
        domain: "",
        status: "unverified",
        lastStatusUpdatedAt: new Date().toISOString()
    });
    const { limitReached } = usePlanLimitsPage(
        t,
        "/careers",
        "addCareersDomain",
        handleCancel
    );

    const addDataReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const handleSubmit = async (values: Compleo.IObject) => {
        console.log("handleSubmit values", values);
        try {
            const response = await apiDirectCall(postAddress, "post", {
                ...values
            });
            console.log("domainSettingsNew handleSubmit response", response);
            if (response.data?.dnsConfig) {
                setDomainSettingsInfo({ ...response.data.dnsConfig });
            }
        } catch (ex) {
            console.log("handleSubmit error", ex);
            return ex.response;
        }

        setShowDomainSettingsInfo(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation, //&& readyLists,
        i18nV: i18n,
        postMethod: !limitReached ? handleSubmit : undefined,
        postReturn: addDataReturn,
        //redirectAddress: "/careers",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: handleCancel,
        formikEnableReinitialize: false
        //customLists: customLists,
        //fieldsToHide: fieldsToHide,
        //formikReturn: formikReturn
    });
    const ready = readyTranslation && finished; // && readyLists;

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (showDomainSettingsInfo && domainSettingsInfo) {
        const {
            fullDomain,
            dnsRecordType,
            dnsRecordName,
            dnsRecordValue,
            apexName
        } = domainSettingsInfo;

        compRetorno = (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {`Importante! Necessário registrar entrada no DNS`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <p>{`Para finalizar a configuração do domínio ${fullDomain} com o site de carreiras ${uniqueLink}, a seguinte entrada deve ser registrada no DNS do domínio ${apexName}:`}</p>
                        <p>
                            <b>Nome</b>: {dnsRecordName}
                        </p>
                        <p>
                            <b>Tipo</b>: {dnsRecordType}
                        </p>
                        <p>
                            <b>Valor</b>: {dnsRecordValue}
                        </p>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRedirectAfterPost}
                    >
                        OK
                    </Button>
                </Grid>
            </Grid>
        );
    } else if (ready) {
        compRetorno = formCompleo;
    }

    return compRetorno;
};

export default DomainSettingsNew;
