import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";

interface IProps {
    language: "pt-BR" | "en-US" | "fr" | "es";
    numberType: "integer" | "decimal";
    maxLength?: number;
    decimalDigits?: number;
}

interface ILocalInputProps {
    maxLength?: number;
}

const formatMap = {
    "pt-BR": {
        thousandSeparator: ".",
        decimalSeparator: ","
    },
    "en-US": {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    fr: {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    es: {
        thousandSeparator: ",",
        decimalSeparator: "."
    }
};

const NumberField = (props: IProps & IInputProps) => {
    //debugger;
    const {
        name,
        label,
        helperTextDefault,
        language,
        numberType,
        maxLength,
        decimalDigits,
        //inputRef,
        ...other
    } = props;
    const {
        error,
        message,
        field,
        setFieldValue,
        watch
    } = useRhfFieldControlled(name, helperTextDefault);
    const fieldValue = watch(name);

    let inputPropsObj: ILocalInputProps = {};
    if (maxLength) {
        inputPropsObj["maxLength"] = maxLength;
    }

    const thousandSeparator: string | boolean | undefined =
        numberType === "integer"
            ? undefined
            : formatMap[language].thousandSeparator;

    return (
        <NumberFormat
            id={name}
            label={label}
            error={error}
            helperText={message}
            onValueChange={(values) => {
                if (values.floatValue === null) {
                    setFieldValue(undefined);
                } else {
                    setFieldValue(values.floatValue);
                }
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={formatMap[language].decimalSeparator}
            isNumericString={true}
            decimalScale={numberType === "integer" ? 0 : decimalDigits}
            fixedDecimalScale={numberType === "integer" ? false : true}
            customInput={TextField}
            inputProps={inputPropsObj}
            {...other}
            // {...field} avoid to add this because can impact setFocus property (https://github.com/compleoteam/compleo/issues/344)
            onBlur={field.onBlur}
            value={fieldValue}
            onChange={undefined}
            inputRef={field.ref}
            type="text"
        />
    );
};

NumberField.defaultProps = {
    language: "pt-BR",
    numberType: "decimal",
    decimalDigits: 2
};

export default NumberField;
