import { Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobContext from "../../useJobContext";
import { HISTORYCHANGESTATUSHEIGHT } from "../JobHistoryHelper";

import { IJobHistoryProps } from "../JobHistoryHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: "flex",
            padding: theme.spacing(2),
            height: "100%",
            flexDirection: "column"
        },
        divider: {
            marginTop: theme.spacing(1)
        },
        comment: {
            textAlign: "left",
            overflow: "auto"
        },
        grow: {
            flexGrow: 1
        },
        viewIcon: {
            margin: theme.spacing(0.1),
            padding: theme.spacing(0.1)
        }
    })
);

function createMarkup(data: string) {
    return { __html: data };
}

const JobHistoryChangeStatus = (props: IJobHistoryProps) => {
    const classes = useStyles();
    const { historyItem, tLocal } = props;
    const [data] = useJobContext();
    const { t, JobId, language } = data;

    return (
        <Paper elevation={1} className={classes.paper}>
            {historyItem.comment && (
                <Typography variant="body2" className={classes.comment}>
                    <div
                        dangerouslySetInnerHTML={createMarkup(
                            historyItem.comment
                        )}
                    />
                </Typography>
            )}
            {historyItem.comment && <div className={classes.grow} />}
            {historyItem.comment && <Divider className={classes.divider} />}
            <Typography variant="body2">
                <strong>
                    {tLocal("changeStatusDescription", {
                        status: tLocal(`JOBSTATUS_${historyItem.status}`)
                    })}
                </strong>
            </Typography>
            <OtherJobData
                historyItem={historyItem}
                language={language}
                t={tLocal}
            />
            <Typography variant="caption">
                {historyItem.createdByUser}
            </Typography>
        </Paper>
    );
};

interface IOtherJobData {
    historyItem: Compleo.IObject;
    t: any;
    language: string;
}
const OtherJobData = (props: IOtherJobData) => {
    const { historyItem, t, language } = props;
    const otherJobData = historyItem?.otherJobData;

    if (otherJobData?.reasonForCancellation) {
        return (
            <OtherJobDataReturn
                otherJobData={otherJobData}
                t={t}
                fieldName="reasonForCancellation"
                language={language}
            />
        );
    }

    if (otherJobData?.reasonForSuspension) {
        return (
            <OtherJobDataReturn
                otherJobData={otherJobData}
                t={t}
                fieldName="reasonForSuspension"
                language={language}
            />
        );
    }

    return null;
};

interface IOtherJobDataReturn {
    fieldName: string;
    otherJobData: Compleo.IObject;
    t: any;
    language: string;
}

const OtherJobDataReturn = (props: IOtherJobDataReturn) => {
    const { fieldName, otherJobData, t, language } = props;
    return (
        <Typography variant="body2">
            <strong>{t(fieldName)}: </strong>
            {otherJobData?.[fieldName]?.[`label-${language}`] ||
                otherJobData?.[fieldName]?.label}
        </Typography>
    );
};

export default JobHistoryChangeStatus;
