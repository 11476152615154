import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import CustomComponents from "./components/UserCustomComponents";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import _ from "lodash";
import { CompleoErrorMessage } from "Components/CompleoErrorMessage";
// import { getFieldsToHide, allBranchControlFields } from "./UserNew";

const RoleEdit = (props: any) => {
    const classes = useFormStyles();
    const { company, reloadCompanyAndPermissionData } = useAuthState();

    const moduleName = "ROLEEDIT";
    const [metadata] = useGetMetadata(moduleName);
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/user/roleedit`;

    const [editRoleReturn, editRole] = useApi(postAddress, "post");

    const postAddressSearch = `/user/rolesearch`;
    const [getRoleReturn, gerRole] = useApiCache(
        postAddressSearch,
        "post",
        { uuid: props.roleid, companyId: company.companyId },
        false
    );

    const [valuesFromSource, pkSKValues] = useValuesFromSource(
        metadata,
        true,
        {},
        getRoleReturn,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.roleid;

    const editRoleLocal = async (values: any) => {
        const returnFunction = await editRole(values);
        reloadCompanyAndPermissionData();
        return returnFunction;
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        editRoleLocal,
        editRoleReturn,
        "/role/list",
        FormClassic,
        metadata,
        valuesFromSource,
        CustomComponents
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (getRoleReturn.status === "error") {
        compRetorno = (
            <CompleoErrorMessage
                errorTitle={t("userNotFoundMessage")}
                errorDescription={t("userNotFoundMessageDetail")}
            />
        );
    }
    if (finished && readyTranslation && getRoleReturn.status === "success") {
        compRetorno = formCompleo;

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default RoleEdit;
