import React from "react";
import { CompleoDynamicDashboardPage } from "customHooks/useDynamicDashboard";
import DynamicDashboardItems from "../DynamicDashboard/DynamicDashboardItems";

const DashboardJobs = (props: any) => {
    const module = "JOBDASHBOARD";

    return (
        <CompleoDynamicDashboardPage
            moduleName={module}
            listApiAddress="/reports/jobdynamicdashboard"
        >
            <DynamicDashboardItems dashboardName={module} />
        </CompleoDynamicDashboardPage>
    );
};

export default DashboardJobs;
