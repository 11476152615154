import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";

const createMarkup = (tooltiptext: string) => {
    return { __html: tooltiptext };
};

interface IProps {
    title: string;
    detail: string;
    buttonAriaText?: string;
}

const TooltipCompleo = (props: IProps) => {
    const { title, detail, buttonAriaText } = {
        buttonAriaText: "Show help. Exibir ajuda",
        ...props
    };
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    PopperProps={{
                        disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <React.Fragment>
                            <Typography variant="h6" color="inherit">
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        title
                                    )}
                                />
                            </Typography>
                            <Typography variant="body2" color="inherit">
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        detail
                                    )}
                                />
                            </Typography>
                        </React.Fragment>
                    }
                >
                    <IconButton
                        color="primary"
                        onClick={handleTooltipOpen}
                        aria-label={buttonAriaText}
                    >
                        <HelpIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

export default TooltipCompleo;
