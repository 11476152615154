import { useTranslation } from "react-i18next";
import { CopyToClipboardChip } from "./CopyToClipboardChip";
import { BoxProps } from "@material-ui/core";
import { useEmailParser } from "customHooks/useEmailParser";

interface ICopyToClipboardEmailParserProps {
    jobId?: string;
}
export function CopyToClipboardEmailParser(
    props: BoxProps & ICopyToClipboardEmailParserProps
) {
    const { jobId, ...boxProps } = props;
    const [t, , readyTranslation] = useTranslation("MODALFEATUREUPGRADEPLAN", {
        useSuspense: false
    });

    const { isEnabled, defaultEmailParser } = useEmailParser({
        jobId
    });

    if (readyTranslation && isEnabled && defaultEmailParser) {
        return (
            <CopyToClipboardChip
                text={defaultEmailParser}
                t={t}
                childrenProps={boxProps}
                chipVariant="default"
            />
        );
    } else {
        return null;
    }
}
