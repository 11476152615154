import { Button, Chip, Grid, Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ModalViewEmail from "../../ApplicantEmail/ModalViewEmail";
import ApplicantHistoryTemplate from "./ApplicantHistoryTemplate";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { transformDBData } from "functions/util";
export interface IModalHistoryViewEmailState {
    open: boolean;
    globalEmailId: string;
    recipients: Compleo.IObject[];
    fromEmail?: Compleo.IObject;
}
export const defaultModalHistoryViewEmailState: IModalHistoryViewEmailState = {
    open: false,
    globalEmailId: "",
    recipients: [],
    fromEmail: {}
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0)
        },
        tags: {
            marginLeft: theme.spacing(1),
            maxWidth: 250
        }
    })
);

const ApplicantHistoryRequestEvaluationAutomation = (
    props: IApplicantHistoryProps
) => {
    const classes = useStyles();
    const { historyItem, tLocal, language } = props;

    return (
        <ApplicantHistoryTemplate {...props} type="requestEvaluationAutomation">
            <>
                <ApplicantHistoryItemDetail
                    title={tLocal("JobTitle")}
                    transformDBDataParam={{
                        value: historyItem.jobData?.title,
                        language: language,
                        t: tLocal
                    }}
                />
                <ApplicantHistoryItemDetail
                    title={tLocal("NewStage")}
                    transformDBDataParam={{
                        value: historyItem.automationStageData?.newStage?.name,
                        language: language,
                        t: tLocal
                    }}
                />
                <ApplicantHistoryItemDetail
                    title={tLocal("OldStage")}
                    transformDBDataParam={{
                        value: historyItem.automationStageData?.oldStage?.name,
                        language: language,
                        t: tLocal
                    }}
                />
                <ApplicantHistoryItemDetail
                    title={tLocal("externalEvaluators")}
                    transformDBDataParam={{
                        value: historyItem.operationInfo?.externalEvaluators,
                        language: language,
                        t: tLocal,
                        arrayJoinOption: " - "
                    }}
                />
                <ApplicantHistoryItemDetail
                    title={tLocal("evaluators")}
                    transformDBDataParam={{
                        value: historyItem.operationInfo?.evaluators,
                        language: language,
                        t: tLocal,
                        arrayJoinOption: " - "
                    }}
                />
            </>
        </ApplicantHistoryTemplate>
    );
};

export default ApplicantHistoryRequestEvaluationAutomation;
