import React from "react";
import ItemCard from "./ItemCard";
import Icon from "@material-ui/core/Icon";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { Typography } from "@material-ui/core";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    returnType: "card" | "table";
}

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FieldTime = (props: IProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, returnType } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const localFieldName = cardFields[fieldName].fieldName || fieldName;
    switch (returnType) {
        case "card":
            const value = transformDBData({
                value: _.get(fieldData, localFieldName),
                t,
                language,
                replaceOption: "-",
                customDateFormat: "time"
            });
            return (
                <ItemCard
                    label={
                        cardFields[fieldName].cardIcon ? (
                            <Icon color="action" fontSize="small">
                                {cardFields[fieldName].cardIcon}
                            </Icon>
                        ) : (
                            `${t(fieldName)}: `
                        )
                    }
                    removeColonAfterLabel={true}
                >
                    {value}
                </ItemCard>
            );
        case "table":
            return (
                <>
                    {transformDBData({
                        value: _.get(fieldData, localFieldName),
                        t,
                        language,
                        replaceOption: "-",
                        customDateFormat: "time"
                    })}
                </>
            );
    }
};

export default FieldTime;
