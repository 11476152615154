import React from "react";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { FieldProps } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

interface IProps {
    label: string;
    helperTextDefault: null | string;
}

const CheckboxControl = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status },
        ...other
    } = props;
    const classes = useStyles();

    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let mensagemErro = hasError && errorText !== undefined ? errorText : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    let classError = classes.color;
    if (hasError) {
        classError = classes.colorError;
    }

    return (
        <FormControl error={hasError} className="items-center">
            <FormControlLabel
                className={classError}
                control={
                    <Checkbox
                        {...field}
                        {...other}
                        checked={field.value}
                        value={field.value}
                    />
                }
                label={label}
            />
            {hasError && <FormHelperText>{mensagemErro}</FormHelperText>}
        </FormControl>
    );
};

export default CheckboxControl;
