export type ApplicantFirstDataType = {
    name: string;
    email: string;
    cpf?: string;
    id?: string;
};

export interface ICVFileApplicant {
    streamContent: any;
    fileName: string;
}

export interface IApplicantNewLocalStateData {
    jsonParsedCV?: Compleo.IObject;
    urlCV?: string;
    pageToRender: ApplicantNewPageToRender;
    ApplicantFirstData?: ApplicantFirstDataType;
    cvFile?: ICVFileApplicant;
    editId?: string;
}

export type ApplicantNewRenderType =
    | "upload"
    | "normal"
    | "existentParser"
    | "edit";

export type ApplicantNewPageToRender =
    | "upload"
    | "verificationData"
    | "dataEntry"
    | "edit";

export interface IApplicantNewMainState {
    companyId: number;
    ready: boolean;
    t: any;
    language: string;
    queryKey: string;
    localStateData: IApplicantNewLocalStateData;
    renderType: ApplicantNewRenderType;
    operationid?: string;
    itemid?: string;
}

export interface IPayloadApplicantNewAction {
    jsonParsedCV?: Compleo.IObject;
    urlCV?: string;
    pageToRender?: ApplicantNewPageToRender;
    ApplicantFirstData?: ApplicantFirstDataType;
    cvFile?: ICVFileApplicant;
    editId?: string;
}

export interface IApplicantNewMainStateAction {
    type: "update" | "reset";
    payload: IPayloadApplicantNewAction;
}

export const getInitialPageToRender: (
    renderType: ApplicantNewRenderType
) => ApplicantNewPageToRender = (renderType: ApplicantNewRenderType) => {
    switch (renderType) {
        case "edit":
            return "edit";
        case "upload":
            return "upload";
        default:
            return "verificationData";
    }
};
