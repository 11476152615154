import {
    useFormContext,
    useController,
    Control,
    FieldValues,
    ControllerFieldState,
    ControllerRenderProps,
    UseFormStateReturn,
    UseFormGetValues,
    UseFormSetValue,
    UseFormSetError,
    UseFormWatch,
    UseFormReset,
    UseFormClearErrors
} from "react-hook-form";
import { getErrorAndMessagesReactHookForm } from "../Inputs/util/general";

export const useRhfField = (
    fieldName: string,
    helperTextDefault?: string | null
) => {
    const {
        setValue,
        getValues,
        getFieldState,
        control,
        register,
        formState,
        setError,
        watch,
        reset
    } = useFormContext();

    const registerReturn = register(fieldName);
    const fieldState = getFieldState(fieldName, formState);
    const getFieldValue = () => getValues(fieldName);
    const setFieldValue = (value: any) => setValue(fieldName, value);

    const { error, message } = getErrorAndMessagesReactHookForm({
        errorObj: fieldState.error,
        touched: fieldState.isTouched,
        helperTextDefault: helperTextDefault || "",
        isSubmitted: formState.isSubmitted
    });

    return {
        control,
        fieldState,
        getFieldValue,
        setFieldValue,
        error,
        message,
        registerReturn,
        getValues,
        setError,
        formState,
        setValue,
        watch,
        reset
    };
};
export interface IReturnUseRhfFieldControlled {
    control: Control<FieldValues, object>;
    fieldState: ControllerFieldState;
    field: ControllerRenderProps<FieldValues, string>;
    setFieldValue: (value: any) => void;
    error: boolean;
    message: string | null;
    formState: UseFormStateReturn<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    setError: UseFormSetError<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    reset: UseFormReset<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
}
export const useRhfFieldControlled = (
    fieldName: string,
    helperTextDefault?: string | null
) => {
    const {
        setValue,
        control,
        getValues,
        setError,
        watch,
        reset,
        clearErrors
    } = useFormContext();

    const { field, fieldState, formState } = useController({
        name: fieldName,
        control
    });
    const setFieldValue = (value: any) => setValue(fieldName, value);

    const { error, message } = getErrorAndMessagesReactHookForm({
        errorObj: fieldState.error,
        touched: fieldState.isTouched,
        isSubmitted: formState.isSubmitted,
        helperTextDefault: helperTextDefault || ""
    });

    const returnData: IReturnUseRhfFieldControlled = {
        control,
        fieldState,
        field,
        setFieldValue,
        error,
        message,
        formState,
        getValues,
        setValue,
        setError,
        watch,
        reset,
        clearErrors
    };
    return returnData;
};

export const useRhfFieldOnlyErrorsOrMessages = (
    fieldNames: string[] | string,
    helperTextDefault?: string | null,
    joinLetter: string = " - "
) => {
    const { getFieldState, formState } = useFormContext();

    let errorReturn = false;
    let messageReturn = "";

    if (Array.isArray(fieldNames)) {
        fieldNames.map((fieldName, index) => {
            const fieldState = getFieldState(fieldName, formState);
            const { error, message } = getErrorAndMessagesReactHookForm({
                errorObj: fieldState.error,
                touched: fieldState.isTouched,
                helperTextDefault: helperTextDefault || "",
                isSubmitted: formState.isSubmitted
            });
            if (error) {
                errorReturn = true;
                if (messageReturn) {
                    messageReturn = `${messageReturn}${joinLetter}${
                        message || ""
                    }`;
                } else {
                    messageReturn = message || "";
                }
            }
        });
    } else {
        const fieldName = fieldNames;
        const fieldState = getFieldState(fieldName, formState);
        const { error, message } = getErrorAndMessagesReactHookForm({
            errorObj: fieldState.error,
            touched: fieldState.isTouched,
            helperTextDefault: helperTextDefault || "",
            isSubmitted: formState.isSubmitted
        });
        errorReturn = error;
        messageReturn = message || "";
    }

    return {
        error: errorReturn,
        message: messageReturn || helperTextDefault
    };
};

export const useRhfFieldWithoutControllerOrRegister = (
    fieldName: string,
    helperTextDefault?: string | null
) => {
    const {
        setValue,
        getValues,
        getFieldState,
        control,
        formState,
        setError,
        watch,
        reset
    } = useFormContext();

    const fieldState = getFieldState(fieldName, formState);
    const getFieldValue = () => getValues(fieldName);
    const setFieldValue = (value: any) => setValue(fieldName, value);

    const { error, message } = getErrorAndMessagesReactHookForm({
        errorObj: fieldState.error,
        touched: fieldState.isTouched,
        helperTextDefault: helperTextDefault || "",
        isSubmitted: formState.isSubmitted
    });

    return {
        control,
        fieldState,
        getFieldValue,
        setFieldValue,
        error,
        message,
        getValues,
        setError,
        formState,
        setValue,
        watch,
        reset
    };
};
export interface IInputProps {
    label?: string;
    helperTextDefault?: null | string | undefined;
    maxLength?: number;
    onChange?: any;
    t?: any;
    name: string;
    type?: string;
    fullWidth?: boolean;
    className?: any;
    required?: boolean;
    variant?: "standard" | "filled" | "outlined" | undefined;
    size?: "small" | "medium" | undefined;
    metadata?: any;
    inputProps?: any;
    classes?: any;
}
