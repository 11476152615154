import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { defaultMapProps, getWidgetData } from "../Helpers/_DashboardHelper";
import useDashboardItemHelper from "./useDashboardItemHelper";
import DashboardMainItem from "./DashboardMainItem";

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
        paddingRight: 16,
        paddingBottom: 16,
        margin: 0
    },
    content: {
        width: "100%",
        height: "100%"
    },
    title: {
        fontSize: 14
    },
    count: {
        marginTop: 8
    }
});

interface IDashboardPieChart {
    widgetName: string;
    widget: CompleoShared.Dashboard.widgetsMetadata;
}

const DashboardBarChart = (props: IDashboardPieChart) => {
    const { widgetName, widget } = props;
    const { t, i18n, ready, getData } = useDashboardItemHelper({
        widgetName: widgetName
    });
    const data = getData?.response?.data?.data;

    const graphData = getWidgetData({
        data: data,
        widgetDef: widget,
        widgetName: widgetName,
        language: i18n.languages[0],
        mapProps: widget.mapProps || defaultMapProps
    });

    const finalData = React.useMemo(() => graphData, [graphData.length]);

    const objKeys = finalData.map((item: any) => item.name);

    const colorData = useRandomColor({
        objectKeys: ["main"],
        luminosity: "dark"
    });

    return (
        <DashboardMainItem ready={ready} t={t} widgetName={widgetName}>
            <ResponsiveContainer minWidth="80%" minHeight="80%">
                <BarChart data={finalData} maxBarSize={45}>
                    <XAxis
                        dataKey="name"
                        scale="point"
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis domain={[0, 5]} />
                    <Tooltip />
                    <Bar
                        dataKey="value"
                        fill={colorData.colorsObject["main"]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </DashboardMainItem>
    );
};

export default DashboardBarChart;
