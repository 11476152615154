import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "customHooks/DataTable/datatable-column-header";
import { DataTableRow } from "customHooks/DataTable/datatable-row";
import { JobTitle } from "../utils/JobTitleColumn";
import { getCustomListColumn } from "../utils/CustomColum";
import { isNumber } from "../utils/index";

export const columns: (
    t: any,
    language: string
) => ColumnDef<Compleo.IObject>[] = (t: any, language: string) => {
    const customerColumn = getCustomListColumn(
        "customer",
        t("customer"),
        language
    );
    const branchColumn = getCustomListColumn("branch", t("branch"), language);
    return [
        {
            accessorKey: "title",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader column={column} title={t("title")} />
                );
            },
            cell: ({ row, getValue, cell }) => {
                return <JobTitle row={row} />;
            },
            enableGrouping: true
        },
        {
            accessorKey: "stage",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("stage")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                return (
                    <DataTableRow
                        row={row}
                        value={value}
                        align="right"
                        cell={cell}
                    />
                );
            }
        },
        {
            accessorKey: "status",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("status")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                return (
                    <DataTableRow
                        row={row}
                        value={t(`JOBSTATUS_${value}`)}
                        align="right"
                        cell={cell}
                    />
                );
            }
        },
        customerColumn,
        branchColumn,
        {
            accessorKey: "openingDate",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("openingDate")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                return (
                    <DataTableRow
                        row={row}
                        value={value}
                        renderType={"date"}
                        align="right"
                        cell={cell}
                    />
                );
            },
            enableGrouping: false
        },
        {
            accessorKey: "minDate",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("minDate")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                if (value) {
                    return (
                        <DataTableRow
                            row={row}
                            value={value}
                            renderType={"date"}
                            align="right"
                            cell={cell}
                        />
                    );
                }
                return <DataTableRow row={row} value={""} cell={cell} />;
            },
            aggregatedCell: () => "",
            enableGrouping: false
        },
        {
            accessorKey: "diffFromOpeningDate",
            header: ({ column }) => {
                return (
                    <DataTableColumnHeader
                        column={column}
                        title={t("diffFromOpeningDate")}
                        align="right"
                    />
                );
            },
            cell: ({ row, getValue, cell }) => {
                const value = getValue() as string;
                if (isNumber(value)) {
                    // value is a valid number
                    return (
                        <DataTableRow
                            row={row}
                            value={value}
                            renderType={"number"}
                            decimalPlaces={2}
                            align="right"
                            cell={cell}
                        />
                    );
                }
                return <DataTableRow row={row} value={""} cell={cell} />;
            },
            aggregatedCell: ({ getValue, row }) => {
                const value = getValue() as string;
                if (isNumber(value)) {
                    const newValue = `${t("average")}: ${
                        Math.round(getValue<number>() * 100) / 100
                    }`;
                    return (
                        <>
                            <DataTableRow
                                row={row}
                                value={newValue}
                                align="right"
                            />
                        </>
                    );
                }
                return <DataTableRow row={row} value={""} />;
            },
            aggregationFn: "mean",
            enableGrouping: false
        }
    ];
};
