import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { JobReportsTypeToRender } from "./JobReportTitle";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    changeReportToShow: (
        newReport: CompleoShared.Reports.IJobReportsToRender
    ) => void;
    jobReportToShow: CompleoShared.Reports.IJobReportsToRender;
}

const JobReportsMenu = (props: IProps) => {
    const {
        anchorEl,
        setAnchorEl,
        t,
        changeReportToShow,
        jobReportToShow
    } = props;

    const options = JobReportsTypeToRender;

    // const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (
        action: CompleoShared.Reports.IJobReportsToRender
    ) => {
        changeReportToShow(action);
        handleClose();
    };

    return (
        <div>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option}
                            onClick={() => clickMenuItem(option)}
                            disabled={option === jobReportToShow}
                        >
                            {t(`reportTitle_${option}`)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

// const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
//     const translatedNames = menuItems.map((item) => {
//         return {
//             label: t(`ELASTICLISTSETTINGS:MainMenu_${item}`),
//             value: item
//         };
//     });
//     translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
//     return translatedNames;
// };

export default JobReportsMenu;
