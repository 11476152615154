import { Box, Grid } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import JobReportsToolbar from "./components/JobReportsToolbar";
// import { JobReportsTypeToRender } from "./components/JobReportTitle";
import JobReportsSwitch from "./components/JobReportsSwitch";
import { useTranslation } from "react-i18next";
import { IEvalutionReportsDBReturn } from "../EvaluationRequestMain";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main
            // width: "100%"
        }
    })
);

const storageNameItem = "lastJobReportToShowExternal";

interface IJobReportsMain {
    allowedReports: CompleoShared.Reports.IJobReportsToRender[];
    externalEvaluationId: string;
    jobId: string;
    jobTitle: string;
    dynamicDashboardsList: IEvalutionReportsDBReturn[];
    companyId: number;
}
const JobReportsMain = (props: IJobReportsMain) => {
    const classes = useStyles();
    const {
        // report,
        allowedReports,
        externalEvaluationId,
        jobId,
        jobTitle,
        dynamicDashboardsList,
        companyId
    } = props;
    const [t, i18n, readyTranslation] = useTranslation("JOBVIEW", {
        useSuspense: false
    });

    const [jobReportToShow, setJobReportToShow] = React.useState<
        CompleoShared.Reports.IJobReportsToRender
    >(allowedReports[0]);

    const [dynamicDashboardId, setDynamicDashboardId] = React.useState<
        string | undefined
    >((dynamicDashboardsList[0]?.value || "").split(":")[1]);

    const changeReportToShow = (
        newReport: CompleoShared.Reports.IJobReportsToRender
    ) => {
        setJobReportToShow(newReport);
        localStorage.setItem(storageNameItem, newReport);
    };

    React.useEffect(() => {
        const lastReport = localStorage.getItem(
            storageNameItem
        ) as CompleoShared.Reports.IJobReportsToRender;
        if (
            lastReport &&
            lastReport !== jobReportToShow &&
            allowedReports.includes(lastReport)
        ) {
            setJobReportToShow(lastReport);
        }
    }, []);

    if (readyTranslation) {
        return (
            <Box className={classes.container}>
                <Box>
                    <JobReportsToolbar
                        t={t}
                        jobReportToShow={jobReportToShow}
                        changeReportToShow={changeReportToShow}
                        allowedReports={allowedReports}
                        dynamicDashboardsList={dynamicDashboardsList}
                        dynamicDashboardId={dynamicDashboardId}
                        setDynamicDashboardId={setDynamicDashboardId}
                    />
                </Box>
                <Box>
                    <JobReportsSwitch
                        jobReportToShow={jobReportToShow}
                        externalEvaluationId={externalEvaluationId}
                        jobId={jobId}
                        jobTitle={jobTitle}
                        allowedReports={allowedReports}
                        dashboardId={dynamicDashboardId}
                        companyId={companyId}
                    />
                </Box>
            </Box>
        );
    } else {
        return (
            <Box className={classes.container}>
                <Loading />
            </Box>
        );
    }
};

export default JobReportsMain;
