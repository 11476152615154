import React from "react";
import useJobContext from "../useJobContext";
import { useQueryClient } from "react-query";
import { getStateFromDB, getTotalStageFromDb, handleOrderData } from "./helper";
import _ from "lodash";
import { apiDirectCall } from "customHooks/useApi";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useRefreshKanban = () => {
    const [data, dispatchData] = useJobContext();
    const queryClient = useQueryClient();
    const showMessage = useShowMessage();

    const updateAllInfo = async (message?: string) => {
        const currentFilter = _.cloneDeep(data.fullFilter);
        currentFilter.otherGenericParams = {
            ...currentFilter.otherGenericParams,
            filterStageIds: []
        };

        const dataOrderDB = await apiDirectCall(
            data.apiKanbanOrderDataAddress,
            "post",
            { ...data.orderDataFilter, updateOrderData: false }
        );
        const stagesTotal = getTotalStageFromDb(
            dataOrderDB.data,
            data.LocalStateData.allStages
        );
        const orderData =
            dataOrderDB?.data?.orderPipelineData?.priority?.orderData || [];
        const newOrderData = handleOrderData(orderData);

        const dataApplicant = await apiDirectCall(
            data.apiKanbanListAddress,
            "post",
            currentFilter
        );

        const newStateData = getStateFromDB({
            dbData: dataApplicant,
            stateData: data.LocalStateData.ApplicantsData,
            orderData: newOrderData,
            onlyUpdateStagesFromDB: false
        });

        dispatchData.dispatchGeneralPageInfo({
            payload: {
                ApplicantsData: newStateData,
                orderData: newOrderData,
                stagesOnlyTotal: stagesTotal
            },
            type: "update"
        });
        queryClient.invalidateQueries(data.apiKeyApplicant);
        queryClient.invalidateQueries(data.apiKeyOrderData);
        if (message) {
            showMessage({
                message: message,
                timeout: 1000,
                snackBarOrign: { horizontal: "right", vertical: "bottom" }
            });
        }
        return true;
    };

    return updateAllInfo;
};

export default useRefreshKanban;
