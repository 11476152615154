import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface IProps {
    options: Compleo.IObject[];
    value: any;
    setValue: any;
    defaultProps: any;
    name: string;
    labelProperty: string;
    label: string;
    helperText: string;
    error: boolean;
    required: boolean;
    onBlur: any;
}

const AutoCompleteNaked = (props: IProps) => {
    const {
        value,
        setValue,
        options,
        defaultProps,
        name,
        labelProperty,
        label,
        helperText,
        error,
        required,
        onBlur
    } = props;

    return (
        <Autocomplete
            {...defaultProps}
            options={options}
            id={name}
            value={value}
            getOptionLabel={(option: any) => option[labelProperty]}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    error={error}
                    required={required}
                    onBlur={onBlur}
                />
            )}
        />
    );
};

export default AutoCompleteNaked;
