import React, { useReducer } from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { getExtension, transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { IFieldProps } from ".";
import {
    createLocalUrlFromS3,
    downloadFileFromS3,
    IS3ExternalData
} from "customHooks/useCompleoForm/Inputs/File/File";
import ApplicantCVPreview from "Pages/Applicant/ApplicantNewEdit/ApplicantCVPreview";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import FileTags from "customHooks/useCompleoForm/Inputs/File/FileTags";
import { matchSorter } from "match-sorter";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ModalViewFile, {
    AllowedExtensionsViewFile,
    AllowedExtensionsViewFileList
} from "Components/ModalViewFile/ModalViewFile";
import useModalViewFile from "Components/ModalViewFile/useModalViewFile";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            fontSize: 13,
            "& input::placeholder": {
                fontSize: 13
            },
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(2)
        }
    })
);

const initialState: string[] = [];
const reducer = (
    state: string[],
    action: { type: "add" | "remove"; value: string }
) => {
    switch (action.type) {
        case "add":
            state.push(action.value);
            return state;
        case "remove":
            const newState = state.filter((item) => item !== action.value);
            // state.splice(state.indexOf(action.value), 1);
            return newState;
        default:
            throw new Error();
    }
};

interface otherProps {
    disableSearch?: boolean;
}

const FieldFile = (props: IFieldProps & otherProps) => {
    const classes = useStyles();
    const {
        fieldData,
        fieldName,
        fieldDef,
        t,
        language,
        inline,
        propsToAdd,
        disableSearch = false
    } = props;

    const onlyShowFileName =
        props?.fieldDef?.elementConfig?.onlyShowFileName === true;
    const initialFieldValue = _.get(fieldData, fieldName) || [];
    const fieldValue = Array.isArray(initialFieldValue)
        ? initialFieldValue
        : [initialFieldValue];

    const [downloading, dispatchDownloading] = useReducer(
        reducer,
        initialState
    );

    const externalData: IS3ExternalData | undefined = propsToAdd.evaluationId
        ? {
              evaluationId: propsToAdd.evaluationId
          }
        : undefined;

    const { ModalComponentViewFile, viewFileS3Key } = useModalViewFile(t);

    const downloadFile = async (key: string, path: string) => {
        dispatchDownloading({ type: "add", value: key });
        await downloadFileFromS3(key, path, externalData);
        dispatchDownloading({ type: "remove", value: key });
    };

    const [textSearch, setTextSearch] = React.useState("");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(event.target.value);
    };
    const filteredArray: Compleo.IObject[] = textSearch
        ? matchSorter(fieldValue, textSearch, {
              keys: [
                  "FileInfo.path",
                  "originalFileName",
                  "tags",
                  "FileInfo.tags"
              ]
          })
        : fieldValue;

    // console.log("downloading", downloading);

    if (fieldValue.length) {
        return (
            <>
                <Box width="100%" mt={2}>
                    {/* <strong>{t(fieldName)}</strong> */}
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        style={{
                            fontWeight: 600
                        }}
                    >
                        {t(fieldName)}
                    </Typography>
                </Box>
                <Box width="100%">
                    {!disableSearch && fieldValue.length >= 2 && (
                        <TextField
                            className={classes.margin}
                            placeholder={t("CGT_search")}
                            fullWidth
                            value={textSearch}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ButtonIconTooltipCompleo
                                            label="Clear"
                                            onClick={() => setTextSearch("")}
                                        >
                                            <CloseIcon />
                                        </ButtonIconTooltipCompleo>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    <List dense>
                        {filteredArray.map((file: Compleo.IObject) => {
                            const icon = downloading.filter(
                                (f: any) => f === file.key
                            ).length
                                ? "block"
                                : "get_app";

                            const extension = getExtension(
                                file.key
                            ) as AllowedExtensionsViewFile;
                            const enableView = AllowedExtensionsViewFileList.includes(
                                extension
                            );
                            return (
                                <React.Fragment key={file.key}>
                                    <ListItem key={file.key}>
                                        <ListItemText
                                            primary={file.originalFileName}
                                            primaryTypographyProps={{
                                                style: { fontSize: 13 }
                                            }}
                                            secondary={
                                                <FileTags
                                                    tags={file?.tags || []}
                                                />
                                            }
                                        />

                                        {!onlyShowFileName && (
                                            <ListItemSecondaryAction>
                                                {enableView && (
                                                    <ButtonIconTooltipCompleo
                                                        label={t("CGT_view")}
                                                        onClick={() =>
                                                            viewFileS3Key(
                                                                file.key,
                                                                externalData
                                                            )
                                                        }
                                                    >
                                                        <Icon>visibility</Icon>
                                                    </ButtonIconTooltipCompleo>
                                                )}
                                                <ButtonIconTooltipCompleo
                                                    label={t("CGT_download")}
                                                    onClick={() =>
                                                        downloadFile(
                                                            file.key,
                                                            file.originalFileName
                                                        )
                                                    }
                                                >
                                                    <Icon>{icon}</Icon>
                                                </ButtonIconTooltipCompleo>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                    {ModalComponentViewFile}
                </Box>
            </>
        );
    } else {
        return <Box width="100%">{t("COMPLEOGENERAL_NoDataAvailable")}</Box>;
    }
};

export default FieldFile;
