import _ from "lodash";

export type ModalDataType = {
    open: boolean;
    section: Compleo.IObject;
    index: number;
    name: string;
    new: boolean;
    questionWithAnswers: boolean;
    enableRanking: boolean;
};

const handleFieldsToHide = (
    value: boolean,
    fieldToHideName: string,
    fields: string[]
) => {
    const newHiddenFields = [...fields];
    if (!value && !newHiddenFields.includes(fieldToHideName)) {
        newHiddenFields.push(fieldToHideName);
    }
    if (value && newHiddenFields.includes(fieldToHideName)) {
        newHiddenFields.splice(newHiddenFields.indexOf(fieldToHideName), 1);
    }
    return newHiddenFields;
};

export const verifyFields = (
    enableStartMessageValue: any,
    enableResultsValues: any,
    enableRanking: boolean,
    enableResultsByRankingScore: boolean,
    hasResults: boolean,
    setFieldValue: (fieldName: string, value: any) => void,
    hiddenFields: string[],
    setHiddenFields: React.Dispatch<React.SetStateAction<string[]>>,
    runClearResults: boolean = true
) => {
    // const values = value?.values || {};

    const enableStartMessage =
        enableStartMessageValue === undefined ? false : enableStartMessageValue;
    const enableResults =
        enableResultsValues === undefined ? false : enableResultsValues;

    let mainArray = handleFieldsToHide(enableStartMessage, "startMessage", [
        ...hiddenFields
    ]);
    mainArray = handleFieldsToHide(enableResults, "results", [...mainArray]);
    mainArray = handleFieldsToHide(
        enableRanking && enableResultsValues,
        "enableResultsByRankingScore",
        [...mainArray]
    );
    mainArray = handleFieldsToHide(
        hasResults &&
            enableResultsByRankingScore &&
            enableRanking &&
            enableResultsValues,
        "rankingToResults",
        [...mainArray]
    );
    mainArray = handleFieldsToHide(
        !hasResults &&
            enableResultsByRankingScore &&
            enableRanking &&
            enableResultsValues,
        "warningRankingToResults",
        [...mainArray]
    );

    if (!_.isEqual(hiddenFields, mainArray)) {
        if (runClearResults && !enableResults) {
            setFieldValue("results", []);
        }
        setHiddenFields(mainArray);
    }
};
