import React from "react";
import { Grid } from "@material-ui/core";
import TextFieldCompleo from "./TextFieldControlled";
import GenericFormatField, {
    IGenericFormatFieldProps
} from "./GenericFormatField";
import { useApiCache } from "customHooks/useApi";
import ComboAutoComplete from "./ComboAutoComplete";
import * as countries from "apiCompleo/CountryStateCityServer";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import TextField from "@material-ui/core/TextField";

const getUFLabel = (uf: string, estado: string) => {
    return {
        label: `${estado} (${uf})`,
        value: uf
    };
};

const getCityLabel = (
    uf: string,
    id_cidade: string,
    cidade: string,
    latitude?: number,
    longitude?: number
) => {
    return {
        label: cidade,
        value: id_cidade,
        uf: uf,
        latitude: latitude,
        longitude: longitude
    };
};

interface IProps {
    classes: any;
}

const useAddressFields = (mainFieldName: string, t: any) => {
    const countryName = `${mainFieldName}.country`;
    const postalCode = `${mainFieldName}.postalCode`;
    const addressline1 = `${mainFieldName}.addressline1`;
    const addressline2 = `${mainFieldName}.addressline2`;
    const houseNumber = `${mainFieldName}.number`;
    const neighborhood = `${mainFieldName}.neighborhood`;
    const city = `${mainFieldName}.city`;
    const provinceOrState = `${mainFieldName}.provinceOrState`;
    const latitude = `${mainFieldName}.latitude`;
    const longitude = `${mainFieldName}.longitude`;
    const ibgecode_city = `${mainFieldName}.ibgeCodeCity`;
    const ibgecode_state = `${mainFieldName}.ibgeCodeState`;

    const countryField = useRhfFieldControlled(
        countryName,
        t(`${countryName}_helperText`, "")
    );
    const postalCodeField = useRhfFieldControlled(
        postalCode,
        t(`${postalCode}_helperText`, "")
    );
    const addressline1Field = useRhfFieldControlled(
        addressline1,
        t(`${addressline1}_helperText`, "")
    );
    const addressline2Field = useRhfFieldControlled(
        addressline2,
        t(`${addressline2}_helperText`, "")
    );
    const houseNumberField = useRhfFieldControlled(
        houseNumber,
        t(`${houseNumber}_helperText`, "")
    );
    const neighborhoodField = useRhfFieldControlled(
        neighborhood,
        t(`${neighborhood}_helperText`, "")
    );
    const cityField = useRhfFieldControlled(city, t(`${city}_helperText`, ""));
    const provinceOrStateField = useRhfFieldControlled(
        provinceOrState,
        t(`${provinceOrState}_helperText`, "")
    );
    const latitudeField = useRhfFieldControlled(
        latitude,
        t(`${latitude}_helperText`, "")
    );
    const longitudeField = useRhfFieldControlled(
        longitude,
        t(`${longitude}_helperText`, "")
    );
    const ibgecode_cityField = useRhfFieldControlled(
        ibgecode_city,
        t(`${ibgecode_city}_helperText`, "")
    );
    const ibgecode_stateField = useRhfFieldControlled(
        ibgecode_state,
        t(`${ibgecode_state}_helperText`, "")
    );
    return {
        countryField,
        postalCodeField,
        addressline1Field,
        addressline2Field,
        houseNumberField,
        neighborhoodField,
        cityField,
        provinceOrStateField,
        latitudeField,
        longitudeField,
        ibgecode_cityField,
        ibgecode_stateField
    };
};

const Address = (props: IInputProps & IProps) => {
    const { metadata, t, classes, required } = props;
    const [tlocal, i18n, readyTranslation] = useTranslation("ADDRESSFIELD", {
        useSuspense: false
    });
    const language = i18n.languages[0];
    const allFields = useAddressFields(metadata.fieldName, tlocal);
    const formikCountry = allFields.countryField.field.value;
    const formikState = allFields.provinceOrStateField.field.value;

    const [zipCode, setZipCode] = React.useState("");
    const [ufState, setUfState] = React.useState("");
    const [countryState, setCountryState] = React.useState<{
        label: string;
        value: string;
    } | null>(null);

    const [countriesList, setCountryList] = React.useState<Compleo.IObject[]>(
        []
    );
    const [states, setStates] = React.useState<Compleo.IObject[]>([]);
    const [cities, setCities] = React.useState<Compleo.IObject[]>([]);

    const isBrazil =
        (countryState?.label || "").toLowerCase().trim().includes("brasil") ||
        (countryState?.label || "").toLowerCase().trim().includes("brazil");

    let fieldsToRequire: string[] = [];
    if (metadata.requiredType !== undefined) {
        fieldsToRequire = metadata.requiredType.split("|");
    }
    const urlCEP = `/general/getaddress/${zipCode}`;
    const [addressReturn, callGetAddress] = useApiCache(
        urlCEP,
        "get",
        {},
        true,
        1000 * 60 * 5
    );

    const urlStates = `/general/listufs`;
    const [ufsReturn, callGetUFS] = useApiCache(
        urlStates,
        "get",
        {},
        false,
        1000 * 60 * 60
    );

    React.useEffect(() => {
        // let messageCEP = "";
        if (addressReturn.status === "success") {
            const data = addressReturn.response.data;
            if (data.endereco === undefined) {
                // messageCEP = tlocal("messageZipCodeNotFound");
                allFields.postalCodeField.setError(
                    allFields.postalCodeField.field.name,
                    { message: tlocal("messageZipCodeNotFound") }
                );
                if (allFields.addressline1Field.field.value !== "") {
                    allFields.addressline1Field.setFieldValue("");
                }
                if (allFields.neighborhoodField.field.value !== "") {
                    allFields.neighborhoodField.setFieldValue("");
                }
                if (allFields.provinceOrStateField.field.value !== null) {
                    allFields.provinceOrStateField.setFieldValue(null);
                }
                if (allFields.cityField.field.value !== null) {
                    allFields.cityField.setFieldValue(null);
                }
            } else {
                allFields.postalCodeField.clearErrors(
                    allFields.postalCodeField.field.name
                );
            }
        }
    }, [addressReturn.response.data?.endereco, addressReturn.status]);

    const urlCities = `/general/listcities/${formikState?.value}`;
    const [citiesReturn, callGetCities] = useApiCache(
        urlCities,
        "get",
        {},
        true,
        1000 * 60 * 60
    );

    React.useEffect(() => {
        if (zipCode !== "" && isBrazil) {
            if (zipCode.trim().length === 9) {
                callGetAddress({}, urlCEP);
            }
        }
    }, [zipCode, urlCEP]);

    React.useEffect(() => {
        if (formikState?.value) {
            // console.log("state ufState", ufState);
            // console.log("state formikState?.value", formikState?.value);
            // console.log("state addressReturn.status", addressReturn.status);
            if (isBrazil) {
                callGetCities({}, urlCities);
            }

            if (ufState && ufState !== formikState?.value) {
                debugger;
                if (isBrazil) {
                    // callGetCities({}, urlCities);
                    if (addressReturn.status === "success") {
                        const data = addressReturn.response.data;
                        // console.log("state data.endereco", data.endereco);

                        if (data.endereco === undefined) {
                            console.log("logcity data.endereco");
                            allFields.cityField.setFieldValue(null);
                        }
                    } else {
                        console.log("logcity else 1");
                        allFields.cityField.setFieldValue(null);
                    }
                } else {
                    console.log("logcity else 2");
                    allFields.cityField.setFieldValue(null);
                }
            }
        }
        if (formikState?.value !== ufState) {
            setUfState(formikState?.value);
        }
    }, [formikState?.value, addressReturn.status, isBrazil]);

    React.useEffect(() => {
        if (formikCountry?.label) {
            if (
                countryState?.label &&
                countryState?.label !== formikCountry?.label
            ) {
                allFields.provinceOrStateField.setFieldValue(null);
                allFields.cityField.setFieldValue(null);
                allFields.latitudeField.setFieldValue(undefined);
                allFields.longitudeField.setFieldValue(undefined);
                allFields.ibgecode_cityField.setFieldValue(undefined);
                allFields.ibgecode_stateField.setFieldValue(undefined);
            }
            setCountryState(formikCountry);
        }
    }, [formikCountry?.label, formikCountry?.value]);

    React.useEffect(() => {
        if (addressReturn.status === "success" && isBrazil) {
            const data = addressReturn.response.data;
            if (
                data.endereco !== undefined &&
                (zipCode || "").trim().length === 9
            ) {
                allFields.addressline1Field.setFieldValue(data.endereco);
                allFields.neighborhoodField.setFieldValue(data.bairro);
                allFields.provinceOrStateField.setFieldValue(
                    getUFLabel(data.uf, data.estado)
                );
                const cityData = getCityLabel(
                    data.uf,
                    data.id_cidade,
                    data.cidade,
                    data.cidade_latitude,
                    data.cidade_longitude
                );
                allFields.cityField.setFieldValue(cityData);
                allFields.latitudeField.setFieldValue(data.latitude);
                allFields.longitudeField.setFieldValue(data.longitude);
                allFields.ibgecode_cityField.setFieldValue(
                    data.cidade_cod_ibge
                );
                allFields.ibgecode_stateField.setFieldValue(data.uf_cod_ibge);
            } else {
                allFields.latitudeField.setFieldValue(undefined);
                allFields.longitudeField.setFieldValue(undefined);
                allFields.ibgecode_cityField.setFieldValue(undefined);
                allFields.ibgecode_stateField.setFieldValue(undefined);
            }
        }
    }, [
        addressReturn.response.data,
        addressReturn.status,
        cities[0]?.uf,
        zipCode,
        addressReturn.response.data?.endereco
    ]);

    React.useEffect(() => {
        if (
            isBrazil &&
            (zipCode || "").trim().length > 0 &&
            (zipCode || "").trim().length < 9
        ) {
            if (allFields.addressline1Field.field.value !== "") {
                allFields.addressline1Field.setFieldValue("");
            }
            if (allFields.neighborhoodField.field.value !== "") {
                allFields.neighborhoodField.setFieldValue("");
            }
            if (allFields.provinceOrStateField.field.value !== null) {
                allFields.provinceOrStateField.setFieldValue(null);
            }
            if (allFields.cityField.field.value !== null) {
                allFields.cityField.setFieldValue(null);
            }
        }
    }, [zipCode, isBrazil]);

    React.useEffect(() => {
        if (isBrazil) {
            if (
                ufsReturn.status === "success" &&
                ufsReturn.response.data &&
                ufsReturn.response.data.ufs &&
                Array.isArray(ufsReturn.response.data.ufs)
            ) {
                const localStates = ufsReturn.response.data.ufs.map(
                    (u: any) => {
                        return getUFLabel(u.uf, u.estado);
                    }
                );
                setStates(localStates);
            }
        } else {
            if (countryState?.value) {
                countries
                    .getStatesOfCountry(countryState?.value)
                    .then((data) => {
                        const localStates = data.map((s: any) => {
                            return {
                                value: s.id,
                                label: s.name,
                                country_id: (s?.country_id || "").toString()
                            };
                        });
                        setStates(localStates);
                    });
            }
        }
    }, [countryState, countryState?.value, isBrazil, ufsReturn.status]);

    // let cities: Compleo.IObject[] = [];
    React.useEffect(() => {
        if (isBrazil) {
            if (
                citiesReturn.status === "success" &&
                citiesReturn.response.data &&
                Array.isArray(citiesReturn.response.data) &&
                ufState
            ) {
                const localCities = citiesReturn.response.data.map((c: any) => {
                    return getCityLabel(
                        c.uf,
                        c.idCidade,
                        c.cidade,
                        c.latitude,
                        c.longitude
                    );
                });
                setCities(localCities);
            }
            if (!ufState) {
                setCities([]);
            }
        } else {
            // console.log(countries.getCitiesOfState(ufState));
            countries.getCitiesOfState(ufState).then((data) => {
                const localCities = data.map((s: any) => {
                    return {
                        value: s.id,
                        label: s.name,
                        state_id: s.state_id
                    };
                });
                setCities(localCities);
            });
        }
    }, [countryState, citiesReturn.status, ufState]);

    React.useEffect(() => {
        countries.getAllCountries().then((data) => {
            const allCountries = data.map((c: any) => {
                const labelPtBR = `${
                    c?.translations?.br || c?.translations?.pt || c.name
                }`;
                const labelEnUS = c.name;
                const label = language === "pt-BR" ? labelPtBR : labelEnUS;

                return {
                    value: c.id.toString(),
                    label: label,
                    "label-pt-BR": labelPtBR,
                    "label-en-US": labelEnUS,
                    iso3: c.iso3,
                    iso2: c.iso2
                };
            });
            setCountryList(allCountries);
        });
    }, []);

    const formikPostalCode = allFields.postalCodeField.field.value;
    if (formikPostalCode !== undefined && formikPostalCode !== zipCode) {
        setZipCode(formikPostalCode);
    }

    // Remove formatting and limit for another countries
    let format: string | undefined = "#####-###";
    let maxPostalCodeLength = 9;
    let PostalCodeComponent: (
        props: IGenericFormatFieldProps & IInputProps
    ) => JSX.Element = GenericFormatField;

    if (!isBrazil) {
        format = undefined;
        maxPostalCodeLength = 15;
        PostalCodeComponent = TextFieldCompleo;
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={9}>
                    <ComboAutoComplete
                        required={fieldsToRequire.includes(
                            allFields.countryField.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                        name={allFields.countryField.field.name}
                        label={tlocal("country")}
                        options={countriesList}
                        className={classes.textField}
                        // component={ComboAutoComplete}
                        campoMetadata={metadata}
                        helperTextDefault={tlocal(`country_helperText`, "")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <PostalCodeComponent
                        type="text"
                        name={allFields.postalCodeField.field.name}
                        label={tlocal("postalCode")}
                        // helperTextDefault={messageCEP}
                        // fullWidth
                        className={classes.textField}
                        // component={PostalCodeComponent}
                        required={fieldsToRequire.includes(
                            allFields.postalCodeField.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                        format={format || ""}
                        maxLength={maxPostalCodeLength}
                        // error={messageCEP !== ""}
                        fieldRHFDef={allFields.postalCodeField}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={9}>
                    <TextField
                        label={tlocal("addressline1")}
                        // id={name}
                        error={allFields.addressline1Field.error}
                        helperText={allFields.addressline1Field.message}
                        fullWidth
                        className={classes.textField}
                        // {...allFields.addressline1Field.field}
                        onBlur={allFields.addressline1Field.field.onBlur}
                        value={allFields.addressline1Field.field.value}
                        onChange={allFields.addressline1Field.field.onChange}
                        inputRef={allFields.addressline1Field.field.ref}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <TextFieldCompleo
                        type="text"
                        name={allFields.houseNumberField.field.name}
                        label={tlocal("number")}
                        fullWidth
                        className={classes.textField}
                        // component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            allFields.houseNumberField.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextFieldCompleo
                        type="text"
                        name={allFields.addressline2Field.field.name}
                        label={tlocal("addressline2")}
                        fullWidth
                        className={classes.textField}
                        // component={TextFieldCompleo}
                        required={fieldsToRequire.includes(
                            allFields.addressline2Field.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={tlocal("neighborhood")}
                        // id={name}
                        error={allFields.neighborhoodField.error}
                        helperText={allFields.neighborhoodField.message}
                        fullWidth
                        className={classes.textField}
                        // {...allFields.neighborhoodField.field}
                        onBlur={allFields.neighborhoodField.field.onBlur}
                        value={allFields.neighborhoodField.field.value}
                        onChange={allFields.neighborhoodField.field.onChange}
                        inputRef={allFields.neighborhoodField.field.ref}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <ComboAutoComplete
                        required={fieldsToRequire.includes(
                            allFields.provinceOrStateField.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                        name={allFields.provinceOrStateField.field.name}
                        label={tlocal("provinceOrState")}
                        options={states}
                        className={classes.textField}
                        // component={ComboAutoComplete}
                        // helperText={t(`${provinceOrState}_helperText`, "")}
                        campoMetadata={metadata}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <ComboAutoComplete
                        name={allFields.cityField.field.name}
                        label={tlocal("city")}
                        options={cities}
                        className={classes.textField}
                        // component={ComboAutoComplete}
                        required={fieldsToRequire.includes(
                            allFields.cityField.field.name.replace(
                                `${metadata.fieldName}.`,
                                ""
                            )
                        )}
                        campoMetadata={metadata}
                        setValueToDefaultIfNotInList={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Address;
