import Stages from "./Stages";

const CustomComponents = [
    {
        name: "stages",
        component: Stages
    }
];

export default CustomComponents;
