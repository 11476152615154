import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IEvalutionReportsDBReturn } from "Pages/EvaluationRequestExternal/EvaluationRequestMain";
import { Divider } from "@material-ui/core";
import { IReportsShareOptions } from "Pages/Applicant/ApplicantEvaluationRequest/useModalEvaluationList";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    changeReportToShow: (
        newReport: CompleoShared.Reports.IJobReportsToRender
    ) => void;
    jobReportToShow: CompleoShared.Reports.IJobReportsToRender;
    allowedReports: CompleoShared.Reports.IJobReportsToRender[];
    dynamicDashboardsList: IEvalutionReportsDBReturn[];
    dynamicDashboardId: string | undefined;
    setDynamicDashboardId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
}

const JobReportsMenu = (props: IProps) => {
    const {
        anchorEl,
        setAnchorEl,
        t,
        changeReportToShow,
        jobReportToShow,
        allowedReports,
        dynamicDashboardId,
        dynamicDashboardsList,
        setDynamicDashboardId
    } = props;
    const optionsDash: IReportsShareOptions[] = [];
    const options: IReportsShareOptions[] = allowedReports
        .filter((i) => i !== "dashboard")
        .map((i) => {
            return {
                label: t(`reportTitle_${i}`),
                value: i as string,
                dashboard: false
            };
        });

    if (allowedReports.includes("dashboard") && dynamicDashboardsList.length) {
        dynamicDashboardsList.map((item) => {
            optionsDash.push({
                label: item.label,
                value: item.value,
                dashboard: true
            });
        });
    }
    options.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    optionsDash.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    const mergeOptions = [...options, ...optionsDash];
    // const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (action: string) => {
        if (action.includes("dashboardId:")) {
            changeReportToShow("dashboard");
            setDynamicDashboardId(action.split(":")[1]);
        } else {
            changeReportToShow(
                action as CompleoShared.Reports.IJobReportsToRender
            );
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="sort-elastic-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {mergeOptions.map((option, index) => {
                    const addLine =
                        index > 0 &&
                        option.dashboard &&
                        !mergeOptions[index - 1].dashboard;

                    return (
                        <>
                            {addLine && <Divider />}
                            <MenuItem
                                key={option.value}
                                onClick={() => clickMenuItem(option.value)}
                                disabled={
                                    option.value === jobReportToShow ||
                                    (jobReportToShow === "dashboard" &&
                                        option.value ===
                                            `dashboardId:${dynamicDashboardId}`)
                                }
                            >
                                {option.label}
                            </MenuItem>
                        </>
                    );
                })}
            </Menu>
        </div>
    );
};

// const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
//     const translatedNames = menuItems.map((item) => {
//         return {
//             label: t(`ELASTICLISTSETTINGS:MainMenu_${item}`),
//             value: item
//         };
//     });
//     translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
//     return translatedNames;
// };

export default JobReportsMenu;
