import React, { ComponentType, Component, ReactNode, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
// import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import HelperLoading from "Components/Loading/HelperLoading";
import { LanguageContext } from "_ReactContext/LanguageContext";
import { useGlobalDialogForm } from "_ReactContext/GlobalDialogContext";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import theme from "../../theme/Theme";

export type SecondComponentType = {
    component: JSX.Element;
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        // height: "100vh",
        overflow: "auto",
        backgroundColor: "#f4f6f8"
        // backgroundColor: theme.palette.background.default
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    toolbar: theme.mixins.toolbar
}));

interface IBaseProps {
    children: ReactNode;
    className?: string;
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
}

const InternalWithoutMenu = (props: IBaseProps) => {
    const classes = useStyles();
    const { maxWidth } = props;
    const GlobalDialog = useGlobalDialogForm();
    const { company, user } = useAuthState();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);

    let loadingState = false;
    loadingState =
        queryNavigation.status !== "success" ||
        company.companyId === 0 ||
        user === null ||
        user === "" ||
        user === undefined;

    // ***********************************************************************
    // ***********************************************************************
    // Prepare the translation of all main components to control loading in
    // just one place
    // ***********************************************************************
    // ***********************************************************************
    const [
        tChangeLanguage,
        i18nChangeLanguage,
        readyTranslationChangeLanguage
    ] = useTranslation("CHANGELANGUAGE", {
        useSuspense: false
    });

    const readyAllTranslations = readyTranslationChangeLanguage;
    // ***********************************************************************
    // ***********************************************************************
    // END TRANSLATION LOADING
    // ***********************************************************************
    // ***********************************************************************

    if (!loadingState) {
        const language: string = i18nChangeLanguage.languages[0];

        return (
            <LanguageContext.Provider value={language}>
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <main className={classes.content}>
                            <Container
                                className={classes.container}
                                maxWidth={maxWidth}
                            >
                                {props.children}
                                <GlobalDialog.FormDialog />
                            </Container>
                        </main>
                    </div>
                </ThemeProvider>
            </LanguageContext.Provider>
        );
    } else {
        return <HelperLoading />;
    }
};

export default InternalWithoutMenu;
