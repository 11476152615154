import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Box, Chip, createStyles, makeStyles, Theme } from "@material-ui/core";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Delete } from "@material-ui/icons";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            paddingRight: theme.spacing(0.2),
            paddingBottom: theme.spacing(2),
            margin: 0
        },
        content: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(2)
        },
        title: {
            // fontSize: 14,
            marginBottom: theme.spacing(3)
        },
        count: {
            marginTop: 8
        },
        grow: {
            flexGrow: 1
        },
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#2196f3",
            marginRight: 0
        }
    })
);

interface IDashboardMainItem {
    children: any;
    itemName: string;
    ready: boolean;
    t: any;
}

const FunnelItem = (props: IDashboardMainItem) => {
    const { children, itemName: widgetName, ready, t } = props;

    const classes = useStyles();
    const legend = t(`${widgetName}_WidgetLegend`, "");
    if (ready) {
        return (
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Box display="flex" className={classes.title}>
                        <Box>
                            <Typography variant="body2" component="span">
                                <strong>{t(`${widgetName}_Name`)}</strong>
                            </Typography>
                        </Box>
                        <Box flexGrow={1} />
                        {legend && (
                            <Box>
                                {" "}
                                <Chip
                                    className={classes.chip}
                                    size="small"
                                    label={legend}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box padding={1} width="100%" height="100%">
                        {children}
                    </Box>
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Loading />
                </CardContent>
            </Card>
        );
    }
};

export default FunnelItem;
