import { Row } from "@tanstack/react-table";
import { DataTableRow } from "customHooks/DataTable/datatable-row";
import { Link } from "@gatsbyjs/reach-router";

interface JobTitleProps {
    row: Row<Compleo.IObject>;
}
export function JobTitle(props: JobTitleProps) {
    const { row } = props;
    const rowData = row.original as Compleo.IObject;
    const title = rowData.title;
    const jobId = rowData.pk.split(":")[1];
    const finalValue = `${title} - ${jobId}`;
    return (
        <Link to={`/job/view/${jobId}`}>
            <DataTableRow row={row} value={finalValue} />
        </Link>
    );
}
