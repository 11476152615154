export const fieldsRules = {
    fieldsToRemove: [
        "applicantSchedule",
        "applicantEmail",
        "applicantHistoryData",
        "applicantRating",
        "applicantAssessments"
        // "cv",
        // "documents"
    ],
    formsToRemove: [
        "applicantHistoryData",
        "applicantEmailData",
        "applicantScheduleData",
        "applicantRating",
        "applicantAssessments"
        // "cvData",
        // "documentData"
    ]
};
