import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import _ from "lodash";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "../../../Email/ApplicantEmailRecipientsTags";
import { Grid, Typography } from "@material-ui/core";
import useApplicantViewContext from "../useApplicantViewContext";

interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    recipients: IRecipientsTags[];
    threadId: string;
    jobId?: string;
    subject: string;
}

const ModalRepplyEmailThread = (props: IModalNewMail) => {
    const { open, handleClose, recipients, threadId, jobId, subject } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [data] = useApplicantViewContext();
    const applicantId = data.applicantId;

    const module = "EMAILREPLYTHREAD";
    const [metadata] = useGetMetadata(module);

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "template",
            props: {
                type: jobId
                    ? "communicationApplicantJob"
                    : "communicationApplicant"
            }
        }
    ];

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, true);
    initialValues.threadId = threadId;
    initialValues.recipients = recipients;
    const templateArray = initialValues?.template || [];
    for (let index = 0; index < templateArray.length; index++) {
        initialValues.template[index]._tempEmailData.subject = subject;
    }
    if (data?.applicantData?.preferredLanguage) {
        initialValues.applicantPreferredLanguage =
            data?.applicantData?.preferredLanguage;
    }
    // console.log("initialValues", initialValues);

    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        // console.log("values", JSON.stringify(values, null, 4));
        try {
            setIsUpdating(true);
            // console.log("values: ", JSON.stringify(values, null, 4));
            const postAddress = `/email/sendemailthreadreply`;
            await apiDirectCall(postAddress, "post", values);
            setIsUpdating(false);
            handleClose();
            return retorno;
        } catch (ex) {
            console.log("ex", JSON.stringify(ex, null, 4));
            setIsUpdating(false);
            return ex.response;
        }
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        secondaryButtonFunction: handleClose,
        additionalFieldProperties: addProps
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {" "}
                {t("subject")}
                {": "}
                <strong>{subject}</strong>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sm={2} container justify="flex-end">
                        <Typography variant="body2" component="span">
                            <strong>{t("recipients")}:</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                        <ApplicantEmailRecipientsTags
                            applicantEmail={data.applicantData?.email}
                            recipients={recipients}
                            t={t}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item container xs={12}>
                        {FormReturn}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalRepplyEmailThread;
