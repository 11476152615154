import GridLayout from "react-grid-layout";
import useDashboardContext from "../useDashboardContext";
import { breakpointsList } from "./useDashboard";

const useGridOnLayoutChange = () => {
    const [data, dispatch] = useDashboardContext();
    const { dispatchGeneralPageInfo } = dispatch;

    const onLayoutChange = (
        currentLayout: GridLayout.Layout[],
        layouts: GridLayout.Layouts
    ) => {
        const currentDef = data.localStateData?.currentDashboardDef;
        // const defaultLayouts =
        //     data.localStateData.compleoDashboardDef?.layouts || {};

        // debugger;
        if (currentDef) {
            currentDef.layouts = layouts;
            // for (const breakpoint of breakpointsList) {
            //     const widgetsAdjusted = (layouts[breakpoint] || []).map(
            //         (i) => i.i
            //     );

            //     const layoutsToAdd = (defaultLayouts[breakpoint] || []).filter(
            //         (l) => !widgetsAdjusted.includes(l.i)
            //     );
            //     layoutsToAdd.map((l) => {
            //         if (currentDef.layouts[breakpoint]) {
            //             currentDef.layouts[breakpoint].push(l);
            //         }
            //     });
            // }
            dispatchGeneralPageInfo({
                payload: {
                    currentDashboardDef: currentDef
                },
                type: "update"
            });
        }
    };
    return onLayoutChange;
};

export default useGridOnLayoutChange;
