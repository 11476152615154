import React, { ComponentType, Component, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import HelperLoading from "Components/Loading/HelperLoading";
import Logo from "assets/LogoAzul";
import theme from "../../theme/Theme";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f4f6f8"
    },
    container: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    },
    logo: {
        width: "200px"
    },
    logoGrid: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
}));

interface IBaseProps {
    children: ReactNode;
    className?: string;
    featureName?: string;
    featureMainDescription?: string;
    additionalGrid?: ReactNode;
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
}

const Page = (props: IBaseProps) => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("CHANGELANGUAGE", {
        useSuspense: false
    });
    let formSize: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined = "md";
    if (props.maxWidth !== undefined) {
        formSize = props.maxWidth;
    }

    if (readyTranslation) {
        const language: string = i18n.languages[0];
        const hasTitle = props.featureName || props.featureMainDescription;

        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />

                    <main className={classes.content}>
                        <Grid
                            container
                            className={classes.logoGrid}
                            spacing={3}
                            // alignItems="center"
                            // justify="center"
                        >
                            <Logo className={classes.logo} />
                        </Grid>
                        <Container
                            className={classes.container}
                            maxWidth={formSize}
                        >
                            <Grid container spacing={3}>
                                {hasTitle && (
                                    <Grid item xs={12} sm={8}>
                                        {props.featureName !== "" ? (
                                            <Typography
                                                variant="caption"
                                                component="h4"
                                            >
                                                {props.featureName}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                        {props.featureMainDescription !== "" ? (
                                            <Typography
                                                variant="h5"
                                                component="h3"
                                            >
                                                {props.featureMainDescription}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <div className={classes.root}>
                                        {props.children}
                                    </div>
                                </Grid>
                            </Grid>
                            {props.additionalGrid !== null
                                ? props.additionalGrid
                                : null}
                        </Container>
                    </main>
                </div>
            </ThemeProvider>
        );
    } else {
        return <HelperLoading />;
    }
};

export default Page;
