import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import Icon from "@material-ui/core/Icon";
import {
    useCompleoSimpleList,
    actionsTableListType
} from "customHooks/useCompleoSimpleList";

const actionsTable: actionsTableListType = [
    {
        icon: "edit",
        tooltip: "Edit Module",
        onClick: (event: any, rowData: any) => {
            // console.log("rowData", rowData);
            navigate(
                `/admincompleo/editcompleoidea/${rowData.skGS1pk.split(":")[1]}`
            );
        }
    }
];

const ModuleList = (props: any) => {
    const moduleName = "ADMINCOMPLEOIDEASLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/admin/idealist";
    const { company } = useAuthState();
    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
            // totalFakeDataMultiply: 650
        },
        actionsTable
    );

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                rightButton={{
                    text: t("buttonTopRight"),
                    action: () => navigate(`/admincompleo/newcompleoidea`),
                    enabled: true,
                    startIcon: <Icon>add</Icon>
                }}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ModuleList;
