import React from "react";
import { Divider } from "@material-ui/core";
import TranslationGrid from "./TranslationGrid";
import {
    FieldValues,
    useForm,
    UseFormGetValues,
    UseFormReturn,
    useFormContext,
    FormProvider
} from "react-hook-form";
interface IFieldsAndMessageProps {
    formGroup: Compleo.IObject;
    fields: Compleo.IObject[];
    messages: Compleo.IObject[];
    fieldName: string;
    tField: any;
    language: string;
    t: any;
}

const TranslationFieldsAndMessages = (props: IFieldsAndMessageProps) => {
    const {
        formGroup,
        fields,
        messages,
        fieldName,
        tField,
        language,
        t
    } = props;
    return (
        <>
            {fields
                .filter((f: any) => f.formGroupId === formGroup.id)
                .map((f: any) => {
                    return (
                        <React.Fragment key={f.fieldName}>
                            <TranslationGrid
                                language={language}
                                localFieldName={f.fieldName}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(f.fieldName)}
                                type={tField("field")}
                            />
                            <Divider />
                            <TranslationGrid
                                language={language}
                                localFieldName={`${f.fieldName}_helperText`}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(f.fieldName)}
                                type={tField("fieldDescription")}
                            />
                            <Divider />
                            <TranslationGrid
                                language={language}
                                localFieldName={`${f.fieldName}_tooltipTitle`}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(f.fieldName)}
                                type={tField("fieldHelpTitle")}
                            />
                            <Divider />
                            <TranslationGrid
                                language={language}
                                localFieldName={`${f.fieldName}_tooltipDetail`}
                                mainFieldName={fieldName}
                                t={t}
                                tField={tField}
                                label={t(f.fieldName)}
                                type={tField("fieldHelpText")}
                            />
                            <Divider />
                            {messages
                                .filter((m: any) => m.field === f.fieldName)
                                .map((message: any) => (
                                    <React.Fragment key={message.messageId}>
                                        <TranslationGrid
                                            language={language}
                                            localFieldName={message.messageId}
                                            mainFieldName={fieldName}
                                            t={t}
                                            tField={tField}
                                            label={t(message.messageId)}
                                            type={tField("validationMessages")}
                                        />
                                        <Divider />
                                    </React.Fragment>
                                ))}
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default TranslationFieldsAndMessages;
