import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { evaluationPageToRender } from "../EvaluationRequestMain";
import Paper from "@material-ui/core/Paper";
interface IEvaluationTabs {
    pageToRender: evaluationPageToRender;
    setPageToRender: React.Dispatch<
        React.SetStateAction<evaluationPageToRender>
    >;
    pageToRenderList: evaluationPageToRender[];
    t: any;
}
const EvaluationTabs = (props: IEvaluationTabs) => {
    const { pageToRender, setPageToRender, t, pageToRenderList } = props;
    const currentValue = pageToRenderList.indexOf(pageToRender);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const newPageToRender = pageToRenderList[newValue];
        setPageToRender(newPageToRender);
    };

    return (
        <Paper square>
            <Tabs
                value={currentValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
            >
                {pageToRenderList.map((tab, index) => (
                    <Tab key={tab} label={t(`TabPage_${tab}`)} />
                ))}
            </Tabs>
        </Paper>
    );
};

export default EvaluationTabs;
