import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import JobShareButtons from "./JobShareButtons";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

interface IJobShareSiteList {
    listCareers: CompleoShared.CareersSettings.IJobCareersListStatus[];
    classes: any;
    t: any;
}

const JobShareSiteList = (props: IJobShareSiteList) => {
    const { listCareers, classes, t } = props;
    const showMessage = useShowMessage();

    const copyLink = (link: string) => {
        navigator.clipboard.writeText(link);
        showMessage({
            message: t("copyJobLinkSucess"),
            timeout: 1000,
            typeMessage: "success",
            snackBarOrign: {
                vertical: "top",
                horizontal: "center"
            }
        });
    };
    return (
        <>
            {listCareers
                .filter((i) => i.enabled)
                .map((careerItem) => {
                    const careerUniqueLink = (
                        careerItem?.careerSettings?.uniqueLink || ""
                    ).toLowerCase();

                    return (
                        <Paper
                            elevation={1}
                            className={classes.paper}
                            key={careerItem.careerSettings.uniqueLink}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                    >
                                        {t("careers")}:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        style={{ marginLeft: 16 }}
                                    >
                                        <strong>{careerUniqueLink}</strong>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert>
                                        <AlertTitle>
                                            {t("messageJobPublished")}
                                        </AlertTitle>
                                        <a
                                            href={careerItem.rootURL}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ marginRight: 12 }}
                                        >
                                            {careerItem.rootURL}
                                        </a>
                                        <ButtonIconTooltipCompleo
                                            onClick={() => {
                                                copyLink(careerItem.rootURL);
                                            }}
                                            label={t("copyJobLink")}
                                            size="small"
                                        >
                                            <FileCopyIcon />
                                        </ButtonIconTooltipCompleo>
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <JobShareButtons
                                        shareUrl={careerItem.rootURL}
                                        t={t}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}
        </>
    );
};

export default JobShareSiteList;
