import React, { useReducer } from "react";

const reducer = (
    state: Compleo.CustomLists.ListDefinitionType[],
    action: Compleo.CustomLists.ListDefinitionType
) => {
    const newState = state.filter(
        (item) => item.fieldName !== action.fieldName
    );
    newState.push(action);
    return newState;
};

const verifyReady = (
    fieldList: string[],
    state: Compleo.CustomLists.ListDefinitionType[]
) => {
    const currentFieldsLoaded = state.map((item) => item.fieldName);
    for (const field of fieldList) {
        if (!currentFieldsLoaded.includes(field)) {
            return false;
        }
    }
    return true;
};

const useJobList: (
    applicantData?: Compleo.IObject
) => [Compleo.CustomLists.ListDefinitionType[], boolean] = (
    applicantData?: Compleo.IObject
) => {
    const [listDefinition, dispatch] = useReducer(reducer, []);

    const jobs = applicantData?.Jobs || [];
    React.useEffect(() => {
        const jobList: Compleo.IObject[] = [];
        jobs.map((item: any) => {
            jobList.push({
                JobId: item.JobId,
                JobTitle: item.JobTitle
            });
        });
        dispatch({
            data: jobList,
            fieldName: "job",
            labelName: "JobTitle",
            valueName: "JobId"
        });
    }, [jobs.length]);

    const ready = verifyReady(["job"], listDefinition);
    return [listDefinition, ready];
};

export default useJobList;
