import React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import {
    parsePhoneNumberFromString,
    AsYouType,
    getCountries,
    getCountryCallingCode,
    CountryCode,
    parseIncompletePhoneNumber
} from "libphonenumber-js";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

// @ts-ignore
import en from "react-phone-number-input/locale/en.json";

interface IProps {
    label: string;
    helperTextDefault: null | string;
}

const FTextField = (props: IProps & FieldProps) => {
    const startCountry: CountryCode = "BR";
    const [country, setCountry] = React.useState(startCountry);
    const {
        label,
        field,
        form: { dirty, touched, errors, status, setFieldValue },
        helperTextDefault,
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;

    React.useEffect(() => {
        changeValue(field.value, false);
    }, []);

    let mensagemErro =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    const handleChange = (e: any) => {
        changeValue(e.target.value);
    };

    const changeValue = (value: any, addCountryCode: boolean = true) => {
        if (value !== undefined && value !== "") {
            let fromCountry: any = country;
            let phoneNumber = value;
            if (addCountryCode) {
                phoneNumber = `+${getCountryCallingCode(fromCountry)}${value}`;
            }
            const formatedPhoneNumber = formatPhoneNumber(phoneNumber);
            const countryFromPhoneNumber = getCountryName(formatedPhoneNumber);

            if (
                countryFromPhoneNumber !== undefined &&
                countryFromPhoneNumber !== ""
            ) {
                fromCountry = countryFromPhoneNumber;
                setCountry(fromCountry);
            }

            setStateValues(formatedPhoneNumber);
        } else {
            setFieldValue(field.name, "");
        }
    };

    const setStateValues = (value: string) => {
        const unformatedValue = parseIncompletePhoneNumber(value);
        setFieldValue(field.name, unformatedValue);
    };

    const formatPhoneNumber = (value: string) => {
        const phoneNumberParsed = parsePhoneNumberFromString(value);

        if (phoneNumberParsed !== undefined) {
            const countryCallingCode = phoneNumberParsed.countryCallingCode;
            const phoneNumber = new AsYouType(phoneNumberParsed.country).input(
                phoneNumberParsed.nationalNumber.toString()
            );
            let finalPhoneNumber = `+${countryCallingCode} ${phoneNumber}`;

            if (finalPhoneNumber.slice(-1) === ")") {
                finalPhoneNumber = finalPhoneNumber
                    .replace("(", "")
                    .replace(")", "");
            }

            return finalPhoneNumber;
        } else {
            return value;
        }
    };

    const getCountryName = (value: string) => {
        const phoneNumberParsed = parsePhoneNumberFromString(value);

        if (phoneNumberParsed !== undefined) {
            if (phoneNumberParsed.country == undefined) {
                return "";
            } else {
                return phoneNumberParsed.country;
            }
        } else {
            return "";
        }
    };

    const updateCountryFromPhoneNumber = (value: string) => {
        if (value !== undefined && value !== "") {
            let fromCountry: any = country;
            const countryFromPhoneNumber = getCountryName(value);
            if (
                countryFromPhoneNumber !== undefined &&
                countryFromPhoneNumber !== ""
            ) {
                fromCountry = countryFromPhoneNumber;
                if (country !== fromCountry) {
                    setCountry(fromCountry);
                }
            }
        }
    };

    const handleCountryChange = (value: any) => {
        setCountry(value);

        const phoneNumberParsed = parsePhoneNumberFromString(field.value);
        if (phoneNumberParsed !== undefined) {
            const countryCallingCode = phoneNumberParsed.countryCallingCode;
            const newCountryCallingCode = getCountryCallingCode(value);
            let newPhoneNumber = field.value.replace(
                `+${countryCallingCode}`,
                `+${newCountryCallingCode}`
            );
            newPhoneNumber = formatPhoneNumber(newPhoneNumber);
            setStateValues(newPhoneNumber);
        } else {
            const newPhoneNumber = ``;
            setStateValues(newPhoneNumber);
        }
    };

    let newCountries: { [key: string]: any };
    newCountries = en;

    const removeCountryCodeFromShowValue = () => {
        const value = field.value || "";
        const countryCallingCode = getCountryCallingCode(country);
        const newValue = formatPhoneNumber(value);
        updateCountryFromPhoneNumber(value);
        const withoutCountryCode = newValue.replace(
            `+${countryCallingCode}`,
            ""
        );
        return withoutCountryCode.trim();
    };

    const fieldValue = removeCountryCodeFromShowValue();

    const options = getCountries().map((countryItem: CountryCode) => {
        return {
            value: countryItem,
            label:
                newCountries[countryItem] + getCountryCallingCode(countryItem)
        };
    });
    return (
        <React.Fragment>
            <TextField
                id={field.name}
                label={label}
                error={hasError || (status && status[field.name] !== undefined)}
                helperText={mensagemErro}
                {...field}
                {...other}
                // margin="normal"
                value={fieldValue}
                onChange={handleChange}
                // value={phoneNumberSession}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <NativeSelect
                                value={country}
                                onChange={(e: any) =>
                                    handleCountryChange(e.target.value)
                                }
                                inputProps={{
                                    name: `country${field.name}`,
                                    id: `country${field.name}`,
                                    "aria-label": "Choose a country"
                                }}
                            >
                                {getCountries().map(
                                    (countryItem: CountryCode) => (
                                        <option
                                            key={countryItem}
                                            value={countryItem}
                                        >
                                            {newCountries[countryItem]} +
                                            {getCountryCallingCode(countryItem)}
                                        </option>
                                    )
                                )}
                            </NativeSelect>
                        </InputAdornment>
                    )
                }}
            />
        </React.Fragment>
    );
};

export default FTextField;
