import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/FilterList";
import MoreVert from "@material-ui/icons/MoreVert";
import {
    Chip,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import useJobContext from "./useJobContext";
import JobTabs from "./JobTabs";
import ShareIcon from "@material-ui/icons/Share";
import theme from "theme/Theme";
import JobViewMainMenu from "./JobViewMainMenu";
import useSessionValues from "_ReactContext/SessionValues";
import { JobStatusColor } from "customHooks/useList/components/FieldsType/FieldJobStatus";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import JobShareMenu from "./JobShare/JobShareMenu";

const useStyles = () => {
    const isXsSMSize = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [sessionData] = useSessionValues();

    return makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: "flex",
                flexDirection: "column",
                minHeight: 60
            },
            menuButton: {
                marginRight: theme.spacing(2)
            },
            appBar: {
                width: isXsSMSize
                    ? "calc(100vw - 10px)"
                    : `calc(100vw - ${sessionData.menuWidth + 30}px)`,
                zIndex: theme.zIndex.drawer + 1,
                top: theme.mixins.toolbar.minHeight,
                paddingTop: theme.spacing(1.5)
                // backgroundColor: "white"
            },
            toolbar: {
                // zIndex: theme.zIndex.drawer + 5,
                // backgroundColor: "white"
            },
            title: {
                flexGrow: 1
            },
            grow: {
                flexGrow: 1
            },
            pageTitle: {
                // display: "flex",
                display: "inline-block",
                flexDirection: "column",
                justifyContent: "center"
                // width: 100
            },
            chipCard: {
                marginRight: theme.spacing(0.5),
                marginLeft: 8,
                borderRadius: 6
            }
        })
    );
};

interface IProps {}

const JobToolbar = (props: IProps) => {
    const [data, dispatch] = useJobContext();
    const { t, JobId } = data;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [
        anchorElShare,
        setAnchorElShare
    ] = React.useState<null | HTMLElement>(null);

    const classes = useStyles()();
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const openShareMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElShare(event.currentTarget);
    };
    const isJobRequest = data.module === "JOBREQUESTVIEW";

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                color="inherit"
                className={classes.appBar}
                elevation={0}
            >
                <Toolbar className={classes.toolbar}>
                    <Tooltip title={data.Job?.data?.title}>
                        <Typography
                            variant="h6"
                            className={classes.pageTitle}
                            noWrap
                        >
                            {data.Job?.data?.title}
                        </Typography>
                    </Tooltip>
                    <Chip
                        className={classes.chipCard}
                        style={{
                            backgroundColor: JobStatusColor[data.jobStatus],
                            color: "white"
                        }}
                        size="small"
                        label={t(`JOBSTATUS_${data.Job?.data?.status}`)}
                    />
                    {!isJobRequest && (
                        <ButtonIconTooltipCompleo
                            label="Share"
                            onClick={openShareMenu}
                        >
                            <ShareIcon />
                        </ButtonIconTooltipCompleo>
                    )}
                    <div className={classes.grow} />
                    <JobTabs />
                    <IconButton edge="end" color="inherit" onClick={openMenu}>
                        <MoreVert />
                    </IconButton>
                </Toolbar>
                <JobViewMainMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    t={t}
                    jobId={JobId}
                />
                <JobShareMenu
                    anchorEl={anchorElShare}
                    setAnchorEl={setAnchorElShare}
                    t={t}
                    jobId={JobId}
                />
            </AppBar>
        </div>
    );
};

export default JobToolbar;
