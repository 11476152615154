import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import ApplicantView from "./ApplicantView";
import theme from "theme/Theme";
import { Divider, useMediaQuery } from "@material-ui/core";
import { useHotkeys } from "react-hotkeys-hook";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CloseIcon from "@material-ui/icons/Close";
import {
    ApplicantViewExternalData,
    ApplicantViewJobDataInsideJob,
    ApplicantViewModulesType
} from "./useApplicantViewContext/ApplicantViewTypes";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperDialogProps: {
            backgroundColor: theme.palette.backgroundGrey.main,
            minHeight: "95vh",
            maxHeight: "95vh"
        },
        dialogActions: {
            backgroundColor: "white",
            borderTopColor: theme.palette.backgroundGrey.main,
            borderTopWidth: 2,
            borderTopStyle: "solid"
        }
    })
);

export interface IApplicantViewModalContainerNavigationInfo {
    index: number;
    fieldArray: Compleo.IObject[];
    currentPage: number;
    totalPages: number;
    changePageFN: (pageNumber: number) => void;
}

interface IPropsItemFilter {
    open: boolean;
    onClose: () => void;
    ApplicantId?: string;
    navigationInfo?: IApplicantViewModalContainerNavigationInfo;
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    loadingData: boolean;
    tabOpen?: string;
    module: ApplicantViewModulesType;
    externalData?: ApplicantViewExternalData;
    openRatingOnStart?: boolean;
    applicantIdFieldName?: string;
    customCVsData?: Compleo.IObject[];
    evaluationId?: string;
    cvToShare?: Compleo.IObject;
}

const getApplicantId = (
    ApplicantId?: string,
    navigationInfo?: IApplicantViewModalContainerNavigationInfo,
    currentIndex?: number,
    applicantIdFieldName: string = "pk"
) => {
    if (navigationInfo) {
        let fieldData: Compleo.IObject | null = null;
        if (currentIndex !== undefined) {
            fieldData = navigationInfo.fieldArray[currentIndex];
        } else {
            fieldData = navigationInfo.fieldArray[navigationInfo.index];
        }
        const pkData = _.get(fieldData, applicantIdFieldName);
        return (pkData || "").split(":")[1] || pkData;
    } else {
        return ApplicantId || "";
    }
};

const ApplicantViewModalContainer = (props: IPropsItemFilter) => {
    const {
        onClose,
        open,
        ApplicantId,
        navigationInfo,
        jobDataInsideJob,
        loadingData,
        tabOpen,
        module,
        externalData,
        openRatingOnStart,
        applicantIdFieldName,
        customCVsData,
        evaluationId,
        cvToShare
    } = props;
    const classes = useStyles();
    const moduleName = module;

    const [currentIndex, setCurrentIndex] = React.useState(
        navigationInfo?.index !== undefined ? navigationInfo?.index : -1
    );
    const currentPageSize = (navigationInfo?.fieldArray || []).length;
    const localApplicantId = getApplicantId(
        ApplicantId,
        navigationInfo,
        currentIndex,
        applicantIdFieldName
    );

    React.useEffect(() => {
        if (open) {
            setCurrentIndex(
                navigationInfo?.index !== undefined ? navigationInfo?.index : -1
            );
        }
    }, [open]);
    React.useEffect(() => {
        if (open && !localApplicantId && !loadingData) {
            // console.log("loadingData", loadingData);
            onClose();
        }
    }, [open, localApplicantId, loadingData]);

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const isXSScreen = useMediaQuery(theme.breakpoints.only("xs"));
    const classPaperProps = !isXSScreen ? classes.paperDialogProps : "";

    const previousApplicant = (
        currentIndexParam: number,
        currentPageSizeParam: number
    ) => {
        if (currentIndexParam > 0) {
            setCurrentIndex(currentIndexParam - 1);
        } else {
            if (navigationInfo) {
                if (navigationInfo.currentPage > 1) {
                    navigationInfo.changePageFN(navigationInfo.currentPage - 1);
                    setCurrentIndex(currentPageSizeParam - 1);
                    // console.log("last item");
                }
            }
        }
    };

    const nextApplicant = (
        currentIndexParam: number,
        currentPageSizeParam: number
    ) => {
        if (currentIndexParam === currentPageSizeParam - 1) {
            if (navigationInfo) {
                if (navigationInfo.currentPage < navigationInfo.totalPages) {
                    navigationInfo.changePageFN(navigationInfo.currentPage + 1);
                    setCurrentIndex(0);
                    // console.log("last item");
                }
            }
        } else {
            setCurrentIndex(currentIndexParam + 1);
        }
    };

    useHotkeys(
        "ctrl+q,right,left",
        (event: any, handler: any) => {
            switch (handler.key) {
                case "ctrl+q":
                    onClose();
                    break;
                case "right":
                    nextApplicant(currentIndex, currentPageSize);
                    break;
                case "left":
                    previousApplicant(currentIndex, currentPageSize);
                    break;
                default:
                    break;
            }
        },
        {},
        [currentIndex, currentPageSize]
    );

    const disableNextButton =
        currentPageSize === 0 ||
        (navigationInfo?.totalPages === navigationInfo?.currentPage &&
            currentIndex === currentPageSize - 1);
    return (
        <Dialog
            fullWidth
            fullScreen={isXSScreen}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xl"
            aria-labelledby={t("ModalSortColumn_Title")}
            open={open}
            PaperProps={{ className: classPaperProps }}
        >
            <DialogContent style={{ padding: 0, margin: 0 }}>
                {open && localApplicantId && readyTranslation && (
                    <ApplicantView
                        applicantId={localApplicantId}
                        containerType="modal"
                        jobDataInsideJob={jobDataInsideJob}
                        index={currentIndex}
                        tabOpen={tabOpen}
                        module={module}
                        externalData={externalData}
                        openRatingOnStart={openRatingOnStart}
                        customCVsData={customCVsData}
                        evaluationId={evaluationId}
                        cvToShare={cvToShare}
                    />
                )}
            </DialogContent>
            {open && localApplicantId && readyTranslation && (
                <DialogActions className={classes.dialogActions}>
                    {((currentPageSize > 0 &&
                        (navigationInfo?.currentPage || 0) > 1) ||
                        currentIndex > 0) && (
                        <Button
                            onClick={() => {
                                previousApplicant(
                                    currentIndex,
                                    currentPageSize
                                );
                            }}
                            color="primary"
                            variant="outlined"
                            startIcon={<NavigateBeforeIcon />}
                        >
                            {t("COMPLEOGENERAL_PREVIOUS")}
                        </Button>
                    )}
                    {!disableNextButton && (
                        <Button
                            onClick={() => {
                                nextApplicant(currentIndex, currentPageSize);
                            }}
                            color="primary"
                            variant="outlined"
                            endIcon={<NavigateNextIcon />}
                        >
                            {t("COMPLEOGENERAL_NEXT")}
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        color="secondary"
                        variant="contained"
                        startIcon={<CloseIcon />}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ApplicantViewModalContainer;
