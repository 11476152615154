import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";

export const useNextPageFunctionsNewUser = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const verifyEmailAddress = async (nextPageFunction: any, values: any) => {
        const params = {
            fieldValueToValidate: values.email,
            companyId,
            validationType: "userEmailExistsLogged"
        };
        const url = "/general/genericvalidationlogged";
        const validation = await apiDirectCall(url, "post", params);
        const valid = validation.data.validationResult;

        if (!valid) {
            callDialog({
                title: t("emailExists_ModalTitle"),
                bodyText: t("emailExists_Message"),
                agreeButtonText: t("emailExists_Yes"),
                disagreeButtonText: t("emailExists_No"),
                agreeFunction: async () => {
                    navigate(`/user/edit/${values.email}`);
                },
                disagreeFunction: () => {
                    nextPageFunction(true);
                }
            });
        } else {
            nextPageFunction();
        }
    };

    const nextPageFunctions = [{ page: 1, fn: verifyEmailAddress }];

    return nextPageFunctions;
};
