import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "_ReactContext/AuthContext";
import GridLayout from "react-grid-layout";
import useDashboardContext from "../useDashboardContext";
import React from "react";
import {
    IDashboardMainDef,
    IDashboardMainState
} from "../useDashboardContext/DashboardTypes";
import { useApi, useApiCache } from "customHooks/useApi";
import _ from "lodash";

type breakpointsListType = "lg" | "md" | "sm" | "xs" | "xss";
export const breakpointsList: breakpointsListType[] = [
    "lg",
    "md",
    "sm",
    "xs",
    "xss"
];

const useDashboardGetDefaultData = () => {
    const moduleName = "DASHBOARDMAIN";
    const [data, dispatch] = useDashboardContext();

    const { isFeatureAuthorized } = useAuthorization();
    const isAdmin = isFeatureAuthorized("DashboardAdmin"); //TODO: Criar feature
    // const accessType = isAdmin ? "admin" : "user";
    const accessType: CompleoShared.Dashboard.accessType = "admin";

    const [metadata] = useGetMetadata(moduleName);

    const otherPageProps = metadata.response.data?.OtherPageProps;
    const widgets: { [x: string]: CompleoShared.Dashboard.widgetsMetadata } =
        otherPageProps?.widgets || {};
    const breakpoints = otherPageProps?.[`widgetGridLayout_${accessType}`]
        ?.breakpoints || {
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 480,
        xxs: 0
    };
    const cols = otherPageProps?.[`widgetGridLayout_${accessType}`]?.cols || {
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2
    };

    const layouts: GridLayout.Layouts = {};
    for (const breakItem of breakpointsList) {
        if (otherPageProps?.[`widgetGridLayout_${accessType}`]?.[breakItem]) {
            layouts[breakItem] =
                otherPageProps?.[`widgetGridLayout_${accessType}`]?.[breakItem];
        }
    }
    const defaultWidgets: string[] = [];
    const allWidgets: string[] = [];
    for (const widgetName of Object.keys(widgets)) {
        const accessTypeWidget = widgets[widgetName].accessType;
        if (accessTypeWidget.includes(accessType)) {
            if (widgets[widgetName].defaultPage === true) {
                defaultWidgets.push(widgetName);
            }
            allWidgets.push(widgetName);
        }
    }

    React.useEffect(() => {
        if (
            Object.keys(layouts).length &&
            defaultWidgets.length &&
            allWidgets.length
        ) {
            const dashboardDef: IDashboardMainDef = {
                layouts: layouts,
                widgetList: defaultWidgets,
                widgetListAllOptions: allWidgets
            };
            dispatch.dispatchGeneralPageInfo({
                payload: { compleoDashboardDef: dashboardDef },
                type: "update"
            });
        }
    }, [Object.keys(layouts).length, defaultWidgets.length, allWidgets.length]);

    const ready =
        metadata.status === "success" &&
        data?.localStateData?.compleoDashboardDef;

    return {
        breakpoints,
        cols,
        widgets,
        ready,
        accessType
    };
};

const useDashboard = () => {
    const [data, dispatch] = useDashboardContext();
    const defaultData = useDashboardGetDefaultData();
    const [mainReady, setMainReady] = React.useState(false);

    const postAddressSearch = `/reports/listdashboards`;
    const [getListDashboard] = useApiCache(
        postAddressSearch,
        "post",
        { isAdmin: true, companyId: data.companyId },
        false
    );
    const listData = getListDashboard.response?.data || [];
    const listReady = getListDashboard.status === "success";

    React.useEffect(() => {
        if (listReady && defaultData.ready) {
            const dashData = getDashboard(data, listData);
            const dashList = getDashboardList(data, listData);
            const defaultDashData = _.cloneDeep(
                data.localStateData?.compleoDashboardDef
            );

            if (dashData) {
                dispatch.dispatchGeneralPageInfo({
                    payload: {
                        currentDashboardDef: dashData,
                        mainDashboardDef: defaultDashData,
                        dashboardList: dashList
                    },
                    type: "update"
                });
            } else {
                if (
                    defaultDashData &&
                    data.localStateData?.pageToRender === "main"
                ) {
                    dispatch.dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: defaultDashData,
                            dashboardList: dashList
                        },
                        type: "update"
                    });
                } else {
                    dispatch.dispatchGeneralPageInfo({
                        payload: {
                            currentDashboardDef: undefined,
                            mainDashboardDef: defaultDashData,
                            dashboardList: dashList
                        },
                        type: "update"
                    });
                }
            }
            setMainReady(true);
        }
    }, [
        listData.length,
        defaultData.ready,
        listReady,
        data.localStateData?.pageToRender
    ]);

    return { ...defaultData, ready: mainReady };
};

const getDashboard = (
    dataContext: IDashboardMainState,
    listData: Compleo.IObject[]
) => {
    const pageToRender = dataContext.localStateData.pageToRender;
    switch (pageToRender) {
        case "main": {
            const dashData = listData.filter((item: Compleo.IObject) =>
                item.skGS1pk.includes("TYPE:MAIN#")
            )[0];
            if (dashData) {
                const dashToRender: IDashboardMainDef = {
                    layouts: dashData?.gridLayoutDef?.layouts || {},
                    widgetList: dashData?.gridLayoutDef?.widgetList || {},
                    widgetListAllOptions:
                        dataContext.localStateData?.compleoDashboardDef
                            ?.widgetListAllOptions || []
                };
                return dashToRender;
            } else {
                return undefined;
            }
        }
        default: {
            const dashData = getDashboardList(dataContext, listData);
            const dashIdToRender = getLastDashboardToRender(dashData);
            const dashToRender =
                dashData.filter((i) => i.id === dashIdToRender)[0] || null;
            return dashToRender;
        }
    }
};

const getDashboardList = (
    dataContext: IDashboardMainState,
    listData: Compleo.IObject[]
) => {
    const dashList: IDashboardMainDef[] = listData
        .filter(
            (item: Compleo.IObject) =>
                item.skGS1pk.includes("TYPE:CUSTOM#") ||
                item.skGS1pk.includes("TYPE:COMPANY#")
        )
        .map((item) => {
            const type = item.skGS1pk.includes("TYPE:CUSTOM#")
                ? "user"
                : "company";
            const id =
                type === "user"
                    ? item.skGS1pk.split("#")[2].split(":")[1]
                    : item.skGS1pk.split("#")[1].split(":")[1];
            return {
                name: item.name,
                layouts: item.gridLayoutDef?.layouts || {},
                widgetList: item.gridLayoutDef?.widgetList || [],
                widgetListAllOptions:
                    dataContext.localStateData?.compleoDashboardDef
                        ?.widgetListAllOptions || [],
                type: type,
                id: id
            };
        });

    return dashList;
};

const dashboardToRenderKey = "dashboardLastDashboardToRender";

const getLastDashboardToRender = (dashData: Compleo.IObject[]) => {
    const dataFromStorage = localStorage.getItem(dashboardToRenderKey);
    const dashIdList = dashData.map((i) => i.id);

    if (dataFromStorage && dashIdList.includes(dataFromStorage as string)) {
        const returnData = dataFromStorage as string;
        return returnData;
    }
    return dashIdList[0];
};

export const setLastDashboardToRender = (
    dashboardId: string,
    dashData: IDashboardMainDef[]
) => {
    const dashIdList = dashData.map((i) => i.id);

    if (dashboardId && dashIdList.includes(dashboardId)) {
        localStorage.setItem(dashboardToRenderKey, dashboardId);
    }
};

export default useDashboard;
