import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { getCustomSearchOrderedLocal } from "customHooks/useList/ListHelpers/ListUtil";
import CardJobFields from "./CardFields";
import { Grid } from "@material-ui/core";

import OtherActionsJob from "../_componentsJob/OtherActions";
import OtherActionsActivities from "../_componentsActivities/OtherActions";
import StatusActionsJob from "../_componentsJob/StatusActions";
import FieldJobStatus from "../FieldsType/FieldJobStatus";
import OtherActionsApplicant from "../_componentsApplicant/OtherActions";
import OtherActionsJobRequest from "../_componentsJobRequest/OtherActions";

import OtherActionsJobApplicant from "../_componentsJobApplicant/OtherActions";
import StatusActionsJobApplicant from "../_componentsJobApplicant/StatusActions";

import useGlobalValues from "_ReactContext/GlobalValues";
import JobKanbanApplicantActionButtons from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActionButtons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // maxWidth: 345,
            width: "100%",
            marginBottom: theme.spacing(2),
            height: "100%"
        },
        media: {
            height: 140
        },
        chip: {
            marginRight: theme.spacing(0.5)
        },
        avatarSmall: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        buttonRight: {
            marginLeft: "auto"
        }
    })
);

interface IProps {
    fieldData: Compleo.IObject;
}

const CardApplicant = (props: IProps) => {
    const { fieldData } = props;
    const [globalValues, globalValuesDispatch] = useGlobalValues();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields, otherSettings } =
        listData.definitionInfo.elasticDefData?.data || {};
    const moduleName = listData.tempInfo.moduleName;

    const sessionName = `cardFieldsOrder_${listData.tempInfo.moduleName}`;
    const orderData = globalValues.userdb[sessionName];
    const cardFieldsDetail = getCustomSearchOrderedLocal(
        cardFields || {},
        orderData,
        "primary"
    );

    const orderedFields = cardFieldsDetail
        .sort((a, b) => a.order - b.order)
        .map((item) => item.name);

    const maxItemsToShow = otherSettings?.cardMaxFieldsToShow || 8;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container>
                    {orderedFields.slice(0, maxItemsToShow).map((fieldName) => {
                        const fieldDef = cardFields[fieldName];
                        return (
                            <CardJobFields
                                key={fieldName}
                                fieldData={fieldData}
                                fieldName={fieldName}
                                type={fieldDef?.type}
                            />
                        );
                    })}
                </Grid>
            </CardContent>
            <CardActionsItem moduleName={moduleName} fieldData={fieldData} />
        </Card>
    );
};

interface ICardActions {
    fieldData: Compleo.IObject;
    moduleName: string;
}

const CardActionsItem = (props: ICardActions) => {
    const { fieldData, moduleName } = props;
    const [listData] = useList();

    switch (moduleName) {
        case "APPLICANTLIST":
        case "JOBAPPLICANTLIST":
            return (
                <CardActions disableSpacing>
                    <OtherActionsApplicant fieldData={fieldData} />
                </CardActions>
            );
        case "JOBLIST":
            return (
                <CardActions disableSpacing>
                    <FieldJobStatus
                        fieldData={fieldData}
                        fieldName={"status"}
                        returnType={"card"}
                    />
                    <StatusActionsJob
                        fieldData={fieldData}
                        t={listData.tempInfo.t}
                        useApiKey={listData.tempInfo.useApiKey}
                    />
                    <OtherActionsJob fieldData={fieldData} />
                </CardActions>
            );
        case "JOBREQUESTLIST":
            return (
                <CardActions disableSpacing>
                    <FieldJobStatus
                        fieldData={fieldData}
                        fieldName={"status"}
                        returnType={"card"}
                    />
                    <StatusActionsJob
                        fieldData={fieldData}
                        t={listData.tempInfo.t}
                        useApiKey={listData.tempInfo.useApiKey}
                        isJobRequestList={true}
                    />
                    <OtherActionsJobRequest fieldData={fieldData} />
                </CardActions>
            );
        case "JOBAPPLICANTLISTJOBVIEW":
            return (
                <CardActions disableSpacing>
                    <StatusActionsJobApplicant fieldData={fieldData} />
                </CardActions>
            );
        case "REPORTSACTIVITIES":
            return (
                <CardActions disableSpacing>
                    <OtherActionsActivities fieldData={fieldData} />
                </CardActions>
            );
        default:
            return null;
    }
};

export default CardApplicant;
