import React from "react";
import TextField from "@material-ui/core/TextField";
import { IInputProps, useRhfField } from "../helpers/reactHookFormsHelper";

const FTextField = (props: IInputProps) => {
    const { name, label, helperTextDefault, maxLength, t, ...other } = props;
    const { error, message, registerReturn } = useRhfField(
        name,
        helperTextDefault
    );
    // const refData = register(name);

    return (
        <TextField
            label={label}
            id={name}
            error={error}
            helperText={message}
            {...other}
            inputProps={{
                maxLength: maxLength
            }}
            {...registerReturn}
        />
    );
};

export default FTextField;
