import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    createStyles,
    FormControlLabel,
    makeStyles,
    Switch,
    Theme
} from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardQuestionsAnswers from "./CardQuestionsAnswers";
import ComboAutoComplete from "customHooks/useCompleoReactHookForm/Inputs/ComboAutoComplete";
import { useTranslation } from "react-i18next";
import {
    useRhfFieldControlled,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IPropsStageItems {
    listSections: Compleo.IObject[];
    classError: any;
    t: any;
    handleEditStage: any;
    handleDeleteStage: any;
    questionsFieldName: string;
    resultsFieldName: string;
    enableRanking: boolean;
    enableResultsByRankingScore: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        comboCard: {
            marginLeft: "auto",
            marginRight: theme.spacing(2),
            minWidth: "80%"
        }
    })
);

const getOptions = (
    questions: Compleo.IObject[],
    currentIndex: number,
    results: Compleo.IObject[],
    t: any
) => {
    const nextQuestions = questions.slice(currentIndex + 1);
    const resultsArr = [...results].map((item, index) => {
        return {
            label: item.name,
            value: item.name,
            group: t("labelResults")
        };
    });
    resultsArr.push({
        label: t("labelEndQuestionnaire"),
        value: "finished",
        group: t("labelResults")
    });
    nextQuestions.map((item) =>
        resultsArr.push({
            label: item.name,
            value: item.id,
            group: t("labelQuestion")
        })
    );
    return resultsArr;
};

const QuestionItems = (props: IPropsStageItems) => {
    const {
        listSections,
        classError,
        t,
        handleEditStage,
        handleDeleteStage,
        questionsFieldName,
        resultsFieldName,
        enableRanking,
        enableResultsByRankingScore
    } = props;

    const { field, setFieldValue } = useRhfFieldControlled(questionsFieldName);

    const { watch } = useRhfFieldWithoutControllerOrRegister(resultsFieldName);
    const questionsValues = watch(questionsFieldName);
    const resultsValues = watch(resultsFieldName);

    const removeResultsFromActions =
        enableRanking && enableResultsByRankingScore;

    const classes = useStyles();

    const modalModuleName = "QUESTIONNAIRENEW";
    const [tQNEW] = useTranslation(modalModuleName, {
        useSuspense: false
    });

    const handleEnableActions = (index: number) => {
        const currentStateValue = [...questionsValues];
        currentStateValue[index].enableActions = !currentStateValue[index]
            .enableActions;
        setFieldValue(currentStateValue);
    };
    // const newList = listSections;
    // console.log("newList", newList);
    return (
        <>
            {listSections.map((section: any, index: number) => {
                const options = getOptions(
                    questionsValues || [],
                    index,
                    removeResultsFromActions ? [] : resultsValues || [],
                    tQNEW
                );
                const enableActions = (questionsValues || [])[index]
                    ?.enableActions;
                const questionType = (questionsValues || [])[index]
                    ?.questiontype?.value;
                // console.log("enableActions", enableActions);
                // console.log("questionsValues", questionsValues);

                const mainListCurrentItem = listSections.findIndex(
                    (item: any) => item.id === section.id
                );
                return (
                    <Draggable
                        key={section.id}
                        draggableId={section.id}
                        index={index}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <Card
                                className={classes.root}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <CardHeader
                                    title={t(section.name)}
                                    titleTypographyProps={{
                                        variant: "body1"
                                    }}
                                    subheader={section.questiontype.label}
                                    subheaderTypographyProps={{
                                        variant: "caption"
                                    }}
                                    action={
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={enableActions}
                                                    onChange={() =>
                                                        handleEnableActions(
                                                            index
                                                        )
                                                    }
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label={tQNEW(
                                                "labelEnableQuestionActions"
                                            )}
                                        />
                                    }
                                />
                                <CardQuestionsAnswers
                                    answers={section.answers}
                                    t={t}
                                    index={index}
                                    options={options}
                                    enableActions={enableActions}
                                    questionType={questionType}
                                    questionsFieldName={questionsFieldName}
                                    enableRanking={enableRanking}
                                />
                                <CardActions disableSpacing>
                                    <Tooltip
                                        title={t("ImageField_deleteQuestion")}
                                    >
                                        <IconButton
                                            aria-label={t(
                                                "ImageField_deleteQuestion"
                                            )}
                                            color="secondary"
                                            onClick={() =>
                                                handleDeleteStage(section)
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={t("ImageField_editQuestion")}
                                    >
                                        <IconButton
                                            aria-label={t(
                                                "ImageField_editQuestion"
                                            )}
                                            color="primary"
                                            onClick={() =>
                                                handleEditStage(
                                                    section,
                                                    mainListCurrentItem
                                                )
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    {enableActions && (
                                        <ComboAutoComplete
                                            required={false}
                                            name={`${questionsFieldName}.${index}.action`}
                                            label={t("dropdownActionQuestion")}
                                            options={options}
                                            groupByField="group"
                                            className={classes.comboCard}
                                            campoMetadata={{}}
                                            language={"pt-BR"}
                                        />
                                    )}
                                </CardActions>
                            </Card>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default QuestionItems;
