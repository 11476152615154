import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";
import { useTranslation } from "react-i18next";

export const useCancelButtonForm = (navigateTo: string) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const [t, i18n, readyTranslation] = useTranslation("USECANCELBUTTONFORM", {
        useSuspense: false
    });

    const fCancel = () =>
        callDialog({
            title: t("title"),
            bodyText: t("message"),
            agreeButtonText: t("buttonYes"),
            disagreeButtonText: t("buttonNo"),
            agreeFunction: async () => {
                navigate(navigateTo);
            },
            disagreeFunction: () => {}
        });
    return fCancel;
};
