import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { IValidateOnEntryDefinition } from "customHooks/useCompleoReactHookForm/Inputs/TagsCreatable";
import { validateEmail } from "functions/util";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import useCustomLists from "./useCustomLists";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import isEqual from "lodash/isEqual";

export interface IGenericStageActionsModal {
    open: boolean;
    onClose: () => void;
    fieldName: string;
    module: string;
    setValue: UseFormSetValue<FieldValues>;
    initialValuesDB: Compleo.IObject;
}

const GenericStageActionsModal = (props: IGenericStageActionsModal) => {
    const {
        open,
        onClose,
        fieldName,
        module,
        setValue,
        initialValuesDB = {}
    } = props;

    const [isUpdating, setIsUpdating] = React.useState(false);
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const defaultFieldsToHide: string[] = React.useMemo(
        () => ["cvToShare"],
        []
    );
    const [listDefinition, listsReady] = useCustomLists(t, readyTranslation);

    const [initialValues] = useValuesFromSource(metadata, false, {
        _ignoreUploadEmailFields: true,
        _ignoreUploadFileFields: true,
        ...initialValuesDB
    });

    const tagsAddProps: IValidateOnEntryDefinition = {
        errorMessage: t("actionApplicantRequestEvaluation.invalidEmail"),
        validateFunction: validateEmail
    };
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "actionApplicantRequestEvaluation.externalEvaluators",
            props: {
                validateOnEntryDefinition: tagsAddProps
            }
        }
    ];

    const ready =
        readyTranslation && metadata.status === "success" && listsReady;

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const displayCVField = watch("displayCV");
    React.useEffect(() => {
        let fieldsToHideFN = defaultFieldsToHide;
        if (displayCVField) {
            fieldsToHideFN = defaultFieldsToHide.filter(
                (item) => item !== "cvToShare"
            );
        }
        if (!isEqual(fieldsToHide, fieldsToHideFN)) {
            setFieldsToHide(fieldsToHideFN);
        }
    }, [displayCVField, defaultFieldsToHide, fieldsToHide]);

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        setIsUpdating(true);
        retorno.status = 200;
        try {
            const newValues = _.cloneDeep(values);
            delete newValues._ignoreUploadEmailFields;
            delete newValues._ignoreUploadFileFields;
            setValue(fieldName, [newValues]);
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: listDefinition,
        additionalFieldProperties: addProps,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide: fieldsToHide
    });

    const handleSave = async () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }
    if (ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_ModalTitle")}
                open={open}
            >
                <DialogTitle>{t("a_ModalTitle")}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_FeatureMainDescription")}
                open={open}
            >
                <DialogContent dividers>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

export default GenericStageActionsModal;
