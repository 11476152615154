import { Component } from "react";
import { connect } from "formik";
import scrollToElement from "scroll-to-element";

// Based on this gist: https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b
class ErrorFocus extends Component {
    static defaultProps = {
        offset: 0,
        align: "top",
        focusDelay: 200,
        ease: "linear",
        duration: 1000
    };

    componentDidUpdate(prevProps) {
        const orderErrorsObject = (errors, camposMetadados) => {
            const newErrors = [];
            camposMetadados.sort((a, b) => {
                if (a.stepOrder !== b.stepOrder) {
                    return a.stepOrder - b.stepOrder;
                }
                if (a.formGroupOrder !== b.formGroupOrder) {
                    return a.formGroupOrder - b.formGroupOrder;
                }
                if (a.order === undefined && b.order === undefined) {
                    return 0;
                }
                if (a.order === undefined) {
                    return -1;
                }
                if (b.order === undefined) {
                    return 1;
                }
                return a.order - b.order;
            });

            camposMetadados.map((c) => {
                const objKey = Object.keys(errors).filter(
                    (e) => e === c.fieldName
                );
                if (objKey !== undefined && objKey.length > 0) {
                    newErrors.push(objKey[0]);
                }
            });
            return newErrors;
        };

        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const { camposMetadados } = prevProps;
        let sortedKeys = [];
        if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            sortedKeys = orderErrorsObject(errors, camposMetadados);
        }
        const keys = sortedKeys;

        if (keys.length > 0 && isSubmitting && !isValidating) {
            const selector = `[name="${keys[0]}"]`;
            const errorElement = document.querySelector(selector);

            if (errorElement) {
                const {
                    offset,
                    ease,
                    duration,
                    focusDelay,
                    align
                } = this.props;
                scrollToElement(errorElement, {
                    offset,
                    ease,
                    duration,
                    align
                });

                this.timeout = setTimeout(
                    () => errorElement.focus(),
                    duration + focusDelay
                );
            }
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        return null;
    }
}

export default connect(ErrorFocus);
