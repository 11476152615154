import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";

const JobTitleEdit = (props: any) => {
    // const classes = useFormStyles();
    const cancelFunction = useCancelButtonForm("/jobtitle/list");

    const { company } = useAuthState();

    const [metadata] = useGetMetadata("JOBTITLEEDIT");
    const [t, i18n, readyTranslation] = useTranslation("JOBTITLEEDIT", {
        useSuspense: false
    });
    //console.log("BranchEdit props", props);
    const postAddress = `/jobtitle/jobtitleedit`;

    const [editBranchReturn, editBranch] = useApi(postAddress, "post");

    const postAddressSearch = `/jobtitle/jobtitlesearch`;
    const [getBranchReturn, getBranch] = useApiCache(
        postAddressSearch,
        "post",
        {
            id: props.uuid,
            companyId: company.companyId
        },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getBranchReturn,
        ["data", "data"]
    );
    valuesFromSource.id = props.uuid;

    const [formCompleo, finished] = useCompleoForm(
        null,
        t,
        props,
        readyTranslation,
        i18n,
        editBranch,
        editBranchReturn,
        "/jobtitle/list",
        FormClassic,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined,
        cancelFunction
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation && getBranchReturn.status === "success") {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobTitleEdit;
