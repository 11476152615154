import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {
    IApplicantHistoryProps,
    getColorByHistoryType,
    getSizeByHistoryType
} from "./ApplicantHistoryHelper";
import { TimelineOppositeContent } from "@material-ui/lab";
import Skeleton from "@material-ui/lab/Skeleton";
import ApplicantHistoryLoading from "./Items/ApplicantHistoryLoading";
import CachedIcon from "@material-ui/icons/Cached";

const useStyles = (dotColor: string, heightRoot: number) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            "@keyframes rotation": {
                from: {
                    transform: "rotate(0deg)"
                },
                to: {
                    transform: "rotate(359deg)"
                }
            },
            root: { margin: theme.spacing(2) },
            paper: { padding: theme.spacing(2) },
            timeDot: {
                borderColor: dotColor,
                backgroundColor: dotColor,
                animation: "rotation 2s infinite linear"
            },
            icon: {
                color: "white",
                animation: "rotation 2s infinite linear"
            },
            content: { height: heightRoot }
        })
    );
};

const ApplicantHistoryTimelineItemLoading = (props: IApplicantHistoryProps) => {
    const { historyItem } = props;
    const color = getColorByHistoryType(historyItem?.type);
    const heightItem = getSizeByHistoryType(historyItem?.type);
    const classes = useStyles(color, heightItem)();

    return (
        <TimelineItem className={classes.root}>
            <TimelineOppositeContent style={{ flex: 0.2 }}>
                <Skeleton variant="text" width="100%" />
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot variant="default" className={classes.timeDot}>
                    <CachedIcon className={classes.icon} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <ApplicantHistoryLoading />
            </TimelineContent>
        </TimelineItem>
    );
};

export default ApplicantHistoryTimelineItemLoading;
