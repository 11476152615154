import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3, 2),
            width: "100%"
        },
        tipoDetail: {
            marginTop: theme.spacing(3)
        }
    })
);

interface IProps {
    errorTitle: string;
    errorDescription: string;
}

export const CompleoErrorMessage = (props: IProps) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Typography variant="h5" component="h3" color="secondary">
                {props.errorTitle}
            </Typography>
            <Typography component="p" className={classes.tipoDetail}>
                {props.errorDescription}
            </Typography>
        </Paper>
    );
};

export const PageNotFoundComponent = () => {
    const classes = useStyles();
    const [t, i18n, readyTranslation] = useTranslation("PAGENOTFOUND", {
        useSuspense: false
    });

    if (readyTranslation) {
        return (
            <CompleoErrorMessage
                errorTitle={t("title")}
                errorDescription={t("description")}
            />
        );
    } else {
        return <Loading />;
    }
};

export default CompleoErrorMessage;
