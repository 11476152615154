import React from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { apiDirectCall, useApi, useApiCache } from "customHooks/useApi";
import { useQueryClient } from "react-query";

const listAddress = "/notifications/fastlist";

export interface IUseModalNotificationsReturn {
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    openMenu: (
        event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
    ) => void;
    options: any[];
    total: number;
}
export type useModalNotificationsType = () => IUseModalNotificationsReturn;

const useModalNotifications: useModalNotificationsType = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = (
        event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const { company, user } = useAuthState();
    const [data, dataStatus] = useApiCache(
        listAddress,
        "post",
        {
            companyId: company.companyId,
            limitReturn: 1000
        },
        false,
        undefined,
        undefined,
        undefined,
        { refetchInterval: 1000 * 60, refetchIntervalInBackground: false } // busca novas notificações a cada minuto
    );
    const options: any[] = data?.response?.data || [];
    const total = options.length;
    const returnData: IUseModalNotificationsReturn = {
        anchorEl,
        setAnchorEl,
        openMenu,
        options,
        total
    };
    return returnData;
};

export const useMarkMessageAsRead = () => {
    const { company } = useAuthState();
    const queryClient = useQueryClient();

    const markMessageAsRead = async (notificationId: string) => {
        const listAddressMarkAsRead = "/notifications/settoread";
        await apiDirectCall(listAddressMarkAsRead, "post", {
            companyId: company.companyId,
            notificationId: notificationId
        });
        queryClient.invalidateQueries(listAddress);
    };

    const markAllMessagesAsRead = async () => {
        const listAddressMarkAsRead = "/notifications/settoread";
        await apiDirectCall(listAddressMarkAsRead, "post", {
            companyId: company.companyId,
            deleteAllUser: true
        });
        queryClient.invalidateQueries(listAddress);
    };
    return { markMessageAsRead, markAllMessagesAsRead };
};

export default useModalNotifications;
