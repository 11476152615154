import React from "react";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { apiDirectCall } from "customHooks/useApi/api";
import FileViewer from "Components/FileViewer";
import PDFViewerStream from "Components/PDFViewerStream";

const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            // padding: theme.spacing(4),
            // paddingBottom: theme.spacing(6),
            width: "100%",
            marginTop: theme.spacing(1),
            padding: 0
            // height: 700
            // margin: 0
        }
    })
);

const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

interface IProps {
    url: string;
    isLocalStreamUrl?: boolean;
}

const ApplicantCVPreview = (props: IProps) => {
    const classes = useFormStyles();
    // const [linkCV, setLinkCV] = React.useState<string | null>(null);
    const { url, isLocalStreamUrl } = props;
    // if (linkCV === null && cvkey !== undefined) {
    //     linkFromS3(cvkey).then((link: any) => {
    //         // console.log("link", link);
    //         if (link === null) {
    //             setLinkCV("");
    //         } else {
    //             setLinkCV(link);
    //         }
    //     });
    // } else if (url) {
    //     setLinkCV(url);
    // }

    // let LinkCV: JSX.Element | null = !url ? null : <Loading />;

    if (url) {
        return (
            <Paper className={classes.paper}>
                <PDFViewerStream
                    path={url}
                    isLocalStreamUrl={isLocalStreamUrl}
                />
            </Paper>
        );
    } else {
        return (
            <Typography color="secondary">
                Não foi possível carregar o currículo
            </Typography>
        );
    }
    // return LinkCV;
};

export default ApplicantCVPreview;
