import React from "react";
import * as Home from "Pages/Home/_HomeConfig";
import * as User from "Pages/Users/_UsersConfig";
import * as Login from "Pages/Login/_LoginConfig";
import * as Tests from "Pages/TestsSample/_TestsConfig";
import * as InputsExample from "Pages/InputsExample/_InputsExampleConfig";
import * as Customer from "Pages/Customer/_CustomerConfig";
import * as Job from "Pages/Job/_JobConfig";
import * as Branch from "Pages/Branch/_BranchConfig";
import * as Applicant from "Pages/Applicant/_ApplicantConfig";
import * as Trial from "Pages/Trial/_TrialConfig";
import * as AdminCompleo from "Pages/adminCompleo/_adminConfig";
import * as Settings from "Pages/Settings/_SettingsConfig";
import * as Role from "Pages/Role/_RoleConfig";
import * as Contact from "Pages/Contact/_ContactConfig";
import * as Pipeline from "Pages/Pipeline/_PipelineConfig";
import * as Scorecard from "Pages/Scorecard/_ScorecardConfig";
import * as ApplicationForm from "Pages/ApplicationForm/_ApplicationFormConfig";
import * as LanguageSettings from "Pages/LanguageSettings/_LanguageSettingsConfig";
import * as Questionnaire from "Pages/Questionnaire/_QuestionnaireConfig";
import * as EmailTemplate from "Pages/EmailTemplate/_Config";
import * as BulkActions from "Pages/BulkAction/_BulkActionsConfig";
import * as Notifications from "Pages/Notification/_NotificationConfig";
import * as Profile from "Pages/Profile/_ProfileConfig";
import * as ScheduleTemplate from "Pages/ScheduleTemplate/_Config";
import * as Calendar from "Pages/Calendar/_CalendarConfig";
import * as ApplicantDataSettings from "Pages/ApplicantDataSettings/_ApplicantDataSettingsConfig";
import * as EvaluationRequestExternal from "Pages/EvaluationRequestExternal/_Config";
import * as Dashboard from "Pages/Dashboard/_Config";
import * as Reports from "Pages/Reports/_Config";
import * as Search from "Pages/Search/_Config";
import * as CareersSettings from "Pages/CareersSettings/_Config";
import * as ExportSettings from "Pages/ExportData/_ExportDataActionsConfig";
import * as JobTitle from "Pages/JobTitle/_JobTitleConfig";
import * as CustomCV from "Pages/CustomCV/_Config";

import { useApi } from "customHooks/useApi";

const componentsArray = [
    Home,
    User,
    Login,
    Tests,
    Customer,
    Job,
    Branch,
    InputsExample,
    Trial,
    Applicant,
    AdminCompleo,
    Settings,
    Contact,
    Role,
    Pipeline,
    ApplicationForm,
    LanguageSettings,
    Scorecard,
    Questionnaire,
    EmailTemplate,
    BulkActions,
    Notifications,
    Profile,
    ScheduleTemplate,
    Calendar,
    ApplicantDataSettings,
    EvaluationRequestExternal,
    Dashboard,
    Reports,
    Search,
    CareersSettings,
    ExportSettings,
    JobTitle,
    CustomCV
];
const components: Compleo.IObject = [];
componentsArray.map((mainComp: any) => {
    Object.entries(mainComp).map((obj: any) => {
        components[obj[0]] = obj[1];
    });
});

type useRoutesType = {
    isLoading: boolean;
    routes: Compleo.IObject[] | null;
};

const useRoutes = () => {
    const [routesReturn, getRoutes] = useApi("/general/listroutes", "get");
    const [useRoutesReturn, setUseRoutesResturn] = React.useState<
        useRoutesType
    >({ isLoading: true, routes: null });

    React.useEffect(() => {
        getRoutes();
    }, []);

    if (
        routesReturn.status === "success" &&
        useRoutesReturn.isLoading === true
    ) {
        const routesToReturn: Compleo.IObject[] = [];
        const dataRoutes = routesReturn.response.data.routes;
        dataRoutes
            .filter((item: any) => item.component)
            .map((droute: any) => {
                // debugger;
                // const Comp = components.filter((c: any) => c[droute.component]);
                const Comp = components[droute.component];
                routesToReturn.push({
                    ...droute,
                    componentName: droute.component,
                    component: Comp
                });
            });
        setUseRoutesResturn({ isLoading: false, routes: routesToReturn });
    }
    return useRoutesReturn;
};

export default useRoutes;
