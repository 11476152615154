import React from "react";
import { CompleoListPage } from "customHooks/useList";

const JobRequestList = (props: any) => {
    const module = "JOBREQUESTLIST";

    return (
        <CompleoListPage
            moduleName={module}
            listApiAddress="/jobrequest/listrequest"
        />
    );
};

export default JobRequestList;
