import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useTranslation } from "react-i18next";

export const useCancelModalEdit = (onClose: (value?: string) => void) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const [t, i18n, readyTranslation] = useTranslation(
        "USECANCELBUTTONEDITMODAL",
        {
            useSuspense: false
        }
    );

    const fCancel = () =>
        callDialog({
            title: t("title"),
            bodyText: t("message"),
            agreeButtonText: t("buttonYes"),
            disagreeButtonText: t("buttonNo"),
            agreeFunction: async () => {
                onClose();
            },
            disagreeFunction: () => {}
        });
    return fCancel;
};
