import React, { Component, useState } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
// import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { navigate } from "@gatsbyjs/reach-router";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import FormData from "./components/FormData";
import Translations from "./components/TranslationComponent/Translations";
import useHandleInitialValues from "./components/useHandleInitialValues";
import useGetLanguagesCareers from "../../customHooks/useGetLanguages/useGetLanguages";
import CustomComponents from "./components/CustomComponents";

const ApplicationFormEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/applicationform/list");
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const languagesString = (languages || []).map((l: any) => l.value);
    const { company } = useAuthState();
    const moduleName = "APPLICATIONFORMEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/applicationform/edit`;

    const [editApplicationFormReturn, editApplicationForm] = useApi(
        postAddress,
        "post"
    );
    const [metadata] = useGetMetadata(moduleName);

    const postAddressSearch = `/applicationform/search`;
    const [getApplicationFormSearchResult] = useApi(
        postAddressSearch,
        "post",
        { uuid: props.uuid, companyId: company.companyId },
        false
    );
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getApplicationFormSearchResult,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;
    // console.log("valuesFromSource", valuesFromSource);
    const {
        initialValues,
        translations,
        readyInitialValues,
        messages
    } = useHandleInitialValues(
        valuesFromSource,
        languagesString,
        defaultLanguage,
        true
    );

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "translations",
            props: {
                translations: translations,
                messages: messages,
                languages: languages,
                defaultLanguage: defaultLanguage
            }
        }
    ];

    const finalReady = readyInitialValues && readyTranslation;

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: finalReady,
        i18nV: i18n,
        postMethod: editApplicationForm,
        postReturn: editApplicationFormReturn,
        redirectAddress: "/applicationform/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction,
        additionalFieldProperties: addProps
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation && readyInitialValues && readyLanguages) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default ApplicationFormEdit;
