import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import useApplicantViewContext from "../useApplicantViewContext";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Chip,
    Collapse,
    ListItemIcon,
    ListItemSecondaryAction,
    Paper
} from "@material-ui/core";
import _ from "lodash";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { transformDBData } from "functions/util";
import ModalViewEvent from "./ModalViewEvent";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import RecipientStatusTags from "../../../Email/Schedule/RecipientStatusTags";
import { People, Delete, Edit } from "@material-ui/icons";
import ModalParticipantsStatus from "../../../Email/Schedule/ModalParticipantsStatus";
import ModalEditEvent from "Pages/Email/Schedule/ModalEditEvent";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import CompleoAvatar from "Pages/_General/CompleoAvatar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);
export interface IModalEditEvent {
    open: boolean;
    scheduleId: string;
}
interface IModalViewScheduleState {
    open: boolean;
    eventData: Compleo.IObject;
}
export interface IModalParticipantsState {
    open: boolean;
    mainData: Compleo.IObject[];
    allRecipients: Compleo.IObject[];
}
interface IProps {
    t: any;
    invalidateQuery?: string[];
}
const AplicantScheduleThreadList = (props: IProps) => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const { t, invalidateQuery = [] } = props;
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    const [threadOpen, setThreadOpen] = React.useState<Compleo.IObject>({});
    const [modalParticipantsState, setModalParticipantsState] = React.useState<
        IModalParticipantsState
    >({ open: false, mainData: [], allRecipients: [] });

    const defaultModalViewEmailState: IModalViewScheduleState = {
        open: false,
        eventData: {}
    };
    const [modalViewEmailState, setModalViewEmailState] = React.useState<
        IModalViewScheduleState
    >(defaultModalViewEmailState);
    const handleModalViewEmailStateClose = () => {
        setModalViewEmailState(defaultModalViewEmailState);
    };
    const openModalViewEmail = (data: Compleo.IObject) => {
        setModalViewEmailState({ open: true, eventData: data });
    };

    const defaultModalEditEventState: IModalEditEvent = {
        open: false,
        scheduleId: ""
    };
    const [modalEditEventState, setModalEditEventState] = React.useState<
        IModalEditEvent
    >(defaultModalEditEventState);
    const handleModalEditEventClose = () => {
        setModalEditEventState(defaultModalEditEventState);
    };
    const openModalEditEvent = (scheduleId: string) => {
        setModalEditEventState({
            open: true,
            scheduleId
        });
    };

    const [data] = useApplicantViewContext();
    const { language } = data;
    const listAddress = `/email/listschedule`;
    const postData = {
        applicantId: data.applicantId,
        companyId: data.companyId
    };
    const [emailList, refreshEmailList] = useApiCache(
        listAddress,
        "post",
        postData,
        false
    );
    const dataEmail: Compleo.IObject[] = emailList.response?.data?.data || [];

    React.useEffect(() => {
        refreshEmailList(postData);
    }, [data.applicantId]);
    // console.log("dataEmail", dataEmail);

    const handleClick = (threadPK: string) => {
        const localThreadOpen = _.cloneDeep(threadOpen);
        localThreadOpen[threadPK] = !(localThreadOpen[threadPK] === true);
        setThreadOpen(localThreadOpen);
    };

    const handleCancelEvent = (scheduleId: string) => {
        callDialog({
            title: t("cancelEventTitle"),
            bodyText: t("cancelEventMessage"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const result = await apiDirectCall(
                    "/email/cancelscheduleevent",
                    "post",
                    {
                        companyId: data.companyId,
                        scheduleId: scheduleId
                    }
                );
                if (result.status === 200) {
                    for (const queryKeyData of invalidateQuery) {
                        queryClient.invalidateQueries(queryKeyData);
                    }
                    showMessage(t("CancelEvent_succesMessage"), "success");
                    return true;
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    if (dataEmail.length) {
        return (
            <Paper className={classes.paperContainer}>
                <List className={classes.root}>
                    {dataEmail.map((item) => {
                        const messagesCount = (item.MessagesThread || [])
                            .length;
                        const open = threadOpen[item.pk] === true;
                        const scheduleId = item.pk.split(":")[1];

                        return (
                            <React.Fragment key={item.pk}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <CompleoAvatar
                                            idParams={{
                                                email: item.createdByUser
                                            }}
                                            type="user"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                >
                                                    <strong>
                                                        {item.subject}
                                                    </strong>
                                                </Typography>
                                                {item.deleted === true && (
                                                    <Chip
                                                        size="small"
                                                        color="secondary"
                                                        label={t("canceled")}
                                                        style={{
                                                            marginLeft: 8
                                                        }}
                                                    />
                                                )}
                                            </>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {`${messagesCount} ${t(
                                                        "messages"
                                                    )}`}
                                                </Typography>
                                                {` - ${t("lastMessage")}: `}
                                                {transformDBData({
                                                    t: t,
                                                    language: language,
                                                    value: item.lastUpdateAt,
                                                    customDateFormat:
                                                        "dateRelative"
                                                })}
                                                <br />
                                                <strong>{`${t(
                                                    "dateTimeWhen"
                                                )}: `}</strong>
                                                {transformDBData({
                                                    t: t,
                                                    language: language,
                                                    value: item.startDateTime,
                                                    customDateFormat:
                                                        "dateWithTime"
                                                })}
                                                <strong>{` ${t("-")} `}</strong>
                                                {transformDBData({
                                                    t: t,
                                                    language: language,
                                                    value: item.endDateTime,
                                                    customDateFormat: "time"
                                                })}
                                                {item.location && (
                                                    <>
                                                        <br />
                                                        <strong>{`${t(
                                                            "where"
                                                        )}: `}</strong>
                                                        {transformDBData({
                                                            t: t,
                                                            language: language,
                                                            value:
                                                                item.location,
                                                            customDateFormat:
                                                                "dateWithTime"
                                                        })}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        {item.deleted !== true && (
                                            <>
                                                <ButtonIconTooltipCompleo
                                                    label={t(
                                                        "iconParticipants"
                                                    )}
                                                    onClick={() =>
                                                        setModalParticipantsState(
                                                            {
                                                                open: true,
                                                                mainData:
                                                                    item.RecipientsStatus ||
                                                                    [],
                                                                allRecipients:
                                                                    item.recipients ||
                                                                    []
                                                            }
                                                        )
                                                    }
                                                >
                                                    <People />
                                                </ButtonIconTooltipCompleo>
                                                {!data.readOnly && (
                                                    <ButtonIconTooltipCompleo
                                                        label={t("iconEdit")}
                                                        onClick={() =>
                                                            openModalEditEvent(
                                                                scheduleId
                                                            )
                                                        }
                                                    >
                                                        <Edit />
                                                    </ButtonIconTooltipCompleo>
                                                )}
                                                {!data.readOnly && (
                                                    <ButtonIconTooltipCompleo
                                                        label={t("iconDelete")}
                                                        onClick={() =>
                                                            handleCancelEvent(
                                                                scheduleId
                                                            )
                                                        }
                                                    >
                                                        <Delete color="secondary" />
                                                    </ButtonIconTooltipCompleo>
                                                )}
                                            </>
                                        )}
                                        <ButtonIconTooltipCompleo
                                            label={t("showMessagesExpand")}
                                            onClick={() => handleClick(item.pk)}
                                        >
                                            {open ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ButtonIconTooltipCompleo>
                                    </ListItemSecondaryAction>
                                    {/* <ListItemIcon>
                                        <ButtonIconTooltipCompleo
                                            label={t("showMessagesExpand")}
                                            onClick={() => handleClick(item.pk)}
                                        >
                                            {open ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ButtonIconTooltipCompleo>
                                    </ListItemIcon> */}
                                </ListItem>
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <AplicantEmailThreadListItem
                                        items={item.MessagesThread || []}
                                        t={t}
                                        language={language}
                                        openModalViewEmail={openModalViewEmail}
                                    />
                                </Collapse>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
                {modalViewEmailState.open && (
                    <ModalViewEvent
                        handleClose={handleModalViewEmailStateClose}
                        open={modalViewEmailState.open}
                        eventData={modalViewEmailState.eventData}
                        t={t}
                        language={language}
                    />
                )}
                {modalParticipantsState.open && (
                    <ModalParticipantsStatus
                        handleClose={() =>
                            setModalParticipantsState({
                                open: false,
                                mainData: [],
                                allRecipients: []
                            })
                        }
                        {...modalParticipantsState}
                        t={t}
                    />
                )}
                {modalEditEventState.open && (
                    <ModalEditEvent
                        handleClose={() => handleModalEditEventClose()}
                        {...modalEditEventState}
                        invalidateQuery={["/email/listschedule"]}
                        applicantPreferredLanguage={
                            data?.applicantData?.preferredLanguage
                        }
                    />
                )}
            </Paper>
        );
    } else if (emailList.status !== "success") {
        return <Loading />;
    } else {
        return null;
    }
};

interface IAplicantEmailThreadListItem {
    items: Compleo.IObject[];
    language: string;
    t: any;
    openModalViewEmail: (data: Compleo.IObject) => void;
}
const AplicantEmailThreadListItem = (props: IAplicantEmailThreadListItem) => {
    const { items, language, t, openModalViewEmail } = props;
    const classes = useStyles();
    const [data] = useApplicantViewContext();

    // const sortedItems =
    items.sort(function (a, b) {
        return a.createdAt < b.createdAt
            ? -1
            : a.createdAt > b.createdAt
            ? 1
            : 0;
    });
    const statusMeetingList: CompleoShared.Common.ScheduleMessageType[] = [
        "ACCEPTED",
        "DECLINED",
        "MAYBE",
        "INVITE",
        "INVITEUPDATE",
        "CANCELINVITE"
    ];

    return (
        <List component="div" disablePadding dense>
            {items.map((item) => {
                const hasAttachments = item.hasAttachments === true;
                const isFromApplicant =
                    data.applicantData?.email === item.userFrom?.email;

                return (
                    <React.Fragment key={item.skGS1pk}>
                        <ListItem
                            key={item.skGS1pk}
                            button
                            className={classes.nested}
                            onClick={() => openModalViewEmail(item)}
                        >
                            <ListItemAvatar>
                                {isFromApplicant ? (
                                    <CompleoAvatar
                                        idParams={{ id: data.applicantId }}
                                        type="applicant"
                                        className={classes.avatarsmall}
                                    />
                                ) : (
                                    <CompleoAvatar
                                        idParams={{
                                            email: item.userFrom?.email
                                        }}
                                        type="user"
                                        className={classes.avatarsmall}
                                    />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    item.userFrom?.name || item.userFrom?.email
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {statusMeetingList.includes(
                                                item.type
                                            ) ? (
                                                <RecipientStatusTags
                                                    t={t}
                                                    status={item.type}
                                                />
                                            ) : (
                                                item.startOfBody
                                            )}
                                        </Typography>
                                        {" - "}
                                        {transformDBData({
                                            t: t,
                                            language: language,
                                            value: item.createdAt,
                                            customDateFormat: "dateRelative"
                                        })}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default AplicantScheduleThreadList;
