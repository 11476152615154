import React, { ComponentType, Component, ReactNode, useState } from "react";
import AppBar from "Components/AppBar/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
// import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import HelperLoading from "Components/Loading/HelperLoading";
import { LanguageContext } from "_ReactContext/LanguageContext";
import { useGlobalDialogForm } from "_ReactContext/GlobalDialogContext";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import useQueryNavigation from "customHooks/useQuery/useQueryNavigation";
import theme from "../../theme/Theme";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export type SecondComponentType = {
    component: JSX.Element;
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f4f6f8"
        // backgroundColor: theme.palette.background.default
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    toolbar: theme.mixins.toolbar
}));

type RightButtonType = {
    enabled: boolean;
    text: string;
    action: any;
    startIcon?: any;
};

interface IBaseProps {
    children: ReactNode;
    className?: string;
    featureName?: string;
    featureMainDescription?: string;
    rightButton?: RightButtonType;
    RightButtonComponent?: JSX.Element;
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
    secondComponent?: {
        component: JSX.Element;
        size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
    };
}

const Page = (props: IBaseProps) => {
    const classes = useStyles();
    const { rightButton, RightButtonComponent, maxWidth = "md" } = props;
    const GlobalDialog = useGlobalDialogForm();
    const { company, user } = useAuthState();
    const queryNavigation = useQueryNavigation(user || "", company.companyId);
    // const theme = useTheme();
    // theme.da
    const isXsSize = useMediaQuery(theme.breakpoints.only("xs"));
    const smMainSize: any =
        props.secondComponent !== undefined
            ? 12 - props.secondComponent.size
            : 12;
    const smSecondSize: any = 12 - smMainSize;

    let loadingState = false;
    loadingState =
        queryNavigation.status !== "success" ||
        company.companyId === 0 ||
        user === null ||
        user === "" ||
        user === undefined;

    // ***********************************************************************
    // ***********************************************************************
    // Prepare the translation of all main components to control loading in
    // just one place
    // ***********************************************************************
    // ***********************************************************************
    const [
        tChangeLanguage,
        i18nChangeLanguage,
        readyTranslationChangeLanguage
    ] = useTranslation("CHANGELANGUAGE", {
        useSuspense: false
    });

    const [tAppBar, i18nAppBar, readyTranslationAppBar] = useTranslation(
        "APPBAR",
        {
            useSuspense: false
        }
    );

    const [
        tNavigation,
        i18nNavigation,
        readyTranslationNavigation
    ] = useTranslation("NAVIGATION", {
        useSuspense: false
    });

    const readyAllTranslations =
        readyTranslationAppBar &&
        readyTranslationChangeLanguage &&
        readyTranslationNavigation;
    // ***********************************************************************
    // ***********************************************************************
    // END TRANSLATION LOADING
    // ***********************************************************************
    // ***********************************************************************

    if (readyAllTranslations && !loadingState) {
        const language: string = i18nChangeLanguage.languages[0];

        return (
            <LanguageContext.Provider value={language}>
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            languageRendered={language}
                            tAppBar={tAppBar}
                            tChangeLanguage={tChangeLanguage}
                            // tNavigation={tNavigation}
                            i18n={i18nChangeLanguage}
                        />
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            <Container
                                className={classes.container}
                                maxWidth={maxWidth}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={8}>
                                        {props.featureName !== "" ? (
                                            <Typography
                                                variant="caption"
                                                component="h4"
                                            >
                                                {props.featureName}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                        {props.featureMainDescription !== "" ? (
                                            <Typography
                                                variant="h5"
                                                component="h3"
                                            >
                                                {props.featureMainDescription}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                    <RightButton
                                        RightButtonComponent={
                                            RightButtonComponent
                                        }
                                        rightButton={rightButton}
                                        isXsSize={isXsSize}
                                    />
                                    <Grid item xs={12} sm={smMainSize}>
                                        <div className={classes.root}>
                                            {props.children}
                                        </div>
                                    </Grid>
                                    {props.secondComponent !== undefined && (
                                        <Grid item xs={12} sm={smSecondSize}>
                                            <div className={classes.root}>
                                                {
                                                    props.secondComponent
                                                        .component
                                                }
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                                <GlobalDialog.FormDialog />
                            </Container>
                        </main>
                    </div>
                </ThemeProvider>
            </LanguageContext.Provider>
        );
    } else {
        return <HelperLoading />;
    }
};

interface IPropsRightButton {
    isXsSize: boolean;
    rightButton?: RightButtonType;
    RightButtonComponent?: JSX.Element;
}

const RightButton = (props: IPropsRightButton) => {
    const { rightButton, RightButtonComponent, isXsSize } = props;
    const InternalGrid = (props: any) => (
        <Grid
            container
            item
            xs={12}
            sm={4}
            alignItems={isXsSize ? "center" : "center"}
            justify={isXsSize ? "flex-start" : "flex-end"}
        >
            {props.children}
        </Grid>
    );

    if (rightButton) {
        return (
            <InternalGrid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={rightButton.action}
                    startIcon={rightButton.startIcon}
                >
                    {rightButton.text}
                </Button>
            </InternalGrid>
        );
    } else if (RightButtonComponent) {
        const Component = () => RightButtonComponent;
        return (
            <InternalGrid>
                <Component />
            </InternalGrid>
        );
    } else {
        return null;
    }
};

export default Page;
