import React from "react";
import Autocomplete, {
    AutocompleteProps,
    createFilterOptions
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { getControlOptionsAndChildrenFilterV2 } from "./util/AutoCompleteUtil";
import { getErrorAndMessages } from "./util/general";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import { ISelectProps } from "./ComboAutoComplete";
import { useWatch } from "react-hook-form";

interface IProps {
    validateOnEntryDefinition?: IValidateOnEntryDefinition;
}

export interface IValidateOnEntryDefinition {
    validateFunction: (value: string) => boolean;
    errorMessage: string;
}

const filter = createFilterOptions<{ label: string; inputValue?: string }>();

const TagsCreatable = (props: IProps & ISelectProps & IInputProps) => {
    const {
        name,
        freeSolo,
        multiple,
        label,
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        t,
        validateOnEntryDefinition,
        ...other
    } = props;
    const language = props.language || "pt-BR";
    const showMessage = useShowMessage();

    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);

    const freeSoloControl = freeSolo === undefined ? false : freeSolo;
    const multipleControl = multiple === undefined ? false : multiple;

    let defaultValue = null;
    if (multipleControl) {
        defaultValue = [];
    }

    const valueFromFormik = field.value;
    const controlValue =
        (valueFromFormik || "") === "" ? defaultValue : valueFromFormik;

    const fieldValue = useWatch({
        control,
        name: props?.parentFilterField?.parentFieldName
    });
    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilterV2(
        campoMetadata,
        options,
        customLists,
        name,
        fieldValue,
        props
    );
    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;

    const addNewValue = (lastItemArray: string, newArrayValue: any[]) => {
        if (validateOnEntryDefinition) {
            if (validateOnEntryDefinition.validateFunction(lastItemArray)) {
                if (Array.isArray(newArrayValue)) {
                    // TAGS CREATABLE - ARRAY VALUE
                    setFieldValue(newArrayValue);
                } else {
                    // SELECT CREATABLE - ONE VALUE
                    setFieldValue({
                        value: newArrayValue,
                        label: newArrayValue
                    });
                }
            } else {
                showMessage({
                    message: validateOnEntryDefinition.errorMessage,
                    typeMessage: "error",
                    snackBarOrign: {
                        horizontal: "center",
                        vertical: "top"
                    },
                    timeout: 2000
                });
            }
        } else {
            if (Array.isArray(newArrayValue)) {
                // TAGS CREATABLE - ARRAY VALUE
                setFieldValue(newArrayValue);
            } else {
                // SELECT CREATABLE - ONE VALUE
                setFieldValue({
                    value: newArrayValue,
                    label: newArrayValue
                });
            }
        }
    };

    const onChangeMethod = (event: any, newValue: any | null) => {
        if (newValue === null) {
            setFieldValue([]);
        } else {
            const lastItem = newValue[newValue.length - 1];
            if (typeof lastItem === "string") {
                addNewValue(lastItem, newValue);
            } else if (newValue && (lastItem || {}).inputValue) {
                addNewValue((lastItem || {}).inputValue, [
                    ...newValue.slice(0, -1),
                    lastItem.inputValue
                ]);
            } else if (newValue) {
                setFieldValue(newValue);
            } else {
                setFieldValue([]);
            }
        }
    };

    return (
        <Autocomplete
            freeSolo={freeSoloControl}
            multiple={multipleControl}
            id={field.name}
            value={controlValue}
            options={controlOptions}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                    return option;
                }

                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label || option[`label-${language}`] || "";
            }}
            onChange={onChangeMethod}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                    filtered.push({
                        inputValue: params.inputValue,
                        label: t("TAGSCREATABLE_add", {
                            value: params.inputValue
                        })
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) =>
                option.label || option[`label-${language}`] || ""
            }
            onBlur={field.onBlur}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    helperText={message}
                    inputRef={field.ref}
                    // margin="normal"
                />
            )}
        />
    );
};

export default TagsCreatable;
