import React, { useState, useEffect, memo } from "react";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import FontAwesome from "Components/FontAwesome";
import { Paper, Grid } from "@material-ui/core";
import { b64toBlob, getBase64DOCX } from "./helper";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

type margins = {
    top: string;
    bottom: string;
    right: string;
    left: string;
};
interface IProps {
    htmlToConvert: string;
    t: any;
    margins?: margins;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButtonClassName: {
            paddingLeft: "0px"
        }
    })
);

const Html2Docx = (props: IProps) => {
    const { htmlToConvert, t, margins } = props;
    const classes = useStyles();
    const [blob, setBlob] = useState<Blob | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [lastHtml, setLastHtml] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (htmlToConvert && htmlToConvert !== lastHtml) {
            setLoading(true);
            const base64HTML = btoa(
                unescape(encodeURIComponent(htmlToConvert))
            );

            getBase64DOCX(base64HTML, `customCV_${Date.now()}.html`).then(
                (data: any) => {
                    const base64Docx = data.base64data;
                    const blob = b64toBlob(
                        base64Docx,
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    );
                    setLastHtml(htmlToConvert);
                    setBlob(blob);
                    setLoading(false);
                }
            );
        }
    }, [htmlToConvert, margins, lastHtml]);

    if (blob && !loading) {
        const localURL = blob && blobUrl(blob);

        return (
            <Alert severity="warning">
                <AlertTitle>{t("CustomCVWordDownloadAlert_Title")}</AlertTitle>

                <div
                    dangerouslySetInnerHTML={createMarkup(
                        t("CustomCVWordDownloadAlert")
                    )}
                />
                <a href={localURL} target="_blank" rel="noreferrer">
                    <ButtonIconTooltipCompleo
                        label={t("CustomCV_WordDownload")}
                        iconButtonClassName={classes.iconButtonClassName}
                    >
                        <FontAwesome
                            faClassName="fas fa-file-word fa-lg"
                            color="primary"
                        />
                    </ButtonIconTooltipCompleo>
                    <span>{t("CustomCV_WordDownload")}</span>
                </a>
            </Alert>
        );
    }

    return <Loading size={24} />;
};

let urls = new WeakMap();

let blobUrl = (blob: any) => {
    if (urls.has(blob)) {
        return urls.get(blob);
    } else {
        let url = URL.createObjectURL(blob);
        urls.set(blob, url);
        return url;
    }
};

function createMarkup(value: string) {
    return { __html: value };
}

export default memo(Html2Docx);
