import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";

const useCustomLists: () => [
    Compleo.CustomLists.ListDefinitionType[],
    boolean
] = () => {
    const { company } = useAuthState();
    const companyId = company.companyId || "0";
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition] = React.useState<
        Compleo.CustomLists.ListDefinitionType[]
    >([]);

    const [customers] = useApiCache(
        "/contact/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    const [branchs] = useApiCache(
        "/branch/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );

    if (
        customers.status === "success" &&
        !listFilled(listDefinition, "filters_customers")
    ) {
        listDefinition.push({
            fieldName: "filters_customers",
            data: customers.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }

    if (readyLanguages && !listFilled(listDefinition, "languagesCareers")) {
        listDefinition.push({
            fieldName: "languagesCareers",
            data: languages,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }

    if (
        branchs.status === "success" &&
        !listFilled(listDefinition, "filters_branchs")
    ) {
        listDefinition.push({
            fieldName: "filters_branchs",
            data: branchs.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }

    if (!ready) {
        const readyModules = verifyRead([customers, branchs]);
        if (readyModules && readyLanguages) {
            setReady(true);
        }
    }
    return [listDefinition, ready];
};

const verifyRead = (api: ApiStateType[]) => {
    return api.filter((a: ApiStateType) => a.status !== "success").length === 0;
};

const listFilled = (
    listDefinition: Compleo.CustomLists.ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

export default useCustomLists;
