import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import React, { Component } from "react";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";

const usePlanLimits = () => {
    const { company } = useAuthState();

    const getLimit = async (action: CompleoShared.Common.limitType) => {
        const data = await apiDirectCall("/general/verifylimits", "post", {
            companyId: company?.companyId,
            action: action
        });
        return data?.data?.data || {};
    };

    return { getLimit: getLimit };
};

export const usePlanLimitsPage = (
    t: any,
    navigateTo: string,
    action: CompleoShared.Common.limitType,
    customNavigationFunction?: () => void
) => {
    const { getLimit } = usePlanLimits();
    const { callDialog } = useGlobalDialog();
    const [limitReached, setLimitReached] = React.useState(false);

    React.useEffect(() => {
        getLimit(action).then((dataLimit) => {
            if (!dataLimit.allowAction) {
                setLimitReached(true);
                callDialog({
                    title: t("PLANLIMIT_title"),
                    bodyText: t(getMessageByAction(action), {
                        maxValue: dataLimit.maxValue,
                        currentTotal: dataLimit.currentTotal
                    }),
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    agreeFunction: async () => {
                        customNavigationFunction ? customNavigationFunction() : navigate(navigateTo);
                    }
                });
                return undefined;
            }
        });
    }, []);

    return { limitReached };
};

const getMessageByAction = (action: CompleoShared.Common.limitType) => {
    switch (action) {
        case "addApplicant":
            return "PLANLIMIT_applicantMessage";
        case "addJob":
            return "PLANLIMIT_jobMessage";
        case "addUser":
            return "PLANLIMIT_userMessage";
        case "addCareersSite":
            return "PLANLIMIT_careersSiteMessage";
        case "addCareersDomain":
            return "PLANLIMIT_careersDomainMessage";
    }
};

export default usePlanLimits;
