import React, { useReducer } from "react";

const useHandleInitialIdsEmailData = (fieldValue: any) => {
    const ids = (fieldValue || []).map((item: any) => item.id);
    const [initialIds, setInitialIds] = React.useState(ids);
    const [reloadAfterUndefined, setReloadAfterUndefined] = React.useState(
        false
    );

    React.useEffect(() => {
        if (fieldValue === undefined) {
            setReloadAfterUndefined(true);
        }
    }, [fieldValue]);
    React.useEffect(() => {
        if (reloadAfterUndefined && Array.isArray(ids)) {
            setInitialIds(ids);
        }
    }, [reloadAfterUndefined]);

    return initialIds;
};

export default useHandleInitialIdsEmailData;
