import React from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { IFieldProps } from ".";
import { Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5)
        }
    })
);

const FieldNumberRange = (props: IFieldProps) => {
    const classes = useStyles();
    const { fieldData, fieldName, fieldDef, t, language } = props;

    const minValueFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    const maxValueFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };
    const typeFieldDef = { ...fieldDef, columnSize: { xs: 12, sm: 6 } };

    const value = _.get(fieldData, `${fieldName}.type.value`);
    const typeLabel =
        value === "monthly"
            ? { "label-en-US": "Monthly", "label-pt-BR": "Mensal" }
            : { "label-en-US": "Hourly", "label-pt-BR": "Por Hora" };
    _.set(fieldData, `${fieldName}.type`, {
        value: value,
        ...typeLabel
    });

    return (
        <Grid container className={classes.root}>
            <ItemView
                label={`${t(`${fieldName}MinLabel`)}: `}
                fieldDef={minValueFieldDef}
                removeColonAfterLabel={true}
                inline={true}
            >
                {transformDBData({
                    value: {
                        value: _.get(fieldData, `${fieldName}.minValue`),
                        currency: _.get(fieldData, `${fieldName}.currency`)
                    },
                    t,
                    language,
                    replaceOption: "-"
                })}
            </ItemView>
            <ItemView
                label={`${t(`${fieldName}MaxLabel`)}: `}
                fieldDef={maxValueFieldDef}
                removeColonAfterLabel={true}
                inline={true}
            >
                {transformDBData({
                    value: {
                        value: _.get(fieldData, `${fieldName}.maxValue`),
                        currency: _.get(fieldData, `${fieldName}.currency`)
                    },
                    t,
                    language,
                    replaceOption: "-"
                })}
            </ItemView>
            <ItemView
                label={`${t(`${fieldName}TypeTitleLabel`)}: `}
                fieldDef={typeFieldDef}
                removeColonAfterLabel={true}
                inline={true}
            >
                {transformDBData({
                    value: _.get(fieldData, `${fieldName}.type`),
                    t,
                    language,
                    replaceOption: "-"
                })}
            </ItemView>
        </Grid>
    );
};

export default FieldNumberRange;
