import React from "react";
import Table, { TableTypeMap } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import TableContainer, {
    TableContainerTypeMap
} from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import { TFunction } from "react-i18next";
import {
    CommonProps,
    OverridableComponent,
    OverridableTypeMap
} from "@material-ui/core/OverridableComponent";
import { Box, Button, Grid } from "@material-ui/core";
import { toCSV, toPDF } from "./helperCompleoMUITable";

export interface ICompleoMUITableRowsDef {
    name: string;
    propsHeader?: TableCellProps;
    propsItems?: TableCellProps;
    fieldName: string;
}

interface ICompleoMUITable {
    columns: ICompleoMUITableRowsDef[];
    data: Compleo.IObject[];
    t: TFunction;
    tableContainerProps?: OverridableComponent<
        TableContainerTypeMap<{}, "div">
    >;
    tableContainerStyle?: any;
    tableProps?: OverridableComponent<TableTypeMap<{}, "table">>;
    enableExportToPDF?: boolean;
    enableExportToCSV?: boolean;
    exportFileName?: string;
    reportPDFTitle?: string;
}

const CompleoMUITable = (props: ICompleoMUITable) => {
    const {
        columns,
        data,
        t,
        tableContainerProps,
        tableProps,
        tableContainerStyle,
        enableExportToCSV = false,
        enableExportToPDF = false,
        exportFileName = "data",
        reportPDFTitle = exportFileName
    } = props;

    const exportDataCSV = () => {
        const columnNames = columns.map((i) => i.fieldName);
        const columnLabel = columns.map((i) => i.name);
        toCSV(exportFileName, columnNames, data, columnLabel);
    };
    const exportDataPDF = () => {
        const columnNames = columns.map((i) => i.fieldName);
        const columnLabel = columns.map((i) => i.name);
        toPDF(reportPDFTitle, columnNames, data, columnLabel, exportFileName);
    };
    return (
        <Grid container spacing={0}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginBottom: 8 }}
            >
                {/* <Grid item xs={12}> */}
                {enableExportToCSV && (
                    <Button
                        onClick={exportDataCSV}
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        {t("COMPLEOGENERAL_exportToCSV")}
                    </Button>
                )}
                {enableExportToPDF && (
                    <Button
                        onClick={exportDataPDF}
                        style={{ marginLeft: 8 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        {t("COMPLEOGENERAL_exportToPDF")}
                    </Button>
                )}
                {/* </Grid> */}
            </Grid>
            <Grid item xs={12}>
                <TableContainer
                    {...tableContainerProps}
                    style={tableContainerStyle}
                >
                    <Table stickyHeader {...tableProps}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => {
                                    return (
                                        <TableCell
                                            key={column.name}
                                            {...(column.propsHeader || {})}
                                        >
                                            <strong>
                                                {t
                                                    ? t(column.name)
                                                    : column.name}
                                            </strong>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => {
                                return (
                                    <TableRow hover key={row.name}>
                                        {columns.map((column) => {
                                            return (
                                                <TableCell
                                                    key={column.name}
                                                    component="th"
                                                    scope="row"
                                                    {...(column.propsItems ||
                                                        {})}
                                                >
                                                    {
                                                        row[
                                                            column.fieldName ||
                                                                column.name
                                                        ]
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default CompleoMUITable;
