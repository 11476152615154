import React from "react";
import { FixedSizeList as ListReactWindow } from "react-window";
import FilterListItem from "./FilterListItem";

interface IPropsItemFilter {
    allBuckets: Compleo.IObject[];
    itemName: string;
}

const FilterListItemModal = React.memo((props: IPropsItemFilter) => {
    const { allBuckets, itemName } = props;

    const Row = ({ index, style }: any) => {
        const bucket = allBuckets[index];

        return (
            <div style={style}>
                <FilterListItem bucket={bucket} itemName={itemName} />
            </div>
        );
    };

    return (
        <ListReactWindow
            className="List"
            height={250}
            itemCount={allBuckets.length}
            itemSize={20}
            width="100%"
        >
            {Row}
        </ListReactWindow>
    );
});

export default FilterListItemModal;
