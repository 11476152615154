import * as React from "react";
import { ReactComponent as LogoImported } from "assets/img/compleo_horizontal_branco.svg";

interface IProps {
    className: string;
}

export const Logo = (props: IProps) => {
    return <LogoImported className={props.className} />;
};

export default Logo;
