import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from "@material-ui/core";
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { apiDirectCall } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        },
        captionFilters: {
            fontWeight: 600
        },
        captionNoDataFound: {
            color: theme.palette.secondary.main
        }
    })
);

interface IProps {
    filterData: Compleo.IObject[];
    handleClose: any;
}

const LoadFilterModalList = (props: IProps) => {
    const classes = useStyles();
    const [listData, listDispatch] = useList();
    const { t } = listData.tempInfo;
    const { filterData, handleClose } = props;

    const userFilters = filterData.filter(
        (item: Compleo.IObject) => item.isUserFilter === true
    );
    const companyFilters = filterData.filter(
        (item: Compleo.IObject) => item.isUserFilter === false
    );
    return (
        <>
            <Typography variant="caption" className={classes.captionFilters}>
                {t("ELASTICLISTSETTINGS:LoadFilters_YourFilters")}
            </Typography>
            <List component="nav" className={classes.root}>
                {userFilters.length > 0 ? (
                    userFilters
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => (
                            <React.Fragment key={item.name}>
                                <Item item={item} handleClose={handleClose} />
                            </React.Fragment>
                        ))
                ) : (
                    <Typography
                        variant="caption"
                        className={classes.captionNoDataFound}
                    >
                        {t("ELASTICLISTSETTINGS:LoadFilters_NoDataFound")}
                    </Typography>
                )}
            </List>
            <Typography variant="caption" className={classes.captionFilters}>
                {t("ELASTICLISTSETTINGS:LoadFilters_CompanyFilters")}
            </Typography>
            <List component="nav" className={classes.root}>
                {companyFilters.length > 0 ? (
                    companyFilters
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => (
                            <React.Fragment key={item.name}>
                                <Item item={item} handleClose={handleClose} />
                            </React.Fragment>
                        ))
                ) : (
                    <Typography
                        variant="caption"
                        className={classes.captionNoDataFound}
                    >
                        {t("ELASTICLISTSETTINGS:LoadFilters_NoDataFound")}
                    </Typography>
                )}
            </List>
        </>
    );
};
interface IItemProps {
    item: Compleo.IObject;
    handleClose: any;
}

const Item = (props: IItemProps) => {
    const queryClient = useQueryClient();
    const { item, handleClose } = props;
    const [listData, listDispatch] = useList();
    const { setFilters, setLocalInfo } = listDispatch;
    const { t, moduleName } = listData.tempInfo;
    const { company } = useAuthState();
    const id = item.skGS1pk.split(":")[1];
    const { callDialog } = useGlobalDialog();

    const deleteFilter = async (
        id: string,
        module: string,
        companyId: number,
        isUserFilter: boolean
    ) => {
        callDialog({
            title: t("ELASTICLISTSETTINGS:LoadFilters_DeleteFilterTitle"),
            bodyText: t("ELASTICLISTSETTINGS:LoadFilters_DeleteFilterMessage"),
            disagreeButtonText: t(
                "ELASTICLISTSETTINGS:LoadFilters_DeleteFilterNo"
            ),
            agreeButtonText: t(
                "ELASTICLISTSETTINGS:LoadFilters_DeleteFilterYes"
            ),
            agreeFunction: async () => {
                await apiDirectCall("/sessions/deletefilter", "post", {
                    companyId: companyId,
                    filterType: module,
                    filterID: id,
                    isUserFilter: isUserFilter
                });
                queryClient.invalidateQueries("listFilters");
            },
            disagreeFunction: async () => {
                return false;
            }
        });
    };

    const openFilter = (value: Compleo.IObject) => {
        const filters = _.cloneDeep(value?.filters || {});
        const localInfo = _.cloneDeep(value?.localInfo || {});
        localInfo.loadedMainSearchFromFilterSaved = filters.mainSearch || "";

        setFilters({
            ...listData.filtersInfo.filters,
            ...filters,
            filterUpdated: true
        });
        setLocalInfo({
            ...listData.localInfo,
            ...localInfo
        });
        handleClose();
    };

    return (
        <>
            <ListItem
                button
                dense
                alignItems="center"
                style={{
                    margin: 4
                }}
            >
                <ListItemText
                    onClick={() => openFilter(item.value)}
                    primary={item.name}
                />
                <ListItemIcon>
                    <ButtonIconTooltipCompleo
                        label={t("ELASTICLISTSETTINGS:LoadFilters_OpenFilter")}
                        onClick={() => openFilter(item.value)}
                    >
                        <Icon color="primary">check</Icon>
                    </ButtonIconTooltipCompleo>
                </ListItemIcon>
                <ListItemIcon>
                    <ButtonIconTooltipCompleo
                        label={t(
                            "ELASTICLISTSETTINGS:LoadFilters_DeleteFilter"
                        )}
                        onClick={() =>
                            deleteFilter(
                                id,
                                moduleName,
                                company.companyId,
                                item.isUserFilter
                            )
                        }
                    >
                        <Icon color="secondary">delete</Icon>
                    </ButtonIconTooltipCompleo>
                </ListItemIcon>
            </ListItem>
            <Divider />
        </>
    );
};

export default LoadFilterModalList;
