import React from "react";
import { TFunction } from "i18next";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import NumberFormat, { FormatInputValueFunction } from "react-number-format";
import { format, formatRelative } from "date-fns";
import { ptBR, enUS, fr, es } from "date-fns/locale";
import isNumber from "lodash/isNumber";
// import { formatMapCurrency } from "customHooks/useCompleoForm/Inputs/CurrencyField";

export const getFNSLocaleFromString = (language: string) => {
    switch (language) {
        case "pt-BR":
            return ptBR;
        case "en-US":
            return enUS;
        case "fr":
            return fr;
        case "es":
            return es;
        default:
            return ptBR;
    }
};

export type currencyLanguageTypes = "pt-BR" | "en-US" | "fr" | "es";

export type IFormatMapCurrency = {
    "pt-BR": {
        thousandSeparator: "." | ",";
        decimalSeparator: "." | ",";
    };
    "en-US": {
        thousandSeparator: "." | ",";
        decimalSeparator: "." | ",";
    };
    fr: {
        thousandSeparator: "." | ",";
        decimalSeparator: "." | ",";
    };
    es: {
        thousandSeparator: "." | ",";
        decimalSeparator: "." | ",";
    };
};
export const formatMapCurrency: IFormatMapCurrency = {
    "pt-BR": {
        thousandSeparator: ".",
        decimalSeparator: ","
    },
    "en-US": {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    fr: {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    es: {
        thousandSeparator: ",",
        decimalSeparator: "."
    }
};
export const currenciesList = [
    {
        value: "BRL",
        label: "R$"
    },
    {
        value: "EUR",
        label: "€"
    },
    {
        value: "USD",
        label: "$"
    }
];

export const formatMap: Compleo.IObject = {
    dayMonthYear: {
        "pt-BR": { format: "dd/MM/yyyy" },
        "en-US": { format: "MM/dd/yyyy" },
        "en-UK": { format: "MM/dd/yyyy" },
        fr: { format: "MM/dd/yyyy" },
        es: { format: "MM/dd/yyyy" }
    },
    monthYear: {
        "pt-BR": { format: "MM/yyyy" },
        "en-US": { format: "MM/yyyy" },
        "en-UK": { format: "MM/yyyy" },
        fr: { format: "MM/yyyy" },
        es: { format: "MM/yyyy" }
    },
    dayMonthYearTime: {
        "pt-BR": { format: "dd/MM/yyyy HH:mm", ampm: false },
        "en-US": { format: "MM/dd/yyyy hh:mm a", ampm: true },
        "en-UK": { format: "MM/dd/yyyy hh:mm a", ampm: true },
        fr: { format: "MM/dd/yyyy hh:mm a", ampm: true },
        es: { format: "MM/dd/yyyy hh:mm a", ampm: true }
    },
    time: {
        "pt-BR": { format: "HH:mm", ampm: false },
        "en-US": { format: "hh:mm a", ampm: true },
        "en-UK": { format: "hh:mm a", ampm: true },
        fr: { format: "hh:mm a", ampm: true },
        es: { format: "hh:mm a", ampm: true }
    }
};

export const getFormat: (
    language: Compleo.RegionalSettings.languages,
    type: Compleo.RegionalSettings.formatType
) => { format: string; ampm?: boolean } = (
    language: Compleo.RegionalSettings.languages,
    type: Compleo.RegionalSettings.formatType
) => {
    const returnData =
        formatMap?.[type]?.[language || "pt-BR"] ||
        formatMap?.[type]?.["pt-BR"];

    return returnData;
};

export const formatDate = (
    value: string,
    language: Compleo.RegionalSettings.languages,
    type: Compleo.RegionalSettings.formatType
) => {
    if (type !== "relative") {
        const formatString = getFormat(language, type);
        return format(new Date(value), formatString.format);
    } else {
        const locale = getFNSLocaleFromString(language);
        const dataReturn = formatRelative(new Date(value), new Date(), {
            locale: locale
        });
        return dataReturn;
    }
};

export const maskPhone = (value: string) => {
    const phoneNumberParsed = parsePhoneNumberFromString(value || "");

    if (phoneNumberParsed !== undefined) {
        const countryCallingCode = phoneNumberParsed.countryCallingCode;
        const phoneNumber = new AsYouType(phoneNumberParsed.country).input(
            phoneNumberParsed.nationalNumber.toString()
        );
        let finalPhoneNumber = `+${countryCallingCode} ${phoneNumber}`;

        if (finalPhoneNumber.slice(-1) === ")") {
            finalPhoneNumber = finalPhoneNumber
                .replace("(", "")
                .replace(")", "");
        }

        return finalPhoneNumber;
    } else {
        return value;
    }
};

export const maskCPF = (value: string) => {
    const newValue = (
        <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            format="###.###.###-##"
        />
    );
    return newValue;
};

export const maskCNPJ = (value: string) => {
    const newValue = (
        <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            format="##.###.###/####-##"
        />
    );
    return newValue;
};

export const maskCurrencyToString = (
    value: string,
    language = "pt-BR",
    currencyType: string,
    decimalScale: number = 2
) => {
    if (isNumber(value)) {
        return new Intl.NumberFormat(language, {
            style: "currency",
            currency: currencyType
        }).format(Number(value));
    }

    const currencyPreffix = currenciesList.filter(
        (c) => c.value === currencyType
    )[0];
    const prefix = `${currencyPreffix?.label || "$"} `;
    return `${prefix} ${value}`;
};

export const maskCurrency = (
    value: string,
    language: currencyLanguageTypes = "pt-BR",
    currencyType?: string,
    decimalScale: number = 2
) => {
    // const a = formatMapCurrency;
    const currencyPreffix = currenciesList.filter(
        (c) => c.value === currencyType
    )[0];

    const newValue = (
        <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={
                formatMapCurrency?.[language]?.thousandSeparator ||
                formatMapCurrency?.["pt-BR"]?.thousandSeparator
            }
            decimalSeparator={
                formatMapCurrency?.[language]?.decimalSeparator ||
                formatMapCurrency?.["pt-BR"]?.decimalSeparator
            }
            prefix={`${currencyPreffix?.label || "$"} `}
            fixedDecimalScale={true}
            decimalScale={decimalScale}
        />
    );
    return newValue;
};

export const maskNumber = (
    value: string | number,
    language: currencyLanguageTypes = "pt-BR",
    decimalScale: number = 2
) => {
    const newValue = (
        <NumberFormat
            value={value}
            displayType={"text"}
            decimalScale={decimalScale}
            thousandSeparator={
                formatMapCurrency[language].thousandSeparator ||
                formatMapCurrency?.["pt-BR"]?.thousandSeparator
            }
            decimalSeparator={
                formatMapCurrency[language].decimalSeparator ||
                formatMapCurrency?.["pt-BR"]?.decimalSeparator
            }
        />
    );
    // console.log("newValue", newValue);
    return newValue;
};
