import React from "react";
import Page from "../_Layouts/DefaultInternal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReactJson from "react-json-view";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { apiDirectCall } from "customHooks/useApi/api";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        paper: {
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(6),
            width: "100%",
            marginTop: theme.spacing(4)
        },
        formcontainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%"
        },
        button: {
            margin: theme.spacing(2)
        },
        inputhidden: {
            display: "none"
        },
        paperDropzone: {
            padding: theme.spacing(3),
            // background: colorText,
            border: "1px dashed"
        }
    })
);

const TestParser = () => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false
    });
    const { ref, ...rootProps } = getRootProps();

    const inputFile = React.useRef<any>(null);
    const classes = useStyles();
    const [parsedJson, setParsedJson] = React.useState<Compleo.IObject>({});
    const [
        errorExecution,
        setErrorExecution
    ] = React.useState<null | Compleo.IObject>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [time3Steps, setTime3Steps] = React.useState("");
    const [timeDirect, setTimeDirect] = React.useState("");
    const postAddress = `/parser/parserdirect`;

    const files = acceptedFiles.map((file: any) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map((e: any) => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    resolve(reader.result.split(",")[1]);
                } else {
                    reject({ message: "Error converting to base64 string" });
                }
            };
            reader.onerror = (error) => reject(error);
        });

    const handleSubmit = async (event: any) => {
        const t0 = performance.now();
        event.preventDefault();
        try {
            setIsLoading(true);
            // if (inputFile.current !== null) {
            //     console.log(
            //         `Selected file - ${inputFile.current.files[0].name}`
            //     );
            //     console.log(inputFile.current.files);
            // }

            const file = acceptedFiles[0];
            console.log("file", file);
            const re = /(?:\.([^.]+))?$/;
            const extension = (re.exec(file.name) || [])[1];

            const axPost = await axios.post(
                "https://jcfyazbhyb.execute-api.us-east-1.amazonaws.com/prod/parser/requests3url",
                {
                    extension: extension,
                    originalFileName: file.name
                }
            );
            console.log("axPost", axPost);
            const signedUrl = axPost.data.uploadURL;
            const filename = axPost.data.fileName;
            var options = {
                headers: {
                    "Content-Type": file.type,
                    "x-amz-tagging": `originalFileName=${file.name}`
                }
            };

            const axiosReturn = await axios.put(signedUrl, file, options);
            console.log("axiosReturn", axiosReturn);

            const startMiliSecondsToWait = 3000;
            let secondsToWait = startMiliSecondsToWait;
            let parserResult: null | Compleo.IObject = null;
            let lastErrorParser: null | Compleo.IObject = null;

            for (let index = 0; index < 5; index++) {
                await sleep(secondsToWait);

                try {
                    const result = await axios.post(
                        "https://jcfyazbhyb.execute-api.us-east-1.amazonaws.com/prod/parser/parserresult",
                        {
                            fileName: filename
                        }
                    );
                    console.log("result", result);
                    parserResult = result.data.parsed;
                } catch (e) {
                    console.log("error", 4);
                    secondsToWait = Math.round(startMiliSecondsToWait / 2);
                    lastErrorParser = e;
                }

                if (parserResult !== null) {
                    setParsedJson(parserResult);
                    break;
                }
            }
            if (parserResult === null) {
                if (lastErrorParser !== null) {
                    throw lastErrorParser;
                } else {
                    throw { message: "Unexpected error on parser" };
                }
            }
            setErrorExecution(null);
            setIsLoading(false);
        } catch (ex) {
            setErrorExecution(ex);
            setIsLoading(false);
        }
        const t1 = performance.now();
        setTime3Steps("Time - 3 steps: " + (t1 - t0) + " milliseconds.");
    };

    const directParser = async (event: any) => {
        const t0 = performance.now();

        event.preventDefault();
        try {
            setIsLoading(true);
            if (inputFile.current !== null) {
                console.log(
                    `Selected file - ${inputFile.current.files[0].name}`
                );
                console.log(inputFile.current.files);
            }

            const file = inputFile.current.files[0];
            const base64 = await toBase64(file);
            const re = /(?:\.([^.]+))?$/;
            const extension = (re.exec(file.name) || [])[1];

            var options = {
                headers: {
                    "Content-Type": file.type,
                    "x-amz-tagging": `originalFileName=${file.name}`
                }
            };

            const axiosReturn = await apiDirectCall(
                "/parser/parserdirect",
                "post",
                {
                    base64File: base64,
                    fileName: file.name
                }
            );
            console.log("axiosReturn", axiosReturn);

            let parserResult: null | Compleo.IObject = null;
            parserResult = axiosReturn.data.data;
            if (parserResult !== null) {
                setParsedJson(parserResult);
            }

            setErrorExecution(null);
            setIsLoading(false);
        } catch (ex) {
            setErrorExecution(ex);
            setIsLoading(false);
        }
        const t1 = performance.now();
        setTimeDirect("Time - direct: " + (t1 - t0) + " milliseconds.");
    };

    return (
        <Page>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" component="h3">
                            Parser (Test)
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <form onSubmit={handleSubmit}>
                            <Grid item xs={12}>
                                <label>
                                    Escolha um currículo para o parser (pdf ou
                                    docx):
                                </label>
                            </Grid>
                            <Grid item xs>
                                <RootRef rootRef={ref}>
                                    <h4>Documents</h4>
                                    <Paper
                                        {...rootProps}
                                        className={classes.paperDropzone}
                                    >
                                        <input {...getInputProps()} />
                                        <p>
                                            Drag 'n' drop some files here, or
                                            click to select files
                                        </p>
                                    </Paper>
                                    <Paper>
                                        <h4>Files</h4>
                                        <ul>{files}</ul>
                                        <h4>Rejected Files</h4>
                                        <ul>{fileRejectionItems}</ul>
                                    </Paper>
                                </RootRef>
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="document/*"
                                    className={classes.inputhidden}
                                    type="file"
                                    ref={inputFile}
                                    id="cv"
                                    name="cv"
                                />
                                <label htmlFor="cv">
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        disabled={isLoading}
                                    >
                                        Upload
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                {(
                                    (((inputFile || {}).current || {}).files ||
                                        [])[0] || {}
                                ).name || ""}
                            </Grid>
                            <Grid item xs={12}>
                                {time3Steps} <br />
                                {timeDirect}
                            </Grid>
                            <Button
                                className={classes.button}
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={
                                    isLoading || inputFile.current === null
                                }
                            >
                                3 steps Parser
                            </Button>
                            <Button
                                className={classes.button}
                                onClick={directParser}
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={
                                    isLoading || inputFile.current === null
                                }
                            >
                                Direct Parser
                            </Button>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Paper className={classes.paper}>
                            {errorExecution === null ? (
                                <ReactJson
                                    src={parsedJson}
                                    displayDataTypes={false}
                                    theme="bright:inverted"
                                />
                            ) : (
                                <>
                                    <Typography color="secondary">
                                        Error (see details below):
                                    </Typography>
                                    <ReactJson
                                        src={JSON.parse(
                                            JSON.stringify(errorExecution)
                                        )}
                                        displayDataTypes={false}
                                        theme="bright"
                                    />
                                </>
                                // <>{JSON.stringify(errorExecution)}</>
                            )}
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </Page>
    );
};

export default TestParser;
