import React from "react";
import Button from "@material-ui/core/Button";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useCompleoReactHookFormNew, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata, {
    useGetMetadataViewEdit
} from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useQueryClient } from "react-query";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import {
    IUseCompleoViewEditDataParams,
    UseCompleoEditCustomComponents
} from "customHooks/useCompleoView";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { useCancelModalEdit } from "./useCancelModalEdit";

interface IModalEdit {
    open: boolean;
    onClose: (value?: string) => void;
    formId: string;
    data: Compleo.IObject;
    editParams: IUseCompleoViewEditDataParams;
    CustomEditComponents?: UseCompleoEditCustomComponents[];
}

const ModalEdit = (props: IModalEdit) => {
    const {
        onClose,
        open,
        formId,
        data,
        editParams,
        CustomEditComponents = []
    } = props;
    const {
        moduleEditName,
        postFunction,
        postReturn,
        keyData,
        useQueryKeyInvalidate = [],
        customLists
    } = editParams;
    const cancelFunction = useCancelModalEdit(onClose);

    const { listDefinition, ready: readyLists = true } = customLists || {};
    const queryClient = useQueryClient();
    const [updatingData, setUpdatingData] = React.useState(false);

    const [metadata] = useGetMetadataViewEdit(moduleEditName, formId);
    const [tLocal, i18n, readyTranslation] = useTranslation([moduleEditName], {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, false);
    for (const keyName of Object.keys(initialValues)) {
        if (data[keyName]) {
            initialValues[keyName] = data[keyName];
        }
    }
    for (const keyName of Object.keys(keyData)) {
        initialValues[keyName] = keyData[keyName];
    }

    const localPost = async (values: any) => {
        setUpdatingData(true);
        const returnData = await postFunction(values);
        if (returnData.status === 200) {
            if (useQueryKeyInvalidate.length) {
                for (const keyname of useQueryKeyInvalidate) {
                    queryClient.invalidateQueries(keyname);
                }
            }
            onClose();
        }
        setUpdatingData(false);
        return returnData;
    };

    const ready =
        readyTranslation && metadata.status === "success" && readyLists;

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: tLocal,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { formState, getValues } = reactHookFormMethods.reactHookFormMethods;

    const [formCompleo, finished, submitForm] = useCompleoReactHookFormNew({
        t: tLocal,
        ready: ready,
        i18nV: i18n,
        postMethod: localPost,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: listDefinition,
        CustomComponents: CustomEditComponents,
        reactHookFormMethods: reactHookFormMethods
    });

    const handleOk = () => {
        const modifiedFields = Object.keys(formState.touchedFields || {})
            .length;
        if (modifiedFields > 0) {
            cancelFunction();
        } else {
            onClose();
        }
    };
    const handleSave = async () => {
        submitForm();
    };

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && ready) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={tLocal("ModalEdit_Title")}
            open={open}
        >
            <DialogTitle>{tLocal("ModalEdit_Title")}</DialogTitle>
            <DialogContent dividers>{compRetorno}</DialogContent>
            <DialogActions>
                <Button
                    onClick={handleOk}
                    color="primary"
                    disabled={updatingData}
                >
                    {tLocal("ModalEdit_ButtonClose")}
                </Button>
                <Button
                    disabled={updatingData}
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                >
                    {tLocal("ModalEdit_ButtonSave")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalEdit;
