import React from "react";
import ItemCard from "./ItemCard";
import Chip from "@material-ui/core/Chip";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import useJobContext from "Pages/Job/JobView/useJobContext";
import EmbedLink from "./EmbedLink";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        chipTable: {
            margin: theme.spacing(0.5)
        }
    })
);

const FieldJobScreeningQuestionnaire = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType, url } = props;
    const classes = useStyles();
    const [listData] = useList();
    const { t, language } = listData.tempInfo;
    const { cardFields } = listData.definitionInfo.elasticDefData?.data || {};
    const defFieldInfo = cardFields[fieldName];
    const [data] = useJobContext();

    // const questionnaireValue = ApplicantData["Questionnaires"].filter(
    //     (item: Compleo.IObject) =>
    //         item.jobId === data.JobId &&
    //         item.skGS1pk.includes(
    //             "QUESTIONNAIREID:SCREENING#QUESTIONID:GLOBAL#"
    //         )
    // )[0];

    const itemValue = (fieldData["Questionnaires"] || []).filter(
        (item: Compleo.IObject) =>
            item.jobId === data.JobId &&
            item.skGS1pk.includes(
                "QUESTIONNAIREID:SCREENING#QUESTIONID:GLOBAL#"
            )
    )[0]?.ranking;
    const value = transformDBData({
        value: itemValue,
        t,
        language,
        replaceOption: "-",
        numberDecimalScale: 1
    });

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    label={
                        cardFields[fieldName].cardIcon ? (
                            <Icon color="action" fontSize="small">
                                {cardFields[fieldName].cardIcon}
                            </Icon>
                        ) : (
                            `${t(fieldName)}: `
                        )
                    }
                    removeColonAfterLabel={true}
                >
                    <EmbedLink url={url}>{value} %</EmbedLink>
                </ItemCard>
            );

        case "table":
            return <EmbedLink url={url}>{value} %</EmbedLink>;
    }
};

export default FieldJobScreeningQuestionnaire;
