import React from "react";
import randomColor from "randomcolor";

interface IUseRandomColor {
    total?: number;
    hue?: string | number;
    objectKeys?: string[];
    luminosity?: "bright" | "light" | "dark" | "random";
    format?:
        | "hsvArray"
        | "hslArray"
        | "hsl"
        | "hsla"
        | "rgbArray"
        | "rgb"
        | "rgba"
        | "hex";
    keyToRenderAgain?: string;
}

export interface IColorsObject {
    [x: string]: string;
}

const useRandomColor = (params: IUseRandomColor) => {
    const {
        total = 0,
        hue,
        objectKeys = [],
        luminosity,
        format,
        keyToRenderAgain
    } = params;
    const [colors, setColors] = React.useState<string[]>([]);
    const [colorsObject, setColorsObject] = React.useState<IColorsObject>({});

    React.useEffect(() => {
        if (total > 0) {
            const returnData = randomColor({
                count: total,
                hue: hue,
                luminosity,
                format
            });
            setColors(returnData);
        }
    }, [total, hue, luminosity, format]);

    React.useEffect(() => {
        if (objectKeys.length) {
            const colorArray = randomColor({
                count: objectKeys.length,
                hue: hue,
                luminosity,
                format
            });
            const objReturnData: IColorsObject = {};
            for (let index = 0; index < objectKeys.length; index++) {
                objReturnData[objectKeys[index]] = colorArray[index];
            }
            setColorsObject(objReturnData);
        }
    }, [objectKeys.length, hue, luminosity, format, keyToRenderAgain]);

    return { colors, colorsObject };
};

export default useRandomColor;
