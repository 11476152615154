import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
    Chip,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    Switch,
    Typography
} from "@material-ui/core";
import {
    IJobViewStages,
    IStagesOnlyTotal
} from "Pages/Job/JobView/useJobContext/JobViewTypes";
import {
    getGroupFromStage,
    pipelineGroupType
} from "Pages/Pipeline/components/helperPipeline";
import { getColorFromPipelineGroupType } from "Pages/Job/JobView/JobKanban/JobKanbanColumn";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getTotalStage } from "Pages/Job/JobView/JobKanban/helper";
import { useQueryClient } from "react-query";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import ChangeStageButtonDisqualifyReasonModal from "./ChangeStageButtonDisqualifyReasonModal";
import useGlobalValues from "_ReactContext/GlobalValues";
import { Forward } from "@material-ui/icons";
import TooltipCompleo from "Components/Tooltip";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

interface IOptions {
    id: string;
    label: string;
    isGroup: boolean;
    icon?: string;
    groupColor?: string;
    groupType?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        },
        chipNoMargin: {
            margin: 0,
            borderRadius: 6
        }
    })
);

const useGetOptions = (stages: IJobViewStages[], language: string) => {
    const { company } = useAuthState();
    const [options, setOptions] = React.useState<IOptions[]>([]);
    const [getPipelinePatterns] = useApiCache(
        "/pipeline/getpipelinepatterns",
        "post",
        { companyId: company.companyId },
        false,
        1000 * 60 * 10
    );
    const data = getPipelinePatterns.response.data || {};
    // const typesOfStage = data?.typesOfStage?.items || []
    const listTypesOfStage = data?.typesOfStage?.items || [];

    const optionsReturn: IOptions[] = [];
    let groupTypeGlobal: pipelineGroupType | null = null;
    if (listTypesOfStage.length) {
        for (const stage of stages) {
            const groupType = getGroupFromStage(stage);
            const colorGroup = getColorFromPipelineGroupType(groupType);
            const typeOfStage = listTypesOfStage.filter(
                (item: any) => item.value === stage.type
            )[0];
            // debugger;

            if (groupType !== groupTypeGlobal) {
                const label = (typeOfStage || {})[`label-${language}`];
                optionsReturn.push({
                    id: groupType || "",
                    isGroup: true,
                    label: label,
                    groupColor: colorGroup.mainColor,
                    groupType: groupType || "",
                    icon: typeOfStage?.icon
                });
                groupTypeGlobal = groupType;
            }

            optionsReturn.push({
                id: stage.id,
                isGroup: false,
                label: stage.name,
                groupColor: colorGroup.mainColor,
                groupType: groupType || "",
                icon: typeOfStage?.icon
            });
        }
    }
    React.useEffect(() => {
        if (optionsReturn.length && !options.length) {
            setOptions(optionsReturn);
        }
    }, [optionsReturn]);
    return { options };
};

export type ChangeStageButtonDisqualifyReasonModalFnToRunType = (
    disqualifyReason?: Compleo.IObject | undefined
) => Promise<void>;
interface IModalDisqualifyReasonParams {
    open: boolean;
    fnToRun?: ChangeStageButtonDisqualifyReasonModalFnToRunType;
}
const globalValueMoveToBeginOfList = "changeStageMoveBeginList";

interface IChangeStageButton {
    currentStage: IJobViewStages;
    stages: IJobViewStages[];
    applicantData: Compleo.IObject;
    jobData: Compleo.IObject;
    language: string;
    listKeyToInvalidate?: string[];
    fnAfterUpdate?: any;
    stagesOnlyTotal?: IStagesOnlyTotal[];
    currentIndex: number;
    outsideKanban?: boolean;
    showAsTag?: boolean;
    fontSize?: number;
    t: any;
    readonly: boolean;
    buttonBorder?: boolean;
}
const ChangeStageButton = (props: IChangeStageButton) => {
    const {
        stages,
        currentStage,
        applicantData,
        jobData,
        language,
        listKeyToInvalidate = [],
        fnAfterUpdate,
        stagesOnlyTotal = [],
        currentIndex,
        outsideKanban = false,
        showAsTag = false,
        fontSize,
        t,
        readonly,
        buttonBorder = false
    } = props;
    const classes = useStyles();
    const { company } = useAuthState();
    const [globalValues, dispatchGlobalValues] = useGlobalValues();
    const [isBeginList, setIsBeginList] = React.useState(
        globalValues.userdb[globalValueMoveToBeginOfList]?.beginList === true
    );

    const changeMoveListValue = () => {
        const newValue = !isBeginList;
        setIsBeginList(newValue);
        dispatchGlobalValues.addOrEditGlobalValue({
            name: globalValueMoveToBeginOfList,
            type: "userdb",
            value: { beginList: newValue }
        });
    };
    // const [
    //     modalReasonDisqualifyOpen,
    //     setModalReasonDisqualifyOpen
    // ] = React.useState(false);
    const queryClient = useQueryClient();
    const showMessageFullControl = useShowMessageFullControl();
    const [modalDisqualifyParams, setModalDisqualifyParams] = React.useState<
        IModalDisqualifyReasonParams
    >({ open: false });

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    // const [selectedIndex, setSelectedIndex] = React.useState(1);

    const localOptions = useGetOptions(stages, language);
    // console.log("localOptions", localOptions);

    const saveValues = async (newStage: IOptions) => {
        const fnToRun = async (disqualifyReason?: Compleo.IObject) => {
            showMessageFullControl.openMessage({
                message: t("COMPLEOGENERAL_MessageUpdating"),
                variant: "info",
                showLinearProgress: true
            });
            const applicantId = applicantData.pk.split(":")[1];
            const addToEndOfList = !isBeginList;
            // setSelectedIndex(index);
            const dataPost: Compleo.IObject = {
                companyId: company.companyId,
                jobId: jobData.JobId,
                applicantId: applicantId,
                stageId: newStage.id,
                addToEndOfList: addToEndOfList,
                oldStageId: currentStage?.id,
                priorityObj: { orderData: [] },
                newIndex: 0
            };
            if (disqualifyReason) {
                dataPost.disqualifyReason = disqualifyReason;
                dataPost.disqualifyApplicants = true;
            }
            // debugger;
            const postAddress = `/job/editapplicantjobstage`;
            const dataAPI = await apiDirectCall(postAddress, "post", dataPost);

            if (dataAPI.status === 200) {
                if (fnAfterUpdate) {
                    const destinationIndex = addToEndOfList
                        ? getTotalStage(stagesOnlyTotal, [newStage.id])
                        : 0;
                    await fnAfterUpdate({
                        outsideKanban,
                        destinationIndex,
                        applicantId: applicantData.pk,
                        stageData: currentStage,
                        currentIndex,
                        newStageId: newStage.id
                    });
                }

                if (listKeyToInvalidate.length) {
                    for (const keyName of listKeyToInvalidate) {
                        queryClient.invalidateQueries(keyName);
                    }
                }
            }
            // showMessageFullControl.closeMessage();
            showMessageFullControl.openMessage({
                message: t("COMPLEOGENERAL_MessageUpdated"),
                autoHideDuration: 2000,
                variant: "success"
            });
        };
        return fnToRun;
    };

    const handleMenuItemClick = async (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        setOpen(false);
        const newStage = localOptions?.options[index];
        const fnToExecute = await saveValues(newStage);
        if (newStage.groupType === "rejected") {
            setModalDisqualifyParams({ open: true, fnToRun: fnToExecute });
        } else {
            await fnToExecute();
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const seletectedOption = localOptions.options.filter(
        (item) => item.id === currentStage?.id
    )[0];

    if (!seletectedOption) {
        return null;
    }
    if (!readonly) {
        return (
            <>
                <Button
                    variant={
                        buttonBorder
                            ? "outlined"
                            : showAsTag
                            ? "contained"
                            : "text"
                    }
                    // startIcon={<Icon>{seletectedOption?.icon}</Icon>}
                    startIcon={!showAsTag && <Forward />}
                    color="inherit"
                    size="small"
                    onClick={handleToggle}
                    ref={anchorRef}
                    endIcon={<ArrowDropDownIcon />}
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    style={{
                        backgroundColor: showAsTag
                            ? seletectedOption?.groupColor
                            : undefined,
                        color: showAsTag ? "white" : undefined,
                        textTransform: "none"
                    }}
                >
                    <Typography
                        noWrap
                        style={{ margin: 0, padding: 0, fontSize: fontSize }}
                    >
                        {seletectedOption?.label}
                    </Typography>
                </Button>
                <Menu
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: 370
                        }
                    }}
                >
                    {localOptions.options.map((option, index) => (
                        <RenderMenuItem
                            key={option.id}
                            handleMenuItemClick={handleMenuItemClick}
                            index={index}
                            option={option}
                            seletectedOption={seletectedOption}
                        />
                    ))}
                    <Divider />
                    <List
                        subheader={
                            <ListSubheader>
                                {t(
                                    "CHANGESTAGEBUTTON_moveToBeginOfListSettins"
                                )}
                            </ListSubheader>
                        }
                    >
                        <ListItem>
                            <ListItemIcon>
                                <TooltipCompleo
                                    title={t("COMPLEOGENERAL_HELP")}
                                    detail={t(
                                        "CHANGESTAGEBUTTON_moveToBeginOfListHelp"
                                    )}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id="changeEndOfList"
                                primary={t(
                                    "CHANGESTAGEBUTTON_moveToBeginOfList"
                                )}
                            />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={changeMoveListValue}
                                    checked={isBeginList}
                                    inputProps={{
                                        "aria-labelledby": "changeEndOfList"
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Menu>
                {showMessageFullControl.MessageElement}
                <ChangeStageButtonDisqualifyReasonModal
                    open={modalDisqualifyParams.open}
                    onClose={() => setModalDisqualifyParams({ open: false })}
                    fnToRun={modalDisqualifyParams.fnToRun}
                />
            </>
        );
    } else
        return (
            <Chip
                className={showAsTag ? classes.chipCard : classes.chipNoMargin}
                style={{
                    backgroundColor: showAsTag
                        ? seletectedOption?.groupColor
                        : "transparent",
                    color: "white",
                    fontSize: !showAsTag ? 16 : undefined
                }}
                size={showAsTag ? "small" : "medium"}
                label={seletectedOption?.label}
            />
        );
};

interface IRenderMenuItem {
    handleMenuItemClick: (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => void;
    index: number;
    seletectedOption: IOptions;
    option: IOptions;
}

const RenderMenuItem = (props: IRenderMenuItem) => {
    const { handleMenuItemClick, index, seletectedOption, option } = props;
    const currentOption = option;
    const isSelected = option.id === seletectedOption.id;
    const enableButton =
        currentOption.isGroup === true || isSelected ? undefined : true;
    const onClickFN =
        currentOption.isGroup === true || isSelected
            ? undefined
            : (event: any) => handleMenuItemClick(event, index);

    return (
        <>
            <MenuItem
                selected={isSelected}
                onClick={onClickFN}
                button={enableButton}
            >
                {!currentOption.isGroup && (
                    <ListItemIcon>
                        <Icon
                            fontSize="small"
                            style={{ color: currentOption.groupColor }}
                        >
                            {currentOption.icon}
                        </Icon>
                    </ListItemIcon>
                )}
                <ListItemText primary={currentOption.label} />
            </MenuItem>
            {currentOption.isGroup && <Divider />}
        </>
    );
};

export default ChangeStageButton;
