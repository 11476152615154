import React from "react";
import Page from "../_Layouts/InternalFullPage";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Box, Typography } from "@material-ui/core";
import DashboardMenu from "./Helpers/DashboardMenu";
import DashboardTabs from "./Helpers/DashboardTabs";
import useDashboardContext from "./useDashboardContext";
import DashboardMainPage from "./DashboardMainPage/DashboardMainPage";
import { setLastPageToRender } from "./useDashboardContext/_dashboardContextHelper";
import JobsDateLimitList from "./JobsDateLimit/JobsDateLimitList";
import CalendarPage from "Pages/Calendar/Calendar";
import DashboardJobs from "./DashboardJobs/DashboardJobs";
import DashboardApplicants from "./DashboardApplicants/DashboardApplicants";
import { DashboardSwitchPage } from "./DashboardMain";
import { DashboardPageToRenderType } from "./useDashboardContext/DashboardTypes";

interface IProps {
    dashboardToShow?: DashboardPageToRenderType;
}

const DashboardMainUniqueView = (props: IProps) => {
    const { dashboardToShow } = props;
    const [data, dispatch] = useDashboardContext();
    const { t } = data;

    React.useEffect(() => {
        dispatch.dispatchGeneralPageInfo({
            payload: {
                pageToRender: dashboardToShow
            },
            type: "update"
        });
    }, [dashboardToShow]);

    if (data.ready) {
        return (
            <Box display="flex" flexDirection="column">
                {/* <Box display="flex" flexDirection="row" pl={1.2}>
                    <Box flexGrow={1} />
                    <Box>
                        <DashboardMenu t={t} />
                    </Box>
                </Box> */}
                <Box>
                    <DashboardSwitchPage />
                </Box>
            </Box>
        );
    } else {
        return (
            <Page maxWidth={false}>
                <Loading />
            </Page>
        );
    }
};

export default DashboardMainUniqueView;
