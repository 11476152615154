import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useAuthorization } from "_ReactContext/AuthContext";
import useGetMetadata from "customHooks/useGetMetadata";
import { useListPageFunctionsDeleteCustomer } from "./components/useListPageFunctionsDeleteCustomer";

import {
    useCompleoSimpleList,
    actionsTableListType,
    actionsTableFunction
} from "customHooks/useCompleoSimpleList";
import { Icon } from "@material-ui/core";

function actionsTable(
    featuresAccess: string[],
    listPageFunctions: Compleo.IObject[]
) {
    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: "Edit Customer",
                onClick: (event: any, rowData: any) => {
                    //console.log("edit rowData", rowData);
                    navigate(`/customer/edit/${rowData.skGS1pk.split(":")[1]}`);
                },
                hidden: !featuresAccess.includes("EditCustomer")
            },
            {
                icon: "delete",
                tooltip: "Delete Customer",
                onClick: (event: any, rowData: any) => {
                    // console.log("clique no delete");
                    if (listPageFunctions.length > 0) {
                        listPageFunctions[0].fn(rowData).then(
                            (resolve: boolean) => {
                                //console.log("result return do callDialog", res);
                                // console.log(
                                //     "result return do callDialog",
                                //     resolve
                                // );
                                if (resolve) {
                                    refreshListFunction();
                                }
                            },
                            (reject: boolean) => {
                                console.log(
                                    "reject return do callDialog",
                                    reject
                                );
                            }
                        );
                    }
                },
                hidden: !featuresAccess.includes("DeleteCustomer")
            }
        ];

        return result;
    };
}

const CustomerList = (props: any) => {
    const [t, i18n, readyTranslation] = useTranslation("CUSTOMERLIST", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("CUSTOMERLIST");
    const listAddress = "/customer/list";
    const { company } = useAuthState();

    const listPageFunctions = useListPageFunctionsDeleteCustomer(t);

    const { isFeatureAuthorized } = useAuthorization();
    const featuresAccess = [];

    if (isFeatureAuthorized("EditCustomer")) {
        featuresAccess.push("EditCustomer");
    }

    if (isFeatureAuthorized("DeleteCustomer")) {
        featuresAccess.push("DeleteCustomer");
    }
    const canAddCustomer = isFeatureAuthorized("AddCustomer");

    const actions: actionsTableFunction = actionsTable(
        featuresAccess,
        listPageFunctions
    );

    const [renderTable, finished] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            companyId: company.companyId
        },
        actions
    );

    // refreshFunction = () => refresh();

    // console.log("customerList - refreshFunction", refreshFunction);

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
                rightButton={
                    canAddCustomer
                        ? {
                              text: t("buttonTopRight"),
                              action: () => {
                                  navigate(`/customer/new`);
                              },
                              enabled: true,
                              startIcon: <Icon>add</Icon>
                          }
                        : undefined
                }
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default CustomerList;
