import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getControlOptionsAndChildrenFilterV2 } from "./util/AutoCompleteUtil";
import {
    IInputProps,
    useRhfFieldControlled
} from "../helpers/reactHookFormsHelper";
import { ISelectProps } from "./ComboAutoComplete";
import { useWatch } from "react-hook-form";

const Tags = (props: IInputProps & ISelectProps) => {
    const {
        name,
        label,
        options,
        helperTextDefault,
        campoMetadata,
        customLists,
        required,
        freeSolo,
        ...other
    } = props;
    const freeSoloControl = freeSolo === undefined ? false : freeSolo;
    const language = props.language || "pt-BR";

    const {
        error,
        message,
        setFieldValue,
        field,
        getValues,
        setValue,
        control
    } = useRhfFieldControlled(name, helperTextDefault);

    const fieldValue = useWatch({
        control,
        name: props?.parentFilterField?.parentFieldName
    });
    const controlOptionsAndChildrenFilter = getControlOptionsAndChildrenFilterV2(
        campoMetadata,
        options,
        customLists,
        name,
        fieldValue,
        props
    );

    const defaultValue: Compleo.IObject[] = [];

    const valueFromFormik = field.value;
    const controlValue =
        (valueFromFormik || "") === "" ? defaultValue : valueFromFormik;

    const controlOptions = controlOptionsAndChildrenFilter.controlOptions;

    const onChangeMethod = (event: any, newValue: any | null) => {
        if (newValue === null) {
            setFieldValue(defaultValue);
        } else {
            setFieldValue(newValue);
        }
    };

    return (
        <Autocomplete
            freeSolo={freeSoloControl}
            multiple
            id={field.name}
            value={controlValue}
            options={controlOptions}
            filterSelectedOptions
            getOptionSelected={(option, value) => {
                return value.value === option.value;
            }}
            getOptionLabel={(option: any) =>
                option.label || option[`label-${language}`] || ""
            }
            onChange={onChangeMethod}
            onBlur={field.onBlur}
            renderInput={(params: any) => (
                <TextField
                    {...(other?.inputProps || {})}
                    {...params}
                    error={error}
                    required={required}
                    label={label}
                    helperText={message}
                    inputRef={field.ref}
                    // margin="normal"
                />
            )}
        />
    );
};

export default Tags;
