import React from "react";
import { FieldProps } from "formik";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
interface IProps {
    label: string;
    helperTextDefault: null | string;
    //inputRef: (instance: NumberFormat | null) => void;
    value?: number;
    language: "pt-BR" | "en-US" | "fr" | "es";
    numberType: "integer" | "decimal";
    maxLength?: number;
    decimalDigits?: number;
}

interface IInputProps {
    maxLength?: number;
}

const formatMap = {
    "pt-BR": {
        thousandSeparator: ".",
        decimalSeparator: ","
    },
    "en-US": {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    fr: {
        thousandSeparator: ",",
        decimalSeparator: "."
    },
    es: {
        thousandSeparator: ",",
        decimalSeparator: "."
    }
};

const NumberField = (props: IProps & FieldProps) => {
    //debugger;
    const {
        label,
        field,
        form: {
            dirty,
            touched,
            errors,
            status,
            setFieldValue,
            setFieldTouched
        },
        helperTextDefault,
        value,
        language,
        numberType,
        maxLength,
        decimalDigits,
        //inputRef,
        ...other
    } = props;

    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let errorMessage =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (errorMessage === "") {
            errorMessage += status[field.name];
        } else {
            errorMessage += " - " + status[field.name];
        }
    }

    let inputPropsObj: IInputProps = {};
    if (maxLength) {
        inputPropsObj["maxLength"] = maxLength;
    }

    const thousandSeparator: string | boolean | undefined =
        numberType === "integer"
            ? undefined
            : formatMap?.[language]?.thousandSeparator ||
              formatMap?.["pt-BR"]?.thousandSeparator;

    //console.log(`value of ${field.name}`, field.value);

    return (
        <NumberFormat
            id={field.name}
            label={label}
            type="text"
            error={hasError || (status && status[field.name] !== undefined)}
            helperText={errorMessage}
            onValueChange={(values) => {
                setFieldValue(field.name, values.floatValue, true);
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={formatMap[language].decimalSeparator}
            isNumericString={true}
            decimalScale={numberType === "integer" ? 0 : decimalDigits}
            fixedDecimalScale={numberType === "integer" ? false : true}
            customInput={TextField}
            inputProps={inputPropsObj}
            //getInputRef={inputRef}
            {...field}
            onChange={undefined}
            {...other}
        />
    );
};

NumberField.defaultProps = {
    language: "pt-BR",
    numberType: "decimal",
    decimalDigits: 2
};

export default NumberField;
