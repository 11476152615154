import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Grid } from "@material-ui/core";
import { IItemView } from "./ItemView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemCardValue: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(3)
        },
        gridItem: {
            marginTop: theme.spacing(0.5)
        }
    })
);

const ItemViewInline = (props: IItemView) => {
    const classes = useStyles();

    return (
        <Box alignItems="center" justifyContent="center" alignSelf="center">
            <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{
                    fontWeight: 600
                }}
            >
                {props.label}
                {props.removeColonAfterLabel ? "" : ":"}
            </Typography>
            {props.dontApplyTypography ? (
                props.children
            ) : (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.itemCardValue}
                >
                    {props.children}
                </Typography>
            )}
        </Box>
    );
};

export default ItemViewInline;
