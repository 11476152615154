import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ListItemSecondaryAction } from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Button, ButtonGroup } from "@material-ui/core";
import { FieldOptionsType } from "./FormData";
import { fieldsRules } from "./helper";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

interface IButtonGroupFieldProps {
    variantButtonRequired: any;
    variantButtonOptional: any;
    variantButtonHidden: any;
    handleFieldOption: any;
    requiredRule: boolean;
    t: any;
    fieldName: string;
}

export const ButtonGroupFieldApplicantForm = (
    props: IButtonGroupFieldProps
) => {
    const {
        variantButtonHidden,
        variantButtonOptional,
        variantButtonRequired,
        handleFieldOption,
        requiredRule,
        t,
        fieldName
    } = props;
    const showMessage = useShowMessage();

    const handleClick = async (value: FieldOptionsType, fieldName: string) => {
        if (!requiredRule) {
            handleFieldOption(value, fieldName);
        } else {
            showMessage(t("fieldDefaultRequiredMessage"), "warning", null);
        }
    };
    return (
        <ButtonGroup
            size="small"
            color={!requiredRule ? "secondary" : undefined}
            aria-label="small outlined button group"
        >
            <Button
                variant={variantButtonRequired}
                onClick={() => handleClick("required", fieldName)}
            >
                {t("buttonRequired")}
            </Button>
            <Button
                variant={variantButtonOptional}
                onClick={() => handleClick("optional", fieldName)}
            >
                {t("buttonOptional")}
            </Button>

            <Button
                variant={variantButtonHidden}
                onClick={() => handleClick("hidden", fieldName)}
            >
                {t("buttonHidden")}
            </Button>
        </ButtonGroup>
    );
};

interface IPropsStageItems {
    listFields: Compleo.IObject[];
    formId: string;
    classError: any;
    t: any;
    tapplicant: any;
    handleEditStage: any;
    handleDeleteStage: any;
    fieldValue: any;
    setFieldValue: (value: any) => void;
}
const Fields = (props: IPropsStageItems) => {
    const {
        listFields,
        formId,
        classError,
        t,
        tapplicant,
        handleEditStage,
        handleDeleteStage,
        fieldValue,
        setFieldValue
    } = props;
    const newList: Compleo.IObject[] = listFields.filter(
        (field: any) => field.formGroupId === formId
    );

    const handleFieldOption = (value: FieldOptionsType, fieldName: string) => {
        const newValue = { ...fieldValue };
        const fields = [...newValue.fields];
        fields.filter(
            (item: any) => item.fieldName === fieldName
        )[0].fieldOptions = value;
        setFieldValue({ ...newValue, fields: fields });
        // currentField.fieldOptions =
    };

    return (
        <>
            {newList.map((field: any, index: number) => {
                const requiredRule = fieldsRules.requiredFields.includes(
                    field.fieldName
                );
                const fieldOptions: FieldOptionsType = requiredRule
                    ? "required"
                    : field.fieldOptions || "optional";

                const variantButtonRequired =
                    fieldOptions === "required" ? "contained" : "outlined";
                const variantButtonOptional =
                    fieldOptions === "optional" ? "contained" : "outlined";
                const variantButtonHidden =
                    fieldOptions === "hidden" ? "contained" : "outlined";
                return (
                    <Draggable
                        key={field.fieldName}
                        draggableId={field.fieldName}
                        index={index}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ListItem
                                    button
                                    alignItems="center"
                                    style={{
                                        // borderStyle: "dotted",
                                        // borderWidth: 1,
                                        margin: 4
                                    }}
                                >
                                    <ListItemIcon>
                                        <Tooltip title="">
                                            <Icon color="action">
                                                drag_indicator
                                            </Icon>
                                        </Tooltip>
                                    </ListItemIcon>

                                    <ListItemText
                                        className={classError}
                                        primary={tapplicant(field.fieldName)}
                                    />
                                    <ListItemSecondaryAction>
                                        <ButtonGroupFieldApplicantForm
                                            fieldName={field.fieldName}
                                            handleFieldOption={
                                                handleFieldOption
                                            }
                                            requiredRule={requiredRule}
                                            t={t}
                                            variantButtonHidden={
                                                variantButtonHidden
                                            }
                                            variantButtonOptional={
                                                variantButtonOptional
                                            }
                                            variantButtonRequired={
                                                variantButtonRequired
                                            }
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default Fields;
