import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useTranslation } from "react-i18next";

export interface IModalSessionExpired {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ModalSessionExpired = (prop: IModalSessionExpired) => {
    const { open, setOpen } = prop;
    const [t, i18n, readyTranslation] = useTranslation("MODALSESSIONEXPIRED", {
        useSuspense: false
    });

    const handleClose = () => {
        // reload the page
        window.location.reload();
    };
    const ready = readyTranslation;

    if (ready) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("ModalTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("ModalMessage")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {t("ModalButton")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">""</DialogTitle>
                <DialogContent>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

export default ModalSessionExpired;
