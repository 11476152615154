import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "customHooks/DataTable/datatable-column-header";
import { DataTableRow } from "customHooks/DataTable/datatable-row";
import { isNumber } from ".";

export const getTimeBetweenStatusColumn = (columnName: string, t: any) => {
    const from = columnName.split("_")[1];
    const to = columnName.split("_")[3];
    const label = `${t(`JOBSTATUS_${from}`)} -> ${t(`JOBSTATUS_${to}`)}`;

    const returnData: ColumnDef<Compleo.IObject> = {
        accessorKey: columnName,
        header: ({ column }) => {
            return (
                <DataTableColumnHeader
                    column={column}
                    title={label}
                    align="right"
                />
            );
        },
        cell: ({ row, getValue }) => {
            const value = getValue() as string;
            if (isNumber(value)) {
                // value is a valid number
                return (
                    <DataTableRow
                        row={row}
                        value={value}
                        renderType={"number"}
                        decimalPlaces={2}
                        align="right"
                    />
                );
            }
            return <DataTableRow row={row} value={""} />;
        },
        aggregatedCell: ({ getValue, row }) => {
            const value = getValue() as string;
            if (isNumber(value)) {
                const newValue = `${t("average")}: ${
                    Math.round(getValue<number>() * 100) / 100
                }`;
                return (
                    <>
                        <DataTableRow
                            row={row}
                            value={newValue}
                            align="right"
                        />
                    </>
                );
            }
            return <DataTableRow row={row} value={""} />;
        },
        aggregationFn: "mean",
        enableGrouping: false
    };
    return returnData;
};
