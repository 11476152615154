import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ListItemSecondaryAction } from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { getStagesFromGroup } from "./helperPipeline";

interface IPropsStageItems {
    listStages: Compleo.IObject[];
    group: any;
    classError: any;
    t: any;
    handleEditStage: any;
    handleDeleteStage: any;
    listTypesOfStage: Compleo.IObject[];
    stageIdsWithApplicants?: string[];
}

const StagesItems = (props: IPropsStageItems) => {
    const {
        listStages,
        group,
        classError,
        t,
        handleEditStage,
        handleDeleteStage,
        listTypesOfStage,
        stageIdsWithApplicants = []
    } = props;
    const newList: Compleo.IObject[] = getStagesFromGroup(group, listStages);

    return (
        <>
            {newList.map((stage: any, index: number) => {
                const typeOfStage = listTypesOfStage.filter(
                    (item: any) => item.value === stage.type.value
                )[0];
                const mainListCurrentItem = listStages.findIndex(
                    (item: any) => item.id === stage.id
                );
                return (
                    <Draggable
                        key={stage.id || stage.name}
                        draggableId={stage.name}
                        index={index}
                        isDragDisabled={stage.fixed}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <ListItem
                                button
                                alignItems="flex-start"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                {typeOfStage.icon && (
                                    <ListItemIcon>
                                        <Tooltip
                                            title={`${t("type")}: ${
                                                typeOfStage.label
                                            }`}
                                        >
                                            <Icon color="action">
                                                {typeOfStage.icon}
                                            </Icon>
                                        </Tooltip>
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    className={classError}
                                    primary={t(stage.name)}
                                    secondary={
                                        stageIdsWithApplicants.includes(
                                            stage.id
                                        )
                                            ? t("hasApplicantsMessage")
                                            : undefined
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {!stage.fixed && newList.length > 1 && (
                                        <Tooltip
                                            title={t("COMPLEOGENERAL_Delete")}
                                        >
                                            <IconButton
                                                aria-label={t(
                                                    "COMPLEOGENERAL_Delete"
                                                )}
                                                color="secondary"
                                                onClick={() =>
                                                    handleDeleteStage(stage)
                                                }
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={t("COMPLEOGENERAL_Edit")}>
                                        <IconButton
                                            aria-label={t(
                                                "COMPLEOGENERAL_Edit"
                                            )}
                                            color="primary"
                                            onClick={() =>
                                                handleEditStage(
                                                    stage,
                                                    mainListCurrentItem
                                                )
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default StagesItems;
