import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer
} from "recharts";
import { IAddMainAndGroupRatingScorecardReturn } from "./ApplicantRatingHelper";

const getScorecardGraphData = (
    scorecard: IAddMainAndGroupRatingScorecardReturn,
    itemName?: string
) => {
    const dataReturn: Compleo.IObject[] = [];
    if (!itemName) {
        scorecard.scorecard.map((item) => {
            dataReturn.push({
                subject: item.name,
                mainValue: item.averageRating / 2,
                fullMark: 5
            });
        });
    } else {
        const itemData = scorecard.scorecard.filter(
            (item) => item.name === itemName
        )[0];
        if (itemData) {
            itemData.items.map((localItem: Compleo.IObject) => {
                dataReturn.push({
                    subject: localItem.name,
                    mainValue: localItem.evaluation / 2,
                    fullMark: 5
                });
            });
        }
    }
    return dataReturn;
};

interface IProps {
    open: boolean;
    handleClose: () => void;
    scorecard: IAddMainAndGroupRatingScorecardReturn;
    t: any;
    itemName?: string;
}

const ModalScorecardGraph = (props: IProps) => {
    const { open, handleClose, scorecard, t, itemName } = props;
    const data = getScorecardGraphData(scorecard, itemName);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">{t("scorecard")}</DialogTitle>
            <DialogContent>
                <ResponsiveContainer
                    minHeight={300}
                    minWidth={300}
                    width="100%"
                    height="100%"
                >
                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis angle={30} domain={[0, 5]} />
                        <Radar
                            name="Mike"
                            dataKey="mainValue"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                        />
                    </RadarChart>
                </ResponsiveContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalScorecardGraph;

// const data = [
//     {
//         subject: "Math",
//         A: 120,
//         B: 110,
//         fullMark: 150
//     },
//     {
//         subject: "Chinese",
//         A: 98,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "English",
//         A: 86,
//         B: 130,
//         fullMark: 150
//     },
//     {
//         subject: "Geography",
//         A: 99,
//         B: 100,
//         fullMark: 150
//     },
//     {
//         subject: "Physics",
//         A: 85,
//         B: 90,
//         fullMark: 150
//     },
//     {
//         subject: "History",
//         A: 65,
//         B: 85,
//         fullMark: 150
//     }
// ];
