import React from "react";
import Page from "../_Layouts/DefaultInternal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import * as mui from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        }
    })
);

const IconSearch = () => {
    const mui2: any = mui;
    const allIcons = Object.keys(mui2)
        .sort()
        .map((importName) => {
            let theme;
            if (importName.indexOf("Outlined") !== -1) {
                theme = "Outlined";
            } else if (importName.indexOf("TwoTone") !== -1) {
                theme = "Two tone";
            } else if (importName.indexOf("Rounded") !== -1) {
                theme = "Rounded";
            } else if (importName.indexOf("Sharp") !== -1) {
                theme = "Sharp";
            } else {
                theme = "Filled";
            }
            const name = importName.replace(
                /(Outlined|TwoTone|Rounded|Sharp)$/,
                ""
            );
            let searchable = name;
            // if (synonyms[searchable]) {
            //     searchable += ` ${synonyms[searchable]}`;
            // }
            // searchIndex.add(importName, searchable);
            // @ts-ignore
            const Co = mui[importName];
            const icon = {
                importName,
                name,
                theme,
                Component: Co
            };
            // allIconsMap[importName] = icon;
            return icon;
        });

    return (
        <>
            {allIcons.slice(0, 30).map((icon: any) => {
                // const OtherComponent = React.lazy(() => import(`@material-ui/icons/${icon.importName}`));
                return (
                    <div key={icon.importName}>
                        {`name: ${icon.name} - importName: ${icon.importName} - theme: ${icon.theme}`}
                        <Icon>
                            {icon.importName
                                .split(/(?=[A-Z])/)
                                .join("_")
                                .toLowerCase()}
                        </Icon>
                        <icon.Component
                            tabIndex={-1}
                            // onClick={handleOpenClick}
                            title={icon.importName}
                            // className={classes.iconSvg}
                        />
                    </div>
                );
            })}
        </>
    );
};

const Home = () => {
    const classes = useStyles();

    return (
        <Page>
            <Paper className={classes.root}>
                <Typography variant="h5" component="h3">
                    This is a sheet of paper.
                </Typography>
                <Typography component="p">
                    Paper can be used to build surface or other elements for
                    your application.
                </Typography>
                {/* <IconSearch /> */}
            </Paper>
        </Page>
    );
};

export default Home;
