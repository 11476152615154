import React from "react";
import {
    FieldDefault,
    FieldTitle,
    IFieldProps,
    FieldTags,
    FieldAvatar,
    FieldJobStatus,
    FieldJobCode,
    FieldDistance,
    FieldPipelineStage,
    FieldApplicantTitle,
    FieldTime,
    FieldEvaluation,
    FieldJobEvaluation,
    FieldUrl,
    FieldWhatsApp,
    FieldApplicantHistoryType,
    FieldApplicantTitleActivities,
    FieldJobScreeningQuestionnaire,
    FieldJobNumber
} from "../FieldsType";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    type?: string;
}

const CardJobFields = (props: IProps) => {
    const { type, fieldData, fieldName } = props;
    const propsField: IFieldProps = {
        fieldName,
        fieldData,
        returnType: "card"
    };

    switch (type) {
        case "jobTitle":
            return (
                <FieldTitle
                    {...propsField}
                    url={`/job/view/${fieldData.pk.split(":")[1]}`}
                />
            );
        case "jobTitleApplicantHistory":
            return (
                <FieldDefault
                    {...propsField}
                    url={`/job/view/${
                        (fieldData?.jobData?.pk || "").split(":")[1]
                    }`}
                />
            );
        case "jobTitleRequest":
            return (
                <FieldTitle
                    {...propsField}
                    url={`/job/viewrequest/${fieldData.pk.split(":")[1]}`}
                />
            );
        case "applicantTitle":
            return (
                <FieldApplicantTitle
                    {...propsField}
                    url={`/applicant/edit/${fieldData.pk.split(":")[1]}`}
                />
            );
        case "applicantTitleActivity":
            return <FieldApplicantTitleActivities {...propsField} />;
        case "tags":
            return <FieldTags {...propsField} />;
        case "avatar":
            return <FieldAvatar {...propsField} />;
        case "jobStatus":
            return <FieldJobStatus {...propsField} />;
        case "jobCode":
            return <FieldJobCode {...propsField} />;
        case "jobNumber":
            return <FieldJobNumber {...propsField} />;
        case "distance":
            return <FieldDistance {...propsField} />;
        case "pipelineStage":
            return <FieldPipelineStage {...propsField} />;
        case "time":
            return <FieldTime {...propsField} />;
        case "evaluation":
            return <FieldEvaluation {...propsField} />;
        case "jobEvaluation":
            return <FieldJobEvaluation {...propsField} />;
        case "url":
            return <FieldUrl {...propsField} />;
        case "whatsapp":
            return <FieldWhatsApp {...propsField} />;
        case "applicantHistoryType":
            return <FieldApplicantHistoryType {...propsField} />;
        case "screeningQuestionnaireRanking":
            return <FieldJobScreeningQuestionnaire {...propsField} />;
        default:
            return <FieldDefault {...propsField} />;
    }
};

export default CardJobFields;
