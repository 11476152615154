import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import {
    Divider,
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import { downloadFileFromS3, isFileFromDB, downloadLocalFile } from "./File";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import Edit from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileTags from "./FileTags";
import FileModalEditTags from "./FileModalEditTags";
import { size } from "lodash";
import {
    AllowedExtensionsViewFile,
    AllowedExtensionsViewFileList
} from "Components/ModalViewFile/ModalViewFile";
import { getExtension } from "functions/util";
import useModalViewFile from "Components/ModalViewFile/useModalViewFile";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconFiles: {
            margin: theme.spacing(0.5),
            padding: theme.spacing(0.2)
        },
        listText: {
            marginLeft: theme.spacing(0.5)
        }
    })
);

interface IFileList {
    fileparam: any;
    removeFile: (name: any) => void;
    editTags: (
        name: any,
        tags: string[],
        newFileName?: string | undefined
    ) => void;
    enableTags: boolean;
    t: any;
}

const FileListItem = (props: IFileList) => {
    const classes = useStyles();
    const { fileparam, removeFile, enableTags, t, editTags } = props;
    const [modalEditOpen, setModalEditOpen] = React.useState(false);
    const {
        ModalComponentViewFile,
        viewFileS3Key,
        viewFile
    } = useModalViewFile(t);

    let file = fileparam?.FileInfo || fileparam;
    // debugger;
    const fileFromDB = isFileFromDB(file);
    if (fileFromDB) {
        // file.path = file.originalFileName;
        file.name = file.originalFileName;
    }
    const [downloading, setDownloading] = React.useState(false);

    const extension = getExtension(file.name) as AllowedExtensionsViewFile;
    const enableView = AllowedExtensionsViewFileList.includes(extension);

    return (
        <React.Fragment>
            <ListItem>
                <ListItemIcon>
                    {enableTags && (
                        <ButtonIconTooltipCompleo
                            label={t("CGT_editTags")}
                            onClick={() => setModalEditOpen(true)}
                            size="small"
                            color="default"
                            className={classes.iconFiles}
                            edge="start"
                        >
                            <Edit fontSize="small" />
                        </ButtonIconTooltipCompleo>
                    )}
                    <ButtonIconTooltipCompleo
                        label={t("CGT_delete")}
                        onClick={() => removeFile(file.name)}
                        size="small"
                        color="default"
                        className={classes.iconFiles}
                    >
                        <DeleteIcon fontSize="small" />
                    </ButtonIconTooltipCompleo>
                    <ButtonIconTooltipCompleo
                        label={t("CGT_download")}
                        onClick={async () => {
                            setDownloading(true);
                            if (fileFromDB) {
                                await downloadFileFromS3(file.key, file.name);
                            } else {
                                await downloadLocalFile(file, file.name);
                            }
                            setDownloading(false);
                        }}
                        size="small"
                        color="default"
                        className={classes.iconFiles}
                    >
                        {!downloading ? (
                            <GetAppIcon fontSize="small" />
                        ) : (
                            <CircularProgress size={24} />
                        )}
                    </ButtonIconTooltipCompleo>
                    {enableView && (
                        <ButtonIconTooltipCompleo
                            size="small"
                            color="default"
                            label={t("CGT_view")}
                            onClick={() => {
                                if (fileFromDB) {
                                    viewFileS3Key(file.key);
                                } else {
                                    const urlLocal = URL.createObjectURL(file);
                                    viewFile(urlLocal, extension, true);
                                }
                            }}
                        >
                            <Icon>visibility</Icon>
                        </ButtonIconTooltipCompleo>
                    )}
                </ListItemIcon>

                <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ style: { fontSize: 13 } }}
                    secondary={
                        enableTags && <FileTags tags={fileparam?.tags || []} />
                    }
                    className={classes.listText}
                />
            </ListItem>
            <Divider />
            <FileModalEditTags
                open={modalEditOpen}
                handleClose={() => setModalEditOpen(false)}
                t={t}
                tags={fileparam?.tags || []}
                editTags={editTags}
                name={file.name}
            />
            {ModalComponentViewFile}
        </React.Fragment>
    );
};

export default FileListItem;
