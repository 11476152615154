import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultExternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { navigate } from "@gatsbyjs/reach-router";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";

const TrialPlanSelect = (props: any) => {
    const classes = useFormStyles();

    const [t, i18n, readyTranslation] = useTranslation("TRIALPLAN", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("TRIALPLAN");
    const [valuesFromSource] = useValuesFromSource(metadata, true, {});

    const planReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const setPlan = async (values: any) => {
        let retorno: Compleo.IObject = {};
        retorno = { status: 200 };
        navigate(`/trial/new/${values.plan.value}`);
        return retorno;
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        setPlan,
        planReturn,
        null,
        FormExternal,
        metadata,
        valuesFromSource,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page featureName={t("a_FormGroup_basicData")}>{compRetorno}</Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default TrialPlanSelect;
