import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { IEvalutionReportsDBReturn } from "Pages/EvaluationRequestExternal/EvaluationRequestMain";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        chip: {
            borderRadius: 6,
            color: "white",
            backgroundColor: "#2196f3",
            padding: 4
        }
    });
});

export const JobReportsTypeToRender: CompleoShared.Reports.IJobReportsToRender[] = [
    "funnel",
    "dashboard",
    "activities",
    "activitiesRecruiter"
];

interface IJobReportTitle {
    type: CompleoShared.Reports.IJobReportsToRender;
    t: any;
    dynamicDashboardsList: IEvalutionReportsDBReturn[];
    dynamicDashboardId: string | undefined;
}

const JobReportTitle = (props: IJobReportTitle) => {
    const classes = useStyles();
    const { t, type, dynamicDashboardId, dynamicDashboardsList } = props;

    const dashboardName = (dynamicDashboardsList || []).filter(
        (i) => i.value === `dashboardId:${dynamicDashboardId}`
    )[0]?.label;
    const title =
        type !== "dashboard" ? t(`reportTitle_${type}`) : dashboardName;
    return (
        <Box ml={0}>
            {" "}
            <Chip
                className={classes.chip}
                size="medium"
                label={
                    <Typography component="p" variant="body1">
                        <strong>{title}</strong>
                    </Typography>
                }
            />
        </Box>
    );
};

export default JobReportTitle;
