import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { useQueryClient } from "react-query";
import { useJobListDropdown } from "customHooks/useList/ListHelpers/BulkActions/helperBulkActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    applicantId: string;
    listKeyToInvalidate?: string[];
    currentJobs?: Compleo.IObject[];
}

const ApplicantAddToJob = (props: IPropsItemFilter) => {
    const {
        onClose,
        open,
        applicantId,
        listKeyToInvalidate = [],
        currentJobs = []
    } = props;

    const [isUpdating, setIsUpdating] = React.useState(false);
    const formikRef = React.useRef(null);
    const classes = useStyles();
    const jobData = useJobListDropdown();

    const showMessage = useShowMessage();
    const queryClient = useQueryClient();
    // const updateAllInfo = useRefreshKanban();

    const { company } = useAuthState();
    const module = "MODALAPPLICANTADDTOJOB";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const [initialValues] = useValuesFromSource(metadata, false, {});
    const ready = readyTranslation && metadata.status === "success";

    const customList: Compleo.ListDefinitionType[] = [];
    if (jobData.ready) {
        const currentJobIds: string[] = currentJobs.map(
            (item: Compleo.IObject) => item.JobId
        );
        const jobDataFiltered = jobData.data.filter(
            (item: Compleo.IObject) => !currentJobIds.includes(item.value)
        );
        customList.push({
            fieldName: "job",
            data: jobDataFiltered,
            valueName: "title",
            labelName: "pk"
        });
    }

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        const jobId = values?.job?.value || "";

        const dataPost: Compleo.IObject = {
            companyId: company.companyId,
            jobId: jobId,
            applicantId: applicantId
        };

        const postAddress = `/job/addapplicanttojob`;

        try {
            const dataAPI = await apiDirectCall(postAddress, "post", dataPost);
            if (dataAPI.status === 200) {
                if (listKeyToInvalidate.length) {
                    for (const keyName of listKeyToInvalidate) {
                        queryClient.invalidateQueries(keyName);
                    }
                }
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);
        onClose();
        return retorno;
    };

    const handleSave = () => {
        const formikValue: any = formikRef.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const title = t("ModalTitle");

    const [formCompleo, finished] = useCompleoFormNew({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formikRef,
        customLists: customList
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (finished && ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                aria-labelledby={title}
                open={open}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default ApplicantAddToJob;
