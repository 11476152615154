import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("buttonEditApplicationForm"),
                onClick: (event: any, rowData: any) => {
                    navigate(
                        `/applicationform/edit/${rowData.skGS1pk.split(":")[1]}`
                    );
                }
            },
            (rowData: any) => {
                // console.log("rowData", rowData);
                let hidden = false;
                if (rowData.default === true) {
                    hidden = true;
                }

                return {
                    icon: "check",
                    tooltip: t("buttonMakeDefault"),
                    onClick: async (event: any, rowData: any) => {
                        const uuid = rowData.skGS1pk.split(":")[1];
                        const postAddress = `/applicationform/makedefault`;

                        const result = await apiDirectCall(
                            postAddress,
                            "post",
                            {
                                companyId: companyId,
                                uuid: uuid
                            }
                        );
                        if (result.status === 200) {
                            showMessage(
                                t("ApplicationFormMakeDefault_succesMessage"),
                                "success"
                            );
                            refreshListFunction();
                            return true;
                        }
                    },
                    hidden: hidden
                };
            },
            {
                icon: "delete",
                tooltip: t("buttonDeleteApplicationForm"),
                onClick: async (event: any, rowData: any) => {
                    const uuid = rowData.skGS1pk.split(":")[1];
                    const postAddress = `/applicationform/delete`;

                    callDialog({
                        title: t("ApplicationFormDelete_ModalTitle"),
                        bodyText: t("ApplicationFormDelete_Message"),
                        agreeButtonText: t("ApplicationFormDelete_yes"),
                        disagreeButtonText: t("ApplicationFormDelete_no"),
                        agreeFunction: async () => {
                            //const uuid = rowData.skGS1pk.split(":")[1];
                            const result = await apiDirectCall(
                                postAddress,
                                "post",
                                {
                                    companyId: companyId,
                                    uuid: uuid,
                                    deleted: true
                                }
                            );
                            if (result.status === 200) {
                                showMessage(
                                    t("ApplicationFormDelete_succesMessage"),
                                    "success"
                                );
                                refreshListFunction();
                                return true;
                            }
                            return false;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            }
        ];

        return result;
    };
};

export default useActionsTable;
