import React from "react";
import { CompleoListPage } from "customHooks/useList";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useJobContext from "./useJobContext";
import ModalApplicantContainer from "customHooks/useList/ModalApplicantContainer";
import { ApplicantViewModulesType } from "Pages/Applicant/ApplicantView/useApplicantViewContext/ApplicantViewTypes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            // display: "flex",
            // flexGrow: 1,
            // flexDirection: "column",
            // width: "100%"
        }
    })
);

const JobApplicantList = (props: any) => {
    const classes = useStyles();
    const [data] = useJobContext();

    const module = "JOBAPPLICANTLISTJOBVIEW";
    const paramModuleName: ApplicantViewModulesType =
        data.module === "JOBREQUESTVIEW"
            ? "APPLICANTVIEWREQUESTER"
            : "APPLICANTVIEW";

    return (
        <Box className={classes.container}>
            <CompleoListPage
                moduleName={module}
                listApiAddress="/job/applicantlistjobview"
                layoutType="jobview"
                otherParamsQuery={{ jobId: data.JobId }}
            >
                <ModalApplicantContainer
                    jobDataInsideJob={{
                        jobId: data.JobId,
                        jobData: data.Job,
                        jobDataContext: data
                    }}
                    module={paramModuleName}
                />
            </CompleoListPage>
        </Box>
    );
};

export default JobApplicantList;
