import React from "react";

const useAdditionalProperties = (
    languages: any,
    defaultLanguage: any,
    readyLanguages: any,
    currentLanguage: string
) => {
    const [additionalProperties, setAdditionalProperties] = React.useState<{
        addProps: Compleo.useCompleoForm.AdditionalPropertiesField[];
        ready: boolean;
    }>({ ready: false, addProps: [] });

    React.useEffect(() => {
        const newAdditionalProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [];
        if (languages && defaultLanguage && readyLanguages) {
            newAdditionalProps.push({
                fieldName: "translations",
                props: { languages, currentLanguage, defaultLanguage }
            });

            setAdditionalProperties({
                addProps: newAdditionalProps,
                ready: true
            });
        }
    }, [languages, defaultLanguage, readyLanguages, currentLanguage]);

    return {
        additionalProperties: additionalProperties.addProps,
        ready: additionalProperties.ready
    };
};

export default useAdditionalProperties;
