import React from "react";
import { SnackbarOrigin } from "@material-ui/core/Snackbar";

export type TypeMessage = "success" | "error" | "info" | "warning";

export interface INotificationContext {
    message?: string;
    typeMessage?: TypeMessage;
    enabled?: boolean;
    timeout?: null | number;
    snackBarOrign?: SnackbarOrigin;
    className?: string;
}

const initialState: INotificationContext = {
    enabled: false,
    timeout: null,
    snackBarOrign: {
        vertical: "top",
        horizontal: "right"
    }
};
const NotificationContext = React.createContext({
    notification: initialState,
    setMessageObjParam: (params: INotificationContext) => null,
    setMessage: (type: TypeMessage, message: string, timeout: null | number) =>
        null,
    clearMessage: () => null
});

const useNotification = () => {
    const context = React.useContext(NotificationContext);
    if (!context) {
        throw new Error(
            `useNotification must be used within a NotificationProvider`
        );
    }
    return context;
};

const NotificationProvider = (props: any) => {
    const [notification, setNotificationState] = React.useState(initialState);
    const setMessage = (
        type: TypeMessage,
        message: string,
        timeout: null | number
    ) => {
        const newState = { ...initialState };
        newState.message = message;
        newState.typeMessage = type;
        newState.enabled = true;
        newState.timeout = timeout;
        setNotificationState(newState);
    };

    const setMessageObjParam = (params: INotificationContext) => {
        const newState = { ...initialState, ...params, enabled: true };
        setNotificationState(newState);
    };
    const clearMessage = () => {
        const newState = { ...notification };
        newState.enabled = false;
        setNotificationState(newState);
    };
    return (
        <NotificationContext.Provider
            value={{
                notification,
                setMessage,
                clearMessage,
                setMessageObjParam
            }}
            {...props}
        />
    );
};

export { NotificationProvider, useNotification };
