export const HISTORYCOMMENTMAINHEIGHT = 180;
export const HISTORYCHANGESTATUSHEIGHT = 100;
export const HISTORYCHANGESTATUSHEIGHTWITHCOMMENT = 200;
export interface IJobHistoryProps {
    historyItem: Compleo.IObject;
    tLocal: any;
}

export const getIconByHistoryType = (
    historyType: CompleoShared.Common.JobHistoryType
) => {
    switch (historyType) {
        case "changeStatus":
            return "swap_horiz";
        case "addComment":
            return "comment";
        default:
            return "fiber_manual_record";
    }
};

export const getColorByHistoryType = (
    historyType: CompleoShared.Common.JobHistoryType
) => {
    switch (historyType) {
        case "changeStatus":
            return "#f44336";
        case "addComment":
            return "#3f51b5";
        default:
            return "grey";
    }
};

export const getSizeByHistoryType = (
    historyType: CompleoShared.Common.JobHistoryType,
    hasComment: boolean = false
) => {
    switch (historyType) {
        case "changeStatus":
            if (hasComment) {
                return HISTORYCHANGESTATUSHEIGHTWITHCOMMENT;
            } else {
                return HISTORYCHANGESTATUSHEIGHT;
            }
        case "addComment":
            return HISTORYCOMMENTMAINHEIGHT;
        default:
            return HISTORYCOMMENTMAINHEIGHT;
    }
};
