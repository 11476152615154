import React from "react";
import { Grid, Button } from "@material-ui/core";
import { IFieldProps } from ".";
import { FieldViewFile } from "../FieldsType";
import { FieldViewCustomCV } from "../FieldsType";

const FieldViewMultipleFiles = (
    props: IFieldProps & {
        customCVsData: Compleo.IObject[];
        completeMetadata: any;
    }
) => {
    const { fieldData, t, language, customCVsData } = props;
    const propsField: IFieldProps = props;

    const { propsToAdd } = propsField;
    const { evaluationId, cvToShare } = propsToAdd;

    const [documentIndex, setDocumentIndex] = React.useState(0);

    const cvTypeList = customCVsData.map((customCV: Compleo.IObject) => ({
        id: customCV.skGS1pk.split(":")[1],
        name: customCV.name
    }));

    if (fieldData.cv) {
        cvTypeList.unshift({
            id: "cv",
            name: language === "pt-BR" ? "CV do Candidato" : t("cv")
        });
    }

    return (
        <>
            {evaluationId && cvToShare?.value === "cv" && (
                <FieldViewFile {...propsField} />
            )}
            {evaluationId && cvToShare?.value !== "cv" && (
                <FieldViewCustomCV
                    {...propsField}
                    customCVId={cvToShare.value}
                />
            )}
            {(!evaluationId || cvToShare?.value === undefined) && (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1} direction="row">
                            {cvTypeList.map((cvType: any, index: number) => (
                                <Grid item key={cvType.id}>
                                    <Button
                                        variant="text"
                                        size="small"
                                        color={
                                            documentIndex === index
                                                ? "primary"
                                                : "default"
                                        }
                                        style={
                                            documentIndex === index
                                                ? {
                                                      textDecoration:
                                                          "underline"
                                                  }
                                                : undefined
                                        }
                                        onClick={() => setDocumentIndex(index)}
                                    >
                                        {cvType.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        {documentIndex === 0 &&
                        cvTypeList[documentIndex].id === "cv" ? (
                            <FieldViewFile {...propsField} />
                        ) : (
                            <FieldViewCustomCV
                                {...propsField}
                                customCVId={cvTypeList[documentIndex].id}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default FieldViewMultipleFiles;
