import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import "react-image-crop/dist/ReactCrop.css";
import { IImageDefinitionMetadata } from "./types";
// import Webcam from "react-webcam";

interface IProps {
    classes: any;
    completedCrop: any;
    imageFromS3: string | null;
    removeImage: any;
    previewCanvasRef: any;
    imageDefinition: IImageDefinitionMetadata;
    labelImage: string;
    t: any;
    enableCamera: boolean;
}

const Preview = (props: IProps) => {
    const {
        classes,
        completedCrop,
        imageFromS3,
        removeImage,
        previewCanvasRef,
        imageDefinition,
        labelImage,
        t
    } = props;

    const calculateWidth = Math.round(completedCrop?.width ?? 0);
    const previewWidth =
        calculateWidth === 0 ? 0 : imageDefinition.minWidth || calculateWidth;
    const calculateHeight = Math.round(completedCrop?.height ?? 0);
    const previewHeight =
        calculateHeight === 0
            ? 0
            : imageDefinition.minHeight || calculateHeight;

    const hasImage = completedCrop || imageFromS3;

    if (hasImage) {
        return (
            <Grid container className={classes.gridPreview}>
                <Grid
                    item
                    container
                    xs={12}
                    sm={1}
                    className={classes.gridPreview}
                    alignItems="center"
                    justify="flex-start"
                    direction="row"
                    zeroMinWidth
                >
                    <Tooltip title={t("ImageField_deleteImageButton")}>
                        <IconButton
                            aria-label={t("ImageField_deleteImageButton")}
                            color="secondary"
                            onClick={removeImage}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={11} className={classes.gridPreview}>
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            width: previewWidth,
                            height: previewHeight,
                            marginTop: 5
                        }}
                    />
                    {imageFromS3 && (
                        <img
                            alt={labelImage}
                            src={imageFromS3}
                            style={{
                                width: 200,
                                height: 200,
                                marginTop: 5
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

export default Preview;
