import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControlLabel,
    Icon,
    Switch,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React, { useState } from "react";
import useJobContext from "../useJobContext";
import JobKanbanColumn from "./JobKanbanColumn";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { DragDropContext } from "react-beautiful-dnd";
import useUpdateCard from "./useUpdateCard";
import {
    PipelineViewType,
    PipelineViewList
} from "../useJobContext/JobViewTypes";
import {
    getStages,
    getTotalFilteredApplicantsByStage,
    getTotalStage
} from "./helper";
import { CompleoListPageBlank } from "customHooks/useList";
import Sync from "@material-ui/icons";
import SyncIcon from "@material-ui/icons/Sync";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import FontAwesome from "Components/FontAwesome";
import { isListFilterApplied } from "customHooks/useList/ListHelpers/ListUtil";
import {
    indigo,
    lightBlue,
    grey,
    blue,
    green,
    red
} from "@material-ui/core/colors";
// import { sideMenuWidth } from "Components/MenuCompleo/MenuCompleo";
import theme from "theme/Theme";
import DialogKanbanFilter from "./Filter/DialogKanbanFilter";
import useSessionValues from "_ReactContext/SessionValues";
import useRefreshKanban from "./useRefreshKanban";

const useStyles = () => {
    const [sessionData] = useSessionValues();

    const isXsSMSize = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    return makeStyles((theme: Theme) => {
        return createStyles({
            mainContainer: {
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                marginLeft: theme.spacing(1)
                // backgroundColor: "blue"
            },
            actionsContainer: {
                display: "flex",
                marginTop: theme.spacing(1),
                position: "fixed",
                // right: 0,
                // left: "auto",
                width: isXsSMSize
                    ? "calc(100vw - 30px)"
                    : `calc(100vw - ${sessionData.menuWidth + 50}px)`
            },
            toolbarKanban: {
                minHeight: 40
            },
            buttonsOptions: {
                display: "flex"
            },
            buttonFilter: {
                // display: "flex"
                // flexGrow: 1
            },
            container: {
                display: "flex",
                flex: 1
                // minWidth: "100vw",
                // overflow: "auto"
                // overflowX: "auto",
                // overflowY: "hidden",
                // backgroundColor: "blue"
            },
            tooltipFilter: {
                // margin: theme.spacing(0.2),
                padding: 0,
                marginRight: theme.spacing(1.5),
                marginLeft: theme.spacing(1.5)
            },
            grow: {
                flexGrow: 1
            },
            buttonItemsSelected: {
                backgroundColor: grey[300],
                borderColor: grey[600]
                // color: "white"
            },
            buttonItemsDefault: {
                borderColor: grey[600]
            },
            textButton: {
                fontSize: 13,
                display: "inline-block"
            }
        });
    });
};

const JobKanbanMain = (props: any) => {
    const [data, dispatch] = useJobContext();
    const classes = useStyles()();
    const { onDragEnd, onDragStart } = useUpdateCard();
    const { pipelineView } = data.LocalStateData;
    const updateAllInfo = useRefreshKanban();
    const [refreshInProgress, setRefreshInProgress] = useState(false);

    const [filterOpen, setFilterOpen] = React.useState(false);
    const { t } = data;
    const stages = getStages(
        data.Job.data?.pipeline?.stages || [],
        pipelineView
    );

    const filterApplied = isListFilterApplied(data.LocalStateData.filter);

    const refreshData = async () => {
        setRefreshInProgress(true);
        await updateAllInfo(t("KanbanMessage_Sync"));
        setRefreshInProgress(false);
    };

    return (
        <CompleoListPageBlank
            moduleName="JOBAPPLICANTLISTFILTERKANBAN"
            listApiAddress="/job/applicantlistkanbanfilter"
            otherParamsQuery={{ jobId: data.JobId }}
        >
            <Box className={classes.mainContainer}>
                <Divider />
                <div className={classes.toolbarKanban}>
                    <Box className={classes.actionsContainer}>
                        <Box className={classes.buttonsOptions}>
                            <ButtonOptionsQualifiedDisqualified />
                        </Box>
                        <div className={classes.grow} />
                        <Box>
                            <ButtonIconTooltipCompleo
                                label={t("Kanban_SyncIcon")}
                                onClick={() => refreshData()}
                                className={classes.tooltipFilter}
                            >
                                {refreshInProgress ? (
                                    <Loading size={16} />
                                ) : (
                                    <SyncIcon />
                                )}
                            </ButtonIconTooltipCompleo>
                        </Box>
                        <Box>
                            <ButtonIconTooltipCompleo
                                label={t("Kanban_FilterIcon")}
                                onClick={() => setFilterOpen(true)}
                                className={classes.tooltipFilter}
                            >
                                {/* <FilterListIcon /> */}
                                <FontAwesome
                                    faClassName="fas fa-filter fa-xs"
                                    color={
                                        filterApplied ? "secondary" : undefined
                                    }
                                />
                            </ButtonIconTooltipCompleo>
                        </Box>
                    </Box>
                </div>

                <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                >
                    <Box className={classes.container}>
                        {stages.map((item) => (
                            <JobKanbanColumn key={item.id} ColumnData={item} />
                        ))}
                    </Box>
                </DragDropContext>
                <DialogKanbanFilter
                    open={filterOpen}
                    handleClose={() => setFilterOpen(false)}
                />
            </Box>
        </CompleoListPageBlank>
    );
};

const ButtonOptionsQualifiedDisqualified = (props: any) => {
    const [data, dispatch] = useJobContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { t } = data;
    const { pipelineView } = data.LocalStateData;
    const classes = useStyles()();

    const getTotalByItem = (pipelineViewTotal: PipelineViewType) => {
        const stages = getStages(
            data.Job.data?.pipeline?.stages || [],
            pipelineViewTotal
        );
        const listStageIds = stages.map((item) => item.id);
        const totalApplicantsType = getTotalStage(
            data.LocalStateData.stagesOnlyTotal,
            listStageIds
        );
        const totalFilteredApplicantsType = getTotalFilteredApplicantsByStage(
            data,
            listStageIds
        );
        return { totalApplicantsType, totalFilteredApplicantsType };
    };

    const handleChange = (pipelineView: PipelineViewType) => {
        dispatchGeneralPageInfo({
            type: "update",
            payload: { pipelineView: pipelineView }
        });
    };
    return (
        <ButtonGroup size="small">
            {PipelineViewList.map((item) => {
                const total = getTotalByItem(item);
                const totalReadyText =
                    total.totalApplicantsType ===
                    total.totalFilteredApplicantsType
                        ? `${total.totalApplicantsType}`
                        : `${total.totalFilteredApplicantsType} 
                        ${t("ofText")} ${total.totalApplicantsType}`;

                const totalText = totalReadyText;

                return (
                    <Button
                        key={item}
                        // variant={
                        //     pipelineView === item ? "contained" : "outlined"
                        // }
                        disableElevation
                        onClick={() => handleChange(item)}
                        className={
                            pipelineView === item
                                ? classes.buttonItemsSelected
                                : classes.buttonItemsDefault
                        }
                        // style={{ borderColor: "red", backgroundColor: "blue" }}
                    >
                        <Typography noWrap className={classes.textButton}>{`${t(
                            `Pipeline_Button_${item}`
                        )} (${totalText})`}</Typography>
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

export default JobKanbanMain;
