import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Field } from "formik";
import { FieldProps } from "formik";
import { useAuthorization, useAuthState } from "_ReactContext/AuthContext";
import { Grid } from "@material-ui/core";
import { navigate, useLocation } from "@gatsbyjs/reach-router";
import { clearAccentuatedStringFileUpload, toBase64 } from "functions/util";
import { apiDirectCall } from "customHooks/useApi/api";
import LinearProgress, {
    LinearProgressProps
} from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import File from "customHooks/useCompleoForm/Inputs/File/File";
import useApplicantNewContext from "../ApplicantNewEdit/useApplicantNewContext/useApplicantNewContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import AlertUpgradePlan from "customHooks/useFeatureUpgradePlan/AlertUpgradePlan";
import axios, { AxiosResponse } from "axios";

const useStylesLocal = makeStyles({
    root: {
        width: "100%"
    }
});

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
) {
    const classes = useStylesLocal();
    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box minWidth={35}>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        </div>
    );
}

interface IProps {
    metadata: any;
    className: any;
    t: any;
    classes: any;
    required: boolean;
    label: string;
    requiredMetadata: boolean;
    helperTextDefault: null | string;
}

const UploadComponent = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        t,
        helperTextDefault,
        metadata,
        requiredMetadata,
        ...other
    } = props;
    const [data, dispatchData] = useApplicantNewContext();
    const location = useLocation();
    const isBeta = location.search === "?beta=1";
    // const parserUrl = isBeta
    //     ? "https://aec6ultwj6qfjwd5zaxk4zfm6u0dyrtv.lambda-url.us-east-1.on.aws"
    //     : "/parser/parserdirect";
    const parserUrl = "/parser/parserdirect";

    const showMessage = useShowMessage();
    const { isFeatureAuthorized } = useAuthorization();

    const limitOneCVByPlan = isFeatureAuthorized("BulkParser_Upgrade");

    metadata.fileDefinition.maxFiles = limitOneCVByPlan
        ? 1
        : metadata.fileDefinition.maxFiles;
    // limitOneCVByPlan

    const [uploading, setUploading] = React.useState<boolean | number>(false);
    const { company } = useAuthState();
    const companyId = company.companyId.toString();

    const calculatePercentual = (
        actualOperation: number,
        numberOfOperations: number
    ) => {
        return Math.floor((actualOperation / numberOfOperations) * 100);
    };

    const uploadOneFile = async (acceptedFiles: any[]) => {
        setUploading(30);
        const file = acceptedFiles[0];
        const fileNameCleared = clearAccentuatedStringFileUpload(file.name);
        const base64 = await toBase64(file);

        try {
            let axiosReturnParser = null;
            // if (isBeta) {
            //     axiosReturnParser = await axios.post(parserUrl, {
            //         base64File: base64,
            //         fileName: fileNameCleared,
            //         returnPDF: true,
            //         newVersion: isBeta,
            //         companyId: companyId
            //     });
            // } else {
            axiosReturnParser = await apiDirectCall(parserUrl, "post", {
                base64File: base64,
                fileName: fileNameCleared,
                returnPDF: true,
                newVersion: isBeta,
                companyId: companyId
            });
            // }
            setUploading(90);
            const parserResult = {
                data: axiosReturnParser.data.data,
                base64data: axiosReturnParser.data.base64data,
                fileName: axiosReturnParser.data.fileName
            };

            var binary = atob(parserResult.base64data);
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type "application/pdf"
            var blob = new Blob([view], { type: "application/pdf" });
            var urlPDFConveted = URL.createObjectURL(blob);

            dispatchData({
                type: "update",
                payload: {
                    // jsonParsedCV: {},
                    jsonParsedCV: parserResult.data,
                    urlCV: urlPDFConveted,
                    pageToRender: "verificationData",
                    cvFile: { streamContent: file, fileName: file.name }
                }
            });
        } catch (ex) {
            showMessage({
                message: t("NotIdentifiedProblem"),
                snackBarOrign: { vertical: "top", horizontal: "right" },
                timeout: 1000 * 10,
                typeMessage: "error"
            });
            setUploading(false);
        }
    };

    const onDropFunction = async (
        acceptedFiles: any[],
        rejectedFiles: any[]
    ) => {
        try {
            let actualOperation = 0;
            setUploading(actualOperation);
            if (acceptedFiles.length === 0) {
                return;
            }
            if (rejectedFiles.length > 0) {
                return;
            }
            if (acceptedFiles.length === 1) {
                await uploadOneFile(acceptedFiles);
                return;
            }

            const numberOfOperations = (acceptedFiles.length || 0) + 1.05;

            const re = /(?:\.([^.]+))?$/;
            const filesAndExtensions: Compleo.IObject = [];
            const valueDefinition: CompleoShared.ParserOperation.IParsedItem[] = [];
            const keyFieldName = "parserUpload";

            for (const file of acceptedFiles) {
                const extension = (re.exec(file.name) || [])[1];
                // const fileNameCleared = clearString(clearAccentuatedString(file.name), "_");
                const originalFileName = clearAccentuatedStringFileUpload(
                    file.name
                );
                filesAndExtensions.push({
                    extension: extension,
                    originalFileName: originalFileName,
                    fieldName: keyFieldName,
                    contentType: file.type
                });
            }

            const axPost = await apiDirectCall(
                "/general/requests3url",
                "post",
                {
                    files: filesAndExtensions,
                    companyId: company.companyId || 0
                }
            );
            actualOperation++;
            setUploading(
                calculatePercentual(actualOperation, numberOfOperations)
            );
            const urls = axPost.data;

            for (const file of acceptedFiles) {
                const base64 = await toBase64(file);
                let axiosReturnParser = null;
                // if (isBeta) {
                //     axiosReturnParser = await axios.post(parserUrl, {
                //         base64File: base64,
                //         fileName: clearAccentuatedStringFileUpload(file.name),
                //         newVersion: isBeta,
                //         companyId: companyId
                //     });
                // } else {
                axiosReturnParser = await apiDirectCall(parserUrl, "post", {
                    base64File: base64,
                    fileName: clearAccentuatedStringFileUpload(file.name),
                    newVersion: isBeta,
                    companyId: companyId
                });
                // }
                actualOperation++;
                setUploading(
                    calculatePercentual(actualOperation, numberOfOperations)
                );

                let parserResult: null | Compleo.IObject = null;
                parserResult = axiosReturnParser.data.data;

                // console.log("parserResult", JSON.stringify(parserResult, null, 4));

                const originalFileName = clearAccentuatedStringFileUpload(
                    file.name
                );

                const returnData = urls.filter(
                    (u: any) =>
                        u.originalFileName === originalFileName &&
                        u.fieldName === keyFieldName
                )[0];
                const signedUrl = returnData.uploadURL;
                const filename = returnData.fileName;
                const options = {
                    headers: {
                        "Content-Type": returnData.contentType || file.type,
                        "x-amz-tagging": `originalFileName=${originalFileName}`
                    }
                };
                const axiosReturn = await apiDirectCall(
                    signedUrl,
                    "put",
                    file,
                    options,
                    true,
                    false
                );
                valueDefinition.push({
                    cv: {
                        key: filename,
                        originalFileName: file.name
                    },
                    jsonCV: parserResult
                });
            }

            const body = {
                parsedItems: valueDefinition,
                companyId: company.companyId,
                source: "site"
            };
            const axiosReturnSendData = await apiDirectCall(
                "/parser/parseroperationnew",
                "post",
                body
            );
            setUploading(100);
            const postData = axiosReturnSendData.data;

            if (postData.TotalItens === 1) {
                navigate(`/applicant/new/${postData.OPERATIONID}/1`);
            } else {
                navigate(`/applicant/parserlistdetail/${postData.OPERATIONID}`);
            }
            setUploading(false);
        } catch (ex) {
            console.log("ex", ex);
            showMessage({
                message: t("NotIdentifiedProblem"),
                snackBarOrign: { vertical: "top", horizontal: "right" },
                timeout: 1000 * 10,
                typeMessage: "error"
            });
            setUploading(false);
        }
    };

    const newMetadata = { ...metadata, elementType: "multipleFiles" };
    const pageReady = !uploading;
    if (pageReady) {
        return (
            <Grid item xs={12}>
                <Field
                    metadata={newMetadata}
                    type="text"
                    name={field.name}
                    label={t(field.name)}
                    helperTextDefault={t(`${field.name}_helperText`, "")}
                    fullWidth
                    className={props.className}
                    component={File}
                    required={requiredMetadata}
                    t={t}
                    onDropFunction={onDropFunction}
                />
                <AlertUpgradePlan feature="bulkParser" />
            </Grid>
        );
    } else {
        //@ts-ignore
        if (uploading === false) {
            return <Loading />;
        } else {
            const progress: number = Number(uploading);
            return <LinearProgressWithLabel value={progress} />;
        }
    }
};

export default UploadComponent;
