import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Legend,
    LabelList,
    Tooltip
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { IBucketReportItem } from "../BucketReportTable";
import { Box } from "@material-ui/core";

const BucketReportPie = (props: IBucketReportItem) => {
    const { aggregation, title, t, totalFilteredValue } = props;
    const data = (aggregation?.items || []).map((i: Compleo.IObject) => {
        return {
            value: i.total,
            name: i.label
        };
    });

    const finalData = data;
    // React.useMemo(() => data, [
    //     data.length,
    //     totalFilteredValue
    // ]);
    const objKeys = finalData.map((item: any) => item.name);

    const colorData = useRandomColor({
        objectKeys: objKeys,
        luminosity: "dark",
        keyToRenderAgain: aggregation?.key
    });

    return (
        <Box width="100%" height={350}>
            <ResponsiveContainer minWidth="100%" minHeight="100%">
                <PieChart>
                    <Pie
                        data={finalData}
                        cx="50%"
                        cy="50%"
                        // labelLine={true}
                        label={true}
                        // label={renderCustomizedLabel}
                        // outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {finalData.map(
                            (entry: Compleo.IObject, index: number) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colorData.colorsObject[entry.name]}
                                />
                            )
                        )}
                    </Pie>
                    <Legend verticalAlign="bottom" height={36} />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default BucketReportPie;
