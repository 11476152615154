import React from "react";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

interface IProps {
    className?: string;
    evaluationValue: number;
    size?: "small" | "medium" | "large";
    showTextValue?: boolean;
}
const CompleoCompanyRating = (props: IProps) => {
    const {
        className,
        evaluationValue,
        size = "medium",
        showTextValue = false
    } = props;
    const { company } = useAuthState();
    const finalValue = evaluationValue / 2;

    const ratingComp = React.useMemo(
        () => (
            <Rating
                name="read-only"
                value={finalValue}
                precision={0.1}
                readOnly
                className={className}
                getLabelText={(value) => value.toString()}
                size={size}
            />
        ),
        [finalValue]
    );

    return (
        <Tooltip title={finalValue}>
            <Box
                display="flex"
                flexDirection="row"
                style={{ margin: 0, padding: 0 }}
                alignContent="center"
                alignItems="center"
            >
                <Box display="flex" alignContent="center" alignItems="center">
                    {ratingComp}
                </Box>
                {showTextValue && (
                    <Box ml={0.5} alignContent="center" alignItems="center">
                        <Typography variant="caption">
                            (
                            {Math.round((finalValue + Number.EPSILON) * 100) /
                                100}
                            )
                        </Typography>
                    </Box>
                )}
            </Box>
        </Tooltip>
    );
};

export default CompleoCompanyRating;
