import React from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import TextField from "customHooks/useCompleoReactHookForm/Inputs/TextField";
import RichTextV2 from "customHooks/useCompleoReactHookForm/Inputs/RichTextV2/RichTextV2";

interface IProps {
    t: any;
    language: Compleo.IObject;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        rootList: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        textField: {
            margin: theme.spacing(1)
        },
        gridContainer: {},
        gridDescription: {
            padding: "1px"
        },
        gridField: {
            padding: "1px"
        },
        divider: {
            backgroundColor: theme.palette.error.main,
            height: "1px"
        },
        typoBold: {
            fontWeight: "bold"
        }
    })
);

const TranslatedData = (props: IProps) => {
    const classes = useStyles();
    const { t, language } = props;

    const ready = true;

    if (ready) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        name={`translatedData.${language.value}.title`}
                        fullWidth
                        required={false}
                        size="small"
                        label={t("title")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RichTextV2
                        t={t}
                        name={`translatedData.${language.value}.description`}
                        metadata={{
                            fieldName: `translatedData.${language.value}.description`,
                            richTextConfig: {
                                height: "300px"
                            }
                        }}
                        helperTextDefault={t(`description_helperText`, "")}
                        required={false}
                        label={t("description")}
                    />
                </Grid>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default TranslatedData;
