import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantHistoryTimelineItem from "./ApplicantHistoryTimelineItem";
import ApplicantHistoryTimelineItemLoading from "./ApplicantHistoryTimelineItemLoading";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const ApplicantHistoryRow = (props: any) => {
    const classes = useStyles();
    const { data, index, style } = props;
    const { historyData, tLocal, language } = data;
    const historyItem = historyData[index];

    if (historyItem !== undefined) {
        return (
            <div style={style}>
                <ApplicantHistoryTimelineItem
                    historyItem={historyItem}
                    tLocal={tLocal}
                    language={language}
                />
            </div>
        );
    }
    return (
        <div style={style}>
            <ApplicantHistoryTimelineItemLoading
                historyItem={historyItem}
                tLocal={tLocal}
                language={language}
            />
        </div>
    );
};

export default ApplicantHistoryRow;
