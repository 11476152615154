import React from "react";
import ItemView from "./ItemView";
import Icon from "@material-ui/core/Icon";
import { clearArrayInfo, transformDBData } from "functions/util";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { IFieldProps } from ".";
import {
    getFilteredMetadataAndRenamedMetadataComplexField,
    renameComplexField
} from "customHooks/useCompleoForm/Inputs/ComplexField";
import { useValuesFromSourceDirect } from "customHooks/useValuesFromSource";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { getFields } from "customHooks/useCompleoForm/helpers/getFields";
import { MainViewItemData } from "../MainFields/MainView";
import FieldSwitchType from "../MainFields/FieldSwitchType";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface ILocalProps {
    completeMetadata: Compleo.IObject[];
}

const FieldComplexField = (props: IFieldProps & ILocalProps) => {
    const classes = useStyles();
    const {
        fieldData,
        fieldName,
        fieldDef,
        t,
        language,
        inline,
        completeMetadata
    } = props;

    const fieldArray = fieldData[fieldName] || [];
    const showLabel = fieldDef?.elementConfig?.showLabel === true;
    console.log("showLabel", showLabel);

    return (
        <>
            {showLabel && (
                <Paper
                    elevation={0}
                    style={{
                        padding: 4,
                        marginTop: 8,
                        width: "100%",
                        paddingBottom: 0
                    }}
                >
                    {/* <Divider style={{ marginBottom: 8 }} /> */}
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        style={{
                            fontWeight: 900
                        }}
                    >
                        {t(fieldName)}
                    </Typography>
                </Paper>
            )}
            {fieldArray.map((itemData: Compleo.IObject, index: number) => {
                return (
                    <ComplexFieldDetail
                        completeMetada={completeMetadata}
                        fieldName={fieldName}
                        index={index}
                        language={language}
                        t={t}
                        itemData={itemData}
                        key={index}
                    />
                );
            })}
        </>
    );
};

interface IComplexFieldDetail {
    index: number;
    completeMetada: any;
    itemData: any;
    t: any;
    fieldName: string;
    language: string;
}

const ComplexFieldDetail = (props: IComplexFieldDetail) => {
    const { t, index, fieldName, itemData, completeMetada, language } = props;
    const camposMetadados = getFilteredMetadataAndRenamedMetadataComplexField(
        fieldName,
        completeMetada,
        index
    );

    let lastOrderItem = -1;
    return (
        <>
            <Paper elevation={0} style={{ padding: 8, width: "100%" }}>
                <Divider
                    style={{
                        width: "100%",
                        margin: 0,
                        marginTop: 8,
                        marginBottom: 4,
                        padding: 0
                    }}
                />
                <Grid container spacing={1}>
                    <Grid item container spacing={1} xs={12} sm={11}>
                        {camposMetadados.renamedMetadata.map((m: any) => {
                            const lastOrderParam = lastOrderItem;
                            lastOrderItem = m.order;
                            const fieldDef = m;

                            const valueData = {
                                [fieldName]: itemData
                            };
                            return (
                                <MainViewItemData
                                    key={m.fieldName}
                                    lastOrderItem={lastOrderItem}
                                    lastOrderParam={lastOrderParam}
                                    inline={false}
                                >
                                    <FieldSwitchType
                                        fieldData={valueData}
                                        fieldName={clearArrayInfo(m.fieldName)}
                                        type={fieldDef?.elementType}
                                        t={t}
                                        language={language}
                                        fieldDef={fieldDef}
                                        inline={false}
                                        completeMetadata={completeMetada}
                                        hideComplexFieldsDetail={false}
                                        CustomComponents={[]}
                                    />
                                </MainViewItemData>
                            );
                        })}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default FieldComplexField;
