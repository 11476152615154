import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import CustomComponents from "./components/CustomComponents";
import { useApi, useApiCache } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useAdditionalProperties from "./components/useAdditionalPropertiesAndFormMenu";
import useGetLanguagesCareers from "customHooks/useGetLanguages/useGetLanguages";
import { useAuthState } from "_ReactContext/AuthContext";
import { verifyFields } from "./components/helper";

const QuestionnaireEdit = (props: any) => {
    const cancelFunction = useCancelButtonForm("/questionnaire/list");
    const { company } = useAuthState();
    const [hiddenFields, setHiddenFields] = React.useState<string[]>([]);
    const moduleName = "QUESTIONNAIREEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/questionnaire/edit`;
    const [
        languages,
        defaultLanguage,
        readyLanguages
    ] = useGetLanguagesCareers();
    const addProps = useAdditionalProperties(
        languages,
        defaultLanguage,
        readyLanguages,
        i18n.languages[0]
    );

    React.useEffect(() => {
        if (readyLanguages) {
            if (languages.length < 2) {
                setHiddenFields(["translations"]);
            }
        }
    }, [languages, readyLanguages]);

    const [editReturn, edit] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);

    const postAddressSearch = `/questionnaire/search`;
    const [searchResult] = useApi(
        postAddressSearch,
        "post",
        { uuid: props.uuid, companyId: company.companyId },
        false
    );
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        searchResult,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;
    valuesFromSource.deleted = false;
    valuesFromSource.defaultLanguageLabel = t(`Language_${defaultLanguage}`);

    const ready =
        readyTranslation && searchResult.status === "success" && addProps.ready;

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });

    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const enableStartMessageValue = watch("enableStartMessage");
    const enableResultsValues = watch("enableResults");
    const enableRanking = watch("enableRanking") === true;
    const enableResultsByRankingScore =
        watch("enableResultsByRankingScore") === true;
    const results = watch("results") || [];
    const hasResults = results.length > 0;

    const customList: Compleo.CustomLists.ListDefinitionType[] = [
        {
            fieldName: "rankingToResults.result",
            data: results,
            labelName: "name",
            valueName: "name"
        }
    ];

    React.useEffect(() => {
        verifyFields(
            enableStartMessageValue,
            enableResultsValues,
            enableRanking,
            enableResultsByRankingScore,
            hasResults,
            setValue,
            hiddenFields,
            setHiddenFields
        );
    }, [
        enableStartMessageValue,
        enableResultsValues,
        enableRanking,
        enableResultsByRankingScore,
        hiddenFields,
        hasResults
    ]);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: ready,
        i18nV: i18n,
        postMethod: edit,
        postReturn: editReturn,
        redirectAddress: "/questionnaire/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction,
        additionalFieldProperties: addProps.additionalProperties,
        fieldsToHide: hiddenFields,
        reactHookFormMethods: reactHookFormMethods,
        customLists: customList
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default QuestionnaireEdit;
