import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
// import { Field } from "formik";
import { Grid, ListItem, Typography } from "@material-ui/core";
import TextField from "customHooks/useCompleoReactHookForm/Inputs/TextField";
import RichText from "customHooks/useCompleoReactHookForm/Inputs/RichText/RichText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        rootList: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        textField: {
            margin: theme.spacing(1)
        },
        gridContainer: {},
        gridDescription: {
            padding: "1px"
        },
        gridField: {
            padding: "1px"
        },
        divider: {
            backgroundColor: theme.palette.error.main,
            height: "1px"
        },
        typoBold: {
            fontWeight: "bold"
        }
    })
);

interface ITranslationGridProps {
    language: string;
    mainFieldName: string;
    localFieldName: string;
    label: string;
    type: string;
    richText?: boolean;
    t: any;
}

const TranslationGrid = (props: ITranslationGridProps) => {
    const classes = useStyles();
    const {
        language,
        mainFieldName,
        localFieldName,
        label,
        type,
        richText,
        t
    } = {
        richText: false,
        ...props
    };

    return (
        <ListItem>
            <Grid container className={classes.gridContainer}>
                <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    className={classes.gridDescription}
                >
                    <Typography className={classes.typoBold} variant="caption">
                        {`${type} - `}
                    </Typography>
                    <Typography variant="caption">{label}</Typography>
                </Grid>
                <Grid item xs={richText ? 12 : 9} className={classes.gridField}>
                    {richText ? (
                        <RichText
                            name={`${mainFieldName}.${language}.${localFieldName}`}
                            t={t}
                            metadata={{
                                fieldName: `${mainFieldName}.${language}.${localFieldName}`
                            }}
                            helperTextDefault=""
                            required={false}
                            label=""
                        />
                    ) : (
                        <TextField
                            type="text"
                            name={`${mainFieldName}.${language}.${localFieldName}`}
                            // label="Translation"
                            fullWidth
                            className={classes.textField}
                            required={true}
                            variant="outlined"
                            size="small"
                        />
                    )}
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default TranslationGrid;
