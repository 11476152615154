import _ from "lodash";

interface ITransformPivotData {
    data: Compleo.IObject[];
    widget: CompleoShared.Dashboard.widgetsMetadata;
    dataRoot: string;
    countRoot: string;
    t: any;
}
export const transformPivotData = (params: ITransformPivotData) => {
    const columns = getColumns(params);
    const rows = getRows(params);
    const finalData = getFinalData(params, rows, columns);

    return finalData;
};

const getFinalData = (
    params: ITransformPivotData,
    rows: Compleo.IObject[],
    columns: string[]
) => {
    const { data, widget, dataRoot, countRoot, t } = params;
    const columnsObj = columns.map((item) => {
        let label = item;
        const columnDef = widget.pivotTableConfig?.column;
        if (columnDef?.translateOptions?.translate === true) {
            const prefix = columnDef?.translateOptions?.prefix || "";
            label = t(`${prefix}${item}`);
        }
        return { type: "headerLabel", value: label, id: item };
    });

    const columnRowsName = Object.keys(rows[0] || {})
        // .filter((item) => {
        //     const isIdColumn =
        //         (widget.pivotTableConfig?.rows || []).filter(
        //             (i) => i?.idColumnName === item
        //         ).length > 0;
        //     return !isIdColumn;
        // })
        .map((item) => {
            let label = item;
            const rowDef = widget.pivotTableConfig?.rows.filter(
                (i) => i.mainColumnName === item
            )[0];
            if (rowDef?.translateOptions?.translate === true) {
                const prefix = rowDef?.translateOptions?.prefix || "";
                label = t(`${prefix}${item}`);
            }

            const isIdColumn =
                (widget.pivotTableConfig?.rows || []).filter(
                    (i) => i?.idColumnName === item
                ).length > 0;
            if (!isIdColumn) {
                return { type: "headerValues", value: label, id: item };
            } else {
                return { type: "idColumn", value: label, id: item };
            }
        });
    const finalData: Compleo.IObject[] = [
        {
            itemType: "header",
            data: [...columnRowsName, ...columnsObj]
        }
    ];

    const mainColumnName =
        widget.pivotTableConfig?.column?.mainColumnName || "";
    const rowDef = widget.pivotTableConfig?.rows || [];

    rows.map((rowItem) => {
        const valuesArray: Compleo.IObject[] = [];
        columns.map((colItem) => {
            const itemData = data.filter((itemDetail) => {
                const columnData = _.get(
                    itemDetail,
                    `${dataRoot}.${mainColumnName}`
                );

                const rowDataReturn: Compleo.IObject = {};
                rowDef.map((rDef) => {
                    rowDataReturn[rDef.mainColumnName] = _.get(
                        itemDetail,
                        `${dataRoot}.${rDef.mainColumnName}`
                    );
                    if (rDef.idColumnName) {
                        rowDataReturn[rDef.idColumnName] = _.get(
                            itemDetail,
                            `${dataRoot}.${rDef.idColumnName}`
                        );
                    }
                });
                if (columnData && columnData === colItem) {
                    if (_.isEqual(rowDataReturn, rowItem)) {
                        return true;
                    }
                }
                return false;
            })[0];
            valuesArray.push({
                type: "rowValue",
                value: itemData?.doc_count || null,
                columnId: colItem
            });
        });

        const rowLabelData = Object.keys(rowItem)
            // .filter((item) => {
            //     const isIdColumn =
            //         (widget.pivotTableConfig?.rows || []).filter(
            //             (i) => i?.idColumnName === item
            //         ).length > 0;

            //     return !isIdColumn;
            // })
            .map((item) => {
                const isIdColumn =
                    (widget.pivotTableConfig?.rows || []).filter(
                        (i) => i?.idColumnName === item
                    ).length > 0;

                if (!isIdColumn) {
                    return {
                        type: "rowLabel",
                        value: rowItem[item],
                        columnId: item
                    };
                } else {
                    return {
                        type: "rowId",
                        value: rowItem[item],
                        columnId: item
                    };
                }
            });

        finalData.push({
            itemType: "row",
            data: [...rowLabelData, ...valuesArray]
        });
    });
    return finalData;
};

const getColumns = (params: ITransformPivotData) => {
    const { data, widget, dataRoot, countRoot } = params;
    const mainColumnName = widget.pivotTableConfig?.column?.mainColumnName;
    if (!mainColumnName) {
        return [];
    }
    const columns: string[] = data.map((item) =>
        _.get(item, `${dataRoot}.${mainColumnName}`)
    );
    const uniqueColumns = _.uniq(columns);
    return uniqueColumns;
};

const getRows = (params: ITransformPivotData) => {
    const { data, widget, dataRoot, countRoot } = params;
    const rowDef: CompleoShared.Dashboard.IRowsColumnsDashboard[] =
        widget.pivotTableConfig?.rows || [];

    const rows = data.map((item) => {
        const rowDataReturn: Compleo.IObject = {};
        rowDef.map((rDef) => {
            rowDataReturn[rDef.mainColumnName] = _.get(
                item,
                `${dataRoot}.${rDef.mainColumnName}`
            );
            if (rDef.idColumnName) {
                rowDataReturn[rDef.idColumnName] = _.get(
                    item,
                    `${dataRoot}.${rDef.idColumnName}`
                );
            }
        });
        return rowDataReturn;
    });
    const uniqueRows = _.uniqWith(rows, _.isEqual);
    return uniqueRows;
};
