import React from "react";
import ItemCard from "./ItemCard";
import Typography from "@material-ui/core/Typography";
import useList from "customHooks/useList";
import { transformDBData } from "functions/util";
import { IFieldProps } from ".";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "@gatsbyjs/reach-router";
import _ from "lodash";
import EmbedLink from "./EmbedLink";

const FieldTitle = (props: IFieldProps) => {
    const { fieldData, fieldName, returnType, url } = props;
    const [listData] = useList();
    const { t, language } = listData.tempInfo;

    switch (returnType) {
        case "card":
            return (
                <ItemCard
                    dontApplyTypography={true}
                    removeColonAfterLabel={true}
                    smSize={12}
                >
                    <EmbedLink url={url}>
                        <Typography gutterBottom variant="h6">
                            {_.get(fieldData, fieldName)}
                        </Typography>
                    </EmbedLink>
                </ItemCard>
            );
        case "table":
            return (
                <EmbedLink url={url}>
                    {transformDBData({
                        value: _.get(fieldData, fieldName),
                        t,
                        language,
                        replaceOption: "-"
                    })}
                </EmbedLink>
            );
    }
};

export default FieldTitle;
