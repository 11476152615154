import React from "react";
import useJobContext from "Pages/Job/JobView/useJobContext";
import ApplicantViewHeaderButtonRemoveFromJob from "./ApplicantViewHeaderButtonRemoveFromJob";
import { ApplicantViewJobDataInsideJob } from "./useApplicantViewContext/ApplicantViewTypes";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { BlockRounded, TrendingUp } from "@material-ui/icons";
import { IJobViewStages } from "Pages/Job/JobView/useJobContext/JobViewTypes";
import JobKanbanApplicantChangeStageModal from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/JobKanbanApplicantChangeStageModal";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useJobChangeStageFunctionParam from "Pages/Job/JobView/JobKanban/JobKanbanApplicant/JobKanbanApplicantActions/useJobChangeStageFunctionParam";
import useApplicantViewContext from "./useApplicantViewContext";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        iconActionButton: {
            color: "inherit"
        }
    });
});

interface IProps {
    jobDataInsideJob?: ApplicantViewJobDataInsideJob;
    // ApplicantData: Compleo.IObject;
    listKeyToInvalidate?: string[];
    currentIndex: number;
    t: any;
    stageId: string;
}

const DisqualifyRequalifyJobInsideJobContainer = (props: IProps) => {
    const classes = useStyles();
    const [data] = useJobContext();
    const [applicantContext] = useApplicantViewContext();

    const {
        jobDataInsideJob,
        currentIndex,
        listKeyToInvalidate,
        t,
        stageId
    } = props;
    const jobDataContext = jobDataInsideJob?.jobDataContext || {};
    const [disqualifyModalOpen, setDisqualifyModalOpen] = React.useState(false);
    const jobModalFN = useJobChangeStageFunctionParam();

    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === stageId
    )[0];
    const JobData = (applicantContext.applicantData?.Jobs || []).filter(
        (item: Compleo.IObject) => item.JobId === jobDataInsideJob?.jobId
    )[0];
    const applicantData = {
        ...applicantContext.applicantData,
        jobData: JobData
    };

    const tJobView = data.t;

    const title =
        stageData?.type !== "rejected"
            ? tJobView("ModalDisqualify_title")
            : tJobView("ModalRequalify_title");

    if (!jobDataContext.readonly && stageData) {
        return (
            <>
                <ButtonIconTooltipCompleo
                    label={title}
                    onClick={() => setDisqualifyModalOpen(true)}
                    className={classes.iconActionButton}
                >
                    {stageData.type !== "rejected" ? (
                        <BlockRounded fontSize="small" />
                    ) : (
                        <TrendingUp fontSize="small" />
                    )}
                </ButtonIconTooltipCompleo>
                {disqualifyModalOpen && (
                    <JobKanbanApplicantChangeStageModal
                        onClose={() => setDisqualifyModalOpen(false)}
                        open={disqualifyModalOpen}
                        allStages={data.LocalStateData.allStages}
                        stageData={stageData}
                        t={data.t}
                        ApplicantData={applicantData}
                        currentIndex={currentIndex}
                        stagesOnlyTotal={data.LocalStateData.stagesOnlyTotal}
                        listKeyToInvalidate={[]}
                        changeStage={false}
                        outsideKanban={false}
                        fnAfterUpdate={jobModalFN.jobChangeStageFunction}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default DisqualifyRequalifyJobInsideJobContainer;
