import { pipelineGroupType } from "Pages/Pipeline/components/helperPipeline";

export interface IJobFunnelChildren {
    mainData: IMainJobFunnelData;
    t: any;
    exportFileName?: string;
}
interface IDisqualifyReasonsWithStage {
    jobId: string;
    pipelineStage: string;
    pipelineStageId: string;
    stageType: string;
    disqualifyReason: string;
    stageDisqualified: string;
    stageIdDisqualified: string;
    total: number;
}

interface IApplicantsByStage {
    jobId: string;
    pipelineStage: string;
    pipelineStageId: string;
    stageType: string;
    stageGroup: pipelineGroupType;
    total: number;
}

interface IDisqualifyReasons {
    jobId: string;
    stageType: string;
    disqualifyReason: string;
    total: number;
}

interface IPipelineStages {
    name: string;
    fixed: boolean;
    id: string;
    type: {
        value: string;
    };
    order: number;
}

export interface IReturnFunnelData {
    value: number;
    name: string;
    order: number;
    rejectedAtStage: number;
    totalCurrentStage: number;
}

export interface IMainJobFunnelData {
    data: {
        applicantsByStage: IApplicantsByStage[];
        disqualifyReasonsWithStage: IDisqualifyReasonsWithStage[];
        disqualifyReasons: IDisqualifyReasons[];
    };
    pipelineStages: IPipelineStages[];
}

export const handleFunnelData = (mainData: IMainJobFunnelData, t: any) => {
    const applicantsByStage = mainData?.data?.applicantsByStage || [];
    const disqualifyReasonsWithStage =
        mainData?.data?.disqualifyReasonsWithStage || [];

    const total = applicantsByStage.reduce(
        (previous, current, index, items) => {
            return previous + current.total;
        },
        0
    );
    const reversePipelineStages = getReversePipelineStages(
        mainData?.pipelineStages || []
    );

    const returnData: IReturnFunnelData[] = [];

    reversePipelineStages.reduce((previous, current, index, allData) => {
        const totalCurrentStage =
            applicantsByStage.filter((i) => i.pipelineStage === current.name)[0]
                ?.total || 0;

        const rejectedAtStage = disqualifyReasonsWithStage
            .filter((i) => i.stageDisqualified === current.name)
            .reduce((previousRejected, currentRejected) => {
                return previousRejected + (currentRejected?.total || 0);
            }, 0);

        const currentValue = previous + totalCurrentStage + rejectedAtStage;
        returnData.push({
            name: current.name,
            value: currentValue,
            order: allData.length - index,
            rejectedAtStage: rejectedAtStage,
            totalCurrentStage: totalCurrentStage
        });
        return currentValue;
    }, 0);

    const rejectedAtNewStage = getRejectedsAtNewGroupStage(mainData);
    const totalAtNewGroup = getTotalAtNewGroupStage(mainData);

    returnData.push({
        name: t("newApplicants"),
        value: total,
        order: 0,
        rejectedAtStage: rejectedAtNewStage,
        totalCurrentStage: totalAtNewGroup
    });

    returnData.sort((a, b) => a.order - b.order);
    return returnData;
};

const getReversePipelineStages = (pipelineStages: IPipelineStages[]) => {
    const typesToRemove = [
        "newApplied",
        "newSourced",
        "newReferred",
        "rejected"
    ];
    return pipelineStages
        .filter((i) => !typesToRemove.includes(i.type?.value))
        .sort((a, b) => b.order - a.order);
};

const getRejectedsAtNewGroupStage = (mainData: IMainJobFunnelData) => {
    const newStageName: string[] = (mainData?.pipelineStages || [])
        .filter((i: Compleo.IObject) =>
            ["newApplied", "newSourced", "newReferred"].includes(i?.type?.value)
        )
        .map((i: Compleo.IObject) => i.name);

    const rejectedAtNewStage = (
        mainData?.data?.disqualifyReasonsWithStage || []
    )
        .filter((i) => i.stageType === "rejected")
        .reduce((previous, current, index, items) => {
            if (newStageName.includes(current.stageDisqualified)) {
                return current.total + previous;
            } else {
                return previous;
            }
        }, 0);
    return rejectedAtNewStage;
};

const getTotalAtNewGroupStage = (mainData: IMainJobFunnelData) => {
    const rejectedAtNewStage = (mainData?.data?.applicantsByStage || [])
        .filter((i) =>
            ["newApplied", "newSourced", "newReferred"].includes(i.stageType)
        )
        .reduce((previous, current, index, items) => {
            return current.total + previous;
        }, 0);
    return rejectedAtNewStage;
};
