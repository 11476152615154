import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import { FieldProps } from "formik";

interface IProps {
    label: string;
    helperTextDefault: null | string;
}

const CNPJField = (props: IProps & FieldProps) => {
    const {
        label,
        field,
        form: { dirty, touched, errors, status },
        helperTextDefault,
        ...other
    } = props;
    const errorText = errors[field.name];
    const hasError = touched[field.name] && errorText !== undefined;
    let mensagemErro =
        hasError && errorText !== undefined
            ? errorText
            : helperTextDefault !== undefined
            ? helperTextDefault
            : "";

    if (status && status[field.name]) {
        if (mensagemErro === "") {
            mensagemErro += status[field.name];
        } else {
            mensagemErro += " - " + status[field.name];
        }
    }

    return (
        <NumberFormat
            id={field.name}
            label={label}
            error={hasError || (status && status[field.name] !== undefined)}
            helperText={mensagemErro}
            {...field}
            {...other}
            format="##.###.###/####-##"
            customInput={TextField}
        />
    );
};

// CNPJField.defaultProps = {
//     fullWidth: true,
//     margin: "normal"
// };

export default CNPJField;
