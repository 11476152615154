import _ from "lodash";
import { clearArrayInfo } from "functions/util";

export const getControlOptionsAndChildrenFilter = (
    campoMetadata: any,
    options: any,
    customLists: any,
    field: any,
    values: any,
    props: any
) => {
    let controlOptions = options !== undefined ? options : [];
    let parentFilterField = props.parentFilterField;
    let childrenFilterFieldName = props.childrenFilterFieldName;

    const selectCustomList =
        campoMetadata.selectCustomList === undefined
            ? false
            : campoMetadata.selectCustomList;

    if (selectCustomList) {
        const customList = (customLists || []).filter(
            (c: any) => c.fieldName === clearArrayInfo(field.name)
        )[0];
        const dataOptions = customList?.data || [];
        controlOptions = convertToAutocompletePattern(
            dataOptions,
            customList?.valueName || "value",
            customList?.labelName || "label"
        );
        if (customList?.parentFilterField) {
            parentFilterField = customList.parentFilterField;
        }
        if (customList?.childrenFilterFieldName) {
            childrenFilterFieldName = customList.childrenFilterFieldName;
        }
    }

    if (parentFilterField) {
        const parentObject = _.get(values, parentFilterField.parentFieldName);
        if (parentObject) {
            const parentValue =
                parentObject[parentFilterField.parentIdFieldFilter];
            controlOptions = controlOptions.filter(
                (c: any) =>
                    c[(parentFilterField || {}).fieldFilter] === parentValue
            );
        } else {
            controlOptions = [];
        }
    }
    return { controlOptions, childrenFilterFieldName };
};

export const convertToAutocompletePattern = (
    data: any[],
    valueName: string,
    labelName: string
) => {
    const dataReturn: Compleo.IObject[] = [];
    data.map((d: any) => {
        const {
            [valueName]: removeValue,
            [labelName]: removeLabel,
            ...rest
        } = d;
        dataReturn.push({ value: d[valueName], label: d[labelName], ...rest });
    });
    return dataReturn;
};
