import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { Box, Button, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ApplicantViewModalContainer from "../ApplicantView/ApplicantViewModalContainer";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { navigate } from "@gatsbyjs/reach-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper
        }
    })
);

const ApplicantMergeList = (props: any) => {
    const classes = useStyles();
    const { company } = useAuthState();
    const [openModal, setOpenModal] = React.useState<null | string>(null);
    const [mainApplicantId, setMainApplicantId] = React.useState<string>(
        props.applicantid
    );
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const { callDialog } = useGlobalDialog();

    const [checked, setChecked] = React.useState<string[]>([props.applicantid]);
    const moduleName = "APPLICANTMERGE";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddressSearch = `/applicant/applicantmergelist`;
    const [getData] = useApiCache(
        postAddressSearch,
        "post",
        {
            id: props.applicantid,
            companyId: company.companyId
        },
        false
    );
    const items = getData.response?.data?.data || [];
    const readyData = getData.status === "success" && readyTranslation;

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const setAsMain = (applicantId: string) => {
        setMainApplicantId(applicantId);
        const currentIndex = checked.indexOf(applicantId);
        if (currentIndex === -1) {
            const newChecked = [...checked];
            newChecked.push(applicantId);
            setChecked(newChecked);
        }
    };

    const submit = () => {
        setIsSubmitting(true);
        if (checked.length < 2 || checked.length > 5) {
            callDialog({
                title: t("errorTitle"),
                bodyText: t("errorMessage"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: () => {
                    setIsSubmitting(false);
                }
            });
            return;
        }
        const postData: Compleo.IObject = {
            companyId: company.companyId,
            applicantsIds: checked,
            mainApplicantId: mainApplicantId
        };

        apiDirectCall("/applicant/mergeapplicants", "post", postData)
            .then((data) => {
                callDialog({
                    title: t("successTitle"),
                    bodyText: t("successMessage"),
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    agreeFunction: () => {
                        navigate("/applicant/list");
                        setIsSubmitting(false);
                    }
                });
            })
            .catch((error) => {
                callDialog({
                    title: t("errorTitle"),
                    bodyText: t("NotIdentifiedProblem"),
                    agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                    agreeFunction: () => {
                        setIsSubmitting(false);
                    }
                });
                setIsSubmitting(false);
            });
    };

    return (
        <Page
            featureName={t("a_FeatureName", "")}
            featureMainDescription={t("a_FeatureMainDescription", "")}
        >
            {readyData ? (
                <Box display="flex" flexDirection="column" width="100%">
                    <Box width="100%" mb={2}>
                        <Alert>{t("instructions")}</Alert>
                    </Box>
                    <Box width="100%" mb={3}>
                        <List className={classes.root}>
                            {items.map(
                                (value: Compleo.IObject, index: number) => {
                                    const labelId = `checkbox-list-label-${value.pk}`;
                                    const applicantIdItem = value.pk.split(
                                        ":"
                                    )[1];
                                    const isMain =
                                        mainApplicantId === applicantIdItem;
                                    return (
                                        <React.Fragment key={value.pk}>
                                            <ListItem
                                                role={undefined}
                                                dense
                                                button={
                                                    isMain ? false : undefined
                                                }
                                                onClick={
                                                    !isMain
                                                        ? handleToggle(
                                                              applicantIdItem
                                                          )
                                                        : () => {}
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            isMain
                                                                ? true
                                                                : checked.indexOf(
                                                                      applicantIdItem
                                                                  ) !== -1
                                                        }
                                                        tabIndex={-1}
                                                        disableRipple
                                                        disabled={isMain}
                                                        inputProps={{
                                                            "aria-labelledby": labelId
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={labelId}
                                                    primary={`${value.name} ${
                                                        isMain
                                                            ? ` - ${t(
                                                                  "mainApplicant"
                                                              )}`
                                                            : ""
                                                    }`}
                                                    secondary={`${value.email} - ${value.cpf}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <ButtonIconTooltipCompleo
                                                        label={t("btnView")}
                                                        onClick={() => {
                                                            setOpenModal(
                                                                applicantIdItem
                                                            );
                                                        }}
                                                        edge={
                                                            isMain
                                                                ? "end"
                                                                : undefined
                                                        }
                                                    >
                                                        <VisibilityIcon />
                                                    </ButtonIconTooltipCompleo>
                                                    {!isMain && (
                                                        <ButtonIconTooltipCompleo
                                                            label={t(
                                                                "btnDefault"
                                                            )}
                                                            edge="end"
                                                            onClick={() => {
                                                                setAsMain(
                                                                    applicantIdItem
                                                                );
                                                            }}
                                                        >
                                                            <CheckIcon />
                                                        </ButtonIconTooltipCompleo>
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {items.length - 1 > index && (
                                                <Divider />
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </List>
                    </Box>
                    <Box width="100%">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                submit();
                            }}
                            disabled={isSubmitting}
                        >
                            {t("btnSubmit")}
                        </Button>
                    </Box>
                    <ApplicantViewModalContainer
                        onClose={() => setOpenModal(null)}
                        open={openModal !== null}
                        loadingData={readyData}
                        ApplicantId={openModal ? openModal : ""}
                        module="APPLICANTVIEW"
                    />
                </Box>
            ) : (
                <Loading />
            )}
        </Page>
    );
};

export default ApplicantMergeList;
