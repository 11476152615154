import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Divider, ListItemIcon } from "@material-ui/core";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CompleoTabs, {
    ITabsCompleo,
    TabTypeCompleo
} from "Components/CompleoTabs";
import { ITemplateEmailData } from "./EmailData";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

export interface DialogBodyProps {
    handleChooseAnOption?: (value: string) => Promise<void>;
    handleChooseAnTemplate?: (template: ITemplateEmailData) => Promise<void>;
    placeholders?: Compleo.IObject[];
    templates?: ITemplateEmailData[];
    t: any;
}

export const DialogChooseTemplateOrPlaceholderBody = (
    props: DialogBodyProps
) => {
    const [loading, setLoading] = React.useState(false);
    const {
        handleChooseAnOption,
        handleChooseAnTemplate,
        placeholders = [],
        templates = [],
        t
    } = props;

    const handleListItemClick = async (value: string) => {
        if (handleChooseAnOption) {
            setLoading(true);
            await handleChooseAnOption(value);
            setLoading(false);
        }
    };

    const handleClickTemplate = async (template: ITemplateEmailData) => {
        if (handleChooseAnTemplate) {
            setLoading(true);

            await handleChooseAnTemplate(template);
            setLoading(false);
        }
    };

    const tabs: TabTypeCompleo[] = [];

    if (placeholders && placeholders.length && placeholders.length > 0) {
        const Tab1Component = (
            <List>
                {placeholders.map((placeholder: Compleo.IObject) => {
                    const required = placeholder?.required === true;
                    const label = required
                        ? `${placeholder.label} (${t(
                              "EMAILDATAFIELD_required"
                          )})`
                        : placeholder.label;
                    return (
                        <ChooseItem
                            key={placeholder.value}
                            handleClick={() =>
                                handleListItemClick(placeholder.value)
                            }
                            IconItem={FindReplaceIcon}
                            label={label}
                        />
                    );
                })}
            </List>
        );
        tabs.push({
            label: t("EMAILDATAFIELD_textChoosePlaceholder"),
            Component: Tab1Component
        });
    }

    if (templates && templates.length && templates.length > 0) {
        const Tab2Component = (
            <List>
                {templates.map((template) => (
                    <ChooseItem
                        key={template.label}
                        handleClick={async () =>
                            await handleClickTemplate(template)
                        }
                        IconItem={MailOutlineIcon}
                        label={template.label}
                    />
                ))}
            </List>
        );
        tabs.push({
            label: t("EMAILDATAFIELD_textChooseTemplate"),
            Component: Tab2Component
        });
    }

    if (!loading) {
        if (tabs.length > 1) {
            return <CompleoTabs t={t} tabs={tabs} />;
        } else if (tabs.length === 1) {
            return tabs[0].Component;
        } else {
            return null;
        }
    } else {
        return <Loading />;
    }
};

export interface DialogSubjectProps {
    handleChooseAnOption: (value: string) => void;
    placeholders: Compleo.IObject[];
}

export const DialogChoosePlaceHolderSubject = (props: DialogSubjectProps) => {
    const { handleChooseAnOption, placeholders } = props;
    const handleListItemClick = (value: string) => {
        handleChooseAnOption(value);
    };

    return (
        <List>
            {placeholders.map((placeholder: Compleo.IObject) => (
                <ChooseItem
                    key={placeholder.value}
                    handleClick={() => handleListItemClick(placeholder.value)}
                    IconItem={FindReplaceIcon}
                    label={placeholder.label}
                />
            ))}
        </List>
    );
};
interface IChooseItemProps {
    handleClick: any;
    label: string;
    IconItem: any;
}

const ChooseItem = (props: IChooseItemProps) => {
    const { handleClick, label, IconItem } = props;
    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <IconItem />
                </ListItemIcon>
                <ListItemText primary={label} />
            </ListItem>
            <Divider />
        </React.Fragment>
    );
};
