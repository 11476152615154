import { Box, Divider, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ApplicantViewMainView from "./ApplicantViewMainView";
import ApplicantViewHeader from "./ApplicantViewHeader";
import useApplicantViewContext from "./useApplicantViewContext";
import ApplicantViewSideContainer from "./ApplicantViewSideContainer";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import theme from "theme/Theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main,
            // backgroundColor: "green",
            // maxHeight: "70%",
            width: "100%"
            // minHeight: "100%"
            // maxHeight: "70%"
        },
        mainView: {
            overflow: "auto",
            height: "calc(95vh - 80px - 54px - 20px)"
            // display: "flex",
            // flexDirection: "column"
            // height: "60vh"
        }
    })
);

interface IProps {}

const ApplicantViewMain = (props: IProps) => {
    const classes = useStyles();
    const [data] = useApplicantViewContext();

    const isXSSMScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));
    const firstBoxSize = isXSSMScreen || data.readOnly ? 100 : isMd ? 50 : 70;

    if (data.ready && data?.applicantData?.pk) {
        return (
            <Box className={classes.container}>
                <ApplicantViewHeader />
                <Box display="flex" flexDirection="row">
                    <Box
                        className={classes.mainView}
                        // overflow="auto"
                        width={`${firstBoxSize}%`}
                        // height="60vh"
                    >
                        <ApplicantViewMainView readOnly={data.readOnly} />
                    </Box>
                    {!data.readOnly && (
                        <Hidden smDown>
                            <Divider orientation="vertical" flexItem />
                            <Box
                                width={`${100 - firstBoxSize}%`}
                                className={classes.mainView}
                            >
                                <ApplicantViewSideContainer />
                            </Box>
                        </Hidden>
                    )}
                </Box>
            </Box>
        );
    } else {
        return (
            <Box padding={50}>
                <Loading />
            </Box>
        );
    }
};

export default ApplicantViewMain;
