import React from "react";
import Page from "Pages/_Layouts/DefaultExternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi, ApiStateType } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { newPasswordFunction } from "./components/NewPasswordFunction";
import { useAuthState } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import GenericAdditionalGrid from "./GenericAdditionalGrid";

const NewPassword = (props: any) => {
    const classes = useFormStyles();
    const [t, i18n, readyTranslation] = useTranslation("NEWPASSWORD", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("NEWPASSWORD");

    const { login } = useAuthState();
    const redirectAfterLogin: null | string | string[] = "/";

    const newPassword = newPasswordFunction(t, props.email, login);
    const newPasswordReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const valuesFromSource = {
        confirmationCode: "",
        password: "",
        confirmpassword: ""
    };

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        newPassword,
        newPasswordReturn,
        redirectAfterLogin,
        FormExternal,
        metadata,
        valuesFromSource,
        null,
        null,
        undefined
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;
    const additionalGrid = <GenericAdditionalGrid t={t} />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureMainDescription={t("a_PageName")}
                maxWidth="xs"
                additionalGrid={additionalGrid}
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default NewPassword;
