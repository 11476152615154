import React from "react";
import Page from "Pages/_Layouts/DefaultExternal";
import useCompleoForm from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useApi } from "customHooks/useApi";
import FormExternal from "customHooks/useCompleoForm/components/FormExternal";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { navigate } from "@gatsbyjs/reach-router";
import useValuesFromSource from "customHooks/useValuesFromSource";

const TrialNew = (props: any) => {
    const classes = useFormStyles();
    const [t, i18n, readyTranslation] = useTranslation("TRIALREQUESTEMAIL", {
        useSuspense: false
    });
    let id = "";
    const postAddress = `/trial/new`;

    const [addTrialReturn, addTrial] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata("TRIALREQUESTEMAIL");

    if (addTrialReturn.status === "success") {
        id = addTrialReturn.response.data.data.uidAccess;
        navigate(`/trial/trialcompany/${id}`);
    }

    const [valuesFromSource] = useValuesFromSource(metadata, false, {}); //nao enviar o company id => false
    valuesFromSource.timeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo";
    valuesFromSource.plan = props.plan;

    const [formCompleo, finished] = useCompleoForm(
        classes,
        t,
        props,
        readyTranslation,
        i18n,
        addTrial,
        addTrialReturn,
        null,
        FormExternal,
        metadata,
        valuesFromSource,
        null,
        null
    );

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page featureMainDescription={t("a_PageName")}>{compRetorno}</Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default TrialNew;
